import moment from 'moment'
import { parseISO } from 'date-fns'

export const ALL = 'All'
export const dashboardModelTypes = [
  { label: 'Documents', value: 'document' },
  { label: 'Loads', value: 'load' },
  { label: 'Invoices', value: 'invoice' },
]

export const dashboardDateRange = {
  startDate: parseISO(
    `${moment().startOf('month').format('YYYY-MM-DDT00:00:00.000')}`,
  ),
  endDate: parseISO(`${moment().format('YYYY-MM-DDT00:00:00.000')}`),
  key: 'selection',
}

export const createDashboardPostBody = currentModelType => {
  let newPostBody = {}

  switch (currentModelType) {
    case 'document': {
      newPostBody = dashboardDocumentsPostBody
      break
    }
    case 'load': {
      newPostBody = dashboardLoadsPostBody
      break
    }
    case 'invoice': {
      newPostBody = dashboardInvoicesPostBody
      break
    }
    default:
      break
  }

  return newPostBody
}

export const createDashboardQuery = ({ createdDateRange, currentUser }) => {
  const buyer_id = currentUser?.id || 0

  let query = ''

  if (buyer_id) {
    query += `buyer_id=${buyer_id}`
  }
  if (
    createdDateRange &&
    createdDateRange.startDate &&
    createdDateRange.endDate
  ) {
    const { startDate, endDate } = createdDateRange
    if (!query) {
      query += `filters[created_at]=${moment(startDate).format(
        'YYYY-MM-DDT00:00:00.000',
      )}...${moment(endDate).format('YYYY-MM-DDT23:59:59.000')}`
    } else {
      query += `&filters[created_at]=${moment(startDate).format(
        'YYYY-MM-DDT00:00:00.000',
      )}...${moment(endDate).format('YYYY-MM-DDT23:59:59.000')}`
    }
  }
  if (query.length > 0) {
    query = '?' + query
  }

  return query
}

export const searchParamsToFilters = (urlHash, container) => {
  let filters = {}

  urlHash.forEach((value, key) => {
    if (value === 'Not Set' || !value) {
      value = null
    }
    if (VALID_URL_FILTERS[container].includes(key)) {
      if (ENUM_FILTERS.includes(key)) {
        const newLoadStatus = mapPropertyLabels(value, ENUM_FIELDS)
        filters[key] = newLoadStatus
      } else if (ID_FILTERS.includes(key) && value) {
        filters[key] = parseInt(value)
      } else if (DATE_FILTERS.includes(key)) {
        mapDateSearchParams(filters, key, value, container)
      } else if (key === 'qty') {
        filters[key] = splitQuantityString(value)
      } else {
        filters[key] = value
      }
    }
  })

  return filters
}

export const invoiceParamsToReduxFilters = (defaultFilters, urlFilters) => {
  const filtersCopy = { ...defaultFilters }

  for (const prop in urlFilters) {
    if (prop == 'startDate' || prop == 'endDate') {
      filtersCopy[prop] = urlFilters[prop]
    } else {
      let newArrayFilter = [...filtersCopy[prop]]
      newArrayFilter.push(urlFilters[prop])
      filtersCopy[prop] = newArrayFilter
    }
  }

  return filtersCopy
}

const mapDateSearchParams = (filters, key, date, container) => {
  switch (container) {
    case 'documents':
      if (!filters['dateRange']) {
        filters['dateRange'] = {}
      }
      filters['dateRange'][key] = parseISO(
        moment(date).format('YYYY-MM-DDT00:00:00.000'),
      )
      break
    case 'loads':
      if (!filters['createdAt']) {
        filters['createdAt'] = {}
      }
      filters['createdAt'][key] = parseISO(
        moment(date).format('YYYY-MM-DDT00:00:00.000'),
      )
      break
    case 'invoices':
      let d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      const dateString = [year, month, day].join('-')
      filters[key] = dateString
      break
    default:
      break
  }

  return filters
}

const mapPropertyLabels = (label, ids) => {
  if (!label) {
    return label
  }

  let newValue = ''

  for (let i = 0; i < ids.length; i++) {
    if (label == ids[i].label) {
      newValue = ids[i].value
      break
    }
  }

  return newValue
}

const splitQuantityString = qty => {
  const qtyArray = qty.split('...')

  return {
    lower: parseInt(qtyArray[0]),
    upper: parseInt(qtyArray[1]),
  }
}

const dashboardDocumentsPostBody = {
  type: 'buyer',
  model: 'documents',
  group_by: ['status', 'doc_type', ['doc_type', 'status']],
}

const dashboardLoadsPostBody = {
  type: 'buyer',
  model: 'loads',
  group_by: [
    'qty',
    'seller_id',
    'hitl_status',
    'buyer_terminal_id',
    'seller_terminal_id',
    'buyer_seller_product_id',
    'primary_parser_status',
    ['buyer_terminal_id', 'buyer_seller_product_id'],
  ],
  calculations: [
    ['count', ''],
    ['sum', 'qty'],
  ],
}

const dashboardInvoicesPostBody = {
  type: 'buyer',
  model: 'invoices',
  group_by: ['qty', 'load_count', 'status', 'category', 'seller_id'],
  calculations: [
    ['count', ''],
    ['sum', 'load_count'],
    ['sum', 'total'],
    ['sum', 'qty'],
  ],
}

const ENUM_FIELDS = [
  { label: 'notSent', value: 0 },
  { label: 'waiting', value: 1 },
  { label: 'done', value: 2 },
  { label: 'updated', value: 3 },
  { label: 'notSent', value: 0 },
  { label: 'load', value: 1 },
  { label: 'invoice', value: 2 },
  { label: 'Material', value: 0 },
  { label: 'Delivery', value: 1 },
  { label: 'Delivered Material', value: 2 },
  { label: 'Multi-line Delivered Material', value: 3 },
]

const VALID_URL_FILTERS = {
  documents: ['docType', 'status', 'startDate', 'endDate'],
  loads: [
    'primaryParserStatus',
    'hitlStatus',
    'buyerTerminalId',
    'qty',
    'productId',
    'startDate',
    'endDate',
  ],
  invoices: ['type', 'status', 'sellerId', 'startDate', 'endDate'],
}

const ENUM_FILTERS = ['docType', 'primaryParserStatus', 'hitlStatus', 'types']
const ID_FILTERS = ['buyersList', 'productId', 'sellers']
const DATE_FILTERS = ['startDate', 'endDate']
