import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../redux/reducers'
import { routerMiddleware } from 'connected-react-router'

export default function configureStore(initialState, history) {
  const middewares = [thunkMiddleware, routerMiddleware(history)]

  const resetReducer = history => (state, action) => {
    if (action.type == 'userAccesses/resetStore') {
      return rootReducer(history)(undefined, action)
    }

    return rootReducer(history)(state, action)
  }

  return createStore(
    resetReducer(history),
    initialState,
    compose(applyMiddleware(...middewares)),
  )
}
