var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useReducer } from 'react';
import { documentLabelerReducer, } from 'documentLabeler/state/DocumentLabelerReducer';
import { DocumentLabelerState, } from 'documentLabeler/state/DocumentLabelerState';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
export var DocumentLabelerStateContext = createContext(null);
export var DocumentLabelerDispatchContext = createContext(null);
/**
 * Document Labeler Provider, handles state management and actions for the Document Labeler
 *
 * State functions are handled in DocuemntLabelerState.tsx and the
 * Action reducer is handled in DocumentLabelerReducer.tsx
 */
export var DocumentLabelerProvider = function (_a) {
    var initialState = _a.initialState, rootRef = _a.rootRef, children = _a.children;
    var onLabelUpdate = useBBConfiguration().onLabelUpdate;
    var _b = useReducer(documentLabelerReducer, DocumentLabelerState.generateInitialState(initialState, rootRef)), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        if (onLabelUpdate) {
            onLabelUpdate(DocumentLabelerState.convertInternalStateToOutputData(state));
        }
    }, [state.docInfo]);
    useEffect(function () {
        dispatch({
            type: 'setDocInfo',
            payload: initialState.data,
        });
    }, [initialState.data]);
    return (_jsx(DocumentLabelerStateContext.Provider, __assign({ value: state }, { children: _jsx(DocumentLabelerDispatchContext.Provider, __assign({ value: dispatch }, { children: children })) })));
};
/**
 * This useState fn is responsible for transforming the internal state of
 * this provider into more easily consumable external state for
 * use in children components.
 */
var useState = function () {
    var context = useContext(DocumentLabelerStateContext);
    if (context === null) {
        throw new Error('Document Labeler useState must be used within a Provider');
    }
    return context;
};
var useDispatch = function () {
    var context = useContext(DocumentLabelerDispatchContext);
    if (context === null) {
        throw new Error('Document Labeler useDispatch must be used within a Provider');
    }
    return context;
};
export var useDocumentLabeler = function () {
    return { state: useState(), dispatch: useDispatch() };
};
