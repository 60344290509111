import React, { useCallback, useEffect, useState } from 'react'
import { Loading } from '../shared'
import { IonItem, IonLabel, IonToggle } from '@ionic/react'
import humps from 'humps'
import { apiClient } from '~/api/ApiClient'

const LINE_ITEMS = [
  { label: 'Quantity', value: 'qty', enum: 0 },
  { label: 'Ext. Total', value: 'netTons', enum: 1 },
  { label: 'Date', value: 'date', enum: 2 },
  { label: 'Num', value: 'num', enum: 3 },
  { label: 'Buyer Code', value: 'buyerCode', enum: 4 },
  { label: 'Buyer Name', value: 'buyerName', enum: 5 },
  { label: 'Seller Code', value: 'sellerCode', enum: 6 },
  { label: 'Seller Name', value: 'sellerName', enum: 7 },
  { label: 'Fleet Code', value: 'fleetCode', enum: 8 },
  { label: 'Fleet Name', value: 'fleetName', enum: 9 },
  { label: 'Product Code', value: 'productCode', enum: 10 },
  { label: 'Product Name', value: 'productName', enum: 11 },
  {
    label: 'Seller Terminal Code',
    value: 'sellerTerminalCode',
    enum: 12,
  },
  {
    label: 'Seller Terminal Name',
    value: 'sellerTerminalName',
    enum: 13,
  },
  {
    label: 'Buyer Terminal Code',
    value: 'buyerTerminalCode',
    enum: 14,
  },
  {
    label: 'Buyer Terminal Name',
    value: 'buyerTerminalName',
    enum: 15,
  },
  { label: 'PO Num', value: 'poNum', enum: 16 },
]

const EditLineItemCarryDowns = ({ companyId }) => {
  const [loading, setLoading] = useState(false)
  const [lineItemCarryDowns, setLineItemCarryDowns] = useState([])

  useEffect(() => {
    const fetchValidations = async () => {
      setLoading(true)
      const response = await apiClient.companies.getById(companyId)
      if (response.billLineCarryDownFields) {
        const fieldNames = response.company.billLineCarryDownFields.map(
          e => LINE_ITEMS.find(li => li.enum == e).value,
        )
        setLineItemCarryDowns(fieldNames)
      } else {
        setLineItemCarryDowns([])
      }

      setLoading(false)
    }

    fetchValidations().catch(error => console.log(error))
  }, [companyId])

  const onChangeCarryDown = useCallback(
    async ({ target: { value } }) => {
      if (loading) return
      if (getCarryDown(value)) return

      setLoading(true)
      let reqList = lineItemCarryDowns
      const index = lineItemCarryDowns.indexOf(value)

      if (index > -1) {
        reqList.splice(index, 1)
      } else {
        reqList.push(value)
      }

      const req = {
        billLineCarryDownFields: reqList.map(f => humps.decamelize(f)),
      }

      await apiClient.companies.update(companyId, {
        company: req
      })
      setLineItemCarryDowns(reqList)
      setLoading(false)
    },
    [loading, lineItemCarryDowns, getCarryDown, companyId],
  )

  const getCarryDown = useCallback(
    name => {
      return lineItemCarryDowns.find(li => li == name)
    },
    [lineItemCarryDowns],
  )

  return (
    <>
      {loading && <Loading />}
      {LINE_ITEMS.map(lineItem => (
        <IonItem key={`expectedLoadFields.${lineItem.value}`}>
          <IonLabel>{lineItem.label}</IonLabel>
          <IonToggle
            slot='end'
            onIonChange={onChangeCarryDown}
            checked={getCarryDown(lineItem.value)}
            value={lineItem.value}
          />
        </IonItem>
      ))}
    </>
  )
}

export default EditLineItemCarryDowns
