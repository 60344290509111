import { getInvoiceURL } from './documentUtils'
import { FLAGABLE_TYPE } from '~/constants/invoice-type'
import { prepareQueryString } from './filterUtils'
import { INVOICES_NOT_SET_DATE_FIELDS } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'

export const combineInvoicesQuery = ({
  filters,
  searchWord,
  pageNumber = 1,
  perPage = 24,
  sortOptions,
  source,
}) => {
  const params = {
    page: pageNumber,
    per_page: perPage,
  }

  let query =
    '&' +
    Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')

  query += `&source=${source || 'Invoice'}`

  query += '&'
  query += prepareQueryString(
    { ...filters, searchWord },
    sortOptions,
    INVOICES_NOT_SET_DATE_FIELDS,
  )

  return query
}

export const onOpenNewWindow = (id, url) => async () => {
  const newUrl = await getInvoiceURL(id, url)
  window.open(newUrl, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
}

export const onOpenNewTab = (id, url) => async () => {
  const newUrl = await getInvoiceURL(id, url)
  window.open(newUrl, '_blank')
}

export const getUpdatedInvoiceAfterFlags = async billLinesResponse => {
  if (billLinesResponse?.billLines?.length > 0) {
    const billLines = billLinesResponse?.billLines || []
    const billLineIds = billLines.map(line => line.id)

    const flagsResponse = await apiClient.flags.get({
      filters: {
        flagableId: billLineIds,
        flagableType: FLAGABLE_TYPE.BillLine,
      },
    })

    if (flagsResponse?.flags && flagsResponse?.flags.length > 0) {
      const newBillLines = billLines.map(line => {
        const newFlags = flagsResponse?.flags.filter(
          flag => flag.flagableId === line.id,
        )

        return {
          ...line,
          flags: newFlags,
        }
      })

      return newBillLines
    }
  }

  return billLinesResponse?.billLines
}
