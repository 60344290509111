import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryShifts = () => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['shifts', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.shifts.get()
      return response.shifts
    },
    ...DEFAULT_QUERY_OPTIONS,
  })

  const shiftsData = useMemo(() => data || [], [data])

  return {
    shiftsData,
    isLoadingShifts: isLoading,
  }
}

export default useQueryShifts
