import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'
import preProcessingTestUiSlice from '~/redux/reducers/ui/preProcessingTestUiSlice'

export const fetchPreProcessingTestData = createAsyncThunk(
  'fetchPreProcessingTestData',
  async (payload, { dispatch, getState }) => {
    dispatch(preProcessingTestUiSlice.actions.setTableLoading(true))
    try {
      const companyId = getState().session?.user?.company?.id
      const { filters, searchWord } = getState().ui.preProcessingTests
      const { perPage, ...splitFilters } = filters
      splitFilters.searchWord = searchWord
      const { preprocessingTests } = await apiClient.preprocessingTests.get({
        filters: splitFilters,
        perPage: filters.perPage,
        buyerId: companyId,
        page: 1
      })
      const mapPreprocessingTests = preprocessingTests.map(
        ({ configurations, ...data }) => ({
          ...data,
          configurations: JSON.parse(configurations),
        }),
      )
      dispatch(preProcessingTestSlice.actions.index(mapPreprocessingTests))
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(preProcessingTestUiSlice.actions.setTableLoading(false))
    }
  },
)

export const preProcessingTestSlice = createSlice({
  name: 'preProcessingTests',
  initialState: {},
  reducers: {
    index: (state, action) => {
      const newState = {}
      action.payload.forEach(document => {
        newState[document.id] = document
      })

      return newState
    },
  },
})

export default preProcessingTestSlice
