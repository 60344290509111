/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, InputGroup } from 'react-bootstrap'
import { IFiltersFieldProps } from './type'
import React, { useCallback, useEffect, useState } from 'react'
import { produce } from 'immer'
import { IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import './styles.scss'
import { STATIC_VARIABLE_OPTIONS } from '~/utils/constants'

function FiltersField(props: IFiltersFieldProps) {
  const { field, filters, onChange } = props

  const [fieldValue, setFieldValue] = useState(field)
  const [filterValue, setFilterValue] = useState(filters[field])

  const value = filters[field]

  const PROVIDE_VALUE = ''

  const staticVariableValues = STATIC_VARIABLE_OPTIONS.map(({ value }) => value)

  const VALUE_OPTIONS = [
    {
      label: '**Provide value',
      value: PROVIDE_VALUE,
    },
    ...STATIC_VARIABLE_OPTIONS,
  ]

  const renderValueOpts = VALUE_OPTIONS.map(opt => (
    <option
      selected={filterValue === opt.value}
      key={opt.value}
      value={opt.value}
    >
      {opt.label}
    </option>
  ))

  const onRemoveField = useCallback(() => {
    const newFilters = produce(filters, draft => {
      if (draft) {
        delete draft[field as any]
      }
    })

    onChange(newFilters)
  }, [field, filters, onChange])

  const onChangeFieldValue = (value: string) => {
    setFieldValue(value)
  }

  const onBlurFieldValue = () => {
    const newFilters = produce(filters, draft => {
      draft[fieldValue] = draft[field]
      delete draft[field]
    })
    onChange(newFilters)
  }

  const onSelectValue = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target
      const newFilters = produce(filters, draft => {
        draft[fieldValue] = value
      })
      onChange(newFilters)
    },
    [fieldValue, filters, onChange],
  )

  useEffect(() => {
    setFieldValue(field)
  }, [field])

  useEffect(() => {
    setFilterValue(value || '')
  }, [value])

  return (
    <div>
      <InputGroup className='mb-1 FiltersField__inputGroup'>
        <Form.Control
          placeholder='Ex: driver_id'
          onChange={event => {
            const { value } = event.target
            onChangeFieldValue(value)
          }}
          onBlur={onBlurFieldValue}
          value={fieldValue}
        />
        <Form.Select
          aria-label='Default select example'
          onChange={onSelectValue}
          style={{ fontSize: 13 }}
        >
          {renderValueOpts}
        </Form.Select>
        {!staticVariableValues.includes(filterValue) && (
          <Form.Control
            onChange={event => {
              const { value } = event.target
              setFilterValue(value)
            }}
            onBlur={() => {
              const newFilters = produce(filters, draft => {
                draft[fieldValue] = filterValue
              })
              onChange(newFilters)
            }}
          />
        )}
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}
          className='icon'
          onClick={onRemoveField}
        >
          <IonIcon icon={closeCircle} color='danger' />
        </div>
      </InputGroup>
    </div>
  )
}

export default React.memo(FiltersField)
