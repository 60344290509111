import { combineReducers } from 'redux'
import { driverFleetsSlice } from './driverFleets.js'
import { driversSlice } from './drivers.js'
import { peopleSlice } from './people.js'
import { truckFleetsSlice } from './truckFleets.js'
import { trucksSlice } from './trucks.js'
import addressesSlice from './addresses'
import billLinesSlice from './billLines.js'
import buyerSellerProductsSlice from './buyerSellerProducts'
import buyerSellerSlice from './buyerSellers'
import checkPayrollItemsSlice from './checkPayrollItems'
import companiesSlice from './companies'
import companyBankAccountsSlice from './companyBankAccounts'
import companyEmailsSlice from './companyEmails'
import companyPayrollsSlice from './companyPayrolls'
import companyPhoneNumbersSlice from './companyPhoneNumbers'
import fieldValidationsSlice from './fieldValidations.js'
import fixedPricesSlice from './fixedPrices.js'
import flagsSlice from './flags.js'
import invoicesSlice from './invoices.js'
import lastFetchedAtSlice from './lastFetchedAt.js'
import loadsSlice from './loadsSlice.js'
import ordersSlice from './orders.js'
import payrollItemSlice from './payrollItem'
import preProcessingTestSlice from './preProcessingTest'
import productGroupsSlice from './productGroups.js'
import productsSlice from './products.js'
import salesProposalsSlice from './salesProposals.js'
import sellerProductsSlice from './sellerProducts.js'
import tagsSlice from './tags.js'
import terminalsSlice from './terminals.js'
import userTableConfigurationsSlice from './userTableConfigurationsSlice'
import { locationSlice } from './locations'
import { orderExtrasSlice } from './orderExtras.js'
import { orderExtraRangesSlice } from './orderExtraRanges.js'
import { schedulesSlice } from './schedules.js'
import { workersSlice } from './workers.js'
import { usersSlice } from './users.js'
import { userAccessesSlice } from './userAccesses.js'

export default combineReducers({
  addresses: addressesSlice.reducer,
  billLines: billLinesSlice.reducer,
  buyerSellerProducts: buyerSellerProductsSlice.reducer,
  buyerSellers: buyerSellerSlice.reducer,
  checkPayrollItems: checkPayrollItemsSlice.reducer,
  companies: companiesSlice.reducer,
  companyBankAccounts: companyBankAccountsSlice.reducer,
  companyEmails: companyEmailsSlice.reducer,
  companyPayrolls: companyPayrollsSlice.reducer,
  companyPhoneNumbers: companyPhoneNumbersSlice.reducer,
  driverFleets: driverFleetsSlice.reducer,
  drivers: driversSlice.reducer,
  fieldValidations: fieldValidationsSlice.reducer,
  fixedPrices: fixedPricesSlice.reducer,
  flags: flagsSlice.reducer,
  invoices: invoicesSlice.reducer,
  lastFetchedAt: lastFetchedAtSlice.reducer,
  loads: loadsSlice.reducer,
  locations: locationSlice.reducer,
  orders: ordersSlice.reducer,
  orderExtras: orderExtrasSlice.reducer,
  orderExtraRanges: orderExtraRangesSlice.reducer,
  people: peopleSlice.reducer,
  payrollStatus: payrollItemSlice.reducer,
  preProcessingTests: preProcessingTestSlice.reducer,
  productGroups: productGroupsSlice.reducer,
  products: productsSlice.reducer,
  salesProposals: salesProposalsSlice.reducer,
  schedules: schedulesSlice.reducer,
  sellerProducts: sellerProductsSlice.reducer,
  tags: tagsSlice.reducer,
  terminals: terminalsSlice.reducer,
  truckFleets: truckFleetsSlice.reducer,
  trucks: trucksSlice.reducer,
  userAccesses: userAccessesSlice.reducer,
  users: usersSlice.reducer,
  userTableConfigurations: userTableConfigurationsSlice.reducer,
  workers: workersSlice.reducer,
})
