const DEFAULT_COMPANY_AUTOCHARGE_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Buyer',
    field: 'buyer.name',
    show: true,
    value: 'buyer.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'buyer.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Application Level',
    field: 'applicationLevel',
    show: true,
    value: 'applicationLevel',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'applicationLevel',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Allocated Per',
    field: 'allocatedPer',
    show: true,
    value: 'allocatedPer',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'allocatedPer',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    label: 'Product',
    field: 'buyerSellerProduct.name',
    show: true,
    value: 'buyerSellerProduct.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 5,
    isAsc: true,
    sortField: 'buyerSellerProduct.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_AUTOCHARGE_COLUMNS
