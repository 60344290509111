import _ from 'lodash'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { createSelector } from 'reselect'

export const selectProductsDataState = state => state.data.products
export const selectProductsData = createSelector(
  selectProductsDataState,
  state => {
    const data = []
    _.forEach(state, value => {
      data.push(value)
    })

    return data
  },
)

export const selectProductsUiState = state => state.ui.products
export const selectProductTableLoading = createSelector(
  selectProductsUiState,
  state => state.tableLoading,
)
export const selectProductTableLoadingRowModel = createSelector(
  selectProductsUiState,
  state => state.tableLoadingRowModel,
)
export const selectProductFormLoading = createSelector(
  selectProductsUiState,
  state => state.formLoading,
)

export const selectCommonProduct = state =>
  columnHashToColumnsArray(state.data.products)

export const selectSortedCommonProducts = createSelector(
  selectCommonProduct,
  products => _.orderBy(products, 'name'),
)

export const selectCommonProductOptions = createSelector(
  selectCommonProduct,
  products => {
    return products
      .map(c => ({
        value: c.id,
        label: c.name,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label))
  },
)
