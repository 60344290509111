import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IonSpinner } from '@ionic/react'

import { deepCopy } from '~/utils/utils'
import { updateLineItemTagLinks } from '~/redux/actions/tagLinkActions'
import { selectCommonTags } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { apiClient } from '~/api/ApiClient'

import './TagLinkModal.scss'

const TagLinkModal = props => {
  const {
    lineItem,
    taggableType,
    isOpen,
    // Load line items are in redux, billLines has local useState passed down
    setNewLineItem = null,
    ...modalProps
  } = props

  const dispatch = useDispatch()
  const tags = useSelector(selectCommonTags)
  const [lineItemTagLinks, setLineItemTagLinks] = useState([])
  const [checkedTagOptions, setCheckedTagOptions] = useState([])
  const [loading, setLoading] = useState(false)

  // Set up list of actual tags for the company
  useEffect(() => {
    if (tags) {
      const checkedTags = []
      tags.map(tag => {
        tag.models.includes(taggableType) &&
          checkedTags.push({
            value: tag.id,
            checked: false,
            color: tag.color,
            name: tag.name,
            code: tag.code,
          })
      })

      setCheckedTagOptions(checkedTags)
    }
  }, [tags, taggableType])

  // Fetch the line item's tag links
  useEffect(() => {
    if (isOpen === true || isOpen === undefined) {
      fetchTagLinks()
    }
  }, [fetchTagLinks, isOpen])

  // Check each actual tag against tag links tagId
  useEffect(() => {
    if (lineItemTagLinks.length > 0) {
      mapTagLinksToTagsList(lineItemTagLinks)
    }
  }, [lineItemTagLinks, mapTagLinksToTagsList])

  const fetchTagLinks = useCallback(() => {
    setLoading(true)
    apiClient.tagLinks.get({
      filters: {
        taggableId: lineItem.id
      }
    })
      .then(links => {
        setLineItemTagLinks(links)
      })
      .catch(err => {
        console.log('err', err)
        toast.error(toastMessages.serverError)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [lineItem])

  const mapTagLinksToTagsList = useCallback(
    tagLinks => {
      const optionsCopy = deepCopy(checkedTagOptions)
      optionsCopy.map(option => {
        tagLinks.map(link => {
          if (option.value == link.tagId) {
            option.checked = true
          }
        })
      })

      setCheckedTagOptions(optionsCopy)
    },
    [checkedTagOptions],
  )

  const handleOptionsChange = useCallback(
    e => {
      if (loading) {
        return
      }

      const { checked, value } = e.target

      const optionsCopy = deepCopy(checkedTagOptions)
      const index = optionsCopy.findIndex(row => row.value == value)
      optionsCopy[index].checked = !optionsCopy[index].checked

      setCheckedTagOptions(optionsCopy)

      if (checked) {
        createNewTagLink(value)
      } else {
        const link = lineItemTagLinks.find(link => link.tagId == value)
        if (link) {
          deleteTagLink(link.id)
        } else {
          toast.error('Unable to modify tags currently.')
        }
      }
    },
    [
      loading,
      createNewTagLink,
      lineItemTagLinks,
      checkedTagOptions,
      deleteTagLink,
    ],
  )

  const createNewTagLink = useCallback(
    id => {
      const payload = {
        tagId: parseInt(id),
        taggableType: taggableType,
        taggableId: lineItem.id,
      }

      setLoading(true)
      apiClient.tagLinks.create(payload)
        .then(response => {
          const tagId = response.tagId
          if (_.isNumber(tagId) || _.isString(tagId)) {
            const newTagIds = deepCopy(lineItem.tagIds)
            newTagIds.push(tagId)

            if (setNewLineItem) {
              setNewLineItem({ id: lineItem.id, tagIds: newTagIds })
            } else {
              dispatch(
                updateLineItemTagLinks(
                  { id: lineItem.id, tagIds: newTagIds },
                  taggableType,
                ),
              )
            }

            lineItemTagLinks.push(response)
            setLineItemTagLinks(lineItemTagLinks)
          } else {
            if (_.isArray(tagId) && _.size(tagId) > 0) {
              toast.error(tagId[0])
            } else {
              toast.error(toastMessages.createError)
            }
          }
        })
        .catch(err => {
          console.log(err)
          toast.error(toastMessages.createError)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [lineItem, lineItemTagLinks, taggableType, setNewLineItem, dispatch],
  )

  const deleteTagLink = useCallback(
    async id => {
      setLoading(true)
      apiClient.tagLinks.delete(id)
        .then(response => {
          const newTagIds = deepCopy(lineItem.tagIds)
          newTagIds.splice(newTagIds.indexOf(response.tagId), 1)

          if (setNewLineItem) {
            setNewLineItem({ id: lineItem.id, tagIds: newTagIds })
          } else {
            dispatch(
              updateLineItemTagLinks(
                { id: lineItem.id, tagIds: newTagIds },
                taggableType,
              ),
            )
          }

          const index = lineItemTagLinks
            .map(line => line.id)
            .indexOf(response.id)
          lineItemTagLinks.splice(index, 1)
          setLineItemTagLinks(lineItemTagLinks)
        })
        .catch(err => {
          console.log('err', err)
          toast.error(toastMessages.deleteError)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [lineItem, lineItemTagLinks, taggableType, setNewLineItem, dispatch],
  )

  return (
    <>
      <div className='TagLinkModal__container' {...modalProps}>
        {checkedTagOptions &&
          checkedTagOptions.map((tag, index) => (
            <div
              key={index}
              style={{ border: `2px solid ${tag.color}`, marginBottom: 1 }}
              className='select-search-modal-item body-grid'
            >
              <label
                style={{ color: tag.color }}
                htmlFor={`${tag.id}${index}`}
                className='order-modal-item-label'
              >
                {tag.name}
              </label>
              {loading ? (
                <IonSpinner name='dots' className='mt-2' />
              ) : (
                <input
                  style={{ color: tag.color }}
                  id={`${tag.id}${index}`}
                  className='order-modal-item-input'
                  type='checkbox'
                  onChange={handleOptionsChange}
                  value={tag.value}
                  checked={tag.checked}
                  disabled={loading}
                />
              )}
            </div>
          ))}
      </div>
    </>
  )
}

TagLinkModal.propTypes = {
  lineItem: PropTypes.object,
  taggableType: PropTypes.oneOf(['Load', 'Invoice', 'BillLine']),
  isOpen: PropTypes.bool,
}

TagLinkModal.defaultProps = {
  lineItem: {},
}

export default TagLinkModal
