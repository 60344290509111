import { components } from 'react-select'
import { Button } from 'react-bootstrap'

export const CreateButton = onClick => props => {
  const {
    selectProps: { inputValue },
  } = props

  const onClickCreate = e => {
    e.stopPropagation()
    onClick && onClick(inputValue)
  }
  return (
    <components.MenuList {...props}>
      {props.children}
      <div className='p-1' style={{ marginLeft: 4 }}>
        <Button onClick={onClickCreate} className='w-100'>
          Create
        </Button>
      </div>
    </components.MenuList>
  )
}
