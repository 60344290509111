import type { ISvgIconProps } from './type'

const ClockOutIcon = (props: ISvgIconProps) => {
  const { size = 16, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M12 4a8 8 0 1 0 0 16 1 1 0 1 1 0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8Zm11.614 11.21a1 1 0 0 1 .175 1.404l-4.666 6a1 1 0 0 1-1.484.106l-2.333-2.25a1 1 0 1 1 1.388-1.44l1.533 1.478 3.984-5.122a1 1 0 0 1 1.403-.175ZM13 6a1 1 0 1 0-2 0v6a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V6Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default ClockOutIcon
