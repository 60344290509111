export enum EBuyerSellerRelationship {
  Seller = 'Seller',
  Buyer = 'Buyer',
  Fleet = 'Fleet',
}

export enum EBuyerSellerCompanyStatus {
  goodStanding = 'Good Standing',
  applied = 'Applied',
}
