import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import { formatCurrencyToDollar } from '~/utils/formatCurrency'

import { useQueryWorkers } from '~/hooks/useQueryData'
import buildFullName from '~/utils/buildFullName'

export interface IPayrollJournalTableProps {
  data: any
}

function PayrollJournalTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const { findWorkerByCheckUid } = useQueryWorkers()

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Employee',
        accessorKey: 'employee',
        minSize: 130,
        maxSize: 130,
        Cell({ cell }) {
          const cellValue = cell.getValue()
          const worker = findWorkerByCheckUid(cellValue)
          if (worker) {
            return buildFullName(worker.person)
          }
          return '-'
        },
      },
      {
        header: 'Gross Total',
        accessorKey: 'grossTotal',
        align: 'right',
        minSize: 110,
        maxSize: 110,
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(original.totals.employeeGross)
        },
      },
      {
        header: 'Net Pay',
        accessorKey: 'netPay',
        align: 'right',
        minSize: 110,
        maxSize: 110,
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(original.totals.employeeNet)
        },
      },
      {
        header: 'Reimbursements',
        accessorKey: 'reimbursements',
        align: 'right',
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(
            original.totals.employeeReimbursements,
          )
        },
      },
      {
        header: 'Hourly (Regular)',
        accessorKey: 'totalHourly',
        align: 'right',
        Cell({ row: { original } }) {
          const total = original.earningTotals.reduce(
            (sum: number, { amount }: any) => sum + Number(amount),
            0,
          )
          return formatCurrencyToDollar.format(total)
        },
      },
      {
        header: 'Hourly (Regular)',
        accessorKey: 'totalHours',
        align: 'right',
        Cell({ row: { original } }) {
          const total = original.earningTotals.reduce(
            (sum: number, { hours }: any) => sum + hours,
            0,
          )
          return total
        },
      },
      {
        header: 'FICA (EE)',
        accessorKey: 'fica',
        align: 'right',
        minSize: 130,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'FICA',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Federal Income Tax (EE)',
        accessorKey: 'federalIncomeTax',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'Federal Income Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Medicare (EE)',
        accessorKey: 'medicare',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'Medicare',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Additional Medicare (EE)',
        accessorKey: 'additionalMedicare',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'Additional Medicare',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York Paid Family Leave Insurance (EE)',
        accessorKey: 'NewYorkPaidFamilyLeaveInsurance',
        align: 'right',
        minSize: 280,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) =>
              description === 'New York Paid Family Leave Insurance',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York SDI (EE)',
        accessorKey: 'NewYorkSDI',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'New York SDI',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York State Tax (EE)',
        accessorKey: 'NewYorkStateTax',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'New York State Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York City Tax (EE)',
        accessorKey: 'NewYorkCityTax',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.employeeTaxes.find(
            ({ description }: any) => description === 'New York City Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Employer FICA Tax (ER)',
        accessorKey: 'EmployerFICATax',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) => description === 'Employer FICA Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Federal Unemployment Tax (ER)',
        accessorKey: 'FederalUnemploymentTax',
        align: 'right',
        minSize: 220,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) =>
              description === 'Federal Unemployment Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'Employer Medicare Tax (ER)',
        accessorKey: 'EmployerMedicareTax',
        align: 'right',
        minSize: 180,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) => description === 'Employer Medicare Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York MCTMT Employer Payroll Tax (ER)',
        accessorKey: 'NewYorkMCTMTEmployerPayrollTax',
        align: 'right',
        minSize: 270,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) =>
              description === 'New York MCTMT Employer Payroll Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York State Unemployment Tax (ER)',
        accessorKey: 'NewYorkStateUnemploymentTax',
        align: 'right',
        minSize: 270,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) =>
              description === 'New York State Unemployment Tax',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
      {
        header: 'New York Reemployment Fund (ER)',
        accessorKey: 'NewYorkReemploymentFund',
        align: 'right',
        minSize: 270,
        Cell({ row: { original } }) {
          const obj = original.companyTaxes.find(
            ({ description }: any) =>
              description === 'New York Reemployment Fund',
          )
          if (obj) {
            return obj.amount
          }
          return '-'
        },
      },
    ],
    [findWorkerByCheckUid],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default PayrollJournalTable
