import { useCallback, memo, useState } from 'react'

import { ContainerSearchBar } from '~/components/shared'
import RenderFilters from '../RenderFilters'

import _ from 'lodash'
import clsx from 'clsx'

const VerticalCommonFilter = props => {
  const {
    filterData,
    filterOptions,
    onChangeFilters,
    className,
    pluralModelName,
  } = props

  const [searchValue, setSearchValue] = useState('')

  const handleSearch = useCallback(newSearchValue => {
    setSearchValue(newSearchValue)
  }, [])

  const renderFilterOptions = useCallback(() => {
    const newFilters = filterOptions.filter(({ label }) => {
      const lowerLabel = _.toLower(label)
      const lowerSearch = _.toLower(searchValue)

      return lowerLabel.includes(lowerSearch)
    })

    return (
      <RenderFilters
        filterData={filterData}
        filterOptions={newFilters}
        onChange={onChangeFilters}
        pluralModelName={pluralModelName}
      />
    )
  }, [filterData, filterOptions, onChangeFilters, pluralModelName, searchValue])

  return (
    <div className={clsx('VerticalCommonFilter__container', className)}>
      <ContainerSearchBar
        className='FilterModalPopup__searchBar'
        onSearchBarChange={handleSearch}
        searchBarValue={searchValue}
      />
      {renderFilterOptions()}
    </div>
  )
}

export default memo(VerticalCommonFilter)
