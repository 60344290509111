import humps from 'humps'

const getErrorFromCheckResponse = response => {
  try {
    const parsedResponse = humps.camelizeKeys(JSON.parse(response.body))
    if (parsedResponse.error) {
      const { message, inputErrors } = parsedResponse.error
      if (inputErrors?.length) {
        const { field, message } = inputErrors[0]
        return `${field} - ${message}`
      }
      return message
    }
    return ''
  } catch (error) {
    console.log('error', error)
    return ''
  }
}

export const parseErrorFromCheckResponse = response => {
  if (response.error) {
    const { message, inputErrors } = response.error
    if (inputErrors?.length) {
      const { field, message } = inputErrors[0]
      return `${field} - ${message}`
    }
    return message
  }
  return ''
}

export default getErrorFromCheckResponse
