import useTruckNumberSection from './useTruckNumberSection'

import clsx from 'clsx'

import type { ITruckNumberSectionProps } from './type'

import './styles.scss'
import Tooltip from 'rc-tooltip'
import { Popover } from 'react-bootstrap'

function TruckNumberSection(props: ITruckNumberSectionProps) {
  const {
    className,
    truckId,
    tooltipProps,
    renderTruckName = () => truck?.name,
    tooltipPlacement,
    ...spanProps
  } = props

  const { onRightClick, truck, truckTooltipContent } =
    useTruckNumberSection(props)

  return (
    <Tooltip
      placement={tooltipPlacement || 'top'}
      overlay={
        <Popover className='TruckNumberSection__tooltipOverlay'>
          <Popover.Body>{truckTooltipContent}</Popover.Body>
        </Popover>
      }
      destroyTooltipOnHide
      showArrow={false}
      overlayClassName='TruckNumberSection__overlay'
    >
      <span
        className={clsx('TruckNumberSection__container', className)}
        onContextMenu={onRightClick}
        {...spanProps}
      >
        {renderTruckName(truck)}
      </span>
    </Tooltip>
  )
}

export default TruckNumberSection
