const parseLoadSize = loadSize => {
  return Math.round((parseFloat(loadSize) + Number.EPSILON) * 1000) / 1000
}

const defaultAvatarImg =
  'https://workloadnode.s3.amazonaws.com/Logos/favicon.ico'

const avatarStyles = {
  seller: {
    border: '2px solid limegreen',
  },
  buyer: {
    border: '2px solid blue',
  },
  name: {
    seller: {
      display: 'inline-block',
      width: '35px',
      height: '35px',
      borderRadius: '30px',
      background: 'rgb(129, 239, 125)',
      textAlign: 'center',
      color: 'white',
      paddingTop: '4px',
    },
    buyer: {
      display: 'inline-block',
      width: '35px',
      height: '35px',
      borderRadius: '30px',
      background: 'rgb(21, 114, 242)',
      textAlign: 'center',
      color: 'white',
      paddingTop: '4px',
    },
  },
}

export { parseLoadSize, defaultAvatarImg, avatarStyles }
