import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import addessesUiSlice from '~/redux/reducers/ui/addresses'

export const name = 'addresses'

export const fetchAddressesData = createAsyncThunk(
  'addresses/fetchAddressesData',
  async (payload, { dispatch }) => {
    dispatch(addessesUiSlice.actions.setLoadingTable(true))
    try {
      const { addressableId, addressableType } = payload

      const { addresses } = await apiClient.addresses.get({
        filters: {
          addressableId,
          addressableType,
        },
      })
      dispatch(addressesDataSlice.actions.index(addresses))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(addessesUiSlice.actions.setLoadingTable(false))
    }
  },
)

const addressesDataSlice = createSlice({
  name,
  initialState: {},
  reducers: {
    index(_state, action) {
      const newState = {}
      action.payload.forEach(address => {
        newState[address.id] = address
      })

      return newState
    },
    update(state, { payload }) {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    add(state, { payload }) {
      state[payload.id] = payload
    },
    remove(state, { payload }) {
      delete state[payload.id]
      toast.success(toastMessages.deleteSuccess)
    },
  },
})

export const fetchAddresses = createAsyncThunk(
  'addresses/fetchAddresses',
  async (params = {}, { dispatch }) => {
    try {
      const { addresses } = await apiClient.addresses.get(params)
      dispatch(addressesDataSlice.actions.index(addresses))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    }
  },
)

export default addressesDataSlice
