var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useEffect, useRef } from 'react';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { TransformWrapper, TransformComponent, } from 'react-zoom-pan-pinch';
import { EndUserBlockRenderUtils } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/EndUserBlockRenderUtils';
var DocumentImageDisplayer = function (props) {
    var document = props.document, width = props.width;
    var _a = useDocumentLabeler(), state = _a.state, dispatch = _a.dispatch;
    var localState = state.localState, docInfo = state.docInfo;
    var renderedImgHeight = localState.renderedImgHeight, fieldViewing = localState.fieldViewing, activeField = localState.activeField;
    var transformComponentRef = useRef(null);
    var imageProps = useMemo(function () {
        if (typeof state.localState.renderedImgHeight === 'number') {
            return {
                height: renderedImgHeight,
            };
        }
        return {};
    }, [state]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onImgLoad = function (event) {
        dispatch({
            type: 'setImageHeight',
            payload: event.target.offsetHeight,
        });
    };
    useEffect(function () {
        var _a, _b, _c;
        if (fieldViewing) {
            var id_1 = fieldViewing.id;
            var results = docInfo.results;
            var findSelectedField = results.fields.find(function (field) { return field.id === id_1; });
            var firstBlock = ((findSelectedField === null || findSelectedField === void 0 ? void 0 : findSelectedField.blocks) || [])[0];
            if (firstBlock) {
                var boundingBox = firstBlock.boundingBox;
                var coords = EndUserBlockRenderUtils.getBlockRenderCoords(boundingBox, [imageProps.height || 0], width);
                var scale = 2.5;
                var xPosition = coords.left * scale;
                var yPosition = coords.top * scale;
                (_a = transformComponentRef.current) === null || _a === void 0 ? void 0 : _a.setTransform(-(xPosition - 250), -(yPosition - 250), scale);
            }
            else {
                (_b = transformComponentRef.current) === null || _b === void 0 ? void 0 : _b.setTransform(0, 0, 1);
            }
        }
        else {
            (_c = transformComponentRef.current) === null || _c === void 0 ? void 0 : _c.setTransform(0, 0, 1);
        }
    }, [fieldViewing, docInfo, activeField]);
    return (_jsx(TransformWrapper, __assign({ ref: transformComponentRef }, { children: _jsx(TransformComponent, { children: _jsx("img", __assign({}, imageProps, { src: document, width: width, alt: "DocumentImageDisplayer", id: "DocumentImageDisplayer", onLoad: onImgLoad })) }) })));
};
export { DocumentImageDisplayer };
