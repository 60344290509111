import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IOrderExtra, IGetOrderExtrasParams } from '~/types/models/IOrderExtra'
import useModifyOrderExtras from './useModifyOrderExtras'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryOrderExtras = (
  params: IGetOrderExtrasParams = {},
  options?: Partial<UseQueryOptions<IOrderExtra[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'orderExtras',
      sessionUser?.id,
      buildGetUrl(apiClient.orderExtras.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.orderExtras.get(params)
      return response.orderExtras
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addOrderExtra, updateOrderExtra, removeOrderExtra } =
    useModifyOrderExtras(params)

  const orderExtras = useMemo(() => data || [], [data])

  const orderExtrasObj = useMemo(
    () =>
      Object.fromEntries(
        orderExtras.map(orderExtra => [orderExtra.id, orderExtra]),
      ),
    [orderExtras],
  )

  return {
    orderExtras,
    orderExtrasObj,
    isLoadingOrderExtras: isLoading,
    addOrderExtra,
    updateOrderExtra,
    removeOrderExtra,
  }
}

export default useQueryOrderExtras
