import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import ProductGlCodeForm from './ProductGlCodeForm'

import type { IDialogProductGlCodeProps } from './type'

function DialogProductGlCodeForm(props: IDialogProductGlCodeProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Product GL Code'
      isHiddenOkButton
      onClose={onClose}
    >
      <ProductGlCodeForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogProductGlCodeForm
