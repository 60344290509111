import React, { useState, useEffect, useMemo } from 'react'
import { Chart } from 'react-google-charts'

import _ from 'lodash'
import { convertData } from './helpers'
import PropTypes from 'prop-types'

const ChartBox = ({ orders, options }) => {
  const [chart, setChart] = useState()

  useEffect(() => {
    setChart(convertData(orders))
  }, [orders])

  const DEFAULT_OPTIONS = useMemo(
    () => ({
      chartArea: { width: '50%' },
      hAxis: {
        title: 'Tons/LDs qty',
        minValue: 0,
      },
    }),
    [],
  )

  const mergeOptions = _.merge(DEFAULT_OPTIONS, options)

  return (
    <Chart
      chartType='BarChart'
      width='100%'
      height='400px'
      data={chart}
      options={mergeOptions}
    />
  )
}

ChartBox.propTypes = {
  options: PropTypes.object,
  orders: PropTypes.shape({
    terminals: PropTypes.arrayOf(PropTypes.string),
    products: PropTypes.arrayOf(PropTypes.string),
    amounts: PropTypes.object,
  }),
}

export default ChartBox
