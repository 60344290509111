import { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useFilterOptions from '~/hooks/useFilterOptions'
import useStoreFilters from '~/hooks/useStoreFilters'
import { useDeepCompareEffect } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import { CompanyTable, DialogBtfstpForm, Loading } from '~/components/shared'
import CompanyBTFSTPList from '~/components/company/CompanyBTFSTPList'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import {
  selectCommonSellerOptions,
  selectCommonBuyerTerminalOptions,
} from '~/redux/selectors'
import getSearchedData from '~/utils/getSearchedData'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import filterWhere from 'filter-where'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

function CompanyBTFSTPSubTab() {
  const [companyBTFSTPData, setCompanyBTFSTPData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [filterData, setFilterData] = useState(
    defaultModuleFilters[moduleName.company.btfstps],
  )
  const [currentTab, setCurrentTab] = useState(null)
  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })

  const { confirmation } = useConfirmationProvider()

  const { filterOptions } = useFilterOptions(moduleName.company.btfstps)
  const [storedFilters, setStoredFilters] = useStoreFilters(
    moduleName.company.btfstps,
  )

  const commonSellerOptions = useSelector(selectCommonSellerOptions)
  const commonBuyerTerminalOptions = useSelector(
    selectCommonBuyerTerminalOptions,
  )

  const mapCompanyBTFSTPData = useMemo(() => {
    const mapData = companyBTFSTPData.map(item => {
      const { sellerTerminal } = item
      const seller = _.find(commonSellerOptions, {
        id: sellerTerminal?.companyId,
      })

      return {
        ...item,
        seller,
        sellerId: seller?.id,
      }
    })

    let filteredData = mapData

    if (filterData.sellerId.length > 0) {
      filteredData = mapData.filter(filterWhere(filterData))
    }

    return getSearchedData(filteredData, searchKey, [
      'billingMethod',
      'buyerSellerProduct.name',
      'buyerTerminal.code',
      'buyerTerminal.name',
      'buyerTerminalProductSchedule.startDate',
      'sellerProduct.name',
      'sellerProduct.code',
      'sellerTerminal.name',
      'sellerTerminal.code',
      'seller.name',
      'seller.code',
    ])
  }, [commonSellerOptions, companyBTFSTPData, filterData, searchKey])

  const handleChangeTab = useCallback(({ value }) => {
    setCurrentTab(value)
  }, [])

  const handleSearchKeyChange = useCallback(newSearchKey => {
    setSearchKey(newSearchKey)
  }, [])

  const getCurrentTab = useMemo(
    () => currentTab || _.get(commonBuyerTerminalOptions, '[0].value'),
    [commonBuyerTerminalOptions, currentTab],
  )

  const getApiCall = useCallback(() => {
    return apiClient.btfstps.get({
      filters: {
        buyerTerminalId: [getCurrentTab],
      },
    })
  }, [getCurrentTab])

  const getTerminals = useCallback(async () => {
    if (!getCurrentTab) {
      return
    }
    setLoading(true)
    try {
      const response = await getApiCall()
      setCompanyBTFSTPData(response.buyerTerminalFromSellerTerminalProducts)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall, getCurrentTab])

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete buyer terminal #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.btfstps.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setCompanyBTFSTPData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const handleChangeFilters = useCallback(newFilters => {
    setFilterData(newFilters)
  }, [])

  const handleSaveDefaultFilters = useCallback(() => {
    setStoredFilters({ data: filterData })
  }, [filterData, setStoredFilters])

  const handleClearFilters = useCallback(newFilters => {
    setFilterData(newFilters)
  }, [])

  useEffect(() => {
    getTerminals()
  }, [getTerminals])

  useDeepCompareEffect(() => {
    setFilterData(storedFilters.data)
  }, [storedFilters.data])

  return (
    <>
      {loading && <Loading />}
      <CompanyTable
        description='Manage the individual terminal-to-terminal product purchases.'
        title='BTFSTPs'
        renderCountText={() => `${mapCompanyBTFSTPData.length} BTFSTPs`}
        tabs={commonBuyerTerminalOptions}
        currentTab={getCurrentTab}
        onChangeTab={handleChangeTab}
        buttonProps={{
          buttonText: 'Add BTFSTP',
          loading,
        }}
        // FormComponent={OnBoardingForm}
        formProps={{
          isOpen: formState.isOpen,
          onToggleForm: isOpen => {
            setFormState({
              isOpen,
              formData: undefined,
            })
          },
        }}
        renderTable={() => (
          <CompanyBTFSTPList
            companyBTFSTPs={mapCompanyBTFSTPData}
            onEditClick={handleOpenUpdateForm}
            deleteParser={handleDeleteRow}
            seller={commonSellerOptions}
          />
        )}
        filterProps={{
          searchKey,
          moduleName: moduleName.company.btfstps,
          filterData,
          filterOptions,
          onChangeFilters: handleChangeFilters,
          onSaveDefaultFilters: handleSaveDefaultFilters,
          onResetFilterToDefault: handleClearFilters,
          onClearFilters: handleClearFilters,
          onSearchKeyChange: handleSearchKeyChange,
        }}
      />
      <DialogBtfstpForm
        isOpen={formState.isOpen}
        formData={formState.formData}
        onClose={onCloseForm}
        afterCreate={newData => {
          setCompanyBTFSTPData(prev => [newData, ...prev])
          onCloseForm()
        }}
        afterUpdate={newData => {
          onCloseForm()
          setCompanyBTFSTPData(prev =>
            produce(prev, draft => {
              const index = _.findIndex(draft, { id: newData.id })
              if (index > -1) {
                draft[index] = {
                  ...draft[index],
                  ...newData,
                }
              }
            }),
          )
        }}
      />
    </>
  )
}

export default CompanyBTFSTPSubTab
