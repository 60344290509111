import React from 'react'

import { IonItem } from '@ionic/react'
import { CompanyInfo, DropdownPicker } from '~/components/shared'
import PropTypes from 'prop-types'

import './ImageLabelItem.scss'
import clsx from 'clsx'

const ImageLabelItem = props => {
  const {
    item,
    rightItem,
    hasMultipleScopes,
    dropdownField,
    onUpdateFilter,
    killStyling = false,
    companyId,
    currentScope,
  } = props

  const isActiveItem = item?.id === companyId

  return (
    <>
      <IonItem
        color={isActiveItem && !killStyling ? 'ice' : ''}
        style={isActiveItem ? { zIndex: '2' } : {}}
        lines={killStyling ? 'none' : 'full'}
      >
        <CompanyInfo
          companyType={currentScope}
          className={clsx('ImageLabelItem__companyAvatar', {
            activeItem: isActiveItem && hasMultipleScopes,
          })}
          showImagePopup
          company={{
            value: item?.id,
            name: item?.name,
            image: item?.imageUrl || item?.avatar,
            code: item?.code,
          }}
          searchableGoogle={false}
        />
        <div slot='end'>
          {isActiveItem && hasMultipleScopes && (
            <div
              className='access-scope-header'
              style={{ marginTop: 8, marginBottom: 4 }}
            >
              {dropdownField.map(({ field, filterKey, value, items }) => (
                <DropdownPicker
                  key={field}
                  field={field}
                  filterKey={filterKey}
                  value={value}
                  items={items}
                  onValueChange={onUpdateFilter}
                  hideOptions={items?.length === 1}
                />
              ))}
            </div>
          )}
          {rightItem && <div slot='end'>{rightItem}</div>}
        </div>
      </IonItem>
    </>
  )
}

ImageLabelItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  companyId: PropTypes.number,
  currentScope: PropTypes.oneOf(['fleet', 'seller', 'buyer']),
  hasMultipleScopes: PropTypes.bool,
  dropdownField: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      filterKey: PropTypes.string, // label
      value: PropTypes.number,
      item: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
  ),
  onUpdateFilter: PropTypes.func,
}

export default ImageLabelItem
