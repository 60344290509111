import { createSelector } from 'reselect'

const lastFetchedAtState = state => state.data.lastFetchedAt

export const selectBuyerSellersLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.buyerSellers || 0,
)

export const selectBuyerSellerProductsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.buyerSellerProducts || 0,
)

export const selectCompaniesLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.companies || 0,
)

export const selectProductsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.products || 0,
)

export const selectProductGroupsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.productGroups || 0,
)

export const selectSellerProductsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.sellerProducts || 0,
)

export const selectTagsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.tags || 0,
)

export const selectTerminalsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.terminals,
)

export const selectUserGroupsLastFetchedAt = createSelector(
  lastFetchedAtState,

  state => state.userGroups || 0,
)
