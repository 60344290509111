import { useFieldArray } from 'react-hook-form'
import { ExtrasFormRangesRow } from './ExtrasFormRangesRow'
import { useEffect } from 'react'

export const ExtrasFormRangesFieldArray = ({
  name,
  control,
  sellerProductId,
  hideRange,
  prefix,
  extrasIndex,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}orderExtrasAttributes.${extrasIndex}.orderExtraRangesAttributes`,
  })

  useEffect(() => {
    if (sellerProductId && fields.length === 0) {
      append({ qty: null, startLoad: 1, endLoad: null })
    }
  }, [sellerProductId, append, fields.length])

  if (sellerProductId)
    return (
      <div>
        {fields.map((field, rangeIndex) => (
          <ExtrasFormRangesRow
            key={field.id}
            name={name}
            control={control}
            index={rangeIndex}
            hideRange={hideRange}
            onAdd={() => append({ qty: null, startLoad: 1, endLoad: null })}
            onRemove={index => remove(index)}
          />
        ))}
      </div>
    )

  return null
}
