import moment from 'moment'
import { documentText } from 'ionicons/icons'
import { prepareQueryString } from '~/utils'

export const ALL = 'All'
export const DOCUMENTS_PER_PAGE = 24

export const PER_PAGE_FIELDS = [
  { label: '12', value: 12 },
  { label: '20', value: 20 },
  { label: '24', value: 24 },
  { label: '36', value: 36 },
  { label: '48', value: 48 },
]

export const docType = [
  { value: 'Load', label: 'Load' },
  { value: 'Invoice', label: 'Invoice' },
  { value: null, label: 'Not Set' },
]

export const DOCUMENT_STATUSES = [
  { label: 'New', value: 'New' },
  { label: 'Used', value: 'Used' },
  { label: 'Issue', value: 'Issue' },
  { label: 'Pending', value: 'Pending' },
]

export const DOCUMENT_ACCESS_TYPES = [
  { label: 'All', value: ALL },
  { label: 'Uploaded by Me', value: 'Mine' },
  { label: 'Uploaded by Others', value: 'Others' },
]

export const DOCUMENT_DOC_TYPES = [
  { label: 'Load', value: 1 },
  { label: 'Invoice', value: 2 },
  { label: 'Not Set', value: 0 },
]

export const DOCUMENT_FILE_TYPES = [
  { label: 'PDF', value: 'pdf', checked: false },
  { label: 'Image', value: 'image', checked: false },
  { label: 'Octet Stream', value: 'octet-stream', checked: false },
]

export const DOCUMENT_SORT_FIELDS = [
  {
    label: 'Id',
    value: 'id',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'id',
    isHidden: false,
  },
  {
    label: 'Created Date',
    value: 'created_at',
    type: 'date',
    sorted: true,
    isAsc: false,
    sortField: 'created_at',
    isHidden: false,
  },
  {
    label: 'Company Name',
    value: 'name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'name',
    isHidden: false,
  },
  {
    label: 'Status',
    value: 'status',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
  },
  {
    label: 'File Type',
    value: 'file_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'file_type',
    isHidden: false,
  },
  {
    label: 'Load / Invoice',
    value: 'doc_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'doc_type',
    isHidden: false,
  },
  {
    label: '# of Pages',
    value: 'total_pages',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'total_pages',
    isHidden: false,
  },
]

export const DOCUMENTS_COLUMNS = [
  {
    header: '#',
    field: 'index',
    dataClass: 'index-col --align-center',
    value: 'id',
    size: 0.5,
  },
  {
    header: 'Date',
    field: 'date',
    dataClass: 'date-col --align-center',
    value: 'created_at',
    size: 1,
  },
  {
    header: 'Name',
    field: 'name',
    dataClass: 'name-col --align-center',
    size: 3,
  },
  {
    header: 'Seller',
    field: 'seller',
    dataClass: 'seller-col --align-center',
    value: 'name',
    size: 1,
  },
  {
    header: 'User',
    field: 'user',
    dataClass: 'user-col --align-center',
    size: 2,
  },
  {
    header: 'Doc type',
    field: 'docType',
    dataClass: 'doc-type-col --align-center',
    value: 'doc_type',
    size: 1,
  },
  {
    header: 'Buyer terminal',
    field: 'buyerTerminalId',
    dataClass: 'terminal-type-col --align-center',
    value: 'buyer_terminal_id',
    size: 1,
  },
  {
    header: 'Status',
    field: 'status',
    dataClass: 'status-col --align-center',
    value: 'status',
    size: 1,
  },
  {
    header: 'Pages',
    field: 'totalPages',
    dataClass: 'total-pages-col --align-center',
    value: 'total_pages',
    size: 1,
  },
  {
    header: '',
    field: 'expand',
    dataClass: 'total-pages-col --align-center',
    value: '',
    size: 0.5,
  },
  {
    header: '',
    field: 'actions',
    dataClass: 'actions-col --align-center',
    size: 0.5,
  },
]

export const NO_SORT_COLUMNS = [DOCUMENT_STATUSES]

export const addDocumentSource = document => {
  document.src =
    document.fileType && document.fileExtension == 'pdf'
      ? documentText
      : document.url

  return document
}

export const convertDocumentList = (documents, shift = 0) => {
  return documents.map((document, index) => {
    const {
      sellerImage,
      sellerName,
      sellerId,
      buyerId,
      buyerTerminalId,
      sellerTerminalId,
      parsedEmailId,
      originalName,
      ...rest
    } = document

    return {
      ...rest,
      id: document.id,
      index: index + 1,
      src:
        document.fileType && document.fileExtension == 'pdf'
          ? documentText
          : document.url,
      name: document.name,
      buyerId,
      user: document.user,
      docType: document.docType,
      fileExtension: document.fileExtension,
      status: document.status,
      totalPages: document.totalPages,
      issueDescription: document.issueDescription,
      sellerId,
      sellerImage,
      sellerName,
      buyerTerminalId,
      url: document.url,
      fileType: document.fileType,
      createdAt: document.createdAt,
      urlHost: document.urlHost,
      highlight: document.highlight ? true : false,
      sellerTerminalId,
      parsedEmailId,
      originalName,
      shiftedIndex: index + shift,
    }
  })
}
export const convertFiltersToQuery = ({
  currentUser,
  page,
  filters,
  sortData,
  invoice,
  perPage,
}) => {
  const params = invoice
    ? {
        buyer_id: invoice.buyerId,
        'filters[seller_id][]': invoice.sellerId,
        per_page: 200,
      }
    : {
        buyer_id: currentUser.company.id,
        page: page,
        per_page: perPage,
      }

  let query = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
  query += '&'
  query += prepareQueryString(filters, sortData)

  return query
}

export const isDocumentWithinFilters = (filters, document) => {
  let isWithin = true
  for (let filterField in filters) {
    if (filterField == 'date') {
      if (
        !moment(document.created_at).isAfter(filters.startDate) ||
        !moment(document.created_at).isBefore(filters.endDate)
      ) {
        isWithin = false
        break
      }
    } else if (filterField == 'searchWord') {
      if (
        !(document.name || '')
          .toLowerCase()
          .includes((filters.searchWord || '').toLowerCase())
      ) {
        isWithin = false
        break
      }
    } else {
      if (filters.filterField && !filterField.includes(document[filterField])) {
        isWithin = false
        break
      }
    }
  }

  return isWithin
}
