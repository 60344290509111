import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { ITestResult, ITestResultGetParams } from '~/types/models/ITestResult'
import { useModifyTestResults } from './useModifyTestResults'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryTestResults = (
  params: ITestResultGetParams = {},
  options?: Partial<UseQueryOptions<ITestResult[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'testResults',
      sessionUser?.id,
      buildGetUrl(apiClient.testResults, params),
    ],
    [params, sessionUser?.id],
  )

  const { data, isLoading } = useQuery({
    queryKey,
    async queryFn() {
      const { testResults } = await apiClient.testResults.get(params)
      return testResults
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const testResults = useMemo(() => data || [], [data])

  const {
    addTestResult,
    updateTestResult,
    removeTestResult,
    renewAllTestResults,
  } = useModifyTestResults(queryKey)

  return {
    testResults,
    isLoading,
    addTestResult,
    updateTestResult,
    removeTestResult,
    renewAllTestResults,
  }
}

export default useQueryTestResults
