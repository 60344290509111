import React, { useState, useEffect, useCallback } from 'react'
import { IonIcon, IonText } from '@ionic/react'

import PropTypes from 'prop-types'
import { caretUp, caretDown } from 'ionicons/icons'
import clsx from 'clsx'
import _ from 'lodash'

import './PdfPageSelector.scss'

const PdfPageSelector = props => {
  const {
    pageNumber,
    numPages,
    onBackPress,
    onForwardPress,
    onInputChange,
    onInputBlur,
    onKeyPress,
    forwardButtonProps,
    backButtonProps,
  } = props

  const isShowInputPage = _.toNumber(numPages) > 1

  const [currentPage, setCurrentPage] = useState(1)

  const onBlur = useCallback(
    event => {
      onInputBlur && onInputBlur(event)
      if (currentPage > numPages || currentPage <= 0) {
        setCurrentPage(pageNumber)
      } else {
        onInputChange && onInputChange(event, { currentPage })
      }
    },
    [currentPage, numPages, onInputBlur, onInputChange, pageNumber],
  )

  const onChange = useCallback(event => {
    const { value } = event.target
    setCurrentPage(Number(value))
  }, [])

  useEffect(() => {
    setCurrentPage(pageNumber)
  }, [pageNumber])

  return (
    <div className='PdfPageSelector__container'>
      {_.isNumber(numPages) && (
        <IonText style={{ fontSize: 12 }}>
          Page &nbsp;
          {isShowInputPage && (
            <input
              type='number'
              onChange={onChange}
              value={currentPage}
              style={{
                width: 35,
                backgroundColor: 'black',
                textAlign: 'center',
                color: 'white',
              }}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              max={numPages}
              min={0}
            />
          )}
          {!isShowInputPage && <span>{pageNumber}</span>}
          &nbsp;<span>{`of ${numPages || ''}`}</span>
        </IonText>
      )}

      {numPages > 1 && (
        <div className='PdfPageSelector__controlPage'>
          <span
            onClick={onForwardPress}
            className={clsx('icon', {
              disabled: pageNumber >= numPages || forwardButtonProps.disabled,
            })}
          >
            <IonIcon icon={caretUp} className='PdfPageSelector__controlIcon' />
          </span>
          <span
            onClick={onBackPress}
            className={clsx('icon', {
              disabled: pageNumber == 1 || backButtonProps.disabled,
            })}
          >
            <IonIcon
              icon={caretDown}
              className={clsx('PdfPageSelector__controlIcon')}
            />
          </span>
        </div>
      )}
    </div>
  )
}

PdfPageSelector.propTypes = {
  forwardButtonProps: PropTypes.object,
  pageNumber: PropTypes.number,
  numPages: PropTypes.number.isRequired,
  onBackPress: PropTypes.func,
  onForwardPress: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  backButtonProps: PropTypes.object,
}

PdfPageSelector.defaultProps = {
  forwardButtonProps: {},
  backButtonProps: {},
}

export default PdfPageSelector
