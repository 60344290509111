import { useState, useCallback, useEffect, useMemo } from 'react'

import { Form } from 'react-bootstrap'
import RegexDropdownField from './Dropdown'

import _ from 'lodash'

import './RegexField.scss'

const RegexField = props => {
  const { value, onChange, name, ...inputProps } = props

  const FLAG_OPTIONS = useMemo(
    () => [
      {
        label: 'Case Sensitive',
        value: null,
      },
      {
        label: 'Case Insensitive',
        value: 'i',
      },
      {
        label: 'Multiline - Matches new lines',
        value: 'm',
      },
    ],
    [],
  )

  const [textValue, setTextValue] = useState('')
  const [suffix, setSuffix] = useState('i')

  const flagValues = useMemo(
    () => FLAG_OPTIONS.map(({ value }) => value),
    [FLAG_OPTIONS],
  )

  const getFlagFromFlagsStr = useCallback(
    flags => {
      if (_.size(flags) === 0) {
        return ''
      }
      const [firstflagRemoveSpace] = flags.split('') // array of string

      if (flagValues.includes(firstflagRemoveSpace)) {
        return firstflagRemoveSpace
      }

      return ''
    },
    [flagValues],
  )

  const handleChange = useCallback(
    event => {
      const { value } = event.target
      setTextValue(value)
      const { regex } = getRegexText(value)

      let changeValue = `/${regex}/${suffix}`
      if (_.size(value) === 0) {
        changeValue = ''
      }
      onChange && onChange(event, { value: changeValue })
    },
    [getRegexText, onChange, suffix],
  )

  const getRegexText = useCallback(text => {
    if (_.size(text) === 0) {
      return {
        regex: '',
        flag: '',
      }
    }
    const [, , regex, flag] = text.match(/(\/?)(.+)\1([a-z]*)/i)

    return {
      regex,
      flag,
    }
  }, [])

  const handleChangeSuffiesRegex = useCallback(
    newSuffix => {
      setSuffix(newSuffix)
      const { regex } = getRegexText(textValue)
      let changeValue = `/${regex}/${newSuffix}`
      if (_.size(textValue) === 0) {
        changeValue = ''
      }

      onChange && onChange({ target: { name } }, { value: changeValue })
    },
    [getRegexText, name, onChange, textValue],
  )

  useEffect(() => {
    if (_.size(value) === 0) {
      setSuffix('')
      setTextValue('')

      return
    }
    const [, , regexStr, flags] = value.match(/(\/?)(.+)\1([a-z]*)/i)
    const newFlags = getFlagFromFlagsStr(flags)
    setSuffix(newFlags)
    setTextValue(regexStr)
  }, [getFlagFromFlagsStr, value])

  return (
    <div className='RegexField__container'>
      <Form.Control
        value={textValue}
        onChange={handleChange}
        // onMouseLeave={handleMouseLeave}
        name={name}
        {...inputProps}
      />
      <RegexDropdownField
        value={suffix}
        onChange={handleChangeSuffiesRegex}
        options={FLAG_OPTIONS}
      />
    </div>
  )
}

export default RegexField
