import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { ConcordFormDropdownV2 } from '~/components/shared'

function ConcordDropdownV2WithFormControl(props) {
  const { name, control, onChange, ...dropdownProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const onSelectOption = useCallback(
    (event, { value, selectedOption }) => {
      field.onChange(value)
      onChange &&
        onChange({
          name,
          value,
          selectedOption,
        })
    },
    [field, name, onChange],
  )

  return (
    <ConcordFormDropdownV2
      {...dropdownProps}
      name={name}
      value={field.value}
      error={fieldState.error?.message}
      onChange={onSelectOption}
    />
  )
}

export default ConcordDropdownV2WithFormControl
