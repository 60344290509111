import { useMemo } from 'react'

import { ConcordFormStructure } from '~/components/shared'

import { ALLOW_COMMUNICATION_CHANNEL_OPTIONS } from '~/utils/constants'
import { userSchema } from './schemas'
import { EFieldType } from '~/types/enums/ECommonEnum'

import './WorkerForm.scss'

function UserTab(props) {
  const defaultValues = useMemo(
    () => ({
      email: '',
      phoneNumber: '',
      allowedCommunicationChannels: [],
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        isPhoneNumber: true,
        type: EFieldType.number,
      },
      {
        name: 'allowedCommunicationChannels',
        label: 'Allowed Communication Channels',
        type: EFieldType.multipleSelect,
        options: ALLOW_COMMUNICATION_CHANNEL_OPTIONS,
      },
    ],
    [],
  )

  const defaultValuesMap = useMemo(() => {
    const allowedCommunicationChannelsDefaults =
      props.defaultValues?.allowedCommunicationChannels?.map(acc =>
        ALLOW_COMMUNICATION_CHANNEL_OPTIONS.find(
          option => option.value === acc,
        ),
      ) || []

    if (props.defaultValues) {
      return {
        ...props.defaultValues,
        allowedCommunicationChannels: allowedCommunicationChannelsDefaults,
      }
    } else {
      return defaultValues
    }
  }, [defaultValues, props.defaultValues])

  return (
    <ConcordFormStructure
      fields={fields}
      schema={userSchema}
      submitText='Next'
      {...props}
      defaultValues={defaultValuesMap}
    />
  )
}

UserTab.propTypes = {}

export default UserTab
