import { createSlice } from '@reduxjs/toolkit'

export const flagsSlice = createSlice({
  name: 'flags',
  initialState: {},
  reducers: {
    updateFlags: (state, action) => {
      action.payload.flags.forEach(flag => {
        state[flag.id] = { ...flag }
      })
    },
    updateFlag: (state, action) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default flagsSlice
