import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductGroups } from '~/redux/actions/CommonData/productGroupsActions'
import { selectProductGroupsLastFetchedAt } from '~/redux/selectors/selectLastFetchedAt'

export const useInitializeProductGroups = () => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const lastFetchedAt = useSelector(selectProductGroupsLastFetchedAt)

  useEffect(() => {
    if (!fetched && !lastFetchedAt) {
      dispatch(fetchProductGroups())
      setFetched(true)
    }
  }, [fetched, lastFetchedAt, dispatch])
}
