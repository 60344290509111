import { useCallback, useEffect, useRef, useState } from 'react'

import { IonButtons, IonSpinner } from '@ionic/react'
import { CommonDialogV2, ICommonDialogProps, Button } from '~/components/shared'

import { arrowRedoCircle, arrowUndoCircle, closeCircle } from 'ionicons/icons'

import Cropper from 'react-cropper'

import CropperInstance from 'cropperjs'
import isValidUrl from '~/utils/isValidUrl'
import { toBase64 } from '~/utils'
import { toast } from 'react-toastify'
import { Alert } from 'react-bootstrap'
import { When } from 'react-if'
import isBase64 from 'is-base64'

interface IDialogImageModifierProps extends ICommonDialogProps {
  image: string | File | null
  onSave: (image: string) => void
}

function DialogImageModifier(props: IDialogImageModifierProps) {
  const { isOpen, image, onClose, onSave, ...dialogProps } = props

  const [imageState, setImageState] = useState<string | undefined>(undefined)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const cropRef = useRef<CropperInstance>()

  const fetchImageAndConvert = useCallback(async () => {
    setIsLoading(true)
    try {
      setError('')
      if (image instanceof File) {
        const base64 = await toBase64(image)
        setImageState(base64)
      } else if (typeof image === 'string') {
        if (isBase64(image)) {
          setImageState(image)
        } else {
          const response = await fetch(image as string)
          const blob = await response.blob()
          const base64 = await toBase64(blob)
          setImageState(base64)
        }
      }
    } catch (error) {
      setError('Error happens while fetching image')
    } finally {
      setIsLoading(false)
    }
  }, [image])

  const onClickSaveButton = useCallback(() => {
    const croppedCanvas = cropRef.current?.getCroppedCanvas()
    const croppedDataURL = croppedCanvas?.toDataURL()
    if (croppedDataURL) {
      onSave(croppedDataURL)
    } else {
      toast.error('Image is nout found!')
    }
  }, [onSave])

  const initializeImage = useCallback(() => {
    if (image) {
      if (image instanceof File) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setImageState((image as any).preview)
      } else if (isValidUrl(image)) {
        fetchImageAndConvert()
      } else if (typeof image === 'string') {
        setImageState(image)
      }
    }
  }, [fetchImageAndConvert, image])

  useEffect(() => {
    if (isOpen) {
      initializeImage()
    } else {
      setError('')
      setImageState('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, isOpen])

  return (
    <CommonDialogV2
      isOpen={isOpen}
      isHiddenOkButton
      isHiddenHeader={!error}
      onClose={onClose}
      {...dialogProps}
    >
      <When condition={isLoading}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IonSpinner name='lines' />
        </div>
      </When>
      {error && (
        <Alert variant='danger' style={{ fontSize: 14 }}>
          <span>{error}</span>&nbsp;
          <span className='hyperLink' onClick={fetchImageAndConvert}>
            Retry
          </span>
        </Alert>
      )}
      <When condition={Boolean(imageState)}>
        <IonButtons style={{ marginBottom: 8 }}>
          <Button
            icon={arrowUndoCircle}
            fill='solid'
            size='small'
            onClick={() => {
              cropRef.current?.rotate(-5)
            }}
          />
          <Button
            icon={arrowRedoCircle}
            fill='solid'
            size='small'
            onClick={() => {
              cropRef.current?.rotate(5)
            }}
          />
          <Button
            label='Save'
            fill='solid'
            size='small'
            onClick={onClickSaveButton}
          />
          <Button
            icon={closeCircle}
            fill='solid'
            size='small'
            color='danger'
            onClick={() => {
              onClose && onClose()
              initializeImage()
            }}
          />
        </IonButtons>
        <Cropper
          src={imageState}
          initialAspectRatio={1}
          // viewMode={1}
          background={false}
          responsive
          // autoCropArea={1}
          checkOrientation={false}
          zoomable={false}
          autoCrop={false}
          onInitialized={instance => {
            cropRef.current = instance
          }}
          width='100%'
          checkCrossOrigin
        />
      </When>
    </CommonDialogV2>
  )
}

export default DialogImageModifier
