import moment from 'moment'
import { createSelector } from 'reselect'

export const selectStartTimesState = state => state.startTimes

export const selectStartTimeFilters = createSelector(
  selectStartTimesState,
  state => state.router.location.query,
  (startTimeState, queryUrl) => {
    const startDate = queryUrl.startDate
      ? moment(decodeURIComponent(queryUrl.startDate)).toDate()
      : null
    const endDate = queryUrl.endDate
      ? moment(decodeURIComponent(queryUrl.endDate)).toDate()
      : null
    return {
      ...startTimeState.filters,
      startTime: {
        startDate,
        endDate,
      },
    }
  },
)

export const selectStartTimeSorts = createSelector(
  selectStartTimesState,
  state => state.sorts,
)

export const selectStartTimeColumns = createSelector(
  selectStartTimesState,
  state => state.columns,
)

export const selectStartTimesData = createSelector(
  selectStartTimesState,
  state => state.startTimes,
)

export const selectStartTimeLoading = createSelector(
  selectStartTimesState,
  state => state.loading,
)

export const selectStartTimeViewMode = createSelector(
  selectStartTimesState,
  state => state.viewMode,
)

export const selectStartTimeSearchWord = createSelector(
  selectStartTimesState,
  state => state.searchWord,
)

export const selectLoadingStartTimeIds = createSelector(
  selectStartTimesState,
  state => state.loadingStartTimeIds,
)
