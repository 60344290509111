import * as types from '../actions/actionTypes'
import { produce, original } from 'immer'

export const initialState = {
  loading: true,
  error: null,
  invoice: null,
  compareInvoiceError: null,
  productDetailsLoading: false,
  isFormFieldOpen: false,
  showSearchTable: false,
  showConfirmationMessage: false,
  searchTableRows: {},
  selectedBillLine: null,
}

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INVOICE: {
      return {
        ...state,
        invoice: null,
        error: null,
        loading: true,
      }
    }

    case types.FETCH_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoice: action.invoice,
        compareInvoiceError: action.compareInvoiceError,
      }
    }

    case types.FETCH_INVOICE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        compareInvoiceError: action.compareInvoiceError,
      }
    }
    case types.UPDATE_INVOICE: {
      return {
        ...state,

        // disable loading on API calls for this page for UX
        loading: false,
      }
    }

    case types.UPDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoice: action.invoice,
      }
    }

    case types.UPDATE_INVOICE_ERROR: {
      return {
        ...state,

        loading: false,
        error: action.error,
      }
    }

    case types.UPDATE_BILL_LINE: {
      return {
        ...state,

        // disable loading on API calls for this page for UX
        loading: false,
      }
    }

    case types.UPDATE_BILL_LINE_SUCCESS: {
      return produce(state, draft => {
        const {
          updatedBillLines,
          isCompareInvoice,
          invoice,
          compareBillLine,
          billLine,
        } = action

        if (updatedBillLines && draft.invoice?.billLines?.length) {
          draft.invoice.billLines.forEach(billLine => {
            const index = updatedBillLines.findIndex(
              ({ id }) => id === billLine.id,
            )
            if (index !== -1) {
              const originalBillLine = original(billLine)
              billLine = { ...originalBillLine, ...updatedBillLines[index] }
            }
          })
          const index = draft.invoice.billLines.findIndex(
            ({ id }) => (id === billLine.id) === id,
          )
          if (index !== -1) {
            const originalBillLine = original(draft.invoice?.billLines[index])
            draft.invoice.billLines[index] = {
              ...originalBillLine,
              ...billLine,
            }
          }
        } else {
          const index = draft.invoice?.billLines.findIndex(
            ({ id }) => id === billLine.id,
          )
          if (index !== -1) {
            const originalBillLine = original(draft.invoice?.billLines[index])
            draft.invoice.billLines[index] = {
              ...originalBillLine,
              ...billLine,
            }
          }
        }

        if (isCompareInvoice) {
          draft.invoice.compareInvoice.total = invoice.total
          draft.invoice.compareInvoice.loadCount = invoice.loadCount
          draft.invoice.compareInvoice.qty = invoice.qty
        } else {
          draft.invoice.total = invoice.total
          draft.invoice.total = invoice.loadCount
          draft.invoice.total = invoice.qty
        }

        if (
          compareBillLine &&
          draft.invoice.compareInvoice &&
          draft.invoice.compareInvoice.billLines
        ) {
          draft.invoice.compareInvoice.billLines =
            draft.invoice.compareInvoice.billLines.map(billLine =>
              billLine?.id === compareBillLine?.id ? compareBillLine : billLine,
            )
        }
      })
    }

    case types.UPDATE_BILL_LINE_ERROR: {
      return {
        ...state,

        loading: false,
        error: action.error,
      }
    }

    case types.REMOVE_INVOICE_COMPARE: {
      const newState = Object.assign({}, state)

      if (newState.invoice?.compareInvoice) {
        newState.invoice.compareInvoice = null
      }
      if (newState?.invoice.compareInvoiceId)
        newState.invoice.compareInvoiceId = null

      return newState
    }

    case types.ONLOAD_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetailsLoading: true,
      }
    }

    case types.LOAD_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        productDetailsLoading: false,
      }
    }

    case types.UPDATE_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT: {
      return produce(state, draft => {
        const { updatedBillLines } = action.payload
        if (draft.invoice?.billLines?.length > 0) {
          draft.invoices.billLines.forEach(billLine => {
            const index = updatedBillLines.findIndex(
              ({ id }) => id === billLine.id,
            )
            if (index !== -1) {
              const originalBillLine = original(billLine)
              billLine = { ...originalBillLine, ...updatedBillLines[index] }
            }
          })
        }
      })
    }

    case types.TOGGLE_BILL_LINES_FORM_FIELD: {
      const { isOpen } = action

      return {
        ...state,
        isFormFieldOpen: isOpen,
      }
    }

    case types.INVOICE_UPDATE_INVOICE_SELLER: {
      const { payload } = action

      return produce(state, draft => {
        draft.invoice.seller = {
          ...draft.invoice.seller,
          ...payload,
        }
      })
    }

    case types.INVOICE_UPDATE_INVOICE_DETAIL: {
      const { payload } = action

      return produce(state, draft => {
        draft.invoice = {
          ...draft.invoice,
          ...payload,
        }
      })
    }

    case types.OPEN_BILL_LINES_SEARCH_TABLE: {
      const { data } = action

      return {
        ...state,
        showSearchTable: true,
        searchTableRows: {
          [data.parentBillLineId]: data,
        },
      }
    }

    case types.CLOSE_BILL_LINES_SEARCH_TABLE: {
      const { data } = action

      delete state.searchTableRows[data.parentBillLineId]

      return {
        ...state,
        showSearchTable: false,
      }
    }

    case types.OPEN_BILL_LINES_ASSOCIATION_CONFIRMATION: {
      return {
        ...state,
        showConfirmationMessage: true,
        selectedBillLine: action.data,
      }
    }

    case types.CLOSE_BILL_LINES_ASSOCIATION_CONFIRMATION: {
      return {
        ...state,
        showConfirmationMessage: false,
        selectedBillLine: null,
      }
    }

    case types.UPDATE_BILL_LINE_TAG_LINKS: {
      return produce(state, draft => {
        const { tagLinkData } = action

        const lineIndex = draft.invoice.billLines.findIndex(
          billLine => billLine.id == tagLinkData.id,
        )
        draft.invoice.billLines[lineIndex].tagIds = tagLinkData.tagIds
      })
    }

    default: {
      return state
    }
  }
}

export default invoiceReducer
