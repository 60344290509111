import { useContext } from 'react'

import CompanyViewContext from './CompanyViewContext'

const useCompanyViewProvider = () => {
  const context = useContext(CompanyViewContext)
  return context
}

export default useCompanyViewProvider
