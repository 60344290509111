import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

const dataState = state => state.data
const uiState = state => state.ui

export const selectProductGroups = createSelector(dataState, state =>
  columnHashToColumnsArray(state.productGroups),
)

export const selectProductGroupLoading = createSelector(
  uiState,
  state => state.productGroups.loading,
)

export const selectProductGroupOptions = createSelector(
  selectProductGroups,

  productGroups =>
    productGroups.map(({ id, code, name }) => ({
      value: id,
      code,
      name,
      label: name,
    })),
)
