import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonContent,
} from '@ionic/react'
import LoadForm from '~/components/load/LoadForm'

import PropTypes from 'prop-types'

const DialogCreateLoad = props => {
  const {
    document,
    onClose,
    afterCreateLoad,
    afterDeleteDocument,
    ...dialogProps
  } = props

  return (
    <IonModal {...dialogProps}>
      <IonHeader translucent>
        <IonToolbar color='black'>
          <IonTitle color='black'>Create Load</IonTitle>
          <IonButton
            color='danger'
            slot='end'
            onClick={onClose}
            title='Close Modal'
          >
            Close
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadForm
          document={document}
          hidePopup={onClose}
          afterCreateLoad={afterCreateLoad}
          afterDeleteDocument={afterDeleteDocument}
        />
      </IonContent>
    </IonModal>
  )
}

DialogCreateLoad.propTypes = {
  document: PropTypes.object,
  onClose: PropTypes.func,
  afterCreateLoad: PropTypes.func,
  afterDeleteDocument: PropTypes.func,
}

export default DialogCreateLoad
