import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IGetOffdaysParams, IOffday } from '~/types/models/IOffday'
import useModifyOffdays from './useModifyOffdays'
import { useCallback, useMemo } from 'react'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryOffdays = (
  params: IGetOffdaysParams,
  options?: Partial<UseQueryOptions<IOffday[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'offdays',
      sessionUser?.id,
      buildGetUrl(apiClient.offdays.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.offdays.get(params)
      return response.offdays
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const offdaysData = useMemo(() => data || [], [data])

  const findOffDayByWorkerId = useCallback(
    (workerId: number) => offdaysData.find(item => item.workerId === workerId),
    [offdaysData],
  )

  const { addOffday, updateOffday, removeOffday } = useModifyOffdays(params)

  return {
    offdaysData,
    isLoadingOffdaysData: isLoading,
    findOffDayByWorkerId,
    addOffday,
    updateOffday,
    removeOffday,
  }
}

export default useQueryOffdays
