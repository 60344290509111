import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IPrizeFormProps } from './type'
import type { IPrizeFormValues } from '~/types/models/IPrize'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'

const PrizeForm = (props: IPrizeFormProps) => {
  const { afterCreate, afterUpdate, formData, campaignId, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Prize String',
        name: 'prizeString',
        isRequired: true,
      },
      {
        label: 'Qty',
        name: 'qty',
        isRequired: true,
        size: 6,
        type: EFieldType.number,
      },
      {
        label: 'Value',
        name: 'value',
        isRequired: true,
        size: 6,
      },
    ],
    [],
  )

  const defaultValues = useMemo<IPrizeFormValues>(
    () => ({
      campaignId: null,
      prizeString: '',
      qty: null,
      value: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        campaignId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        prizeString: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        qty: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        value: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IPrizeFormValues) => {
      const payload = _.pick(formValues, [
        'campaignId',
        'prizeString',
        'qty',
        'value',
      ])
      const { errors, ...response } = await apiClient.prizes.create({
        prize: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IPrizeFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'campaignId',
          'prizeString',
          'qty',
          'value',
        ])

        const { errors, ...response } = await apiClient.prizes.update(
          formValues.id,
          {
            prize: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IPrizeFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating && campaignId) {
      formRef.current?.setValue('campaignId', campaignId)
    }
  }, [campaignId, isUpdating])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default PrizeForm
