export enum ECheckPayFrequency {
  daily = 'daily',
  weekly = 'weekly',
  biweekly = 'biweekly',
  semimonthly = 'semimonthly',
  monthly = 'monthly',
}

export enum ECheckBusinessType {
  soleProprietorship = 'sole_proprietorship',
  partnership = 'partnership',
  cCorporation = 'c_corporation',
  sCorporation = 's_corporation',
  llc = 'llc',
}

export enum ECheckCompanyIndustryType {
  autoOrMachineSales = 'auto_or_machine_sales',
  autoOrMachineRepair = 'auto_or_machine_repair',
  artsOrEntertainmentOrRecreation = 'arts_or_entertainment_or_recreation',
  cleaningServices = 'cleaning_services',
  consultingServices = 'consulting_services',
  educationalServices = 'educational_services',
  familyCareServices = 'family_care_services',
  financialServices = 'financial_services',
  foodAndBeverageRetailOrWholesale = 'food_and_beverage_retail_or_wholesale',
  generalConstructionOrGeneralContracting = 'general_construction_or_general_contracting',
  healthCare = 'health_care',
  hospitalityOrAccommodation = 'hospitality_or_accommodation',
  hvacOrPlumbingOrElectricalContracting = 'hvac_or_plumbing_or_electrical_contracting',
  legalServices = 'legal_services',
  nonFoodRetailOrWholesale = 'non_food_retail_or_wholesale',
  other = 'other',
  personalCareServices = 'personal_care_services',
  realEstate = 'real_estate',
  restaurant = 'restaurant',
  scientificOrTechnicalServices = 'scientific_or_technical_services',
  securityServices = 'security_services',
  staffing = 'staffing',
  tobaccoOrAlcoholSales = 'tobacco_or_alcohol_sales',
  transportation = 'transportation',
}

export enum ECheckCompanyProcessingPeriod {
  oneDay = 'one_day',
  twoDay = 'two_day',
  fourDay = 'four_day',
}

export enum ECheckStatus {
  completed = 'completed',
  needsAttention = 'needs_attention',
  inReview = 'in_review',
}
