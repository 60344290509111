import _ from 'lodash'
import { getNumberValue, toFixed } from '~/utils/utils'

export const getApiSortField = (sortList = []) => {
  const _sortFields = sortList.filter(option => option.sorted)
  if (_sortFields.length == 0) return

  return {
    value: _sortFields[0].value,
    ascendingSort: _sortFields[0].isAsc,
  }
}

export const getLocalSortFields = (sortList = []) => {
  //
  return sortList.filter(option => option.sorted)
}

export const sortDataFromSortList = (data = [], sortList = []) => {
  if (sortList.length == 0) return data

  const interatee = sortList.map(({ sortField, type }) => {
    return data => {
      const value = _.get(data, sortField)
      if (type === 'string') {
        return value === null ? '' : value
      } else if (type === 'int') {
        return value === null ? 0 : parseInt(value)
      }

      return value
    }
  })
  const order = sortList.map(line => (line.isAsc ? 'asc' : 'desc'))

  return _.orderBy(data, interatee, order)
}

export const reorderSortField = (sortOptions, field) => {
  const newOptions = sortOptions.map(option => {
    return option.sortField == field
      ? {
          ...option,
          isAsc: !option.isAsc,
        }
      : option
  })

  return newOptions
}

// export const toggleSortField = (sortOptions, field) => {
//   const newOptions = sortOptions.map(option => {
//     return option.sortField == field
//       ? {
//           ...option,
//           sorted: !option.sorted,
//         }
//       : option
//   })

//   return newOptions
// }

export const sortOnHeader = (sortOptions, field) => {
  //
  const option = sortOptions.find(o => o.sortField == field)

  if (!option) return sortOptions
  if (!option.sorted) {
    const otherOptions = sortOptions.filter(o => o.sortField != field)

    return [
      {
        ...option,
        sorted: true,
      },
      ...otherOptions,
    ]
  } else {
    return reorderSortField(sortOptions, field)
  }
}

export const enableSortField = (sortOptions, field, isCheckedKey) => {
  const option = sortOptions.find(o => o.sortField == field)

  let sortedFields = []
  let unsortedFields = []
  if (!option) return sortOptions
  if (!option[isCheckedKey]) {
    sortedFields = sortOptions.filter(o => o[isCheckedKey])
    unsortedFields = sortOptions.filter(
      o => !o[isCheckedKey] && o.sortField !== option.sortField,
    )

    return [
      ...sortedFields,
      {
        ...option,
        [isCheckedKey]: true,
      },
      ...unsortedFields,
    ]
  } else {
    sortedFields = sortOptions.filter(o =>
      o[isCheckedKey] && o.sortField !== option.sortField ? true : false,
    )
    unsortedFields = sortOptions.filter(o => !o[isCheckedKey])

    return [
      ...sortedFields,
      {
        ...option,
        [isCheckedKey]: false,
      },
      ...unsortedFields,
    ]
  }
}

export const getSortFieldAndIndex = (sortOptions, field, isCheckedKey) => {
  const sortedOptions = sortOptions.filter(option => option[isCheckedKey])
  const sortIndex = sortedOptions.findIndex(
    option => option.sortField === field,
  )
  const sortField = sortIndex > -1 ? sortedOptions[sortIndex] : undefined

  return {
    index: sortIndex,
    sortField,
  }
}

export const getMatchSortValues = billLines => {
  return (
    billLines &&
    billLines.map(line => ({
      ...line,
      total: parseFloat(toFixed(line.total, 2)),
      qty: parseFloat(toFixed(line.qty, 2)),
      price: parseFloat(toFixed(line.price, 2)),
      discount: getNumberValue(line.discount, 2),
    }))
  )
}
