import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IPaymentTermFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import * as Yup from 'yup'
import { TERM_TYPE_OPTIONS } from '~/utils/constants'
import { IPaymentTermFormValues } from '~/types/models/IPaymentTerm'

const PaymentTermForm = (props: IPaymentTermFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Name',
        name: 'name',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Term Type',
        name: 'termType',
        size: 6,
        type: EFieldType.singleSelect,
        options: TERM_TYPE_OPTIONS,
        isRequired: true,
      },
      {
        label: 'Term Qty',
        name: 'termQty',
        type: EFieldType.number,
        isRequired: true,
      },
    ],
    [],
  )

  const defaultValues = useMemo<IPaymentTermFormValues>(
    () => ({
      name: '',
      termType: null,
      termQty: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        termQty: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IPaymentTermFormValues) => {
      const { errors, ...response } = await apiClient.paymentTerms.create({
        paymentTerm: {
          name: formValues.name,
          termQty: formValues.termQty,
          termType: formValues.termType,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IPaymentTermFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['name', 'termType', 'termQty'])
        const { errors, ...response } = await apiClient.paymentTerms.update(
          formValues.id,
          {
            paymentTerm: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IPaymentTermFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default PaymentTermForm
