import { useCallback } from 'react'
import { ConcordFormDropdown } from '~/components/shared'
import { IonIcon } from '@ionic/react'
import { flag } from 'ionicons/icons'
import _ from 'lodash'
import clsx from 'clsx'
import { getClassNameByLoadStatus } from '~/utils'
import { DEFAULT_PRIMARY_PARSING_STATUSES } from '~/constants/loads/filters'

function ConcordFormCommonLoadStatusDropdown(props) {
  const { className, value, flags, renderNonOptionLabel, ...dropdownProps } =
    props

  const getOption = useCallback(
    value => _.find(DEFAULT_PRIMARY_PARSING_STATUSES, { label: value }) || {},
    [],
  )

  return (
    <ConcordFormDropdown
      className={clsx('remove-border-from-common-seller-dropdown', className)}
      value={getOption(value)}
      options={DEFAULT_PRIMARY_PARSING_STATUSES}
      styles={{
        option: (provided, state) => {
          const { style = {} } = state.data

          return _.merge(provided, style, { fontSize: 14 })
        },
      }}
      getOptionLabel={option => {
        const optionValue = getOption(option.label)

        if (option.label) {
          const label = option.label === 'No Collection' ? 'NC' : option.label

          return (
            <span
              className={clsx(
                'table-pill',
                getClassNameByLoadStatus(optionValue.label),
              )}
              style={{ maxWidth: '15rem', opacity: 1 }}
            >
              {flags?.length > 0 ? (
                <span style={{ fontSize: 12, paddingRight: 4 }}>
                  <IonIcon
                    style={{ color: 'red' }}
                    icon={flag}
                    className={'icon-inner'}
                  />
                </span>
              ) : (
                <span>&bull;</span>
              )}
              {label}
            </span>
          )
        }

        return renderNonOptionLabel(option)
      }}
      {...dropdownProps}
    />
  )
}

ConcordFormCommonLoadStatusDropdown.defaultProps = {
  renderNonOptionLabel: () => '',
}

export default ConcordFormCommonLoadStatusDropdown
