import { useCallback, useState, useEffect } from 'react'

import { Calendar } from 'react-date-range'
import { CommonDialog } from '~/components/shared'

import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from 'clsx'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DialogDatePicker.scss'

function DialogDatePicker(props) {
  const { value, onChange, onClose, className, ...dialogProps } = props

  const [dateValue, setDateValue] = useState(null)

  const handleChangeDateRange = useCallback(date => {
    setDateValue(date)
  }, [])

  const handleOk = useCallback(() => {
    onChange && onChange(dateValue)
    onClose && onClose()
  }, [onChange, onClose, dateValue])

  useEffect(() => {
    if (_.isNil(value)) {
      return setDateValue(value)
    }

    if (_.isString(value)) {
      return setDateValue(moment(value).toDate())
    }

    setDateValue(value)
  }, [value])

  return (
    <CommonDialog
      {...dialogProps}
      className={clsx(className, 'DialogDatePicker__dialog')}
      onOk={handleOk}
      onClose={onClose}
    >
      <Calendar
        className='DialogDatePicker__calendar'
        date={dateValue}
        onChange={handleChangeDateRange}
      />
    </CommonDialog>
  )
}

DialogDatePicker.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
}

export default DialogDatePicker
