import { useState, useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { IonButton, IonIcon } from '@ionic/react'

import { list, grid } from 'ionicons/icons'
import PropTypes from 'prop-types'

const viewOptions = [
  { name: 'List', value: 'list' },
  { name: 'Grid', value: 'grid' },
]

const ToggleListGridView = props => {
  const { onChange, isGrid } = props

  const [loadIndexView, setLoadIndexView] = useState(isGrid ? 'grid' : 'list')

  const { isMobileSmallScreen } = useBreakpoint()

  const toggleLoadIndexView = useCallback(() => {
    setLoadIndexView(loadIndexView === 'list' ? 'grid' : 'list')
    onChange()
  }, [onChange, loadIndexView])

  if (isMobileSmallScreen) {
    return (
      <IonButton onClick={toggleLoadIndexView}>
        <IonIcon icon={isGrid ? list : grid} />
      </IonButton>
    )
  }

  return (
    <ButtonGroup
      className='mb-2'
      name='radio'
      style={{ width: '115px', height: '30px', float: 'right' }}
    >
      {viewOptions.map((viewOption, idx) => (
        <ToggleButton
          size='lg'
          key={idx}
          id={`radio-${idx}`}
          type='radio'
          variant={idx % 2 ? 'outline-secondary' : 'outline-primary'}
          name='radio'
          value={viewOption.value}
          checked={loadIndexView === viewOption.value}
          onChange={toggleLoadIndexView}
        >
          {viewOption.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  )
}

ToggleListGridView.propTypes = {
  onChange: PropTypes.func,
  isGrid: PropTypes.bool,
}

ToggleListGridView.defaultProps = {
  isGrid: false,
}

export default ToggleListGridView
