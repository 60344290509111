import { createSelector } from 'reselect'

const selectDocumentsUiState = state => {
  return state.ui.documents
}

export const selectDocumentColumns = createSelector(
  selectDocumentsUiState,

  documentUiState => {
    return documentUiState.columns
      .filter(c => c.hideable)
      .sort((a, b) => a.displayIndex - b.displayIndex)
  },
)

export const selectDocumentsUiSortableColumns = createSelector(
  selectDocumentsUiState,

  documentUiState =>
    documentUiState.columns
      .filter(c => c.sortable)
      .sort((a, b) => a.sortIndex - b.sortIndex),
)

// if (savedConfigs && savedConfigs.length > 0) {
//   savedConfigs.forEach(config => {
//     documentUiState.columns.forEach(col => {
//       if (config.columnName == col.columnName) {
//         col = { ...col, ...config }
//       }
//     })
//   })
// }

export const selectDocumentsInitialLoad = createSelector(
  selectDocumentsUiState,
  uI => uI.initialLoad,
)

export const selectDocumentsUI = createSelector(
  selectDocumentsUiState,
  uI => uI,
)

export const selectDocumentsFilters = createSelector(
  selectDocumentsUiState,
  uI => uI.filters,
)
