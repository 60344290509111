import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './DecimalInput.scss'

const DecimalInput = ({
  name,
  value,
  updateValue,
  numPlaces,
  style = {},
  className = '',
  isInteger,
}) => {
  const [numValue, setNumValue] = useState(null)

  const handleInput = useCallback(
    e => {
      const inputValue = e.target.value

      let decRegex

      if (isInteger) {
        decRegex = /^(\s*|\d+)(?:\.\d{1})?$/
      } else {
        //create regex for number of decimal places (1 or 2)
        decRegex =
          numPlaces == 1
            ? /^(\s*|\d+)(?:\.\d{1})?(\.)?$/
            : /^(\s*|\d+)(?:\.\d{1,2})?(\.)?$/
      }

      if (decRegex.test(inputValue)) {
        setNumValue(inputValue)
        updateValue && updateValue(inputValue)
      }
    },
    [numPlaces, updateValue, isInteger],
  )

  useEffect(() => {
    setNumValue(value)
  }, [value])

  return (
    <input
      style={style}
      className={className}
      onChange={handleInput}
      name={name}
      type='text'
      value={numValue}
    />
  )
}

DecimalInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  updateValue: PropTypes.func,
  numPlaces: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  isInteger: PropTypes.bool,
}

DecimalInput.defaultProps = {
  isInteger: false,
  numPlaces: 2,
}

export default DecimalInput
