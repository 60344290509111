import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Container, Row } from 'react-bootstrap'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react'
import { useSelector } from 'react-redux'

import {
  useQueryEmails,
  useQueryPhoneNumbers,
  useQuerySchedule,
  useQuerySensors,
  useQueryUoms,
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryTerminals,
  useQueryUsers,
  useQueryCompanyAddresses,
} from '~/hooks/useQueryData'

import _, { debounce } from 'lodash'
import {
  selectMyCurrentCompany,
  selectDriverFleets,
  selectTruckFleets,
  selectDriverDetails,
} from '~/redux/selectors'
import { toast } from 'react-toastify'
import {
  ConcordFormTextField,
  LineChart,
  SensorCard,
  SignatureModal,
  TimeRange,
  ToolTipOverlay,
} from '~/components/shared'
import { OrderFormDialog } from '~/components/shared/SchedulesTable/OrderFormDialog'
import clsx from 'clsx'
import { isToday, isSameYear } from '~/utils/getDay'
import { apiClient } from '~/api/ApiClient'
import buildFullName from '~/utils/buildFullName'
import moment from 'moment'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import { EEmailTypes } from '~/types/enums/EEmail'
import { EAddressType, EAddressableType } from '~/types/enums/EAddress'
import { ENoteableType } from '~/types/enums/ENote'
import NoteDropdown from '../shared/NoteButton/NoteDropdown'
import NoteEditDropdown from '../shared/NoteButton/NoteEditDropdown'
import NoteConfirmDelete from '../shared/NoteButton/NoteConfirmDelete'
import { getInitName } from '~/utils'
import { When } from 'react-if'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import isNumber from '~/utils/isNumber'
import isDateInRange from '~/utils/isDateInRange'
import { arrowBackOutline } from 'ionicons/icons'
import getDateRangeToday from '~/utils/getDateRangeToday'
import { QRCode } from 'react-qrcode-logo'
import { useIsMobile } from '~/hooks/useIsMobile'
import './LoadPrint.scss'
import { TextToggleInput } from '../shared/TextToggleInput/TextToggleInput'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import getFilteredData from '~/utils/getFilteredData'
import buildFullAddress from '~/utils/buildFullAddress'
import { useQueryLoadTimes } from '~/hooks/useQueryData/useQueryLoadTimes'

const LoadPrint = props => {
  const { load, onClose } = props

  const [loadExtras, setLoadExtras] = useState([])
  const [isShowSignatureModal, setIsShowSignatureModal] = useState(false)
  const [signalName, setSignalName] = useState('')
  const [signature, setSignature] = useState('')
  const [isUpdatingSignatureName, setIsUpdatingSignatureName] = useState(false)
  const [notes, setNotes] = useState([])
  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultTab, setDefaultTab] = useState(undefined)
  const [sensorSelected, setSensorSelected] = useState(null)
  const [timeRange, setTimeRange] = useState({
    startTime: '00:00',
    endTime: '23:59',
  })
  const [hasImageError, setHasImageError] = useState(false)

  const driverFleets = useSelector(selectDriverFleets)
  const truckFleets = useSelector(selectTruckFleets)
  const currentCompany = useSelector(selectMyCurrentCompany)
  const driverDetails = useSelector(selectDriverDetails)

  const isMobile = useIsMobile()

  const styles = useMemo(
    () => ({
      printLoadRow: {
        marginLeft: '10px !important',
        marginRight: '10px !important',
        marginBottom: '10px !important',
        fontSize: '15px',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
      },
      table: {
        border: '1px solid #e5e5e5',
        width: '100%',
      },
      tableHeader: {
        borderBottom: '1px solid #e5e5e5',
      },
      tableRow: {
        borderBottom: '1px solid #e5e5e5',
        // fontFamily: 'Arial, Helvetica, sans-serif'
      },
      tableRowNote: {
        borderBottom: '1px solid #e5e5e5',
      },
      tableCell: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
      },
      tableCellValue: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
        paddingTop: 2,
        paddingBottom: 2,
      },
      tableNoteCell: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      tableCellHeader: {
        borderBottom: '1px solid #e5e5e5',
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        border: '1px solid grey',
        borderRadius: '8px',
        padding: '12px',
      },
      cardColumn: {
        width: '50%',
      },
      header: {
        marginBottom: 8,
        display: 'flex',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: isMobile ? 'flex-start ' : 'space-between',
        flexDirection: isMobile ? 'column' : 'row',
      },
      companyInfo: {
        display: 'flex',
        alignItems: 'center',
      },
      companyAvatar: {
        width: 60,
        height: 60,
      },
      companyNameCode: {
        fontSize: 16,
        marginLeft: 8,
      },
      companyName: {
        fontWeight: 'bold',
      },
      companyCode: {
        fontSize: 14,
      },
      tableExtraHeader: {
        width: '100%',
        fontSize: 14,
        fontWeight: '600',
        paddingLeft: 8,
        fontStyle: 'italic',
        borderBottom: '1px solid #e5e5e5',
      },
      signatureContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        marginBottom: 16,
      },
      signatureBox: {
        minWidth: 200,
        minHeight: 125,
        cursor: 'pointer',
        marginTop: 4,
      },
      actionNote: {},
      noteAvatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      noteAvatarInitName: {
        width: 25,
        height: 25,
        background: '#2dd36f',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 4,
        color: 'white',
      },
      noteAvatarFullname: {
        color: '#0008',
        fontSize: 13,
      },
    }),
    [isMobile],
  )

  const driverFleet = useMemo(
    () => driverFleets[load.driverFleetId],
    [driverFleets, load.driverFleetId],
  )

  const truckFleet = useMemo(
    () => truckFleets[load.truckFleetId],
    [load.truckFleetId, truckFleets],
  )

  const { currentUom } = useQueryUoms()

  const { getTimeFormat, getDateTimeFormat, getTimeFormatType } =
    useDateTimeFormat()

  const { sensorsData } = useQuerySensors(
    {
      sensor: {
        ...getDateRangeToday(),
        limit: 20,
      },
      truckId: truckFleet?.truckId,
    },
    {
      enabled: Boolean(truckFleet?.truckId && truckFleet?.truck?.sensorSource),
    },
  )

  const { loadTimesMapped } = useQueryLoadTimes({
    filters: {
      loadId: load?.id,
    },
  })

  const labelsInChart = useMemo(() => {
    if (sensorSelected) {
      return sensorSelected.data
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            timeRange.startTime,
            timeRange.endTime,
          )
        })
        .map(({ timestamp }) => moment(timestamp).format('H:mm:ss'))
    }
    return []
  }, [sensorSelected, timeRange.endTime, timeRange.startTime])

  const dataInChart = useMemo(() => {
    if (sensorSelected) {
      const data = sensorSelected.data
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            timeRange.startTime,
            timeRange.endTime,
          )
        })
        .map(({ value }) => {
          if (isNumber(value)) {
            return Number(value)
          }
          return 0
        })
      return [
        {
          data,
          borderColor: sensorSelected.background,
          backgroundColor: sensorSelected.background,
        },
      ]
    }
    return []
  }, [sensorSelected, timeRange.endTime, timeRange.startTime])

  const ticketTime = useMemo(
    () => load.ticketTime || load.createdAt,
    [load.createdAt, load.ticketTime],
  )

  const printRef = React.createRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: false,
    documentTitle: 'Ticket',
  })

  const { scheduleData, refetchScheduleData } = useQuerySchedule(
    load.scheduleId,
  )

  const buyerId = load.buyerId || scheduleData?.order?.buyerId
  const sellerId = load.sellerId || scheduleData?.order?.sellerId
  const buyerTerminalId =
    load.buyerTerminalId || scheduleData?.order?.buyerTerminalId
  const sellerTerminalId =
    load.sellerTerminalId || scheduleData?.sellerTerminalId

  const { findCompanyById } = useQueryCompanies({})
  const { findTerminalById } = useQueryTerminals()
  const { emailsData } = useQueryEmails()
  const { phoneNumbersData } = useQueryPhoneNumbers()
  const { addressesData } = useQueryCompanyAddresses()
  const { findUserByUserAccessId } = useQueryUsers()

  const { findBuyerSellerProductById, sellerProducts } =
    useQueryBuyerSellerProducts({
      filters: {
        joinsSellerProduct: true,
      },
    })

  const buyerCompany = findCompanyById(buyerId)
  const sellerCompany = findCompanyById(sellerId)
  const buyerTerminal = findTerminalById(buyerTerminalId)
  const sellerTerminal = findTerminalById(sellerTerminalId)
  const buyerSellerProduct = findBuyerSellerProductById(
    load.buyerSellerProductId,
  )

  const uomIds = useMemo(
    () =>
      _.uniq(
        Object.values(sellerProducts)
          .map(({ uomId }) => uomId)
          .filter(i => !!i),
      ),
    [sellerProducts],
  )

  const { uomsObj } = useQueryUoms(
    { filters: { id: uomIds } },
    { enabled: Boolean(uomIds.length) },
  )

  const operationsOrGeneralEmail = getFilteredData(emailsData, {
    emailTypes: [EEmailTypes.operations, EEmailTypes.general],
    emailableId: currentCompany.id,
  })[0]

  const operationsOrGeneralPhoneNumber = getFilteredData(phoneNumbersData, {
    phoneTypes: [EPhoneNumberTypes.operations, EPhoneNumberTypes.general],
    phoneableId: currentCompany.id,
    phoneableType: EPhoneableType.company,
  })[0]

  const officeOrMailingAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: currentCompany.id,
    addressableType: EAddressableType.company,
  })[0]

  const buyerAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: load.buyerId,
    addressableType: EAddressableType.company,
  })[0]

  const sellerAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: load.sellerId,
    addressableType: EAddressableType.company,
  })[0]

  const buyerTerminalAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.location],
    addressableId: load.buyerTerminalId,
    addressableType: EAddressableType.terminal,
  })[0]

  const sellerTerminalAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.location],
    addressableId: load.sellerTerminalId,
    addressableType: EAddressableType.terminal,
  })[0]

  const renderNoteUserAccess = useCallback(
    userAccessId => {
      const user = findUserByUserAccessId(userAccessId)
      const fullName = buildFullName(user?.person)
      const initialName = getInitName(fullName)

      return (
        <div style={styles.noteAvatarContainer}>
          <div style={styles.noteAvatarInitName}>{initialName}</div>
          <When condition={fullName !== 'Unknown'}>
            <div style={styles.noteAvatarFullname}>{fullName}</div>
          </When>
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findUserByUserAccessId],
  )

  const renderNoteCreatedAt = useCallback(
    createdAt => {
      let format = `MMM-DD, YYYY ${getTimeFormatType}`
      if (isToday(createdAt)) {
        format = `${getTimeFormatType}`
      } else if (isSameYear(createdAt)) {
        format = `MMM-DD, ${getTimeFormatType}`
      }
      return moment(createdAt).format(format)
    },
    [getTimeFormatType],
  )

  const renderNotesTable = useMemo(
    () => (
      <div
        style={{
          width: isMobile ? '100%' : 300,
          marginTop: isMobile ? 12 : 0,
        }}
      >
        <div style={{ ...styles.printLoadRow }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h2 style={{ marginRight: 8 }}>Notes</h2>
            <NoteDropdown
              notesData={notes}
              noteableType={ENoteableType.Load}
              afterCreateNote={() => loadNotes(load?.id)}
              tooltipProps={{
                placement: 'bottom',
              }}
              noteableId={load?.id}
            />
          </div>
        </div>
        <Row style={{ ...styles.printLoadRow }}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th
                  style={{
                    ...styles.tableCellHeader,
                    textAlign: 'left',
                    paddingLeft: 8,
                  }}
                >
                  Note
                </th>
                <th
                  style={{
                    ...styles.tableCellHeader,
                  }}
                >
                  User
                </th>
                <th
                  style={{
                    ...styles.tableCellHeader,
                    borderRightWidth: 0,
                    textAlign: 'left',
                    paddingLeft: 8,
                  }}
                >
                  Time
                </th>
                <th style={{ ...styles.tableCellHeader, borderRight: 0 }}></th>
              </tr>
            </thead>
            <tbody>
              {notes.map(extra => (
                <tr style={styles.tableRowNote} key={extra.id}>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '45%',
                      textAlign: 'left',
                      paddingLeft: 8,
                    }}
                  >
                    {extra.note}
                  </td>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '25%',
                    }}
                  >
                    {renderNoteUserAccess(extra.userAccessId)}
                  </td>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '20%',
                      textAlign: 'left',
                      paddingLeft: 8,
                      borderRightWidth: 0,
                      fontSize: 13,
                    }}
                  >
                    {renderNoteCreatedAt(extra.createdAt)}
                  </td>
                  <td style={styles.actionNote}>
                    <NoteEditDropdown
                      noteData={extra}
                      afterUpdateNote={() => loadNotes(load?.id)}
                    />
                    <NoteConfirmDelete
                      onConfirm={() => loadNotes(load?.id)}
                      noteData={extra}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </div>
    ),
    [
      isMobile,
      load?.id,
      loadNotes,
      notes,
      renderNoteCreatedAt,
      renderNoteUserAccess,
      styles.actionNote,
      styles.printLoadRow,
      styles.table,
      styles.tableCellHeader,
      styles.tableCellValue,
      styles.tableRowNote,
    ],
  )

  const renderCompanyCard = (company, address) => {
    return (
      <div style={styles.card}>
        <b>{company?.code}</b>
        <b>{company?.name}</b>
        <div style={{ fontSize: 13, fontStyle: 'italic' }}>
          {buildFullAddress(address)}
        </div>
      </div>
    )
  }

  const getPageMargins = () => {
    return '@page {margin: 48px 48px 48px 48px !important; }'
  }

  const onOpenSignatureModal = useCallback(() => {
    setIsShowSignatureModal(true)
  }, [])

  const onCloseSignatureModal = useCallback(() => {
    setIsShowSignatureModal(false)
  }, [])

  const onChangeTimeRange = useCallback(newTimeRange => {
    setTimeRange(newTimeRange)
  }, [])

  const onSelectSensor = useCallback(sensor => {
    setSensorSelected(prev => {
      if (sensor.code === prev?.code) {
        return null
      }
      return sensor
    })
    setTimeRange({
      startTime: '00:00',
      endTime: '23:59',
    })
  }, [])

  const editQty = id =>
    debounce(async newQty => {
      try {
        const loadExtra = await apiClient.loadExtras.update(id, { qty: newQty })
        setLoadExtras(
          loadExtras.map(extra => (extra.id === id ? loadExtra : extra)),
        )
      } catch (error) {
        toast.error(error.message)
      }
    })

  const loadNotes = useCallback(async id => {
    try {
      const { notes: dataNotes } = await apiClient.notes.get({
        filters: {
          noteableId: id,
          noteableType: 'Load',
        },
      })
      setNotes(dataNotes)
    } catch (error) {
      toast.error(error.message)
    }
  }, [])

  const onOpenOrderForm = useCallback(
    defaultTab => () => {
      setIsOpenOrderForm(true)
      if (defaultTab !== undefined) {
        setDefaultTab(defaultTab)
      }
    },
    [],
  )

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
    setDefaultTab(undefined)
  }, [])

  useEffect(() => {
    const fetchLoadExtras = async () => {
      if (load?.id) {
        try {
          const { loadExtras } = await apiClient.loadExtras.get({
            filters: { loadId: load.id },
          })
          setLoadExtras(loadExtras)
        } catch (error) {
          toast.error(error.message)
        }
      }
    }
    fetchLoadExtras()
  }, [load?.id])

  useEffect(() => {
    load?.id && loadNotes(load.id)
  }, [load?.id, loadNotes])

  return (
    <>
      <Container
        ref={printRef}
        style={{
          marginTop: '20px',
          fontFamily:
            'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        }}
      >
        <style>{getPageMargins()}</style>
        <div style={styles.header}>
          <div>
            <div style={styles.companyInfo}>
              {hasImageError ? (
                <div
                  style={{
                    ...styles.companyAvatar,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 20,
                    fontWeight: 600,
                    backgroundColor: '#1090ff',
                    color: 'white',
                    borderRadius: 12,
                  }}
                >
                  {getInitName(currentCompany.name)}
                </div>
              ) : (
                <img
                  style={styles.companyAvatar}
                  src={currentCompany.imageUrl || currentCompany.avatar}
                  onError={() => {
                    setHasImageError(true)
                  }}
                />
              )}

              <div style={styles.companyNameCode}>
                <div style={styles.companyName}>{currentCompany.name}</div>
                <div style={styles.companyCode}>{currentCompany.code}</div>
              </div>
            </div>
            <div style={{ fontSize: 15 }}>
              <span style={{ fontWeight: 700 }}>Address</span>:&nbsp;
              <span style={{ fontWeight: 400 }}>
                {buildFullAddress(officeOrMailingAddress)}
              </span>
            </div>
          </div>
          <div>
            <div style={{ fontSize: 15 }}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', paddingRight: 8 }}>
                      <span style={{ fontWeight: 700 }}>Email:</span>
                    </td>
                    <td>
                      <span style={{ fontWeight: 400 }}>
                        {operationsOrGeneralEmail?.email}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', paddingRight: 8 }}>
                      <span style={{ fontWeight: 700 }}>Phone:</span>
                    </td>
                    <td>
                      <span style={{ fontWeight: 400 }}>
                        {formatPhoneNumber(
                          operationsOrGeneralPhoneNumber?.number || '',
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Row style={styles.printLoadRow}>
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeader}>
                <th style={styles.tableCellHeader}>Ticket #</th>
                <th style={styles.tableCellHeader}>Date</th>
                <th style={styles.tableCellHeader}>Time</th>
                <th style={styles.tableCellHeader}>Truck</th>
                <th style={{ ...styles.tableCellHeader, borderRightWidth: 0 }}>
                  Driver
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={styles.tableCell}>{load?.id}</td>
                <td style={styles.tableCell}>
                  {getDateTimeFormat(ticketTime, 'YYYY-MM-DD')}
                </td>
                <td style={styles.tableCell}>{getTimeFormat(ticketTime)}</td>
                <td style={styles.tableCell}>
                  {truckFleet?.truck?.name || '-'}
                </td>
                <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                  {buildFullName(driverFleet?.person, '-')}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeader}>
                <th style={styles.tableCellHeader}>
                  Load Size {currentUom ? `(${currentUom?.code})` : ''}
                </th>
                <th style={styles.tableCellHeader}>Running Total</th>
                <th style={styles.tableCellHeader}>Order #</th>
                <th style={{ ...styles.tableCellHeader, borderRightWidth: 0 }}>
                  Order Load #
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={styles.tableCell}>{Number(load?.qty)}</td>
                <td style={styles.tableCell}>
                  {`${load?.orderQty ? Number(load?.orderQty) : 0} / ${Number(
                    scheduleData?.qty || 0,
                  )}`}
                </td>
                <td style={styles.tableCell}>
                  <span className='hyperLink' onClick={onOpenOrderForm()}>
                    {scheduleData?.order?.num}&nbsp;
                  </span>

                  <span
                    className='hyperLink'
                    onClick={onOpenOrderForm(scheduleData?.scheduleNumber)}
                  >
                    {scheduleData?.scheduleNumber
                      ? `- ${scheduleData?.scheduleNumber}`
                      : ''}
                  </span>
                </td>

                <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                  {load?.orderLoad}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        <div style={{ ...styles.printLoadRow, ...styles.row }}>
          <div style={{ ...styles.cardColumn, marginRight: 12 }}>
            <h2>Buyer</h2>
            {renderCompanyCard(buyerCompany, buyerAddress)}
          </div>
          <div style={{ ...styles.cardColumn, marginLeft: 12 }}>
            <h2>Deliver To:</h2>
            {renderCompanyCard(buyerTerminal, buyerTerminalAddress)}
          </div>
        </div>
        <div style={{ ...styles.printLoadRow, ...styles.row }}>
          <div style={{ ...styles.cardColumn, marginRight: 12 }}>
            <h2>Seller</h2>
            {renderCompanyCard(sellerCompany, sellerAddress)}
          </div>
          <div style={{ ...styles.cardColumn, marginLeft: 12 }}>
            <h2>Deliver From:</h2>
            {renderCompanyCard(sellerTerminal, sellerTerminalAddress)}
          </div>
        </div>

        <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
          <h2>Products</h2>
        </Row>
        <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableCellHeader}>Code</th>
                <th style={styles.tableCellHeader}>Name</th>
                <th style={styles.tableCellHeader}>Qty</th>
                <th style={{ ...styles.tableCellHeader, borderRight: 0 }}>
                  Unit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={styles.tableCell}>
                  {buyerSellerProduct?.sellerProductCode || '-'}
                </td>
                <td style={styles.tableCell}>
                  {buyerSellerProduct?.sellerProductName || '-'}
                </td>
                <td style={{ ...styles.tableCell, width: 100 }}>
                  {Number(load?.qty)}
                </td>
                <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                  {currentUom?.code}
                </td>
              </tr>

              {loadExtras.map(extra => (
                <tr style={styles.tableRow} key={extra.id}>
                  <td style={styles.tableCell}>
                    {sellerProducts &&
                      sellerProducts[extra?.sellerProductId]?.code}
                  </td>
                  <td style={styles.tableCell}>
                    {sellerProducts &&
                      sellerProducts[extra?.sellerProductId]?.name}
                  </td>
                  <ToolTipOverlay
                    content={
                      sellerProducts[extra?.sellerProductId]?.orderType === 0 &&
                      `${extra?.orderExtraQty}/${currentUom?.code}`
                    }
                  >
                    {console.log(
                      'extra?.orderExtraQty',
                      Number(extra?.orderExtraQty),
                    )}
                    <td style={styles.tableCell}>
                      <TextToggleInput
                        type='number'
                        value={extra?.orderExtraQty || 0}
                        onChange={editQty(extra?.id)}
                        label={Number(extra?.orderExtraQty)}
                      />
                    </td>
                  </ToolTipOverlay>
                  <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                    {uomsObj[sellerProducts[extra?.sellerProductId]?.uomId]
                      ?.code || ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {loadTimesMapped.length > 0 && (
            <>
              <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
                <h2>Times</h2>
              </Row>

              <table style={styles.table}>
                <thead>
                  <tr>
                    {loadTimesMapped.map(({ name }) => (
                      <th key={name} style={styles.tableCellHeader}>
                        {name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr style={styles.tableRow}>
                    {loadTimesMapped.map(({ name, time }) => (
                      <td key={name} style={styles.tableCell}>
                        {moment(time).format('H:mm')}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {isMobile && renderNotesTable}

          <div style={styles.signatureContainer}>
            {!isMobile && renderNotesTable}
            <div>
              <QRCode
                value={JSON.stringify({
                  id: load.id,
                  idDriverOpeningTicket: driverDetails?.id,
                })}
                size={150}
                logoImage='https://workloadnode.s3.amazonaws.com/Logos/Concord+Main+Logo+Black+BG+square+cropped.png'
                logoWidth={30}
                qrStyle='dots'
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 12,
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Signature</span>
              <div
                className={clsx('LoadPrint__signatureBox', {
                  hasSignature: signature,
                })}
                onClick={onOpenSignatureModal}
              >
                <div style={styles.signatureBox}>
                  {signature && (
                    <img src={signature} width={200} height={125} />
                  )}
                </div>
              </div>
              {isUpdatingSignatureName ? (
                <ConcordFormTextField
                  autoFocus
                  value={signalName}
                  className='LoadPrint__signalNameTextbox'
                  onChange={(event, { value }) => {
                    setSignalName(value)
                  }}
                  onBlur={() => {
                    setIsUpdatingSignatureName(false)
                  }}
                  onKeyPress={e => {
                    if (e.which === 13) {
                      setIsUpdatingSignatureName(false)
                    }
                  }}
                />
              ) : (
                <div
                  onClick={() => {
                    setIsUpdatingSignatureName(true)
                  }}
                  style={{
                    minHeight: 29.5,
                    minWidth: 161,
                    textAlign: 'center',
                    fontSize: 16,
                    marginTop: 8,
                  }}
                >
                  {signalName || 'Name'}
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>

      <IonGrid className='LoadPrint__sensorContainer'>
        <IonRow>
          {sensorsData.map(sensor => (
            <IonCol
              size={sensorSelected ? '12' : '4'}
              key={`${sensor.code}${sensor.name}`}
              className={clsx('sensorItem clickable', {
                isExpanded: sensor.code === sensorSelected?.code,
                isHidden: sensorSelected?.code,
              })}
            >
              <SensorCard sensorData={sensor} onClick={onSelectSensor} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <When condition={Boolean(sensorSelected)}>
        <div className='LoadPrint__chartHeader'>
          <div className='LoadPrint__topLeftChartHeader'>
            <span
              className='backIcon clickable'
              onClick={() => {
                setSensorSelected(null)
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </span>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              <span>{sensorSelected?.name}</span>&nbsp;-&nbsp;
              <span>Load # {load?.num}</span>
            </span>
          </div>
          <TimeRange value={timeRange} onChange={onChangeTimeRange} />
        </div>
        <LineChart labels={labelsInChart} datasets={dataInChart} />
      </When>

      <div>
        <IonButtons>
          <IonButton
            style={{ width: '100%' }}
            expand='block'
            color='primary'
            onClick={handlePrint}
            fill='solid'
          >
            Print
          </IonButton>

          {onClose && (
            <IonButton
              style={{ width: '100%' }}
              expand='block'
              color='medium'
              onClick={onClose}
              fill='solid'
            >
              Close
            </IonButton>
          )}
        </IonButtons>
      </div>
      <SignatureModal
        isOpen={isShowSignatureModal}
        onClose={onCloseSignatureModal}
        onConfirm={signature => {
          setSignature(signature)
        }}
      />
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        orderData={scheduleData?.order}
        defaultTab={defaultTab}
        className='LoadPrint__orderFormDialog'
        afterUpdate={() => {
          refetchScheduleData()
        }}
      />
    </>
  )
}

export default LoadPrint
