import _ from 'lodash'

const diffObjects = function (obj1, obj2, includeId = true) {
  try {
    const result = _.reduce(
      obj1,
      (result, value, key) => {
        if (includeId) {
          if (key === 'id') {
            result.id = value
          }
        }
        if (_.isPlainObject(value)) {
          const diff = diffObjects(value, obj2[key])
          if (!_.isEmpty(diff)) {
            result[key] = diff
          }
        } else if (!_.isEqual(value, obj2[key])) {
          result[key] = value
        }
        return result
      },
      {},
    )

    return _.isEmpty(result) ? {} : _.cloneDeep(result)
  } catch (error) {
    return {}
  }
}

export default diffObjects
