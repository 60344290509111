import { useState, useMemo, useCallback, useEffect } from 'react'
import { useRouter } from '~/hooks/useRouter'
import useTableCompanyBuyerTerminalReferenceSubTab from './useTableCompanyBuyerTerminalReferenceSubTab'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogBuyerTerminalReferenceForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { useWindowSize } from 'react-use'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope } from '~/redux/selectors'

function CompanyBuyerTerminalReferenceSubTab(props) {
  const [buyerTerminalReferences, setBuyerTerminalReferences] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    formData: null,
  })
  const currentScope = useSelector(selectCurrentScope)
  const [columnFilters, setColumnFilters] = useState([
    { id: 'view', value: currentScope },
  ])

  const router = useRouter()

  const filterData = useMemo(() => {
    const data = {}
    columnFilters.forEach(({ id, value }) => {
      data[id] = value
    })
    return data
  }, [columnFilters])

  const companyId = useMemo(
    () => props.companyId || router.params.id,
    [props.companyId, router.params.id],
  )

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const { isLoadingCompaniesData } = useQueryCompanies()
  const { isLoadingTerminals } = useQueryTerminals()

  const isTableLoading = loading || isLoadingCompaniesData || isLoadingTerminals

  const getApiCall = useCallback(() => {
    const params = {
      filters: {},
    }
    if (filterData.view === EScope.seller) {
      params.filters.sellerId = companyId
    } else {
      params.filters.buyerId = companyId
    }

    return apiClient.buyerTerminalReferences.get(params)
  }, [companyId, filterData.view])

  const getBuyerTerminalReferences = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setBuyerTerminalReferences(response.buyerTerminalReferences)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete buyer terminal reference #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const response = await apiClient.buyerTerminalReferences.delete(
            rowData.id,
          )
          if (_.size(response?.errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setBuyerTerminalReferences(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setDialogProps({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const formData = { [columnField]: value }
      const { errors } = await apiClient.buyerTerminalReferences.update(
        rowId,
        formData,
      )
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        setBuyerTerminalReferences(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === rowId)
            if (index > -1) {
              draft[index][columnField] = value
              toast.success(toastMessages.updateSuccess)
            } else {
              toast.error(`Cannot find record #${rowId}`)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const onToggleForm = useCallback(nextValue => {
    setDialogProps({
      isOpen: nextValue,
      formData: null,
    })
  }, [])

  const afterCreateItem = useCallback(
    newItem => {
      setBuyerTerminalReferences(prev => [...prev, newItem])
      onToggleForm(false)
    },
    [onToggleForm],
  )

  const afterUpdateItem = useCallback(
    updatedItem => {
      setBuyerTerminalReferences(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === updatedItem.id)
          if (index !== -1) {
            draft[index] = {
              ...draft[index],
              ...updatedItem,
            }
          }
        }),
      )
      onToggleForm(false)
    },
    [onToggleForm],
  )

  const { columns } = useTableCompanyBuyerTerminalReferenceSubTab()

  useEffect(() => {
    getBuyerTerminalReferences()
  }, [getBuyerTerminalReferences])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={buyerTerminalReferences}
          tableHeight={windowSize.height - 220}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          manualFiltering
          companyViewProps={{
            name: moduleName.company.buyerTerminalReferences,
            description:
              'View and manage the terminal names and code of your buyers.',
            onClickTopButton() {
              onToggleForm(true)
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 80,
              maxSize: 80,
            },
          }}
          state={{
            isLoading: isTableLoading,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenUpdateForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          filterOptions={[
            {
              label: 'View',
              type: EFieldType.singleSelect,
              field: 'view',
              options: [
                {
                  label: 'Buyer',
                  value: EScope.buyer,
                },
                {
                  label: 'Seller',
                  value: EScope.seller,
                },
              ],
            },
          ]}
          onCellEditEnd={onCellEditEnd}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>
      <DialogBuyerTerminalReferenceForm
        isOpen={dialogProps.isOpen}
        formData={dialogProps.formData}
        onClose={() => onToggleForm(false)}
        afterCreate={afterCreateItem}
        afterUpdate={afterUpdateItem}
      />
    </>
  )
}

CompanyBuyerTerminalReferenceSubTab.propTypes = {}

export default CompanyBuyerTerminalReferenceSubTab
