import { START_TIME_COLOR } from './constants'

const getStartTimeColor = (color: string | null | undefined) => {
  if (color) {
    return (START_TIME_COLOR as any)[color] || 'white'
  }
  return 'white'
}

export default getStartTimeColor
