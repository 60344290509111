import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { REWARD_SETTING_PROVIDER_OPTIONS } from '~/utils/constants'

import type { IRewardSettingFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import type { IRewardSettingFormValues } from '~/types/models/IRewardSetting'

const RewardSettingForm = (props: IRewardSettingFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
        size: 6,
      },
      {
        label: 'Provider',
        name: 'provider',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: REWARD_SETTING_PROVIDER_OPTIONS,
      },
      {
        label: 'Uid',
        name: 'uid',
      },
    ],
    [],
  )

  const defaultValues = useMemo<IRewardSettingFormValues>(
    () => ({
      name: '',
      provider: null,
      uid: '',
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        provider: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IRewardSettingFormValues) => {
      const payload = _.pick(formValues, ['name', 'provider', 'uid'])
      const { errors, ...response } = await apiClient.rewardSettings.create({
        rewardSetting: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IRewardSettingFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['name', 'provider', 'uid'])

        const { errors, ...response } = await apiClient.rewardSettings.update(
          formValues.id,
          {
            rewardSetting: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IRewardSettingFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default RewardSettingForm
