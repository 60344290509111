import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetPhoneNumbersParams,
  IPhoneNumber,
} from '~/types/models/IPhoneNumber'
import { produce } from 'immer'

const useModifyPhoneNumbers = (params?: IGetPhoneNumbersParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'phoneNumbers',
      sessionUser?.id,
      buildGetUrl(apiClient.phoneNumbers.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addPhoneNumber = useCallback(
    (data: IPhoneNumber) => {
      queryClient.setQueryData<IPhoneNumber[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updatePhoneNumber = useCallback(
    (id: number, data: IPhoneNumber) => {
      queryClient.setQueryData<IPhoneNumber[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePhoneNumber = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPhoneNumber[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return { addPhoneNumber, updatePhoneNumber, removePhoneNumber }
}

export default useModifyPhoneNumbers
