import React from 'react'

const FinalSegment = ({
  adjustedSegments,
  finalSegment,
  finalSegmentLabel,
  isFinalized,
  styleFinalSegment,
  index,
}) => {
  return finalSegment ? (
    <>
      <div className='segment-container'>
        <div className={styleFinalSegment() + ' segment'}>
          <div className={styleFinalSegment() + ' segment-interior'}>
            <p className='segment-number'>{styleFinalSegment(true)}</p>
          </div>
        </div>
        <p
          className={`segment-label ${isFinalized ? 'completed' : ''}`}
          style={{ marginLeft: isFinalized ? -12 : -6 }}
        >
          {finalSegmentLabel}
        </p>
      </div>
    </>
  ) : (
    <div className='segment-container'>
      <div className='segment'>
        <div
          className={`segment-interior ${
            adjustedSegments.length > index + 1 ? 'gray' : 'inverse'
          }`}
        >
          <p
            className={`segment-number ${
              adjustedSegments.length > index + 1 ? '' : 'inverse'
            }`}
          >
            ?
          </p>
        </div>
      </div>
      <p
        className={`segment-label ${isFinalized ? 'completed' : ''}`}
        style={{ marginLeft: isFinalized ? 0 : -8 }}
      >
        {finalSegmentLabel}
      </p>
    </div>
  )
}

export default FinalSegment
