import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IWorkTypeFormProps } from './type'
import { IWorkTypeFormValues } from '~/types/models/IWorkType'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'

const WorkTypeForm = (props: IWorkTypeFormProps) => {
  const { afterCreate, afterUpdate, formData, className, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Code',
        name: 'code',
        isRequired: true,
      },
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
      },
    ],
    [],
  )

  const defaultValues: IWorkTypeFormValues = {
    code: '',
    name: '',
    companyId: currentCompany.id,
  }

  const schema = useMemo(
    () =>
      Yup.object({
        code: Yup.string().required('This field is required'),
        name: Yup.string().required('This field is required'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IWorkTypeFormValues) => {
      const payload = _.pick(formValues, ['code', 'name', 'companyId'])
      const { errors, ...response } = await apiClient.workTypes.create({
        workType: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IWorkTypeFormValues) => {
      if (formData?.id) {
        const payload = _.pick(formValues, ['code', 'name'])
        const { errors, ...response } = await apiClient.workTypes.update(
          formData.id,
          {
            workType: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IWorkTypeFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <ConcordFormStructure
        className={className}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
        {...formProps}
      />
    </div>
  )
}

export default WorkTypeForm
