import { useCallback, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormStructure,
  ConcordRadioWithFormControl,
  ConcordTextFieldWithFormControl,
  IConcordFormField,
} from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ISettingFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { useQueryRetrieveSettingValues } from '~/hooks/useQueryData'
import { ISettingFormValues } from '~/types/models/ISetting'

const SettingForm = (props: ISettingFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const {
    settingNewValuesData,
    settingFieldOptions,
    getSettingOptionsFromValues,
    getSettingFieldTypeFromValues,
  } = useQueryRetrieveSettingValues()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Setting Field',
        name: 'settingField',
        options: settingFieldOptions,
        type: EFieldType.singleSelect,
        isRequired: true,
        render({ control, name, label, setValue }) {
          return (
            <ConcordDropdownV2WithFormControl
              name={name}
              control={control}
              label={label}
              isRequired
              options={settingFieldOptions}
              onChange={() => {
                setValue('settingValue', null)
              }}
            />
          )
        },
      },
      {
        label: 'Setting Value',
        name: 'settingValue',
        render({ control, name, label, watch }) {
          const settingField = watch('settingField', '')
          const values = settingNewValuesData
            ? settingNewValuesData[_.camelCase(settingField)]
            : undefined
          if (values) {
            const type = getSettingFieldTypeFromValues(settingField, values)
            const options = getSettingOptionsFromValues(settingField, values)
            if (type === EFieldType.singleSelect) {
              return (
                <ConcordDropdownV2WithFormControl
                  name={name}
                  control={control}
                  label={label}
                  options={options}
                  isRequired
                />
              )
            }

            if (type === EFieldType.radio) {
              return (
                <ConcordRadioWithFormControl
                  control={control}
                  name={name}
                  label={label}
                  options={options}
                  isRequired
                />
              )
            }

            return (
              <ConcordTextFieldWithFormControl
                name={name}
                control={control}
                label={label}
                isRequired
              />
            )
          }
          return null
        },
      },
    ],
    [
      getSettingFieldTypeFromValues,
      getSettingOptionsFromValues,
      settingFieldOptions,
      settingNewValuesData,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        settingField: Yup.string().required('This field is required!'),
        settingValue: Yup.mixed().required('This field is required!'),
      }),
    [],
  )

  const defaultValues = useMemo<ISettingFormValues>(
    () => ({
      settingField: '',
      settingValue: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: ISettingFormValues) => {
      const { errors, ...response } = await apiClient.settings.create({
        setting: {
          settingField: formValues.settingField,
          settingValue: formValues.settingValue,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
      console.log('formValues', formValues)
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ISettingFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['settingField', 'settingValue'])
        const { errors, ...response } = await apiClient.settings.update(
          formValues.id,
          {
            setting: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: ISettingFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      error={error}
      isLoading={isLoading}
      ref={formRef}
      formData={formData}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default SettingForm
