import { useCallback, useMemo, useRef, useState } from 'react'

import {
  CloseIcon,
  ConcordFormLayout,
  ConcordFormStructure,
  IConcordFormField,
  PlusIcon,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { ISalesProposalFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  ISalesProposal,
  ISalesProposalFormData,
} from '~/types/models/ISalesProposal'
import {
  useQueryBuyerSellers,
  useQueryPriceEscalations,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { ESalesProposalStatus } from '~/types/enums/ESalesProposal'
import { useQuerySellerToBuyerTerminals } from '~/hooks/useQueryData/useQuerySellerToBuyerTerminals'
import { PriceEscalationCard } from './PriceEscalationCard'

const SalesProposalForm = (props: ISalesProposalFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isPriceEscalationCreating, setIsPriceEscalationCreating] =
    useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const {
    sellerCompanyOptionsWithCurrentCompany,
    buyerCompanyOptionsWithCurrentCompany,
  } = useQueryBuyerSellers()
  const { sellerTerminalOptions, buyerTerminalOptions } = useQueryTerminals()

  const { priceEscalationsData, addPriceEscalation, updatePriceEscalation } =
    useQueryPriceEscalations(
      {
        filters: {
          salesProposalId: formData?.id as number,
        },
      },
      // { enabled: Boolean(formData?.id) },
    )

  const { isSellerToBuyerTerminalsLoading, sellerToBuyerTerminalOptions } =
    useQuerySellerToBuyerTerminals({
      filters: {
        status: [
          'future',
          'imminent',
          'bid',
          'won',
          'active',
          'complete',
          'lost',
          'null',
        ] as any,
      },
      perPage: 1000,
    } as any)

  const fields: IConcordFormField[] = [
    {
      name: 'sellerToBuyerTerminalId',
      label: 'Sales Backlog',
      size: 12,
      isRequired: true,
      type: EFieldType.singleSelect,
      options: sellerToBuyerTerminalOptions,
      isLoading: isSellerToBuyerTerminalsLoading,
      onChange({ selectedOption }) {
        formRef.current?.setValue('senderCompanyId', selectedOption.seller?.id)
        formRef.current?.setValue(
          'sellerTerminalId',
          selectedOption.sellerTerminal?.id,
        )
        formRef.current?.setValue('buyerId', selectedOption.buyer?.id)
        formRef.current?.setValue(
          'buyerTerminalId',
          selectedOption.buyerTerminal?.id,
        )
      },
    },
    {
      name: 'senderCompanyId',
      label: 'Seller',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: sellerCompanyOptionsWithCurrentCompany,
    },
    {
      name: 'sellerTerminalId',
      label: 'Seller Terminal',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: sellerTerminalOptions,
    },
    {
      name: 'buyerId',
      label: 'Buyer',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: buyerCompanyOptionsWithCurrentCompany,
    },
    {
      name: 'buyerTerminalId',
      label: 'Buyer Terminal',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: buyerTerminalOptions,
    },
    {
      name: 'date',
      label: 'Date',
      type: EFieldType.date,
    },
    {
      label: '',
      name: 'priceEscalations',
      isHidden: !isUpdating,
      render() {
        return (
          <ConcordFormLayout
            label='Price Escalations'
            extraIcons={[
              {
                Icon: isPriceEscalationCreating ? (
                  <CloseIcon color='var(--ion-color-danger)' />
                ) : (
                  <PlusIcon color='var(--ion-color-concord)' />
                ),
                onClick() {
                  setIsPriceEscalationCreating(prev => !prev)
                },
              },
            ]}
          >
            <div>
              {isPriceEscalationCreating && (
                <div>
                  <PriceEscalationCard
                    salesProposal={formData as ISalesProposal}
                    afterCreate={data => {
                      addPriceEscalation(data)
                      setIsPriceEscalationCreating(false)
                    }}
                  />
                </div>
              )}

              {priceEscalationsData.map(item => (
                <PriceEscalationCard
                  key={item.id}
                  priceEscalation={item}
                  afterUpdate={data => {
                    updatePriceEscalation(data.id, data)
                  }}
                />
              ))}
            </div>
          </ConcordFormLayout>
        )
      },
    },
  ]

  const schema = useMemo(
    () =>
      Yup.object({
        sellerToBuyerTerminalId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
      }),
    [],
  )

  const defaultValues = useMemo<ISalesProposalFormData>(
    () => ({
      senderCompanyId: null,
      sellerTerminalId: null,
      buyerTerminalId: null,
      buyerId: null,
      status: ESalesProposalStatus.inProgress,
      date: null,
      sellerToBuyerTerminalId: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (payload: ISalesProposalFormData) => {
      const { errors, ...res } = await apiClient.salesProposals.create({
        ...payload,
        status: ESalesProposalStatus.inProgress,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: ISalesProposalFormData) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.salesProposals.update(
          formData.id,
          payload,
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: ISalesProposalFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default SalesProposalForm
