import { RTTableFooterCell } from './RTTableFooterCell'
import type {
  RTColumnVirtualizer,
  RTHeader,
  RTHeaderGroup,
  IReusableTableRowData,
  ReusableTableInstance,
  RTVirtualItem,
} from '../../types'

export interface IRTTableFooterRowProps<TData extends IReusableTableRowData> {
  columnVirtualizer?: RTColumnVirtualizer
  footerGroup: RTHeaderGroup<TData>
  table: ReusableTableInstance<TData>
}

export const RTTableFooterRow = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  footerGroup,
  table,
  ...rest
}: IRTTableFooterRowProps<TData>) => {
  const {
    options: { layoutMode },
  } = table

  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } =
    columnVirtualizer ?? {}

  // if no content in row, skip row
  if (
    !footerGroup.headers?.some(
      header =>
        (typeof header.column.columnDef.footer === 'string' &&
          !!header.column.columnDef.footer) ||
        header.column.columnDef.Footer,
    )
  ) {
    return null
  }

  return (
    <tr
      {...rest}
      style={{
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        position: 'relative',
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        background: 'white',
      }}
    >
      {virtualPaddingLeft ? (
        <th style={{ display: 'flex', width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? footerGroup.headers).map(
        (footerOrVirtualFooter, staticColumnIndex) => {
          let footer = footerOrVirtualFooter as RTHeader<TData>
          if (columnVirtualizer) {
            staticColumnIndex = (footerOrVirtualFooter as RTVirtualItem).index
            footer = footerGroup.headers[staticColumnIndex]
          }

          return footer ? (
            <RTTableFooterCell
              footer={footer}
              key={footer.id}
              staticColumnIndex={staticColumnIndex}
              table={table}
            />
          ) : null
        },
      )}
      {virtualPaddingRight ? (
        <th style={{ display: 'flex', width: virtualPaddingRight }} />
      ) : null}
    </tr>
  )
}
