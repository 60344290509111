import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'

import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import buildFullAddress from '~/utils/buildFullAddress'
import { ITerminal } from '~/types/models/ITerminal'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { ICompany } from '~/types/models/ICompany'
import { EScope } from '~/types/enums/ECommonEnum'

const useTableCompanyTerminalSubTab = () => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const { allCompaniesData } = useQueryBuyerSellers()

  const columns = useMemo<IRTColumnDef<ITerminal>[]>(
    () => [
      {
        header: 'Company',
        accessorKey: 'companyId',
        size: 250,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          let company: ICompany | undefined
          let companyType = EScope.fleet

          if (cellValue === currentCompany.id) {
            company = currentCompany
            companyType = currentScope
          } else {
            company = allCompaniesData.find(({ id }) => id === cellValue)
            companyType =
              currentScope === EScope.buyer ? EScope.seller : EScope.buyer
          }

          return (
            <CompanyInfo
              companyType={companyType}
              company={{
                name: company?.name,
                code: company?.code,
                value: company?.id,
                image: company?.logo,
                label: company
                  ? `${company.code} - ${company.name}`
                  : 'Unknown',
              }}
            />
          )
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 150,
        enableEditing: true,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        size: 150,
        enableEditing: true,
      },
      {
        header: 'Address',
        id: 'address',
        enableEditing: false,
        size: 250,
        accessorFn(rowData) {
          return buildFullAddress(rowData.address)
        },
      },
      {
        header: 'Time zone',
        accessorKey: 'timeZone',
        size: 120,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
      },
    ],
    [currentCompany, currentScope, allCompaniesData],
  )

  return { columns }
}

export default useTableCompanyTerminalSubTab
