import type { IKeyValuePairItemProps } from './type'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { useEffect, useMemo, useState } from 'react'
import { IonIcon } from '@ionic/react'
import {
  checkmarkCircle,
  closeCircle,
  closeCircleOutline,
} from 'ionicons/icons'
import { AutoWidthInput, DropdownWithCustomChildren } from '~/components/shared'
import { When } from 'react-if'
import clsx from 'clsx'

function KeyValuePairItem(props: IKeyValuePairItemProps) {
  const {
    label,
    value,
    name,
    type = EFieldType.text,
    options = [],
    onUpdate,
    width,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>('')
  const [isFocusing, setIsFocusing] = useState(false)

  const showingValue = useMemo(() => {
    if (type === EFieldType.text) {
      return formValue
    }
    if (type === EFieldType.checkbox) {
      return (
        <span
          className='icon'
          onDoubleClick={() => {
            onUpdate && onUpdate(name, !formValue)
            setFormValue(!formValue)
          }}
        >
          <IonIcon
            icon={formValue ? checkmarkCircle : closeCircle}
            color={formValue ? 'success' : 'danger'}
          />
        </span>
      )
    }
    if (type === EFieldType.singleSelect) {
      return options.find(opt => opt.value === formValue)?.label
    }
  }, [type, formValue, onUpdate, name, options])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = () => {
    onUpdate && onUpdate(name, formValue)
    setIsFocusing(false)
  }

  const renderValue = useMemo(() => {
    if (type === EFieldType.singleSelect) {
      return (
        <DropdownWithCustomChildren
          options={options}
          value={formValue}
          className='no-hover dropdownSelector'
          onChange={(event, { value }) => {
            setFormValue(value)
            onUpdate && onUpdate(name, value)
          }}
        >
          {showingValue || '-'}
        </DropdownWithCustomChildren>
      )
    }

    if (type === EFieldType.checkbox) {
      return showingValue
    }

    return (
      <div className='textInputContainer'>
        <AutoWidthInput
          value={formValue}
          className='textInput'
          minWidth={width || 100}
          type={type}
          onChange={event => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setFormValue((event.target as any).value)
          }}
          onFocus={() => {
            setIsFocusing(true)
          }}
        />
        <When condition={isFocusing}>
          <span className='textIcon icon' onClick={onSubmit}>
            <IonIcon icon={checkmarkCircle} color='success' />
          </span>
          <span
            className='textIcon icon'
            onClick={() => {
              setIsFocusing(false)
              setFormValue(value)
            }}
          >
            <IonIcon icon={closeCircleOutline} color='danger' />
          </span>
        </When>
      </div>
    )
  }, [
    type,
    formValue,
    isFocusing,
    onSubmit,
    options,
    showingValue,
    onUpdate,
    name,
    value,
    width,
  ])

  useEffect(() => {
    setFormValue(value)
  }, [value])

  return (
    <div className='KeyValuePairItem__container'>
      <span className='itemLabel'>{label}: </span>
      <span className={clsx('itemValue', type)}>{renderValue}</span>
    </div>
  )
}

export default KeyValuePairItem
