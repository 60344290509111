import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import {
  IGetSellerTerminalProduct,
  ISellerTerminalProduct,
} from '~/types/models/ISellerTerminalProduct'

const useModifySellerTerminalProducts = (params: IGetSellerTerminalProduct) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'sellerTerminalProducts',
      sessionUser?.id,
      buildGetUrl('/seller_terminal_products', params),
    ],
    [params, sessionUser?.id],
  )

  const addSellerTerminalProduct = useCallback(
    (newItem: ISellerTerminalProduct) => {
      queryClient.setQueryData<ISellerTerminalProduct[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [newItem, ...oldData]
          }

          return undefined
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateSellerTerminalProduct = useCallback(
    (id: number, item: Partial<ISellerTerminalProduct>) => {
      queryClient.setQueryData<ISellerTerminalProduct[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  ...item,
                }
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeSellerTerminalProduct = useCallback(
    (id: number) => {
      queryClient.setQueryData<ISellerTerminalProduct[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addSellerTerminalProduct,
    updateSellerTerminalProduct,
    removeSellerTerminalProduct,
  }
}

export default useModifySellerTerminalProducts
