import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IGetSensorParams, ISensor } from '~/types/models/ISensor'
import { toast } from 'react-toastify'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySensors = (
  params: IGetSensorParams,
  options?: Partial<UseQueryOptions<ISensor[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data } = useQuery({
    queryKey: [
      'sensors',
      sessionUser?.id,
      params.truckId,
      // buildGetUrl(apiClient.sensors.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.sensors.get(params)

      if (response.errors) {
        toast.error(response.errors)
        return []
      }
      return response
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  return {
    sensorsData: data || [],
  }
}

export default useQuerySensors
