import { useContext } from 'react'

import WorkerFormContext from './WorkerFormContext'

const useWorkerFormProvider = () => {
  const context = useContext(WorkerFormContext)

  return context
}

export default useWorkerFormProvider
