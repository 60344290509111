import React from 'react'
import { IonButton, IonLabel, IonIcon, IonRow, IonCol } from '@ionic/react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

const SubmitButton = ({
  label,
  labelProps,
  icon,
  iconProps,
  style,
  color,
  onClick,
  loading,
  type = true,
  disabled,
  isBillLine,
  classNameRow,
  ...buttonProps
}) => {
  return (
    <IonRow
      className={clsx('profileButton', classNameRow, {
        'bill-line-form-field-row': isBillLine,
      })}
    >
      <IonCol className={isBillLine ? 'bill-line-form-field-col' : ''}>
        <IonButton
          className={isBillLine ? 'bill-line-form-field-button' : ''}
          color={color}
          style={style}
          onClick={onClick}
          expand='block'
          type={type ? 'submit' : 'button'}
          fill='solid'
          disabled={loading || disabled}
          {...buttonProps}
        >
          {icon && <IonIcon icon={icon} {...iconProps} />}
          <IonLabel style={{ marginLeft: icon ? 5 : 0 }} {...labelProps}>
            {loading ? 'In Progress' : label}
          </IonLabel>
        </IonButton>
      </IonCol>
    </IonRow>
  )
}

SubmitButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'seller',
    'buyer',
    'fleet',
    'orange',
    'crimson',
  ]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.bool,
  disabled: PropTypes.bool,
  isBillLine: PropTypes.bool,
}

SubmitButton.defaultProps = {
  label: 'Upload',
  color: 'secondary',
}

export default SubmitButton
