import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePusher from './usePusher'

import { selectSessionUser } from '~/redux/selectors'
import { USER_SCOPE } from '../../utils/constants'
import {
  updateUINewMessage,
  updateUIUnreadCountMessage,
} from '~/redux/actions/chatActions'
import { selectWorkerId, selectMyCurrentCompany, selectCurrentScope } from '~/redux/selectors'

const usePusherChat = () => {
  const user = useSelector(selectSessionUser)
  const workerId = useSelector(selectWorkerId)
  const currentCompany = useSelector(selectMyCurrentCompany)
  const currentScope = useSelector(selectCurrentScope)

  const dispatch = useDispatch()

  const channelName = useMemo(() => {
    let channelName = ''
    switch (currentScope) {
      case USER_SCOPE.fleet: {
        channelName = `chat_company_${currentCompany.id}`
        break
      }
      case USER_SCOPE.driver:
      case USER_SCOPE.driver_fleet: {
        channelName = `chat_worker_${workerId}`
        break
      }
      default: {
        channelName = undefined
      }
    }

    return channelName
  }, [currentCompany, currentScope, workerId])

  const handleNewMessageEvent = useCallback(
    data => {
      if (data?.message?.userId != user.id) {
        dispatch(updateUINewMessage(data.message))
        dispatch(updateUIUnreadCountMessage(1))
      }
    },
    [dispatch, user.id],
  )

  const events = useMemo(
    () => [
      {
        name: 'new_msg',
        handler: handleNewMessageEvent,
      },
    ],
    [handleNewMessageEvent],
  )

  usePusher({ channelName, events })

  return {}
}

export default usePusherChat
