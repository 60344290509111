import { useController } from 'react-hook-form'

import { ConcordFormDropdown } from '~/components/shared'

function ConcordDropdownWithFormControl(props) {
  const { name, control, isMulti, ...dropdownProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  return (
    <ConcordFormDropdown
      {...dropdownProps}
      name={name}
      value={field.value}
      isMulti={isMulti}
      onChange={selectedOption => {
        if (isMulti) {
          const values = selectedOption.map(({ value }) => value)
          field.onChange(values)
        } else {
          field.onChange(selectedOption.value)
        }
      }}
      error={fieldState.error?.message}
    />
  )
}

ConcordDropdownWithFormControl.propTypes = {}

export default ConcordDropdownWithFormControl
