import { useMemo } from 'react'

import { IonText } from '@ionic/react'
import { CompanyAvatar, ToolTipOverlay } from '~/components/shared'

import { USER_STATUSES } from '~/utils/constants'
import buildFullName from '~/utils/buildFullName'
import moment from 'moment'
import { useQueryTerminals, useQueryUserGroups } from '~/hooks/useQueryData'
import { formatPhoneNumber } from '~/utils/utils'

const useTableUsersCompanyColumns = () => {
  const { findUserGroupById } = useQueryUserGroups()
  const { findTerminalById } = useQueryTerminals()

  const columns = useMemo(
    () => [
      {
        header: 'Profile',
        id: 'profile',
        size: 300,
        accessorFn(rowData) {
          return buildFullName(rowData?.person)
        },
        Cell({ row, renderedCellValue }) {
          return (
            <div className='CompanyUserSubTab__profileContainer'>
              <CompanyAvatar
                company={{
                  value: row.original.id,
                  name: renderedCellValue,
                }}
              />
              <div className='CompanyUserSubTab__profileInfo'>
                <span>{renderedCellValue}</span>
                <span>
                  <a href={`mailto:${row.original.email}`}>
                    {row.original.email}
                  </a>
                </span>
              </div>
            </div>
          )
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phoneNumber',
        size: 130,
        Cell({ cell }) {
          const cellValue = cell.getValue()
          if (cellValue) {
            return formatPhoneNumber(cellValue)
          }
          return '-'
        },
        // editVariant: EFieldType.number,
        // editNumberFieldProps: () => ({
        //   format: '+1 (###) ### ####',
        //   allowEmptyFormatting: true,
        //   mask: '_',
        // }),
      },
      {
        header: 'User Group',
        id: 'userAccess.userGroupId',
        accessorFn(row) {
          return findUserGroupById(row.userAccess.userGroupId)?.name
        },
        Cell({ renderedCellValue }) {
          return renderedCellValue
        },
      },
      {
        header: 'Status',
        accessorKey: 'userAccess.status',
        size: 100,
        Cell({ cell }) {
          return (
            <IonText color={USER_STATUSES[cell.getValue()]}>
              {cell.getValue()}
            </IonText>
          )
        },
      },
      {
        header: 'Terminal',
        accessorKey: 'worker.terminalId',
        maxSize: 125,
        Cell({ cell }) {
          const cellValue = cell.getValue()
          const terminal = findTerminalById(cellValue)
          if (terminal) {
            return (
              <ToolTipOverlay placement='top' content={terminal.name}>
                <div>{terminal.code}</div>
              </ToolTipOverlay>
            )
          }
          return '-'
        },
      },
      {
        header: 'Default',
        accessorKey: 'userAccess.default',
        size: 100,
      },
      {
        header: 'Title',
        id: 'title',
        size: 80,
        accessorFn(rowData) {
          if (rowData.worker) {
            return rowData.worker.title
          }

          return ''
        },
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        Cell({ cell }) {
          const cellValue = cell.getValue()
          return moment(cellValue).format('lll')
        },
      },
    ],
    [findTerminalById, findUserGroupById],
  )

  return { columns }
}

export default useTableUsersCompanyColumns
