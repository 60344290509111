import { components } from 'react-select'

import { CompanyAvatar, EditIcon } from '~/components/shared'
import { useQueryCompanies } from '~/hooks/useQueryData'
import { EScope } from '~/types/enums/ECommonEnum'

function CustomTerminalOption(props) {
  const { data, selectProps, isSelected } = props
  const { showUpdateButtons, onClickEdit } = selectProps

  const { findCompanyById } = useQueryCompanies({})

  const company = findCompanyById(data.companyId)

  return (
    <components.Option
      {...props}
      className='d-flex align-items-center justify-content-space-between p-2'
    >
      <div className='d-flex align-items-center' style={{ width: '100%' }}>
        <CompanyAvatar
          searchableGoogle={false}
          hideAnchor
          company={company}
          companyType={EScope.fleet}
        />
        <div style={{ marginLeft: 4 }}>
          <div style={{ marginBottom: 2 }}>{data.name}</div>
          <div>{data.code}</div>
        </div>
      </div>

      {showUpdateButtons && (
        <div>
          {showUpdateButtons && (
            <span
              style={{ marginLeft: 6, cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation()
                onClickEdit && onClickEdit(data)
              }}
            >
              <EditIcon
                size={14}
                color={
                  isSelected ? 'var(--bs-white)' : 'var(--ion-color-concord)'
                }
              />
            </span>
          )}
        </div>
      )}
    </components.Option>
  )
}

export default CustomTerminalOption
