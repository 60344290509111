import { useCallback, useMemo, useRef, useState } from 'react'
import {
  useQueryCalculations,
  useQueryRewardSettings,
} from '~/hooks/useQueryData'

import {
  CalculationForm,
  ConcordFormStructure,
  IConcordFormField,
  RewardSettingForm,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ICampaignFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICampaignFormValues } from '~/types/models/ICampaign'
import moment from 'moment'
import {
  CAMPAIGN_CADENCE_OPTIONS,
  CAMPAIGN_MIN_MAX_OPTIONS,
  CAMPAIGN_TYPE_OPTIONS,
} from '~/utils/constants'
import { addCircleOutline, closeCircle } from 'ionicons/icons'

import './styles.scss'

const CampaignForm = (props: ICampaignFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingCalculation, setIsCreatingCalculation] = useState(false)
  const [isCreatingRewardSetting, setIsCreatingRewardSetting] = useState(false)

  const { rewardSettingOptions, addRewardSetting } = useQueryRewardSettings()
  const { calculationOptions, addCalculation } = useQueryCalculations()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
        size: 6,
      },
      {
        label: 'Min max',
        name: 'minMax',
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        options: CAMPAIGN_MIN_MAX_OPTIONS,
      },
      {
        label: 'Date',
        name: 'dateRange',
        type: EFieldType.dateRange,
        size: 12,
        isRequired: true,
      },
      {
        label: 'Campaign Type',
        name: 'campaignType',
        type: EFieldType.singleSelect,
        options: CAMPAIGN_TYPE_OPTIONS,
        size: 6,
        isRequired: true,
      },
      {
        label: 'Campaign Cadence',
        name: 'campaignCadence',
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        options: CAMPAIGN_CADENCE_OPTIONS,
      },
      {
        label: 'Calculation',
        name: 'calculationId',
        isRequired: true,
        options: calculationOptions,
        type: EFieldType.singleSelect,
        size: 6,
        extraIcons: [
          {
            icon: isCreatingCalculation ? closeCircle : addCircleOutline,
            color: isCreatingCalculation ? 'danger' : 'concord',
            onClick() {
              setIsCreatingCalculation(prev => !prev)
            },
          },
        ],
      },
      {
        label: 'Reward Setting',
        name: 'rewardSettingId',
        isRequired: true,
        options: rewardSettingOptions,
        type: EFieldType.singleSelect,
        size: 6,
        extraIcons: [
          {
            icon: isCreatingRewardSetting ? closeCircle : addCircleOutline,
            color: isCreatingRewardSetting ? 'danger' : 'concord',
            onClick() {
              setIsCreatingRewardSetting(prev => !prev)
            },
          },
        ],
      },
      {
        name: 'rewardSettingForm',
        render({ setValue }) {
          return (
            <RewardSettingForm
              className='CampaignForm__container'
              afterCreate={newItem => {
                addRewardSetting(newItem)
                setValue('rewardSettingId', newItem.id)
                setIsCreatingRewardSetting(false)
              }}
            />
          )
        },
        isHidden: !isCreatingRewardSetting,
      },
      {
        name: 'calculationForm',
        render({ setValue }) {
          return (
            <CalculationForm
              className='CampaignForm__container'
              afterCreate={newItem => {
                addCalculation(newItem)
                setValue('calculationId', newItem.id)
                setIsCreatingCalculation(false)
              }}
            />
          )
        },
        isHidden: !isCreatingCalculation,
      },
    ],
    [
      addCalculation,
      addRewardSetting,
      calculationOptions,
      isCreatingCalculation,
      isCreatingRewardSetting,
      rewardSettingOptions,
    ],
  )

  const defaultValues = useMemo<ICampaignFormValues>(
    () => ({
      name: '',
      campaignType: null,
      campaignCadence: null,
      dateRange: {
        startDate: new Date(),
        endDate: moment().add({ days: 1 }).toDate(),
      },
      calculationId: null,
      minMax: null,
      rewardSettingId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        campaignType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        campaignCadence: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        minMax: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        calculationId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        rewardSettingId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: ICampaignFormValues) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload: any = _.pick(formValues, [
        'name',
        'campaignType',
        'campaignCadence',
        'calculationId',
        'minMax',
        'rewardSettingId',
      ])
      payload.startDate = moment(formValues.dateRange.startDate).format(
        'YYYY-MM-DD',
      )
      payload.endDate = moment(formValues.dateRange.endDate).format(
        'YYYY-MM-DD',
      )
      const { errors, ...response } = await apiClient.campaigns.create({
        campaign: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ICampaignFormValues) => {
      if (formValues.id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: any = _.pick(formValues, [
          'name',
          'campaignType',
          'campaignCadence',
          'calculationId',
          'minMax',
          'rewardSettingId',
        ])
        payload.startDate = moment(formValues.dateRange.startDate).format(
          'YYYY-MM-DD',
        )
        payload.endDate = moment(formValues.dateRange.endDate).format(
          'YYYY-MM-DD',
        )

        const { errors, ...response } = await apiClient.campaigns.update(
          formValues.id,
          {
            campaign: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: ICampaignFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default CampaignForm
