import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const KeyboardIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        d='M10 13a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4ZM17 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM6 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM10 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM13 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM18 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z'
      />
      <path
        fill={color}
        fillRule='evenodd'
        d='M20 5a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h16Zm0 2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h16Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default KeyboardIcon
