import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import {
  ConcordFormDropdownV2,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import {
  useQueryBuyerSellers,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { FIXED_PRICE_ITEM_TYPE_OPTIONS } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IFixedPriceFormProps } from './type'
import { IFixedPriceFormData } from '~/types/models/IFixedPrice'
import { EFixedPrice } from '~/types/enums/EFixedPrice'
import moment from 'moment'

function FixedPriceForm(props: IFixedPriceFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [sellerIdSelected, setSellerIdSelected] = useState<number | null>(null)

  const { sellerCompanyOptions, isLoadingBuyerSellers, allCompaniesData } =
    useQueryBuyerSellers()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const formRef = useRef<any>()

  const {
    sellerTerminalsData,
    isLoadingTerminals,
    buyerTerminalOptions,
    isFetchedTerminals,
  } = useQueryTerminals()

  const { sellerProductOptions, isLoadingSellerProducts } =
    useQuerySellerProducts(
      {
        filters: {
          sellerId: sellerIdSelected as number,
        },
      },
      { enabled: Boolean(sellerIdSelected) },
    )

  const isUpdating = Boolean(formData?.id)

  const sellerOptions = useMemo(() => {
    if (currentScope === EScope.seller) {
      return [
        {
          label: `${currentCompany.code} - ${currentCompany.name}`,
          value: currentCompany.id,
        },
        ...sellerCompanyOptions,
      ]
    }
    return sellerCompanyOptions
  }, [
    currentCompany.code,
    currentCompany.id,
    currentCompany.name,
    currentScope,
    sellerCompanyOptions,
  ])

  const sellerTerminalOptions = useMemo(() => {
    if (sellerIdSelected) {
      return sellerTerminalsData
        .filter(({ companyId }) => companyId === sellerIdSelected)
        .map(({ code, name, id }) => ({
          value: id,
          label: `${code} - ${name}`,
        }))
    }
    return []
  }, [sellerIdSelected, sellerTerminalsData])

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Seller',
        name: 'sellerId',
        type: EFieldType.custom,
        isRequired: true,
        options: sellerOptions,
        isLoading: isLoadingBuyerSellers,
        render({ name, label, setValue }) {
          return (
            <ConcordFormDropdownV2
              isRequired
              options={sellerOptions}
              name={name}
              label={label}
              value={sellerIdSelected as number}
              isLoading={isLoadingBuyerSellers}
              onChange={(event, { value }) => {
                setSellerIdSelected(value)
                setValue('sellerTerminalId', null)
                setValue('pricedProductId', null)
              }}
            />
          )
        },
      },
      {
        label: 'Seller Terminal',
        name: 'sellerTerminalId',
        type: EFieldType.singleSelect,
        options: sellerTerminalOptions,
        isLoading: isLoadingTerminals,
        isRequired: true,
        isDisabled: !sellerIdSelected,
        hint() {
          if (!sellerIdSelected) {
            return 'Select Seller first'
          }
        },
      },
      {
        label: 'Product',
        name: 'pricedProductId',
        type: EFieldType.singleSelect,
        options: sellerProductOptions,
        isLoading: isLoadingSellerProducts,
        isRequired: true,
        isDisabled: !sellerIdSelected,
        hint() {
          if (!sellerIdSelected) {
            return 'Select Seller first'
          }
        },
      },
      {
        label: 'Item Type',
        name: 'itemType',
        type: EFieldType.singleSelect,
        options: FIXED_PRICE_ITEM_TYPE_OPTIONS,
        isRequired: true,
        render({ label, name, setValue, watch }) {
          return (
            <ConcordFormDropdownV2
              label={label}
              name={name}
              options={FIXED_PRICE_ITEM_TYPE_OPTIONS}
              isRequired
              value={watch('itemType', null)}
              onChange={(event, { value }) => {
                setValue('itemType', value)
                setValue('buyerTerminalId', null)
              }}
            />
          )
        },
      },
      {
        label: 'Delivered To',
        name: 'buyerTerminalId',
        options: buyerTerminalOptions,
        type: EFieldType.singleSelect,
        isRequired: true,
        isHidden: ({ watch }) => watch('itemType') !== EFixedPrice.materials,
      },
      {
        label: 'Price',
        name: 'price',
        type: EFieldType.number,
        isRequired: true,
        size: 4,
      },
      {
        label: 'Flat Rate',
        name: 'flatRate',
        type: EFieldType.number,
        size: 4,
      },
      {
        label: 'Discount',
        name: 'discount',
        type: EFieldType.number,
        size: 4,
      },
      {
        label: 'Date',
        name: 'date',
        type: EFieldType.dateRange,
        size: 12,
      },
    ],
    [
      buyerTerminalOptions,
      isLoadingBuyerSellers,
      isLoadingSellerProducts,
      isLoadingTerminals,
      sellerIdSelected,
      sellerOptions,
      sellerProductOptions,
      sellerTerminalOptions,
    ],
  )

  const defaultValues: IFixedPriceFormData = {
    sellerTerminalId: null,
    pricedProductId: null,
    itemType: null,
    buyerTerminalId: null,
    price: null,
    flatRate: null,
    discount: null,
  }

  const schema = Yup.object({
    sellerTerminalId: Yup.number()
      .required('This field is required')
      .typeError('This field is required'),
    pricedProductId: Yup.number()
      .required('This field is required')
      .typeError('This field is required'),
    itemType: Yup.number()
      .required('This field is required')
      .typeError('This field is required'),
    buyerTerminalId: Yup.number()
      .nullable()
      .when('itemType', ([itemType], schema) => {
        if (itemType === EFixedPrice.materials) {
          return Yup.number()
            .required('This field is required')
            .typeError('This field is required')
        }
        return schema
      }),
    price: Yup.number()
      .min(0)
      .required('This field is required')
      .typeError('This field is required'),
    flatRate: Yup.number()
      .min(0)
      .nullable()
      .transform(num => {
        if (isNaN(num)) {
          return null
        }
        return num
      }),
    discount: Yup.number()
      .min(0)
      .nullable()
      .transform(num => {
        if (isNaN(num)) {
          return null
        }
        return num
      }),
  })

  const create = async (formValues: IFixedPriceFormData) => {
    const response = await apiClient.fixedPrices.create(formValues)
    const errorKeys: any = Object.keys(response.errors || {})
    if (errorKeys.length > 0) {
      const message = response.errors[errorKeys[0]][0]
      const key = errorKeys[0]
      setBackendError(`${_.startCase(key)}: ${message}`)
    } else {
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const update = async (formValues: IFixedPriceFormData) => {
    const response = await apiClient.fixedPrices.update(
      formData?.id as number,
      formValues,
    )
    const errorKeys: any = Object.keys(response.errors)
    if (errorKeys.length > 0) {
      const message = response.errors[errorKeys[0]][0]
      const key = errorKeys[0]
      setBackendError(`${_.startCase(key)}: ${message}`)
    } else {
      afterUpdate && afterUpdate(response)
      toast.success(toastMessages.updateSuccess)
    }
  }

  const onSubmitForm = async (formValues: IFixedPriceFormData) => {
    setIsLoading(true)
    setBackendError('')
    const payload: IFixedPriceFormData = _.pick(formValues, [
      'sellerTerminalId',
      'pricedProductId',
      'itemType',
      'buyerTerminalId',
      'price',
      'flatRate',
      'discount',
    ])
    if (formValues.date) {
      payload.startDate = moment(formValues.date.startDate).format('YYYY-MM-DD')
      payload.endDate = moment(formValues.date.endDate).format('YYYY-MM-DD')
    }
    try {
      if (isUpdating) {
        await update(payload)
      } else {
        await create(payload)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isUpdating) {
      if (isFetchedTerminals) {
        const terminal = sellerTerminalsData.find(
          ({ id }) => id === formData?.sellerTerminalId,
        )
        const seller =
          currentScope === EScope.seller
            ? currentCompany
            : allCompaniesData.find(({ id }) => id === terminal?.companyId)

        setSellerIdSelected(seller?.id as number)
      }
      const itemType = EFixedPrice[(formData as any)?.itemType]

      if (typeof itemType === 'number') {
        const itemTypeVal = FIXED_PRICE_ITEM_TYPE_OPTIONS.find(
          ({ value }) => value === itemType,
        )?.value
        formRef.current?.setValue('itemType', itemTypeVal)
      }

      if (formData?.startDate && formData?.endDate) {
        formRef.current?.setValue('date', {
          startDate: formData?.startDate,
          endDate: formData?.endDate,
        })
      }
    } else if (currentScope === EScope.seller) {
      setSellerIdSelected(currentCompany.id)
    }
  }, [
    allCompaniesData,
    currentCompany,
    currentCompany.id,
    currentScope,
    formData,
    formData?.sellerTerminalId,
    isFetchedTerminals,
    isUpdating,
    sellerOptions,
    sellerTerminalsData,
  ])

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      onSubmit={onSubmitForm}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      ref={formRef}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default FixedPriceForm
