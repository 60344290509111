import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import usePusherDriver from '~/hooks/usePusher/usePusherDriver'
import usePusherChat from '~/hooks/usePusher/usePusherChat'
import useFetchDriverProfile from '~/hooks/useFetchDriverProfile'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { getPlatforms, IonApp, setupIonicReact } from '@ionic/react'

import { Route, Switch } from 'react-router-dom'
import { SplashScreen } from '@capacitor/splash-screen'
import RouteFromPath from '../components/routes/RouteFromPath'
import { MainPage } from '~/components'
import {
  ModalDownloadProgressProvider,
  ProviderComposer,
} from '~/components/shared'
import {
  WorkerFormProvider,
  ConfirmationProvider,
  TruckFormProvider,
  LoadFormProvider,
  OrderFormProvider,
  CompanyViewProvider,
  PricingFormProvider,
} from '~/contexts'
import { UseShowingTagModalProvider } from '~/hooks/useShowingTagModal'
import { UseShowingPdfViewerProvider } from '~/hooks/useShowingPdfViewer'
import { UseBrowserBackStackProvider } from '~/hooks/useBrowserBackStack'
import { DialogCreateEditCompanyProvider } from '~/hooks/useDialogCreateEditCompany'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { getSessionState } from '~/redux/selectors'
import { provider } from '~/components/shared/ProviderComposer/'
import { QueryClient, QueryClientProvider } from 'react-query'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { Provider, ErrorBoundary } from '@rollbar/react'

import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'

///* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

///* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

///* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import 'react-datepicker/dist/react-datepicker.css'

import '~/styles/home.scss'
import '~/styles/styles.scss'
import '../themes/app/global.scss'
import { EStatus } from '~/types/enums/ECommonEnum'
import NotFoundContainer from '~/containers/NotFoundContainer'

setupIonicReact()

SplashScreen.hide()
Chart.register(CategoryScale)

const queryClient = new QueryClient()

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
  captureUncaught: process.env.REACT_APP_ROLLBAR_TOKEN ? true : false,
  captureUnhandledRejections: process.env.REACT_APP_ROLLBAR_TOKEN
    ? true
    : false,
}

const App = () => {
  usePusherDriver()
  usePusherChat()
  useFetchDriverProfile()

  const { publicRoutes, commonPrivateRoutes, allPrivateMenuOptions } =
    useNavigatedMenuItems()

  const sessionState = useSelector(getSessionState)

  const renderRoutes = useCallback(
    () =>
      [...commonPrivateRoutes, ...allPrivateMenuOptions].map(route => (
        <RouteFromPath
          {...route}
          key={route.path}
          authenticated={sessionState?.authenticated}
        />
      )),
    [allPrivateMenuOptions, commonPrivateRoutes, sessionState?.authenticated],
  )

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.checkhq.com/component-initialize.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const platforms = getPlatforms()

    console.log('platforms', platforms)
  }, [])

  // const ldClient = useLDClient()
  // useEffect(() => {
  //   ldClient &&
  //     sessionState.user &&
  //     ldClient.identify(
  //       {
  //         kind: 'user',
  //         key: sessionState.user?.id,
  //         name: sessionState.user?.name,
  //         email: sessionState.user?.email,
  //         environment: process.env.NODE_ENV,
  //       },
  //       null,
  //       console.log('NEW USER IDENTIFIED'),
  //     )
  // }, [sessionState.user, ldClient])

  useEffect(() => {
    if (
      window.location.pathname !== '/sign_up' &&
      sessionState.user?.userAccess?.status === EStatus.Pending
    ) {
      window.location.href = '/sign_up'
    }
  }, [sessionState.user?.userAccess?.status])

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ProviderComposer
          providers={[
            provider(DndProvider, { backend: HTML5Backend }),
            provider(UseBrowserBackStackProvider),
            provider(QueryClientProvider, { client: queryClient }),
            provider(ConfirmationProvider),
            provider(ModalDownloadProgressProvider),
            provider(UseShowingTagModalProvider),
            provider(UseShowingPdfViewerProvider),
            provider(DialogCreateEditCompanyProvider),
            provider(WorkerFormProvider),
            provider(TruckFormProvider),
            provider(LoadFormProvider),
            provider(OrderFormProvider),
            provider(CompanyViewProvider),
            provider(PricingFormProvider),
          ]}
        >
          <IonApp>
            <Switch>
              {publicRoutes.map((route, index) => (
                <RouteFromPath
                  key={`route${index}`}
                  {...route}
                  authenticated={sessionState?.authenticated}
                />
              ))}
              <MainPage className='' loading={sessionState.loading}>
                {!sessionState.loading && sessionState?.checked && (
                  <Switch>
                    {renderRoutes()}
                    <Route path='*' component={NotFoundContainer} />
                  </Switch>
                )}
              </MainPage>
            </Switch>
          </IonApp>
        </ProviderComposer>
      </ErrorBoundary>
    </Provider>
  )
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App)
