import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productsUiSlice from '~/redux/reducers/ui/products'
import { apiClient } from '~/api/ApiClient'

export const actFetchProducts = createAsyncThunk(
  'fetchProducts',
  async (_params, { dispatch }) => {
    dispatch(productsUiSlice.actions.setTableLoading(true))
    try {
      const { products } = await apiClient.products.get()
      dispatch(productsSlice.actions.initializeProducts({ products }))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(productsUiSlice.actions.setTableLoading(false))
    }
  },
)

export const actDeleteProduct = createAsyncThunk(
  'deleteProduct',
  async (product, { dispatch }) => {
    dispatch(productsUiSlice.actions.toggleTableLoadingRowModel(product.id))
    try {
      const response = await apiClient.products.delete(product.id)
      if (response.id) {
        dispatch(productsSlice.actions.deleteProduct(response.id))
        toast.success(toastMessages.deleteSuccess)
      } else {
        toast.error(toastMessages.deleteError)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(productsUiSlice.actions.toggleTableLoadingRowModel(product.id))
    }
  },
)

const productsSlice = createSlice({
  name: 'products',
  initialState: {},
  reducers: {
    initializeProducts: (state, action) => {
      const newState = {}
      action.payload.products.forEach(product => {
        newState[product.id] = product
      })

      return newState
    },
    addProduct(state, { payload }) {
      state[payload.id] = payload
    },
    updateProducts: (state, action) => {
      action.payload.products.forEach(product => {
        if (state[product.id]) {
          state[product.id] = product
          if (product.deletedAt !== null) delete state[product.id]
        }
      })
    },
    updateProduct: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
      }
    },
    deleteProduct: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export default productsSlice
