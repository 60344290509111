import { useState, useCallback, useMemo } from 'react'

import {
  IonIcon,
  IonButtons,
  IonButton,
  IonPopover,
  IonLabel,
  IonList,
  IonItem,
} from '@ionic/react'
import { components as ReactSelectComponents } from 'react-select'
import {
  trashBinOutline,
  createOutline,
  ellipsisVerticalOutline,
} from 'ionicons/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSellersFromBuyerSellers,
  selectSellerCompanies,
} from '~/redux/selectors'
import { fetcheditBuyerSeller } from '~/redux/actions/myCompanyActions'
import { deleteBuyerSeller as deleteBuyerSellerAction } from '~/redux/actions/CommonData/buyerSellerActions'
import { updateCompany } from '~/redux/actions/CommonData/companiesActions'
import { toast } from 'react-toastify'

import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

// import './TerminalCustomOption.scss'

export const CompanyCustomOption = props => {
  const { data } = props
  const [popover, setPopover] = useState({ show: false, event: null })

  const companies = useSelector(selectSellerCompanies)
  const buyerSellers = useSelector(selectSellersFromBuyerSellers)

  const dispatch = useDispatch()

  const company = useMemo(() => {
    return companies.find(c => c.id == data.value)
  }, [companies, data.value])

  const buyerSeller = useMemo(() => {
    const bs = buyerSellers.find(bs => bs.sellerId == data.value)
    if (!bs) return null
    return {
      id: bs.id,
      companyId: bs.seller.id,
      buyerCode: bs.buyer.code,
      buyerName: bs.buyer.name,
      sellerCode: bs.seller.code,
      sellerName: bs.seller.name,
      seller: bs.seller.id,
      buyer: bs.buyer.id,
      buyerStatus: bs.buyerStatus,
      sellerStatus: bs.sellerStatus,
      dueInDays: bs.dueInDays,
      dueInMonths: bs.dueInMonths,
      dueAtEndOfMonth: bs.dueAtEndOfMonth,
      autoSendToHitl: bs.autoSendToHitl,
      invoiceInterval: bs.invoiceInterval,
    }
  }, [buyerSellers, data.value])

  const editBuyerSeller = useCallback(() => {
    if (buyerSeller) dispatch(fetcheditBuyerSeller(buyerSeller))
    setPopover({ show: false })
  }, [buyerSeller, dispatch])

  const deleteBuyerSeller = useCallback(async () => {
    if (!buyerSeller) return
    apiClient.buyerSellers
      .delete(buyerSeller.id)
      .then(res => {
        dispatch(deleteBuyerSellerAction(res.id))
      })
      .catch(() => {
        toast.error(toastMessages.deleteError)
      })

    setPopover({ show: false })
  }, [buyerSeller, dispatch])

  const editCompany = useCallback(() => {
    setPopover({ show: false })
  }, [])

  const deleteCompany = useCallback(async () => {
    await apiClient.companies
      .delete(company.id)
      .then(res => {
        dispatch(updateCompany(res))
        dispatch(deleteBuyerSellerAction(res.id))
        toast.success(toastMessages.deleteSuccess)
      })
      .catch(err => {
        console.log('error', err)
      })

    setPopover({ show: false })
  }, [dispatch, company?.id])

  return (
    <>
      <div className='TerminalCustomOption__root'>
        <ReactSelectComponents.Option {...props} />
        <div className='TerminalCustomOption__icons'>
          <IonButtons>
            <IonButton onClick={e => setPopover({ show: true, event: e })}>
              <IonIcon icon={ellipsisVerticalOutline} color='primary' />
            </IonButton>
          </IonButtons>

          <IonPopover
            mode='ios'
            isOpen={popover.show}
            event={popover.event}
            onDidDismiss={() => setPopover({ show: false })}
          >
            <IonList>
              <IonItem
                button
                lines='none'
                onClick={() => editBuyerSeller()}
                style={{ fontSize: 13 }}
              >
                <IonIcon slot='start' icon={createOutline} color='primary' />
                <IonLabel color='dark'> Edit Buyer Seller</IonLabel>
              </IonItem>
              <IonItem
                expand='block'
                button
                lines='none'
                style={{ fontSize: 13 }}
                onClick={() => deleteBuyerSeller()}
              >
                <IonIcon slot='start' icon={trashBinOutline} color='danger' />
                <IonLabel color='dark'> Delete Buyer Seller</IonLabel>
              </IonItem>
              <IonItem
                expand='block'
                button
                lines='none'
                style={{ fontSize: 13 }}
                onClick={() => editCompany()}
              >
                <IonIcon slot='start' icon={createOutline} color='primary' />
                <IonLabel color='dark'> Edit Company</IonLabel>
              </IonItem>
              <IonItem
                expand='block'
                button
                lines='none'
                style={{ fontSize: 13 }}
                onClick={() => deleteCompany()}
              >
                <IonIcon slot='start' icon={trashBinOutline} color='danger' />
                <IonLabel color='dark'> Delete Company</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>
        </div>
      </div>
    </>
  )
}
CompanyCustomOption.propTypes = {}
