import _ from 'lodash'

// eslint-disable-next-line no-useless-escape
const REGEX = /\.([0-9a-z]+)(?:[\?#]|$)/i

/**
 * @param {string} filename
 */
const getExtension = filename => {
  return filename.match(REGEX)
}

export const isPdf = filename => {
  try {
    const extension = getExtension(filename)
    const lowerStringExtension = (extension || []).map(_.toLower)

    return lowerStringExtension.includes('pdf')
  } catch (error) {
    console.log('error isPdf', error)

    return false
  }
}

export const isImage = filename => {
  try {
    const extension = getExtension(filename)
    const imageExtension = ['png', 'jpg', 'jpeg']
    const lowerStringExtension = (extension || []).map(_.toLower)

    return lowerStringExtension.some(value => imageExtension.includes(value))
  } catch (error) {
    console.log('error isImage', error)
    return false
  }
}
