import React from 'react'
import ActionsButton from '../ActionsButton'

const EditPreview = ({
  document,
  editable,
  viewable,
  onEdit,
  onDelete,
  onPreview,
  deletable = true,
  noMargin = false,
  className,
}) => {
  const showPdfmessage =
    document?.type === 'pdf' ||
    document?.fileExtension === 'pdf' ||
    document?.type === 'application/pdf'
  const _editable = editable && !showPdfmessage && document?.source != 'storage'
  const _onEdit = () => {
    onEdit && onEdit(document)
  }
  const _onDelete = () => {
    onDelete && onDelete(document)
  }
  const _onPreview = e => {
    e.preventDefault()
    e.stopPropagation()
    onPreview && onPreview(document)
  }

  return (
    <div
      style={{ textAlign: 'center', marginLeft: noMargin ? 0 : '80px' }}
      className={className}
    >
      <ActionsButton
        viewable={viewable}
        editable={_editable}
        showPdfmessage={showPdfmessage}
        deletable={deletable}
        _onEdit={_onEdit}
        _onPreview={_onPreview}
        _onDelete={_onDelete}
      />
    </div>
  )
}

export default EditPreview
