import { useCallback, useState, useEffect, memo, useMemo } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { DateRangePicker as DatePicker } from 'react-date-range'
import { CommonDialogV2 } from '~/components/shared'
import _ from 'lodash'
import { IonButton, IonLabel, IonButtons } from '@ionic/react'
import { getTitle } from '~/utils/getRangeDate'
import moment from 'moment'
import clsx from 'clsx'
import { isMobileWebApp, isMobileApp } from '~/utils/getCurrentPlatform'
import { Form } from 'react-bootstrap'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DateRangePicker.scss'

const DateRangePicker = props => {
  const {
    range = {},
    keySelect = 'selection',
    title,
    onRangeChange,
    className,
    style,
    field,
    pluralModelName,
    format,
  } = props

  const [isShow, setIsShow] = useState(false)
  const key = keySelect
  const [selectionRange, setSelectionRange] = useState({})
  const [isUsingNoDate, setIsUsingNoDate] = useState(false)

  const isAllOption = getTitle(range) === 'ALL'

  const isMobile = isMobileApp() || isMobileWebApp()

  const hideUseNoDateCheckbox = useMemo(() => {
    const exceptFields = ['createdAt', 'updatedAt']

    return exceptFields.includes(field) || !pluralModelName
  }, [field, pluralModelName])

  const renderNoDateSentMessage = useCallback(
    () => (
      <IonLabel>
        This filter will return all {pluralModelName} without a{' '}
        {_.startCase(field)}
      </IonLabel>
    ),
    [field, pluralModelName],
  )

  useDeepCompareEffect(() => {
    if (_.isNull(range)) {
      setIsUsingNoDate(true)
      return
    }
    if (_.size(range) === 0) {
      setSelectionRange({
        startDate: null,
        endDate: null,
        key,
      })

      return
    }

    let { startDate, endDate } = range
    if (_.isString(startDate)) {
      startDate = moment(startDate).toDate()
    }

    if (_.isString(endDate)) {
      endDate = moment(endDate).toDate()
    }
    setSelectionRange({
      key,
      startDate,
      endDate,
    })
  }, [range, key])

  const handleSelect = useCallback(
    date => {
      setSelectionRange(date[key])
    },
    [key],
  )

  const onToggleUsingNoDate = useCallback(event => {
    const { checked } = event.target
    setIsUsingNoDate(checked)
  }, [])

  const onHidePicker = useCallback(() => {
    let { startDate, endDate } = range || {}
    if (_.isString(startDate)) {
      startDate = moment(startDate).toDate()
    }

    if (_.isString(endDate)) {
      endDate = moment(endDate).toDate()
    }
    setSelectionRange({ startDate, endDate, key })
    setIsShow(false)
  }, [range, key])

  const onShowPicker = useCallback(() => {
    setIsShow(true)
  }, [])

  const onConfirm = useCallback(() => {
    if (onRangeChange) {
      let value = {
        startDate: moment(selectionRange.startDate).toISOString(),
        endDate: moment(selectionRange.endDate).toISOString(),
      }
      if (isUsingNoDate) {
        value = null
      }
      onRangeChange(value)
    }
    setIsShow(false)
  }, [
    isUsingNoDate,
    onRangeChange,
    selectionRange?.endDate,
    selectionRange?.startDate,
  ])

  const onReset = useCallback(() => {
    setSelectionRange({ startDate: null, endDate: null, key })
  }, [key])

  useEffect(() => {
    const keyPressHandler = e => {
      const currentActiveElement = document.querySelector('.' + className)
      if (`${e.code}` == 'Enter' && currentActiveElement && isShow) {
        e.preventDefault()
        onConfirm()
      }
    }
    document.addEventListener('keydown', keyPressHandler)

    return () => {
      document.removeEventListener('keydown', keyPressHandler)
    }
  }, [onConfirm, className, isShow])

  return (
    <>
      <IonButtons className={clsx('dateSubmitButton', className)} style={style}>
        <IonButton
          fill='clear'
          onClick={onShowPicker}
          color={isAllOption ? 'medium' : 'primary'}
        >
          <IonLabel style={{ fontWeight: 'bold', fontSize: 'small' }}>
            {title}:&nbsp;
          </IonLabel>
          <IonLabel
            style={{
              maxWidth: '100%',
              padding: 0,
              opacity: isAllOption ? 0.6 : 1,
              fontSize: 'small',
            }}
            color='dark'
          >
            {getTitle(range, format)}
          </IonLabel>
        </IonButton>
      </IonButtons>
      <CommonDialogV2
        isOpen={isShow}
        onClose={onHidePicker}
        okText='Reset'
        okButtonProps={{
          color: 'fleet',
        }}
        onOk={onReset}
        className='DateRangePicker__dialog'
        title={
          <div>
            {hideUseNoDateCheckbox && <IonLabel>Select Date Range</IonLabel>}
            {!hideUseNoDateCheckbox && (
              <Form.Check
                label={`Search for ${pluralModelName} where ${_.startCase(
                  field,
                )} is not set`}
                checked={isUsingNoDate}
                onChange={onToggleUsingNoDate}
                className='DateRangePicker__notsetCheckbox'
              />
            )}
          </div>
        }
      >
        <DatePicker
          className={isMobile ? 'mobile-view' : ''}
          ranges={[selectionRange]}
          onChange={handleSelect}
        />
        {isUsingNoDate && (
          <div className='DateRangePicker__notSetMessage'>
            {renderNoDateSentMessage()}
          </div>
        )}
        <div style={{ padding: 4 }}>
          <IonButton
            color='primary'
            onClick={onConfirm}
            title='Confirm info'
            className={className}
            style={{ width: '100%', margin: 0 }}
          >
            Confirm
          </IonButton>
        </div>
      </CommonDialogV2>
    </>
  )
}

// DateRangePicker.propTypes = {
//   range: PropTypes.shape({
//     startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//     endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//     key: PropTypes.string,
//   }),
//   keySelect: PropTypes.string,
//   title: PropTypes.string,
//   onRangeChange: PropTypes.func,

//   filterModalRef: PropTypes.object,
//   className: PropTypes.string,
// }

export default memo(DateRangePicker)
