import { useState, useCallback, useMemo, useRef } from 'react'
import { IonButton } from '@ionic/react'
import { swapVerticalOutline } from 'ionicons/icons'

import { ReorderCheckbox } from '~/components/shared'
import MultiselectOrderToggle from './Toggle'
import MultiselectOrderButtonGroup from './ButtonGroup'
import { changeColorBorder } from './helpers'
import { ControlledMenu, useClick, useMenuState } from '@szhsin/react-menu'

import clsx from 'clsx'
import './DataSortTool.scss'
import './ColumnsButton.scss'

const MultiselectOrderDropdown = props => {
  const {
    options = [],
    canReorder = true,
    isCheckedKey = 'sorted',
    classNameReoderWrap,
    classNameReoderContainer,
    containerStyle,
    extraView,
    icon,
    isButtonGroup,
    isMyCompanyPage = false,
    hasDirection = true,
    onChange,
    onClear,
    onGoPage,
    onReset,
    onSave,
    onSaveConfiguration,
    showToolTip,
    disableClearButton,
    disableResetButton,
    disableSaveButton,
    tooltipClearButton,
    tooltipSaveButton,
    tooltipResetButton,
    tooltipProps,
    tooltipPlacement,
    tooltipTitle,
  } = props

  const [isSortUpdated, setIsSortUpdated] = useState(false)

  const buttonRef = useRef(null)
  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const anchorProps = useClick(menuState.state, toggleMenu)

  const toggleShowSortOptions = menuState.state === 'open'

  const contentTooltip = useMemo(() => {
    const sortFields = (options || []).filter(
      col => !col.isHidden && col[isCheckedKey],
    )

    return sortFields.map(({ isAsc, label, value }, index) => (
      <div key={value || index}>
        <span>{label}</span>
        {hasDirection && <span>: {isAsc ? '▲' : '▼'}</span>}
      </div>
    ))
  }, [hasDirection, options, isCheckedKey])

  // const classes = useMemo(() => {
  //   if (isButtonGroup) {
  //     return clsx(className, {
  //       trigger: _.size(contentTooltip) > 0,
  //       focus: toggleShowSortOptions,
  //     })
  //   }

  //   return clsx(
  //     'DataSortTool__container my-company-btn-secondary sort-btn button-toggle',
  //     { focus: toggleShowSortOptions, active: _.size(contentTooltip) > 0 },
  //     className,
  //   )
  // }, [className, contentTooltip, isButtonGroup, toggleShowSortOptions])

  const onChangeOptions = useCallback(
    (newOptions, sortField) => {
      onChange && onChange(newOptions, { sortField })
    },
    [onChange],
  )

  const _onSaveConfig = useCallback(async () => {
    await onSaveConfiguration?.()
    setIsSortUpdated(false)
  }, [onSaveConfiguration])

  if (options.length === 0) return null

  return (
    <>
      <div
        ref={buttonRef}
        style={
          isMyCompanyPage
            ? changeColorBorder(options, toggleShowSortOptions)
            : {}
        }
        {...anchorProps}
      >
        <MultiselectOrderToggle
          contentTooltip={contentTooltip}
          icon={icon}
          isButtonGroup={isButtonGroup}
          isMyCompanyPage={isMyCompanyPage}
          showToolTip={showToolTip}
          tooltipPlacement={tooltipPlacement}
          tooltipProps={tooltipProps}
          tooltipTitle={tooltipTitle}
        />
      </div>

      <ControlledMenu
        {...menuState}
        anchorRef={buttonRef}
        onClose={() => toggleMenu(false)}
      >
        <div
          className={clsx(
            classNameReoderWrap,
            'DataSortTool__wrapReorderCheckbox',
          )}
        >
          {extraView}
          <ReorderCheckbox
            className={clsx(
              'DataSortTool__reorderCheckbox',
              classNameReoderContainer,
              { savingData: onSave || onClear },
            )}
            canReorder={canReorder}
            containerStyle={containerStyle}
            hasDirection={hasDirection}
            isCheckedKey={isCheckedKey}
            onSortChange={onChangeOptions}
            sortOptions={options}
          />
          {onSaveConfiguration && isSortUpdated && (
            <div
              className='ion-text-center'
              style={{
                width: '100%',
                borderTop: '2px solid black',
                padding: 5,
              }}
            >
              <IonButton
                onClick={_onSaveConfig}
                slot='start'
                color='dark'
                fill='solid'
              >
                Save as Default
              </IonButton>
            </div>
          )}
          <MultiselectOrderButtonGroup
            onClear={onClear}
            onGoPage={onGoPage}
            onReset={onReset}
            onSave={onSave}
            disableClearButton={disableClearButton}
            disableSaveButton={disableSaveButton}
            disableResetButton={disableResetButton}
            tooltipSaveButton={tooltipSaveButton}
            tooltipClearButton={tooltipClearButton}
            tooltipResetButton={tooltipResetButton}
          />
        </div>
      </ControlledMenu>
    </>
  )
}

MultiselectOrderDropdown.defaultProps = {
  icon: swapVerticalOutline,
  tooltipProps: {},
  tooltipSaveButton: 'Update Default Options',
  tooltipClearButton: 'Uncheck All Options',
  tooltipResetButton: 'Reset to Default Options',
}

export default MultiselectOrderDropdown
