import { useCallback, useRef, useState, useMemo } from 'react'
import { useMeasure } from 'react-use'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import { IonButton, IonIcon } from '@ionic/react'

import _ from 'lodash'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import clsx from 'clsx'

import './styles.scss'

export interface IRTHorizontalScrollProps {
  className?: string
  isHidden?: boolean
  children: (params: any) => React.ReactNode
}

function RTHorizontalScroll(props: IRTHorizontalScrollProps) {
  const { children, className, isHidden } = props

  const [isShowHorizontalScrollLeft, setIsShowHorizontalScrollLeft] =
    useState(false)
  const [isShowHorizontalScrollRight, setIsShowHorizontalScrollRight] =
    useState(false)
  const [isShowHorizontalScroll, setIsShowHorizontalScroll] = useState(false)
  const [scrollXPosition, setScrollXPosition] = useState(0)

  const [ref, measure] = useMeasure()
  const { height, width } = measure
  const { windowSize } = useBreakpoint()

  const isShowLeftScroll = useMemo(() => {
    if (_.isNil(isShowHorizontalScroll)) {
      return isShowHorizontalScrollLeft
    }

    return isShowHorizontalScroll && isShowHorizontalScrollLeft
  }, [isShowHorizontalScroll, isShowHorizontalScrollLeft])

  const isShowRightScroll = useMemo(() => {
    if (_.isNil(isShowHorizontalScroll)) {
      return isShowHorizontalScrollRight
    }

    return isShowHorizontalScroll && isShowHorizontalScrollRight
  }, [isShowHorizontalScroll, isShowHorizontalScrollRight])

  const childrenRef = useRef<any>()

  const setRef = useCallback(
    (elementRef: any) => {
      ref(elementRef)
      setIsShowHorizontalScroll(
        elementRef?.scrollWidth > elementRef?.clientWidth,
      )
      childrenRef.current = elementRef
      setIsShowHorizontalScrollLeft(elementRef?.scrollLeft >= 5)
      const limitScroll = elementRef?.scrollWidth - elementRef?.clientWidth
      setIsShowHorizontalScrollRight(elementRef?.scrollLeft < limitScroll)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref, scrollXPosition, height, width, windowSize],
  )

  const onScroll = (() => {
    let ticking = false

    return (e: any) => {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          let documentScrollLeft = e.target.scrollLeft
          setScrollXPosition(documentScrollLeft)

          ticking = false
        })
        ticking = true
      }
    }
  })()

  const handleHorizontalScrollLeft = useCallback(() => {
    if (_.isNumber(childrenRef.current?.scrollLeft)) {
      childrenRef.current.scrollLeft -= 150
    }
  }, [])

  const handleHorizontalScrollRight = useCallback(() => {
    if (_.isNumber(childrenRef.current?.scrollLeft)) {
      childrenRef.current.scrollLeft += 150
    }
  }, [])

  if (isHidden) {
    return (
      <div className={clsx('RTHorizontalScroll__container', className)}>
        {children({ setRef, onScroll, measure, scrollXPosition })}
      </div>
    )
  }

  return (
    <div className={clsx('RTHorizontalScroll__container', className)}>
      {isShowLeftScroll && (
        <IonButton
          color='snowgrey'
          className='RTHorizontalScroll__backHorizontalScroll'
          style={{ height: height }}
          onClick={handleHorizontalScrollLeft}
        >
          <IonIcon icon={chevronBackOutline} />
        </IonButton>
      )}

      {isShowRightScroll && (
        <IonButton
          color='snowgrey'
          className='RTHorizontalScroll__forwardHorizontalScroll'
          style={{ height: height }}
          onClick={handleHorizontalScrollRight}
        >
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      )}
      {children({ setRef, onScroll, measure, scrollXPosition })}
    </div>
  )
}

RTHorizontalScroll.defaultProps = {
  arrowHeight: 100,
}

export default RTHorizontalScroll
