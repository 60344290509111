import { useMemo } from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { UniversalLoadingPage } from '../shared'

const RouteFromPath = props => {
  const {
    private: isPrivate,
    path,
    alias,
    component: Component,
    ...restProps
  } = props

  const getPath = useMemo(() => {
    if (alias) {
      return alias
    }

    return path
  }, [alias, path])

  if (isPrivate) {
    return <PrivateRoute {...props} component={Component} />
  }

  return (
    <Route {...restProps} path={getPath}>
      <Component
        fallback={<UniversalLoadingPage isWrappedByBody={getPath !== '/'} />}
        {...restProps.props}
      />
    </Route>
  )
}

export default RouteFromPath
