import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  DeleteIcon,
  DownloadIcon,
  OpenIcon,
  PdfIcon,
  useModalDownloadProgress,
} from '~/components/shared'
import { useConfirmationProvider } from '~/contexts'

import { IonIcon } from '@ionic/react'
import {
  readerOutline,
  apertureOutline,
  cardOutline,
  mailOutline,
  layersOutline,
  browsersOutline,
  exitOutline,
  addCircleOutline,
  radioButtonOffOutline,
  checkmarkCircleOutline,
  gitPullRequestOutline,
  createOutline,
} from 'ionicons/icons'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { getDocumentURL } from '~/utils/documentUtils'
import { useHistory } from 'react-router-dom'
import OverlayCreateTag from '~/components/load/OverlayCreateTag'
import { Modal } from 'react-bootstrap'
import { selectCommonTagsOptions } from '~/redux/selectors'
import getFileNameFromAWSLink from '~/utils/getFileNameFromAWSLink'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

const useDocumentMenu = () => {
  const { confirmation } = useConfirmationProvider()
  const history = useHistory()
  const [showTagForm, setShowTagForm] = useState(false)
  const tags = useSelector(selectCommonTagsOptions)

  const { downloadFile } = useModalDownloadProgress()

  const renderDocumentMenu = (
    concordDocument,
    {
      afterCreateLoad,
      onOpenCreateLoadDialog,
      onOpenPdfReview,
      afterDeleteDocument,
      onOpenEmailContent,
      onSplitInvoice,
      handleParseInvoice,
      onOpenNewTab,
      onOpenNewWindow,
      onUpdateTagLinks,
    },
  ) => {
    const handleCreateLoad = async (loadBody = {}) => {
      try {
        const result = await apiClient.documents.createLoad(
          concordDocument.id,
          loadBody,
        )
        toast.success(toastMessages.createSuccess)
        afterCreateLoad && afterCreateLoad(result)
      } catch (error) {
        toast.error(toastMessages.createError)
      }
    }

    const handleOpenMailContentDialog = () => {
      onOpenEmailContent && onOpenEmailContent(concordDocument)
    }

    const handleOpenPdfReview = async () => {
      try {
        const url = await getDocumentURL(concordDocument)
        onOpenPdfReview && onOpenPdfReview(url, concordDocument)
      } catch (error) {
        toast.error(toastMessages.createError)
      }
    }

    const handleOpenSplitInvoiceDialog = () => {
      onSplitInvoice && onSplitInvoice(concordDocument)
    }

    const handleDeleteDocument = async () => {
      try {
        const action = await confirmation({
          message: `Do you want to delete '${concordDocument.name}' file?`,
        })
        if (action === EYesNo.Yes) {
          const result = await apiClient.documents.delete(concordDocument.id)
          toast.success(toastMessages.deleteSuccess)
          afterDeleteDocument && afterDeleteDocument(result)
        }
      } catch (error) {
        toast.error(toastMessages.createError)
      }
    }

    const handleDownloadPDF = () => {
      const { url } = concordDocument
      const name = getFileNameFromAWSLink(url)
      downloadFile(url, name)
    }

    const createLoadMenu = {
      label: 'Auto Create Loads',
      icon: <IonIcon icon={gitPullRequestOutline} />,
      onClick: () => handleCreateLoad(),
      color: 'success',
    }

    const modalCreateLoadMenu = {
      label: 'Create Loads Form',
      icon: <IonIcon icon={createOutline} />,
      onClick: event => onOpenCreateLoadDialog(event, concordDocument),
      color: 'success',
    }

    const createInvoiceMenu = {
      label: 'Parse Invoice',
      className: 'text-success',
      icon: <IonIcon color={'success'} icon={cardOutline} />,
      onClick: () => handleParseInvoice(concordDocument),
      color: 'success',
    }

    const splitInvoiceMenu = {
      label: 'Split Invoice',
      className: 'DocumentItem__menuItem',
      icon: <IonIcon color={'success'} icon={layersOutline} />,
      onClick: handleOpenSplitInvoiceDialog,
      color: 'success',
    }

    const viewLoadsMenu = {
      label: 'View Loads',
      className: 'DocumentItem__menuItem',
      icon: <IonIcon icon={readerOutline} />,
      onClick: () => history.push(`/loads?documentId=${concordDocument.id}`),
    }

    const viewInvoicesMenu = {
      label: 'View Invoice',
      className: 'DocumentItem__menuItem',
      icon: <IonIcon icon={readerOutline} />,
      onClick: () => history.push(`/invoices/${concordDocument.invoiceId}`),
    }

    const tagsSubMenu = [
      {
        label: 'Create new tag',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={addCircleOutline} />,
        onClick: () => setShowTagForm(true),
      },
      ...tags.map(option => ({
        ...option,
        icon: (
          <IonIcon
            icon={
              concordDocument?.tagIds?.includes(option.value)
                ? checkmarkCircleOutline
                : radioButtonOffOutline
            }
          />
        ),
        className: option.style,
        onClick: () => {
          const req = {
            tagId: option.value,
            taggableType: 'Document',
            taggableId: concordDocument.id,
          }

          if (concordDocument.tagIds.includes(option.value)) {
            apiClient.tagLinks
              .get({
                filters: {
                  tagId: option.value,
                  taggableId: concordDocument.id,
                },
              })
              .then(res => {
                apiClient.tagLinks.delete(res[0].id).then(response => {
                  onUpdateTagLinks &&
                    onUpdateTagLinks(response.taggableId, response.tagId)
                })
              })
          } else {
            apiClient.tagLinks.create(req).then(res => {
              onUpdateTagLinks && onUpdateTagLinks(res.taggableId, res.tagId)
            })
          }
        },
      })),
    ]

    const menu = [
      {
        label: 'PDF',
        icon: <PdfIcon />,
        onClick: handleOpenPdfReview,
      },
      {
        label: 'Details',
        className: 'DocumentItem__menuItem',
        icon: <OpenIcon />,
        onClick: () => history.push(`/documents/${concordDocument.id}`),
      },
      {
        label: 'Pre-processing',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={apertureOutline} />,
        onClick: () => history.push(`/process_document/${concordDocument.id}`),
      },
      {
        label: 'View in new tab',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={exitOutline} />,
        onClick: onOpenNewTab,
      },
      {
        label: 'View in new window',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={browsersOutline} />,
        onClick: onOpenNewWindow,
      },
      {
        label: 'Tags',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={addCircleOutline} />,
        onClick: () => setShowTagForm(true),
        subMenu: tagsSubMenu,
      },
    ]

    if (concordDocument.parsedEmailId) {
      menu.push({
        label: 'View Email',
        className: 'DocumentItem__menuItem',
        icon: <IonIcon icon={mailOutline} />,
        onClick: handleOpenMailContentDialog,
      })
    }

    menu.push({
      label: 'Download PDF',
      className: 'DocumentItem__menuItem',
      icon: <DownloadIcon />,
      onClick: handleDownloadPDF,
      color: 'export',
      hide: !concordDocument.url,
    })

    menu.push({
      label: 'Delete',
      className: 'DocumentItem__menuItem delete',
      icon: <DeleteIcon />,
      onClick: handleDeleteDocument,
      color: 'danger',
    })

    if (concordDocument.docType === 'Load') {
      return [createLoadMenu, modalCreateLoadMenu, viewLoadsMenu, ...menu]
    }

    if (concordDocument.docType === 'Invoice' && concordDocument.sellerId) {
      return [
        createInvoiceMenu,
        splitInvoiceMenu,
        ...(concordDocument?.status != 'New' ? [viewInvoicesMenu] : []),
        ...menu,
      ]
    }

    return menu
  }

  const renderCreateTagForm = () =>
    showTagForm && (
      <Modal show={showTagForm} onHide={() => setShowTagForm(false)}>
        <OverlayCreateTag
          onClose={() => setShowTagForm(false)}
          afterCreateTag={() => setShowTagForm(false)}
        />
      </Modal>
    )

  return { renderDocumentMenu, renderCreateTagForm }
}

export default useDocumentMenu
