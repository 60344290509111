var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { HtmlVisibilityUtil } from 'common/util/HtmlVisibilityUtil';
import { EndUserBlockRenderUtils } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/EndUserBlockRenderUtils';
var TABLE_AREA_HEIGHT = 305;
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        position: 'absolute',
        borderRadius: theme.shape.borderRadius,
        scrollMargin: "".concat(theme.spacing(5), "px"),
        // recenter blocks to offset the border radius
        marginLeft: theme.shape.borderRadius * -0.5,
        marginTop: theme.shape.borderRadius * -0.5,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}); });
/**
 * Component used for rendering a colored block over the DocumentColoredBlockLayer
 * @param props
 */
export var ColoredBlock = function (_a) {
    var block = _a.block, color = _a.color, onClick = _a.onClick, docPageHeights = _a.docPageHeights, docRenderWidth = _a.docRenderWidth, _b = _a.opacity, opacity = _b === void 0 ? 1 : _b, _c = _a.autoScroll, autoScroll = _c === void 0 ? false : _c, isFieldViewing = _a.isFieldViewing;
    var classes = useStyles();
    var state = useDocumentLabeler().state;
    var blockRef = useRef(null);
    var _d = useState(alpha(color, 0.2)), bgColor = _d[0], setBGColor = _d[1];
    useEffect(function () {
        var _a;
        var bottomOffset = ((_a = state.localState.activeField) === null || _a === void 0 ? void 0 : _a.type) === FieldType.Table
            ? TABLE_AREA_HEIGHT
            : 0;
        if (blockRef.current &&
            state.localState.activeField &&
            autoScroll &&
            state.localState.rootRef &&
            !HtmlVisibilityUtil.isInContainer(blockRef.current, state.localState.rootRef, {
                bottomOffset: bottomOffset,
            })) {
            blockRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [
        state.localState.activeField,
        blockRef.current,
        state.localState.rootRef,
    ]);
    useEffect(function () {
        setBGColor(alpha(color, 0.2));
    }, [color]);
    var handleOnHover = function () {
        setBGColor(alpha(color, 0.8));
    };
    var handleOnHoverExit = function () {
        setBGColor(alpha(color, 0.2));
    };
    var coords = useMemo(function () {
        var result = EndUserBlockRenderUtils.getBlockRenderCoords(block.boundingBox, docPageHeights, docRenderWidth);
        if (isFieldViewing) {
            result.height *= 2.5;
            result.width *= 2.5;
            result.top = 250;
            result.left = 250;
        }
        return result;
    }, [block.boundingBox, docPageHeights, docRenderWidth, isFieldViewing]);
    return (_jsx("div", { style: __assign(__assign({}, coords), { opacity: opacity, backgroundColor: bgColor, border: "2px solid ".concat(color) }), className: classes.Root, onClick: onClick, ref: blockRef, onMouseEnter: handleOnHover, onMouseLeave: handleOnHoverExit }));
};
