import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryScheduleLoads, useQuerySchedules } from '~/hooks/useQueryData'

import {
  CompanyInfo,
  EditIcon,
  ICommonTabItem,
  ToolTipOverlay,
} from '~/components/shared'
import OrderForm from '~/components/shared/OrderForm/OrderForm'

import { selectCompanies, selectAllTerminals } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { arrowDownCircleOutline, closeOutline } from 'ionicons/icons'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import moment from 'moment'

import type {
  IOrderFormDialogProps,
  IOrderFormValues,
  IOrderScheduleAttributeFormValues,
} from './type'
import type { ICompany } from '~/types/models/ICompany'
import type { ITerminal } from '~/types/models/ITerminal'
import _ from 'lodash'
import { produce } from 'immer'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { IScheduleLoad } from '~/types/models/IScheduleLoad'
import diffObjects from '~/utils/diffObjects'
import { Badge, Button } from 'react-bootstrap'
import { ISchedule } from '~/types/models/ISchedule'
import { EOrderStatus } from '~/types/enums/EOrder'
import { Unless } from 'react-if'
import { useDeepCompareEffect } from 'react-use'

const useOrderFormDialog = (props: IOrderFormDialogProps) => {
  const { isOpen, onClose, orderData, defaultTab, afterUpdate, className } =
    props

  const [defaultValues, setDefaultValues] =
    useState<IOrderFormValues | null>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [currentTab, setCurrentTab] =
    useState<undefined | string | number>(undefined)
  const [isToggleMap, setIsToggleMap] = useState(false)

  const ref = useRef<
    Record<string, { getValues: () => any; watch: () => any }>
  >({})

  const companyObjects: Record<number, ICompany> = useSelector(selectCompanies)
  const terminalObjects: Record<number, ITerminal> =
    useSelector(selectAllTerminals)

  const { schedulesData, refetchSchedulesData, isSchedulesDataFetched } =
    useQuerySchedules(
      {
        filters: {
          orderId: [orderData?.id as number],
        },
      },
      { enabled: Boolean(orderData?.id && isOpen) },
    )

  const scheduleIds = useMemo(
    () => schedulesData.map(({ id }) => id),
    [schedulesData],
  )

  const { scheduleLoadsData, refetchScheduleLoadsData } = useQueryScheduleLoads(
    {
      filters: {
        scheduleId: scheduleIds,
      },
    },
    { enabled: scheduleIds.length > 0 },
  )

  const orderBuyerTerminal = useMemo(() => {
    if (orderData?.buyerTerminalId) {
      return terminalObjects[orderData?.buyerTerminalId]
    }

    return null
  }, [orderData?.buyerTerminalId, terminalObjects])

  const getScheduleLoadsByScheduleId = useCallback(
    (schedule: ISchedule, _scheduleLoads?: IScheduleLoad[]) => {
      const scheduleLoads =
        _scheduleLoads ||
        scheduleLoadsData.filter(item => item?.scheduleId === schedule.id)
      const hasNotIncludeRunningTotal = scheduleLoadsData.some(
        ({ runningTotal }) => !runningTotal,
      )
      if (hasNotIncludeRunningTotal) {
        const scheduleWithRunningTotal = scheduleLoads.map(
          (scheduleLoad, index) => {
            const { qty } = scheduleLoad
            let runningTotal = qty
            if (index !== 0) {
              const prevItem = scheduleLoads[index]
              runningTotal = Number(prevItem.qty) + Number(qty)
            }

            return {
              ...scheduleLoad,
              runningTotal: Number(runningTotal).toFixed(1),
            }
          },
        )

        return scheduleWithRunningTotal
      }
      return scheduleLoads
    },
    [scheduleLoadsData],
  )

  const buyer = useMemo(() => {
    if (orderData) {
      return companyObjects[orderData.buyerId]
    }
    return null
  }, [companyObjects, orderData])

  const seller = useMemo(() => {
    if (orderData) {
      return companyObjects[orderData.sellerId]
    }
    return null
  }, [companyObjects, orderData])

  const buyerTerminal = useMemo(() => {
    if (orderData) {
      return terminalObjects[orderData.buyerTerminalId]
    }
    return null
  }, [orderData, terminalObjects])

  const DEFAULT_FORM_VALUES = useMemo<IOrderFormValues | null>(() => {
    const schedulesAttributes: IOrderScheduleAttributeFormValues[] = _.orderBy(
      schedulesData,
      'scheduleNumber',
      'asc',
    ).map(schedule => {
      const date = moment(schedule.startTime).format('YYYY-MM-DD')
      const startTime = moment(schedule.startTime).format('HH:mm')

      return {
        id: schedule.id,
        date,
        startTime,
        plus: schedule.plus as boolean,
        qty: schedule.qty,
        loadSize: schedule.loadSize,
        spacing: schedule.spacing,
        sellerTerminalId: schedule.sellerTerminalId,
        fleetId: schedule.fleetId,
        scheduleLoadsAttributes: getScheduleLoadsByScheduleId(schedule),
        scheduleNumber: schedule.scheduleNumber as string,
        status: schedule.status,
        nextLoads: schedule.nextLoads,
        color: schedule.color,
      }
    })

    if (orderData) {
      return {
        id: orderData.id,
        status: orderData.status,
        buyerId: orderData.buyerId,
        buyerTerminalId: orderData.buyerTerminalId,
        sellerId: orderData.sellerId,
        buyerSellerProductId: orderData.buyerSellerProductId as number,
        color: orderData.color as string,
        poNum: orderData.poNum as string,
        num: orderData.num,
        schedulesAttributes,
        orderExtras: [],
        sellerTerminalId:
          schedulesData.length > 0
            ? schedulesData[0].sellerTerminalId
            : undefined,
        fleetId:
          schedulesData.length > 0 ? schedulesData[0].fleetId : undefined,
      }
    }
    return null
  }, [getScheduleLoadsByScheduleId, orderData, schedulesData])

  const onChangeOrderForm = useCallback((formValues: IOrderFormValues) => {
    const newOrderValues = _.omit(formValues, 'schedulesAttributes')
    setDefaultValues(prev => {
      if (prev) {
        return { ...prev, ...newOrderValues }
      }
      return null
    })
  }, [])

  const generateNewScheduleLoads = useCallback(
    async (
      scheduleAttributes: IOrderScheduleAttributeFormValues,
      scheduleLoad: IScheduleLoad[],
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ) => {
      if (!scheduleAttributes.id) {
        return scheduleLoad
      }
      if (field === 'scheduleLoadsAttributes') {
        return newValue as IScheduleLoad[]
      }
      const [scheduleName, , updatingField] = field.split('.')
      const allowedFieldsToUpdate = [
        'qty',
        'loadSize',
        'sellerTerminalId',
        'date',
        'startTime',
        'plus',
        'loads',
        'color',
      ]
      // let scheduleLoadAttributeChanged
      if (
        scheduleName === 'schedulesAttributes' &&
        allowedFieldsToUpdate.includes(updatingField)
      ) {
        // scheduleLoadAttributeChanged = updatingField
        // if (updatingField === 'date') {
        //   scheduleLoadAttributeChanged = 'startTime'
        // }
        const { schedules } = await apiClient.scheduleLoads.createNew({
          order: {
            id: defaultValues?.id,
            buyerTerminalId: defaultValues?.buyerTerminalId as number,
            schedulesAttributes: [
              {
                id: scheduleAttributes.id as number,
                startTime: `${scheduleAttributes.date} ${scheduleAttributes.startTime}`,
                spacing: Number(scheduleAttributes.spacing),
                sellerTerminalId: scheduleAttributes.sellerTerminalId,
                fleetId: scheduleAttributes.fleetId,
                qty: parseFloat(scheduleAttributes.qty as string),
                loadSize: parseFloat(scheduleAttributes.loadSize as string),
                plus: scheduleAttributes.plus,
                scheduleRowChanged: 0,
                scheduleLoadAttributeChanged: 'qty',
                scheduleLoadsAttributes: scheduleLoad,
              },
            ],
          },
        })

        const schedule = schedules.find(
          ({ id }) => scheduleAttributes.id === id,
        )
        if (schedule) {
          const scheduleLoadWithId = schedule.scheduleLoads.map(item => ({
            ...item,
            scheduleId: scheduleAttributes.id,
          }))

          return scheduleLoadWithId
        }
        return scheduleLoad
      }

      return scheduleLoad
    },
    [defaultValues?.buyerTerminalId, defaultValues?.id],
  )

  const onChangeScheduleForm = useCallback(
    (schedule: IOrderScheduleAttributeFormValues, index: number) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (newOrder: IOrderFormValues, { field, value }: any) => {
        if (defaultValues) {
          const newScheduleLoads = await generateNewScheduleLoads(
            newOrder.schedulesAttributes[0],
            schedule.scheduleLoadsAttributes as IScheduleLoad[],
            field,
            value,
          )

          const newValues = produce(defaultValues, draft => {
            draft.schedulesAttributes[index] = newOrder.schedulesAttributes[0]
            if (newScheduleLoads.length) {
              draft.schedulesAttributes[index].scheduleLoadsAttributes =
                newScheduleLoads
            }
          })
          setDefaultValues(newValues)
        }
      },
    [defaultValues, generateNewScheduleLoads],
  )

  const onRemoveDraftSchedule = useCallback(
    (index: number) => () => {
      setDefaultValues(prev =>
        produce(prev, draft => {
          if (draft) {
            draft.schedulesAttributes.splice(index, 1)
          }
        }),
      )
    },
    [],
  )

  useDeepCompareEffect(() => {
    console.log(
      'defaultValues.schedulesAttributes',
      defaultValues?.schedulesAttributes,
    )
  }, [defaultValues?.schedulesAttributes])

  const tabs = useMemo<ICommonTabItem[]>(() => {
    if (defaultValues) {
      return defaultValues.schedulesAttributes.map((schedule, index) => {
        const scheduleNumber = schedule.scheduleNumber || index + 1
        const defaultValues = {
          id: orderData?.id,
          status: orderData?.id,
          buyerId: orderData?.buyerId,
          buyerTerminalId: orderData?.buyerTerminalId,
          sellerId: orderData?.sellerId,
          buyerSellerProductId: orderData?.buyerSellerProductId,
          color: orderData?.color,
          poNum: orderData?.poNum,
          schedulesAttributes: [
            {
              id: schedule.id,
              date: schedule.date,
              startTime: schedule.startTime,
              plus: schedule.plus,
              qty: schedule.qty,
              loadSize: schedule.loadSize,
              spacing: schedule.spacing,
              sellerTerminalId: schedule.sellerTerminalId,
              fleetId: schedule.fleetId,
              status: schedule.status,
              scheduleNumber: null,
              scheduleLoadsAttributes: schedule.scheduleLoadsAttributes,
              nextLoads: schedule.nextLoads,
              color: schedule.color,
            },
          ],
        }

        return {
          name: scheduleNumber,
          backgroundColor: schedule.color,
          label: (
            <div>
              <Badge
                bg='secondary'
                style={{
                  marginRight: 8,
                  fontSize: 12,
                  verticalAlign: 'middle',
                }}
              >
                #{scheduleNumber}
              </Badge>
              <Badge
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                bg={(ORDER_STATUSES as any)[schedule.status]?.color}
                style={{ fontSize: 12, verticalAlign: 'middle' }}
              >
                <span style={{ verticalAlign: 'middle' }}>
                  {schedule.status}
                </span>
                <span style={{ verticalAlign: 'middle', marginLeft: 4 }}>
                  <IonIcon
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    icon={(ORDER_STATUSES as any)[schedule.status]?.icon}
                  />
                </span>
              </Badge>
              <Unless condition={Boolean(schedule.id)}>
                <Badge
                  bg='danger'
                  style={{
                    fontSize: 12,
                    verticalAlign: 'middle',
                    marginLeft: 8,
                  }}
                  onClick={onRemoveDraftSchedule(index)}
                >
                  <IonIcon icon={closeOutline} />
                </Badge>
              </Unless>
            </div>
          ),
          render() {
            return (
              <OrderForm
                isHiddenOrderForm
                isHiddenButtons
                onClickSubmitisShowScheduleLoadsByDefault
                defaultValues={defaultValues}
                onChange={onChangeScheduleForm(schedule, index)}
                isHiddenAddScheduleButton
                isHiddenScheduleStatus={false}
                hiddenButtons={['addSchedule']}
                ref={node => {
                  ref.current[index] = node
                }}
              />
            )
          },
        }
      })
    }
    return []
  }, [defaultValues, onChangeScheduleForm, onRemoveDraftSchedule, orderData])

  const onToggleShowTerminalMap = useCallback(() => {
    setIsToggleMap(prev => !prev)
  }, [])

  const dialogTitle = useMemo(
    () => (
      <div className='dialogTitle'>
        <Badge bg='secondary' className='item'>
          #{orderData?.num}
        </Badge>
        <CompanyInfo
          company={{
            label: buyer?.name as string,
            value: buyer?.id,
            code: buyer?.code,
            name: buyer?.name,
            image: buyer?.logo,
          }}
          searchableGoogle={false}
          hideAnchor
          className='item'
        />
        <CompanyInfo
          company={{
            label: seller?.name as string,
            value: seller?.id,
            code: seller?.code,
            name: seller?.name,
            image: seller?.logo,
          }}
          searchableGoogle={false}
          hideAnchor
          companyType={EScope.seller}
          className='item'
        />
        <ToolTipOverlay content='Buyer terminal' placement='top'>
          <div>
            <IonChip color='buyer' className='item'>
              <div
                className='terminal-icon dropoff'
                style={{ marginRight: 4, width: 16, height: 16, fontSize: 12 }}
              >
                <IonIcon icon={arrowDownCircleOutline} />
              </div>
              <IonLabel>{buyerTerminal?.name}</IonLabel>
            </IonChip>
            <Button onClick={onToggleShowTerminalMap}>
              <EditIcon color='white' />
            </Button>
          </div>
        </ToolTipOverlay>
      </div>
    ),
    [
      buyer?.code,
      buyer?.id,
      buyer?.logo,
      buyer?.name,
      buyerTerminal?.name,
      onToggleShowTerminalMap,
      orderData?.num,
      seller?.code,
      seller?.id,
      seller?.logo,
      seller?.name,
    ],
  )

  const onChangeTab = useCallback((tab: string | number) => {
    setCurrentTab(tab)
  }, [])

  const updateOrder = useCallback(async () => {
    if (defaultValues?.id) {
      const payload = _.pick(defaultValues, ['poNum', 'color'])
      const { errors } = await apiClient.orders.update(defaultValues.id, {
        order: payload,
      })
      if ((errors as string[]).length) {
        toast.error((errors as string[])[0])
      }
    }
  }, [defaultValues])

  const scheduleUpdatePayloads = useMemo(() => {
    const diff = diffObjects(
      defaultValues?.schedulesAttributes.filter(({ id }) => id),
      DEFAULT_FORM_VALUES?.schedulesAttributes,
      true,
    )
    const payloads: Partial<ISchedule>[] = []
    Object.keys(diff).forEach(key => {
      const { id, ...values }: Partial<IOrderScheduleAttributeFormValues> = (
        diff as any
      )[key]
      if (Object.keys(values).length) {
        const {
          startTime,
          date,
          scheduleNumber,
          orderExtrasAttributes,
          ...schedule
        } = values as any
        const payload: Partial<IOrderScheduleAttributeFormValues> = {
          ...schedule,
        }
        if (startTime || date) {
          const current = defaultValues?.schedulesAttributes.find(
            i => i.id === id,
          )
          const newStartTime = startTime || current?.startTime
          const newDate = date || current?.date

          const [hours, minutes] = (newStartTime || '').split(':')
          const joinedDateTime = moment(newDate)
            .set({
              hours: Number(hours),
              minutes: Number(minutes),
            })
            .toISOString()
          payload.startTime = joinedDateTime
        }

        payloads.push({
          id,
          ...payload,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)
      }
    })
    return payloads
  }, [
    DEFAULT_FORM_VALUES?.schedulesAttributes,
    defaultValues?.schedulesAttributes,
  ])

  const submitButtonText = useMemo(() => {
    const countCreatingSchedules =
      defaultValues?.schedulesAttributes.filter(({ id }) => !id).length || 0

    if (countCreatingSchedules > 1) {
      return `Create ${countCreatingSchedules} New Schedule`
    }

    if (countCreatingSchedules === 1) {
      return 'Create New Schedule'
    }

    if (scheduleUpdatePayloads.length) {
      return 'Update Schedule'
    }

    return ''
  }, [defaultValues?.schedulesAttributes, scheduleUpdatePayloads.length])

  const updateSchedules = useCallback(async () => {
    const apis: Promise<unknown>[] = []
    scheduleUpdatePayloads.forEach(({ id, ...payload }) =>
      apis.push(
        apiClient.schedules.update(id as number, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          schedule: payload as any,
        }),
      ),
    )
    const responses: any = await Promise.all(apis)
    const error = _.get(responses, '[0].errors[0]')
    if (error) {
      toast.error(error)
    }
  }, [scheduleUpdatePayloads])

  const handleClose = useCallback(() => {
    onClose && onClose()
    localStorage.removeItem('orderForm')
  }, [onClose])

  const onAddSchedule = useCallback(() => {
    const nextScheduleNum = tabs.length + 1
    setCurrentTab(nextScheduleNum)
    setDefaultValues(prev =>
      produce(prev, draft => {
        if (draft) {
          draft.schedulesAttributes.push({
            date: moment().format('YYYY-MM-DD'),
            startTime: moment().format('HH:mm'),
            plus: true,
            qty: null,
            loadSize: null,
            spacing: null,
            sellerTerminalId: draft.schedulesAttributes[0]?.sellerTerminalId,
            fleetId: draft.schedulesAttributes[0]?.fleetId,
            status: EOrderStatus.WillCall,
            scheduleNumber: null,
            scheduleLoadsAttributes: [],
            nextLoads: [],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any)
        }
      }),
    )
  }, [tabs.length])

  const createMoreSchedules = useCallback(async () => {
    const newSchedules = Object.keys(ref.current)
      .filter(key => typeof key === 'string')
      .map(key => {
        const formParams = ref.current[key]

        const formValues = formParams.watch()
        return formValues
      })
      .map((data, index) => {
        const [schedule] = data.schedulesAttributes
        const [hours, minutes] = schedule.startTime.split(':')
        return {
          ...schedule,
          scheduleNumber: index + 1,
          orderId: data.id,
          startTime: moment(schedule.date)
            .set({
              hours,
              minutes,
            })
            .toISOString(),
        }
      })
      .filter(({ id }) => !id)

    const postApis = newSchedules.map(schedule =>
      apiClient.schedules.create({ schedule }),
    )
    if (postApis.length) {
      const response = await Promise.all(postApis)
      const scheduleIds = response.map(({ id }) => id)
      if (scheduleIds.length) {
        const { scheduleLoads } = await apiClient.scheduleLoads.get({
          filters: {
            scheduleId: scheduleIds,
          },
        })
        setDefaultValues(prev =>
          produce(prev, draft => {
            if (draft) {
              // let startIndex = (draft.schedulesAttributes.length - newSchedules.length) + 1
              let tempIndex = 0
              draft.schedulesAttributes.forEach(schedule => {
                if (!schedule.id) {
                  const findScheduleLoads = scheduleLoads.filter(
                    ({ scheduleId }) => scheduleId === response[tempIndex].id,
                  )
                  const withRunningTotal = getScheduleLoadsByScheduleId(
                    response[tempIndex],
                    findScheduleLoads,
                  )
                  schedule.id = response[tempIndex].id
                  schedule.scheduleLoadsAttributes = withRunningTotal
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ;(schedule as any).scheduleNumber =
                    response[tempIndex].scheduleNumber
                  tempIndex++
                }
              })
            }
          }),
        )
      }
    }
  }, [getScheduleLoadsByScheduleId])

  const onClickSubmit = useCallback(async () => {
    setIsUpdating(true)
    try {
      await Promise.all([
        updateOrder(),
        updateSchedules(),
        createMoreSchedules(),
      ])
      afterUpdate && afterUpdate()
      handleClose()
      refetchScheduleLoadsData()
      refetchSchedulesData()
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsUpdating(false)
    }
  }, [
    afterUpdate,
    handleClose,
    refetchScheduleLoadsData,
    refetchSchedulesData,
    updateOrder,
    updateSchedules,
    createMoreSchedules,
  ])

  useDeepCompareEffect(() => {
    setDefaultValues(DEFAULT_FORM_VALUES)
  }, [DEFAULT_FORM_VALUES || {}])

  useEffect(() => {
    if (defaultTab) {
      setCurrentTab(defaultTab)
    } else if (isSchedulesDataFetched && schedulesData.length === 1) {
      setCurrentTab(schedulesData[0].scheduleNumber as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab, isSchedulesDataFetched])

  return {
    isOpen,
    dialogTitle,
    tabs,
    orderData,
    defaultValues,
    isUpdating,
    defaultTab,
    className,
    currentTab,
    isToggleMap,
    orderBuyerTerminal,
    submitButtonText,
    onToggleShowTerminalMap,
    onAddSchedule,
    onClose,
    onChangeOrderForm,
    onClickSubmit,
    handleClose,
    onChangeTab,
    setDefaultValues,
    afterUpdate,
  }
}

export default useOrderFormDialog
