import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'

import type { IDialogProductFormProps } from './type'
import ProductForm from './ProductForm'

function DialogProductForm(props: IDialogProductFormProps) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props

  return (
    <CommonDialogV2 {...dialogProps} title='Product' isHiddenOkButton>
      <ProductForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogProductForm
