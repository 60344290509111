import { createContext } from 'react'

import type { IConfirmationProviderValues } from './type'

const ConfirmationContext = createContext<IConfirmationProviderValues>({
  confirmation() {
    return Promise.resolve('Cancel')
  },
})

export default ConfirmationContext
