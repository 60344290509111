import { IonIcon, IonButton, IonButtons, IonLabel } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import {
  pencilOutline,
  trashOutline,
  eye,
  paperPlaneOutline,
} from 'ionicons/icons'

import clsx from 'clsx'

import './ActionsButton.scss'

const ActionsButton = ({
  viewable = true,
  editable,
  showPdfmessage,
  deletable = true,
  _onEdit,
  _onPreview,
  _onDelete,
  className,
  onSubmit,
  tooltipSubmitProps,
  submitButtonProps,
}) => {
  return (
    <IonButtons className={clsx('ActionButtons__container', className)}>
      {onSubmit && (
        <ToolTipOverlay content='' {...tooltipSubmitProps}>
          <IonButton
            fill='solid'
            className='ActionButtons__button submit'
            color='primary'
            onClick={onSubmit}
            {...submitButtonProps}
          >
            <IonIcon slot='icon-only' icon={paperPlaneOutline} />
            <IonLabel>Submit</IonLabel>
          </IonButton>
        </ToolTipOverlay>
      )}
      {editable &&
        !showPdfmessage && ( //show crop image
          <IonButton
            fill='solid'
            className='ActionButtons__button'
            color='success'
            onClick={_onEdit}
          >
            <IonIcon slot='icon-only' icon={pencilOutline} />
          </IonButton>
        )}
      {viewable && (
        <IonButton
          fill='solid'
          className='ActionButtons__button'
          onClick={_onPreview}
        >
          <IonIcon slot='icon-only' icon={eye} />
        </IonButton>
      )}
      {deletable && (
        <IonButton
          fill='solid'
          className='ActionButtons__button'
          color='danger'
          onClick={_onDelete}
        >
          <IonIcon slot='icon-only' icon={trashOutline} />
        </IonButton>
      )}
    </IonButtons>
  )
}

ActionsButton.defaultProps = {
  tooltipSubmitProps: {},
}

export default ActionsButton
