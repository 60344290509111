import { useCallback, useMemo, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import type { IParsedEmailFormProps } from './type'
import { IParsedEmailFormData } from '~/types/models/IParsedEmail'
import { EFieldType } from '~/types/enums/ECommonEnum'
import _ from 'lodash'

const DialogParsedEmailForm = (props: IParsedEmailFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'subject',
        label: 'Subject',
        isRequired: true,
      },
      {
        name: 'content',
        label: 'Content',
        as: 'textarea',
        rows: 6,
        isRequired: true,
      },
      {
        name: 'fromEmail',
        label: 'From Email',
        size: 6,
        isRequired: true,
      },
      {
        name: 'fromName',
        label: 'From Name',
        size: 6,
        isRequired: true,
      },
      {
        name: 'toEmail',
        label: 'To Email',
        size: 6,
        isRequired: true,
      },
      {
        name: 'timeReceived',
        label: 'Time Received',
        size: 6,
        isRequired: true,
        type: EFieldType.date,
      },
    ],
    [],
  )

  const defaultValues = useMemo<IParsedEmailFormData>(
    () => ({
      subject: '',
      content: '',
      fromEmail: '',
      fromName: '',
      toEmail: '',
      timeReceived: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        subject: Yup.string().required('This field is required!'),
        content: Yup.string().required('This field is required!'),
        fromEmail: Yup.string().required('This field is required!').email(),
        fromName: Yup.string().required('This field is required!').email(),
        toEmail: Yup.string().required('This field is required!').email(),
        timeReceived: Yup.lazy(val => {
          if (val instanceof Date) {
            return Yup.date().required('This field is required!')
          }
          return Yup.string().required('This field is required!')
        }),
      }),
    [],
  )

  const onUpdate = useCallback(
    async (formValues: IParsedEmailFormData) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'subject',
          'content',
          'fromEmail',
          'fromName',
          'toEmail',
          'timeReceived',
        ])
        const { errors, ...response } = await apiClient.parsedEmails.update(
          formValues.id,
          {
            parsedEmail: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.createSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IParsedEmailFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default DialogParsedEmailForm
