import React, { useCallback, useMemo, useRef, useState } from 'react'

import { Form } from 'react-bootstrap'
import { ConcordFormLayout } from '~/components/shared'
import ReactSelect, { ActionMeta, PropsValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import _ from 'lodash'

import type {
  IConcordFormDropdownOption,
  IConcordFormDropdownProps,
} from './type'

import CustomMenuList from './components/CustomMenuList'
import CustomOption from './components/CustomOption'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'

import './styles.scss'

function ConcordFormDropdownV2<T extends IConcordFormDropdownOption>(
  props: IConcordFormDropdownProps<T>,
  ref: any,
) {
  const {
    options,
    onChange,
    label,
    error,
    value,
    name,
    className,
    isLoading,
    isDisabled,
    isRequired,
    getOptionLabel = ({ label }) => label || '',
    isHiddenCreateIcon,
    onClickCreateIcon,
    isOptionDisabled,
    placeholder = '',
    extraIcons,
    hint,
    isReadOnly,
    isMulti,
    formatOptionLabel,
    isCreatable,
    showCreateButton,
    showUpdateButtons,
    showDeleteButtons,
    renderForm,
    onDeleteOption,
    components,
    showSearchField,
    ...dropdownProps
  } = props

  const inputRef = useRef()
  const { confirmation } = useConfirmationProvider()

  const [formState, setFormState] = useState({
    isOpen: false,
    optionSelected: null as any,
  })

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedOption = useMemo<T | {} | T['value']>(() => {
    try {
      if (_.isPlainObject(value)) {
        return value
      }

      if (_.isNil(value)) {
        return null
      }

      if (_.isNumber(value) || _.isString(value) || _.isBoolean(value)) {
        if (options.length === 0) {
          return {
            label: value,
            value,
          }
        }
        const result = options.find(option => option.value === value) || {
          value,
          label: value,
        }
        return result
      }
      if (_.isArray(value)) {
        return value.map(val => {
          const result = options.find(option => option.value === val) || {
            value: val,
            label: val,
          }
          return result
        })
      }

      return {}
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }, [options, value])

  const onClickCreate = () => {
    setFormState({
      isOpen: true,
      optionSelected: null,
    })
  }

  const onClickDelete = async (option: any) => {
    const result = await confirmation({
      message: 'Are you sure you want to delete this item?',
    })
    if (result === EYesNo.Yes) {
      onDeleteOption && onDeleteOption(option)
    }
  }

  const onClickEdit = (option: any) => {
    setFormState({
      isOpen: true,
      optionSelected: option,
    })
  }

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      optionSelected: null,
    })
  }

  const handleSelectOption = useCallback(
    (params: SingleValue<T>, event: ActionMeta<T>) => {
      const { name } = event
      onChange &&
        onChange(event, {
          value: isMulti
            ? (params as any).map(({ value }: any) => value)
            : params?.value,
          name,
          selectedOption: params as T,
        } as any)
    },
    [isMulti, onChange],
  )

  const renderError = useMemo(() => {
    if (error) {
      return <Form.Text className='error'>{error}</Form.Text>
    }

    return null
  }, [error])

  const isDisabledStyles = useMemo(() => {
    if (isDisabled) {
      return {
        opacity: 0.3,
        userSelect: 'none',
        pointerEvents: 'none',
      }
    }
    if (isReadOnly) {
      return {
        userSelect: 'none',
        pointerEvents: 'none',
        borderColor: '#f4f4f4',
      }
    }
    return {}
  }, [isDisabled, isReadOnly])

  const SelectComponent = isCreatable ? CreatableSelect : ReactSelect

  return (
    <ConcordFormLayout
      label={label}
      error={error}
      className={className}
      isDisabled={isLoading}
      isRequired={isRequired}
      isLoading={isLoading}
      extraIcons={extraIcons}
      isHiddenCreateIcon={isHiddenCreateIcon}
      onClickCreateIcon={onClickCreateIcon}
      hint={hint}
      ref={ref}
    >
      <SelectComponent
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        classNamePrefix='ConcordFormDropdownV2'
        isMulti={isMulti as any}
        ref={inputRef as any}
        name={name}
        value={selectedOption as PropsValue<T>}
        onChange={handleSelectOption}
        isOptionDisabled={isOptionDisabled as any}
        formatOptionLabel={formatOptionLabel}
        showCreateButton={showCreateButton}
        showUpdateButtons={showUpdateButtons}
        showDeleteButtons={showDeleteButtons}
        showSearchField={showSearchField}
        onClickCreate={onClickCreate}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
        {...dropdownProps}
        components={{
          MenuList: CustomMenuList,
          Option: CustomOption,
          ...components,
        }}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: error ? 'red !important' : provided.borderColor,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(isDisabledStyles as any),
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 999999,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            display: isReadOnly ? 'none' : 'flex',
            padding: 0,
          }),
          option: (provided, state: any) => {
            return {
              ...provided,
              backgroundColor: state.data.__isNew__
                ? 'var(--ion-color-primary)'
                : state.isSelected
                ? '#2684FF'
                : undefined,
              color: state.isSelected
                ? 'white'
                : state.data.__isNew__
                ? 'white'
                : 'black',
              '&:hover': {
                backgroundColor: '#e5e5e5',
                color: 'black',
              },
            }
          },
          ...props.styles,
        }}
      />
      {renderError as JSX.Element}

      {typeof renderForm === 'function'
        ? renderForm({ ...formState, setFormState, onCloseForm })
        : null}
    </ConcordFormLayout>
  )
}

export default React.forwardRef(ConcordFormDropdownV2)
