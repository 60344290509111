import { memo, useMemo, useCallback, useState, useEffect } from 'react'

import { IonPopover, IonIcon, IonItem } from '@ionic/react'
import { caretDownOutline } from 'ionicons/icons'

import _ from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import './ButtonDropdown.scss'

const ButtonDropdown = props => {
  const { options, value, onChange, label, className } = props

  const [dropdownValue, setDropdownValue] = useState(null)
  const [popoverState, setPopoverState] = useState({
    isOpen: false,
    event: undefined,
  })

  const labelOptionSelected = useMemo(() => {
    if (value) {
      const optionSelected = _.find(options || [], { value: dropdownValue })
      return optionSelected?.label || label
    }

    return label
  }, [value, label, options, dropdownValue])

  const handleOpenMenu = useCallback(
    event => {
      if (options.length > 0) {
        setPopoverState({
          isOpen: true,
          event,
        })
      }
    },
    [options.length],
  )

  const handleCloseMenu = useCallback(() => {
    setPopoverState({ isOpen: false, event: undefined })
  }, [])

  const handleClickItem = useCallback(
    params => event => {
      const { onClick, label, value, index } = params
      onClick && onClick(event, { label, value, index })
      onChange && onChange(event, { label, value, index })
      handleCloseMenu()
    },
    [handleCloseMenu, onChange],
  )

  useEffect(() => {
    setDropdownValue(value)
  }, [value])

  return (
    <>
      <div
        className={clsx('ButtonDropdown__labelContainer', className)}
        onClick={handleOpenMenu}
      >
        <div className='ButtonDropdown__label'>{labelOptionSelected}</div>
        {options.length > 0 && (
          <IonIcon
            icon={caretDownOutline}
            className='ButtonDropdown__dropdownIcon'
          />
        )}
      </div>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.isOpen}
        onDidDismiss={handleCloseMenu}
        mode='ios'
      >
        {options.map(({ label, value, onClick }, index) => {
          return (
            <IonItem
              key={value}
              detail={false}
              onClick={handleClickItem({ onClick, label, value, index })}
              button
              lines={index === options.length - 1 ? 'none' : 'full'}
            >
              {label}
            </IonItem>
          )
        })}
      </IonPopover>
    </>
  )
}

ButtonDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      onClick: PropTypes.func,
    }),
  ),
}

ButtonDropdown.defaultProps = {
  options: [],
}

export default memo(ButtonDropdown)
