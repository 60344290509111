import { createSlice } from '@reduxjs/toolkit'

export const preProcessingTestUiSlice = createSlice({
  name: 'preProcessingTests',
  initialState: {
    tableLoading: false,
    filters: {
      createdAt: {},
      status: ['New'],
      docType: [1],
      companyId: null,
      perPage: 20,
    },
    searchWord: '',
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    setTableLoading(state, { payload }) {
      state.tableLoading = payload
    },
    setSearchWord(state, { payload }) {
      state.searchWord = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
})

export default preProcessingTestUiSlice
