import { createSlice } from '@reduxjs/toolkit'

const companyPayrollsSlice = createSlice({
  name: 'companyPayrolls',
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload
    },
  },
})

export default companyPayrollsSlice
