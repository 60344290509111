import { createSelector } from 'reselect'
import { IZoneReduxUiSlice } from '~/types/models/IZone'

export type RootReducer = {
  ui: {
    zones: IZoneReduxUiSlice
  }
}

export const selectZoneUiState = (state: RootReducer) => state.ui.zones

export const selectZonesColumns = createSelector(selectZoneUiState, uiState =>
  uiState.columns
    .filter(c => c.hideable)
    .sort((a, b) => a.displayIndex - b.displayIndex),
)

export const selectZonesSortOptions = createSelector(
  selectZoneUiState,
  uiState =>
    uiState.columns
      .filter(c => c.sortable)
      .sort((a, b) => (a.sortIndex as number) - (b.sortIndex as number)),
)
