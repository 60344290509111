import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetPreprocessingTestsParams,
  IGetPreprocessingTestsResponse,
} from '~/types/models/IPreprocessingTest'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryPreProcessingTests = (
  params: IGetPreprocessingTestsParams,
  options?: Partial<UseQueryOptions<IGetPreprocessingTestsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'preprocessingTests',
      sessionUser?.id,
      buildGetUrl(apiClient.preprocessingTests.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.preprocessingTests.get(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const preprocessingTests = useMemo(
    () => data?.preprocessingTests || [],
    [data?.preprocessingTests],
  )

  return {
    preprocessingTests,
    isPreprocessingTestsLoading: isLoading,
  }
}

export default useQueryPreProcessingTests
