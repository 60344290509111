import { createSelector } from 'reselect'

const selectUserTableConfigurations = state => {
  return state.data.userTableConfigurations
}
const getId = (_state, id) => id

export const selectUserDocumentsColumns = createSelector(
  selectUserTableConfigurations,

  userTableConfigs => userTableConfigs.documents.columnConfigurations,
)

export const selectUserLoadsColumns = createSelector(
  selectUserTableConfigurations,

  userTableConfigs => userTableConfigs.loads.columnConfigurations,
)

export const selectUserInvoicesColumns = createSelector(
  selectUserTableConfigurations,

  userTableConfigs => userTableConfigs.invoices.columnConfigurations,
)

export const selectMyTableColumns = createSelector(
  selectUserTableConfigurations,

  userTableConfigs => userTableConfigs,
)

export const selectAllUserTableConfigurations = createSelector(
  selectUserTableConfigurations,
  userTableConfigurations => userTableConfigurations,
)

export const selectUserTableConfiguration = tableName =>
  createSelector(
    selectUserTableConfigurations,
    userTableConfigurations => userTableConfigurations[tableName] || {},
  )

export const selectUserTableConfigurationById = createSelector(
  selectUserTableConfigurations,
  getId,
  (userTableConfigurations, id) => {
    return userTableConfigurations[id] || {}
  },
)
