import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const OpenIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M17 17H3V3h7V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7ZM12 1v2h3.59l-9.83 9.83 1.41 1.41L17 4.41V8h2V1h-7Z'
      />
    </svg>
  )
}
export default OpenIcon
