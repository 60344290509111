import { isValidElement, useMemo } from 'react'
import { useHandleContextMenu } from '~/components/shared'

import _ from 'lodash'

const ContextMenu = props => {
  const { children, menu, className, style } = props

  const { ref, handleClickMenu, handleRightClickMenu, renderMenuType, isOpen } =
    useHandleContextMenu(props)

  const childrenRendered = useMemo(() => {
    if (isValidElement(children)) {
      return children
    }

    if (_.isFunction(children)) {
      return children({ isOpen })
    }

    return null
  }, [children, isOpen])

  if (!menu || menu?.length === 0) {
    return childrenRendered
  }

  return (
    <div onClick={handleClickMenu(true)} className={className} style={style}>
      <span
        className='ContextMenu__container'
        ref={ref}
        onContextMenu={handleRightClickMenu}
      >
        {childrenRendered}
      </span>
      {renderMenuType(menu)}
    </div>
  )
}

// ContextMenu.propTypes = {
//   menu: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       onClick: PropTypes.func,
//       className: PropTypes.string, // Menu Context Item's props
//       labelProps: PropTypes.object, // IonText's props
//       render: PropTypes.func,
//     }),
//   ),
//   type: PropTypes.oneOf(['leftClick', 'rightClick']),
//   onRightClick: PropTypes.func,
//   onToggleMenu: PropTypes.func,
//   className: PropTypes.string,
//   style: PropTypes.object,
// }

ContextMenu.defaultProps = {
  type: 'leftClick',
}

export default ContextMenu
