import moment from 'moment'

function isDateInRange(date, startTime, endTime) {
  const momentDate = moment(date)
  const momentStartTime = moment(startTime).format('YYYY-MM-DD')
  const momentEndTime = moment(endTime).format('YYYY-MM-DD')

  // Check if the date is between the start time and end time, inclusive
  return (
    momentDate.isBetween(momentStartTime, momentEndTime) ||
    momentDate.format('YYYY-MM-DD') === momentStartTime ||
    momentDate.format('YYYY-MM-DD') === momentEndTime
  )
}

export default isDateInRange
