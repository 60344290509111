import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { IRTColumnDef } from '~/components/shared'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { EEmailTypes } from '~/types/enums/EEmail'
import { IEmail } from '~/types/models/IEmail'

import { EMAIL_TYPE_NUM_VALUE_OPTIONS } from '~/utils/constants'

const useTableCompanyEmails = () => {
  const columns = useMemo<IRTColumnDef<IEmail>[]>(
    () => [
      {
        header: 'Email',
        accessorKey: 'email',
        size: 300,
      },
      {
        header: 'Email Types',
        accessorKey: 'emailTypes',
        size: 200,
        filterSelectOptions: EMAIL_TYPE_NUM_VALUE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<EEmailTypes[]>()
          const emailTypes = cellValue.map(val => _.startCase(EEmailTypes[val]))

          return emailTypes.join(', ')
        },
      },
      {
        header: 'Created At',
        id: 'createdAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.createdAt).format('lll')
        },
      },
      {
        header: 'Updated At',
        id: 'updatedAt',
        accessorFn(rowData) {
          return moment(rowData.updatedAt).format('lll')
        },
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyEmails
