import { useCallback, useState, useEffect, useMemo } from 'react'

import { ConcordFormLayout, ToolTipOverlay } from '~/components/shared'
import { IonIcon, IonButton, IonButtons, IonText } from '@ionic/react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons'
import { produce } from 'immer'
import TooltipSlider from './TooltipSlider'

import './ConcordFormMultipeNumberRange.scss'

function ConcordFormMultipeNumberRange(props) {
  const {
    onChange,
    value: valueProps,
    label,
    className,
    error,
    min,
    max,
  } = props

  const [numberRangeValue, setNumberRangeValue] = useState([]) // array of number[]

  const isCreating = useMemo(
    () =>
      numberRangeValue.some(
        ([minValue, maxValue]) => _.isNil(minValue) || _.isNil(maxValue),
      ),
    [numberRangeValue],
  )

  const onAddNumberRange = useCallback(() => {
    setNumberRangeValue(prev => [...prev, []])
  }, [])

  const onChangeNumberRange = useCallback(
    index => selectedNumberRange => {
      const newNumberRange = produce(numberRangeValue, draft => {
        draft[index] = selectedNumberRange
      })

      onChange && onChange({}, { value: newNumberRange })
      setNumberRangeValue(newNumberRange)
    },
    [numberRangeValue, onChange],
  )

  const onRemoveNumberRange = useCallback(
    index => () => {
      const newNumberRangeValue = produce(numberRangeValue, draft => {
        draft.splice(index, 1)
      })
      setNumberRangeValue(newNumberRangeValue)
      onChange && onChange({}, { value: newNumberRangeValue })
    },
    [numberRangeValue, onChange],
  )

  const renderNumberRangeField = useCallback(() => {
    return numberRangeValue.map((numberRange, index) => (
      <div className='ConcordFormMultipeNumberRange__numberRange' key={index}>
        <div className='ConcordFormMultipeNumberRange__labelRange'>
          Value: {numberRange[0]} - {numberRange[1]}
        </div>
        <TooltipSlider
          range
          defaultValue={numberRange}
          marks={{ [max]: max }}
          allowCross={false}
          min={min}
          max={max}
          className='ConcordFormMultipeNumberRange__slider'
          tipFormatter={value => `${value}`}
          onChange={onChangeNumberRange(index)}
        />
        <ToolTipOverlay content='Remove' placement='top'>
          <span
            className='icon ConcordFormMultipeNumberRange__removeNumberRange'
            onClick={onRemoveNumberRange(index)}
          >
            <IonIcon color='danger' icon={removeCircleOutline} />
          </span>
        </ToolTipOverlay>
      </div>
    ))
  }, [max, min, numberRangeValue, onChangeNumberRange, onRemoveNumberRange])

  useEffect(() => {
    setNumberRangeValue(valueProps || [])
  }, [valueProps])

  return (
    <ConcordFormLayout
      label={
        <div className='ConcordFormMultipeNumberRange__labelRoot'>
          <span>{label}</span>
          <IonButtons>
            <IonButton
              slot='icon-only'
              onClick={onAddNumberRange}
              disabled={isCreating}
            >
              <IonIcon color='concord' icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
          {error && <IonText>{error}</IonText>}
        </div>
      }
      className={clsx('ConcordFormMultipeNumberRange__root', className)}
      error={error}
    >
      {renderNumberRangeField()}
    </ConcordFormLayout>
  )
}

ConcordFormMultipeNumberRange.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

export default ConcordFormMultipeNumberRange
