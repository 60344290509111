import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useQueryDriversNew } from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { ToggleSection } from '../../index'
import {
  CheckSignatoryForm,
  ConcordFormStructure,
  ICheckSignatoryFormValues,
  IConcordFormField,
} from '~/components/shared'
import { Unless, When } from 'react-if'

import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import loadCheckComponent from '~/utils/loadCheckComponent'
import { EFieldType } from '~/types/enums/ECommonEnum'
import * as Yup from 'yup'

import type { ICheckComponentProps, ICheckTaxFormValues } from './type'
import type { INullOrUndefined } from '~/types/models/ICommonModel'

import './styles.scss'

function CheckComponent(props: ICheckComponentProps) {
  const {
    label,
    apiMethod,
    getApiPayload,
    name,
    isOpenComponentOnMounted,
    checkUid,
    isShowFieldsForTax,
    taxValues,
    onChangeTaxValues,
    onEvent,
    isOpen,
    unNeededUid,
    icons = [],
    id = '',
  } = props

  const [checkEmbeddedLink, setCheckEmbeddedLink] = useState('')
  const [isOpenSection, setIsOpenSection] = useState(false)
  const [taxFormValues, setTaxFormValues] = useState<ICheckTaxFormValues>({
    federalOnly: false,
    jurisdiction: '',
  })
  const [handlerCheckComponent, setHandlerCheckComponent] =
    useState<INullOrUndefined | { close: () => void }>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const { stateOptions } = useQueryDriversNew()

  const idElement = useMemo(
    () => `CheckComponent__componentContainer_${name}__${id}`,
    [name, id],
  )

  const idParentElement = useMemo(() => {
    const parentId = _.uniqueId('parent_id_')
    return `CheckComponent__componentContainer_${name}_${parentId}_${id}`
  }, [id, name])

  const taxFields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Jurisdiction',
        name: 'jurisdiction',
        placeholder: 'Ex: NY',
        size: 12,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: stateOptions,
      },
      {
        label: 'Federal Only',
        name: 'federalOnly',
        size: 6,
        type: EFieldType.checkbox,
      },
    ],
    [stateOptions],
  )

  const schema = Yup.object({
    jurisdiction: Yup.string().required('This field is required!'),
  })

  const onSubmitForm = useCallback(
    async (data?: ICheckSignatoryFormValues) => {
      if (apiMethod && getApiPayload) {
        setIsLoading(true)
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const payload = getApiPayload({ ...data, ...taxFormValues } as any)
          const { body, url: urlResponse, link } = await apiMethod(payload)
          if (!body && (urlResponse || link)) {
            setCheckEmbeddedLink(link || urlResponse)
            setIsOpenSection(true)
          } else {
            const { url, error } = JSON.parse(body)
            if (error) {
              toast.error(toastMessages.createError)
            } else {
              setCheckEmbeddedLink(url)
              setIsOpenSection(true)
            }
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [apiMethod, getApiPayload, taxFormValues],
  )

  const onToggle = useCallback(
    async (nextValue: boolean) => {
      if (nextValue && !unNeededUid && !checkUid) {
        await confirmation({
          message: 'You need to select a signatory first',
          header: 'Warning!',
          buttons: [
            {
              text: 'Ok',
              action: 'OK',
            },
          ],
        })
      } else {
        setIsOpenSection(nextValue)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkUid, unNeededUid],
  )

  const closeCheckComponent = useCallback(() => {
    if (handlerCheckComponent) {
      handlerCheckComponent.close()
      setHandlerCheckComponent(null)
    }
  }, [handlerCheckComponent])

  const onSubmitTaxForm = useCallback(
    (formValues: ICheckTaxFormValues) => {
      setTaxFormValues({
        jurisdiction: formValues.jurisdiction,
        federalOnly: formValues.federalOnly,
      })
      onChangeTaxValues &&
        onChangeTaxValues({
          jurisdiction: formValues.jurisdiction,
          federalOnly: formValues.federalOnly,
        })
    },
    [onChangeTaxValues],
  )

  useEffect(() => {
    if (checkEmbeddedLink) {
      setTimeout(() => {
        const handler = loadCheckComponent(checkEmbeddedLink, idElement, {
          parentId: idParentElement,
          onEvent,
        })
        setHandlerCheckComponent(handler)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkEmbeddedLink, idElement, idParentElement])

  useEffect(() => {
    if (
      isOpenComponentOnMounted &&
      isOpenSection &&
      !checkEmbeddedLink &&
      !isShowFieldsForTax
    ) {
      onSubmitForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkEmbeddedLink,
    isOpenComponentOnMounted,
    isOpenSection,
    isShowFieldsForTax,
  ])

  useUpdateEffect(() => {
    setCheckEmbeddedLink('')
    closeCheckComponent()
  }, [checkUid])

  useUpdateEffect(() => {
    if (isOpenSection && taxFormValues.jurisdiction) {
      onSubmitForm()
      closeCheckComponent()
    }
  }, [taxFormValues, isOpenSection])

  useEffect(() => {
    if (typeof taxValues === 'object' && !_.isEqual(taxValues, taxFormValues)) {
      setTaxFormValues(taxValues)
    }
  }, [taxFormValues, taxValues])

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setIsOpenSection(isOpen)
    }
  }, [isOpen])

  return (
    <ToggleSection
      title={label}
      isOpen={isOpenSection}
      onToggle={onToggle}
      icons={icons}
      name={name}
      isLoading={isLoading}
    >
      <Unless condition={isOpenComponentOnMounted}>
        <CheckSignatoryForm onSubmit={onSubmitForm} />
      </Unless>
      <When condition={isShowFieldsForTax}>
        <ConcordFormStructure
          fields={taxFields}
          isHiddenCancelButton
          isHiddenSearch
          className='CheckComponent__taxForm'
          schema={schema}
          formData={taxFormValues}
          onSubmit={onSubmitTaxForm}
        />
      </When>
      <div id={idElement} style={{ height: checkEmbeddedLink ? 850 : 0 }}></div>
    </ToggleSection>
  )
}
export default CheckComponent
