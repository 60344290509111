import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { IonText } from '@ionic/react'
import { CompanyInfo, IRTColumnDef, ToolTipOverlay } from '~/components/shared'

import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryBuyerSellers,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { IFixedPrice } from '~/types/models/IFixedPrice'
import { ICompany } from '~/types/models/ICompany'
import { EFixedPrice } from '~/types/enums/EFixedPrice'
import { FIXED_PRICE_ITEM_TYPE_OPTIONS } from '~/utils/constants'
import _ from 'lodash'

interface IProps {
  fixedPricesData: IFixedPrice[]
}

const useTableCompanyEmailParserSubTab = ({ fixedPricesData }: IProps) => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const sellerTerminalIds = _(fixedPricesData)
    .map('sellerTerminalId')
    .uniq()
    .value()

  const { sellerCompanies } = useQueryBuyerSellers()
  const { sellerTerminalOptions, buyerTerminalOptions, terminalsData } =
    useQueryTerminals()

  const sellers = useMemo(() => {
    if (currentScope === EScope.seller) {
      return [currentCompany, ...sellerCompanies]
    }
    return sellerCompanies
  }, [currentCompany, currentScope, sellerCompanies])

  const sellerIds = _(sellerTerminalIds)
    .map(id => {
      const terminal = terminalsData.find(c => c.id === id)
      const seller = sellers.find(c => c.id === terminal?.companyId)

      return seller?.id
    })
    .uniq()
    .value()
    .filter(num => num)

  const { sellerProducts, sellerProductOptions } = useQuerySellerProducts(
    {
      filters: {
        sellerId: sellerIds as number[],
      },
    },
    { enabled: sellerIds.length > 0 },
  )

  const { findTerminalById } = useQueryTerminals()

  const columns = useMemo<IRTColumnDef<IFixedPrice>[]>(
    () => [
      {
        header: 'Seller',
        id: 'sellerId',
        size: 250,
        Cell({ row }) {
          const terminal = terminalsData.find(
            ({ id }) => id === row.original.sellerTerminalId,
          )
          const seller = sellers.find(({ id }) => id === terminal?.companyId)

          return (
            <CompanyInfo
              companyType={EScope.seller}
              defaultCompanyCode='Seller'
              company={{
                value: seller?.id,
                name: seller?.name,
                code: seller?.code,
                image: seller?.imageUrl,
                label: seller ? `${seller.code} - ${seller.name}` : 'Unknown',
              }}
            />
          )
        },
      },
      {
        header: 'Seller Terminal',
        id: 'sellerTerminalId',
        filterSelectOptions: sellerTerminalOptions,
        filterVariant: EFieldType.multipleSelect,
        accessorFn({ sellerTerminalId }) {
          const terminal = findTerminalById(sellerTerminalId)
          return terminal ? terminal?.code : ''
        },
        size: 200,
        Cell({ renderedCellValue, row }) {
          const sellerTerminal = findTerminalById(row.original.sellerTerminalId)
          return (
            <ToolTipOverlay content={sellerTerminal?.name} placement='top'>
              <IonText>{renderedCellValue}</IonText>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Buyer Terminal',
        id: 'buyerTerminalId',
        filterSelectOptions: buyerTerminalOptions,
        filterVariant: EFieldType.multipleSelect,
        accessorFn({ buyerTerminalId }) {
          const terminal = findTerminalById(buyerTerminalId)
          return terminal ? terminal?.code : ''
        },
        size: 200,
        Cell({ renderedCellValue, row }) {
          const terminal = findTerminalById(row.original.buyerTerminalId)

          return (
            <ToolTipOverlay content={terminal?.name} placement='top'>
              <IonText>{renderedCellValue}</IonText>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Date',
        accessorKey: 'date',
        Cell({ row }) {
          const rowData = row.original
          if (rowData.startDate && rowData.endDate) {
            return `${rowData.startDate} → ${rowData.endDate}`
          }
          return '-'
        },
      },
      {
        header: 'Item Type',
        accessorKey: 'itemType',
        filterSelectOptions: FIXED_PRICE_ITEM_TYPE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        size: 150,
        Cell({ cell }) {
          const cellValue = cell.getValue<any>()
          const itemType = EFixedPrice[cellValue]
          if (typeof itemType === 'number') {
            return FIXED_PRICE_ITEM_TYPE_OPTIONS.find(
              ({ value }) => value === itemType,
            )?.label
          }
          return null
        },
      },
      {
        header: 'Product',
        accessorKey: 'pricedProductId',
        filterSelectOptions: sellerProductOptions,
        filterVariant: EFieldType.multipleSelect,
        size: 200,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const prod = sellerProducts.find(({ id }) => id === cellValue)
          return (
            <ToolTipOverlay content={prod?.code} placement='top'>
              <span>{prod?.name}</span>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Price',
        accessorKey: 'price',
        size: 120,
        filterVariant: EFieldType.number,
        enableEditing: true,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          decimalScale: 2,
          fixedDecimalScale: true,
        }),
      },
      {
        header: 'Discount',
        accessorKey: 'discount',
        size: 120,
        filterVariant: EFieldType.number,
        enableEditing: true,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          decimalScale: 2,
          fixedDecimalScale: true,
        }),
      },
      {
        header: 'Flat Rate',
        accessorKey: 'flatRate',
        size: 150,
        filterVariant: EFieldType.number,
        enableEditing: true,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          decimalScale: 2,
          fixedDecimalScale: true,
        }),
      },
      {
        header: 'Net Price',
        accessorKey: 'netPrice',
        size: 120,
      },
    ],
    [
      buyerTerminalOptions,
      findTerminalById,
      sellerProductOptions,
      sellerProducts,
      sellerTerminalOptions,
      sellers,
      terminalsData,
    ],
  )

  return { columns }
}

export default useTableCompanyEmailParserSubTab
