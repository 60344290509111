import { useContext } from 'react'

import PayrollReportFormValuesContext from './PayrollReportFormValuesContext'

const usePayrollReportFormValuesProvider = () => {
  const context = useContext(PayrollReportFormValuesContext)
  return context
}

export default usePayrollReportFormValuesProvider
