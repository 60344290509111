import { CommonDialogV2 } from '~/components/shared'
import DataCorrectionMethodForm from './DataCorrectionMethodForm'

import type { IDialogDataCorrectionMethodFormProps } from './type'

function DialogDataCorrectionMethodForm(
  props: IDialogDataCorrectionMethodFormProps,
) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props
  return (
    <CommonDialogV2
      {...dialogProps}
      title='Data Correction Method'
      isHiddenOkButton
      size='lg'
    >
      <DataCorrectionMethodForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogDataCorrectionMethodForm
