import { formatMetaData, mapPropertyIds, colorArray } from '~/utils'

export const formatBarGraphCard = (dashboardData, buyerTerminals) => {
  const newBuyerTerminals = formatMetaData(buyerTerminals)
  const formattedTerminals = mapPropertyIds(
    dashboardData,
    newBuyerTerminals,
  ).map((entry, index) => {
    return [entry[0], parseInt(entry[1]), colorArray[index]]
  })
  formattedTerminals.unshift(['Buyer Terminal', 'QTY', { role: 'style' }])

  return formattedTerminals
}
