import { CommonDialogV2 } from '~/components/shared'

import EarningRateForm from './EarningRateForm'

import './DialogEarningRateForm.scss'

function DialogEarningRateForm(props) {
  const {
    afterCreate,
    afterUpdate,
    formData,
    ...dialogProps
  } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title="Earning Rate"
      isHiddenOkButton
    >
      <EarningRateForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogEarningRateForm
