import { useMemo, useState, useCallback, memo } from 'react'
import useFindCompany from '~/hooks/useFindCompany'

import { IonButton, IonIcon } from '@ionic/react'
import FormEmailParsers from './FormEmailParsers'
import { ContainerSearchBar, ToolTipOverlay } from '~/components/shared'
import { Badge } from 'react-bootstrap'

import { add } from 'ionicons/icons'
import clsx from 'clsx'

import './SectionEmailParsers.scss'
import _ from 'lodash'

const SectionEmailParsers = props => {
  const {
    onSelect,
    selectedParser,
    onUpdate,
    emailParsers,
    onChangeParser,
    onResetChangesParser,
    onClickCreateParser,
    isCreating,
    onSubmitCreateParser,
    loadParsers,
    concordDocument,
  } = props

  const [searchValue, setSearchValue] = useState('')
  const [isFocusingSearchBar, setIsFocusingSearchBar] = useState(false)

  const { findCompany } = useFindCompany()

  const mapEmailParser = useMemo(() => {
    return emailParsers.map(({ buyerId, sellerId, ...parser }) => {
      const buyer = findCompany(buyerId)
      const seller = findCompany(sellerId)

      return {
        ...parser,
        buyerId,
        sellerId,
        buyer,
        seller,
      }
    })
  }, [findCompany, emailParsers])

  const isDisplaySearchBar = useMemo(() => {
    const originalParsers = mapEmailParser.filter(({ id }) => id !== -1)

    return originalParsers.length > 1
  }, [mapEmailParser])

  const filterEmailParsers = useMemo(() => {
    return mapEmailParser.map(parser => {
      const {
        seller,
        buyer,
        docTypeValue,
        buyerRegex,
        docTypeRegex,
        sellerRegex,
      } = parser
      const sellerCode = _.lowerCase(seller.code)
      const sellerName = _.lowerCase(seller.name)
      const buyerCode = _.lowerCase(buyer.code)
      const buyerName = _.lowerCase(buyer.name)
      const lowerBuyerRegex = _.lowerCase(buyerRegex)
      const lowerSellerRegex = _.lowerCase(docTypeRegex)
      const lowerDocTypeRegex = _.lowerCase(sellerRegex)

      const lowerSearchValue = _.lowerCase(searchValue)

      const matchedText = [
        sellerCode,
        sellerName,
        buyerCode,
        buyerName,
        docTypeValue,
        lowerBuyerRegex,
        lowerSellerRegex,
        lowerDocTypeRegex,
      ]

      const isMatched = matchedText.some(text =>
        text.includes(lowerSearchValue),
      )

      return {
        ...parser,
        isMatched: isMatched || parser.id === -1, // create parser value
      }
    })
  }, [mapEmailParser, searchValue])

  const countBadge = useMemo(() => {
    return filterEmailParsers.filter(({ isMatched }) => isMatched).length
  }, [filterEmailParsers])

  const handleChangeSearch = useCallback(keyword => {
    setSearchValue(keyword)
  }, [])

  const handleFocusSearchBar = useCallback(() => {
    setIsFocusingSearchBar(true)
  }, [])

  const handleBlurSearchBar = useCallback(() => {
    setIsFocusingSearchBar(false)
  }, [])

  const emailParserRendered = useMemo(() => {
    return filterEmailParsers.map((emailParser, index) => (
      <FormEmailParsers
        className={clsx({
          SectionEmailParsers__hideFormParser: !emailParser.isMatched,
        })}
        loading={loadParsers.includes(emailParser.id)}
        key={`FormEmailParser_${emailParser.id}`}
        emailParser={emailParser}
        onUpdate={onUpdate}
        onSelect={onSelect}
        isSelected={selectedParser.id === emailParser.id}
        onChangeParser={onChangeParser}
        index={index}
        onResetChangesParser={onResetChangesParser}
        onSubmit={onSubmitCreateParser}
      />
    ))
  }, [
    filterEmailParsers,
    loadParsers,
    onChangeParser,
    onResetChangesParser,
    onSelect,
    onSubmitCreateParser,
    onUpdate,
    selectedParser.id,
  ])

  return (
    <div className='SectionEmailParsers__container'>
      <div className='SectionEmailParsers__header'>
        <div className='SectionEmailParsers__titleContainer'>
          <span className='SectionEmailParsers__title'>Email Parsers</span>
          {emailParsers?.length > 0 && (
            <ToolTipOverlay
              content={
                emailParsers.length > 1
                  ? `${emailParsers.length} Email Parsers`
                  : `${emailParsers.length} Email Parser`
              }
              placement='top'
            >
              <Badge className='SectionEmailParsers__badgeTotal' bg='danger'>
                {emailParsers.length}
              </Badge>
            </ToolTipOverlay>
          )}
        </div>
        {isDisplaySearchBar && (
          <ContainerSearchBar
            searchBarPlaceholder='Search by seller, buyer or doc type'
            searchBarValue={searchValue}
            onSearchBarChange={handleChangeSearch}
            onFocus={handleFocusSearchBar}
            onBlur={handleBlurSearchBar}
            className={clsx('SectionEmailParsers__searchBar', {
              isFocusingSearchBar,
            })}
            countBadge={countBadge}
            showBadge={searchValue.length > 0 && countBadge > 0}
          />
        )}

        {!isFocusingSearchBar && (
          <ToolTipOverlay
            content={
              concordDocument.sellerId
                ? 'Create Email Parser'
                : 'Set seller before creating an email parser'
            }
            placement='top'
          >
            <IonButton
              onClick={onClickCreateParser}
              color={isCreating ? 'danger' : 'primary'}
              className={clsx('SectionEmailParsers__createButton', {
                hideSearchBar: !isDisplaySearchBar,
              })}
              disabled={!concordDocument.sellerId}
            >
              <IonIcon
                slot='icon-only'
                className={clsx('SectionEmailParsers__createIcon', {
                  close: isCreating,
                })}
                icon={add}
              />
            </IonButton>
          </ToolTipOverlay>
        )}
      </div>
      <div className='SectionEmailParsers__body'>{emailParserRendered}</div>
    </div>
  )
}

export default memo(SectionEmailParsers)
