import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import { produce } from 'immer'

import type { IUser } from '~/types/models/IUser'
import type { IFlagableConfiguration } from '~/types/models/IFlagableConfiguration'

const useModifyFlagableConfigurations = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'flagableConfigurations',
      sessionUser?.id,
      buildGetUrl(apiClient.flagableConfigurations.endpoint, params),
      sessionUser?.currentScope,
    ],
    [params, sessionUser?.currentScope, sessionUser?.id],
  )

  const addFlagableConfiguration = useCallback(
    (data: IFlagableConfiguration) => {
      queryClient.setQueryData<IFlagableConfiguration[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateFlagableConfiguration = useCallback(
    (id: number, data: IFlagableConfiguration) => {
      queryClient.setQueryData<IFlagableConfiguration[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft[index] = data
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeFlagableConfiguration = useCallback(
    (id: number) => {
      queryClient.setQueryData<IFlagableConfiguration[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addFlagableConfiguration,
    updateFlagableConfiguration,
    removeFlagableConfiguration,
  }
}

export default useModifyFlagableConfigurations
