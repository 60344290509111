import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetCheckSetupCompanyResponse } from '~/types/models/ICheck'
import { useCallback, useMemo } from 'react'
import { ICommonOption } from '~/types/models/ICommonModel'
import _ from 'lodash'
import moment from 'moment'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCheckCompanyDetails = (
  companyCheckUid: string,
  options?: Partial<UseQueryOptions<IGetCheckSetupCompanyResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryClient = useQueryClient()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['checkCompany', sessionUser?.id, companyCheckUid],
    queryFn() {
      return apiClient.check.getCompanyById(companyCheckUid)
    },
    enabled: Boolean(sessionUser?.id && companyCheckUid),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const renewCheckCompanyDetails = useCallback(
    (data: IGetCheckSetupCompanyResponse) => {
      queryClient.setQueryData<IGetCheckSetupCompanyResponse | undefined>(
        ['checkCompany', sessionUser?.id, companyCheckUid],
        oldData => {
          if (oldData) {
            return {
              ...oldData,
              ...data,
            }
          }
        },
      )
    },
    [companyCheckUid, queryClient, sessionUser?.id],
  )

  const isImplemtationComplete = useMemo(
    () => data?.implementation?.status === 'completed',
    [data?.implementation?.status],
  )

  const yearOptions = useMemo(() => {
    if (data) {
      const companyYear = data.startDate.split('-')[0]
      const numYear = parseInt(companyYear)
      const currentYear = moment().get('years')
      const options: ICommonOption[] = []
      for (let i = currentYear; i <= numYear; i++) {
        options.push({
          label: i.toString(),
          value: i,
        })
      }
      return _.orderBy(options, ['value'], ['desc'])
    }
    return []
  }, [data])

  return {
    checkCompanyDetails: data,
    refetchCheckCompanyDetails: refetch,
    isLoadingCheckCompany: isLoading,
    isImplemtationComplete,
    renewCheckCompanyDetails,
    yearOptions,
  }
}

export default useQueryCheckCompanyDetails
