import { useEffect, useCallback, useState, useMemo } from 'react'
import { useWindowSize } from 'react-use'
import useTableEarningRates from './useTableEarningRates'

import { DialogEarningRateForm, ReusableTable } from '~/components/shared'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

import './TableEarningRates.scss'
import getErrorFromCheckResponse from '~/utils/getErrorFromCheckResponse'
import { moduleName } from '~/utils/constants'
import { useQueryUsers } from '~/hooks/useQueryData'

function TableEarningRates(props) {
  const { employeeUid, tableHeight } = props

  const windowSize = useWindowSize()

  const [earningRates, setEarningRates] = useState([])
  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })

  const isEmployeeView = useMemo(() => Boolean(employeeUid), [employeeUid])

  const { isLoadingUsersData } = useQueryUsers()

  const isTableLoading = isLoadingUsersData || loading

  const onDbClickStatus = useCallback(async (event, earningRate) => {
    apiClient.check.updateEarningRate(earningRate.id, {
      earningRate: {
        active: !earningRate.active,
      },
    })
    setEarningRates(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(({ id }) => id === earningRate.id)
        if (index !== -1) {
          draft[index].active = !earningRate.active
        }
      }),
    )
  }, [])

  const { columns } = useTableEarningRates({
    onDbClickStatus,
    isEmployeeView,
  })

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const fetchEarningRates = useCallback(async () => {
    setLoading(true)
    const response = await apiClient.check.getEarningRates({
      earningRate: {
        employee: employeeUid,
      },
    })
    setEarningRates(response.results)
    setLoading(false)
  }, [employeeUid])

  const afterCreate = useCallback(
    () => {
      fetchEarningRates()
      onCloseForm()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const afterUpdate = useCallback(
    response => {
      setEarningRates(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => response.id === id)
          if (index !== -1) {
            draft[index] = {
              ...draft[index],
              ...response,
            }
          }
        }),
      )
      onCloseForm()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id

      const response = await apiClient.check.updateEarningRate(rowId, {
        earningRate: {
          [columnField]: value,
        },
      })
      const error = getErrorFromCheckResponse(response)
      if (error) {
        toast.error(toastMessages.updateError)
      } else {
        toast.success(toastMessages.updateSuccess)
        setEarningRates(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => rowId === id)
            if (index !== -1) {
              draft[index] = {
                ...draft[index],
                [columnField]: value,
              }
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  useEffect(() => {
    fetchEarningRates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          data={earningRates}
          columns={columns}
          tableHeight={tableHeight || windowSize.height - 220}
          enableTopToolbar
          enableCompanyView
          companyViewProps={{
            name: moduleName.company.earningRates,
            description: 'Manage your Earning rates.',
            onClickTopButton() {
              setFormState({
                isOpen: true,
                formData: undefined,
              })
            },
          }}
          state={{
            isLoading: isTableLoading,
          }}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogEarningRateForm
        isOpen={formState?.isOpen}
        formData={formState?.formData}
        onClose={onCloseForm}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </>
  )
}

export default TableEarningRates
