import useLoadStatusModal from './useLoadStatusModal'

import { ListGroup } from 'react-bootstrap'
import { CommonDialogV2 } from '~/components/shared'

import type { ILoadStatusModalProps } from './type'

import './styles.scss'

function LoadStatusModal(props: ILoadStatusModalProps) {
  const { isOpen, onClose, renderStatuses } = useLoadStatusModal(props)

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      title='Load Status'
      isHiddenOkButton
    >
      <ListGroup>{renderStatuses}</ListGroup>
    </CommonDialogV2>
  )
}
export default LoadStatusModal
