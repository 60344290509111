import { useCallback, useState, useEffect } from 'react'

import { ConcordFormLayout } from '~/components/shared'
import { Form } from 'react-bootstrap'
import NumericFormat from 'react-number-format'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import './ConcordFormNumberRangeField.scss'
import { IonText } from '@ionic/react'
import _ from 'lodash'

function ConcordFormNumberRangeField(props) {
  const {
    onChange,
    value: valueProps,
    label,
    disabled,
    loading,
    className,
    error,
    name,
    ...numberFieldProps
  } = props

  const [rangeValue, setRangeValue] = useState([])

  const minValue = _.isNil(rangeValue[0]) ? undefined : Number(rangeValue[0])
  const maxValue = _.isNil(rangeValue[1]) ? undefined : Number(rangeValue[1])

  const onChangeMinValue = useCallback(
    ({ value }) => {
      setRangeValue([value, maxValue])
    },
    [maxValue],
  )

  const onChangeMaxValue = useCallback(
    ({ value }) => {
      setRangeValue([minValue, value])
    },
    [minValue],
  )

  useEffect(() => {
    setRangeValue(valueProps || [])
  }, [valueProps])

  return (
    <ConcordFormLayout
      label={label}
      className={clsx('ConcordFormNumberRangeField__root', className)}
      error={error}
    >
      <NumericFormat
        value={minValue}
        customInput={Form.Control}
        onValueChange={onChangeMinValue}
        error={error}
        placeholder='Min'
        onBlur={event => {
          onChange && onChange(event, { value: rangeValue })
        }}
        // isAllowed={({ floatValue }) => {
        //   if (_.isNil(maxValue) || _.isNil(floatValue)) {
        //     return true
        //   }

        //   return floatValue <= maxValue
        // }}
        {...numberFieldProps}
      />
      <IonText className='ConcordFormNumberRangeField__space'>-</IonText>
      <NumericFormat
        value={maxValue}
        customInput={Form.Control}
        onValueChange={onChangeMaxValue}
        error={error}
        placeholder='Max'
        onBlur={event => {
          onChange && onChange(event, { value: rangeValue })
        }}
        // isAllowed={({ floatValue }) => {
        //   if (_.isNil(minValue) || _.isNil(floatValue)) {
        //     return true
        //   }

        //   return floatValue >= maxValue
        // }}
        {...numberFieldProps}
      />
      {error && <Form.Text>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

ConcordFormNumberRangeField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

export default ConcordFormNumberRangeField
