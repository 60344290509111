import { IonText, IonBadge } from '@ionic/react'

import PropTypes from 'prop-types'

const LoadFlags = ({ index, flag }) => (
  <IonText key={index}>
    <IonText style={{ fontSize: '18px' }}>
      <IonBadge
        color='danger'
        style={{
          marginLeft: index == 0 ? 0 : '20px',
          padding: '2px 4px',
        }}
      >
        {index + 1}
      </IonBadge>{' '}
      {flag.message}{' '}
    </IonText>
  </IonText>
)

LoadFlags.propTypes = {
  index: PropTypes.number,
  flag: PropTypes.shape({
    message: PropTypes.string,
  }),
}

export default LoadFlags
