import { useCallback, useMemo } from 'react'

import { Dropdown } from 'react-bootstrap'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './SingleSelect.scss'

function SingleSelect(props) {
  const { options, value, label, onChange, className } = props

  const mapOptions = useMemo(() => {
    if (_.isArray(options)) {
      return _.map(options, option => {
        if (_.isPlainObject(option)) {
          return option
        }

        return {
          label: option,
          value: option,
        }
      })
    }

    return []
  }, [options])

  const mapValue = useMemo(() => {
    if (_.isNil(value)) {
      return ''
    }

    const option = _.find(mapOptions, option => option.value == value)

    return option?.label
  }, [mapOptions, value])

  const optionRendered = useMemo(() => mapOptions.map(({ label, value }) => (
    <Dropdown.Item eventKey={value} key={value}>
      {label}
    </Dropdown.Item>
  )), [mapOptions])

  const handleSelectDropdown = useCallback(
    selectedValue => {
      onChange && onChange(selectedValue)
    },
    [onChange],
  )

  return (
    <Dropdown
      onSelect={handleSelectDropdown}
      className={clsx('SingleSelect__container', className)}
    >
      <Dropdown.Toggle variant='primary' id='dropdown-basic'>
        {label}: {mapValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>{optionRendered}</Dropdown.Menu>
    </Dropdown>
  )
}
SingleSelect.propTypes = {
  // options: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     label: PropTypes.string.isRequired,
  //     value: PropTypes.number.isRequired,
  //   }),
  // ).isRequired,
  options: PropTypes.any,
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}

export default SingleSelect
