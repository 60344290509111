import type { ISvgIconProps } from './type'

const CheckHqIcon = (props: ISvgIconProps) => {
  const { size = 16, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 328 328'
      {...svgProps}
    >
      <path
        fill={color}
        d='M170.7 69.1c-25.1 3.1-56.2 20-73.9 40.1C75 134 63.6 165.1 66.9 190.4c4.3 31.8 26.1 53 58.4 56.6 23.7 2.7 49-4.4 73.7-20.9 8.7-5.7 25.4-21.3 24.8-23-.1-.6-8.9-6.9-19.4-14l-19.1-13-3.9 4c-15.2 15.6-39.5 18.6-51.7 6.4-21.4-21.3 5-64.4 39.4-64.5 10.7 0 20.9 5.8 24.5 13.9l1.7 3.9 26.9-13.4c14.7-7.4 26.8-13.6 26.8-13.9 0-2.5-7.1-15.1-11.5-20.5-14.8-18-38.9-26.3-66.8-22.9z'
      />
    </svg>
  )
}

export default CheckHqIcon
