import { createContext } from 'react'
import { IPayrollReportFormValuesContextProps } from './type'

const PayrollReportFormValuesContext =
  createContext<IPayrollReportFormValuesContextProps>({
    reportFormValues: {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      content: 'csv',
      payroll: [],
      includeContractors: false,
      includeEmployeeId: false,
      date: new Date(),
    },
    onChangeReportFormValues() {},
  })

export default PayrollReportFormValuesContext
