import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const TruckIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 50 50'
      fill={color}
      {...svgProps}
    >
      <path d='M10 3v10h10V3H10zm23 10v14H1a1 1 0 0 0-1 1v6c0 2.206 1.794 4 4 4h4.09c.478 2.833 2.942 5 5.91 5s5.431-2.167 5.91-5H33c.48 2.832 3.031 5 6 5s5.43-2.168 5.906-5H47c1.57 0 3-1.43 3-3v-9.625c0-2.012-1.449-4.067-1.625-4.313L44.219 15.5C43.266 14.352 41.77 13 40 13h-7zM4 15v10h10V15H4zm12 0v10h10V15H16zm22 3h5.594l3.187 4.25c.34.48 1.219 1.953 1.219 3.125V26H38c-1 0-2-1-2-2v-4c0-1.105 1-2 2-2zM14 33c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zm25 0c2.207 0 4 1.793 4 4s-1.793 4-4 4-4-1.793-4-4 1.793-4 4-4z' />
    </svg>
  )
}

export default TruckIcon
