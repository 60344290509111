import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import companyBankAccountsUiSlice from '~/redux/reducers/ui/companyBankAccounts'
import { apiClient } from '~/api/ApiClient'

export const fetchCompanyBankAccounts = createAsyncThunk(
  'fetchCompanyBankAccounts',
  async (payload, { dispatch }) => {
    dispatch(companyBankAccountsUiSlice.actions.setLoadingTable(true))
    try {
      const { bankAccounts } = await apiClient.bankAccounts.get()
      dispatch(companyBankAccountsSlice.actions.index(bankAccounts))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(companyBankAccountsUiSlice.actions.setLoadingTable(false))
    }
  },
)

const companyBankAccountsSlice = createSlice({
  name: 'companyBankAccounts',
  initialState: {},
  reducers: {
    index(_state, { payload }) {
      const newState = {}
      payload.forEach(item => {
        newState[item.id] = item
      })

      return newState
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export default companyBankAccountsSlice
