import type {
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'

export interface IRTEditActionButtonsProps<
  TData extends IReusableTableRowData,
> {
  row: RTRow<TData>
  table: ReusableTableInstance<TData>
  variant?: 'icon' | 'text'
}

export const RTEditActionButtons = <TData extends IReusableTableRowData>({
  row,
  table,
  variant = 'icon',
}: IRTEditActionButtonsProps<TData>) => {
  const {
    getState,
    options: {
      icons: { CancelIcon, SaveIcon },
      onCreatingRowCancel,
      onCreatingRowSave,
      onEditingRowCancel,
      onEditingRowSave,
    },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingRow,
  } = table
  const { creatingRow, editingRow, isSaving } = getState()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const handleCancel = () => {
    if (isCreating) {
      onCreatingRowCancel?.({ row, table })
      setCreatingRow(null)
    } else if (isEditing) {
      onEditingRowCancel?.({ row, table })
      setEditingRow(null)
    }
    row._valuesCache = {} as any //reset values cache
  }

  const handleSubmitRow = () => {
    //look for auto-filled input values
    Object.values(editInputRefs?.current)
      .filter(inputRef => row.id === inputRef?.name?.split('_')?.[0])
      ?.forEach(input => {
        if (
          input.value !== undefined &&
          Object.hasOwn(row?._valuesCache as object, input.name)
        ) {
          // @ts-ignore
          row._valuesCache[input.name] = input.value
        }
      })
    if (isCreating)
      onCreatingRowSave?.({
        exitCreatingMode: () => setCreatingRow(null),
        row,
        table,
        values: row._valuesCache,
      })
    else if (isEditing) {
      onEditingRowSave?.({
        exitEditingMode: () => setEditingRow(null),
        row,
        table,
        values: row?._valuesCache,
      })
    }
  }

  return (
    <div
      onClick={e => e.stopPropagation()}
      style={{
        display: 'flex',
        gap: '0.75rem',
      }}
    >
      {variant === 'icon' ? (
        <>
          <button aria-label='cancel' onClick={handleCancel}>
            <CancelIcon />
          </button>
          {((isCreating && onCreatingRowSave) ||
            (isEditing && onEditingRowSave)) && (
            <button
              aria-label='save'
              color='info'
              disabled={isSaving}
              onClick={handleSubmitRow}
            >
              {isSaving ? <div>circle progress</div> : <SaveIcon />}
            </button>
          )}
        </>
      ) : (
        <>
          <button onClick={handleCancel} style={{ minWidth: '100px' }}>
            cancel
          </button>
          <button
            disabled={isSaving}
            onClick={handleSubmitRow}
            style={{ minWidth: '100px' }}
          >
            {isSaving && <div>circle progress</div>}
            save
          </button>
        </>
      )}
    </div>
  )
}
