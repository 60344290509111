import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
// import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'

const costCodesSlice = createSlice({
  name: moduleName.company.costCodes,
  initialState: {
    columns: [],
  },
})

export default costCodesSlice
