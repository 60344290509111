import { notSetOption } from '~/utils/constants'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { createSelector } from 'reselect'

export const selectFleetsDataState = state => state.data.companies
const selectFleetsUiState = state => state.ui.fleets

export const selectCommonFleets = createSelector(
  selectFleetsDataState,

  fleets => {
    const newFleets = convertSortAndFilter(fleets)

    return newFleets
  },
)

export const selectCommonFleetOptions = createSelector(
  selectFleetsDataState,

  fleets => {
    const newFleets = convertSortAndFilter(fleets)

    try {
      return newFleets.map(({ id, code, name, logo }) => ({
        value: id,
        code,
        name,
        label: `${code} - ${name}`,
        // company,
        image: logo,
      }))
    } catch (error) {
      console.log('error', error)

      return []
    }
  },
)

export const selectCommonFleetOptionsWithNotSet = createSelector(
  selectCommonFleetOptions,

  fleets => {
    return [notSetOption, ...fleets]
  },
)

export const selectCommonFleetLoading = createSelector(
  selectFleetsUiState,
  state => state.fleetLoading,
)

export const selectCommonFleetPagination = createSelector(
  selectFleetsUiState,
  state => state.fleetPagination,
)

export const selectCommonFeetLoadingRowModel = createSelector(
  selectFleetsUiState,
  state => state.fleetLoadingRowModel,
)

export const selectCommonFleetSearchKey = createSelector(
  selectFleetsUiState,
  state => state.fleetSearchKey,
)

const convertSortAndFilter = fleetHash => {
  return columnHashToColumnsArray(fleetHash)
    .filter(f => f.fleet == 'Yes')
    .sort((a, b) => a.id - b.id)
}
