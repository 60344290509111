import { useState, useEffect, useCallback, useMemo } from 'react'
import { helpCircleOutline } from 'ionicons/icons'
import { Accordion, Badge } from 'react-bootstrap'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import { IonItem, IonList, IonLabel, IonSearchbar, IonIcon } from '@ionic/react'
import { Unless, When } from 'react-if'

import { CompanyAvatar, ToolTipOverlay, CompanyInfo } from '~/components/shared'
import {
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { EScope } from '~/types/enums/ECommonEnum'
import { OrderFormDialog } from '../shared/SchedulesTable/OrderFormDialog'

import './LoadFormScheduleSelect.scss'
import buildObjectName from '~/utils/buildObjectName'

const LoadFormScheduleSelect = ({
  schedules,
  selectedSchedule,
  handleSetSchedule,
}) => {
  const [activeKey, setActiveKey] = useState(null)

  const [scheduleSearch, setScheduleSearch] = useState('')
  const [filteredSchedules, setFilteredSchedules] = useState([])
  const [orderFormDialog, setOrderFormDialog] = useState({
    isOpen: false,
    orderData: null,
    defaultTab: -1,
  })

  const { findCompanyById } = useQueryCompanies()
  const { findTerminalById } = useQueryTerminals()

  const { currentUom } = useQueryUoms()
  const { findBuyerSellerProductById } = useQueryBuyerSellerProducts(
    {
      filters: {
        joinsSellerProduct: true,
      },
    },
    { enabled: Boolean(selectedSchedule?.order?.buyerSellerProductId) },
  )

  const buyerSellerProduct = findBuyerSellerProductById(
    selectedSchedule?.order?.buyerSellerProductId,
  )

  const scheduleSellerTerminal = useMemo(() => {
    if (selectedSchedule) {
      return findTerminalById(selectedSchedule.sellerTerminalId)
    }
    return null
  }, [findTerminalById, selectedSchedule])

  const scheduleSeller = useMemo(() => {
    if (scheduleSellerTerminal) {
      return findCompanyById(scheduleSellerTerminal.companyId)
    }
    return null
  }, [findCompanyById, scheduleSellerTerminal])

  const scheduleBuyerTerminal = useMemo(() => {
    if (selectedSchedule) {
      return findTerminalById(selectedSchedule.order.buyerTerminalId)
    }
    return null
  }, [findTerminalById, selectedSchedule])

  const scheduleBuyer = useMemo(() => {
    if (scheduleBuyerTerminal) {
      return findCompanyById(scheduleBuyerTerminal.companyId)
    }
    return null
  }, [findCompanyById, scheduleBuyerTerminal])

  const nextLoad = useMemo(() => {
    if (selectedSchedule) {
      return selectedSchedule.nextLoads[0]
    }
    return null
  }, [selectedSchedule])

  const onCloseOrderForm = useCallback(() => {
    setOrderFormDialog({
      isOpen: false,
      orderData: null,
      defaultTab: -1,
    })
  }, [])

  const onOpenOrderForm = useCallback(
    event => {
      event.stopPropagation()
      setOrderFormDialog({
        isOpen: true,
        orderData: selectedSchedule?.order,
        defaultTab: selectedSchedule?.scheduleNumber || -1,
      })
    },
    [selectedSchedule?.order, selectedSchedule?.scheduleNumber],
  )

  const handleSearch = useCallback(
    e => setScheduleSearch(e.target.value),
    [setScheduleSearch],
  )

  const getScheduleTime = schedule => {
    const date = new Date(schedule.startTime)
    const hours = date.getHours()
    const minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

    return `${hours}:${minutes}`
  }

  useEffect(() => {
    setFilteredSchedules(
      schedules.filter(schedule => {
        const query = scheduleSearch.toLowerCase()
        const valueall =
          ' + ' +
          schedule?.num +
          schedule?.buyerTerminal?.code +
          schedule?.buyerName +
          schedule?.sellerName +
          schedule?.product?.code + //name
          getScheduleTime(schedule) +
          schedule?.qty +
          schedule
        // todo: sellerTerminal/code, seller/buyer code, spacing, loadSize, schedule.order.num,

        return valueall.toLowerCase().indexOf(query) >= 0
      }),
    )
  }, [scheduleSearch, schedules])

  return (
    <>
      <Accordion
        activeKey={activeKey}
        onClick={() => setActiveKey(activeKey ? null : '1')}
        className={clsx('LoadFormScheduleSelect__container')}
      >
        <Accordion.Item eventKey='1'>
          <Accordion.Header style={{ minHeight: 40 }}>
            <Unless condition={false}>
              <h4 style={{ marginBottom: 0 }}>Schedules</h4>
              <Badge bg='danger' style={{ fontSize: 12, marginLeft: 4 }}>
                {filteredSchedules.length}
              </Badge>
            </Unless>
          </Accordion.Header>

          <When condition={Boolean(selectedSchedule)}>
            <ToolTipOverlay
              content={
                <div>
                  <div>
                    <span>Load time: </span>
                    <span>{nextLoad?.loadTime} min</span>
                  </div>
                  <div>
                    <span>Travel time: </span>
                    <span>{nextLoad?.travelTimeToBuyer} min</span>
                  </div>
                  <div>
                    <span>Arrive time: </span>
                    <span>
                      {moment(nextLoad?.arriveBuyerTime).format('H:mm')}
                    </span>
                  </div>
                </div>
              }
              placement='top'
            >
              <div className='LoadFormScheduleSelect__scheduleContainer'>
                <div className='headerContainer'>
                  <div>
                    <span className='headerLabel'>Order: </span>
                    <span className='hyperLink' onClick={onOpenOrderForm}>
                      #{selectedSchedule?.order.num}-
                      {selectedSchedule?.scheduleNumber}
                    </span>
                  </div>
                  <div>
                    <span className='headerLabel'>Next Load: </span>
                    <span>10:30</span>
                  </div>
                  <div>
                    <span className='headerLabel'>Qty: </span>
                    <span>
                      {Number(selectedSchedule?.qtyCounter)}/
                      {Number(selectedSchedule?.qty)} {currentUom?.code}
                    </span>
                  </div>
                  <div>
                    <span className='headerLabel'>Spacing: </span>
                    <span>{Number(selectedSchedule?.spacing)} mins</span>
                  </div>
                </div>

                <div className='bodyContainer'>
                  <CompanyInfo
                    searchableGoogle={false}
                    hideAnchor
                    company={{
                      value: scheduleBuyerTerminal?.id,
                      label: scheduleBuyerTerminal?.name,
                      name: scheduleBuyerTerminal?.name,
                      code: scheduleBuyerTerminal?.code,
                      image: scheduleBuyer?.logo,
                    }}
                  />
                  <CompanyInfo
                    searchableGoogle={false}
                    hideAnchor
                    company={{
                      value: scheduleSellerTerminal?.id,
                      label: scheduleSellerTerminal?.name,
                      name: scheduleSellerTerminal?.name,
                      code: scheduleSellerTerminal?.code,
                      image: scheduleSeller?.logo,
                    }}
                    companyType={EScope.seller}
                  />
                </div>

                <div className='footerContainer'>
                  <div>
                    <span style={{ fontWeight: 600 }}>Product: </span>
                    <span>
                      {buildObjectName({
                        name: buyerSellerProduct?.sellerProductName,
                        code: buyerSellerProduct?.sellerProductCode,
                      })}
                    </span>
                  </div>
                  <div>
                    <Badge
                      bg={ORDER_STATUSES[selectedSchedule?.status]?.color}
                      style={{ fontSize: 12, verticalAlign: 'middle' }}
                    >
                      <span style={{ verticalAlign: 'middle' }}>
                        {selectedSchedule?.status}
                      </span>
                      <span style={{ verticalAlign: 'middle', marginLeft: 4 }}>
                        <IonIcon
                          icon={ORDER_STATUSES[selectedSchedule?.status]?.icon}
                        />
                      </span>
                    </Badge>
                  </div>
                </div>
              </div>
            </ToolTipOverlay>
          </When>

          <Accordion.Body>
            <IonSearchbar value={scheduleSearch} onIonChange={handleSearch} />
            <IonList>
              {filteredSchedules.map(schedule => {
                const {
                  qty,
                  qtyCounter,
                  order: { buyerId, sellerId, buyerTerminalId },
                } = schedule

                const buyer = findCompanyById(buyerId)
                const seller = findCompanyById(sellerId)
                const buyerTerminal = findTerminalById(buyerTerminalId)

                return (
                  <IonItem
                    color={selectedSchedule?.id === schedule.id && 'primary'}
                    key={schedule.id}
                    button
                    onClick={() => handleSetSchedule(schedule)}
                  >
                    <IonLabel className='ion-text-wrap'>
                      <span>
                        Order #{schedule?.order?.num || '-'} | Schedule #
                        {schedule.scheduleNumber || '-'}{' '}
                      </span>
                      | <span>Start: {getScheduleTime(schedule)} </span>|{' '}
                      <span>
                        Buyer Terminal:{' '}
                        {buyerTerminal?.code || (
                          <ToolTipOverlay content='Unknown' placement='top'>
                            <IonIcon
                              className='LoadFormSelect__questionIcon'
                              icon={helpCircleOutline}
                            />
                          </ToolTipOverlay>
                        )}{' '}
                      </span>
                      |{' '}
                      <span>
                        Qty: {parseFloat(qtyCounter)}/{parseFloat(qty)}{' '}
                      </span>
                      |{' '}
                      <span>
                        Product:{' '}
                        {buyerSellerProduct?.sellerProductCode || (
                          <ToolTipOverlay content='Unknown' placement='top'>
                            <IonIcon
                              className='LoadFormSelect__questionIcon'
                              icon={helpCircleOutline}
                            />
                          </ToolTipOverlay>
                        )}
                      </span>
                      | <span>Buyer: </span>
                      <CompanyAvatar
                        showImagePopup
                        companyType='buyer'
                        tooltipMessage={
                          _.size(buyer) > 0
                            ? `${buyer.code} - ${buyer.name}`
                            : ''
                        }
                        style={{ display: 'inline-block', margin: '0 4px' }}
                        company={{
                          value: buyer.id,
                          name: buyer.name,
                          image: buyer.logo,
                        }}
                      />
                      | <span>Seller: </span>
                      <CompanyAvatar
                        showImagePopup
                        companyType='seller'
                        tooltipMessage={
                          _.size(seller) > 0
                            ? `${seller.code} - ${seller.name}`
                            : ''
                        }
                        style={{ display: 'inline-block', marginLeft: 4 }}
                        company={{
                          value: seller.id,
                          name: seller.name,
                          image: seller.logo,
                        }}
                      />
                    </IonLabel>
                  </IonItem>
                )
              })}
            </IonList>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <OrderFormDialog
        orderData={orderFormDialog.orderData}
        isOpen={orderFormDialog.isOpen}
        defaultTab={orderFormDialog.defaultTab}
        onClose={onCloseOrderForm}
        className='LoadFormScheduleSelect__orderForm'
      />
    </>
  )
}

export default LoadFormScheduleSelect
