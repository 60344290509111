import { PropsWithChildren, useCallback, useState } from 'react'

import PayrollReportFormValuesContext from './PayrollReportFormValuesContext'

import type { IFormValues, IPayrollReportFormValuesProviderProps } from './type'
import getStartDateInMonthToToday from '~/utils/getStartDateInMonthToToday'

function PayrollReportFormValuesProvider(
  props: PropsWithChildren<IPayrollReportFormValuesProviderProps>,
) {
  const { children } = props

  const [reportFormValues, setReportFormValues] = useState<IFormValues>({
    dateRange: getStartDateInMonthToToday(),
    content: 'table',
    payroll: [],
    includeContractors: false,
    includeEmployeeId: false,
    date: new Date(),
  })

  const onChangeReportFormValues = useCallback(
    (formValues: Partial<IFormValues>) => {
      setReportFormValues(prev => ({
        ...prev,
        ...formValues,
      }))
    },
    [],
  )

  return (
    <>
      <PayrollReportFormValuesContext.Provider
        value={{ reportFormValues, onChangeReportFormValues }}
      >
        {children}
      </PayrollReportFormValuesContext.Provider>
    </>
  )
}
export default PayrollReportFormValuesProvider
