import { truckFleetsSlice } from '../reducers/data/truckFleets'

export const updateTruckFleet = truck => dispatch =>
  dispatch(truckFleetsSlice.actions.update(truck))

export const deleteTruckfleet = truckId => dispatch =>
  dispatch(truckFleetsSlice.actions.delete({ id: truckId }))

export const initializeTruckFleets = trucks => dispatch =>
  dispatch(truckFleetsSlice.actions.index(trucks))
