import React from 'react'
import { IonRow, IonGrid, IonCol } from '@ionic/react'
import CompanyBTFSTPsubList from './CompanyBTFSTPsubList'

const CompanyBTFSTPList = ({
  companyBTFSTPs,
  onEditClick,
  deleteParser,
  seller,
}) => {
  return (
    <>
      <IonGrid className='my-company-btf-stps-container'>
        {companyBTFSTPs &&
          companyBTFSTPs.map(companyBTFSTP => (
            <IonRow key={companyBTFSTP.id}>
              <IonCol>
                <CompanyBTFSTPsubList
                  companyBTFSTPs={companyBTFSTP}
                  onEditClick={onEditClick}
                  deleteParser={deleteParser}
                  seller={seller}
                />
              </IonCol>
            </IonRow>
          ))}
        {companyBTFSTPs && companyBTFSTPs.length == 0 && (
          <IonRow>
            <h3 style={{ color: 'red' }}>{'Not Found'}</h3>
          </IonRow>
        )}
      </IonGrid>
    </>
  )
}

export default CompanyBTFSTPList
