import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getSavedFiltersFromStorage } from '~/utils/translateSavedFilters'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import DEFAULT_ZONES_COLUMNS from '~/constants/zones/columns'

import type {
  IZoneReduxActUpdateUiColumn,
  IZoneReduxActUpdateUiColumns,
  IZoneReduxActUpdateUiFilter,
  IZoneReduxActUpdateUiPayload,
  IZoneReduxUiSlice,
} from '~/types/models/IZone'

const savedFilters = getSavedFiltersFromStorage({
  moduleName: 'zones',
  defaultValues: defaultModuleFilters.zones,
})

export const zonesUiSlice = createSlice({
  name: 'zones',
  initialState: {
    columns: DEFAULT_ZONES_COLUMNS,
    filters: savedFilters,
    initialLoad: true,
  } as IZoneReduxUiSlice,
  reducers: {
    updateUi: (
      state,
      { payload }: PayloadAction<IZoneReduxActUpdateUiPayload>,
    ) => {
      for (const [key, value] of Object.entries(payload)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-semi
        ;(state as any)[`${key}`] = value
      }
    },
    updateUiColumn: (
      state,
      { payload }: PayloadAction<IZoneReduxActUpdateUiColumn>,
    ) => {
      let { column } = payload
      let updateIndex = state.columns.findIndex(
        columnState => columnState.columnName == column.columnName,
      )
      state.columns[updateIndex] = {
        ...state.columns[updateIndex],
        ...column,
      }
    },
    updateUiColumns: (
      state,
      { payload }: PayloadAction<IZoneReduxActUpdateUiColumns>,
    ) => {
      payload.forEach(column => {
        let updateIndex = state.columns.findIndex(
          columnState => column.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state.columns.push({ ...column })

          return
        }

        state.columns[updateIndex] = {
          ...state.columns[updateIndex],
          ...column,
        }
      })
    },
    updateUiFilter: (
      state,
      { payload }: PayloadAction<IZoneReduxActUpdateUiFilter>,
    ) => {
      for (const [key, value] of Object.entries(payload)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-semi
        ;(state.filters as any)[`${key}`] = value
      }
      state.initialLoad = false
    },
  },
})

export default zonesUiSlice
