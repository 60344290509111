import { createSelector } from 'reselect'

export const getOrdersState = state => state.data.orders
const selectId = (state, id) => id

export const selectOrdersList = createSelector(getOrdersState, state => {
  return Object.values(state)
})

export const selectLoadingOrder = state => state.ui.orderTracking.loading
export const selectLoadOrders = state => state.ui.orderTracking.loadedOrders
export const selectOrderFilters = state => state.ui.orderTracking.filters
export const selectOrderDateRange = state =>
  state.ui.orderTracking.filters.date || {}

export const selectOrderById = createSelector(
  [getOrdersState, selectId],
  (orders, id) => orders[id] || {},
)

export const selectOrderLoadingTable = state => state.ui.orders.loadingTable
