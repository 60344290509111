import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IPaymentType } from '~/types/models/IPaymentType'

const useModifyPaymentTypes = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['paymentTypes', sessionUser?.id],
    [sessionUser?.id],
  )

  const addPaymentType = useCallback(
    (data: IPaymentType) => {
      queryClient.setQueryData<IPaymentType[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updatePaymentType = useCallback(
    (id: number, data: IPaymentType) => {
      queryClient.setQueryData<IPaymentType[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePaymentType = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPaymentType[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPaymentType,
    updatePaymentType,
    removePaymentType,
  }
}

export default useModifyPaymentTypes
