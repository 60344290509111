import { useCallback, useMemo, useState } from 'react'
import { useFormatDateToTz } from '~/hooks/useFormatDateToTz'

import moment from 'moment'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'

import type { IScheduleLoad } from '~/types/models/IScheduleLoad'
import type { ITerminal } from '~/types/models/ITerminal'
import type { IScheduleLoadsTableProps } from './type'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { IRTColumnDef, RTCell } from '../ReusableTable'
import {
  useQueryCompanies,
  useQueryLoads,
  useQueryScheduleLoads,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { ColumnFiltersState } from '@tanstack/react-table'
import _ from 'lodash'
import { ILoad } from '~/types/models/ILoad'
import { DropdownWithCustomChildren } from '../ConcordForm'
import { DEFAULT_PRIMARY_PARSING_STATUSES } from '~/constants/loads/filters'
import LoadStatusCell from '~/components/load/LoadIndexTable/LoadStatusCell'
import buildFullName from '~/utils/buildFullName'
import { Badge } from 'react-bootstrap'
import { ITruckFleet } from '~/types/models/ITruckFleet'

const useScheduleLoadsTable = (props: IScheduleLoadsTableProps) => {
  const {
    tableHeight,
    state,
    extraColumns = [],
    apiParams,
    afterUpdate,
  } = props

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'view',
      value: 'showScheduleLoads',
    },
    {
      id: 'beginSellerTerminalId',
      value: [],
    },
    {
      id: 'ticketTime',
      value: {},
    },
    {
      id: 'fleetId',
      value: [],
    },
  ])
  const [ticketModal, setTicketModal] = useState({
    isOpen: false,
    load: null as ILoad | null,
  })
  const [truckFleetModal, setTruckFleetModal] = useState({
    isOpen: false,
    truckFleet: null as ITruckFleet | null,
  })

  const filterData = useMemo(() => {
    return columnFilters
      .filter(({ id }) => id !== 'view')
      .reduce((acc, filter) => {
        acc[filter.id] = filter.value
        return acc
      }, {} as Record<string, any>)
  }, [columnFilters])

  const view = columnFilters[0]?.value as string
  const showLoads = view === 'showLoads'
  const showScheduleLoadsAndLoads = view === 'showScheduleLoadsAndLoads'

  const {
    scheduleLoadsData,
    isLoadingScheduleLoads,
    refetchScheduleLoadsData,
  } = useQueryScheduleLoads(_.merge(apiParams, { filters: filterData }), {
    enabled: Boolean((apiParams.filters.scheduleId || [])?.length > 0),
  })

  const { sellerTerminalOptions, isLoadingTerminals, findTerminalById } =
    useQueryTerminals()

  const { loadsData, isLoadingLoadsData, refetchLoadsData } = useQueryLoads(
    {
      filters: {
        scheduleId: apiParams.filters.scheduleId,
      },
    },
    { enabled: view !== 'showScheduleLoads' },
  )

  const { fleetCompanyOptions, isLoadingCompaniesData } = useQueryCompanies({})
  const { getDateFromISODateWithTz } = useFormatDateToTz()
  const { findUserByDriverFleetId, isLoadingUsersData } = useQueryUsers()
  const { findTruckFleetById } = useQueryTruckFleets()

  const isTableLoading =
    state?.isLoading ||
    isLoadingScheduleLoads ||
    isLoadingTerminals ||
    isLoadingLoadsData ||
    isLoadingCompaniesData ||
    isLoadingUsersData

  const tableData = useMemo(() => {
    if (showLoads) {
      return loadsData
    }

    return scheduleLoadsData
  }, [scheduleLoadsData, loadsData, showLoads])

  const filterOptions = [
    {
      label: 'View',
      field: 'view',
      type: EFieldType.singleSelect,
      options: [
        {
          label: 'Show Schedule Loads',
          value: 'showScheduleLoads',
        },
        {
          label: 'Show Loads',
          value: 'showLoads',
        },
        {
          label: 'Show Schedule Loads with Load',
          value: 'showScheduleLoadsAndLoads',
        },
      ],
    },
    {
      label: 'Seller Terminal',
      field: 'beginSellerTerminalId',
      type: EFieldType.multipleSelect,
      options: sellerTerminalOptions,
    },
    {
      label: 'Ticket Time',
      field: 'ticketTime',
      type: EFieldType.dateRange,
    },
    {
      label: 'Fleet',
      field: 'fleetId',
      type: EFieldType.multipleSelect,
      options: fleetCompanyOptions,
    },
  ]

  const timeDifference = useCallback(
    (
      startTimeString: string,
      endTimeString: string,
    ): { hours: number; minutes: number } => {
      const startTime = moment(getDateFromISODateWithTz(startTimeString))
        .format('HH:mm')
        .split(':')
      const endTime = endTimeString.split(':')

      const startTimeHours = Number(startTime[0])
      const startTimeMinutes = Number(startTime[1])

      const endTimeHours = Number(endTime[0])
      const endTimeMinutes = Number(endTime[1])

      const diffHours = endTimeHours - startTimeHours
      const diffMinutes = endTimeMinutes - startTimeMinutes

      return { hours: diffHours, minutes: diffMinutes }
    },
    [getDateFromISODateWithTz],
  )

  const onLoadCellEditEnd = async (value: any, cell: RTCell<ILoad>) => {
    const { column, row } = cell
    const columnField = column.id
    const rowId = row.original.id
    const payload = {
      [columnField]: value,
    }
    const { errors } = await apiClient.loads.update(rowId, payload)
    if ((errors as string[]).length > 0) {
      toast.error(toastMessages.updateError)
    } else {
      toast.success(toastMessages.updateSuccess)
      refetchLoadsData()
      afterUpdate && afterUpdate()
    }
  }

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<IScheduleLoad | ILoad>) => {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const originalValue = (cell.row.original as any)[columnField]
      const payload = {
        [columnField]: value,
      }
      if (showLoads) {
        const { errors } = await apiClient.loads.update(rowId, payload)
        if ((errors as string[]).length > 0) {
          toast.error(toastMessages.updateError)
        } else {
          toast.success(toastMessages.updateSuccess)
          refetchLoadsData()
        }
      } else {
        if (columnField === 'ticketTime') {
          const { hours, minutes } = timeDifference(originalValue, value)
          delete payload.ticketTime
          payload.arriveBuyerTime = moment(
            (row.original as any).arriveBuyerTime,
          )
            .add({ hours, minutes })
            .toISOString()
        }
        const { errors } = await apiClient.scheduleLoads.update(rowId, {
          scheduleLoad: payload,
        })
        if ((errors as string[]).length > 0) {
          toast.error(toastMessages.updateError)
        } else {
          toast.success(toastMessages.updateSuccess)
          refetchScheduleLoadsData()
          afterUpdate && afterUpdate()
        }
      }
    },
    [
      afterUpdate,
      refetchLoadsData,
      refetchScheduleLoadsData,
      showLoads,
      timeDifference,
    ],
  )

  const onShowTicketModal = (load: ILoad) => () => {
    setTicketModal({
      load,
      isOpen: true,
    })
  }

  const onCloseTicketModal = () => {
    setTicketModal({
      isOpen: false,
      load: null,
    })
  }

  const onShowTruckFleetForm = (truckFleet: ITruckFleet) => () => {
    setTruckFleetModal({
      truckFleet,
      isOpen: true,
    })
  }

  const onCloseTruckFleetForm = () => {
    setTruckFleetModal({
      truckFleet: null,
      isOpen: false,
    })
  }

  const loadColumns: IRTColumnDef<ILoad>[] = [
    {
      header: '#',
      accessorKey: 'num',
      minSize: 80,
      maxSize: 80,
      Cell({ cell, row }) {
        const cellValue = cell.getValue<string>()
        if (cellValue) {
          return (
            <span
              className='hyperLink'
              onClick={onShowTicketModal(row.original)}
            >
              {cellValue}
            </span>
          )
        }
        return ''
      },
    },
    {
      header: 'Qty',
      accessorKey: 'qty',
      minSize: 80,
      maxSize: 80,
    },
    {
      header: 'Ticket time',
      accessorKey: 'ticketTime',
      minSize: 120,
      maxSize: 120,
      Cell({ cell }) {
        const cellValue = cell.getValue<string>()
        if (cellValue) {
          return moment(cellValue).format('HH:mm')
        }
        return ''
      },
    },
    {
      header: 'Seller Terminal',
      accessorKey: 'sellerTerminalId',
      enableEditing: true,
      editVariant: EFieldType.singleSelect,
      editSelectOptions: sellerTerminalOptions,
      editDropdownFieldProps() {
        return {
          formatOptionLabel: (option: ITerminal) => option.name,
        }
      },
    },
    {
      header: 'Buyer Terminal',
      accessorKey: 'buyerTerminalId',
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return findTerminalById(cellValue)?.name
      },
    },
    {
      header: 'Fleet',
      accessorKey: 'fleetId',
      maxSize: 250,
      minSize: 250,
      enableEditing: row => !row.parentId,
      editVariant: EFieldType.singleSelect,
      editSelectOptions: fleetCompanyOptions,
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return fleetCompanyOptions.find(({ value }) => value === cellValue)
          ?.label
      },
    },
    {
      header: 'Driver',
      accessorKey: 'driverFleetId',
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        const user = findUserByDriverFleetId(cellValue)
        return buildFullName(user?.person)
      },
      minSize: 100,
      maxSize: 100,
    },
    {
      header: 'Truck',
      accessorKey: 'truckFleetId',
      minSize: 100,
      maxSize: 100,
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        const truckFleet = findTruckFleetById(cellValue)
        if (truckFleet) {
          return (
            <Badge
              bg='primary'
              style={{ fontSize: 13, fontWeight: 600 }}
              className='clickable'
              onClick={onShowTruckFleetForm(truckFleet as ITruckFleet)}
            >
              {truckFleet?.truck?.name}
            </Badge>
          )
        }
        return ''
      },
    },
    {
      header: 'Status',
      accessorKey: 'primaryParserStatus',
      maxSize: 120,
      minSize: 120,
      Cell({ row, cell }) {
        return (
          <DropdownWithCustomChildren
            options={DEFAULT_PRIMARY_PARSING_STATUSES}
            styles={{
              option: (provided, state) => {
                const { style = {} } = state.data
                return _.merge(provided, style, { fontSize: 14 })
              },
            }}
            onChange={(event, { value }) => {
              onCellEditEnd(value, cell as any)
            }}
          >
            <LoadStatusCell load={row.original as ILoad} />
          </DropdownWithCustomChildren>
        )
      },
    },
  ]

  const columns = useMemo<IRTColumnDef<IScheduleLoad>[]>(
    () => [
      {
        header: 'Qty',
        accessorKey: 'qty',
        minSize: 80,
        maxSize: 80,
      },
      {
        header: 'Ticket time',
        accessorKey: 'ticketTime',
        minSize: 120,
        maxSize: 120,
        enableEditing: row => !row.parentId,
        editVariant: EFieldType.time,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          return moment(cellValue).format('HH:mm')
        },
      },
      {
        header: 'Start terminal',
        accessorKey: 'beginSellerTerminalId',
        enableEditing: row => !row.parentId,
        editSelectOptions: sellerTerminalOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: (option: ITerminal) => option.name,
          }
        },
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          return sellerTerminalOptions.find(({ value }) => value === cellValue)
            ?.name
        },
      },
      {
        header: 'At job',
        accessorKey: 'arriveBuyerTime',
        enableEditing: row => !row.parentId,
        editVariant: EFieldType.time,
      },
      {
        header: 'Back',
        accessorKey: 'arriveSellerTime',
        enableEditing: row => !row.parentId,
        editVariant: EFieldType.time,
      },
      {
        header: 'Return Terminal',
        accessorKey: 'returnSellerTerminalId',
        enableEditing: row => !row.parentId,
        editSelectOptions: sellerTerminalOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: (option: ITerminal) => option.name,
          }
        },
      },
      {
        header: 'Total',
        accessorKey: 'totalTime',
        maxSize: 100,
        minSize: 100,
        Cell({ row }) {
          const { ticketTime, arriveSellerTime } = row.original as any
          const d1 = new Date(ticketTime).getTime()
          const d2 = new Date(arriveSellerTime).getTime()
          const minutes = Math.floor((d2 - d1) / 1000 / 60)
          return <div>{minutes} mins</div>
        },
      },
      {
        header: 'Fleet',
        accessorKey: 'fleetId',
        maxSize: 250,
        minSize: 250,
        enableEditing: row => !row.parentId,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: fleetCompanyOptions,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          return fleetCompanyOptions.find(({ value }) => value === cellValue)
            ?.label
        },
      },
      ...extraColumns,
    ],
    [extraColumns, fleetCompanyOptions, sellerTerminalOptions],
  )

  return {
    columns,
    onCellEditEnd,
    state,
    tableHeight,
    filterOptions,
    scheduleLoadsData,
    isLoadingScheduleLoads,
    columnFilters,
    setColumnFilters,
    tableData,
    isTableLoading,
    showLoads,
    showScheduleLoadsAndLoads,
    loadColumns,
    loadsData,
    ticketModal,
    truckFleetModal,
    onLoadCellEditEnd,
    onCloseTicketModal,
    onCloseTruckFleetForm,
  }
}

export default useScheduleLoadsTable
