import { useRef, useCallback } from 'react'
import { useClickAway } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { useTransition, animated } from 'react-spring'

import { IonText, IonButton } from '@ionic/react'
import { InfoIcon } from '~/components/shared'

import { selectWarningBrowserPopup } from '~/redux/selectors'
import { updateAppWarningBrowserPopup } from '~/redux/actions/applicationActions'

import './PopupReenableLocationPermission.scss'

function PopupReenableLocationPermission() {
  const ref = useRef()

  const dispatch = useDispatch()
  const warningBrowserPopupState = useSelector(selectWarningBrowserPopup)

  const transitions = useTransition(warningBrowserPopupState.isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: warningBrowserPopupState.isOpen,
  })

  const handleClose = useCallback(() => {
    dispatch(updateAppWarningBrowserPopup(false))
  }, [dispatch])

  useClickAway(ref, handleClose)

  if (warningBrowserPopupState.isOpen) {
    return transitions((styles, item) =>
      item ? (
        <animated.div style={{ ...styles, zIndex: 9999 }}>
          <div className='PopupReenableLocationPermission__container' ref={ref}>
            <div className='PopupReenableLocationPermission__title'>
              <IonText>Location access is required!</IonText>
            </div>

            <div className='PopupReenableLocationPermission__description'>
              <span>
                We need to know your location to show this on the map, click
              </span>
              <InfoIcon
                size={18}
                className='PopupReenableLocationPermission__infoIcon'
              />
              <span>to open the settings page and enable this permisison</span>
            </div>

            <div className='PopupReenableLocationPermission__buttons'>
              <IonButton onClick={handleClose}>Got it!</IonButton>
            </div>
          </div>
        </animated.div>
      ) : null,
    )
  }

  return null
}

PopupReenableLocationPermission.propTypes = {}

export default PopupReenableLocationPermission
