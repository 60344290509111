import { IonIcon, IonButton } from '@ionic/react'
import {
  saveOutline,
  closeOutline,
  refreshOutline,
  arrowRedoOutline,
} from 'ionicons/icons'
import { ToolTipOverlay } from '~/components/shared'

const MultiselectOrderButtonGroup = ({
  onSave,
  onClear,
  onReset,
  onGoPage,
  disableClearButton,
  disableSaveButton,
  disableResetButton,
  tooltipSaveButton,
  tooltipClearButton,
  tooltipResetButton,
}) => {
  return (
    <div className='DataSorTool__savingData'>
      {onSave && (
        <ToolTipOverlay content={tooltipSaveButton} placement='bottom'>
          <div style={{ padding: 5 }}>
            <IonButton
              onClick={onSave}
              slot='start'
              color='concord'
              fill='solid'
              disabled={disableSaveButton}
            >
              <IonIcon icon={saveOutline} />
            </IonButton>
          </div>
        </ToolTipOverlay>
      )}

      {onReset && (
        <ToolTipOverlay content={tooltipResetButton} placement='bottom'>
          <div style={{ padding: 5 }}>
            <IonButton
              onClick={onReset}
              slot='start'
              color='medium'
              fill='solid'
              disabled={disableResetButton}
            >
              <IonIcon icon={refreshOutline} />
            </IonButton>
          </div>
        </ToolTipOverlay>
      )}

      {onClear && (
        <ToolTipOverlay content={tooltipClearButton} placement='bottom'>
          <div style={{ padding: 5 }}>
            <IonButton
              onClick={onClear}
              slot='start'
              color='danger'
              fill='solid'
              disabled={disableClearButton}
            >
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
        </ToolTipOverlay>
      )}

      {onGoPage && (
        <ToolTipOverlay content='Go to Settings page' placement='bottom'>
          <div style={{ padding: 5 }}>
            <IonButton
              onClick={onGoPage}
              slot='start'
              color='primary'
              fill='solid'
            >
              <IonIcon icon={arrowRedoOutline} />
            </IonButton>
          </div>
        </ToolTipOverlay>
      )}
    </div>
  )
}

export default MultiselectOrderButtonGroup
