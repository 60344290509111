import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyLocations from './useModifyLocations'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetLocationsParams, ILocation } from '~/types/models/ILocation'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryLocations = (
  params: Partial<IGetLocationsParams> = {},
  options?: Partial<UseQueryOptions<ILocation[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addLocation, updateLocation, removeLocation } =
    useModifyLocations(params)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'locations',
      sessionUser?.id,
      buildGetUrl(apiClient.locations.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.locations.get(params)
      return response.locations
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const locationsData = useMemo(() => data || [], [data])

  const findLocationById = (id: number) => locationsData.find(l => l.id === id)
  const findLocationByLocationableId = (locationableId: number) =>
    locationsData.find(l => l.locationableId === locationableId)

  return {
    locationsData,
    isLocationsDataLoading: isLoading,
    isLocationsDataFetched: isFetched,
    addLocation,
    updateLocation,
    removeLocation,
    findLocationById,
    findLocationByLocationableId,
  }
}

export default useQueryLocations
