import React from 'react'
import useTruckInfoBox from './useTruckInfoBox'

import { When } from 'react-if'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import { IonChip, IonIcon } from '@ionic/react'

import { closeOutline } from 'ionicons/icons'
import buildFullName from '~/utils/buildFullName'
import { getInitName } from '~/utils'

import type { ITruckInfoBoxProps } from './type'

import './styles.scss'

function TruckInfoBox(props: ITruckInfoBoxProps) {
  const {
    truckLocation,
    truck,
    isOpenInfoBox,
    renderTruckInfo,
    truckDetails,
    driverFleetOfTruck,
    isShowDriverInfo,
    onOpenTruckInfo,
    onCloseTruckInfo,
    onToggleDriverInfo,
  } = useTruckInfoBox(props)

  return (
    <>
      <When condition={Boolean(truckLocation)}>
        <InfoBox
          zIndex={1000}
          position={truckLocation as google.maps.LatLng}
          options={{
            closeBoxURL: '',
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(-12, 10),
          }}
        >
          <div className='TruckInfoBox__truckName' onClick={onOpenTruckInfo}>
            {truck.name}
          </div>
        </InfoBox>
      </When>
      <When condition={Boolean(isOpenInfoBox && truckLocation)}>
        <InfoBox
          zIndex={1000}
          position={truckLocation as google.maps.LatLng}
          options={{
            closeBoxURL: '',
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(30, 55),
            boxStyle: {
              minWidth: '45px',
            },
            isHidden: false,
          }}
        >
          <div className='TruckInfoBox__truckInfo'>
            <div className='closeIcon' onClick={onCloseTruckInfo}>
              <IonIcon icon={closeOutline} />
            </div>

            {renderTruckInfo}

            <div style={{ marginTop: 4 }}>
              <span style={{ fontWeight: 600 }}>Truck details: </span>
              <span>{truckDetails}</span>
            </div>

            <When condition={Boolean(driverFleetOfTruck)}>
              <IonChip
                color='concord'
                className='driverInfoContainer'
                onClick={onToggleDriverInfo}
              >
                <div className='driverAvatarAndName'>
                  <div className='driverInitialName'>
                    <span>
                      {getInitName(buildFullName(driverFleetOfTruck?.person))}
                    </span>
                  </div>
                </div>
                <div>{buildFullName(driverFleetOfTruck?.person)}</div>
              </IonChip>

              <When condition={isShowDriverInfo}>
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontWeight: 600 }}>Email: </span>
                  <a href={`mailto:${driverFleetOfTruck?.user?.email}`}>
                    {driverFleetOfTruck?.user?.email}
                  </a>
                </div>
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontWeight: 600 }}>Phone: </span>
                  <a href={`tel:${driverFleetOfTruck?.user?.phoneNumber}`}>
                    {driverFleetOfTruck?.user?.phoneNumber}
                  </a>
                </div>
              </When>
            </When>
          </div>
        </InfoBox>
      </When>
    </>
  )
}
export default React.memo(TruckInfoBox)
