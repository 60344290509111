import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IBankAccount } from '~/types/models/IBankAccount'

const useModifyBankAccounts = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'bankAccounts',
      sessionUser?.id,
      buildGetUrl(apiClient.bankAccounts.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addBankAccount = useCallback(
    (data: IBankAccount) => {
      queryClient.setQueryData<IBankAccount[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateBankAccount = useCallback(
    (id: number, data: IBankAccount) => {
      queryClient.setQueryData<IBankAccount[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeBankAccount = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBankAccount[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addBankAccount,
    updateBankAccount,
    removeBankAccount,
  }
}

export default useModifyBankAccounts
