import { useState, useCallback, useMemo } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonButtons,
  IonPopover,
} from '@ionic/react'
import { BackButton } from '~/components/shared'
import useAccesses from '~/hooks/useAccesses'
import HeaderPopOver from './PopOver'
import HeaderCompanyChip from './CompanyChip'
import HeaderCreateButton from '~/containers/OrderTracking/HeaderCreateButton'
import DriverChatButton from './DriverChatButton'
import { EScope } from '~/types/enums/ECommonEnum'
import { DriverBanner } from './DriverBanner'

import './Header.scss'
import { useQueryStripeAccount } from '~/hooks/useQueryData'
import { Alert } from 'react-bootstrap'
import { useWindowSize } from 'react-use'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useRouter } from '~/hooks/useRouter'

const Header = props => {
  const {
    user,
    headerTitle,
    rightButton,
    backButton,
    onBack,
    onMenu,
    children,
    rightStyle,
    createButtons,
  } = props

  const { isMobileScreen } = useBreakpoint()
  const [showAccessList, setShowAccessList] = useState(false)
  const [popoverState, setPopoverState] = useState({
    event: null,
    isOpen: false,
  })
  const windowSize = useWindowSize()
  const isCollapsed = useSelector(state => state.sidebar.isCollapsed)
  const router = useRouter()

  const { isStripeAccountFetched, stripeAccount } = useQueryStripeAccount()

  const isOnboardingComplete = useMemo(
    () => stripeAccount?.payoutsEnabled,
    [stripeAccount?.payoutsEnabled],
  )

  const isSellerPaymentBannerShown = useMemo(
    () =>
      router.pathname === '/payments' &&
      isStripeAccountFetched &&
      !isOnboardingComplete,
    [isOnboardingComplete, isStripeAccountFetched, router.pathname],
  )

  const marginLeft = useMemo(() => {
    if (windowSize.width <= 960) {
      return 0
    }

    if (isCollapsed) {
      return 96
    }

    return 236
  }, [isCollapsed, windowSize.width])

  const onOpenPopover = useCallback(event => {
    setPopoverState({
      event,
      isOpen: true,
    })
    setShowAccessList(false)
  }, [])

  const onClosePopover = useCallback(() => {
    setPopoverState({
      event: null,
      isOpen: false,
    })
  }, [])

  const {
    session,
    currentAccess,
    accessesList,
    userAccess,
    onUpdateFilter,
    onToggleAccess,
  } = useAccesses({ onClosePopover })

  return (
    <>
      <IonHeader className='Header__container d-print-none'>
        <IonToolbar style={{ height: '100%' }}>
          <IonButtons slot='start'>
            {isMobileScreen && <IonMenuButton slot='start' onClick={onMenu} />}
            {!isMobileScreen && (
              <img
                className='header-logo'
                alt='Concord Materials'
                src='/logo.png'
                onClick={onOpenPopover}
              />
            )}
            {(backButton || (user && user.userType == 'Company')) && (
              <BackButton onBack={onBack} />
            )}
            <HeaderCompanyChip />
            {session?.user?.currentScope === EScope.driverFleet && (
              <div style={{ marginLeft: 4 }}>
                <DriverChatButton />
              </div>
            )}
          </IonButtons>
          {!children && <IonTitle>{headerTitle}</IonTitle>}
          {children}
          <IonButtons
            slot='end'
            style={
              rightStyle ||
              (!children ? { justifyContent: 'flex-end' } : undefined)
            }
          >
            {rightButton}
            {createButtons && <HeaderCreateButton options={createButtons} />}
            {/*{session?.user?.currentScope === EScope.driverFleet && (
              <DriverChatButton />
            )}*/}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <DriverBanner />

      {isSellerPaymentBannerShown ? (
        <div style={{ marginLeft }}>
          <Alert style={{ fontSize: 14, marginBottom: 0 }} variant='danger'>
            You can process payments but payouts setup not complete, click{' '}
            <Link to='/embedded_payments'>here</Link> to continue.
          </Alert>
        </div>
      ) : null}

      <IonPopover {...popoverState} mode='ios' onDidDismiss={onClosePopover}>
        <HeaderPopOver
          showOverlay={popoverState.isOpen}
          showAccessList={showAccessList}
          accessesList={accessesList}
          setShowAccessList={setShowAccessList}
          currentAccess={currentAccess}
          session={session}
          onUpdateFilter={onUpdateFilter}
          onToggleAccess={onToggleAccess}
          userAccess={userAccess}
        />
      </IonPopover>
    </>
  )
}
export default Header
