import { useSelector } from 'react-redux'
import useErrorBoundary from 'use-error-boundary'

import { IonContent } from '@ionic/react'
import { Loading } from '~/components/shared'
import { ToastContainer, Slide } from 'react-toastify'
import NotFoundPage from '~/containers/NotFoundPage'

import { selectAppContentScrollY } from '~/redux/selectors'
import clsx from 'clsx'

const BodyContent = props => {
  const { children, className, loading, toastProps } = props

  const isScrollY = useSelector(selectAppContentScrollY)

  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  if (process.env.NODE_ENV === 'development') {
    if (error) {
      console.log('error', error)
    }
  }

  if (didCatch) {
    return (
      <NotFoundPage
        message={
          <div>
            <div>There is something off with Concord!</div>
            <div>
              <span>If this error occurs normally, please contact </span>
              <a href='mailto:hello@concordmaterials.com'>
                hello@concordmaterials.com
              </a>
            </div>
          </div>
        }
        onGoBack={() => {
          window.location.reload()
        }}
        buttonText='Reload Page'
      />
    )
  }

  return (
    <ErrorBoundary>
      <IonContent
        className={clsx('Body__content', className)}
        scrollY={isScrollY}
      >
        {children}
        {loading && <Loading />}
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          limit={4}
          newestOnTop
          closeOnClick
          rtl={false}
          style={{
            fontSize: '1.4rem',
            zIndex: 10000,
          }}
          transition={Slide}
          pauseOnFocusLoss
          draggable
          {...toastProps}
        />
      </IonContent>
    </ErrorBoundary>
  )
}

export default BodyContent
