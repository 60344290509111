import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGlCode } from '~/types/models/IGlCode'

const useModifyGlCode = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['glCodes', sessionUser?.id],
    [sessionUser?.id],
  )

  const addGlCode = useCallback(
    (data: IGlCode) => {
      queryClient.setQueryData<IGlCode[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateGlCode = useCallback(
    (id: number, data: IGlCode) => {
      queryClient.setQueryData<IGlCode[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeGlCode = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGlCode[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addGlCode,
    updateGlCode,
    removeGlCode,
  }
}

export default useModifyGlCode
