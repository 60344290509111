import useEnrollmentProfileForm from './useEnrollmentProfileForm'

import { Alert } from 'react-bootstrap'
import { ConcordFormStructure } from '~/components/shared'
import { When } from 'react-if'

import './styles.scss'

function EnrollmentProfileForm() {
  const {
    fields,
    onSubmitForm,
    formRef,
    isLoading,
    isLoadingCheckEnrollmentProfile,
    schema,
    isImplemtationComplete,
  } = useEnrollmentProfileForm()

  return (
    <div>
      <When condition={isImplemtationComplete}>
        <Alert variant='warning' className='EnrollmentProfileForm__banner'>
          Company review is complete. You may not update the EnrollmentProfile
          at this time
        </Alert>
      </When>
      <ConcordFormStructure
        isHiddenSearch
        isHiddenCancelButton
        fields={fields}
        onSubmit={onSubmitForm}
        ref={formRef}
        isLoading={isLoading || isLoadingCheckEnrollmentProfile}
        schema={schema}
        isHiddenSubmitButton={isImplemtationComplete}
        className='EnrollmentProfileForm__form'
      />
    </div>
  )
}
export default EnrollmentProfileForm
