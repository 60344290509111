import { DEFAULT_ICON_SIZE } from './constants'
import type { ISvgIconProps } from './type'

const ArrowForwardIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 1024 1024'
      fill={color}
      {...svgProps}
    >
      <path d='M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z' />
    </svg>
  )
}
export default ArrowForwardIcon
