import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import useStoreFilters from '~/hooks/useStoreFilters'
import { useConfirmationProvider } from '~/contexts'
import useStoreSorts from '~/hooks/useStoreSorts'
import { useRouter } from '~/hooks/useRouter'

import { IonIcon } from '@ionic/react'
import { Link } from 'react-router-dom'
import {
  funnelOutline,
  saveOutline,
  refreshOutline,
  cloudDownloadOutline,
  cogOutline,
} from 'ionicons/icons'
import { ContainerSearchBar, ToolTipOverlay } from '~/components/shared'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import ClearFiltersButton from './ClearFiltersButton'
import { ionicColor } from '~/constants/theme'
// import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getTitleRange } from '~/utils/getRangeDate'
import ContainerFilterTooltipItem from './TooltipItem'
import FilterModalPopup from './FilterModalPopup'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import useStoreColumns from '~/hooks/useStoreColumns'
import useFilterOptions from '~/hooks/useFilterOptions'
import { ControlledMenu, useMenuState, MenuItem } from '@szhsin/react-menu'
import RenderFilters from './RenderFilters'
import MultiselectOrderDropDown from '~/components/shared/MultiselectOrderDropdown'

import moment from 'moment'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { FILTER_OPTION_TYPES, PROFILE_SETTINGS } from '~/utils/constants'

import './ContainerFilter.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'

const ContainerFilter = ({
  columnsDisplayed,
  dataExported,
  filterData,
  filterOptions,
  //filterOrder
  hideExtraButtons = false,
  hideMarginTop,
  isMyCompanyPage = false,
  leftExtraSection,
  lineItem,
  moduleName,
  rightExtraSection,
  searchBarClassName,
  searchKey,
  searchPlaceholder = 'Search...',
  showSearch,
  sortOptions,
  onChangeColumnsDisplayed,
  onChangeFilters,
  onClearFilters,
  onFiltersReset,
  onResetFilterToDefault,
  onSearchKeyChange,
  onSortChange,
  setBorderColor,
  displayMode: displayModeProps,
  filterStyle,
}) => {
  const { isScreenDown } = useBreakpoint()
  const { getFilteOptions } = useFilterOptions(moduleName, {
    lineItem: lineItem ? lineItem : null,
  })
  const moduleFilters = moduleName ? getFilteOptions(moduleName) : filterOptions
  const [storeFilters, setStoreFilters, resetStoreData, { defaultEmptyValue }] =
    useStoreFilters(moduleName)

  const [storedSorts, setStoredSorts] = useStoreSorts(moduleName)
  const [storedColumns, setStoredColumns] = useStoreColumns(moduleName)
  const router = useRouter()

  const { confirmation } = useConfirmationProvider()

  const hideClearButton = _.isEqual(filterData, defaultEmptyValue)
  const hideResetToDefault = _.isEqual(filterData, storeFilters.data)

  const isEqualFilters = useMemo(() => {
    const mapFilters = {}

    _.forEach(filterData, (value, key) => {
      if (_.isPlainObject(value)) {
        if (_.has(value, 'startDate') && _.has(value, 'endDate')) {
          mapFilters[key] = {}
          mapFilters[key].startDate = moment(value.startDate).toISOString()
          mapFilters[key].endDate = moment(value.endDate).toISOString()
        } else {
          mapFilters[key] = value
        }
      } else {
        mapFilters[key] = value
      }
    })

    return _.isEqual(storeFilters.data, mapFilters)
  }, [filterData, storeFilters.data])

  const displayMode = useMemo(() => {
    return displayModeProps || storeFilters?.mode || 'horizontal'
  }, [displayModeProps, storeFilters])

  const containerFilterRef = useRef()
  const [isHovering, setIsHovering] = useState(false)

  const [isShowModal, setIsShowModal] = useState(false)

  const saveButtonRef = useRef()
  const [savingMenuProps, toggleSavingMenu] = useMenuState({ transition: true })

  useEffect(() => {
    if (isMyCompanyPage) {
      setBorderColor &&
        setBorderColor(!hideClearButton ? ionicColor.snowGrey : ionicColor.dark)
    }
  }, [setBorderColor, hideClearButton, isMyCompanyPage])

  const toggleFiltersModal = () => setIsShowModal(false)

  const disabledClearButtonSortOptions = useMemo(() => {
    if (sortOptions.length === 0) {
      return true
    }

    return _.every(sortOptions || [], ['sorted', false])
  }, [sortOptions])

  const disabledSaveButtonSortOptions = useMemo(() => {
    return _.isEqual(storedSorts.data, sortOptions)
  }, [sortOptions, storedSorts.data])

  const handleClearAllFilters = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to clear all filters!',
    })

    if (result === EYesNo.Yes) {
      resetStoreData()
      handleClearFilters()
    }
  }, [confirmation, resetStoreData, handleClearFilters])

  const handleSaveSortData = useCallback(() => {
    setStoredSorts({ data: sortOptions })
  }, [setStoredSorts, sortOptions])

  const handleClearSortData = useCallback(() => {
    const newSorts = sortOptions.map(sortItem => ({
      ...sortItem,
      sorted: false,
    }))
    onSortChange && onSortChange(newSorts)
  }, [onSortChange, sortOptions])

  const handleResetSortData = useCallback(() => {
    onSortChange && onSortChange(storedSorts.data)
  }, [onSortChange, storedSorts.data])

  const handleResetFilterToDefault = useCallback(() => {
    onResetFilterToDefault && onResetFilterToDefault(storeFilters.data)
  }, [onResetFilterToDefault, storeFilters.data])

  const handleClearFilters = useCallback(() => {
    onClearFilters && onClearFilters(defaultEmptyValue)
  }, [defaultEmptyValue, onClearFilters])

  const handleSortOptionsDirectToSettings = useCallback(() => {
    const url = queryString.stringifyUrl({
      url: '/profile',
      query: {
        sub_tab: moduleName,
        tab: PROFILE_SETTINGS.storedData,
        section: 'sorts',
      },
    })

    router.push(url)
  }, [moduleName, router])

  const renderTooltipFilterItem = useCallback(
    filterItem => {
      const { label, field, type, options } = filterItem
      switch (type) {
        case FILTER_OPTION_TYPES.dateRange: {
          const value = getTitleRange(filterData[field])

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value}
            />
          )
        }

        case 'multipleSelect': {
          const values = filterData[field]
          const value = (options || [])
            .filter(option => values.includes(option.value))
            .map(({ label }) => label)
            .join(', ')

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value || 'All'}
            />
          )
        }

        case 'singleSelect': {
          const value = filterData[field]

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value || 'All'}
            />
          )
        }

        default: {
          return null
        }
      }
    },
    [filterData],
  )

  const tooltipFilters = useMemo(() => {
    let tooltipMessage = null
    if (!moduleName) {
      tooltipMessage = (filterOptions || []).map(option =>
        renderTooltipFilterItem(option),
      )
    } else {
      let sourceArr =
        storeFilters.order && storeFilters.order.length > 0
          ? storeFilters.order
          : Object.keys(storeFilters.data)

      tooltipMessage = sourceArr.map(key => {
        let foundKey = moduleFilters.find(({ field }) => field == key)
        if (!foundKey) return null
        return renderTooltipFilterItem(foundKey)
      })
    }

    if (_.size(tooltipMessage) === 0) {
      return null
    }

    return <ul className='ContainerFilter__wrapFilterItem'>{tooltipMessage}</ul>
  }, [
    moduleName,
    filterOptions,
    renderTooltipFilterItem,
    storeFilters.order,
    storeFilters.data,
    moduleFilters,
  ])

  const handleToggleSaveFilter = useCallback(() => {
    if (savingMenuProps.state === 'closed' || !savingMenuProps.state) {
      toggleSavingMenu(true)
    } else {
      toggleSavingMenu(false)
    }
  }, [savingMenuProps.state, toggleSavingMenu])

  const handleSaveDefaultFilters = useCallback(() => {
    try {
      setStoreFilters({ data: filterData })
      toast.success('Saved filter successfully.')
    } catch {
      toast.error('An error has occurred.')
    }
  }, [filterData, setStoreFilters])

  const handleChangeFilters = useCallback(
    newFilters => {
      if (storeFilters.autoSave) {
        setStoreFilters({ data: newFilters })
      }
      onChangeFilters && onChangeFilters(newFilters)
    },
    [onChangeFilters, setStoreFilters, storeFilters.autoSave],
  )

  const renderFilterSection = useCallback(() => {
    if (displayMode === 'vertical' || isScreenDown('md')) {
      return null
    }

    return (
      <>
        <div
          className='container-filters-scroll hide-scrollbar'
          style={{ marginTop: hideMarginTop ? 0 : 18, ...filterStyle }}
          ref={containerFilterRef}
          onPointerEnter={() => setIsHovering(true)}
          onPointerLeave={() => setIsHovering(false)}
        >
          <div
            className={clsx('ContainerFilter__container', {
              active: !hideClearButton,
            })}
          >
            <RenderFilters
              filterData={filterData}
              filterOptions={moduleFilters}
              filterOrder={storeFilters.order}
              onChange={handleChangeFilters}
            />
          </div>
        </div>

        {!hideExtraButtons && (
          <>
            {!hideClearButton && (
              <ToolTipOverlay content='Clear All Filters' placement='bottom'>
                <span>
                  <ClearFiltersButton
                    onFiltersReset={handleClearFilters}
                    label=''
                  />
                </span>
              </ToolTipOverlay>
            )}

            {!hideResetToDefault && (
              <ToolTipOverlay
                content='Reset to Default Filters'
                placement='bottom'
              >
                <span>
                  <ClearFiltersButton
                    onFiltersReset={handleResetFilterToDefault}
                    label=''
                    icon={refreshOutline}
                  />
                </span>
              </ToolTipOverlay>
            )}

            {_.size(filterData) > 0 && moduleName && (
              <ClearFiltersButton
                onFiltersReset={handleToggleSaveFilter}
                label=''
                icon={saveOutline}
                className={clsx('save-filter-button', {
                  isActive: !isEqualFilters,
                })}
                ref={saveButtonRef}
                tooltip='Default Settings'
              />
            )}
          </>
        )}
      </>
    )
  }, [
    displayMode,
    filterData,
    storeFilters.order,
    moduleFilters,
    handleClearFilters,
    handleResetFilterToDefault,
    handleToggleSaveFilter,
    hideExtraButtons,
    hideClearButton,
    hideMarginTop,
    hideResetToDefault,
    isEqualFilters,
    isScreenDown,
    handleChangeFilters,
    moduleName,
    filterStyle,
  ])

  const mapColumns = useMemo(() => {
    if (_.size(columnsDisplayed) === 0) {
      return []
    }

    let columnsToMap = []
    if (columnsDisplayed.some(column => 'hideable' in column)) {
      columnsToMap = columnsDisplayed.filter(column => column.hideable)
    } else {
      columnsToMap = columnsDisplayed
    }

    return _.map(columnsToMap, col => ({
      ...col,
      sortField: col.field,
      // sorted: show,
    }))
  }, [columnsDisplayed])

  const tooltipColumns = useMemo(() => {
    if (mapColumns.length === 0) {
      return ''
    }
    const mapLabel = _(mapColumns).filter('show', true).map('label').join(', ')
    const totalColumns = mapColumns.length
    const totalSelectedColumns = _(mapColumns).filter('show', true).size()

    return (
      <div>
        <span>
          <strong>
            COLUMNS - {`${totalSelectedColumns} / ${totalColumns}`}
          </strong>
        </span>
        <br />
        <span>{mapLabel}</span>
      </div>
    )
  }, [mapColumns])

  const disabledClearButtonColumns = useMemo(() => {
    if (columnsDisplayed.length === 0) {
      return true
    }

    return _.every(columnsDisplayed || [], ['show', false])
  }, [columnsDisplayed])

  const disabledSaveButtonColumns = useMemo(() => {
    return _.isEqual(storedColumns.data, columnsDisplayed)
  }, [columnsDisplayed, storedColumns.data])

  const handleChangeColumns = useCallback(
    nextColumns => {
      const mapNextColumns = nextColumns.map(col => ({
        ...col,
        field: col.sortField,
        // show: sorted,
      }))

      onChangeColumnsDisplayed && onChangeColumnsDisplayed(mapNextColumns)
    },
    [onChangeColumnsDisplayed],
  )

  const handleSaveColumns = useCallback(() => {
    setStoredColumns({ data: columnsDisplayed })
  }, [columnsDisplayed, setStoredColumns])

  const handleClearColumns = useCallback(() => {
    const newColumns = columnsDisplayed.map(column => ({
      ...column,
      show: false,
    }))

    onChangeColumnsDisplayed && onChangeColumnsDisplayed(newColumns)
  }, [columnsDisplayed, onChangeColumnsDisplayed])

  const handleResetColumns = useCallback(() => {
    onChangeColumnsDisplayed && onChangeColumnsDisplayed(storedColumns.data)
  }, [onChangeColumnsDisplayed, storedColumns.data])

  const handleDirectToSettingsColumns = useCallback(() => {
    const url = queryString.stringifyUrl({
      url: '/profile',
      query: {
        sub_tab: moduleName,
        tab: PROFILE_SETTINGS.storedData,
        section: 'columns',
      },
    })

    router.push(url)
  }, [moduleName, router])

  const hideGroupButtons = useMemo(
    () =>
      (sortOptions?.length || 0) == 0 &&
      (columnsDisplayed?.length || 0) == 0 &&
      (dataExported?.length || 0) == 0 &&
      !(displayMode === 'vertical' || isScreenDown('md')),
    [sortOptions, columnsDisplayed, dataExported, displayMode, isScreenDown],
  )

  return (
    <>
      {showSearch && (
        <ContainerSearchBar
          searchBarPlaceholder={searchPlaceholder}
          searchBarValue={searchKey}
          onSearchBarChange={onSearchKeyChange}
          searchBarParent='ContainerFilter'
          className={searchBarClassName}
        />
      )}
      {leftExtraSection}
      {!hideGroupButtons && (
        <ButtonGroup className='ContainerFilter__buttonGroupContainer d-flex'>
          {sortOptions && sortOptions.length > 0 && (
            <MultiselectOrderDropDown
              options={sortOptions}
              onChange={onSortChange}
              isButtonGroup
              onSave={handleSaveSortData}
              onClear={handleClearSortData}
              onReset={handleResetSortData}
              onGoPage={handleSortOptionsDirectToSettings}
              disableClearButton={disabledClearButtonSortOptions}
              disableSaveButton={disabledSaveButtonSortOptions}
              disableResetButton={disabledSaveButtonSortOptions}
              className='ContainerFilter__buttonGroup sortFilters'
            />
          )}
          {columnsDisplayed.length > 0 && (
            <MultiselectOrderDropDown
              options={mapColumns}
              onChange={handleChangeColumns}
              icon={cogOutline}
              isButtonGroup
              isCheckedKey={'show'}
              hasDirection={false}
              onSave={handleSaveColumns}
              onClear={handleClearColumns}
              onReset={handleResetColumns}
              onGoPage={handleDirectToSettingsColumns}
              disableClearButton={disabledClearButtonColumns}
              disableSaveButton={disabledSaveButtonColumns}
              disableResetButton={disabledSaveButtonColumns}
              tooltipProps={{
                placement: 'bottom',
                content: tooltipColumns,
              }}
              className={clsx(
                'ColumnsButton__container',
                'ContainerFilter__buttonGroup',
              )}
            />
          )}

          {dataExported?.length > 0 && (
            <ToolTipOverlay content='Export to CSV' placement='bottom'>
              <div className='ContainerFilter__buttonGroup export-button'>
                <ToggleButton
                  size='lg'
                  type='radio'
                  name='narrow'
                  variant='outline-primary'
                >
                  <CSVLink
                    data={dataExported}
                    filename={moduleName}
                    separator=';'
                    target='_blank'
                  >
                    <IonIcon
                      className='Filter__icon'
                      icon={cloudDownloadOutline}
                    />
                  </CSVLink>
                </ToggleButton>
              </div>
            </ToolTipOverlay>
          )}
          {(displayMode === 'vertical' || isScreenDown('md')) && (
            <>
              <ToolTipOverlay content='Filters' placement='bottom'>
                <div
                  className={clsx('ContainerFilter__buttonGroup', {
                    trigger: !hideClearButton,
                  })}
                >
                  <ToggleButton
                    size='lg'
                    type='radio'
                    name='narrow'
                    variant='outline-primary'
                    onClick={() => setIsShowModal(true)}
                  >
                    <IonIcon className='Filter__icon' icon={funnelOutline} />
                  </ToggleButton>
                </div>
              </ToolTipOverlay>

              <ToolTipOverlay content='Save filters' placement='bottom'>
                <div
                  className={clsx('ContainerFilter__buttonGroup', {
                    focus: !isEqualFilters,
                  })}
                >
                  <ToggleButton
                    size='lg'
                    type='radio'
                    name='narrow'
                    variant='outline-primary'
                    ref={saveButtonRef}
                    onClick={handleToggleSaveFilter}
                  >
                    <IonIcon className='Filter__icon' icon={saveOutline} />
                  </ToggleButton>
                </div>
              </ToolTipOverlay>
            </>
          )}
        </ButtonGroup>
      )}

      {moduleFilters.length > 0 && renderFilterSection()}

      {rightExtraSection}

      <FilterModalPopup
        open={isShowModal}
        onClose={toggleFiltersModal}
        onReset={onFiltersReset}
        hideResetButton={hideClearButton}
        filterData={filterData}
        filterOptions={moduleFilters}
        onChangeFilters={handleChangeFilters}
      />

      {tooltipFilters && (
        <ControlledMenu
          direction='bottom'
          className='ContainerFilter__filterOverlay'
          state={isHovering ? 'open' : 'closed'}
          anchorRef={containerFilterRef}
          onClick={() => setIsHovering(false)}
          onClose={() => setIsHovering(false)}
        >
          {tooltipFilters}
        </ControlledMenu>
      )}

      {_.size(filterData) > 0 && moduleName && (
        <ControlledMenu
          {...savingMenuProps}
          anchorRef={saveButtonRef}
          onPointerLeave={() => toggleSavingMenu(false)}
          onClose={() => toggleSavingMenu(false)}
        >
          <ToolTipOverlay
            placement='left'
            content={storeFilters.autoSave ? 'Auto Save: ON' : ''}
          >
            <MenuItem
              disabled={storeFilters.autoSave || isEqualFilters}
              style={{ fontSize: 14 }}
              onClick={handleSaveDefaultFilters}
            >
              Update Default Filters
            </MenuItem>
          </ToolTipOverlay>
          <MenuItem
            style={{ fontSize: 14 }}
            onClick={handleClearAllFilters}
            disabled={_.isEqual(defaultEmptyValue, storeFilters.data)}
          >
            Clear All Filters and Save
          </MenuItem>
          {moduleName && (
            <MenuItem style={{ fontSize: 14 }}>
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/profile?sub_tab=${moduleName}&tab=${PROFILE_SETTINGS.storedData}&section=filters`}
              >
                Go to settings page
              </Link>
            </MenuItem>
          )}
        </ControlledMenu>
      )}
    </>
  )
}

// ContainerFilter.propTypes = {
//   columnsDisplayed: PropTypes.array,
//   onChangeColumnsDisplayed: PropTypes.func,
//   leftExtraSection: PropTypes.func,
//   rightExtraSection: PropTypes.func
// }

ContainerFilter.defaultProps = {
  buyerTerminalField: [],
  dropDownField: [],
  multiSelectField: [],
  rangeSelectFilter: [],
  dateRangeTitle: 'Created At',
  ticketDateRangeTitle: 'Date',
  tableColumns: [],
  onChangeColumns: () => { },
  columnsDisplayed: [],
  tooltipDirection: 'left',

  filterOptions: [],
  filterData: {},
  sortOptions: [],
}

export default ContainerFilter
