import _ from 'lodash'
import * as types from '../actions/actionTypes'

export const initialState = {
  isCollapsed: false,
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR_VIEW: {
      let isCollapsed = !state.isCollapsed

      if (!_.isNil(action.payload)) {
        isCollapsed = action.payload
      }

      return {
        ...state,
        isCollapsed,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default sidebarReducer
