import { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCompanies } from '~/redux/selectors'
import { getCoordinates } from '~/utils/utils'

import type { ITerminalMarkerProps } from './type'
import type { ICompany } from '~/types/models/ICompany'

export type FuncIsTerminalInsideGeofenceParams = {
  markerPosition: google.maps.LatLng
  polyPath: google.maps.LatLngLiteral[]
}

const useTerminalMarker = (props: ITerminalMarkerProps) => {
  const {
    terminal,
    icon,
    color,
    extraInfo,
    isShowInfoBox,
    title,
    isHiddenGeofence,
    onMoveMarker,
  } = props
  const { location, coordinates, geofence } = terminal

  const [isOpenInfoBox, setIsOpenInfoBox] = useState(isShowInfoBox)

  const companies: Record<number, ICompany> = useSelector(selectCompanies)

  const polygonPoints = useMemo<google.maps.LatLngLiteral[]>(
    () => getCoordinates(coordinates, geofence),
    [coordinates, geofence],
  )

  const company = useMemo<ICompany | null>(
    () => companies[terminal.companyId],
    [companies, terminal.companyId],
  )

  const position = useMemo(() => {
    if (location) {
      return new google.maps.LatLng(
        parseFloat(location?.lat as string),
        parseFloat(location?.lng as string),
      )
    }

    return undefined
  }, [location])

  const onToggleOpenInfoBox = useCallback(() => {
    setIsOpenInfoBox(prev => !prev)
  }, [])

  const onDragMarker = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const location = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      onMoveMarker && onMoveMarker(location)
    },
    [onMoveMarker],
  )

  return {
    position,
    onToggleOpenInfoBox,
    isOpenInfoBox,
    terminal,
    company,
    polygonPoints,
    location,
    icon,
    color,
    extraInfo,
    title,
    isHiddenGeofence,
    onDragMarker,
  }
}

export default useTerminalMarker
