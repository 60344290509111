import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyTags from './useModifyTags'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetTagsParams, ITag } from '~/types/models/ITag'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryTags = (
  params: IGetTagsParams = {},
  options?: Partial<UseQueryOptions<ITag[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'tags',
      sessionUser?.id,
      buildGetUrl(apiClient.tags.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.tags.get(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const tagsData = useMemo(() => data || [], [data])

  const tagOptions = useMemo(
    () =>
      tagsData.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [tagsData],
  )

  const { addTag, updateTag, removeTag, renewAllTags } = useModifyTags(params)

  return {
    tagsData,
    isTagsLoading: isLoading,
    tagOptions,
    addTag,
    updateTag,
    removeTag,
    renewAllTags,
  }
}

export default useQueryTags
