import { CommonDialogV2 } from '~/components/shared'
import PayScheduleForm from './PayScheduleForm'

import './DialogPayScheduleForm.scss'

function DialogPayScheduleForm(props) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title="Pay Schedule"
      isHiddenOkButton
    >
      <PayScheduleForm
        isHiddenCancelButton
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
      />
    </CommonDialogV2>
  )
}

export default DialogPayScheduleForm
