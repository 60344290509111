import { RTTableFooterRow } from './RTTableFooterRow'
import type {
  RTColumnVirtualizer,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'

export interface IRTTableFooterProps<TData extends IReusableTableRowData> {
  columnVirtualizer?: RTColumnVirtualizer
  table: ReusableTableInstance<TData>
}

export const RTTableFooter = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  table,
  ...rest
}: IRTTableFooterProps<TData>) => {
  const {
    getFooterGroups,
    getState,
    options: { enableStickyFooter, layoutMode },
    refs: { tableFooterRef },
  } = table
  const { isFullScreen } = getState()

  const stickFooter =
    (isFullScreen || enableStickyFooter) && enableStickyFooter !== false

  return (
    <tfoot
      {...rest}
      ref={(ref: HTMLTableSectionElement) => {
        tableFooterRef.current = ref
        if ((rest as any)?.ref) {
          // @ts-ignore
          rest.ref.current = ref
        }
      }}
      style={{
        bottom: stickFooter ? 0 : undefined,
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        opacity: stickFooter ? 0.97 : undefined,
        outline: stickFooter ? '#e5e5e5' : undefined,
        position: stickFooter ? 'sticky' : 'relative',
        zIndex: stickFooter ? 1 : undefined,
        borderBottom: '1px solid rgb(255, 255, 255)',
      }}
    >
      {getFooterGroups().map(footerGroup => (
        <RTTableFooterRow
          columnVirtualizer={columnVirtualizer}
          footerGroup={footerGroup as any}
          key={footerGroup.id}
          table={table}
        />
      ))}
    </tfoot>
  )
}
