import { useMemo } from 'react'

import { Page, View, Document, StyleSheet, Text } from '@react-pdf/renderer'
import { PDFInvoiceHeader, PDFDataTable } from '~/components/shared'

import { formatCurrencyToDollar } from '~/utils/formatCurrency'

const styles = StyleSheet.create({
  page: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingTop: 8,
  },
  container: {
    fontSize: 11,
  },
  footer: {
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%'
  },
  footerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6
  },
  footerRowLabel: {
    minWidth: 80,
    textAlign: 'left'
  },
  footerRowValue: {
    marginLeft: 4,
    // minWidth: 300,
    textAlign: 'right'
  }
})

function PDFProjectedInvoice(props) {
  const {
    invoice,
    terminals,
    data,
    buyerSellerProducts,
    order,
    orderExtras = [],
    orderExtraRanges,
    sellerCompany,
    buyerCompany,
  } =
    props

  const subtotal = useMemo(
    () => {
      return  orderExtraRanges.length > 0 &&
      orderExtraRanges.reduce((acc, range) => {
        const bspId = orderExtras.find(
          ({ id }) => id === range?.orderExtraId,
        ).buyerSellerProductId
        const bsp = buyerSellerProducts[bspId]

        const rowTotal = parseFloat(
          parseFloat(bsp?.price) *
            parseFloat(range?.qty) *
            (range?.endLoad - range?.startLoad + 1),
        )

        return isNaN(rowTotal) ? acc + 0 : acc + rowTotal
      }, 0)
    },
    [buyerSellerProducts, orderExtraRanges, orderExtras],
  )

  const orderTotal = order?.qty * order?.product?.price * order?.lds

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    [],
  )

  const sellerTerminal = useMemo(() => {
    return terminals.find(({ id }) => id === invoice.sellerTerminalId)
  }, [invoice?.sellerTerminalId, terminals])

  const buyerTerminal = useMemo(() => {
    return terminals.find(({ id }) => id === invoice.buyerTerminalId)
  }, [invoice?.buyerTerminalId, terminals])

  const totalQty = useMemo(() => {
    const qty = invoice.qty ? Number(invoice.qty) : 0
    return qty.toFixed(2)
  }, [invoice.qty])

  const totalPrice = useMemo(() => {
    const total = invoice.total ? invoice.total : 0

    return formatter.format(total)
  }, [formatter, invoice.total])

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.container}>
          <PDFInvoiceHeader
            seller={{
              name: sellerCompany?.name,
            }}
            buyer={{
              name: buyerCompany?.name,
            }}
            sellerTerminal={sellerTerminal}
            buyerTerminal={buyerTerminal}
            rawSellerTerminal={
              invoice?.sellerTerminalCode || invoice?.sellerTerminalName
            }
            rawBuyerTerminal={
              invoice?.buyerTerminalCode || invoice?.buyerTerminalName
            }
            totalQty={totalQty}
            totalPrice={totalPrice}
            startDate={invoice.startDate}
            endDate={invoice.endDate}
            dueDate={invoice.dueDate}
          />
        </View>

        <View>
          <PDFDataTable
            data={data}
            isHiddenFooter={false}
            renderFooter={() => (
              <View style={styles.footer}>
                <View>
                  <View style={styles.footerRow}>
                    <Text style={styles.footerRowLabel}>Subtotal: </Text>
                    <Text style={styles.footerRowValue}>
                      {formatCurrencyToDollar.format(subtotal + orderTotal)}
                    </Text>
                  </View>


                  <View style={styles.footerRow}>
                    <Text style={styles.footerRowLabel}>Tax: </Text>
                    <Text style={styles.footerRowValue}>
                      {formatCurrencyToDollar.format(invoice?.tax)}
                    </Text>
                  </View>


                  <View style={styles.footerRow}>
                    <Text style={styles.footerRowLabel}>Total: </Text>
                    <Text style={styles.footerRowValue}>
                      {formatCurrencyToDollar.format(
                        parseFloat(subtotal) + orderTotal + parseFloat(invoice?.tax)
                      )}
                    </Text>
                  </View>
                </View>

              </View>
            )}
            columns={[
              {
                Header: 'Product',
                accessor: 'product.name',
                width: 200,
              },
              {
                Header: 'Price',
                accessor: 'price',
                align: 'right',
                width: 100,
                valueGetter({ rowData }) {
                  if (Number.isNaN(rowData.price) || rowData.price === 'NaN' || !rowData.price) {
                    return ''
                  }
                  return formatCurrencyToDollar.format(rowData.price)
                }
              },
              {
                Header: 'Qty',
                accessor: 'qty',
                align: 'right',
                width: 100,
              },
              {
                Header: 'Loads',
                accessor: 'lds',
                align: 'right',
                width: 50,
              },
              {
                Header: 'Total Price',
                accessor: 'totalPrice',
                align: 'right',
                width: 150,
                valueGetter({ rowData }) {
                  if (rowData?.qty && rowData?.product?.price && rowData?.lds) {
                    const totalPrice = rowData?.qty * rowData?.product?.price * rowData?.lds
                    return formatCurrencyToDollar.format(totalPrice)
                  }

                  return ''
                }
              }
            ]}
          />
        </View>
      </Page>
    </Document>
  )
}

PDFProjectedInvoice.propTypes = {}

export default PDFProjectedInvoice
