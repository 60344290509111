import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import OrderExtraForm from './OrderExtraForm'
import { IDialogOrderExtraFormProps } from './type'

function DialogOrderExtraForm(props: IDialogOrderExtraFormProps) {
  const {
    formData,
    orderId,
    scheduleId,
    afterCreate,
    afterUpdate,
    onClose,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Order Extra'
      isHiddenOkButton
      onClose={onClose}
    >
      <OrderExtraForm
        formData={formData}
        orderId={orderId}
        scheduleId={scheduleId}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogOrderExtraForm
