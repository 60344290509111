import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import type {
  RTDefinedTableOptions,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../types'
import { getAllLeafColumnDefs, getColumnId } from './column.utils'

export function defaultDisplayColumnProps<TData extends IReusableTableRowData>({
  header,
  id,
  size,
  tableOptions,
}: {
  header?: string
  id: ERTDisplayColumnId
  size: number
  tableOptions: RTDefinedTableOptions<TData>
}) {
  const { defaultDisplayColumn, displayColumnDefOptions } = tableOptions
  return {
    ...defaultDisplayColumn,
    header: header ? header : '',
    size,
    ...displayColumnDefOptions?.[id],
    id,
  } as const
}

export const showRowDragColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => {
  const { enableRowDragging, enableRowOrdering } = tableOptions
  return !!(enableRowDragging || enableRowOrdering)
}

export const showRowExpandColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => {
  const {
    enableExpanding,
    enableGrouping,
    renderDetailPanel,
    state: { grouping },
    columns,
  } = tableOptions

  const hasGroupingColumn = columns.some(({ enableGrouping }) => enableGrouping)

  return !!(
    enableExpanding ||
    ((enableGrouping || hasGroupingColumn) && grouping?.length) ||
    renderDetailPanel
  )
}

export const showRowActionsColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => {
  const {
    createDisplayMode,
    editDisplayMode,
    enableEditing,
    enableRowActions,
    state: { creatingRow },
  } = tableOptions
  return !!(
    enableRowActions ||
    (creatingRow && createDisplayMode === 'row') ||
    (enableEditing && ['modal', 'row'].includes(editDisplayMode ?? ''))
  )
}

export const showRowSelectionColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => !!tableOptions.enableRowSelection

export const showRowNumbersColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => !!tableOptions.enableRowNumbers

export const showRowSpacerColumn = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): boolean => tableOptions.layoutMode === 'grid-no-grow'

export const getLeadingDisplayColumnIds = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
) =>
  [
    showRowSelectionColumn(tableOptions) && ERTDisplayColumnId.select,
    showRowDragColumn(tableOptions) && ERTDisplayColumnId.drag,
    tableOptions.positionActionsColumn === 'first' &&
      showRowActionsColumn(tableOptions) &&
      ERTDisplayColumnId.actions,
    tableOptions.positionExpandColumn === 'first' &&
      showRowExpandColumn(tableOptions) &&
      ERTDisplayColumnId.expand,
    showRowNumbersColumn(tableOptions) && ERTDisplayColumnId.numbers,
  ].filter(Boolean) as ERTDisplayColumnId[]

export const getTrailingDisplayColumnIds = <
  TData extends IReusableTableRowData,
>(
  tableOptions: RTStatefulTableOptions<TData>,
) =>
  [
    tableOptions.positionActionsColumn === 'last' &&
      showRowActionsColumn(tableOptions) &&
      ERTDisplayColumnId.actions,
    tableOptions.positionExpandColumn === 'last' &&
      showRowExpandColumn(tableOptions) &&
      ERTDisplayColumnId.expand,
    showRowSpacerColumn(tableOptions) && ERTDisplayColumnId.spacer,
  ].filter(Boolean) as ERTDisplayColumnId[]

export const getDefaultColumnOrderIds = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
  reset = false,
) => {
  const {
    state: { columnOrder: currentColumnOrderIds = [] },
  } = tableOptions

  const leadingDisplayColIds: string[] =
    getLeadingDisplayColumnIds(tableOptions)
  const trailingDisplayColIds: string[] =
    getTrailingDisplayColumnIds(tableOptions)

  const defaultColumnDefIds = getAllLeafColumnDefs(tableOptions.columns).map(
    columnDef => getColumnId(columnDef),
  )

  let allLeafColumnDefIds = reset
    ? defaultColumnDefIds
    : Array.from(new Set([...currentColumnOrderIds, ...defaultColumnDefIds]))

  allLeafColumnDefIds = allLeafColumnDefIds.filter(
    colId =>
      !leadingDisplayColIds.includes(colId) &&
      !trailingDisplayColIds.includes(colId),
  )

  return [
    ...leadingDisplayColIds,
    ...allLeafColumnDefIds,
    ...trailingDisplayColIds,
  ]
}
