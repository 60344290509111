import { useCallback, useState, useMemo } from 'react'
import useTableAddressColumns from './useTableAddressColumns'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogAddressForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'
import { toast } from 'react-toastify'
import { moduleName } from '~/utils/constants'

import './AddressTable.scss'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryAddresses } from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'

function AddressTable(props) {
  const { addressableType, addressableId, tableHeight } = props

  const windowSize = useWindowSize()

  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    formData: null,
  })

  const {
    addressesData,
    isLoadingAddressesData,
    addAddress,
    updateAddress,
    removeAddress,
  } = useQueryAddresses({
    filters: {
      addressableId,
      addressableType,
    },
  })

  const { confirmation } = useConfirmationProvider()

  const onDeleteAddress = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete address #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        try {
          const response = await apiClient.addresses.delete(rowData.id)
          if (response.id) {
            removeAddress(rowData.id)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(error.message)
        }
      }
    },
    [confirmation, removeAddress],
  )

  const onOpenEditForm = useCallback(rowData => {
    setDialogProps({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const { columns } = useTableAddressColumns({
    addressesData,
  })

  const afterCreateAddress = useCallback(
    createdAddress => {
      addAddress(createdAddress)
      setDialogProps({ isOpen: false, formData: null })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const afterUpdateAddress = useCallback(
    updatedAddress => {
      updateAddress(updatedAddress.id, updatedAddress)
      setDialogProps({ isOpen: false, formData: null })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onCloseDialog = useCallback(() => {
    setDialogProps({
      isOpen: false,
      formData: null,
    })
  }, [])

  const getDialogProps = useMemo(() => {
    return {
      ...dialogProps,
      onClose: onCloseDialog,
    }
  }, [dialogProps, onCloseDialog])

  const onOpenDialog = useCallback(() => {
    setDialogProps({
      isOpen: true,
      formData: null,
    })
  }, [])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableCompanyView
          enableTopToolbar
          enableRowActions
          enableColumnPinning
          companyViewProps={{
            name: moduleName.company.addresses,
            description: 'Manage your addresses.',
            onClickTopButton: onOpenDialog,
          }}
          columns={columns}
          data={addressesData}
          tableHeight={tableHeight || windowSize.height - 220}
          state={{
            isLoading: isLoadingAddressesData,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 90,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => onOpenEditForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => onDeleteAddress(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
        />
      </div>

      <DialogAddressForm
        {...getDialogProps}
        addressableType={addressableType}
        addressableId={addressableId}
        addressData={addressesData}
        afterCreate={afterCreateAddress}
        afterUpdate={afterUpdateAddress}
      />
    </>
  )
}
AddressTable.propTypes = {}
export default AddressTable
