import { createSlice } from '@reduxjs/toolkit'

export const buyerSellersUiSlice = createSlice({
  name: 'buyerSellers',
  initialState: {
    loading: false,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
  },
})

export default buyerSellersUiSlice
