import { useCallback, useState } from 'react'
import useTableBankAccounts from './useTableBankAccounts'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogBankAccountForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { moduleName } from '~/utils/constants'

import './TableBankAccounts.scss'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryBankAccounts } from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'

function TableBankAccounts(props) {
  const { tableHeight } = props

  const {
    bankAccountsData,
    isLoadingBankAccounts,
    addBankAccount,
    updateBankAccount,
    removeBankAccount,
  } = useQueryBankAccounts()

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    formData: null,
  })

  const onOpenCreateDialog = useCallback(() => {
    setDialogState({
      isOpen: true,
      formData: null,
    })
  }, [])

  const onCloseDialog = useCallback(() => {
    setDialogState({
      isOpen: false,
      formData: null,
    })
  }, [])

  const onOpenEditDialog = useCallback(rowData => {
    setDialogState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const afterCreate = newBankAccount => {
    onCloseDialog()
    addBankAccount(newBankAccount)
  }

  const afterUpdate = bankAccount => {
    onCloseDialog()
    updateBankAccount(bankAccount.id, bankAccount)
  }

  const onRemovePhoneNumber = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete bank account ${rowData.name}`,
      })
      if (result === EYesNo.Yes) {
        try {
          const response = await apiClient.bankAccounts.delete(rowData.id)
          if (response.id) {
            removeBankAccount(response.id)
            toast.success(toastMessages.deleteSuccess)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(error.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { columns } = useTableBankAccounts()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={bankAccountsData}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          companyViewProps={{
            name: moduleName.company.bankAccounts,
            description: 'Manage your bank accounts.',
            onClickTopButton: onOpenCreateDialog,
          }}
          tableHeight={tableHeight || windowSize.height - 220}
          state={{
            isLoading: isLoadingBankAccounts,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              maxSize: 80,
              minSize: 80,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => onOpenEditDialog(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => onRemovePhoneNumber(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
        />
      </div>
      <DialogBankAccountForm
        isOpen={dialogState.isOpen}
        formData={dialogState.formData}
        onClose={onCloseDialog}
        afterUpdate={afterUpdate}
        afterCreate={afterCreate}
      />
    </>
  )
}

export default TableBankAccounts
