import { createSlice } from '@reduxjs/toolkit'

const sellerProductsSlice = createSlice({
  name: 'sellerProducts',
  initialState: {},
  reducers: {
    initializeSellerProducts: (state, action) => {
      const newState = {}

      action.payload.forEach(sellerProduct => {
        newState[sellerProduct.id] = sellerProduct
      })

      return newState
    },
    updateSellerProducts: (state, action) => {
      action.payload.forEach(sellerProduct => {
        if (state[sellerProduct.id]) {
          state[sellerProduct.id] = sellerProduct
          if (sellerProduct.deletedAt !== null) delete state[sellerProduct.id]
        }
      })
    },
    updateSellerProduct: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = payload
      }
    },
  },
})

export default sellerProductsSlice
