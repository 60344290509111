import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import {
  ellipseOutline,
  addCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { selectSessionUser } from '~/redux/selectors'

const OverallProgress = ({
  progress,
  formStructure,
  onViewProgress = null,
  onLogout,
}) => {
  const currentUser = useSelector(selectSessionUser)

  const returnIcon = stageNum => {
    if (progress < stageNum) {
      return ellipseOutline
    }
    if (progress == stageNum) {
      return addCircleOutline
    }

    return checkmarkCircleOutline
  }

  const progressShown = formStructure.filter(({ isHidden }) => !isHidden)

  return (
    <div>
      <Container>
        <Row className='mt-2'>
          <Col className='d-grid'>
            <Button
              variant='outline-secondary'
              className='m-2 mx-4'
              onClick={onViewProgress}
            >
              View your submissions
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className='icons mt-4' sm={3}>
            {progressShown &&
              progressShown.map((stage, i, arr) => (
                <>
                  <Row
                    key={i}
                    className='mx-auto '
                    style={
                      progress < stage.progress
                        ? { color: '#EAECF0' }
                        : { color: '#0d6efd' }
                    }
                  >
                    <IonIcon icon={returnIcon(stage.progress)} />
                  </Row>
                  {!(i + 1 === arr.length) && (
                    <div
                      className='vl'
                      style={
                        progress < arr[i + 1].progress
                          ? { borderColor: '#EAECF0' }
                          : { borderColor: '#0d6efd' }
                      }
                    ></div>
                  )}
                </>
              ))}
          </Col>

          <Col className='text mt-4'>
            {progressShown &&
              progressShown.map((stage, index) => (
                <Row className='mx-auto spacing' key={index}>
                  {stage.label}
                </Row>
              ))}
          </Col>
        </Row>
        {currentUser?.id && (
          <Row>
            <Col className='d-grid'>
              <Button
                onClick={onLogout}
                variant='outline-secondary'
                className='m-4'
              >
                Logout
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default OverallProgress
