import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_SELLER_PRODUCT_COLUMNS } from '~/constants/companySubTabs'

const sellerProductsSlice = createSlice({
  name: moduleName.company.sellerProducts,
  initialState: {
    columns: DEFAULT_COMPANY_SELLER_PRODUCT_COLUMNS,
  },
})

export default sellerProductsSlice
