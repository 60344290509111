import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectDocumentColumns,
  selectDocumentsUiSortableColumns,
  selectUserDocumentsColumns,
  selectTerminals,
} from '~/redux/selectors'
import DEFAULT_DOCUMENT_COLUMNS from '~/constants/documents/columns'
import ContainerFilterV3 from '~/components/shared/ContainerFilterV2/ContainerFilterV3'
import PropTypes from 'prop-types'
import useDocumentsUiControls from '~/hooks/useDocuments/useDocumentsUiControls'
import { deepCopy } from '~/utils/utils'
import mapExportDataFromColumns from '~/utils/mapExportDataFromColumns'

import './styles.scss'

const DocumentsHeader = ({ onSaveDefaultFilters, documents }) => {
  const { onUpdateFilters, onUpdateColumns, onUpdateFilterSearchKey } =
    useDocumentsUiControls()

  const displayColumns = useSelector(selectDocumentColumns)
  const documentFilters = useSelector(state => state.ui.documents.filters) //modernize
  const sortables = useSelector(selectDocumentsUiSortableColumns)
  const columnsFromUserTableConfigurations = useSelector(
    selectUserDocumentsColumns,
  )
  const terminals = useSelector(selectTerminals)

  const exportedColumns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'seller.label',
        Header: 'Seller',
      },
      {
        accessor: 'userEmail',
        exportValueGetter(rowData) {
          const { user } = rowData
          if (user) {
            const fullName = `${user.firstName} ${user.lastName}`.trim()

            return `${rowData.email || ''} - ${fullName}`
          }

          return ''
        },
        Header: 'User',
      },
      {
        accessor: 'docType',
        Header: 'Doc Type',
      },
      {
        accessor: 'buyerTerminalId',
        exportValueGetter(rowData) {
          const terminal = terminals.find(
            ({ id }) => rowData.buyerTerminalId === id,
          )

          return terminal?.name
        },
        Header: 'Buyer Terminal',
      },
      {
        accessor: 'sellerTerminalId',
        exportValueGetter(rowData) {
          const terminal = terminals.find(
            ({ id }) => rowData.sellerTerminalId === id,
          )

          return terminal?.name
        },
        Header: 'Seller Terminal',
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        accessor: 'totalPages',
        Header: 'Pages',
      },
    ],
    [terminals],
  )

  const filterExportColumns = useMemo(() => {
    const newColumns = []
    exportedColumns.forEach(column => {
      const findDisplayColumn = displayColumns.find(
        ({ show, columnName }) => column.accessor === columnName && show,
      )
      if (findDisplayColumn) {
        newColumns.push(column)
      }
    })

    return newColumns
  }, [displayColumns, exportedColumns])

  const dataExported = mapExportDataFromColumns(filterExportColumns, documents)

  useEffect(() => {
    if (columnsFromUserTableConfigurations.length > 0) {
      onUpdateColumns(columnsFromUserTableConfigurations)
    }
  }, [columnsFromUserTableConfigurations, onUpdateColumns])

  const handleUpdateColumns = useCallback(
    updateColumns => {
      const newColumns = deepCopy(displayColumns)

      updateColumns.forEach((option, index) => {
        const updateIndex = newColumns.findIndex(
          column => column.field == option.field,
        )

        newColumns[updateIndex] = {
          ...option,
          displayIndex: index,
        }
      })

      onUpdateColumns && onUpdateColumns(newColumns)
    },
    [displayColumns, onUpdateColumns],
  )

  const handleUpdateSortOptions = useCallback(
    updatedOptions => {
      const newOptions = deepCopy(sortables)

      updatedOptions.forEach((option, index) => {
        const updateIndex = newOptions.findIndex(
          column => column.field == option.field,
        )

        newOptions[updateIndex] = {
          ...option,
          sortIndex: index,
        }
      })

      onUpdateColumns && onUpdateColumns(newOptions)
    },
    [sortables, onUpdateColumns],
  )

  const handleResetFilters = useCallback(
    newFilters => {
      onUpdateFilters(newFilters)
    },
    [onUpdateFilters],
  )

  const handleResetFilterToDefault = useCallback(
    newFilters => {
      onUpdateFilters(newFilters)
    },
    [onUpdateFilters],
  )

  return (
    <ContainerFilterV3
      showSearch
      searchKey={documentFilters.searchWord}
      onSearchKeyChange={onUpdateFilterSearchKey}
      sortOptions={sortables}
      onSortChange={handleUpdateSortOptions}
      columnsDisplayed={displayColumns}
      columnsFromUserTableConfigurations={columnsFromUserTableConfigurations}
      onChangeColumnsDisplayed={handleUpdateColumns}
      filterData={documentFilters}
      onSaveDefaultFilters={onSaveDefaultFilters}
      moduleName='documents'
      onChangeFilters={onUpdateFilters}
      onResetFilterToDefault={handleResetFilterToDefault}
      onClearFilters={handleResetFilters}
      DEFAULT_COLUMNS={DEFAULT_DOCUMENT_COLUMNS}
      dataExported={dataExported}
    />
  )
}

DocumentsHeader.propTypes = {
  columnsDisplayed: PropTypes.array,
  onChangeColumnsDisplayed: PropTypes.func,
}

export default DocumentsHeader
