import type { INullOrUndefined } from '~/types/models/ICommonModel'

const buildFullAddress = (address: any | INullOrUndefined) => {
  if (address) {
    return [
      address.street,
      address.address_2,
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.postalCode,
      address.zip,
      address.country,
    ]
      .filter(text => text)
      .join(', ')
  }
  return null
}

export default buildFullAddress
