import { RefObject } from 'react'
import {
  IReusableTableRowData,
  ReusableTableInstance,
  RTColumn,
} from '../../../types'
import './styles.scss'
import { GroupIcon } from '~/components/shared'

export interface IRTTableHeadCellGroupHandleProps<
  TData extends IReusableTableRowData,
> {
  column: RTColumn<TData>
  table: ReusableTableInstance<TData>
  tableHeadCellRef: RefObject<HTMLTableCellElement>
}

const RTTableHeadCellGroupHandle = <TData extends IReusableTableRowData>(
  props: IRTTableHeadCellGroupHandleProps<TData>,
) => {
  const { column } = props
  const isGroupped = column.getIsGrouped()

  const onToggleGroup = () => {
    column.toggleGrouping()
  }

  return (
    <div
      className='RTTableHeadCellGroupHandle__container'
      onClick={onToggleGroup}
      style={{ opacity: isGroupped ? 1 : '0.3' }}
    >
      <GroupIcon className='RTTableHeadCellGroupHandle__icon' size={16} />
    </div>
  )
}

export default RTTableHeadCellGroupHandle
