import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { ICheckEnrollmentProfile } from '~/types/models/ICheck'

const useModifyEnrollmentProfile = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const key = useMemo(
    () => ['checkEnrollmentProfile', sessionUser?.id],
    [sessionUser?.id],
  )

  const renewEnrollmentProfile = useCallback(
    (newData: ICheckEnrollmentProfile) => {
      queryClient.setQueryData<ICheckEnrollmentProfile | undefined>(key, () => {
        return newData
      })
    },
    [key, queryClient],
  )

  return { renewEnrollmentProfile }
}

export default useModifyEnrollmentProfile
