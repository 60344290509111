import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IonItem, IonLabel, IonButton, IonHeader } from '@ionic/react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DateTime } from 'luxon'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { Alert, Form } from 'react-bootstrap'
import { When } from 'react-if'

import LoadFormScheduleSelect from './LoadFormScheduleSelect'
import LoadPrint from './LoadPrint'
import {
  CloseIcon,
  CommonDialogV2,
  DropdownWithCustomChildren,
  Loading,
  ReusableTable,
  ToolTipOverlay,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import {
  selectDriverFleetsWithDriverNameOptions,
  selectTruckFleetsWithTruckNameOptions,
  selectAvailableTruckFleetOptions,
  selectAssignedTruckFleetOptions,
  selectDriverFleets,
  selectOrderTrackingFiltersLite,
} from '~/redux/selectors'
import { parseLoadSize } from './helpers'
import { onUpdate } from '../../redux/actions/loadsActions'
import { updateDriverFleet as updateDriverFleetInStore } from '~/redux/actions/driverFleetsActions'
import { fetchOrderExtras } from '~/redux/reducers/data/orderExtras'
import { fetchOrderExtraRanges } from '~/redux/reducers/data/orderExtraRanges'
import {
  selectOrderExtraRangesList,
  selectOrderExtras,
} from '~/redux/selectors'
import LoadFormOrderExtrasCard from './LoadFormOrderExtrasCard'
import { apiClient } from '~/api/ApiClient'
import { useNextLoadsTable } from './useNextLoadsTable'
import { EStatus, EYesNo } from '~/types/enums/ECommonEnum'
import { EDriverWorkingStatus } from '~/types/enums/EDriver'
import { driverFleetsSlice } from '~/redux/reducers/data/driverFleets'
import {
  useQuerySellerProducts,
  useQueryLoad,
  useQueryUoms,
  useQuerySchedules,
  useQueryTerminals,
  useQueryCompanyAddresses,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { FloatingSelectV2 } from '../shared/FloatingForm/FloatingSelectV2'
import { FloatingInputV2 } from '../shared/FloatingForm/FloatingInputV2'

import './LoadForm.scss'
import { useConfirmationProvider } from '~/contexts'
import { useLocalStorage } from 'react-use'

// import moment from 'moment'
// import DriverTruckTimeField from '../load/AssignLoads/UpdateStatusBtn/DriverTruckTimeField'
// import { ControlledMenu } from '@szhsin/react-menu'
// import DriverTruckTimeField from '../load/AssignLoads/UpdateStatusBtn/DriverTruckTimeField'

const schema = yup.object({
  truckFleetId: yup.number().required('Truck required!'),
  driverFleetId: yup.number().required('Driver required!'),
  qty: yup
    .number()
    .required('Quantity required!')
    .positive('Quantity must be greater than 0'),
  sellerTerminalId: yup.number().required('Seller terminal required!'),
  scheduleId: yup.number().required('Schedule required!'),
  updateLaterScheduleLoads: yup.boolean(),
})

const LOAD = 'load'

const LoadForm = props => {
  const {
    setShow,
    driverFleet,
    defaultSchedule,
    isHiddenHeader,
    onChangeTruckFleetId,
    // onTogglePrintLoad,
  } = props

  useQueryCompanyAddresses() // start to fetch data to avoid slow performance issue on the ticket modal

  const [isLoading, setIsLoading] = useState(false)
  const [showPrintLoad, setShowPrintLoad] = useState(false)
  const [createdLoad, setCreatedLoad] = useState(null)
  const [isOpenLoadPrint, setIsOpenLoadPrint] = useState(false)
  const [extras, setExtras] = useState([])
  const [autoExtras, setAutoExtras] = useState([])
  const [backendError, setBackendError] = useState('')
  const [scheduleId, setScheduleId] = useState(defaultSchedule?.id)
  const [isUpdatingScheduleLoads, setIsUpdatingScheduleLoads] = useState(false)

  const { user } = useSelector(state => state.session)
  const driversOptions = useSelector(selectDriverFleetsWithDriverNameOptions)
  const trucksOptions = useSelector(selectTruckFleetsWithTruckNameOptions)
  const orderExtras = useSelector(selectOrderExtras)
  const orderExtraRanges = useSelector(selectOrderExtraRangesList)
  const driverFleetObjects = useSelector(selectDriverFleets)

  const filtersLite = useSelector(selectOrderTrackingFiltersLite)

  const { confirmation } = useConfirmationProvider()

  const [
    hideConfirmationWhenUpdateSchedule,
    setHideConfirmationWhenUpdateSchedule,
  ] = useLocalStorage('concord.showConfirmationWhenUpdateSchedule', false)

  const {
    schedulesData,
    updateSchedule,
    findScheduleById,
    addLoad,
    isLoadingLoadsData,
    refetchSchedulesData,
  } = useQuerySchedules({
    filters: _.omit(filtersLite, 'view'),
    hasLoads: true,
    hasNotes: true,
  })

  const schedule = findScheduleById(scheduleId) || defaultSchedule
  const { findTerminalById, terminalsData } = useQueryTerminals()
  const { updateDriverFleet } = useQueryUsers()

  const availableTruckFleetOptions = useSelector(
    selectAvailableTruckFleetOptions,
  )
  const assignedTruckFleetOptions = useSelector(selectAssignedTruckFleetOptions)

  const spIds = useMemo(
    () =>
      _.uniq(
        extras
          .map(({ sellerProductId }) => sellerProductId)
          .concat(autoExtras.map(({ sellerProductId }) => sellerProductId)),
      ),
    [extras, autoExtras],
  )

  const { sellerProductsObj: sellerProducts } = useQuerySellerProducts(
    { filters: { id: spIds } },
    { enabled: Boolean(spIds.length) },
  )

  const uomIds = useMemo(
    () =>
      _.uniq(
        Object.values(sellerProducts)
          .map(({ uomId }) => uomId)
          .filter(i => !!i),
      ),
    [sellerProducts],
  )

  const { uomsObj } = useQueryUoms(
    { filters: { id: uomIds } },
    { enabled: Boolean(uomIds.length) },
  )

  const truckOptions = useMemo(
    () => [
      {
        label: 'Available trucks',
        options: availableTruckFleetOptions,
      },
      {
        label: 'Assigned trucks',
        options: assignedTruckFleetOptions,
      },
    ],
    [assignedTruckFleetOptions, availableTruckFleetOptions],
  )

  const dispatch = useDispatch()

  const ordersSellerTerminals = useMemo(
    () =>
      terminalsData
        .filter(terminal => terminal.companyId === schedule?.order.sellerId)
        .map(terminal => ({ value: terminal.id, label: terminal.name })),
    [terminalsData, schedule],
  )

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      truckFleetId: driverFleet?.currentTruckFleetId,
      driverFleetId: driverFleet?.id,
      qty: parseLoadSize(schedule?.loadSize) || null,
      sellerTerminalId:
        ordersSellerTerminals?.length === 1 && ordersSellerTerminals[0]?.value,
    },
    resolver: yupResolver(schema),
  })

  const driverFleetId = useWatch({ control, name: 'driverFleetId' })
  const qty = useWatch({ control, name: 'qty' })
  const truckFleetId = useWatch({ control, name: 'truckFleetId' })
  const sellerTerminalId = useWatch({ control, name: 'sellerTerminalId' })
  const loadId = useWatch({ control, name: 'id' })

  const driverFleetSelected = driverFleetObjects[driverFleetId]

  const { loadData } = useQueryLoad(driverFleetSelected?.loadId, {
    enabled: Boolean(
      driverFleetSelected?.loadId &&
        driverFleetSelected?.loadStatus === 'Delivery Complete',
    ),
  })

  useEffect(() => {
    if (orderExtraRanges && schedule?.scheduleNumber) {
      const oeids = Object.values(orderExtras)
        .filter(({ orderId }) => orderId === schedule.orderId)
        .map(({ id }) => id)

      const rangesWithinOrder = orderExtraRanges.filter(({ orderExtraId }) =>
        oeids.includes(orderExtraId),
      )

      const withinRange = rangesWithinOrder.filter(({ startLoad, endLoad }) => {
        if (startLoad && schedule.scheduleNumber < startLoad) return false
        if (endLoad && schedule.scheduleNumber > endLoad) return false
        return true
      })

      const withChecked = withinRange.map(oe => ({
        ...orderExtras[oe.orderExtraId],
        ...oe,
        checked: true,
      }))

      setExtras(withChecked)
    }
  }, [orderExtraRanges, orderExtras, schedule])

  const onUpdateDriverFleet = useCallback(
    (field, value) => {
      dispatch(
        driverFleetsSlice.actions.update({
          id: driverFleetSelected?.id,
          [field]: value,
        }),
      )
      apiClient.driverFleets.update(driverFleetSelected?.id, {
        [field]: value,
      })
    },
    [dispatch, driverFleetSelected?.id],
  )

  const onOpenTimeOverlay = useCallback(() => {
    // const bounding = event.target.getBoundingClientRect()
    // const xPosition = bounding.x || event.pageX
    // const yPosition = bounding.bottom ? bounding.bottom + 8 : event.pageY + 10
    // setAnchorPoint({ x: xPosition, y: yPosition })
    // setIsOpen(prev => !prev)
    // setTimeValue(moment().format('HH:mm'))
    onUpdateDriverFleet('working', EDriverWorkingStatus.working)
  }, [onUpdateDriverFleet])

  // const onChangeTimeField = useCallback(
  //   (event, inputtedTime) => {
  //     setIsOpen(false)

  //     const [hours, minutes] = inputtedTime.split(':')
  //     const dateTime = moment()
  //       .set({
  //         hours,
  //         minutes,
  //       })
  //       .toISOString()
  //   },
  //   [driverFleet],
  // )

  const onChangeTruckFleet = useCallback(
    async (event, { value }) => {
      onUpdateDriverFleet('currentTruckFleetId', value)
      setValue('truckFleetId', value)
      toast.success('Update default truck successfully!')
    },
    [onUpdateDriverFleet, setValue],
  )

  const errorsList = useMemo(
    () => [
      {
        message: "Driver fleet's status should be active",
        isHidden: driverFleetSelected?.status === EStatus.Active.toLowerCase(),
        extraComponent: (
          <span
            className='LoadForm__errorItem'
            onClick={() => {
              onUpdateDriverFleet('status', 'active')
            }}
          >
            Change to Active
          </span>
        ),
      },
      {
        message: `Driver is ${driverFleetSelected?.working}`,
        isHidden: driverFleetSelected?.working === EDriverWorkingStatus.working,
        extraComponent: (
          <span className='LoadForm__errorItem' onClick={onOpenTimeOverlay}>
            Update status to Working
          </span>
        ),
      },
      {
        message: 'Driver does not have a truck assigned',
        isHidden: Boolean(
          driverFleetSelected?.currentTruckFleetId || truckFleetId,
        ),
        extraComponent: (
          <DropdownWithCustomChildren
            options={truckOptions}
            placeholder=''
            noHover
            className='LoadForm__customDropdown'
            onChange={onChangeTruckFleet}
          >
            <span className='LoadForm__errorItem'>Set truck</span>
          </DropdownWithCustomChildren>
        ),
      },
      {
        message: 'Driver is on a current load',
        isHidden: !(
          driverFleetSelected?.loadId &&
          driverFleetSelected?.loadStatus === 'Delivery Complete'
        ),
        extraComponent: (
          <span
            className='LoadForm__errorItem'
            onClick={() => {
              setIsOpenLoadPrint(true)
            }}
          >
            #{driverFleetSelected?.loadId}
          </span>
        ),
      },
    ],
    [
      driverFleetSelected,
      onChangeTruckFleet,
      onOpenTimeOverlay,
      onUpdateDriverFleet,
      truckFleetId,
      truckOptions,
    ],
  )

  const actualErrorsList = useMemo(
    () => errorsList.filter(({ isHidden }) => !isHidden),
    [errorsList],
  )

  const submitLoad = async data => {
    setIsLoading(true)
    setBackendError('')
    const loadExtrasAttributes = extras
      .filter(({ checked }) => checked)
      .map(orderExtraRange => ({
        orderExtraQty: orderExtraRange.qty,
        orderExtraId: orderExtraRange.orderExtraId,
        sellerProductId:
          orderExtras[orderExtraRange.orderExtraId].sellerProductId,
      }))
      .concat(
        autoExtras
          .filter(({ checked }) => checked)
          .map(autoExtra => ({
            orderExtraQty: autoExtra.qty,
            autoExtraId: autoExtra.id,
            sellerProductId: autoExtra.sellerProductId,
          })),
      )

    try {
      const response = await apiClient.loads.createFleetLoad({
        load: {
          fleetId: user?.company?.id,
          companyId: user?.company?.id,
          buyerId: schedule?.order?.buyerId,
          sellerId: schedule?.order?.sellerId,
          buyerTerminalId: schedule?.order?.buyerTerminalId,
          buyerSellerProductId:
            schedule?.buyerSellerProductId ||
            schedule?.order?.buyerSellerProductId,
          ...data,
          loadExtrasAttributes,
          scheduleLoadId: nextLoad.id,
        },
      })
      if (response.errors.length === 0) {
        dispatch(onUpdate(response.load))
        dispatch(
          updateDriverFleetInStore({
            id: driverFleetId,
            loadId: response.load.id,
            currentTruckFleetId: truckFleetId,
          }),
        )
        updateDriverFleet(driverFleetId, {
          loadId: response.load.id,
          currentTruckFleetId: truckFleetId,
        })
        updateSchedule(schedule.id, { qtyCounter: response.load.orderQty })
        addLoad(response.load)
        setShowPrintLoad(true)
        setCreatedLoad(response.load)
        const newSchedule = await apiClient.schedules.getById(schedule.id)
        updateSchedule(newSchedule.id, newSchedule)
      } else {
        setBackendError(
          `${toastMessages.createError} - ${response.errors.join('')}`,
        )
      }
    } catch (e) {
      console.log('e', e)
      toast.error(`${toastMessages.createError} - ${e.name}`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetSchedule = useCallback(
    schedule => {
      setScheduleId(schedule.id)
      setValue('scheduleId', schedule.id)
      if (schedule.nextLoads.length < 2) {
        setValue('loadId', schedule.nextLoads[0]?.id)
      }
    },
    [setValue],
  )

  const expectedQtyText = useMemo(() => {
    if (!schedule?.qtyCounter && !schedule?.qty) return ''

    const expected =
      parseFloat(schedule?.qtyCounter || 0) + parseFloat(qty || 0)
    return `${expected} / ${parseFloat(schedule?.qty)}`
  }, [qty, schedule])

  const changeExtras = useCallback(
    index => newValue => {
      const updatedLoadExtraRanges = [...extras]
      updatedLoadExtraRanges[index] = {
        ...updatedLoadExtraRanges[index],
        ...newValue,
      }
      setExtras(updatedLoadExtraRanges)
    },
    [extras],
  )

  const nextLoadOptions = useMemo(() => {
    if (!schedule?.nextLoads) return []
    return schedule?.nextLoads.map((ld, index) => {
      const time = DateTime.fromISO(ld.ticketTime).toFormat('H:mm')
      const beginSellerTerminal = findTerminalById(ld.beginSellerTerminalId)
      const label = [
        `#${index + 1}`,
        time,
        `QTY: ${Number(ld.qty || '0')}`,
        beginSellerTerminal?.code,
      ]
        .filter(Boolean)
        .join(' | ')
      return {
        label: label,
        value: ld.id,
        ...ld,
      }
    })
  }, [findTerminalById, schedule?.nextLoads])

  const projectedMinutesShift = useMemo(() => {
    if (!schedule?.nextLoads[0]) return 0
    const now = DateTime.now()
    const nextLoad = schedule.nextLoads[0]
    const time = DateTime.fromISO(nextLoad.ticketTime)
    const diffMins = time.diff(now, 'minutes').toObject().minutes.toFixed(0)
    return diffMins
  }, [schedule?.nextLoads])

  const { columns, COLUMN_NAMES } = useNextLoadsTable({
    projectedMinutesShift,
    sellerTerminalId,
    qty,
    schedulesData,
  })

  const nextLoad = useMemo(
    () => schedule?.nextLoads?.find(({ id }) => id === loadId),
    [loadId, schedule?.nextLoads],
  )

  const projectedNextLoad = useMemo(() => {
    let load = nextLoad

    if (!nextLoad) return null

    COLUMN_NAMES.forEach(name => {
      const time = DateTime.fromISO(nextLoad?.[name])

      load = {
        ...load,
        [name]: time.plus({ minutes: projectedMinutesShift }).toISO(),
      }
    })

    return load
  }, [COLUMN_NAMES, projectedMinutesShift, nextLoad])

  useEffect(() => {
    if (loadId) {
      //when we select a load from schedule, fill these fields
      setValue('sellerTerminalId', nextLoad?.beginSellerTerminalId)
      setValue('qty', nextLoad?.qty)
      setValue('returnSellerTerminalId', nextLoad?.returnSellerTerminalId)
    }
  }, [loadId, nextLoad, setValue])

  useEffect(() => {
    schedule && dispatch(fetchOrderExtras(schedule?.orderId))
  }, [dispatch, schedule])

  useEffect(() => {
    if (orderExtras) {
      const ids = Object.values(orderExtras).map(({ id }) => id)
      if (ids.length > 0)
        dispatch(fetchOrderExtraRanges({ filters: { orderExtraId: ids } }))
    }
  }, [dispatch, orderExtras])

  useEffect(() => {
    if (nextLoadOptions?.length === 1) {
      setValue('id', nextLoadOptions[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLoadOptions])

  useEffect(() => {
    defaultSchedule && handleSetSchedule(defaultSchedule)
  }, [defaultSchedule, handleSetSchedule])

  useEffect(() => {
    if (driverFleet) {
      setValue('driverFleetId', driverFleet.id)
      setValue('truckFleetId', driverFleet.currentTruckFleetId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverFleet])

  useEffect(() => {
    onChangeTruckFleetId && onChangeTruckFleetId(truckFleetId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckFleetId])

  // useEffect(() => {
  //   createdLoad
  //     ? onTogglePrintLoad(`Load # ${createdLoad?.id}`)
  //     : onTogglePrintLoad('Create Load')
  // }, [createdLoad, onTogglePrintLoad])

  const changeAutoExtras = useCallback(
    index => newValue => {
      const newAutoExtras = [...autoExtras]
      newAutoExtras[index] = {
        ...newAutoExtras[index],
        ...newValue,
      }
      setAutoExtras(newAutoExtras)
    },
    [autoExtras],
  )

  const updateScheduleLoad = async (id, payload) => {
    await apiClient.scheduleLoads.update(id, {
      scheduleLoad: payload,
    })
    refetchSchedulesData()
  }

  const onUpdateScheduleLoad = async (id, field, payload) => {
    setIsUpdatingScheduleLoads(true)
    try {
      if (!hideConfirmationWhenUpdateSchedule) {
        const result = await confirmation({
          message: `Do you want to update schedule load ${field}`,
          showCheckbox: true,
          checkboxLabel: "Don't show again",
          onChangeCheckbox(event, checked) {
            setHideConfirmationWhenUpdateSchedule(checked)
          },
        })
        if (result === EYesNo.Yes) {
          await updateScheduleLoad(id, payload)
        }
      } else {
        await updateScheduleLoad(id, payload)
      }
    } catch (error) {
      toast.error(toastMessages.updateError)
    } finally {
      setIsUpdatingScheduleLoads(false)
    }
  }

  const onEditScheduleLoads = async (value, cell) => {
    setIsUpdatingScheduleLoads(true)
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      updateScheduleLoad(rowId, {
        [columnField]: value,
      })
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsUpdatingScheduleLoads(false)
    }
  }

  const fetchAutoExtras = useCallback(async () => {
    const params = {
      filters: {
        applicationType: LOAD,
        // sellerId,
        // buyerId,
        // startTime,
        // sellerTerminalId,
        // buyerTerminalId,
        // sellerProductId,
      },
    }
    const { autoExtras, errors } = await apiClient.autoExtras.get(params)

    if (_.isEmpty(autoExtras)) return
    if (!_.isEmpty(errors)) toast.error(errors.join('\n'))

    const withChecked = autoExtras.map(ae => ({ ...ae, checked: true }))
    setAutoExtras(withChecked)
  }, [])

  useEffect(() => {
    fetchAutoExtras()
  }, [fetchAutoExtras])

  return (
    <>
      {!isHiddenHeader && (
        <>
          {isLoading && <Loading />}
          <IonHeader>
            <IonItem
              expand='block'
              color='transparent'
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              lines='none'
            >
              <IonLabel>
                {showPrintLoad ? `Load # ${createdLoad?.num}` : 'New Load'}
              </IonLabel>
              <button
                style={{ background: 'none' }}
                onClick={() => setShow(false)}
              >
                <CloseIcon />
              </button>
            </IonItem>
          </IonHeader>
        </>
      )}
      {showPrintLoad && <LoadPrint load={createdLoad} />}
      {!showPrintLoad && (
        <>
          <form
            onSubmit={handleSubmit(submitLoad)}
            className='fs-5 mx-2 LoadForm__container'
          >
            {backendError && (
              <Alert variant='danger' style={{ fontSize: 13 }}>
                {backendError}
              </Alert>
            )}

            <When
              condition={
                actualErrorsList.length > 0 && Boolean(driverFleetSelected)
              }
            >
              <Alert variant='danger' className='alertContainer'>
                <ul>
                  {actualErrorsList.map(({ message, extraComponent }) => (
                    <li key={message} style={{ maxHeight: 22 }}>
                      <span style={{ verticalAlign: 'middle' }}>{message}</span>
                      &nbsp;&nbsp;&nbsp;{extraComponent}
                    </li>
                  ))}
                </ul>
              </Alert>
            </When>

            <LoadFormScheduleSelect
              schedules={schedulesData}
              handleSetSchedule={handleSetSchedule}
              selectedSchedule={schedule}
              sellerProducts={sellerProducts}
            />

            <div className='d-flex gap-2 my-2 justify-content-between'>
              <div className='d-flex flex-row align-items-center'>
                <FloatingSelectV2
                  name='id'
                  label='Schedule LD'
                  options={nextLoadOptions}
                  control={control}
                />

                <div
                  className='d-flex flex-row align-items-center gap-1'
                  style={{ marginLeft: 8 }}
                >
                  <FloatingInputV2
                    name='qty'
                    label='QTY'
                    type='number'
                    value={qty}
                    errors={errors}
                    onChange={({ value, field }) => {
                      setValue(field, value)
                    }}
                    onBlur={event => {
                      onUpdateScheduleLoad(nextLoad?.id, 'Qty', {
                        qty: event.target.value,
                      })
                    }}
                  />

                  <IonLabel>
                    <b>{expectedQtyText} </b>
                  </IonLabel>
                </div>
              </div>
              <Controller
                name='updateLaterScheduleLoads'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.Check
                    key={value}
                    type='Checkbox'
                    label='Update later schedule loads'
                    value={value}
                    onChange={e => onChange(e.target.checked)}
                    style={{
                      borderColor: errors?.updateLaterScheduleLoads && 'red',
                      width: 140,
                    }}
                    className='fs-3'
                  />
                )}
              />
            </div>

            <div className='d-flex flex-wrap gap-2 my-2'>
              <div className='d-flex flex-row align-items-center'>
                <ToolTipOverlay
                  placement='right'
                  content={
                    sellerTerminalId !== nextLoad?.beginSellerTerminalId
                      ? 'Seller terminal does not match scheduled load'
                      : ''
                  }
                >
                  <div>
                    <FloatingSelectV2
                      name='sellerTerminalId'
                      label={'Seller Terminal'}
                      placeholder='Select Seller Terminal'
                      options={ordersSellerTerminals}
                      control={control}
                      isWarning={
                        sellerTerminalId &&
                        sellerTerminalId !== nextLoad?.beginSellerTerminalId
                      }
                      onChange={value => {
                        onUpdateScheduleLoad(
                          nextLoad?.id,
                          'Begin Seller Terminal',
                          {
                            beginSellerTerminalId: value,
                          },
                        )
                      }}
                    />
                  </div>
                </ToolTipOverlay>
              </div>

              <FloatingSelectV2
                name='returnSellerTerminalId'
                label={'Return Seller Terminal'}
                placeholder='Select Return Seller Terminal'
                options={ordersSellerTerminals}
                control={control}
                onChange={value => {
                  onUpdateScheduleLoad(nextLoad?.id, 'Return Seller Terminal', {
                    returnSellerTerminalId: value,
                  })
                }}
              />

              <FloatingSelectV2
                name='truckFleetId'
                label='Truck'
                placeholder='Select Truck'
                options={trucksOptions}
                control={control}
              />

              <FloatingSelectV2
                label='Driver'
                name='driverFleetId'
                placeholder='Select Driver'
                options={driversOptions}
                control={control}
              />
            </div>
            <table className='table'>
              <thead>
                <tr>
                  <th>Check</th>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Extra Source</th>
                  <th>Start Load</th>
                  <th>End Load</th>
                </tr>
              </thead>
              <tbody>
                {schedule?.id &&
                  extras &&
                  extras.map((extra, index) => {
                    const sp =
                      sellerProducts[
                        orderExtras[extra?.orderExtraId]?.sellerProductId
                      ]

                    return (
                      <LoadFormOrderExtrasCard
                        sellerProduct={sp}
                        orderExtraRange={extra}
                        changeOrderExtra={changeExtras(index)}
                        spUoms={uomsObj}
                        key={extra.id}
                      />
                    )
                  })}

                {schedule?.id &&
                  autoExtras &&
                  autoExtras.map((ae, index) => (
                    <LoadFormOrderExtrasCard
                      sellerProduct={sellerProducts[ae.sellerProductId]}
                      orderExtraRange={ae}
                      changeOrderExtra={changeAutoExtras(index)}
                      spUoms={uomsObj}
                      key={ae.id}
                    />
                  ))}
              </tbody>
            </table>

            {projectedNextLoad && (
              <ReusableTable
                columns={columns}
                data={[nextLoad, projectedNextLoad]}
                tableHeight={150}
                onCellEditEnd={onEditScheduleLoads}
                state={{
                  showProgressBars:
                    isLoadingLoadsData || isUpdatingScheduleLoads,
                }}
              />
            )}
          </form>

          <IonButton
            expand='block'
            color={qty && schedule ? 'primary' : 'medium'}
            disabled={
              !(qty && schedule && sellerTerminalId) ||
              actualErrorsList.length > 0 ||
              isLoading
            }
            onClick={handleSubmit(submitLoad)}
          >
            Create Load
          </IonButton>
        </>
      )}

      <CommonDialogV2
        isOpen={isOpenLoadPrint}
        onClose={() => {
          setIsOpenLoadPrint(false)
        }}
        isHiddenOkButton
        size='lg'
        isHeaderAddedPaddingOnMobile
      >
        <LoadPrint load={loadData} />
      </CommonDialogV2>

      {/* <ControlledMenu
        anchorPoint={anchorPoint}
        state={isOpen ? 'open' : 'closed'}
        direction='right'
        onClose={() => {
          setIsOpen(false)
        }}
        className='UpdateStatusBtn__timeOverlayRoot'
      >
        <DriverTruckTimeField
          value={timeValue}
          onChange={onChangeTimeField}
          onClose={() => {
            setIsOpen(false)
          }}
        />
      </ControlledMenu> */}
    </>
  )
}

export default LoadForm
