import { useCallback, useMemo, useState } from 'react'

import { DialogWorkerForm } from '~/components/shared'
import WorkerFormContext from './WorkerFormContext'

import type {
  IWorkerFormDefaultValues,
  IWorkerFormProviderProps,
  IWorkerFormProviderValues,
} from './type'

function WorkerFormProvider(props: IWorkerFormProviderProps) {
  const { children } = props

  const [isOpenWorkerForm, setIsOpenWorkerForm] = useState(false)
  const [workerFormData, setWorkerFormData] =
    useState<IWorkerFormDefaultValues | null>(null)

  const onOpenWorkerFormData = useCallback(
    (formDefaultValues: IWorkerFormDefaultValues) => {
      setWorkerFormData(formDefaultValues)
      setIsOpenWorkerForm(true)
    },
    [],
  )

  const onCloseWorkerFormData = useCallback(() => {
    setWorkerFormData(null)
    setIsOpenWorkerForm(false)
  }, [])

  const providerValues = useMemo<IWorkerFormProviderValues>(
    () => ({
      onOpenWorkerFormData,
      onCloseWorkerFormData,
    }),
    [onCloseWorkerFormData, onOpenWorkerFormData],
  )

  return (
    <>
      <WorkerFormContext.Provider value={providerValues}>
        {children}
      </WorkerFormContext.Provider>
      <DialogWorkerForm
        isOpen={isOpenWorkerForm}
        onClose={onCloseWorkerFormData}
        afterCreateWorker={onCloseWorkerFormData}
        defaultValues={workerFormData}
        afterUpdateWorker={onCloseWorkerFormData}
      />
    </>
  )
}
export default WorkerFormProvider
