import _ from 'lodash'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import { useQueryInitialCompanyData } from '~/hooks/useQueryData'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import {
  BILL_LINE_MATCH_OPTIONS,
  BILL_LINE_SORTING_FIELD_OPTIONS,
  COMPANY_INVOICE_INTERVAL_OPTIONS,
  DATE_FORMAT_OPTIONS,
  DEFAULT_DATE_FORMAT,
  EXCLUDED_BILL_LINE_FIELDS_OPTIONS,
  TIME_FORMAT_OPTIONS,
} from '~/utils/constants'

import './styles.scss'

interface IProps {
  formData?: ICompany
  afterUpdate?: (company: ICompany) => void
  onSubmit?: (company: ICompany) => void
}

function CompanySettingAutomationForm(props: IProps) {
  const { formData, afterUpdate, ...rest } = props

  const [isLoading, setIsLoading] = useState(false)

  const {
    requiredActionsOptions,
    autoLoadCreateOptions,
    isLoadingInitialCompanyData,
  } = useQueryInitialCompanyData()

  const fields: IConcordFormField[] = [
    {
      name: 'invoiceInterval',
      label: 'Invoice Interval',
      type: EFieldType.singleSelect,
      options: COMPANY_INVOICE_INTERVAL_OPTIONS,
      size: 6,
    },
    {
      name: 'removePageRanges',
      label: 'Remove Page Ranges',
      placeholder: 'eg: "[1,3], [4.7], [8,10]"',
      size: 6,
    },
    {
      name: 'regexToSkipBillLine',
      label: 'Regex To Skip Bill Line',
      size: 6,
    },
    {
      name: 'blDateFormat',
      label: 'Bill Line Date Format',
      size: 6,
    },
    {
      name: 'calcDifferenceThreshhold',
      label: 'Difference Threshold',
      type: EFieldType.number,
      size: 6,
    },
    {
      name: 'invoiceHeaderOffset',
      label: 'Invoice Header offset',
      type: EFieldType.number,
      size: 6,
    },
    {
      name: 'billLineSortingField',
      label: 'Bill Line Sorting Field',
      size: 6,
      type: EFieldType.singleSelect,
      options: BILL_LINE_SORTING_FIELD_OPTIONS,
    },
    {
      name: 'billLineMatchingFields',
      label: 'Bill Line Matching Field',
      type: EFieldType.multipleSelect,
      options: BILL_LINE_MATCH_OPTIONS,
      size: 6,
    },
    {
      name: 'excludedBillLineFields',
      label: 'Excluded Bill Line Fields',
      type: EFieldType.multipleSelect,
      options: EXCLUDED_BILL_LINE_FIELDS_OPTIONS,
      size: 6,
    },
    {
      name: 'loadSizeArray',
      label: 'Load Size Array',
      type: EFieldType.tags,
      size: 6,
      className: 'CompanySettingAutomationForm__loadSizeOptions',
    },
    {
      name: 'invoiceDateFormat',
      label: 'Invoice Date Format',
      size: 4,
    },
    {
      name: 'dateFormat',
      label: 'Date Format',
      size: 4,
      type: EFieldType.singleSelect,
      options: DATE_FORMAT_OPTIONS,
    },
    {
      name: 'timeFormat',
      label: 'Time Format',
      size: 4,
      type: EFieldType.singleSelect,
      options: TIME_FORMAT_OPTIONS,
    },
    {
      name: 'invoiceJobApproved',
      label: 'HITL Invoice Job Approved',
      isDisabled: true,
      type: EFieldType.radio,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      name: 'ticketJobApproved',
      label: 'HITL Ticket Job Approved',
      type: EFieldType.radio,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      name: 'autoCreateLoad',
      label: 'Create Loads on Document Upload',
      type: EFieldType.radio,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      name: 'checkForSplit',
      label: 'Check For Split',
      type: EFieldType.radio,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      name: 'useFuzzyMatching',
      label: 'Use Fuzzy Matching',
      type: EFieldType.checkbox,
    },
    {
      name: 'createRelationsOnTheFly',
      label: 'Create Relations On the Fly',
      type: EFieldType.checkbox,
    },
    {
      name: 'requiredTicketActions',
      label: 'Required Ticket Actions',
      options: requiredActionsOptions,
      type: EFieldType.multipleSelect,
      isLoading: isLoadingInitialCompanyData,
    },
    {
      name: 'autoCreateLoadMatching',
      label: 'Auto Create Load Matching',
      options: autoLoadCreateOptions,
      type: EFieldType.multipleSelect,
      isLoading: isLoadingInitialCompanyData,
    },
  ]

  const onSubmit = async (formValues: ICompany) => {
    const payload = _.pick(formValues, [
      'invoiceInterval',
      'removePageRanges',
      'regexToSkipBillLine',
      'blDateFormat',
      'invoiceDateFormat',
      'dateFormat',
      'timeFormat',
      'calcDifferenceThreshhold',
      'invoiceHeaderOffset',
      'billLineSortingField',
      'billLineMatchingFields',
      'excludedBillLineFields',
      'invoiceJobApproved',
      'ticketJobApproved',
      'autoCreateLoad',
      'checkForSplit',
      'useFuzzyMatching',
      'createRelationsOnTheFly',
      'requiredTicketActions',
      'autoCreateLoadMatching',
      'loadSizeArray',
    ])
    if (formData?.id) {
      setIsLoading(true)
      try {
        const { company } = await apiClient.companies.update(formData.id, {
          company: payload as any,
        })
        afterUpdate && afterUpdate(company)
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.error('Company is not found!')
    }
  }

  return (
    <ConcordFormStructure
      fields={fields}
      formData={{
        ...formData,
        dateFormat: formData?.dateFormat || DEFAULT_DATE_FORMAT,
        timeFormat: formData?.timeFormat || 'H:mm',
      }}
      isHiddenCancelButton
      onSubmit={onSubmit}
      isLoading={isLoading}
      {...rest}
    />
  )
}

export default CompanySettingAutomationForm
