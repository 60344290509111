import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  openSearchTable,
  openAssociateBillLinesConfirmation,
} from '~/redux/actions/invoiceActions'
import { IonCol, IonButton, IonLabel } from '@ionic/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { IonAlert } from '@ionic/react'
import { closeAssociateBillLinesConfirmation } from '~/redux/actions/invoiceActions'
import { apiClient } from '~/api/ApiClient'

const SelectBillLineCol = ({
  parentBillLine,
  billLine,
  className,
  style,
  associateBillLine,
}) => {
  const dispatch = useDispatch()

  const onSelectBillLineClick = useCallback(() => {
    if (associateBillLine) {
      dispatch(openAssociateBillLinesConfirmation(billLine))
    } else {
      dispatch(openSearchTable(billLine))
    }
  }, [dispatch, billLine, associateBillLine])

  const { showConfirmationMessage } = useSelector(state => state.invoice)

  const associateBothBillLines = useCallback(async () => {
    // update both billlines to match each other
    apiClient.billLines.update(parentBillLine.id, { billLineId: billLine.id })
    apiClient.billLines.update(billLine.id, { billLineId: parentBillLine.id })

    closeModal()
  }, [parentBillLine, billLine, closeModal])

  const closeModal = useCallback(() => {
    dispatch(closeAssociateBillLinesConfirmation())
  }, [dispatch])

  return (
    <IonCol
      className={className}
      style={{ background: 'none', borderLeft: 'none', ...style }}
      sizeSm={'1'}
    >
      <>
        <div style={{ zIndex: '9999', display: 'flex' }}>
          <>
            <OverlayTrigger
              overlay={
                <Tooltip>{associateBillLine ? 'Use' : 'Select'}</Tooltip>
              }
            >
              <div className='ActionButton' style={{ width: 46 }}>
                <IonButton
                  style={{ height: 24 }}
                  onClick={onSelectBillLineClick}
                  color='primary'
                  expand='block'
                >
                  <IonLabel>{associateBillLine ? 'Use' : 'Select'}</IonLabel>
                </IonButton>
              </div>
            </OverlayTrigger>
          </>
          <IonAlert
            isOpen={showConfirmationMessage}
            onDidDismiss={() => closeModal()}
            header='Confirm'
            message='Are you sure you want to match these two bill lines?'
            buttons={[
              {
                text: 'Ok',
                handler: () => {
                  associateBothBillLines()
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  closeModal()
                },
              },
            ]}
          />
        </div>
      </>
    </IonCol>
  )
}

export default SelectBillLineCol
