import { createContext } from 'react'

import type { IWorkerFormProviderValues } from './type'

const WorkerFormContext = createContext<IWorkerFormProviderValues>({
  onOpenWorkerFormData() {},
  onCloseWorkerFormData() {},
})

export default WorkerFormContext
