import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { createSlice } from '@reduxjs/toolkit'

export const loadsSlice = createSlice({
  name: 'loads',
  initialState: {},
  reducers: {
    updateAll: (_state, action) => {
      const newState = {}
      action.payload.loads.forEach((load, index) => {
        newState[load.id] = { ...load, index }
      })

      return newState
    },
    updateLoads: (state, action) => {
      action.payload.loads.forEach(load => {
        if (state[load.id]) {
          state[load.id] = load
          if (load.deletedAt !== null) delete state[load.id]
        }
      })
    },
    updateLoad: (state, { payload }) => {
      if (state[payload.id]) {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
        if (!_.isNil(payload.deletedAt)) {
          delete state[payload.id]
        }
      } else {
        state[payload.id] = payload
      }
    },
    deleteLoad: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export default loadsSlice
