import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
import { ICommonOption } from '~/types/models/ICommonModel'
import { IUser } from '~/types/models/IUser'
import { MAP_PRODUCT_GROUP_ORDER_TYPE } from '~/utils/constants'
import useModifyProductGroups from './useModifyProductGroups'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryProductGroups = () => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const { data = [], isLoading } = useQuery({
    queryKey: ['productGroups', sessionUser.id],
    async queryFn() {
      const response = await apiClient.productGroups.get()
      return response.map(item => {
        return {
          ...item,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          orderType: (MAP_PRODUCT_GROUP_ORDER_TYPE as any)[item.orderType],
        }
      })
    },
    enabled: Boolean(sessionUser.id),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const productGroupOptions = useMemo<ICommonOption[]>(
    () =>
      data.map(({ code, name, id }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [data],
  )

  const findProductGroupById = useCallback(
    (id: number | null) => data.find(item => item.id === id),
    [data],
  )

  const { addProductGroup, updateProductGroup, deleteProductGroup } =
    useModifyProductGroups()

  return {
    productGroups: data,
    productGroupOptions,
    isLoadingProductGroups: isLoading,
    addProductGroup,
    updateProductGroup,
    deleteProductGroup,
    findProductGroupById,
  }
}

export default useQueryProductGroups
