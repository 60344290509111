import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { ConcordFormDropdown } from '~/components/shared'
import TerminalCustomOption from './TerminalCustomOption'
import TerminalDropdownForm from './TerminalCustomOption/TerminalDropdownForm'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

function ConcordLoadTerminalDropdown(props) {
  const {
    loadId,
    companyId,
    showDropdown: showDropdownProps,
    onCloseDropdown,
    onCreate,
    onUpdate,
    onDelete,
    onOpenDropdown,
    addableTerminal,
    editableTerminal,
    deletableTerminal,
    isLoading: isLoadingProps,
    ...dropdownProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [terminals, setTerminals] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const options = useMemo(
    () =>
      _(terminals)
        .map(({ id, code, name }) => ({
          value: id,
          code,
          name,
          label: `${code} - ${name}`,
        }))
        .orderBy('label')
        .value(),
    [terminals],
  )

  const getTerminals = useCallback(async () => {
    setIsLoading(true)
    try {
      if (companyId) {
        const response = await apiClient.terminals.get({
          filters: {
            companyId,
          },
          loadId,
        })
        setTerminals(response)
      } else {
        toast.info('Need a Selected Company to fetch terminals!')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }, [companyId, loadId])

  const handleOpenForm = useCallback(() => {
    setMenuIsOpen(false)
    setIsOpenForm(true)
  }, [])

  const handleEdit = useCallback(
    option => {
      const { code, name, value } = option
      setInitialFormValues({
        code,
        name,
        id: value,
      })
      handleOpenForm()
    },
    [handleOpenForm],
  )

  const handleDelete = useCallback(
    async option => {
      const result = await confirmation({
        message: `Are you sure you want to delete terminal #${option.value}?`,
      })

      if (result === EYesNo.Yes) {
        setIsLoading(true)
        try {
          const response = await apiClient.terminals.delete(option.value)
          if (response.id) {
            toast.success(toastMessages.deleteSuccess)
            setTerminals(prev =>
              produce(prev, draft => {
                const index = draft.findIndex(({ id }) => id === response.id)
                if (index > -1) {
                  draft.splice(index, 1)
                }
              }),
            )
            onDelete && onDelete(response)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          toast.error(toastMessages.deleteError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [confirmation, onDelete],
  )

  const handleCloseForm = useCallback(() => {
    setMenuIsOpen(true)
    setIsOpenForm(false)
    setInitialFormValues({})
  }, [])

  const handleCreateTerminal = useCallback(
    createdTerminal => {
      setTerminals(prev =>
        produce(prev, draft => {
          draft.push(createdTerminal)
        }),
      )
      onCreate && onCreate(createdTerminal)
    },
    [onCreate],
  )

  const handleUpdateTerminal = useCallback(
    updatedTerminal => {
      setTerminals(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === updatedTerminal.id)
          if (index !== -1) {
            draft[index] = updatedTerminal
          }
        }),
      )
      onUpdate && onUpdate(updatedTerminal)
    },
    [onUpdate],
  )

  const renderElementBelowDropdown = useCallback(() => {
    if (addableTerminal) {
      return (
        <TerminalDropdownForm
          isLoading={isLoading}
          isOpenForm={isOpenForm}
          onClose={handleCloseForm}
          onOpen={handleOpenForm}
          companyId={companyId}
          initialFormValues={initialFormValues}
          onCreate={handleCreateTerminal}
          onUpdate={handleUpdateTerminal}
        />
      )
    }

    return null
  }, [
    addableTerminal,
    isLoading,
    isOpenForm,
    handleCloseForm,
    handleOpenForm,
    companyId,
    initialFormValues,
    handleCreateTerminal,
    handleUpdateTerminal,
  ])

  const handleCloseDropdown = useCallback(() => {
    onCloseDropdown && onCloseDropdown()
    setIsOpenForm(false)
  }, [onCloseDropdown])

  useEffect(() => {
    if ((loadId || companyId) && showDropdown) {
      getTerminals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, loadId, showDropdown])

  useEffect(() => {
    if (_.isBoolean(showDropdownProps)) {
      setShowDropdown(showDropdownProps)
    }
  }, [showDropdownProps])

  return (
    <ConcordFormDropdown
      options={options}
      isLoading={isLoading || isLoadingProps}
      menuIsOpen={menuIsOpen}
      onEdit={handleEdit}
      onDelete={handleDelete}
      editableTerminal={editableTerminal}
      deletableTerminal={deletableTerminal}
      components={{
        Option: TerminalCustomOption,
      }}
      showDropdown={showDropdown}
      onOpenDropdown={() => {
        setShowDropdown(true)
        onOpenDropdown && onOpenDropdown()
      }}
      onCloseDropdown={handleCloseDropdown}
      renderElementBelowDropdown={renderElementBelowDropdown}
      styles={{
        menuList: provided => ({
          ...provided,
          maxHeight: 200,
        }),
      }}
      {...dropdownProps}
    />
  )
}

ConcordLoadTerminalDropdown.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.number,
  loadId: PropTypes.number,
  menuIsOpen: PropTypes.bool,
  addableTerminal: PropTypes.bool,
  editableTerminal: PropTypes.bool,
  deletableTerminal: PropTypes.bool,
}

ConcordLoadTerminalDropdown.defaultProps = {
  addableTerminal: true,
  editableTerminal: true,
  deletableTerminal: true,
}

export default React.memo(ConcordLoadTerminalDropdown)
