import terminalsSlice from '~/redux/reducers/data/terminals'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt'
import { apiClient } from '~/api/ApiClient'

export const getTerminals = () => dispatch => {
  apiClient.terminals
    .get({
      includeLocation: true,
      includeGeofence: true,
      includeAddress: true
    })
    .then(response => {
      const unix = Math.round(+new Date() / 1000)
      dispatch(terminalsSlice.actions.initializeTerminals(response))
      dispatch(
        lastFetchedAtSlice.actions.updateLastFetchedAt({
          model: 'terminals',
          lastFetchedAt: unix,
        }),
      )
    })
    .catch(err => console.log(err))
}

export const createOrUpdateLocalTerminal = payload => dispatch => {
  dispatch(terminalsSlice.actions.updateTerminal(payload))
}

export const deleteLocalTerminal = payload => dispatch => {
  dispatch(
    terminalsSlice.actions.updateTerminal({
      id: payload.id,
      deletedAt: payload.updatedAt,
    }),
  )
}

export const updateTerminal = payload => dispatch => {
  dispatch(terminalsSlice.actions.updateTerminal(payload))
}
