import { useMemo } from 'react'

import {
  CheckMarkIcon,
  CloseIcon,
  CompanyInfo,
  IRTColumnDef,
} from '~/components/shared'

import { loadTypes, documentTypes } from '~/hooks/useFilterOptions'
import { IDataParser } from '~/types/models/IDataParser'
import { EFieldType } from '~/types/enums/ECommonEnum'

const useTableCompanyParserSubTab = () => {
  const columns = useMemo<IRTColumnDef<IDataParser>[]>(
    () => [
      {
        header: 'Company',
        accessorKey: 'company.name',
        size: 300,
        Cell({ row }) {
          const { company } = row.original

          return (
            <CompanyInfo
              company={{
                name: company?.name,
                label: company?.name,
                value: company?.id,
                image: company?.logo,
                code: company?.code,
              }}
            />
          )
        },
      },
      {
        header: 'Collection',
        accessorKey: 'collection',
        enableEditing: true,
        size: 250,
      },
      {
        header: 'Doc Type',
        accessorKey: 'documentType',
        size: 140,
        filterSelectOptions: documentTypes,
        filterVariant: EFieldType.multipleSelect,
      },
      {
        header: 'Parser',
        accessorKey: 'parser',
        size: 150,
        filterSelectOptions: loadTypes,
        filterVariant: EFieldType.multipleSelect,
      },
      {
        header: 'Uid',
        accessorKey: 'uid',
        size: 200,
        enableEditing: true,
      },
      {
        header: 'Internal use only',
        accessorKey: 'internalUseOnly',
        filterSelectOptions: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        filterVariant: EFieldType.singleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<boolean>()
          return cellValue ? (
            <CheckMarkIcon color='var(--ion-color-success)' />
          ) : (
            <CloseIcon color='var(--ion-color-danger)' />
          )
        },
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyParserSubTab
