import { useMemo, useState, useEffect, useCallback } from 'react'

import { Dropdown } from 'react-bootstrap'

import clsx from 'clsx'

import './RegexDropdownField.scss'

const RegexDropdownField = props => {
  const { value, onChange, options } = props

  const [selectedValue, setSelectedValue] = useState('')
  const [toggleMenu, setToggleMenu] = useState(false)

  const valueRendered = useMemo(() => selectedValue || '', [selectedValue])

  const getDropdownItemClassNames = useCallback(
    option => {
      let selected = selectedValue === option.value

      if (selectedValue === '') {
        selected = option.value === null
      }

      return clsx({ selected })
    },
    [selectedValue],
  )

  const handleSelectRegex = useCallback(
    option => () => {
      if (option.value === null) {
        onChange && onChange('')
        setSelectedValue('')

        return
      }
      const newSelectedRegex =
        option.value === selectedValue ? '' : option.value
      onChange && onChange(newSelectedRegex)
      setSelectedValue(newSelectedRegex)
    },
    [onChange, selectedValue],
  )

  const handleToggleMenu = useCallback(nextShow => {
    setToggleMenu(nextShow)
  }, [])

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  return (
    <div className='RegexDropdownField__suffixRegex'>
      <Dropdown show={toggleMenu} onToggle={handleToggleMenu}>
        <Dropdown.Toggle className='RegexDropdownField__suffixDropdownToggle'>
          {valueRendered && (
            <div style={{ marginRight: 4 }}>
              <i>{valueRendered}</i>
            </div>
          )}
          {!valueRendered && <div style={{ opacity: 0 }}>i</div>}
        </Dropdown.Toggle>
        <Dropdown.Menu className='RegexDropdownField__suffixDropdownMenu'>
          {options.map(option => (
            <Dropdown.Item
              className={getDropdownItemClassNames(option)}
              key={option.value}
              onClick={handleSelectRegex(option)}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default RegexDropdownField
