import * as types from '../actions/actionTypes'
import {
  BILL_LINES_COLUMNS,
  SORT_OPTIONS_FOR_COMPARE_INVOICE,
  INVOICE_PANELS,
} from '~/constants/invoice-type'
import { produce } from 'immer'

export const initialState = {
  header: undefined,
  driverFleetLoadsSections: [
    {
      label: 'Load In Progress',
      field: 'workingProgress',
      hide: false,
    },
    {
      label: 'Returning',
      field: 'workingReturning',
      hide: false,
    },
    {
      label: 'Ready for Load',
      field: 'workingReady',
      hide: false,
    },
    {
      label: 'Break',
      field: 'break',
      hide: false,
    },
    {
      label: 'Off',
      field: 'off',
      hide: false,
    },
    {
      label: 'Available Trucks',
      field: 'availableTrucks',
      hide: false,
    },
    {
      label: 'Start Times',
      field: 'startTimes',
      hide: false,
    },
  ],
  billLinesColumn: {
    applyAllTables: {
      checked: false,
      value: 'Apply To Other Tables',
      label: 'Apply to other tables',
    },
    projected_invoice_columns: BILL_LINES_COLUMNS,
    actual_invoice_columns: BILL_LINES_COLUMNS,
    comparison_invoices_columns: BILL_LINES_COLUMNS,
  },
  billLinesFilter: {
    applyAllFilters: {
      checked: false,
      value: 'Apply To Other Tables',
      label: 'Apply to other tables',
    },
    ticketFilter: {
      flag: [],
      matching: [],
      buyerTerminalId: [],
      buyerSellerProductId: [],
      date: {},
      // costCodeId: [],
      qty: {},
      price: {},
      total: {},
      tax: {},
      extPrice: {},
      tagIds: [],
    },
    invoiceFilter: {
      flag: 'DEFAULT',
      matching: 'DEFAULT',
      buyerTerminalId: [],
      buyerSellerProductId: [],
      date: {},
      // costCodeId: [],
      qty: {},
      price: {},
      total: {},
      tax: {},
      extPrice: {},
      tagIds: [],
    },
  },
  billLinesSort: {
    applyAllSort: {
      checked: false,
      value: 'Apply To Other Tables',
      label: 'Apply to other tables',
    },
    ticketSortValue: SORT_OPTIONS_FOR_COMPARE_INVOICE,
    invoiceSortValue: SORT_OPTIONS_FOR_COMPARE_INVOICE,
  },
  contentScrollY: true,
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_APP_HEADER: {
      return {
        ...state,
        header: action.header,
      }
    }

    case types.UPDATE_SORT_FILTERS: {
      const { value, from } = action
      let ticketValueSort, invoiceValueSort
      const applyAllSort = {
        ...state.billLinesSort?.applyAllSort,
        checked: action.applyAllSort,
      }
      if (action.applyAllSort) {
        ticketValueSort = value
        invoiceValueSort = value
      } else {
        const { ticketSortValue, invoiceSortValue } = state.billLinesSort
        ticketValueSort =
          from === INVOICE_PANELS.TICKETS ? value : ticketSortValue
        invoiceValueSort =
          from === INVOICE_PANELS.INVOICE ? value : invoiceSortValue
      }

      return {
        ...state,
        billLinesSort: {
          ...state.billLinesSort,
          applyAllSort,
          ticketSortValue: ticketValueSort,
          invoiceSortValue: invoiceValueSort,
        },
      }
    }

    case types.UPDATE_SHOW_COLUMNS: {
      const { columns } = action
      let ticketColumns, invoiceColumns, comparisonColumns
      const applyAllTables = {
        ...state.billLinesColumn?.applyAllTables,
        checked: true,
      }

      ;[ticketColumns, invoiceColumns, comparisonColumns] =
        Array(3).fill(columns)
      return {
        ...state,
        billLinesColumn: {
          ...state.billLinesColumn,
          applyAllTables,
          ticket: ticketColumns,
          invoice: invoiceColumns,
          comparison_invoices_columns: comparisonColumns,
        },
      }
    }

    case types.RESET_SHOW_COLUMNS: {
      return {
        ...state,
        billLinesColumn: initialState.billLinesColumn,
      }
    }

    case types.UPDATE_SHOW_FILTERS: {
      const { value, from } = action
      let ticketValue, invoiceValue
      const applyAllFilters = {
        ...state.billLinesFilter?.applyAllFilters,
        checked: action.applyAllFilters,
      }
      if (action.applyAllFilters) {
        ticketValue = value
        invoiceValue = value

        return {
          ...state,
          billLinesFilter: {
            ...state.billLinesFilter,
            applyAllFilters,
            ticketFilter: {
              ...state.billLinesFilter.ticketFilter,
              flag: ticketValue.flag,
              matching: ticketValue.matching,
            },
            invoiceFilter: {
              ...state.billLinesFilter.invoiceFilter,
              flag: invoiceValue.flag,
              matching: invoiceValue.matching,
            },
          },
        }
      } else {
        const filterValue =
          from === INVOICE_PANELS.TICKETS ? 'ticketFilter' : 'invoiceFilter'

        return {
          ...state,
          billLinesFilter: {
            ...state.billLinesFilter,
            applyAllFilters,
            [filterValue]: {
              ...state.billLinesFilter[filterValue],
              flag: value.flag,
              matching: value.matching,
            },
          },
        }
      }
    }

    case types.UPDATE_FILTER_CONTAINER: {
      const data = action.data

      return produce(state, draft => {
        if (action.applyAllFilters) {
          draft.billLinesFilter.ticketFilter = data
          draft.billLinesFilter.invoiceFilter = data
        } else {
          draft.billLinesFilter[action.filterType] = data
        }
      })
    }

    case types.RESET_FILTER_CONTAINER: {
      return produce(state, draft => {
        draft.billLinesFilter.ticketFilter =
          initialState.billLinesFilter.ticketFilter
        draft.billLinesFilter.invoiceFilter =
          initialState.billLinesFilter.invoiceFilter
      })
    }

    case types.APP_SET_CONTENT_SCROLL_Y: {
      return produce(state, draft => {
        draft.contentScrollY = action.payload
      })
    }

    case types.SET_DRIVER_FLEET_LOADS_SECTIONS: {
      return produce(state, draft => {
        draft.driverFleetLoadsSections = action.payload
      })
    }

    default: {
      return state
    }
  }
}

export default appReducer
