import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBuyerSellersLastFetchedAt } from '~/redux/selectors'
import { getBuyerSellers } from '~/redux/actions/CommonData/buyerSellerActions'

export const useInitializeBuyerSellers = () => {
  const dispatch = useDispatch()
  const lastUpdatedAt = useSelector(selectBuyerSellersLastFetchedAt)

  useEffect(() => {
    if (!lastUpdatedAt) {
      dispatch(getBuyerSellers())
    }
  }, [lastUpdatedAt, dispatch])
}
