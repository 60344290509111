import type {
  RTHeader,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { useMemo } from 'react'
import { ArrowDownIcon, ArrowUpIcon, SortIcon } from '~/components/shared'

export interface RTTableHeadCellSortLabelProps<
  TData extends IReusableTableRowData,
> {
  header: RTHeader<TData>
  table: ReusableTableInstance<TData>
}

const RTTableHeadCellSortLabel = <TData extends IReusableTableRowData>(
  props: RTTableHeadCellSortLabelProps<TData>,
) => {
  const { header } = props
  const { column } = header

  const isSorted = !!column.getIsSorted()
  const direction = column.getIsSorted() as 'asc' | 'desc'

  const sortIcon = useMemo(() => {
    if (isSorted) {
      if (direction === 'asc') {
        return <ArrowUpIcon className='RTTableHeadCellSortLabel__sortIcon' />
      }
      return <ArrowDownIcon className='RTTableHeadCellSortLabel__sortIcon' />
    }

    return (
      <SortIcon
        size={16}
        className='RTTableHeadCellSortLabel__sortIcon RTTableHeadCellSortLabel__syncIcon'
      />
    )
  }, [direction, isSorted])

  return <div>{sortIcon}</div>
}

export default RTTableHeadCellSortLabel
