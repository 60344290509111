import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_TAG_COLUMNS } from '~/constants/companySubTabs'

const companyTagsSlice = createSlice({
  name: moduleName.company.tags,
  initialState: {
    columns: DEFAULT_COMPANY_TAG_COLUMNS,
    tagsLoading: false,
  },
})

export default companyTagsSlice
