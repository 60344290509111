import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import {
  IGetRetrieveWorkerReciprocityElectionsParams,
  IWorkerReciprocityElection,
} from '~/types/models/ICheck'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryRetrieveWorkerReciprocityElections = (
  params: IGetRetrieveWorkerReciprocityElectionsParams,
  options?: Partial<UseQueryOptions<IWorkerReciprocityElection[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'retrieveWorkerReciprocityElections',
      sessionUser?.id,
      buildGetUrl('/check/retrieve_worker_reciprocity_elections', params),
    ],
    async queryFn() {
      const response = await apiClient.check.retrieveWorkerReciprocityElections(
        params,
      )
      return response.results
    },
    enabled: Boolean(sessionUser?.id && params.worker.checkUid),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  return {
    workerReciprocityElections: data || [],
    isLoadingWorkerReciprocityElections: isLoading,
    refetchworkerReciprocityElections: refetch,
  }
}

export default useQueryRetrieveWorkerReciprocityElections
