import { createSlice } from '@reduxjs/toolkit'

export const sessionsUiSlice = createSlice({
  name: 'userAccesses',
  initialState: {
    loginPageLoading: false,
    isSigningIn: false,
  },
  reducers: {
    updateLoginPageLoading: (state, action) => {
      state.loginPageLoading = action.payload
    },
    updateIsSigningIn(state, { payload }) {
      state.isSigningIn = payload
    },
  },
})

export default sessionsUiSlice
