import useMapDirection from './useMapDirection'

import { DirectionsRenderer } from 'react-google-maps'

import { IMapDirectionProps } from './type'

function MapDirection(props: IMapDirectionProps) {
  const { directions, isHidden } = useMapDirection(props)

  if (isHidden) {
    return null
  }

  return <DirectionsRenderer {...directions} />
}
export default MapDirection
