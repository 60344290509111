import { getPlatforms } from '@ionic/react'

import { CORDOVA, CAPACITOR, MOBILE_WEB } from '~/utils/constants'

export const isMobileApp = () => {
  const platforms = getPlatforms()

  return platforms.includes(CORDOVA) && platforms.includes(CAPACITOR)
}

export const isMobileWebApp = () => {
  const platforms = getPlatforms()

  return platforms.includes(MOBILE_WEB)
}
