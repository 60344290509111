import type { RefObject } from 'react'
import type {
  RTRow,
  IReusableTableRowData,
  RTRowVirtualizer,
  ReusableTableInstance,
  RTVirtualItem,
} from '../../types'

export interface IRTTableDetailPanelProps<TData extends IReusableTableRowData> {
  parentRowRef: RefObject<HTMLTableRowElement>
  row: RTRow<TData>
  rowVirtualizer?: RTRowVirtualizer
  staticRowIndex: number
  table: ReusableTableInstance<TData>
  virtualRow?: RTVirtualItem
}

export const RTTableDetailPanel = <TData extends IReusableTableRowData>({
  parentRowRef,
  row,
  rowVirtualizer,
  staticRowIndex,
  table,
  virtualRow,
  ...rest
}: IRTTableDetailPanelProps<TData>) => {
  const {
    getState,
    getVisibleLeafColumns,
    options: { layoutMode, renderDetailPanel },
    refs: { tableContainerRef },
  } = table
  const { isLoading } = getState()

  const DetailPanel = !isLoading && renderDetailPanel?.({ row, table })

  return (
    <tr
      // className="Mui-TableBodyCell-DetailPanel"
      data-index={renderDetailPanel ? staticRowIndex * 2 + 1 : staticRowIndex}
      ref={(node: HTMLTableRowElement) => {
        if (node) {
          rowVirtualizer?.measureElement?.(node)
        }
      }}
      {...rest}
      style={{
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        position: virtualRow ? 'absolute' : undefined,
        top: virtualRow
          ? `${parentRowRef.current?.getBoundingClientRect()?.height}px`
          : undefined,
        transform: virtualRow
          ? `translateY(${virtualRow?.start}px)`
          : undefined,
        width: '100%',
      }}
    >
      <td
        colSpan={getVisibleLeafColumns().length}
        {...(rest as any)}
        style={{
          backgroundColor: virtualRow ? 'white' : undefined,
          borderBottom: !row.getIsExpanded() ? 'none' : undefined,
          display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
          py: !!DetailPanel && row.getIsExpanded() ? '1rem' : 0,
          transition: !virtualRow ? 'all 150ms ease-in-out' : undefined,
          width: '100%',
        }}
      >
        <div
          style={{
            width: tableContainerRef.current?.clientWidth,
          }}
        >
          {row.getIsExpanded() && DetailPanel}
        </div>
      </td>
    </tr>
  )
}
