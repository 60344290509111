import { IonSpinner } from '@ionic/react'
import type { IReusableTableRowData, ReusableTableInstance } from '../../types'

export interface IRTTableLoadingOverlayProps<
  TData extends IReusableTableRowData,
> {
  table: ReusableTableInstance<TData>
}

export const RTTableLoadingOverlay = <TData extends IReusableTableRowData>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IRTTableLoadingOverlayProps<TData>,
) => {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.08)',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        maxHeight: '100vh',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3,
      }}
    >
      <IonSpinner name='dots' />
    </div>
  )
}
