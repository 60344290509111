import React from 'react'
import history from '~/utils/history'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { sessionService } from 'redux-react-session'
import configureStore from '~/store/configureStore'
/********Config Pushnotification - Start*************/

// const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

// if (isPushNotificationsAvailable) {
//   PushNotifications.requestPermissions().then(() => {});
//   PushNotifications.addListener('pushNotificationReceived',
//     (notification) => {
//       cordova.plugins.notification.local.schedule({
//         title: notification.title,
//         text: notification.body,
//         /*actions: [{
//             id: 'reply',
//             type: 'input',
//             title: 'Reply',
//             emptyText: 'Type message',
//         },],*/
//         sound: 'file://sound.mp3'
//       });
//       cordova.plugins.notification.local.on('click', (notification, eopts) => {
//         history.push({ pathname: '/chats/channels' });
//       });
//     }
//   );
//   PushNotifications.addListener('pushNotificationActionPerformed',
//     _ => {
//       history.push({ pathname: '/chats/channels' });
//     }
//   );
// }

/********Config Pushnotification - End*************/

const initialState = window.__INITIAL_STATE__
const store = configureStore(initialState, history)
sessionService.initSessionService(store)

const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  )
}

export default AppProvider
