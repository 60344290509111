import * as types from '../actions/actionTypes'

export const initialState = {
  loads: new Date(2000, 0, 1),
  invoices: new Date(2000, 0, 1),
}

const deltaFetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOADS_SUCCESS: {
      return {
        ...state,
        loads: Date.now(),
      }
    }
    case types.FETCH_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: Date.now(),
      }
    }

    case types.FETCH_LOADS_CHANGE_SUCCESS: {
      return {
        ...state,
        loads: Date.now(),
      }
    }

    default: {
      return { ...state }
    }
  }
}

export default deltaFetchReducer
