import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import {
  FORM_FIELD_TYPE,
  PERSON,
  PHONEABLE_TYPE_OPTIONS,
} from '~/utils/constants'
import { selectSessionUser, selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

const BankAccountForm = (props) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)
  const sessionUser = useSelector(selectSessionUser)

  const isUpdating = useMemo(
    () => formData?.id,
    [formData?.id],
  )

  const fields = useMemo(
    () => [
      {
        name: 'bankAccountableType',
        label: 'Bank Accountable Type',
        type: FORM_FIELD_TYPE.singleSelect,
        options: PHONEABLE_TYPE_OPTIONS,
        isRequired: true,
        size: 6,
      },
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
        size: 6,
      },
      {
        name: 'bankName',
        label: 'Bank name',
        isRequired: true,
      },
      {
        name: 'routingNumber',
        label: 'Routing #',
        isRequired: true,
        type: FORM_FIELD_TYPE.number,
      },
    ],
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        bankAccountableType: Yup.string().required(
          'Bank accountable type is required!',
        ),
        name: Yup.string().required('Name is required!'),
        bankName: Yup.string().required('Bank name is required!'),
        routingNumber: Yup.number()
          .required('Routing # is required!')
          .typeError('Routing # is required!'),
      }),
    [],
  )

  const defaultValues = useMemo(
    () => ({
      bankAccountableType: '',
      name: '',
      bankName: '',
      routingNumber: null,
    }),
    [],
  )

  const updateBankAccount = useCallback(
    async formData => {
      const payload = _.pick(formData, [
        'name',
        'bankName',
        'routingNumber',
        'bankAccountableType',
        'bankAccountableId',
      ])
      const response = await apiClient.bankAccounts.update(formData.id, payload)
      if (response.id) {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      } else {
        toast.error(_.get(response, 'errors[0]') || toastMessages.createError)
      }
    },
    [afterUpdate],
  )

  const createBankAccount = useCallback(
    async formData => {
      const response = await apiClient.bankAccounts.create(formData)
      if (response.id) {
        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        toast.error(_.get(response, 'errors[0]') || toastMessages.createError)
      }
    },
    [afterCreate],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      try {
        const payload = {
          ...formData,
        }
        if (formData.bankAccountableType === PERSON) {
          payload.bankAccountableId = sessionUser.person.id
        } else {
          payload.bankAccountableId = currentCompany.id
        }

        if (isUpdating) {
          await updateBankAccount(payload)
        } else {
          await createBankAccount(payload)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      createBankAccount,
      currentCompany.id,
      isUpdating,
      sessionUser.person?.id,
      updateBankAccount,
    ],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      {...formProps}
    />
  )
}

export default BankAccountForm
