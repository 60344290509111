import { Form, Row, Col } from 'react-bootstrap'
import { IonSpinner } from '@ionic/react'
import { CheckMarkIcon, CloseIcon, ToolTipOverlay } from '~/components/shared'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import './KeyValueFormFieldLayout.scss'

const KeyValueFormFieldLayout = props => {
  const {
    children,
    label,
    disabled,
    loading,
    editable,
    renderValue,
    isEdited,
    className,
    onConfirm,
    onReset,
    hideConfirmButton,
  } = props

  return (
    <Form.Group
      as={Row}
      className={clsx('KeyValueFormFieldLayout__formContainer', className, {
        disabled: loading || disabled,
      })}
      controlId={label}
    >
      <Form.Label
        className={clsx('KeyValueFormFieldLayout__label', {
          viewOnly: !editable,
        })}
        column
        sm={5}
        md={5}
        lg={5}
      >
        {label}
      </Form.Label>
      <Col
        sm={7}
        md={7}
        lg={7}
        className='KeyValueFormFieldLayout__inputContainer'
      >
        {editable && children}

        {!editable && <div>{renderValue ? renderValue() : children}</div>}
        {isEdited && (
          <>
            {onConfirm && !hideConfirmButton && !loading && (
              <ToolTipOverlay content='Click to confirm' placement='top'>
                <span onClick={onConfirm}>
                  <CheckMarkIcon className='KeyValueFormFieldLayout__icon' />
                </span>
              </ToolTipOverlay>
            )}
            {onReset && !loading && (
              <ToolTipOverlay content='Click to reset' placement='top'>
                <span onClick={onReset}>
                  <CloseIcon className='KeyValueFormFieldLayout__icon delete' />
                </span>
              </ToolTipOverlay>
            )}
          </>
        )}
        {loading && <IonSpinner name='lines-small' />}
      </Col>
    </Form.Group>
  )
}

KeyValueFormFieldLayout.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  editable: PropTypes.bool,
  renderValue: () => null,
  isEdited: PropTypes.bool,
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  onReset: PropTypes.func,
  hideConfirmButton: PropTypes.bool,
}

KeyValueFormFieldLayout.defaultProps = {
  editable: true,
  renderValue: () => null,
}

export default KeyValueFormFieldLayout
