/*global google*/
import React, { useMemo } from 'react'
import { string, number } from 'prop-types'

import ItemInfo from '~/components/driver/load/ItemInfo'

import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox'
import { When } from 'react-if'

function LocationInfobox(props) {
  const {
    lat,
    lng,
    name,
    details,
    subdetails,
    image,
    label,
    extraInfo
  } = props

  const renderExtraInfo = useMemo(() => (
    extraInfo.filter(({ isHidden }) => !isHidden).map(({ label, value }) => (
      <div key={label} style={{ marginTop: 4 }}>
        <span>{label}: </span>
        <span>{value}</span>
      </div>
    ))
  ), [extraInfo])

  return (
    <InfoBox
      width='auto'
      defaultPosition={new google.maps.LatLng(lat, lng)}
      options={{
        closeBoxURL: '',
        enableEventPropagation: true,
        width: 'auto',
        pixelOffset: new google.maps.Size(-60, -110),
        boxStyle: {
          boxShadow:
            '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.19)',
        },
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          opacity: 1,
          padding: '4px',
          whiteSpace: 'nowrap',
          boxShadow:
            '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <span
          style={{
            fontSize: '12px',
            fontColor: '#08233B',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          {label || name}
        </span>
        <span>
          <div style={{ marginTop: 4 }}>{details}</div>
          <div>{subdetails}</div>
          {
            image && <ItemInfo classes='full-thumbnail' value={image} />
            // <IonThumbnail className="full-thumbnail"><img src={image} /></IonThumbnail>
          }
        </span>

        <When condition={extraInfo?.length}>
          <div>
            {renderExtraInfo}
          </div>
        </When>
      </div>
    </InfoBox>
  )
}

LocationInfobox.propTypes = {
  lat: number.isRequired,
  lng: number.isRequired,
  name: string.isRequired,
  details: string,
  subdetails: string,
  image: string,
}

export default LocationInfobox
