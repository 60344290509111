import { CommonDialogV2 } from '~/components/shared'
import ParsedEmailForm from './ParsedEmailForm'

import type { IDialogParsedEmailFormProps } from './type'

function DialogParsedEmailForm(props: IDialogParsedEmailFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  return (
    <CommonDialogV2
      {...modalProps}
      title='Parsed Email'
      isHiddenOkButton
      onClose={onClose}
    >
      <ParsedEmailForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogParsedEmailForm
