import { useCallback, useEffect } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectIsChatInit,
  selectUnreadChatMessage,
  selectWorkerId,
} from '~/redux/selectors'
import { chatRoutesPaths } from '~/constants/routesPaths'

import { updateUIUnreadCountMessage } from '~/redux/actions/chatActions'
import { apiClient } from '~/api/ApiClient'
import { ChatIcon } from '~/components/shared'

import './DriverChatButton.scss'

const DriverChatButton = () => {
  const init = useSelector(selectIsChatInit)
  const unreadCount = useSelector(selectUnreadChatMessage)
  const workerId = useSelector(selectWorkerId)

  const history = useHistory()
  const dispatch = useDispatch()

  const onClick = () => history.push(chatRoutesPaths.chats)

  const createChat = useCallback(async () => {
    const response = await apiClient.chats.create({ workerId })
    dispatch(updateUIUnreadCountMessage(response.unreadMessagesCount))
  }, [dispatch, workerId])

  useEffect(() => {
    if (init && workerId) {
      createChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, init, workerId])

  return (
    <Button
      onClick={onClick}
      variant='outline-dark'
      className='position-relative me-2'
    >
      <ChatIcon />
      {unreadCount > 0 && (
        <Badge bg='danger' className='badge__notification'>
          {unreadCount}
        </Badge>
      )}
    </Button>
  )
}

export default DriverChatButton
