var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function () { return ({
    PreviewPaneContainer: {
        flex: 1,
        height: '100%',
        padding: 0,
        position: 'relative',
        background: 'transparent',
    },
}); });
/**
 * Container component for the document displayer
 *
 * @param props
 */
export var DocumentContainer = function (_a) {
    var children = _a.children, className = _a.className;
    var classes = useStyles();
    return (_jsx(Box, __assign({ className: clsx(classes.PreviewPaneContainer, className) }, { children: children })));
};
