import { Button } from 'react-bootstrap'
import { components } from 'react-select'
import { SearchIcon } from '~/components/shared/SvgIcons'

function CustomMenuList(props) {
  const { children, ...menuListProps } = props

  const {
    showCreateButton,
    showSearchField,
    onClickCreate,
    onInputChange,
    inputValue,
    onMenuInputFocus,
  } = menuListProps.selectProps

  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': menuListProps.selectProps['aria-label'],
    'aria-labelledby': menuListProps.selectProps['aria-labelledby'],
  }

  return (
    <div>
      {showSearchField && (
        <div
          style={{
            padding: 8,
            borderBottom: '1px solid #e5e5e5',
            position: 'relative',
          }}
        >
          <input
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: 4,
              border: '2px solid var(--ion-color-concord)',
              outline: 'none',
              borderRadius: 4,
              paddingRight: 30,
            }}
            autoCorrect='off'
            autoComplete='off'
            spellCheck='false'
            type='text'
            value={inputValue}
            onChange={e =>
              onInputChange(e.currentTarget.value, {
                action: 'input-change',
              })
            }
            // onMouseDown={e => {
            //   e.stopPropagation()
            //   e.target.focus()
            // }}
            // onTouchEnd={e => {
            //   e.stopPropagation()
            //   e.target.focus()
            // }}
            onFocus={onMenuInputFocus}
            placeholder='Search...'
            {...ariaAttributes}
          />

          <SearchIcon
            style={{ position: 'absolute', right: 20, top: 16 }}
            size={16}
            color='var(--ion-color-concord)'
          />
        </div>
      )}
      <components.MenuList {...menuListProps}>
        {children}
        {showCreateButton && (
          <div className='p-1' style={{ marginTop: 4 }}>
            <Button
              className='w-100'
              style={{ fontSize: 13 }}
              onClick={onClickCreate}
            >
              Create
            </Button>
          </div>
        )}
      </components.MenuList>
    </div>
  )
}

export default CustomMenuList
