import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const ArrowDropdownIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill={color}
      {...svgProps}
    >
      <path fill='none' d='M0 0h24v24H0z' />
      <path d='m7 10 5 5 5-5' />
    </svg>
  )
}

export default ArrowDropdownIcon
