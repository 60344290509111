import { createBrowserHistory, createMemoryHistory } from 'history'

let instance

class History {
  constructor() {
    if (!instance) {
      instance = process.env.REACT_APP_BROWSER
        ? createBrowserHistory()
        : createMemoryHistory()
    }

    return instance
  }
}

// eslint-disable-next-line
export default new History()
