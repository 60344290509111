import React, { useState, useCallback } from 'react'
import { addOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import CostCodesInlineForm from './CostCodesInlineForm'

const CostCodesAddRow = ({ codes, setCodes, rowClassName, gridClassName }) => {
  const [showInlineForm, setShowInlineForm] = useState(false)

  const toggleChild = useCallback(() => {
    setShowInlineForm(!showInlineForm)
  }, [showInlineForm, setShowInlineForm])

  return (
    <>
      <div
        style={{ paddingLeft: showInlineForm ? 0 : 12 }}
        className='body-grid cost-code-add-row'
      >
        {!showInlineForm ? (
          <>
            <div
              className='cost-code-icon-container'
              style={{
                backgroundColor: '#bebebe',
                marginTop: 16,
              }}
              onClick={toggleChild}
            >
              <IonIcon className='cost-code-icon' icon={addOutline} />
            </div>
            <span
              className='cost-code-row-text'
              style={{ color: '#bebebe', marginLeft: 28 }}
            >
              Add new company cost code
            </span>
          </>
        ) : (
          <CostCodesInlineForm
            parents={codes}
            setParents={setCodes}
            parentStyles={{ rowClassName, gridClassName }}
            toggleShowForm={toggleChild}
          />
        )}
      </div>
    </>
  )
}

export default CostCodesAddRow
