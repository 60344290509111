import type {
  IReusableTableRowData,
  ReusableTableInstance,
  RTTableOptions,
} from '../types'
import { useRTTableInstance } from './useRTTableInstance'
import { useRTTableOptions } from './useRTTableOptions'

export const useReusableTable = <TData extends IReusableTableRowData>(
  tableOptions: RTTableOptions<TData>,
): ReusableTableInstance<TData> =>
  useRTTableInstance(useRTTableOptions(tableOptions))
