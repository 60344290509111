import { useCallback, useLayoutEffect } from 'react'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'
import useFuzzy from '~/hooks/useFuzzy'
import { useSelector } from 'react-redux'

import { ContainerSearchBar } from '~/components/shared'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { CompanyMenuItem } from '../components'

import { selectMyCurrentCompany } from '~/redux/selectors'

import './styles.scss'

const CompanyHomeContainer = props => {
  const { onClickSubpage, isShowCreateButton } = props

  const { myCompanyMenu } = useNavigatedMenuItems()

  const { seachedList, searchValue, onSearch } = useFuzzy(
    myCompanyMenu.subtabs,
    {
      keys: ['label', 'description'],
    },
  )

  const currentCompany = useSelector(selectMyCurrentCompany)

  const onClickItem = useCallback(
    (event, item) => {
      onClickSubpage && onClickSubpage(event, item)
    },
    [onClickSubpage],
  )

  const renderCompanySubmenu = useCallback(() => {
    return seachedList
      .filter(
        ({ isHiddenOnHomePage, isHidden }) => !isHiddenOnHomePage && !isHidden,
      )
      .map(item => {
        if (item.title !== 'Home') {
          return (
            <IonCol
              key={item.value}
              sizeXl='4'
              sizeLg='4'
              sizeMd='6'
              sizeSm='6'
              sizeXs='12'
            >
              <CompanyMenuItem
                icon={item.icon}
                label={item.label}
                value={item.value}
                description={item.description}
                className='CompanyHomeContainer__menuItem'
                href={`/companies/${currentCompany.id}?view=${item.value}`}
                DialogComponent={item.DialogComponent}
                onClick={onClickItem}
                isShowCreateButton={isShowCreateButton}
              />
            </IonCol>
          )
        }
      })
  }, [seachedList, currentCompany.id, onClickItem, isShowCreateButton])

  useLayoutEffect(() => {
    const searchElement = document.querySelector(
      'div.CompanyHomeContainer input',
    )
    if (searchElement) {
      searchElement.focus()
    }
  }, [])

  return (
    <div className='CompanyHomeContainer__container'>
      <div className='CompanyHomeContainer__searchBar'>
        <ContainerSearchBar
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
          searchBarPlaceholder='Search...'
          searchBarParent='CompanyHomeContainer'
        />
      </div>
      <div className='CompanyHomeContainer__itemsContainer'>
        <IonGrid>
          <IonRow>{renderCompanySubmenu()}</IonRow>
        </IonGrid>
      </div>
    </div>
  )
}

export default CompanyHomeContainer
