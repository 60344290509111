import { Control, useWatch } from 'react-hook-form'

interface Props {
  control: Control
  index: number
}
export const useWatchRow = ({ control, index }: Props) => {
  const buyerTerminalId = useWatch({
    control,
    name: 'buyerTerminalId',
  })

  const orderId = useWatch({
    control,
    name: 'id',
  })

  const orderNum = useWatch({
    control,
    name: 'num',
  })

  const sellerId = useWatch({
    control,
    name: 'sellerId',
  })

  const buyerId = useWatch({
    control,
    name: 'buyerId',
  })

  const schedule = useWatch({
    control,
    name: `schedulesAttributes.${index}`,
  })

  const allSchedules = useWatch({
    control,
    name: 'schedulesAttributes',
  })

  const scheduleId = useWatch({
    control,
    name: `schedulesAttributes[${index}].id`,
  })

  const startTime = useWatch({
    control,
    name: `schedulesAttributes[${index}].startTime`,
  })

  const sellerTerminalId = useWatch({
    control,
    name: `schedulesAttributes[${index}].sellerTerminalId`,
  })

  const orderSellerTerminalId = useWatch({
    control,
    name: 'sellerTerminalId',
  })

  const date = useWatch({
    control,
    name: `schedulesAttributes[${index}].date`,
  })
  const spacing = useWatch({
    control,
    name: `schedulesAttributes[${index}].spacing`,
  })

  const fleetId = useWatch({
    control,
    name: `schedulesAttributes[${index}].fleetId`,
  })

  const orderFleetId = useWatch({
    control,
    name: 'fleetId',
  })

  const qty = useWatch({
    control,
    name: `schedulesAttributes[${index}].qty`,
  })

  const loadSize = useWatch({
    control,
    name: `schedulesAttributes[${index}].loadSize`,
  })

  const scheduleLoads = useWatch({
    control,
    name: `schedulesAttributes[${index}].scheduleLoadsAttributes`,
    defaultValue: [],
  })

  const plus = useWatch({
    control,
    name: `schedulesAttributes[${index}].plus`,
  })

  const buyerSellerProductId = useWatch({
    control,
    name: 'buyerSellerProductId',
  })

  return {
    buyerTerminalId,
    orderId,
    orderNum,
    sellerId,
    buyerId,
    schedule,
    allSchedules,
    scheduleId,
    startTime,
    sellerTerminalId,
    orderSellerTerminalId,
    date,
    spacing,
    fleetId,
    orderFleetId,
    qty,
    loadSize,
    scheduleLoads,
    plus,
    buyerSellerProductId,
  }
}
