import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { CheckMarkIcon, CloseIcon, ToolTipOverlay } from '~/components/shared'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { LoadingSelectCell } from '~/components/shared/ReactSelect/components'
import moment from 'moment'

const selectStyles = {
  valueContainer: base => ({ ...base, paddingRight: 0, paddingLeft: 0 }),
  dropdownIndicator: base => ({ ...base, paddingLeft: 1, paddingRight: 0 }),
  menu: base => ({ ...base, width: 'auto' }),
}

export const LicenseCell = props => {
  const { rowData, updateDriver, stateOptions } = props

  const id = rowData?.driver?.id
  const [number, setNumber] = useState(rowData?.driver?.licenseNumber)
  const state = rowData?.driver?.licenseState
  const [expiration, setExpiration] = useState(
    rowData?.driver?.licenseExpiration,
  )

  const revertNumber = () => setNumber(rowData?.driver?.licenseNumber)
  const revertExpiration = () =>
    setExpiration(rowData?.driver?.licenseExpiration)

  const updateNumber = async () => {
    await updateDriver({ id, licenseNumber: number })
  }

  const updateState = async licenseState =>
    await updateDriver({ id, licenseState })

  const updateExpiration = async () =>
    await updateDriver({ id, licenseExpiration: expiration })

  useEffect(() => {
    if (!number) setNumber(rowData?.driver?.licenseNumber)
  }, [number, rowData?.driver?.licenseNumber])

  useEffect(() => {
    if (!expiration) setExpiration(rowData?.driver?.licenseExpiration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiration, rowData?.driver?.licenseExpiration])

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <InputToggle
          value={number || 'No license'}
          onSubmit={updateNumber}
          onRevert={revertNumber}
          tooltip='License'
        >
          <input
            type='text'
            value={number}
            onChange={e => setNumber(e.target.value)}
            className='cell-input'
          />
        </InputToggle>
        <div>&nbsp;-&nbsp;</div>
        <InputToggle
          value={state ? `${_.toUpper(state)}` : 'No state'}
          tooltip='State'
        >
          <LoadingSelectCell
            options={stateOptions}
            placeholder='State'
            components={{ IndicatorSeparator: null }}
            onChange={e => {
              updateState(e.value)
            }}
            value={stateOptions.find(option => option.value === state) || []}
            styles={selectStyles}
            // menuPosition='fixed'//attempt to fix auto scrolling, just breaks menu
          />
        </InputToggle>
      </div>
      <InputToggle
        value={expiration ? moment(expiration).format('lll') : 'No expiration'}
        onSubmit={updateExpiration}
        onRevert={revertExpiration}
        tooltip='Expiration'
      >
        <input
          type='date'
          value={expiration}
          onChange={e => setExpiration(e.target.value)}
          className='cell-input'
        />
      </InputToggle>
    </div>
  )
}

const InputToggle = ({ value, onSubmit, onRevert, tooltip, children }) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    setLoading(true)
    try {
      onSubmit && (await onSubmit())
      setShow(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const revert = () => {
    setShow(false)
    onRevert && onRevert()
  }

  return (
    <div>
      {!show && (
        <ToolTipOverlay content={tooltip}>
          <div onClick={() => setShow(!show)}>{value}</div>
        </ToolTipOverlay>
      )}
      {show && (
        <div className='d-flex flex-row gap-1 align-items-center ms-0'>
          {children}
          {loading && <Spinner show={loading} animation='border' />}
          {!loading && (
            <div className='d-flex flex-column'>
              {onSubmit && (
                <button
                  onClick={submit}
                  className='bg-transparent'
                  onBlur={revert}
                >
                  <CheckMarkIcon color='#2dd36f' />
                </button>
              )}

              <button onClick={revert} className='bg-transparent'>
                <CloseIcon color='red' />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
