import _ from 'lodash'

const mapExportDataFromColumns = (columns, data) => {
  try {
    if (columns.length === 0) {
      return []
    }
    const result = []
    data.forEach(userAccess => {
      const object = {}
      columns
        .filter(({ hide, accessor }) => hide !== true && accessor !== 'menu')
        .forEach(({ Header, exportValueGetter, accessor, exportable }) => {
          let value = _.get(userAccess, accessor)

          if (_.isFunction(accessor)) {
            value = accessor(userAccess)
          }

          if (_.isFunction(exportValueGetter)) {
            value = exportValueGetter(userAccess)
          }

          if (exportable !== false) {
            object[Header] = value
          }
        })
      result.push(object)
    })

    return result
  } catch (error) {
    console.log('error', error)

    return []
  }
}

export default mapExportDataFromColumns
