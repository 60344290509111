import { CommonDialogV2, IDialogBTFSTPFormProps } from '~/components/shared'
import BTFSTPForm from './BTFSTPForm'

function DialogBtfstpForm(props: IDialogBTFSTPFormProps) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props
  return (
    <CommonDialogV2 {...dialogProps} title='BTFSTP' isHiddenOkButton>
      <BTFSTPForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogBtfstpForm
