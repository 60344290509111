import { CommonDialogV2 } from '~/components/shared'
import TagForm from './TagForm'

import './DialogTagForm.scss'

function DialogTagForm(props) {
  const { formData, afterCreate, afterUpdate, onClose, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Company Tag'
      isHiddenOkButton
      onClose={onClose}
    >
      <TagForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        onCancel={onClose}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogTagForm
