import { createSlice } from '@reduxjs/toolkit'

export const salesProposalsSlice = createSlice({
  name: 'salesProposals',
  initialState: {},
  reducers: {
    initializeSalesProposals: (state, action) => {
      const newState = {}

      action.payload.forEach(salesProposal => {
        newState[salesProposal.id] = salesProposal
      })

      return newState
    },
    updateSalesProposal: (state, action) => {
      if (action.payload.deletedAt) {
        delete state[action.payload.id]
      } else {
        state[action.payload.id] = action.payload
      }
    },
  },
})

export default salesProposalsSlice
