import * as types from './actionTypes'
import chatUISlice from '~/redux/reducers/ui/chat'

export const fetchMessages = page => ({
  page,
  type: types.FETCH_MESSAGES,
})

export const fetchOrders = () => ({
  type: types.FETCH_ORDERS,
})

export const conversationFetch = user => ({
  user,
  type: types.GET_CONVERSATIONS,
})

export const fetchMessagesSuccess = (messages, admin, totalMessages, page) => ({
  messages,
  admin,
  totalMessages,
  page,
  type: types.FETCH_MESSAGES_SUCCESS,
})

export const fetchConversationsSuccess = conversations => ({
  conversations,
  type: types.FETCH_CONVERSATION_SUCCESS,
})
export const suscribeChat = () => ({
  type: types.SUSCRIBE_CHAT,
})
export const unSuscribeChat = () => ({
  type: types.UNSUSCRIBE_CHAT,
})

export const messageCreation = () => ({
  type: types.CREATE_MESSAGE,
})

export const messageCreationSuccess = message => ({
  message,
  type: types.CREATE_MESSAGE_SUCCESS,
})

export const updateUINewMessage = message => dispatch => {
  dispatch(chatUISlice.actions.updateNewMessage(message))
}

export const updateUIUnreadCountMessage = count => dispatch => {
  dispatch(chatUISlice.actions.updateUnreadCount(count))
}

export const updateUISetUnreadCount = count => dispatch => {
  dispatch(chatUISlice.actions.setUnreadCount(count))
}

export const updateFontSize = font => dispatch => {
  dispatch(chatUISlice.actions.updateFontSize(font))
}
