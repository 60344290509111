import { Form } from 'react-bootstrap'
import {
  IReusableTableRowData,
  ReusableTableInstance,
  RTRow,
} from '../../types'
import {
  getIsRowSelected,
  getRTRowSelectionHandler,
  getRTSelectAllHandler,
} from '../../utils/row.utils'

export interface RTSelectCheckboxProps<TData extends IReusableTableRowData> {
  row?: RTRow<TData>
  staticRowIndex?: number
  table: ReusableTableInstance<TData>
  style?: React.CSSProperties
}

export const RTSelectCheckbox = <TData extends IReusableTableRowData>(
  props: RTSelectCheckboxProps<TData>,
) => {
  const { table, row, staticRowIndex, style } = props

  const { isLoading, density } = table.getState()
  const { selectAllMode, enableMultiRowSelection } = table.options

  const selectAll = !row

  const allRowsSelected = selectAll
    ? selectAllMode === 'page'
      ? table.getIsAllPageRowsSelected()
      : table.getIsAllRowsSelected()
    : undefined

  const isChecked = selectAll
    ? allRowsSelected
    : getIsRowSelected({ row, table })

  const onSelectAllChange = getRTSelectAllHandler({ table })

  const onSelectionChange = row
    ? getRTRowSelectionHandler({
        row,
        staticRowIndex,
        table,
      })
    : undefined

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectAll ? onSelectAllChange(event) : onSelectionChange!(event)
  }

  const commonProps = {
    'aria-label': selectAll ? 'Select All' : 'Select Row',
    checked: isChecked,
    disabled:
      isLoading || (row && !row.getCanSelect()) || row?.id === 'mrt-row-create',
    onChange: onChangeCheckbox,
    style: {
      height: density === 'compact' ? '1.75rem' : '2.5rem',
      margin: density !== 'compact' ? '-0.4rem' : undefined,
      width: density === 'compact' ? '1.75rem' : '2.5rem',
      zIndex: 0,
      ...style,
    },
  }

  if (enableMultiRowSelection) {
    return <Form.Check {...commonProps} />
  }

  return <Form.Check type='radio' {...commonProps} />
}
