export enum ECampaignType {
  top = 'top',
  cumulative = 'cumulative',
}

export enum ECampaignCadence {
  oneTime = 'one_time',
  shift = 'shift',
  day = 'day',
  week = 'week',
  biweek = 'biweek',
  month = 'month',
  bimonth = 'bimonth',
  quarter = 'quarter',
  halfYear = 'half_year',
  year = 'year',
}

export enum ECampaignMinMax {
  min = 'min',
  max = 'max',
}
