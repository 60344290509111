import {
  BuyerSellerForm,
  CommonDialogV2,
  ICommonDialogProps,
} from '~/components/shared'
import { IBuyerSellerFormProps } from './BuyerSellerForm'

export interface IDialogBuyerSellerFormProps
  extends ICommonDialogProps,
    IBuyerSellerFormProps {}

function DialogBuyerSellerForm(props: IDialogBuyerSellerFormProps) {
  const { isOpen, onClose, afterCreate, formData, hiddenFields } = props

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      title='Company'
      isHiddenOkButton
    >
      <BuyerSellerForm
        afterCreate={(company, extraParams) => {
          afterCreate && afterCreate(company, extraParams)
          onClose && onClose()
        }}
        formData={formData}
        hiddenFields={hiddenFields}
      />
    </CommonDialogV2>
  )
}

export default DialogBuyerSellerForm
