import React, { useCallback, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import { selectCompanySellerOptions } from '~/redux/selectors'
import { Loading } from '../shared'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'

const EXPECTED_FIELD_OPTIONS = [
  { label: 'Never', value: 'never', id: 0 },
  { label: 'Sometimes', value: 'sometimes', id: 1 },
  { label: 'Always', value: 'always', id: 2 },
]

const FILE_TYPES = ['Load', 'Invoice', 'Bill Line']

const EXPECTED_LOAD_FIELDS = [
  { label: 'Quantity', value: 'qty' },
  { label: 'Date', value: 'date' },
  { label: 'Num', value: 'num' },
  { label: 'Seller Code', value: 'seller_code' },
  { label: 'Seller Name', value: 'seller_name' },
  { label: 'Fleet Code', value: 'fleet_code' },
  { label: 'Fleet Name', value: 'fleet_name' },
  { label: 'Product Code', value: 'product_code' },
  { label: 'Product Name', value: 'product_name' },
  {
    label: 'Seller Terminal Code',
    value: 'seller_terminal_code',
  },
  {
    label: 'Seller Terminal Name',
    value: 'seller_terminal_name',
  },
  {
    label: 'Buyer Terminal Code',
    value: 'buyer_terminal_code',
  },
  {
    label: 'Buyer Terminal Name',
    value: 'buyer_terminal_name',
  },
  //not columns on load index
  // { label: 'Net Pounds', value: 'net_pounds' },
  // { label: 'Tare Pounds', value: 'tare_pounds' },
  // { label: 'Gross Pounds', value: 'gross_pounds' },
  // { label: 'Tare Tons', value: 'truck_empty_qty' },
  // { label: 'Gross Tons', value: 'post_load_qty' },
  // { label: 'Buyer Code', value: 'buyer_code' },
  // { label: 'Buyer Name', value: 'buyer_name' },
  // { label: 'PO Num', value: 'po_num' },
]

const EXPECTED_INVOICE_FIELDS = [
  { label: 'Price', value: 'price' },
  { label: 'Date', value: 'date' },
  { label: 'Invoice Number', value: 'num' },
  { label: 'Total', value: 'total' },
  { label: 'Total Quantity', value: 'qty' },
  { label: 'Buyer Code', value: 'buyer_code' },
  { label: 'Buyer Name', value: 'buyer_name' },
  { label: 'Buyer Terminal Name', value: 'buyer_terminal_name' },
  { label: 'Buyer Terminal Code', value: 'buyer_terminal_code' },
  { label: 'Invoice Date', value: 'end_date' },
  { label: 'Due Date', value: 'due_date' },
  //
  // { label: 'Product Code', value: 'product_code' },
  // { label: 'Product Name', value: 'product_name' },
  // { label: 'Tax', value: 'tax' },
  // { label: 'Seller Terminal Name', value: 'seller_terminal_name' },
  // { label: 'Seller Terminal Code', value: 'seller_terminal_code' },
  // { label: 'Subtotal', value: 'sub' },
  // { label: 'Payment Terms', value: 'parsed_payment_terms' },
  // { label: 'Seller Code', value: 'seller_code' },
  // { label: 'Seller Name', value: 'seller_name' },
  // { label: 'Credits', value: 'credits' },
  // { label: 'Balance', value: 'balance' },
  // { label: 'Discount Terms', value: 'parsed_discount' },
  // { label: 'Discount', value: 'discount_amount' },
  // { label: 'Freight', value: 'freight' },
  // { label: 'Late Fees', value: 'late_fees' },
]

const EXPECTED_BILL_LINE_FIELDS = [
  { label: 'Ticket Number', value: 'num' },
  { label: 'Date', value: 'line_date' },
  { label: 'Product Name', value: 'product_name' },
  { label: 'Product Code', value: 'product_code' },
  { label: 'Qty', value: 'qty' },
  { label: 'Price', value: 'price' },
  { label: 'Ext Price', value: 'ext' },
  { label: 'Total Ext Price', value: 'doc_ext_price' },
  { label: 'Tax', value: 'tax' },
  { label: 'Other Charges', value: 'other_charges' },
  { label: 'Buyer Terminal Name', value: 'buyer_terminal_name' },
  { label: 'Seller Terminal Name', value: 'seller_terminal_name' },
  { label: 'Buyer Terminal Code', value: 'buyer_terminal_code' },
  { label: 'Seller Terminal Code', value: 'seller_terminal_code' },
]
const validationColor = {
  always: '#eb445a',
  sometimes: 'black',
  never: '#5260ff',
}

const EditFieldValidationsForm = ({ companyId, type }) => {
  const [loading, setLoading] = useState(false)
  const [allFieldValidations, setAllFieldValidations] = useState([])
  const [fieldValidations, setFieldValidations] = useState([])
  const [fileType, setFileType] = useState(type)
  const [selectedCompany, setSelectedCompany] = useState(companyId)
  const sellersOptions = useSelector(selectCompanySellerOptions)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const fetchValidations = async () => {
      setLoading(true)
      const response = await apiClient.fieldValidations.get()
      setAllFieldValidations(response.fieldValidations)
      setLoading(false)
    }

    fetchValidations().catch(error => console.log(error))
  }, [])

  useEffect(() => {
    setFieldValidations(
      allFieldValidations
        .filter(fv => fv.model === fileType)
        .filter(fv => fv.companyId == selectedCompany),
    )
  }, [allFieldValidations, fileType, selectedCompany])

  const onChangeValidation = useCallback(
    async ({ target: { name, value } }) => {
      if (loading) return //lazy duplicate api call prevention
      if (getExpectedPresence(name) == value) return
      setLoading(true)

      const req = {
        fieldValidation: {
          companyId: selectedCompany,
          model: fileType,
          field: name,
          expectedPresence: value,
        },
      }

      let response = {}
      const searchKey = name
      const index = fieldValidations.findIndex(v => v.field === searchKey)

      if (index > -1) {
        response = await apiClient.fieldValidations.update(
          fieldValidations[index].id,
          req,
        )
      } else {
        response = await apiClient.fieldValidations.create(req)
      }

      const fv = fieldValidations.filter(v => v.field !== response.field)

      const newFieldValidations = [...fv, response]

      setFieldValidations(newFieldValidations)
      setLoading(false)
    },
    [loading, selectedCompany, fieldValidations, getExpectedPresence, fileType],
  )

  const getExpectedPresence = useCallback(
    name => {
      return (
        fieldValidations.find(v => v.field == name)?.expectedPresence ||
        'sometimes'
      )
    },
    [fieldValidations],
  )

  const expectedFields = () => {
    switch (fileType) {
      case FILE_TYPES[0]:
        return EXPECTED_LOAD_FIELDS
      case FILE_TYPES[1]:
        return EXPECTED_INVOICE_FIELDS
      case FILE_TYPES[2]:
        return EXPECTED_BILL_LINE_FIELDS
      default:
        return []
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Container className='mt-4'>
        <Row className='d-flex justify-content-between align-items-start mb-3'>
          <Col>
            {FILE_TYPES.map((type, index) => (
              <Form.Check
                key={index}
                inline
                type='radio'
                label={type}
                name='group1'
                id={`inline-${type}-1`}
                checked={fileType == type}
                onClick={() => setFileType(type)}
                disabled={type}
                size='lg'
              />
            ))}
          </Col>
          <Col>
            <Form.Select
              size='lg'
              onChange={e => setSelectedCompany(e.target.value)}
              value={selectedCompany}
              disabled={companyId}
            >
              <option value=''>Select Company</option>
              {sellersOptions.map(seller => (
                <option
                  key={`expectedLoadFields.${seller.value}`}
                  value={seller.value}
                >
                  {seller.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {!selectedCompany && (
          <Row className='mb-3'>
            <Form.Label>
              Please select a company to edit expected fields
            </Form.Label>
          </Row>
        )}

        {selectedCompany && (
          <>
            <Row className='d-flex mb-3'>
              <Form.Group className='me-2'>
                <Form.Control
                  size='lg'
                  type='text'
                  placeholder='Search'
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </Form.Group>
            </Row>

            {expectedFields()
              .filter(fv => _.toLower(fv.label).includes(_.toLower(search)))
              .map(fieldValidation => (
                <Form.Group
                  as={Row}
                  className='justify-content-end mb-3'
                  key={`expectedLoadFields.${fieldValidation.value}`}
                >
                  <Form.Label column className='fs-4'>
                    {fieldValidation.label}
                  </Form.Label>
                  <Col>
                    <Form.Select
                      size='lg'
                      name={fieldValidation.value}
                      onChange={e => onChangeValidation(e)}
                      value={getExpectedPresence(fieldValidation.value)}
                      style={{
                        color:
                          validationColor[
                          getExpectedPresence(fieldValidation.value)
                          ],
                      }}
                    >
                      {EXPECTED_FIELD_OPTIONS.map(o => (
                        <option
                          key={`expectedLoadFields.${o.value}`}
                          value={o.value}
                        >
                          {o.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              ))}
          </>
        )}
      </Container>
    </>
  )
}

export default EditFieldValidationsForm
