import { memo, useCallback, useState } from 'react'

import { IonCol } from '@ionic/react'
import { useMenuState, ControlledMenu, MenuItem } from '@szhsin/react-menu'
import MultipleHighLightText from '../MultipleHighLightText'

import _ from 'lodash'
import { DateTime } from 'luxon'
import clsx from 'clsx'

const EmailParserContent = props => {
  const {
    openEmailParserState,
    data,
    regexToHighLightText,
    delayTime,
    concordDocument,
    emailContent,
    onSetTextToRegex,
    idParserSelected,
  } = props
  const [menuProps, toggleMenu] = useMenuState()
  const [content, setContent] = useState('')
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

  const DATE_FORMAT = 'EEE, LLL-dd HH:mm'

  const handleClickEventItem = useCallback(
    name => () => {
      onSetTextToRegex && onSetTextToRegex(name, content)
    },
    [content, onSetTextToRegex],
  )

  const handleOpenCopyRegex = useCallback(
    event => {
      if (_.isNil(idParserSelected)) {
        return
      }

      event.preventDefault()
      const content = window.getSelection().toString()
      const mapContent = _.replace(content, /\n/g, ' ')
      if (content) {
        setAnchorPoint({
          x: event.clientX,
          y: event.clientY + 12,
        })
        setContent(mapContent)
        toggleMenu(true)
      } else {
        setAnchorPoint({
          x: 0,
          y: 0,
        })
        setContent('')
        toggleMenu(false)
      }
    },
    [idParserSelected, toggleMenu],
  )

  const mapEvents = useCallback(() => {
    const events = [
      {
        text: 'Use This Text to Identify Seller',
        className: 'PopoverSelection__sellerRegex',
        name: 'sellerRegex',
      },
      {
        text: 'Use This Text to Identify Buyer',
        className: 'PopoverSelection__buyerRegex',
        name: 'buyerRegex',
      },
      {
        text: 'Use This Text to Identify Doc Type',
        className: 'PopoverSelection__docTypeRegex',
        name: 'docTypeRegex',
      },
    ]

    return events.map(({ text, className, name }) => (
      <MenuItem
        className={clsx('PopoverSelection__menuItem', className)}
        key={name}
        onClick={handleClickEventItem(name)}
      >
        {text}
      </MenuItem>
    ))
  }, [handleClickEventItem])

  return (
    <>
      <IonCol
        onMouseUp={handleOpenCopyRegex}
        style={{ padding: 0 }}
        size={openEmailParserState ? 7 : 12}
      >
        {_.size(data) > 0 && (
          <div className='DialogEmailContent__container'>
            <div className='DialogEmailContent__headerContainer'>
              <div className='DialogEmailContent__itemContainer'>
                <span className='DialogEmailContent__label'>To: </span>
                <MultipleHighLightText regexes={regexToHighLightText}>
                  {data.toEmail}
                </MultipleHighLightText>
              </div>

              <div className='DialogEmailContent__itemContainer'>
                <span className='DialogEmailContent__label'>From: </span>
                <MultipleHighLightText regexes={regexToHighLightText}>
                  {`${data.fromName} <${data.fromEmail}>`}
                </MultipleHighLightText>
              </div>

              <div className='DialogEmailContent__itemContainer'>
                <div className='DialogEmailContent__itemBlock'>
                  <span className='DialogEmailContent__label'>Time Sent: </span>
                  <MultipleHighLightText regexes={regexToHighLightText}>
                    {DateTime.fromISO(data.timeReceived).toFormat(DATE_FORMAT)}
                  </MultipleHighLightText>
                </div>

                <div className='DialogEmailContent__itemBlock'>
                  <span className='DialogEmailContent__label'>
                    Time Received:{' '}
                  </span>
                  <MultipleHighLightText regexes={regexToHighLightText}>
                    {DateTime.fromISO(data.createdAt).toFormat(DATE_FORMAT)}
                  </MultipleHighLightText>
                </div>

                <div className='DialogEmailContent__itemBlock'>
                  <span className='DialogEmailContent__label'>Delay: </span>
                  <MultipleHighLightText regexes={regexToHighLightText}>
                    {delayTime}
                  </MultipleHighLightText>
                </div>
              </div>

              <div className='DialogEmailContent__itemContainer'>
                <span className='DialogEmailContent__label'>Subject: </span>
                <MultipleHighLightText regexes={regexToHighLightText}>
                  <span className='DialogEmailContent__subject'>
                    {data.subject}
                  </span>
                </MultipleHighLightText>
              </div>

              <div className='DialogEmailContent__itemContainer'>
                <span className='DialogEmailContent__label'>Attachments: </span>
                <MultipleHighLightText regexes={regexToHighLightText}>
                  {`<< ${concordDocument.originalName} >>` || '<< None >>'}
                </MultipleHighLightText>
              </div>
            </div>

            <hr />

            <div className='DialogEmailContent__bodyContainer'>
              <div className='DialogEmailContent__bodyContent'>
                <MultipleHighLightText regexes={regexToHighLightText}>
                  <div dangerouslySetInnerHTML={{ __html: emailContent }} />
                </MultipleHighLightText>
              </div>
            </div>
          </div>
        )}
      </IonCol>
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
      >
        {mapEvents()}
      </ControlledMenu>
    </>
  )
}

export default memo(EmailParserContent)
