import React from 'react'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { ZoomButton } from '~/components/shared'
import PropTypes from 'prop-types'

import './ViewImage.scss'

function ViewImage(props) {
  const { link } = props

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut }) => (
        <React.Fragment>
          <ZoomButton
            onZoomIn={() => zoomIn()}
            onZoomOut={() => zoomOut()}
            className='ViewImage__zoomButton'
          />
          <TransformComponent>
            <img src={link} alt='ViewImage' />
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

ViewImage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default ViewImage
