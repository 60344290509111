import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  IGetParsedEmailsParams,
  IGetParsedEmailsResponse,
  IParsedEmail,
} from '~/types/models/IParsedEmail'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

const useModifyParsedEmails = (params: IGetParsedEmailsParams = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'parsedEmails',
      sessionUser?.id,
      buildGetUrl(apiClient.parsedEmails.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const updateParsedEmail = useCallback(
    (id: number, data: IParsedEmail) => {
      queryClient.setQueryData<IGetParsedEmailsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.parsedEmails.findIndex(item => item.id === id)
              if (draft.parsedEmails[index]) {
                draft.parsedEmails[index] = data
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeParsedEmail = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGetParsedEmailsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.parsedEmails.findIndex(item => item.id === id)
              if (draft.parsedEmails[index]) {
                draft.parsedEmails.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    updateParsedEmail,
    removeParsedEmail,
  }
}

export default useModifyParsedEmails
