import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useStoreGeneral from '~/hooks/useStoreGeneral'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'

import { IonButton, IonIcon } from '@ionic/react'
import { addOutline, closeOutline, scanOutline } from 'ionicons/icons'
import { FormattedMessage } from 'react-intl'
import StaticMenuFooter from './StaticMenuFooter'
import StaticMenuFooterMini from './StaticMenuFooterMini'
import AdminSideMenu from '~/components/admin/AdminSideMenu'
import SubtabHoverMenu from '~/components/admin/AdminSideMenu/SubtabHoverMenu'
import { toggleSidebarView } from '~/redux/actions/sidebarActions.js'
import { Link } from 'react-router-dom'
import {
  ArrowBackIcon,
  ArrowDownIcon,
  DialogDocumentForm,
  ToolTipOverlay,
} from '~/components/shared'
import platform from 'platform'
import { driverRoutesPaths } from '~/constants/routesPaths'

import './StaticMenu.scss'

const StaticMenu = () => {
  const { storedMenu } = useStoreGeneral()

  const isCollapsed = useSelector(state => state.sidebar.isCollapsed)
  const currentUser = useSelector(state => state.session.user)
  const currentScope = currentUser.currentScope
  const isUploading = useSelector(state => state.ui.documents.isUploading)
  const [companyDrop, setCompanyDrop] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [subtabs, setSubtabs] = useState([])
  const [yPos, setYPos] = useState()
  const [url, setUrl] = useState()
  const location = useLocation()
  const dispatch = useDispatch()

  const { settingsMenu } = useNavigatedMenuItems()

  const [showTooltipBackIcon, setShowTooltipBackIcon] = useState(false)
  const [showTooltipForwardIcon, setShowTooltipForwardIcon] = useState(false)

  const getSubtabs = useCallback((url, tabs, y) => {
    const yPixels = y - 250 + 'px'
    setYPos(yPixels)
    setUrl(url)
    setSubtabs(tabs)
  }, [])

  const toggleCompanySubmenu = useCallback((close = true) => {
    if (!close) {
      setCompanyDrop(false)
    } else {
      setCompanyDrop(true)
    }
  }, [])

  const toggleSubmenuOff = useCallback(() => {
    setCompanyDrop(false)
  }, [])

  const showExtendedView = useCallback(() => {
    setCompanyDrop(false)
    dispatch(toggleSidebarView())
    setShowTooltipForwardIcon(false)
    setShowTooltipBackIcon(false)
  }, [dispatch])

  const toggleModal = useCallback((show = true) => {
    setShowModal(show)
  }, [])

  const isMacOS = useMemo(() => {
    const { os } = platform

    return (os.family || '').includes('OS X')
  }, [])

  const collapseExpandTooltipIcon = useMemo(() => {
    const content = isCollapsed ? 'Expand Menu' : 'Minimize Menu'
    const shortcutKey = isMacOS ? '⌘ (Command) + M' : 'Ctrl + M'

    return (
      <div>
        <div>{content}: </div>
        <div>{shortcutKey}</div>
      </div>
    )
  }, [isCollapsed, isMacOS])

  useEffect(() => {
    const keyHash = {
      ControlLeft: { pressed: false, key: 'Left Control' },
      ControlRight: { pressed: false, key: 'Right Control' },
      MetaLeft: { pressed: false, key: 'Left Meta  ' },
      MetaRight: { pressed: false, key: 'Right Shift' },
      KeyM: { pressed: false, key: 'M' },
    }

    const keyPressHandler = e => {
      const activeElement = document.activeElement.nodeName
      if (['INPUT', 'TEXTAREA', 'SELECT'].includes(activeElement)) {
        return
      }

      if (keyHash[e.code]) {
        keyHash[e.code].pressed = true
        e.preventDefault()
      }
      if (isMacOS) {
        if (
          (keyHash.MetaLeft.pressed && keyHash.KeyM.pressed) ||
          (keyHash.MetaRight.pressed && keyHash.KeyM.pressed)
        ) {
          showExtendedView()
        }
      } else {
        if (
          (keyHash.ControlLeft.pressed && keyHash.KeyM.pressed) ||
          (keyHash.ControlRight.pressed && keyHash.KeyM.pressed)
        ) {
          showExtendedView()
        }
      }
    }

    const keyUpHandler = e => {
      if (keyHash[e.code]) {
        e.preventDefault()
        keyHash[e.code].pressed = false
        keyHash.KeyM.pressed = false
      }
    }

    document.addEventListener('keydown', keyPressHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyPressHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [showExtendedView, isMacOS])

  useEffect(() => {
    const isCollapsedMenu = storedMenu === 'narrow'
    dispatch(toggleSidebarView(isCollapsedMenu))
  }, [dispatch, storedMenu])

  return (
    <>
      {!isCollapsed ? (
        <div className='menu-column d-print-none'>
          <div className='create-btn-container'>
            {currentScope === 'driver_fleet' ? (
              <Link
                to={{
                  pathname: driverRoutesPaths.scanTicket,
                  state: { view: 'form' },
                }}
              >
                <IonButton className='create-btn'>
                  <IonIcon style={{ marginRight: '4px' }} icon={scanOutline} />
                  Scan
                </IonButton>
              </Link>
            ) : (
              <IonButton
                className='create-btn'
                color={showModal || isUploading ? 'danger' : 'concord'}
                onClick={() => toggleModal(true)}
              >
                <IonIcon
                  style={{ marginRight: '4px' }}
                  icon={showModal || isUploading ? closeOutline : addOutline}
                />
                {showModal || isUploading ? 'Cancel' : 'Upload'}
              </IonButton>
            )}
          </div>
          <AdminSideMenu
            currentUser={currentUser}
            currentScope={currentScope}
          />
          <StaticMenuFooter
            settings={settingsMenu}
            location={location}
            showExtendedView={showExtendedView}
          />
          <ToolTipOverlay
            placement='top'
            content={collapseExpandTooltipIcon}
            allowToShow={!isCollapsed}
            show={showTooltipBackIcon}
          >
            <div
              className='StaticMenu__collapseArrow'
              onClick={showExtendedView}
              onMouseEnter={() => {
                setShowTooltipBackIcon(true)
              }}
              onMouseLeave={() => {
                setShowTooltipBackIcon(false)
              }}
            >
              <ArrowBackIcon className='' />
            </div>
          </ToolTipOverlay>
        </div>
      ) : (
        <div className='menu-column-mini d-print-none'>
          <div className='create-btn-container-mini'>
            <ToolTipOverlay
              placement='right'
              content={
                <FormattedMessage
                  id={showModal || isUploading ? 'Cancel' : 'Upload document'}
                />
              }
            >
              <IonButton
                className='create-btn-mini'
                color={showModal || isUploading ? 'danger' : 'concord'}
                onClick={() => toggleModal(true)}
              >
                <IonIcon
                  icon={showModal || isUploading ? closeOutline : addOutline}
                />
                {showModal || isUploading ? 'x' : '+'}
              </IonButton>
            </ToolTipOverlay>
          </div>
          <AdminSideMenu
            isCollapsed={isCollapsed}
            currentUser={currentUser}
            currentScope={currentScope}
            getSubtabs={getSubtabs}
            toggleCompanySubmenu={toggleCompanySubmenu}
          />
          <StaticMenuFooterMini
            settings={settingsMenu}
            showExtendedView={showExtendedView}
          />
          <ToolTipOverlay
            placement='top'
            content={collapseExpandTooltipIcon}
            allowToShow={isCollapsed}
            show={showTooltipForwardIcon}
          >
            <div
              className='StaticMenu__collapseArrow'
              onClick={showExtendedView}
              onMouseEnter={() => {
                setShowTooltipForwardIcon(true)
              }}
              onMouseLeave={() => {
                setShowTooltipForwardIcon(false)
              }}
            >
              <ArrowDownIcon className='' />
            </div>
          </ToolTipOverlay>
        </div>
      )}
      <DialogDocumentForm
        isOpen={showModal}
        onClose={() => {
          toggleModal(false)
        }}
        afterCreate={() => {
          toggleModal(false)
        }}
      />
      {companyDrop && (
        <SubtabHoverMenu
          y={yPos}
          subtabs={subtabs}
          url={url}
          toggleSubmenuOff={toggleSubmenuOff}
        />
      )}
    </>
  )
}
export default StaticMenu
