import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const usersSlice = createSlice({
  name: 'users',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(ua => {
        newState[ua.id] = ua
      })
      return newState
    },
    add: (state, action) => {
      state[action.payload.id] = action.payload
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export const selectUsers = state => state.data.users

export const selectUsersList = createSelector(selectUsers, users =>
  Object.values(users),
)

export const fetchUsers = createAsyncThunk(
  'users/fetch',
  async (params = {}, { dispatch }) => {
    try {
      const users = await apiClient.users.get(params)
      dispatch(usersSlice.actions.index(users))
    } catch (error) {
      console.log(error)
    }
  },
)
