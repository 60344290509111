import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import {
  IonButton,
  IonButtons,
  IonLabel,
  isPlatform,
  IonIcon,
  IonModal,
  IonHeader,
  IonContent,
} from '@ionic/react'
import {
  filter,
  funnelOutline,
  closeOutline,
  saveOutline,
} from 'ionicons/icons'
import {
  RangeSelectFilter,
  DateRangePicker,
  DropdownPicker,
  ContainerSearchBar,
  MultiSelectSearchDropdown,
} from '~/components/shared'
import _ from 'lodash'
import MultiselectOrderDropdown from '../MultiselectOrderDropdown'
import ClearFiltersButton from './ClearFiltersButton'
import { ionicColor } from '~/constants/theme'
// import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getTitleRange } from '~/utils/getRangeDate'
import ContainerFilterTooltipItem from './TooltipItem'
import getLabelValueRangeFilter from '~/utils/getLabelValueRangeFilter'

import { ControlledMenu, useMenuState, MenuItem } from '@szhsin/react-menu'

import './ContainerFilter.scss'

const WrappedSelectButton = ({ onClick, currentField }) => {
  return (
    <IonButtons>
      <IonButton
        fill='clear'
        title={
          currentField &&
          _.isArray(currentField?.showValue) &&
          currentField?.showValue?.join(', ')
        }
        color='primary'
        onClick={() =>
          onClick(currentField.onLoadFilter, currentField.currentField)
        }
      >
        <IonLabel style={{ fontWeight: 'bold', fontSize: 'small' }}>
          {currentField.label}&nbsp;
        </IonLabel>
        {currentField.filterValue?.length > 0 &&
          currentField.filterValue?.length != currentField.currentField?.length &&
          currentField.showValue ? (
          <IonLabel style={{ fontSize: 'small' }} color='dark'>
            {currentField.showValue.length > 1
              ? currentField.showValue.length
              : currentField.showValue[0]}
          </IonLabel>
        ) : (
          <IonLabel
            style={{
              maxWidth: '100%',
              padding: 0,
              opacity: 1,
              fontSize: 'small',
            }}
            color='dark'
          >
            {' '}
            All
          </IonLabel>
        )}
      </IonButton>
    </IonButtons>
  )
}

const MultiSelectSearch = ({ currentField, onSubmitSellers, onClick }) => {
  return (
    <MultiSelectSearchDropdown
      field={currentField.value}
      values={currentField.filterValue}
      data={currentField.currentField}
      onSubmit={onSubmitSellers}
      showRefresh={currentField.showRefresh}
      sortable={currentField.sortable}
      dropdownMenuStyle={{
        maxHeight: isPlatform('mobile') && 50,
      }}
    >
      <WrappedSelectButton onClick={onClick} currentField={currentField} />
    </MultiSelectSearchDropdown>
  )
}

const ContainerFilter = ({
  dateRangeTitle,
  ticketDateRangeTitle,
  isShowTicketDate,
  showSearch,
  searchPlaceholder = 'Search...',
  showDatePicker,
  buyerTerminalField = [],
  multiSelectField,
  dropDownField,
  rangeSelectFilter,
  onUpdateIonFilter,
  onCreatedDateRangeChange,
  onTicketDateRangeChange,
  createdDateRange,
  ticketDateRange,
  onUpdateFilter,
  onSearchKeyChange,
  searchKey,
  onFiltersReset,
  hideMarginTop,
  setBorderColor,
  hideClearButton = false,
  onSortChange,
  sortOptions,
  isMyCompanyPage = false,
  showTitle = true,
  searchBarClassName,
  columnsDisplayed,
  onChangeColumnsDisplayed,
  leftExtraSection,
  rightExtraSection,
  onSaveDefaultFilters,
  className,
}) => {
  const { isScreenDown, isScreenUp } = useBreakpoint()

  const containerFilterRef = useRef()
  const [isHovering, setIsHovering] = useState(false)

  const [isShowModal, setIsShowModal] = useState(false)
  const [searchKeyFilter, setSearchKeyFilter] = useState('')

  const saveButtonRef = useRef()
  const [savingMenuProps, toggleSavingMenu] = useMenuState({ transition: true })

  const lowerSearchKey = useMemo(
    () => _.toLower(searchKeyFilter),
    [searchKeyFilter],
  )

  const mapDropDownFields = useMemo(() => {
    return dropDownField.filter(({ filterKey }) => {
      if (!lowerSearchKey) {
        return true
      }

      const formatLabel = _.toLower(filterKey)

      return formatLabel.includes(lowerSearchKey)
    })
  }, [dropDownField, lowerSearchKey])

  const mapBuyerTerminalField = useMemo(() => {
    return buyerTerminalField.filter(({ label }) => {
      if (!lowerSearchKey) {
        return true
      }

      const formatLabel = _.toLower(label)

      return formatLabel.includes(lowerSearchKey)
    })
  }, [buyerTerminalField, lowerSearchKey])

  const mapMultiSelectFields = useMemo(() => {
    return multiSelectField
      .map(field => ({ hide: false, sortable: true, ...field }))
      .filter(({ label, hide }) => {
        if (hide === true) {
          return false
        }
        if (!lowerSearchKey) {
          return true
        }
        const formatLabel = _.toLower(label)

        return formatLabel.includes(lowerSearchKey)
      })
  }, [multiSelectField, lowerSearchKey])

  const mapRangeSelectFilter = useMemo(() => {
    return rangeSelectFilter.filter(({ label }) => {
      if (!lowerSearchKey) {
        return true
      }

      const formatLabel = _.toLower(label)

      return formatLabel.includes(lowerSearchKey)
    })
  }, [rangeSelectFilter, lowerSearchKey])

  const isDisplayDateRange = useMemo(() => {
    if (!showDatePicker) {
      return false
    }

    if (!lowerSearchKey) {
      return true
    }

    return (
      _.toLower(dateRangeTitle || '').includes(lowerSearchKey) ||
      'CREATED AT'.toLowerCase().includes(lowerSearchKey)
    )
  }, [showDatePicker, lowerSearchKey, dateRangeTitle])

  const isDisplayTicketDate = useMemo(() => {
    if (!(showDatePicker && isShowTicketDate)) {
      return false
    }

    if (!lowerSearchKey) {
      return true
    }

    return (
      _.toLower(dateRangeTitle || '').includes(lowerSearchKey) ||
      'CREATED AT'.toLowerCase().includes(lowerSearchKey)
    )
  }, [showDatePicker, lowerSearchKey, dateRangeTitle, isShowTicketDate])

  useEffect(() => {
    if (isMyCompanyPage) {
      setBorderColor(!hideClearButton ? ionicColor.snowGrey : ionicColor.dark)
    }
  }, [setBorderColor, hideClearButton, isMyCompanyPage])

  const isShowFilter = useMemo(() => {
    return Boolean(
      showDatePicker || dropDownField || multiSelectField || rangeSelectFilter,
    )
  }, [showDatePicker, dropDownField, multiSelectField, rangeSelectFilter])

  const onSubmitSellers = useCallback(
    (value, currentField) => {
      onUpdateIonFilter &&
        onUpdateIonFilter(currentField)({ detail: { value } })
    },
    [onUpdateIonFilter],
  )

  const onClick = (onLoadFilter, currentData) => {
    if (_.isEmpty(currentData)) {
      onLoadFilter && onLoadFilter()
    }
  }

  const toggleFiltersModal = () => setIsShowModal(!isShowModal)

  const getBorderButtonColor = () => {
    if (isShowModal) {
      return '2px solid rgb(56, 128, 255)'
    } else {
      if (hideClearButton) {
        return '2px solid #d0d5dd'
      } else {
        return '2px solid var(--ion-color-lightgreen)'
      }
    }
  }

  const handleSearchFilter = useCallback((event, value) => {
    setSearchKeyFilter(value)
  }, [])

  const getTooltipMultiFilters = useCallback(fields => {
    const elements = []
    fields.forEach(field => {
      const { showValue, label, value } = field
      let optionsValue = 'All'
      if (showValue?.length > 0) {
        optionsValue = showValue.join(', ')
      }
      elements.push(
        <ContainerFilterTooltipItem
          key={value}
          label={label}
          value={optionsValue}
        />,
      )
    })

    return elements
  }, [])

  const getTooltipSingleFilters = useCallback(fields => {
    let elements
    fields.forEach(singleField => {
      const { field, filterKey, items, value } = singleField
      const findItem = items?.find(item => item.value === value)

      elements = (
        <ContainerFilterTooltipItem
          key={field}
          label={filterKey}
          value={findItem?.label || 'All'}
        />
      )
    })

    return elements
  }, [])

  const getTooltipRangeSelected = useCallback(fields => {
    const element = []
    fields.forEach(({ label, field, value }) => {
      element.push(
        <ContainerFilterTooltipItem
          key={field}
          label={label}
          value={getLabelValueRangeFilter(value)}
        />,
      )
    })

    return element
  }, [])

  const tooltipRendered = useMemo(() => {
    const tooltipMessage = []
    if (isDisplayDateRange) {
      const element = createdDateRange && (
        <ContainerFilterTooltipItem
          key='container__createdDateRange'
          label={dateRangeTitle}
          value={getTitleRange(createdDateRange)}
        />
      )
      tooltipMessage.push(element)
    }

    if (buyerTerminalField.length > 0) {
      const element = getTooltipMultiFilters(buyerTerminalField)
      tooltipMessage.push(
        <React.Fragment key='container__buyerTerminalField'>
          {element}
        </React.Fragment>,
      )
    }

    if (showDatePicker && isShowTicketDate) {
      const element = ticketDateRange && (
        <ContainerFilterTooltipItem
          key='container__ticketdaterange'
          label={ticketDateRangeTitle}
          value={getTitleRange(ticketDateRange)}
        />
      )
      tooltipMessage.push(element)
    }

    if (dropDownField.length > 0) {
      const element = getTooltipSingleFilters(dropDownField)
      tooltipMessage.push(element)
    }

    if (multiSelectField.length > 0) {
      const element = getTooltipMultiFilters(multiSelectField)
      tooltipMessage.push(element)
    }

    if (rangeSelectFilter?.length > 0) {
      const element = getTooltipRangeSelected(rangeSelectFilter)
      tooltipMessage.push(element)
    }

    return <ul className='ContainerFilter__wrapFilterItem'>{tooltipMessage}</ul>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buyerTerminalField,
    createdDateRange,
    dateRangeTitle,
    dropDownField,
    getTooltipMultiFilters,
    getTooltipRangeSelected,
    getTooltipSingleFilters,
    isDisplayDateRange,
    isShowTicketDate,
    multiSelectField,
    rangeSelectFilter,
    showDatePicker,
    ticketDateRange,
    ticketDateRangeTitle,
  ])

  const renderFilterSection = () => (
    <>
      {/* Mobile */}
      {isScreenDown('md') && (
        <div
          style={{ border: getBorderButtonColor(), borderRadius: 12 }}
          ref={containerFilterRef}
        >
          <IonButton
            onClick={toggleFiltersModal}
            style={{
              margin: 0,
              height: 40,
              '--border-color': 'none',
              '--border-width': 0,
            }}
            color={'dark'}
            fill='outline'
          >
            <IonIcon icon={funnelOutline} size='small' />
            {showTitle && <span style={{ color: 'black' }}>&nbsp;Filter</span>}
          </IonButton>
        </div>
      )}

      {/* Desktop */}
      {isScreenUp('md') && (
        <div
          className={clsx('container-filters-scroll hide-scrollbar', className)}
          style={{ marginTop: hideMarginTop ? 0 : 18 }}
          ref={containerFilterRef}
          onPointerEnter={() => setIsHovering(true)}
          onPointerLeave={() => setIsHovering(false)}
        >
          <div
            className={clsx('ContainerFilter__container', {
              active: !hideClearButton,
            })}
          >
            {isDisplayDateRange && (
              <DateRangePicker
                title={dateRangeTitle}
                range={createdDateRange}
                onRangeChange={onCreatedDateRangeChange}
                className='dateSubmitButton'
              />
            )}
            {mapBuyerTerminalField.map(currentField => (
              <div key={currentField.value}>
                <MultiSelectSearch
                  currentField={currentField}
                  onClick={onClick}
                  onSubmitSellers={onSubmitSellers}
                />
              </div>
            ))}
            {showDatePicker && isShowTicketDate && (
              <DateRangePicker
                title={ticketDateRangeTitle}
                range={ticketDateRange}
                onRangeChange={onTicketDateRangeChange}
                className='ticketDateSubmitButton'
              />
            )}
            {dropDownField.map(({ field, filterKey, value, items, icon }) => (
              <DropdownPicker
                key={field}
                field={field}
                filterKey={filterKey}
                value={value}
                items={items}
                icon={icon}
                onValueChange={onUpdateFilter}
              />
            ))}
            {mapMultiSelectFields.map(currentField => (
              <div key={currentField.value}>
                <MultiSelectSearch
                  currentField={currentField}
                  onClick={onClick}
                  onSubmitSellers={onSubmitSellers}
                />
              </div>
            ))}
            {rangeSelectFilter &&
              rangeSelectFilter.map(subField => (
                <RangeSelectFilter
                  key={subField.field}
                  field={subField.field}
                  label={subField.label}
                  value={subField.value}
                  className='documents-filter'
                  range={subField.range}
                  icon={filter}
                  onValueChange={onUpdateFilter}
                />
              ))}
          </div>
        </div>
      )}

      {onSaveDefaultFilters && (
        <div
          className='documents-container-clear save-button hide-md-down'
          ref={saveButtonRef}
          onClick={() => toggleSavingMenu(true)}
        >
          <IonButtons size='lg'>
            <IonButton fill='clear'>
              <IonIcon icon={saveOutline} slot='end' />
            </IonButton>
          </IonButtons>
        </div>
      )}

      {!hideClearButton && (
        <ClearFiltersButton onFiltersReset={onFiltersReset} />
      )}
    </>
  )

  return (
    <>
      {showSearch && (
        <ContainerSearchBar
          searchBarPlaceholder={searchPlaceholder}
          searchBarValue={searchKey}
          onSearchBarChange={onSearchKeyChange}
          searchBarParent='ContainerFilter'
          className={searchBarClassName}
        />
      )}
      {leftExtraSection}
      {sortOptions && sortOptions.length > 0 && (
        <div
          className='documents-sortby-container'
          style={{
            display: 'flex',
            padding: 1,
            alignItems: 'center',
            color: 'black',
          }}
        >
          <MultiselectOrderDropdown
            options={sortOptions}
            onChange={onSortChange}
            containerStyle={{
              border: '1px solid rgba(0,0,0,.15)',
              width: 250,
              backgroundColor: 'white',
            }}
            isMyCompanyPage
          />
        </div>
      )}

      {/* Columns button */}
      {columnsDisplayed.length > 0 && onChangeColumnsDisplayed && (
        <div
          className='documents-sortby-container'
          style={{
            display: 'flex',
            padding: 1,
            alignItems: 'center',
            color: 'black',
          }}
        >
          <MultiselectOrderDropdown
            options={columnsDisplayed}
            onChange={onChangeColumnsDisplayed}
          />
        </div>
      )}

      {isShowFilter && renderFilterSection()}

      {rightExtraSection}

      <IonModal
        cssClass='ion-modal-mobile'
        isOpen={isShowModal}
        onDidDismiss={toggleFiltersModal}
        canDismiss={true}
        backdropDismiss
      >
        <IonHeader
          style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}
        >
          <IonLabel style={{ fontSize: 18 }}>Search Filters</IonLabel>
        </IonHeader>

        <IonContent>
          <ContainerSearchBar
            onSearchBarChange={handleSearchFilter}
            searchBarValue={searchKeyFilter}
            className='ion-padding'
          />

          {isDisplayDateRange && (
            <DateRangePicker
              title={dateRangeTitle || 'CREATED AT'}
              range={createdDateRange}
              onRangeChange={onCreatedDateRangeChange}
              className='dateSubmitButton'
            />
          )}
          {mapBuyerTerminalField.map(currentField => (
            <MultiSelectSearch
              key={currentField.value}
              currentField={currentField}
              onClick={onClick}
              onSubmitSellers={onSubmitSellers}
            />
          ))}
          {isDisplayTicketDate && (
            <DateRangePicker
              title={ticketDateRangeTitle || 'DATE'}
              range={ticketDateRange}
              onRangeChange={onTicketDateRangeChange}
              className='ticketDateSubmitButton'
            />
          )}
          {mapDropDownFields.map(({ field, filterKey, value, items, icon }) => (
            <DropdownPicker
              field={field}
              filterKey={filterKey}
              value={value}
              items={items}
              icon={icon}
              onValueChange={onUpdateFilter}
              key={field}
            />
          ))}

          {mapMultiSelectFields.map(currentField => (
            <MultiSelectSearch
              key={currentField.value}
              currentField={currentField}
              onClick={onClick}
              onSubmitSellers={onSubmitSellers}
            />
          ))}
          {dropDownField &&
            dropDownField.map(({ field, filterKey, value, items, icon }) => (
              <DropdownPicker
                field={field}
                filterKey={filterKey}
                value={value}
                items={items}
                icon={icon}
                onValueChange={onUpdateFilter}
                key={field}
              />
            ))}

          {multiSelectField &&
            multiSelectField.map(currentField => (
              <MultiSelectSearch
                key={currentField.value}
                currentField={currentField}
                onClick={onClick}
                onSubmitSellers={onSubmitSellers}
              />
            ))}

          {mapRangeSelectFilter.map(subField => (
            <RangeSelectFilter
              key={subField.field}
              field={subField.field}
              label={subField.label}
              value={subField.value}
              className='documents-filter'
              range={subField.range}
              icon={filter}
              onValueChange={onUpdateFilter}
            />
          ))}

          {rangeSelectFilter &&
            rangeSelectFilter.map(subField => (
              <RangeSelectFilter
                key={subField.field}
                field={subField.field}
                label={subField.label}
                value={subField.value}
                className='documents-filter'
                range={subField.range}
                icon={filter}
                onValueChange={onUpdateFilter}
              />
            ))}
        </IonContent>

        {!hideClearButton && (
          <IonButton onClick={onFiltersReset} color='danger'>
            <IonIcon icon={closeOutline} slot='end'></IonIcon>
            <IonLabel className=''>Clear filters</IonLabel>
          </IonButton>
        )}
      </IonModal>
      <ControlledMenu
        direction='bottom'
        className='ContainerFilter__filterOverlay'
        state={isHovering ? 'open' : 'closed'}
        anchorRef={containerFilterRef}
        onClick={() => setIsHovering(false)}
        onClose={() => setIsHovering(false)}
      >
        {tooltipRendered}
      </ControlledMenu>

      {onSaveDefaultFilters && (
        <ControlledMenu
          {...savingMenuProps}
          anchorRef={saveButtonRef}
          onPointerLeave={() => toggleSavingMenu(false)}
          onClose={() => toggleSavingMenu(false)}
        >
          <MenuItem style={{ fontSize: 14 }} onClick={onSaveDefaultFilters}>
            Save filters by default
          </MenuItem>
        </ControlledMenu>
      )}
    </>
  )
}

// ContainerFilter.propTypes = {
//   columnsDisplayed: PropTypes.array,
//   onChangeColumnsDisplayed: PropTypes.func,
//   leftExtraSection: PropTypes.func,
//   rightExtraSection: PropTypes.func
// }

ContainerFilter.defaultProps = {
  buyerTerminalField: [],
  dropDownField: [],
  multiSelectField: [],
  rangeSelectFilter: [],
  dateRangeTitle: 'Created At',
  ticketDateRangeTitle: 'Date',
  tableColumns: [],
  onChangeColumns: () => { },
  columnsDisplayed: [],
  tooltipDirection: 'left',
}

export default ContainerFilter
