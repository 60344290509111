import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import { checkmarkCircle, closeCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

export interface IPayrollJournalTableProps {
  data: any
}

function Paydays(props: IPayrollJournalTableProps) {
  const { data } = props

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Payday',
        accessorKey: 'payday',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'Approval Deadline',
        accessorKey: 'approvalDeadline',
        align: 'center',
      },
      {
        header: 'Impacted By Holiday',
        accessorKey: 'impactedByHoliday',
        align: 'center',
        Cell({ cell }) {
          const cellData = cell.getValue()
          const icon = cellData ? checkmarkCircle : closeCircle
          const color = cellData ? 'success' : 'danger'

          return <IonIcon icon={icon} color={color} />
        },
      },
    ],
    [],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default Paydays
