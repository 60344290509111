import { useState, useCallback, useEffect, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { CommonDialog } from '~/components/shared'
import PdfSplitting from './PdfSplitting'
import PdfSplittingView from './PdfSplittingView'
import { Button } from 'react-bootstrap'

import _ from 'lodash'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import handleSplitInvoice from '~/utils/handleSplitInvoice'
import clsx from 'clsx'
import { apiClient } from '~/api/ApiClient'

import './DialogSplitInvoice.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'

const DialogSplitInvoice = props => {
  const {
    isOpen,
    document,
    onClose,
    onSubmit,
    loading,
    currentCompany,
    onUpdateCompany,
    className,
    ...dialogProps
  } = props

  const [totalPages, setTotalPages] = useState(0)
  const [numberSplitPages, setNumberSplitPages] = useState([]) // ex: [[1,8], []]
  const [selectedPdf, setSelectedPdf] = useState({
    index: -1,
    maxPage: null,
    minPage: null,
  })
  const [toggleOriginalDoc, setToggleOriginalDoc] = useState(false)
  const [deletedSplitPages, setDeletedSplitPages] = useState([])
  const [loadingState, setLoadingState] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const handleChangeTotalPages = useCallback(nextTotalPages => {
    setTotalPages(nextTotalPages)
  }, [])

  const handleSplitPage = useCallback(
    ({ from, to }) => {
      const newNumberSplitPages = handleSplitInvoice(
        numberSplitPages,
        { from, to },
        selectedPdf.index,
      )
      setNumberSplitPages(newNumberSplitPages)

      const findIndex = _.findIndex(newNumberSplitPages, pages =>
        _.isEqual(pages, [from, to]),
      )

      if (findIndex === -1) {
        toast.error(
          'An error has occured. Please wait a few minutes before attempting to reload this page.',
        )
      } else {
        setSelectedPdf({
          index: findIndex,
          minPage: from,
          maxPage: to,
        })
      }
    },
    [numberSplitPages, selectedPdf],
  )

  const getIsDeletedPart = useCallback(
    ([minPage, maxPage]) => {
      return deletedSplitPages.some(value =>
        _.isEqual(value, [minPage, maxPage]),
      )
    },
    [deletedSplitPages],
  )

  const actualNumberSplitPages = useMemo(() => {
    const removeNull = numberSplitPages.filter(value => value)
    const diff = []

    removeNull.forEach(([min, max]) => {
      if (!getIsDeletedPart([min, max])) {
        diff.push([min, max])
      }
    })

    return diff
  }, [getIsDeletedPart, numberSplitPages])

  const updateCompanySellerCheckForSplit = useCallback(async () => {
    if (!currentCompany.id || currentCompany.checkForSplit === true) {
      return
    }

    const result = await confirmation({
      message:
        'Do you want to update the "Check for split" of seller for future documents',
    })

    if (result === EYesNo.Yes) {
      setLoadingState(true)
      try {
        const response = await apiClient.companies.update(currentCompany.id, {
          company: {
            checkForSplit: true,
          },
        })
        onUpdateCompany && onUpdateCompany(response.company)
      } catch (error) {
        toast.error(
          "An error has occurred while updating your company's details.",
        )
      } finally {
        setLoadingState(false)
      }
    }
  }, [confirmation, currentCompany, onUpdateCompany])

  const handleOk = useCallback(async () => {
    updateCompanySellerCheckForSplit()
    onSubmit && onSubmit(actualNumberSplitPages, { document })
  }, [
    actualNumberSplitPages,
    document,
    onSubmit,
    updateCompanySellerCheckForSplit,
  ])

  const handleClickSplitPdf = useCallback(({ index, minPage, maxPage }) => {
    setSelectedPdf({ index, minPage, maxPage })
  }, [])

  const handleToggleOriginalDoc = useCallback(() => {
    setToggleOriginalDoc(prev => !prev)
  }, [])

  const handleMergePrevious = useCallback(({ index, maxPage }) => {
    setNumberSplitPages(prev =>
      produce(prev, draft => {
        draft[index - 1][1] = maxPage
        delete draft[index]
        setSelectedPdf({
          index: index - 1,
          minPage: draft[index - 1][0],
          maxPage,
        })
      }),
    )
  }, [])

  const handleMergeNext = useCallback(({ index, minPage }) => {
    setNumberSplitPages(prev =>
      produce(prev, draft => {
        draft[index + 1][0] = minPage
        delete draft[index]
        setSelectedPdf({
          index: index + 1,
          minPage,
          maxPage: draft[index + 1][1],
        })
      }),
    )
  }, [])

  const handleDeletePart = useCallback(({ minPage, maxPage }) => {
    setDeletedSplitPages(prev =>
      produce(prev, draft => {
        const findIndex = draft.findIndex(
          ([min, max]) => min === minPage && max === maxPage,
        )
        if (findIndex !== -1) {
          draft.splice(findIndex, 1)
        } else {
          draft.push([minPage, maxPage])
        }
      }),
    )
  }, [])

  const getCanMergePrev = useCallback(
    (params, index) => {
      const prevPages = numberSplitPages[index - 1]

      if (_.isNil(prevPages)) {
        return false
      }

      return getIsDeletedPart(prevPages) === false
    },
    [getIsDeletedPart, numberSplitPages],
  )

  const getCanMergeNext = useCallback(
    (params, index) => {
      const nextPage = numberSplitPages[index + 1]

      if (_.isNil(nextPage)) {
        return false
      }

      return getIsDeletedPart(nextPage) === false
    },
    [getIsDeletedPart, numberSplitPages],
  )

  const handleReset = useCallback(() => {
    setNumberSplitPages([[1, totalPages]])
    setSelectedPdf({
      index: 1,
      minPage: 1,
      maxPage: totalPages,
    })
    setDeletedSplitPages([])
  }, [totalPages])

  useEffect(() => {
    if (totalPages !== 0) {
      setNumberSplitPages([[1, totalPages]])
      setSelectedPdf({
        index: 0,
        minPage: 1,
        maxPage: totalPages,
      })
    }
  }, [totalPages])

  useUpdateEffect(() => {
    // reset number of totalPage when this dialog is hidden
    if (!isOpen) {
      setTotalPages(0)
      setDeletedSplitPages([])
      setNumberSplitPages([])
      setSelectedPdf({
        index: -1,
        minPage: null,
        maxPage: null,
      })
      setToggleOriginalDoc(false)
    }
  }, [isOpen])

  return (
    <CommonDialog
      className={clsx('DialogSplitInvoice__container', className)}
      onClose={onClose}
      open={isOpen}
      title='Split Invoice'
      okText='Split this Document'
      onOk={handleOk}
      loading={loading || loadingState}
      okProps={{
        disabled:
          actualNumberSplitPages.length === 0 ||
          _.isEqual(numberSplitPages, [[1, totalPages]]),
      }}
      onReset={handleReset}
      resetProps={{
        disabled: _.isEqual(numberSplitPages, [[1, totalPages]]) || loading,
      }}
      {...dialogProps}
    >
      <IonGrid>
        <IonRow>
          <IonCol size={6}>
            <PdfSplitting
              numberSplitPages={numberSplitPages}
              isSelectDeletedPart={getIsDeletedPart([
                selectedPdf.minPage,
                selectedPdf.maxPage,
              ])}
              isOriginalDoc={selectedPdf.index === -1}
              pdfUrl={document?.url}
              onChangeTotalPages={handleChangeTotalPages}
              totalPages={totalPages}
              onSplitPage={handleSplitPage}
              forwardButtonProps={({ page }) => ({
                disabled: page === selectedPdf.maxPage,
              })}
              backButtonProps={({ page }) => ({
                disabled: page === selectedPdf.minPage,
              })}
              {...selectedPdf}
            />
          </IonCol>
          <IonCol size={6} className='DialogSplitInvoice__viewPdfs'>
            <div className='DialogSplitInvoice__toggleOriginalDoc'>
              <Button onClick={handleToggleOriginalDoc} disabled={loading}>
                {toggleOriginalDoc
                  ? 'Hide Original Document'
                  : 'Show Original Document'}
              </Button>
            </div>

            {toggleOriginalDoc && (
              <PdfSplittingView
                pages={[1, totalPages]}
                pdfUrl={document?.url}
                index={-1}
                onClick={handleClickSplitPdf}
                selectedPdf={selectedPdf}
              />
            )}

            {numberSplitPages.map((pages, index) => {
              return (
                <PdfSplittingView
                  key={index}
                  isDeleted={getIsDeletedPart(pages)}
                  pages={pages}
                  pdfUrl={document?.url}
                  index={index}
                  onClick={handleClickSplitPdf}
                  selectedPdf={selectedPdf}
                  onMergePrevious={handleMergePrevious}
                  onMergeNext={handleMergeNext}
                  onDeletePart={handleDeletePart}
                  canMergePrev={getCanMergePrev(pages, index)}
                  canMergeNext={getCanMergeNext(pages, index)}
                  totalPages={totalPages}
                />
              )
            })}
          </IonCol>
        </IonRow>
      </IonGrid>
    </CommonDialog>
  )
}

DialogSplitInvoice.defaultProps = {
  currentCompany: {},
}

export default DialogSplitInvoice
