import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPayrolls from './useModifyPayrolls'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { getTitleRange } from '~/utils'

import type { IUser } from '~/types/models/IUser'
import type { IPayroll } from '~/types/models/IPayroll'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryPayrolls = (
  params = {},
  options?: Partial<UseQueryOptions<IPayroll[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'payrolls',
      sessionUser?.id,
      buildGetUrl(apiClient.payrolls.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.payrolls.get()
      return response.payrolls
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addPayroll, updatePayroll, removePayroll } = useModifyPayrolls(params)

  const payrollsData = useMemo(() => data || [], [data])

  const payrollsOptions = useMemo(
    () =>
      payrollsData
        .filter(({ checkUid }) => checkUid)
        .map(({ checkUid, periodEnd, periodStart }) => ({
          label: getTitleRange({
            startDate: periodStart,
            endDate: periodEnd,
          }),
          value: checkUid,
        })),
    [payrollsData],
  )

  return {
    payrollsData,
    isPayrollsLoading: isLoading,
    payrollsOptions,
    addPayroll,
    updatePayroll,
    removePayroll,
  }
}

export default useQueryPayrolls
