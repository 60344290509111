import {
  checkmarkCircleOutline,
  arrowRedoOutline,
  pushOutline,
  arrowRedoCircleOutline,
  downloadOutline,
  // helpCircleOutline,
  reloadOutline,
} from 'ionicons/icons'

export const filterTrucksWithStatuses = (trucks = [], statuses = []) =>
  trucks.filter(truck => statuses.includes(truck.loadStatus))

// export const LOAD_STATUS_SECTIONS = ['TKT', 'ATP', 'TOD', 'ATD', 'RET', 'DCO']

export const loadSections = {
  to_seller: {
    header: {
      icon: arrowRedoOutline,
      color: 'medium',
      opacity: 0.7,
      colorHex: '#92949c',
    },
    statusName: 'Ticketed',
    code: 'TKT',
  },
  at_seller: {
    header: {
      icon: pushOutline,
      color: 'dark',
      colorHex: 'black',
    },
    statusName: 'At Plant',
    code: 'ATP',
  },
  to_buyer: {
    header: {
      icon: arrowRedoCircleOutline,
      color: 'secondary',
      colorHex: '#3dc2ff',
    },
    statusName: 'To Job',
    code: 'TOD',
  },
  at_buyer: {
    header: {
      icon: downloadOutline,
      color: 'primary',
      colorHex: '#3880ff',
    },
    statusName: 'Arrived Job',
    code: 'ATD',
  },
  returning: {
    header: {
      icon: reloadOutline,
      color: 'success',
      colorHex: '#2dd36f',
    },
    statusName: 'Returning',
    code: 'RET',
  },
  deliver_complete: {
    header: {
      icon: checkmarkCircleOutline,
      color: 'medium',
      colorHex: '#e5e5e5', //'#92949c',
    },
    statusName: 'Delivery Complete',
    code: 'DCO',
  },
}
