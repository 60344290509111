import { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { sessionService } from 'redux-react-session'
import { selectIsScopeDriver } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

const useFetchDriverProfile = () => {
  const isScopeDriver = useSelector(selectIsScopeDriver)

  const fetchDriverProfile = useCallback(async () => {
    // dispatch(getTrucksForDriver())
    const response = await apiClient.users.getAccountInfo()
    await sessionService.saveUser(response)
  }, [])

  useEffect(() => {
    if (isScopeDriver) {
      fetchDriverProfile()
    }
  }, [fetchDriverProfile, isScopeDriver])
}

export default useFetchDriverProfile
