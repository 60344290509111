import { useCallback, useState } from 'react'

import { ConcordFormLayout, EyeIcon, EyeOffIcon } from '~/components/shared'
import { Form } from 'react-bootstrap'

import clsx from 'clsx'

import './styles.scss'

function PasswordField(props) {
  const {
    onChange,
    value,
    label,
    isDisabled,
    loading,
    className,
    error,
    isRequired,
    extraIcons,
    hint,
    ...textFieldProps
  } = props

  const [isShowPw, setIsShowPw] = useState(false)

  const handleChange = useCallback(
    event => {
      const { value, name } = event.target
      onChange && onChange(event, { value, name })
    },
    [onChange],
  )

  const onToggleShowHide = useCallback(() => {
    setIsShowPw(prev => !prev)
  }, [])

  return (
    <ConcordFormLayout
      label={label}
      className={clsx(className, 'PasswordField__container')}
      error={error}
      isRequired={isRequired}
      extraIcons={extraIcons}
      hint={hint}
    >
      <div className='PasswordField__inputContainer'>
        <Form.Control
          value={value}
          disabled={loading}
          onChange={handleChange}
          error={error}
          required={isRequired}
          className={clsx({ isDisabled })}
          {...textFieldProps}
          type={isShowPw ? 'text' : 'password'}
        />
        <div
          className={clsx('eyeIcon icon', { isShowPw })}
          onClick={onToggleShowHide}
        >
          {isShowPw ? <EyeIcon /> : <EyeOffIcon />}
        </div>
      </div>
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

export default PasswordField
