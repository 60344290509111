import { CommonDialogV2 } from '~/components/shared'
import UserAccessForm from './UserAccessForm'

import './UserAccessForm.scss'

function DialogUserAccessForm(props) {
  const { formData, afterUpdate, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title="User Access"
      isHiddenOkButton
    >
      <UserAccessForm
        formData={formData}
        afterUpdate={afterUpdate}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogUserAccessForm
