import { RTTableHeadRow } from '../RTTableHeadRow'
import type {
  RTColumnVirtualizer,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { RTLinearProgressBar } from '../../toolbar/RTLinearProgressBar'

export interface IRTTableHeadProps<TData extends IReusableTableRowData> {
  columnVirtualizer?: RTColumnVirtualizer
  table: ReusableTableInstance<TData>
}

const RTTableHead = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  table,
  ...rest
}: IRTTableHeadProps<TData>) => {
  const {
    getState,
    options: { enableStickyHeader, layoutMode },
    refs: { tableHeadRef },
  } = table
  const { isFullScreen } = getState()

  const stickyHeader = enableStickyHeader || isFullScreen

  return (
    <thead
      {...rest}
      ref={(ref: HTMLTableSectionElement) => {
        tableHeadRef.current = ref
        if ((rest as any)?.ref) {
          // @ts-ignore
          rest.ref.current = ref
        }
      }}
      style={{
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        opacity: 0.97,
        position: stickyHeader ? 'sticky' : 'relative',
        top: stickyHeader && layoutMode?.startsWith('grid') ? 0 : undefined,
        zIndex: stickyHeader ? 2 : undefined,
        // ...(parseFromValuesOrFunc(tableHeadProps?.sx, theme) as any),
      }}
      className='RTTableHead__container'
      // sx={(theme) => ()}
    >
      <RTLinearProgressBar table={table} />
      {table.getHeaderGroups().map(headerGroup => (
        <RTTableHeadRow
          columnVirtualizer={columnVirtualizer}
          headerGroup={headerGroup as any}
          key={headerGroup.id}
          table={table}
        />
      ))}
    </thead>
  )
}

export default RTTableHead
