import { useCallback, useMemo } from 'react'
import { useQueryUsers } from '~/hooks/useQueryData'

import { IonIcon } from '@ionic/react'
import { ToolTipOverlay, CompanyInfo, IRTColumnDef } from '~/components/shared'

import { checkmarkCircle } from 'ionicons/icons'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { IEarningRate } from '~/types/models/IEarningRate'
import buildFullName from '~/utils/buildFullName'
import { EScope } from '~/types/enums/ECommonEnum'

export interface IUseTableEarningRatesProps {
  onDbClickStatus: (event: any, earningRate: IEarningRate) => void
  isEmployeeView: boolean
}

const useTableEarningRates = (props: IUseTableEarningRatesProps) => {
  const { onDbClickStatus, isEmployeeView } = props

  const { workerUsers } = useQueryUsers()

  const handleDbClickStatus = useCallback(
    (rowData: IEarningRate) => (event: any) => {
      onDbClickStatus(event, rowData)
    },
    [onDbClickStatus],
  )

  const columns = useMemo<IRTColumnDef<IEarningRate>[]>(
    () => [
      {
        header: 'Employee',
        accessorKey: 'employee',
        size: 250,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          const userWorker = workerUsers.find(
            ({ worker }) => worker?.checkUid === cellValue,
          )

          return (
            <CompanyInfo
              companyType={EScope.driver}
              defaultCompanyCode='Worker'
              hideSubtitle
              hideAnchor
              company={{
                name: buildFullName(userWorker?.person),
                label: buildFullName(userWorker?.person),
                code: 'Worker',
                value: userWorker?.id,
              }}
              searchableGoogle={false}
            />
          )
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 200,
        enableEditing: !isEmployeeView,
      },
      {
        header: 'Period',
        accessorKey: 'period',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          return formatCurrencyToDollar.format(cellValue)
        },
      },
      {
        header: 'Active',
        accessorKey: 'active',
        Cell: ({ cell, row }) => {
          const cellValue = cell.getValue<boolean>()
          return (
            <ToolTipOverlay
              content='Double click to change status'
              placement='top'
            >
              <span
                className='icon'
                style={{ fontSize: 16 }}
                onDoubleClick={handleDbClickStatus(row.original)}
              >
                <IonIcon
                  icon={checkmarkCircle}
                  color={cellValue ? 'success' : 'medium'}
                />
              </span>
            </ToolTipOverlay>
          )
        },
      },
    ],
    [handleDbClickStatus, isEmployeeView, workerUsers],
  )

  return { columns }
}

export default useTableEarningRates
