const es = {
  // Login Page
  'login.title': 'Iniciar Sesion',
  'login.signup': 'No tienes una cuenta? Crea una',

  'login.form.email': 'Email',
  'login.form.password': 'Contraseña',
  'login.form.submit': 'Continuar',

  'login.forgot_password': '¿Olvidó su contraseña?',
  'login.form.email.presence': 'Debe ingresar un email para continuar',
  'login.form.email.invalid': 'Debe ingresar un email válido',
  'login.form.password.presence': 'Debe ingresar una contraseña para continuar',

  // Sign up Page
  'signup.title': 'Registrate',
  'signup.login': 'Ya tienes una cuenta? Inicia sesion',
  'signup.client.title': 'Registrate como Cliente',
  'signup.driver.title': 'Registrate como Conductor',
  'signup.option.driver': 'Como Conductor',
  'signup.option.client': 'Como Cliente',
  'signup.signin': 'Iniciar Sesión',
  'signup.form.email': 'Email',
  'signup.form.password': 'Contraseña',
  'signup.form.passconfirmation': 'Confirmación de Contraseña',
  'signup.form.confirmation.presence':
    'Debe confirmar su contraseña para continuar',
  'signup.form.confirmation.equality':
    'Su confirmación de contraseña debe ser igual a la contraseña',
  'signup.form.firstName': 'Primer Nombre',
  'signup.form.middleName': 'Segundo Nombre',
  'signup.form.lastName': 'Apellido',
  'signup.form.phoneNumber': 'Número de Teléfono',
  'signup.form.color': 'Color',
  'signup.form.licenseNumber': 'Número de Licencia',
  'signup.form.licenseState': 'Estado de la licencia',
  'signup.form.licenseExpiration': 'Expiracion de la licencia',
  'signup.form.medicalNumber': 'Número médico',
  'signup.form.medicalExpiration': 'Fecha caducación Medica',
  'signup.form.mvrNumber': 'MVR Número',

  //  Driver SideMenu options
  'sidebar.home': 'Menu principal',
  'sidebar.driverProfile': 'Perfil del conductor',
  'sidebar.truckProfile': 'Perfil del camion',
  'sidebar.currentLoad': 'Carga Actual',
  'sidebar.previousLoad': 'Cargas Previas',
  'sidebar.invoices': 'Facturas',
  'sidebar.lastStatus': 'Ultimo estado',
  'sidebar.viewMap': 'Ver Mapa',
  'sidebar.order': 'Orden',
  'sidebar.tickets': 'Tickets',
  'sidebar.pastLoad': 'Past Loads',
  'sidebar.reminders': 'Order Reminders',
  'sidebar.orders': 'Orders',
  'sidebar.drivers': 'Drivers',
  'sidebar.trucks': 'Trucks',
  'sidebar.channels': 'Channels',
  'sidebar.fleetMap': 'Fleet Map',
  'sidebar.settings': 'Settings',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.accounts': 'Accounts',
  'sidebar.documentProcessing': 'Document Processing',
  'sidebar.adminDashboard': 'Admin Dashboard',
  'sidebar.reviewLoad': 'Review Loads',
  'sidebar.fleets': 'Fleets',
  'sidebar.products': 'Products',
  'sidebar.myCompany': 'My Company',
  'sidebar.barcode': 'Barcode',
  'sidebar.orderTracking': 'Order Tracking',

  'form.cancel': 'Cancel',
  'logout.button': 'Cerrar Sesion',

  'home.welcome': 'Bienvenido a React Redux Base {username}',

  'document.form.name': 'Name',
  'document.form.file': 'Select a file',
  'document.form.url': 'Add a Url',
  'document.form.seller': 'Seller',
  'document.form.optionalFileType': 'Select File Input Type',
  'document.form.type': 'Select document type',
  'document.form.upload': 'Upload',
  'document.form.buyerTerminal': 'Buyer Terminal',

  // Errors
  'email.presence': 'Debe ingresar un email para continuar',
  'email.invalid': 'Debe ingresar un email válido',
  'password.presence': 'Debe ingresar una contraseña para continuar',
  'passwordConfirmation.presence':
    'Debe confirmar su contraseña para continuar',
  'passwordConfirmation.equality':
    'Su confirmación de contraseña debe ser igual a la contraseña',
  'company.presence': 'Debes seleccionar una compañia',

  'document.name': "You must enter document's name",
  'document.file': 'You must select an image or pdf file',
  'document.seller': 'You must select a seller',

  'button.save': 'Save',

  'company.form.valid.name': 'You must enter Company name',
  'company.form.valid.legalName': 'You must enter Legal name',
  'company.form.valid.dateFormat': 'Invalid format',
  'company.form.name': 'Name',
  'company.form.legalName': 'Legal name',
  'company.form.image': 'Image',
  'company.form.buyer': 'Buyer',
  'company.form.seller': 'Seller',
  'company.form.fleet': 'Fleet',
  'company.form.status': 'Status',
  'company.form.parentCompany': 'Parent company',
  'company.form.invoiceInterval': 'Invoice interval',
  'company.form.dateFormat': 'Date format',
}

export default es
