import Immutable from 'immutable'

import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  schedules: [],
  endOfTheList: false,
  loading: undefined,
  loadingMore: undefined,
})

const schedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SCHEDULES: {
      let newState = state.set('loadingMore', true)
      newState = newState.set('endOfTheList', false)
      newState = newState.set('loading', true)

      return newState
    }
    case types.FETCH_SCHEDULES_SUCCESS: {
      const { schedules } = action
      let newState = state.set('loading', false)
      newState = newState.set('loadingMore', false)
      newState = newState.set('schedules', schedules)

      return newState
    }

    default: {
      return state
    }
  }
}

export default schedulesReducer
