const DEFAULT_COMPANY_EMAIL_PARSER_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Buyer',
    field: 'buyer.name',
    show: true,
    value: 'buyer.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'buyer.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Seller Regex',
    field: 'sellerRegex',
    show: true,
    value: 'sellerRegex',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'sellerRegex',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Buyer Regex',
    field: 'buyerRegex',
    show: true,
    value: 'buyerRegex',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'buyerRegex',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    label: 'Doc Type Regex',
    field: 'docTypeRegex',
    show: true,
    value: 'docTypeRegex',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 5,
    isAsc: true,
    sortField: 'docTypeRegex',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'DocType Value',
    field: 'docTypeValue',
    show: true,
    value: 'docTypeValue',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 6,
    isAsc: true,
    sortField: 'docTypeValue',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 7,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_EMAIL_PARSER_COLUMNS
