import _ from 'lodash'

const getLabelValueRangeFilter = value => {
  if (_.size(value) === 0) {
    return 'All'
  }
  const { lower, upper } = value
  if (
    (_.isNil(lower) && _.isNil(upper)) ||
    (_.toString(lower) === '' && _.toString(upper) === '')
  ) {
    return 'All'
  }
  if (_.isNil(lower) || _.toString(lower) === '') {
    return `-Infinity to ${upper}`
  }
  if (_.isNil(upper) || _.toString(upper) === '') {
    return `${lower} to Infinity`
  }

  return `${lower} - ${upper}`
}

export default getLabelValueRangeFilter
