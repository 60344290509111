import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import type {
  IGetScheduleLoadsParams,
  IScheduleLoad,
} from '~/types/models/IScheduleLoad'

const useModifyScheduleLoads = (params: IGetScheduleLoadsParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = [
    'scheduleLoads',
    sessionUser?.id,
    buildGetUrl(apiClient.scheduleLoads.endpoint, params),
  ]

  const addScheduleLoad = (schedule: IScheduleLoad) => {
    queryClient.setQueryData<IScheduleLoad[] | undefined>(queryKey, oldData => {
      if (oldData) {
        return [schedule, ...oldData]
      }

      return [schedule]
    })
  }

  const updateScheduleLoad = (id: number, item: Partial<IScheduleLoad>) => {
    queryClient.setQueryData<IScheduleLoad[] | undefined>(queryKey, oldData =>
      produce(oldData, draft => {
        if (draft) {
          const index = draft.findIndex(({ id }) => id === id)
          if (index !== -1) {
            draft[index] = {
              ...draft[index],
              ...item,
            }
          }
        }
      }),
    )
  }

  return {
    addScheduleLoad,
    updateScheduleLoad,
  }
}

export default useModifyScheduleLoads
