import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCompanyDateTimeFormat,
  selectCompanyTimeFormat,
} from '~/redux/selectors'
import { DATE_TIME_FORMAT } from '~/utils/constants'
import isSameYear from '~/utils/isSameYear'

const useDateTimeFormat = () => {
  const companyDateFormat = useSelector(selectCompanyDateTimeFormat)
  const companyTimeFormat = useSelector(selectCompanyTimeFormat)

  const getDateTimeFormat = useCallback(
    (date: string | Date | undefined, defaultFormat: string) => {
      if (!date) return ''
      if (isSameYear(date)) {
        let dateFormat = ''
        switch (companyDateFormat) {
          case DATE_TIME_FORMAT.MDY:
            dateFormat = 'MM-DD'
            break
          case DATE_TIME_FORMAT.YMD:
            dateFormat = 'MM-DD'
            break
          case DATE_TIME_FORMAT.DMY:
            dateFormat = 'DD-MM'
            break

          case DATE_TIME_FORMAT.firstWeekDayFormat:
            dateFormat = 'ddd, MMM-D'
            break

          case DATE_TIME_FORMAT.secondyWeekDayFormat:
            dateFormat = 'MMM-D (ddd)'
            break

          default:
            dateFormat = defaultFormat
            break
        }
        return moment(date).format(dateFormat)
      } else {
        if (companyDateFormat) {
          return moment(date).format(companyDateFormat)
        } else {
          return moment(date).format(defaultFormat)
        }
      }
    },
    [companyDateFormat],
  )

  const getDateTimeFormatFull = useCallback(
    (date: string | Date | undefined, defaultFormat: string) => {
      if (date) {
        return moment(date).format(companyDateFormat || defaultFormat)
      }
      return date
    },
    [companyDateFormat],
  )

  const getTimeFormat = useCallback(
    (time: string = moment().toISOString(), hasMillisecond = false) => {
      let formatType = 'H:mm'
      switch (companyTimeFormat) {
        case '24':
          formatType = `H:mm${hasMillisecond ? ':ss' : ''}`
          break
        case '12':
          formatType = `h:mm${hasMillisecond ? ':ss' : ''} a`
          break
      }
      return moment(time).format(formatType)
    },
    [companyTimeFormat],
  )

  const getTimeFormatType = useMemo(() => {
    let formatType = 'H:mm'
    switch (companyTimeFormat) {
      case '24':
        formatType = 'H:mm'
        break
      case '12':
        formatType = 'h:mm a'
        break
    }
    return formatType
  }, [companyTimeFormat])

  return {
    getDateTimeFormat,
    getTimeFormat,
    getTimeFormatType,
    companyDateFormat,
    getDateTimeFormatFull,
  }
}

export default useDateTimeFormat
