import { useState, useCallback } from 'react'
import useTableCompanyProductSubTab from './useTableCompanyProductSubTab'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  EditIcon,
  ReusableTable,
  DialogProductForm,
} from '~/components/shared'

import _ from 'lodash'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryProducts, useQueryProductTypes } from '~/hooks/useQueryData'

function CompanyProductSubTab() {
  const windowSize = useWindowSize()

  const {
    productsData,
    isLoadingProducts,
    updateProduct,
    removeProduct,
    addProduct,
  } = useQueryProducts()

  const { isProductTypesLoading } = useQueryProductTypes()

  const { confirmation } = useConfirmationProvider()

  const isTableLoading = isProductTypesLoading || isLoadingProducts

  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete product #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        try {
          const { errors } = await apiClient.products.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            removeProduct(rowData.id)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const handleEditCell = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const formValues = { [column.id]: value }
        const { errors, ...response } = await apiClient.products.update(
          row.original.id,
          formValues,
        )
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          updateProduct(response.id, response)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { columns } = useTableCompanyProductSubTab()

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableCompanyView
          enableRowActions
          companyViewProps={{
            name: moduleName.company.products,
            description: 'Manage the products you purchase.',
            onClickTopButton: () =>
              setFormState({
                isOpen: true,
                formData: undefined,
              }),
          }}
          columns={columns}
          data={productsData}
          tableHeight={windowSize.height - 220}
          state={{
            isLoading: isTableLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
              right: ['primary'],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 80,
            },
          }}
          onCellEditEnd={handleEditCell}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              tooltipProps: {
                placement: 'top',
                content: 'Edit',
              },
              onClick() {
                handleOpenUpdateForm(row.original)
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              color: 'danger',
              tooltipProps: {
                placement: 'top',
                content: 'Delete',
              },
              onClick() {
                handleDeleteRow(row.original)
              },
            },
          ]}
        />
      </div>
      <DialogProductForm
        isOpen={formState.isOpen}
        formData={formState.formData}
        onClose={onCloseForm}
        afterCreate={newProduct => {
          onCloseForm()
          addProduct(newProduct)
        }}
        afterUpdate={newProduct => {
          onCloseForm()
          updateProduct(newProduct.id, newProduct)
        }}
      />
    </>
  )
}

export default CompanyProductSubTab
