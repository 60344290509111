import { DROPDOWN_VISIBLE_DATA } from '~/utils/constants'

export const DEFAULT_PRIMARY_PARSING_STATUSES = [
  {
    value: 2,
    label: 'Done',
    style: {
      backgroundColor: '#d9ffd9',
      color: '#454545',
      '&:hover': {
        background: '#b3fbb3',
      },
    },
  },
  {
    value: 3,
    label: 'Updated',
    style: {
      backgroundColor: '#d7ecff',
      color: '#454545',
      '&:hover': {
        background: '#badeff',
      },
    },
  },
  {
    value: 4,
    label: 'No Collection',
    style: {
      backgroundColor: '#ffeca0',
      color: '#454545',
      '&:hover': {
        background: '#ffc240',
      },
    },
  },
  {
    value: 1,
    label: 'Waiting',
    style: {
      backgroundColor: '#D1D1D1',
      color: '#545454',
      '&:hover': {
        background: '#b7b7b7',
      },
    },
  },
  {
    value: 0,
    label: 'Not Sent',
    style: {
      background: '#ffd4e3',
      color: '#454545',
      '&:hover': {
        background: '#fdafca',
      },
    },
  },
  {
    value: 5,
    label: 'Error',
    style: {
      background: '#f45855',
      color: '#ffff',
      '&:hover': {
        background: '#ef5350',
      },
    },
  },
]

export const LOAD_SORT_FIELDS = [
  {
    label: 'ID',
    value: 'id',
    type: 'number',
    sorted: true,
    isAsc: true,
    sortField: 'id',
    isHidden: false,
  },
  {
    label: 'Num',
    value: 'num',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'num',
    isHidden: false,
  },
  {
    label: 'Qty',
    value: 'qty',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'qty',
    isHidden: false,
  },
  {
    label: 'Seller Name',
    value: 'seller_name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'seller_name',
    isHidden: false,
  },
  {
    label: 'Product Name',
    value: 'product_name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'product_name',
    isHidden: false,
  },
]

export const FLAG_FIELDS = [
  { label: 'All', value: null },
  { label: 'With Flags', value: true },
  { label: 'No Flags', value: false },
]

export const VISIBLE_DATA_FIELDS = [
  { label: 'Only Matched Data', value: DROPDOWN_VISIBLE_DATA.matchDataOnly },
  { label: 'Raw Data Only', value: DROPDOWN_VISIBLE_DATA.rawDataOnly },
  {
    label: 'Show Raw Data if No Match',
    value: DROPDOWN_VISIBLE_DATA.rawDataIfNoMatch,
  },
]

export const PER_PAGE_FIELDS = [
  { label: '12', value: 12 },
  { label: '24', value: 24 },
  { label: '36', value: 36 },
  { label: '48', value: 48 },
]

export const HITL_STATUS_FIELDS = [
  { label: 'Not Sent', value: 0 },
  { label: 'Waiting', value: 1 },
  { label: 'Done', value: 2 },
]
