import { useState, useCallback } from 'react'

import { Modal } from 'react-bootstrap'
import UseShowingTagModalContext from './UseShowingTagModalContext'
import OverlayCreateTag from '~/components/load/OverlayCreateTag'
import _ from 'lodash'

function UseShowingTagModalProvider(props) {
  const { children } = props

  const [showTagForm, setShowTagForm] = useState(false)
  const [createFormProps, setCreateFormProps] = useState({})

  const onToggleShowHideTagForm = useCallback((nextValue, formProps) => {
    setShowTagForm(nextValue)
    setCreateFormProps(formProps || {})
  }, [])

  const afterCreateTag = useCallback(
    createdTag => {
      setShowTagForm(false)
      createFormProps?.afterCreateTag &&
        createFormProps?.afterCreateTag(createdTag)
    },
    [createFormProps],
  )

  return (
    <>
      <UseShowingTagModalContext.Provider
        value={{
          showTagForm,
          onToggleShowHideTagForm,
        }}
      >
        {children}
      </UseShowingTagModalContext.Provider>
      <Modal show={showTagForm} onHide={() => setShowTagForm(false)}>
        <OverlayCreateTag
          onClose={() => setShowTagForm(false)}
          afterCreateTag={afterCreateTag}
          {..._.omit(createFormProps, 'afterCreateTag')}
        />
      </Modal>
    </>
  )
}

UseShowingTagModalProvider.propTypes = {}

export default UseShowingTagModalProvider
