import React, { useState } from 'react'
import { useRouter } from '~/hooks/useRouter'

import { Redirect } from 'react-router-dom'
import { IonBackButton } from '@ionic/react'

function BackButton({ component, onBack }) {
  const [renderBack, setRenderBack] = useState(false)
  const router = useRouter()

  // eslint-disable-next-line no-unused-vars
  const goBack = (event) => {
    event.stopPropagation()
    if (onBack) {
      onBack()
    } else if (component) {
      setRenderBack(true)
    } else {
      router.history.goBack()
    }
  }

  return (
    <span>
      <IonBackButton defaultHref='/' onClick={goBack} />
      {renderBack && component && <Redirect to={component} />}
    </span>
  )
}

BackButton.propTypes = {}

export default BackButton
