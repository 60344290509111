export enum EKeyStroke {
  shift = 'shift',
  meta = 'meta',
  ctrl = 'ctrl',
  metaAndCtrl = 'metaAndCtrl',
  alt = 'alt',
}

export interface IUseKeyStrokesKey {
  keyCode: number | string
  keystroke: EKeyStroke
  action: () => void
}
