import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const StripeIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill={color}
      viewBox='0 0 32 32'
      {...svgProps}
    >
      <path d='m8.25 10.435-2.165.46-.01 7.12c0 1.315.99 2.165 2.305 2.165.73 0 1.265-.135 1.56-.295v-1.69c-.285.115-1.685.525-1.685-.785v-3.16H9.94v-1.89H8.255zm4.455 2.58-.135-.655h-1.92v7.66h2.215v-5.155c.525-.69 1.41-.555 1.695-.465v-2.04c-.3-.105-1.335-.3-1.855.655zM17.32 9.4l-2.23.475v1.81l2.23-.475zM2.245 14.615c0-.345.29-.48.755-.485.675 0 1.535.205 2.21.57v-2.09a5.925 5.925 0 0 0-2.205-.405c-1.8 0-3 .94-3 2.51 0 2.46 3.375 2.06 3.375 3.12 0 .41-.355.545-.85.545-.735 0-1.685-.305-2.43-.71v2c.825.355 1.66.505 2.425.505 1.845 0 3.115-.79 3.115-2.39 0-2.645-3.395-2.17-3.395-3.17zM32 16.28c0-2.275-1.1-4.07-3.21-4.07s-3.395 1.795-3.395 4.055c0 2.675 1.515 3.91 3.675 3.91 1.06 0 1.855-.24 2.46-.575v-1.67c-.605.305-1.3.49-2.18.49-.865 0-1.625-.305-1.725-1.345h4.345c.01-.115.03-.58.03-.795zm-4.395-.84c0-1 .615-1.42 1.17-1.42.545 0 1.125.42 1.125 1.42zm-5.645-3.23c-.87 0-1.43.41-1.74.695l-.115-.55H18.15v10.24l2.22-.47.005-2.51c.32.235.795.56 1.57.56 1.59 0 3.04-1.16 3.04-3.98.005-2.58-1.465-3.985-3.025-3.985zm-.53 6.125c-.52 0-.83-.19-1.045-.42l-.015-3.3c.23-.255.55-.44 1.06-.44.81 0 1.37.91 1.37 2.07.005 1.195-.545 2.09-1.37 2.09zm-6.335 1.685h2.23v-7.66h-2.23z' />
    </svg>
  )
}

export default StripeIcon
