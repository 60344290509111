import { useContext } from 'react'

import UseShowingTagModalContext from './UseShowingTagModalContext'

const useShowingTagModal = () => {
  const context = useContext(UseShowingTagModalContext)

  return context
}

export default useShowingTagModal
