import { RTTablePagination } from '../../footer/RTTablePagination'
import { RTTableFooterActions } from '../../footer/RTTableFooterActions'

import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { getCommonToolbarStyles } from '../../../utils/style.utils'

export interface IRTBottomToolbarProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
  ref?: any
}

export const RTBottomToolbar = <TData extends IReusableTableRowData>({
  table,
}: IRTBottomToolbarProps<TData>) => {
  const {
    getState,
    options: { enablePagination, enableFooterActions },
    refs: { bottomToolbarRef },
  } = table
  const { isFullScreen } = getState()

  return (
    <div
      ref={(node: HTMLDivElement) => {
        if (node) {
          bottomToolbarRef.current = node
        }
      }}
      style={{
        ...getCommonToolbarStyles(),
        bottom: isFullScreen ? '0' : undefined,
        boxShadow: '0 1px 2px -1px #e5e5e5 inset',
        left: 0,
        position: isFullScreen ? 'fixed' : 'relative',
        right: 0,
        minHeight: 48,
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {enableFooterActions && <RTTableFooterActions table={table} />}
        {enablePagination && <RTTablePagination table={table} />}
      </div>
    </div>
  )
}
