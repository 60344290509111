import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { createSlice } from '@reduxjs/toolkit'

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {},
  reducers: {
    initializeTags: (state, action) => {
      action.payload.forEach(tag => (state[tag.id] = tag))
    },
    updateTags: (state, action) => {
      action.payload.forEach(tag => {
        state[tag.id] = tag
      })
    },
    updateTag: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
      }
    },
    deleteTag: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export default tagsSlice
