import { GoogleMap, RefreshIcon } from '~/components/shared'
import MapControl from '~/components/shared/GoogleMap/components/MapControl/MapControl'
import { Marker } from 'react-google-maps'
import { Polygon } from 'react-google-maps'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'

import seperateAddress from '~/utils/seperateAddress'

import './styles.scss'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getCoordinates } from '~/utils/utils'
import { useConfirmationProvider } from '~/contexts'
import isLocationInsideGeofence from '~/utils/isLocationInsideGeofence'
import _ from 'lodash'

export interface ITerminalMapProps {
  location?: {
    lat: number | string
    lng: number | string
  }
  coordinates?: {
    lat: number | string
    lng: number | string
  }[]
  geofence?: {
    latN: number | string
    latS: number | string
    longW: number | string
    longE: number | string
  }
  zoom?: number

  defaultLocation?: ITerminalMapProps['location']
  defaultCoordinates?: ITerminalMapProps['coordinates']
  defaultGeofence?: ITerminalMapProps['geofence']

  onChangeLocation?: (
    location: ITerminalMapProps['location'],
    address: {
      city: string
      country: string
      street: string
      postalCode: string
      state: string
    },
  ) => void

  onDrawGeofence?: (points: google.maps.LatLngLiteral[]) => void

  onRevert?: () => void
}

export type FuncIsTerminalInsideGeofenceParams = {
  markerPosition: google.maps.LatLng
  polyPath: google.maps.LatLngLiteral[]
}

function TerminalMap(props: ITerminalMapProps) {
  const {
    location,
    defaultCoordinates,
    defaultGeofence,
    defaultLocation,
    coordinates,
    geofence,
    zoom,
    onChangeLocation,
    onDrawGeofence,
    onRevert,
  } = props

  const [isDrawingGeofence, setIsDrawingGeofence] = useState(false)
  const [polygonPoints, setPolygonPoints] = useState<
    google.maps.LatLngLiteral[]
  >([])

  const { confirmation } = useConfirmationProvider()

  const isLocationsDiff = useMemo(
    () => (defaultLocation ? !_.isEqual(location, defaultLocation) : false),
    [defaultLocation, location],
  )

  const isCoordinatesDiff = useMemo(
    () =>
      defaultCoordinates ? !_.isEqual(coordinates, defaultCoordinates) : false,
    [defaultCoordinates, coordinates],
  )
  const isGeofenceDiff = useMemo(
    () => (defaultGeofence ? !_.isEqual(geofence, defaultGeofence) : false),
    [defaultGeofence, geofence],
  )

  const showRevertButton =
    isLocationsDiff || isCoordinatesDiff || isGeofenceDiff

  const markerLocation = useMemo(
    () =>
      new google.maps.LatLng(
        Number(location?.lat || 0),
        Number(location?.lng || 0),
      ),
    [location?.lat, location?.lng],
  )

  const showWarningMarkerGenfence = useCallback(async () => {
    await confirmation({
      message:
        'This marker is not inside the current geofence, please create a new geofence by dragging and drawing it',
      buttons: [
        {
          text: 'Ok',
          action: 'Yes',
        },
      ],
    })
  }, [confirmation])

  const isTerminalInsideGeofence = useCallback(
    (params: Partial<FuncIsTerminalInsideGeofenceParams>) => {
      const markerPosition = params.markerPosition || markerLocation
      const polyPath = params.polyPath || polygonPoints

      if (markerPosition) {
        return isLocationInsideGeofence(markerPosition, polyPath)
      }
      return false
    },
    [markerLocation, polygonPoints],
  )

  const onMoveMarker = async (event: any) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    const location = {
      lat,
      lng,
    }
    const API_KEY = 'AIzaSyD8vF1Sm2An_LVf_oUlafdzshwtWDjYHv0'
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`

    const response = await fetch(url)
    const data = await response.json()
    const address = seperateAddress(data.results[0].address_components)
    onChangeLocation && onChangeLocation(location, address)

    if (!isTerminalInsideGeofence({ markerPosition: location as any })) {
      await showWarningMarkerGenfence()
    }
  }

  const onDrawComplete = async (polygon: google.maps.Polygon) => {
    const points = polygon
      .getPath()
      .getArray()
      .map(point => point.toJSON())
    setPolygonPoints(points)
    onDrawGeofence && onDrawGeofence(points)
    setIsDrawingGeofence(false)
    ;(polygon as any).visible = false

    if (!isTerminalInsideGeofence({ polyPath: points })) {
      await showWarningMarkerGenfence()
    }
  }

  const bounding = useMemo(() => {
    const bounds = coordinates?.map(coord => ({
      lat: parseFloat(coord.lat as any),
      lng: parseFloat(coord.lng as any),
    }))

    return bounds
  }, [coordinates])

  useEffect(() => {
    const result = getCoordinates(coordinates, geofence)
    setPolygonPoints(result)
  }, [coordinates, geofence])

  return (
    <GoogleMap
      defaultCenter={markerLocation as any}
      zoom={zoom || 15}
      onClick={onMoveMarker}
      bounding={bounding as any}
    >
      {location && (
        <>
          <Marker
            position={markerLocation as any}
            draggable
            onDragEnd={onMoveMarker}
          />
          <MapControl position={google.maps.ControlPosition.TOP_CENTER}>
            <ButtonGroup>
              {isDrawingGeofence ? (
                <Button
                  style={{ fontSize: 13 }}
                  variant='danger'
                  onClick={() => {
                    setIsDrawingGeofence(false)
                    const result = getCoordinates(coordinates, geofence)
                    setPolygonPoints(result)
                  }}
                >
                  Close
                </Button>
              ) : (
                <Button
                  style={{ fontSize: 13 }}
                  onClick={() => {
                    setIsDrawingGeofence(true)
                    setPolygonPoints([])
                  }}
                >
                  Draw Geofence
                </Button>
              )}
              {showRevertButton && (
                <Button
                  style={{ fontSize: 13 }}
                  variant='success'
                  onClick={onRevert}
                >
                  <RefreshIcon color='white' />
                </Button>
              )}
            </ButtonGroup>
          </MapControl>
        </>
      )}

      {isDrawingGeofence ? (
        <DrawingManager
          drawingMode={google.maps.drawing.OverlayType.POLYGON}
          defaultOptions={{
            drawingControl: false,
            polygonOptions: {
              clickable: true,
              editable: true,
              zIndex: 1,
            },
          }}
          onPolygonComplete={onDrawComplete}
        />
      ) : (
        <Polygon
          path={polygonPoints}
          editable={false}
          draggable={false}
          options={{
            strokeColor: '#1090ff',
            fillColor: '#1090ff',
          }}
        />
      )}
    </GoogleMap>
  )
}

export default TerminalMap
