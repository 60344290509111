import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { ICreateGroupPayload, IUpdateGroupPayload } from '~/types/models/IGroup'
import { IGroupFormProps, IGroupFormValues } from './type'
import useQueryGroups from '~/hooks/useQueryData/useQueryGroups/useQueryGroups'
import { EFieldType } from '~/types/enums/ECommonEnum'

const GroupForm = (props: IGroupFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { groupOptions } = useQueryGroups()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'name',
        label: 'Name',
        size: 6,
        isRequired: true,
        type: EFieldType.text,
      },
      {
        name: 'code',
        label: 'Code',
        isRequired: true,
        type: EFieldType.text,
        size: 6,
      },
      {
        name: 'color',
        label: 'Color',
        size: 6,
        type: EFieldType.color,
      },
      {
        name: 'groupId',
        label: 'Parent Group',
        size: 6,
        type: EFieldType.singleSelect,
        options: groupOptions,
      },
    ],
    [groupOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Name is required'),
        code: Yup.string().required('Code is required'),
        groupId: Yup.number(),
      }),
    [],
  )

  const defaultValues = useMemo<IGroupFormValues>(() => ({}), [])

  const onCreate = useCallback(
    async (payload: ICreateGroupPayload) => {
      const { errors, ...res } = await apiClient.groups.create(payload)
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: IUpdateGroupPayload) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.groups.update(
          formData.id,
          payload,
        )

        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IGroupFormValues) => {
      setIsLoading(true)
      setError('')

      try {
        if (isUpdating) {
          await onUpdate(formValues as IUpdateGroupPayload)
        } else {
          await onCreate(formValues as ICreateGroupPayload)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default GroupForm
