import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMeasure } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import { IonRow, IonCol, IonCard, IonCheckbox } from '@ionic/react'
import { dollar, toFixedWithLocaleString } from '~/utils/utils'
import {
  CheckMarkIcon,
  ListSearchIcon,
  SelectSearchDropdown,
  TooltipIcon,
  ToolTipOverlay,
} from '~/components/shared'
import DatePicker from 'react-datepicker'
import { ActionsCol, CostCodeCol, IndexCol, SelectBillLineCol } from './Columns'
import ContextMenu from '~/components/shared/RightClickMenu/ContextMenu.js'
import {
  selectBuyerTerminals,
  selectBuyerSellerProductOptions,
  selectCommonTags,
} from '~/redux/selectors'
import {
  handleDisplayTagColor,
  handleReturnColors,
  handleColorSplit,
} from '~/utils/utils'
import { DateTime } from 'luxon'
import { renderFlags, returnMatchedFieldsFromEnums } from './helpers'
import EditableTableRowDropMenu from './DropMenu'
// import { toast } from 'react-toastify'
// import { toastMessages } from '~/constants/toast-status-text'
import clsx from 'clsx'
import moment from 'moment'
import _ from 'lodash'

import './styles.scss'
// import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

const addButtonTitle = '+ Add'

/**
 * EditableRow
 * @function
 * @returns {React.Component} - An editable IonRow
 */

const EditableTableRow = ({
  parentBillLine,
  billLine,
  children,
  className,
  onConfirmValue,
  onCancelValue,
  rowIndex,
  currentCellValue,
  currentCell,
  setCurrentCell,
  setCurrentCellValue,
  onDelete,
  onShowDetail,
  showFlags = true,
  showAllColumn = false,
  matchedFields,
  isShortenButtons,
  isSelecting,
  selected,
  onSelect,
  hasUseButton = false,
  onUseBillLine,
  isCompareLine,
  inverted,
  isResolved,
  onShowBillLineFlag,
  billLinesColumns,
  onBillLineHover,
  highLightBillLine,
  onCellHover,
  cellHovered,
  onUnMatchedClick,
  showLeftMargin,
  hideContextMenu = false,
  costCodeValues,
  productGroupsList,
  // setFetchedBillLines,
  // initialBillLinesData,
  // setModalMessage,
  // setRefTerminalModal,
  boldImportantCols = false,
  setNewLineItem = null,
  datePickerProps,
}) => {
  const { billLineMatchingFields } = useSelector(
    state => state.session.user.company,
  )
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [xPos, setXPos] = useState()
  const [yPos, setYPos] = useState()

  //const [productElementRef, { width: productDropdownWidth }] = useMeasure()
  const [costcodeElementRef, { width: costCodeDropdownWidth }] = useMeasure()

  const { confirmation } = useConfirmationProvider()

  const [delayHandler, setDelayHandler] = useState(null)
  const [, setDelayCellHandler] = useState(null)
  const btRedux = useSelector(selectBuyerTerminals)
  const buyerSellerProductValues = useSelector(selectBuyerSellerProductOptions)
  const [buyerTerminals, setBuyerTerminals] = useState([])
  const companyBillLineMatchingFields = useMemo(
    () =>
      billLineMatchingFields.length > 0
        ? returnMatchedFieldsFromEnums(billLineMatchingFields)
        : returnMatchedFieldsFromEnums([0, 6]),
    [billLineMatchingFields],
  )
  const tags = useSelector(selectCommonTags)
  const tagColors = useMemo(
    () => handleDisplayTagColor(tags, billLine),
    [tags, billLine],
  )

  useEffect(() => {
    const valuesAndLables = btRedux.map(terminal => {
      return { value: terminal.id, label: terminal.name, code: terminal.code }
    })

    setBuyerTerminals(valuesAndLables)
  }, [btRedux])

  // /**
  //  * @param {date} - The date string
  //  */

  // const formatDate = (date) =>
  //   Date.parse(date) &&
  //   new Date(date).toLocaleDateString('zh-Hans-CN', {
  //     timeZone: 'UTC',
  //     month: '2-digit',
  //     day: '2-digit',
  //     year: 'numeric'
  //   });

  /**
   * @param {colIndex} - The cell column index
   * @param {rowIndex} - The cell row index
   */

  const onShowEdit =
    ({ colIndex, rowIndex }) =>
    ({ target: { children, firstElementChild, innerHTML } }) => {
      if (currentCell.row !== null) return

      const key = billLinesColumns[colIndex].field

      if (!billLinesColumns[colIndex].editable) return
      if (
        billLinesColumns[colIndex].field == 'buyerTerminalId' ||
        billLinesColumns[colIndex].field == 'costCodeId' ||
        billLinesColumns[colIndex].field == 'buyerSellerProductId'
      )
        return

      const value = children.length
        ? firstElementChild.innerHTML
        : innerHTML === addButtonTitle
        ? ''
        : innerHTML

      const isString = key === 'ticketNum' || key === 'buyerTerminal'

      setCurrentCell({ colIndex, rowIndex })
      setCurrentCellValue(isString ? value : value.replace(/[^0-9.]/g, ''))
    }

  /**
   * @param {Event} - Cell keydown event
   */

  const onKeyDownCellValue = ({ which }) => {
    if (which === 13) {
      onConfirmValue()
    }

    if (which === 27) {
      onCancelValue()
    }
  }

  /**
   * @param {Event} - Cell change event
   */

  const onChangeCellValue = ({ target: { value } }) => {
    if (value === currentCellValue) return

    setCurrentCellValue(value)
  }

  /**
   * @param {React.Component} - "Add" button component
   */

  const BillLineAddButton = ({ colIndex, rowIndex, field }) => {
    return (
      <button
        title='Click to add data'
        style={{
          background: 'transparent',
          color: 'red',
          textAlign:
            colIndex === 2 || field == 'productName' ? 'left' : 'right',
        }}
        onClick={onShowEdit({ colIndex, rowIndex })}
        className='BillLinesAddButton'
      >
        {addButtonTitle}
      </button>
    )
  }

  const onChangeDate =
    ({ rowIndex, colIndex }) =>
    value => {
      setCurrentCell({ rowIndex, colIndex })
      setCurrentCellValue(value)
      onConfirmValue()
    }

  const onChangeDropdown = useCallback(
    ({ rowIndex, colIndex }) =>
      ({ value }) => {
        setCurrentCell({ rowIndex, colIndex })
        setCurrentCellValue(value)
        onConfirmValue()
      },
    [onConfirmValue, setCurrentCell, setCurrentCellValue],
  )

  const onDeleteHandler = useCallback(() => {
    const isCompareLineSelected = inverted ? !isCompareLine : isCompareLine
    onDelete && onDelete(billLine, isCompareLineSelected)
  }, [billLine, onDelete, isCompareLine, inverted])

  const onDeleteBillLineClick = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to delete this bill line?',
    })

    if (result === EYesNo.Yes) {
      onDeleteHandler()
    }
  }, [onDeleteHandler, confirmation])

  const onSelectBillLine = useCallback(() => {
    onSelect && onSelect(billLine)
  }, [billLine, onSelect])

  const onUseBillLineClick = useCallback(() => {
    const isCompareLineSelected = inverted ? !isCompareLine : isCompareLine
    const bilLineSelected = inverted ? { ...billLine, matchedFields } : billLine
    onUseBillLine &&
      onUseBillLine(bilLineSelected, isCompareLineSelected, matchedFields)
  }, [billLine, onUseBillLine, isCompareLine, matchedFields, inverted])

  const onMouseEnterBillLine = useCallback(() => {
    setDelayHandler(
      setTimeout(() => {
        onBillLineHover && onBillLineHover(billLine)
      }, 500),
    )
  }, [onBillLineHover, billLine, setDelayHandler])

  const onMouseLeaveBillLine = useCallback(() => {
    clearTimeout(delayHandler)
    if (!highLightBillLine) onBillLineHover && onBillLineHover(null)
  }, [delayHandler, onBillLineHover, highLightBillLine])

  const onMouseEnterCell = fieldCell => () => {
    if (!isShortenButtons) return
    setDelayCellHandler(
      setTimeout(() => {
        onCellHover && onCellHover(fieldCell)
      }, 500),
    )
  }

  const onMouseLeaveCell = () => {
    if (!cellHovered) onCellHover && onCellHover(null)
  }

  const getBuyerTerminalTooltip = useCallback(
    fieldValue => {
      return buyerTerminals.find(terminal => terminal.value == fieldValue)
        ?.label
    },
    [buyerTerminals],
  )

  const getBuyerTerminalLabel = useCallback(
    fieldValue =>
      buyerTerminals.find(terminal => terminal.value == fieldValue)?.code,
    [buyerTerminals],
  )

  const getCostCodeLabel = useCallback(
    fieldValue =>
      costCodeValues.find(({ value }) => fieldValue == value)?.fullCode,
    [costCodeValues],
  )

  const getCostCodeTooltip = useCallback(
    fieldValue =>
      costCodeValues.find(({ value }) => fieldValue == value)?.label,
    [costCodeValues],
  )

  const getBuyerSellerProductName = useCallback(
    fieldValue => {
      return buyerSellerProductValues.find(({ value }) => fieldValue == value)
        ?.label
    },
    [buyerSellerProductValues],
  )

  const handleRightClickRow = useCallback(
    event => {
      if (!hideContextMenu) {
        event.preventDefault()
        const bounding = event.target.getBoundingClientRect()
        const xPosition = bounding.x || event.pageX
        const yPosition = bounding.bottom
          ? bounding.bottom + 8
          : event.pageY + 10

        setXPos(xPosition)
        setYPos(yPosition)
        setShowContextMenu(prev => !prev)
      }
    },
    [hideContextMenu],
  )

  // const updateCurrentBillLine = useCallback(
  //   data => {
  //     const billLineData = {
  //       buyerSellerProductId: data.value,
  //     }

  //     apiClient.billLines
  //       .update(billLine.id, billLineData)
  //       .then(res => {
  //         const billLinesCopy = JSON.parse(JSON.stringify(initialBillLinesData))
  //         const lineIndex = billLinesCopy
  //           .map(line => line.id)
  //           .indexOf(billLine.id)
  //         setRefTerminalModal(true)

  //         // const bsp = buyerSellerProductValues.find(
  //         //   (x) => x.value == res.billLine.buyerSellerProductId
  //         // );

  //         setModalMessage({
  //           field: 'Buyer Seller Product',
  //           selected_name: '',
  //           selected_code: '', //need to add the product code in the future
  //           selectedId: res.billLine.buyerSellerProductId,
  //           currentLabel: res.billLine.productName,
  //           billLine: res.billLine,
  //         })
  //         billLinesCopy.splice(lineIndex, 1, res.billLine)

  //         setFetchedBillLines(billLinesCopy)

  //         toast.success(toastMessages.updateSuccess)
  //       })
  //       .catch(err => {
  //         console.log(err)

  //         toast.error(toastMessages.updateError)
  //       })
  //   },
  //   [
  //     billLine,
  //     initialBillLinesData,
  //     setFetchedBillLines,
  //     setRefTerminalModal,
  //     setModalMessage,
  //     // buyerSellerProductValues
  //   ],
  // )

  return (
    <>
      <ContextMenu
        show={showContextMenu}
        menu={
          <EditableTableRowDropMenu
            billLine={billLine}
            onShowDetail={onShowDetail}
            onShowBillLineDetail={onShowDetail}
            onDeleteBillLineClick={onDeleteBillLineClick}
            hasUseButton={hasUseButton}
            onUseBillLineClick={onUseBillLineClick}
            matchedFields={matchedFields}
            showContextMenu={showContextMenu}
            isResolved={isResolved}
            onMouseEnterCell={onMouseEnterCell}
            onMouseLeaveCell={onMouseLeaveCell}
            setNewLineItem={setNewLineItem}
          />
        }
        setShow={setShowContextMenu}
        x={xPos}
        y={yPos}
      />

      <IonRow
        onMouseEnter={onMouseEnterBillLine}
        onMouseLeave={onMouseLeaveBillLine}
        className={clsx('EditableIonRow', className)}
        onContextMenu={handleRightClickRow}
      >
        <IonCard
          role='row'
          style={{
            height: 30,
            borderLeft:
              _.size(tagColors) > 0 ? handleReturnColors(tagColors) : '',
            borderImage: _.size(tagColors) > 1 && handleColorSplit(tagColors),
            alignItems: 'center',
            justifyContent: showAllColumn ? '' : 'flex-start',
            backgroundColor: highLightBillLine ? 'rgb(220, 229, 236)' : '',
          }}
        >
          {isSelecting && (
            <IonCol className='checkbox-col'>
              <IonCheckbox checked={selected} onClick={onSelectBillLine} />
            </IonCol>
          )}
          {billLine.matchingScore && (
            <ToolTipOverlay placement='right' content={'Similarity'}>
              <IonCol className='percentage-col'>
                {`${billLine.matchingScore * 100}%`}
              </IonCol>
            </ToolTipOverlay>
          )}
          {billLine.proximityScore && (
            <ToolTipOverlay placement='right' content={'Proximity'}>
              <IonCol className='percentage-col'>
                {`${billLine.proximityScore.toFixed(2) * 100}%`}
              </IonCol>
            </ToolTipOverlay>
          )}
          {billLinesColumns &&
            billLinesColumns.map(
              (
                {
                  field,
                  hidden,
                  dataClass,
                  isDate,
                  isCostCode,
                  isBuyerSellerProduct,
                  isTerminal,
                  editable,
                  addButton,
                  size,
                },
                colIndex,
              ) => {
                const highLightCell =
                  highLightBillLine && cellHovered && cellHovered === field

                if (hidden) return null

                if (field === 'matchIcon') {
                  const MatchIcon = billLine.billLineId
                    ? CheckMarkIcon
                    : ListSearchIcon

                  return (
                    <IonCol key={field}>
                      <ToolTipOverlay
                        placement='top'
                        content={
                          billLine.billLineId ||
                          (billLine.loadId && billLine.invoiceType == 'Invoice')
                            ? 'Matched'
                            : 'Unmatched'
                        }
                      >
                        <div
                          onClick={() => {
                            onUnMatchedClick && onUnMatchedClick(billLine)
                          }}
                          className={clsx('EditableTableRow__matchedIcon', {
                            unmatch:
                              !billLine.billLineId &&
                              !(
                                billLine.loadId &&
                                billLine.invoiceType == 'Invoice'
                              ),
                          })}
                        >
                          <MatchIcon />
                        </div>
                      </ToolTipOverlay>
                    </IonCol>
                  )
                }

                if (field === 'flags') {
                  const errors = renderFlags(billLine)

                  return (
                    <IonCol key={field}>
                      {errors && (
                        <TooltipIcon
                          iconStyle={{ color: 'red' }}
                          toolTip={errors}
                          onShowBillLineFlagModal={() => {
                            onShowBillLineFlag && onShowBillLineFlag(billLine)
                          }}
                          billLine={billLine}
                          containerStyle={{ marginTop: 2 }}
                        />
                      )}
                    </IonCol>
                  )
                }

                if (field === 'index') {
                  const className = clsx('hide-scroll-y', {
                    'edit-col-margin': showLeftMargin,
                    dataClass: !showLeftMargin,
                  })

                  return (
                    <IndexCol
                      key={colIndex}
                      index={rowIndex}
                      showFlags={showFlags}
                      billLine={billLine}
                      className={className}
                      shouldDisplayIndex
                      onShowBillLineDetail={onShowDetail}
                      onShowBillLineFlagModal={onShowBillLineFlag}
                      onMouseEnterCell={onMouseEnterCell}
                      onMouseLeaveCell={onMouseLeaveCell}
                      onUnMatchedClick={onUnMatchedClick}
                      hideFlagIcon
                      hideMatchedIcon
                    />
                  )
                }

                if (field === 'actions')
                  return (
                    <ActionsCol
                      key={colIndex}
                      billLine={billLine}
                      className={`${dataClass} ${
                        showAllColumn && 'wrap-items'
                      }`}
                      isShortenButtons={isShortenButtons}
                      onShowDetail={onShowDetail}
                      onShowBillLineDetail={onShowDetail}
                      onDeleteBillLineClick={onDeleteBillLineClick}
                      hasUseButton={hasUseButton}
                      onUseBillLineClick={onUseBillLineClick}
                      matchedFields={matchedFields}
                      isResolved={isResolved}
                      onMouseEnterCell={onMouseEnterCell}
                      onMouseLeaveCell={onMouseLeaveCell}
                    />
                  )

                if (field === 'selectBillLine')
                  return (
                    <SelectBillLineCol
                      parentBillLine={parentBillLine}
                      key={colIndex}
                      rowIndex={rowIndex}
                      billLine={billLine}
                      associateBillLine={true}
                      className={`${dataClass} ${
                        showAllColumn && 'wrap-items'
                      }`}
                    />
                  )

                const isEditing =
                  currentCell.colIndex === colIndex &&
                  currentCell.rowIndex === rowIndex
                const fieldValue = billLine[field]

                let date = null
                let maxDate = null
                if (isDate) {
                  date = fieldValue && DateTime.fromISO(fieldValue).toJSDate()
                  maxDate = moment().add({ years: 3 }).toDate()
                }

                const tooltipDate = date
                  ? moment(date).format('ddd, MMM DD, YYYY')
                  : ''

                const isAddButton =
                  field == 'qty'
                    ? !isEditing && !fieldValue && fieldValue != 0 && addButton
                    : !isEditing && !fieldValue && addButton
                const matchedField =
                  matchedFields &&
                  matchedFields.find(mField => mField.field === field)

                const roundedDecimals = () => {
                  if (colIndex > 4 && colIndex < 9) {
                    return dollar.format(fieldValue)
                  } else if (colIndex == 4) {
                    return toFixedWithLocaleString(fieldValue, 2)
                  } else if (colIndex == 10) {
                    return
                  } else {
                    return fieldValue ? fieldValue : '-'
                  }
                }

                const colClassNames = clsx(
                  dataClass,
                  'EditableIonRow__cell',
                  'hide-scroll-y',
                  {
                    'unmatched-col': matchedField && !matchedField.matched,
                    'matched-col': matchedField && matchedField.matched,
                    'edit-col-cursor': editable,
                    highLightCell,
                  },
                )

                const matchesField =
                  companyBillLineMatchingFields.includes(field) &&
                  boldImportantCols
                const boldColumnClass = matchesField ? 'matched-field-col' : ''
                const hasUnmatchedFields = matchedField && !matchedField.matched

                return (
                  <IonCol
                    key={colIndex}
                    onClick={
                      !isResolved
                        ? onShowEdit({ colIndex, rowIndex })
                        : undefined
                    }
                    style={{
                      display: isBuyerSellerProduct ? 'block' : 'flex',
                      overflow: isDate ? 'visible' : 'hidden',
                    }}
                    className={`${colClassNames} ${boldColumnClass}`}
                    onMouseEnter={onMouseEnterCell(field)}
                    onMouseLeave={onMouseLeaveCell()}
                    sizeSm={size || ''}
                  >
                    {!isResolved && isAddButton && !isBuyerSellerProduct && (
                      <BillLineAddButton
                        colIndex={colIndex}
                        rowIndex={rowIndex}
                        field={field}
                      />
                    )}
                    <div style={editable ? { cursor: 'pointer' } : {}}>
                      {!isAddButton &&
                        !isEditing &&
                        !isDate &&
                        !isCostCode &&
                        !isBuyerSellerProduct &&
                        !isTerminal &&
                        roundedDecimals()}
                    </div>
                    {!isResolved &&
                      isEditing &&
                      !isDate &&
                      !isCostCode &&
                      !isBuyerSellerProduct &&
                      !isTerminal && (
                        <input
                          value={currentCellValue}
                          onChange={onChangeCellValue}
                          onBlur={onCancelValue}
                          onKeyDown={onKeyDownCellValue}
                          autoFocus
                          className={`BillLinesInput ${field}`}
                        />
                      )}
                    {isDate && !hidden && (
                      <ToolTipOverlay content={tooltipDate} placement='top'>
                        <div style={{ cursor: 'pointer', overflow: 'visible' }}>
                          <DatePicker
                            selected={date}
                            dateFormat='M/dd'
                            disabled={isResolved}
                            maxDate={maxDate}
                            className='EditableTableRow__datePicker'
                            onChange={onChangeDate({ rowIndex, colIndex })}
                            {...datePickerProps}
                          />
                        </div>
                      </ToolTipOverlay>
                    )}
                    {isBuyerSellerProduct &&
                      !hidden &&
                      buyerSellerProductValues && (
                        <>
                          <div>buyer seller product</div>
                        </>
                      )}
                    {isCostCode && !hidden && costCodeValues && (
                      <CostCodeCol
                        billLine={billLine}
                        onChangeDropdown={onChangeDropdown}
                        rowIndex={rowIndex}
                        buyerTerminals={buyerTerminals}
                        getBuyerTerminalName={getBuyerTerminalTooltip}
                        buyerSellerProducts={buyerSellerProductValues}
                        getBuyerSellerProductName={getBuyerSellerProductName}
                        productGroupsList={productGroupsList}
                      >
                        <SelectSearchDropdown
                          modalSize={costCodeDropdownWidth}
                          value={costCodeValues}
                          currentValue={currentCellValue}
                          onChange={onChangeDropdown({ rowIndex, colIndex })}
                          subComponent={
                            <ToolTipOverlay
                              content={getCostCodeTooltip(fieldValue)}
                              allowToShow={getCostCodeLabel(fieldValue)}
                            >
                              <div
                                className={clsx({ editable })}
                                style={{ width: '100%' }}
                                ref={costcodeElementRef}
                              >
                                {getCostCodeLabel(fieldValue) || '-'}
                              </div>
                            </ToolTipOverlay>
                          }
                        />
                      </CostCodeCol>
                    )}
                    {isTerminal && !hidden && (
                      <SelectSearchDropdown
                        modalSize='auto'
                        value={buyerTerminals}
                        currentValue={currentCellValue}
                        onChange={onChangeDropdown({ rowIndex, colIndex })}
                        subComponent={
                          <ToolTipOverlay
                            content={
                              getBuyerTerminalTooltip(fieldValue) ||
                              'No buyer terminal name, click to set'
                            }
                          >
                            <div
                              className={clsx({ editable })}
                              style={{
                                color: hasUnmatchedFields ? 'red' : '',
                                fontWeight: matchesField ? 800 : 400,
                              }}
                            >
                              {getBuyerTerminalLabel(fieldValue) || '-'}
                            </div>
                          </ToolTipOverlay>
                        }
                      />
                    )}
                  </IonCol>
                )
              },
            )}
        </IonCard>
        {children}
      </IonRow>
    </>
  )
}

export default EditableTableRow
