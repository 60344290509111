import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
import { IGetCostCodesParams } from '~/types/models/ICostCode'
import { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCostCodes = (params: IGetCostCodesParams) => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['costCodes', sessionUser.id],
    async queryFn() {
      const response = await apiClient.costCodes.get(params)
      return response
    },
    enabled: Boolean(sessionUser.id),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const costCodesData = data?.costCodes || []

  const costCodeOptions = costCodesData.map(({ id, fullCode }) => ({
    label: fullCode,
    value: id,
  }))

  return {
    costCodesData,
    isCostCodesLoading: isLoading,
    costCodeOptions,
  }
}

export default useQueryCostCodes
