import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { initializeTrucks } from '~/redux/actions/trucksActions'
import { initializeTruckFleets } from '~/redux/actions/truckFleetsActions'
import { apiClient } from '~/api/ApiClient'

export const useTrucks = () => {
  const dispatch = useDispatch()

  const fetchTrucks = useCallback(async () => {
    try {
      const response = await apiClient.trucks.get()
      dispatch(initializeTrucks(response.trucks))
    } catch (error) {
      console.log(error)
    }
  }, [dispatch])

  const fetchTruckFleets = useCallback(async () => {
    try {
      const response = await apiClient.truckFleets.get()
      dispatch(initializeTruckFleets(response.truckFleets))
    } catch (error) {
      console.log(error)
    }
  }, [dispatch])

  return { fetchTrucks, fetchTruckFleets }
}
