import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBreadcrumbLoads from './useModifyBreadcrumbLoads'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBreadcrumbLoads = (loadId: number) => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const { data = [], isLoading } = useQuery({
    queryKey: ['breadcrumbLoads', sessionUser.id, loadId],
    async queryFn() {
      const response = await apiClient.breadcrumbLoads.get(loadId)
      return response.breadcrumbLoads
    },
    enabled: Boolean(sessionUser.id && loadId),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const { addBreadcrumbLoad } = useModifyBreadcrumbLoads(loadId)

  const breadcrumbLoadsData = useMemo(() => data || [], [data])

  return {
    breadcrumbLoadsData,
    isLoadingBreadcrumbLoadsData: isLoading,
    addBreadcrumbLoad,
  }
}

export default useQueryBreadcrumbLoads
