import clsx from 'clsx'
import { IReusableTableRowData, ReusableTableInstance } from '../../../types'
import './styles.scss'
import { useMemo } from 'react'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  DropdownWithCustomChildren,
  ArrowDropdownIcon,
} from '~/components/shared'
import { Button } from 'react-bootstrap'

export interface IRTTableFooterActionButtonsProps<
  TData extends IReusableTableRowData,
> {
  table: ReusableTableInstance<TData>
  className?: string
}

const RTTableFooterActionButtons = <TData extends IReusableTableRowData>(
  props: IRTTableFooterActionButtonsProps<TData>,
) => {
  const { table, className } = props

  const { renderFooterActions } = table.options

  const renderButtons = useMemo(() => {
    if (typeof renderFooterActions === 'function') {
      const buttons = renderFooterActions({ table })

      return buttons.map(btn => {
        switch (btn.type) {
          case EFieldType.singleSelect: {
            return (
              <DropdownWithCustomChildren
                options={btn.options as any}
                key={btn.label}
                className='noHover'
                onChange={(event, { selectedOption }) => {
                  btn.onClick(event, selectedOption)
                }}
              >
                <Button
                  className='RTTableFooterActionButtons__button'
                  variant={btn.variant}
                  disabled={btn.isDisabled}
                >
                  <span>{btn.label}</span>
                  <ArrowDropdownIcon
                    size='14px'
                    className='RTTableFooterActionButtons__dropdown'
                  />
                </Button>
              </DropdownWithCustomChildren>
            )
          }

          default: {
            return (
              <Button
                key={btn.label}
                onClick={btn.onClick}
                style={{ marginRight: 8 }}
                className='RTTableFooterActionButtons__button'
                variant={btn.variant}
                disabled={btn.isDisabled}
              >
                {btn.label}
              </Button>
            )
          }
        }
      })
    }

    return null
  }, [renderFooterActions, table])

  return (
    <div className={clsx('RTTableFooterActionButtons__container', className)}>
      {renderButtons}
    </div>
  )
}

export default RTTableFooterActionButtons
