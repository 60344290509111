import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ILoad } from '~/types/models/ILoad'

const useModifyLoad = (loadId: number) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const updateLoadData = useCallback(
    (loadData: Partial<ILoad>) => {
      queryClient.setQueryData<ILoad | undefined>(
        ['load', sessionUser?.id, loadId],
        oldData => {
          if (oldData) {
            return {
              ...oldData,
              ...loadData,
            }
          }
          return undefined
        },
      )
    },
    [loadId, queryClient, sessionUser?.id],
  )

  return { updateLoadData }
}

export default useModifyLoad
