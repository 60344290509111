export const DEFAULT_INVOICES_COLUMNS = [
  {
    displayIndex: 0,
    label: 'Actions',
    field: 'actions',
    show: true,
    value: 'actions',
    type: '',
    isField: false,
    sorted: false,
    isAsc: true,
    sortField: 'actions',
    isHidden: false,
    sortable: false,
    hideable: false,
  },
  {
    displayIndex: 1,
    field: 'num',
    label: 'Invoice #',
    value: 'num',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'num',
    isHidden: false,
    show: true,
    sortable: true,
    sortIndex: 0,
    hideable: true,
  },
  {
    displayIndex: 2,
    field: 'seller_id',
    label: 'Seller',
    value: 'seller_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'seller_id',
    isHidden: false,
    show: true,
    sortable: true,
    sortIndex: 1,
    hideable: true,
  },
  {
    displayIndex: 3,
    field: 'buyer_id',
    show: true,
    sortable: true,
    sortIndex: 2,
    label: 'Buyer',
    value: 'buyer_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_id',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 4,
    field: 'buyer_terminal_id',
    show: true,
    sortable: true,
    sortIndex: 3,
    label: 'Buyer Terminal',
    value: 'buyer_terminal_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_terminal_id',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 5,
    label: 'Total',
    field: 'total',
    show: true,
    sorted: false,
    sortable: false,
    value: 'total',
    sortField: 'total',
    isAsc: true,
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 6,
    field: 'qty',
    show: true,
    sortable: true,
    sortIndex: 4,
    label: 'Quantity',
    value: 'qty',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'qty',
    isHidden: false,
    hideable: true,
  },

  {
    displayIndex: 7,
    field: 'outstanding.adjustment',
    show: true,
    sortable: true,
    sortIndex: 4,
    label: 'Adjustment',
    value: 'outstanding.adjustment',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'outstanding.adjustment',
    isHidden: false,
    hideable: true,
  },

  {
    displayIndex: 8,
    field: 'outstanding.invoiceTotal',
    show: true,
    sortable: true,
    sortIndex: 4,
    label: 'Invoice Total',
    value: 'outstanding.invoiceTotal',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'outstanding.invoiceTotal',
    isHidden: false,
    hideable: true,
  },

  {
    displayIndex: 9,
    field: 'outstanding.outstanding',
    show: true,
    sortable: true,
    sortIndex: 4,
    label: 'Outstanding',
    value: 'outstanding.outstanding',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'outstanding.outstanding',
    isHidden: false,
    hideable: true,
  },

  {
    displayIndex: 10,
    field: 'outstanding.payments',
    show: true,
    sortable: true,
    sortIndex: 4,
    label: 'Payments',
    value: 'outstanding.payments',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'outstanding.payments',
    isHidden: false,
    hideable: true,
  },

  {
    displayIndex: 11,
    field: 'load_count',
    show: true,
    sortable: true,
    sortIndex: 5,
    label: 'Loads',
    value: 'load_count',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'load_count',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 12,
    field: 'end_date',
    show: true,
    sortable: true,
    sortIndex: 6,
    label: 'Invoice Period',
    value: 'end_date',
    type: 'number',
    sorted: false,
    isAsc: false,
    sortField: 'end_date',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 13,
    field: 'due_date',
    show: true,
    sortable: true,
    sortIndex: 7,
    label: 'Due Date',
    value: 'due_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'due_date',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 14,
    label: 'Status',
    field: 'status',
    show: true,
    sortable: true,
    sortIndex: 8,
    value: 'status',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 15,
    label: 'Type',
    field: 'category',
    show: true,
    sortable: true,
    sortIndex: 9,
    value: 'category',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'category',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 16,
    label: 'Thumbnail',
    field: 'thumbnail',
    show: true,
    sortable: false,
    value: 'thumbnail',
    type: '',
    sorted: false,
    isAsc: true,
    isHidden: false,
    hideable: true,
    sortField: 'thumbnail',
  },
  {
    displayIndex: 17,
    label: 'Created At',
    field: 'created_at',
    show: false,
    sortable: true,
    sortIndex: 10,
    value: 'created_at',
    type: 'number',
    sorted: true,
    isAsc: false,
    sortField: 'created_at',
    isHidden: false,
    hideable: true,
  },
  {
    label: 'Start Date',
    field: 'start_date',
    show: false,
    sortable: true,
    sortIndex: 11,
    value: 'start_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'start_date',
    isHidden: true,
    hideable: false,
  },
]

export default DEFAULT_INVOICES_COLUMNS
