import { useMount } from 'react-use'
import { useCallback, useMemo, useState } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import moment from 'moment'

function FederalEINVerificationsTable() {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Federal Ein',
        accessorKey: 'federalEin',
        minSize: 130,
        maxSize: 130,
      },
      {
        header: 'Legal Name',
        accessorKey: 'legalName',
        minSize: 180,
        maxSize: 180,
      },
      {
        header: 'Start time',
        accessorKey: 'startTime',
        align: 'center',
        minSize: 100,
        maxSize: 100,
        Cell({ row: { original } }) {
          return moment(original.startTime).format('YYYY-MM-DD')
        },
      },
      {
        header: 'End time',
        accessorKey: 'endTime',
        align: 'center',
        minSize: 100,
        maxSize: 100,
        Cell({ row: { original } }) {
          return moment(original.endTime).format('YYYY-MM-DD')
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        align: 'center',
        minSize: 80,
        maxSize: 80,
      },
    ],
    [],
  )

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await apiClient.check.listCompanyFederalEinVerification()
      setData(response.results)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useMount(() => {
    fetchData()
  })

  return (
    <ReusableTable
      state={{ isLoading }}
      data={data}
      columns={columns}
      tableHeight={350}
    />
  )
}
export default FederalEINVerificationsTable
