import {
  RTHeader,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'

export interface RTTableHeadCellResizeHandleProps<
  TData extends IReusableTableRowData,
> {
  header: RTHeader<TData>
  table: ReusableTableInstance<TData>
}

export const RTTableHeadCellResizeHandle = <
  TData extends IReusableTableRowData,
>({
  header,
  table,
}: RTTableHeadCellResizeHandleProps<TData>) => {
  const {
    getState,
    options: { columnResizeDirection, columnResizeMode },
    setColumnSizingInfo,
  } = table
  const { column } = header

  const handler = header.getResizeHandler()

  return (
    <div
      className='Mui-TableHeadCell-ResizeHandle-Wrapper'
      onDoubleClick={() => {
        setColumnSizingInfo(old => ({
          ...old,
          isResizingColumn: false,
        }))
        column.resetSize()
      }}
      onMouseDown={handler}
      onTouchStart={handler}
      style={{
        transform:
          column.getIsResizing() && columnResizeMode === 'onEnd'
            ? `translateX(${
                (columnResizeDirection === 'rtl' ? -1 : 1) *
                (getState().columnSizingInfo.deltaOffset ?? 0)
              }px)`
            : undefined,
        borderRadius: '2px',
        borderWidth: '2px',
        height: 24,
        width: 4,
        touchAction: 'none',
        transition: column.getIsResizing()
          ? undefined
          : 'all 150ms ease-in-out',
        userSelect: 'none',
        zIndex: 4,
        background: '#e5e5e5',
        position: 'absolute',
        right: 4,
        top: 8,
        cursor: 'grab',
      }}
    />
  )
}
