import { IonAvatar } from '@ionic/react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import getInitName from '~/utils/getInitName'
import clsx from 'clsx'
import _ from 'lodash'

import './SellerBuyerAvatar.scss'

const SellerBuyerAvatar = props => {
  const { type, className, style, user } = props

  const initName = getInitName(user.name || 'N')

  return (
    <div
      className={clsx('SellerBuyerAvatar__container', className)}
      style={style}
    >
      {user.logo && (
        <IonAvatar
          src={user.logo}
          className={clsx('SellerBuyerAvatar__image', type)}
        >
          <img src={user.logo} alt='seller_buyer_avatar' />
        </IonAvatar>
      )}

      {!user.logo && (
        <div className={clsx('SellerBuyerAvatar__initialName', type)}>
          <div className='SellerBuyerAvatar__wrap'>{initName}</div>
        </div>
      )}

      <div className='SellerBuyerAvatar__companyInfo'>
        <div>
          <Link to={`/companies/${user.id}`}>{user.name}</Link>
        </div>
        <div className={clsx('SellerBuyerAvatar__companyType', type)}>
          {_.capitalize(type)}
        </div>
      </div>
    </div>
  )
}

SellerBuyerAvatar.propTypes = {
  user: PropTypes.object,
  type: PropTypes.oneOf(['seller', 'buyer']),
  className: PropTypes.string,
}

SellerBuyerAvatar.defaultProps = {
  type: 'seller',
}

export default SellerBuyerAvatar
