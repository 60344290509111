import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser, selectMyCurrentCompany } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICheckEnrollmentProfile } from '~/types/models/ICheck'
import type { ICompany } from '~/types/models/ICompany'
import useModifyEnrollmentProfile from './useModifyEnrollmentProfile'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryEnrollmentProfile = (
  options?: Partial<UseQueryOptions<ICheckEnrollmentProfile>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { data, isLoading } = useQuery({
    queryKey: ['checkEnrollmentProfile', sessionUser?.id],
    queryFn() {
      return apiClient.check.getEnrollmentProfile()
    },
    enabled: Boolean(sessionUser?.id && currentCompany?.checkUid),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { renewEnrollmentProfile } = useModifyEnrollmentProfile()

  return {
    checkEnrollmentProfileData: data,
    isLoadingCheckEnrollmentProfile: isLoading,
    renewEnrollmentProfile,
  }
}

export default useQueryEnrollmentProfile
