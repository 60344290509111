import type { ReactNode, RefObject } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import highlightWords from 'highlight-words'
import { EFieldType } from '~/types/enums/ECommonEnum'

const allowedTypes = ['string', 'number']

export interface IRTTableBodyCellValueProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  rowRef?: RefObject<HTMLTableRowElement>
  staticColumnIndex?: number
  staticRowIndex?: number
  table: ReusableTableInstance<TData>
}

export const RTTableBodyCellValue: any = <TData extends IReusableTableRowData>({
  cell,
  rowRef,
  staticColumnIndex,
  staticRowIndex,
  table,
}: IRTTableBodyCellValueProps<TData>) => {
  const {
    getState,
    options: { enableFilterMatchHighlighting },
  } = table
  const { column, row } = cell
  const { columnDef } = column
  const { globalFilter, globalFilterFn } = getState()
  const filterValue = column.getFilterValue()

  const matchHighlightColor = 'yellow'

  let renderedCellValue =
    cell.getIsAggregated() && columnDef.AggregatedCell
      ? columnDef.AggregatedCell({
          cell,
          column,
          row,
          table,
        })
      : row.getIsGrouped() && !cell.getIsGrouped()
      ? null
      : cell.getIsGrouped() && columnDef.GroupedCell
      ? columnDef.GroupedCell({
          cell,
          column,
          row,
          table,
        })
      : undefined

  const isGroupedValue = renderedCellValue !== undefined

  if (!isGroupedValue) {
    renderedCellValue = cell.renderValue() as ReactNode | number | string
  }

  if (
    enableFilterMatchHighlighting &&
    columnDef.enableFilterMatchHighlighting !== false &&
    String(renderedCellValue) &&
    allowedTypes.includes(typeof renderedCellValue) &&
    ((filterValue &&
      allowedTypes.includes(typeof filterValue) &&
      [EFieldType.text].includes(columnDef.filterVariant!)) ||
      (globalFilter &&
        allowedTypes.includes(typeof globalFilter) &&
        column.getCanGlobalFilter()))
  ) {
    const chunks = highlightWords?.({
      matchExactly:
        (filterValue ? columnDef._filterFn : globalFilterFn) !== 'fuzzy',
      query: (filterValue ?? globalFilter ?? '').toString(),
      text: renderedCellValue?.toString() as string,
    })
    if (chunks?.length > 1 || chunks?.[0]?.match) {
      renderedCellValue = (
        <span aria-label={renderedCellValue as string} role='note'>
          {chunks?.map(({ key, match, text }) => (
            <span
              aria-hidden='true'
              key={key}
              style={
                match
                  ? {
                      backgroundColor: matchHighlightColor,
                      borderRadius: '2px',
                      color: 'black',
                      padding: '2px 1px',
                    }
                  : undefined
              }
            >
              {text}
            </span>
          )) ?? renderedCellValue}
        </span>
      )
    }
  }

  if (columnDef.Cell && !isGroupedValue) {
    renderedCellValue = columnDef.Cell({
      cell,
      column,
      renderedCellValue,
      row,
      rowRef,
      staticColumnIndex,
      staticRowIndex,
      table,
    })
  }

  return renderedCellValue
}
