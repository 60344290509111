import * as types from './actionTypes'
import documentsUiSlice from '../reducers/ui/documentsSlice'

export const resetFilters = () => {
  return {
    type: types.RESET_DOCUMENTS_FILTERS,
  }
}

export const resetFiltersWithInvoiceDocType = () => {
  return {
    type: types.RESET_FILTERS_WITH_INVOICE_DOC_TYPE,
  }
}

/**
 * @param {{ key: string, value: any }} field
 */
export const updateFilters = field => {
  return {
    field,
    type: types.UPDATE_DOCUMENTS_FILTERS,
  }
}

export const updateCompleteFilters = field => {
  return {
    field,
    type: types.UPDATE_ALL_DOCUMENT_FILTERS,
  }
}

export const updateDashboardFilters = filters => {
  return {
    filters,
    type: types.UPDATE_DASHBOARD_DOCUMENTS_FILTERS,
  }
}
export const resetDefaultFilterForDocumentPage = () => {
  return {
    type: types.SET_DEFAULT_FILTER_FOR_DOCUMENTS,
  }
}

export const onUpdateUi = payload => dispatch => {
  dispatch(documentsUiSlice.actions.updateUi(payload))
}

export const onUpdateUiColumn = payload => dispatch => {
  dispatch(documentsUiSlice.actions.updateUiColumn(payload))
}

export const onUpdateUiColumns = payload => dispatch => {
  dispatch(documentsUiSlice.actions.updateUiColumns(payload))
}

export const onUpdateUiFilter = payload => dispatch => {
  dispatch(documentsUiSlice.actions.updateUiFilter(payload))
}
