import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  ICheckUsageRecordsGetParams,
  ICheckUsageRecordsGetResponse,
} from '~/types/models/ICheck'
import { buildGetUrl } from '~/utils/buildUrl'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCheckUsageRecords = (
  params: ICheckUsageRecordsGetParams = {},
  options?: Partial<UseQueryOptions<ICheckUsageRecordsGetResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'checkUsageRecords',
      sessionUser?.id,
      buildGetUrl('/check_usage_records', params),
    ],
    queryFn() {
      return apiClient.check.getUsageRecords(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const usageRecords = data?.result || []

  return {
    usageRecords,
    isCheckUsageRecordsLoading: isLoading,
  }
}

export default useQueryCheckUsageRecords
