import React, { useCallback, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { IonLabel, IonRow, IonCol, IonButtons, IonButton } from '@ionic/react'
import { parseInputErrors } from '~/utils/utils'
import Select, { components } from 'react-select'
import PropTypes from 'prop-types'

const SelectionFieldDropdown = props => {
  const {
    label,
    data = [],
    meta: { touched, error },
    input = {
      value: '',
    },
  } = props

  const [currentValue, setCurrentValue] = useState()
  const [showDrop, setShowDrop] = useState(false)
  useEffect(() => {
    const filterValue = data.filter(a => a.value == input?.value)
    setCurrentValue(filterValue[0])
  }, [input?.value, data])
  const bindValue = useCallback(
    value => {
      input.onChange && input.onChange(value.value)
      setCurrentValue(value)
      setShowDrop(false)
    },
    [input],
  )

  const onShowDrop = () => {
    setShowDrop(true)
  }

  const onCloseDrop = () => {
    setShowDrop(false)
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <div onClick={onCloseDrop}>
          <components.DropdownIndicator {...props} />
        </div>
      )
    )
  }

  return (
    <>
      <IonRow style={{ alignItems: 'center' }}>
        <IonCol size='3' size-lg offset='1'>
          <IonLabel
            className='input-label floating'
            position='floating'
            style={{ fontSize: '1.5em', fontWeight: 'bold' }}
          >
            {label}
          </IonLabel>
        </IonCol>
        <IonCol size='6' size-lg>
          {showDrop ? (
            <Select
              autoFocus
              classNamePrefix='react-select'
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              options={data}
              value={currentValue}
              blurInputOnSelect
              isFocused
              onChange={bindValue}
            />
          ) : (
            <IonButtons>
              <IonButton onClick={onShowDrop}>
                {currentValue ? currentValue?.label : 'Select An Option'}
              </IonButton>
            </IonButtons>
          )}
        </IonCol>
      </IonRow>
      {touched && error && (
        <IonRow>
          <IonLabel color='danger'>
            <FormattedMessage
              id={parseInputErrors(error)}
              defaultMessage={parseInputErrors(error)}
            />
          </IonLabel>
        </IonRow>
      )}
    </>
  )
}

SelectionFieldDropdown.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
}

SelectionFieldDropdown.defaultProps = {
  meta: {},
  input: {},
}

export default SelectionFieldDropdown
