import React, { useMemo, useState, useCallback, useEffect } from 'react'

import ReactSelect, { components as ReactSelectComponents } from 'react-select'
import ReactCreatableSelect from 'react-select/creatable'
import ReactAsyncSelect from 'react-select/async'

import { Dropdown, Form } from 'react-bootstrap'
import { ConcordFormLayout, RecommendedOptions } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'
import {
  concordDropdownType,
  NOT_SET,
  dropdownType as dropdownTypeConstant,
  DROPDOWN_MIN_WIDTH,
  DROPDOWN_VISIBLE_DATA,
} from '~/utils/constants'
import PropTypes from 'prop-types'

import './ConcordFormDropdown.scss'
import { IonSpinner } from '@ionic/react'

const Control = props => {
  const { selectProps, options } = props
  const { showRecommendedOptions, recommendedOptionsProps, onChange } =
    selectProps

  return (
    <>
      <ReactSelectComponents.Control {...props} />
      {showRecommendedOptions && (
        <RecommendedOptions
          onSelect={onChange}
          options={options}
          {...recommendedOptionsProps}
        />
      )}
    </>
  )
}

const ConcordFormDropdown = React.forwardRef((props, ref) => {
  const {
    options,
    label,
    value,
    className,
    onChange,
    getOptionLabel = option => option?.label,
    styles,
    dropdownType,
    onOpenDropdown,
    onCloseDropdown,
    error,
    inputValue,
    onInputChange,
    rawValue,
    showDropdown: showDropdownProps,
    components,
    renderElementBelowDropdown,
    visibleData,
    renderIndicatorElement,
    isLoading,
    changeable,
    isMulti,
    disabled,
    autoClose,
    isAlwaysClosed,
    ...dropdownProps
  } = props

  const [showDropdown, setShowDropdown] = useState(false)
  const [menuIsOpenSelect, setMenuIsOpenSelect] = useState(true)
  const [inputDropdownValue, setInputDropdownValue] = useState('')

  const SelectPickerElement = useMemo(() => {
    switch (dropdownType) {
      case dropdownTypeConstant.dropdown: {
        return ReactSelect
      }

      case dropdownTypeConstant.creatable: {
        return ReactCreatableSelect
      }

      case dropdownTypeConstant.async: {
        return ReactAsyncSelect
      }

      default: {
        return ReactSelect
      }
    }
  }, [dropdownType])

  const mapValueOption = useMemo(() => {
    if (_.isUndefined(value)) {
      return {}
    }
    if (_.isPlainObject(value)) {
      return value
    }
    if (_.isNumber(value) || _.isString(value) || _.isNull(value)) {
      return options.find(option => option.value === value) || {}
    }

    if (_.isArray(value)) {
      const newValue = []

      value.forEach(val => {
        if (_.isPlainObject(val)) {
          newValue.push(val)
        } else if (_.isString(val) || _.isNumber(val)) {
          const findOption = options.find(option => option.value === val) || {}
          newValue.push(findOption)
        }
      })
      return newValue
    }

    return {
      id: value,
    }
  }, [options, value])

  const handleChangeOption = useCallback(
    (selectedOption, action) => {
      onChange && onChange(selectedOption, action)
      if (dropdownProps?.closeMenuOnSelect !== false) {
        handleToggleDropdown(false)
      }
    },
    [dropdownProps?.closeMenuOnSelect, handleToggleDropdown, onChange],
  )

  const handleToggleReactSelect = useCallback(nextOpen => {
    setMenuIsOpenSelect(prev => {
      if (_.isNil(nextOpen)) {
        return !prev
      }

      return nextOpen
    })
  }, [])

  const renderSelectFieldIndicator = useCallback(
    componentsProps => {
      const indicatorClassNames = clsx('ConcordFormDropdown__indicator', {
        closeMenu: !menuIsOpenSelect,
        companyCreateEdit: concordDropdownType.companyCreateEdit,
      })

      const indicatorElement = (
        <div
          onClick={() => handleToggleReactSelect()}
          className={indicatorClassNames}
        >
          <ReactSelectComponents.DropdownIndicator
            className='ConcordFormDropdown__dropdownIndicator'
            {...componentsProps}
          />
        </div>
      )

      return (
        <div className='ConcordFormDropdown__indicatorEditCompany'>
          {renderIndicatorElement && renderIndicatorElement()}
          {indicatorElement}
        </div>
      )
    },
    [renderIndicatorElement, handleToggleReactSelect, menuIsOpenSelect],
  )

  const Component = useMemo(() => {
    const DEFAULT_COMPONENTS = {
      IndicatorSeparator: null,
      DropdownIndicator: renderSelectFieldIndicator,
      Control,
    }

    return _.merge(DEFAULT_COMPONENTS, components)
  }, [components, renderSelectFieldIndicator])

  const isShowingRawValue = useMemo(() => {
    if (visibleData === DROPDOWN_VISIBLE_DATA.rawDataOnly) {
      return true
    }

    if (visibleData === DROPDOWN_VISIBLE_DATA.matchDataOnly) {
      return false
    }

    if (_.isArray(mapValueOption)) {
      return false
    }

    if (getOptionLabel(mapValueOption)) {
      return false
    }

    return true
  }, [getOptionLabel, mapValueOption, visibleData])

  const labelButtonRenderer = useMemo(() => {
    if (visibleData === DROPDOWN_VISIBLE_DATA.rawDataOnly) {
      return rawValue
    }

    if (visibleData === DROPDOWN_VISIBLE_DATA.matchDataOnly) {
      return getOptionLabel(mapValueOption)
    }

    if (_.isArray(mapValueOption) && isMulti) {
      if (React.isValidElement(getOptionLabel(mapValueOption))) {
        return getOptionLabel(mapValueOption)
      }
      return mapValueOption.map(({ label }) => `${label}, `)
    }

    const option = getOptionLabel(mapValueOption) || rawValue

    if (option === NOT_SET) {
      return ''
    }

    return option
  }, [getOptionLabel, isMulti, mapValueOption, rawValue, visibleData])

  const styledComponents = useMemo(() => {
    const defaultStyles = {
      menu: provided => ({
        ...provided,
        position: 'static',
        marginBottom: 0,
      }),
      option: (provided, state) => {
        const { data, isDisabled } = state

        const optionStyles = () => {
          const style = {}

          if (state.label === NOT_SET) {
            style.color = 'white'
            style.background = 'red'
          } else if (data.allOption) {
            style.background = '#ffe9e9'
            style.color = 'black'
            style['&:hover'] = {
              background: '#e0d0d0',
              color: 'black',
            }
          } else {
            style.background = 'white'
            style.color = 'black'
          }

          if (isDisabled) {
            style.opacity = '0.3'
            // style.background = '#464646'
            style.color = '#898989'
          }

          // if (data.otherOption) {
          //   return {
          //     // background: '#e3e3e3',
          //     // color: 'black',
          //     // '&:hover': {
          //     //   background: '#c9c9c9',
          //     //   color: 'black',
          //     // },
          //   }
          // }

          return style
        }

        return {
          ...provided,
          fontSize: 13,
          '&:hover': {
            background: '#e5e5e5',
            color: 'black',
          },
          ...optionStyles(),
        }
      },
      valueContainer: provided => ({
        ...provided,
        fontSize: 13,
      }),
      groupHeading: provided => ({
        ...provided,
        fontSize: 10,
        color: 'rgb(116, 116, 116)',
        borderBottom: '1px solid #767676',
      }),
    }

    return _.merge(defaultStyles, styles)
  }, [styles])

  const handleToggleDropdown = useCallback(
    (nextShow, { source } = {}) => {
      if (source === 'select' && nextShow === false) {
        return
      }
      setShowDropdown(nextShow)
      if (nextShow) {
        onOpenDropdown && onOpenDropdown()
      } else {
        setMenuIsOpenSelect(true)
        onCloseDropdown && onCloseDropdown()
      }
    },
    [onCloseDropdown, onOpenDropdown],
  )

  const handleInputChange = useCallback(
    (newValue, actionMeta) => {
      if (actionMeta.action === 'input-change') {
        onInputChange && onInputChange(newValue, actionMeta)
        setInputDropdownValue(newValue)
      }
    },
    [onInputChange],
  )

  useEffect(() => {
    setInputDropdownValue(inputValue || '')
  }, [inputValue])

  useEffect(() => {
    if (_.isBoolean(showDropdownProps)) {
      setShowDropdown(showDropdownProps)
    }
  }, [showDropdownProps])

  return (
    <>
      <ConcordFormLayout
        isDisabled={disabled}
        label={label}
        className={className}
        error={error}
      >
        <Dropdown
          className='ConcordFormDropdown__dropdownContainer'
          ref={node => {
            // eslint-disable-next-line no-extra-semi
            ;(ref || {}).current = node
            // elRef(node)
          }}
          show={isAlwaysClosed ? false : showDropdown}
          onToggle={handleToggleDropdown}
        >
          <Dropdown.Toggle
            className={clsx('ConcordFormDropdown__toggleButton', {
              disabled: isLoading,
              error,
            })}
          >
            {isLoading && (
              <IonSpinner
                name='lines-small'
                className='ConcordFormDropdown__spinner'
              />
            )}
            <span
              className={clsx('ConcordFormDropdown__toggleLabelButton', {
                rawValue: isShowingRawValue,
              })}
            >
              {labelButtonRenderer}
            </span>
          </Dropdown.Toggle>
          {changeable && (
            <Dropdown.Menu
              className='ConcordFormDropdown__menu'
              style={{ width: DROPDOWN_MIN_WIDTH }}
            >
              <div className='ConcordFormDropdown__selectContainer'>
                <SelectPickerElement
                  value={mapValueOption}
                  menuIsOpen={isAlwaysClosed ? false : menuIsOpenSelect}
                  options={options}
                  styles={styledComponents}
                  onChange={handleChangeOption}
                  placeholder='Select...'
                  inputValue={inputDropdownValue}
                  onInputChange={handleInputChange}
                  components={Component}
                  isLoading={isLoading}
                  isMulti={isMulti}
                  error={error}
                  {...dropdownProps}
                />
                {showDropdown &&
                  renderElementBelowDropdown &&
                  renderElementBelowDropdown({
                    handleToggleReactSelect,
                    inputDropdownValue,
                  })}
              </div>
            </Dropdown.Menu>
          )}
        </Dropdown>
        {error && <Form.Text className='error'>{error}</Form.Text>}
      </ConcordFormLayout>
    </>
  )
})

ConcordFormDropdown.propTypes = {
  renderElementBelowDropdown: PropTypes.func,
}

ConcordFormDropdown.defaultProps = {
  getOptionLabel: option => option?.label,
  formProps: {},
  dropdownType: dropdownTypeConstant.dropdown,
  changeable: true,
}

export default ConcordFormDropdown
