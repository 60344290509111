import { useWindowSize } from 'react-use'

import TablePhoneNumbers from '~/containers/CompanySubTabs/PhoneNumber/TablePhoneNumbers'

function CompanyPhoneNumberTab() {
  const windowSize = useWindowSize()

  return <TablePhoneNumbers tableHeight={windowSize.height - 300} />
}
export default CompanyPhoneNumberTab
