import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '~/redux/actions/CommonData/productsActions'
import { selectProductsLastFetchedAt } from '~/redux/selectors'

export const useInitializeProducts = () => {
  const dispatch = useDispatch()
  const lastUpdated = useSelector(selectProductsLastFetchedAt)

  useEffect(() => {
    !lastUpdated && dispatch(getProducts())
  }, [lastUpdated, dispatch])
}
