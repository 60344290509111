import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
  },
  total: {
    width: '100%',
    textAlign: 'right',
    paddingRight: 8,
  },
})

const PDFDataTableFooter = ({ data, renderFooterContent, renderFooter }) => {
  if (renderFooter) {
    return renderFooter({ data })
  }

  return (
    <View style={styles.row}>
      <Text style={styles.total}>
        {renderFooterContent({ data })}
      </Text>
    </View>
  )
}

PDFDataTableFooter.defaultProps = {
  renderFooterContent: () => '',
}

export default PDFDataTableFooter
