import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import useStoreFilters from '~/hooks/useStoreFilters'
import { useConfirmationProvider } from '~/contexts'

import { IonButtons, IonIcon } from '@ionic/react'
import { Link } from 'react-router-dom'
import {
  funnelOutline,
  saveOutline,
  refreshOutline,
  cloudDownloadOutline,
} from 'ionicons/icons'
import { ContainerSearchBar, ToolTipOverlay } from '~/components/shared'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import ClearFiltersButton from './ClearFiltersButton'
import { ionicColor } from '~/constants/theme'
import clsx from 'clsx'
import { getTitleRange } from '~/utils/getRangeDate'
import ContainerFilterTooltipItem from './TooltipItem'
import FilterModalPopup from './FilterModalPopup'
import { ButtonGroup, Dropdown, ToggleButton } from 'react-bootstrap'
import useFilterOptions from '~/hooks/useFilterOptions'
import { ControlledMenu, useMenuState, MenuItem } from '@szhsin/react-menu'
import RenderFilters from './RenderFilters'
import SortOptionsButton from './SortOptionsButton'
import DisplayColumnsButton from './DisplayColumnsButton'
import { toast } from 'react-toastify'
import { FILTER_OPTION_TYPES, PROFILE_SETTINGS } from '~/utils/constants'

import './ContainerFilter.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'

//  NOTE THIS IS JUST A SEPARATE COMPONENT TO IMPLEMENT COLUMNS FIXES
//  WILL REMOVE AFTER OTHER IMPLEMENTATIONS USE THIS

const ContainerFilterV3 = props => {
  const {
    columnsDisplayed,
    columnsFromUserTableConfigurations,
    dataExported,
    filterData,
    filterOptions,
    //filterOrder
    hideExtraButtons = false,
    isMyCompanyPage = false,
    leftExtraSection,
    lineItem,
    moduleName,
    rightExtraSection,
    searchBarClassName,
    searchKey,
    searchPlaceholder = 'Search...',
    showSearch,
    sortOptions,
    onChangeColumnsDisplayed,
    onChangeFilters,
    onClearFilters,
    onFiltersReset,
    onResetFilterToDefault,
    onSearchKeyChange,
    onSortChange,
    setBorderColor,
    DEFAULT_COLUMNS,
  } = props

  const { isScreenDown } = useBreakpoint()
  const { getFilteOptions } = useFilterOptions(moduleName, {
    lineItem: lineItem ? lineItem : null,
  })
  const moduleFilters = moduleName ? getFilteOptions(moduleName) : filterOptions
  const [storeFilters, setStoreFilters, resetStoreData, { defaultEmptyValue }] =
    useStoreFilters(moduleName)

  const pluralModelName = useMemo(() => {
    return _.startCase(moduleName)
  }, [moduleName])

  const { confirmation } = useConfirmationProvider()

  const hideClearButton = _.isEqual(filterData, defaultEmptyValue)
  const hideResetToDefault = _.isEqual(filterData, storeFilters.data)

  const isEqualFilters = useMemo(() => {
    return _.isEqual(storeFilters.data, filterData)
  }, [filterData, storeFilters.data])

  const displayMode = useMemo(() => {
    if (!storeFilters) {
      return 'horizontal'
    }

    return storeFilters.mode
  }, [storeFilters])

  const [isHovering, setIsHovering] = useState(true)

  const [isShowModal, setIsShowModal] = useState(false)

  const saveButtonRef = useRef()
  const [savingMenuProps, toggleSavingMenu] = useMenuState({ transition: true })

  useEffect(() => {
    if (isMyCompanyPage) {
      setBorderColor &&
        setBorderColor(!hideClearButton ? ionicColor.snowGrey : ionicColor.dark)
    }
  }, [setBorderColor, hideClearButton, isMyCompanyPage])

  const toggleFiltersModal = () => setIsShowModal(false)

  const handleClearAllFilters = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to clear all filters!',
    })

    if (result === EYesNo.Yes) {
      resetStoreData()
      handleClearFilters()
    }
  }, [confirmation, resetStoreData, handleClearFilters])

  const handleResetFilterToDefault = useCallback(() => {
    onResetFilterToDefault && onResetFilterToDefault(storeFilters.data)
  }, [onResetFilterToDefault, storeFilters.data])

  const handleClearFilters = useCallback(() => {
    onClearFilters && onClearFilters(defaultEmptyValue)
  }, [defaultEmptyValue, onClearFilters])

  const renderTooltipFilterItem = useCallback(
    filterItem => {
      const { label, field, type, options } = filterItem
      switch (type) {
        case FILTER_OPTION_TYPES.dateRange: {
          const value = getTitleRange(filterData[field])

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value}
            />
          )
        }

        case 'multipleSelect': {
          const values = filterData[field] || []
          const value = (options || [])
            .filter(option => values.includes(option.value))
            .map(({ label }) => label)
            .join(', ')

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value || 'All'}
            />
          )
        }

        case 'singleSelect': {
          const value = filterData[field]

          return (
            <ContainerFilterTooltipItem
              key={field}
              label={label}
              value={value || 'All'}
            />
          )
        }

        default: {
          return null
        }
      }
    },
    [filterData],
  )

  const tooltipFilters = useMemo(() => {
    let tooltipMessage = null
    if (!moduleName) {
      tooltipMessage = (filterOptions || []).map(option =>
        renderTooltipFilterItem(option),
      )
    } else {
      let sourceArr =
        storeFilters.order && storeFilters.order.length > 0
          ? storeFilters.order
          : Object.keys(storeFilters.data)

      tooltipMessage = sourceArr.map(key => {
        let foundKey = moduleFilters.find(({ field }) => field == key)
        if (!foundKey) return null
        return renderTooltipFilterItem(foundKey)
      })
    }

    if (_.size(tooltipMessage) === 0) {
      return null
    }

    return <ul className='ContainerFilter__wrapFilterItem'>{tooltipMessage}</ul>
  }, [
    moduleName,
    filterOptions,
    renderTooltipFilterItem,
    storeFilters.order,
    storeFilters.data,
    moduleFilters,
  ])

  const handleToggleSaveFilter = useCallback(() => {
    if (savingMenuProps.state === 'closed' || !savingMenuProps.state) {
      toggleSavingMenu(true)
    } else {
      toggleSavingMenu(false)
    }
  }, [savingMenuProps.state, toggleSavingMenu])

  const handleSaveDefaultFilters = useCallback(() => {
    try {
      setStoreFilters({ data: filterData })
      toast.success('Saved filter successfully.')
    } catch {
      toast.error('An error has occurred.')
    }
  }, [filterData, setStoreFilters])

  const handleChangeFilters = useCallback(
    newFilters => {
      if (storeFilters.autoSave) {
        setStoreFilters({ data: newFilters })
      }
      onChangeFilters && onChangeFilters(newFilters)
    },
    [onChangeFilters, setStoreFilters, storeFilters.autoSave],
  )

  const renderFilterSection = useCallback(() => {
    if (displayMode === 'vertical' || isScreenDown('md')) {
      return null
    }

    return (
      <>
        <Dropdown
          show={isHovering}
          className='ContainerFilterV3__dropdownContainer'
        >
          <Dropdown.Toggle>
            <div
              className='container-filters-scroll hide-scrollbar'
              onMouseEnter={() => {
                setIsHovering(true)
              }}
              onMouseLeave={() => {
                setIsHovering(false)
              }}
            >
              <div
                className={clsx('ContainerFilter__container', {
                  active: !hideClearButton,
                })}
              >
                <RenderFilters
                  filterData={filterData}
                  filterOptions={moduleFilters}
                  filterOrder={storeFilters.order}
                  onChange={handleChangeFilters}
                  pluralModelName={pluralModelName}
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>{tooltipFilters}</Dropdown.Menu>
        </Dropdown>

        {!hideExtraButtons && (
          <IonButtons className='documents-container-clear'>
            {!hideClearButton && (
              <ToolTipOverlay content='Clear All Filters' placement='bottom'>
                <ClearFiltersButton
                  onFiltersReset={handleClearFilters}
                  label=''
                />
              </ToolTipOverlay>
            )}

            {!hideResetToDefault && (
              <ToolTipOverlay
                content='Reset to Default Filters'
                placement='bottom'
              >
                <ClearFiltersButton
                  onFiltersReset={handleResetFilterToDefault}
                  label=''
                  icon={refreshOutline}
                />
              </ToolTipOverlay>
            )}

            {_.size(filterData) > 0 && moduleName && (
              <ToolTipOverlay content='Default Settings' placement='bottom'>
                <span ref={saveButtonRef}>
                  <ClearFiltersButton
                    onFiltersReset={handleToggleSaveFilter}
                    label=''
                    icon={saveOutline}
                    className={clsx('save-filter-button', {
                      isActive: !isEqualFilters,
                    })}
                  />
                </span>
              </ToolTipOverlay>
            )}
          </IonButtons>
        )}
      </>
    )
  }, [
    displayMode,
    isScreenDown,
    hideClearButton,
    filterData,
    moduleFilters,
    storeFilters.order,
    handleChangeFilters,
    pluralModelName,
    hideExtraButtons,
    handleClearFilters,
    hideResetToDefault,
    handleResetFilterToDefault,
    moduleName,
    handleToggleSaveFilter,
    isEqualFilters,
    isHovering,
    tooltipFilters,
  ])

  const isVerticalMode = useMemo(
    () => displayMode === 'vertical' || isScreenDown('md'),
    [displayMode, isScreenDown],
  )

  const isEmptyOptions = useMemo(
    () =>
      !sortOptions?.length &&
      !columnsDisplayed?.length &&
      !dataExported?.length &&
      !isVerticalMode,
    [
      isVerticalMode,
      columnsDisplayed?.length,
      dataExported?.length,
      sortOptions?.length,
    ],
  )

  return (
    <div className='d-flex flex-row align-items-center'>
      {showSearch && (
        <ContainerSearchBar
          searchBarPlaceholder={searchPlaceholder}
          searchBarValue={searchKey}
          onSearchBarChange={onSearchKeyChange}
          searchBarParent='ContainerFilter'
          className={searchBarClassName}
        />
      )}
      {leftExtraSection}
      <ButtonGroup
        className={clsx('ContainerFilter__buttonGroupContainer', {
          'no-border': isEmptyOptions,
        })}
      >
        {sortOptions && sortOptions.length > 0 && (
          <SortOptionsButton
            sortOptions={sortOptions}
            columnsFromUserTableConfigurations={
              columnsFromUserTableConfigurations
            }
            moduleName={moduleName}
            onSortChange={onSortChange}
            DEFAULT_COLUMNS={DEFAULT_COLUMNS}
          />
        )}
        {columnsDisplayed.length > 0 && (
          <DisplayColumnsButton
            columnsDisplayed={columnsDisplayed}
            columnsFromUserTableConfigurations={
              columnsFromUserTableConfigurations
            }
            moduleName={moduleName}
            onChangeColumnsDisplayed={onChangeColumnsDisplayed}
            DEFAULT_COLUMNS={DEFAULT_COLUMNS}
          />
        )}

        {dataExported?.length > 0 && (
          <ToolTipOverlay content='Export to CSV' placement='bottom'>
            <ToggleButton
              size='lg'
              type='radio'
              name='narrow'
              variant='outline-primary'
            >
              <CSVLink
                data={dataExported}
                filename={moduleName}
                separator=';'
                target='_blank'
              >
                <IonIcon className='Filter__icon' icon={cloudDownloadOutline} />
              </CSVLink>
            </ToggleButton>
          </ToolTipOverlay>
        )}
        {isVerticalMode && (
          <>
            <ToolTipOverlay content='Filters' placement='bottom'>
              <ToggleButton
                size='lg'
                type='radio'
                name='narrow'
                variant='outline-primary'
                onClick={() => setIsShowModal(true)}
              >
                <IonIcon className='Filter__icon' icon={funnelOutline} />
              </ToggleButton>
            </ToolTipOverlay>

            <ToolTipOverlay content='Save filters' placement='bottom'>
              <ToggleButton
                size='lg'
                type='radio'
                name='narrow'
                variant='outline-primary'
                ref={saveButtonRef}
                onClick={handleToggleSaveFilter}
              >
                <IonIcon className='Filter__icon' icon={saveOutline} />
              </ToggleButton>
            </ToolTipOverlay>
          </>
        )}
      </ButtonGroup>

      {moduleFilters.length > 0 && renderFilterSection()}

      {rightExtraSection}

      <FilterModalPopup
        open={isShowModal}
        onClose={toggleFiltersModal}
        onReset={onFiltersReset}
        hideResetButton={hideClearButton}
        filterData={filterData}
        filterOptions={moduleFilters}
        onChangeFilters={handleChangeFilters}
        pluralModelName={pluralModelName}
      />

      {_.size(filterData) > 0 && moduleName && (
        <ControlledMenu
          {...savingMenuProps}
          anchorRef={saveButtonRef}
          // onPointerLeave={() => toggleSavingMenu(false)}
          onClose={() => toggleSavingMenu(false)}
        >
          <ToolTipOverlay
            placement='left'
            content={storeFilters.autoSave ? 'Auto Save: ON' : ''}
          >
            <MenuItem
              disabled={storeFilters.autoSave || isEqualFilters}
              style={{ fontSize: 14 }}
              onClick={handleSaveDefaultFilters}
              onPointerLeave={() => toggleSavingMenu(false)}
            >
              Update Default Filters
            </MenuItem>
          </ToolTipOverlay>
          <MenuItem
            style={{ fontSize: 14 }}
            onClick={handleClearAllFilters}
            onPointerLeave={() => toggleSavingMenu(false)}
            disabled={_.isEqual(defaultEmptyValue, storeFilters.data)}
          >
            Clear All Filters and Save
          </MenuItem>
          {moduleName && (
            <MenuItem
              style={{ fontSize: 14 }}
              onPointerLeave={() => toggleSavingMenu(false)}
            >
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/profile?sub_tab=${moduleName}&tab=${PROFILE_SETTINGS.storedData}&section=filters`}
              >
                Go to settings page
              </Link>
            </MenuItem>
          )}
        </ControlledMenu>
      )}
    </div>
  )
}

// ContainerFilterV3.propTypes = {
//   columnsDisplayed: PropTypes.array,
//   onChangeColumnsDisplayed: PropTypes.func,
//   //   leftExtraSection: PropTypes.func,
//   //   rightExtraSection: PropTypes.func
// }

ContainerFilterV3.defaultProps = {
  onChangeColumns: () => {},
  columnsDisplayed: [],
  filterOptions: [],
  filterData: {},
  sortOptions: [],
}

export default ContainerFilterV3
