import { CommonDialogV2 } from '~/components/shared'
import BuyerSellerProductForm from './BuyerSellerProductForm'

import type { IDialogCompanyBuyerSellerProductFormDataProps } from './type'

import './styles.scss'

function DialogBuyerSellerProductForm(
  props: IDialogCompanyBuyerSellerProductFormDataProps,
) {
  const { formData, afterCreate, afterUpdate, ...modalProps } = props

  return (
    <CommonDialogV2
      {...modalProps}
      title='Buyer Seller Product'
      isHiddenOkButton
    >
      <BuyerSellerProductForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogBuyerSellerProductForm
