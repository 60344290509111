import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useQueryAllowedHierarchies,
  useQueryAllowedHierarchiesNew,
} from '~/hooks/useQueryData'
import useGetSideOptions from '~/hooks/useGetSideOptions'
import { useSelector } from 'react-redux'

import {
  ConcordFormStructure,
  IConcordFormField,
  hierarchyScheme,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  EAllowedHierarchyBuyerSideType,
  EAllowedHierarchySellerSideType,
} from '~/types/enums/EAllowedHierarchy'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'

import type { ICompany } from '~/types/models/ICompany'
import type { IHierarchyFormValues } from '~/types/models/IHierarchy'
import type { IHierarchyFormProps } from './type'

const HierarchyForm = (props: IHierarchyFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    onInitialize,
    formData,
    allowedHierarchyData,
    isHiddenSubmitButton,
    ...formProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [sellerSideTypeSelected, setSellerSideTypeSelected] = useState('')
  const [buyerSideTypeSelected, setBuyerSideTypeSelected] = useState('')

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { getSellerSideOptions, getBuyerSideOptions } = useGetSideOptions()

  const { allowedHierarchyOptions } = useQueryAllowedHierarchies()

  const { ahSellerSideOptions, ahBuyerSideOptions } =
    useQueryAllowedHierarchiesNew()

  const sellerSideOptions = useMemo(
    () => getSellerSideOptions(sellerSideTypeSelected),
    [getSellerSideOptions, sellerSideTypeSelected],
  )

  const buyerSideOptions = useMemo(
    () => getBuyerSideOptions(buyerSideTypeSelected),
    [getBuyerSideOptions, buyerSideTypeSelected],
  )

  const setSellerSideTypeValue = useCallback(
    (value: string) => {
      formRef.current?.setValue('sellerSideType', value)
      setSellerSideTypeSelected(value)
      if (
        currentScope === EScope.seller &&
        value === EAllowedHierarchySellerSideType.Seller
      ) {
        formRef.current?.setValue('sellerSideId', currentCompany.id)
      } else {
        formRef.current?.setValue('sellerSideId', null)
      }
    },
    [currentCompany.id, currentScope],
  )

  const setBuyerSideTypeValue = useCallback(
    (value: string) => {
      formRef.current?.setValue('buyerSideType', value)
      setBuyerSideTypeSelected(value)
      if (
        currentScope === EScope.buyer &&
        value === EAllowedHierarchyBuyerSideType.Buyer
      ) {
        formRef.current?.setValue('buyerSideId', currentCompany.id)
      } else {
        formRef.current?.setValue('buyerSideId', null)
      }
    },
    [currentCompany.id, currentScope],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'allowedHierarchyId',
        label: 'Allowed Hierarchy',
        isRequired: true,
        type: EFieldType.singleSelect,
        isDisabled: isUpdating,
        isHidden: Boolean(allowedHierarchyData),
        options: allowedHierarchyOptions,
        onChange({ selectedOption }) {
          const { sellerSideType, buyerSideType } = selectedOption
          setSellerSideTypeValue(sellerSideType)
          setBuyerSideTypeValue(buyerSideType)
        },
      },
      {
        name: 'sellerSideType',
        label: 'Seller Side Type',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: ahSellerSideOptions,
        size: 6,
        isDisabled: true,
        onChange({ value }) {
          setSellerSideTypeSelected(value)
          if (
            value === EAllowedHierarchySellerSideType.Seller &&
            currentScope === EScope.seller
          ) {
            formRef.current?.setValue('sellerSideId', currentCompany.id)
          } else {
            formRef.current?.setValue('sellerSideId', null)
          }
        },
      },
      {
        name: 'sellerSideId',
        label: 'Seller Side #',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: sellerSideOptions,
        size: 6,
        isDisabled({ watch }) {
          const sellerSideType = watch('sellerSideType', null)
          if (!sellerSideType) {
            return true
          }
          return (
            sellerSideType === EAllowedHierarchySellerSideType.Seller &&
            currentScope === EScope.seller
          )
        },
      },
      {
        name: 'buyerSideType',
        label: 'Buyer Side Type',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: ahBuyerSideOptions,
        isDisabled: true,
        size: 6,
        onChange({ value }) {
          setBuyerSideTypeSelected(value)
          if (
            value === EAllowedHierarchySellerSideType.Seller &&
            currentScope === EScope.buyer
          ) {
            formRef.current?.setValue('buyerSideId', currentCompany.id)
          } else {
            formRef.current?.setValue('buyerSideId', null)
          }
        },
      },
      {
        name: 'buyerSideId',
        label: 'Buyer Side #',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: buyerSideOptions,
        size: 6,
        isDisabled({ watch }) {
          const buyerSideType = watch('buyerSideType', null)
          if (!buyerSideType) {
            return true
          }
          return (
            buyerSideType === EAllowedHierarchySellerSideType.Seller &&
            currentScope === EScope.buyer
          )
        },
      },
    ],
    [
      isUpdating,
      allowedHierarchyData,
      allowedHierarchyOptions,
      ahSellerSideOptions,
      sellerSideOptions,
      ahBuyerSideOptions,
      buyerSideOptions,
      setSellerSideTypeValue,
      setBuyerSideTypeValue,
      currentScope,
      currentCompany.id,
    ],
  )

  const defaultValues = useMemo<IHierarchyFormValues>(
    () => ({
      sellerSideType: null,
      sellerSideId: null,
      buyerSideType: null,
      buyerSideId: null,
      allowedHierarchyId: null,
      rank: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IHierarchyFormValues) => {
      const { errors, ...response } = await apiClient.hierarchies.create({
        hierarchy: {
          sellerSideType: formValues.sellerSideType,
          sellerSideId: formValues.sellerSideId,
          buyerSideType: formValues.buyerSideType,
          buyerSideId: formValues.buyerSideId,
          allowedHierarchyId: formValues.allowedHierarchyId,
          rank: formValues.rank,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IHierarchyFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'sellerSideType',
          'sellerSideId',
          'buyerSideType',
          'buyerSideId',
        ])
        const { errors, ...response } = await apiClient.hierarchies.update(
          formValues.id,
          {
            hierarchy: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.createSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IHierarchyFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isUpdating) {
      setSellerSideTypeSelected(formData?.sellerSideType as string)
      setBuyerSideTypeSelected(formData?.buyerSideType as string)
    } else if (allowedHierarchyData) {
      formRef.current?.setValue('allowedHierarchyId', allowedHierarchyData.id)
      setSellerSideTypeValue(allowedHierarchyData.sellerSideType)
      setBuyerSideTypeValue(allowedHierarchyData.buyerSideType)
    }
  }, [
    allowedHierarchyData,
    currentCompany.id,
    currentScope,
    formData?.buyerSideType,
    formData?.sellerSideType,
    isUpdating,
    setBuyerSideTypeValue,
    setSellerSideTypeValue,
  ])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        isHiddenSubmitButton={isHiddenSubmitButton}
        onSubmit={handleSubmit}
        schema={hierarchyScheme}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default HierarchyForm
