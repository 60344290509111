import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Card, Button } from 'react-bootstrap'
import { IonProgressBar } from '@ionic/react'
import {
  ConcordFormTextField,
  ConcordFormColorPicker,
  ConcordFormMultipleCheckbox,
} from '~/components/shared'

import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { updateCompanyTag } from '~/redux/actions/CommonData/tagsActions'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import './OverlayCreateTag.scss'
import { apiClient } from '~/api/ApiClient'

function OverlayCreateTag(props) {
  const { afterCreateTag, onClose, type } = props

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const schema = yup.object({
    name: yup.string().required('This field is required!'),
    code: yup.string().required('This field is required!'),
  })

  const { watch, setValue, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      name: '',
      code: '',
      description: '',
      color: '#000000',
      models: [type],
    },
    resolver: yupResolver(schema),
  })

  const watchNameValue = watch('name', '')
  const watchCodeValue = watch('code', '')
  const watchDescriptionValue = watch('description', '')
  const watchColorValue = watch('color', '#000000')
  const watchModelsValue = watch('models', [type])

  const modelOptions = useMemo(
    () => [
      {
        label: 'Bill Line',
        value: 'BillLine',
      },
      {
        label: 'Load',
        value: 'Load',
      },
      {
        label: 'Invoice',
        value: 'Invoice',
      },
      {
        label: 'Document',
        value: 'Document',
      },
    ],
    [],
  )

  const handleChange = useCallback(
    (event, { name, value }) => {
      setValue(name, value)
    },
    [setValue],
  )

  const handleCreateTag = handleSubmit(async formValues => {
    setLoading(true)
    try {
      const { errors, ...response } = await apiClient.tags.create(formValues)
      if (_.size(errors) > 0) {
        toast.error(errors[0])
      } else {
        reset()
        dispatch(updateCompanyTag(response))
        toast.success(toastMessages.createSuccess)
        afterCreateTag && afterCreateTag(response)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.createError)
    } finally {
      setLoading(false)
    }
  })

  return (
    <Card className='OverlayCreateTag__container'>
      {loading && <IonProgressBar type='indeterminate' />}
      <Card.Body>
        <form id='create_tag' onSubmit={handleCreateTag}>
          <ConcordFormTextField
            required
            name='name'
            label='Name'
            className='OverlayCreateTag__field'
            onChange={handleChange}
            value={watchNameValue}
            error={formState.errors?.name?.message}
          />

          <ConcordFormTextField
            required
            name='code'
            label='Code'
            className='OverlayCreateTag__field'
            onChange={handleChange}
            value={watchCodeValue}
            error={formState.errors?.code?.message}
          />

          <ConcordFormTextField
            name='description'
            label='Description'
            className='OverlayCreateTag__field'
            onChange={handleChange}
            value={watchDescriptionValue}
          />

          <ConcordFormColorPicker
            label='Color'
            name='color'
            className='OverlayCreateTag__field'
            onChange={handleChange}
            value={watchColorValue}
          />

          <ConcordFormMultipleCheckbox
            label='Models'
            name='models'
            options={modelOptions}
            className='OverlayCreateTag__field'
            value={watchModelsValue}
            onChange={handleChange}
          />
        </form>
      </Card.Body>
      <Card.Footer className='OverlayCreateTag__footer'>
        <Button disabled={loading} type='submit' form='create_tag'>
          Create
        </Button>
        <Button disabled={loading} onClick={onClose} variant='danger'>
          Close
        </Button>
      </Card.Footer>
    </Card>
  )
}

OverlayCreateTag.defaultProps = {
  type: 'Load',
}

export default OverlayCreateTag
