import { useState, useCallback } from 'react'

import { PdfViewer } from '~/components/shared'
import ShowingPdfViewerContext from './ShowingPdfViewerContext'

function UseShowingPdfViewerProvider(props) {
  const { children } = props

  const [modalState, setModalState] = useState({
    pdfUrl: '',
    open: false,
  })

  const onOpenPdfViewer = useCallback(({ pdfUrl }) => {
    setModalState({
      pdfUrl,
      open: true,
    })
  }, [])

  const onClosePdfViewer = useCallback(() => {
    setModalState({
      pdfUrl: '',
      open: true,
    })
  }, [])

  const onOpenNewWindow = useCallback(() => {
    window.open(
      modalState.pdfUrl,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes',
    )
  }, [modalState.pdfUrl])

  return (
    <>
      <ShowingPdfViewerContext.Provider value={{ onOpenPdfViewer }}>
        {children}
      </ShowingPdfViewerContext.Provider>
      <PdfViewer
        value
        document={modalState.pdfUrl}
        showPopOver={modalState.open}
        setShowPopOver={onClosePdfViewer}
        onOpenNewTab={onOpenNewWindow}
        onOpenNewWindow={onOpenNewWindow}
      />
    </>
  )
}

UseShowingPdfViewerProvider.propTypes = {}

export default UseShowingPdfViewerProvider
