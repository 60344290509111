import { useCallback, useMemo, useState } from 'react'

import {
  DialogTruckFormData,
  IDialogTruckFormDataProps,
} from '~/components/shared'
import TruckFormContext from './TruckFormContext'

import type {
  ITruckFormProviderProps,
  ITruckFormProviderValues,
  ITruckFormValues,
} from './type'

function TruckFormProvider(props: ITruckFormProviderProps) {
  const { children } = props

  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState<ITruckFormValues | null>(null)
  const [formProps, setFormProps] = useState<
    Partial<IDialogTruckFormDataProps>
  >({})

  const onCloseForm = useCallback(() => {
    setIsOpen(false)
    setFormData(null)
  }, [])

  const onOpenTruckForm = useCallback(
    (
      formData: ITruckFormValues,
      formProps?: Partial<IDialogTruckFormDataProps>,
    ) => {
      setFormData(formData)
      setIsOpen(true)
      if (formProps) {
        setFormProps(formProps)
      }
    },
    [],
  )

  const onCloseTruckForm = useCallback(() => {
    setFormData(null)
    setIsOpen(false)
    setFormProps({})
  }, [])

  const onSubmit = useCallback(() => {
    setIsOpen(false)
    setFormData(null)
  }, [])

  const providerValues = useMemo<ITruckFormProviderValues>(
    () => ({ onOpenTruckForm, onCloseTruckForm }),
    [onCloseTruckForm, onOpenTruckForm],
  )

  return (
    <>
      <TruckFormContext.Provider value={providerValues}>
        {children}
      </TruckFormContext.Provider>
      <DialogTruckFormData
        isOpen={isOpen}
        truck={formData?.truck || null}
        truckFleet={formData?.truckFleet || null}
        onClose={onCloseForm}
        onSubmitTruck={onSubmit}
        onSubmitTruckFleet={onSubmit}
        {...formProps}
      />
    </>
  )
}

export default TruckFormProvider
