import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { useRouter } from '~/hooks/useRouter'
import { useQueryCompanies } from '~/hooks/useQueryData'

import clsx from 'clsx'
import { injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { updateCompanyById } from '~/redux/actions/CommonData/companiesActions'
import ProcessedDocumentForm from '~/components/shared/ProcessDocument/Form'
import { SUB_TABS_EDITCOMPANY } from '~/constants/invoice-type'
import { Body } from '~/components'
import { IonList, IonItem, IonLabel, IonRow } from '@ionic/react'
import {
  HeaderContent,
  GoogleSearchCompanyLogo,
  CompanyForm,
  CompanySettingAutomationForm,
} from '~/components/shared'
import ReactSelect from 'react-select'
import EditFieldValidationsForm from '~/components/company/EditFieldValidationsForm'
import EditLineItemsCarryDown from '~/components/company/EditLineItemsCarryDown'
import { companyRoutesPaths } from '~/constants/routesPaths'
import { COMPANY_SUB_TAB_VALUES } from '~/utils/constants'
import CompanyAddressTab from '~/components/company/CompanyAddressTab'
import CompanyEmailTab from '~/components/company/CompanyEmailTab'
import CompanyPhoneNumberTab from '~/components/company/CompanyPhoneNumberTab'
import CompanyBankAccountTab from '~/components/company/CompanyBankAccountTab'
import { replaceUrl } from '~/utils/buildUrl'

import 'react-toastify/dist/ReactToastify.css'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { CardsForm } from './CardsContainer/CardsForm'

const EditCompanyPage = props => {
  const { afterEditCompany, mode, className } = props
  const { loading, updateSuccess } = useSelector(state => state.myCompany)

  const isLayoutVertical = mode === 'vertical'

  const router = useRouter()

  const [currentTabValue, setCurrentTabValue] = useState(null)

  const companyId = useMemo(() => {
    return props.companyId || router.match.params.id
  }, [props.companyId, router.match.params.id])

  const {
    companiesData: [companyDetail],
    isLoadingCompaniesData,
    updateCompany,
  } = useQueryCompanies(
    {
      id: companyId,
    },
    { enabled: Boolean(companyId) },
  )

  const dispatch = useDispatch()

  const { isScreenDown, isScreenUp } = useBreakpoint()

  const currentTab = useMemo(() => {
    const findCompany = SUB_TABS_EDITCOMPANY.find(
      ({ value }) => currentTabValue === value,
    )
    return findCompany || SUB_TABS_EDITCOMPANY[0]
  }, [currentTabValue])

  const isUsingCompanyIdProp = Boolean(props.companyId)

  useEffect(() => {
    if (updateSuccess) {
      if (
        router.match.path === companyRoutesPaths.edit &&
        router.query.back_url
      ) {
        toast.success(toastMessages.editCompanyContainer.infoSuccess)
        router.push(router.query.back_url)
        return
      }

      if (afterEditCompany) {
        afterEditCompany(companyDetail)
      } else {
        toast.success(toastMessages.editCompanyContainer.infoSuccess)
        setTimeout(() => {
          router.history.goBack()
        }, 1000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateSuccess,
    afterEditCompany,
    router.history,
    router.query.back_ur,
    router.match.path,
    companyDetail,
  ])

  const handleSubmit = useCallback(
    async params => {
      let contrast_values = params
        .filter(conf => conf.key == 'increase_contrast')
        .map(conf => conf.inputs)[0]

      let company_params = {
        filePreProcessing: params
          .filter(conf => conf.active)
          .map(conf => conf.key),
        companyId: companyDetail.id,
        angle: params
          .filter(conf => conf.key == 'deskew')
          .map(conf => conf.inputs[0].value)[0],
        contrast: contrast_values.map(val => val.value),
      }

      dispatch(updateCompanyById(companyDetail.id, company_params))
    },
    [companyDetail?.id, dispatch],
  )

  const renderHeader = useCallback(
    () => ({
      backButton: true,
      headerTitle: 'Editing Company',
    }),
    [],
  )

  const handleSelectTab = newValue => {
    if (isUsingCompanyIdProp) {
      setCurrentTabValue(newValue.value)
    } else {
      const url = replaceUrl(router.pathname, {
        view: newValue.value,
      })
      router.push(url)
    }
  }

  const menuOptions = useMemo(() => {
    if (isUsingCompanyIdProp) {
      const ignoredFields = [
        COMPANY_SUB_TAB_VALUES.payroll,
        COMPANY_SUB_TAB_VALUES.address,
        COMPANY_SUB_TAB_VALUES.email,
        COMPANY_SUB_TAB_VALUES.phoneNumbers,
        COMPANY_SUB_TAB_VALUES.bankAccounts,
        COMPANY_SUB_TAB_VALUES.financialApplicationData,
      ]
      return SUB_TABS_EDITCOMPANY.filter(
        ({ value }) => ignoredFields.includes(value) === false,
      )
    }

    return SUB_TABS_EDITCOMPANY
  }, [isUsingCompanyIdProp])

  const subtabRenderer = useMemo(() => {
    switch (currentTabValue) {
      case SUB_TABS_EDITCOMPANY[2].value: {
        return (
          <ProcessedDocumentForm
            company={companyDetail}
            title={'Update Configurations'}
            onSubmit={(params, document = null) =>
              handleSubmit(params, document)
            }
          />
        )
      }

      case SUB_TABS_EDITCOMPANY[3].value: {
        return <EditFieldValidationsForm companyId={companyDetail?.id} />
      }

      case SUB_TABS_EDITCOMPANY[4].value: {
        return <EditLineItemsCarryDown companyId={companyDetail?.id} />
      }

      case COMPANY_SUB_TAB_VALUES.address: {
        return <CompanyAddressTab companyId={companyId} />
      }

      case COMPANY_SUB_TAB_VALUES.email: {
        return <CompanyEmailTab companyId={companyId} />
      }

      case COMPANY_SUB_TAB_VALUES.phoneNumbers: {
        return <CompanyPhoneNumberTab companyId={companyId} />
      }

      case COMPANY_SUB_TAB_VALUES.bankAccounts: {
        return <CompanyBankAccountTab companyId={companyId} />
      }

      case COMPANY_SUB_TAB_VALUES.companyDetails: {
        return (
          <CompanyForm
            canSkipAddressSetup
            canSkipEmailSetup
            canSkipPhoneNumberSetup
            hiddenFields={[
              'setup.owner',
              'phoneNumber',
              'phoneTypes',
              'email',
              'emailTypes',
              'addresses',
              'suggestions',
            ]}
            formData={{
              ...companyDetail,
              seller: companyDetail?.seller === EYesNo.Yes ? true : false,
              buyer: companyDetail?.buyer === EYesNo.Yes ? true : false,
              fleet: companyDetail?.fleet === EYesNo.Yes ? true : false,
              image: companyDetail?.logo,
            }}
            afterUpdate={company => {
              updateCompany(company.id, company)
            }}
          />
        )
      }

      case COMPANY_SUB_TAB_VALUES.settingAutomation: {
        return (
          <CompanySettingAutomationForm
            formData={companyDetail}
            afterUpdate={company => {
              updateCompany(company.id, company)
            }}
          />
        )
      }

      case COMPANY_SUB_TAB_VALUES.financialApplicationData: {
        return <CardsForm />
      }

      default: {
        return (
          <CompanyForm
            canSkipAddressSetup
            canSkipEmailSetup
            canSkipPhoneNumberSetup
            isLoading={isLoadingCompaniesData}
            hiddenFields={[
              'setup.owner',
              'phoneNumber',
              'phoneTypes',
              'email',
              'emailTypes',
              'addresses',
              'suggestions',
            ]}
            formData={{
              ...companyDetail,
              seller: companyDetail?.seller === EYesNo.Yes ? true : false,
              buyer: companyDetail?.buyer === EYesNo.Yes ? true : false,
              fleet: companyDetail?.fleet === EYesNo.Yes ? true : false,
              image: companyDetail?.logo,
            }}
            afterUpdate={company => {
              updateCompany(company.id, company)
            }}
          />
        )
      }
    }
  }, [
    currentTabValue,
    companyDetail,
    handleSubmit,
    companyId,
    updateCompany,
    isLoadingCompaniesData,
  ])

  useEffect(() => {
    setCurrentTabValue(router.query.view)
  }, [router.query.view])

  return (
    <>
      {!props.companyId && <HeaderContent renderContent={renderHeader} />}
      <Body
        loading={loading}
        wrapClassName={clsx('EditCompanyContainer__bodyContainer', className)}
      >
        {(isScreenDown('md') || isLayoutVertical) && (
          <div className='ion-padding'>
            <ReactSelect
              value={currentTab}
              options={menuOptions}
              onChange={handleSelectTab}
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                menu: provided => ({ ...provided, fontSize: 14, zIndex: 9999 }),
                singleValue: provided => ({ ...provided, fontSize: 14 }),
              }}
              getOptionValue={option => option.heading}
              getOptionLabel={option => option.label}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}

        {isScreenUp('md') && !isLayoutVertical && (
          <div className='sub-menu-column'>
            <IonList>
              {menuOptions.map(sub => (
                <IonItem
                  key={sub.heading}
                  className={
                    sub?.label == currentTab?.label
                      ? 'submenu-profile-item --active'
                      : 'submenu-profile-item --passive'
                  }
                  onClick={() => {
                    const url = replaceUrl(router.pathname, {
                      view: sub.value,
                    })
                    router.push(url)
                  }}
                  lines='full'
                >
                  <IonLabel>{sub.label}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </div>
        )}

        <div
          className={clsx('settingForm', {
            EditCompanyContainer__settingForm:
              isScreenDown('md') || isLayoutVertical,
          })}
        >
          <IonRow>
            <IonItem lines='none'>
              <div
                style={{ backgroundColor: currentTab?.color }}
                className='colorBox'
              ></div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonLabel className='labelProfile'>
                  {currentTab?.heading}
                </IonLabel>
                {!companyDetail?.imageUrl &&
                  currentTab?.heading === 'Company Details' && (
                    <GoogleSearchCompanyLogo
                      style={{ marginLeft: 8 }}
                      companyName={companyDetail?.name}
                    />
                  )}
              </div>
            </IonItem>
          </IonRow>

          <div>{subtabRenderer}</div>
        </div>
      </Body>
    </>
  )
}

EditCompanyPage.defaultProps = {
  mode: 'horizontal',
}

export default injectIntl(EditCompanyPage)
