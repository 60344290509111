import { CommonDialogV2, ICommonDialogProps } from '~/components/shared'
import TerminalDetails from './TerminalDetails'
import { useQueryTerminals } from '~/hooks/useQueryData'

import './TerminalContainer.scss'

export interface IDialogTerminalDetailsProps extends ICommonDialogProps {
  id: number
}

function DialogTerminalDetails(props: IDialogTerminalDetailsProps) {
  const { id, ...dialogProps } = props

  const {
    terminalsData: [terminalData],
    isLoadingTerminals,
  } = useQueryTerminals({
    id,
  })

  return (
    <CommonDialogV2
      {...dialogProps}
      className='DialogTerminalDetails__container'
      isHiddenOkButton
      loading={isLoadingTerminals}
      fullscreen
      title={terminalData ? `${terminalData.code} - ${terminalData.name}` : ''}
    >
      <TerminalDetails id={id} />
    </CommonDialogV2>
  )
}

export default DialogTerminalDetails
