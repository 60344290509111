import _ from 'lodash'
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { toast } from 'react-toastify'
import PusherPlugin from '~/plugins/Pusher'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import { sessionService } from 'redux-react-session'
import humps from 'humps'
import { AUTH_TOKEN } from '~/utils/constants'
import { PushNotifications } from '@capacitor/push-notifications'

class PusherService {
  constructor() {
    // this.BEAMS_AUTH_ENDPOINT = 'http://192.168.1.8:8000/beams/auth'
    this.BEAMS_AUTH_ENDPOINT = `${process.env.REACT_APP_API_URL}/pusher/auth`
    this.INSTANCE_ID = process.env.REACT_APP_BEAMS_INSTANCE_ID

    if (!isMobileApp()) {
      this.beamsClient = new PusherPushNotifications.Client({
        instanceId: this.INSTANCE_ID,
      })
    }
  }

  registerBeamsService = () => {
    try {
      return this.beamsClient.start()
    } catch (error) {
      console.log('error registerBeamsService', error)
      toast.error(
        'An error has occurred while trying to connect to the Push services.',
      )
      return Promise.resolve({})
    }
  }

  checkNotificationPermission = async () => {
    try {
      if (isMobileApp()) {
        const result = await PushNotifications.checkPermissions()

        return result.receive !== 'denied'
      }
      const result = Notification.permission

      return result !== 'denied'
    } catch (error) {
      console.log('error', error)

      return false
    }
  }

  registerPushNotificationToSpecificUser = async userId => {
    const isGrantedPermission = await this.checkNotificationPermission()
    if (!isGrantedPermission) {
      return Promise.resolve({})
    }

    if (isMobileApp()) {
      return this.registerMobilePushNotificationToSpecificUser(userId)
    } else {
      return this.registerWebPushNotificationToSpecificUser(userId)
    }
  }

  async getHeaderRequests() {
    const session = await sessionService.loadSession()

    return {
      'access-token': session.token,
      uid: session.uid,
      client: session.client,
      auth_token: AUTH_TOKEN,
      accept: 'application/json',
      'content-type': 'application/json',
    }
  }

  registerWebPushNotificationToSpecificUser = async userId => {
    try {
      const { authToken, userAccess, id, currentScope, currentFleet } =
        await sessionService.loadUser()

      const payload = {
        userId: _.toString(id),
        userToken: authToken,
        userAccessId: _.toString(userAccess?.id),
        accessToken: userAccess?.authToken,
        scope: currentScope,
      }

      if (_.size(currentFleet) > 0) {
        payload.fleetId = _.toString(currentFleet.id)
      }

      const queryParams = humps.decamelizeKeys(payload)
      const headers = await this.getHeaderRequests()

      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: this.BEAMS_AUTH_ENDPOINT,
        queryParams,
        headers,
      })
      await this.beamsClient.start()
      return this.beamsClient.setUserId(_.toString(userId), beamsTokenProvider)
    } catch (error) {
      console.log('error registerWebPushNotificationToSpecificUser', error)
      toast.error(
        'An error has occurred while trying to connect to the Push services.',
      )
      return Promise.resolve()
    }
  }

  registerMobilePushNotificationToSpecificUser = async () => {
    try {
      const { authToken, userAccess, id, currentScope, currentFleet } =
        await sessionService.loadUser()

      const header = await this.getHeaderRequests()

      const payload = {
        userId: _.toString(id),
        authEndpoint: this.BEAMS_AUTH_ENDPOINT,
        instanceId: this.INSTANCE_ID,
        userToken: authToken,
        userAccessId: _.toString(userAccess?.id),
        accessToken: userAccess?.authToken,
        scope: currentScope,
        headerAccessToken: header['access-token'],
        headerUid: header.uid,
        headerClient: header.client,
        headerAuthToken: header.auth_token,
        headerAccept: header.accept,
        headerContentType: header['content-type'],
      }

      if (_.size(currentFleet) > 0) {
        payload.fleetId = _.toString(currentFleet.id)
      }

      return PusherPlugin.registerPushNotificationToSpecificUser(payload)
    } catch (error) {
      console.log('error', error)
      toast.error(
        'An error has occurred while trying to connect to the Push services.',
      )
      return Promise.resolve()
    }
  }

  stopBeamsService = async () => {
    try {
      const isGrantedPermission = await this.checkNotificationPermission()

      if (!isGrantedPermission) {
        return Promise.reject(
          'You have not been granted permission for Push services.',
        )
      }

      if (isMobileApp()) {
        return PusherPlugin.clearAllState()
      } else {
        return this.beamsClient.clearAllState()
      }
    } catch (error) {
      console.log('error', error)
      console.log('An error has occurred while trying to stop Push services.')
      return Promise.reject('There was an unknown error.')
    }
  }
}

export default PusherService
