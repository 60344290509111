import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ConcordFormDropdownV2,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import {
  useQueryBuyerSellerProducts,
  useQueryBuyerSellers,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType, EScope, EStatus } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'

import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { IBTFSTPFormProps } from './type'
import { IBtfstpFormData } from '~/types/models/IBtfstp'
import {
  BILLLING_METHOD_OPTIONS,
  DELIVERY_METHOD_OPTIONS,
  REMINDER_CADENCE_OPTIONS,
  WEEKEND_TYPE_OPTIONS,
} from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'

function BtfstpForm(props: IBTFSTPFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [buyerId, setBuyerId] = useState<number | null>(null)
  const [sellerId, setSellerId] = useState<number | null>(null)

  const {
    buyerCompanyOptionsWithCurrentCompany,
    sellerCompanyOptionsWithCurrentCompany,
    fleetCompanies,
  } = useQueryBuyerSellers()

  const { buyerSellerProductOptions, isLoadingBuyerSellerProducts } =
    useQueryBuyerSellerProducts(
      {
        filters: {
          joinsSellerProduct: true,
        },
      },
      { enabled: Boolean(sellerId && buyerId) },
    )

  const { terminalsData, isLoadingTerminals } = useQueryTerminals()

  const formRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const isUpdating = Boolean(formData?.id)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'buyerId',
        label: 'Buyer',
        size: 6,
        render({ error, clearErrors, setValue }) {
          return (
            <ConcordFormDropdownV2
              label='Buyer'
              isReadOnly={currentScope === EScope.buyer}
              options={buyerCompanyOptionsWithCurrentCompany}
              isRequired
              value={buyerId as any}
              onChange={(event, { value }) => {
                setBuyerId(value)
                clearErrors(['buyerId'])
                setValue('buyerTerminalId', null)
              }}
              error={error}
            />
          )
        },
      },
      {
        name: 'buyerTerminalId',
        label: 'Buyer Terminal',
        type: EFieldType.singleSelect,
        size: 6,
        isLoading: isLoadingTerminals,
        isDisabled: !buyerId,
        isRequired: true,
        options: terminalsData
          .filter(({ companyId }) => companyId === buyerId)
          .map(({ code, name, id }) => ({
            value: id,
            label: `${code} - ${name}`,
          })),
      },
      {
        name: 'sellerId',
        label: 'Seller',
        size: 6,
        render({ error, clearErrors, setValue }) {
          return (
            <ConcordFormDropdownV2
              label='Seller'
              isReadOnly={currentScope === EScope.seller}
              options={sellerCompanyOptionsWithCurrentCompany}
              isRequired
              value={sellerId as any}
              onChange={(event, { value }) => {
                setSellerId(value)
                clearErrors(['sellerId'])
                setValue('sellerTerminalId', null)
                setValue('productId', null)
              }}
              error={error}
            />
          )
        },
      },
      {
        name: 'sellerTerminalId',
        label: 'Seller Terminal',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        isLoading: isLoadingTerminals,
        isDisabled: !sellerId,
        options: terminalsData
          .filter(({ companyId }) => companyId === sellerId)
          .map(({ code, name, id }) => ({
            value: id,
            label: `${code} - ${name}`,
          })),
      },
      {
        name: 'buyerSellerProductId',
        label: 'Product',
        size: 12,
        isLoading: isLoadingBuyerSellerProducts,
        isDisabled: !sellerId,
        options: buyerSellerProductOptions,
        type: EFieldType.singleSelect,
        isRequired: true,
      },
      {
        name: 'deliveryMethod',
        label: 'Delivery Method',
        size: 6,
        options: DELIVERY_METHOD_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'billingMethod',
        label: 'Billing Method',
        size: 6,
        options: BILLLING_METHOD_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'buyerTerminalProductSchedule.reminderCadence',
        label: 'Reminder Cadence',
        size: 6,
        options: REMINDER_CADENCE_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'buyerTerminalProductSchedule.weekendType',
        label: 'Weekend Type',
        size: 6,
        options: WEEKEND_TYPE_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'buyerTerminalProductSchedule.fobPrice',
        label: 'FOB Price',
        size: 6,
        type: EFieldType.number,
      },
      {
        name: 'buyerTerminalProductSchedule.discount',
        label: 'FOB Discount',
        size: 6,
        type: EFieldType.number,
      },
      {
        name: 'deliveredPrice',
        label: 'Delivered Price',
        size: 6,
        type: EFieldType.number,
        isRequired: true,
      },
      {
        name: 'discount',
        label: 'Delivered Discount',
        size: 6,
        type: EFieldType.number,
      },
      {
        name: 'loadSize',
        label: 'Load Size',
        size: 4,
        type: EFieldType.number,
      },
      {
        name: 'lds',
        label: 'LDs',
        size: 4,
        type: EFieldType.number,
      },
      {
        name: 'qty',
        label: 'Qty',
        size: 4,
        type: EFieldType.number,
        isRequired: true,
      },
      {
        name: 'buyerTerminalProductSchedule.priorityDeadline',
        label: 'Priority Deadline',
        size: 4,
      },
      {
        name: 'buyerTerminalProductSchedule.targetDeadline',
        label: 'Target Deadline',
        size: 4,
      },
      {
        name: 'buyerTerminalProductSchedule.finalDeadline',
        label: 'Final Deadline',
        size: 4,
      },
      {
        name: 'buyerFromSellerTerminalProduct.code',
        label: 'Buyer Product Code',
        size: 6,
      },
      {
        name: 'buyerFromSellerTerminalProduct.description',
        label: 'Buyer Product Description',
        size: 6,
      },
      {
        name: 'autoAssignFleet.id',
        label: 'Fleet',
        size: 12,
        type: EFieldType.singleSelect,
        options: fleetCompanies.map(({ id, code, name }) => ({
          value: id,
          label: `${name} - ${code}`,
        })),
      },
      {
        name: 'status',
        label: 'Status',
        type: EFieldType.radio,
        options: [
          {
            label: 'Active',
            value: EStatus.Active,
          },
          {
            label: 'Inactive',
            value: EStatus.Inactive,
          },
        ],
      },
    ],
    [
      buyerCompanyOptionsWithCurrentCompany,
      buyerId,
      buyerSellerProductOptions,
      currentScope,
      fleetCompanies,
      isLoadingBuyerSellerProducts,
      isLoadingTerminals,
      sellerCompanyOptionsWithCurrentCompany,
      sellerId,
      terminalsData,
    ],
  )

  const defaultValues: IBtfstpFormData = {
    buyerId: null,
    sellerId: null,
    buyerTerminalId: null,
    sellerTerminalId: null,
    productId: null,
    qty: null,
    status: EStatus.Active,
  }

  const schema = Yup.object({
    sellerId: Yup.lazy(() => {
      if (sellerId) {
        return Yup.mixed().nullable()
      }
      return Yup.number()
        .required('This field is required!')
        .typeError('This field is required!')
    }),
    buyerId: Yup.lazy(() => {
      if (buyerId) {
        return Yup.mixed().nullable()
      }
      return Yup.number()
        .required('This field is required!')
        .typeError('This field is required!')
    }),
    buyerTerminalId: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
    sellerTerminalId: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
    buyerSellerProductId: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
    deliveredPrice: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
    qty: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
  })

  const create = async (formValues: IBtfstpFormData) => {
    const response = await apiClient.btfstps.create({
      buyerTerminalFromSellerTerminalProduct: {
        ...formValues,
        sellerId,
        buyerId,
        noBuyerFromSellerTerminalProduct: true,
      },
    })
    if (response.errors.length > 0) {
      setBackendError(toastMessages.createError)
    } else {
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const update = async (formValues: IBtfstpFormData) => {
    const response = await apiClient.btfstps.update(formData?.id as number, {
      buyerTerminalFromSellerTerminalProduct: {
        ...formValues,
        sellerId,
        buyerId,
        noBuyerFromSellerTerminalProduct: true,
      },
    })
    if (response.errors.length > 0) {
      setBackendError(toastMessages.updateError)
    } else {
      afterUpdate && afterUpdate(response)
      toast.success(toastMessages.updateSuccess)
    }
  }

  const onSubmitForm = async (formValues: IBtfstpFormData) => {
    setIsLoading(true)
    setBackendError('')
    try {
      if (isUpdating) {
        await update(formValues)
      } else {
        await create(formValues)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isUpdating) {
      if (currentScope === EScope.buyer) {
        setBuyerId(currentCompany.id)
      } else {
        setSellerId(currentCompany.id)
      }
    }
  }, [currentCompany.id, currentScope, isUpdating])

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
      ref={formRef}
      isHiddenCancelButton
      onSubmit={onSubmitForm}
    />
  )
}

export default BtfstpForm
