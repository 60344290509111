import React from 'react'
import { IonButtons, IonButton, IonLabel, IonIcon } from '@ionic/react'
import { openOutline } from 'ionicons/icons'

const MoreDetailsButton = props => {
  const { onClick, title, style } = props

  return (
    <IonButtons style={style}>
      <IonButton onClick={onClick} color='dark'>
        <IonLabel
          style={{
            fontSize: '16px',
            marginLeft: '8px',
          }}
        >
          {title || 'More details'}
        </IonLabel>
        <span onClick={onClick}>
          <IonIcon
            style={{ marginLeft: '3px' }}
            color='primary'
            icon={openOutline}
          />
        </span>
      </IonButton>
    </IonButtons>
  )
}

export default MoreDetailsButton
