import { createSelector } from 'reselect'

export const selectCompanyBankAccountsDataState = state =>
  state.data.companyBankAccounts

export const selectCompanyBankAccountsUiState = state =>
  state.ui.companyBankAccounts

export const selectCompanyBankAccountsData = createSelector(
  selectCompanyBankAccountsDataState,
  state => Object.values(state),
)

export const selectCompanyBankAccountsLoadingTable = createSelector(
  selectCompanyBankAccountsUiState,
  state => state.loadingTable,
)
