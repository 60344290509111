import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSellerProducts } from '~/redux/actions/sellerProductActions'
import { selectSellerProductsLastFetchedAt } from '~/redux/selectors'

export const useInitializeSellerProducts = () => {
  const dispatch = useDispatch()
  const lastFetchedAt = useSelector(selectSellerProductsLastFetchedAt)

  useEffect(() => {
    if (!lastFetchedAt) {
      dispatch(fetchSellerProducts())
    }
  }, [dispatch, lastFetchedAt])
}
