import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_PARSER_COLUMNS } from '~/constants/companySubTabs'

const parsersSlice = createSlice({
  name: moduleName.company.parsers,
  initialState: {
    columns: DEFAULT_COMPANY_PARSER_COLUMNS,
  },
})

export default parsersSlice
