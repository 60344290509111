import { useMemo, useEffect, useState, useCallback } from 'react'

import { ConcordFormStructure } from '~/components/shared'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import { userAccessSchema } from './schemas'
import { apiClient } from '~/api/ApiClient'

function UserAccessTab(props) {
  const [userGroups, setUserGroups] = useState([])

  const defaultValues = useMemo(
    () => ({
      userGroupId: 65,
    }),
    [],
  )

  const userGroupOptions = useMemo(
    () =>
      userGroups.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [userGroups],
  )

  const fields = useMemo(
    () => [
      {
        name: 'userGroupId',
        label: 'User Group',
        type: FORM_FIELD_TYPE.singleSelect,
        options: userGroupOptions,
        isRequired: true,
      },
    ],
    [userGroupOptions],
  )

  const getUserGroups = useCallback(async () => {
    const response = await apiClient.userGroups.get()
    setUserGroups(response.userGroups)
  }, [])

  useEffect(() => {
    getUserGroups()
  }, [getUserGroups])

  return (
    <ConcordFormStructure
      fields={fields}
      schema={userAccessSchema}
      isHiddenCancelButton
      submitText='Create'
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}

UserAccessTab.propTypes = {}

export default UserAccessTab
