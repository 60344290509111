import { useCallback, useState, useEffect, useMemo } from 'react'

import { IonLabel, IonRadioGroup, IonRadio } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import './ConcordFormRadio.scss'
import '../ConcordForm.scss'

const ConcordFormRadio = props => {
  const {
    label,
    options,
    value,
    onChange,
    className,
    isDisabled,
    isRequired,
    defaultValue: defaultValueProps,
  } = props

  const [radioValue, setRadioValue] = useState(null)
  const [defaultValue, setDefaultValue] = useState(null)

  const mapRadioValue = useMemo(() => {
    if (_.isNil(radioValue)) {
      return defaultValue
    }

    return radioValue
  }, [defaultValue, radioValue])

  const handleChange = useCallback(
    ({ value }) =>
      event => {
        onChange && onChange(event, value)
      },
    [onChange],
  )

  const renderOptions = useCallback(() => {
    return options.map((option, index) => (
      <ToolTipOverlay key={index} {...option.tooltipProps}>
        <div className='ConcordFormRadio__option'>
          <IonRadio
            value={option.value}
            className='ConcordFormRadio__optionRadio'
            disabled={option.disabled}
            onClick={handleChange(option)}
          />
          <IonLabel className='ConcordFormRadio__optionLabel'>
            {option.label}
          </IonLabel>
        </div>
      </ToolTipOverlay>
    ))
  }, [handleChange, options])

  useEffect(() => {
    setRadioValue(value)
  }, [value])

  useEffect(() => {
    setDefaultValue(defaultValueProps)
  }, [defaultValueProps])

  return (
    <div
      className={clsx(
        'ConcordFormRadio__container',
        'ConcordForm__container',
        className,
      )}
    >
      <IonLabel className='ConcordFormRadio__label ConcordForm__label'>
        {label}
        {isRequired && (
          <span className='error' style={{ marginLeft: 4 }}>
            *
          </span>
        )}
      </IonLabel>
      <IonRadioGroup
        className={clsx('ConcordFormRadio__optionContainer', { isDisabled })}
        value={mapRadioValue}
      >
        {renderOptions()}
      </IonRadioGroup>
    </div>
  )
}

ConcordFormRadio.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
}

export default ConcordFormRadio
