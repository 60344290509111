import { useMemo, useState } from 'react'
import { apiClient } from '~/api/ApiClient'
import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { useQueryProductTypes } from '~/hooks/useQueryData'
import { EFieldType } from '~/types/enums/ECommonEnum'
import * as Yup from 'yup'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IProductFormProps } from './type'
import { IProductFormData } from '~/types/models/IProduct'
import { EProductPrimary } from '~/types/enums/EProduct'
import { toast } from 'react-toastify'

function ProductForm(props: IProductFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const { productTypeOptions, isProductTypesLoading } = useQueryProductTypes()

  const isUpdating = Boolean(formData?.id)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'name',
        label: 'Name',
        size: 6,
        isRequired: true,
      },
      {
        name: 'code',
        label: 'Code',
        size: 6,
        isRequired: true,
      },
      {
        name: 'description',
        label: 'Description',
        as: 'textarea',
        rows: 3,
      },
      {
        name: 'primary',
        label: 'Primary',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: Object.keys(EProductPrimary).map(field => ({
          value: field,
          label: field,
        })),
      },
      {
        name: 'productTypeId',
        label: 'Product Type',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: productTypeOptions,
        isLoading: isProductTypesLoading,
      },
    ],
    [isProductTypesLoading, productTypeOptions],
  )

  const defaultValues: IProductFormData = {
    code: '',
    name: '',
    description: '',
    primary: EProductPrimary.Material,
    productTypeId: null,
  }

  const schema = Yup.object({
    code: Yup.string().required('This field is required!'),
    name: Yup.string().required('This field is required!'),
    primary: Yup.string().required('This field is required!'),
    productTypeId: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
  })

  const create = async (formValues: IProductFormData) => {
    const response = await apiClient.products.create({
      product: formValues,
    })
    afterCreate && afterCreate(response)
    toast.success(toastMessages.createSuccess)
  }

  const update = async (formValues: IProductFormData) => {
    const payload = _.pick(formValues, [
      'code',
      'name',
      'description',
      'primary',
      'productTypeId',
    ])
    const response = await apiClient.products.update(formData?.id as number, {
      product: payload,
    })
    afterUpdate && afterUpdate(response)
    toast.success(toastMessages.updateSuccess)
  }

  const onSubmitForm = async (formValues: IProductFormData) => {
    setIsLoading(true)
    setBackendError('')
    try {
      if (isUpdating) {
        await update(formValues)
      } else {
        await create(formValues)
      }
    } catch (error) {
      const errorKeys = Object.keys(error || {})
      if (errorKeys.length > 0) {
        const message = (error as any)[errorKeys[0]][0]
        const key = errorKeys[0]
        setBackendError(`${_.startCase(key)}: ${message}`)
      } else {
        setBackendError(toastMessages.serverError)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      onSubmit={onSubmitForm}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default ProductForm
