import { CommonDialogV2 } from '~/components/shared'
import GlCodeForm from './GlCodeForm'

import type { IDialogAllowedHierarchyFormProps } from './type'

function DialogAllowedHierarchyForm(props: IDialogAllowedHierarchyFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  return (
    <CommonDialogV2
      {...modalProps}
      title='GL Code'
      isHiddenOkButton
      onClose={onClose}
    >
      <GlCodeForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogAllowedHierarchyForm
