import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBuyerSellerProducts } from '~/redux/actions/CommonData/buyerSellerProductsActions'
import { selectBuyerSellerProductsLastFetchedAt } from '~/redux/selectors/selectLastFetchedAt'

export const useInitializeBuyerSellerProducts = () => {
  const lastUpdated = useSelector(selectBuyerSellerProductsLastFetchedAt)
  const dispatch = useDispatch()

  useEffect(() => {
    !lastUpdated && dispatch(getBuyerSellerProducts())
  }, [lastUpdated, dispatch])
}
