import { apiClient } from '~/api/ApiClient'
import buyerSellerProductsSlice from '~/redux/reducers/data/buyerSellerProducts'

export const getBuyerSellerProducts = () => dispatch => {
  apiClient.buyerSellerProducts.get()
    .then(response => {
      dispatch(
        buyerSellerProductsSlice.actions.initializeBuyerSellerProducts(
          response,
        ),
      )
    })
    .catch(error => console.log(error))
}

export const updateBuyerSellerProduct = bsp => dispatch => {
  dispatch(buyerSellerProductsSlice.actions.updateBuyerSellerProduct(bsp))
}

export const updateBuyerSellerProducts = payload => dispatch => {
  dispatch(buyerSellerProductsSlice.actions.updateBuyerSellerProducts(payload))
}

export const initBuyerSellerProducts = bsp => dispatch => {
  dispatch(buyerSellerProductsSlice.actions.initializeBuyerSellerProducts(bsp))
}
