import { useCallback } from 'react'

import { ConcordFormCheckbox } from '~/components/shared'
import { FloatingInputV2 } from '~/components/shared/FloatingForm/FloatingInputV2'

import type { IReciprocityInputProps } from './type'

import './styles.scss'

function ReciprocityInput(props: IReciprocityInputProps) {
  const { data, onChange } = props

  const onChangeEffectiveStart = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ value }: any) => {
      onChange({
        ...data,
        effectiveStart: value,
      })
    },
    [data, onChange],
  )

  const onChangeOptIn = useCallback(
    (event: Event, checked: boolean) => {
      onChange({
        ...data,
        optIn: checked,
      })
    },
    [data, onChange],
  )

  return (
    <div className='ReciprocityInput__container'>
      <FloatingInputV2
        label='Jurisdiction'
        className='jurisdiction'
        value={data.jurisdictionName}
        isDisabled
      />
      <FloatingInputV2
        label='Effective Start'
        inputType='date'
        className='effectiveStart'
        value={data.effectiveStart}
        onChange={onChangeEffectiveStart}
      />
      <ConcordFormCheckbox
        label='Opt In'
        className='optIn'
        value={data.optIn}
        onChange={onChangeOptIn}
      />
    </div>
  )
}
export default ReciprocityInput
