import React, { useState } from 'react'
import {
  IonCol,
  IonButton,
  IonRow,
  IonText,
  IonToggle,
  IonItem,
} from '@ionic/react'
import update from 'immutability-helper'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import 'react-toastify/dist/ReactToastify.css'

const CONFIGURATIONS_DEFAULTS = [
  {
    key: 'deskew',
    label: 'Deskew',
    active: false,
    color: 'primary',
    inputs: [{ label: 'Angle', value: '' }],
  },
  {
    key: 'increase_contrast',
    label: 'Increase Contrast',
    active: false,
    color: 'secondary',
    inputs: [
      { label: 'Level 1', value: '' },
      { label: 'Level 2', value: '' },
    ],
  },
  { key: 'despeckling', label: 'Despeckling', active: false, color: 'danger' },
  {
    key: 'blur_correction',
    label: 'Blur Correction',
    active: false,
    color: 'medium',
  },
  {
    key: 'trapezoid_distortion',
    label: 'Trapezoid Distortion',
    active: false,
    color: 'dark',
  },
  {
    key: 'image_binarization',
    label: 'Image Binarization',
    active: false,
    color: 'tertiary',
  },
]

const Form = ({ document, onSubmit }) => {
  const [processingConfiguration, setProcessingConfiguration] = useState(
    CONFIGURATIONS_DEFAULTS,
  )

  const toggleConfiguration = configuration => {
    let filteredConfig = processingConfiguration.filter(
      conf => conf.key == configuration,
    )[0]
    filteredConfig['active'] = !filteredConfig['active']
    let index = processingConfiguration.findIndex(
      conf => conf.key == configuration,
    )
    let updatedConfigurations = update(processingConfiguration, {
      $splice: [[index, 1, filteredConfig]],
    })
    setProcessingConfiguration(updatedConfigurations)
  }

  const checkValidation = (text, conf) => {
    if (conf.key == 'deskew') {
      return text.target.value > 0 && text.target.value <= 360
    } else if (conf.key == 'increase_contrast') {
      return text.target.value > 0 && text.target.value <= 100
    }
  }

  const bindValuesAndSendToParser = async () => {
    await onSubmit(processingConfiguration, document)

    toast.success(toastMessages.preProcessingTest.configurationUpdated)
  }

  const bindInputValues = (value, field, configuration) => {
    let input = configuration.inputs.filter(input => field == input.label)[0]
    input['value'] = value
    let confIndex = processingConfiguration.findIndex(
      conf => conf.key == configuration.key,
    )
    let updatedConfigurations = update(processingConfiguration, {
      $splice: [[confIndex, 1, configuration]],
    })
    setProcessingConfiguration(updatedConfigurations)
  }

  return (
    <div>
      {processingConfiguration &&
        processingConfiguration.map(conf => (
          <IonItem key={conf.key}>
            <IonToggle
              color={conf.color}
              onClick={() => toggleConfiguration(conf.key)}
              checked={conf.active}
            />
            <IonText style={{ width: '30%' }}>{conf.label}</IonText>
            {conf.active && (
              <IonRow style={styles.row}>
                {conf.inputs &&
                  conf.inputs.map(field => (
                    <IonCol key={field.label}>
                      <input
                        min={0}
                        max={conf.key == 'deskew' ? 360 : 100}
                        placeholder={`${field.label} ${
                          conf.key == 'deskew' ? '(0-360)' : '(1%-100%)'
                        }:`}
                        value={field.value}
                        type='number'
                        style={styles.input}
                        onChange={text => {
                          if (checkValidation(text, conf)) {
                            bindInputValues(
                              text.target.value,
                              field.label,
                              conf,
                            )
                          }
                        }}
                      />
                    </IonCol>
                  ))}
              </IonRow>
            )}
          </IonItem>
        ))}

      <IonButton
        style={{ marginTop: 0 }}
        expand='block'
        onClick={() => {
          bindValuesAndSendToParser()
        }}
      >
        Test Pre-processing Configurations
      </IonButton>
    </div>
  )
}
const styles = {
  input: {
    border: '1px solid lightgreen',
    fontSize: 18,
    backgroundColor: 'transparent',
    width: '70%',
  },
  row: { width: '70%' },
}

export default Form
