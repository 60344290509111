import _ from 'lodash'
import { DUE_DATE_DAYS, DUE_DATE_PERIOD } from '~/utils/constants'

export const getTerminals = (data, hasParent = true) => {
  const rowData = []

  data.forEach(({ customerId, invoices }) => {
    if (hasParent) {
      rowData.push({
        customerId,
        invoices,
      })
    }

    const buyerTerminalIds = _.uniq(
      invoices.map(invoice => invoice.buyerTerminalId),
    )
    buyerTerminalIds.forEach(buyerTerminalId => {
      rowData.push({
        customerId: buyerTerminalId,
        isTerminal: true,
        invoices: invoices.filter(i => i.buyerTerminalId === buyerTerminalId),
      })
    })
  })
  return rowData
}

export const getInvoices = (data, hasParent = true) => {
  const rowData = []
  data.forEach(({ customerId, invoices }) => {
    if (hasParent) {
      rowData.push({
        customerId,
        invoices,
      })
    }
    invoices.forEach(invoice => {
      rowData.push({
        customerId: null,
        isInvoice: true,
        invoice,
        invoices: [invoice],
      })
    })
  })
  return rowData
}

export const getArData = (data, view) => {
  if (view === 'Terminal') {
    const rowData = getTerminals(data)
    return rowData
  }

  if (view === 'Invoice') {
    const rowData = getInvoices(data)
    return rowData
  }

  if (view === 'All') {
    const terminals = getTerminals(data)
    const invoices = getInvoices(data, false)

    return [...terminals, ...invoices]
  }

  return data
}

export const getDueDateDays = ({ invoices }, asOfDate) => {
  return DUE_DATE_DAYS.map(day => {
    const withinRange = invoices.filter(({ dueDate }) => {
      const invoiceDueDate = new Date(dueDate)
      const today = asOfDate ? new Date(asOfDate) : new Date()

      const diffDays =
        (today.getTime() - invoiceDueDate.getTime()) / (1000 * 3600 * 24)
      const lower = day - DUE_DATE_PERIOD
      const upper = day
      if (diffDays <= upper && diffDays > lower) return true
      if (upper === 0 && diffDays < 0) return true
      if (upper === 91 && diffDays > 90) return true
      return false
    })

    return withinRange
  })
}

export const getTotal = values =>
  values.reduce((acc, val) => acc + parseFloat(val.balance), 0)
