import { useState, useMemo, useEffect } from 'react'

import Fuse from 'fuse.js'
import _ from 'lodash'

export type IExtraFuzzyResult = {
  percentageMatching: string
}

export interface IFuseOptions<T> extends Omit<Fuse.IFuseOptions<T>, 'keys'> {
  initialSearchValue?: string
  keys: string[]
}

function useFuzzy<T>(list: T[], options: IFuseOptions<T> = { keys: [] }) {
  const [searchValue, setSearchValue] = useState('')
  const fuse = useMemo(
    () =>
      new Fuse(list, {
        ...options,
        shouldSort: true,
        includeScore: true,
      }),
    [list, options],
  )

  const onSearch = (nextValue: string) => {
    setSearchValue(nextValue)
  }

  const seachedList = useMemo(() => {
    if (!searchValue) {
      return list
    }

    const result = fuse.search(searchValue)
    const scoreList = _(result).reverse().map('score').value() as number[]
    const mapResult = [] as (T & { fuzzyOptions: IExtraFuzzyResult })[]

    _.orderBy(result, 'score', 'asc').forEach(({ item }, index) => {
      const percentageMatching = scoreList[index] * 100

      mapResult.push({
        ...item,
        fuzzyOptions: {
          percentageMatching: percentageMatching.toFixed(2),
        },
      })
    })

    return mapResult
  }, [searchValue, fuse, list])

  useEffect(() => {
    if (typeof options?.initialSearchValue === 'string') {
      setSearchValue(options?.initialSearchValue)
    }
  }, [options?.initialSearchValue])

  return { seachedList, onSearch, searchValue }
}

export default useFuzzy
