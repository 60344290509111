import React from 'react'
import { Link } from 'react-router-dom'
import { IonButton } from '@ionic/react'

const NavigateButton = ({ to, ...buttonProps }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none !important' }}>
      <IonButton {...buttonProps} />
    </Link>
  )
}

export default NavigateButton
