import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDeepCompareEffect } from 'react-use'

import { IonGrid, IonRow, IonCol } from '@ionic/react'
import {
  Button,
  ConcordFormTextField,
  ConcordFormNumberField,
  ConcordFormMultipeNumberRange,
  ConcordFormDropdownV2,
  ConcordFormMultipleDropdownSelector,
} from '~/components/shared'

import {
  WORK_PAY_RULE_PERIODS,
  DAYS_OF_WEEK,
  DAY,
  WEEK,
  BI_WEEK,
  MONTH,
  NOT_SET_OPTION,
} from '~/utils/constants'
import _ from 'lodash'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

import './styles.scss'

function WorkPayRuleForm(props) {
  const {
    workPayRuleOptions,
    workPayTypeId,
    formData,
    afterCreate,
    afterUpdate,
  } = props

  const [loading, setLoading] = useState(false)

  const getMaxHours = useCallback(periodDay => {
    switch (periodDay) {
      case DAY.value: {
        return DAY.maxHours
      }

      case WEEK.value: {
        return WEEK.maxHours
      }

      case BI_WEEK.value: {
        return BI_WEEK.maxHours
      }

      case MONTH.value: {
        return MONTH.maxHours
      }

      default: {
        return DAY.maxHours
      }
    }
  }, [])

  const checkRangeNumberBetween = useCallback((x, min, max) => {
    return Number(x) > Number(min) && Number(x) < Number(max)
  }, [])

  const schema = Yup.object({
    name: Yup.string().required('This field is required!'),
    rate: Yup.number()
      .nullable()
      .typeError('This field is required!')
      .when('multipleWorkPayRuleId', ([multipleWorkPayRuleId], schema) => {
        if (_.isNil(multipleWorkPayRuleId)) {
          return Yup.number()
            .nullable()
            .required('This field is required!')
            .typeError('This field is required!')
        }

        return schema
      }),
    multipleOnRateOfAnotherRule: Yup.number()
      .nullable()
      .typeError('This field is required!')
      .when('multipleWorkPayRuleId', ([multipleWorkPayRuleId], schema) => {
        if (!_.isNil(multipleWorkPayRuleId)) {
          return Yup.number()
            .nullable()
            .required('This field is required!')
            .typeError('This field is required!')
        }
        return schema
      }),
    hours: Yup.array()
      .test(
        'hours-duplication',
        'Hours must not be overlapped',
        (value, { createError }) => {
          try {
            if (_.size(value) > 1) {
              let message = ''
              const newReverseArray = value.slice().reverse()
              value.forEach(currentRange => {
                const [currentMinValue, currentMaxValue] = currentRange
                newReverseArray.forEach(nextRange => {
                  const [nextMinValue, nextMaxValue] = nextRange
                  if (
                    checkRangeNumberBetween(
                      nextMinValue,
                      currentMinValue,
                      currentMaxValue,
                    ) ||
                    checkRangeNumberBetween(
                      nextMaxValue,
                      currentMinValue,
                      currentMaxValue,
                    )
                  ) {
                    message = `${currentRange.join('-')} and ${nextRange.join(
                      '-',
                    )} are overlapped, please select the new ones`
                  }
                })
              })

              return message ? createError({ message }) : true
            }

            return true
          } catch (error) {
            console.log('error', error)
            createError({
              message:
                'An unknow value occurs while trying to validate this field!',
            })
            return false
          }
        },
      )
      .test(
        'hours-range',
        'Range is not valid',
        (value, { createError, parent }) => {
          try {
            const message = []
            const maxHours = getMaxHours(parent.perPeriod)
            value.forEach(range => {
              const [min, max] = range
              if (Number(max) > maxHours) {
                message.push(`${min}-${max}`)
              }
            })
            if (message.length > 0) {
              return createError({
                message: `Invalid range: ${message.join(', ')}`,
              })
            }
            return true
          } catch (error) {
            console.log('error', error)
            createError({
              message:
                'An unknow value occurs while trying to validate this field!',
            })
            return false
          }
        },
      ),
    // .test('hours-valid', 'Min and Max value are not null!', value => {
    //   const isMinMaxNull = value.some(
    //     ([min, max]) => _.isNil(min) && _.isNil(max),
    //   )

    //   return !isMinMaxNull
    // }),
  })

  const { watch, setValue, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: '',
      workPayTypeId: null,
      perPeriod: DAY.value,
      rate: null,
      multipleOnRateOfAnotherRule: null,
      multipleWorkPayRuleId: null,
      daysOfWeek: [],
      hours: [],
      hoursWorkPayRuleId: null,
      id: null,
      minHrs: null,
    },
    resolver: yupResolver(schema),
  })

  const watchName = watch('name', '')
  const watchPerPeriod = watch('perPeriod', '')
  const watchRate = watch('rate', null)
  const watchMultipleWorkPayRuleId = watch('multipleWorkPayRuleId', null)
  const watchMultipleOnRateOfAnotherRule = watch(
    'multipleOnRateOfAnotherRule',
    null,
  )
  const watchDaysOffWeek = watch('daysOfWeek', [])
  const watchHoursWorkPayRuleId = watch('hoursWorkPayRuleId', null)
  const watchHours = watch('hours', [])
  const watchId = watch('id', null)
  const watchMinHrs = watch('minHrs', null)

  const isCreating = useMemo(() => _.isNil(watchId), [watchId])

  const minMaxHours = useMemo(() => {
    const min = 0
    let max = getMaxHours(watchPerPeriod)

    return { min, max }
  }, [getMaxHours, watchPerPeriod])

  const mapWorkPayRuleOptions = useMemo(() => {
    return workPayRuleOptions.filter(({ value }) => value !== formData.id)
  }, [formData.id, workPayRuleOptions])

  const handleChange = useCallback(
    name =>
      (event, { value }) => {
        setValue(name, value)
      },
    [setValue],
  )

  const handleChangeDropdown = useCallback(
    name =>
      (event, { value }) => {
        setValue(name, value)

        if (name === 'multipleWorkPayRuleId') {
          setValue('rate', null)
          setValue('multipleOnRateOfAnotherRule', null)
        }
      },
    [setValue],
  )

  const createWorkPayRule = useCallback(
    async formData => {
      setLoading(true)
      try {
        const { workPayRule, errors } = await apiClient.workPayRules.create(
          formData,
        )
        if (errors?.length > 0) {
          toast.error(errors[0])
        } else {
          afterCreate && afterCreate(workPayRule)
          toast.success(toastMessages.createSuccess)
          reset()
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [afterCreate, reset],
  )

  const updateWorkPayRule = useCallback(
    async formData => {
      setLoading(true)
      try {
        const { id, ...payload } = formData
        const { workPayRule, errors } = await apiClient.workPayRules.update(
          id,
          payload,
        )
        if (errors?.length > 0) {
          toast.error(errors[0])
        } else {
          afterUpdate && afterUpdate(workPayRule)
          toast.success(toastMessages.updateSuccess)
          reset()
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [afterUpdate, reset],
  )

  const onSubmitForm = handleSubmit(async formData => {
    if (isCreating) {
      createWorkPayRule({ ...formData, workPayTypeId })
    } else {
      updateWorkPayRule(formData)
    }
  })

  useDeepCompareEffect(() => {
    if (_.size(formData) > 0) {
      _.forEach(formData, (value, key) => {
        setValue(key, value)
      })
    } else {
      reset()
    }
  }, [formData])

  return (
    <form onSubmit={onSubmitForm} className='WorkPayRuleForm__form'>
      <IonGrid>
        <IonRow>
          <IonCol size='6'>
            <ConcordFormTextField
              label='Name'
              value={watchName}
              onChange={handleChange('name')}
              error={formState.errors.name?.message}
            />
          </IonCol>

          <IonCol size='6'>
            <ConcordFormDropdownV2
              label='Per Period'
              options={WORK_PAY_RULE_PERIODS}
              value={watchPerPeriod}
              onChange={handleChangeDropdown('perPeriod')}
              error={formState.errors.perPeriod?.message}
            />
          </IonCol>

          {!_.isNil(watchMultipleWorkPayRuleId) && (
            <IonCol size='6'>
              <ConcordFormNumberField
                label='Multipe On Rate Of Another Rule'
                value={watchMultipleOnRateOfAnotherRule}
                onChange={handleChange('multipleOnRateOfAnotherRule')}
                error={formState.errors.multipleOnRateOfAnotherRule?.message}
              />
            </IonCol>
          )}

          {_.isNil(watchMultipleWorkPayRuleId) && (
            <IonCol size='6'>
              <ConcordFormNumberField
                label='Rate'
                value={watchRate}
                onChange={handleChange('rate')}
                error={formState.errors.rate?.message}
              />
            </IonCol>
          )}

          <IonCol size='6'>
            <ConcordFormDropdownV2
              label='Multipe Work Pay Rule'
              options={[NOT_SET_OPTION, ...mapWorkPayRuleOptions]}
              value={watchMultipleWorkPayRuleId}
              onChange={handleChangeDropdown('multipleWorkPayRuleId')}
              error={formState.errors.multipleWorkPayRuleId?.message}
            />
          </IonCol>

          <IonCol size='6'>
            <ConcordFormMultipleDropdownSelector
              label='Days of Week'
              options={DAYS_OF_WEEK}
              isMulti
              value={watchDaysOffWeek}
              closeMenuOnSelect={false}
              onChange={(event, { value }) => {
                setValue('daysOfWeek', value)
              }}
            />
          </IonCol>

          <IonCol size='6'>
            <ConcordFormDropdownV2
              label='Hours Work Pay Rule'
              options={mapWorkPayRuleOptions}
              value={watchHoursWorkPayRuleId}
              onChange={handleChangeDropdown('hoursWorkPayRuleId')}
            />
          </IonCol>

          <IonCol size='6'>
            <ConcordFormMultipeNumberRange
              {...minMaxHours}
              label='Hours'
              value={watchHours}
              onChange={handleChange('hours')}
              error={formState.errors.hours?.message}
            />
          </IonCol>

          <IonCol size='6'>
            <ConcordFormNumberField
              label='Min Hours'
              value={watchMinHrs}
              onChange={handleChange('minHrs')}
            />
          </IonCol>

          <IonCol size='12'>
            <Button
              size='small'
              type='submit'
              expand='block'
              loading={loading}
              label={isCreating ? 'Create' : 'Update'}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default WorkPayRuleForm
