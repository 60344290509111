import { memo, useCallback } from 'react'

import { CommonDialog } from '~/components/shared'
import EditCompanyContainer from '~/containers/EditCompanyContainer'

import clsx from 'clsx'
// import PropTypes from 'prop-types'

import './DialogCompanyCreateEdit.scss'

const DialogCompanyCreateEdit = props => {
  const {
    companyId,
    afterEditCompany: afterEditCompanyProps,
    open,
    className,
    isUpdatingFleet,
    onClose,
    ...dialogProps
  } = props
  const afterEditCompany = useCallback(
    updatedCompany => {
      afterEditCompanyProps && afterEditCompanyProps(updatedCompany)
      onClose && onClose()
    },
    [afterEditCompanyProps, onClose],
  )

  if (open) {
    return (
      <CommonDialog
        title='Edit company'
        open={open}
        className={clsx('DialogCompanyCreateEdit__container', className)}
        hideFooter
        onClose={onClose}
        {...dialogProps}
      >
        <EditCompanyContainer
          companyId={companyId}
          mode='vertical'
          afterEditCompany={afterEditCompany}
          isUpdatingFleet={isUpdatingFleet}
        />
      </CommonDialog>
    )
  }

  return null
}

DialogCompanyCreateEdit.propTypes = {
  // companyId: PropTypes.number,
  // afterEditCompany: PropTypes.func,
  // open: PropTypes.string,
  // className: PropTypes.string,
  // onClose: PropTypes.func
}

export default memo(DialogCompanyCreateEdit)
