import _ from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
import { DEFAULT_QUERY_OPTIONS } from './constants'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useQueryInitialCompanyData = (params = {}, options = {}) => {
  const sessionUser = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    query: ['companyInitialData', sessionUser?.id],
    queryFn: () => apiClient.companies.getInitialCompanyData(),
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const initialTimezoneData = useMemo(
    () => data?.timeZones || [],
    [data?.timeZones],
  )

  const timeZoneOptions = initialTimezoneData.map(({ name }) => ({
    label: name,
    value: name,
  }))

  const requiredActionsOptions = (data?.requiredActionsOptions || []).map(
    field => ({
      label: _.startCase(field),
      value: field,
    }),
  )

  const autoLoadCreateOptions = Object.keys(
    data?.autoCreateLoadMatching || {},
  ).map(field => ({
    label: _.startCase(field),
    value: data?.autoCreateLoadMatching[field],
  }))

  return {
    initialTimezoneData,
    timeZoneOptions,
    requiredActionsOptions,
    autoLoadCreateOptions,
    isLoadingInitialCompanyData: isLoading,
  }
}

export default useQueryInitialCompanyData
