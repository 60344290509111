import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryLoad } from '~/hooks/useQueryData'
import { useRouter } from '~/hooks/useRouter'
import { useWindowSize } from 'react-use'
import { useCheckWorkerInfoSection } from '~/containers/PayrollsContainer/components'

import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import { selectDriverDetails, selectMyCurrentCompany } from '~/redux/selectors'
import { ELoadStatus } from '~/types/enums/ELoadStatus'
import { ECheckStatus } from '~/types/enums/ECheck'

import './styles.scss'
import { ICompany } from '~/types/models/ICompany'

function DriverBanner() {
  const driverFleet = useSelector(selectDriverDetails)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isCollapsed = useSelector((state: any) => state.sidebar.isCollapsed)

  const loadId = useMemo(() => driverFleet?.loadId, [driverFleet?.loadId])

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const router = useRouter()

  const windowSize = useWindowSize()

  const { loadData } = useQueryLoad(loadId)

  const { workerDetailsData } = useCheckWorkerInfoSection({
    checkUid: driverFleet?.worker?.checkUid,
  })

  const isShowActiveLoadBanner = useMemo(
    () =>
      Boolean(
        loadData &&
          loadData?.status !== ELoadStatus.deliveryComplete &&
          router.pathname !== '/loads',
      ),
    [loadData, router.pathname],
  )

  const isShowCheckNotification = useMemo(
    () =>
      Boolean(
        currentCompany?.checkUid &&
          workerDetailsData &&
          workerDetailsData.onboard?.status !== ECheckStatus.completed &&
          router.pathname !== '/payrolls',
      ),
    [currentCompany?.checkUid, workerDetailsData, router.pathname],
  )

  const marginLeft = useMemo(() => {
    if (windowSize.width <= 960) {
      return 0
    }

    if (isCollapsed) {
      return 96
    }

    return 236
  }, [isCollapsed, windowSize.width])

  const onNavigate = useCallback(
    (url: string) => () => {
      router.push(url)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className='DriverBanner__container' style={{ marginLeft }}>
      <When condition={isShowActiveLoadBanner}>
        <Alert
          variant='primary clickable'
          onClick={onNavigate('/loads')}
          className='DriverBanner_alertItem'
        >
          Active Load: #{loadData?.num || loadData?.id}
        </Alert>
      </When>
      <When condition={isShowCheckNotification}>
        <Alert
          variant='danger clickable'
          className='DriverBanner_alertItem'
          onClick={onNavigate('/payrolls')}
        >
          <div>Your payroll setup is incomplete.</div>
          <div>
            Please access to the <span className='hyperLink'>Payroll</span> page
            to finalize the setup
          </div>
        </Alert>
      </When>
    </div>
  )
}

export default DriverBanner
