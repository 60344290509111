import { useCallback, useState, useEffect } from 'react'
import useTableCompanyTagSubTab from './useTableCompanyTagSubTab'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogTagForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'

import './CompanyTagSubTab.scss'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryTags } from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'

function CompanyTagSubTab() {
  const [wasDragged, setWasDragged] = useState(false)
  const [clonedData, setClonedData] = useState([])

  const {
    tagsData,
    isTagsLoading,
    renewAllTags,
    addTag,
    updateTag,
    removeTag,
  } = useQueryTags()

  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    formData: null,
  })

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const handleOpenCreateTag = useCallback(() => {
    setDialogProps(prev => ({
      ...prev,
      isOpen: true,
      formData: null,
    }))
  }, [])

  const handleOpenEditTag = useCallback(tagDetail => {
    setDialogProps(prev => ({
      ...prev,
      isOpen: true,
      formData: tagDetail,
    }))
  }, [])

  const onCloseDialogTagFormData = useCallback(() => {
    setDialogProps({
      isOpen: false,
      formData: null,
    })
  }, [])

  const afterCreateTag = useCallback(
    tagCreated => {
      addTag(tagCreated)
      onCloseDialogTagFormData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseDialogTagFormData],
  )

  const afterUpdateTag = useCallback(
    tagUpdated => {
      onCloseDialogTagFormData()
      updateTag(tagUpdated.id, tagUpdated)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseDialogTagFormData],
  )

  const deleteTag = useCallback(async tag => {
    try {
      const response = await apiClient.tags.delete(tag.id)
      if (response.id) {
        removeTag(response.id)
        toast.success(toastMessages.deleteSuccess)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.deleteError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteTag = useCallback(
    async tag => {
      try {
        const result = await confirmation({
          message: `Are you sure you want to delete tag #${tag.id}`,
        })
        if (result === EYesNo.Yes) {
          deleteTag(tag)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.deleteError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onCellEditEnd = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id
        const formData = { [columnField]: value }
        const response = await apiClient.tags.update(rowId, formData)
        if (response.id) {
          afterUpdateTag(response)
        } else {
          toast.error(toastMessages.updateError)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { columns } = useTableCompanyTagSubTab()

  const onReorderRow = useCallback((current, next) => {
    setClonedData(prev =>
      produce(prev, draft => {
        draft[current.index] = { ...next.original }
        draft[next.index] = { ...current.original }

        draft.forEach((item, index) => {
          item.rank = index + 1
        })
      }),
    )
    setWasDragged(true)
  }, [])

  const onSaveRanking = useCallback(() => {
    const ids = clonedData.map(({ id }) => id)
    apiClient.rerank.rerank({
      modelName: 'Tag',
      rankings: ids,
      field: 'rank',
      update: false,
    })
    renewAllTags(clonedData)
    setWasDragged(false)
  }, [clonedData, renewAllTags])

  const onRevertChanges = useCallback(() => {
    setClonedData(tagsData)
    setWasDragged(false)
  }, [tagsData])

  const renderFooterActions = useCallback(
    () => [
      {
        variant: 'success',
        onClick: onSaveRanking,
        label: 'Submit changes',
      },
      {
        variant: 'warning',
        onClick: onRevertChanges,
        label: 'Revert changes',
      },
    ],
    [onRevertChanges, onSaveRanking],
  )

  const renderRowActions = useCallback(
    ({ row }) => [
      {
        icon: <EditIcon color='white' />,
        onClick: () => handleOpenEditTag(row.original),
        tooltipProps: {
          content: 'Edit',
          placement: 'top',
        },
      },
      {
        icon: <DeleteIcon color='white' />,
        onClick: () => handleDeleteTag(row.original),
        color: 'danger',
        tooltipProps: {
          content: 'Remove',
          placement: 'top',
        },
      },
    ],
    [handleOpenEditTag, handleDeleteTag],
  )

  useEffect(() => {
    setClonedData(tagsData)
  }, [tagsData])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={clonedData}
          tableHeight={windowSize.height - (wasDragged ? 260 : 220)}
          enableCompanyView
          enableTopToolbar
          enableRowActions
          enableColumnPinning
          enableRowOrdering
          enableSorting={false}
          enableFooterActions={wasDragged}
          enableBottomToolbar={wasDragged}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 75,
              maxSize: 75,
            },
            [ERTDisplayColumnId.drag]: {
              minSize: 40,
              maxSize: 40,
            },
          }}
          companyViewProps={{
            name: moduleName.company.tags,
            description: 'Create and manage your company tags.',
            onClickTopButton: handleOpenCreateTag,
          }}
          state={{
            isLoading: isTagsLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions, ERTDisplayColumnId.drag],
            },
          }}
          renderFooterActions={renderFooterActions}
          renderRowActions={renderRowActions}
          onRowDragEnd={onReorderRow}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogTagForm
        isOpen={dialogProps.isOpen}
        formData={dialogProps.formData}
        afterCreate={afterCreateTag}
        afterUpdate={afterUpdateTag}
        onClose={onCloseDialogTagFormData}
      />
    </>
  )
}

CompanyTagSubTab.propTypes = {}

export default CompanyTagSubTab
