import { useMemo } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import ReactSelect from 'react-select'
import CompanyTableButtonTabs from './ButtonTabs'

import './CompanyTableTabs.scss'

function CompanyTableTabs(props) {
  const { tabs, currentTab, onChangeTab } = props

  const { windowSize } = useBreakpoint()

  const mapValue = useMemo(() => {
    return tabs.find(({ value }) => currentTab === value)
  }, [currentTab, tabs])

  const filterTabs = useMemo(
    () => tabs.filter(({ hide }) => hide !== true),
    [tabs],
  )

  const tabRendered = useMemo(() => {
    // if (filterTabs.length > 3) {
    //   return (
    //     <ReactSelect
    //       options={filterTabs}
    //       value={mapValue}
    //       onChange={onChangeTab}
    //       styles={{
    //         menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    //         menu: provided => ({ ...provided, zIndex: 9999 })
    //       }}
    //     />
    //   )
    // }

    if (windowSize.width > 1350 && filterTabs.length <= 4) {
      return (
        <CompanyTableButtonTabs
          tabs={filterTabs}
          selectedTab={mapValue}
          onChange={onChangeTab}
        />
      )
    }

    return (
      <ReactSelect
        options={filterTabs}
        value={mapValue}
        onChange={onChangeTab}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
        }}
      />
    )
  }, [filterTabs, mapValue, onChangeTab, windowSize.width])

  if (tabs.length === 0) {
    return null
  }

  return <div className='CompanyTableTabs__container'>{tabRendered}</div>
}

CompanyTableTabs.defaultProps = {
  tabs: [],
}

export default CompanyTableTabs
