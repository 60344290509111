import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  CommonTab,
  TruckInfoFormData,
  ICommonTabItem,
  TruckFleetFormData,
} from '~/components/shared'

import _ from 'lodash'
import { toastMessages } from '~/constants/toast-status-text'
import { toast } from 'react-toastify'
import { updateTruck } from '~/redux/actions/trucksActions'

import type { ITruck } from '~/types/models/ITruck'
import type { ITruckFleet } from '~/types/models/ITruckFleet'
import type { ITruckFormDataProps } from './type'
import { truckFleetsSlice } from '~/redux/reducers/data/truckFleets'
import { apiClient } from '~/api/ApiClient'
import { useQueryTruckFleets } from '~/hooks/useQueryData'

const TruckFormData = (props: ITruckFormDataProps) => {
  const { truck, truckFleet, defaultTab, onSubmitTruck, onSubmitTruckFleet } =
    props

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const { updateTruckFleet, refetchTruckFleetsData } = useQueryTruckFleets()

  const updateTruckInfo = useCallback(
    async (formValues: ITruck) => {
      setIsLoading(true)
      try {
        const { errors, ...response } = await apiClient.trucks.update(
          formValues.id,
          {
            truck: formValues,
          },
        )
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          dispatch(updateTruck(response))
          onSubmitTruck && onSubmitTruck(response)
          refetchTruckFleetsData()
        }
      } catch (error) {
        toast.error(toastMessages.createError)
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, onSubmitTruck, refetchTruckFleetsData],
  )

  const createTruckInfo = useCallback(
    async (formValues: ITruck) => {
      setIsLoading(true)
      try {
        const { truck, errors } = await apiClient.trucks.create({
          truck: formValues,
        })
        if (_.size(errors) > 0) {
          toast.error(toastMessages.createError)
        } else {
          dispatch(updateTruck(truck))
          onSubmitTruck && onSubmitTruck(truck)
          refetchTruckFleetsData()
        }
      } catch (error) {
        toast.error(toastMessages.createError)
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, onSubmitTruck, refetchTruckFleetsData],
  )

  const handleSubmitTruck = useCallback(
    (formValues: ITruck) => {
      if (formValues.id) {
        updateTruckInfo(formValues)
      } else {
        createTruckInfo(formValues)
      }
    },
    [createTruckInfo, updateTruckInfo],
  )

  const afterUpdateTruckFleet = useCallback(
    ({ updatedTruckFleet }: { updatedTruckFleet: ITruckFleet }) => {
      dispatch(truckFleetsSlice.actions.update(updatedTruckFleet))
      updateTruckFleet(updatedTruckFleet.id, updatedTruckFleet)
      onSubmitTruckFleet && onSubmitTruckFleet(updatedTruckFleet)
    },
    [dispatch, onSubmitTruckFleet, updateTruckFleet],
  )

  const tabs = useMemo<ICommonTabItem[]>(
    () => [
      {
        name: 'truckInfo',
        label: 'Truck Info',
        render() {
          return (
            <TruckInfoFormData
              formData={truck}
              onSubmit={handleSubmitTruck}
              isHiddenCancelButton
              isLoading={isLoading}
            />
          )
        },
      },
      {
        name: 'truckFleet',
        label: 'Truck Fleet',
        isHidden: !truckFleet,
        render() {
          if (truckFleet) {
            return (
              <TruckFleetFormData
                truckFleet={truckFleet}
                afterUpdateTruckFleet={afterUpdateTruckFleet}
                isHiddenCancelButton
              />
            )
          }
          return null
        },
      },
    ],
    [afterUpdateTruckFleet, handleSubmitTruck, isLoading, truck, truckFleet],
  )

  return <CommonTab tabs={tabs} defaultTab={defaultTab} />
}

export default TruckFormData
