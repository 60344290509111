import { useState } from 'react'
import {
  IonImg,
  IonAvatar,
  IonModal,
  IonItem,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import Select from 'react-select'
import { closeOutline, addOutline } from 'ionicons/icons'
import { useEffect } from 'react'
import { apiClient } from '~/api/ApiClient'

function PickerCompanyAndTerminal(props) {
  const {
    isMyCompany = true, //true:   YES change company, terminal from redux
    //false:  NOO change company, terminal from api
    initialCompany, // { label: name, value: id, image: image }
    choicesCompany, // [{ label: name, value: id, image: image }]
    onPickCompany, // onPickCompany( companyID )
    initialTerminal, // { label: name, value: id }
    onPickTerminal, // onPickTerminal( terminalID )
  } = props

  const [company, setCompany] = useState(initialCompany)
  const [terminal, setTerminal] = useState(initialTerminal)
  const [terminalChoices, setTerminalChoices] = useState([])
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    company?.value &&
      apiClient.terminals.get({
        filters: {
          companyId: company.value
        }
      })
        .then(response => {
          const terminals = response.map(terminal => {
            return { label: terminal.name, value: terminal.id }
          })
          setTerminalChoices(terminals)
        })
        .catch(err => {
          console.log(err)
        })
  }, [company, isMyCompany])

  useEffect(() => {
    if (company?.value != initialCompany?.value) {
      setCompany(initialCompany)
    }
  }, [initialCompany, company])

  const showSearch = options => {
    return options && options.length > 3
  }

  const onChangeCompany = item => {
    setCompany(item)
    setTerminal({ label: '', value: null })
    setShowMenu(false)
    onPickCompany(item.value)
  }

  const onChangeTerminal = t => {
    if (t?.value) {
      setTerminal(t)
      onPickTerminal(t.value)
    }
  }

  const compareWith = (terminal1, terminal2) => {
    return terminal1 && terminal2
      ? terminal1.value === terminal2.value
      : terminal1 === terminal2
  }

  return (
    <>
      {!isMyCompany ? (
        <IonAvatar
          onClick={() => setShowMenu(true)}
          style={{
            height: 32,
            width: 32,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          role='button'
        >
          {company?.image ? (
            <IonImg src={company?.image} />
          ) : (
            <IonIcon src={addOutline} size='large' color='primary' />
          )}
        </IonAvatar>
      ) : (
        <IonAvatar
          style={{
            height: 32,
            width: 32,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <IonImg src={company?.image} />
        </IonAvatar>
      )}

      <IonItem lines='none'>
        <IonSelect
          disabled={!company}
          value={terminal}
          compareWith={compareWith}
          onIonChange={e => onChangeTerminal(e.detail.value)}
          placeholder={terminal?.label || 'not set'}
          style={{
            fontSize: 14,
            width: '100%',
            justifyContent: 'center',
            paddingStart: 0,
            paddingEnd: 0,
          }}
        >
          {terminalChoices &&
            terminalChoices.map(t => (
              <IonSelectOption value={t} key={t.value}>
                {t.label}
              </IonSelectOption>
            ))}
        </IonSelect>
      </IonItem>

      <IonModal
        isOpen={showMenu}
        onDidDismiss={() => setShowMenu(false)}
        style={{ height: 400 }}
        presentingElement={undefined} //?
        canDismiss
      >
        <Select
          options={choicesCompany} //
          value={company} //
          //blurInputOnSelect
          isFocused={showMenu}
          menuIsOpen
          maxMenuHeight={400}
          isSearchable={() => showSearch(choicesCompany)}
          onChange={onChangeCompany} //
        />

        <IonButton onClick={() => setShowMenu(false)} color='danger'>
          <IonIcon icon={closeOutline} />
        </IonButton>
      </IonModal>
    </>
  )
}

export default PickerCompanyAndTerminal
