// Helper to convert data into google-chart-friendly format
export const convertData = orders => {
  //  Setting up column headers first, then rows
  const data = [['Terminal']]
  data[0] = data[0].concat(orders.products)
  orders.terminals.forEach(order => data.push([order]))

  // Check column headers, then check rows
  // Skip 0 index to skip headers
  data.forEach((order, i) => {
    data[0].forEach((columnHeader, j) => {
      if (i == 0 || j == 0) {
        console.log('empty')
      } else {
        if (
          Object.hasOwn(orders.amounts, data[0][j]) &&
          orders.amounts[data[0][j]][data[i][0]]
        ) {
          data[i].push(orders.amounts[data[0][j]][data[i][0]])
        } else {
          data[i].push(0)
        }
      }
    })
  })

  return data
}
