import React, { useCallback, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { IonLabel, IonRow, IonCol, IonButtons, IonButton } from '@ionic/react'
import { parseInputErrors } from '~/utils/utils'
import { SelectSearchDropdown } from '~/components/shared'
import PropTypes from 'prop-types'

const DropDownFormSelect = props => {
  const {
    label,
    data = [],
    meta: { touched, error },
    input = {
      value: '',
    },
    onChange,
  } = props

  const [currentValue, setCurrentValue] = useState()
  useEffect(() => {
    const filterValue = data.filter(a => a.value == input?.value)
    setCurrentValue(filterValue[0])
  }, [input?.value, data])
  const bindValue = useCallback(
    value => {
      onChange && onChange(value.value, value.id)
      input.onChange && input.onChange(value.value)
      setCurrentValue(value)
    },
    [input, onChange],
  )

  return (
    <>
      <IonRow style={{ alignItems: 'center' }}>
        <IonCol size='auto' style={{ marginLeft: 10 }}>
          <IonLabel
            className='input-label floating'
            position='floating'
            style={{ fontSize: '1.5em' }}
          >
            {label}
          </IonLabel>
        </IonCol>
        <IonCol size='6' size-lg>
          <SelectSearchDropdown
            currentValue={currentValue}
            value={data}
            onChange={bindValue}
            modalSize='15vw'
            placement='bottom'
            subComponent={
              <IonButtons>
                <IonButton color='dark'>
                  {currentValue ? currentValue?.label : 'Select An Option'}
                </IonButton>
              </IonButtons>
            }
          />
        </IonCol>
      </IonRow>
      {touched && error && (
        <IonRow>
          <IonLabel color='danger'>
            <FormattedMessage
              id={parseInputErrors(error)}
              defaultMessage={parseInputErrors(error)}
            />
          </IonLabel>
        </IonRow>
      )}
    </>
  )
}

DropDownFormSelect.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
}

export default DropDownFormSelect
