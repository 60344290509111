import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IZone } from '~/types/models/IZone'

const useQueryZones = (
  params = {},
  options?: Partial<UseQueryOptions<IZone[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'zones',
      sessionUser?.id,
      buildGetUrl(apiClient.zones.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.zones.get()
      return response.zones
    },
    enabled: false,
    staleTime: 600000, // 10 minutes
    ...options,
  })

  // const { addHierarchy, updateHierarchy, removeHierarchy } =
  //   useModifyHierarchy(params)

  const zonesData = useMemo(() => data || [], [data])

  const zoneOptions = useMemo(
    () =>
      zonesData.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [zonesData],
  )

  return {
    zonesData,
    isLoadingZones: isLoading,
    zoneOptions,
    // addHierarchy,
    // updateHierarchy,
    // removeHierarchy,
  }
}

export default useQueryZones
