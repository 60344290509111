import { useMemo } from 'react'
import { useQueryPayrolls } from '~/hooks/useQueryData'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

export interface IPayrollJournalTableProps {
  data: any
}

function TaxDepositsTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const { payrollsOptions } = useQueryPayrolls()

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Tax Label',
        accessorKey: 'taxLabel',
        minSize: 350,
        maxSize: 350,
      },
      {
        header: 'Payroll',
        accessorKey: 'payroll',
        minSize: 120,
        maxSize: 120,
        Cell({ row: { original } }) {
          const findPayroll = payrollsOptions.find(
            ({ value }) => value === original.payroll,
          )
          return findPayroll?.label
        },
      },
      {
        header: 'Payroll Payday',
        accessorKey: 'payrollPayday',
        minSize: 100,
        maxSize: 100,
        align: 'center',
      },
      {
        header: 'Due Date',
        accessorKey: 'dueDate',
        align: 'center',
        minSize: 100,
        maxSize: 100,
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        align: 'right',
        minSize: 100,
        maxSize: 100,
      },
      {
        header: 'Deposit Status',
        accessorKey: 'depositStatus',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'Funding Status',
        accessorKey: 'fundingStatus',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
    ],
    [payrollsOptions],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default TaxDepositsTable
