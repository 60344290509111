import type { DragEvent, RefObject } from 'react'
import type {
  RTColumn,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import { reorderColumn } from '../../utils/column.utils'
import { RTGrabHandleButton } from '../buttons/RTGrabHandleButton'

export interface RTTableHeadCellGrabHandleProps<
  TData extends IReusableTableRowData,
> {
  column: RTColumn<TData>
  table: ReusableTableInstance<TData>
  tableHeadCellRef: RefObject<HTMLTableCellElement>
}

export const RTTableHeadCellGrabHandle = <TData extends IReusableTableRowData>(
  props: RTTableHeadCellGrabHandleProps<TData>,
) => {
  const { table, column, tableHeadCellRef } = props

  const {
    getState,
    options: { enableColumnOrdering },
    setColumnOrder,
    setDraggingColumn,
    setHoveredColumn,
  } = table
  const { columnOrder, draggingColumn, hoveredColumn } = getState()

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    setDraggingColumn(column)
    try {
      event.dataTransfer.setDragImage(
        tableHeadCellRef.current as HTMLElement,
        0,
        0,
      )
    } catch (e) {
      console.error(e)
    }
  }

  const handleDragEnd = () => {
    if (hoveredColumn?.id === 'drop-zone') {
      column.toggleGrouping()
    } else if (
      enableColumnOrdering &&
      hoveredColumn &&
      hoveredColumn?.id !== draggingColumn?.id
    ) {
      setColumnOrder(
        reorderColumn(column, hoveredColumn as RTColumn<TData>, columnOrder),
      )
    }
    setDraggingColumn(null)
    setHoveredColumn(null)
  }

  return (
    <RTGrabHandleButton
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      table={table}
    />
  )
}
