import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'
import { useMemo } from 'react'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import {
  IGetWorkerCompanyAttributesParams,
  IWorkerCompanyAttribute,
} from '~/types/models/ICheck'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryWorkerCompanyAttributes = (
  params: IGetWorkerCompanyAttributesParams,
  options: UseQueryOptions<IWorkerCompanyAttribute[]> = {},
) => {
  const sessionUser = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'workerCompanyAttributes',
      buildGetUrl('/check/get_worker_company_attributes', params),
      sessionUser.id,
    ],
    async queryFn() {
      const response = await apiClient.check.getWorkerCompanyAttributes(params)
      return response.companyDefinedAttributes
    },
    enabled: Boolean(sessionUser.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const workerCompanyAttributes = useMemo(() => data || [], [data])

  return {
    workerCompanyAttributes,
    isLoadingWorkerCompanyAttributes: isLoading,
    refectWorkerCompanyAttributes: refetch,
  }
}

export default useQueryWorkerCompanyAttributes
