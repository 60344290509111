import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryTerminals, useQueryUserGroups } from '~/hooks/useQueryData'

import { ConcordFormStructure } from '~/components/shared'

import { REGEX_PHONE_NUMBERS } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { EFieldType } from '~/types/enums/ECommonEnum'
import _ from 'lodash'
import { produce } from 'immer'
import diffObjects from '~/utils/diffObjects'

const UserForm = props => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const formRef = useRef()

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { userGroupOptions } = useQueryUserGroups()

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const { companyTerminalOptions, isLoadingTerminals } = useQueryTerminals()

  const defaultValues = useMemo(
    () => ({
      email: '',
      phoneNumber: '',
      color: '',
      password: '',
      passwordConfirmation: '',
      userAccess: {
        userGroupId: null,
      },
    }),
    [],
  )

  const defaultUpdatePayload = useMemo(() => {
    if (formData) {
      return {
        id: formData.id,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        color: formData.color,
        password: '',
        passwordConfirmation: '',
        personAttributes: {
          firstName: formData.person?.firstName,
          lastName: formData.person?.lastName,
        },
        userAccess: {
          userGroupId: formData.userAccess.userGroupId,
          workerAttributes: {
            title: formData.worker?.title,
            terminalId: formData.worker?.terminalId,
          },
        },
      }
    }
    return undefined
  }, [formData])

  const fields = useMemo(
    () => [
      {
        name: 'email',
        label: 'Email',
        autoComplete: false,
        isRequired: true,
        size: 12,
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        autoComplete: false,
        size: 6,
        type: EFieldType.number,
        isPhoneNumber: true,
      },
      {
        name: 'color',
        label: 'Color',
        type: EFieldType.color,
        size: 6,
      },
      {
        name: 'password',
        label: 'Password',
        autoComplete: 'new-password',
        isRequired: !isUpdating,
        type: EFieldType.password,
        size: 6,
      },
      {
        name: 'passwordConfirmation',
        label: 'Password Confirmation',
        autoComplete: 'new-password',
        isRequired: !isUpdating,
        type: EFieldType.password,
        size: 6,
      },
      {
        name: 'personAttributes',
        label: 'Personal Information',
        fields: [
          {
            name: 'personAttributes.firstName',
            label: 'First Name',
            isRequired: true,
            size: 6,
          },
          {
            name: 'personAttributes.lastName',
            label: 'Last Name',
            isRequired: true,
            size: 6,
          },
        ],
      },
      {
        name: 'userAccess',
        label: 'User Access',
        fields: [
          {
            name: 'userAccess.userGroupId',
            label: 'User Group',
            type: EFieldType.singleSelect,
            options: userGroupOptions,
            isRequired: true,
            size: 6,
          },
          {
            name: 'userAccess.workerAttributes.title',
            label: 'Title',
            size: 6,
          },
        ],
      },
      {
        name: 'workerAttributes',
        label: 'Worker',
        fields: [
          {
            name: 'userAccess.workerAttributes.terminalId',
            label: 'Terminal',
            size: 12,
            isRequired: true,
            type: EFieldType.singleSelect,
            options: companyTerminalOptions,
            isLoading: isLoadingTerminals,
          },
        ],
      },
    ],
    [companyTerminalOptions, isLoadingTerminals, isUpdating, userGroupOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().required('Email is required').email(),
        phoneNumber: Yup.string()
          .test('phoneNumber-regex', 'Phone number is invalid', val => {
            if (val) {
              return REGEX_PHONE_NUMBERS.test(val)
            }

            return true
          })
          .transform(val => val || undefined),
        password: Yup.lazy(() => {
          if (isUpdating) {
            return Yup.string().test(
              'max-password',
              'Password is too short (minimum is 8 characters)',
              password => {
                if (password) {
                  return password.length >= 8
                }

                return true
              },
            )
          }

          return Yup.string()
            .required('Password is required!')
            .min(8, 'Password is too short (minimum is 8 characters)')
        }),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match',
        ),
        userAccess: Yup.object({
          userGroupId: Yup.number()
            .required('This field is required')
            .typeError('This field is required'),
          workerAttributes: Yup.object({
            terminalId: Yup.number()
              .required('This field is required')
              .typeError('This field is required'),
          }),
        }),
        personAttributes: Yup.object({
          firstName: Yup.string()
            .required('This field is required')
            .typeError('This field is required'),
          lastName: Yup.string()
            .required('This field is required')
            .typeError('This field is required'),
        }),
      }),
    [isUpdating],
  )

  const createCompanyUser = useCallback(
    async formValues => {
      const payload = _.pick(formValues, [
        'email',
        'phoneNumber',
        'password',
        'passwordConfirmation',
        'personAttributes',
        'color',
      ])
      payload.userAccessesAttributes = [
        produce(formValues.userAccess, draft => {
          draft.companyId = currentCompany.id
          draft.workerAttributes.companyId = currentCompany.id
        }),
      ]
      const { user } = await apiClient.user.create({
        user: payload,
      })
      if (user.errors?.length) {
        setError(user.errors[0])
      } else {
        afterCreate && afterCreate(user)
      }
    },
    [afterCreate, currentCompany.id],
  )

  const updateCompanyUser = useCallback(
    async formValues => {
      const differentValues = diffObjects(
        formValues,
        defaultUpdatePayload,
        false,
      )
      if (Object.keys(differentValues).length > 0) {
        const payload = produce(differentValues, draft => {
          if (draft.userAccess) {
            draft.userAccessesAttributes = [
              {
                userGroupId: differentValues.userAccess.userGroupId,
                id: formData.userAccess.id,
              },
            ]
            if (draft.userAccess.workerAttributes) {
              draft.userAccessesAttributes[0].workerAttributes = {
                title: draft.userAccess.workerAttributes.title,
                terminalId: draft.userAccess.workerAttributes.terminalId,
                id: formData.worker.id,
              }
            }
          }
          if (!draft.password) {
            delete draft.password
            delete draft.passwordConfirmation
          }

          if (draft.personAttributes) {
            draft.personAttributes.id = formData.person?.id
          }

          delete draft.userAccess
        })
        const { errors, ...response } = await apiClient.users.update(
          formData?.id,
          { user: payload },
        )
        if (errors?.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      }
    },
    [afterUpdate, defaultUpdatePayload, formData],
  )

  const onSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await updateCompanyUser(formValues)
        } else {
          await createCompanyUser(formValues)
        }
      } catch (error) {
        const { errors } = error
        if (errors?.fullMessages?.length > 0) {
          toast.error(errors?.fullMessages[0])
        } else {
          toast.error('Error happens!')
        }
      } finally {
        setIsLoading(false)
      }
    },
    [createCompanyUser, isUpdating, updateCompanyUser],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      ref={formRef}
      error={error}
      isLoading={isLoading}
      onSubmit={onSubmit}
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      formData={defaultUpdatePayload}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default UserForm
