import { useEffect, useMemo, useState } from 'react'
import { FloatingSelectV2 } from '../../FloatingForm/FloatingSelectV2'
import { Control, useWatch } from 'react-hook-form'
import {
  defaultStatuses as statusOptions,
  colorOptions,
} from '~/components/fleet/constants'
import { ProductSelect } from '../../OrderForm/OrderFormProductSelect'
import { EOrderType } from '~/types/enums/ESellerProduct'
import { Collapse } from 'react-bootstrap'
import { selectCompanies, selectTerminalOptions } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { ICompany } from '~/types/models/ICompany'
import { StylesConfig } from 'react-select'
import { ScheduleLoadsOverrideRenderInput } from './OverrideRenderInput'

type Props = {
  index: number
  control: Control
  buyerId?: number
  sellerId?: number
  isShowOverrides: boolean
  onChangeInfo?: (obj: any) => void
}
interface OptionType {
  label: string
  value: number
}

const selectGreenStyle: StylesConfig<OptionType, false> = {
  control: base => ({
    ...base,
    borderColor: '#2dd55b',
  }),
}

export const ScheduleLoadsOrderOverrrides = ({
  index,
  buyerId,
  sellerId,
  control,
  isShowOverrides = false,
  onChangeInfo,
}: Props) => {
  const [show, setShow] = useState(false)

  const companies = useSelector<ICompany[]>(selectCompanies)
  const sellerTerminalOptions = useSelector(selectTerminalOptions)

  const productOrderTypes = useMemo(
    () => [EOrderType.primary, EOrderType.both],
    [],
  )

  const buyerSellerProductId = useWatch({
    control,
    name: `schedulesAttributes[${index}].buyerSellerProductId`,
  })

  const status = useWatch({
    control,
    name: `schedulesAttributes[${index}].status`,
  })

  const color = useWatch({
    control,
    name: `schedulesAttributes[${index}].color`,
  })

  const sellerTerminalId = useWatch({
    control,
    name: `schedulesAttributes[${index}].sellerTerminalId`,
  })

  const fleetId = useWatch({
    control,
    name: `schedulesAttributes[${index}].fleetId`,
  })

  const orderBuyerSellerProductId = useWatch({
    control,
    name: 'buyerSellerProductId',
  })

  const orderStatus = useWatch({
    control,
    name: 'status',
  })

  const orderColor = useWatch({
    control,
    name: 'color',
  })

  const orderSellerTerminalId = useWatch({
    control,
    name: 'sellerTerminalId',
  })

  const orderFleetId = useWatch({
    control,
    name: 'fleetId',
  })

  const fleetOptions = useMemo(() => {
    return (
      companies &&
      Object.values(companies)
        .filter(company => company.fleet)
        .map(company => ({
          label: company.name,
          value: company.id,
        }))
    )
  }, [companies])

  useEffect(() => {
    setShow(isShowOverrides)
  }, [isShowOverrides])

  useEffect(() => {
    const info = {
      count: 0,
      buyerSellerProductId:
        buyerSellerProductId != orderBuyerSellerProductId
          ? buyerSellerProductId
          : undefined,
      status: status != orderStatus ? status : undefined,
      color: color != orderColor ? color : undefined,
      sellerTerminalId:
        sellerTerminalId != orderSellerTerminalId
          ? sellerTerminalId
          : undefined,
      fleetId: fleetId != orderFleetId ? fleetId : undefined,
    }

    const nonNullFieldsCount = Object.entries(info).filter(
      ([key, value]) => key !== 'count' && value !== undefined,
    ).length
    info.count = nonNullFieldsCount
    console.log(info)
    onChangeInfo && onChangeInfo(info)
  }, [
    buyerSellerProductId,
    color,
    fleetId,
    onChangeInfo,
    orderBuyerSellerProductId,
    orderColor,
    orderFleetId,
    orderSellerTerminalId,
    orderStatus,
    sellerTerminalId,
    status,
  ])

  return (
    <div className='d-flex flex-wrap gap-1 align-items-center mb-2 '>
      <Collapse in={show}>
        <div>Overrides:</div>
      </Collapse>

      <ScheduleLoadsOverrideRenderInput
        show={show}
        field={buyerSellerProductId}
        orderField={orderBuyerSellerProductId}
        btnName='Product'
      >
        <ProductSelect
          name={`schedulesAttributes[${index}].buyerSellerProductId`}
          placeholder='Select Product'
          buyerId={buyerId}
          sellerId={sellerId}
          control={control}
          orderType={productOrderTypes}
          disabled={!(buyerId && sellerId)}
          defaultMenuIsOpen
        />
      </ScheduleLoadsOverrideRenderInput>

      <ScheduleLoadsOverrideRenderInput
        show={show}
        field={status}
        orderField={orderStatus}
        btnName='Status'
      >
        <FloatingSelectV2
          control={control}
          name={`schedulesAttributes[${index}].status`}
          label={'Status'}
          placeholder='Select Status'
          options={statusOptions}
          styles={status && selectGreenStyle}
          defaultMenuIsOpen
        />
      </ScheduleLoadsOverrideRenderInput>

      <ScheduleLoadsOverrideRenderInput
        show={show}
        field={color}
        orderField={orderColor}
        btnName='Color'
      >
        <FloatingSelectV2
          control={control}
          name={`schedulesAttributes[${index}].color`}
          label='Color'
          placeholder='Color'
          options={colorOptions}
          styles={color && selectGreenStyle}
          defaultMenuIsOpen
        />
      </ScheduleLoadsOverrideRenderInput>

      <ScheduleLoadsOverrideRenderInput
        show={show}
        field={sellerTerminalId}
        orderField={orderSellerTerminalId}
        btnName='Seller Terminal'
      >
        <FloatingSelectV2
          control={control}
          name={`schedulesAttributes[${index}].sellerTerminalId`}
          label='Seller Terminal'
          options={sellerTerminalOptions}
          isDisabled={!sellerId ? true : false}
          styles={sellerTerminalId && selectGreenStyle}
          defaultMenuIsOpen
        />
      </ScheduleLoadsOverrideRenderInput>

      <ScheduleLoadsOverrideRenderInput
        show={show}
        field={fleetId}
        orderField={orderFleetId}
        btnName='Fleet'
      >
        <FloatingSelectV2
          control={control}
          name={`schedulesAttributes[${index}].fleetId`}
          label='Fleet'
          options={fleetOptions}
          styles={fleetId && selectGreenStyle}
          defaultMenuIsOpen
        />
      </ScheduleLoadsOverrideRenderInput>
    </div>
  )
}
