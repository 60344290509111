import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type {
  IGetSellerProductQueryParams,
  IGetSellerProductResponse,
  ISellerProduct,
} from '~/types/models/ISellerProduct'
import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'

const useModifySellerProducts = (params: IGetSellerProductQueryParams = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'sellerProducts',
      sessionUser?.id,
      buildGetUrl(apiClient.sellerProducts.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addSellerProduct = useCallback(
    (sellerProduct: ISellerProduct) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return {
              sellerProducts: [sellerProduct, ...oldData.sellerProducts],
            }
          }

          return {
            sellerProducts: [sellerProduct],
          }
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateSellerProduct = useCallback(
    (id: number, sellerProduct: Partial<ISellerProduct>) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.sellerProducts.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft.sellerProducts[index] = {
                  ...draft.sellerProducts[index],
                  ...sellerProduct,
                }
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const deleteSellerProduct = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.sellerProducts.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft.sellerProducts.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return { updateSellerProduct, deleteSellerProduct, addSellerProduct }
}

export default useModifySellerProducts
