import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Form, Button } from 'react-bootstrap'
import PdfViewer from '../PdfViewer'
import TooltipErrorMessage from '../TooltipErrorMessage'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import clsx from 'clsx'

import './PdfSplitting.scss'

const PdfSplitting = props => {
  const {
    pdfUrl,
    onChangeTotalPages,
    minPage,
    maxPage,
    onSplitPage,
    forwardButtonProps,
    backButtonProps,
    isOriginalDoc,
    isSelectDeletedPart,
    numberSplitPages,
  } = props

  const schema = Yup.object({
    from: Yup.number('This field is number!')
      .typeError('This field is required!')
      .required('This field is required!')
      .nullable()
      .test(
        'check-max',
        '"From" value should be less than or equal to the "To" field',
        function (value) {
          const { to } = this.options.parent

          return value <= to
        },
      )
      .test('check-min', `at least ${minPage}`, value => value >= minPage),
    to: Yup.number('This field is number!')
      .required('This field is required!')
      .typeError('This field is required!')
      .nullable()
      .test(
        'check-max',
        `maximum value is ${maxPage}`,
        value => value <= maxPage,
      )
      .test(
        'check-max',
        '"To" value should be greater than or equal to the "To" field',
        function (value) {
          const { from } = this.options.parent

          return value >= from
        },
      ),
  })

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues: {
      from: null,
      to: null,
    },
    resolver: yupResolver(schema),
  })

  const watchFromValue = watch('from', null)
  const watchToValue = watch('to', null)

  const isPageInNumberSplitPage = useMemo(() => {
    return numberSplitPages.some(
      ([min, max]) => watchFromValue === min && watchToValue === max,
    )
  }, [numberSplitPages, watchFromValue, watchToValue])

  const handleSubmitSplitPage = useCallback(
    formValues => {
      onSplitPage && onSplitPage(formValues)
    },
    [onSplitPage],
  )

  useEffect(() => {
    setValue('from', minPage)
  }, [minPage, setValue])

  useEffect(() => {
    setValue('to', maxPage)
  }, [maxPage, setValue])

  return (
    <div className='PdfSplitting__container'>
      <Form
        className='PdfSplitting__form'
        onSubmit={handleSubmit(handleSubmitSplitPage)}
      >
        <Form.Group className='PdfSplitting__pageGroupInput'>
          <Form.Label className='PdfSplitting__pageGroupInputLabel'>
            From
          </Form.Label>
          <TooltipErrorMessage message={formState.errors?.from?.message}>
            <Form.Control
              {...register('from')}
              className={clsx('PdfSplitting__pageGroupInputField', {
                error: formState.errors?.from,
              })}
              type='number'
              required
              min={1}
              disabled={isOriginalDoc || isSelectDeletedPart}
            />
          </TooltipErrorMessage>
        </Form.Group>
        <Form.Group className='PdfSplitting__pageGroupInput form-inline'>
          <Form.Label className='PdfSplitting__pageGroupInputLabel'>
            To
          </Form.Label>
          <TooltipErrorMessage message={formState.errors?.to?.message}>
            <Form.Control
              {...register('to')}
              disabled={isOriginalDoc || isSelectDeletedPart}
              required
              type='number'
              min={1}
              className={clsx('PdfSplitting__pageGroupInputField', {
                error: formState.errors?.to,
              })}
            />
          </TooltipErrorMessage>
        </Form.Group>

        <Form.Group className='PdfSplitting__pageGroupInput'>
          <Button
            disabled={
              isOriginalDoc || isSelectDeletedPart || isPageInNumberSplitPage
            }
            type='submit'
            className='PdfSplitting__pageGroupSubmitButton'
          >
            Split
          </Button>
        </Form.Group>
      </Form>

      <PdfViewer
        pdfUrl={pdfUrl}
        onChangeTotalPages={onChangeTotalPages}
        minPage={minPage}
        maxPage={maxPage}
        forwardButtonProps={forwardButtonProps}
        backButtonProps={backButtonProps}
      />
    </div>
  )
}

export default PdfSplitting
