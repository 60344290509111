import { useCallback, useState } from 'react'

import { pencilOutline } from 'ionicons/icons'

import { Dropdown } from 'react-bootstrap'
import { IonIcon, IonText } from '@ionic/react'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { INoteOnSubmitParams, NoteItem } from './NoteItem'
import type { INoteButtonProps } from './type'

import './styles.scss'

function NoteEditDropdown(props: INoteButtonProps) {
  const { afterUpdateNote, noteData } = props

  const [showMenu, setShowMenu] = useState(false)

  const onEditNote = useCallback(
    async ({ note, id }: INoteOnSubmitParams) => {
      const { errors, ...response } = await apiClient.notes.update(
        id as number,
        {
          note: {
            note,
          },
        },
      )
      if (response.id) {
        setShowMenu(false)
        afterUpdateNote && afterUpdateNote(response)
        toast.success(toastMessages.createSuccess)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [afterUpdateNote],
  )

  return (
    <Dropdown
      show={showMenu}
      onToggle={status => {
        setShowMenu(status)
      }}
      style={{
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    >
      <Dropdown.Toggle
        style={{
          backgroundColor: 'white',
          borderWidth: 0,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 0,
        }}
      >
        <IonIcon
          icon={pencilOutline}
          color='primary'
          style={{ width: 15, height: 15 }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div style={{ margin: 10 }}>
          <IonText style={{ fontSize: 13, fontWeight: 500 }}>Notes</IonText>
          <NoteItem
            isUpdating
            isHiddenCloseButton
            onSubmit={onEditNote}
            noteData={noteData}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default NoteEditDropdown
