import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'
import { useMemo } from 'react'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type {
  IGetWorkPayRulesQueryParams,
  IWorkPayRule,
} from '~/types/models/IWorkPayRule'
import { ICommonOption } from '~/types/models/ICommonModel'
import useModifyWorkPayRules from './useModifyWorkPayRules'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryWorkPayRules = (
  params: IGetWorkPayRulesQueryParams = {},
  options: UseQueryOptions<IWorkPayRule[]> = {},
) => {
  const sessionUser = useSelector(selectSessionUser)

  const { createWorkPayRule, updateWorkPayRule, deleteWorkPayRule } =
    useModifyWorkPayRules(params)

  const { data, isLoading } = useQuery({
    queryKey: [
      'workPayRules',
      buildGetUrl(apiClient.workPayRules.endpoint, params),
      sessionUser.id,
    ],
    async queryFn() {
      const response = await apiClient.workPayRules.get(params)
      return response.workPayRules
    },
    enabled: Boolean(sessionUser.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const workPayRules = useMemo(() => data || [], [data])

  const workPayRuleOptions = useMemo<ICommonOption[]>(
    () =>
      workPayRules.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [workPayRules],
  )

  return {
    workPayRules,
    workPayRuleOptions,
    isLoadingWorkPayRules: isLoading,
    createWorkPayRule,
    updateWorkPayRule,
    deleteWorkPayRule,
  }
}

export default useQueryWorkPayRules
