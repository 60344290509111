import { createSelector } from 'reselect'

const getOrderTrackingUIState = state => state.ui.orderTracking
const getOrderTrackingFilters = state => state.ui.orderTracking.filters
const getOrderTrackingCurrentPage = state => state.ui.orderTracking.currentPage
const getOrderTrackingPerpage = state => state.ui.orderTracking.perPage
const getOrderTrackingSearchWord = state => state.ui.orderTracking.searchWord
const getOrderTrackingColumns = state => state.ui.orderTracking.columnsDisplayed
const getOrderTrackingSortOptions = state => state.ui.orderTracking.sortOptions

export const selectOrderTrackingFilterState = createSelector(
  getOrderTrackingCurrentPage,
  getOrderTrackingFilters,
  getOrderTrackingPerpage,
  getOrderTrackingSearchWord,
  (currentPage, filters, perPage, searchWord) => {
    return {
      filters,
      perPage,
      currentPage,
      searchWord,
    }
  },
)

export const selectOrderTrackingFilters = createSelector(
  selectOrderTrackingFilterState,
  filters => filters.filters,
)

export const selectOrderTrackingFiltersLite = createSelector(
  getOrderTrackingUIState,
  state => state.filtersLite,
)

export const selectOrderTrackingSearchWord = createSelector(
  getOrderTrackingSearchWord,
  searchWord => searchWord,
)
export const selectOrderTrackingColumns = createSelector(
  getOrderTrackingColumns,
  columns => columns,
)

export const selectOrderTrackingSortOptions = createSelector(
  getOrderTrackingSortOptions,
  sortOptions => sortOptions,
)

export const selectOrderTrackingOnlyWithLoads = createSelector(
  getOrderTrackingUIState,
  ui => ui.showOnlyOrdersWithLoads,
)

export const selectOrderTrackingMilitaryTime = createSelector(
  getOrderTrackingUIState,
  ui => ui.isMilitaryTime,
)
