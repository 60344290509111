import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const CollapseAllIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill={color}
      {...svgProps}
    >
      <path d='M9 9H4v1h5V9z' />
      <path
        fillRule='evenodd'
        d='m5 3 1-1h7l1 1v7l-1 1h-2v2l-1 1H3l-1-1V6l1-1h2V3zm1 2h4l1 1v4h2V3H6v2zm4 1H3v7h7V6z'
        clipRule='evenodd'
      />
    </svg>
  )
}
export default CollapseAllIcon
