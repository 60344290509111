import { CommonDialogV2 } from '~/components/shared'
import EmailForm from './EmailForm'

import './DialogEmailForm.scss'

function DialogEmailForm(props) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    companyEmailsData,
    ...dialogProps
  } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Email'
      isHiddenOkButton
    >
      <EmailForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
        companyEmailsData={companyEmailsData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}
export default DialogEmailForm
