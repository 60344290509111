import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { IUseQuerySchedulesData, IUseQuerySchedulesParams } from './type'
import { INote } from '~/types/models/INote'
import { ISchedule } from '~/types/models/ISchedule'
import { ILoad } from '~/types/models/ILoad'

const useModifySchedules = (params: IUseQuerySchedulesParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = [
    'schedules',
    sessionUser?.id,
    buildGetUrl(apiClient.schedules.endpoint, params),
  ]

  const addSchedule = (schedule: ISchedule) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData => {
        if (oldData) {
          return [schedule, ...oldData]
        }

        return [schedule]
      },
    )
  }

  const updateSchedule = (scheduleId: number, schedule: Partial<ISchedule>) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(({ id }) => id === scheduleId)
            if (index !== -1) {
              draft[index] = {
                ...draft[index],
                ...schedule,
              }
            }
          }
        }),
    )
  }

  const updateLoad = (load: ILoad) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft) {
            let loadIndex = -1
            const scheduleIndex = draft.findIndex(({ loads }) => {
              if (loads) {
                loadIndex = loads.findIndex(({ id }) => id === load.id)
              }
              return loadIndex > -1
            })
            if (scheduleIndex !== -1) {
              if (loadIndex !== -1) {
                draft[scheduleIndex].loads![loadIndex] = {
                  ...draft[scheduleIndex].loads![loadIndex],
                  ...load,
                }
              }
            }
          }
        }),
    )
  }

  const addScheduleNote = (scheduleId: number, note: INote) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(({ id }) => id === scheduleId)
            if (index !== -1) {
              if (draft[index].notes) {
                draft[index].notes?.push(note)
              } else {
                draft[index].notes = [note]
              }
            }
          }
        }),
    )
  }

  const updateScheduleNote = (scheduleId: number, note: INote) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(({ id }) => id === scheduleId)
            if (index !== -1) {
              const noteIndex = draft[index].notes?.findIndex(
                ({ id }) => id === note.id,
              ) as number
              if (noteIndex !== -1) {
                draft[index]!.notes![noteIndex] = note
              }
            }
          }
        }),
    )
  }

  const deleteScheduleNote = (scheduleId: number, noteId: number) => {
    queryClient.setQueryData<IUseQuerySchedulesData | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(({ id }) => id === scheduleId)
            if (index !== -1) {
              const noteIndex = draft[index].notes?.findIndex(
                ({ id }) => id === noteId,
              ) as number
              if (noteIndex !== -1) {
                draft[index]?.notes?.splice(noteIndex, 1)
              }
            }
          }
        }),
    )
  }

  return {
    addScheduleNote,
    updateScheduleNote,
    deleteScheduleNote,
    updateSchedule,
    addSchedule,
    updateLoad,
  }
}

export default useModifySchedules
