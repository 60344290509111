import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { IonButton, IonProgressBar } from '@ionic/react'
import { Card } from 'react-bootstrap'
import { ConcordFormDropdown, ConcordFormTextField } from '~/components/shared'
import CompanyFormatOptionLabel from '~/components/shared/ConcordForm/Dropdown/Company/FormatOptionLabel'

import _ from 'lodash'
import {
  selectMyCurrentCompany,
  selectAllSellerCompaniesOptions,
  selectCompanySellerOptions,
  selectCompanyFleetOptions,
  selectCompanyBuyerOptions,
  selectAllFleetCompaniesOptions,
  selectAllBuyerCompaniesOptions,
} from '~/redux/selectors'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt.js'
import companiesDataSlice from '~/redux/reducers/data/companies'
import { toast } from 'react-toastify'
import {
  docTypes,
  dropdownType,
  USER_SCOPE,
  ALL_OPTION,
  OTHER_OPTION,
} from '~/utils/constants'
import { toastMessages } from '~/constants/toast-status-text'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { fetchAllCompanies } from '~/redux/actions/CommonData/companiesActions'
import { updateBuyerSeller } from '~/redux/actions/CommonData/buyerSellerActions'

import './CompanyCreateEditForm.scss'
import { apiClient } from '~/api/ApiClient'

function CompanyCreateEditForm(props) {
  const {
    createRequestPayload,
    docType,
    documentId,
    handleToggleReactSelect,
    inputDropdownValue,
    onChange,
    showDropdown,
    type,
  } = props

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  // const isSellerType = useMemo(() => type === USER_SCOPE.seller, [type])

  const dispatch = useDispatch()
  const companySellerOptions = useSelector(state => {
    if (type === USER_SCOPE.buyer) {
      return selectCompanyBuyerOptions(state)
    }

    if (type === USER_SCOPE.seller) {
      return selectCompanySellerOptions(state)
    }

    if (type === USER_SCOPE.fleet) {
      return selectCompanyFleetOptions(state)
    }
  })

  const allCompanySellesOptions = useSelector(state => {
    switch (type) {
      case USER_SCOPE.buyer: {
        return selectAllBuyerCompaniesOptions(state)
      }

      case USER_SCOPE.seller: {
        return selectAllSellerCompaniesOptions(state)
      }

      case USER_SCOPE.fleet: {
        return selectAllFleetCompaniesOptions(state)
      }

      default: {
        return []
      }
    }
  })
  const myCompany = useSelector(selectMyCurrentCompany)

  const schema = yup.object({
    name: yup.string().required('This field is required!'),
    code: yup.string().required('This field is required!'),
  })

  const { watch, setValue, handleSubmit, reset, clearErrors } = useForm({
    defaultValues: {
      name: '',
      code: '',
      id: null,
      image: null,
      type: '',
    },
    resolver: yupResolver(schema),
  })

  const watchName = watch('name', '')
  const watchCode = watch('code', '')
  const watchId = watch('id', null)

  const isCreatingCompany = _.isNil(watchId)

  const options = useMemo(() => {
    const label = `${_.startCase(type)}s`

    const otherSellers = {
      label: `Other ${label}`,
      options: companySellerOptions,
    }

    const allSellers = {
      label: `Other ${label}`,
      options: _.differenceBy(
        allCompanySellesOptions,
        companySellerOptions,
        'value',
      ),
    }

    return [otherSellers, allSellers]
  }, [allCompanySellesOptions, companySellerOptions, type])

  const handleChangeSellerName = useCallback(
    (selectedOption, { action }) => {
      const { label, value, name, code, image, allOption } = selectedOption
      if (action === 'create-option') {
        setValue('name', label)
        setValue('code', '')
        setValue('id', null)
        setValue('image', null)
      } else {
        setValue('name', name)
        setValue('code', code)
        setValue('id', value)
        setValue('image', image)
        setValue('type', allOption ? ALL_OPTION : OTHER_OPTION)
        clearErrors(['code'])
      }
    },
    [clearErrors, setValue],
  )

  const handleChangeSellerCode = useCallback(
    (event, { value }) => {
      setValue('code', value)
    },
    [setValue],
  )

  const handleGetAllCompanies = useCallback(async () => {
    dispatch(fetchAllCompanies())
  }, [dispatch])

  const handleCloseForm = useCallback(() => {
    setIsOpenForm(false)
    handleToggleReactSelect && handleToggleReactSelect(true)
  }, [handleToggleReactSelect])

  const handleOpenForm = useCallback(() => {
    setIsOpenForm(true)
    handleToggleReactSelect && handleToggleReactSelect(false)
  }, [handleToggleReactSelect])

  const createNewBuyerSellerRelationship = useCallback(
    async formValues => {
      if (type === USER_SCOPE.buyer) {
        return
      }
      const { id: sellerId } = formValues
      setFormLoading(true)
      try {
        const relationship = type === USER_SCOPE.seller ? 'Seller' : 'Fleet'
        const response = await apiClient.buyerSellers.create({
          sellerId,
          buyerId: myCompany.id,
          relationship,
        })
        if (response.id) {
          dispatch(companiesDataSlice.actions.addCompany(response.seller))
          dispatch(updateBuyerSeller(response))
          onChange &&
            onChange({
              ...formValues,
              value: sellerId,
            })
          setIsOpenForm(false)
        } else {
          toast.error(
            _.get(response, 'errors[0]') ||
            'An error has occurred while creating a new entry.',
          )
        }
      } catch (error) {
        console.log('error', error)
        toast.error('An error has occurred while creating a new entry.')
      } finally {
        setFormLoading(false)
      }
    },
    [dispatch, myCompany.id, onChange, type],
  )

  const createCompany = useCallback(
    async formValues => {
      setFormLoading(true)
      try {
        const { code, name } = formValues
        const requestPayload = {
          legalName: name,
          code: code,
          name: name,
          loadStatusTableId: 1,
        }

        // if (isSellerType) {
        //   requestPayload.Seller = 'Yes'
        // } else {
        //   requestPayload.Fleet = 'Yes'
        // }
        if (type) {
          requestPayload[_.capitalize(type)] = 'Yes'
        }

        if (documentId) {
          if (docType === docTypes.Invoice) {
            requestPayload.invoiceExampleDocumentId = documentId
          } else if (docType === docTypes.Load) {
            requestPayload.ticketExampleDocumentId = documentId
          }
        }

        const mergeRequest = _.merge(requestPayload, createRequestPayload)

        const { company: createdCompany, buyerSeller } =
          await apiClient.companies.create({ company: mergeRequest }, true)
        if (createdCompany.id) {
          toast.success(toastMessages.createSuccess)
          const mapBuyerSeller = {
            ...buyerSeller,
          }
          if (type === USER_SCOPE.buyer) {
            mapBuyerSeller.buyer = myCompany
            mapBuyerSeller.seller = createdCompany
          } else {
            mapBuyerSeller.buyer = createdCompany
            mapBuyerSeller.seller = myCompany
          }
          dispatch(companiesDataSlice.actions.addCompany(createdCompany))
          dispatch(updateBuyerSeller(mapBuyerSeller))

          onChange &&
            onChange({
              ...formValues,
              value: createdCompany.id,
            })
          setIsOpenForm(false)
        } else {
          toast.error(
            _.get(createdCompany, 'errors.[0]') || toastMessages.createError,
          )
        }
      } catch (error) {
        console.log('error', error)
        toast.error('An error has occurred while creating a new entry.')
      } finally {
        setFormLoading(false)
      }
    },
    [
      createRequestPayload,
      dispatch,
      docType,
      documentId,
      myCompany,
      onChange,
      type,
    ],
  )

  const addCompany = useCallback(
    formValues => {
      if (formValues.type === OTHER_OPTION) {
        onChange && onChange(formValues, {})
        setIsOpenForm(false)
      } else {
        createNewBuyerSellerRelationship(formValues)
      }
    },
    [createNewBuyerSellerRelationship, onChange],
  )

  const handleSubmitForm = handleSubmit(formValues => {
    if (formValues.id) addCompany(formValues)
    else createCompany(formValues)
  })

  const handleResetCompaniesRedux = useCallback(() => {
    dispatch(
      lastFetchedAtSlice.actions.updateLastFetchedAt({
        model: 'companies',
        lastFetchedAt: null,
      }),
    )
  }, [dispatch])

  useUpdateEffect(() => {
    if (isOpenForm) handleGetAllCompanies()
    else reset()
  }, [handleGetAllCompanies, isOpenForm])

  useUpdateEffect(() => {
    if (!showDropdown) {
      reset()
      setIsOpenForm(false)
    }
  }, [showDropdown])

  useEffect(() => {
    return () => handleResetCompaniesRedux()
  }, [handleResetCompaniesRedux])

  return (
    <>
      {!isOpenForm && (
        <IonButton
          onClick={handleOpenForm}
          className='CompanyCreateEditForm__addNewCompanyBtn'
        >
          Add new {_.capitalize(type)}
        </IonButton>
      )}
      {isOpenForm && (
        <Card>
          {formLoading && <IonProgressBar type='indeterminate' />}
          <Card.Body>
            <form>
              <ConcordFormDropdown
                value={{ value: watchId, label: watchName }}
                options={options}
                label={`${_.capitalize(type)} Name`}
                isLoading={formLoading}
                onChange={handleChangeSellerName}
                hideSelectedOptions
                dropdownType={dropdownType.creatable}
                formatOptionLabel={CompanyFormatOptionLabel}
                showDropdown={false}
                inputValue={inputDropdownValue}
              />

              <ConcordFormTextField
                label={`${_.capitalize(type)} Code`}
                value={watchCode}
                onChange={handleChangeSellerCode}
                disabled={watchId}
              />
            </form>
          </Card.Body>
          <Card.Footer>
            <IonButton
              color='primary'
              onClick={handleSubmitForm}
              disabled={formLoading}
            >
              {isCreatingCompany ? 'Create new company' : 'Add'}
            </IonButton>
            <IonButton
              color='danger'
              onClick={handleCloseForm}
              disabled={formLoading}
            >
              Close
            </IonButton>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

CompanyCreateEditForm.propTypes = {}

CompanyCreateEditForm.defaultProps = {
  type: USER_SCOPE.seller,
}

export default CompanyCreateEditForm
