import React from 'react'
import { addLocaleData, IntlProvider } from 'react-intl'
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from '../constants/constants'
import locales from '.'
import includes from 'lodash/includes'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'

// Fix for browsers that don't implement Intl by default e.g.: Safari)
if (!window.Intl) {
  require([
    'intl',
    'intl/locale-data/jsonp/en.js',
    'intl/locale-data/jsonp/es.js',
  ], require => {
    require('intl')
    require('intl/locale-data/jsonp/en.js')
    require('intl/locale-data/jsonp/es.js')
  })
}

const LocalizedProvider = ({ children }) => {
  addLocaleData([...en, ...es])
  const usersLocale = navigator.language.split('-')[0]
  const supportedUserLocale = includes(SUPPORTED_LANGUAGES, usersLocale)
  const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE
  const messages = locales[locale]

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale='en'>
      {children}
    </IntlProvider>
  )
}

export default LocalizedProvider
