import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IReferenceNameFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { IReferenceNameFormData } from '~/types/models/IReferenceName'

const ReferenceName = (props: IReferenceNameFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const {
    buyerCompanyOptionsWithCurrentCompany,
    sellerCompanyOptionsWithCurrentCompany,
  } = useQueryBuyerSellers()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Buyer',
        name: 'buyerId',
        options: buyerCompanyOptionsWithCurrentCompany,
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        isReadOnly: currentScope === EScope.buyer,
      },
      {
        label: 'Seller',
        name: 'sellerId',
        options: sellerCompanyOptionsWithCurrentCompany,
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        isReadOnly: currentScope === EScope.seller,
      },
      {
        label: 'Item Type',
        name: 'itemType',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: [
          'Terminal',
          'Company',
          'BuyerTerminal',
          'BuyerSellerProduct',
          'SellerTerminal',
        ].map(field => ({
          value: field,
          label: field,
        })),
      },
      {
        label: 'Item #',
        name: 'itemId',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Reference Name',
        name: 'referenceName',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Reference Code',
        name: 'referenceCode',
        size: 6,
        isRequired: true,
      },
    ],
    [
      buyerCompanyOptionsWithCurrentCompany,
      currentScope,
      sellerCompanyOptionsWithCurrentCompany,
    ],
  )

  const defaultValues = useMemo<IReferenceNameFormData>(
    () => ({
      buyerId: null,
      sellerId: null,
      itemType: '',
      itemId: '',
      referenceCode: '',
      referenceName: '',
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        buyerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        itemType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        itemId: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        referenceCode: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        referenceName: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IReferenceNameFormData) => {
      const payload = _.pick(formValues, [
        'buyerId',
        'sellerId',
        'itemType',
        'itemId',
        'referenceCode',
        'referenceName',
      ])
      const { errors, referenceName } = await apiClient.referenceNames.create({
        referenceName: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(referenceName)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IReferenceNameFormData) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'buyerId',
          'sellerId',
          'itemType',
          'itemId',
          'referenceCode',
          'referenceName',
        ])
        const { errors, referenceName } = await apiClient.referenceNames.update(
          formValues.id,
          {
            referenceName: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(referenceName)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IReferenceNameFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating) {
      if (currentScope === EScope.seller) {
        formRef.current?.setValue('sellerId', currentCompany.id)
      } else {
        formRef.current?.setValue('buyerId', currentCompany.id)
      }
    }
  }, [currentCompany.id, currentScope, isUpdating])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default ReferenceName
