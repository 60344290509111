import _ from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CompanyInfo } from '~/components/shared'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'

const useTableCompanyEmailParserSubTab = () => {
  const { allCompaniesWithCurrentCompany } = useQueryBuyerSellers()

  const currentCompany = useSelector(selectMyCurrentCompany)
  const currentScope = useSelector(selectCurrentScope)

  const columns = useMemo(
    () => [
      {
        header: 'Company',
        accessorKey: 'companyId',
        size: 250,
        Cell({ cell }) {
          const cellData = cell.getValue()
          const company = allCompaniesWithCurrentCompany.find(
            ({ id }) => id === cellData,
          )

          return (
            <CompanyInfo
              companyType={
                cellData === currentCompany.id
                  ? currentScope
                  : currentScope === EScope.seller
                  ? EScope.buyer
                  : EScope.seller
              }
              company={{
                value: company?.id,
                name: company?.name,
                code: company?.code,
                image: company?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Model',
        accessorKey: 'model',
        minSize: 100,
        maxSize: 100,
      },
      {
        header: 'Source Columns',
        id: 'sourceColumns',
        accessorFn: row =>
          (row.sourceColumns || []).map(text => _.startCase(text)).join(', '),
      },
      {
        header: 'Destination Column',
        id: 'destinationColumn',
        accessorFn: row => _.startCase(row.destinationColumn),
      },
      {
        header: 'Regexp',
        accessorKey: 'regexp',
        enableEditing: true,
      },
      {
        header: 'Header Matching Array',
        id: 'headerMatchingArray',
        minSize: 200,
        accessorFn: row => row.headerMatchingArray.join(', '),
      },
      {
        header: 'Array Value Delimiter',
        accessorKey: 'arrayValueDelimiter',
      },
    ],
    [allCompaniesWithCurrentCompany, currentCompany.id, currentScope],
  )

  return { columns }
}

export default useTableCompanyEmailParserSubTab
