import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const ArrowUpIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M12 5v14m0-14-6 6m6-6 6 6'
      />
    </svg>
  )
}

export default ArrowUpIcon
