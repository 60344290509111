import { Badge, Button } from 'react-bootstrap'
import './styles.scss'
import { GoogleMap } from '~/components/shared'
import { useEffect, useMemo, useState } from 'react'

export interface ICompanyItemBadgeListProps<T> {
  label: string
  data: T[]
  canShowMapOnClick?: boolean
  canShowAllItemsOnMap?: boolean
  render: (item: T, itemSelected: T | null) => JSX.Element
  renderElementOnMap?: (item: T) => JSX.Element
  fnFetchDefaultCenter?: (item: T) => Promise<google.maps.LatLng>
}

const CompanyItemBadgeList = <T extends { id: number }>(
  props: ICompanyItemBadgeListProps<T>,
) => {
  const {
    label,
    render,
    data,
    canShowMapOnClick,
    canShowAllItemsOnMap,
    renderElementOnMap,
    fnFetchDefaultCenter,
  } = props

  const [itemSelected, setItemSelected] = useState<T | null>(null)
  const [isShowingAllItemsOnMap, setIsShowingAllItemsOnMap] = useState(false)

  const [defaultCenterFetched, setDefaultCenterFetched] =
    useState<google.maps.LatLng | undefined>(undefined)

  const bounding = useMemo(
    () =>
      data.map(item => {
        const lat = (item as any).location?.lat
        const lng = (item as any).location?.lng
        if (lat && lng) {
          return {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          }
        }

        return undefined
      }),
    [data],
  )

  const onClickItem = (item: T) => () => {
    if (canShowMapOnClick) {
      if (isShowingAllItemsOnMap) {
        setIsShowingAllItemsOnMap(false)
      }
      setItemSelected(prev => {
        if (prev?.id === item.id) {
          return null
        }
        return item
      })
    }
  }

  const defaultCenter = useMemo(() => {
    if (itemSelected) {
      if (typeof fnFetchDefaultCenter === 'function') {
        if (defaultCenterFetched) {
          return defaultCenterFetched
        }
        return new window.google.maps.LatLng(0, 0)
      }

      return new window.google.maps.LatLng(
        Number((itemSelected as any).location?.lat || 0),
        Number((itemSelected as any).location?.lng || 0),
      )
    }
    return new window.google.maps.LatLng(0, 0)
  }, [defaultCenterFetched, fnFetchDefaultCenter, itemSelected])

  useEffect(() => {
    if (itemSelected && typeof fnFetchDefaultCenter === 'function') {
      fnFetchDefaultCenter(itemSelected).then(res => {
        setDefaultCenterFetched(res)
      })
    }
  }, [fnFetchDefaultCenter, itemSelected])

  return (
    <div style={{ borderTop: '1px solid #e5e5e5', padding: 8 }}>
      <div
        style={{
          fontWeight: 600,
          fontSize: 14,
          marginBottom: 8,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>
          <span>{label}</span>
          {canShowAllItemsOnMap && (
            <Button
              style={{ margin: 4, fontSize: 12 }}
              variant={isShowingAllItemsOnMap ? 'danger' : 'primary'}
              onClick={() => {
                setIsShowingAllItemsOnMap(prev => !prev)
                if (itemSelected) {
                  setItemSelected(null)
                }
              }}
            >
              {isShowingAllItemsOnMap ? 'Hide all' : 'Show all'}
            </Button>
          )}
        </div>
        <div>
          <Badge bg='danger'>{data.length}</Badge>
        </div>
      </div>
      <div>
        {data.map(item => (
          <div
            style={{
              fontSize: 14,
              display: 'inline-block',
              marginBottom: 4,
              marginRight: 4,
            }}
            key={item.id}
            onClick={onClickItem(item)}
          >
            {render(item, itemSelected)}
          </div>
        ))}
      </div>

      {itemSelected && !isShowingAllItemsOnMap ? (
        <div className='CompanyItemBadgeList__googleMap'>
          <GoogleMap defaultCenter={defaultCenter as any} zoom={14}>
            {renderElementOnMap && itemSelected
              ? renderElementOnMap(itemSelected as T)
              : null}
          </GoogleMap>
        </div>
      ) : null}

      {isShowingAllItemsOnMap && (
        <div className='CompanyItemBadgeList__googleMap'>
          <GoogleMap bounding={bounding as any} zoom={2}>
            {data.map(renderElementOnMap as any)}
          </GoogleMap>
        </div>
      )}
    </div>
  )
}

export default CompanyItemBadgeList
