export enum EEmailTypes {
  general,
  ap,
  ar,
  sales,
  qc,
  operations,
  hr,
  mgmt,
}

export enum EEmailableType {
  person = 'Person',
  company = 'Company',
  bakAccount = 'BankAccount',
  creditReference = 'CreditReference',
  startTime = 'StartTime',
}
