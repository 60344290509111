import { useMemo, useState } from 'react'
import { ConcordFormStructure } from '../../FormStructure'
import {
  FORM_FIELD_TYPE,
  WORKER_STATUSES,
  WORKER_TAX_TYPES,
  WORKER_TYPES,
} from '~/utils/constants'
import { useSelector } from 'react-redux'
import {
  selectMyCurrentCompany,
  selectMyTerminalOptions,
} from '~/redux/selectors'
import _ from 'lodash'
import { workerSchema } from './schemas'
import { useQueryWorkPayTypes } from '~/hooks/useQueryData'
import { addCircleOutline } from 'ionicons/icons'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { WorkPayTypeForm } from '~/components/shared'

export const WorkerTab = props => {
  const defaultValues = useMemo(
    () => ({
      terminalId: null,
      status: WORKER_STATUSES.active,
      statusReason: '',
      expectedReturn: null,
      workerType: '',
      workPayTypeId: '',
      taxType: '',
      buisnessName: '',
      ein: '',
    }),
    [],
  )

  const [isOpenWorkPayTypeForm, setIsOpenWorkPayTypeForm] = useState(false)

  const terminalOptions = useSelector(selectMyTerminalOptions)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { workPayTypeOptions, addWorkPayType, isLoadingWorkPayTypes } =
    useQueryWorkPayTypes()

  const workerTypeOptions = useMemo(
    () =>
      Object.values(WORKER_TYPES).map(workerType => ({
        value: workerType,
        label: _.startCase(workerType),
      })),
    [],
  )

  const workerTaxTypeOptions = useMemo(
    () =>
      Object.values(WORKER_TAX_TYPES).map(wtt => ({
        value: wtt,
        label: _.startCase(wtt),
      })),
    [],
  )

  const workerStatusOptions = useMemo(
    () =>
      Object.values(WORKER_STATUSES).map(status => ({
        value: status,
        label: _.startCase(status),
      })),
    [],
  )

  const fields = useMemo(() => {
    if (props.isWorkerContractor) {
      console.log('worker is conttractor')
      return [
        {
          name: 'terminalId',
          label: 'Terminal',
          type: FORM_FIELD_TYPE.singleSelect,
          options: terminalOptions,
          size: 4,
        },
        {
          name: 'workerType',
          label: 'Worker Type',
          type: 'singleSelect',
          options: workerTypeOptions,
          isRequired: true,
          isClearable: true,
          size: 4,
        },
        {
          name: 'workPayTypeId',
          label: 'Work Pay Type',
          type: 'singleSelect',
          options: workPayTypeOptions,
          isLoading: isLoadingWorkPayTypes,
          size: 4,
          isClearable: true,
          extraIcons: [
            {
              icon: addCircleOutline,
              color: 'concord',
              onClick() {
                setIsOpenWorkPayTypeForm(prev => !prev)
              },
            },
          ],
        },
        {
          name: 'workPayTypeForm',
          isHidden: !isOpenWorkPayTypeForm,
          render({ setValue }) {
            return (
              <div style={{ border: '1px solid #e5e5e5' }}>
                <WorkPayTypeForm
                  companyId={currentCompany.id}
                  ishiddenCancelButton={false}
                  cancelText='Close'
                  onCancel={() => {
                    setIsOpenWorkPayTypeForm(false)
                  }}
                  afterCreate={newWorkPayType => {
                    addWorkPayType(newWorkPayType)
                    setValue('workPayTypeId', newWorkPayType.id)
                    setIsOpenWorkPayTypeForm(false)
                  }}
                />
              </div>
            )
          },
        },
        {
          name: 'taxType',
          label: 'Tax Type',
          type: 'radio',
          options: workerTaxTypeOptions,
          isHidden: Boolean(props.defaultValues?.checkUid),
        },
        {
          name: 'buisnessName',
          label: 'Business Name',
          type: 'text',
          isHidden: Boolean(props.defaultValues?.checkUid),
        },
        {
          name: 'ein',
          label: 'EIN',
          type: EFieldType.number,
          isHidden: Boolean(props.defaultValues?.checkUid),
        },
      ]
    }

    return [
      {
        name: 'terminalId',
        label: 'Terminal',
        type: FORM_FIELD_TYPE.singleSelect,
        options: terminalOptions,
      },
      {
        name: 'status',
        label: 'Status',
        type: FORM_FIELD_TYPE.singleSelect,
        options: workerStatusOptions,
        size: 4,
        isRequired: true,
        isClearable: true,
      },
      {
        name: 'statusReason',
        label: 'Status Reason',
        type: 'text',
        size: 4,
        isHidden: ({ watch }) =>
          watch('worker.status') !== WORKER_STATUSES.active,
        isRequired: ({ watch }) =>
          watch('worker.status') === WORKER_STATUSES.active ? false : true,
        isClearable: true,
      },
      {
        name: 'expectedReturn',
        label: 'Expected Return',
        type: FORM_FIELD_TYPE.date,
        size: 4,
        isHidden: ({ watch }) =>
          watch('worker.status') !== WORKER_STATUSES.leave,
        isRequired: ({ watch }) =>
          watch('worker.status') === WORKER_STATUSES.leave,
      },
      {
        name: 'workerType',
        label: 'Worker Type',
        type: 'singleSelect',
        options: workerTypeOptions,
        isRequired: true,
        size: 4,
        isClearable: true,
      },
      {
        name: 'workPayTypeId',
        label: 'Work Pay Type',
        type: 'singleSelect',
        options: workPayTypeOptions,
        isLoading: isLoadingWorkPayTypes,
        size: 4,
        isClearable: true,
        extraIcons: [
          {
            icon: addCircleOutline,
            color: 'concord',
            size: 'small',
            onClick() {
              setIsOpenWorkPayTypeForm(prev => !prev)
            },
          },
        ],
      },
      {
        name: 'workPayTypeForm',
        isHidden: !isOpenWorkPayTypeForm,
        render({ setValue }) {
          return (
            <div style={{ border: '1px solid #e5e5e5' }}>
              <WorkPayTypeForm
                companyId={currentCompany.id}
                ishiddenCancelButton={false}
                cancelText='Close'
                onCancel={() => {
                  setIsOpenWorkPayTypeForm(false)
                }}
                afterCreate={newWorkPayType => {
                  addWorkPayType(newWorkPayType)
                  setValue('workPayTypeId', newWorkPayType.id)
                  setIsOpenWorkPayTypeForm(false)
                }}
              />
            </div>
          )
        },
      },
      {
        name: 'taxType',
        label: 'Tax Type',
        type: 'radio',
        options: workerTaxTypeOptions,
        isHidden: Boolean(props.defaultValues?.checkUid),
      },
    ]
  }, [
    props.isWorkerContractor,
    props.defaultValues?.checkUid,
    terminalOptions,
    workerStatusOptions,
    workerTypeOptions,
    workPayTypeOptions,
    isLoadingWorkPayTypes,
    isOpenWorkPayTypeForm,
    workerTaxTypeOptions,
    currentCompany.id,
    addWorkPayType,
  ])

  return (
    <ConcordFormStructure
      fields={fields}
      schema={workerSchema}
      isHiddenCancelButton
      submitText='Next'
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}
