import type { RefObject } from 'react'
import { RTTableBodyRowGrabHandle } from '../../components/body/RTTableBodyRowGrabHandle'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

export const getRTRowDragColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  return {
    Cell: ({ row, rowRef, table }) => (
      <RTTableBodyRowGrabHandle
        row={row}
        rowRef={rowRef as RefObject<HTMLTableRowElement>}
        table={table}
      />
    ),
    grow: false,
    ...defaultDisplayColumnProps({
      header: '',
      id: ERTDisplayColumnId.drag,
      size: 60,
      tableOptions,
    }),
  }
}
