import { IAddress } from '~/types/models/IAddress'
import './styles.scss'
import { useEffect, useState } from 'react'
import { Marker } from 'react-google-maps'
import buildFullAddress from '~/utils/buildFullAddress'

export interface IAddressMarkerProps {
  address: IAddress
}

function AddressMarker(props: IAddressMarkerProps) {
  const { address } = props

  const [addressLocation, setAddressLocation] =
    useState<google.maps.LatLng | undefined>(undefined)

  const fetchLocation = async () => {
    const fullAddress = buildFullAddress(address)
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=AIzaSyD8vF1Sm2An_LVf_oUlafdzshwtWDjYHv0`
    const response = await fetch(url)
    const data = await response.json()
    setAddressLocation(data.results[0].geometry.location)
  }

  useEffect(() => {
    fetchLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  if (addressLocation) {
    return (
      <Marker
        position={addressLocation}
        options={{
          draggable: false,
        }}
        draggable={false}
      />
    )
  }

  return undefined
}

export default AddressMarker
