var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
import { Box, makeStyles } from '@material-ui/core';
import { DocumentLabelerState } from 'documentLabeler/state/DocumentLabelerState';
import { DocumentPanelToolbar } from 'documentLabeler/components/documentPanel/documentPanelToolbar/DocumentPanelToolbar';
import { Button } from 'common/button/Button';
import clsx from 'clsx';
import { ID_FIELDS_PANEL_HEADER_ROOT } from 'documentLabeler/constants/DocumentLabelerConstants';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        padding: function (_a) {
            var showPdf = _a.showPdf;
            return (showPdf ? theme.spacing(2) : 0);
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    ButtonRoot: {
        width: function (_a) {
            var showPdf = _a.showPdf;
            return (showPdf ? '100%' : 'auto');
        },
    },
    Button: {
        width: function (_a) {
            var showPdf = _a.showPdf;
            return (showPdf ? '100%' : theme.spacing(12));
        },
        textTransform: 'none',
    },
}); });
// const FIELDS = 'Fields';
var SAVE = 'Save';
/**
 * Header component for the fields panel which contains the title of the panel
 * and the save button which dispatches the onSaveCallback action
 */
export var FieldsPanelHeader = function (props) {
    var className = props.className, boxProps = __rest(props, ["className"]);
    var _a = useBBConfiguration(), saveActionButtonText = _a.saveActionButtonText, displayOnly = _a.displayOnly, hideSaveButton = _a.hideSaveButton, saveActionButtonProps = _a.saveActionButtonProps, onSaveCallback = _a.onSaveCallback;
    var _b = useDocumentLabeler(), state = _b.state, dispatch = _b.dispatch;
    var _c = state.localState, showPdf = _c.showPdf, isModifiedField = _c.isModifiedField;
    var classes = useStyles({ showPdf: showPdf });
    var onSaveClick = function () {
        onSaveCallback(DocumentLabelerState.convertInternalStateToOutputData(state));
        dispatch({
            type: 'setIsModifiledField',
            payload: false,
        });
    };
    var shouldShowSaveButton = !hideSaveButton && !displayOnly;
    return (_jsxs(Box, __assign({ id: ID_FIELDS_PANEL_HEADER_ROOT, className: clsx(classes.Root, className) }, boxProps, { children: [!showPdf && _jsx(DocumentPanelToolbar, {}), shouldShowSaveButton && (_jsx(Button, __assign({ variant: "contained", color: "primary", className: classes.Button, containerClassName: classes.ButtonRoot, onClick: onSaveClick, disableElevation: true, disabled: !isModifiedField }, saveActionButtonProps, { children: saveActionButtonText || SAVE })))] })));
};
