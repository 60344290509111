var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useLayoutEffect } from 'react';
import { useDocumentDisplayer } from 'documentLabeler/components/documentPanel/documentDisplayer/useDocumentDisplayer';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { Box, makeStyles } from '@material-ui/core';
import { DocumentDisplayer } from 'documentLabeler/components/documentPanel/documentDisplayer/DocumentDisplayer';
import { DocumentBlockLayer } from 'documentLabeler/components/documentPanel/documentBlockLayer/DocumentBlockLayer';
import { DocumentContainer } from 'documentLabeler/components/documentPanel/documentContainer/DocumentContainer';
import { DocumentPanelToolbar } from 'documentLabeler/components/documentPanel/documentPanelToolbar/DocumentPanelToolbar';
import { DocumentImageDisplayer } from 'documentLabeler/components/documentPanel/documentImageDisplayer/DocumentImageDisplayer';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { withSize } from 'react-sizeme';
import clsx from 'clsx';
import { MimeType } from 'common/types/DocumentLabelerTypes';
import { ID_DOCUMENT_LABELER_CONTENT_ROOT, ID_DOCUMENT_PANEL_TOOLBAR__ROOT, } from 'documentLabeler/constants/DocumentLabelerConstants';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        flex: 1,
    },
    DocumentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: function (_a) {
            var heightOfDocumentContainer = _a.heightOfDocumentContainer;
            return heightOfDocumentContainer || 'auto';
        },
        '&.FullHeight': {
            height: '100%',
        },
    },
    PreviewCard: {
        width: '100%',
    },
    DropzoneWrapper: {
        height: theme.spacing(50),
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TableHeightCover: {
        height: theme.spacing(41),
    },
}); });
var SPACING_DOCUMENT_LABELER = 16;
var DocumentPanelInternal = withSize({
    monitorHeight: true,
})(function (_a) {
    var _b, _c;
    var size = _a.size;
    var _d = useState(null), heightOfDocumentContainer = _d[0], setHeightOfDocumentContainer = _d[1];
    var classes = useStyles({ heightOfDocumentContainer: heightOfDocumentContainer });
    var state = useDocumentLabeler().state;
    var width = (_b = size === null || size === void 0 ? void 0 : size.width) !== null && _b !== void 0 ? _b : 0;
    var height = (_c = size === null || size === void 0 ? void 0 : size.height) !== null && _c !== void 0 ? _c : 0;
    var docDisplayerState = useDocumentDisplayer(state.docInfo.mimeType, { width: width, height: height }, state.docInfo.tempDocUrl);
    var isPdf = state.docInfo.mimeType === MimeType.Pdf;
    useLayoutEffect(function () {
        var elDocumentLabelerContent = document.getElementById(ID_DOCUMENT_LABELER_CONTENT_ROOT);
        var elDocumentToolbar = document.getElementById(ID_DOCUMENT_PANEL_TOOLBAR__ROOT);
        if (elDocumentLabelerContent) {
            var heightDocumentToolbar = (elDocumentToolbar === null || elDocumentToolbar === void 0 ? void 0 : elDocumentToolbar.offsetHeight) || 0;
            var calHeight = elDocumentLabelerContent.offsetHeight -
                (heightDocumentToolbar + SPACING_DOCUMENT_LABELER);
            setHeightOfDocumentContainer(calHeight);
        }
        else {
            setHeightOfDocumentContainer(null);
        }
    }, []);
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(DocumentPanelToolbar, {}), _jsxs(Box, __assign({ className: classes.DocumentContainer, "data-testid": "document-labeler" }, { children: [_jsxs(DocumentContainer, __assign({ className: classes.PreviewCard }, { children: [_jsx(DocumentBlockLayer, { docPageHeights: docDisplayerState.pageHeights, width: width }), isPdf && (_jsx(DocumentDisplayer, { document: state.docInfo.tempDocUrl, loaders: docDisplayerState.loaders, pages: docDisplayerState.pages })), !isPdf && (_jsx(DocumentImageDisplayer, { document: state.docInfo.tempDocUrl, width: width }))] })), state.localState.activeField &&
                        state.localState.activeField.type === FieldType.Table && (_jsx(Box, { className: classes.TableHeightCover }))] }))] })));
});
var useExternalStyles = makeStyles(function (theme) { return ({
    Root: {
        display: 'flex',
        flex: 1,
        backgroundColor: theme.palette.background.default,
        overflow: 'scroll',
        maxHeight: '100%',
    },
}); });
/**
 * Component responsible for displaying & labeling the document.
 */
export var DocumentPanel = function () {
    var classes = useExternalStyles();
    return (_jsx(Box, __assign({ className: clsx(classes.Root, 'DocumentPanel__root') }, { children: _jsx(DocumentPanelInternal, {}) })));
};
