import React, { useCallback, useState } from 'react'
import { IonRow, IonCol, IonCard, IonButton, IonCheckbox } from '@ionic/react'
import { dollar, toFixedWithLocaleString } from '~/utils/utils'
import { SimpleDatePicker } from '~/components/shared'

import './styles.scss'

/**
 * NewTableRow
 * @function
 * @returns {React.Component} - An editable IonRow
 */

const NewTableRow = props => {
  const {
    className,
    showAllColumn = false,
    isShortenButtons,
    isSelecting,
    isResolved,
    billLinesColumns,
    highLightBillLine,
    onCellHover,
    cellHovered,
    selectedBillLines,
    isSelectedAll,
    onSelectAll,
    onUpdate,
    onCancel,
  } = props

  const [data, setData] = useState({})
  const [currentCell, setCurrentCell] = useState({})
  const [currentCellValue, setCurrentCellValue] = useState()
  const [, setDelayCellHandler] = useState(null)
  const [isUpdating, setIsUploading] = useState(false)

  /**
   * @param {date} - The date string
   */
  const formatDate = date =>
    Date.parse(date) &&
    new Date(date).toLocaleDateString('zh-Hans-CN', {
      timeZone: 'UTC',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })

  /**
   * @param {colIndex} - The cell column index
   */

  const onShowEdit = useCallback(
    ({ colIndex }) =>
      () => {
        const key = billLinesColumns[colIndex].field

        if (!billLinesColumns[colIndex].editable) return

        setCurrentCell({ colIndex })
        setCurrentCellValue(data[key])
      },
    [data, billLinesColumns],
  )

  const onCancelValue = useCallback(() => {
    setCurrentCell('')
  }, [])

  const onChangeCellValue = useCallback(({ target: { value } }) => {
    setCurrentCellValue(value)
  }, [])

  const onKeyDownCellValue = useCallback(
    (field, { which }) => {
      if (which === 13) {
        if (currentCellValue?.trim()) {
          setData({
            ...data,
            [field]: currentCellValue,
          })
        }
        setCurrentCell({})
      }

      if (which === 27) {
        onCancelValue()
      }
    },
    [data, currentCellValue, onCancelValue],
  )

  const onChangeDate = useCallback(
    ({ colIndex }) =>
      value => {
        setCurrentCell({ colIndex })
        const date = formatDate(value)
        setData({
          ...data,
          lineDate: date,
        })
      },
    [data],
  )

  const onMouseLeaveCell = () => {
    if (!cellHovered) onCellHover && onCellHover(null)
  }

  const onMouseEnterCell = fieldCell => () => {
    if (!isShortenButtons) return
    setDelayCellHandler(
      setTimeout(() => {
        onCellHover && onCellHover(fieldCell)
      }, 500),
    )
  }

  const onUpdateBillLines = useCallback(async () => {
    setIsUploading(true)
    await onUpdate?.(data)
    setIsUploading(false)
  }, [data, onUpdate])

  return (
    <>
      <IonRow className={`NewTableRow ${className}`} style={{ width: '100%' }}>
        <IonCard
          role='row'
          style={{
            height: 60,
            alignItems: 'center',
            justifyContent: showAllColumn ? '' : 'flex-start',
            backgroundColor: '',
          }}
        >
          {isSelecting && (
            <IonCol className='checkbox-col'>
              <IonCheckbox checked={isSelectedAll} onClick={onSelectAll} />
            </IonCol>
          )}
          {billLinesColumns &&
            billLinesColumns.map(
              (
                { field, hidable, dataClass, isDate, editable, isBlank },
                colIndex,
              ) => {
                // Hidden columns
                const highLightCell =
                  highLightBillLine && cellHovered && cellHovered === field

                if (hidable) return null
                if (isBlank)
                  return (
                    <IonCol
                      key={colIndex}
                      className={`${dataClass} ${editable && 'edit-col-cursor'
                        }`}
                      style={
                        highLightCell
                          ? {
                            backgroundColor: '#0000001f',
                            'border-right': '1px solid #000',
                            'border-left': '1px solid #000',
                          }
                          : {}
                      }
                    >
                      <div></div>
                    </IonCol>
                  )

                if (field === 'actions')
                  return (
                    <>
                      <IonButton
                        onClick={onUpdateBillLines}
                        disabled={
                          isUpdating ||
                          selectedBillLines.length === 0 ||
                          Object.keys(data).length == 0
                        }
                        title={'Edit Bill Lines'}
                        color='dark'
                      >
                        {'Submit'}
                      </IonButton>
                      <IonButton
                        onClick={onCancel}
                        title={'Cancel updating'}
                        color='danger'
                      >
                        {'Cancel'}
                      </IonButton>
                    </>
                  )

                const isEditing = currentCell.colIndex === colIndex
                const fieldValue = data[field] || ''
                const dateStr = isDate && (fieldValue || '')
                const date = Date.parse(dateStr)
                  ? new Date(formatDate(dateStr))
                  : null
                const roundedDecimals = () => {
                  if (!fieldValue) return '-'
                  if (colIndex > 4 && colIndex < 9) {
                    return dollar.format(fieldValue)
                  } else if (colIndex == 4) {
                    return toFixedWithLocaleString(fieldValue, 2)
                  } else {
                    return fieldValue
                  }
                }

                return (
                  <IonCol
                    key={colIndex}
                    onClick={onShowEdit({ colIndex })}
                    className={`${dataClass} ${editable && 'edit-col-cursor'}`}
                    onMouseEnter={onMouseEnterCell(field)}
                    onMouseLeave={onMouseLeaveCell()}
                    style={
                      highLightCell
                        ? {
                          backgroundColor: '#c1e2ff',
                          'border-right': '1px solid #1090ff',
                          'border-left': '1px solid #1090ff',
                        }
                        : {}
                    }
                  >
                    {
                      <div style={editable ? { cursor: 'pointer' } : {}}>
                        {!isEditing && !isDate && roundedDecimals()}
                      </div>
                    }
                    {isEditing && !isDate && (
                      <input
                        value={currentCellValue}
                        onChange={onChangeCellValue}
                        onBlur={onCancelValue}
                        onKeyDown={e => onKeyDownCellValue(field, e)}
                        autoFocus
                        className={`BillLinesInput ${field}`}
                        placeholder='Enter value'
                      />
                    )}
                    {isDate && !hidable && (
                      <div style={{ cursor: 'pointer' }}>
                        <SimpleDatePicker
                          onChange={onChangeDate({ colIndex })}
                          value={date}
                          style={{
                            height: '100%',
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          format='M/dd'
                          limittedYears={3}
                          editable={!isResolved}
                          placement='top'
                        />
                      </div>
                    )}
                  </IonCol>
                )
              },
            )}
        </IonCard>
      </IonRow>
    </>
  )
}

export default NewTableRow
