import { useContext } from 'react'

import UseBrowserBackStackContext from './UseBrowserBackStackContext'

const useBrowserBackStack = () => {
  const context = useContext(UseBrowserBackStackContext)

  return context
}

export default useBrowserBackStack
