const DEFAULT_COMPANY_TAG_COLUMNS = [
  {
    displayIndex: 0,
    sortIndex: 0,
    label: 'Name',
    field: 'name',
    show: true,
    value: 'name',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    sortIndex: 1,
    label: 'Code',
    field: 'code',
    show: true,
    value: 'code',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'code',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    sortIndex: 2,
    label: 'Description',
    field: 'description',
    show: true,
    value: 'description',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'description',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    sortIndex: 3,
    label: 'Color',
    field: 'color',
    show: true,
    value: 'color',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    sortField: 'color',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Models',
    field: 'models',
    show: true,
    value: 'models',
    type: 'string',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'models',
    hideable: true,
    isHidden: false,
  },
  {
    sortIndex: 4,
    label: 'Rank',
    field: 'rank',
    value: 'rank',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    sortField: 'rank',
    hideable: false,
    isHidden: false,
  },
  {
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_TAG_COLUMNS
