import { addCircleOutline } from 'ionicons/icons'

import type { INoteButtonProps } from './type'

import './styles.scss'
import { Dropdown } from 'react-bootstrap'
import { useCallback, useState } from 'react'
import { IonIcon, IonText } from '@ionic/react'
import { INoteOnSubmitParams, NoteItem } from './NoteItem'
import { INoteFormData } from '~/types/models/INote'
import { ENoteableType } from '~/types/enums/ENote'
import { IUser } from '~/types/models/IUser'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

function NoteDropdown(props: INoteButtonProps) {
  const { noteableId, noteableType, afterCreateNote } = props

  const sessionUser: IUser = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const [showNote, setShowNote] = useState(false)

  const onCreateNote = useCallback(
    async ({ note }: INoteOnSubmitParams) => {
      const payload: INoteFormData = {
        noteableId: noteableId as number,
        noteableType: noteableType as ENoteableType,
        userAccessId: sessionUser.userAccess.id,
        companyId: currentCompany.id,
        note,
      }
      const { errors, ...response } = await apiClient.notes.create({
        note: payload,
      })
      if (response.id) {
        afterCreateNote && afterCreateNote(response)
        toast.success(toastMessages.createSuccess)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [
      afterCreateNote,
      currentCompany.id,
      noteableId,
      noteableType,
      sessionUser.userAccess.id,
    ],
  )

  return (
    <div>
      <Dropdown
        show={showNote}
        onMouseOver={() => {
          setShowNote(true)
        }}
        onMouseLeave={() => {
          setShowNote(false)
        }}
      >
        <Dropdown.Toggle style={{ backgroundColor: 'white', borderWidth: 0 }}>
          <span style={{ color: 'var(--ion-color-concord)' }}>
            <IonIcon
              icon={addCircleOutline}
              style={{ width: 18, height: 18 }}
            />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div style={{ margin: 10 }}>
            <IonText style={{ fontSize: 13, fontWeight: 500 }}>Notes</IonText>
            {showNote && (
              <NoteItem
                isUpdating
                isHiddenCloseButton
                onSubmit={onCreateNote}
              />
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default NoteDropdown
