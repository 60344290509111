import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

const dataState = state => state.data.sellerProducts

export const selectSellerProductsBySellerFactory = () => {
  const selectSellerProductsBySeller = createSelector(
    [state => state.data.sellerProducts, (state, item) => item],

    (state, item) => {
      const sellerProducts = columnHashToColumnsArray(state)

      return sellerProducts.filter(
        sp => item && sp.senderCompanyId == item.sellerId,
      )
    },
  )

  return selectSellerProductsBySeller
}

export const selectSellerProducts = createSelector(
  dataState,

  data => columnHashToColumnsArray(data),
)

export const selectSellerProductOptions = createSelector(
  selectSellerProducts,
  sellerProducts =>
    sellerProducts.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
)
