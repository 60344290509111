/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import type { IGlCodeFormProps } from './type'
import type { IGlCodeFormValues } from '~/types/models/IGlCode'

const GlFormForm = React.forwardRef<any, IGlCodeFormProps>((props, ref) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'glCode',
        label: 'GL Code',
        isRequired: true,
      },
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
      },
      {
        name: 'description',
        label: 'Description',
        as: 'textarea',
        rows: 6,
      },
    ],
    [],
  )

  const defaultValues = useMemo<IGlCodeFormValues>(
    () => ({
      glCode: '',
      name: '',
      description: '',
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        glCode: Yup.string().required('This field is required!'),
        name: Yup.string().required('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IGlCodeFormValues) => {
      const { errors, ...response } = await apiClient.glCodes.create({
        glCode: {
          name: formValues.name,
          glCode: formValues.glCode,
          description: formValues.description,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IGlCodeFormValues) => {
      if (formValues.id) {
        const { errors, ...response } = await apiClient.glCodes.update(
          formValues.id,
          {
            glCode: {
              name: formValues.name,
              glCode: formValues.glCode,
              description: formValues.description,
            },
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.createSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IGlCodeFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (ref !== null) {
      // eslint-disable-next-line no-extra-semi
      ;(ref as any).current = {
        ...(ref as any).current,
        onSubmitForm: handleSubmit,
      }
    }
  }, [handleSubmit, ref])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={ref}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
})

export default GlFormForm
