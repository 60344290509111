import { useState, useCallback } from 'react'

import DialogCreateEditCompanyContext from './DialogCreateEditCompanyContext'
import DialogCompanyCreateEdit from '~/components/company/DialogCompanyCreateEdit'

function DialogCreateEditCompanyProvider(props) {
  const { children } = props

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState({})

  const onOpenDialogCreateEditCompany = useCallback(options => {
    setOpen(true)
    setOptions(options)
  }, [])
  const onCloseDialogCreateEditCompany = useCallback(() => {
    setOpen(false)
    setOptions({})
  }, [])

  return (
    <DialogCreateEditCompanyContext.Provider
      value={{ onOpenDialogCreateEditCompany, onCloseDialogCreateEditCompany }}
    >
      {children}
      <DialogCompanyCreateEdit
        open={open}
        onClose={onCloseDialogCreateEditCompany}
        afterEditCompanyProps={onCloseDialogCreateEditCompany}
        {...options}
      />
    </DialogCreateEditCompanyContext.Provider>
  )
}

DialogCreateEditCompanyProvider.propTypes = {}

export default DialogCreateEditCompanyProvider
