import useBundleItemTableColumns from './useBundleFormWithBadges'

import { IonIcon } from '@ionic/react'
import { BundleBadge } from '../BundleBadge'
import { When } from 'react-if'

import { addCircle, closeCircleOutline } from 'ionicons/icons'

import { IBundleFormWithBadgesProps } from './type'

import './styles.scss'

function BundleFormWithBadges(props: IBundleFormWithBadgesProps) {
  const {
    isCreatingBundle,
    renderBundleBadges,
    sellerProductId,
    onOpenCreateForm,
    onCloseForm,
    afterCreateBundle,
  } = useBundleItemTableColumns(props)

  return (
    <div>
      <div className='BundleFormWithBadges__badges'>
        <div className='label'>
          <span>Bundles</span>
          <span
            className='icon'
            onClick={isCreatingBundle ? onCloseForm : onOpenCreateForm}
          >
            <IonIcon
              icon={isCreatingBundle ? closeCircleOutline : addCircle}
              color={isCreatingBundle ? 'danger' : 'concord'}
            />
          </span>
        </div>

        <When condition={isCreatingBundle}>
          <BundleBadge
            sellerProductId={sellerProductId}
            afterCreateBundle={afterCreateBundle}
          />
        </When>

        <div>{renderBundleBadges}</div>
      </div>

      {/* <div className='BundleFormWithBadges__divide' /> */}

      {/* <div className='BundleFormWithBadges__badges'>
        <div className='label'>
          <span>Seller Terminal Products</span>
          <span
            className='icon'
            onClick={
              sellerTerminalProdState.isOpen
                ? onCloseSellerTerProdForm
                : onOpenSellerTerProdCreateForm
            }
          >
            <IonIcon
              icon={
                sellerTerminalProdState.isOpen ? closeCircleOutline : addCircle
              }
              color={sellerTerminalProdState.isOpen ? 'danger' : 'concord'}
            />
          </span>
        </div>

        <When condition={sellerTerminalProdState.isOpen}>
          <SellerTerminalProductForm
            sellerProductId={sellerProductId}
            formData={sellerTerminalProdState.formData}
            className='BundleFormWithBadges__form'
            isHiddenCancelButton={false}
            onCancel={onCloseSellerTerProdForm}
            cancelText='Close'
            afterCreate={afterCreateSellerTerProd}
            afterUpdate={afterUpdateSellerTerProd}
          />
        </When>

        <div>{renderSellerTerminalProductsBadges}</div>
      </div> */}
    </div>
  )
}

export default BundleFormWithBadges
