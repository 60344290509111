import { useEffect } from 'react'
import { useDrivers } from '~/hooks/useDrivers'

export const useInitializeDrivers = () => {
  const { fetchDrivers, fetchDriverFleets, fetchPeople } = useDrivers()

  useEffect(() => {
    fetchDriverFleets()
    fetchDrivers()
    fetchPeople()
  }, [fetchDriverFleets, fetchDrivers, fetchPeople])
}
