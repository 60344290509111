const theme = {
  // colors
  colors: {
    white: '#ffffff',
    purple: '#79589f',
  },
  // fonts
  fontWeights: {
    normal: '400',
    bold: '700',
  },
}
export default theme

export const openShow = {
  border: '2px solid #3880ff',
}

export const enabled = {
  border: '2px solid #10dc60',
}

export const ionicColor = {
  snowGrey: 'snowgrey',
  dark: 'dark',
}
