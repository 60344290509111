import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import companyPayrollsUiSlice from '~/redux/reducers/ui/companyPayrolls'
import { apiClient } from '~/api/ApiClient'

export const fetchCompanyPayrolls = createAsyncThunk(
  'fetchCompanyPayrolls',
  async (payload, { dispatch }) => {
    dispatch(companyPayrollsUiSlice.actions.setLoading(true))
    try {
      const { payrolls } = await apiClient.payrolls.get()
      dispatch(companyPayrollsSlice.actions.index(payrolls))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(companyPayrollsUiSlice.actions.setLoading(false))
    }
  },
)

const companyPayrollsSlice = createSlice({
  name: 'companyPayrolls',
  initialState: {},
  reducers: {
    index(_state, { payload }) {
      const newState = {}
      payload.forEach(item => {
        newState[item.id] = item
      })

      return newState
    },
    update: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export default companyPayrollsSlice
