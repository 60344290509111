import React from 'react'
import { useRouter } from '~/hooks/useRouter'
import { Chart } from 'react-google-charts'
import { dashboardGraphCardOnClick } from './helpers'

import '~/styles/dashboard.scss'
import './GraphCard.scss'

const GraphCard = props => {
  const { cardData, modelType, createdDateRange } = props

  const router = useRouter()

  const onPieChartClick = ({ chartWrapper, google }) => {
    dashboardGraphCardOnClick(
      chartWrapper,
      google,
      cardData,
      router.history,
      modelType,
      createdDateRange,
    )
  }

  return (
    <div className='GraphCard__container'>
      <div className='graph-card'>
        <div>
          <p className='graph-card-label'>{cardData.title}</p>
          <Chart
            chartType='PieChart'
            loader={<div>Loading Chart</div>}
            data={cardData.data}
            options={{
              pieSliceText: 'label',
              slices: {
                0: { color: '#1090ff' },
                1: { color: '#30BB50' },
                2: { color: '#FF2F0F' },
              },
            }}
            chartEvents={[
              {
                eventName: 'ready',
                callback: () => {},
              },
              {
                eventName: 'select',
                callback: onPieChartClick,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default GraphCard
