import { useState, useCallback } from 'react'
import useModalDownloadProgress from './useModalDownloadProgress'

import DownloadCard from './DownloadCard'

import { CollapseAllIcon, DownloadIcon } from '~/components/shared'

import clsx from 'clsx'

import './ModalDownloadProgress.scss'

function ModalDownloadProgress() {
  const [isExpanded, setIsExpanded] = useState(true)

  const { files } = useModalDownloadProgress()

  const onCollapse = useCallback(() => {
    setIsExpanded(false)
  }, [])

  const onExpand = useCallback(() => {
    setIsExpanded(true)
  }, [])

  const renderDownloadCard = useCallback(() => {
    return files.map(file => <DownloadCard file={file} key={file.name} />)
  }, [files])

  if (files.length === 0) {
    return null
  }

  return (
    <div className={clsx('ModalDownloadProgress__root', { isExpanded })}>
      {isExpanded && (
        <>
          <span onClick={onCollapse}>
            <CollapseAllIcon className='ModalDownloadProgress__collapseIcon' />
          </span>
          <div>{renderDownloadCard()}</div>
        </>
      )}

      {!isExpanded && (
        <div className='ModalDownloadProgress__downloadIcon' onClick={onExpand}>
          <span className='ModalDownloadProgress__badge'>{files.length}</span>
          <DownloadIcon />
        </div>
      )}
    </div>
  )
}

ModalDownloadProgress.propTypes = {}

export default ModalDownloadProgress
