import { createSlice } from '@reduxjs/toolkit'

export const trucksUiSlice = createSlice({
  name: 'trucks',
  initialState: {
    endOfTheList: false,
    loading: undefined,
    loadingMore: undefined,
    trucksForDriver: [], //?
    drivers: [],
    viewMode: 'list',
    lastUpdatedAt: null,
  },
  reducers: {
    update: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value
      }
    },
  },
})
