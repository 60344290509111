import { useMemo, useState } from 'react'
import { Alert, Form, ListGroup } from 'react-bootstrap'
import {
  Button,
  CompanyInfo,
  ContainerSearchBar,
  ToggleSection,
} from '~/components/shared'
import { useQueryCompanies, useQueryUserAccesses } from '~/hooks/useQueryData'

import './styles.scss'
import { ICompany } from '~/types/models/ICompany'
import useFuzzy from '~/hooks/useFuzzy'
import { useSelector } from 'react-redux'
import { selectSessionUser } from '~/redux/selectors'
import { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { IonProgressBar } from '@ionic/react'
import { toastMessages } from '~/constants/toast-status-text'
import { DRIVER_GROUP_ID } from '~/utils/constants'
import { EStatus } from '~/types/enums/ECommonEnum'

interface IDriverCompanySignProps {
  afterCreate: () => void
  onBack: () => void
  onNext: () => void
}

function DriverCompanySign(props: IDriverCompanySignProps) {
  const { afterCreate, onBack } = props

  const [companyChecked, setCompanyChecked] = useState<ICompany | null>(null)
  const [backendError, setBackendError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentUser: IUser = useSelector(selectSessionUser)

  const { companiesData, isLoadingCompaniesData, findCompanyById } =
    useQueryCompanies({ all: true, perPage: 1000 }, { retry: false })

  const { userAccessesData, addUserAccess, isLoadingUserAccessesData } =
    useQueryUserAccesses({
      filters: {
        userId: currentUser.id,
      },
    })

  const companiesDataFiltered = useMemo(
    () =>
      companiesData.filter(
        ({ id }) => !userAccessesData.find(({ companyId }) => companyId === id),
      ),
    [companiesData, userAccessesData],
  )

  const { seachedList, searchValue, onSearch } = useFuzzy(
    companiesDataFiltered,
    {
      keys: ['code', 'name'],
    },
  )

  const onSelectCompany = (company: ICompany) => () => {
    setCompanyChecked(prev => {
      if (prev?.id === company.id) {
        return null
      }
      return company
    })
  }

  const onApplyCompany = async () => {
    setBackendError('')
    setIsLoading(true)
    try {
      if (companyChecked) {
        const { errors, userAccess } = await apiClient.userAccesses.create({
          companyId: companyChecked.id,
          userId: currentUser.id,
          userGroupId: DRIVER_GROUP_ID,
          status: EStatus.Pending,
        } as any)
        if (errors.length > 0) {
          setBackendError(errors[0])
        } else {
          afterCreate()
          addUserAccess(userAccess)
          setCompanyChecked(null)
        }
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='DriverCompanySign__container'>
      {isLoadingCompaniesData && isLoadingUserAccessesData ? (
        <IonProgressBar type='indeterminate' style={{ margin: '8px 0' }} />
      ) : null}

      {userAccessesData.length ? (
        <ToggleSection
          label='Pending Application'
          isOpenByDefault={userAccessesData.length > 0}
          badges={[
            {
              label: userAccessesData.length,
            },
          ]}
        >
          <ListGroup
            variant='flush'
            style={{ maxHeight: '130px', overflow: 'auto' }}
          >
            {userAccessesData.map(({ id, companyId }) => {
              const company = findCompanyById(companyId)
              return (
                <ListGroup.Item key={id}>
                  <CompanyInfo
                    company={{
                      label: company
                        ? `${company.code} - ${company.name}`
                        : 'Unknown',
                      value: companyId,
                      image: company?.logo,
                      name: company?.name,
                      code: company?.code,
                    }}
                    hideAnchor
                    searchableGoogle={false}
                  />
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </ToggleSection>
      ) : null}

      <ToggleSection
        label='Companies'
        badges={[
          {
            label: seachedList.length,
          },
        ]}
        isOpenByDefault={userAccessesData.length <= 0}
      >
        <ContainerSearchBar
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
        />
        {backendError && (
          <Alert variant='danger' style={{ fontSize: 13, margin: '8px 0' }}>
            {backendError}
          </Alert>
        )}
        <ListGroup variant='flush' className='listItems'>
          {seachedList.map(company => {
            const { id, name, code, logo } = company
            return (
              <ListGroup.Item
                key={id}
                onClick={onSelectCompany(company)}
                className='clickable'
              >
                <Form.Check
                  onChange={onSelectCompany(company)}
                  checked={companyChecked?.id === id}
                />
                <CompanyInfo
                  company={{
                    label: `${code} - ${name}`,
                    value: id,
                    image: logo,
                    name,
                    code,
                  }}
                  hideAnchor
                  searchableGoogle={false}
                />
              </ListGroup.Item>
            )
          })}
        </ListGroup>

        <Button
          style={{ width: '100%' }}
          expand='full'
          isDisabled={!companyChecked}
          loading={isLoading}
          onClick={onApplyCompany}
        >
          Apply to company selected
        </Button>
      </ToggleSection>

      <div style={{ marginTop: 8, display: 'flex', width: '100%' }}>
        {!currentUser?.userGroup?.id && (
          <Button
            style={{ width: '100%' }}
            label='Back'
            expand='full'
            color='medium'
            onClick={onBack}
            loading={isLoading}
          />
        )}
      </div>
    </div>
  )
}

export default DriverCompanySign
