import { useCallback, useMemo } from 'react'
import { useWorkerFormProvider } from '~/contexts'

import type { IDriverAvatarProps } from './type'
import type { IWorkerFormDefaultValues } from '~/contexts/WorkerFormProvider/type'
import buildFullName from '~/utils/buildFullName'
import { useQueryUsers } from '~/hooks/useQueryData'

export const useDriverAvatar = (props: IDriverAvatarProps) => {
  const { driverFleetId, isAssignable, ...infoProps } = props

  const { onOpenWorkerFormData } = useWorkerFormProvider()

  const { findUserByDriverFleetId } = useQueryUsers()

  const user = findUserByDriverFleetId(driverFleetId as number)

  const driverFleet = user?.driverFleet

  const fullName = useMemo<string>(() => {
    if (!driverFleet?.id) return isAssignable ? '+' : '?'
    return buildFullName(user?.person, '?')
  }, [driverFleet?.id, isAssignable, user?.person])

  const driverFormData = useMemo<IWorkerFormDefaultValues | null>(() => {
    if (user) {
      return {
        id: user?.id,
        person: user.person,
        driver: user.driver,
        user: {
          id: user.id,
          email: user?.email || '',
          phoneNumber: user?.phoneNumber || '',
          allowedCommunicationChannels:
            user?.allowedCommunicationChannels || [],
        },
        userAccess: user.userAccess,
        worker: user.worker,
        driverFleet: user.driverFleet,
      } as IWorkerFormDefaultValues
    }

    return null
  }, [user])

  const onRightClickAvatar = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (driverFormData) {
        event.preventDefault()
        event.stopPropagation()
        onOpenWorkerFormData(driverFormData)
      }
    },
    [driverFormData, onOpenWorkerFormData],
  )
  const toolTipMessage = useMemo<string>(() => {
    const missingString = isAssignable ? 'Assign a Driver' : 'No Driver'
    return driverFleet?.id ? fullName : missingString
  }, [driverFleet?.id, fullName, isAssignable])

  return {
    fullName,
    toolTipMessage,
    onRightClickAvatar,
    infoProps,
    onOpenWorkerFormData,
    driverFormData,
    driverFleet,
  }
}

export default useDriverAvatar
