import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { ConcordFormMultipleDropdownSelector } from '~/components/shared'

function ConcordMultipleDropdownSelectorFormControl(props) {
  const { name, control, ...dropdownProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const onChange = useCallback(
    (event, { value }) => {
      field.onChange(value)
    },
    [field],
  )

  return (
    <ConcordFormMultipleDropdownSelector
      {...dropdownProps}
      name={name}
      value={field.value}
      error={fieldState.error?.message}
      onChange={onChange}
    />
  )
}

ConcordMultipleDropdownSelectorFormControl.propTypes = {}

export default ConcordMultipleDropdownSelectorFormControl
