import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { produce } from 'immer'
import { IDriver } from '~/types/models/IDriver'

const useModifyDrivers = (
  params: {
    id?: number
  } = {},
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'drivers',
      sessionUser?.id,
      buildGetUrl(apiClient.drivers.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const updateDriver = useCallback(
    (id: number, driver: Partial<IDriver>) => {
      queryClient.setQueryData<IDriver[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = {
                ...draft[index],
                ...driver,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return { updateDriver }
}

export default useModifyDrivers
