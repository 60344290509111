import * as types from './actionTypes'
import { INVOICE_PANELS } from '~/constants/invoice-type'

export const updateAppHeader = header => ({
  header,
  type: types.UPDATE_APP_HEADER,
})

export const updateShowBillLinesColumns = (
  from = INVOICE_PANELS.TICKETS,
  applyAllTables = false,
  columns,
) => ({
  type: types.UPDATE_SHOW_COLUMNS,
  columns,
  from,
  applyAllTables,
})

export const updateBillLinesFilters = (
  from = INVOICE_PANELS.TICKETS,
  applyAllFilters = false,
  value,
) => ({
  type: types.UPDATE_SHOW_FILTERS,
  value,
  from,
  applyAllFilters,
})

export const updateBillLinesSort = (
  from = INVOICE_PANELS.TICKETS,
  applyAllSort = false,
  value,
) => ({
  type: types.UPDATE_SORT_FILTERS,
  value,
  from,
  applyAllSort,
})

export const resetShowBillLinesCheckbox = () => ({
  type: types.RESET_SHOW_COLUMNS,
})

export const updateBillLinesContainerFilter = (
  data,
  filterType,
  applyAllFilters = false,
) => ({
  type: types.UPDATE_FILTER_CONTAINER,
  data,
  filterType,
  applyAllFilters,
})

export const resetBillLinesContainerFilter = () => ({
  type: types.RESET_FILTER_CONTAINER,
})

/**
 * @param {boolean} payload
 */
export const setAppContentScrollY = payload => ({
  type: types.APP_SET_CONTENT_SCROLL_Y,
  payload,
})

export const actSetDriverFleetLoadsSections = payload => ({
  type: types.SET_DRIVER_FLEET_LOADS_SECTIONS,
  payload,
})
