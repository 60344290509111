import { createSlice } from '@reduxjs/toolkit'

const fleetsUiSlice = createSlice({
  name: 'fleets',
  initialState: {
    fleetLoading: false,
    fleetLoadingRowModel: [],
    fleetPagination: {
      currentPage: 1,
      perPage: 24,
      totalItems: 0,
    },
    fleetSearchKey: '',
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateFleetPagination: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.fleetPagination[key] = value
      }
    },
  },
})

export default fleetsUiSlice
