import { useCallback, useMemo, useState, useRef } from 'react'
import { useDeepCompareEffect } from 'react-use'

import ReactSelect from 'react-select'
import KeyValueFormFieldLayout from '../FieldLayout'
import { ToolTipOverlay } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'

import './KeyValueFormDropdownField.scss'

const KeyValueFormDropdownField = props => {
  const {
    label,
    value,
    options,
    className,
    onChange,
    getOptionValue,
    getOptionLabel,
    field,
    onConfirmUpdate,
    loading,
    hideConfirmButton,
    editable,
    style,
    error,
    name,
    ...dropdownProps
  } = props

  const inputRef = useRef({})

  const [optionValue, setOptionValue] = useState({})

  const isEdited = useMemo(() => {
    if (_.size(options) === 0) {
      return false
    }
    if (_.isNil(optionValue)) {
      return _.toString(optionValue) !== _.toString(value)
    }

    if (_.size(optionValue) === 0) {
      return false
    }

    if (_.has(optionValue, 'value')) {
      return optionValue?.value !== value
    }

    if (_.has(optionValue, 'id')) {
      return optionValue?.id !== value
    }

    return false
  }, [optionValue, options, value])

  const mapValueOption = useMemo(() => {
    if (_.isPlainObject(value) && options.length > 0) {
      return options.find(
        option => getOptionValue(option) === getOptionValue(value),
      )
    } else if (_.isNumber(value) || _.isString(value)) {
      return options.find(option => getOptionValue(option) === value)
    }

    return {
      id: value,
    }
  }, [getOptionValue, options, value])

  useDeepCompareEffect(() => {
    setOptionValue(mapValueOption)
  }, [value, options, getOptionValue, mapValueOption])

  const menuStyles = useMemo(
    () => ({
      container: base => ({ ...base, width: '100%' }),
      control: base => ({
        ...base,
        border: 'none !important',
        backgroundColor: 'transparent',
        '&:hover': {
          boxShadow: '0 0 0 0.25rem rgba(13, 110, 253, 0.25)',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.25rem rgba(13, 110, 253, 0.25)',
        },
      }),
      indicatorSeparator: base => ({ ...base, display: 'none' }),
      menu: base => ({ ...base, fontSize: 14 }),
    }),
    [],
  )

  const handleChangeDropdown = useCallback(
    newValue => {
      const value = getOptionValue(newValue)
      setOptionValue(newValue)
      onChange && onChange({ target: { name } }, value)
    },
    [getOptionValue, name, onChange],
  )

  const blurInput = useCallback(() => {
    if (_.isFunction(inputRef?.current?.blur)) {
      inputRef.current.blur()
    }
  }, [])

  const handleConfirmUpdateValue = useCallback(
    event => {
      const newValue = {
        field,
        value: getOptionValue(optionValue),
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
      blurInput()
    },
    [blurInput, field, getOptionValue, onConfirmUpdate, optionValue],
  )

  const handleCancelEditing = useCallback(() => {
    let originalValue = value

    if (_.isNumber(value) || _.isString(value)) {
      originalValue = options.find(option => getOptionValue(option) === value)
    }
    setOptionValue(originalValue)
    blurInput()
  }, [blurInput, value, getOptionValue, options])

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleConfirmUpdateValue(event)
      } else if (event.keyCode === 27) {
        handleCancelEditing(event)
      }
    },
    [handleConfirmUpdateValue, handleCancelEditing],
  )

  const handleResetValue = useCallback(() => {
    setOptionValue(mapValueOption)
  }, [mapValueOption])

  return (
    <KeyValueFormFieldLayout
      hideConfirmButton={hideConfirmButton}
      label={label}
      loading={loading}
      editable={editable}
      renderValue={() => optionValue?.value}
      isEdited={isEdited}
      onConfirm={handleConfirmUpdateValue}
      onReset={handleResetValue}
    >
      <ToolTipOverlay content={error} placement='bottom'>
        <span className={clsx('KeyValueFormDropdownField__wrap', { error })}>
          <ReactSelect
            inputId={label}
            value={optionValue}
            options={options}
            menuPortalTarget={document.body}
            styles={{ ...menuStyles, ...style }}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            onChange={handleChangeDropdown}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            {...dropdownProps}
          />
        </span>
      </ToolTipOverlay>
    </KeyValueFormFieldLayout>
  )
}

KeyValueFormDropdownField.defaultProps = {
  getOptionValue: option => option.value,
  options: [],
  getOptionLabel: option => option?.label,
  style: {},
}

export default KeyValueFormDropdownField
