import { useTable, useSortBy } from 'react-table'
import { useScheduleLoadsTable } from '../OrderForm/useScheduleLoadsTable'

import { ArrowDownIcon, ArrowUpIcon } from '~/components/shared'

export const ScheduleLoadsTable = ({
  index,
  scheduleLoads = [],
  sellerId,
  updateScheduleLoads,
  isReadOnly,
  schedule,
}) => {
  const { columns } = useScheduleLoadsTable({
    index,
    sellerId,
    updateField: updateScheduleLoads,
    isReadOnly,
    schedule,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: scheduleLoads,
        columns,
      },
      useSortBy,
    )

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={i}
                  className='p-2'
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: 'white',
                  }}
                >
                  {column.render('Header')}

                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, i) => {
                  return (
                    <td key={i} {...cell.getCellProps()} className='p-2'>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
