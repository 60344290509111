import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { EFieldType } from '~/types/enums/ECommonEnum'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ORDER_TYPE_OPTIONS } from '~/utils/constants'

import type { IProductGroupFormData } from '~/types/models/IProductGroup'
import type { IProductGroupFormProps } from './type'
import type { ICompany } from '~/types/models/ICompany'

const ProductGroupForm = (props: IProductGroupFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [isLoading, setIsLoading] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
      },
      {
        name: 'code',
        label: 'Code',
        isRequired: true,
      },
      {
        name: 'orderType',
        label: 'Order type',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: ORDER_TYPE_OPTIONS,
      },
      {
        name: 'active',
        label: 'Status',
        type: EFieldType.radio,
        options: [
          {
            label: 'Active',
            value: true,
          },
          {
            label: 'Inactive',
            value: false,
          },
        ],
      },
    ],
    [],
  )

  const defaultValues = useMemo<IProductGroupFormData>(
    () => ({
      name: '',
      code: '',
      active: true,
      orderType: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Name is required!'),
        code: Yup.string().required('Code is required!'),
        orderType: Yup.number()
          .required('Order type is required!')
          .typeError('Order type is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IProductGroupFormData) => {
      const { errors, status, productGroup } =
        await apiClient.productGroups.create(formValues)
      if (status === 'error') {
        const keys = Object.keys(errors)
        if (keys.length > 0) {
          const [key] = keys
          if (errors[key].length > 0) {
            const message = errors[key][0]
            toast.error(`${key} ${message}`)
          }
        }
      } else {
        afterCreate && afterCreate(productGroup)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IProductGroupFormData) => {
      const payload = _.pick(formValues, [
        'active',
        'code',
        'companyId',
        'name',
        'orderType',
      ])
      const { productGroup, errors, status } =
        await apiClient.productGroups.update(formValues.id as number, payload)
      if (status === 'error') {
        const keys = Object.keys(errors)
        if (keys.length > 0) {
          const [key] = keys
          if (errors[key].length > 0) {
            const message = errors[key][0]
            toast.error(`${key} ${message}`)
          }
        }
      } else {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(productGroup)
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IProductGroupFormData) => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          const payload: IProductGroupFormData = {
            ...formValues,
            companyId: currentCompany.id,
          }
          await onCreate(payload)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [currentCompany.id, isUpdating, onCreate, onUpdate],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      formData={formData}
      defaultValues={defaultValues}
      fields={fields}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      schema={schema}
      isLoading={isLoading}
    />
  )
}

export default ProductGroupForm
