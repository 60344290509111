import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBuyerSellers from './useModifyBuyerSellers'

import { apiClient } from '~/api/ApiClient'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IBuyerSeller } from '~/types/models/IBuyerSeller'
import { EScope, EStatus } from '~/types/enums/ECommonEnum'
import { DRIVER_GROUP_ID } from '~/utils/constants'
import _ from 'lodash'
import { EBuyerSellerRelationship } from '~/types/enums/EBuyerSeller'
import { ICompany } from '~/types/models/ICompany'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBuyerSellers = (
  params = {},
  options?: Partial<UseQueryOptions<IBuyerSeller[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const {
    addBuyerSeller,
    updateBuyerSeler,
    removeBuyerSeller,
    updateBuyerSellerByCompany,
  } = useModifyBuyerSellers({})

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'buyerSellers',
      sessionUser?.id,
      buildGetUrl(apiClient.buyerSellers.endpoint, params),
      sessionUser?.currentScope,
    ],
    async queryFn() {
      const response = await apiClient.buyerSellers.get()
      return response
    },
    enabled: Boolean(
      sessionUser?.id &&
        sessionUser?.userAccess &&
        sessionUser?.userAccess?.status !== EStatus.Pending &&
        sessionUser.userGroup?.id !== DRIVER_GROUP_ID,
    ),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const buyerSellersData = useMemo(() => data || [], [data])

  const buyerSellerCompanyIds = useMemo(() => {
    if (currentScope) {
      if (currentScope === EScope.buyer) {
        return buyerSellersData.map(({ seller }) => seller?.id)
      }
      return buyerSellersData.map(({ buyer }) => buyer?.id)
    }

    return []
  }, [buyerSellersData, currentScope])

  const sellerRelationships = useMemo(() => {
    if (currentScope === EScope.seller) {
      return []
    }
    return buyerSellersData.filter(({ seller }) => seller)
  }, [buyerSellersData, currentScope])

  const buyerRelationships = useMemo(() => {
    if (currentScope === EScope.buyer) {
      return []
    }
    return buyerSellersData.filter(({ buyer }) => buyer)
  }, [buyerSellersData, currentScope])

  const fleetRelationships = useMemo(
    () =>
      buyerSellersData
        .filter(
          ({ relationship }) => relationship === EBuyerSellerRelationship.Fleet,
        )
        .filter(({ seller }) => seller),
    [buyerSellersData],
  )

  const buyerCompanies = useMemo(
    () => buyerRelationships.map(({ buyer }) => buyer),
    [buyerRelationships],
  )

  const buyerCompaniesWithCurrentCompany = useMemo(() => {
    if (currentScope === EScope.buyer) {
      return [currentCompany, ...buyerCompanies]
    }

    return buyerCompanies
  }, [buyerCompanies, currentCompany, currentScope])

  const buyerCompanyOptionsWithCurrentCompany = useMemo(
    () =>
      buyerCompaniesWithCurrentCompany.map(({ id, name, code }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [buyerCompaniesWithCurrentCompany],
  )

  const sellerCompanies = useMemo(
    () => sellerRelationships.map(({ seller }) => seller),
    [sellerRelationships],
  )

  const sellerCompaniesWithCurrentCompany = useMemo(() => {
    if (currentScope === EScope.seller) {
      return [currentCompany, ...sellerCompanies]
    }

    return sellerCompanies
  }, [currentCompany, currentScope, sellerCompanies])

  const sellerCompanyOptions = useMemo(
    () =>
      sellerCompanies.map(({ id, name, code }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [sellerCompanies],
  )

  const sellerCompanyOptionsWithCurrentCompany = useMemo(
    () =>
      sellerCompaniesWithCurrentCompany.map(({ id, name, code }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [sellerCompaniesWithCurrentCompany],
  )

  const fleetCompanies = useMemo(
    () => fleetRelationships.map(({ seller }) => seller),
    [fleetRelationships],
  )

  const sellerRelationshipOptions = useMemo(
    () =>
      sellerRelationships.map(({ seller }) => ({
        value: seller?.id,
        label: `${seller?.code} - ${seller?.name}`,
        name: seller?.name,
      })),
    [sellerRelationships],
  )

  const buyerRelationshipOptions = useMemo(
    () =>
      buyerRelationships.map(({ buyer }) => ({
        value: buyer?.id,
        label: `${buyer?.code} - ${buyer?.name}`,
        image: buyer?.logo,
        name: buyer?.name,
      })),
    [buyerRelationships],
  )

  const allCompaniesData = _.uniqBy(
    [...buyerCompanies, ...sellerCompanies],
    'id',
  ).filter(company => company)

  const allCompaniesWithCurrentCompany = useMemo(
    () => [currentCompany, ...allCompaniesData],
    [allCompaniesData, currentCompany],
  )

  const allCompanyOptions = allCompaniesData.map(company => ({
    value: company?.id,
    label: company ? `${company.code} - ${company.name}` : '',
    code: company?.code,
    name: company?.name,
  }))

  const allCompanyWithCurrentCompanyOptions =
    allCompaniesWithCurrentCompany.map(company => ({
      value: company?.id,
      label: company ? `${company.code} - ${company.name}` : '',
      code: company?.code,
      name: company?.name,
    }))

  const findBuyerCompanyOption = (id: number) =>
    buyerRelationshipOptions.find(({ value }) => value === id)

  const findSellerCompanyOption = (id: number) =>
    sellerRelationshipOptions.find(({ value }) => value === id)

  const findCompanyById = (id: number) =>
    allCompaniesWithCurrentCompany.find(company => company.id === id)

  return {
    buyerSellersData,
    isLoadingBuyerSellers: isLoading,
    sellerRelationships,
    sellerRelationshipOptions,
    buyerRelationships,
    buyerRelationshipOptions,
    isFetchedBuyerSellers: isFetched,
    buyerSellerCompanyIds,
    buyerCompanies,
    sellerCompanies,
    fleetCompanies,
    allCompaniesData,
    allCompanyOptions,
    fleetRelationships,
    allCompaniesWithCurrentCompany,
    allCompanyWithCurrentCompanyOptions,
    sellerCompanyOptions,
    sellerCompaniesWithCurrentCompany,
    sellerCompanyOptionsWithCurrentCompany,
    buyerCompanyOptionsWithCurrentCompany,
    buyerCompaniesWithCurrentCompany,
    addBuyerSeller,
    updateBuyerSeler,
    removeBuyerSeller,
    refetchQueryAddresses: refetch,
    findBuyerCompanyOption,
    findSellerCompanyOption,
    findCompanyById,
    updateBuyerSellerByCompany,
  }
}

export default useQueryBuyerSellers
