import { CommonDialogV2 } from '~/components/shared'
import EmailParserForm from './EmailParserForm'

import './DialogEmailParserForm.scss'

function DialogEmailParserForm(props) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Email Parser'
      isHiddenOkButton
    >
      <EmailParserForm
        isHiddenCancelButton
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogEmailParserForm
