import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import usePaymentTypesTable from './usePaymentTypesTable'

import {
  DeleteIcon,
  DialogPaymentTypeForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'
import { moduleName } from '~/utils/constants'

function PaymentTypesTable() {
  const {
    tableColumns,
    afterCreate,
    afterUpdate,
    isOpenDialogForm,
    selectedData,
    paymentTypes,
    windowSize,
    isLoadingTable,
    onCloseDialogForm,
    onOpenCreateDialogForm,
    onRemove,
    onOpenEditDialogForm,
    onCellEditEnd,
  } = usePaymentTypesTable()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={tableColumns}
          data={paymentTypes}
          enableTopToolbar
          tableHeight={windowSize.height - 220}
          enableCompanyView
          enableRowActions
          onCellEditEnd={onCellEditEnd}
          companyViewProps={{
            name: moduleName.company.paymentTypes,
            onClickTopButton: onOpenCreateDialogForm,
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 85,
            },
          }}
          state={{
            isLoading: isLoadingTable,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: onOpenEditDialogForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: onRemove(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
        />
      </div>

      <DialogPaymentTypeForm
        isOpen={isOpenDialogForm}
        formData={selectedData}
        onClose={onCloseDialogForm}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </>
  )
}
export default PaymentTypesTable
