import { useMemo, useCallback } from 'react'

import { isPlatform, IonButton, IonButtons, IonLabel } from '@ionic/react'
import { MultiSelectSearchDropdown } from '~/components/shared'

import _ from 'lodash'

const ContainerFilterMultipleSelect = props => {
  const {
    field,
    showNotSetOption,
    options = [],
    sortable,
    label,
    onLoadFilter,
    onChange,
    values,
    showRefresh,
    customOption,
  } = props

  const mapOptions = useMemo(() => {
    const uniqOptions = _.uniqBy(options, 'value')
    if (showNotSetOption || showRefresh) {
      const filterData = uniqOptions.filter(
        option => !_.isNil(option.value) || option.value !== 'null',
      )
      filterData.unshift({
        value: 'null',
        label: 'Not Set',
      })

      return filterData
    }

    return uniqOptions
  }, [options, showRefresh, showNotSetOption])

  const showValues = useMemo(() => {
    if (!values) {
      return 'All'
    }

    if (values.length === 0) {
      return 'All'
    }
    const filteredOptions = mapOptions.filter(option =>
      values.includes(option.value),
    )

    if (filteredOptions.length === 1) {
      return filteredOptions[0].label
    }

    return filteredOptions.length
  }, [mapOptions, values])

  const isAllOption = showValues === 'All'

  const handleLoadFilter = useCallback(() => {
    if (_.isEmpty(options)) {
      onLoadFilter && onLoadFilter()
    }
  }, [onLoadFilter, options])

  return (
    <MultiSelectSearchDropdown
      field={field}
      values={values}
      data={mapOptions}
      onSubmit={onChange}
      showRefresh={showNotSetOption || showRefresh}
      sortable={sortable}
      dropdownMenuStyle={{
        maxHeight: isPlatform('mobile') && 50,
      }}
      customOption={customOption}
    >
      <IonButtons>
        <IonButton
          fill='clear'
          title={showValues}
          color={isAllOption ? 'medium' : 'primary'}
          onClick={handleLoadFilter}
        >
          <IonLabel style={{ fontWeight: 'bold', fontSize: 'small' }}>
            {label}:&nbsp;
          </IonLabel>
          <IonLabel
            style={{ fontSize: 'small', opacity: isAllOption ? 0.6 : 1 }}
            color='dark'
          >
            {showValues}
          </IonLabel>
        </IonButton>
      </IonButtons>
    </MultiSelectSearchDropdown>
  )
}

export default ContainerFilterMultipleSelect
