import React, { useState, useCallback } from 'react'
import useImageEditing from '~/hooks/useImageEditing'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import {
  IonModal,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonTitle,
  IonIcon,
  IonText,
} from '@ionic/react'
import InnerImageZoom from 'react-inner-image-zoom'
import { remove, add, pencilOutline } from 'ionicons/icons'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { closeOutline } from 'ionicons/icons'
import { ImageEditing, ButlerBlock } from '~/components/shared'
import _ from 'lodash'
import clsx from 'clsx'
import { isParserButler } from '~/utils/checkParser'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import PropTypes from 'prop-types'

import './ImageViewer.scss'
import { apiClient } from '~/api/ApiClient'

const ImageViewer = props => {
  const {
    isOpen,
    image,
    onHidePreview,
    title,
    extraButtons,
    showEdit,
    onEditComplete,
    imageProps,
    load,
    className,
  } = props

  const [zoomValue, setZoomValue] = useState(1.5)
  const [textField, setTextField] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [showPdf, setShowPdf] = useState(true)
  const { isMobileScreen } = useBreakpoint()
  const {
    setCropper,
    imageName,
    setImageName,
    onCropImage,
    isCropping,
    onZoom,
    onMoveLeft,
    onMoveRight,
    onDoneEditing,
    setIsCropping,
  } = useImageEditing(onEditComplete, setIsEditing)

  const onModalClose = useCallback(() => {
    onHidePreview && onHidePreview()
    setIsEditing(false)
  }, [onHidePreview])

  const onKeyPress = useCallback(e => {
    if (e.key === 'Enter') {
      setZoomValue(parseFloat(e.target.value))
      setTextField(null)
    }
  }, [])

  const onEditOutline = () => {
    setIsEditing(true)
  }
  const onToggleShowPdf = useCallback(toggle => {
    setShowPdf(toggle)
  }, [])

  const handleUpdateOcrJson = useCallback(
    async docInfo => {
      try {
        await apiClient.loads.update(load.id, {
          ocrJson: docInfo.trainingDocumentLabels,
        })
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [load.id],
  )

  const renderLoadImage = useCallback(
    currentLoad => {
      if (_.size(currentLoad) === 0) {
        if (isEditing) {
          return (
            <ImageEditing
              onSetCropper={setCropper}
              editedImage={{
                name: imageName,
                preview: image,
                image: image,
              }}
              imageName={imageName}
              onSetImageName={setImageName}
              onCropImage={onCropImage}
              isCropping={isCropping}
              onZoom={onZoom}
              onMoveLeft={onMoveLeft}
              onMoveRight={onMoveRight}
              onDoneEditing={onDoneEditing}
              useFileInModal
              setIsCropping={setIsCropping}
              isEditing
              onCancel={() => setIsEditing(false)}
              fromImagePreview
            />
          )
        }

        return <ShowImageComponent {...imageProps} />
      }
      if (
        isParserButler(currentLoad?.parserName) &&
        currentLoad?.documentId &&
        currentLoad?.modelId
      ) {
        return (
          <ButlerBlock
            modelId={currentLoad.modelId}
            documentId={currentLoad.uid}
            className={clsx('ImageViewer__loadButlerBlock', {
              hidePdf: !showPdf,
            })}
            onSave={handleUpdateOcrJson}
            toolbarProps={{
              showPdf,
              onToggleShowPdf,
            }}
          />
        )
      }

      return renderLoadImage()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      image,
      imageName,
      imageProps,
      isCropping,
      isEditing,
      onCropImage,
      onDoneEditing,
      onMoveLeft,
      onMoveRight,
      onToggleShowPdf,
      onZoom,
      setCropper,
      setImageName,
      setIsCropping,
      showPdf,
      handleUpdateOcrJson,
    ],
  )

  const ShowImageComponent = props => {
    return (
      <>
        {isMobileScreen ? (
          <PinchZoomPan
            zoomButtons={false}
            minScale={1}
            initialScale={1}
            // position='center'
            maxScale={4}
            {...props}
          >
            <img src={image} alt='test' />
          </PinchZoomPan>
        ) : (
          <InnerImageZoom
            src={image}
            zoomScale={zoomValue}
            alt='Ticket Image'
            fullscreenOnMobile={true}
            zoomType={'hover'}
            hasSpacer
            hideHint
            className='desktop-image-viewer'
            {...props}
          />
        )}
      </>
    )
  }

  const onZoomChange = value => {
    if (value == '+') {
      setZoomValue(zoomValue + 0.1)
    } else {
      setZoomValue(zoomValue - 0.1)
    }
  }

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={onModalClose}
      cssClass={className}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonRow>
            {!isMobileScreen && (
              <>
                <IonButton
                  size='small'
                  fill='clear'
                  onClick={() => onZoomChange('-')}
                  disabled={zoomValue < 0.5}
                >
                  <IonIcon slot='icon-only' icon={remove} />
                </IonButton>
                <IonText style={{ fontSize: 16, marginTop: '6px' }}>
                  Zoom:
                  <input
                    type='text'
                    onChange={e => setTextField(e.target.value)}
                    value={textField || zoomValue.toFixed(1)}
                    style={{
                      width: 35,
                      backgroundColor: textField ? '#30BB50' : 'black',
                      textAlign: 'center',
                      color: textField ? 'black' : 'white',
                    }}
                    onKeyPress={onKeyPress}
                    maxLength={3}
                    pattern='[+-]?\d+(?:[.,]\d+)?'
                  />
                </IonText>
                <IonButton
                  size='small'
                  fill='clear'
                  onClick={() => onZoomChange('+')}
                  disabled={zoomValue >= 2}
                >
                  <IonIcon slot='icon-only' icon={add} />
                </IonButton>
              </>
            )}
            <IonTitle style={{ textAlign: 'center' }}>
              {title || 'Image Viewer'}
            </IonTitle>
          </IonRow>
          <IonButtons slot='end'>
            {extraButtons}
            {showEdit && (
              <IonButton
                color='success'
                onClick={onEditOutline}
                title='Edit'
                fill='solid'
              >
                <IonIcon icon={pencilOutline} />
              </IonButton>
            )}
            <IonButton
              color='danger'
              onClick={onModalClose}
              title='Close Modal'
              fill='solid'
            >
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-text-center modal-content'>
        {renderLoadImage(load)}
      </IonContent>
    </IonModal>
  )
}

ImageViewer.propTypes = {
  imageProps: PropTypes.object,
  load: PropTypes.object,
  isOpen: PropTypes.bool,
  image: PropTypes.string,
  onHidePreview: PropTypes.func,
  title: PropTypes.string,
  extraButtons: PropTypes.func, // React element
  onEditComplete: PropTypes.func,
}

ImageViewer.defaultProps = {
  imageProps: {},
  load: {},
}

export default ImageViewer
