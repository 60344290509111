import React, { useCallback, useMemo, useState } from 'react'

import { ConcordFormStructure } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { FORM_FIELD_TYPE } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'

const TagForm = props => {
  const { afterCreate, afterUpdate, formData, isHiddenCancelButton, onCancel } =
    props

  const [isLoading, setIsLoading] = useState(false)

  const fields = useMemo(
    () => [
      {
        label: 'Name',
        name: 'name',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Code',
        name: 'code',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Description',
        name: 'description',
      },
      {
        label: 'Rank',
        name: 'rank',
        size: 6,
        type: FORM_FIELD_TYPE.number,
      },
      {
        label: 'Color',
        name: 'color',
        size: 6,
        type: FORM_FIELD_TYPE.color,
      },
      {
        label: 'Models',
        name: 'models',
        isRequired: true,
        type: FORM_FIELD_TYPE.multipleSelect,
        options: [
          {
            value: 'BillLine',
            label: 'Bill Line',
          },
          {
            value: 'Load',
            label: 'Load',
          },
          {
            value: 'Invoice',
            label: 'Invoice',
          },
          {
            value: 'Document',
            label: 'Document',
          },
        ],
      },
    ],
    [],
  )

  const defaultValues = useMemo(
    () => ({
      name: '',
      code: '',
      description: '',
      rank: null,
      color: '',
      models: [],
    }),
    [],
  )

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Name is required!'),
        code: Yup.string().required('Code is required'),
        models: Yup.array().min(1, 'Models must be at least one!'),
      }),
    [],
  )

  const updateTag = useCallback(
    async formValues => {
      const payload = _.pick(formValues, [
        'code',
        'color',
        'description',
        'models',
        'name',
        'rank',
      ])

      const response = await apiClient.tags.update(formValues.id, payload)
      if (response.id) {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.useCompanyTags.updateSuccess)
      } else {
        toast.error(toastMessages.updateError)
      }
    },
    [afterUpdate],
  )

  const createTag = useCallback(
    async formValues => {
      const response = await apiClient.tags.create(formValues)
      if (response.id) {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.useCompanyTags.createSuccess)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [afterCreate],
  )

  const handleSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateTag(formValues)
        } else {
          await createTag(formValues)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createTag, isUpdating, updateTag],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
      isHiddenCancelButton={isHiddenCancelButton}
      onCancel={onCancel}
    />
  )
}

export default TagForm
