import { useCallback, memo, useMemo } from 'react'

import ContainerFilterMultipleSelect from '../MultipleSelect'
import {
  DatePicker,
  DateRangePicker,
  DropdownPicker,
  RangeSelectFilter,
} from '~/components/shared'

import { produce } from 'immer'
// import PropTypes from 'prop-types'
import { filter } from 'ionicons/icons'
import { FILTER_OPTION_TYPES } from '~/utils/constants'
import { EFieldType } from '~/types/enums/ECommonEnum'

const RenderFilters = props => {
  const {
    filterOptions,
    onChange,
    filterData = {},
    filterOrder = [],
    pluralModelName,
  } = props

  const handleChangeDateFilters = useCallback(
    ({ field }) =>
      selectedDate => {
        const newFilterData = produce(filterData, draft => {
          draft[field] = selectedDate
        })
        onChange && onChange(newFilterData)
      },
    [filterData, onChange],
  )

  const handleChangeMultipleSelect = useCallback(
    (selectedValues, field) => {
      const newFilterData = produce(filterData, draft => {
        const newValues = [...new Set(selectedValues)]
        draft[field] = newValues
      })
      onChange && onChange(newFilterData)
    },
    [filterData, onChange],
  )

  const handleChangeSingleSelectFilter = useCallback(
    ({ field, value }) => {
      const newFilterData = produce(filterData, draft => {
        draft[field] = value
      })
      onChange && onChange(newFilterData)
    },
    [filterData, onChange],
  )

  const handleChangeRangeNumber = useCallback(
    ({ field, value }) => {
      const newFilterData = produce(filterData, draft => {
        draft[field] = value
      })
      onChange && onChange(newFilterData)
    },
    [filterData, onChange],
  )
  const sourceArr = useMemo(
    () =>
      filterOrder && filterOrder.length > 0
        ? filterOrder.map(key =>
            filterOptions.find(({ field }) => field == key),
          )
        : filterOptions,
    [filterOrder, filterOptions],
  )

  return sourceArr
    .filter(({ isHidden }) => isHidden !== true)
    .map(option => {
      switch (option?.type) {
        case FILTER_OPTION_TYPES.dateRange: {
          const range = filterData[option.field]

          return (
            <DateRangePicker
              key={option.field}
              keySelect={option.field}
              title={option.label}
              field={option.field}
              range={range}
              onRangeChange={handleChangeDateFilters(option)}
              className='dateSubmitButton'
              pluralModelName={pluralModelName}
            />
          )
        }

        case EFieldType.date: {
          const date = filterData[option.field]

          return (
            <DatePicker
              key={option.field}
              title={option.label}
              date={date}
              minDate={option.minDate}
              maxDate={option.maxDate}
              onDateChange={handleChangeDateFilters(option)}
            />
          )
        }

        case FILTER_OPTION_TYPES.multipleSelect: {
          return (
            <ContainerFilterMultipleSelect
              {...option}
              values={filterData[option.field] || []}
              key={option.field}
              filterData={filterData}
              onChange={handleChangeMultipleSelect}
            />
          )
        }

        case FILTER_OPTION_TYPES.singleSelect: {
          return (
            <DropdownPicker
              key={option.field}
              field={option.field}
              filterKey={option.label}
              value={filterData[option.field]}
              items={option.options}
              icon={option.icon}
              onValueChange={handleChangeSingleSelectFilter}
            />
          )
        }

        case FILTER_OPTION_TYPES.rangeNumber:
        case EFieldType.number: {
          return (
            <RangeSelectFilter
              key={option.field}
              field={option.field}
              label={option.label}
              value={filterData[option.field]}
              className='documents-filter'
              icon={filter}
              onValueChange={handleChangeRangeNumber}
            />
          )
        }

        default: {
          return <span />
        }
      }
    })
}

RenderFilters.propTypes = {
  // filterOptions: PropTypes.array,
  // onChange: PropTypes.func,
  // filterData: PropTypes.object
}

export default memo(RenderFilters)
