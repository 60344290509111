import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonButton,
  IonCol,
  IonPage,
  IonRadioGroup,
  IonRadio,
  IonSearchbar,
  IonFooter,
  IonHeader,
} from '@ionic/react'

//data is an array with items format {label: string, value: int}
const SelectSearchTable = ({ data = [], currentItem, onSubmit, onCancel }) => {
  const [searchText, setSearchText] = useState('')
  const [selected, setSelected] = useState(
    currentItem ? currentItem.value || currentItem.id || currentItem : null,
  )

  const searchResult = useMemo(() => {
    if (!searchText) {
      return data
    }
    return data.filter(item => {
      return item.label.toLowerCase().includes(searchText.toLowerCase())
    })
  }, [searchText, data])

  const submit = () => {
    onSubmit(selected)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonSearchbar
          className='searchbar-input'
          value={searchText}
          no-border
          onIonInput={e => setSearchText(e.detail.value)}
          debounce={150}
        />
      </IonHeader>

      <IonContent>
        <IonList>
          <IonRadioGroup
            value={selected}
            onIonChange={e => setSelected(e.detail.value)}
          >
            {searchResult.map((item, index) => (
              <IonItem key={index}>
                <IonRadio
                  color='primary'
                  value={item.value}
                  labelPlacement='end'
                  justify='start'
                >
                  <IonLabel color={item.value == null ? 'danger' : ''}>
                    {item.label}
                  </IonLabel>
                </IonRadio>
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </IonContent>

      <IonFooter>
        <IonRow>
          <IonCol size='6'>
            {' '}
            <IonButton
              color='medium'
              expand='block'
              type='button'
              onClick={onCancel}
            >
              <FormattedMessage id='button.cancel' />
            </IonButton>
          </IonCol>
          <IonCol size='6'>
            <IonButton
              color='secondary'
              expand='block'
              type='button'
              onClick={submit}
            >
              <FormattedMessage id='button.submit' />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  )
}
export default SelectSearchTable
