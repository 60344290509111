import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'

export const getRTRowNumbersColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  const { rowNumberDisplayMode } = tableOptions
  const {
    pagination: { pageIndex, pageSize },
  } = tableOptions.state

  return {
    Cell: ({ row, staticRowIndex }) =>
      ((rowNumberDisplayMode === 'static'
        ? (staticRowIndex || 0) + (pageSize || 0) * (pageIndex || 0)
        : row.index) ?? 0) + 1,
    Header: () => 'Row Number',
    grow: false,
    ...defaultDisplayColumnProps({
      header: '#',
      id: ERTDisplayColumnId.numbers,
      size: 50,
      tableOptions,
    }),
  }
}
