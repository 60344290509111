import { useCallback, useEffect, useState } from 'react'
import { ILoadProgress, ILoadProgressProps } from '../type'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { loadSections } from '~/utils/loadUtils'

export const useLoadProgress = (props: ILoadProgressProps) => {
  const { load } = props

  const [data, setData] = useState<ILoadProgress[]>([])
  const { getLoadStatusesInSection } = useQueryLoadStatuses()

  const renderContentTooltip = useCallback(
    (index: number) => {
      switch (index) {
        case 0:
          return 'Ticketed'
        case 1: {
          if (data[1].percent == 100) {
            return 'Arrived Seller'
          }
          return 'Arrive Seller'
        }
        case 2: {
          if (data[2].percent == 100) {
            return 'Left Seller'
          }
          return 'Leave Seller'
        }
        case 3: {
          if (data[3].percent == 100) {
            return 'Arrived Buyer'
          }
          return 'Arrive Buyer'
        }
        case 4: {
          if (data[4].percent == 100) {
            return 'Left Buyer'
          }
          return 'Leave Buyer'
        }
        case 5: {
          if (data[5].percent == 100) {
            return 'Delivery Complete'
          }
          return 'Returning'
        }
      }
      return ''
    },
    [data],
  )

  useEffect(() => {
    if (load) {
      let formatData: ILoadProgress[] = []
      let isExisted = false
      let percent = 0

      Object.values(loadSections).forEach(section => {
        const statuses = getLoadStatusesInSection(section.statusName).map(
          ({ name }) => name,
        )
        percent = 0

        if (statuses?.includes(load)) {
          isExisted = true
          let indexStatus: number = statuses.indexOf(load)

          if (indexStatus != -1) {
            percent = ((indexStatus + 1) / statuses.length) * 100
          }
        }

        formatData.push({
          color:
            section.header.colorHex ||
            `var(--ion-color-${section.header.color})` ||
            '#e5e5e5',
          percent: isExisted ? percent : 100,
        })
      })
      setData(formatData)
    }
  }, [getLoadStatusesInSection, load])

  return {
    data,
    renderContentTooltip,
  }
}

export default useLoadProgress
