export interface IGetDirectionOnMapParams {
  startingPoint: google.maps.LatLng
  endingPoint: google.maps.LatLng
  waypoints?: google.maps.DirectionsWaypoint[]
}

export interface IGetDirectionOnMapResponse {
  directions: google.maps.DirectionsResult
  options: {
    suppressMarkers: boolean
    preserveViewport: boolean
  }
  travelTime: number
  distance: number
}

const getDirectionOnMap = (
  params: IGetDirectionOnMapParams,
  options?: Partial<google.maps.DirectionsRendererOptions>,
) => {
  const { startingPoint, endingPoint, waypoints } = params
  return new Promise<IGetDirectionOnMapResponse>((resolve, reject) => {
    const DirectionsService = new window.google.maps.DirectionsService()
    DirectionsService.route(
      {
        origin: startingPoint,
        destination: endingPoint,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK && result) {
          const newData = {
            directions: result,
            options: {
              suppressMarkers: true,
              preserveViewport: true,
              suppressInfoWindows: true,
              ...options,
            },
            travelTime: result.routes[0].legs.reduce(
              (acc, leg) => acc + (leg.duration?.value || 0),
              0,
            ),
            distance: result.routes[0].legs.reduce(
              (acc, leg) => acc + (leg.distance?.value || 0),
              0,
            ),
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resolve(newData as any)
        } else {
          reject('Error happens when finding directions')
        }
      },
    )
  })
}

export default getDirectionOnMap
