import { CheckComponent, ICheckComponentProps } from '../components'

const renderCheckSections = (
  options: ICheckComponentProps[],
  workerUid: string = '',
) => {
  return options
    .filter(({ isHidden }) => !isHidden)
    .map(
      ({
        name,
        label,
        apiMethod,
        getApiPayload,
        isOpenComponentOnMounted,
        checkUid,
        isShowFieldsForTax,
        taxValues,
        icons,
        onChangeTaxValues,
        onEvent,
        isOpen,
        unNeededUid,
      }) => (
        <CheckComponent
          key={name}
          label={label}
          apiMethod={apiMethod}
          getApiPayload={getApiPayload}
          name={name}
          checkUid={checkUid}
          isOpenComponentOnMounted={isOpenComponentOnMounted}
          isShowFieldsForTax={isShowFieldsForTax}
          taxValues={taxValues}
          icons={icons}
          onChangeTaxValues={onChangeTaxValues}
          onEvent={onEvent}
          isOpen={isOpen}
          unNeededUid={unNeededUid}
          id={workerUid}
        />
      ),
    )
}

export default renderCheckSections
