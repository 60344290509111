import { useState, useEffect } from 'react'

const EmptyDataView = ({ children, loading }) => {
  const [firstLoad, setFirstLoad] = useState(false)

  useEffect(() => {
    if (!firstLoad && loading) {
      setFirstLoad(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return firstLoad && !loading ? children : null
}

export default EmptyDataView
