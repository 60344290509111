var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { Confidence, FieldType, } from 'common/types/DocumentLabelerTypes';
import { v4 } from 'uuid';
var removeAllBlocksFromTable = function (state, action) {
    var fieldId = action.payload.fieldId;
    var _a = DocumentLabelerReducerUtils.getTableFromState(state, fieldId), table = _a.table, idx = _a.idx;
    var updatedTable = __assign(__assign({}, table), { rows: [] });
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var removeAllBlocksFromFormField = function (state, action) {
    var fieldId = action.payload.fieldId;
    var _a = DocumentLabelerReducerUtils.getFieldFromState(state, fieldId), field = _a.field, idx = _a.idx;
    var updatedField = __assign(__assign({}, field), { blocks: [], region: undefined, textOverride: undefined });
    return DocumentLabelerReducerUtils.updateStateWithNewField(state, updatedField, idx);
};
var removeAllBlocksFromField = function (state, action) {
    var fieldType = action.payload.fieldType;
    if (fieldType === FieldType.Table) {
        return removeAllBlocksFromTable(state, action);
    }
    else {
        return removeAllBlocksFromFormField(state, action);
    }
};
var setTextFieldOverride = function (state, action) {
    return DocumentLabelerReducerUtils.updateTextOverride(state, action.payload);
};
var setTableCellTextOverride = function (state, action) {
    var _a = action.payload, tableInfo = _a.table, textOverride = _a.textOverride;
    if (!tableInfo.activeCell) {
        throw new Error('Cannot set cell text override without specifying a cell');
    }
    var _b = DocumentLabelerReducerUtils.getTableFromState(state, tableInfo.id), table = _b.table, idx = _b.idx;
    var _c = DocumentLabelerReducerUtils.getCellInfoFromTable(table, tableInfo.activeCell), cell = _c.cell, rowIdx = _c.rowIdx, columnIdx = _c.columnIdx;
    var updatedCell = __assign(__assign({}, cell), { textOverride: textOverride });
    var updatedTable = DocumentLabelerReducerUtils.updateTableWithNewCell(table, updatedCell, rowIdx, columnIdx);
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var removeRowFromTable = function (state, action) {
    var _a = action.payload, tableId = _a.tableId, rowId = _a.rowId;
    var _b = DocumentLabelerReducerUtils.getTableFromState(state, tableId), table = _b.table, idx = _b.idx;
    var updatedTable = __assign(__assign({}, table), { rows: table.rows.filter(function (row) { return row.id !== rowId; }) });
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var addRowToTable = function (state, action) {
    var tableId = action.payload.tableId;
    var _a = DocumentLabelerReducerUtils.getTableFromState(state, tableId), table = _a.table, idx = _a.idx;
    var newRow = {
        id: v4(),
        cells: table.columns.map(function (col) { return ({
            columnId: col.id,
            confidenceScore: Confidence.UserReviewed,
            blocks: [],
        }); }),
    };
    var updatedTable = __assign(__assign({}, table), { rows: __spreadArray(__spreadArray([], table.rows, true), [newRow], false) });
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
export var FieldReducerUtils = {
    removeAllBlocksFromField: removeAllBlocksFromField,
    setTextFieldOverride: setTextFieldOverride,
    setTableCellTextOverride: setTableCellTextOverride,
    removeRowFromTable: removeRowFromTable,
    addRowToTable: addRowToTable,
};
