export const toastOptions = {
  position: 'bottom-left',
}

export const toastMessages = {
  accessChanges: 'Access changes were successfully saved.',
  createError: 'There was as an error creating a new entry.',
  createSuccess: 'Created successfully!',
  deleteError: 'There was an error attempting to delete.',
  deleteSuccess: 'Deleted successfully!',
  documentFetchFail: 'There is an error while loading document',
  emailError: 'There was an error using the email provided: ',
  errors: 'Please correct the following error: ',
  fleetRemoval: 'Order fleet was successfully removed from order #',
  forbidden: '403 Forbidden',
  formError: 'Please fill in all the required fields and try submitting again.',
  imageRemoval: 'Order image was successfully removed.',
  invoiceUpdateError: 'An error has occurred while updating the Invoice. ',
  invoiceDuplicate: 'The selected Invoice document has already been submitted.',
  loadFetchError: 'There was an error fetching the load. ',
  loadsFetchFail: 'There is an error while loading Loads',
  newFleet: 'New order fleet was added.',
  newImage: 'New order image was added.',
  newLoad: 'There is a new load available in itinerary',
  newNote: 'New order note was added.',
  noteRemoval: 'Order note was successfully removed.',
  parse:
    'Updated successfully. Our app is extracting the data and importing it. Check back for an update soon.',
  parseUpdate:
    'Successfully submitted document. Our app is extracting the data and importing it. Check back for an update soon.',
  password: 'Please provide a password.',
  serverError: 'A server error has occurred.',
  updateError: 'There was an error attempting to update.',
  updateSuccess: 'Updated successfully!',

  accessRequestList: {
    response: 'Your response on request is noted.',
  },
  apiService: {
    jsonError: 'A server error has occurred. Please try again later.',
    unauthorized: 'Invalid login email or password.  Please try again.',
  },
  companyContainer: {
    success:
      'Updated successfully. Our app is extracting the data and importing it. Check back for an update soon.',
  },
  documentsContainer: {
    uploadError: 'There was an error uploading your document.',
    uploadSuccess: 'The document(s) upload was successful.',
    selectFile: 'Please select a file to upload.',
  },
  driverHomePage: {
    scan: 'Barcode scan completed.',
  },
  editCompanyContainer: {
    infoSuccess: 'Info was updated successfully.',
    infoError: 'There was an error updating your info.',
  },
  emailParsersForm: {
    buyerError: "This company doesn't have any buyers.",
    sellerError: "This company doesn't have any sellers.",
  },
  loginPage: {
    matchPasswordError:
      'Please make sure your password confirmation matches your password.',
    updateSuccess: 'Password was updated successfully.',
  },
  orderDetails: {
    error: 'Please select at least one document or image.',
    orderUpdate: 'Successfully updated order #',
  },
  useAccessRequests: {
    success: 'Request submitted successfully.',
    error: 'There was an error processing your request.',
  },
  useAccessUser: {
    success: 'User was created successfully.',
  },
  useCompanyAutoCharge: {
    createSuccess: 'Auto charge was created successfully.',
    updateSuccess: 'Auto charge was updated successfully.',
  },
  useCompanyBTFSTP: {
    createSuccess: 'Boarding form was created successfully.',
    updateSuccess: 'Boarding form was updated successfully.',
  },
  useCompanyDataCorrection: {
    error: 'Please set data correction method correctly.',
    bracketError: 'Please wrap with a pair of {}',
    updateSuccess: 'Data correction method was successfully updated.',
    createSuccess: 'Data correction method was successfully created.',
  },
  useCompanyFleets: {
    createSuccess: 'Company fleet was successfully created.',
    updateSuccess: 'Company fleet was successfully updated.',
    duplicateError: 'This fleet already exists.',
  },
  useCompanyInfoParser: {
    createSuccess: 'Info parser was successfully created.',
    updateSuccess: 'Info parser was successfully updated.',
    duplicateError: 'The parsing column and assign column can not be the same.',
  },
  useCompanyProducts: {
    createSuccess: 'Company product was added successfully.',
    updateSuccess: 'Company product was updated successfully.',
  },
  useDataParser: {
    createSuccess: 'Data parser was created successfully.',
    updateSuccess: 'Data parser was updated successfully.',
  },
  useEmailParsers: {
    inputError: 'Please fill in either seller or buyer.',
    createSuccess: 'Email parser was created successfully.',
    updateSuccess: 'Email parser was updated successfully.',
  },
  useFleets: {
    createSuccess: 'Fleet was successfully created.',
    updateSuccess: 'Fleet was successfully updated.',
  },
  useProducts: {
    createSuccess: 'Product was created successfully.',
    updateSuccess: 'Product was updated successfully.',
  },
  useTerminalDetail: {
    updateSuccess: 'Location was successfully updated.',
  },
  useSellerProducts: {
    createSuccess: 'Seller product was created successfully.',
    updateSuccess: 'Seller product was updated successfully.',
  },
  useTerminals: {
    createSuccess: 'Terminal was created successfully.',
    updateSuccess: 'Terminal was updated successfully.',
  },
  useCostCodes: {
    createSuccess: 'Cost code was created successfully.',
    updateSuccess: 'Cost code was updated successfully.',
  },
  useBuyerSellerProducts: {
    createSuccess: 'Buyer seller product was created successfully.',
    updateSuccess: 'Buyer seller product was updated successfully.',
  },
  useCompanyTags: {
    createSuccess: 'Company tag was created successfully.',
    updateSuccess: 'Company tag was updated successfully.',
  },
  preProcessingTest: {
    configurationUpdated: 'File Pre Processing Configurations are updated.',
  },
  fleetsOrderForm: {
    createSuccess: 'Fleet order was created successfully.',
  },
  errorLengthScopes:
    'Cannot Select All Disallowed Scopes. Select atmost one less than total',
}
