import { useMemo } from 'react'
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import { RTAggregationFns } from '../fns/aggregationFns'
import { RTFilterFns } from '../fns/filterFns'
import { RTSortingFns } from '../fns/sortingFns'
import { RTDefaultIcons } from '../icons'
import type {
  RTDefinedTableOptions,
  IReusableTableRowData,
  RTTableOptions,
} from '../types'
import { EFieldType } from '~/types/enums/ECommonEnum'

export const RTDefaultColumn = {
  filterVariant: EFieldType.text,
  maxSize: 1000,
  minSize: 40,
  size: 180,
} as const

export const RTDefaultDisplayColumn = {
  columnDefType: 'display',
  enableClickToCopy: false,
  enableColumnActions: false,
  enableColumnDragging: false,
  enableColumnFilter: false,
  enableColumnOrdering: false,
  enableEditing: false,
  enableGlobalFilter: false,
  enableGrouping: false,
  enableHiding: false,
  enableResizing: false,
  enableSorting: false,
} as const

export const useRTTableOptions: <TData extends IReusableTableRowData>(
  tableOptions: RTTableOptions<TData>,
) => RTDefinedTableOptions<TData> = <TData extends IReusableTableRowData>({
  aggregationFns,
  autoResetExpanded = false,
  columnFilterDisplayMode = 'subheader',
  columnResizeDirection,
  columnResizeMode = 'onChange',
  createDisplayMode = 'row',
  defaultColumn,
  defaultDisplayColumn,
  editDisplayMode = 'table',
  enableBatchRowSelection = true,
  enableTopToolbar = false,
  enableColumnActions = true,
  enableColumnFilters = true,
  enableColumnOrdering = false,
  enableColumnPinning = false,
  enableColumnResizing = false,
  enableColumnVirtualization,
  enableExpandAll = true,
  enableExpanding,
  enableFacetedValues = false,
  enableFilterMatchHighlighting = true,
  enableFilters = true,
  enableGlobalFilter = true,
  enableGlobalFilterRankedResults = true,
  enableGrouping = false,
  enableHiding = true,
  enableMultiRowSelection = true,
  enableMultiSort = true,
  enablePagination = false,
  enableRowPinning = false,
  enableRowSelection = false,
  enableRowVirtualization = true,
  enableSelectAll = true,
  enableSorting = true,
  enableStickyHeader = false,
  enableTableFooter = true,
  enableTableHead = true,
  showPaginationPages = true,
  showPerPageOptions = true,
  filterFns,
  icons,
  layoutMode,
  manualFiltering,
  manualGrouping,
  manualPagination,
  manualSorting,
  positionActionsColumn = 'first',
  positionCreatingRow = 'top',
  positionExpandColumn = 'first',
  positionGlobalFilter = 'right',
  positionToolbarDropZone = 'top',
  rowNumberDisplayMode = 'static',
  rowPinningDisplayMode = 'sticky',
  selectAllMode = 'page',
  sortingFns,
  enableStickyFooter = true,
  tableHeight = 500,
  getRowId = row => row.id,
  ...rest
}: RTTableOptions<TData>) => {
  icons = useMemo(() => ({ ...RTDefaultIcons, ...icons }), [icons])
  aggregationFns = useMemo(
    () => ({ ...RTAggregationFns, ...aggregationFns }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  filterFns = useMemo(() => ({ ...RTFilterFns, ...filterFns }), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  sortingFns = useMemo(() => ({ ...RTSortingFns, ...sortingFns }), [])
  defaultColumn = useMemo(
    () => ({ ...RTDefaultColumn, ...defaultColumn }),
    [defaultColumn],
  )
  defaultDisplayColumn = useMemo(
    () => ({
      ...RTDefaultDisplayColumn,
      ...defaultDisplayColumn,
    }),
    [defaultDisplayColumn],
  )
  //cannot be changed after initialization
  ;[enableColumnVirtualization, enableRowVirtualization] = useMemo(
    () => [enableColumnVirtualization, enableRowVirtualization],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  if (!columnResizeDirection) {
    columnResizeDirection = 'ltr'
  }

  layoutMode =
    layoutMode || (enableColumnResizing ? 'grid-no-grow' : 'semantic')
  if (
    layoutMode === 'semantic' &&
    (enableRowVirtualization || enableColumnVirtualization)
  ) {
    layoutMode = 'grid'
  }

  if (enableRowVirtualization) {
    enableStickyHeader = true
  }

  if (enablePagination === false && manualPagination === undefined) {
    manualPagination = true
  }

  if (!rest.data?.length) {
    manualFiltering = true
    manualGrouping = true
    manualPagination = true
    manualSorting = true
  }

  return {
    aggregationFns,
    autoResetExpanded,
    columnFilterDisplayMode,
    columnResizeDirection,
    columnResizeMode,
    createDisplayMode,
    defaultColumn,
    defaultDisplayColumn,
    editDisplayMode,
    enableBatchRowSelection,
    enableTopToolbar,
    enableColumnActions,
    enableColumnFilters,
    enableColumnOrdering,
    enableColumnPinning,
    enableColumnResizing,
    enableColumnVirtualization,
    enableExpandAll,
    enableExpanding,
    enableFacetedValues,
    enableFilterMatchHighlighting,
    enableFilters,
    enableGlobalFilter,
    enableGlobalFilterRankedResults,
    enableGrouping,
    enableHiding,
    enableMultiRowSelection,
    enableMultiSort,
    enablePagination,
    enableRowPinning,
    enableRowSelection,
    enableRowVirtualization,
    enableSelectAll,
    enableSorting,
    enableStickyHeader,
    enableTableFooter,
    enableTableHead,
    enableStickyFooter,
    filterFns,
    showPaginationPages,
    showPerPageOptions,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel:
      enableExpanding || enableGrouping ? getExpandedRowModel() : undefined,
    getFacetedMinMaxValues: enableFacetedValues
      ? getFacetedMinMaxValues()
      : undefined,
    getFacetedRowModel: enableFacetedValues ? getFacetedRowModel() : undefined,
    getFacetedUniqueValues: enableFacetedValues
      ? getFacetedUniqueValues()
      : undefined,
    getFilteredRowModel:
      (enableColumnFilters || enableGlobalFilter || enableFilters) &&
      !manualFiltering
        ? getFilteredRowModel()
        : undefined,
    getGroupedRowModel:
      enableGrouping && !manualGrouping ? getGroupedRowModel() : undefined,
    getPaginationRowModel:
      enablePagination && !manualPagination
        ? getPaginationRowModel()
        : undefined,
    getSortedRowModel:
      enableSorting && !manualSorting ? getSortedRowModel() : undefined,
    getSubRows: row => row?.subRows,
    icons,
    layoutMode,
    manualFiltering,
    manualGrouping,
    manualPagination,
    manualSorting,
    positionActionsColumn,
    positionCreatingRow,
    positionExpandColumn,
    positionGlobalFilter,
    positionToolbarDropZone,
    rowNumberDisplayMode,
    rowPinningDisplayMode,
    selectAllMode,
    sortingFns,
    tableHeight,
    getRowId,
    ...rest,
  } as RTDefinedTableOptions<TData>
}
