import { createSelector } from 'reselect'

export const selectAppState = state => state.app

export const selectAppContentScrollY = createSelector(
  selectAppState,
  app => app.contentScrollY,
)

export const selectAppBillLinesColumn = createSelector(
  selectAppState,
  app => app.billLinesColumn,
)

export const selectInvoiceBillLineColumns = createSelector(
  selectAppBillLinesColumn,
  billLinesColumn => billLinesColumn.invoice || [],
)

export const selectDriverFleetLoadsSection = createSelector(
  selectAppState,
  app => app.driverFleetLoadsSections,
)

export const selectMappingDriverFleetLoadsSection = createSelector(
  selectDriverFleetLoadsSection,
  driverFleetLoadsSections =>
    driverFleetLoadsSections.map(({ field, label, hide }) => ({
      label,
      sortField: field,
      sorted: !hide,
    })),
)
