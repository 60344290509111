import { PropsWithChildren, useCallback, useState } from 'react'

import CompanyViewContext from './CompanyViewContext'
import { DialogCompanyView } from '~/containers/my-company/components'
import { Button } from '~/components'
import { IonButtons } from '@ionic/react'
import { When } from 'react-if'

import { arrowBack } from 'ionicons/icons'

import type { ICompanyViewProviderProps } from './type'

function CompanyViewProvider(
  props: PropsWithChildren<ICompanyViewProviderProps>,
) {
  const { children } = props

  const [isOpen, setIsOpen] = useState(false)
  const [view, setView] = useState('')

  const onOpenCompanyViewDialog = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onCloseCompanyViewDialog = useCallback(() => {
    setIsOpen(false)
    setView('')
  }, [])

  const onClickSubpage = useCallback(
    (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      item: {
        label: string
        value: string
      },
    ) => {
      event.preventDefault()
      setView(item.value)
    },
    [],
  )

  const onBackToHomePage = useCallback(() => {
    setView('')
  }, [])

  return (
    <>
      <CompanyViewContext.Provider
        value={{
          onOpenCompanyViewDialog,
          onCloseCompanyViewDialog,
        }}
      >
        {children}
        <DialogCompanyView
          fullscreen
          isHiddenOkButton
          title={
            <When condition={Boolean(view)}>
              <IonButtons>
                <Button
                  size='small'
                  label='Back to Home page'
                  fill='clear'
                  color='fleet'
                  icon={arrowBack}
                  onClick={onBackToHomePage}
                />
              </IonButtons>
            </When>
          }
          view={view}
          isOpen={isOpen}
          onClose={onCloseCompanyViewDialog}
          onClickSubpage={onClickSubpage}
        />
      </CompanyViewContext.Provider>
    </>
  )
}
export default CompanyViewProvider
