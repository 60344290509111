import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { createSlice } from '@reduxjs/toolkit'

export const terminalsSlice = createSlice({
  name: 'terminals',
  initialState: {},
  reducers: {
    initializeTerminals: (state, action) => {
      action.payload.forEach(terminal => (state[terminal.id] = terminal))
    },
    add: (state, { payload }) => {
      state[payload.id] = payload
    },
    updateTerminals: (state, action) => {
      action.payload.forEach(terminal => {
        if (state[terminal.id]) {
          state[terminal.id] = terminal
          if (terminal.deletedAt !== null) delete state[terminal.id]
        }
      })
    },
    updateTerminal: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
      }
    },
    deleteTerminal: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export default terminalsSlice
