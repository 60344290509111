import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { IonButton, IonIcon, IonModal, IonContent } from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import { toast } from 'react-toastify'
import LoadGridCard from '~/components/load/LoadGridCard'
import {
  DocumentView,
  Button,
  MoreDetailsButton,
  LoadFlags,
} from '~/components/shared'
import clsx from 'clsx'
import { Form } from 'react-bootstrap'
import _ from 'lodash'

import './ImageZoom.scss'
import { apiClient } from '~/api/ApiClient'

const ZOOM_STEP = 0.2
const IMAGE_WRAPPER_HEIGHT = 500

const ZoomControl = ({
  onZoomOut,
  onZoomIn,
  inputOptions,
  scale,
  updateScale,
}) => {
  return (
    <div style={styles.tools}>
      <div className='d-flex'>
        <IonButton size='small' expand='block' color='dark' onClick={onZoomOut}>
          <IonIcon icon={remove} />
        </IonButton>
        <input
          type='range'
          min={inputOptions.minScale}
          max={inputOptions.maxScale}
          value={scale}
          onChange={updateScale}
          style={styles.input}
          step={ZOOM_STEP}
        />
        <IonButton size='small' expand='block' color='dark' onClick={onZoomIn}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    </div>
  )
}

const ImageZoom = props => {
  const {
    url,
    alt,
    minScale = 1.0,
    maxScale = 4,
    reponsive,
    imageClassName,
    extraButtons,
    onFetchCurrentLoad,
    onToggleLoading,
    currentLoad,
  } = props

  const transformComponentRef = useRef()
  const imgRef = useRef()

  const { isScreenDown } = useBreakpoint()

  const [scale, setScale] = useState(1)
  const [toggleBillLines, setToggleBillLines] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [toggleMaterialInvoice, setToggleMaterialInvoice] = useState(false)
  const [toggleLogisticsInvoice, setToggleLogisticsInvoice] = useState(false)
  const [materialInvoiceUrl, setMaterialInvoiceUrl] = useState(null)
  const [deliveryInvoiceUrl, setDeliveryInvoiceUrl] = useState(null)
  const [dragId, setDragId] = useState()
  const [boxes, setBoxes] = useState([
    {
      id: 'ticket-image',
      order: 1,
    },
    {
      id: 'material-invoice',
      order: 2,
    },
    {
      id: 'delivery-invoice',
      order: 3,
    },
  ])

  const renderMapButtons = useCallback(() => {
    const defaultButtons = [
      {
        label: 'Bill Lines & Flags',
        type: 'checkbox',
        onChange: onShowBillLine,
        hide: _.size(currentLoad?.flags) === 0,
      },
      {
        label: 'Material Invoice',
        type: 'checkbox',
        onChange: onShowMaterialInvoice,
        hide: Boolean(!currentLoad?.materialInvoiceId),
      },
      {
        label: 'Logistics Invoice',
        type: 'checkbox',
        onClick: onShowLogisticsInvoice,
        hide: Boolean(!currentLoad?.deliveryInvoiceId),
      },
    ]

    const mergeButtons = [...defaultButtons, ...extraButtons]

    return mergeButtons
      .filter(button => button.hide !== true)
      .map(button => {
        const {
          type,
          label,
          icon,
          tooltipProps,
          onClick,
          iconProps,
          ...buttonProps
        } = button

        if (type === 'checkbox') {
          return (
            <div className='ImageZoom__checkboxButton' key={label}>
              <Form.Check {...buttonProps} label={label} onClick={onClick} />
            </div>
          )
        }

        return (
          <Button
            key={label || icon}
            buttonProps={buttonProps}
            tooltipProps={tooltipProps}
            onClick={onClick}
            label={label}
            icon={icon}
            iconProps={iconProps}
          />
        )
      })
  }, [
    currentLoad,
    extraButtons,
    onShowBillLine,
    onShowLogisticsInvoice,
    onShowMaterialInvoice,
  ])

  const handleDrag = ev => {
    setDragId(ev.currentTarget.id)
  }

  const handleDrop = ev => {
    const dragBox = boxes.find(box => box.id === dragId)
    const dropBox = boxes.find(box => box.id === ev.currentTarget.id)

    const dragBoxOrder = dragBox.order
    const dropBoxOrder = dropBox.order

    const newBoxState = boxes.map(box => {
      if (box.id === dragId) {
        box.order = dropBoxOrder
      }
      if (box.id === ev.currentTarget.id) {
        box.order = dragBoxOrder
      }

      return box
    })
    if (dragId == 'ticket-image' || ev.currentTarget.id == 'ticket-image') {
      setTimeout(() => {
        zoom(2)
        zoom(1)
      }, 200)
    }

    setBoxes(newBoxState)
  }

  const updateScale = useCallback(e => {
    const targetScale = parseFloat(e.target.value)
    zoom(targetScale)
  }, [])

  const onImageLoaded = useCallback(() => {
    transformComponentRef?.current?.zoomIn(1)
    transformComponentRef?.current?.resetTransform()
    transformComponentRef?.current?.centerView(1)
  }, [])

  const getLoadFlags = useCallback(async () => {
    onToggleLoading && onToggleLoading(true)
    try {
      const loadResponse = await apiClient.loads.getById(currentLoad.id)
      const error = loadResponse.message || loadResponse.error

      if (error) return toast.error(error)

      onFetchCurrentLoad && onFetchCurrentLoad(loadResponse)
    } catch (error) {
      console.log('error', error)
      toast.error('Server occurs an error!')
    } finally {
      onToggleLoading && onToggleLoading(false)
    }
  }, [currentLoad, onFetchCurrentLoad, onToggleLoading])

  const getMaterialInvoice = useCallback(async materialInvoiceId => {
    const response = await apiClient.url.getById(
      materialInvoiceId,
      {
        model: 'Invoice'
      }
    )
    setMaterialInvoiceUrl(response.url)
  }, [])

  const getLogisticsInvoice = useCallback(async logisticsInvoiceId => {
    const response = await apiClient.url.getById(
      logisticsInvoiceId,
      {
        model: 'Invoice'
      }
    )
    setDeliveryInvoiceUrl(response.url)
  }, [])

  useEffect(() => {
    if (toggleBillLines) {
      getLoadFlags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBillLines])

  useEffect(() => {
    const imgElCurrent = imgRef?.current
    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded)

      return () => imgElCurrent.removeEventListener('load', onImageLoaded)
    }
  }, [onImageLoaded])

  const zoom = scale => {
    transformComponentRef?.current?.centerView(scale)
    setScale(scale)
  }

  const onZoomOut = useCallback(
    e => {
      e.preventDefault()
      const newScale = (+scale - ZOOM_STEP).toFixed(2)
      if (newScale < minScale) return
      zoom(newScale)
    },
    [minScale, scale],
  )

  const onZoomIn = useCallback(
    e => {
      e.preventDefault()
      const newScale = (+scale + ZOOM_STEP).toFixed(2)
      if (newScale > maxScale) return
      zoom(newScale)
    },
    [maxScale, scale],
  )

  const onShowBillLine = useCallback(() => {
    setToggleBillLines(prev => !prev)
  }, [])

  const onShowDetailsModal = useCallback(() => {
    setShowDetailsModal(true)
  }, [])

  const onShowMaterialInvoice = useCallback(
    event => {
      const { checked } = event.target
      try {
        if (checked) {
          getMaterialInvoice(currentLoad?.materialInvoiceId)
          transformComponentRef?.current?.setTransform(0, 0, 1)
        }
      } finally {
        setToggleMaterialInvoice(checked)
      }
    },
    [getMaterialInvoice, currentLoad?.materialInvoiceId],
  )

  const onShowLogisticsInvoice = useCallback(
    event => {
      const { checked } = event.target

      try {
        if (!checked) {
          getLogisticsInvoice(currentLoad?.deliveryInvoiceId)
          transformComponentRef?.current?.setTransform(0, 0, 1)
        }
      } finally {
        setToggleLogisticsInvoice(checked)
      }
    },
    [getLogisticsInvoice, currentLoad?.deliveryInvoiceId],
  )

  const getSectionView = useCallback(() => {
    if (!toggleMaterialInvoice && !toggleLogisticsInvoice) {
      return 1
    } else if (
      (toggleMaterialInvoice && !toggleLogisticsInvoice) ||
      (!toggleMaterialInvoice && toggleLogisticsInvoice)
    ) {
      return 2
    } else if (toggleMaterialInvoice && toggleLogisticsInvoice) {
      return 3
    }
  }, [toggleLogisticsInvoice, toggleMaterialInvoice])

  const renderLoadFlags = useCallback(() => {
    if (toggleBillLines) {
      if (currentLoad?.flags?.length > 0) {
        return (
          <div
            style={{
              margin: '20px 10px',
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            {currentLoad.flags.map((flag, index) => (
              <LoadFlags key={index} flag={flag} index={index} />
            ))}
          </div>
        )
      }

      return <div>No Flags</div>
    }

    return null
  }, [currentLoad?.flags, toggleBillLines])

  const getPaddingTop = () => {
    if (reponsive && isScreenDown('sm')) {
      return 50
    }

    return getSectionView() != 1 ? '50px' : '0'
  }

  const renderBox = useCallback(
    boxId => {
      switch (boxId) {
        case 'ticket-image': {
          return (
            <div>
              <TransformWrapper
                ref={transformComponentRef}
                initialScale={1}
                initialPositionX={window.innerHeight / 2 + 50}
                initialPositionY={250}
                centerOnInit
                minScale={minScale}
                maxScale={maxScale}
                centerZoomedOut
                doubleClick={{ disabled: true }}
                wheel={{ disable: true, wheelDisabled: true }}
              >
                {() => (
                  <>
                    <TransformComponent
                      wrapperClass={clsx('image-wrapper', imageClassName)}
                    >
                      <img
                        src={url}
                        alt={alt}
                        ref={imgRef}
                        style={styles.image}
                      />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </div>
          )
        }

        case 'material-invoice': {
          if (toggleMaterialInvoice) {
            return (
              <div
                className={`${toggleMaterialInvoice
                  ? `wrap-material-invoice-${getSectionView()}`
                  : 'd-none'
                  }`}
              >
                {materialInvoiceUrl || currentLoad.materialInvoiceUrl ? (
                  <div>
                    <DocumentView
                      document={
                        materialInvoiceUrl || currentLoad.materialInvoiceUrl
                      }
                      ratio={1}
                    />
                  </div>
                ) : (
                  <div className='text-center'>No supplier invoice yet</div>
                )}
              </div>
            )
          }
          return null
        }

        case 'delivery-invoice': {
          if (toggleLogisticsInvoice) {
            return (
              <div
                className={`${toggleLogisticsInvoice
                  ? `wrap-delivery-invoice-${getSectionView()}`
                  : 'd-none'
                  }`}
              >
                {deliveryInvoiceUrl || currentLoad.deliveryInvoiceUrl ? (
                  <div>
                    <DocumentView
                      document={
                        deliveryInvoiceUrl || currentLoad.deliveryInvoiceUrl
                      }
                      ratio={1}
                    />
                  </div>
                ) : (
                  <div className='text-center'>No supplier invoice yet</div>
                )}
              </div>
            )
          }
          return null
        }

        default: {
          return boxId
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      alt,
      deliveryInvoiceUrl,
      getSectionView,
      imageClassName,
      materialInvoiceUrl,
      maxScale,
      minScale,
      onZoomIn,
      onZoomOut,
      scale,
      toggleLogisticsInvoice,
      toggleMaterialInvoice,
      updateScale,
      url,
    ],
  )

  return (
    <div className='ImageZoom__container'>
      <div className='ImageZoom__buttonGroup'>{renderMapButtons()}</div>

      <div className='ImageZoom__zoomControl'>
        {_.size(currentLoad) > 0 && (
          <MoreDetailsButton onClick={onShowDetailsModal} />
        )}
        <ZoomControl
          onZoomOut={onZoomOut}
          onZoomIn={onZoomIn}
          inputOptions={{ minScale, maxScale }}
          scale={scale}
          updateScale={updateScale}
        />
      </div>
      <div
        style={{
          display: `${getSectionView() != 1 ? 'flex' : 'block'}`,
          paddingTop: getPaddingTop(),
        }}
      >
        {boxes
          .sort((a, b) => a.order - b.order)
          .map(box => (
            <div
              key={box.id}
              draggable={getSectionView() != 1}
              id={box.id}
              onDragOver={ev => ev.preventDefault()}
              onDragStart={handleDrag}
              onDrop={handleDrop}
              className={`${((box.id == 'material-invoice' && !toggleMaterialInvoice) ||
                (box.id == 'delivery-invoice' && !toggleLogisticsInvoice)) &&
                getSectionView() == 2
                ? 'd-none'
                : ''
                }`}
              style={{
                paddingLeft: `${getSectionView() != 1 ? '5px' : '0'}`,
                paddingRight: `${getSectionView() != 1 ? '5px' : '0'}`,
                width: `${getSectionView() == 2
                  ? '50%'
                  : getSectionView() == 3
                    ? '33.3%'
                    : '100%'
                  }`,
              }}
            >
              {renderBox(box.id)}
            </div>
          ))}
      </div>

      {renderLoadFlags()}

      <IonModal
        className='loadDetails-modal'
        isOpen={showDetailsModal}
        onDidDismiss={() => setShowDetailsModal(false)}
        canDismiss
      >
        <IonContent>
          {_.size(currentLoad) && (
            <LoadGridCard load={currentLoad} expandGrid={true} />
          )}
        </IonContent>
      </IonModal>
    </div>
  )
}

const center = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
const styles = {
  container: { ...center },
  tools: { ...center, marginTop: 10, marginBottom: 10 },
  buttonTools: { width: 10, height: 10 },
  input: {
    border: 'none',
    fontSize: 18,
    margin: 10,
    textAlign: 'center',
    backgroundColor: 'transparent',
    width: 200,
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: IMAGE_WRAPPER_HEIGHT,
    objectFit: 'cover',
  },
}

ImageZoom.defaultProps = {
  extraButtons: [],
  currentLoad: {},
}

ImageZoom.propTypes = {}

export default ImageZoom
