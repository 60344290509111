import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { RTSelectCheckbox } from '../../components/inputs/RTSelectCheckbox'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'

export const getRTRowSelectColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  const { enableMultiRowSelection, enableSelectAll } = tableOptions

  return {
    Cell: ({ row, staticRowIndex, table }) => (
      <RTSelectCheckbox
        row={row}
        staticRowIndex={staticRowIndex}
        table={table}
        style={{ marginLeft: 2 }}
      />
    ),
    Header:
      enableSelectAll && enableMultiRowSelection
        ? ({ table }) => <RTSelectCheckbox table={table} />
        : undefined,
    grow: false,
    ...defaultDisplayColumnProps({
      header: '',
      id: ERTDisplayColumnId.select,
      size: enableSelectAll ? 60 : 70,
      tableOptions,
    }),
  }
}
