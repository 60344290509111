import { Badge, Button } from 'react-bootstrap'
import { useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { ExtrasFormRangesFieldArray } from './ExtrasFormRangesFieldArray'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import { EOrderType, EQtyType } from '~/types/enums/ESellerProduct'
import _ from 'lodash'
import { DeleteIcon } from '~/components/shared'
import { FloatingSelectV2 } from '../../FloatingForm/FloatingSelectV2'

export const ExtrasFormRow = ({
  index,
  control,
  remove,
  buyerId,
  sellerId,
  prefix,
  setValue,
}) => {
  const fieldsName = useMemo(
    () => `${prefix}orderExtrasAttributes.${index}.orderExtraRangesAttributes`,
    [prefix, index],
  )

  const extraOrderTypes = useMemo(() => [EOrderType.extra, EOrderType.both], [])

  const { sellerProductsObj, sellerProductOptions } = useQuerySellerProducts(
    {
      sellerId: [sellerId],
      filters: {
        joinsSellerProduct: true,
        orderTypes: extraOrderTypes,
      },
    },
    { enabled: Boolean(buyerId && sellerId) },
  )

  const sellerProductId = useWatch({
    control,
    name: `${prefix}orderExtrasAttributes.${index}.sellerProductId`,
  })

  const qtyType = useMemo(
    () => sellerProductsObj[sellerProductId]?.qtyType,
    [sellerProductId, sellerProductsObj],
  )

  const qtyTypeValue = _.startCase(Object.values(EQtyType)[qtyType])

  const isPerOrder = useMemo(() => qtyType === EQtyType.per_order, [qtyType])

  useEffect(() => {
    if (isPerOrder) {
      setValue(
        `${prefix}orderExtrasAttributes.${index}.orderExtraRangesAttributes`,
        [{ qty: null, startLoad: 1, endLoad: 1 }],
      )
    }
  }, [isPerOrder, index, prefix, setValue])

  return (
    <div>
      <div className='d-flex pb-1 align-items-center'>
        <FloatingSelectV2
          control={control}
          name={`${prefix}orderExtrasAttributes.${index}.sellerProductId`}
          options={sellerProductOptions}
          placeholder='Select product'
          companyId={sellerId}
          label='Product'
          isDisabled={!sellerId}
        />

        {qtyTypeValue && (
          <Badge pill className='d-flex ms-1'>
            {qtyTypeValue}
          </Badge>
        )}

        <Button
          onClick={() => remove(index)}
          variant='danger'
          className='ms-auto'
        >
          <DeleteIcon color='#fff' />
        </Button>
      </div>

      {fieldsName && (
        <ExtrasFormRangesFieldArray
          name={fieldsName}
          control={control}
          sellerProductId={sellerProductId}
          hideRange={isPerOrder}
          setValue={setValue}
          extrasIndex={index}
          prefix={prefix}
        />
      )}
    </div>
  )
}
