import { useMemo } from 'react'

import { IRTColumnDef } from '~/components/shared'
import { ITag } from '~/types/models/ITag'

const useTableCompanyTagSubTab = () => {
  const columns = useMemo<IRTColumnDef<ITag>[]>(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        size: 60,
        Cell({ cell, row }) {
          const cellValue = cell.getValue<number | undefined>()

          return <span>{cellValue || row.index + 1}</span>
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
        size: 150,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableEditing: true,
        size: 200,
      },
      {
        header: 'Models',
        id: 'models',
        accessorFn: row => row.models.join(', '),
      },
      {
        header: 'Color',
        accessorKey: 'color',
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          if (!cellValue) {
            return ''
          }

          return (
            <div>
              <span>{cellValue}</span>
              <span
                style={{
                  height: 12,
                  width: 12,
                  border: `1px solid ${cellValue}`,
                  backgroundColor: `${cellValue}`,
                  display: 'inline-block',
                  marginLeft: 6,
                  marginTop: 4,
                }}
              ></span>
            </div>
          )
        },
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyTagSubTab
