import queryString from 'query-string'

export const INVOICE_PARSER_BASE_URL =
  'https://app.impira.com/o/cncrd.co-c7cb2cb2/files'

export const createInvoiceParserUrl = uuid => {
  return queryString.stringifyUrl({
    url: INVOICE_PARSER_BASE_URL,
    query: {
      r: uuid,
    },
  })
}
