import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'
import { useQueryCompanies } from '~/hooks/useQueryData'

import { selectCurrentScope } from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { IReferenceName } from '~/types/models/IReferenceName'

const useTableCompanyReferenceNameSubTab = () => {
  const currentScope: EScope = useSelector(selectCurrentScope)

  const isBuyer = currentScope === EScope.buyer

  const { companiesData, buyerCompanyOptions, sellerCompanyOptions } =
    useQueryCompanies({})

  const columns = useMemo<IRTColumnDef<IReferenceName>[]>(
    () => [
      {
        header: 'Buyer',
        accessorKey: 'buyerId',
        minSize: 230,
        maxSize: 230,
        filterSelectOptions: isBuyer ? undefined : buyerCompanyOptions,
        filterVariant: isBuyer ? undefined : EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const company = companiesData.find(({ id }) => id === cellValue)

          return (
            <CompanyInfo
              companyType={EScope.buyer}
              defaultCompanyCode='Buyer'
              company={{
                name: company?.name,
                code: company?.code,
                label: company?.name || '',
                value: company?.id,
                image: company?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Seller',
        accessorKey: 'sellerId',
        minSize: 230,
        maxSize: 230,
        filterSelectOptions: isBuyer ? sellerCompanyOptions : undefined,
        filterVariant: isBuyer ? EFieldType.multipleSelect : undefined,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const company = companiesData.find(({ id }) => id === cellValue)

          return (
            <CompanyInfo
              companyType={EScope.seller}
              defaultCompanyCode='Seller'
              company={{
                name: company?.name,
                code: company?.code,
                label: company?.name || '',
                value: company?.id,
                image: company?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Item Type',
        accessorKey: 'itemType',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'Item #',
        accessorKey: 'itemId',
        minSize: 80,
        maxSize: 80,
      },
      {
        header: 'Reference Name',
        accessorKey: 'referenceName',
        enableEditing: true,
      },
      {
        header: 'Reference Code',
        accessorKey: 'referenceCode',
        enableEditing: true,
      },
    ],
    [buyerCompanyOptions, companiesData, isBuyer, sellerCompanyOptions],
  )

  return { columns }
}

export default useTableCompanyReferenceNameSubTab
