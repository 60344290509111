import React from 'react'
import { IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import FinalSegment from './FinalSegment'

const ProgressBar = props => {
  const {
    adjustedSegments,
    finalSegment,
    finalSegmentLabel,
    initialSegmentLabel,
    isFinalized,
    progressBarType,
    secondarySegments,
    segments,
    status,
    styleFinalSegment,
  } = props

  return (
    <div className='body-grid status-bar-container'>
      {segments.map((segment, index) => {
        const includesSegment = adjustedSegments.includes(segment)
        const firstSegmentLabel = index === 0 ? initialSegmentLabel : segment

        return index + 1 === segments.length ? (
          <FinalSegment
            adjustedSegments={adjustedSegments}
            finalSegment={finalSegment}
            finalSegmentLabel={finalSegmentLabel}
            isFinalized={isFinalized}
            styleFinalSegment={styleFinalSegment}
            index={index}
            key={index}
          />
        ) : (
          <React.Fragment key={index}>
            <div className='segment-container' key={index}>
              <div className='segment'>
                <div
                  className={`segment-interior ${
                    adjustedSegments.length > index ? '' : 'inverse'
                  }`}
                >
                  <p
                    className={`segment-number ${
                      adjustedSegments.length > index ? '' : 'inverse'
                    }`}
                  >
                    {index + 1}
                  </p>
                </div>
              </div>
              <p
                className={`segment-label ${
                  includesSegment ? 'completed' : ''
                }`}
              >
                {includesSegment ? firstSegmentLabel : secondarySegments[index]}
              </p>
            </div>
            <div className='segment-bar'>
              <div
                className={
                  adjustedSegments.length > index + 1
                    ? `segment-bar-progress-${index + 1}`
                    : ''
                }
              ></div>
            </div>
          </React.Fragment>
        )
      })}
      {/*  Exported is a special segment  */}
      {progressBarType === 'invoice' && (
        <React.Fragment>
          <div className='segment-bar'>
            <div
              className={status === 'Exported' ? 'segment-bar-progress-4' : ''}
            ></div>
          </div>
          <div className='segment-container'>
            <div
              className={`segment ${status === 'Exported' ? 'exported' : ''}`}
            >
              <div
                className={`segment-interior ${
                  status === 'Exported' ? 'exported' : 'inverse'
                }`}
              >
                <p
                  className={`segment-number ${
                    status === 'Exported' ? 'exported' : 'inverse'
                  }`}
                >
                  <IonIcon
                    style={{ fontSize: 23, paddingTop: 2 }}
                    icon={cloudDownloadOutline}
                  />
                </p>
              </div>
            </div>
            <p
              className={`segment-label ${
                status === 'Exported' ? 'completed' : ''
              }`}
              style={{ marginLeft: status == 'Exported' ? -12 : -4 }}
            >
              {status === 'Exported' ? 'Exported' : 'Export'}
            </p>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ProgressBar
