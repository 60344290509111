import { useMemo } from 'react'
import { useRTColumnVirtualizer } from '../../../hooks/useRTColumnVirtualizer'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { parseCSSVarId } from '../../../utils/style.utils'
import { parseFromValuesOrFunc } from '../../../utils/utils'
import { RTTableBody, MemoRTTableBody } from '../../body/RTTableBody'
import { RTTableFooter } from '../../footer/RTTableFooter'
import { RTTableHead } from '../../head/RTTableHead'

import './styles.scss'
import { useRTRows } from '../../../hooks/useRTRows'

export interface IRTTableProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
  style?: React.CSSProperties
}

const RTTable = <TData extends IReusableTableRowData>({
  table,
  ...rest
}: IRTTableProps<TData>) => {
  const {
    getFlatHeaders,
    getState,
    options: {
      columns,
      enableTableFooter,
      enableTableHead,
      layoutMode,
      memoMode,
      renderCaption,
    },
  } = table
  const { columnSizing, columnSizingInfo, columnVisibility } = getState()

  const rows = useRTRows(table)

  const Caption = parseFromValuesOrFunc(renderCaption, { table })

  const columnSizeVars = useMemo(() => {
    const headers = getFlatHeaders()
    const colSizes: { [key: string]: number } = {}
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      const colSize = header.getSize()
      colSizes[`--header-${parseCSSVarId(header.id)}-size`] = colSize
      colSizes[`--col-${parseCSSVarId(header.column.id)}-size`] = colSize
    }
    return colSizes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, columnSizing, columnSizingInfo, columnVisibility])

  const columnVirtualizer = useRTColumnVirtualizer(table)

  const commonTableGroupProps = {
    columnVirtualizer,
    table,
  }

  return (
    <table
      {...rest}
      style={{
        ...columnSizeVars,
        ...rest?.style,
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        overflow: rows.length ? undefined : 'hidden',
      }}
      className='RTTable__container'
    >
      {!!Caption && <caption>{Caption}</caption>}
      {enableTableHead && <RTTableHead {...commonTableGroupProps} />}
      {memoMode === 'table-body' || columnSizingInfo.isResizingColumn ? (
        <MemoRTTableBody {...commonTableGroupProps} />
      ) : (
        <RTTableBody {...commonTableGroupProps} />
      )}
      {enableTableFooter && <RTTableFooter {...commonTableGroupProps} />}
    </table>
  )
}

export default RTTable
