import { produce } from 'immer'

const getIndexOfSmallestNumber = (documentsPage, { from }) => {
  let findIndex = 0

  documentsPage.forEach(([minPage], index) => {
    if (minPage <= from) {
      findIndex = index
    }
  })

  return findIndex
}

const handleSplitPage = (documentsPage, { from, to }) => {
  const index = getIndexOfSmallestNumber(documentsPage, { from, to })

  const result = produce(documentsPage, draft => {
    draft[index][1] = from - 1
    draft.push([from, to])

    if (to - documentsPage[index][1] !== 0) {
      draft.push([to + 1, documentsPage[index][1]])
    }

    draft.forEach(([min, max], index) => {
      if (min > max) {
        delete draft[index]
      }
    })
  })
  const resultWithoutNull = result.filter(pages => pages)

  for (let i = 0; i < resultWithoutNull.length - 1; i++) {
    for (let j = i + 1; j < resultWithoutNull.length; j++) {
      if (
        resultWithoutNull[i][0] > resultWithoutNull[j][0] &&
        resultWithoutNull[i][1] > resultWithoutNull[j][1]
      ) {
        const tmp = resultWithoutNull[i]
        resultWithoutNull[i] = resultWithoutNull[j]
        resultWithoutNull[j] = tmp
      }
    }
  }

  return resultWithoutNull
}

export default handleSplitPage
