import React, { useCallback } from 'react'
import ActionsButton from '../ActionsButton'
import {
  IonCard,
  IonCardContent,
  IonImg,
  IonIcon,
  IonLabel,
  IonInput,
} from '@ionic/react'
import { documentTextOutline } from 'ionicons/icons'
// import { useTextWidth } from '@imagemarker/use-text-width';
import clsx from 'clsx'

import './DocumentPreview.scss'
// import _ from 'lodash';

const DocumentPreview = ({
  document,
  editable,
  viewable,
  customView,
  onEdit,
  onDelete,
  onPreview,
  onTextChange,
  deletable = true,
  onSubmit,
  tooltipSubmitProps,
  submitButtonProps,
  customViewClassName,
  className,
}) => {
  const showPdfmessage =
    document?.fileType === 'pdf' ||
    document?.type === 'pdf' ||
    document?.fileExtension === 'pdf' ||
    document?.type === 'application/pdf'
  const _editable = editable && !showPdfmessage && document?.source != 'storage'
  const _nameEditable = editable && document?.source != 'storage'
  const name =
    document.displayName == undefined ? document.name : document.displayName
  // const textWidth = useTextWidth({ text: name, font: '15px Roboto, "Helvetica Neue", sans-serif' }) + 40;

  const _onEdit = () => {
    onEdit && onEdit(document)
  }
  const _onDelete = () => {
    onDelete && onDelete(document)
  }

  const _onTextChange = value => {
    onTextChange && onTextChange(document, value)
  }

  const _onPreview = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onPreview && onPreview(document)
    },
    [document, onPreview],
  )

  // const _onBlur = () => {
  //   setWidth(160)
  // }

  // const _onFocus = () => {
  //   let _width = textWidth < 160 ? 160 : textWidth
  //   _width = _width > 250 ? 250 : _width
  //   setWidth(_width)
  // }

  return (
    <IonCard className={clsx('DocumentReview__container', className)}>
      {customView ? (
        <div
          className={clsx(
            'DocumentReview__customViewContainer',
            customViewClassName,
          )}
        >
          <div className='body-grid'>
            {!showPdfmessage ? (
              <IonImg
                onClick={_onPreview}
                className='DocumentReview__customViewImage'
                src={document.image}
              />
            ) : (
              <span onClick={_onPreview}>
                <IonIcon
                  className='DocumentReview__customViewPDF'
                  icon={documentTextOutline}
                />
              </span>
            )}
            {_nameEditable ? (
              <div style={{ minWidth: '80%' }}>
                <IonInput
                  className='pdf-input'
                  multiple={true}
                  value={
                    document.displayName == undefined
                      ? document.name
                      : document.displayName
                  }
                  onIonChange={e => _onTextChange(e.detail.value)}
                />
              </div>
            ) : (
              !!name && (
                <IonLabel
                  style={{
                    width: '76%',
                    textAlign: 'left',
                    fontSize: 16,
                    paddingTop: 4,
                    marginBottom: 2,
                    marginLeft: 4,
                    overflow: 'hidden',
                  }}
                >
                  {name}
                </IonLabel>
              )
            )}
          </div>
          <div style={{ textAlign: 'left', marginLeft: 2 }}>
            <ActionsButton
              viewable={viewable}
              editable={_editable}
              showPdfmessage={showPdfmessage}
              deletable={deletable}
              _onEdit={_onEdit}
              _onPreview={_onPreview}
              _onDelete={_onDelete}
              onSubmit={onSubmit}
              tooltipSubmitProps={tooltipSubmitProps}
              submitButtonProps={submitButtonProps}
            />
          </div>
        </div>
      ) : (
        <IonCardContent className='DocumentReview__document'>
          <ActionsButton
            editable={_editable}
            showPdfmessage={showPdfmessage}
            _onEdit={_onEdit}
            _onPreview={_onPreview}
            _onDelete={_onDelete}
            deletable={deletable}
            className='DocumentReview__actionButtons'
          />
          <div onClick={_onPreview}>
            {!showPdfmessage ? (
              <IonImg
                src={document.image}
                style={{
                  height: '116px',
                  width: '100%',
                  resizeMode: 'contain',
                  margin: 'auto',
                  marginBottom: 4,
                }}
              />
            ) : (
              // <span>
              //   <IonIcon
              //     style={{ width: 80, height: 80 }}
              //     icon={documentTextOutline}
              //   />
              // </span>
              <embed src={document.image} />
            )}
          </div>
          {_nameEditable ? (
            <IonInput
              multiple={true}
              // onFocus={_onFocus}
              // onBlur={_onBlur}
              value={
                document.displayName == undefined
                  ? document.name
                  : document.displayName
              }
              style={{ backgroundColor: '#dbdbdb' }}
              onIonChange={e => _onTextChange(e.detail.value)}
            />
          ) : (
            !!name && (
              <IonLabel
                style={{ margin: 'auto', height: 30, textAlign: 'center' }}
              >
                {name}
              </IonLabel>
            )
          )}
        </IonCardContent>
      )}
    </IonCard>
  )
}

DocumentPreview.defaultProps = {
  customViewWidth: 250,
}

export default DocumentPreview
