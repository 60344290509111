const getInitName = (fullName, defaultValue = 'Unknown') => {
  if (typeof fullName !== 'string') {
    return defaultValue
  }

  // eslint-disable-next-line no-useless-escape
  const strName = fullName.replace(/\"/g, '')
  const allNames = strName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }

    return acc
  }, '')

  return initials
}

export default getInitName
