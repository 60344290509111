import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ConcordFormDropdownV2,
  CommonDialogV2,
  ConcordFormStructure,
} from '~/components/shared'

import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import _ from 'lodash'
import {
  COMPANY_PAY_FREQUENCY_OPTIONS,
  REGULAR_OPTION,
  OFFCYCLE_OPTION,
  WEEKLY_OPTION,
  BIWEEKLY_OPTION,
  SEMIMONTHLY,
  MONTHLY,
} from '~/utils/constants'
import { sessionService } from 'redux-react-session'
import { fetchCompanyPayrolls } from '~/redux/reducers/data/companyPayrolls'
import moment from 'moment'

import './DialogCreateEditPayroll.scss'
import { useUpdateEffect } from 'react-use'
import { apiClient } from '~/api/ApiClient'
import { EFieldType } from '~/types/enums/ECommonEnum'

function DialogCreateEditPayroll(props) {
  const { afterCreate, formData, afterUpdate, ...dialogProps } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPayFrequencyOption, setIsLoadingPayFrequencyOption] =
    useState(false)

  const dispatch = useDispatch()
  const currentCompany = useSelector(selectMyCurrentCompany)

  const [defaultValues, setDefaultValues] = useState({
    periodStart: null,
    periodEnd: null,
    payrollType: REGULAR_OPTION.value
  })

  const schema = Yup.object({
    periodStart: Yup.lazy((val) => {
      if (typeof val === 'string') {
        return Yup.string()
      }
      return Yup.date().required('This field is required').nullable()
    }),
    periodEnd: Yup.lazy((val) => {
      if (typeof val === 'string') {
        return Yup.string()
      }
      return Yup.date().required('This field is required').nullable()
    })
  })

  const isUpdating = Boolean(defaultValues.id)

  const payFrequency = useMemo(
    () => currentCompany.payFrequency,
    [currentCompany.payFrequency],
  )

  const isDisabledRegularOption = useMemo(() => {
    return !payFrequency && !isUpdating
  }, [isUpdating, payFrequency])

  const onChangePeriodStartDate = useCallback(
    selectedDate => {
      if (defaultValues.payrollType === OFFCYCLE_OPTION.value) {
        setDefaultValues(prev => ({
          ...prev,
          periodStart: selectedDate,
          periodEnd: null
        }))
        return
      }
      let endDate = null

      switch (payFrequency) {
        case WEEKLY_OPTION.value: {
          endDate = moment(selectedDate).add({ days: 6 }).toISOString()
          break
        }

        case BIWEEKLY_OPTION.value: {
          endDate = moment(selectedDate).add({ days: 13 }).toISOString()
          break
        }

        case SEMIMONTHLY.value: {
          const day = moment(selectedDate).format('DD')
          if (day === '01') {
            endDate = moment(selectedDate).add({ days: 15 }).toISOString()
          } else {
            endDate = moment(selectedDate).endOf('month').toISOString()
          }
          break
        }

        case MONTHLY.value: {
          const endDateOfMonth = moment(selectedDate)
            .endOf('month')
            .format('DD')
          endDate = moment(selectedDate)
            .add({
              days: parseInt(endDateOfMonth) - 1,
            })
            .toISOString()
          break
        }

        default: {
          endDate = null
        }
      }
      setDefaultValues(prev => ({
        ...prev,
        periodStart: selectedDate,
        periodEnd: endDate
      }))
    },
    [defaultValues.payrollType, payFrequency],
  )

  const onChangePeriodEndDate = useCallback(
    selectedDate => {
      setDefaultValues(prev => ({
        ...prev,
        periodEnd: selectedDate
      }))
    },
    [],
  )

  const fields = useMemo(() => [
    {
      name: 'periodStart',
      label: 'Period Start',
      type: EFieldType.date,
      size: '6',
      onChange: onChangePeriodStartDate,
    },
    {
      name: 'periodEnd',
      label: 'Period End',
      type: EFieldType.date,
      size: '6',
      onChange: onChangePeriodEndDate,
    },
    {
      name: 'companyPayFrequency',
      label: 'Company Pay Frequency',
      type: EFieldType.singleSelect,
      size: '12',
      options: COMPANY_PAY_FREQUENCY_OPTIONS,
      render() {
        return (
          <ConcordFormDropdownV2
            options={COMPANY_PAY_FREQUENCY_OPTIONS}
            label='Company Pay Frequency'
            value={payFrequency}
            onChange={onChangePayFrequency}
            isLoading={isLoadingPayFrequencyOption}
          />
        )
      }
    },
    {
      name: 'payrollType',
      label: 'Payroll type',
      type: EFieldType.radio,
      options: [
        {
          ...REGULAR_OPTION,
          disabled: isDisabledRegularOption,
          tooltipProps: {
            content: 'Set Pay Frequency before selecting this option',
            placement: 'bottom',
            allowToShow: isDisabledRegularOption,
          },
        },
        OFFCYCLE_OPTION,
      ]
    }
  ], [
    isDisabledRegularOption,
    isLoadingPayFrequencyOption,
    payFrequency,
    onChangePayFrequency,
    onChangePeriodEndDate,
    onChangePeriodStartDate,
  ])


  const updatePayroll = useCallback(
    async formValues => {
      const { periodStart, periodEnd, payrollType } = formValues
      const payload = {
        periodStart,
        periodEnd,
        payrollType,
      }
      const response = await apiClient.payrolls.update(formValues.id, payload)
      if (response.id) {
        dispatch(fetchCompanyPayrolls())
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      } else {
        toast.error(_.get(response, 'errors[0]') || toastMessages.createError)
      }
    },
    [afterUpdate, dispatch],
  )

  const createPayroll = useCallback(
    async formValues => {
      const { periodStart, periodEnd, payrollType } = formValues
      var someDate = new Date(periodEnd)
      var numberOfDaysToAdd = 4
      var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd)
      const payload = {
        periodStart,
        periodEnd,
        payrollType,
        companyId: currentCompany.id,
        payday: new Date(result),
      }
      const response = await apiClient.payrolls.create(payload)
      if (response.id) {
        dispatch(fetchCompanyPayrolls())
        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        toast.error(_.get(response, 'errors[0]') || toastMessages.createError)
      }
    },
    [afterCreate, currentCompany.id, dispatch],
  )

  const handleSubmitForm = useCallback(async formValues => {
    setIsLoading(true)
    try {
      const payload = {
        ...formValues,
      }
      payload.companyId = currentCompany.id
      if (isUpdating) {
        await updatePayroll(payload)
      } else {
        await createPayroll(payload)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }, [createPayroll, currentCompany.id, isUpdating, updatePayroll])

  const onChangePayFrequency = useCallback(
    async (event, { value }) => {
      setIsLoadingPayFrequencyOption(true)
      try {
        const { company } = await apiClient.companies.update(currentCompany.id, {
          company: {
            payFrequency: value,
          }
        })
        const sessionData = await sessionService.loadUser()
        const newData = { ...sessionData, company, currentFleet: company }
        await sessionService.saveUser(newData)
        setDefaultValues(prev => ({
          ...prev,
          periodStart: null,
          periodEnd: null
        }))
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setIsLoadingPayFrequencyOption(false)
      }
    },
    [currentCompany.id],
  )

  useEffect(() => {
    let payrollType = OFFCYCLE_OPTION.value
    if (payFrequency) {
      payrollType = REGULAR_OPTION.value
    }
    if (!props.isOpen) {
      setDefaultValues((prev) => ({
        ...prev,
        payrollType
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, payFrequency])

  useUpdateEffect(() => {
    if (_.size(formData) > 0) {
      setDefaultValues(formData)
    }
  }, [formData])

  return (
    <CommonDialogV2
      {...dialogProps}
      title="Payroll"
      loading={isLoading}
      isHiddenOkButton
    >
      <ConcordFormStructure
        fields={fields}
        isHiddenSearch
        isHiddenCancelButton
        submitText={isUpdating ? 'Update' : 'Create'}
        isLoading={isLoading}
        onSubmit={handleSubmitForm}
        schema={schema}
        formData={defaultValues}
        onChange={(newVal) => {
          setDefaultValues(newVal)
        }}
      />
    </CommonDialogV2>
  )
}

DialogCreateEditPayroll.defaultProps = {
  formData: {},
}

export default DialogCreateEditPayroll
