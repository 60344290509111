import { createSlice } from '@reduxjs/toolkit'

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {},
  reducers: {
    index: (state, action) => {
      const newState = {}
      action.payload.invoices.forEach(invoice => {
        newState[invoice.id] = invoice
      })

      return newState
    },

    updateInvoices: (state, action) => {
      action.payload.forEach(invoice => {
        if (invoice.deletedAt) {
          delete state[invoice.id]
        } else {
          state[invoice.id] = invoice
        }
      })

      return state
    },

    updateInvoice: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
  },
})

export default invoicesSlice
