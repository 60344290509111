import posthog from 'posthog-js'
import { connect } from 'react-redux'
import { postHogEnabled } from './helpers'
let postHogIdentify = false

const PostHog = ({ user }) => {
  if (!postHogIdentify && postHogEnabled()) {
    postHogIdentify = true
    posthog.identify(user.id, {
      email: user.email,
      id: user.id,
    })
  }

  return null
}
export default connect(
  state => ({
    user: state.session.user,
  }),
  null,
)(PostHog)
