import React, { PropsWithChildren, useEffect, useRef } from 'react'
import {
  GoogleMap as ReactGoogleMap,
  WithGoogleMapProps,
  withGoogleMap,
  TrafficLayer,
} from 'react-google-maps'
import { When } from 'react-if'

import { compose, withProps } from 'recompose'

import type { IGoogleMapProps } from './type'

import './styles.scss'

const GoogleMap = compose<
  WithGoogleMapProps & PropsWithChildren<IGoogleMapProps>,
  unknown
>(
  withProps({
    googleMapURL: process.env.REACT_APP_G_API_URL,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className='MapView__containerElement' />,
    mapElement: (
      <div
        className='mapElementContainer'
        style={{ height: 'calc(100% - 10px)' }}
      />
    ),
  }),
  withGoogleMap,
)(props => {
  const {
    bounding = [],
    isShowTraffic,
    children,
    zoom,
    defaultCenter = {
      lat: -3.745,
      lng: -38.523,
    },
    onClick,
    options,
  } = props

  const mapRef = useRef<ReactGoogleMap>()

  useEffect(() => {
    if (bounding.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds()
      bounding.forEach(bound => {
        bounds.extend(bound)
      })
      mapRef.current.fitBounds(bounds)
    }
  }, [bounding, defaultCenter])

  return (
    <ReactGoogleMap
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={mapRef as any}
      defaultCenter={defaultCenter}
      zoom={zoom || 1}
      options={{
        fullscreenControl: false,
        streetViewControl: true,
        scaleControl: false,
        mapTypeControl: true,
        panControl: false,
        zoomControl: true,
        rotateControl: false,
        minZoom: 1,
        maxZoom: 20,
        ...options,
        // zoom: zoom || 1,
      }}
      center={defaultCenter}
      onClick={onClick}
    >
      {children}
      <When condition={isShowTraffic}>
        <TrafficLayer />
      </When>
    </ReactGoogleMap>
  )
})

export default React.memo<
  Omit<WithGoogleMapProps, 'containerElement' | 'mapElement'> &
    PropsWithChildren<IGoogleMapProps>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(GoogleMap as any)
