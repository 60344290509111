var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
import { alpha, Box, Button, IconButton, makeStyles, } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import { Close, Create, CenterFocusStrong } from '@material-ui/icons';
import { OutlinedTextField } from 'common/display/OutlinedTextField/OutlinedTextField';
import { TruncatableTypography } from 'common/display/TruncatableTypography/TruncatableTypography';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import clsx from 'clsx';
import { MimeType } from 'common/types/DocumentLabelerTypes';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { DocumentLabelerState } from 'documentLabeler/state/DocumentLabelerState';
import { DELAY_TIME_TO_CALL_SAVE_CALLBACK } from 'utils/constants';
var SAVE = 'Save';
var CANCEL = 'Cancel';
var CLOSE = 'Close';
var EMPTY_VALUE = 'No Value Specified';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        display: 'flex',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        cursor: 'pointer',
        justifyContent: 'space-between',
        height: theme.spacing(6),
    },
    TopRowContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    FieldIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        paddingLeft: 0,
    },
    FieldInfoContainer: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    FieldActionContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    FieldLabelTypography: {
        color: theme.palette.text.disabled,
    },
    FieldValueTypography: {
        color: theme.palette.text.secondary,
        '&.Disabled': {
            color: theme.palette.text.disabled,
        },
    },
    Button: {
        textTransform: 'none',
    },
    IconButton: {
        padding: 0,
        marginRight: theme.spacing(1),
        '&.Hide': {
            opacity: 0,
            pointerEvents: 'none',
        },
    },
    ViewingField: {
        color: blue[500],
    },
    CancelButton: {
        color: red[300],
    },
}); });
/**
 * Component responsible for rendering a field in the fields panel.
 * Takes in information about the field and its associated display color
 * and handles selecting the field and editing the field value.
 * @param Props
 */
export var FieldsPanelDisplayRow = function (_a) {
    var _b;
    var _c, _d;
    var id = _a.id, type = _a.type, name = _a.name, value = _a.value, color = _a.color, _e = _a.hasValue, hasValue = _e === void 0 ? true : _e;
    var classes = useStyles();
    var _f = useBBConfiguration(), fieldDisplayNameFormatter = _f.fieldDisplayNameFormatter, displayOnly = _f.displayOnly, onSaveCallback = _f.onSaveCallback;
    var _g = useDocumentLabeler(), state = _g.state, dispatch = _g.dispatch;
    var _h = useState(false), editingText = _h[0], setEditingText = _h[1];
    var _j = useState(value), localValue = _j[0], setLocalValue = _j[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _k = useState(null), timeoutId = _k[0], setTimeoutId = _k[1];
    var fieldIsActive = ((_c = state.localState.activeField) === null || _c === void 0 ? void 0 : _c.id) === id;
    var fieldIsViewing = ((_d = state.localState.fieldViewing) === null || _d === void 0 ? void 0 : _d.id) === id;
    var displayFieldName = fieldDisplayNameFormatter
        ? fieldDisplayNameFormatter(name)
        : name;
    var handleFieldClick = function () {
        if (fieldIsActive) {
            dispatch({
                type: 'setActiveField',
                payload: undefined,
            });
        }
        else {
            dispatch({
                type: 'setActiveField',
                payload: {
                    id: id,
                    type: type,
                },
            });
        }
    };
    var handleClearLabels = function () {
        return dispatch({
            type: 'removeAllBlocksFromField',
            payload: {
                fieldId: id,
                fieldType: type,
            },
        });
    };
    var handleSaveValue = function () {
        var result = DocumentLabelerReducerUtils.updateTextOverride(state, {
            fieldId: id,
            textOverride: localValue,
        });
        dispatch({
            type: 'setState',
            payload: result,
        });
        setEditingText(false);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        var newTimeoutId = setTimeout(function () {
            onSaveCallback(DocumentLabelerState.convertInternalStateToOutputData(result));
            clearTimeout(timeoutId);
        }, DELAY_TIME_TO_CALL_SAVE_CALLBACK);
        setTimeoutId(newTimeoutId);
    };
    var handleFocusField = useCallback(function (event) {
        event.stopPropagation();
        dispatch({
            type: 'setViewingField',
            payload: {
                id: id,
                type: type,
            },
        });
        if (!fieldIsViewing) {
            setLocalValue(value);
            setEditingText(true);
        }
    }, [fieldIsViewing, id, type]);
    var handleCloseEditField = useCallback(function (event) {
        event.stopPropagation();
        setEditingText(false);
        dispatch({
            type: 'setViewingField',
            payload: undefined,
        });
    }, []);
    var handleCancelEditField = useCallback(function (event) {
        event.stopPropagation();
        setEditingText(false);
        setLocalValue(value);
        dispatch({
            type: 'setViewingField',
            payload: undefined,
        });
    }, [value]);
    var renderButtonWhenEditing = useCallback(function () {
        if (localValue === value) {
            return (_jsx(Button, __assign({ variant: "text", color: "secondary", className: classes.Button, disableElevation: true, onClick: handleCloseEditField }, { children: CLOSE })));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "text", color: "primary", className: classes.Button, disableElevation: true, onClick: function (event) {
                        event.stopPropagation();
                        handleSaveValue();
                        dispatch({
                            type: 'setViewingField',
                            payload: undefined,
                        });
                    }, "data-testid": "save-field-value-btn" }, { children: SAVE })), _jsx(Button, __assign({ variant: "text", color: "secondary", className: clsx(classes.Button, classes.CancelButton), disableElevation: true, onClick: handleCancelEditField }, { children: CANCEL }))] }));
    }, [localValue, value, handleCloseEditField, handleCancelEditField]);
    return (_jsxs(Box, __assign({ className: clsx(classes.Root, 'FieldsPanelDisplayRow__root'), onClick: handleFieldClick, style: {
            borderLeft: "4px solid ".concat(color),
            backgroundColor: fieldIsActive ? alpha(color, 0.1) : 'transparent',
        }, "data-testid": "field-".concat(name) }, { children: [_jsx(Box, __assign({ className: classes.FieldInfoContainer }, { children: editingText ? (_jsx("form", __assign({ onSubmit: function (event) {
                        event.stopPropagation();
                        handleSaveValue();
                        dispatch({
                            type: 'setViewingField',
                            payload: undefined,
                        });
                    } }, { children: _jsx(OutlinedTextField, { autoFocus: true, className: "FieldsPanelDisplayRow__textField", value: localValue, placeholder: EMPTY_VALUE, onChange: function (e) { return setLocalValue(e.target.value); }, inputProps: {
                            'data-testid': 'field-value-input',
                        } }) }))) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ className: classes.TopRowContainer }, { children: _jsx(TruncatableTypography, { value: displayFieldName, paragraph: false, typographyProps: {
                                    variant: 'caption',
                                    className: classes.FieldLabelTypography,
                                } }) })), _jsx(TruncatableTypography, { value: value, paragraph: false, typographyProps: {
                                variant: 'subtitle2',
                            } })] })) })), !displayOnly && (_jsx(_Fragment, { children: editingText ? (renderButtonWhenEditing()) : (_jsxs(Box, __assign({ className: clsx(classes.FieldActionContainer, 'FieldsPanelDisplayRow__fieldActionContainer') }, { children: [_jsx(IconButton, __assign({ className: clsx(classes.IconButton, {
                                Hide: type === FieldType.Table,
                            }), onClick: function (event) {
                                event.stopPropagation();
                                setLocalValue(value);
                                setEditingText(true);
                            }, "data-testid": "edit-field-icon" }, { children: _jsx(Create, { fontSize: "small" }) })), _jsx(IconButton, __assign({ className: clsx(classes.IconButton, {
                                Hide: !hasValue,
                            }), onClick: function (event) {
                                event.stopPropagation();
                                handleClearLabels();
                            }, "data-testid": "clear-field-label-icon" }, { children: _jsx(Close, { fontSize: "small" }) })), state.docInfo.mimeType !== MimeType.Pdf && (_jsx(IconButton, __assign({ className: clsx(classes.IconButton, {
                                Hide: !hasValue,
                            }), onClick: handleFocusField, "data-testid": "clear-field-label-icon" }, { children: _jsx(CenterFocusStrong, { className: clsx((_b = {},
                                    _b[classes.ViewingField] = fieldIsViewing,
                                    _b)), fontSize: "small" }) })))] }))) }))] })));
};
