import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'
import { IDialogSalesProposalForm } from './type'
import SalesProposalForm from './SalesProposalForm'

function DialogSalesProposalForm(props: IDialogSalesProposalForm) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Sales Proposal'
      isHiddenOkButton
      onClose={onClose}
    >
      <SalesProposalForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
export default DialogSalesProposalForm
