import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryDriversNew } from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { ConcordFormAddressInput, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IDialogCreateCheckWorkplaceProps, IWorkplaceData } from './type'
import type { IAddressInputOnChangeParams } from '~/components/shared/ConcordForm/AddressInput/type'
import { EFieldType, EYesNo } from '~/types/enums/ECommonEnum'
import areAddressesEqual from '~/utils/areAddressesEqual'
import { ITerminal } from '~/types/models/ITerminal'
import { EAddressTypeStr, EAddressableType } from '~/types/enums/EAddress'
import buildFullAddress from '~/utils/buildFullAddress'
import { parseErrorFromCheckResponse } from '~/utils/getErrorFromCheckResponse'

const useDialogCheckWorkplace = (props: IDialogCreateCheckWorkplaceProps) => {
  const { isOpen, terminal, onClose, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [formData, setFormData] = useState<IWorkplaceData>({
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    company: '',
  })

  const { stateOptions, isLoadingDriversNew, stringStatesData } =
    useQueryDriversNew()

  const { confirmation } = useConfirmationProvider()

  const isUpdating = useMemo(
    () => Boolean(terminal?.checkUid),
    [terminal?.checkUid],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        address: Yup.object({
          line1: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          line2: Yup.string().nullable(),
          city: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          state: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          postalCode: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          country: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
        }),
      }),
    [],
  )

  const formFields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Search address',
        name: 'searchAddress',
        isHidden: !isUpdatingAddress,
        size: 12,
        render({ label, setValue, clearErrors, setError }) {
          return (
            <ConcordFormAddressInput
              label={label}
              onChange={(
                event: unknown,
                { address, location }: IAddressInputOnChangeParams,
              ) => {
                const { city, country, postalCode, state, street } = address
                clearErrors()
                const hasStateInOptions = stringStatesData.includes(state)
                let stateValue = ''
                if (hasStateInOptions) {
                  stateValue = state
                } else {
                  setError('address.state', {
                    message: `${state} is not an option`,
                  })
                }

                setValue('address', {
                  line1: street,
                  city,
                  state: stateValue,
                  postalCode,
                  country,
                })
                setValue('location', location)
              }}
            />
          )
        },
      },
      {
        label: 'Line 1',
        name: 'address.line1',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'Line 2',
        name: 'address.line2',
        size: 6,
      },
      {
        label: 'City',
        name: 'address.city',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'State',
        name: 'address.state',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        type: EFieldType.singleSelect,
        options: stateOptions,
        isLoading: isLoadingDriversNew,
      },
      {
        label: 'Postal Code',
        name: 'address.postalCode',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'Country',
        name: 'address.country',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
    ],
    [isLoadingDriversNew, isUpdatingAddress, stateOptions, stringStatesData],
  )

  const getSetupData = useCallback(async () => {
    if (terminal) {
      setIsLoading(true)
      try {
        const response = await apiClient.check.setupTerminal({
          terminalId: terminal.id,
        })
        setFormData(response)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    }
  }, [terminal])

  const getCheckWorkplace = useCallback(async () => {
    if (terminal) {
      setIsLoading(true)
      try {
        const response = await apiClient.check.retrieveTerminal(
          terminal.checkUid,
        )
        setFormData(response)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    }
  }, [terminal])

  const updateTerminalAddress = useCallback(
    async (formData: IWorkplaceData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let responseAddress: any
      const isAddressChanged = !areAddressesEqual(formData.address, {
        ...terminal?.address,
        line1: terminal?.address?.street || '',
        postalCode: terminal?.address?.zip || '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
      if (isAddressChanged) {
        responseAddress = {
          street: formData.address.line1,
          city: formData.address.city,
          country: formData.address.country,
          state: formData.address.state,
          zip: formData.address.postalCode,
        }
        if ((terminal?.address?.addressTypes || []).length === 0) {
          responseAddress.addressTypes = [EAddressTypeStr.location]
        }
        await apiClient.addresses.update(
          terminal?.address?.id as number,
          responseAddress,
        )
      }
      return responseAddress
    },
    [terminal?.address],
  )

  const updateWorkplace = useCallback(
    async (formData: IWorkplaceData) => {
      // const responseAddress = await updateTerminalAddress(formData)
      const response = await apiClient.check.updateTerminal(
        terminal?.checkUid as string,
        {
          terminal: formData,
        },
      )
      if (response.id && terminal) {
        afterUpdate(terminal)
      }
    },
    [afterUpdate, terminal],
  )

  const createWorkplace = useCallback(
    async (formData: IWorkplaceData) => {
      const result = await confirmation({
        message: (
          <span>
            {"Workplace's"} address cannot be updated once its created. Are you
            sure the address:{' '}
            <span style={{ fontWeight: 600, fontStyle: 'italic' }}>
              {buildFullAddress(formData.address)}
            </span>{' '}
            is accurate?
          </span>
        ),
        header: 'Warning',
      })
      if (result === EYesNo.Yes) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let responseAddress: any = undefined
        if (terminal?.address?.id) {
          responseAddress = await updateTerminalAddress(formData)
        } else {
          responseAddress = await apiClient.addresses.create({
            street: formData.address.line1,
            city: formData.address.city,
            country: formData.address.country,
            state: formData.address.state,
            zip: formData.address.postalCode,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            addressTypes: [EAddressTypeStr.location] as any,
            addressableType: EAddressableType.terminal,
            addressableId: terminal?.id as number,
          })
        }
        const response = await apiClient.check.createTerminal(
          {
            terminal: formData,
          },
          {
            terminalId: terminal?.id as number,
          },
        )
        if (response.id && terminal) {
          const newTerminal: ITerminal = {
            ...terminal,
            checkUid: response.id,
          }
          if (responseAddress) {
            newTerminal.address = responseAddress
          }
          afterCreate(newTerminal)
        } else {
          const error = parseErrorFromCheckResponse(response)
          setBackendError(error)
        }
      }
    },
    [afterCreate, confirmation, terminal, updateTerminalAddress],
  )

  const onSubmitForm = useCallback(
    async (formData: IWorkplaceData) => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await updateWorkplace(formData)
        } else {
          await createWorkplace(formData)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.createError)
      } finally {
        setIsLoading(false)
      }
    },
    [createWorkplace, isUpdating, updateWorkplace],
  )

  const onToggleEdit = useCallback(() => {
    setIsUpdatingAddress(prev => !prev)
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (terminal?.checkUid) {
        getCheckWorkplace()
      } else {
        getSetupData()
      }
    }
  }, [getCheckWorkplace, getSetupData, isOpen, terminal?.checkUid])

  // useEffect(() => {
  //   if (isOpen && !terminal?.address) {
  //     setIsUpdatingAddress(true)
  //   } else {
  //     setIsUpdatingAddress(false)
  //     setFormData({
  //       address: {
  //         line1: '',
  //         line2: '',
  //         city: '',
  //         state: '',
  //         postalCode: '',
  //         country: '',
  //       },
  //       company: '',
  //     })
  //     setBackendError('')
  //   }
  // }, [isOpen, terminal?.address])

  return {
    isOpen,
    onClose,
    formFields,
    formData,
    schema,
    isLoading,
    isUpdating,
    isUpdatingAddress,
    terminal,
    onSubmitForm,
    onToggleEdit,
    backendError,
  }
}

export default useDialogCheckWorkplace
