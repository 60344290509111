import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'
import buyerSellerSlice from '~/redux/reducers/data/buyerSellers'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt'
import buyerSellersUiSlice from '~/redux/reducers/ui/buyerSellers'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

export const getBuyerSellers = createAsyncThunk(
  'buyerSellers/fetchBuyerSellers',
  async (_params, { dispatch }) => {
    dispatch(buyerSellersUiSlice.actions.updateUi({ loading: true }))
    try {
      const response = await apiClient.buyerSellers.get()
      const unix = Math.round(+new Date() / 1000)

      dispatch(
        lastFetchedAtSlice.actions.updateLastFetchedAt({
          model: 'buyerSellers',
          lastFetchedAt: unix,
        }),
      )

      return response
    } catch (error) {
      console.log('error', error)
      return []
    } finally {
      dispatch(buyerSellersUiSlice.actions.updateUi({ loading: false }))
    }
  },
)

export const reAddBuyerSellerRelationship = createAsyncThunk(
  'buyerSellers/reAddBuyerSeller',
  async (params, { dispatch, getState }) => {
    const { payload } = params
    dispatch(buyerSellersUiSlice.actions.updateUi({ loading: true }))
    try {
      const buyerSellers = columnHashToColumnsArray(
        getState().data.buyerSellers,
      )

      const index = buyerSellers.findIndex(
        ({ buyer, seller }) =>
          buyer.id === payload.buyerId && seller.id === payload.sellerId,
      )

      if (index !== -1) {
        if (buyerSellers[index].relationship !== payload.relationship) {
          const deleteResponse = await apiClient.buyerSellers.delete(
            buyerSellers[index].id,
          )

          if (deleteResponse.id) {
            dispatch(
              buyerSellerSlice.actions.updateBuyerSeller({
                deletedAt: deleteResponse.updatedAt,
                id: deleteResponse.id,
              }),
            )
          }

          createBuyerSeller(payload)
        }
      } else {
        createBuyerSeller(payload)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(buyerSellersUiSlice.actions.updateUi({ loading: false }))
    }
  },
)

export const updateBuyerSeller = payload => dispatch => {
  dispatch(buyerSellerSlice.actions.updateBuyerSeller(payload))
}

export const deleteBuyerSeller = id => async dispatch => {
  dispatch(buyerSellerSlice.actions.deleteBuyerSeller(id))
}

const createBuyerSeller = payload => async dispatch => {
  const createResponse = await apiClient.buyerSellers.create(payload)
  if (createResponse.id) {
    dispatch(buyerSellerSlice.actions.updateBuyerSeller(createResponse))
  }
}
