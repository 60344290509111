import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectBuyerTerminals } from '~/redux/selectors'
import { Chart } from 'react-google-charts'
import { formatBarGraphCard } from './helpers'
import '~/styles/dashboard.scss'

const BarGraphCard = ({ options }) => {
  const dashboardLoadData = useSelector(state => state.dashboard.load)
  const buyerTerminals = useSelector(selectBuyerTerminals)

  const [barGraphData, setBarGraphData] = useState([])

  useEffect(() => {
    if (dashboardLoadData && buyerTerminals) {
      const formattedData = formatBarGraphCard(
        dashboardLoadData[options.dashboardKey],
        buyerTerminals,
      )

      setBarGraphData(formattedData)
    }
  }, [dashboardLoadData, options, buyerTerminals])

  return (
    <div className='GraphCard__container'>
      <div className='graph-card'>
        <div style={{ height: 8 }}></div>
        <Chart
          chartType='ColumnChart'
          loader={<div>Loading Chart</div>}
          data={barGraphData}
          options={options}
          chartEvents={[
            {
              eventName: 'ready',
              callback: () => {},
            },
            {
              eventName: 'select',
              callback: () => {},
            },
          ]}
        />
      </div>
    </div>
  )
}

export default BarGraphCard
