import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const PhoneIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        d='M1 5V1h6v4L4.5 7.5l4 4L11 9h4v6h-4C5.477 15 1 10.523 1 5Z'
      />
    </svg>
  )
}
export default PhoneIcon
