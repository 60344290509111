import { useEvent } from 'react-use'
import { IUseKeyStrokesKey, EKeyStroke } from './type'

const useKeyStrokes = (keystrokes: IUseKeyStrokesKey[]) => {
  useEvent('keydown', event => {
    event.stopPropagation()
    const eventKeys = {
      [EKeyStroke.shift]: event.shiftKey,
      [EKeyStroke.meta]: event.metaKey,
      [EKeyStroke.ctrl]: event.ctrlKey,
      [EKeyStroke.alt]: event.altKey,
    }
    keystrokes.forEach(({ keyCode, action, keystroke }) => {
      if (keystroke === EKeyStroke.metaAndCtrl) {
        if ((eventKeys.meta || eventKeys.ctrl) && event.keyCode === keyCode) {
          action()
        }
      } else {
        if (event.keyCode === keyCode && eventKeys[keystroke]) {
          action()
        }
      }
    })
  })
}

export default useKeyStrokes
