import { createSelector } from 'reselect'
import { ADDRESS_TYPE_OPTIONS } from '~/utils/constants'

export const selectAddresses = state => state.data.addresses
export const selectAddressesUiState = state => state.ui.addresses

export const selectAddressesData = createSelector(selectAddresses, state => {
  const data = Object.values(state)
  const mapData = data.map(item => {
    const addressTypes = []
    item.addressTypes?.forEach(type => {
      const findType = ADDRESS_TYPE_OPTIONS.find(
        ({ numValue }) => type === numValue,
      )
      if (findType) {
        addressTypes.push(findType.value)
      }
    })

    return { ...item, addressTypes }
  })

  return mapData
})

export const selectAddressLoadingTable = createSelector(
  selectAddressesUiState,
  state => state.loadingTable,
)
