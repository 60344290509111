import { useWindowSize } from 'react-use'

import CompanyTableEmails from '~/containers/CompanySubTabs/Email/TableEmails'

function CompanyEmailTab() {
  const windowSize = useWindowSize()

  return <CompanyTableEmails tableHeight={windowSize.height - 300} />
}
export default CompanyEmailTab
