import { CommonDialogV2, TruckFormData } from '~/components/shared'

import type { IDialogTruckFormDataProps } from './type'

function DialogTruckFormData(props: IDialogTruckFormDataProps) {
  const { onClose, isOpen, ...truckForm } = props

  return (
    <CommonDialogV2
      isOpen={isOpen}
      isHiddenOkButton
      onClose={onClose}
      title='Truck'
    >
      <TruckFormData {...truckForm} />
    </CommonDialogV2>
  )
}

export default DialogTruckFormData
