import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryCheckCompanyDetails,
  useQueryEnrollmentProfile,
  useQueryCheckAppliedForIds,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import _ from 'lodash'
import {
  selectCompanyTerminalsWithCheck,
  selectMyCurrentCompany,
} from '~/redux/selectors'
import camelKeyToNormalString from '~/utils/camelKeyToNormalString'
import {
  CHECK_COMPANY_BUSINESS_TYPE_OPTIONS,
  CHECK_COMPANY_INDUSTRY_TYPE_OPTIONS,
  CHECK_COMPANY_PAY_FREQUENCY_OPTIONS,
  CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
  CHECK_STATUS_COLOR,
  isStartWithEmp,
} from '~/utils/constants'

import { ICompany } from '~/types/models/ICompany'
import { ICommonOption } from '~/types/models/ICommonModel'
import { ICheckCompanyInfoSectionProps } from './type'
import { ITerminal } from '~/types/models/ITerminal'
import { selectWorkersList } from '~/redux/selectors/selectWorkers'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import buildFullAddress from '~/utils/buildFullAddress'
import { ICreateCheckCompanyFormValues } from '../DialogCreateCheckCompany'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { Unless, When } from 'react-if'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { IonSpinner } from '@ionic/react'
import clsx from 'clsx'
import humps from 'humps'
import { Badge } from 'react-bootstrap'
import { ECheckStatus } from '~/types/enums/ECheck'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'

const useCheckCompanyInfoSection = (props: ICheckCompanyInfoSectionProps) => {
  const { onSelectStep } = props

  const { getDateTimeFormatFull } = useDateTimeFormat()

  const [isOpenCompanyDialog, setIsOpenCompanyDialog] = useState(false)
  const [error, setError] = useState<React.ReactNode>('')
  const [requestedEmbeddedError, setRequestedEmbeddedError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const { checkEnrollmentProfileData } = useQueryEnrollmentProfile()
  const { checkAppliedForIds } = useQueryCheckAppliedForIds()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const terminalsInCheck: ITerminal[] = useSelector(
    selectCompanyTerminalsWithCheck,
  )
  const workersData = useSelector(selectWorkersList)

  const companyCheckUid = useMemo(
    () => currentCompany.checkUid,
    [currentCompany.checkUid],
  )

  const workersDataWithCheck = useMemo(
    () => workersData.filter(({ checkUid }) => checkUid),
    [workersData],
  )

  const {
    checkCompanyDetails,
    refetchCheckCompanyDetails,
    isLoadingCheckCompany,
    renewCheckCompanyDetails,
    isImplemtationComplete,
  } = useQueryCheckCompanyDetails(companyCheckUid as string)

  const isOnboardStatusComplete = useMemo(() => {
    return checkCompanyDetails?.onboard?.status === ECheckStatus.completed
  }, [checkCompanyDetails?.onboard?.status])

  const renderCompanyPaymentTooltip = useMemo(() => {
    if (checkCompanyDetails && checkCompanyDetails.onboard.bankAccount) {
      return (
        <ul>
          {checkCompanyDetails.onboard.bankAccount.map((name, index) => (
            <li key={index}>{_.startCase(name)}</li>
          ))}
        </ul>
      )
    }
    return undefined
  }, [checkCompanyDetails])

  const onEdit = useCallback(() => {
    setIsOpenCompanyDialog(true)
  }, [])

  const onCloseCompanyDialog = useCallback(() => {
    setIsOpenCompanyDialog(false)
  }, [])

  const afterUpdateCheckCompany = useCallback(() => {
    refetchCheckCompanyDetails()
    onCloseCompanyDialog()
  }, [onCloseCompanyDialog, refetchCheckCompanyDetails])

  const onClickStep = useCallback(
    (key: string) => () => {
      onSelectStep && onSelectStep(key)
    },
    [onSelectStep],
  )

  const renderCompanyExtraTooltip = useCallback(
    (name: 'filingAuthorization' | 'setupParameters') => {
      if (checkCompanyDetails && checkCompanyDetails.onboard[name]) {
        const data = checkCompanyDetails.onboard[name] || []
        return (
          <ul>
            {data.map(({ name, status }, index) => (
              <li key={index}>
                {name} - {_.startCase(status)}
              </li>
            ))}
          </ul>
        )
      }

      return undefined
    },
    [checkCompanyDetails],
  )

  const findOptions = useCallback(
    (value: string, options: ICommonOption[]) =>
      options.find(opt => opt.value === value),
    [],
  )

  const numberOfCheckEmployee = useMemo(() => {
    let count = 0
    workersDataWithCheck.forEach(({ checkUid }) => {
      if (isStartWithEmp(checkUid)) {
        count++
      }
    })
    return count
  }, [workersDataWithCheck])

  const numberOfCheckContractor = useMemo(() => {
    let count = 0
    workersDataWithCheck.forEach(({ checkUid }) => {
      if (!isStartWithEmp(checkUid)) {
        count++
      }
    })
    return count
  }, [workersDataWithCheck])

  const employeeContractorText = useMemo(() => {
    const text: string[] = []
    if (numberOfCheckEmployee === 0) {
      text.push('Employees')
    }
    if (numberOfCheckContractor === 0) {
      text.push('Contractors')
    }

    return text.join(' and ')
  }, [numberOfCheckContractor, numberOfCheckEmployee])

  const companyFormValues = useMemo<
    ICreateCheckCompanyFormValues | undefined
  >(() => {
    if (checkCompanyDetails) {
      return {
        id: checkCompanyDetails.id,
        legalName: checkCompanyDetails.legalName,
        tradeName: checkCompanyDetails.tradeName,
        businessType: checkCompanyDetails.businessType,
        industryType: checkCompanyDetails.industryType,
        payFrequency: checkCompanyDetails.payFrequency,
        processingPeriod: checkCompanyDetails.processingPeriod,
        startDate: checkCompanyDetails.startDate,
        feinId: checkCompanyDetails.feinId,
        email: checkCompanyDetails.email,
        phone: checkCompanyDetails.phone,
        address: checkCompanyDetails.address,
      }
    }
    return undefined
  }, [checkCompanyDetails])

  const countRemainingSteps = useMemo(() => {
    if (checkCompanyDetails) {
      return checkCompanyDetails.onboard.remainingSteps.length
    }
    return 0
  }, [checkCompanyDetails])

  const onStartImplementation = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      const { body } = await apiClient.check.postStartImplementation()
      const data = JSON.parse(body)
      if (data.error) {
        const { input_errors } = data.error
        if (input_errors?.length) {
          const { message } = input_errors[0]
          setError(message)
        }
      } else if (data.id) {
        // setError('')
        const parseData = humps.camelizeKeys(data)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renewCheckCompanyDetails(parseData as any)
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Error')
    } finally {
      setIsLoading(false)
    }
  }, [renewCheckCompanyDetails])

  const onCancelImplementation = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to cancel implementation',
    })
    if (result === EYesNo.Yes) {
      setIsLoading(true)
      try {
        const { body } = await apiClient.check.postCancelImplementation()
        const data = JSON.parse(body)
        if (data.error?.message) {
          setError(data.error.message)
        } else if (data.id) {
          const parseData = humps.camelizeKeys(data)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renewCheckCompanyDetails(parseData as any)
        }
      } catch (error) {
        console.log('error', error)
        toast.error('Error')
      } finally {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasAtLeastEmployeeAndWorker = useMemo(
    () => numberOfCheckEmployee > 0 && numberOfCheckContractor > 0,
    [numberOfCheckContractor, numberOfCheckEmployee],
  )

  const isShowStartImplementation = useMemo(() => {
    return (
      hasAtLeastEmployeeAndWorker &&
      checkCompanyDetails?.implementation.status === 'needs_attention' &&
      checkCompanyDetails?.onboard.status === 'completed'
    )
  }, [
    checkCompanyDetails?.implementation.status,
    checkCompanyDetails?.onboard.status,
    hasAtLeastEmployeeAndWorker,
  ])

  const hasBankAccountStep = useMemo(() => {
    if (checkCompanyDetails) {
      return checkCompanyDetails.onboard.blockingSteps.includes('bank_account')
    }

    return false
  }, [checkCompanyDetails])

  const allowToRequestEmbeddedSetp = useMemo(() => {
    return (
      !hasBankAccountStep &&
      checkEnrollmentProfileData?.payrollHistoryAccessMethod
    )
  }, [
    checkEnrollmentProfileData?.payrollHistoryAccessMethod,
    hasBankAccountStep,
  ])

  const onRequestEmbeddedSetup = useCallback(async () => {
    if (allowToRequestEmbeddedSetp) {
      setRequestedEmbeddedError('')
      setIsLoading(true)
      try {
        const { body } = await apiClient.check.postCompanyRequestEmbeddedSetup()
        const data = JSON.parse(body)
        if (data.error) {
          const { input_errors } = data.error
          if (input_errors?.length) {
            const { message } = input_errors[0]
            setRequestedEmbeddedError(message)
          }
        } else if (data.id) {
          // setError('')
          const parseData = humps.camelizeKeys(data)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renewCheckCompanyDetails(parseData as any)
        }
      } catch (error) {
        console.log('error', error)
        toast.error('Error')
      } finally {
        setIsLoading(false)
      }
    }
  }, [allowToRequestEmbeddedSetp, renewCheckCompanyDetails])

  const renderTaxIds = useMemo(() => {
    return checkAppliedForIds.map(({ jurisdiction }, index) => (
      <Badge style={{ marginRight: 4 }} bg='secondary' key={index}>
        {jurisdiction}
      </Badge>
    ))
  }, [checkAppliedForIds])

  const renderImplentationStatus = useMemo(() => {
    if (checkCompanyDetails?.implementation) {
      const { remainingSteps } = checkCompanyDetails.implementation
      const remainingStepsKeys = Object.keys(remainingSteps || {})
      return (
        <ul>
          <li>
            <span className='key'>Status: </span>
            <Badge
              style={{ fontSize: 11 }}
              bg={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (CHECK_STATUS_COLOR as any)[
                  checkCompanyDetails.implementation.status
                ]
              }
            >
              {_.startCase(checkCompanyDetails.implementation.status)}
            </Badge>
          </li>
          {remainingStepsKeys.length > 0 && (
            <li>
              <span className='key'>Remaining steps: </span>
              <span>
                <ul>
                  {remainingStepsKeys.map((key, index) => (
                    <li key={index}>
                      <span>{_.upperCase(key)}: </span>
                      <span>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {_.startCase((remainingSteps as any)[key].status)}
                      </span>
                    </li>
                  ))}
                </ul>
              </span>
            </li>
          )}

          <Unless condition={isImplemtationComplete}>
            <li>
              <span
                className={clsx({
                  isDisabled: isLoading,
                  hyperLink: isShowStartImplementation,
                })}
                onClick={onStartImplementation}
              >
                {isLoading ? (
                  <IonSpinner style={{ height: 20 }} name='dots' />
                ) : (
                  'Start implementation'
                )}
              </span>
              <When condition={Boolean(error)}>
                <span className='error'> - {error}</span>
              </When>

              <ul>
                {terminalsInCheck.length === 0 && (
                  <li>
                    <span
                      className='hyperLink'
                      onClick={onClickStep('workplaces')}
                    >
                      Create Workplaces
                    </span>
                  </li>
                )}

                {(numberOfCheckEmployee === 0 ||
                  numberOfCheckContractor === 0) && (
                  <li>
                    <span
                      className='hyperLink'
                      onClick={onClickStep('employeeAndContractors')}
                    >
                      Create {employeeContractorText}
                    </span>
                  </li>
                )}

                <Unless
                  condition={
                    checkCompanyDetails?.onboard?.status === 'completed'
                  }
                >
                  <li className='hyperLink' onClick={onClickStep('onboard')}>
                    Setup company
                  </li>
                </Unless>
              </ul>
            </li>
          </Unless>

          <When
            condition={
              checkCompanyDetails?.implementation.status === 'in_review'
            }
          >
            <li>
              <span
                className={clsx('hyperLink', { isDisabled: isLoading })}
                onClick={onCancelImplementation}
              >
                {isLoading ? (
                  <IonSpinner style={{ height: 20 }} name='dots' />
                ) : (
                  'Cancel implementation'
                )}
              </span>
              <When condition={Boolean(error)}>
                <span className='error'> - {error}</span>
              </When>
            </li>
          </When>
        </ul>
      )
    }
    return null
  }, [
    error,
    checkCompanyDetails?.implementation,
    terminalsInCheck.length,
    isLoading,
    onClickStep,
    numberOfCheckEmployee,
    numberOfCheckContractor,
    employeeContractorText,
    isShowStartImplementation,
    onStartImplementation,
    onCancelImplementation,
    checkCompanyDetails?.onboard?.status,
    isImplemtationComplete,
  ])

  const renderCheckOnboardStatus = useMemo(() => {
    if (checkCompanyDetails) {
      return (
        <ul>
          <li>
            <span className='key'>Status: </span>
            <Badge
              style={{ fontSize: 11 }}
              bg={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (CHECK_STATUS_COLOR as any)[checkCompanyDetails.onboard.status]
              }
            >
              {_.startCase(checkCompanyDetails.onboard.status)}
            </Badge>
          </li>
          {checkCompanyDetails.onboard.remainingSteps.length > 0 && (
            <li>
              <span className='key'>Remaining steps: </span>
              <span>
                <ul>
                  {checkCompanyDetails.onboard.remainingSteps.map(
                    (step, index) => (
                      <li key={index}>
                        <span
                          className='hyperLink'
                          onClick={onClickStep(_.camelCase(step))}
                        >
                          {camelKeyToNormalString(step)}
                        </span>
                        {step === 'bank_account' && renderCompanyPaymentTooltip}
                        {step !== 'bank_account' &&
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          renderCompanyExtraTooltip(_.camelCase(step) as any)}
                      </li>
                    ),
                  )}
                </ul>
              </span>
            </li>
          )}
          {checkCompanyDetails.onboard.blockingSteps.length > 0 && (
            <li>
              <span className='key'>Blocking steps: </span>
              <span>
                <ul>
                  {checkCompanyDetails.onboard.blockingSteps.map(
                    (step, index) => (
                      <li
                        key={index}
                        className='hyperLink'
                        onClick={onClickStep(_.camelCase(step))}
                      >
                        {camelKeyToNormalString(step)}
                      </li>
                    ),
                  )}
                </ul>
              </span>
            </li>
          )}
        </ul>
      )
    }
    return null
  }, [
    checkCompanyDetails,
    onClickStep,
    renderCompanyExtraTooltip,
    renderCompanyPaymentTooltip,
  ])

  const renderCompanyCheckInfo = useMemo(() => {
    if (checkCompanyDetails) {
      return [
        {
          label: 'Legal Name',
          value: checkCompanyDetails.legalName,
        },
        {
          label: 'Trade Name',
          value: checkCompanyDetails.tradeName,
        },
        {
          label: 'Address',
          value: buildFullAddress(checkCompanyDetails.address),
        },
        {
          label: 'Email',
          value: checkCompanyDetails.email,
        },
        {
          label: 'Phone',
          value: formatPhoneNumber(checkCompanyDetails.phone),
        },
        {
          label: 'Website',
          value: checkCompanyDetails.website,
        },
        {
          label: 'Start date',
          value: getDateTimeFormatFull(
            checkCompanyDetails.startDate,
            'YYYY-MM-DD',
          ),
        },
        {
          label: 'Business Type',
          value: findOptions(
            checkCompanyDetails?.businessType || '',
            CHECK_COMPANY_BUSINESS_TYPE_OPTIONS,
          )?.label,
        },
        {
          label: 'Industry Type',
          value: findOptions(
            checkCompanyDetails.industryType,
            CHECK_COMPANY_INDUSTRY_TYPE_OPTIONS,
          )?.label,
        },
        {
          label: 'Pay Frequency',
          value: findOptions(
            checkCompanyDetails.payFrequency,
            CHECK_COMPANY_PAY_FREQUENCY_OPTIONS,
          )?.label,
        },
        {
          label: 'Processing Period',
          value: findOptions(
            checkCompanyDetails.processingPeriod,
            CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
          )?.label,
        },
        {
          label: 'Tax IDs Applied For',
          value: renderTaxIds,
          isHidden: checkAppliedForIds.length === 0,
        },
        {
          label: 'Implementation',
          value: renderImplentationStatus,
        },
        {
          label: (
            <>
              <span
                className={clsx({
                  isDisabled: isLoading,
                  hyperLink: allowToRequestEmbeddedSetp,
                })}
                onClick={onRequestEmbeddedSetup}
              >
                {isLoading ? (
                  <IonSpinner style={{ height: 20 }} name='dots' />
                ) : (
                  'Request embedded setup (optional)'
                )}
              </span>
              <When condition={Boolean(requestedEmbeddedError)}>
                <span className='error'> - {requestedEmbeddedError}</span>
              </When>
            </>
          ),
          value: (
            <ul>
              <When condition={hasBankAccountStep}>
                <li className='hyperLink' onClick={onClickStep('bankAccount')}>
                  Company must complete bank account setup
                </li>
              </When>
              <li
                className='hyperLink'
                onClick={onClickStep('previousPayrollProviderAccess')}
              >
                Embedded Setup may only be used for companies migrating from a
                previous payroll provider
              </li>

              <When
                condition={
                  !checkEnrollmentProfileData?.payrollHistoryAccessMethod
                }
              >
                <li
                  className='hyperLink'
                  onClick={onClickStep('EnrollmentProfile')}
                >
                  Company must affirm access to previous payroll provider via{' '}
                  <span style={{ fontWeight: 600 }}>
                    Payroll History Access Method
                  </span>{' '}
                  on EnrollmentProfile
                </li>
              </When>
            </ul>
          ),
          isHidden: isImplemtationComplete,
        },
        {
          label: 'Onboard',
          value: renderCheckOnboardStatus,
        },
      ]
    }

    return []
  }, [
    allowToRequestEmbeddedSetp,
    checkCompanyDetails,
    checkEnrollmentProfileData?.payrollHistoryAccessMethod,
    findOptions,
    hasBankAccountStep,
    isLoading,
    onClickStep,
    onRequestEmbeddedSetup,
    renderCheckOnboardStatus,
    renderImplentationStatus,
    requestedEmbeddedError,
    isImplemtationComplete,
    renderTaxIds,
    checkAppliedForIds,
    getDateTimeFormatFull,
  ])

  return {
    renderCompanyCheckInfo,
    renderCheckOnboardStatus,
    checkCompanyDetails,
    refetchCheckCompanyDetails,
    renderCompanyPaymentTooltip,
    renderCompanyExtraTooltip,
    isLoadingCheckCompany,
    countRemainingSteps,
    onEdit,
    isOpenCompanyDialog,
    companyFormValues,
    isOnboardStatusComplete,
    onCloseCompanyDialog,
    afterUpdateCheckCompany,
  }
}

export default useCheckCompanyInfoSection
