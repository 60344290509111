import { createSelector } from 'reselect'

export const selectCompanyPayrollsDataState = state =>
  state.data.companyPayrolls

export const selectCompanyPayrollsUiState = state => state.ui.companyPayrolls

export const selectCompanyPayrollsData = createSelector(
  selectCompanyPayrollsDataState,
  state => Object.values(state),
)

export const selectCompanyPayrollsLoading = createSelector(
  selectCompanyPayrollsUiState,
  state => state.loading,
)
