import { useWindowSize } from 'react-use'

import TableBankAccounts from '~/containers/CompanySubTabs/BankAccounts/TableBankAccounts'

function CompanyBankAccountTab() {
  const windowSize = useWindowSize()

  return <TableBankAccounts tableHeight={windowSize.height - 300} />
}

export default CompanyBankAccountTab
