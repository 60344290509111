import { useCallback, memo, useState } from 'react'
import useTableCompanyUserSubTab from './useTableCompanyUserSubTab'

import {
  DialogUserForm,
  DialogUserAccessForm,
  ReusableTable,
  EditIcon,
  CompanyIcon,
  ViewMapIcon,
} from '~/components/shared'
import DialogUserTerminals from './DialogUserTerminals'

import { moduleName } from '~/utils/constants'

import './CompanyUserSubTab.scss'
import { useWindowSize } from 'react-use'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import {
  useQueryTerminals,
  useQueryUserGroups,
  useQueryUsers,
} from '~/hooks/useQueryData'

function CompanyUserSubTab() {
  const windowSize = useWindowSize()
  const [userTerminalsModal, setUserTerminalsModal] = useState({
    open: false,
    userAccessId: null,
  })
  const [dialogUserState, setDialogUserState] = useState({
    isOpen: false,
    formData: null,
  })
  const [dialogUserAccessState, setDialogUserAccessState] = useState({
    isOpen: false,
    formData: null,
  })

  const { isLoadingUsersData, refetchUsersData, usersData } = useQueryUsers()
  const { isLoadingTerminals } = useQueryTerminals()
  const { isLoadingUserGroup } = useQueryUserGroups()

  const isTableLoading =
    isLoadingUsersData || isLoadingTerminals || isLoadingUserGroup

  const onOpenUserCreateForm = () => {
    setDialogUserState({
      isOpen: true,
      formData: null,
    })
  }

  const handleOpenUserTerminalModal = useCallback(user => {
    setUserTerminalsModal({
      open: true,
      userAccessId: user.userAccess.id,
    })
  }, [])

  const handleOpenEditUserForm = useCallback(user => {
    setDialogUserState({
      isOpen: true,
      formData: user,
    })
  }, [])

  const handleOpenEditAccess = useCallback(user => {
    setDialogUserAccessState({
      isOpen: true,
      formData: user.userAccess,
    })
  }, [])

  const { columns } = useTableCompanyUserSubTab()

  const handleCloseUserTerminalsModal = useCallback(() => {
    setUserTerminalsModal({
      open: false,
      userAccessId: null,
    })
  }, [])

  const afterUpdateUserAccess = useCallback(() => {
    setDialogUserAccessState({
      isOpen: false,
      formData: null,
    })
  }, [])

  const afterCreateCompanyUser = useCallback(async () => {
    setDialogUserState({
      isOpen: false,
      formData: null,
    })
    refetchUsersData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const afterUpdateCompanyUser = useCallback(async () => {
    setDialogUserState({
      isOpen: false,
      formData: null,
    })
    refetchUsersData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={usersData}
          enableRowActions
          enableCompanyView
          enableTopToolbar
          tableHeight={windowSize.height - 220}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 105,
            },
          }}
          companyViewProps={{
            description: "Mange your company's users.",
            name: moduleName.company.users,
            onClickTopButton: onOpenUserCreateForm,
          }}
          state={{
            isLoading: isTableLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
            sorting: [
              {
                id: 'createdAt',
                desc: true,
              },
            ],
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenEditUserForm(row.original),
              tooltipProps: {
                content: 'Edit User',
                placement: 'top',
              },
            },
            {
              icon: <CompanyIcon color='white' />,
              onClick: () => handleOpenEditAccess(row.original),
              tooltipProps: {
                content: 'Edit User Access',
                placement: 'top',
              },
            },
            {
              icon: <ViewMapIcon color='white' />,
              onClick: () => handleOpenUserTerminalModal(row.original),
              tooltipProps: {
                content: 'View Terminals',
                placement: 'top',
              },
            },
          ]}
        />
      </div>
      <DialogUserTerminals
        {...userTerminalsModal}
        onClose={handleCloseUserTerminalsModal}
      />
      <DialogUserForm
        isOpen={dialogUserState.isOpen}
        formData={dialogUserState.formData}
        onClose={() => {
          setDialogUserState({
            isOpen: false,
            formData: null,
          })
        }}
        afterCreate={afterCreateCompanyUser}
        afterUpdate={afterUpdateCompanyUser}
      />

      <DialogUserAccessForm
        isOpen={dialogUserAccessState.isOpen}
        formData={dialogUserAccessState.formData}
        onClose={() => {
          setDialogUserAccessState({
            isOpen: false,
            formData: null,
          })
        }}
        afterUpdate={afterUpdateUserAccess}
      />
    </>
  )
}

CompanyUserSubTab.propTypes = {}

export default memo(CompanyUserSubTab)
