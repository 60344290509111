import { Alert } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { IonIcon } from '@ionic/react'
import { Unless } from 'react-if'

import { pencil } from 'ionicons/icons'

import type { IInfoSectionProps } from './type'

import './styles.scss'

function InfoSection(props: IInfoSectionProps) {
  const { data, color, isLoading, isHiddenEditButton = true, onEdit } = props

  if (isLoading && data.length === 0) {
    return (
      <div className='InfoSection__loading'>
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
      </div>
    )
  }

  if (data.length) {
    return (
      <Alert variant={color} className='InfoSection__container'>
        <Unless condition={isHiddenEditButton}>
          <span className='editIcon icon' onClick={onEdit}>
            <IonIcon icon={pencil} />
          </span>
        </Unless>
        <ul>
          {data
            .filter(({ isHidden }) => !isHidden)
            .map(({ label, value }, index) => (
              <li key={index}>
                <span className='key'>{label}: </span>
                <span>{value}</span>
              </li>
            ))}
        </ul>
      </Alert>
    )
  }

  return null
}
export default InfoSection
