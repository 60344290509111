import { useEffect, useState } from 'react'

import { CheckMarkIcon, CloseIcon } from '~/components/shared'

import type { IOrderExtraRange } from '~/types/models/IOrderExtraRange'
import './styles.scss'
import { apiClient } from '~/api/ApiClient'
import * as Yup from 'yup'
import { Alert } from 'react-bootstrap'

export interface IOrderExtraRangeCardProps {
  orderExtraRange: IOrderExtraRange
  afterUpdate: (orderExtraRange: IOrderExtraRange) => void
}

function OrderExtraRangeCard(props: IOrderExtraRangeCardProps) {
  const { orderExtraRange, afterUpdate } = props

  const { qty, startLoad, endLoad } = orderExtraRange
  const load = [startLoad, endLoad].filter(Boolean).join('-')
  const loadLabel = startLoad && endLoad ? 'LDs' : 'LD'

  const [error, setError] = useState('')
  const [isQtyInputOpen, setIsQtyInputOpen] = useState(false)
  const [isLoadInputsOpen, setIsLoadInputsOpen] = useState(false)
  const [qtyValue, setQtyValue] = useState<any>(qty)
  const [loadValue, setLoadValue] = useState({
    startLoad: 1,
    endLoad: null as number | null,
  })

  const loadSchema = Yup.object({
    startLoad: Yup.number()
      .required('Start load is required!')
      .typeError('Start load should be number')
      .min(1, 'Start load should be positive'),
    endLoad: Yup.number()
      .nullable()
      .transform(num => {
        if (isNaN(num)) {
          return null
        }
        return num
      })
      .test('positive-end-load', 'End load should be positive', val => {
        if (val) {
          return val > 0
        }
        return true
      })
      .test(
        'end-load-is-greater-than-start-load',
        'End load should be greater than Start load',
        (val, { parent }) => {
          if (!val) {
            return true
          }
          return (val || 0) >= parent.startLoad
        },
      ),
  })

  const qtySchema = Yup.number()
    .required('Qty is required!')
    .typeError('Qty should be number')
    .min(1, 'Qty should be positive')

  const onUpdate = async (payload: any) => {
    const response = await apiClient.orderExtraRanges.update(
      orderExtraRange.id,
      { orderExtraRange: payload },
    )
    afterUpdate(response)
  }

  useEffect(() => {
    setQtyValue(qty)
  }, [qty])

  useEffect(() => {
    setLoadValue({
      startLoad,
      endLoad,
    })
  }, [startLoad, endLoad])

  return (
    <div>
      {error && <Alert variant='danger'>{error}</Alert>}

      <span>Qty: </span>
      {isQtyInputOpen ? (
        <span>
          <input
            className='OrderExtraRangeCard__input'
            type='number'
            style={{ marginRight: 4 }}
            value={qtyValue}
            onChange={event => {
              setQtyValue(event.target.value)
            }}
          />
          <span
            className='clickable'
            onClick={async () => {
              setError('')
              try {
                await qtySchema.validate(qtyValue)
                setIsQtyInputOpen(false)
                onUpdate({ qty: qtyValue })
              } catch (error: any) {
                console.log('error', error)
                setError(error.message)
              }
            }}
          >
            <CheckMarkIcon color='var(--bs-success)' />
          </span>
          <span
            className='clickable'
            onClick={() => {
              setIsQtyInputOpen(false)
              setQtyValue(qty)
            }}
          >
            <CloseIcon color='var(--bs-danger)' size={15} />
          </span>
        </span>
      ) : (
        <span
          onClick={() => {
            setIsQtyInputOpen(true)
          }}
        >
          {Number(qty)}
        </span>
      )}
      <span> | </span>
      <span>{loadLabel}: </span>
      {isLoadInputsOpen ? (
        <span>
          <input
            placeholder='Start'
            className='OrderExtraRangeCard__input'
            value={loadValue.startLoad}
            type='number'
            onChange={event => {
              setLoadValue(prev => ({
                ...prev,
                startLoad: event.target.value as any,
              }))
            }}
          />
          <input
            placeholder='End'
            className='OrderExtraRangeCard__input'
            style={{ marginLeft: 4, marginRight: 4 }}
            value={loadValue.endLoad as any}
            type='number'
            onChange={event => {
              setLoadValue(prev => ({
                ...prev,
                endLoad: event.target.value as any,
              }))
            }}
          />
          <span
            className='clickable'
            onClick={async () => {
              setError('')
              try {
                await loadSchema.validate(loadValue)
                setIsLoadInputsOpen(false)
                onUpdate(loadValue)
              } catch (error: any) {
                console.log('error', error)
                setError(error.message)
              }
            }}
          >
            <CheckMarkIcon color='var(--bs-success)' />
          </span>
          <span
            className='clickable'
            onClick={() => {
              setIsLoadInputsOpen(false)
              setLoadValue({
                startLoad,
                endLoad,
              })
            }}
          >
            <CloseIcon color='var(--bs-danger)' size={15} />
          </span>
        </span>
      ) : (
        <span
          onClick={() => {
            setIsLoadInputsOpen(true)
          }}
        >
          {load}
        </span>
      )}
    </div>
  )
}

export default OrderExtraRangeCard
