import { createSlice, createSelector } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {},
  reducers: {
    initializeSchedules: (state, action) => {
      action.payload.forEach(schedule => (state[schedule.id] = schedule))
    },
    updateSchedules: (state, action) => {
      action.payload.forEach(schedule => {
        if (state[schedule.id]) {
          state[schedule.id] = schedule
          if (schedule.deletedAt !== null) delete state[schedule.id]
        }
      })
    },
    updateSchedule: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
      }
    },
    deleteSchedule: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export const getSchedules = state => state.data.schedules

export const selectSchedulesList = createSelector(getSchedules, schedules =>
  Object.values(schedules),
)
