export enum EAddressableType {
  company = 'Company',
  bankAccount = 'BankAccount',
  creditReference = 'CreditReference',
  terminal = 'Terminal',
  person = 'Person',
}

export enum EAddressType {
  mailing,
  office,
  ar,
  ap,
  residence,
  hr,
  physical,
  billing,
  location,
  other,
}

export enum EAddressTypeStr {
  mailing = 'mailing',
  ar = 'ar',
  office = 'office',
  ap = 'ap',
  residence = 'residence',
  hr = 'hr',
  physical = 'physical',
  billing = 'billing',
  location = 'location',
  other = 'other',
}
