import { createSlice } from '@reduxjs/toolkit'

const name = 'addresses'

const addressesUiSlice = createSlice({
  name,
  initialState: {
    loadingTable: false,
  },
  reducers: {
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
  },
})

export default addressesUiSlice
