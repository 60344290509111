import sellerProductsSlice from '~/redux/reducers/data/sellerProducts'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt'
import { apiClient } from '~/api/ApiClient'

export const fetchSellerProducts = () => (dispatch) => {
  apiClient.sellerProducts.get()
    .then(response => {
      const unix = Math.round(+new Date() / 1000)
      dispatch(sellerProductsSlice.actions.initializeSellerProducts(response.sellerProducts))
      dispatch(
        lastFetchedAtSlice.actions.updateLastFetchedAt({
          model: 'sellerProducts',
          lastFetchedAt: unix,
        }),
      )
    })
    .catch(err => console.log(err))
}

export const updateSellerProduct = (sellerProduct) => (dispatch) => {
  dispatch(sellerProductsSlice.actions.updateSellerProduct(sellerProduct))
}

export const updateSellerProducts = (sellerProducts) => (dispatch) => {
  dispatch(sellerProductsSlice.actions.updateSellerProducts(sellerProducts))
}
