import { useState, useMemo, useCallback, useEffect } from 'react'
import useTableCompanyFixedPriceSubTab from './useTableCompanyFixedPriceSubTab'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogFixedPriceForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'
import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import {
  useQueryBuyerSellers,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'

function CompanyFixedPriceSubTab() {
  const [fixedPriceData, setFixedPriceData] = useState([])
  const [loading, setLoading] = useState(false)
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const filterData = useMemo(() => {
    const data = {}
    columnFilters.forEach(({ id, value }) => {
      data[id] = value
    })
    return data
  }, [columnFilters])

  const { isLoadingSellerProducts } = useQuerySellerProducts()
  const { isLoadingTerminals } = useQueryTerminals()
  const { isLoadingBuyerSellers } = useQueryBuyerSellers()

  const isLoadingTable =
    loading ||
    isLoadingSellerProducts ||
    isLoadingTerminals ||
    isLoadingBuyerSellers

  const getApiCall = useCallback(() => {
    const { createdAt, endDate, ...restFilterData } = filterData

    return apiClient.fixedPrices.get({
      filters: {
        ...restFilterData,
        searchKey: globalFilter,
      },
    })
  }, [filterData, globalFilter])

  const getFixedPriceData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setFixedPriceData(response.fixedPrices)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete fixed price #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.fixedPrices.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setFixedPriceData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const handleOpenUpdateForm = useCallback(rowData => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const rowId = row.original.id
      const columnField = column.id
      const formValues = { [columnField]: value }

      const { errors } = await apiClient.fixedPrices.update(rowId, formValues)
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        setFixedPriceData(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === rowId)
            if (index > -1) {
              draft[index][columnField] = value
              toast.success(toastMessages.updateSuccess)
            } else {
              toast.error(`Cannot find record #${rowId}`)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const { columns } = useTableCompanyFixedPriceSubTab({
    fixedPricesData: fixedPriceData,
  })

  useEffect(() => {
    getFixedPriceData()
  }, [getFixedPriceData])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableCompanyView
          enableTopToolbar
          enableRowActions
          enableColumnPinning
          manualFiltering
          tableHeight={windowSize.height - 220}
          companyViewProps={{
            description: 'Manage prices paid for materials and hauling.',
            name: moduleName.company.fixedPrices,
            onClickTopButton() {
              setFormState({
                isOpen: true,
                formData: undefined,
              })
            },
          }}
          state={{
            isLoading: isLoadingTable,
            columnFilters,
            globalFilter,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 95,
            },
          }}
          data={fixedPriceData}
          columns={columns}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenUpdateForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Delete',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={onCellEditEnd}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>
      <DialogFixedPriceForm
        isOpen={formState.isOpen}
        formData={formState.formData}
        onClose={onCloseForm}
        afterCreate={newItem => {
          onCloseForm()
          setFixedPriceData(prev => [newItem, ...prev])
        }}
        afterUpdate={newItem => {
          onCloseForm()
          setFixedPriceData(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(({ id }) => id === newItem.id)
              if (index !== -1) {
                draft[index] = newItem
              }
            }),
          )
        }}
      />
    </>
  )
}

export default CompanyFixedPriceSubTab
