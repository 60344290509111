import { useCallback } from 'react'
import {
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonCheckbox,
  IonLabel,
} from '@ionic/react'
import { checkboxOutline } from 'ionicons/icons'
import { ToolTipOverlay, ViewPDF } from '~/components/shared'
import { Button } from '~/components/shared'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './DocumentView.scss'

const DocumentView = props => {
  const {
    document,
    extraButtons,
    selectionButton,
    onselectionButtonClick,
    selected,
    mapButtons,
    contentWidth,
    style,
    menu,
  } = props

  const renderMapButtons = useCallback(() => {
    return mapButtons.map((button, index) => {
      const {
        icon,
        label,
        onClick,
        type,
        buttonProps,
        tooltipProps,
        iconProps,
        ...inputProps
      } = button

      if (type === 'checkbox') {
        return (
          <div
            className='d-flex align-items-center ion-margin-start'
            key={index}
          >
            <IonCheckbox
              onClick={onClick}
              style={{ marginRight: 8 }}
              {...inputProps}
            />
            <IonLabel style={{ fontSize: 14 }}>{label}</IonLabel>
          </div>
        )
      }

      return (
        <Button
          key={label || icon}
          buttonProps={buttonProps}
          tooltipProps={tooltipProps}
          onClick={onClick}
          label={label}
          icon={icon}
          iconProps={iconProps}
        />
      )
    })
  }, [mapButtons])

  return (
    <div
      style={style}
      onDoubleClick={event => {
        event.stopPropagation()
      }}
    >
      <IonHeader translucent style={{ width: contentWidth }}>
        <IonToolbar color='dark'>
          <div>
            {selectionButton && (
              <IonButton
                fill='solid'
                size='small'
                color={selected ? 'success' : 'primary'}
                onClick={onselectionButtonClick}
              >
                <IonIcon
                  icon={checkboxOutline}
                  tooltip
                  title='Click to make this seller level processing configuration.'
                />
              </IonButton>
            )}
            {extraButtons && (
              <IonButtons style={{ float: 'right', marginRight: '30px' }}>
                {menu?.length > 0 &&
                  menu.map((item, index) => (
                    <ToolTipOverlay content={item.label} key={index}>
                      <IonButton
                        fill='solid'
                        color={item.color || 'primary'}
                        onClick={item.onClick}
                        disabled={item.disabled}
                      >
                        {item.icon}
                      </IonButton>
                    </ToolTipOverlay>
                  ))}
              </IonButtons>
            )}

            {mapButtons && mapButtons.length > 0 && (
              <IonButtons>{renderMapButtons()}</IonButtons>
            )}
          </div>
        </IonToolbar>
      </IonHeader>

      <ViewPDF link={document} />
    </div>
  )
}

DocumentView.defaultProps = {
  mapButtons: [],
  contentHeight: 400,
  contentWidth: null,
}

export default DocumentView
