import type { ISvgIconProps } from './type'

const ListSearchIcon = (props: ISvgIconProps) => {
  const { size = 16, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      {...svgProps}
    >
      <path
        d='M11.91 3.66h-9v1.5h9ZM11.91 6.66h-9v1.5h9ZM2.91 9.66h6v1.5h-6Zm0 0'
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: color,
          fillOpacity: 1,
        }}
      />
      <path
        d='M10.316 9.566a2.252 2.252 0 0 0 2.563 3.625l1.152 1.149 1.059-1.059-1.149-1.152a2.252 2.252 0 0 0-3.625-2.563Zm1.063 2.121a.747.747 0 1 0 1.059-1.058.747.747 0 1 0-1.06 1.059Zm0 0'
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: color,
          fillOpacity: 1,
        }}
      />
    </svg>
  )
}

export default ListSearchIcon
