import { components } from 'react-select'
import { Button } from 'react-bootstrap'
import { EditIcon } from '~/components/shared'

export const EditOption = onClickEdit => props => {
  const { data } = props

  const onClick = e => {
    e.stopPropagation()
    onClickEdit && onClickEdit(props.data.value)
  }

  return (
    <components.Option
      {...props}
      className='d-flex flex-row align-items-center'
    >
      {data?.label}
      <Button onClick={onClick} variant='outline-dark' className='ms-auto'>
        <EditIcon />
      </Button>
    </components.Option>
  )
}
