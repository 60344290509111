import { When } from 'react-if'
import { Marker, Polygon } from 'react-google-maps'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import { CompanyAvatar } from '~/components/shared'

import type { ITerminalMarkerProps } from './type'

import './styles.scss'
import useTerminalMarker from './useTerminalMarker'

function TerminalMarker(props: ITerminalMarkerProps) {
  const {
    position,
    onToggleOpenInfoBox,
    isOpenInfoBox,
    terminal,
    company,
    location,
    polygonPoints,
  } = useTerminalMarker(props)

  return (
    <>
      <When condition={Boolean(location)}>
        <Marker position={position} onClick={onToggleOpenInfoBox} />
      </When>

      <When condition={isOpenInfoBox}>
        <div className=''>
          <InfoBox
            position={position}
            zIndex={1000}
            options={{
              closeBoxURL: '',
              enableEventPropagation: true,
              alignBottom: true,
              pixelOffset: new window.google.maps.Size(15, -3),
              boxStyle: {
                minWidth: 120,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: 4,
              },
            }}
          >
            <div className='TerminalMarker__infoboxContent'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CompanyAvatar
                  company={{
                    value: terminal.id as number,
                    label: terminal.name as string,
                    image: company?.logo,
                  }}
                  width={20}
                  height={20}
                />
                <div className='terminalInfo'>
                  <div>{terminal.name}</div>
                  <div className='code'>{terminal.code}</div>
                </div>
              </div>
              <div className='address'>{location?.address}</div>
            </div>
          </InfoBox>
        </div>
      </When>
      <Polygon
        path={polygonPoints}
        editable={false}
        draggable={false}
        defaultOptions={{
          strokeColor: '#1090ff',
          fillColor: '#1090ff',
        }}
      />
    </>
  )
}
export default TerminalMarker
