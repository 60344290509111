const getClassNameByLoadStatus = primaryParserStatus => {
  switch (primaryParserStatus) {
    case 'Done':
      return '--success'
    case 'Waiting':
      return '--brand'
    case 'Not Sent':
      return '--failure'
    case 'No Collection':
      return '--noCollection'
    case 'Error':
      return '--error'
    default:
      return '--process'
  }
}

export default getClassNameByLoadStatus
