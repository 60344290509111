const DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Company',
    field: 'company.name',
    show: true,
    value: 'company.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'company.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Parsing Column',
    field: 'parsingColumn',
    show: true,
    value: 'parsingColumn',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'parsingColumn',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Assign Column',
    field: 'assignColumn',
    show: true,
    value: 'assignColumn',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'assignColumn',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Type',
    field: 'model',
    show: true,
    value: 'model',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'model',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    label: 'Regexp',
    field: 'regexp',
    show: true,
    value: 'regexp',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 5,
    isAsc: true,
    sortField: 'regexp',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_COLUMNS
