import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'

import type { IDialogParserColumnMappingFormProps } from './type'
import ParserColumnMappingForm from './ParserColumnMappingForm'

function DialogParserColumnMappingForm(
  props: IDialogParserColumnMappingFormProps,
) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props
  return (
    <CommonDialogV2
      {...dialogProps}
      title='Parser Column Mapping'
      isHiddenOkButton
    >
      <ParserColumnMappingForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogParserColumnMappingForm
