import React from 'react'
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import { toastMessages } from '~/constants/toast-status-text';
//import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const DriverCable = () => {
  // const driver = useSelector(state => state.session.user.driver);

  // const onReceived = (res) => {
  //   toast.success(toastMessages.newLoad);
  //   console.log(res);
  // }

  return (
    <>
      {/* {driver &&
        <ActionCableConsumer
          channel={{ channel: `DriverLoadsChannel`, id: driver?.id }}
          onReceived={onReceived}
        />
      } */}
    </>
  )
}

export default DriverCable
