import { useMemo, useCallback, useState, useEffect } from 'react'

import { Form } from 'react-bootstrap'
import { ConcordFormLayout } from '~/components/shared'

import clsx from 'clsx'
import { produce } from 'immer'

import './ConcordFormMultipleCheckbox.scss'

function ConcordFormMultipleCheckbox(props) {
  const { label, className, name, options, value, onChange } = props

  const [checkedValues, setCheckedValues] = useState([])

  const handleChange = useCallback(
    event => {
      const { value: checkboxValue } = event.target
      const newValues = produce(checkedValues, draft => {
        const index = draft.indexOf(checkboxValue)
        if (index === -1) {
          draft.push(checkboxValue)
        } else {
          draft.splice(index, 1)
        }
      })

      setCheckedValues(newValues)
      onChange && onChange(event, { name, value: newValues })
    },
    [name, onChange, checkedValues],
  )

  const checkboxRendered = useMemo(
    () =>
      options.map(option => (
        <Form.Check
          {...option}
          key={option.value}
          checked={checkedValues.includes(option.value)}
          onChange={handleChange}
        />
      )),
    [options, checkedValues, handleChange],
  )

  useEffect(() => {
    setCheckedValues(value)
  }, [value])

  return (
    <ConcordFormLayout
      label={label}
      className={clsx(className, 'ConcordFormMultipleCheckbox__container')}
    >
      {checkboxRendered}
    </ConcordFormLayout>
  )
}

ConcordFormMultipleCheckbox.defaultProps = {
  options: [],
  value: [],
}

export default ConcordFormMultipleCheckbox
