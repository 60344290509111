import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useFuzzy from '~/hooks/useFuzzy'
import { useShowingTagModal } from '~/hooks/useShowingTagModal'

import { IonIcon, IonText } from '@ionic/react'
import { MenuItem, SubMenu } from '@szhsin/react-menu'
import { ContainerSearchBar } from '~/components/shared'

import { colord } from 'colord'
import { selectCommonTags } from '~/redux/selectors'
import {
  checkmarkCircleOutline,
  radioButtonOffOutline,
  addCircleOutline,
} from 'ionicons/icons'

import './TagsMenu.scss'

function TagsMenu(props) {
  const { tagIds = [], onSelectTag } = props

  const [searchBarValue, setSearchBarValue] = useState('')

  const commonTags = useSelector(selectCommonTags)

  const { onToggleShowHideTagForm } = useShowingTagModal()

  const sortedTagsByChecked = useMemo(() => {
    const checkedTags = []
    const unCheckedTags = []

    commonTags.forEach(tag => {
      const isTagContained = tagIds.includes(tag.id)

      if (isTagContained) {
        checkedTags.push(tag)
      } else {
        unCheckedTags.push(tag)
      }
    })

    return [...checkedTags, ...unCheckedTags]
  }, [commonTags, tagIds])

  const { seachedList: searchedTags } = useFuzzy(sortedTagsByChecked, {
    initialSearchValue: searchBarValue,
    keys: ['code', 'name'],
  })

  const handleSelectTag = useCallback(
    selectedTag => () => {
      onSelectTag && onSelectTag(selectedTag)
    },
    [onSelectTag],
  )

  const afterCreateTag = useCallback(
    createdTag => {
      onSelectTag && onSelectTag(createdTag)
    },
    [onSelectTag],
  )

  const onShowCreateTagForm = useCallback(() => {
    onToggleShowHideTagForm(true, { afterCreateTag })
  }, [afterCreateTag, onToggleShowHideTagForm])

  const renderTagItem = useCallback(() => {
    return searchedTags.map(tag => {
      const isTagContained = tagIds.includes(tag.id)
      const isDark = colord(tag.color || 'rgb(192, 192, 192)').isDark()

      return (
        <MenuItem
          key={tag.id}
          style={{
            backgroundColor: tag.color,
            color: isDark ? 'white' : 'black',
          }}
          onClick={handleSelectTag(tag)}
        >
          <IonIcon
            style={{ marginRight: 8 }}
            icon={
              isTagContained ? checkmarkCircleOutline : radioButtonOffOutline
            }
          />
          <IonText>{tag.name}</IonText>
        </MenuItem>
      )
    })
  }, [handleSelectTag, searchedTags, tagIds])

  const onSearchBarChange = useCallback(nextSearch => {
    setSearchBarValue(nextSearch)
  }, [])

  return (
    <>
      <SubMenu label='Tags' className='TagsMenu__root'>
        <ContainerSearchBar
          searchBarValue={searchBarValue}
          containerClassName='TagsMenu__searchBar'
          onSearchBarChange={onSearchBarChange}
        />
        <div className='TagsMenu__tagList'>
          <MenuItem
            style={{ background: 'black', color: 'white' }}
            onClick={onShowCreateTagForm}
          >
            <IonIcon style={{ marginRight: 8 }} icon={addCircleOutline} />
            <IonText>Create Tag</IonText>
          </MenuItem>
          {renderTagItem()}
        </div>
      </SubMenu>
    </>
  )
}

TagsMenu.propTypes = {}

export default TagsMenu
