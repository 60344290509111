import { createSlice } from '@reduxjs/toolkit'
import defaultModuleFilters from '~/constants/defaultModuleFilters'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: null,
    filters: defaultModuleFilters.dashboard,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
    },
  },
})

export default dashboardSlice
