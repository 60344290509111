import type { DragEvent, RefObject } from 'react'
import type {
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import { RTGrabHandleButton } from '../buttons/RTGrabHandleButton'

export interface IRTTableBodyRowGrabHandleProps<
  TData extends IReusableTableRowData,
> {
  row: RTRow<TData>
  rowRef: RefObject<HTMLTableRowElement>
  table: ReusableTableInstance<TData>
}

export const RTTableBodyRowGrabHandle = <TData extends IReusableTableRowData>({
  row,
  rowRef,
  table,
  ...rest
}: IRTTableBodyRowGrabHandleProps<TData>) => {
  const { onRowDragEnd } = table.options

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    try {
      event.dataTransfer.setDragImage(rowRef.current as HTMLElement, 0, 0)
    } catch (e) {
      console.error(e)
    }
    table.setDraggingRow(row as any)
  }

  const handleDragEnd = () => {
    const { draggingRow, hoveredRow } = table.getState()
    if (draggingRow && hoveredRow) {
      onRowDragEnd && onRowDragEnd(draggingRow, hoveredRow as RTRow<TData>)
    }
    table.setDraggingRow(null)
    table.setHoveredRow(null)
  }

  return (
    <RTGrabHandleButton
      {...rest}
      location='row'
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      table={table}
    />
  )
}
