export enum ELoadPrimaryParserStatus {
  'Not Sent',
  'Waiting',
  'Done',
  'Updated',
  'No Collection',
  'Error',
}

export enum ELoadPrimaryParserStatusString {
  notSent = 'Not Sent',
  waiting = 'Waiting',
  done = 'Done',
  updated = 'Updated',
  noCollection = 'No Collection',
  error = 'Error',
}

export enum ELoadHitlStatus {
  'Not Sent',
  'Waiting',
  'Done',
}

export enum ELoadFilterVisiableData {
  rawDataIfNoMatch = 'raw_data_if_no_match',
  rawDataOnly = 'raw_data_only',
  matchedDataOnly = 'matched_data_only',
}
