import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { ConcordFormTextField } from '~/components/shared'

function ConcordTextFieldWithFormControl(props) {
  const { control, name, onChange, onBlur, ...textFieldProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const methods = useFormContext()

  const handleChange = useCallback(
    (event, { value }) => {
      field.onChange(value)
      onChange &&
        onChange({
          name,
          value,
        })
    },
    [field, name, onChange],
  )

  const handleBlur = () => {
    onBlur && onBlur(field.value, { ...methods, name })
  }

  return (
    <ConcordFormTextField
      {...textFieldProps}
      {...field}
      name={name}
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={fieldState.error?.message}
    />
  )
}

export default ConcordTextFieldWithFormControl
