import { useContext } from 'react'

import OrderFormContext from './OrderFormContext'

const useOrderFormProvider = () => {
  const context = useContext(OrderFormContext)
  return context
}

export default useOrderFormProvider
