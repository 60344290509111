import { createSelector } from 'reselect'

const selectInvoiceState = state => state.invoice
const selectInvoicesIndex = state => state.data.invoices
const uiState = state => state.ui.invoice
const returnInvoice = (invoices, id) =>
  !invoices[id] || !id ? null : invoices[id]

export const selectInvoiceCurrentId = createSelector(
  uiState,

  uiState => uiState?.currentInvoiceId,
)

export const selectInvoiceCompareId = createSelector(
  selectInvoiceState,
  ({ invoice }) => invoice?.compareInvoiceId || null,
)

export const selectInvoiceDetail = createSelector(
  selectInvoiceState,
  ({ invoice }) => invoice,
)

export const findInvoiceFromIndex = createSelector(
  [selectInvoicesIndex, state => state.ui.invoice.currentInvoiceId],

  returnInvoice,
)

export const findCompareInvoiceFromIndex = createSelector(
  [selectInvoicesIndex, state => state.ui.invoice.compareTableInvoiceId],

  returnInvoice,
)

export const selectTableInvoice = createSelector(
  [
    state => state.data.invoices,
    state => state.ui.invoice,
    (state, isCompareInvoice) => isCompareInvoice,
  ],

  (invoices, uiState, isCompareInvoice) => {
    if (isCompareInvoice) {
      return invoices[uiState.compareTableInvoiceId]
    } else {
      return invoices[uiState.currentInvoiceId]
    }
  },
)

export const selectInvoiceUiState = createSelector(uiState, uiState => uiState)

export const selectSuggestModalUiState = createSelector(
  uiState,

  uiState => ({
    currentInvoice: uiState.currentInvoice,
    currentSortOptions: uiState.currentSortOptions,
    isShowInvoice: uiState.isShowInvoice,
    matchItems: uiState.matchItems,
    matchSelected: uiState.matchSelected,
    showSuggestModal: uiState.showSuggestModal,
  }),
)

export const selectComparisonTableColumns = createSelector(
  uiState,

  uiState => uiState.comparisonTableColumns,
)
