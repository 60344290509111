import React, { useState, useCallback, useMemo } from 'react'
import { useConfirmationProvider } from '~/contexts'
import { useSelector } from 'react-redux'

import { toast } from 'react-toastify'
import { produce } from 'immer'

import { ConcordFormDropdown } from '~/components/shared'
import TerminalCustomOption from '../ConcordLoadTerminalDropdown/TerminalCustomOption'
import BuyerSellerDropdownForm from './BuyerSellerDropdownForm'
import { toastMessages } from '~/constants/toast-status-text'
import { selectCurrentCompanyOptions, selectTerminals } from '~/redux/selectors'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

function ConcordLoadBuyerSellerProductDropdown(props) {
  const {
    className,
    onCreate,
    onUpdate,
    onDelete,
    onOpenDropdown,
    loadId,
    sellerId,
    sellerTerminalId,
    buyerTerminalId,
    buyerId,
    ...dropdownProps
  } = props

  const [buyerSellerProductsForLoad, setBuyerSellerProductsForLoad] = useState(
    [],
  )
  const [buyerSellerProducts, setBuyerSellerProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [initialFormValues, setInitialFormValues] = useState({})

  const { confirmation } = useConfirmationProvider()

  const currentCompanies = useSelector(selectCurrentCompanyOptions)
  const terminals = useSelector(selectTerminals)

  const seller = useMemo(() => {
    const findSeller = currentCompanies.find(({ value }) => value === sellerId)

    return findSeller || {}
  }, [currentCompanies, sellerId])

  const buyerTerminal = useMemo(
    () => terminals.find(({ id }) => id === buyerTerminalId) || {},
    [buyerTerminalId, terminals],
  )

  const sellerTerminal = useMemo(
    () => terminals.find(({ id }) => id === sellerTerminalId) || {},
    [sellerTerminalId, terminals],
  )

  const mapOptions = useMemo(() => {
    const options = []

    if (buyerSellerProductsForLoad.length > 0) {
      let label = `Products ${seller?.name || ''}`
      if (sellerTerminal?.name) {
        label += ` from ${sellerTerminal?.name}`
        label += ` to ${buyerTerminal?.name}`
      }
      const mapProductOptions = _(buyerSellerProductsForLoad)
        .map(({ name, id, code, productGroupId }) => ({
          name,
          value: id,
          code,
          productGroupId,
          label: name,
        }))
        .orderBy('label')
        .value()

      options.push({
        label,
        options: mapProductOptions,
      })
    }

    if (buyerSellerProducts.length > 0) {
      const label = `Products bought from seller: ${seller?.name}`
      const mapProductOptions = _(buyerSellerProducts)
        .map(({ name, id, code, productGroupId }) => ({
          name,
          value: id,
          code,
          productGroupId,
          label: name,
        }))
        .orderBy('label')
        .value()

      options.push({
        label,
        options: mapProductOptions,
      })
    }

    return options
  }, [
    buyerSellerProductsForLoad,
    buyerSellerProducts,
    sellerTerminal?.name,
    buyerTerminal?.name,
    seller?.name,
  ])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response01 = await apiClient.buyerSellerProducts.get({ loadId })
      setBuyerSellerProductsForLoad(response01.buyerSellerProducts)

      const notIds = response01.buyerSellerProducts
        .map(({ id }) => id)
        .filter(id => id)

      const response02 = await apiClient.buyerSellerProducts.get({
        buyerId,
        sellerId,
        filters: {
          notIds,
        },
      })
      setBuyerSellerProducts(response02.buyerSellerProducts)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }, [buyerId, loadId, sellerId])

  const handleOpenDropdown = useCallback(() => {
    if (buyerId && sellerId) {
      fetchData()
    }
    onOpenDropdown && onOpenDropdown()
  }, [buyerId, fetchData, onOpenDropdown, sellerId])

  const handleOpenForm = useCallback(() => {
    setMenuIsOpen(false)
    setIsOpenForm(true)
  }, [])

  const handleCloseForm = useCallback(() => {
    setMenuIsOpen(true)
    setIsOpenForm(false)
    setInitialFormValues({})
  }, [])

  const handleEdit = useCallback(
    option => {
      const { code, name, value, productGroupId } = option
      setInitialFormValues({
        code,
        name,
        id: value,
        productGroupId,
      })
      handleOpenForm()
    },
    [handleOpenForm],
  )

  const handleCreateProduct = useCallback(
    createdProduct => {
      setBuyerSellerProducts(prev =>
        produce(prev, draft => {
          draft.push(createdProduct)
        }),
      )
      onCreate && onCreate(createdProduct)
    },
    [onCreate],
  )

  const handleUpdateProduct = useCallback(
    updatedProduct => {
      setBuyerSellerProducts(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === updatedProduct.id)
          if (index !== -1) {
            draft[index] = updatedProduct
          }
        }),
      )
      setBuyerSellerProductsForLoad(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === updatedProduct.id)
          if (index !== -1) {
            draft[index] = updatedProduct
          }
        }),
      )
      onUpdate && onUpdate(updatedProduct)
    },
    [onUpdate],
  )

  const handleDelete = useCallback(
    async option => {
      const result = await confirmation({
        message: `Are you sure you want to delete product #${option.value}?`,
      })

      if (result === EYesNo.Yes) {
        setIsLoading(true)
        try {
          const response = await apiClient.buyerSellerProducts.delete(
            option.value,
          )
          if (response.id) {
            toast.success(toastMessages.deleteSuccess)
            setBuyerSellerProductsForLoad(prev =>
              produce(prev, draft => {
                const index = draft.findIndex(({ id }) => id === response.id)
                if (index > -1) {
                  draft.splice(index, 1)
                }
              }),
            )
            setBuyerSellerProducts(prev =>
              produce(prev, draft => {
                const index = draft.findIndex(({ id }) => id === response.id)
                if (index > -1) {
                  draft.splice(index, 1)
                }
              }),
            )
            onDelete && onDelete(response)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          toast.error(toastMessages.deleteError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [confirmation, onDelete],
  )

  const renderElementBelowDropdown = useCallback(
    () => (
      <BuyerSellerDropdownForm
        isLoading={isLoading}
        isOpenForm={isOpenForm}
        onClose={handleCloseForm}
        onOpen={handleOpenForm}
        sellerId={sellerId}
        buyerId={buyerId}
        initialFormValues={initialFormValues}
        onCreate={handleCreateProduct}
        onUpdate={handleUpdateProduct}
      />
    ),
    [
      buyerId,
      handleCloseForm,
      handleCreateProduct,
      handleOpenForm,
      handleUpdateProduct,
      initialFormValues,
      isLoading,
      isOpenForm,
      sellerId,
    ],
  )

  return (
    <ConcordFormDropdown
      className={className}
      options={mapOptions}
      isLoading={isLoading}
      menuIsOpen={menuIsOpen}
      onOpenDropdown={handleOpenDropdown}
      onEdit={handleEdit}
      onDelete={handleDelete}
      styles={{
        menuList: provided => ({
          ...provided,
          maxHeight: 150,
        }),
      }}
      renderElementBelowDropdown={renderElementBelowDropdown}
      components={{
        Option: TerminalCustomOption,
      }}
      {...dropdownProps}
    />
  )
}
ConcordLoadBuyerSellerProductDropdown.propTypes = {
  className: PropTypes.string,
  loadId: PropTypes.number,
}

export default React.memo(ConcordLoadBuyerSellerProductDropdown)
