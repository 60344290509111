import { createSelector } from 'reselect'
import _ from 'lodash'

const selectCompanyState = state => state.myCompany

export const selectCompanyLoading = state => state.myCompany.loading

export const selectCompanyEditingReferenceName = createSelector(
  selectCompanyState,
  company => company.editingReferenceName,
)

export const selectCompanyFleets = createSelector(
  selectCompanyState,
  company => company.fleets,
)

export const selectSortedCompanyFleets = createSelector(
  selectCompanyFleets,
  fleets => _.orderBy(fleets, 'name'),
)

export const selectCompanyProductDetail = createSelector(
  selectCompanyState,
  company => company.companyProduct,
)

export const selectCompanyFixedPriceDetail = createSelector(
  selectCompanyState,
  company => company.fixedPrice,
)

export const selectCompanySubPart = createSelector(
  selectCompanyState,
  state => state.subPart,
)

export const selectCompanyLastUpdatedAt = createSelector(
  selectCompanyState,
  company => company.lastUpdatedAt,
)

export const selectCurrentBuyerSeller = createSelector(
  selectCompanyState,
  company => company.buyerSeller,
)
