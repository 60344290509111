export const formatProductQtyChart = (
  dashboardData,
  products,
  selectedTerminal,
) => {
  const data = [['Product', 'QTY', { role: 'style' }]]
  const parsedHash = parsebuyerTerminalProductSumOfQty(dashboardData)

  if (!selectedTerminal.value && !selectedTerminal.label) {
    return data
  }

  let index = 0
  const productHash = parsedHash[selectedTerminal.value]
  for (const key in productHash) {
    const namedProduct =
      products.find(product => product.id == key)?.name || 'Not Set'
    data.push([
      `${namedProduct}`,
      parseFloat(productHash[key]),
      colorArray[index],
    ])
    index++
  }

  return data
}

const parsebuyerTerminalProductSumOfQty = dashboardData => {
  const buyerTerminalsHash = {}

  for (const key in dashboardData) {
    const newKey = key.slice(1, key.length - 1).split(',')

    buyerTerminalsHash[newKey[0]] = {
      ...buyerTerminalsHash[newKey[0]],
      [newKey[1]]: dashboardData[key],
    }
  }

  return buyerTerminalsHash
}

export const mapPropertyIds = (ids, options) => {
  const newArray = []

  // for (const key in ids) {
  //   if (key === '') {
  //     newArray.push([key || 'Not Set', ids[key], null])
  //     break
  //   }
  //   for (let i = 0; i < labels.length; i++) {
  //     if (key == labels[i].value) {
  //       newArray.push([labels[i].label, ids[key], labels[i].value])
  //       labels.splice(i, 1)
  //       break
  //     } else if (i == labels.length - 1) {
  //       newArray.push([key, ids[key], key])
  //     }
  //   }
  // }

  for (const key in ids) {
    if (key === '') {
      newArray.push([key || 'Not Set', ids[key], null])
      break
    }
    options.forEach(option => {
      const { value, label } = option
      if (key == value) {
        newArray.push([label, ids[key], value])
      }
    })
  }

  return newArray
}

export const formatMetaData = (data, key) => {
  if (key) {
    return data.map(s => ({
      value: s[key].id,
      label: `${s[key].name}`,
    }))
  } else {
    return data.map(s => ({
      value: s.id,
      label: `${s.name}`,
    }))
  }
}

export const colorArray = [
  '#008744',
  '#0057e7',
  '#d62d20',
  '#ffa700',
  '#feda75',
  '#fa7e1e',
  '#d62976',
  '#962fbf',
  '#4f5bd5',
  '#d7c797',
  '#845422',
  '#ead61c',
  '#a47c48',
  '#000000',
]
