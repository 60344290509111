const DEFAULT_DOCUMENT_COLUMNS = [
  {
    displayIndex: 0,
    key: 'checkbox',
    label: 'Checkbox',
    field: 'checkbox',
    show: true,
    columnName: 'checkbox',
    sortField: 'checkbox',
    type: '',
    isField: false,
    sorted: false,
    isAsc: true,
    sortable: false,
    checkboxColumn: true,
    hideable: true,
    showCheckbox: 'true',
  },
  {
    displayIndex: 1,
    key: 'actions',
    label: 'Actions',
    field: 'actions',
    show: true,
    columnName: 'actions',
    sortField: 'actions',
    type: '',
    isField: false,
    sorted: false,
    isAsc: true,
    sortable: false,
    checkboxColumn: true,
    hideable: true,
    showCheckbox: 'true',
  },
  {
    displayIndex: 3,
    key: 'createdAt',
    label: 'Date',
    field: 'createdAt',
    show: true,
    value: 'created_at',
    type: 'date',
    sorted: true,
    isAsc: false,
    sortField: 'createdAt',
    isHidden: false,
    columnName: 'createdAt',
    isField: true,
    sortable: true,
    sortIndex: 1,
    hideable: true,
  },
  {
    displayIndex: 4,
    key: 'name',
    label: 'Document Name',
    field: 'name',
    show: true,
    value: 'name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'name',
    isHidden: false,
    columnName: 'name',
    isField: true,
    sortable: true,
    sortIndex: 2,
    hideable: true,
  },
  {
    displayIndex: 5,
    key: 'seller',
    label: 'Seller',
    field: 'seller',
    show: true,
    value: 'seller',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'seller',
    isHidden: false,
    columnName: 'seller',
    isField: true,
    sortable: false,
    hideable: true,
  },
  {
    displayIndex: 6,
    key: 'userEmail',
    label: 'User Email',
    field: 'userEmail',
    show: true,
    value: 'userEmail',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'userEmail',
    isHidden: false,
    columnName: 'userEmail',
    isField: false,
    sortable: false,
    hideable: true,
  },
  {
    displayIndex: 7,
    key: 'docType',
    label: 'Load / Invoice',
    field: 'docType',
    show: true,
    value: 'doc_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'docType',
    isHidden: false,
    columnName: 'docType',
    isField: true,
    sortable: true,
    sortIndex: 3,
    hideable: true,
  },
  {
    displayIndex: 8,
    key: 'buyerTerminalId',
    label: 'Buyer Terminal',
    field: 'buyerTerminalId',
    show: true,
    value: 'buyerTerminalId',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'buyerTerminalId',
    isHidden: false,
    columnName: 'buyerTerminalId',
    isField: true,
    sortable: false,
    hideable: true,
  },
  {
    displayIndex: 9,
    key: 'sellerTerminalId',
    label: 'Seller Terminal',
    field: 'sellerTerminalId',
    show: true,
    value: 'sellerTerminalId',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'sellerTerminalId',
    isHidden: false,
    columnName: 'sellerTerminalId',
    isField: true,
    sortable: false,
    hideable: true,
  },
  {
    displayIndex: 10,
    key: 'status',
    label: 'Status',
    field: 'status',
    show: true,
    value: 'status',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
    columnName: 'status',
    isField: true,
    sortable: true,
    sortIndex: 4,
    hideable: true,
  },
  {
    displayIndex: 11,
    key: 'totalPages',
    label: 'Total Pages',
    field: 'totalPages',
    show: true,
    value: 'total_pages',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'totalPages',
    isHidden: false,
    columnName: 'totalPages',
    isField: true,
    sortable: true,
    sortIndex: 5,
    hideable: true,
  },
  {
    label: 'File Type',
    field: 'fileType',
    show: false,
    value: 'file_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'fileType',
    isHidden: true,
    columnName: 'fileType',
    isField: true,
    sortable: true,
    sortIndex: 6,
    hideable: false,
  },
]

export default DEFAULT_DOCUMENT_COLUMNS
