import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectMyTableColumns } from '~/redux/selectors'
import { onUpdateUiColumns } from '~/redux/actions/CompanySubTabs/subTabUiActions'
import { deepCopy } from '~/utils/utils'
import { tablesWithoutColumnConfigurations } from '~/utils/constants'

const useCompanySubTabUi = subTabName => {
  const dispatch = useDispatch()
  const myCompanySavedDefaults = useSelector(selectMyTableColumns)
  const columns = useSelector(
    state => state.ui.companySubTabs[subTabName]?.columns || [],
  )
  const columnsDisplayed = useMemo(
    () =>
      columns
        .filter(c => c.hideable)
        .sort((a, b) => a.displayIndex - b.displayIndex),
    [columns],
  )
  const sortOptions = useMemo(
    () =>
      columns.filter(c => c.sortable).sort((a, b) => a.sortIndex - b.sortIndex),
    [columns],
  )
  const columnsFromUserTableConfigurations = useMemo(() => {
    if (tablesWithoutColumnConfigurations.includes(subTabName)) {
      return []
    }

    return myCompanySavedDefaults[subTabName]?.columnConfigurations
  }, [myCompanySavedDefaults, subTabName])

  const handleUpdateSortOptions = useCallback(
    updatedOptions => {
      const newOptions = deepCopy(sortOptions)

      updatedOptions.forEach((option, index) => {
        const updateIndex = newOptions.findIndex(
          column => column.field == option.field,
        )

        newOptions[updateIndex] = {
          ...option,
          sortIndex: index,
        }
      })

      dispatch(onUpdateUiColumns(newOptions, subTabName))
    },
    [sortOptions, subTabName, dispatch],
  )

  const handleUpdateColumns = useCallback(
    updateColumns => {
      const newColumns = deepCopy(columns)

      updateColumns.forEach((option, index) => {
        const updateIndex = newColumns.findIndex(
          column => column.field == option.field,
        )

        newColumns[updateIndex] = {
          ...option,
          displayIndex: index,
        }
      })

      dispatch(onUpdateUiColumns(newColumns, subTabName))
    },
    [columns, subTabName, dispatch],
  )

  return {
    columnsDisplayed,
    sortOptions,
    columnsFromUserTableConfigurations,
    handleUpdateSortOptions,
    handleUpdateColumns,
  }
}

export default useCompanySubTabUi
