import React, { useState, useCallback } from 'react'
import { IonThumbnail, IonImg, IonPopover } from '@ionic/react'

import PropTypes from 'prop-types'

const Thumbnail = React.forwardRef((props, ref) => {
  const { image, enablePopupImage, onImagePress, slot, style, className } =
    props

  const imgSource =
    image ||
    'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png'
  const [showPopup, setShowPopup] = useState(false)

  const handleImagePress = useCallback(() => {
    onImagePress && onImagePress()
    if (enablePopupImage) {
      setShowPopup(true)
    }
  }, [onImagePress, enablePopupImage])

  const hidePopup = useCallback(() => {
    setShowPopup(false)
  }, [])

  return (
    <IonThumbnail className={className} slot={slot} style={style} ref={ref}>
      <IonImg src={imgSource} onClick={handleImagePress} />
      {enablePopupImage && (
        <IonPopover
          cssClass={'imagePreviewPopup'}
          isOpen={showPopup}
          onDidDismiss={hidePopup}
        >
          <IonImg alt='popover' src={imgSource} />
        </IonPopover>
      )}
    </IonThumbnail>
  )
})

Thumbnail.propTypes = {
  image: PropTypes.string,
  enablePopupImage: PropTypes.bool,
  onImagePress: PropTypes.func,
  slot: PropTypes.oneOf(['start', 'end']),
  style: PropTypes.object,
  className: PropTypes.string,
}

Thumbnail.defaultProps = {
  enablePopupImage: true,
}

export default Thumbnail
