const buildObjectName = obj => {
  if (obj) {
    const label = [obj.code, obj.name]
    return label.filter(Boolean).join(' - ')
  }

  return ''
}

export default buildObjectName
