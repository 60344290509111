import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
// import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'

const setupSlice = createSlice({
  name: moduleName.company.setup,
  initialState: {
    columns: [],
  },
})

export default setupSlice
