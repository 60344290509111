import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  isValidElement,
  memo,
} from 'react'
import useOnClickOutside from '~/hooks/useOnClickOutside'
import { useUpdateEffect } from 'react-use'

import { IonIcon } from '@ionic/react'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FormatOptionSellerLabel from '~/components/load/CustomOption/FormatOptionSellerLabel'
import { createOutline } from 'ionicons/icons'
import { ToolTipOverlay } from '~/components/shared'
import DialogCompanyCreateEdit from '~/components/company/DialogCompanyCreateEdit'

import _ from 'lodash'
import clsx from 'clsx'
import { notSetOptionStyles } from '~/utils'
import PropTypes from 'prop-types'

import './SelectSearchDropdown.scss'

const CustomMenu = React.forwardRef((props, ref) => {
  const { style, ...restProps } = props

  return (
    <div ref={ref} {...restProps}>
      {props.children}
    </div>
  )
})

const SelectSearchDropdown = ({
  value,
  currentValue,
  onChange,
  subComponent,
  modalSize = '10vw',
  viewMode,
  isSellerField,
  className,
  extraComponent,
  CustomOptionComponent = null,
  isContainNotSet,
  placement,
  width,
  clickOutsideToCloseMenu,
  dropdownProps,
  editableCompany,
  styling = true,
  afterEditCompany,
  canEdit,
}) => {
  const showSearch = value && value.length > 3
  const [showMenu, setShowMenu] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [editCompanyDialog, setEditCompanyDialog] = useState({
    open: false,
    companyId: null,
  })

  const dropdownOptions = useMemo(() => {
    if (!value) {
      return []
    }

    if (isContainNotSet) {
      const removeNotSet = value.filter(
        ({ label }) => _.toLower(label) !== 'not set',
      )
      removeNotSet.push({
        value: null,
        label: 'Not Set',
      })

      return removeNotSet
    }

    return value
  }, [value, isContainNotSet])

  const elementRef = useRef()

  const handleCloseDropdown = useCallback(() => {
    setMenuIsOpen(false)
  }, [])

  const handleOpenDropdown = useCallback(() => {
    setMenuIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setShowMenu(false)
  }, [])

  const renderExtraComponent = useCallback(() => {
    if (isValidElement(extraComponent)) {
      return extraComponent
    }

    if (_.isFunction(extraComponent)) {
      return extraComponent({
        parentInputValue: inputValue,
        handleCloseDropdown,
        handleOpenDropdown,
        handleClose,
      })
    }

    return null
  }, [
    extraComponent,
    handleClose,
    handleCloseDropdown,
    handleOpenDropdown,
    inputValue,
  ])

  const handleToggleDropdownMenu = useCallback(() => {
    setMenuIsOpen(prev => !prev)
  }, [])

  const handleClickEditCompany = useCallback(() => {
    setEditCompanyDialog({
      open: true,
      companyId: currentValue?.value,
    })
  }, [currentValue?.value])

  const editCompanyRendered = useMemo(() => {
    if (editableCompany && currentValue?.value) {
      return (
        <ToolTipOverlay
          content={`Edit company ${currentValue?.label}`}
          placement='top'
        >
          <div
            className={styling && 'SelectSearchDropdown__editIcon'}
            onClick={handleClickEditCompany}
          >
            <IonIcon icon={createOutline} />
          </div>
        </ToolTipOverlay>
      )
    }

    return null
  }, [
    currentValue?.label,
    currentValue?.value,
    editableCompany,
    handleClickEditCompany,
    styling,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <div
          className={styling && clsx('SelectSearchDropdown__dropdownIndicator')}
        >
          {editCompanyRendered}
          <div
            onClick={handleToggleDropdownMenu}
            className={
              styling &&
              clsx('SelectSearchDropdown__dropdownIndicatorIcon', {
                collapsed: !menuIsOpen,
              })
            }
          >
            <components.DropdownIndicator {...props} />
          </div>
        </div>
      )
    )
  }

  const onChangeSelect = e => {
    onChange(e)
    setShowMenu(false)
  }

  const handleToggleMenu = useCallback(
    (nextValue, { originalEvent }) => {
      originalEvent.stopPropagation()
      if (canEdit) {
        setShowMenu(nextValue)
      }
    },
    [canEdit],
  )

  const handleChangeInputValue = useCallback(
    (nextValue, { action, prevInputValue }) => {
      if (action === 'menu-close') {
        setInputValue(prevInputValue)
      } else {
        setInputValue(nextValue)
      }
    },
    [],
  )

  const handleCloseCompanyCreateEdit = useCallback(() => {
    setEditCompanyDialog({
      open: false,
      companyId: null,
    })
  }, [])

  const handleAfterEditCompany = useCallback(
    updatedCompany => {
      handleCloseCompanyCreateEdit()
      afterEditCompany && afterEditCompany(updatedCompany)
    },
    [afterEditCompany, handleCloseCompanyCreateEdit],
  )

  const components = useMemo(() => {
    const commonOptions = {
      DropdownIndicator,
      IndicatorSeparator: null,
    }
    if (CustomOptionComponent) {
      commonOptions.Option = CustomOptionComponent
    }
  }, [CustomOptionComponent, DropdownIndicator])

  useOnClickOutside(elementRef, () => {
    if (!clickOutsideToCloseMenu) {
      return
    }
    setShowMenu(prev => {
      if (prev) {
        return false
      }
    })
  })

  useUpdateEffect(() => {
    if (!showMenu) {
      setInputValue('')
      setMenuIsOpen(true)
    }
  }, [showMenu])

  return (
    <>
      <Dropdown
        //style={styling && { width: '100%' }}
        onToggle={handleToggleMenu}
        show={!viewMode && showMenu}
        className={className}
        placement={placement}
      >
        <Dropdown.Toggle
          id='dropdown'
          variant='link'
          size='lg'
          role='menu'
          bsPrefix='p-0'
          className={styling && 'SelectSearchDropdown__dropdownToogle'}
        >
          {subComponent}
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            width: modalSize,
            padding: 0,
            border: 0,
            margin: '0',
          }}
          ref={elementRef}
          as={CustomMenu}
        >
          {showMenu && (
            <>
              <Select
                inputValue={inputValue}
                onInputChange={handleChangeInputValue}
                autoFocus
                classNamePrefix='react-select'
                components={components}
                options={dropdownOptions}
                value={currentValue}
                blurInputOnSelect
                menuIsOpen={menuIsOpen}
                isSearchable={showSearch}
                placeholder={showSearch ? 'Search' : 'Select An option'}
                onChange={onChangeSelect}
                formatOptionLabel={isSellerField ? FormatOptionSellerLabel : {}}
                styles={{
                  menu: provided => ({ ...provided, width: 250 }),
                  container: provided => ({ ...provided, width }),
                  ...notSetOptionStyles,
                }}
                {...dropdownProps}
              />
              {renderExtraComponent()}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <DialogCompanyCreateEdit
        {...editCompanyDialog}
        afterEditCompany={handleAfterEditCompany}
        onClose={handleCloseCompanyCreateEdit}
      />
    </>
  )
}

SelectSearchDropdown.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      image: PropTypes.string,
    }),
  ).isRequired,
  currentValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func,
  subComponent: PropTypes.object.isRequired,
  isSellerField: PropTypes.bool,
  extraComponent: PropTypes.func,
  isContainNotSet: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
}

SelectSearchDropdown.defaultProps = {
  placement: 'bottom',
  width: 250,
  clickOutsideToCloseMenu: true,
  dropdownProps: {},
  editableCompany: false,
}

export default memo(SelectSearchDropdown)
