import { IonChip, IonText } from '@ionic/react'
import { CompanyAvatar } from '~/components/shared/CompanyAvatar'
import { Unless } from 'react-if'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

import type { ICompanyChipProps } from './type'

import './styles.scss'
import clsx from 'clsx'

function CompanyChip(props: ICompanyChipProps) {
  const { company, companyType, isHiddenAvatar, tooltipMessage } = props

  return (
    <ToolTipOverlay content={tooltipMessage} placement='top'>
      <div
        className={clsx('CompanyChip__container', { hasAvatar: company.image })}
      >
        <IonChip
          style={{
            padding: '0 8px',
            margin: 0,
            marginRight: 0,
            marginLeft: 8,
          }}
          color={companyType}
        >
          <Unless condition={isHiddenAvatar}>
            <CompanyAvatar
              company={company}
              width={20}
              height={20}
              style={{ marginRight: 4 }}
              companyType={companyType}
            />
          </Unless>
          <IonText>{company?.label}</IonText>
        </IonChip>
      </div>
    </ToolTipOverlay>
  )
}

export default CompanyChip
