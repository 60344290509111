import { useMemo, useRef, useState } from 'react'
import { useReactTable } from '@tanstack/react-table'
import type {
  RTCell,
  RTColumn,
  IRTColumnDef,
  RTColumnFilterFnsState,
  RTColumnOrderState,
  RTColumnSizingInfoState,
  RTDefinedTableOptions,
  RTDensityState,
  RTFilterOption,
  RTGroupingState,
  RTPaginationState,
  RTRow,
  IReusableTableRowData,
  RTStatefulTableOptions,
  ReusableTableInstance,
  RTTableState,
  RTUpdater,
} from '../types'
import {
  getAllLeafColumnDefs,
  getColumnId,
  getDefaultColumnFilterFn,
  prepareColumns,
} from '../utils/column.utils'
import {
  getDefaultColumnOrderIds,
  showRowActionsColumn,
  showRowDragColumn,
  showRowExpandColumn,
  showRowNumbersColumn,
  showRowSelectionColumn,
  showRowSpacerColumn,
} from '../utils/displayColumn.utils'
import { createRow } from '../utils/tanstack.helpers'
import { getRTRowActionsColumnDef } from './display-columns/getRTRowActionsColumnDef'
import { getRTRowDragColumnDef } from './display-columns/getRTRowDragColumnDef'
import { getRTRowNumbersColumnDef } from './display-columns/getRTRowNumbersColumnDef'
import { getRTRowSelectColumnDef } from './display-columns/getRTRowSelectColumnDef'
import { getRTRowSpacerColumnDef } from './display-columns/getRTRowSpacerColumnDef'
import { useRTEffects } from './useRTEffects'
import { getRTRowExpandColumnDef } from './display-columns/getRTRowExpandColumnDef'

/**
 * The MRT hook that wraps the TanStack useReactTable hook and adds additional functionality
 * @param definedTableOptions - table options with proper defaults set
 * @returns the MRT table instance
 */
export const useRTTableInstance = <TData extends IReusableTableRowData>(
  definedTableOptions: RTDefinedTableOptions<TData>,
): ReusableTableInstance<TData> => {
  const lastSelectedRowId = useRef<null | string>(null)
  const actionCellRef = useRef<HTMLTableCellElement>(null)
  const bottomToolbarRef = useRef<HTMLDivElement>(null)
  const editInputRefs = useRef<Record<string, HTMLInputElement>>({})
  const filterInputRefs = useRef<Record<string, HTMLInputElement>>({})
  const searchInputRef = useRef<HTMLInputElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableHeadCellRefs = useRef<Record<string, HTMLTableCellElement>>({})
  const tablePaperRef = useRef<HTMLDivElement>(null)
  const topToolbarRef = useRef<HTMLDivElement>(null)
  const tableHeadRef = useRef<HTMLTableSectionElement>(null)
  const tableFooterRef = useRef<HTMLTableSectionElement>(null)

  //transform initial state with proper column order
  const initialState: Partial<RTTableState<TData>> = useMemo(() => {
    const initState = definedTableOptions.initialState ?? {}
    initState.columnOrder =
      initState.columnOrder ??
      getDefaultColumnOrderIds({
        ...definedTableOptions,
        state: {
          ...definedTableOptions.initialState,
          ...definedTableOptions.state,
        },
      } as RTStatefulTableOptions<TData>)
    initState.globalFilterFn = definedTableOptions.globalFilterFn ?? 'fuzzy'
    return initState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  definedTableOptions.initialState = initialState

  const [actionCell, setActionCell] = useState<RTCell<TData> | null>(
    initialState.actionCell ?? null,
  )
  const [outsideElement, setOutsideElement] = useState<JSX.Element | null>(
    initialState.outsideElement || null,
  )
  const [creatingRow, _setCreatingRow] = useState<RTRow<TData> | null>(
    initialState.creatingRow ?? null,
  )
  const [columnFilterFns, setColumnFilterFns] =
    useState<RTColumnFilterFnsState>(() =>
      Object.assign(
        {},
        ...getAllLeafColumnDefs(
          definedTableOptions.columns as IRTColumnDef<TData>[],
        ).map(col => ({
          [getColumnId(col)]:
            col.filterFn instanceof Function
              ? col.filterFn.name ?? 'custom'
              : col.filterFn ??
                initialState?.columnFilterFns?.[getColumnId(col)] ??
                getDefaultColumnFilterFn(col),
        })),
      ),
    )
  const [columnOrder, onColumnOrderChange] = useState<RTColumnOrderState>(
    initialState.columnOrder ?? [],
  )
  const [columnSizingInfo, onColumnSizingInfoChange] =
    useState<RTColumnSizingInfoState>(
      initialState.columnSizingInfo ?? ({} as RTColumnSizingInfoState),
    )
  const [density, setDensity] = useState<RTDensityState>(
    initialState?.density ?? 'comfortable',
  )
  const [draggingColumn, setDraggingColumn] = useState<RTColumn<TData> | null>(
    initialState.draggingColumn ?? null,
  )
  const [draggingRow, setDraggingRow] = useState<RTRow<TData> | null>(
    initialState.draggingRow ?? null,
  )
  const [editingCell, setEditingCell] = useState<RTCell<TData> | null>(
    initialState.editingCell ?? null,
  )
  const [editingRow, setEditingRow] = useState<RTRow<TData> | null>(
    initialState.editingRow ?? null,
  )
  const [globalFilterFn, setGlobalFilterFn] = useState<RTFilterOption>(
    initialState.globalFilterFn ?? 'fuzzy',
  )
  const [grouping, onGroupingChange] = useState<RTGroupingState>(
    initialState.grouping ?? [],
  )
  const [hoveredColumn, setHoveredColumn] = useState<Partial<
    RTColumn<TData>
  > | null>(initialState.hoveredColumn ?? null)
  const [hoveredRow, setHoveredRow] = useState<Partial<RTRow<TData>> | null>(
    initialState.hoveredRow ?? null,
  )
  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    initialState?.isFullScreen ?? false,
  )
  const [pagination, onPaginationChange] = useState<RTPaginationState>(
    initialState?.pagination ?? { pageIndex: 0, pageSize: 25 },
  )
  const [showAlertBanner, setShowAlertBanner] = useState<boolean>(
    initialState?.showAlertBanner ?? false,
  )
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(
    initialState?.showColumnFilters ?? false,
  )
  const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(
    initialState?.showGlobalFilter ?? false,
  )
  const [showToolbarDropZone, setShowToolbarDropZone] = useState<boolean>(
    initialState?.showToolbarDropZone ?? false,
  )

  definedTableOptions.state = {
    actionCell,
    columnFilterFns,
    columnOrder,
    columnSizingInfo,
    creatingRow,
    density,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    globalFilterFn,
    grouping,
    hoveredColumn,
    hoveredRow,
    isFullScreen,
    pagination,
    showAlertBanner,
    showColumnFilters,
    showGlobalFilter,
    showToolbarDropZone,
    outsideElement,
    ...definedTableOptions.state,
  }

  //The table options now include all state needed to help determine column visibility and order logic
  const statefulTableOptions =
    definedTableOptions as RTStatefulTableOptions<TData>

  //don't recompute columnDefs while resizing column or dragging column/row
  const columnDefsRef = useRef<IRTColumnDef<TData>[]>([])
  statefulTableOptions.columns =
    statefulTableOptions.state.columnSizingInfo.isResizingColumn ||
    statefulTableOptions.state.draggingColumn ||
    statefulTableOptions.state.draggingRow
      ? columnDefsRef.current
      : prepareColumns({
          columnDefs: [
            ...([
              showRowSelectionColumn(statefulTableOptions) &&
                getRTRowSelectColumnDef(statefulTableOptions),

              showRowDragColumn(statefulTableOptions) &&
                getRTRowDragColumnDef(statefulTableOptions),

              showRowExpandColumn(statefulTableOptions) &&
                getRTRowExpandColumnDef(statefulTableOptions),

              showRowActionsColumn(statefulTableOptions) &&
                getRTRowActionsColumnDef(statefulTableOptions),

              showRowNumbersColumn(statefulTableOptions) &&
                getRTRowNumbersColumnDef(statefulTableOptions),
            ].filter(Boolean) as IRTColumnDef<TData>[]),
            ...statefulTableOptions.columns,
            ...([
              showRowSpacerColumn(statefulTableOptions) &&
                getRTRowSpacerColumnDef(statefulTableOptions),
            ].filter(Boolean) as IRTColumnDef<TData>[]),
          ],
          tableOptions: statefulTableOptions,
        })
  columnDefsRef.current = statefulTableOptions.columns

  //if loading, generate blank rows to show skeleton loaders
  statefulTableOptions.data = useMemo(
    () =>
      (statefulTableOptions.state.isLoading ||
        statefulTableOptions.state.showSkeletons) &&
      !statefulTableOptions.data.length
        ? [
            ...Array(
              Math.min(statefulTableOptions.state.pagination.pageSize, 20),
            ).fill(null),
          ].map(() =>
            Object.assign(
              {},
              ...getAllLeafColumnDefs(statefulTableOptions.columns).map(
                col => ({
                  [getColumnId(col)]: null,
                }),
              ),
            ),
          )
        : statefulTableOptions.data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      statefulTableOptions.data,
      statefulTableOptions.state.isLoading,
      statefulTableOptions.state.showSkeletons,
    ],
  )

  //@ts-ignore
  const table = useReactTable({
    onColumnOrderChange,
    onColumnSizingInfoChange,
    onGroupingChange,
    onPaginationChange,
    ...statefulTableOptions,
    globalFilterFn: statefulTableOptions.filterFns?.[globalFilterFn ?? 'fuzzy'],
  }) as ReusableTableInstance<TData>

  table.refs = {
    actionCellRef,
    bottomToolbarRef,
    editInputRefs,
    filterInputRefs,
    lastSelectedRowId,
    searchInputRef,
    tableContainerRef,
    tableFooterRef,
    tableHeadCellRefs,
    tableHeadRef,
    tablePaperRef,
    topToolbarRef,
  }

  table.setActionCell = statefulTableOptions.onActionCellChange ?? setActionCell
  table.setOutsideElement = setOutsideElement
  table.setCreatingRow = (row: RTUpdater<RTRow<TData> | null | true>) => {
    let _row = row
    if (row === true) {
      _row = createRow(table)
    }
    statefulTableOptions?.onCreatingRowChange?.(_row as RTRow<TData> | null) ??
      _setCreatingRow(_row as RTRow<TData> | null)
  }
  table.setColumnFilterFns =
    statefulTableOptions.onColumnFilterFnsChange ?? setColumnFilterFns
  table.setDensity = statefulTableOptions.onDensityChange ?? setDensity
  table.setDraggingColumn =
    statefulTableOptions.onDraggingColumnChange ?? setDraggingColumn
  table.setDraggingRow =
    statefulTableOptions.onDraggingRowChange ?? setDraggingRow
  table.setEditingCell =
    statefulTableOptions.onEditingCellChange ?? setEditingCell
  table.setEditingRow = statefulTableOptions.onEditingRowChange ?? setEditingRow
  table.setGlobalFilterFn =
    statefulTableOptions.onGlobalFilterFnChange ?? setGlobalFilterFn
  table.setHoveredColumn =
    statefulTableOptions.onHoveredColumnChange ?? setHoveredColumn
  table.setHoveredRow = statefulTableOptions.onHoveredRowChange ?? setHoveredRow
  table.setIsFullScreen =
    statefulTableOptions.onIsFullScreenChange ?? setIsFullScreen
  table.setShowAlertBanner =
    statefulTableOptions.onShowAlertBannerChange ?? setShowAlertBanner
  table.setShowColumnFilters =
    statefulTableOptions.onShowColumnFiltersChange ?? setShowColumnFilters
  table.setShowGlobalFilter =
    statefulTableOptions.onShowGlobalFilterChange ?? setShowGlobalFilter
  table.setShowToolbarDropZone =
    statefulTableOptions.onShowToolbarDropZoneChange ?? setShowToolbarDropZone

  useRTEffects(table)

  return table
}
