import React from 'react'
import ReactDOM from 'react-dom/client'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { setUseWhatChange } from '@simbathesailor/use-what-changed'

import App from './components/App'
import { postHogInit } from './components/posthog/index'
import LocalizedProvider from './locales/LocalizedProvider'
import AppProvider from './components/AppProvider'
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import { CookiesProvider } from 'react-cookie'
import { StorageProvider } from '~/hooks/useStorage'

import 'bootstrap/dist/css/bootstrap.min.css'
import './themes/app/custom.scss'
import './styles/styles.scss'
import './styles/ipad.scss'

require('./favicon.ico') // Tell webpack to load favicon.ico

postHogInit()
setUseWhatChange(process.env.NODE_ENV === 'development')

// Load service worker
if (process.env.ENABLE_PWA) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/main-sw.js')
  })
}

const rootElement = document.getElementById('app') as Element
const root = ReactDOM.createRoot(rootElement)

const renderApp = (Component: React.ElementType) => {
  root.render(
    <CookiesProvider>
      <ActionCableProvider url={process.env.REACT_APP_CABLE_URL}>
        <LocalizedProvider>
          <AppProvider>
            <StorageProvider>
              <Component />
            </StorageProvider>
          </AppProvider>
        </LocalizedProvider>
      </ActionCableProvider>
    </CookiesProvider>,
  )

  // Call the element loader after the app has been rendered the first time
  defineCustomElements(window)
}

renderApp(App)

if (module.hot) {
  module.hot.accept('./components/App', () => {
    renderApp(App)
  })
}
