import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ListGroup } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'

import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectUsersDriverFleet } from '~/redux/selectors'

import type { ILoadStatusModalProps } from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import useGeolocation from '~/hooks/useGeolocation'
import { ILoadStatus } from '~/types/models/ILoad'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'

const useLoadStatusModal = (props: ILoadStatusModalProps) => {
  const { isOpen, onClose, loadData, onChangeLoadStatus } = props

  const driverFleet: IDriverFleet = useSelector(selectUsersDriverFleet)

  const { locationAttributes } = useGeolocation()
  const { getLoadStatus, getLoadStatusesInSection } = useQueryLoadStatuses()

  const onClickStatusItem = useCallback(
    (status: string) => () => {
      apiClient.loadTimes.create({
        loadTime: {
          loadId: loadData.id,
          loadStatusCode: getLoadStatus(status)?.code,
          time: new Date().toISOString(),
          breadcrumbLoadAttributes: {
            loadId: loadData.id,
            driverFleetId: driverFleet.id,
            truckFleetId:
              loadData.truckFleetId ||
              (driverFleet.currentTruckFleetId as number),
            locationAttributes: {
              eventType: status,
              source: 'Concord',
              lat: locationAttributes?.lat as number,
              lng: locationAttributes?.lng as number,
              time: locationAttributes?.time as string,
            },
          },
        },
      })
      onClose && onClose()
      onChangeLoadStatus &&
        onChangeLoadStatus(
          { ...loadData, status: status as ILoadStatus },
          status,
        )
      toast.success(toastMessages.updateSuccess)
    },
    [
      driverFleet?.currentTruckFleetId,
      driverFleet?.id,
      getLoadStatus,
      loadData,
      locationAttributes?.lat,
      locationAttributes?.lng,
      locationAttributes?.time,
      onChangeLoadStatus,
      onClose,
    ],
  )

  const renderStatuses = useMemo(() => {
    const statuses = getLoadStatusesInSection(loadData.status)
    if (statuses) {
      return statuses.map((status, index) => (
        <ListGroup.Item
          key={index}
          action
          className='LoadStatusModal__statusItem'
          active={loadData.status === status.name}
          onClick={onClickStatusItem(status.name)}
        >
          <span className='icon'>
            <IonIcon icon={status.icon} />
          </span>
          <span>
            {status.code} {status.name}
          </span>
        </ListGroup.Item>
      ))
    }
    return null
  }, [getLoadStatusesInSection, loadData.status, onClickStatusItem])

  return {
    isOpen,
    onClose,
    renderStatuses,
  }
}

export default useLoadStatusModal
