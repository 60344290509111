const en = {
  // Login Page
  'login.title': 'Login',
  'login.signup': "Don't have an account? Create one",

  'login.form.email': 'Email',
  'login.form.password': 'Password',
  'login.form.submit': 'Sign In',

  'login.forgot_password': 'Forgot your password?',

  // Sign up Page
  'signup.title': 'Sign up',
  'signup.login': 'Already have an account? Log in',
  'signup.client.title': 'Sign up as a Buyer or Seller',
  'signup.company.title': 'Submit New Company',
  'signup.user.title': 'Create User for Company',
  'signup.driver.title': 'Sign up as a Driver',
  'signup.option.driver': 'As a driver',
  'signup.option.client': 'As a buyer or seller',
  'signup.option.company': 'Submit New Company',
  'signup.option.user': 'Sign Up A new User',
  'signup.signin': 'Sign in',
  'signup.form.email': 'Email',
  'signup.form.password': 'Password',
  'signup.form.passconfirmation': 'Password confirmation',
  'signup.form.userType': 'User Type',
  'signup.form.firstName': 'First Name',
  'signup.form.middleName': 'Middle Name',
  'signup.form.lastName': 'Last Name',
  'signup.form.phoneNumber': 'Phone Number',
  'signup.form.color': 'Color',
  'signup.form.company': 'Company',
  'signup.form.licenseNumber': 'License Number',
  'signup.form.licenseState': 'License State',
  'signup.form.licenseExpiration': 'License Expiration',
  'signup.form.medicalNumber': 'Medical Number',
  'signup.form.medicalExpiration': 'Medical Expiration',
  'signup.form.mvrNumber': 'MVR Number',
  'signup.form.user_group': 'User Group',

  // Side Menu
  Accounts: 'Accounts',
  Barcode: 'Barcode',
  Channels: 'Channels',
  Documents: 'Documents',
  Fleets: 'Fleets',
  Products: 'Products',
  Invoices: 'Invoices',
  Orders: 'Orders',
  Settings: 'Settings',
  Trucks: 'Trucks',
  Reminders: 'Reminders',
  Drivers: 'Drivers',
  'Fleet Map': 'Fleet Map',
  'Review Loads': 'Review Loads',
  'Order Tracking': 'Order Tracking',
  'Load Capture': 'Load Capture',
  'My Company': 'My Company',
  'Document Processing': 'Document Processing',
  'Order Reminders': 'Order Reminders',
  Dashboard: 'Dashboard',
  Loads: 'Loads',
  'Start Times': 'Start Times',
  Itinerary: 'Itinerary',
  'Scan Tickets': 'Scan Tickets',
  'Truck Profile': 'Truck Profile',
  'Driver Profile': 'Driver Profile',
  'Past Loads': 'Past Loads',
  'View Map': 'View Map',
  'Current Load': 'Current Load',
  'Create Order': 'Create Order',

  //  Driver SideMenu options
  'sidebar.home': 'Documents',
  'sidebar.driverProfile': 'Driver Profile',
  'sidebar.truckProfile': 'Truck Profile',
  'sidebar.currentLoad': 'Current Load',
  'sidebar.previousLoad': 'Previous Loads',
  'sidebar.invoices': 'Invoices',
  'sidebar.lastStatus': 'Last Status',
  'sidebar.viewMap': 'View Map',
  'sidebar.order': 'Order',
  'sidebar.tickets': 'Tickets',
  'sidebar.chat': 'Chat',
  'sidebar.pastLoad': 'Past Loads',
  'sidebar.reminders': 'Order Reminders',
  'sidebar.orders': 'Orders',
  'sidebar.drivers': 'Drivers',
  'sidebar.trucks': 'Trucks',
  'sidebar.channels': 'Channels',
  'sidebar.fleetMap': 'Fleet Map',
  'sidebar.settings': 'Settings',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.accounts': 'Accounts',
  'sidebar.adminDashboard': 'Admin Dashboard',
  'sidebar.reviewLoad': 'Loads',
  'sidebar.fleets': 'Fleets',
  'sidebar.products': 'Products',
  'sidebar.myCompany': 'My Company',
  'sidebar.barcode': 'Barcode',
  'sidebar.scanTicket': 'Scan Tickets',
  'sidebar.itinerary': 'Itinerary',
  'sidebar.orderTracking': 'Order Tracking',

  'profile.form.firstName': 'First Name',
  'profile.form.middleName': 'Middle Name',
  'profile.form.lastName': 'Last Name',
  'profile.form.suffix': 'Suffix',
  'profile.form.email': 'Email',
  'profile.form.phoneNumber': 'Phone Number',
  'profile.form.licenseNumber': 'License Number',
  'profile.form.licenseState': 'License State',
  'profile.form.licenseExpiration': 'License Expiration',
  'profile.form.medicalNumber': 'Medical Number',
  'profile.form.medicalExpiration': 'Medical Expiration',
  'profile.form.mvrNumber': 'MVR Number',
  'profile.form.dob': 'Date of Birth',

  'truckProfile.form.name': 'Name',
  'truckProfile.form.vehicleId': 'Vehicle ID',
  'truckProfile.form.label': 'Label',
  'truckProfile.form.vin': 'VIN',
  'truckProfile.form.year': 'Year',
  'truckProfile.form.make': 'Make',
  'truckProfile.form.model': 'Model',
  'truckProfile.form.licensePlate': 'License Plate',
  'truckProfile.form.gpsInterval': 'GPS Interval',
  'truckProfile.form.bedType': 'Bed Type',
  'truckProfile.form.recordBreadcrumbs': 'Record Breadcrumbs?',
  'truckProfile.form.maxqty': 'Max Quantity',
  'truckProfile.form.registrationExpirationDate':
    'Registration Expiration Date',
  'truckProfile.form.inspectionExpirationDate': 'Inspection Expiration Date',
  'truckProfile.form.weightPermitNum': 'Weight Permit',
  'truckProfile.form.weightAllowance': 'Weight Allowance',
  'truckProfile.form.height': 'Height',
  'truckProfile.form.length': 'Length',
  'truckProfile.form.axleWeight': 'Axle Weight',
  'truckProfile.form.totalWeight': 'Total Weight',
  'truckProfile.form.emptyWeight': 'Empty Weight',
  'form.cancel': 'Cancel',

  'checked.driverSignature': 'Driver Signed',
  'checked.clientSignature': 'Client Signed',

  'signaturePadModal.driverTitle': 'Driver Signature',
  'signaturePadModal.clientTitle': 'Client Signature',
  'signaturePadModal.successMessage': 'Sucessfully signed!',
  'instructionsModal.title': 'Driver Instructions',
  'instructionsModal.emptyState': 'No instructions provided',
  'currentLoad.emptyState': 'No load assigned',
  'currentLoad.newLoadLink': 'Select one',
  'notesModal.title': 'Load Notes',
  'notesModal.noteCard.deleteAlert.cancelButton': 'Cancel',
  'notesModal.noteCard.deleteAlert.confirmButton': 'Confirm',
  'notesModal.noteCard.deleteAlert.header': 'Delete Note',
  'notesModal.noteCard.deleteAlert.message': 'Are you sure?',

  'Log Out': 'Logout',
  'logout.button': 'LOGOUT',

  'home.welcome': 'Welcome to Concord {email}',

  'document.form.name': 'Name',
  'document.form.file': 'Select file(s)',
  'document.form.url': 'Add  Urls',
  'document.form.optionalFileType': 'Select File Input Type',
  'document.form.seller': 'Seller',
  'document.form.type': 'Select document type',
  'document.form.upload': 'Upload',
  'document.form.buyerTerminal': 'Buyer Terminal',

  'createRegion.form.status': 'Select Status',
  'createRegion.form.code': 'Code',
  'createRegion.form.upload': 'Upload',
  'createRegion.code.presence': 'You must enter a code',
  'createRegion.form.name': 'Name',
  'createRegion.name.presence': 'You must enter name',
  'createRegion.form.maxLoadSize': 'Max Load Size',
  'createRegion.form.onSiteTime': 'On Site Time',
  'createRegion.form.earliestArrivalTime': 'Earliest Arrival Time',
  'createRegion.form.latestArrivalTime': 'Latest Arrival Time',
  'createRegion.form.callPhoneNumber': 'Call Phone Number',
  'createRegion.form.alertPhoneNumber': 'Alert Phone Munber',
  'createRegion.form.fullAddress': 'Full Address',
  'createRegion.name.parentName': 'Select One',
  'createRegion.name.empty': 'Cannot Be empty',
  'createRegion.name.invalidTime': 'Invalid Time',

  // Errors
  'email.presence': 'You must enter an email to continue',
  'email.invalid': 'You must enter a valid email',
  'password.presence': 'You must enter a password to continue',
  'passwordConfirmation.presence':
    'You must enter a password confirmation to continue',
  'passwordConfirmation.equality':
    'Your password confirmation must be equal to the password',
  'company.presence': 'You must select a company',

  'firstName.presence': 'You must enter your first name',
  'lastName.presence': 'You must enter your last name',
  'phoneNumber.presence': 'You must enter your phone number',

  'model.presence': "You must enter the truck's model",
  'year.presence': "You must enter the truck's year",

  'document.name': "You must enter document's name",
  'document.file': 'You must select an image or pdf file',
  'document.seller': 'You must select a seller',

  'button.cancel': 'Cancel',
  'button.submit': 'Submit',
  'button.save': 'Save',

  'company.form.valid.name': 'You must enter Company name',
  'company.form.valid.legalName': 'You must enter Legal name',
  'company.form.valid.dateFormat': 'Invalid format',
  'company.form.name': 'Name',
  'company.form.legalName': 'Legal name',
  'company.form.image': 'Image',
  'company.form.buyer': 'Buyer',
  'company.form.seller': 'Seller',
  'company.form.fleet': 'Fleet',
  'company.form.status': 'Status',
  'company.form.parentCompany': 'Parent company',
  'company.form.invoiceInterval': 'Invoice interval',
  'company.form.dateFormat': 'Date format',
  'company.form.invoiceCollection': 'Invoice Collection',
  'company.form.ticketCollection': 'Ticket Collection',
  'company.form.parserType': 'Parser Type',
  'company.form.deskewInvoice': 'Invoice Date Format',
  'company.form.firstNumberofPages': 'First Number Of Pages',
  'company.form.lastNumberofPages': 'Last Number Of Pages',
  'company.form.removePageRanges':
    'Remove Page Ranges (eg. "[1,3],[4,7],[8,10]")',
  'company.form.regexToSkipBillLine': 'Regrex To Skip Bill Line',
  'company.form.blDateFormat': 'Bill Line Date Format',
  'company.form.code': 'code',
  'company.form.useFuzzyMatching': 'Use Fuzzy Matching',
  'company.form.createRelationsOnTheFly': 'Create Relations On the Fly',
  'company.form.timezone': 'Timezone',
  'company.form.checkForSplit': 'Check For Split',

  'onboarding.form.buyerterminal': 'Buyer Terminal',
  'onboarding.form.sellerterminal': 'Seller Terminal',
  'onboarding.form.plant': 'Product',
  'onboarding.form.productCode': 'Product Code',
  'onboarding.form.productDesc': 'Product Description',
  'onboarding.form.fleet': 'Fleet',
  'onboarding.form.fobprice': 'FOB Price',
  'onboarding.form.devprice': 'Delivered price',
  'onboarding.form.loadsize': 'Load Size',
  'onboarding.form.lds': 'LDS',
  'onboarding.form.priority': 'Priority Deadline',
  'onboarding.form.target': 'Target Deadline',
  'onboarding.form.final': 'Final Deadline',
  'onboarding.form.cadence': 'Reminder Cadence',
  'onboarding.form.weektype': 'Weekend Type',
  'onboarding.form.status': 'Status',
  'onboarding.form.upload': 'Upload',
  'onboarding.form.buyer': 'Buyer',
  'onboarding.form.seller': 'Seller',
  'onboarding.form.deliveryMethod': 'Delivery Method',
  'onboarding.form.billingMethod': 'Billing Method',
  'onboarding.form.qty': 'Qty',
  'onboarding.form.fobDiscount': 'FOB Discount',
  'onboarding.form.deliveredDiscount': 'Delivered Discount',

  'onboarding.form.valid.selection': 'Select Atleast One',
  'onboarding.form.valid.text': 'Required',

  'dataLink.form.term': 'Term',
  'dataLink.form.model': 'Model',
  'dataLink.form.column': 'Column',
  'dataLink.form.upload': 'Upload',

  'dataParser.form.parser': 'Parser',
  'dataParser.form.collection': 'Collection',
  'dataParser.form.documentType': 'Document Type',
  'dataParser.form.companyId': 'Company',
  'dataParser.form.valid.company': 'Please Select a company',

  'dataLink.form.valid.term': 'Term Required',
  'dataLink.form.valid.model': 'Model Required',
  'dataLink.form.valid.column': 'Column Required',
  'dataParser.form.valid.parser': 'Parser Required',
  'dataParser.form.valid.collection': 'Collection Required',
  'dataParser.form.valid.documentType': 'Select this for parsers',

  'companyFleet.form.fleet': 'Fleet',
  'companyFleet.form.rank': 'Rank',
  'companyFleet.form.status': 'Status',

  'emailParsers.form.buyer': 'Buyer',
  'emailParsers.form.buyerRegex': 'Buyer Regex',
  'emailParsers.form.seller': 'Seller',
  'emailParsers.form.docTypeRegex': 'Doc Type Regex',
  'emailParsers.form.docTypeValue': 'Doc Type Value',
  'emailParsers.form.sellerRegex': 'Seller Regex',

  'infoParser.form.company': 'Company',
  'infoParser.form.model': 'Model',
  'infoParser.form.parsingColumn': 'Parsing Column',
  'infoParser.form.assignColumn': 'Assign Column',
  'infoParser.form.regexp': 'Regexp',

  'infoParser.form.valid.company': 'Required a Company',
  'infoParser.form.valid.model': 'Model Required',
  'infoParser.form.valid.parsingColumn': 'Parsing Column Required',
  'infoParser.form.valid.assignColumn': 'Assign Column Required',
  'infoParser.form.valid.regexp': 'Regexp Required',

  'fleet.form.code': 'Code',
  'fleet.form.name': 'Name',
  'fleet.form.email': 'Email',
  'fleet.form.valid.code': 'Required',

  'product.form.name': 'Name',
  'product.form.code': 'Code',
  'product.form.description': 'Description',
  'product.form.productType': 'Product Type',
  'product.form.status': 'Status',

  'companyProduct.form.companyType': 'Company Type',
  'companyProduct.form.company': 'Company',
  'companyProduct.form.product': 'Seller Product',
  'companyProduct.form.status': 'Status',
  'companyProduct.form.primary': 'Primary',
  'companyProduct.form.code': 'Code',
  'companyProduct.form.name': 'Name',
  'companyProduct.form.description': 'Description',
  'companyProduct.form.price': 'Price',

  'company.form.valid.company': 'Select company Type for this',
  'company.form.valid.seller': 'Select Company before. Required!',

  'buyerSeller.form.buyerCode': 'Buyer Code',
  'buyerSeller.form.sellerCode': 'Seller Code',
  'buyerSeller.form.buyerName': 'Buyer Name',
  'buyerSeller.form.sellerName': 'Seller Name',
  'buyerSeller.form.company': 'Company',
  'buyerSeller.form.buyerStatus': 'Buyer Status',
  'buyerSeller.form.sellerStatus': 'Seller Status',
  'buyerSeller.form.invoice': 'Invoice Interval',
  'buyerSeller.form.dataMethod': 'Data Matching Method',

  'userAccess.form.status': 'Status',
  'userAccess.form.default': 'Default',
  'userAccess.form.recieve': 'Receive Order Emails',

  'autoUpdate.form.columnName': 'Column Name',
  'autoUpdate.form.model': 'Model',
  'autoUpdate.form.companyId': 'Company',
  'autoUpdate.form.method': 'Method',
  'autoUpdate.form.rankMethod': 'Rank Method',
  'autoUpdate.form.argument': 'Argument',
  'autoUpdate.form.rankArgument': 'Rank Argument',

  'autoCharge.form.buyerSeller': 'Seller Buyer',
  'autoCharge.form.buyerProduct': 'Product',
  'autoCharge.form.allocatedPer': 'Allocated Per',
  'autoCharge.form.applicationLevel': 'Application Level',
  'dataCorrection.form.company': 'Company',
  'dataCorrection.form.model': 'Model',
  'dataCorrection.form.formatRegex': 'Format Regrex',
  'dataCorrection.form.fieldLength': 'Field Length',
  'dataCorrection.form.columnName': 'Column Name',
  'dataCorrection.form.correctionDict':
    'Correction Dict(eg - "{gsub_correction: { "O": "0" },gsub_with_regexp_correction: { "E\\w{2}": "EV0" },remove_whitespace: true}")',
  'dataCorrection.form.array':
    'Fuzzy Matching Array(String followed by comma eg - s1,s2,s3,s4)',

  'fleetForm.form.pricePerQty': 'Price Per Qty',
  'fleetForm.form.flatRate': 'Flat Rate',
  'fleetForm.form.origin': 'Buyer Terminal',
  'fleetForm.form.destination': 'Seller Terminal',
  'fleetForm.form.travel': 'Travel Time',
  'fleetForm.form.distance': 'Distance',
  'fleetForm.form.directions': 'Directions',
  'fleetForm.form.summary': 'Summary',
  'fleetForm.form.buyersellerProduct': 'Product',
  'loadForm.form.qty': 'Enter Correct number',
  'update.form.update': 'Update',

  // Seller Terminal Form

  'sellerTerminal.form.sellerCode': 'Terminal Code',
  'sellerTerminal.form.sellerName': 'Terminal Name',
  'sellerTerminal.form.valid.sellerCode': 'Required',
  'sellerTerminal.form.valid.sellerName': 'Required',

  // Buyer Terminal Form
  'buyerTerminal.form.buyerCode': 'Terminal Code',
  'buyerTerminal.form.buyerName': 'Terminal Name',
  'buyerTerminal.form.valid.buyerCode': 'Required',
  'buyerTerminal.form.valid.buyerName': 'Required',

  // Product Form
  'product.form.productCode': 'Product Code',
  'product.form.productName': 'Product Name',
  'product.form.valid.productCode': 'Required',
  'product.form.valid.productName': 'Required',

  // Seller Form
  'seller.form.sellerCode': 'Seller Code',
  'seller.form.sellerName': 'Seller Name',
}

export default en
