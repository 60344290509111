import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'

import type { IUser } from '~/types/models/IUser'
import type { IEmail, IGetEmailsParams } from '~/types/models/IEmail'
import _ from 'lodash'
import useModifyEmails from './useModifyEmails'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryEmails = (
  params: Partial<IGetEmailsParams> = {},
  options?: Partial<UseQueryOptions<IEmail[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'emails',
      sessionUser?.id,
      buildGetUrl(apiClient.emails.endpoint, params),
    ],
    async queryFn() {
      const res = await apiClient.emails.get(params)
      return res.emails
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addEmail, updateEmail, removeEmail } = useModifyEmails(params)

  const getEmailTypeLabels = (emailTypes: EEmailTypes[]) => {
    const label: string[] = []
    emailTypes.filter(num => label.push(_.startCase(EEmailTypes[num])))
    return label.join(', ')
  }

  const emailsData = useMemo(() => data || [], [data])

  const findEmailById = (id: number) => emailsData.find(e => e.id === id)

  const companyEmailsData = useMemo(
    () =>
      emailsData.filter(
        ({ emailableType }) => EEmailableType.company === emailableType,
      ),
    [emailsData],
  )

  const companyEmailOptions = useMemo(
    () =>
      companyEmailsData.map(({ id, email, emailTypes }) => {
        const types = getEmailTypeLabels(emailTypes as EEmailTypes[])
        return {
          value: id,
          label: `${email} (${types})`,
        }
      }),
    [companyEmailsData],
  )

  const hrEmail = useMemo(
    () =>
      emailsData.find(({ emailTypes }) =>
        (emailTypes as EEmailTypes[]).includes(EEmailTypes.hr),
      ),
    [emailsData],
  )

  const firstOrHrEmail = useMemo<IEmail | undefined>(
    () => hrEmail || emailsData[0],
    [emailsData, hrEmail],
  )

  return {
    emailsData,
    isLoadingEmailsData: isLoading,
    companyEmailsData,
    companyEmailOptions,
    isEmailsFetched: isFetched,
    hrEmail,
    firstOrHrEmail,
    addEmail,
    updateEmail,
    removeEmail,
    findEmailById,
  }
}

export default useQueryEmails
