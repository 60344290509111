import { createSelector } from 'reselect'
import ct from 'countries-and-timezones'
import { USER_SCOPE } from '~/utils/constants'

export const getSessionState = state => state.session || {}
export const getSessionUiState = state => state.ui.sessions
export const getDriverFleetState = state => state.session.user.driverFleet || {}

export const selectSessionUser = createSelector(getSessionState, state => {
  try {
    return state.user || {}
  } catch (error) {
    console.log('error', error)
    return {}
  }
})

export const selectPersonalDetails = createSelector(
  selectSessionUser,
  user => user.person || {},
)

export const selectPersonName = createSelector(
  selectPersonalDetails,
  ({ firstName = '', lastName = '' }) => {
    if (!firstName && !lastName) {
      return ''
    }
    return `${firstName || ''} ${lastName || ''}`
  },
)

export const selectDriverDetails = createSelector(
  selectSessionUser,
  user => user.driver || user.driverFleet || {},
)

export const selectWorkerDetails = createSelector(
  selectSessionUser,
  user => user.driverFleet?.worker,
)

export const selectMyCurrentCompany = createSelector(
  selectSessionUser,
  user => {
    const { company, currentFleet } = user

    return company || currentFleet || {}
  },
)

export const selectCurrentDriverFleet = createSelector(
  selectSessionUser,
  ({ driverFleet }) => driverFleet,
)

export const selectUsersDriverFleet = createSelector(
  selectSessionUser,
  user => user.driverFleet,
)

export const selectWorkerId = createSelector(
  selectUsersDriverFleet,
  driverFleet => driverFleet?.workerId,
)

export const selectDefaultTerminalIds = createSelector(
  selectSessionUser,
  user => {
    const { defaultTerminals } = user

    if (!defaultTerminals) {
      return null
    }

    return defaultTerminals.map(({ id }) => id)
  },
)

export const selectSessionAuthenticated = createSelector(
  getSessionState,
  state => state.authenticated,
)

export const selectSessionChecked = createSelector(
  getSessionState,
  state => state.checked,
)

export const selectCompanyTimeZone = createSelector(
  selectMyCurrentCompany,
  company => {
    return company?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  },
)

export const selectCompanyDateTimeFormat = createSelector(
  selectMyCurrentCompany,
  company => {
    return company?.dateTimeFormat
  },
)

export const selectCompanyTimeFormat = createSelector(
  selectMyCurrentCompany,
  company => {
    return company?.timeFormat || '24'
  },
)

export const selectBrowserTz = createSelector(
  selectCompanyTimeZone,
  timezone => {
    const result = ct.getTimezone(timezone)
    if (result) {
      return result.utcOffsetStr.replace(/:/g, '')
    }
    return '-0500'
  },
)

export const selectCompanyId = createSelector(
  selectMyCurrentCompany,
  company => company?.id,
)

export const selectCurrentScope = createSelector(
  selectSessionUser,
  user => user.currentScope,
)

export const selectIsScopeBuyer = createSelector(
  selectCurrentScope,
  scope => scope === USER_SCOPE.buyer,
)

export const selectIsScopeSeller = createSelector(
  selectCurrentScope,
  scope => scope === USER_SCOPE.seller,
)

export const selectIsSellerBuyerScope = createSelector(
  selectIsScopeBuyer,
  selectIsScopeSeller,
  (isBuyer, isSeller) => isBuyer || isSeller,
)

export const selectIsScopeDriver = createSelector(
  selectCurrentScope,
  scope => scope === USER_SCOPE.driver || scope === USER_SCOPE.driver_fleet,
)

export const selectUserMenuOptions = createSelector(
  selectSessionUser,
  user => user?.menuOptions || [],
)

export const selectCurrentFleetUser = createSelector(
  selectSessionUser,
  user => user?.currentFleet || {},
)

export const selectUser = createSelector(selectSessionUser, user => user)

export const selectLoginPageLoading = createSelector(
  state => state.ui,

  state => state.sessions.loginPageLoading,
)

export const selectLoadId = createSelector(
  selectDriverDetails,
  driverFleet => driverFleet.loadId || null,
)

export const selectIsSigningIn = createSelector(
  getSessionUiState,
  state => state.isSigningIn,
)
