import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure } from '~/components/shared'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import {
  selectIsScopeBuyer,
  selectCommonBuyerOptions,
  selectCommonSellerOptions,
  selectTerminalOptions,
  selectMyCurrentCompany
} from '~/redux/selectors'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

const BuyerTerminalReferenceForm = (props) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const isBuyer = useSelector(selectIsScopeBuyer)
  const buyerOptions = useSelector(selectCommonBuyerOptions)
  const sellerOptions = useSelector(selectCommonSellerOptions)
  const terminalOptions = useSelector(selectTerminalOptions)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const fields = useMemo(() => [
    {
      name: 'name',
      label: 'Name',
      isRequired: true,
      size: 6
    },
    {
      name: 'code',
      label: 'Code',
      isRequired: true,
      size: 6
    },
    {
      name: 'buyerId',
      label: 'Buyer',
      isRequired: true,
      type: FORM_FIELD_TYPE.singleSelect,
      options: buyerOptions,
      size: 6,
      isHidden: isBuyer
    },
    {
      name: 'sellerId',
      label: 'Seller',
      isRequired: true,
      type: FORM_FIELD_TYPE.singleSelect,
      options: sellerOptions,
      size: 6,
      isHidden: !isBuyer
    },
    {
      name: 'buyerTerminalId',
      label: 'Terminal',
      isRequired: true,
      type: FORM_FIELD_TYPE.singleSelect,
      options: terminalOptions,
      size: 6
    }
  ], [buyerOptions, isBuyer, sellerOptions, terminalOptions])

  const defaultValues = useMemo(() => ({
    name: '',
    code: '',
    buyerId: null,
    sellerId: null,
    buyerTerminalId: null
  }), [])

  const isUpdating = useMemo(() => formData?.id, [formData?.id])

  const schema = useMemo(() => Yup.object({
    name: Yup.string().required('Name is required!'),
    code: Yup.string().required('Code is required!'),
    buyerId: Yup.lazy(() => {
      if (isBuyer) {
        return Yup.number().nullable()
      }

      return Yup.number('Buyer is required!').typeError('Buyer is required!')
    }),
    sellerId: Yup.lazy(() => {
      if (isBuyer) {
        return Yup.number('Seller is required!').typeError('Seller is required!')
      }
      return Yup.number().nullable()
    }),
    buyerTerminalId: Yup.number().required('Terminal is required!').typeError('Terminal is required!')
  }), [isBuyer])

  const updateBuyerTerminalReference = useCallback(
    async ({ id, sellerId, ...formData }) => {
      const response = await apiClient.buyerTerminalReferences.update(id, formData)
      if (response.id) {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      } else {
        toast.error(toastMessages.updateError)
      }
    },
    [afterUpdate],
  )

  const createBuyerTerminalReference = useCallback(
    async (formData) => {
      const payload = { ...formData }
      if (isBuyer) {
        payload.buyerId = currentCompany?.id
      } else {
        payload.sellerId = currentCompany.id
      }
      const response = await apiClient.buyerTerminalReferences.create(payload)
      if (response.id) {
        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [afterCreate, currentCompany.id, isBuyer],
  )

  const handleSubmit = useCallback(
    async (formData) => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateBuyerTerminalReference(formData)
        } else {
          await createBuyerTerminalReference(formData)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    [
      createBuyerTerminalReference,
      isUpdating,
      updateBuyerTerminalReference
    ],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      fields={fields}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default BuyerTerminalReferenceForm
