import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const RefreshIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M21 3v5m0 0h-5m5 0-3-2.708A9 9 0 1 0 20.777 14'
      />
    </svg>
  )
}
export default RefreshIcon
