import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ConcordFormDropdown } from '~/components/shared'

import clsx from 'clsx'
import {
  selectCommonFleetOptionsWithNotSet,
  selectCommonFleetLoading,
} from '~/redux/selectors'
import { fetchCommonFleets } from '~/redux/actions/fleetActions'

function ConcordFormCommonFleetDropdown(props) {
  const { className, ...dropdownProps } = props

  const dispatch = useDispatch()
  const fleetOptions = useSelector(selectCommonFleetOptionsWithNotSet)
  const commonFleetLoading = useSelector(selectCommonFleetLoading)

  const handleOpenDropdown = useCallback(async () => {
    dispatch(fetchCommonFleets())
  }, [dispatch])

  return (
    <ConcordFormDropdown
      options={fleetOptions}
      isLoading={commonFleetLoading}
      onOpenDropdown={handleOpenDropdown}
      className={clsx('remove-border-from-common-seller-dropdown', className)}
      styles={{
        menuList: provided => ({
          ...provided,
          maxHeight: 150,
        }),
      }}
      placement='top'
      {...dropdownProps}
    />
  )
}

ConcordFormCommonFleetDropdown.propTypes = {}

export default ConcordFormCommonFleetDropdown
