import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import {
  IStripePaymentMethod,
  IStripePaymentMethodGetListParams,
} from '~/types/models/IStripe'

const useModifyPaymentMethods = (params: IStripePaymentMethodGetListParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'stripePaymentMethods',
      sessionUser?.id,
      buildGetUrl(apiClient.stripe.endpoint + '/payment_methods', params),
    ],
    [params, sessionUser?.id],
  )

  const addPaymentMethod = useCallback(
    (data: IStripePaymentMethod) => {
      queryClient.setQueryData<IStripePaymentMethod[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPaymentMethod,
  }
}

export default useModifyPaymentMethods
