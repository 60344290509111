import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const BeakerIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width={size}
      height={size}
      viewBox='0 0 27 32'
      {...svgProps}
    >
      <g fill={color}>
        <path d='M17.5 1a.5.5 0 0 0 0-1h-9a.5.5 0 0 0 0 1h9zM.99 27.635c-1.074 1.511-.954 2.498-.664 3.06C.633 31.29 1.433 32 3.5 32h20c2.067 0 2.867-.71 3.174-1.306.29-.562.41-1.549-.648-3.034l-6.219-9.95-.088-.124C19.272 16.948 17 13.022 17 9.75V2.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v7.25c0 3.491-2.465 7.957-2.493 8.005L.99 27.635zm24.796 2.6c-.251.487-1.084.765-2.286.765h-20c-1.202 0-2.035-.278-2.286-.765-.229-.444-.02-1.162.62-2.066l4.999-8.948.007.004c.91.525 1.851 1.068 3.719 1.068s2.81-.542 3.719-1.066c.833-.48 1.619-.934 3.22-.934.607 0 1.133.075 1.617.21l6.08 9.712c.611.858.82 1.576.591 2.02zM10 9.75V3h6v6.75c0 2.84 1.516 6.042 2.404 7.602a7.862 7.862 0 0 0-.905-.059c-1.869 0-2.81.542-3.719 1.066-.833.48-1.619.934-3.22.934-1.601 0-2.387-.454-3.219-.934l-.019-.011.046-.082C7.393 18.226 10 13.58 10 9.75z' />
      </g>
    </svg>
  )
}

export default BeakerIcon
