import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAllTerminals, selectCompanies } from '~/redux/selectors'
import Select from 'react-select'
import { DateTime } from 'luxon'
import { ScheduleLoadsTableInputQty } from '../ScheduleLoads/ScheduleLoadsTableInputQty'
import { components } from 'react-select'
import { CompanyAvatar } from '../CompanyAvatar'
import TimeField from 'react-simple-timefield'
import Tooltip from 'rc-tooltip'

import clsx from 'clsx'

export const useScheduleLoadsTable = ({
  index,
  sellerId,
  updateField,
  isReadOnly,
  schedule,
}) => {
  const terminals = useSelector(selectAllTerminals)
  const companies = useSelector(selectCompanies)

  const sellerTerminalOptions = useMemo(() => {
    if (!sellerId) return []
    return Object.values(terminals)
      .filter(t => t.companyId === sellerId)
      .map(terminal => ({
        label: terminal.name,
        value: terminal.id,
        code: terminal.code,
      }))
  }, [terminals, sellerId])

  const fleetOptions = useMemo(() => {
    return (
      companies &&
      Object.values(companies)
        .filter(company => company.fleet)
        .map(company => ({
          label: company.name,
          value: company.id,
        }))
    )
  }, [companies])

  const AvatarCodeOption = props => (
    <components.Option
      {...props}
      className='d-flex flex-row align-items-center'
    >
      <CompanyAvatar
        company={{
          value: props?.selectProps?.value?.value,
          label: props?.selectProps?.value?.label,
          code: props?.selectProps?.value?.code,
        }}
        height={20}
        width={20}
        tooltipMessage={props?.selectProps?.value?.label}
      />
      &nbsp;
      {props?.selectProps?.value?.code}
    </components.Option>
  )

  const AvatarCodeValue = props => (
    <components.ValueContainer
      {...props}
      className='d-flex flex-row align-items-center'
    >
      <CompanyAvatar
        company={{
          value: props?.selectProps?.value?.value,
          label: props?.selectProps?.value?.label,
          code: props?.selectProps?.value?.code,
        }}
        height={20}
        width={20}
        tooltipMessage={props?.selectProps?.value?.label}
      />
      &nbsp;
      {props?.selectProps?.value?.code}
    </components.ValueContainer>
  )

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'num',
        Cell: ({ row }) => <div>{row.index + 1}</div>,
      },
      {
        Header: 'QTY',
        accessor: 'qty',
        Cell: ({ row: { index }, data }) => {
          return (
            <ScheduleLoadsTableInputQty
              index={index}
              schedules={data}
              updateField={updateField}
              isReadOnly={isReadOnly}
            />
          )
        },
      },
      {
        Header: 'Running',
        accessor: 'runningTotal',
        Cell: ({ row, value, data }) => {
          const { original } = row
          if (
            row.index === data.length - 1 &&
            schedule?.plus &&
            Number(original.qty) === 0
          ) {
            return '+'
          }

          return Number(value)
        },
      },
      {
        Header: 'Ticket Time',
        accessor: 'ticketTime',
        sortType: (a, b) =>
          new Date(b.values.ticketTime) - new Date(a.values.ticketTime),
        Cell: ({ row }) => {
          const time = DateTime.fromISO(row.original.ticketTime).toFormat(
            'H:mm',
          )
          return <div>{time}</div>
        },
      },

      {
        Header: 'Start Terminal',
        accessor: 'beginSellerTerminalId',
        sortType: (a, b) =>
          terminals[b.values.beginSellerTerminalId]?.name -
          terminals[a.values.beginSellerTerminalId]?.name,
        Cell: ({ row }) => {
          const { original } = row

          return (
            <Tooltip
              placement='top'
              overlay={() => (
                <div>
                  <div>
                    <span>Ticket to Load: </span>
                    <span>{original.ticketToLoadTime}</span>
                  </div>
                  <div>
                    <span>Loading: </span>
                    <span>{original.loadTime}</span>
                  </div>
                  <div>
                    <span>Loaded to Leave: </span>
                    <span>{original.loadedToLeaveTime}</span>
                  </div>
                  <div>
                    <span>Travel Time: </span>
                    <span>{original.travelTimeToBuyer}</span>
                  </div>
                </div>
              )}
              overlayClassName='rcTooltip'
            >
              <div>
                <Select
                  className={clsx('d-flex', { isReadOnly })}
                  options={sellerTerminalOptions}
                  defaultValue={{
                    label: terminals[row.original.beginSellerTerminalId]?.name,
                    value: row.original.beginSellerTerminalId,
                    code: terminals[row.original.beginSellerTerminalId]?.code,
                  }}
                  components={{
                    IndicatorSeparator: null,
                    Option: AvatarCodeOption,
                    ValueContainer: AvatarCodeValue,
                  }}
                  onChange={e =>
                    updateField({
                      row: index,
                      field: 'beginSellerTerminalId',
                      value: e.value,
                    })
                  }
                  isSearchable
                />
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: 'Arrive Job',
        accessor: 'arriveBuyerTime',
        sortType: (a, b) =>
          new Date(b.values.arriveBuyerTime) -
          new Date(a.values.arriveBuyerTime),
        Cell: ({ value, row }) => {
          const formatted = new DateTime.fromISO(value).toFormat('HH:mm')
          const { original } = row
          return (
            <Tooltip
              placement='top'
              overlay={() => (
                <div>
                  <span>On Site: </span>
                  <span>{parseInt(original.onSiteTime)}min</span>
                </div>
              )}
              overlayClassName='rcTooltip'
            >
              <div>
                <TimeField
                  onChange={e =>
                    updateField({
                      row: index,
                      field: 'arriveBuyerTime',
                      value: e.value,
                    })
                  }
                  value={formatted}
                  style={{
                    borderColor: 'hsl(0, 0%, 80%)',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    fontSize: 16,
                    width: 64,
                    padding: '5px 8px',
                    color: '#333',
                    borderRadius: 2,
                  }}
                />
              </div>
            </Tooltip>

            // <input
            //   type='time'
            //   value={formatted}
            //   onChange={e =>
            //     updateField({
            //       row: index,
            //       field: 'arriveBuyerTime',
            //       value: e.value,
            //     })
            //   }
            //   format='H:mm'
            //   className={clsx({ isReadOnly })}
            //   disabled
            // />
          )
        },
      },
      {
        Header: 'Return Terminal',
        accessor: 'returnSellerTerminalId',
        sortType: (a, b) =>
          terminals[b.values.returnSellerTerminalId]?.name -
          terminals[a.values.returnSellerTerminalId]?.name,
        Cell: ({ row }) => {
          const { original } = row

          return (
            <Tooltip
              placement='top'
              overlay={() => (
                <div>
                  <span>Return Time: </span>
                  <span>{original.travelTimeToSeller}</span>
                </div>
              )}
              overlayClassName='rcTooltip'
            >
              <div>
                <Select
                  className={clsx('d-flex', { isReadOnly })}
                  options={sellerTerminalOptions}
                  defaultValue={{
                    label: terminals[row.original.returnSellerTerminalId]?.name,
                    value: row.original.returnSellerTerminalId,
                    code: terminals[row.original.returnSellerTerminalId]?.code,
                  }}
                  components={{
                    IndicatorSeparator: null,
                    Option: AvatarCodeOption,
                    ValueContainer: AvatarCodeValue,
                  }}
                  onChange={e =>
                    updateField({
                      row: index,
                      field: 'returnSellerTerminalId',
                      value: e.value,
                      code: e.code,
                    })
                  }
                  isSearchable
                />
              </div>
            </Tooltip>
          )
        },
      },

      {
        Header: 'Total',
        accessor: 'totalTime',
        sortType: (a, b) =>
          b.values.ticketTime +
          b.values.arriveSellerTime -
          (a.values.ticketTime + a.values.arriveSellerTime),
        Cell: ({ row }) => {
          const d1 = new Date(row.original.ticketTime).getTime()
          const d2 = new Date(row.original.arriveSellerTime).getTime()
          const minutes = Math.floor((d2 - d1) / 1000 / 60)
          return <div>{minutes} mins</div>
        },
      },

      {
        Header: 'Fleet',
        accessor: 'fleetId',
        sortType: (a, b) =>
          companies[b.values.fleetId]?.name - companies[a.values.fleetId]?.name,
        Cell: ({ row }) => {
          const fleetId = row.values.fleetId
          const fleetOption = fleetOptions.find(
            ({ value }) => value === fleetId,
          )

          return (
            <Select
              className={clsx('d-flex', { isReadOnly })}
              options={fleetOptions}
              defaultValue={fleetOption}
              components={{ IndicatorSeparator: null }}
              onChange={e =>
                updateField({ row: index, field: 'fleetId', value: e.value })
              }
              isSearchable
            />
          )
        },
      },
    ],
    [
      terminals,
      sellerTerminalOptions,
      updateField,
      index,
      companies,
      fleetOptions,
      isReadOnly,
      schedule,
    ],
  )

  return { columns }
}
