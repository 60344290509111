import { useMemo } from 'react'
import { ConcordToolbar } from '~/components/shared'

import _ from 'lodash'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import './styles.scss'
import { EFieldType, ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import moment from 'moment'

export interface IRTTopToolbarProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
}

const RTTopToolbar = <TData extends IReusableTableRowData>(
  props: IRTTopToolbarProps<TData>,
) => {
  const { table } = props
  const { options } = table

  const state = table.getState()
  const columns = table.getAllColumns()
  const enableSection = options.enableRowSelection
  const columnVisibility = state.columnVisibility
  const enableSorting = options.enableSorting
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsExcepting = [
    ERTDisplayColumnId.select,
    ERTDisplayColumnId.actions,
    ERTDisplayColumnId.drag,
    ERTDisplayColumnId.expand,
  ]
  const columnOrder = state.columnOrder

  const csvFileName = useMemo(() => {
    const name = options.companyViewProps?.name || 'concord'
    return `${_.snakeCase(name)}_${moment().format('YYYY_MM_DD-HH_mm_ss')}`
  }, [options.companyViewProps?.name])

  const filterData = useMemo(() => {
    const filters: Record<string, any> = {}
    state.columnFilters.map(({ id, value }) => (filters[id] = value))
    return filters
  }, [state.columnFilters])

  const filterOptions = useMemo(() => {
    const opts = columns
      .filter(({ columnDef }) => columnDef.filterVariant !== EFieldType.text)
      .map(({ columnDef }) => {
        return {
          field: columnDef.id,
          label: columnDef.header,
          type: columnDef.filterVariant,
          options: columnDef.filterSelectOptions,
        }
      })
    if (options.filterOptions) {
      return [...options.filterOptions, ...opts]
    }
    return opts
  }, [columns, options.filterOptions])

  const sortOptions = useMemo(
    () =>
      enableSorting
        ? columns
            .filter(
              ({ columnDef }) =>
                (columnDef.enableSorting === undefined ||
                  columnDef.enableSorting) &&
                !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
            )
            .map(({ columnDef }) => {
              const colSorted = state.sorting.find(
                ({ id }) => id === columnDef.accessorKey,
              )

              return {
                label: columnDef.header,
                sortField: columnDef.accessorKey,
                isAsc: !colSorted?.desc,
                sorted: Boolean(colSorted),
              }
            })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, state.sorting],
  )

  const columnOptions = useMemo(
    () =>
      _(columns)
        .filter(
          ({ columnDef }) =>
            !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
        )
        .map(({ columnDef }) => {
          let isHidden = false

          const order = columnOrder.findIndex(ord => ord === columnDef.id)

          if ((columnVisibility as any)[columnDef.accessorKey] === false) {
            isHidden = true
          }

          return {
            label: columnDef.header,
            field: columnDef.accessorKey,
            hide: isHidden,
            order,
          }
        })
        .orderBy(['order', 'asc'])
        .value(),
    [columnOrder, columnVisibility, columns, columnsExcepting],
  )

  const dataExported = useMemo(() => {
    try {
      if (options.data.length > 0) {
        const result: any[] = []
        options.data.forEach(item => {
          const obj: Record<string, any> = {}
          columns
            .filter(
              ({ columnDef }) =>
                !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
            )
            .map(({ columnDef }) => {
              let value: any = _.get(item, columnDef.id)
              if (columnDef.accessorFn) {
                value = columnDef.accessorFn(item)
              }
              obj[columnDef.header] = value
            })
          result.push(obj)
        })
        return result
      }
      return []
    } catch (error) {
      console.log('error', error)
      return []
    }
  }, [columns, columnsExcepting, options.data])

  const onFilterChange = (filters: Record<string, any>) => {
    const newFilters = Object.keys(filters).map(field => ({
      id: field,
      value: filters[field],
    }))
    table.setColumnFilters(newFilters)
  }

  const onSortChange = (sortOpts: any) => {
    const newSortOpts = sortOpts
      .filter(({ sorted }: any) => sorted)
      .map((opt: any) => ({
        id: opt.sortField,
        desc: !opt.isAsc,
      }))
    table.setSorting(newSortOpts)
  }

  const onSearchBarChange = _.debounce((searchKey: string) => {
    table.setGlobalFilter(searchKey)
  }, 600)

  const onColumnChange = (newColumns: any[]) => {
    const newColumnOpts: Record<string, boolean> = {}
    newColumns.forEach(({ field, hide }) => {
      newColumnOpts[field] = !hide
    })
    const newColumnOrder = newColumns.map(({ field }) => field)
    if (enableSection) {
      newColumnOrder.unshift(ERTDisplayColumnId.select)
    }
    table.setColumnOrder(newColumnOrder)
    table.setColumnVisibility(newColumnOpts)
  }

  return (
    <ConcordToolbar
      className='RTTopToolbar__container'
      filterOptions={filterOptions}
      filterData={filterData}
      searchBarValue={state.globalFilter}
      onFilterChange={onFilterChange}
      onSearchBarChange={onSearchBarChange}
      sortOptions={sortOptions}
      onSortChange={onSortChange}
      columnOptions={columnOptions}
      onColumnChange={onColumnChange}
      exportingData={dataExported}
      filterSectionWidth={options.toolbarProps?.filterSectionWidth}
      isHiddenSearchBar={options?.toolbarProps?.isHiddenSearchBar}
      csvFileName={csvFileName}
    />
  )
}

export default RTTopToolbar
