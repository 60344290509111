import { IonCol, IonRow } from '@ionic/react'
import { ICalculationVarItemProps } from './type'
import {
  ConcordFormDropdownV2,
  ConcordFormLayout,
  ConcordFormTagsInput,
} from '~/components/shared'
import { useQueryNewCalculationData } from '~/hooks/useQueryData'
import { addCircleOutline } from 'ionicons/icons'
import { FiltersField } from '../FiltersField'

function CalculationVarItem(props: ICalculationVarItemProps) {
  const {
    data,
    error,
    onChangeModel,
    onChangeCalculationMethods,
    onAddFilter,
    onChangeFilters,
  } = props

  const { modelOptions } = useQueryNewCalculationData()

  return (
    <IonRow>
      <IonCol size='6'>
        <ConcordFormDropdownV2
          isRequired
          label='Model'
          error={error?.model?.message}
          options={modelOptions}
          value={data.model}
          onChange={onChangeModel}
        />
      </IonCol>

      <IonCol size='6'>
        <ConcordFormTagsInput
          error={error?.calculationMethod?.message}
          className='CalculationVarsSection__tagInput'
          label={
            <>
              <span>
                Calculation Method{' '}
                <span className='error' style={{ verticalAlign: 'top' }}>
                  *
                </span>
              </span>
              <span className='hintText' style={{ marginLeft: 4 }}>
                Ex: count
              </span>
            </>
          }
          value={data.calculationMethod || []}
          onChange={onChangeCalculationMethods}
        />
      </IonCol>

      <ConcordFormLayout
        label='Filters'
        style={{ padding: 4 }}
        isRequired
        error={error?.filters?.message}
        extraIcons={[
          {
            icon: addCircleOutline,
            color: 'primary',
            onClick: onAddFilter,
          },
        ]}
      >
        {error?.filters?.message && (
          <div className='error'>{error?.filters?.message}</div>
        )}
        {Object.keys(data.filters || {}).map(field => (
          <FiltersField
            key={field}
            field={field}
            filters={data.filters}
            onChange={onChangeFilters}
          />
        ))}
      </ConcordFormLayout>
    </IonRow>
  )
}

export default CalculationVarItem
