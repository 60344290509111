import { DEFAULT_ICON_SIZE } from './constants'
import type { ISvgIconProps } from './type'

const DoubleArrowForwardIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m5.5 5 6.293 6.293a1 1 0 0 1 0 1.414L5.5 19M13.5 5l6.293 6.293a1 1 0 0 1 0 1.414L13.5 19'
      />
    </svg>
  )
}
export default DoubleArrowForwardIcon
