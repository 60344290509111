import { useMemo } from 'react'

import { CompanyInfo, IRTColumnDef, ToolTipOverlay } from '~/components/shared'

import {
  useQueryTerminals,
  useQueryUoms,
  useQueryUsers,
} from '~/hooks/useQueryData'
import buildFullName from '~/utils/buildFullName'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { ICommonOption } from '~/types/models/ICommonModel'

const useTableTrucksColumns = () => {
  const { currentUom } = useQueryUoms()
  const { driverFleetOptions, driverFleetUsers, isUsersFetched } =
    useQueryUsers()

  const { companyTerminalOptions } = useQueryTerminals()

  const columns = useMemo<IRTColumnDef<ITruckFleet>[]>(
    () => [
      {
        header: 'Truck Name',
        accessorKey: 'truck.name',
      },
      {
        header: 'Truck Info',
        accessorKey: 'truckInfo',
        size: 200,
        Cell({ row }) {
          const { truck } = row.original
          return `${truck.year || ''} ${truck.make || ''} ${truck.model || ''}`
        },
      },
      {
        header: 'Driver',
        accessorKey: 'currentTruckFleetId',
        enableEditing: true,
        editSelectOptions: driverFleetOptions as any,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps({ row }) {
          const truckFleetId = row.original.id
          const user = driverFleetUsers.find(
            ({ driverFleet }) =>
              driverFleet?.currentTruckFleetId === truckFleetId,
          )
          return {
            value: user
              ? {
                  value: user.driverFleet?.id,
                  label: buildFullName(user.person),
                }
              : undefined,
            formatOptionLabel: (opt: ICommonOption) => {
              if (opt.value === null) {
                return <span className='error'>Not set</span>
              }
              return (
                <CompanyInfo
                  company={{
                    value: opt.value,
                    label: opt.label,
                    name: opt.label,
                  }}
                  hideAnchor
                  searchableGoogle={false}
                  hideSubtitle
                  companyType={EScope.driver}
                />
              )
            },
          }
        },
      },
      {
        header: 'Park Terminal',
        accessorKey: 'truck.parkTerminalId',
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: companyTerminalOptions,
        editDropdownFieldProps() {
          return {
            getOptionLabel: (
              opt: ICommonOption<{ code: string; name: string }>,
            ) => (
              <ToolTipOverlay content={opt.name} placement='top'>
                <span>{opt.code}</span>
              </ToolTipOverlay>
            ),
          }
        },
      },
      {
        header: 'Plate',
        accessorKey: 'truck.licensePlate',
      },
      {
        header: 'Load Status',
        accessorKey: 'loadStatus',
      },
      {
        header: 'Max Qty',
        accessorKey: 'truck.maxQty',
        size: 120,
        Cell({ row }) {
          const rowData = row.original
          if (rowData.truck?.maxQty) {
            return `${rowData.truck.maxQty} ${currentUom?.code || ''}`
          }
          return ''
        },
      },
      {
        header: 'Empty Weight',
        accessorKey: 'truck.emptyWeight',
        size: 140,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          if (cellValue) {
            return `${cellValue} lbs`
          }

          return null
        },
      },
      {
        header: 'GPS Interval',
        accessorKey: 'truck.gpsInterval',
        size: 140,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          if (cellValue) {
            return `${cellValue} sec`
          }

          return null
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUom?.code,
      isUsersFetched,
      driverFleetOptions,
      companyTerminalOptions,
    ],
  )

  return { columns }
}

export default useTableTrucksColumns
