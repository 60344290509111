import { useMemo } from 'react'

import { IRTColumnDef } from '~/components/shared'

import moment from 'moment'
import { BANK_ACOUNTABLE_TYPE_OPTIONS } from '~/utils/constants'
import { IBankAccount } from '~/types/models/IBankAccount'
import { EFieldType } from '~/types/enums/ECommonEnum'

const TableBankAccounts = () => {
  const columns = useMemo<IRTColumnDef<IBankAccount>[]>(
    () => [
      { header: 'Name', accessorKey: 'name', size: 120 },
      { header: 'Bank Name', accessorKey: 'bankName' },
      { header: 'Routing Number', accessorKey: 'routingNumber' },
      {
        header: 'Bank Accountable Type',
        accessorKey: 'bankAccountableType',
        size: 200,
        filterSelectOptions: BANK_ACOUNTABLE_TYPE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
      },
      {
        header: 'Created At',
        id: 'createdAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.createdAt).format('lll')
        },
      },
      {
        header: 'Updated At',
        accessor: 'updatedAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.updatedAt).format('lll')
        },
      },
    ],
    [],
  )

  return { columns }
}

export default TableBankAccounts
