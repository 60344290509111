import { createSlice } from '@reduxjs/toolkit'

const companyBankAccountsSlice = createSlice({
  name: 'companyBankAccounts',
  initialState: {
    loadingTable: false,
  },
  reducers: {
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
  },
})

export default companyBankAccountsSlice
