import _ from 'lodash'

export const fullAddress = addressObj => {
  if (addressObj?.fullAddress) {
    //safeguard until backend can parse below fields
    return addressObj.fullAddress
  }

  const arr = _.pick(addressObj, ['street', 'address2', 'city', 'state', 'zip'])

  return _.values(arr)
    .filter(n => n)
    .join(', ')
}
