import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const orderExtraRangesSlice = createSlice({
  name: 'orderExtraRanges',
  initialState: {},
  reducers: {
    initialize: (state, action) => {
      action.payload.forEach(
        orderExtraRange => (state[orderExtraRange.id] = orderExtraRange),
      )
    },
    update: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    delete: (state, { payload }) => {
      delete state[payload.id]
    },
  },
})

export const fetchOrderExtraRanges = createAsyncThunk(
  'fetchOrderExtraRanges',
  async (params = {}, { dispatch }) => {
    try {
      const { orderExtraRanges } = await apiClient.orderExtraRanges.get(params)
      dispatch(orderExtraRangesSlice.actions.initialize(orderExtraRanges))
    } catch (error) {
      console.log('error', error)
    }
  },
)
