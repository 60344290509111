import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPaymentMethods from './useModifyPaymentMethods'

import { apiClient } from '~/api/ApiClient'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import {
  IStripePaymentMethod,
  IStripePaymentMethodGetListParams,
} from '~/types/models/IStripe'
import { ICompany } from '~/types/models/ICompany'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryContests = (
  params: IStripePaymentMethodGetListParams,
  options?: Partial<UseQueryOptions<IStripePaymentMethod[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'stripePaymentMethods',
      sessionUser?.id,
      buildGetUrl(apiClient.stripe.endpoint + '/payment_methods', params),
    ],
    async queryFn() {
      const response = await apiClient.stripe.getPaymentMethods(params)
      return response.paymentMethods
    },
    enabled: Boolean(currentCompany.stripeCustomerId),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const paymentMethodsData = useMemo(() => data || [], [data])

  const { addPaymentMethod } = useModifyPaymentMethods(params)

  return {
    paymentMethodsData,
    isPaymentMethodsLoading: isLoading,
    addPaymentMethod,
    refetchPaymentMethods: refetch,
  }
}

export default useQueryContests
