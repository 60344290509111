import { createSelector } from 'reselect'
import { ISellerToBuyerTerminalReduxUiSlice } from '~/types/models/ISellerToBuyerTerminal'

export type RootReducer = {
  ui: {
    sellerToBuyerTerminals: ISellerToBuyerTerminalReduxUiSlice
  }
}

export const selectSellerToBuyerTerminalUiState = (state: RootReducer) =>
  state.ui.sellerToBuyerTerminals

export const selectSellerToBuyerTerminalColumns = createSelector(
  selectSellerToBuyerTerminalUiState,
  uiState =>
    uiState.columns
      .filter(c => c.hideable)
      .sort((a, b) => a.displayIndex - b.displayIndex),
)

export const selectSellerToBuyerTerminalSortOptions = createSelector(
  selectSellerToBuyerTerminalUiState,
  uiState =>
    uiState.columns
      .filter(c => c.sortable)
      .sort((a, b) => (a.sortIndex as number) - (b.sortIndex as number)),
)
