import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IHierarchyRowFormValues } from '~/types/models/IHierarchyRow'
import type { IHierarchyRowFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryHierarchies,
  useQueryPricings,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import {
  EHierarchableType,
  hierarchableTypeOptions,
} from '~/types/enums/EHierarchyRow'

const HierarchyRowForm = (props: IHierarchyRowFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    hierarchyData,
    readableFields = [],
    ...formProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { hierarchyOptions } = useQueryHierarchies()

  const { pricingOptions } = useQueryPricings()

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const getHierarchableOptions = useCallback(
    (hierarchableType: EHierarchableType | null) => {
      switch (hierarchableType) {
        case EHierarchableType.Pricing:
          return pricingOptions

        default:
          return []
      }
    },
    [pricingOptions],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Hierarchy',
        name: 'hierarchyId',
        options: hierarchyOptions,
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        isDisabled: Boolean(hierarchyData),
      },
      {
        label: 'Hierarchable Type',
        name: 'hierarchableType',
        size: 6,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: hierarchableTypeOptions,
        isReadOnly: readableFields.includes('hierarchableType'),
      },
      {
        label: 'Hierarchable #',
        name: 'hierarchableId',
        size: 12,
        isRequired: true,
        type: EFieldType.singleSelect,
        render({ name, control, label, watch }) {
          const hierarchableType = watch('hierarchableType', '')

          return (
            <ConcordDropdownV2WithFormControl
              name={name}
              control={control}
              label={label}
              isRequired
              options={getHierarchableOptions(hierarchableType)}
            />
          )
        },
      },
      {
        name: 'sellerProductId',
        label: 'Seller Product #',
        options: sellerProductOptions,
        isRequired: true,
        type: EFieldType.singleSelect,
        size: 6,
      },
      {
        name: 'rank',
        label: 'Rank',
        isRequired: true,
        type: EFieldType.number,
        size: 6,
      },
    ],
    [
      getHierarchableOptions,
      hierarchyData,
      hierarchyOptions,
      readableFields,
      sellerProductOptions,
    ],
  )

  const defaultValues = useMemo<IHierarchyRowFormValues>(
    () => ({
      rank: null,
      sellerProductId: null,
      hierarchableType: null,
      hierarchyId: null,
      hierarchableId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        hierarchableType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerProductId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        hierarchyId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        hierarchableId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        rank: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      const { errors, ...response } = await apiClient.hierarchyRows.create({
        hierarchyRow: {
          rank: formValues.rank,
          sellerProductId: formValues.sellerProductId,
          hierarchableType: formValues.hierarchableType,
          hierarchyId: formValues.hierarchyId,
          hierarchableId: formValues.hierarchableId,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'sellerProductId',
          'hierarchableType',
          'hierarchyId',
        ])
        const { errors, ...response } = await apiClient.hierarchyRows.update(
          formValues.id,
          {
            hierarchyRow: {
              ...payload,
            },
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating && hierarchyData) {
      formRef.current?.setValue('hierarchyId', hierarchyData.id)
    }
  }, [hierarchyData, isUpdating])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default HierarchyRowForm
