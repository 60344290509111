import { useCallback } from 'react'

import { IonIcon } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import { searchCircle } from 'ionicons/icons'

import queryString from 'query-string'
import clsx from 'clsx'

const GoogleSearchCompanyLogo = props => {
  const { companyName, className, style } = props

  const handleDirectToGoogle = useCallback(() => {
    const query = queryString.stringifyUrl({
      url: 'https://google.com/search',
      query: {
        q: `${companyName} logo`,
        tbm: 'isch',
      },
    })
    window.open(query)
  }, [companyName])

  return (
    <ToolTipOverlay content='Search Google' placement='top'>
      <div
        style={style}
        className={clsx('Header__searcIcon', className)}
        onClick={handleDirectToGoogle}
      >
        <IonIcon slot='icon-only' icon={searchCircle} />
      </div>
    </ToolTipOverlay>
  )
}

export default GoogleSearchCompanyLogo
