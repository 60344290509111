import { useCallback, useMemo } from 'react'
import useStoreData from '~/hooks/useStoreData'

import { toast } from 'react-toastify'
import resetObjValues from '~/utils/resetObjValues'
import { translateSavedFilters } from '~/utils/translateSavedFilters'

const useStoreFilters = moduleName => {
  const { storedFilters, saveFilters } = useStoreData(moduleName)

  const defaultEmptyValue = useMemo(
    () => resetObjValues(storedFilters.data),
    [storedFilters.data],
  )

  const defaultOrder = useMemo(
    () => storedFilters.order || [],
    [storedFilters.order],
  )

  const filterData = useMemo(() => {
    const convertData = translateSavedFilters(storedFilters)

    return convertData
  }, [storedFilters])

  const setStoreData = useCallback(
    newFilters => {
      try {
        saveFilters(newFilters)
      } catch (error) {
        console.log('error', error)
        toast.error('An error has occurred.')
      }
    },
    [saveFilters],
  )

  const resetStoreData = useCallback(() => {
    saveFilters({ data: defaultEmptyValue, order: defaultOrder })
  }, [defaultEmptyValue, defaultOrder, saveFilters])

  return [filterData, setStoreData, resetStoreData, { defaultEmptyValue }]
}

export default useStoreFilters
