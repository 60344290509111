import { CommonDialogV2 } from '~/components/shared'
import ProductGroupForm from './ProductGroupForm'

import type { IDialogProductGroupFormData } from './type'

function DialogProductGroupForm(props: IDialogProductGroupFormData) {
  const { formData, afterCreate, afterUpdate, ...modalProps } = props

  return (
    <CommonDialogV2 {...modalProps} title='Product Group' isHiddenOkButton>
      <ProductGroupForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}

export default DialogProductGroupForm
