import { useCallback, useEffect, useState } from 'react'

import { Accordion as BAccordion } from 'react-bootstrap'

import clsx from 'clsx'

import './styles.scss'

function Accordion(props) {
  const { items, className, initialActiveTabs, alwaysOpen, defaultActiveKey } =
    props

  const [activeKey, setActiveKey] = useState('')

  const mapItems = useCallback(() => {
    return items.map(
      ({ title, eventKey, component: Component, ...itemProps }) => (
        <BAccordion.Item key={eventKey} eventKey={eventKey} {...itemProps}>
          <BAccordion.Header>{title}</BAccordion.Header>
          <BAccordion.Body>{Component}</BAccordion.Body>
        </BAccordion.Item>
      ),
    )
  }, [items])

  const onSelect = useCallback(eventKey => {
    setActiveKey(eventKey)
  }, [])

  useEffect(() => {
    setActiveKey(initialActiveTabs)
  }, [initialActiveTabs])

  return (
    <BAccordion
      className={clsx('Accordion__container', className)}
      activeKey={activeKey}
      onSelect={onSelect}
      alwaysOpen={alwaysOpen}
      defaultActiveKey={defaultActiveKey}
    >
      {mapItems()}
    </BAccordion>
  )
}

export default Accordion
