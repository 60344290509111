import Immutable from 'immutable'

import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  currentTruck: {},
  loading: false,
  error: undefined,
})

const truckReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_TRUCK:
    case types.FETCH_CURRENT_TRUCK: {
      const newState = state.set('loading', true)

      return newState.set('error', undefined)
    }
    case types.UPDATE_CURRENT_TRUCK_SUCCESS:
    case types.FETCH_CURRENT_TRUCK_SUCCESS: {
      const { currentTruck } = action
      const newState = state.set('currentTruck', currentTruck)

      return newState.set('loading', false)
    }
    case types.ADD_NEW_TRUCK: {
      return state.set('loading', true)
    }
    case types.ADD_NEW_TRUCK_SUCCESS: {
      const { truck } = action
      const newState = state.set('currentTruck', truck)

      return newState.set('loading', false)
    }
    case types.FETCH_CURRENT_TRUCK_FAILURE: {
      const { error } = action
      const newState = state.set('error', error)

      return newState.set('loading', false)
    }

    default: {
      return state
    }
  }
}

export default truckReducer
