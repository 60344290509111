import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CompanyAvatar,
  CompanyInfo,
  GoogleMap,
  TerminalForm,
  TerminalMarker1,
} from '~/components/shared'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import buildFullName from '~/utils/buildFullName'
import { EScope } from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers, useQueryTerminals } from '~/hooks/useQueryData'

import './styles.scss'
import buildFullAddress from '~/utils/buildFullAddress'
import { ListGroup } from 'react-bootstrap'
import { IonButtons } from '@ionic/react'
import { addCircle } from 'ionicons/icons'

const TerminalFormOnboarding = props => {
  const {
    handleContinue,
    hiddenFields,
    defaultValues,
    companyOptions = [],
    savedRelationship,
  } = props

  const sessionUser = useSelector(selectSessionUser)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { findBuyerCompanyOption, findSellerCompanyOption } =
    useQueryBuyerSellers()

  const { terminalsData, isFetchedTerminals, addTerminal } = useQueryTerminals(
    {
      filters: {
        companyId: companyOptions.map(({ value }) => value),
      },
    },
    { enabled: Boolean(sessionUser.userAccess) && companyOptions.length > 0 },
  )

  const [isOpenForm, setIsOpenForm] = useState(true)
  const [terminalSelected, setTerminalSelected] = useState(null)

  const terminalLocation = useMemo(() => {
    if (terminalSelected) {
      return new window.google.maps.LatLng(
        Number(terminalSelected.location?.lat || 0),
        Number(terminalSelected.location?.lng || 0),
      )
    }
    return new window.google.maps.LatLng(0, 0)
  }, [terminalSelected])

  const onClickTerminal = terminal => () => {
    setTerminalSelected(prev => {
      if (prev?.id === terminal?.id) {
        return null
      }
      return terminal
    })
  }

  useEffect(() => {
    if (terminalsData.length <= 0 && !isFetchedTerminals) {
      setIsOpenForm(true)
    }
  }, [isFetchedTerminals, terminalsData.length])

  return (
    <div>
      <div
        style={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          padding: 8,
        }}
      >
        <CompanyInfo
          company={{
            value: currentCompany?.id,
            name: [currentCompany?.code, currentCompany?.name]
              .filter(text => text)
              .join(' - '),
            image: currentCompany?.logo,
            code: buildFullName(sessionUser.person),
          }}
          hideAnchor
          searchableGoogle={false}
          companyType={sessionUser?.currentScope || EScope.fleet}
        />
      </div>

      <div className='TerminalFormOnboarding__addIcon'>
        <IonButtons>
          <Button
            icon={addCircle}
            label='Create Terminal'
            onClick={() => {
              setIsOpenForm(prev => !prev)
            }}
          />
        </IonButtons>
      </div>

      {isOpenForm && (
        <div
          style={{ border: '1px solid #e5e5e5', marginTop: 8, marginBottom: 8 }}
        >
          <TerminalForm
            hiddenFields={hiddenFields}
            companyOptions={companyOptions}
            defaultValues={defaultValues}
            afterCreate={newTerminal => {
              addTerminal(newTerminal)
              setIsOpenForm(false)
            }}
            isHiddenCancelButton={terminalsData.length <= 0}
            onCancel={() => {
              setIsOpenForm(false)
            }}
            savedRelationship={savedRelationship}
          />
        </div>
      )}

      {terminalsData.length > 0 && (
        <ListGroup
          style={{ marginTop: 6, maxHeight: 300, overflow: 'auto' }}
          flush
        >
          {terminalsData.map(terminal => {
            const company =
              terminal.companyId === currentCompany?.id
                ? {
                    label: `${currentCompany?.code} - ${currentCompany?.name}`,
                    name: currentCompany?.name,
                    value: currentCompany?.id,
                  }
                : findBuyerCompanyOption(terminal.companyId) ||
                  findSellerCompanyOption(terminal.companyId)

            return (
              <ListGroup.Item
                active={terminal.id === terminalSelected?.id}
                key={terminal.id}
                onClick={onClickTerminal(terminal)}
                style={{ display: 'flex', alignItems: 'center', fontSize: 13 }}
                className='TerminalFormOnboarding__terminalCard clickable'
              >
                <CompanyAvatar
                  company={company}
                  hideAnchor
                  searchableGoogle={false}
                  companyType={
                    sessionUser?.currentScope === EScope.buyer
                      ? EScope.seller
                      : EScope.buyer
                  }
                />
                <div className='description' style={{ marginLeft: 8 }}>
                  <div className='name'>
                    <span className='terminalName'>
                      {terminal.code} - {terminal.name}
                    </span>
                  </div>
                  <div className='address'>
                    {buildFullAddress(terminal.address)}
                  </div>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      )}

      {terminalSelected && (
        <div className='TerminalFormOnboarding__googleMap'>
          <GoogleMap defaultCenter={terminalLocation} zoom={15}>
            <TerminalMarker1 terminal={terminalSelected} />
          </GoogleMap>
        </div>
      )}

      <Button
        label='Next'
        expand='full'
        onClick={handleContinue}
        style={{ marginTop: 8 }}
      />
    </div>
  )
}

export default TerminalFormOnboarding
