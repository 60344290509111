import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import ReactNumberFormat from 'react-number-format'

import { useQueryUoms } from '~/hooks/useQueryData'
import { CloseIcon, PlusIcon, ToolTipOverlay } from '~/components/shared'
import { selectAllCompanies, selectAllTerminals } from '~/redux/selectors'
import { ITerminal } from '~/types/models/ITerminal'

import type {
  ICustomLdsQtyFieldOnChangeParams,
  ICustomLdsQtyFieldProps,
} from './type'

import './styles.scss'

const CustomInput = React.forwardRef((props, ref: any) => {
  return <input {...props} ref={ref} />
})

function CustomLdsQtyField(props: ICustomLdsQtyFieldProps) {
  const {
    plus,
    qtyCounter,
    ldsCounter,
    qty,
    lds,
    sellerTerminalId,
    onChange,
    onChangePlusLoad,
  } = props

  const [isFocusing, setIsFocusing] = useState(false)
  const [isUsingLds, setIsUsingLds] = useState(false)
  const [numberValue, setNumberValue] =
    useState<number | null | undefined>(null)

  const { currentUom } = useQueryUoms()

  const terminalObjects: Record<number, ITerminal> =
    useSelector(selectAllTerminals)

  const companies = useSelector(selectAllCompanies)

  const sellerTerminal = useMemo(
    () => terminalObjects[sellerTerminalId],
    [sellerTerminalId, terminalObjects],
  )

  const numberFieldRef = useRef<HTMLInputElement>()

  const isEmptyQty = useMemo(() => _.isNil(qty), [qty])
  const isEmptyLds = useMemo(() => _.isNil(lds), [lds])

  const parseValueToNumber = useCallback(
    (value: undefined | string | null | number) => {
      if (value === undefined) {
        return 0
      }

      return Number(value)
    },
    [],
  )

  const placeholder = useMemo(() => {
    if (isFocusing) {
      return isUsingLds ? 'LDs' : 'Qty'
    }
    return ''
  }, [isFocusing, isUsingLds])

  const isDifferentValue = useMemo(() => {
    if (isUsingLds) {
      return parseValueToNumber(lds) !== parseValueToNumber(numberValue)
    }
    return parseValueToNumber(qty) !== parseValueToNumber(numberValue)
  }, [isUsingLds, lds, numberValue, parseValueToNumber, qty])

  const prefix = useMemo(() => {
    if (isUsingLds && ldsCounter) return `${ldsCounter} / `
    if (!isUsingLds && qtyCounter) return `${parseFloat(qtyCounter)} / `
    return ''
  }, [isUsingLds, ldsCounter, qtyCounter])

  const suffix = useMemo(() => {
    const str: string[] = []

    if (isUsingLds) {
      str.push('LD')
      sellerTerminal && str.push(sellerTerminal.code)
      return str.join(' - ')
    } else {
      plus && str.push(' +')
      currentUom && str.push(currentUom.code)
      return str.join(' ')
    }
  }, [currentUom, isUsingLds, plus, sellerTerminal])

  const onTogglePlus = useCallback(() => {
    const nextValue = !isUsingLds
    let val: null | number = null
    if (nextValue) {
      if (!isEmptyLds) {
        val = Number(lds)
      }
    } else {
      if (!isEmptyQty) {
        val = Number(qty)
      }
    }
    setIsUsingLds(nextValue)
    setNumberValue(val)
  }, [isEmptyLds, isEmptyQty, isUsingLds, lds, qty])

  const handleChangeValue = useCallback(() => {
    const params: ICustomLdsQtyFieldOnChangeParams = {}
    if (isUsingLds) {
      params.lds = numberValue
      params.qty = null
    } else {
      params.qty = numberValue
      params.lds = null
    }
    onChange && onChange(params)
  }, [isUsingLds, numberValue, onChange])

  useEffect(() => {
    const result = lds || qty
    setNumberValue(Number(result))
    setIsUsingLds(Boolean(lds))
  }, [lds, qty])

  const onMouseLeave = useCallback(() => {
    setIsFocusing(false)
    numberFieldRef.current?.blur()
    if (isDifferentValue) {
      handleChangeValue()
    }
  }, [handleChangeValue, isDifferentValue])

  const tooltipContent = useMemo(() => {
    const companyName = companies[sellerTerminal?.companyId]?.name
    return `${companyName || ''} ${sellerTerminal?.name || ''}`
  }, [companies, sellerTerminal])

  const onChangePlus = useCallback(() => {
    onChangePlusLoad &&
      onChangePlusLoad({
        plus: !plus,
      })
  }, [onChangePlusLoad, plus])

  return (
    <ToolTipOverlay content={tooltipContent} placement='top'>
      <div className='CustomLdsQtyField__container' onMouseLeave={onMouseLeave}>
        <ReactNumberFormat
          value={numberValue || ''}
          placeholder={placeholder}
          className='numberInput'
          customInput={CustomInput}
          onFocus={() => setIsFocusing(true)}
          suffix={suffix ? ` ${suffix}` : undefined}
          prefix={prefix}
          onValueChange={({ floatValue }) => setNumberValue(floatValue)}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.keyCode === 13) onMouseLeave()
          }}
          getInputRef={numberFieldRef}
        />
        {isFocusing && (
          <>
            {!isUsingLds && (
              <ToolTipOverlay
                placement='top'
                content={plus ? 'Remove Plus load' : 'Add Plus Load'}
              >
                <span
                  className='clickable'
                  style={{ marginRight: 4 }}
                  onClick={onChangePlus}
                >
                  {plus ? (
                    <CloseIcon color='var(--bs-danger)' />
                  ) : (
                    <PlusIcon color='var(--ion-color-concord)' />
                  )}
                </span>
              </ToolTipOverlay>
            )}
            <ToolTipOverlay
              content={`Switch to ${isUsingLds ? 'Qty' : 'LDs'}`}
              placement='top'
            >
              <Form className='formCheckbox'>
                <Form.Check
                  type='switch'
                  checked={isUsingLds}
                  onChange={onTogglePlus}
                />
              </Form>
            </ToolTipOverlay>
          </>
        )}
      </div>
    </ToolTipOverlay>
  )
}
export default CustomLdsQtyField
