import { useMemo, useCallback } from 'react'
import useStoreData from '~/hooks/useStoreData'

import _ from 'lodash'
import defaultSorts from './defaultSorts'
import moment from 'moment'

const useStoreSorts = moduleName => {
  const { moduleStoreData, DEFAULT_STRUCTURE_DATA, saveSortsData } =
    useStoreData(moduleName)
  const sortDefaultValues = useMemo(() => {
    return _.get(defaultSorts, moduleName) || _.get(defaultSorts, 'default')
  }, [moduleName])

  const storedSorts = useMemo(() => {
    const { sorts } = DEFAULT_STRUCTURE_DATA
    try {
      const savingData = _.get(moduleStoreData, 'sorts')

      if (_.isPlainObject(savingData)) {
        if (_.isNil(savingData.data) || _.size(savingData.data) === 0) {
          return {
            ...savingData,
            data: sortDefaultValues,
          }
        }

        return savingData
      }

      return {
        ...sorts,
        data: sortDefaultValues,
      }
    } catch (error) {
      console.log('error', error)

      return {
        ...sorts,
        data: sortDefaultValues,
      }
    }
  }, [DEFAULT_STRUCTURE_DATA, moduleStoreData, sortDefaultValues])

  // const storedEmptySort = useMemo(() => {
  //   if (_.size(storedSorts?.data) === 0) {
  //     return []
  //   }

  //   return _.map(storedSorts?.data || [], (sortItem) => ({
  //     ...sortItem,
  //     sorted: false
  //   }))
  // }, [storedSorts?.data])

  // const isSortEmpty = useMemo(() => {
  //   if (_.size(storedSorts?.data) === 0) {
  //     return true
  //   }

  //   return _.every(storedSorts?.data || [], ['sorted', false])
  // }, [storedSorts?.data])

  const handleStoreData = useCallback(
    newSortsData => {
      const sortsData = {
        ...storedSorts,
        ...newSortsData,
        updatedAt: moment().format('YYYY-MM-DD'),
      }
      saveSortsData(sortsData)
    },
    [saveSortsData, storedSorts],
  )

  const handleResetStoreData = useCallback(() => {
    handleStoreData(sortDefaultValues)
  }, [handleStoreData, sortDefaultValues])

  // const handleClearStoreData = useCallback(() => {
  //   handleStoreData(storedEmptySort)
  // }, [handleStoreData, storedEmptySort])

  return [storedSorts, handleStoreData, handleResetStoreData]
}

export default useStoreSorts
