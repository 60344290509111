import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryCompanies } from '~/hooks/useQueryData'

import {
  ConcordFormStructure,
  ConcordFormAddressInput,
  ConcordDropdownV2WithFormControl,
  ToggleSection,
  TerminalMap,
} from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { FORM_FIELD_TYPE, STATUS_OPTIONS } from '~/utils/constants'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'
import { EFieldType, EStatus } from '~/types/enums/ECommonEnum'
import { EAddressTypeStr } from '~/types/enums/EAddress'
import buildFullAddress from '~/utils/buildFullAddress'

import './styles.scss'
import { produce } from 'immer'

const TerminalForm = (props, ref) => {
  const {
    afterCreate,
    afterUpdate,
    hiddenFields = [],
    savedRelationship = {},
    formData,
    defaultValues,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { companyOptions, findCompanyById, isLoadingCompaniesData } =
    useQueryCompanies({})

  const isUpdating = useMemo(() => formData?.id, [formData?.id])

  const getAddressPayload = useCallback(
    terminalForm => {
      const { addressAttributes } = terminalForm.locationAttributes

      return {
        address: {
          line1: addressAttributes.street,
          city: addressAttributes.city,
          state: addressAttributes.state,
          postalCode: addressAttributes.zip,
          country: addressAttributes.country,
        },
        company: currentCompany.checkUid, //?
      }
    },
    [currentCompany.checkUid],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideAddressFields = ({ watch }) => {
    const address = watch('locationAttributes.addressAttributes', {})
    return Object.keys(address || {}).every(field => !address[field])
  }

  const fields = useMemo(
    () => [
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
        size: 4,
      },
      {
        label: 'Code',
        name: 'code',
        size: 2,
      },
      {
        label: 'Company',
        name: 'companyId',
        size: 6,
        type: EFieldType.singleSelect,
        options: companyOptions,
        isRequired: true,
        isLoading: isLoadingCompaniesData,
        isHidden: hiddenFields.includes('companyId'),
        render({ label, name, setValue, control }) {
          return (
            <ConcordDropdownV2WithFormControl
              isRequired
              options={companyOptions}
              control={control}
              name={name}
              label={label}
              onChange={({ value }) => {
                if (savedRelationship[value]) {
                  const { location, address } = savedRelationship[value]
                  setValue('locationAttributes', {
                    lat: location?.lat,
                    lng: location?.lng,
                    addressAttributes: address,
                  })
                } else {
                  setValue('locationAttributes', undefined)
                }
              }}
            />
          )
        },
      },
      {
        label: 'Color',
        name: 'color',
        type: EFieldType.color,
      },
      {
        label: 'Search address',
        name: 'searchAddress',
        render({ label, setValue, watch, clearErrors, errors }) {
          console.log('errors', errors)

          const isHiddenAdd = hideAddressFields({ watch })
          const watchLocation = watch('locationAttributes', {})
          const watchAddress = watch('locationAttributes.addressAttributes', {})
          const watchGeofence = watch(
            'locationAttributes.geofenceAttributes',
            {},
          )
          const watchTimezone = watch('timeZone', '')
          const value = buildFullAddress(watchAddress)
          const hasError =
            Object.keys(errors.locationAttributes || {}).length > 0

          return (
            <ConcordFormAddressInput
              label={label}
              showTimezoneBadge
              isShowedByDefault={isHiddenAdd}
              value={value}
              showManualAdress
              error={hasError ? 'Address is required!' : ''}
              timezone={watchTimezone}
              onSelectTimezone={tz => {
                setValue('timeZone', tz)
              }}
              onChange={(
                { isNew, value },
                { location, address, coordinates },
              ) => {
                clearErrors([
                  'locationAttributes.addressAttributes.city',
                  'locationAttributes.addressAttributes.country',
                  'locationAttributes.addressAttributes.zip',
                  'locationAttributes.addressAttributes.street',
                  'locationAttributes.addressAttributes.state',
                ])
                if (isNew) {
                  setValue('locationAttributes', {
                    id: watchLocation.id,
                    inputtedAddress: value,
                  })
                } else {
                  setValue('locationAttributes', {
                    id: watchLocation.id,
                    lat: location.lat,
                    lng: location.lng,
                    default: true,
                    addressAttributes: {
                      id: watchAddress.id,
                      city: address.city,
                      country: address.country,
                      zip: address.postalCode,
                      street: address.street,
                      state: address.state,
                    },
                    geofenceAttributes: coordinates
                      ? {
                          coordinatesAttributes: coordinates,
                        }
                      : watchGeofence,
                  })
                }
              }}
            />
          )
        },
      },
      {
        label: 'Street',
        name: 'locationAttributes.addressAttributes.street',
        size: 9,
        isRequired: true,
        isHidden: hideAddressFields,
      },
      {
        label: 'Line 2',
        name: 'locationAttributes.addressAttributes.line_2',
        size: 3,
        isHidden: hideAddressFields,
      },
      {
        label: 'City',
        name: 'locationAttributes.addressAttributes.city',
        size: 4,
        isRequired: true,
        isHidden: hideAddressFields,
      },
      {
        label: 'State',
        name: 'locationAttributes.addressAttributes.state',
        size: 3,
        isRequired: true,
        isHidden: hideAddressFields,
        // type: EFieldType.singleSelect,
        // options: stateOptions,
      },
      {
        label: 'Zip',
        name: 'locationAttributes.addressAttributes.zip',
        size: 3,
        isHidden: hideAddressFields,
      },
      {
        label: 'Country',
        name: 'locationAttributes.addressAttributes.country',
        size: 2,
        isRequired: true,
        isHidden: hideAddressFields,
      },
      {
        label: 'View Terminal and Set Geofence on Map',
        name: 'viewTerminalOnMap',
        isHidden: hideAddressFields,
        render({ watch, setValue }) {
          const terminal = watch()
          return (
            <ToggleSection label='View Terminal and Set Geofence on Map'>
              <div className='TerminalForm__mapContainer'>
                <TerminalMap
                  location={{
                    lat: terminal?.locationAttributes?.lat,
                    lng: terminal?.locationAttributes?.lng,
                  }}
                  defaultLocation={{
                    lat: formData?.location?.lat,
                    lng: formData?.location?.lng,
                  }}
                  geofence={terminal.locationAttributes.geofenceAttributes}
                  defaultGeofence={{
                    id: formData?.geofence?.id,
                    coordinatesAttributes: formData?.coordinates,
                  }}
                  coordinates={
                    terminal.locationAttributes.geofenceAttributes
                      ?.coordinatesAttributes
                  }
                  defaultCoordinates={formData?.coordinates}
                  onChangeLocation={(location, address) => {
                    setValue('locationAttributes', {
                      ...terminal.locationAttributes,
                      ...location,
                      addressAttributes: {
                        id: terminal.locationAttributes.addressAttributes.id,
                        ...address,
                      },
                    })
                  }}
                  onDrawGeofence={points => {
                    const locationAttr = produce(
                      terminal.locationAttributes,
                      draft => {
                        draft.geoCode = false
                        draft.geofenceSkipCreate = true
                        draft.default = true
                        if (draft.geofenceAttributes) {
                          const oldCoordinates = (
                            draft.geofenceAttributes.coordinatesAttributes || []
                          )
                            .filter(({ id }) => id)
                            .map(geo => ({
                              id: geo.id,
                              _destroy: true,
                            }))
                          draft.geofenceAttributes.coordinatesAttributes = [
                            ...oldCoordinates,
                            ...points,
                          ]
                        } else {
                          draft.geofenceAttributes = {
                            coordinatesAttributes: points,
                          }
                        }
                      },
                    )
                    setValue('locationAttributes', locationAttr)
                  }}
                  onRevert={() => {
                    setValue('locationAttributes', {
                      lat: formData?.location?.lat,
                      lng: formData?.location?.lng,
                      id: formData?.location?.id,
                      addressAttributes: {
                        id: formData?.address?.id,
                        city: formData?.address?.city,
                        country: formData?.address?.country,
                        zip: formData?.address?.zip,
                        street: formData?.address?.street,
                        state: formData?.address?.state,
                        line_2: formData?.address?.line2,
                      },
                      geofenceAttributes: {
                        id: formData?.geofence?.id,
                        coordinatesAttributes: formData?.coordinates,
                      },
                    })
                  }}
                />
              </div>
            </ToggleSection>
          )
        },
      },
      {
        label: 'Status',
        name: 'status',
        type: FORM_FIELD_TYPE.radio,
        options: STATUS_OPTIONS,
        size: 12,
      },
      {
        label: 'Create workplace in Check',
        name: 'sendToCheck',
        type: EFieldType.checkbox,
        isDisabled({ watch }) {
          const checkUid = watch('checkUid', null)
          return checkUid && isUpdating
        },
        size: 12,
        isHidden({ watch }) {
          const companyId = watch('companyId', null)
          const company = findCompanyById(companyId)

          return !company?.checkUid
        },
      },
    ],
    [
      companyOptions,
      isLoadingCompaniesData,
      hiddenFields,
      savedRelationship,
      formData,
      isUpdating,
      findCompanyById,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Name is required!'),
        companyId: Yup.number().required('Company is required!'),
        locationAttributes: Yup.lazy(val => {
          if (val.inputtedAddress) {
            return Yup.object()
          }

          return Yup.object({
            addressAttributes: Yup.object({
              city: Yup.string().required('City is required!').nullable(),
              country: Yup.string().required('Country is required!').nullable(),
              zip: Yup.string().nullable(),
              street: Yup.string().required('Street is required!').nullable(),
              state: Yup.string()
                .required('This field is required!')
                .nullable(),
            }),
          })
        }),
      }),
    [],
  )

  const createOrDeleteCoordinates = useCallback(
    async (coordinates, formValues) => {
      const oldCoordinates = []
      const newCoordinates = []

      coordinates.forEach(({ _destroy, id, lat, lng }) => {
        if (_destroy && id) {
          oldCoordinates.push({
            id,
            _destroy: true,
          })
        } else if (!id) {
          newCoordinates.push({
            lat: lat,
            lng: lng,
          })
        }
      })

      return apiClient.geofences.update(
        formValues.locationAttributes.geofenceAttributes?.id,
        {
          geofence: {
            coordinatesAttributes: [...oldCoordinates, ...newCoordinates],
          },
        },
      )
    },
    [],
  )

  const updateTerminal = useCallback(
    async ({ id, ...formValues }) => {
      const payload = _.pick(formValues, [
        'code',
        'name',
        'timeZone',
        'status',
        'color',
      ])

      if (formValues.locationAttributes.inputtedAddress) {
        payload.skipLocationCreate = true
        payload.locationAttributes = {
          default: true,
          geoCode: false,
          addressAttributes: {
            fullAddress: formValues.locationAttributes.inputtedAddress,
            addressTypes: [EAddressTypeStr.location],
          },
          geofenceAttributes: {},
        }
      } else {
        const locationAttributes = {
          lat: formValues.locationAttributes.lat,
          lng: formValues.locationAttributes.lng,
          id: formValues.locationAttributes.id,
          default: true,
          addressAttributes: {
            ...formValues.locationAttributes.addressAttributes,
            addressTypes: [EAddressTypeStr.location],
          },
        }

        if (formValues.locationAttributes.geofenceAttributes?.id) {
          await createOrDeleteCoordinates(
            formValues.locationAttributes.geofenceAttributes
              .coordinatesAttributes || [],
            formValues,
          )
        }

        if (
          Object.keys(formValues.locationAttributes.geofenceAttributes || {})
            .length === 0
        ) {
          locationAttributes.geoCode = false
          locationAttributes.geofenceAttributes = undefined
        } else {
          locationAttributes.geoCode = false
          locationAttributes.geofenceSkipCreate = true
          locationAttributes.geofenceAttributes =
            formValues.locationAttributes.geofenceAttributes
        }

        payload.locationAttributes = locationAttributes
      }

      payload.locationsAttributes = [payload.locationAttributes]

      if (!formData.checkUid) {
        payload.sendToCheck = formValues.sendToCheck
      }
      const response = await apiClient.terminals.update(id, {
        terminal: _.omit(payload, 'locationAttributes'),
        includeLocation: true,
        includeAddress: true,
        includeGeofence: true,
      })
      if (response.id) {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      } else {
        setBackendError(toastMessages.updateError)
      }
    },
    [afterUpdate, createOrDeleteCoordinates, formData?.checkUid],
  )

  const createTerminal = useCallback(
    async ({ isCreateWorkplace, ...formValues }) => {
      const payload = {
        ...formValues,
      }
      if (formValues.locationAttributes.inputtedAddress) {
        payload.skipLocationCreate = true
        payload.locationAttributes = {
          default: true,
          geoCode: false,
          addressAttributes: {
            fullAddress: formValues.locationAttributes.inputtedAddress,
            addressTypes: [EAddressTypeStr.location],
          },
          geofenceAttributes: {},
        }
      } else {
        payload.locationAttributes = {
          ...formValues.locationAttributes,
          addressAttributes: {
            ...formValues.locationAttributes.addressAttributes,
            addressTypes: [EAddressTypeStr.location],
          },
          // geofenceAttributes: formValues.locationAttributes.geofenceAttributes,
        }

        if (
          Object.keys(formValues.locationAttributes.geofenceAttributes || {})
            .length === 0
        ) {
          payload.locationAttributes.geoCode = false
          payload.locationAttributes.geofenceAttributes = undefined
        } else {
          payload.locationAttributes.geoCode = false
          payload.locationAttributes.geofenceSkipCreate = true
          payload.locationAttributes.geofenceAttributes =
            formValues.locationAttributes.geofenceAttributes
        }
      }

      payload.locationsAttributes = [payload.locationAttributes]

      const response = await apiClient.terminals.create({
        terminal: _.omit(payload, 'locationAttributes'),
        includeLocation: true,
        includeAddress: true,
        includeGeofence: true,
      })
      if (response.id) {
        if (isCreateWorkplace && currentCompany.checkUid) {
          const addressPayload = getAddressPayload(formValues)
          const res = await apiClient.check.createTerminal(
            {
              terminal: addressPayload,
            },
            { terminalId: response.id },
          )
          if (res.id) {
            response.checkUid = res.checkUid
          }
        }

        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [afterCreate, currentCompany.checkUid, getAddressPayload],
  )

  const handleSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await updateTerminal(formValues)
        } else {
          await createTerminal(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    [createTerminal, isUpdating, updateTerminal],
  )

  useEffect(() => {
    if (ref !== null) {
      ref.current = {
        ...ref.current,
        onSubmitForm: handleSubmit,
      }
    }
  }, [handleSubmit, ref])

  useEffect(() => {
    if (isUpdating && formData?.location) {
      ref?.current?.setValue?.('locationAttributes', {
        lat: formData?.location?.lat,
        lng: formData?.location?.lng,
        id: formData?.location?.id,
        addressAttributes: {
          id: formData?.address?.id,
          city: formData?.address?.city,
          country: formData?.address?.country,
          zip: formData?.address?.zip,
          street: formData?.address?.street,
          state: formData?.address?.state,
          line_2: formData?.address?.line2,
        },
        geofenceAttributes: {
          id: formData?.geofence?.id,
          coordinatesAttributes: formData?.coordinates,
        },
      })
    } else {
      ref?.current?.setValue?.('locationAttributes', {})
    }
    ref?.current?.setValue?.('sendToCheck', Boolean(formData?.checkUid))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating])

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      defaultValues={{
        status: EStatus.Active,
        companyId: defaultValues?.companyId || currentCompany.id,
        locationAttributes: {
          geofenceAttributes: {
            coordinatesAttributes: [],
          },
        },
      }}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      ref={ref}
      submitText={isUpdating ? 'Update' : 'Create'}
      isHiddenCancelButton
    />
  )
}

export default React.forwardRef(TerminalForm)
