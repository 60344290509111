import React, { useCallback } from 'react'
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonInput,
} from '@ionic/react'
import {
  crop,
  arrowRedoOutline,
  arrowUndoOutline,
  removeCircleOutline,
  addCircleOutline,
} from 'ionicons/icons'
import { ImagePreview } from '~/components/shared'

const ImageEditing = props => {
  const {
    onSetCropper,
    editedImage,
    isEditing,
    imageName,
    onSetImageName,
    onCropImage,
    isCropping,
    onZoom,
    onMoveLeft,
    onMoveRight,
    onDoneEditing,
    updateEditing,
    setIsCropping,
    onCancel,
    useFileInModal,
    fromImagePreview,
  } = props

  const _onSetCropper = useCallback(
    cropper => {
      onSetCropper && onSetCropper(cropper)
    },
    [onSetCropper],
  )

  const _onCancel = useCallback(() => {
    if (useFileInModal) onCancel && onCancel()
    else {
      updateEditing && updateEditing(false)
      setIsCropping && setIsCropping(false)
    }
  }, [useFileInModal, onCancel, updateEditing, setIsCropping])

  const zoomIn = () => {
    onZoom('+')
  }

  const zoomOut = () => {
    onZoom('-')
  }

  return (
    <IonCard
      style={{
        maxWidth: fromImagePreview
          ? window.innerWidth * 0.9
          : window.innerWidth * 0.7,
      }}
    >
      <IonCardContent>
        <IonRow
          no-padding
          style={
            fromImagePreview && { display: 'flex', justifyContent: 'center' }
          }
        >
          <ImagePreview
            imagefile={[editedImage]}
            saveCropper={cropper => _onSetCropper(cropper)}
            cropstart={isEditing}
            cropdata={editedImage?.image}
          >
            Image preview
          </ImagePreview>
        </IonRow>
        <IonRow style={{ marginTop: 20, alignItems: 'center' }}>
          <IonLabel>File Name: </IonLabel>
          <IonInput
            placeholder='Enter file name'
            style={{ backgroundColor: '#dbdbdb' }}
            value={imageName}
            onIonChange={e => {
              onSetImageName(e.detail.value)
            }}
          />
        </IonRow>
        <IonRow>
          <IonCol style={{ textAlign: 'center' }}>
            <IonButton onClick={zoomOut}>
              <IonIcon icon={removeCircleOutline} />
            </IonButton>
            <IonButton onClick={onMoveLeft}>
              <IonIcon icon={arrowRedoOutline} />
            </IonButton>
            <IonButton
              onClick={onCropImage}
              color={isCropping ? 'danger' : 'primary'}
            >
              <IonIcon icon={crop} />
            </IonButton>
            <IonButton onClick={onMoveRight}>
              <IonIcon icon={arrowUndoOutline} />
            </IonButton>
            <IonButton onClick={zoomIn}>
              <IonIcon icon={addCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ textAlign: 'center' }}>
            <IonButton color='success' onClick={onDoneEditing}>
              <IonLabel>Done</IonLabel>
            </IonButton>

            <IonButton color='danger' onClick={_onCancel}>
              <IonLabel>Cancel</IonLabel>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default ImageEditing
