const DEFAULT_LOAD_COLUMNS = [
  {
    displayIndex: 0,
    label: '',
    columnName: 'checkbox',
    sortField: 'checkbox',
    field: 'checkbox',
    type: '',
    isField: false,
    show: true,
    sorted: false,
    isAsc: true,
    sortable: false,
    checkboxColumn: true,
    hideable: false,
    headerStyle: {
      borderRightWidth: '.5px',
      width: '4%',
    },
    showCheckbox: 'true',
  },
  {
    displayIndex: 1,
    label: 'Actions',
    columnName: 'menuActions',
    sortField: 'menuActions',
    field: 'menuActions',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '9.25%',
    },
    type: 'menuActions',
    show: true,
    isField: false,
    sorted: false,
    isAsc: true,
    sortable: false,
    hideable: false,
  },
  {
    displayIndex: 2,
    label: '#',
    isField: true,
    columnName: 'num',
    sortField: 'num',
    value: 'num',
    type: 'text',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '7.25%',
    },
    show: true,
    sorted: false,
    isAsc: true,
    sortIndex: 0,
    sortable: true,
    field: 'num',
    hideable: true,
  },
  {
    displayIndex: 3,
    label: 'Date',
    columnName: 'date',
    sortField: 'date',
    type: 'date',
    isField: true,
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '9.25%',
    },
    show: true,
    sorted: false,
    isAsc: true,
    sortIndex: 1,
    sortable: true,
    field: 'date',
    hideable: true,
  },
  {
    displayIndex: 4,
    label: 'Qty',
    columnName: 'qty',
    sortField: 'qty',
    type: 'number',
    show: true,
    isField: true,
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '8%',
    },
    sorted: false,
    isAsc: true,
    sortIndex: 2,
    sortable: true,
    field: 'qty',
    hideable: true,
  },
  {
    displayIndex: 5,
    label: 'Seller',
    columnName: 'sellerBuyer',
    sortField: 'sellerBuyer',
    type: 'select',
    show: true,
    isField: true,
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '14.25%',
    },
    sorted: false,
    isAsc: true,
    sortable: false,
    field: 'sellerBuyer',
    hideable: true,
  },
  {
    displayIndex: 6,
    label: 'Seller Terminal',
    columnName: 'seller_terminal',
    sortField: 'seller_terminal',
    type: 'select',
    show: true,
    isField: true,
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '10.25%',
    },
    sorted: false,
    isAsc: true,
    sortable: false,
    field: 'seller_terminal',
    hideable: true,
  },
  {
    displayIndex: 7,
    label: 'Buyer Terminal',
    columnName: 'buyer_terminal',
    sortField: 'buyer_terminal',
    type: 'select',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '10.25%',
    },
    show: true,
    isField: true,
    sorted: false,
    isAsc: true,
    sortable: false,
    field: 'buyer_terminal',
    hideable: true,
  },
  {
    displayIndex: 8,
    label: 'Buyer Seller Product',
    columnName: 'buyer_seller_product',
    sortField: 'buyer_seller_product',
    type: 'select',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '13.25%',
    },
    show: true,
    isField: true,
    sorted: false,
    isAsc: true,
    sortable: false,
    field: 'buyer_seller_product',
    hideable: true,
  },
  {
    displayIndex: 9,
    label: 'Fleet',
    columnName: 'fleet',
    sortField: 'fleet',
    type: 'select',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '10.25%',
    },
    show: true,
    isField: true,
    sorted: false,
    isAsc: true,
    sortable: false,
    field: 'fleet',
    hideable: true,
  },
  {
    displayIndex: 10,
    label: 'Status',
    columnName: 'primary_parser_status',
    sortField: 'primary_parser_status',
    type: 'status',
    headerStyle: {
      borderRightWidth: '.5px',
      textAlign: 'center',
      fontSize: '15px',
      width: '8.25%',
    },
    show: true,
    isField: true,
    sorted: false,
    isAsc: true,
    sortIndex: 3,
    sortable: true,
    field: 'primary_parser_status',
    hideable: true,
  },
]

export default DEFAULT_LOAD_COLUMNS
