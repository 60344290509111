import { useCallback, useState } from 'react'
import './ColorPickerInput.scss'
import { IonPopover } from '@ionic/react'
import { SketchPicker } from 'react-color'

function ColorPickerInput(props) {
  const { color = 'white', onCompletedChangeColor } = props

  const [colorValue, setColorValue] = useState(color)
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  })

  const onOpenColor = useCallback(e => {
    e.stopPropagation()
    setShowPopover({ showPopover: true, event: e })
  }, [])

  const onCloseColor = useCallback(() => {
    setShowPopover({ showPopover: false, event: undefined })
  }, [])

  return (
    <div>
      <div
        onClick={onOpenColor}
        className='colorPickerInput_input'
        style={{ backgroundColor: colorValue }}
      />
      <IonPopover
        cssClass={'popupClass'}
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={onCloseColor}
        mode='ios'
      >
        <SketchPicker
          className='sketchPicker'
          color={colorValue}
          onChange={e => setColorValue(e.hex)}
          onChangeComplete={event => {
            onCompletedChangeColor && onCompletedChangeColor(event.hex)
          }}
        />
      </IonPopover>
    </div>
  )
}

export default ColorPickerInput
