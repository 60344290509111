import { useState, useEffect, useCallback, useRef, memo } from 'react'
import { useClickAway } from 'react-use'
import clsx from 'clsx'

import './styles.scss'

const EditInlineTextField = props => {
  const {
    value,
    onChange,
    defaultValue,
    name,
    matched = true,
    className,
    ...textFieldProps
  } = props

  const [textValue, setTextValue] = useState('')
  const color = matched ? '#1090ff' : 'orange'

  const inputRef = useRef()

  const handleChange = useCallback(event => {
    const { value } = event.target
    setTextValue(value)
  }, [])

  const handleSubmitForm = useCallback(
    event => {
      event.preventDefault()
      onChange && onChange(event, { value: textValue, name })
      inputRef.current.blur()
    },
    [name, onChange, textValue],
  )

  const handleKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Escape') {
        setTextValue(defaultValue)
      }
    },
    [defaultValue],
  )

  useClickAway(inputRef, () => {
    setTextValue(defaultValue)
  })

  useEffect(() => {
    setTextValue(value)
  }, [value])

  return (
    <form
      onSubmit={handleSubmitForm}
      onKeyDown={handleKeyDown}
      className='TextFieldEditInline__form'
    >
      <input
        ref={inputRef}
        style={{ color }}
        className={clsx('TextFieldEditInline__input', className)}
        type='text'
        value={textValue}
        onChange={handleChange}
        {...textFieldProps}
      />
    </form>
  )
}

export default memo(EditInlineTextField)
