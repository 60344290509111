import { useState } from 'react'
import { useQueryBundles, useQuerySellerProducts } from '~/hooks/useQueryData'

import Tooltip from 'rc-tooltip'
import { Popover } from 'react-bootstrap'
import { ReusableTable } from '~/components/shared'
import { SellerTerminalProductsList } from '../../ConcordForm/FormData/BundleForm/SellerTerminalProductsList'
import { BundleItemsList } from '../../ConcordForm/FormData/BundleForm/BundleItemsList'

import './styles.scss'

export interface IBundleItemsOverlayProps {
  children: any
  sellerProductId: number
}

function BundleItemsOverlay(props: IBundleItemsOverlayProps) {
  const { children, sellerProductId } = props

  const [visible, setVisible] = useState(false)

  const { bundlesData, isLoadingBundles } = useQueryBundles(
    {
      filters: {
        sellerProductId,
      },
    },
    { enabled: Boolean(sellerProductId) && visible },
  )

  const { sellerProducts } = useQuerySellerProducts()

  return (
    <Tooltip
      placement='top'
      overlay={
        <Popover className='BundleItemsOverlay__popover'>
          <Popover.Body>
            <ReusableTable
              data={bundlesData}
              columns={[
                {
                  header: '#',
                  accessorKey: 'num',
                  minSize: 55,
                  maxSize: 55,
                },
                {
                  header: 'Name',
                  accessorKey: 'name',
                  minSize: 120,
                  maxSize: 120,
                },
                {
                  header: 'Seller Product',
                  accessorKey: 'sellerProductId',
                  minSize: 150,
                  maxSize: 150,
                  Cell({ row }) {
                    const rowData = row.original
                    const { sellerProductId } = rowData
                    const product = sellerProducts.find(
                      ({ id }) => id === sellerProductId,
                    )
                    return product?.name
                  },
                },
              ]}
              tableHeight={345}
              state={{
                isLoading: isLoadingBundles,
              }}
              renderDetailPanel={({ row }) => {
                const rowData = row.original

                return (
                  <div style={{ padding: 8 }}>
                    <SellerTerminalProductsList
                      bundleId={rowData.id}
                      sellerProductId={sellerProductId}
                    />
                    <BundleItemsList
                      bundleId={rowData.id}
                      sellerProductId={sellerProductId}
                    />
                  </div>
                )
              }}
            />
          </Popover.Body>
        </Popover>
      }
      destroyTooltipOnHide
      visible={visible}
      overlayClassName='BundleItemsOverlay__overlay'
      onVisibleChange={setVisible}
    >
      {children}
    </Tooltip>
  )
}

export default BundleItemsOverlay
