import { useMemo } from 'react'
import { FORM_FIELD_TYPE } from '~/utils/constants'
import { ConcordFormStructure } from '../../FormStructure'
import { driverFleetSchema } from './schemas'

export const DriverFleetTab = props => {
  const defaultValues = useMemo(
    () => ({
      rank: '',
      color: '',
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'rank',
        label: 'Rank',
        size: 6,
        type: FORM_FIELD_TYPE.number,
      },
      {
        name: 'color',
        label: 'Color',
        size: 4,
        type: FORM_FIELD_TYPE.color,
      },
    ],
    [],
  )
  return (
    <ConcordFormStructure
      fields={fields}
      schema={driverFleetSchema}
      submitText='Next'
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}
