import { CommonDialogV2, ICommonDialogProps } from '~/components/shared'
import StartTimeForm, { IStartTimeFormProps } from './StartTimeForm'

interface IDialogStartTimeFormProps
  extends ICommonDialogProps,
    IStartTimeFormProps {}

function DialogStartTimeForm(props: IDialogStartTimeFormProps) {
  const { afterCreateStartTimes, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Create Start Times'
      size='xl'
      isHiddenOkButton
    >
      <StartTimeForm afterCreateStartTimes={afterCreateStartTimes} />
    </CommonDialogV2>
  )
}

export default DialogStartTimeForm
