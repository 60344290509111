const DEFAULT_COMPANY_SELLER_PRODUCT_COLUMNS = [
  {
    displayIndex: 0,
    sortIndex: 0,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    sortIndex: 1,
    label: 'Name',
    field: 'name',
    show: true,
    value: 'name',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    sortIndex: 2,
    label: 'Code',
    field: 'code',
    show: true,
    value: 'code',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    sortField: 'code',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    sortIndex: 3,
    label: 'Description',
    field: 'description',
    show: true,
    value: 'description',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'description',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    sortIndex: 4,
    label: 'Product code - name',
    field: 'product.name',
    show: true,
    value: 'product.name',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'product.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    sortIndex: 5,
    label: 'Status',
    field: 'status',
    show: true,
    value: 'status',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'status',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_SELLER_PRODUCT_COLUMNS
