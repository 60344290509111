import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IGetNewGroupDataResponse } from '~/types/models/IUserGroup'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryNewGroup = (
  options?: Partial<UseQueryOptions<IGetNewGroupDataResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['newGroup', sessionUser?.id],
    queryFn() {
      return apiClient.userGroups.new()
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  return {
    newGroupData: data,
    isLoadingNewGroup: isLoading,
  }
}

export default useQueryNewGroup
