import { useState, useCallback } from 'react'
import _ from 'lodash'

const usePdfPageSelector = () => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [submittedPage, setSubmittedPage] = useState(1)

  const onChange = useCallback(
    e => {
      const value = +e.target.value.replace(/[^0-9]/g, ' ')
      if (value > numPages) return
      setPageNumber(value)
    },
    [numPages],
  )

  const isPageNumberInvalid = number => {
    return number === 0 || _.isNaN(number) || !_.isEmpty(number)
  }

  const handlePageValue = useCallback(() => {
    if (isPageNumberInvalid(pageNumber)) setPageNumber(submittedPage)
    else setSubmittedPage(pageNumber)
  }, [pageNumber, submittedPage])

  const onBlur = useCallback(() => {
    handlePageValue()
  }, [handlePageValue])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') handlePageValue()
    },
    [handlePageValue],
  )

  const updatePage = useCallback(page => {
    setSubmittedPage(page)
    setPageNumber(page)
  }, [])

  const incrementPage = useCallback(() => {
    updatePage(pageNumber + 1)
  }, [pageNumber, updatePage])

  const decrementPage = useCallback(() => {
    updatePage(pageNumber - 1)
  }, [pageNumber, updatePage])

  return {
    numPages,
    pageNumber,
    submittedPage,
    onKeyPress,
    onChange,
    onBlur,
    decrementPage,
    incrementPage,
    setNumPages,
    setPageNumber,
  }
}

export default usePdfPageSelector
