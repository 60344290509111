import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const LayerIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m21 14-9 6-9-6m18-4-9 6-9-6 9-6 9 6Z'
      />
    </svg>
  )
}
export default LayerIcon
