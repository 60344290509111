import _ from 'lodash'
import moment from 'moment'

export const translateDateRange = (dateRange, updatedAt) => {
  try {
    let { startDate, endDate } = dateRange
    const today = moment().format('YYYY-MM-DD')

    const diffDate = moment(updatedAt).diff(today, 'days')
    if (diffDate !== 0) {
      startDate = moment(startDate)
        .add(diffDate - 1, 'days')
        .toDate()
      endDate = moment(endDate)
        .add(diffDate - 1, 'days')
        .toDate()
    }

    return {
      ...dateRange,
      startDate,
      endDate,
    }
  } catch (error) {
    console.log('error', error)

    return {
      ...dateRange,
      startDate: null,
      endDate: null,
    }
  }
}

export const translateSavedFilters = filters => {
  const { updatedAt, data } = filters
  if (!updatedAt) {
    return filters
  }

  const newFilters = {}

  _.forEach(data, (value, key) => {
    let newValue = value
    if (_.isPlainObject(value)) {
      if (_.has(value, 'startDate') && _.has(value, 'endDate')) {
        newValue = translateDateRange(value, updatedAt)
      }
    }
    newFilters[key] = newValue
  })

  return {
    ...filters,
    filters: newFilters,
    data: newFilters,
  }
}

export const getSavedFiltersFromStorage = ({ moduleName, defaultValues }) => {
  const savedInfo = JSON.parse(localStorage.getItem('concord_materials'))
  const savedFilters = _.get(savedInfo, `${moduleName}.filters`)
  console.log('savedFilters in module', savedFilters)
  if (savedFilters) {
    return translateSavedFilters(savedFilters).data
  }

  return defaultValues
}
