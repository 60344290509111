import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_COMPANY_BUYER_TERMINAL_REFERENCE_COLUMNS } from '~/constants/companySubTabs'
import { moduleName } from '~/utils/constants'

const buyerTerminalReferencesSlice = createSlice({
  name: moduleName.company.buyerTerminalReferences,
  initialState: {
    columns: DEFAULT_COMPANY_BUYER_TERMINAL_REFERENCE_COLUMNS,
  },
})

export default buyerTerminalReferencesSlice
