import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryWorkers } from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { Button, CompanyAvatar } from '~/components/shared'
import { IonButtons } from '@ionic/react'
import { If, Else, Then, When, Unless } from 'react-if'
import { Form } from 'react-bootstrap'

import { checkmark, closeCircle, createOutline, trash } from 'ionicons/icons'
import { isToday, isSameYear } from '~/utils/getDay'
import moment from 'moment'
import { selectSessionUser } from '~/redux/selectors'
import buildFullName from '~/utils/buildFullName'
import autosize from 'autosize'

import type { INoteItemProps } from './type'
import type { IUser } from '~/types/models/IUser'

import './styles.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'

function NoteItem(props: INoteItemProps) {
  const { isUpdating, isHiddenCloseButton, onSubmit, noteData, onDelete } =
    props

  const textareaRef = useRef()

  const [isEditing, setIsEditing] = useState(false)
  const [noteText, setNoteText] = useState('')

  const { findWorkerByUserAccessId } = useQueryWorkers()

  const { confirmation } = useConfirmationProvider()

  const currentUser: IUser = useSelector(selectSessionUser)

  const author = useMemo(() => {
    if (currentUser.userAccess.id === noteData?.userAccessId) {
      return currentUser.person
    }
    const worker = findWorkerByUserAccessId(noteData?.userAccessId)
    return worker?.person
  }, [
    currentUser.person,
    currentUser.userAccess.id,
    findWorkerByUserAccessId,
    noteData?.userAccessId,
  ])

  const isShowingCheckmark = useMemo(() => {
    return (
      (isUpdating && noteText.length > 0) || (noteData?.note || '') !== noteText
    )
  }, [isUpdating, noteData?.note, noteText])

  const isShowingTextbox = useMemo(
    () => isUpdating || isEditing,
    [isEditing, isUpdating],
  )

  const renderTimestamp = useMemo(() => {
    let format = 'MMM-DD, YYYY HH:mm'
    if (isToday(noteData?.createdAt)) {
      format = 'HH:mm'
    } else if (isSameYear(noteData?.createdAt)) {
      format = 'MMM-DD HH:mm'
    }
    return moment(noteData?.createdAt).format(format)
  }, [noteData?.createdAt])

  const onToggleEditMode = useCallback(() => {
    setIsEditing(prev => !prev)
    setNoteText(noteData?.note || '')
  }, [noteData?.note])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target
      setNoteText(value)
    },
    [],
  )

  const onClickCheckmark = useCallback(() => {
    setIsEditing(false)
    onSubmit &&
      onSubmit({
        note: noteText,
        id: noteData?.id,
      })
  }, [noteData?.id, noteText, onSubmit])

  const onClickDelete = useCallback(async () => {
    if (noteData) {
      const result = await confirmation({
        message: 'Are you sure you want to delete this note',
      })
      if (result === EYesNo.Yes) {
        onDelete && onDelete(noteData)
      }
    }
  }, [confirmation, noteData, onDelete])

  useEffect(() => {
    setNoteText(noteData?.note || '')
  }, [noteData?.note])

  useEffect(() => {
    if (noteText) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      autosize(textareaRef.current as any)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      autosize.destroy(textareaRef.current as any)
    }
  }, [noteText])

  return (
    <div className='NoteItem__container'>
      <div className='header'>
        <CompanyAvatar
          company={{
            label: buildFullName(author),
            value: noteData?.userAccessId as number,
          }}
          width={30}
          height={30}
          className='avatar'
        />
        <span className='timestamp'>{renderTimestamp}</span>
        <IonButtons>
          <Unless condition={isHiddenCloseButton}>
            <Button
              size='small'
              icon={isShowingTextbox ? closeCircle : createOutline}
              color={isShowingTextbox ? 'medium' : 'warning'}
              tooltipProps={{
                content: isShowingTextbox ? 'Close' : 'Edit',
                placement: 'top',
              }}
              onClick={onToggleEditMode}
            />
            <Button
              size='small'
              icon={trash}
              color='danger'
              onClick={onClickDelete}
            />
          </Unless>
          <When condition={isShowingTextbox && isShowingCheckmark}>
            <Button
              icon={checkmark}
              color='success'
              tooltipProps={{
                content: 'Submit',
                placement: 'top',
              }}
              onClick={onClickCheckmark}
            />
          </When>
        </IonButtons>
      </div>

      <If condition={isShowingTextbox}>
        <Then>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Control
                style={{ fontSize: 13, marginTop: 12, maxHeight: 100 }}
                as='textarea'
                // rows={8}
                value={noteText}
                onChange={onChange}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ref={textareaRef as any}
              />
            </Form.Group>
          </Form>
        </Then>
        <Else>
          <div className='content'>{noteData?.note}</div>
        </Else>
      </If>
    </div>
  )
}
export default NoteItem
