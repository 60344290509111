import { useQueryTerminals } from '../useQueryTerminals'
import { useQueryCompanies } from '../useQueryCompanies'
import useQueryAddresses from './useQueryAddresses'

import { EAddressableType } from '~/types/enums/EAddress'

const useQueryCompanyAddresses = () => {
  const { isFetchedTerminals, terminalsData } = useQueryTerminals()
  const { isCompaniesDataFetched, companiesData } = useQueryCompanies({})

  const terminalIds = terminalsData.map(({ id }) => id)
  const companyIds = companiesData.map(({ id }) => id)

  const { addressesData } = useQueryAddresses(
    {
      filters: {
        addressableType: [EAddressableType.company, EAddressableType.terminal],
        addressableId: [...companyIds, ...terminalIds],
      },
    },
    {
      enabled:
        isCompaniesDataFetched &&
        isFetchedTerminals &&
        (terminalIds.length > 0 || companyIds.length > 0),
    },
  )

  return { addressesData }
}

export default useQueryCompanyAddresses
