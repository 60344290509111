import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import { selectMyCurrentCompany } from '~/redux/selectors'

export const useUom = () => {
  const [uom, setUom] = useState({})
  const { uomId } = useSelector(selectMyCurrentCompany)

  useEffect(() => {
    const fetchUom = async () => {
      const { uoms } = await apiClient.uoms.get({ id: uomId })
      setUom(uoms[0])
    }
    if (uomId) fetchUom()
  }, [uomId])

  return { uom }
}
