import { useEffect, useRef, useState } from 'react'
import { IAutoWidthInputProps } from './type'

function AutoWidthInput(props: IAutoWidthInputProps) {
  const { value, onChange, minWidth = 50, className, ...inputProps } = props

  const [inputWidth, setInputWidth] = useState(minWidth)

  const spanRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(Math.max(spanRef.current.offsetWidth, minWidth))
    }
  }, [value, minWidth])

  return (
    <div className={className}>
      <input
        type='text'
        value={value}
        onChange={onChange}
        style={{ width: inputWidth }}
        {...inputProps}
      />
      <span
        ref={spanRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre',
        }}
      >
        {value || ' '}
      </span>
    </div>
  )
}

export default AutoWidthInput
