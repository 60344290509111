import productsSlice from '~/redux/reducers/data/products.js'
import productsUiSlice from '~/redux/reducers/ui/products.js'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt.js'
import { apiClient } from '~/api/ApiClient'

export const getProducts = () => dispatch => {
  apiClient.products.get()
    .then(products => {
      const unix = Math.round(+new Date() / 1000)
      dispatch(productsSlice.actions.initializeProducts(products))
      dispatch(
        lastFetchedAtSlice.actions.updateLastFetchedAt({
          model: 'products',
          lastFetchedAt: unix,
        }),
      )
    })
    .catch(error => console.log(error))
}

export const updateProducts = payload => dispatch => {
  dispatch(productsSlice.actions.updateProduct(payload))
}

export const updateSelectedProduct = product => dispatch => {
  dispatch(productsUiSlice.actions.updateUi({ selectedProduct: product }))
}
