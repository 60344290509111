import { RTTableHeadCell } from './RTTableHeadCell'
import type {
  RTColumnVirtualizer,
  RTHeader,
  RTHeaderGroup,
  IReusableTableRowData,
  ReusableTableInstance,
  RTVirtualItem,
} from '../../types'

export interface IRTTableHeadRowProps<TData extends IReusableTableRowData> {
  columnVirtualizer?: RTColumnVirtualizer
  headerGroup: RTHeaderGroup<TData>
  table: ReusableTableInstance<TData>
}

export const RTTableHeadRow = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  headerGroup,
  table,
  ...rest
}: IRTTableHeadRowProps<TData>) => {
  const {
    options: { enableStickyHeader, layoutMode },
  } = table

  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } =
    columnVirtualizer ?? {}

  return (
    <tr
      {...rest}
      style={{
        borderBottom: '1px solid #e5e5e5',
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        position:
          enableStickyHeader && layoutMode === 'semantic'
            ? 'sticky'
            : 'relative',
        top: 0,
      }}
      className='RTTableHeadRow__container'
    >
      {virtualPaddingLeft ? (
        <th style={{ display: 'flex', width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? headerGroup.headers).map(
        (headerOrVirtualHeader, staticColumnIndex) => {
          let header = headerOrVirtualHeader as RTHeader<TData>
          if (columnVirtualizer) {
            staticColumnIndex = (headerOrVirtualHeader as RTVirtualItem).index
            header = headerGroup.headers[staticColumnIndex]
          }

          return header ? (
            <RTTableHeadCell
              columnVirtualizer={columnVirtualizer}
              header={header}
              key={header.id}
              staticColumnIndex={staticColumnIndex}
              table={table}
            />
          ) : null
        },
      )}
      {virtualPaddingRight ? (
        <th style={{ display: 'flex', width: virtualPaddingRight }} />
      ) : null}
    </tr>
  )
}
