import { createSlice } from '@reduxjs/toolkit'

export const userAccessesUiSlice = createSlice({
  name: 'userAccesses',
  initialState: {
    resetStore: false,
  },
  reducers: {
    resetStore: (state) => {
      state.resetStore = true
    },
  },
})

export default userAccessesUiSlice
