import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { apiClient } from '~/api/ApiClient'
import { ExtrasFormFieldArray } from './ExtrasFormFieldArray'

export const ExtrasForm = ({
  prefix = '',
  autoExtrasParams,
  onLoading,
  onFetch,
  onAutoExtrasLoading,
  ...props
}) => {
  const [autoExtras, setAutoExtras] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchAutoExtras = useCallback(
    async params => {
      setLoading(true)
      const { autoExtras, errors } = await apiClient.autoExtras.get(params)
      setLoading(false)
      if (_.isEmpty(autoExtras)) return
      if (!_.isEmpty(errors)) toast.error(errors.join('\n'))

      setAutoExtras(autoExtras)
      onFetch && onFetch(autoExtras)
    },
    [onFetch],
  )

  useEffect(() => {
    if (autoExtrasParams) fetchAutoExtras(autoExtrasParams)
  }, [autoExtrasParams, fetchAutoExtras])

  useEffect(() => {
    if (onAutoExtrasLoading) {
      loading ? onAutoExtrasLoading(true) : onAutoExtrasLoading(false)
    }
  }, [loading, onAutoExtrasLoading])

  useEffect(() => onLoading && onLoading(loading), [onLoading, loading])

  return (
    <ExtrasFormFieldArray
      prefix={prefix}
      autoExtras={autoExtras}
      loading={loading}
      {...props}
    />
  )
}
