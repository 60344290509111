import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { selectDriverDetails } from '~/redux/selectors'

import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ICheckNetPaySplit } from '~/types/models/ICheck'

const useModifyCheckNetPaySplits = () => {
  const driverDetails: IDriverFleet | null = useSelector(selectDriverDetails)

  const workerUid = driverDetails?.worker.checkUid

  const queryClient = useQueryClient()

  const key = useMemo(() => ['checkNetPaySplits', workerUid], [workerUid])

  const addCheckNetPaySplits = useCallback(
    (payload: ICheckNetPaySplit) => {
      queryClient.setQueryData<ICheckNetPaySplit[] | undefined>(
        key,
        oldData => {
          if (oldData) {
            return [payload, ...oldData]
          }
          return oldData
        },
      )
    },
    [key, queryClient],
  )

  return { addCheckNetPaySplits }
}

export default useModifyCheckNetPaySplits
