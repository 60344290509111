import { useMemo } from 'react'

import { CompanyInfo } from '~/components/shared'

const useTableCompanyEmailParserSubTab = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Seller',
        accessorKey: 'seller.name',
        size: 250,
        Cell({ row }) {
          return (
            <CompanyInfo
              companyType='seller'
              company={{
                value: row?.original?.seller?.id,
                name: row?.original?.seller?.name,
                code: row?.original?.seller?.code,
                image: row?.original?.seller?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Buyer',
        accessorKey: 'buyer.name',
        size: 250,
        Cell({ row }) {
          return (
            <CompanyInfo
              companyType='buyer'
              company={{
                value: row?.original?.buyer?.id,
                name: row?.original?.buyer?.name,
                code: row?.original?.buyer?.code,
                image: row?.original?.buyer?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Seller Regex',
        accessorKey: 'sellerRegex',
        size: 200,
        enableEditing: true,
      },
      {
        header: 'Buyer Regex',
        accessorKey: 'buyerRegex',
        size: 200,
        enableEditing: true,
      },
      {
        header: 'Doc Type Regex',
        accessorKey: 'docTypeRegex',
        size: 200,
        enableEditing: true,
      },
      {
        header: 'Doc Type Value',
        accessorKey: 'docTypeValue',
        size: 200,
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyEmailParserSubTab
