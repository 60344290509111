import { createContext } from 'react'
import { IPricingContextProps } from './type'

const PricingFormContext = createContext<IPricingContextProps>({
  isOpenPricingForm: false,
  onOpenPricingForm() {},
  onClosePricingForm() {},
})

export default PricingFormContext
