import { useMemo } from 'react'
import type {
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../types'
import { getRTRows } from '../utils/row.utils'

export const useRTRows = <TData extends IReusableTableRowData>(
  table: ReusableTableInstance<TData>,
): RTRow<TData>[] => {
  const {
    getRowModel,
    getState,
    options: { data, enableGlobalFilterRankedResults, positionCreatingRow },
  } = table
  const {
    creatingRow,
    expanded,
    globalFilter,
    pagination,
    rowPinning,
    sorting,
  } = getState()

  const rows = useMemo(
    () => getRTRows(table),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      creatingRow,
      data,
      enableGlobalFilterRankedResults,
      expanded,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getRowModel().rows,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      positionCreatingRow,
      rowPinning,
      sorting,
    ],
  )

  return rows
}
