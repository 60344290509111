import { useState, useEffect, useCallback, useMemo } from 'react'

import {
  IonButtons,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonSearchbar,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { Button } from '~/components'
import { FormattedMessage } from 'react-intl'
import { caretDown } from 'ionicons/icons'
import { humanizeKey, parseInputErrors } from '~/utils/utils'
import { addCircleOutline } from 'ionicons/icons'
import PropTypes from 'prop-types'
import { CommonDialogV2, InfoIcon } from '~/components/shared'

import './styles.scss'

const SelectionField = props => {
  const {
    label,
    style,
    data = [],
    meta: { touched, error, active },
    input = {
      value: '',
    },
    isMultiple,
    isDisabled,
    isHidden,
    onTerminal,
    setSortingModal,
    changeField,
    setField,
    classNameDialog,
    showingProps,
    ...selectProps
  } = props

  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [currentId, setCurrentId] = useState(input.value)
  const _onChange = e => {
    input.onChange && input.onChange(e.target.value)
    onTerminal && e.target.value && onTerminal(e.target.value)
    setSortingModal && setSortingModal(true)
  }
  const onSaveData = value => {
    input.onChange && input.onChange(value)
    onTerminal && value && onTerminal(value)
  }

  useEffect(() => {
    if (typeof showingProps === 'boolean' && showingProps == true) {
      setShowModal(showingProps)
    }
  }, [showingProps])

  useEffect(() => {
    if (selectProps.newItemId) {
      triggerSelectOption(selectProps.newItemId)
    }
  }, [data, selectProps.newItemId, triggerSelectOption])

  useEffect(() => {
    setCurrentId(input.value)
    if (changeField) {
      input.onChange && input.onChange(input.value)
      setField && setField(false)
    }
  }, [input, setField, changeField])

  const triggerSelectOption = useCallback(
    id => {
      setCurrentId(id)
      input.onChange && input.onChange(id)
    },
    [input],
  )

  const onChange = e => {
    setSearch(e.target.value)
    const tempSelected = currentId
    setCurrentId('')
    setCurrentId(tempSelected)
  }

  const filtereddata = useMemo(
    () =>
      data.filter(item =>
        humanizeKey(item?.label || '')
          .toLowerCase()
          .includes(search.toLowerCase()),
      ),
    [data, search],
  )

  const onCloseModal = () => {
    setShowModal(false)
  }

  const onShowModal = () => {
    setShowModal(true)
  }

  return (
    !isHidden && (
      <IonItem lines='full' style={style} className='item-input'>
        <IonLabel className='input-label' style={{ marginRight: 40 }}>
          {label}
        </IonLabel>
        {isMultiple ? (
          <>
            <IonSelect
              value={input.value}
              onIonChange={_onChange}
              okText='Confirm'
              {...selectProps}
              multiple={isMultiple}
              disabled={isDisabled}
            >
              {data.map(item => (
                <IonSelectOption key={item.value} value={item.value}>
                  {item.label}
                </IonSelectOption>
              ))}
            </IonSelect>
            {setSortingModal && (
              <IonButtons slot='end'>
                <IonButton
                  color='primary'
                  onClick={() => setSortingModal(true)}
                >
                  Sort
                </IonButton>
              </IonButtons>
            )}
          </>
        ) : (
          <IonButtons>
            <Button
              icon={caretDown}
              color='dark'
              disabled={isDisabled}
              label={data.filter(e => e.value == input.value)[0]?.label}
              onClick={onShowModal}
            />
          </IonButtons>
        )}
        {touched && error && !active && (
          <IonItem lines='none'>
            <InfoIcon
              style={{ position: 'relative', top: -2, marginRight: 2 }}
            />
            <IonLabel className='validation-error' color='danger'>
              <FormattedMessage
                id={parseInputErrors(error)}
                defaultMessage={parseInputErrors(error)}
              />
            </IonLabel>
          </IonItem>
        )}
        <CommonDialogV2
          isOpen={showModal}
          onClose={onCloseModal}
          okText='Submit'
          onOk={() => {
            onSaveData(currentId)
            setShowModal(false)
          }}
          className='SelectionField__containerModal'
        >
          <IonSearchbar value={search} onIonChange={onChange} />
          <IonList>
            <IonRadioGroup
              value={currentId}
              onIonChange={e => setCurrentId(e.detail.value)}
            >
              {filtereddata.map((item, index) => (
                <IonItem key={index}>
                  <IonRadio slot='start' color='primary' value={item.value} />
                  <IonLabel>{item.label}</IonLabel>
                </IonItem>
              ))}
            </IonRadioGroup>
          </IonList>
          {selectProps.modalName && (
            <div
              className='pt-3 pb-3 btn-add-item'
              style={styles.wrapAddNewButton}
              onClick={() => {
                setShowModal(false)
                setTimeout(() => {
                  selectProps.toggleModal(selectProps.modalName)
                }, 300)
              }}
            >
              <IonIcon
                icon={addCircleOutline}
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 16,
                  marginRight: 30,
                }}
                slot='start'
              />
              <span>
                Add new{' '}
                {selectProps.modalName === 'sellerProduct'
                  ? 'Product'
                  : 'Terminal'}
              </span>
            </div>
          )}
        </CommonDialogV2>
      </IonItem>
    )
  )
}

const styles = {
  wrapAddNewButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '2px',
    fontSize: '16px',
    backgroundColor: '#e9edf1',
  },
}

SelectionField.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
}

SelectionField.defaultProps = {
  meta: {},
  input: {},
}

export default SelectionField
