export enum EEarningCodeType {
  hourly = 'hourly',
  salaried = 'salaried',
  overtime = 'overtime',
  doubleOvertime = 'double_overtime',
  paidHoliday = 'paid_holiday',
  pto = 'pto',
  sick = 'sick',
  piece = 'piece',
  restAndRecovery = 'rest_and_recovery',
  nonProductive = 'non_productive',
}
