import useNoteButton from './useNoteButton'

import Tooltip from 'rc-tooltip'
import { When } from 'react-if'
import { IonButton, IonIcon } from '@ionic/react'
import { createOutline } from 'ionicons/icons'

import type { INoteButtonProps } from './type'

import './styles.scss'

function NoteButton(props: INoteButtonProps) {
  const { Overlay, notesData, tooltipProps } = useNoteButton(props)

  return (
    <Tooltip
      placement='top'
      overlay={Overlay}
      overlayClassName='NoteButton__tooltip'
      destroyTooltipOnHide
      {...tooltipProps}
    >
      <div className='NoteButton__container'>
        <IonButton size='small' fill='clear' className='m-0 fs-5'>
          <IonIcon slot='icon-only' icon={createOutline} />
        </IonButton>
        <When condition={notesData.length > 0}>
          <div className='badgeButton'>{notesData.length}</div>
        </When>
      </div>
    </Tooltip>
  )
}
export default NoteButton
