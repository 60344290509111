import OffDayForm from './OffDayForm'
import { CommonDialogV2 } from '~/components/shared'

import type { IDialogOffDayFormDataProps } from './type'

import { IOffday } from '~/types/models/IOffday'

import './styles.scss'

function DialogOffDayForm(props: IDialogOffDayFormDataProps) {
  const {
    isOpen,
    onClose,
    defaultValues,
    isHiddenSubmitButton,
    offdaysData,
    afterCreate,
    afterUpdate,
  } = props

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      title='Request Days Off'
      isHiddenOkButton
      className='DialogOffDayForm__container'
    >
      <OffDayForm
        defaultValues={defaultValues}
        afterCreate={(formValues: IOffday) => {
          onClose && onClose()
          afterCreate && afterCreate(formValues)
        }}
        afterUpdate={(formValues: IOffday) => {
          onClose && onClose()
          afterUpdate && afterUpdate(formValues)
        }}
        isHiddenSubmitButton={isHiddenSubmitButton}
        offdaysData={offdaysData}
      />
    </CommonDialogV2>
  )
}
export default DialogOffDayForm
