import { useMemo } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { ConcordFormDropdownV2 } from '~/components/shared/ConcordForm'
import { ICommonOption } from '~/types/models/ICommonModel'

export interface IRTEditCellSingleSelectFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  isMulti?: boolean
}

const RTEditCellSingleSelectField = <TData extends IReusableTableRowData>(
  props: IRTEditCellSingleSelectFieldProps<TData>,
) => {
  const { cell, table, isMulti } = props

  const {
    getState,
    options: { onCellEditEnd },
    // refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()
  const { editSelectOptions } = column.columnDef

  const selectOptions = useMemo(() => {
    if (editSelectOptions) {
      if (typeof editSelectOptions === 'function') {
        return editSelectOptions({ cell, column, row, table })
      }
      return editSelectOptions
    }
    return []
  }, [cell, column, editSelectOptions, row, table])

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const cellValue = cell.getValue()

  const dropdownFieldProps =
    typeof column.columnDef.editDropdownFieldProps === 'function'
      ? column.columnDef.editDropdownFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const onChange = (event: any, { value, selectedOption }: any) => {
    if (value !== cell.getValue()) {
      onCellEditEnd && onCellEditEnd(value, cell, selectedOption)
      saveInputValueToRowCache(value)
      setEditingCell(null)
      // setValue(value)
    }
  }

  return (
    <ConcordFormDropdownV2
      className='RTEditCellSingleSelectField__container'
      options={selectOptions as ICommonOption[]}
      value={cellValue as any}
      onChange={onChange}
      isMulti={isMulti}
      {...dropdownFieldProps}
    />
  )
}

export default RTEditCellSingleSelectField
