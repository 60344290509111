import useCheckSignatoryForm from './useCheckSignatoryForm'

import {
  DropdownWithCustomChildren,
  ConcordFormDropdownV2,
  CompanyInfo,
  ConcordFormStructure,
  CompanyAvatar,
  ToolTipOverlay,
} from '~/components/shared'
import { When } from 'react-if'
import Skeleton from 'react-loading-skeleton'

import { SIGNER_OTHER_USER_OPTION } from '~/utils/constants'

import type { ICheckSignatoryFormProps } from './type'

import './styles.scss'
import { Alert } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import { pencil } from 'ionicons/icons'
import buildFullName from '~/utils/buildFullName'
import clsx from 'clsx'

function CheckSignatoryForm(props: ICheckSignatoryFormProps) {
  const {
    DESIGNATE_SIGNER_TYPE_OPTIONS,
    signerType,
    checkSignatoryOptions,
    fields,
    schema,
    isLoading,
    formRef,
    isShowBanner,
    onSelectSignerType,
    onSubmitForm,
    onSelectOtherSigner,
    selectedSignatoryOption,
    selectedSignatory,
    isLoadingCheckSignatoriesData,
    onToggleForm,
  } = useCheckSignatoryForm(props)

  if (isLoadingCheckSignatoriesData) {
    return (
      <div className='CheckSignatoryForm__loading'>
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
        <Skeleton height={14} width='100%' className='item' />
      </div>
    )
  }

  return (
    <div className={clsx('CheckSignatoryForm__container', { isShowBanner })}>
      <Alert
        variant='secondary'
        className={clsx('CheckSignatoryForm__banner', { isShowBanner })}
      >
        <span style={{ marginRight: 8 }}>Designated Signatory: </span>
        <ToolTipOverlay
          content={selectedSignatory?.email}
          placement='top'
          className='CheckSignatoryForm__tooltip'
        >
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CompanyAvatar
              company={{
                value: selectedSignatory?.id as number,
                label: buildFullName(selectedSignatory),
                name: buildFullName(selectedSignatory),
              }}
            />
            <span className='fullName'>{buildFullName(selectedSignatory)}</span>
            &nbsp;
            <span>- {selectedSignatory?.title}</span>&nbsp;
          </div>
        </ToolTipOverlay>
        <span className='clickable changeButton' onClick={onToggleForm}>
          <IonIcon icon={pencil} />
        </span>
      </Alert>

      <div className={clsx('formBody', { isHidden: isShowBanner })}>
        <div className='headerContainer'>
          <ConcordFormDropdownV2
            className='designateSignerDropdown'
            label='Designate Signer:'
            options={DESIGNATE_SIGNER_TYPE_OPTIONS}
            value={signerType}
            onChange={onSelectSignerType}
          />

          <When condition={signerType === SIGNER_OTHER_USER_OPTION.value}>
            <DropdownWithCustomChildren
              options={checkSignatoryOptions}
              className='otherSignersDropdown'
              onChange={onSelectOtherSigner}
              value={selectedSignatoryOption}
            >
              <CompanyInfo
                company={{
                  value: selectedSignatoryOption?.value,
                  label: selectedSignatoryOption?.label as string,
                  name: selectedSignatoryOption?.label as string,
                }}
                hideSubtitle
                hideAnchor
                searchableGoogle={false}
              />
            </DropdownWithCustomChildren>
          </When>
        </div>

        <div className='bodyContainer'>
          <ConcordFormStructure
            fields={fields}
            isHiddenCancelButton
            isHiddenSearch
            schema={schema}
            defaultValues={{
              firstName: '',
              lasstName: '',
              title: '',
              email: '',
            }}
            onSubmit={onSubmitForm}
            isLoading={isLoading}
            ref={formRef}
          />
        </div>
      </div>
    </div>
  )
}
export default CheckSignatoryForm
