import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPaymentTerms from './useModifyPaymentTerms'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetPaymentTermsParams,
  IPaymentTerm,
} from '~/types/models/IPaymentTerm'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryPaymentTerms = (
  params: Partial<IGetPaymentTermsParams> = {},
  options?: Partial<UseQueryOptions<IPaymentTerm[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'paymentTerms',
      sessionUser?.id,
      buildGetUrl(apiClient.paymentTerms.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.paymentTerms.get(params)
      return response.paymentTerms
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const paymentTermsData = useMemo(() => data || [], [data])

  const { addPaymentTerm, updatePaymentTerm, removePaymentTerm } =
    useModifyPaymentTerms(params)

  return {
    paymentTermsData,
    isLoadingPaymentTerms: isLoading,
    addPaymentTerm,
    updatePaymentTerm,
    removePaymentTerm,
  }
}

export default useQueryPaymentTerms
