import useDialogCheckWorkplace from './useDialogCheckWorkplace'

import { CommonDialogV2, ConcordFormStructure } from '~/components/shared'

import { pencilOutline } from 'ionicons/icons'

import type { IDialogCreateCheckWorkplaceProps } from './type'
import { Alert } from 'react-bootstrap'
import { When } from 'react-if'

function DialogCheckWorkplace(props: IDialogCreateCheckWorkplaceProps) {
  const {
    isOpen,
    onClose,
    formFields,
    formData,
    schema,
    isLoading,
    isUpdating,
    isUpdatingAddress,
    terminal,
    backendError,
    onSubmitForm,
    onToggleEdit,
  } = useDialogCheckWorkplace(props)

  return (
    <CommonDialogV2
      isOpen={isOpen}
      loading={isLoading}
      onClose={onClose}
      isHiddenOkButton
      title='Check Workplace'
      extraButtons={[
        {
          icon: pencilOutline,
          isHidden: !terminal?.address || Boolean(terminal?.checkUid),
          fill: isUpdatingAddress ? 'outline' : 'solid',
          tooltipProps: {
            content: 'Edit address',
            placement: 'top',
          },
          onClick: onToggleEdit,
        },
      ]}
    >
      <When condition={isUpdating}>
        <Alert variant='warning'>
          <div style={{ fontSize: 13 }}>
            Cannot edit workplace address (except Line 2) that may have payroll
            tax related implications. Please create a new workplace if you have
            a new location or contact support{' '}
            <a href='mailto:hello@concordmaterials.com'>
              hello@concordmaterials.com
            </a>
          </div>
        </Alert>
      </When>

      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}

      <ConcordFormStructure
        fields={formFields}
        isHiddenCancelButton
        isHiddenSearch
        formData={formData}
        schema={schema}
        onSubmit={onSubmitForm}
        isLoading={isLoading}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </CommonDialogV2>
  )
}
export default DialogCheckWorkplace
