import Immutable from 'immutable'

import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  currentLocation: {},
  breadcrumbs: [],
  loading: undefined,
})

const bredcrumbsLoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BREADCRUMB_LOADS: {
      return state.set('loading', true)
    }
    case types.FETCH_BREADCRUMB_LOADS_SUCCESS: {
      const { breadcrumbs } = action
      const newState = state.set('breadcrumbs', breadcrumbs)

      return newState.set('loading', false)
    }
    case types.FETCH_CURRENT_LOCATION: {
      const { location } = action

      return state.set('currentLocation', location)
    }
    default: {
      return state
    }
  }
}

export default bredcrumbsLoadReducer
