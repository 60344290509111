import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'

import type { IDialogFixedPriceFormProps } from './type'
import FixedPriceForm from './FixedPriceForm'

function DialogFixedPriceForm(props: IDialogFixedPriceFormProps) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props
  return (
    <CommonDialogV2 {...dialogProps} title='Fixed Price' isHiddenOkButton>
      <FixedPriceForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogFixedPriceForm
