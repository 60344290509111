import { createSelector } from 'reselect'

const userGroups = state => state.userAccesses.get('userGroups')

export const selectUserGroups = createSelector(
  userGroups,

  userGroups => {
    if (!userGroups) {
      return
    }

    let userGroupHash = {}
    userGroups.forEach(user => (userGroupHash[user.id] = user))

    return userGroupHash
  },
)
