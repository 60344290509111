import { useState, useCallback } from 'react'
import { useRouter } from '~/hooks/useRouter'

import { Link } from 'react-router-dom'
import { IonItem, IonIcon, IonLabel, IonSpinner, IonBadge } from '@ionic/react'
import {
  chevronDownCircleOutline,
  chevronUpCircleOutline,
} from 'ionicons/icons'

import '~/styles/side-menu.scss'
import { useSelector } from 'react-redux'
import { selectUnreadChatMessage } from '~/redux/selectors'
import clsx from 'clsx'

const SideMenuLink = ({
  label,
  icon,
  path,
  alias,
  subtabs,
  onToggleMobileMenu,
  loading,
  isDisabled = false,
}) => {
  const unreadCount = useSelector(selectUnreadChatMessage)

  const [companyDrop, setCompanyDrop] = useState(false)
  const { location } = useRouter()

  const getSubtabUrl = useCallback(({ value, path: subpath }) => {
    if (value === 'home' || subpath) {
      return null
    }
    return `view=${value}`
  }, [])

  const renderSubtabs = useCallback(() => {
    if (Array.isArray(subtabs) && companyDrop) {
      return subtabs.map(subtab => {
        const { label, value, path: subpath } = subtab

        return (
          <Link
            key={value}
            to={{
              pathname: subpath || path,
              search: getSubtabUrl(subtab),
            }}
            style={{
              color: '#111111',
              display: 'flex',
              textDecoration: 'none',
            }}
          >
            <IonItem
              className={
                value == location.state?.tab
                  ? 'activeTab submenu-item'
                  : 'passiveTab submenu-item'
              }
              lines='none'
            >
              <IonLabel className='submenu-text'>{label}</IonLabel>
            </IonItem>
          </Link>
        )
      })
    }
  }, [companyDrop, getSubtabUrl, location.state?.tab, path, subtabs])

  return (
    <>
      <Link
        key={label}
        to={{
          pathname: alias || path,
          state: { hideBackButton: true },
        }}
        onClick={onToggleMobileMenu}
        style={{
          color: '#111111',
          display: 'flex',
          textDecoration: 'none',
        }}
        className={clsx({ isDisabled })}
      >
        <IonItem
          className={clsx('menu-item', {
            menuDropdown: path === location.pathname,
            hoverDropdown: path !== location.pathname,
          })}
          lines='none'
          disabled={loading}
        >
          {loading && <IonSpinner name='lines-small' />}

          {!loading && (
            <IonIcon
              style={{ marginBottom: '3px' }}
              color={path == location.pathname ? 'concord' : 'cloudgrey'}
              icon={icon}
            />
          )}
          <IonLabel
            style={{ marginLeft: '12px' }}
            className={
              path == location.pathname
                ? 'labelDropdown-select'
                : 'labelDropdown'
            }
          >
            {label}
          </IonLabel>

          {subtabs && (
            <span
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setCompanyDrop(!companyDrop)
              }}
            >
              <IonIcon
                style={{
                  fontSize: 24,
                  marginTop: 6,
                  color: 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)',
                }}
                icon={
                  companyDrop
                    ? chevronUpCircleOutline
                    : chevronDownCircleOutline
                }
              />
            </span>
          )}

          {label == 'Chats' && unreadCount > 0 && (
            <IonBadge color='danger'>{unreadCount}</IonBadge>
          )}
        </IonItem>
      </Link>

      {renderSubtabs()}
    </>
  )
}
export default SideMenuLink
