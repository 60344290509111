import { ICommonModel, ITimestamp } from './ICommonModel'

export enum EApplicationType {
  order = 'order',
  load = 'load',
  schedule = 'schedule',
}

export enum EAutoExtraDateRangeType {
  exact = 'exact',
  seasonal = 'seasonal',
}

export interface IAutoExtra extends ICommonModel {
  active?: boolean
  applicationRange: string
  applicationType: EApplicationType
  companyId: number
  daysOfTheWeek?: number[]
  endTime: ITimestamp
  qty: number
  sellerProductId: number
  startTime: ITimestamp
  dateRange: string
  dateRangeType: EAutoExtraDateRangeType
}

export interface IGetAutoExtrasResponse {
  autoExtras: IAutoExtra[]
}

export interface IGetAutoExtrasParams {
  filters?: {
    active?: boolean
    applicationType?: EApplicationType | EApplicationType[]
    createdAt?: Date
    dateRangeType?: EAutoExtraDateRangeType
    dayOfTheWeek?: number[]
    deletedAt?: Date
    endTime?: ITimestamp
    qty?: number
    startTime?: ITimestamp
    updatedAt?: Date
    searchWord?: string
    for_date_time?: ITimestamp
  }
}

export interface IAutoExtraFilter {
  active: boolean
  applicationType: EApplicationType
  createdAt: Date
  // overlapping: any
  dateRangeType: EAutoExtraDateRangeType
  daysOfTheWeek: number[]
  deletedAt: Date
  endTime: ITimestamp
  qty: number
  sellerProductId: number
  startTime: ITimestamp
  updatedAt: Date
}

export type ICreateAutoExtraPayload = Omit<IAutoExtra, keyof ICommonModel>

export interface ICreateAutoExtraResponse extends IAutoExtra {
  errors: string[]
}

export interface IUpdateAutoExtraPayload {
  autoExtra: Partial<IAutoExtra>
}

export interface IUpdateAutoExtraResponse extends IAutoExtra {
  errors: string[]
}
