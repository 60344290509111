import { apiClient } from '~/api/ApiClient'
import { fieldValidationsSlice } from '../reducers/data/fieldValidations'

export const updateFieldValidations = fv => dispatch => {
  dispatch(fieldValidationsSlice.actions.updateFieldValidations(fv))
}

export const fetchFieldValidations = () => async dispatch => {
  const res = await apiClient.fieldValidations.get()
  dispatch(updateFieldValidations(res.fieldValidations))
}
