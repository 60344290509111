import { colord } from 'colord'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { createSelector } from 'reselect'
import { moduleName } from '~/utils/constants'

export const selectCommonTags = createSelector(
  state => state.data,

  data => columnHashToColumnsArray(data.tags),
)

export const selectCommonTagsLoading = createSelector(
  state => state.ui,

  ui => ui.companySubTabs[moduleName.company.tags].tagsLoading,
)

export const selectCommonTagsOptions = createSelector(
  state => columnHashToColumnsArray(state.data.tags),

  tags => {
    try {
      return tags.map(t => {
        const isDark = colord(t.color || 'rgb(192, 192, 192)').isDark()

        return {
          value: t.id,
          label: t.name,
          style: {
            backgroundColor: t.color,
            color: isDark ? 'white' : '#454545',
            '&:hover': {
              backgroundColor: colord(t.color || 'rgb(192, 192, 192)')
                .lighten(0.1)
                .toHex(),
            },
          },
        }
      })
    } catch {
      return []
    }
  },
)
