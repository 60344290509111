import _ from 'lodash'

/**
 * @param {object[]} data
 * @param {string} searchKey
 * @param {string[]} fields
 *
 * ```js
 * > fields ["id", "code", "name", "company.name"]
 * ```
 */
const getSearchedData = (data, searchKey, fields) => {
  if (!searchKey || _.size(fields) === 0) {
    return data
  }
  const filterFields = {}

  fields.forEach(field => {
    filterFields[field] = _.toLower(searchKey)
  })

  return data.filter(item => {
    const result = _.some(filterFields, (value, key) => {
      const getItemValue = _.get(item, key)

      return _.toLower(getItemValue).includes(value)
    })

    return result
  })
}

export default getSearchedData
