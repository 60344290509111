import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyProductTaxOverrides from './useModifyProductTaxOverrides'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import {
  IGetProductTaxOverridesParams,
  IProductTaxOverride,
} from '~/types/models/IProductTaxOverride'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryProductTaxOverrides = (
  params: Partial<IGetProductTaxOverridesParams> = {},
  options?: Partial<UseQueryOptions<IProductTaxOverride[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'productTaxOverrides',
      sessionUser?.id,
      buildGetUrl(apiClient.productTaxOverrides.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.productTaxOverrides.get(params)
      return response.productTaxOverrides
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const productTaxOverridesData = useMemo(() => data || [], [data])

  const {
    addProductTaxOverride,
    updateProductTaxOverride,
    removeProductTaxOverride,
  } = useModifyProductTaxOverrides(params)

  return {
    productTaxOverridesData,
    isLoadingProductTaxOverrides: isLoading,
    addProductTaxOverride,
    updateProductTaxOverride,
    removeProductTaxOverride,
  }
}

export default useQueryProductTaxOverrides
