import { CompanyAvatar } from '~/components/shared'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './FormatOptionSellerLabel.scss'

const FormatOptionSellerLabel = props => {
  const { label, image, imageUrl, className, name, hideAvatar, value } = props

  const isNotSet = _.toLower(label) === 'not set'

  const renderAvatar = () => {
    if (isNotSet || _.size(label) === 0 || hideAvatar) {
      return null
    }

    return (
      <CompanyAvatar
        companyType='seller'
        company={{
          label,
          name,
          value,
          image: image || imageUrl,
        }}
      />
    )
  }

  return (
    <div
      className={clsx('FormatOptionSellerLabel__container', className, {
        notSet: label === '',
      })}
    >
      {renderAvatar()}
      <div className='FormatOptionSellerLabel__label'>{label}</div>
    </div>
  )
}

FormatOptionSellerLabel.propTypes = {
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
}

export default FormatOptionSellerLabel
