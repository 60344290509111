import { useEffect, useRef } from 'react'
import { components } from 'react-select'
import { DeleteIcon, EditIcon } from '~/components/shared/SvgIcons'

function CustomOption(props) {
  const { children, data, isSelected, label, ...restProps } = props
  const { showUpdateButtons, showDeleteButtons, onClickEdit, onClickDelete } =
    restProps.selectProps

  const ref = useRef()

  useEffect(() => {
    props.isSelected && ref.current.scrollIntoView()
  }, [props.isSelected])

  return (
    <components.Option
      {...restProps}
      data={data}
      isSelected={isSelected}
      className='d-flex justify-content-between align-items'
      innerRef={ref}
    >
      {label || data?.label}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showUpdateButtons && (
          <span
            style={{ marginLeft: 6, cursor: 'pointer' }}
            onClick={event => {
              event.stopPropagation()
              onClickEdit && onClickEdit(data)
            }}
          >
            <EditIcon
              size={14}
              color={
                isSelected ? 'var(--bs-white)' : 'var(--ion-color-concord)'
              }
            />
          </span>
        )}

        {showDeleteButtons && (
          <span
            style={{ marginLeft: 2, cursor: 'pointer' }}
            onClick={event => {
              event.stopPropagation()
              onClickDelete && onClickDelete(data)
            }}
          >
            <DeleteIcon size={16} color='var(--bs-danger)' />
          </span>
        )}
      </div>
    </components.Option>
  )
}

export default CustomOption
