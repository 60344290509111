// App common constants

export const SUPPORTED_LANGUAGES = ['en', 'es']
export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_SELLER_IMAGE =
  'https://workloadnode.s3.amazonaws.com/Logos/favicon.ico'

export const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: false,
  streetViewControl: true,
  scaleControl: false,
  mapTypeControl: true,
  panControl: false,
  zoomControl: true,
  rotateControl: false,
}

export const CURRENT_SCOPE = {
  BUYER: 'buyer',
  SELLER: 'seller',
  DRIVER: 'driver',
  fleet: 'fleet',
}
