import { produce } from 'immer'

export const mergeColumnChangesIntoColumnDefaults = (
  updatedColumns,
  columnDefaults,
) => {
  const arrayDefaults = columnArrayToColumnHash(columnDefaults)

  return produce(arrayDefaults, draft => {
    updatedColumns.map(
      ({ displayIndex, show, sortIndex, sorted, isAsc, sortField, field }) => {
        const columnBody = {
          displayIndex,
          show,
          sortIndex,
          sorted,
          isAsc,
          sortField,
          field,
        }

        draft[field] = columnBody
      },
    )
  })
}

export const columnHashToColumnsArray = columnHash => {
  const columnsArray = []

  for (const column in columnHash) {
    columnsArray.push(columnHash[column])
  }

  return columnsArray
}

export const columnArrayToColumnHash = columnArray => {
  const columnHash = {}

  columnArray.forEach(col => {
    columnHash[col.field] = col
  })

  return columnHash
}
