/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { usePayrollReportFormValuesProvider } from '~/contexts'

import {
  Button,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import PaydaysTable from './PaydaysTable'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { ECheckCompanyProcessingPeriod } from '~/types/enums/ECheck'
import { CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS } from '~/utils/constants'
import { ApiClientRequestOption, apiClient } from '~/api/ApiClient'

function PaydaysForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const isMobile = useIsMobile()

  const { reportFormValues, onChangeReportFormValues } =
    usePayrollReportFormValuesProvider()

  const formData = useMemo(
    () => ({
      start: reportFormValues.date,
      processingPeriod: ECheckCompanyProcessingPeriod.oneDay,
    }),
    [reportFormValues.date],
  )

  const [summaryData, setSummaryData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowTable, setIsShowTable] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'start',
        label: 'Date',
        type: EFieldType.date,
        size: isMobile ? 12 : 3,
        isRequired: true,
      },
      {
        name: 'processingPeriod',
        label: 'Processing Period',
        size: isMobile ? 12 : 3,
        type: EFieldType.singleSelect,
        options: CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
      },
    ],
    [isMobile],
  )

  const onSubmit = useCallback(() => {
    formRef.current.handleSubmit(async (formData: any) => {
      setIsLoading(true)
      try {
        const params: Partial<ApiClientRequestOption> = {
          query: {},
        }
        params.query.qsParams = {
          start: moment(formData.start).format('YYYY-MM-DD'),
          processingPeriod: formData.processingPeriod,
        }
        onChangeReportFormValues({
          date: formData.start,
        })
        const response = await apiClient.check.paydays(params)
        setSummaryData(response.results)
        setIsShowTable(true)
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [onChangeReportFormValues])

  return (
    <div>
      <ConcordFormStructure
        fields={fields}
        isHiddenCancelButton
        isHiddenSubmitButton
        ref={formRef}
        formData={formData}
      />
      <Button label='Submit' onClick={onSubmit} loading={isLoading} />
      <When condition={isShowTable}>
        <PaydaysTable data={summaryData} />
      </When>
    </div>
  )
}
export default PaydaysForm
