import { useMemo } from 'react'

import PropTypes from 'prop-types'

import TextField from './TextField'
import DropdownField from './DropdownField'
import DateField from './DateField'
import CustomField from './CustomField'
import RegexField from './RegexField'

const FormField = props => {
  const { type, ...fieldProps } = props

  const fieldRendered = useMemo(() => {
    switch (type) {
      case 'text': {
        return <TextField {...fieldProps} />
      }

      case 'number': {
        return <TextField type='number' {...fieldProps} />
      }

      case 'dropdown': {
        return <DropdownField {...fieldProps} />
      }

      case 'date': {
        return <DateField {...fieldProps} />
      }

      case 'date-time': {
        return (
          <DateField
            {...fieldProps}
            dateFormat='MMM-dd yyyy - HH:mm'
            showTimeInput
          />
        )
      }

      case 'custom': {
        return <CustomField {...fieldProps} />
      }

      case 'regex': {
        return <RegexField {...fieldProps} />
      }

      // case 'time': {
      //   return <FormTimeField {...fieldProps} />
      // }

      default: {
        return <TextField {...fieldProps} />
      }
    }
  }, [fieldProps, type])

  return fieldRendered
}

FormField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'dropdown',
    'date',
    'time',
    'date-time',
    'custom',
    'regex',
  ]),
  onConfirmUpdate: PropTypes.func,
  value: PropTypes.any,
}

FormField.defaultProps = {
  type: 'text',
}

export default FormField
