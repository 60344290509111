import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetPaymentPayablesParams,
  IPaymentPayable,
} from '~/types/models/IPaymentPayable'
import useModifyPaymentPayable from './useModifyPaymentPayable'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryPaymentPayables = (
  params: IGetPaymentPayablesParams,
  options?: Partial<UseQueryOptions<IPaymentPayable[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addPaymentPayable, removePaymentPayable } =
    useModifyPaymentPayable(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'paymentPayables',
      sessionUser?.id,
      buildGetUrl(apiClient.paymentPayables.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.paymentPayables.get(params)
      return response.paymentPayables
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const paymentPayablesData = useMemo(() => data || [], [data])

  return {
    paymentPayablesData,
    isLoadingPaymentPayableData: isLoading,
    refetchQueryPaymentPayables: refetch,
    addPaymentPayable,
    removePaymentPayable,
  }
}

export default useQueryPaymentPayables
