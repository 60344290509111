var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TypesafeUnreachableError } from 'common/util/error';
import { ActiveFieldReducerUtils, } from 'documentLabeler/state/ActiveFieldReducerUtils';
import { BlockReducerUtils, } from 'documentLabeler/state/BlockReducerUtils';
import { FieldReducerUtils, } from 'documentLabeler/state/FieldReducerUtils';
import { RegionReducerUtils, } from 'documentLabeler/state/RegionReducerUtils';
/**
 * Reducer for handling new state generation in response
 * to a new action with a current state.
 */
export var documentLabelerReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'setActiveField':
            return ActiveFieldReducerUtils.setActiveField(state, action);
        case 'addBlocksToActiveField':
            return BlockReducerUtils.addBlockToActiveField(state, action);
        case 'removeBlockFromField':
            return BlockReducerUtils.removeBlockFromField(state, action);
        case 'addRegionToActiveField':
            return RegionReducerUtils.addRegionToActiveField(state, action);
        case 'clearRegionFromField':
            return RegionReducerUtils.clearRegionFromField(state, action);
        case 'removeAllBlocksFromField':
            return FieldReducerUtils.removeAllBlocksFromField(state, action);
        case 'setFieldTextOverride':
            return FieldReducerUtils.setTextFieldOverride(state, action);
        case 'setTableCellTextOverride':
            return FieldReducerUtils.setTableCellTextOverride(state, action);
        case 'addRowToTable':
            return FieldReducerUtils.addRowToTable(state, action);
        case 'removeRowFromTable':
            return FieldReducerUtils.removeRowFromTable(state, action);
        case 'setShowHidePdf':
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { showPdf: action.payload }) });
        case 'setPdfScale': {
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { pdfScale: action.payload }) });
        }
        case 'increasePdfScale': {
            var _b = state.localState, pdfScale = _b.pdfScale, zoomMaxScale = _b.zoomMaxScale, renderedImgHeight = _b.renderedImgHeight;
            var newPdfScale = pdfScale + 0.1;
            var newImageHeight = renderedImgHeight + 25;
            if (newPdfScale >= zoomMaxScale) {
                return state;
            }
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { renderedImgHeight: newImageHeight, pdfScale: newPdfScale }) });
        }
        case 'decreasePdfScale': {
            var _c = state.localState, pdfScale = _c.pdfScale, zoomMinScale = _c.zoomMinScale, renderedImgHeight = _c.renderedImgHeight;
            var newPdfScale = pdfScale - 0.1;
            var newImageHeight = renderedImgHeight - 25;
            if (newPdfScale <= zoomMinScale) {
                return state;
            }
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { pdfScale: newPdfScale, renderedImgHeight: newImageHeight }) });
        }
        case 'setImageHeight': {
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { renderedImgHeight: action.payload }) });
        }
        case 'setViewingField': {
            var fieldViewing = state.localState.fieldViewing;
            var newViewingField = undefined;
            // current value is undefined
            if ((!fieldViewing || fieldViewing.id !== ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.id)) &&
                action.payload !== undefined) {
                newViewingField = action.payload;
            }
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { activeField: undefined, fieldViewing: newViewingField }) });
        }
        case 'setIsModifiledField': {
            return __assign(__assign({}, state), { localState: __assign(__assign({}, state.localState), { isModifiedField: action.payload }) });
        }
        case 'setDocInfo': {
            return __assign(__assign({}, state), { docInfo: action.payload });
        }
        case 'setState': {
            return action.payload;
        }
        default:
            throw new TypesafeUnreachableError(action);
    }
};
