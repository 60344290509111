import { produce, original } from 'immer'
import { toast } from 'react-toastify'

import { DEFAULT_ORDERS_FILTERS } from '~/constants/orders/constants'
import * as types from '../actions/actionTypes'

export const initialState = {
  orders: [],
  loadedOrders: false,
  date: {
    startDate: undefined,
    endDate: undefined,
  },
  filters: DEFAULT_ORDERS_FILTERS,
  endOfTheList: false,
  loading: undefined,
  loadingMore: undefined,
  toggleShowOrders: false,
}

const ordersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ORDER_CREATE_LOAD_START:
      case types.ORDER_UPDATE_START: {
        draft.loading = true
        break
      }

      case types.ORDER_CREATE_LOAD_FAILURE:
      case types.ORDER_UPDATE_FAILURE: {
        draft.loading = false
        break
      }

      case types.FETCH_ORDERS: {
        draft.loadingMore = true
        draft.endOfTheList = false
        draft.loading = true
        break
      }

      case types.FETCH_ORDERS_SUCCESS: {
        const { orders } = action
        draft.loading = false
        draft.loadingMore = false
        draft.loadedOrders = true
        draft.orders = orders
        break
      }

      case types.TOGGLE_SHOW_ORDER: {
        draft.toggleShowOrders = !draft.toggleShowOrders
        break
      }

      case types.UPDATE_ORDERS_FILTERS: {
        const { filters } = action
        draft.filters[filters.key] = filters.value
        draft.loadedOrders = false
        break
      }

      case types.RESET_ORDERS_FILTERS: {
        const { date } = action
        const { startDate, endDate } = date

        draft.filters = initialState.filters
        draft.loadedOrders = false
        draft.dateRange.startDate = startDate
        draft.dateRange.endDate = endDate
        break
      }

      case types.UPDATE_ORDERS_DATE: {
        const { date } = action
        const { startDate, endDate } = date

        draft.dateRange.startDate = startDate
        draft.dateRange.endDate = endDate
        draft.loadedOrders = false
        break
      }

      case types.CREATE_ORDERS_DATE: {
        const { date } = action
        const { startDate, endDate } = date

        draft.dateRange.startDate = startDate
        draft.dateRange.endDate = endDate
        break
      }

      case types.ORDER_SET_ORDERS_DATA: {
        const { payload } = action

        draft.orders = payload
        break
      }

      case types.ORDER_UPDATE_LOAD_STATUS: {
        const { loadId, orderId, status, updatedAt } = action.payload

        const orderIndex = draft.orders.findIndex(({ id }) => id === orderId)
        const order = original(draft.orders[orderIndex])

        const loadIndex = order.loads.findIndex(({ id }) => id === loadId)

        draft.orders[orderIndex].loads[loadIndex].status = status
        draft.orders[orderIndex].loads[loadIndex].updatedAt = updatedAt

        break
      }

      case types.ORDER_UPDATE_TRACKING_DATA: {
        const { payload } = action
        const index = draft.orders.findIndex(order => order.id === payload.id)
        if (index === -1) {
          draft.orders.push({
            ...payload,
            loads: [],
          })
          toast.info(
            `A new order #${payload.id} has been added successfully!`,
            { position: toast.POSITION.TOP_RIGHT },
          )
        } else {
          const originOrder = original(draft.orders[index])
          draft.orders[index] = { ...originOrder, ...payload }
          toast.success(`Order #${payload.id} has been updated successfully!`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }

        break
      }

      case types.ORDER_UPDATE_SUCCESS: {
        const { order } = action

        const index = draft.orders.findIndex(o => o.id === order.id)
        if (index != -1) draft.orders[index] = order
        break
      }

      case types.ORDER_CREATE_LOAD_SUCCESS: {
        const { orderId, load } = action
        const index = draft.orders.findIndex(({ id }) => id === orderId)
        if (index != -1) draft.orders[index].loads.push(load)
        break
      }

      default: {
        return draft
      }
    }
  })

export default ordersReducer
