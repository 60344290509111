import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { isStartWithEmp } from '~/utils/constants'

import type { IUser } from '~/types/models/IUser'
import type { IGetCheckSetupCompanyResponse } from '~/types/models/ICheck'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCheckContractorDetails = (
  checkUid: string,
  options?: Partial<UseQueryOptions<IGetCheckSetupCompanyResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['checkContractor', sessionUser?.id, checkUid],
    queryFn() {
      return apiClient.check.getContractorByUid(checkUid)
    },
    enabled: Boolean(sessionUser?.id && checkUid && !isStartWithEmp(checkUid)),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  return {
    checkContractorDetails: data,
    refetchCheckContractorDetails: refetch,
    isLoadingCheckContractor: isLoading,
  }
}

export default useQueryCheckContractorDetails
