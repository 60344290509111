import { useEffect, useState } from 'react'

import type { ISalesProposal } from '~/types/models/ISalesProposal'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { CheckMarkIcon, CloseIcon } from '~/components/shared/SvgIcons'
import { produce } from 'immer'
import _ from 'lodash'
import { ICommonOption } from '~/types/models/ICommonModel'
import { apiClient } from '~/api/ApiClient'
import { Alert, Button } from 'react-bootstrap'
import { toastMessages } from '~/constants/toast-status-text'
import { IPriceEscalation } from '~/types/models/IPriceEscalation'
import {
  EPriceEscalationCadence,
  EPriceEscalationType,
} from '~/types/enums/EPriceEscalation'

export interface IPriceEscalationCardProps {
  salesProposal?: ISalesProposal
  priceEscalation?: IPriceEscalation
  afterUpdate?: (data: IPriceEscalation) => void
  afterCreate?: (data: IPriceEscalation) => void
}

function PriceEscalationCard(props: IPriceEscalationCardProps) {
  const { salesProposal, afterUpdate, priceEscalation, afterCreate } = props

  const [formData, setFormData] = useState<Partial<IPriceEscalation>>({})
  const [editingFields, setEditingFields] = useState<string[]>([])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fields = [
    {
      label: 'Amount',
      value: formData.amount,
      type: EFieldType.number,
      name: 'amount',
    },
    {
      label: 'Type',
      value: formData.escalationType,
      type: EFieldType.singleSelect,
      name: 'escalationType',
      options: [
        {
          label: '$',
          value: EPriceEscalationType.dollar,
        },
        { label: '%', value: EPriceEscalationType.percent },
      ],
    },
    {
      label: 'Date',
      value: formData.date,
      type: EFieldType.date,
      name: 'date',
    },
    {
      label: 'Candence',
      value: formData.escalationCadence,
      type: EFieldType.singleSelect,
      name: 'escalationCadence',
      options: Object.keys(EPriceEscalationCadence).map(field => ({
        label: _.startCase(field),
        value: field,
      })),
    },
  ]

  const onToggleField = (name: string) => () => {
    if (priceEscalation) {
      setEditingFields(prev =>
        produce(prev, draft => {
          const index = draft.indexOf(name)
          if (index !== -1) {
            draft.splice(index, 1)
          } else {
            draft.push(name)
          }
        }),
      )
    }
  }

  const onSubmitChange = (name: string) => async () => {
    onToggleField(name)()

    if (priceEscalation) {
      const response = await apiClient.priceEscalations.update(
        priceEscalation.id,
        {
          [name]: (formData as any)[name],
        },
      )
      afterUpdate && afterUpdate(response)
    }
  }

  const onCreate = async () => {
    if (formData.amount) {
      setIsLoading(true)
      try {
        if (salesProposal) {
          const response = await apiClient.priceEscalations.create({
            escalationCadence: formData.escalationCadence,
            escalationType:
              formData.escalationType || EPriceEscalationType.dollar,
            amount: formData.amount,
            date: formData.date as string,
            salesProposalId: salesProposal.id,
            senderCompanyId: salesProposal.senderCompanyId,
          })
          afterCreate && afterCreate(response)
        }
      } catch (error) {
        console.log('error', error)
        setError(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
      setError('')
    } else {
      setError('Amount is required')
    }
  }

  const renderEditField = (
    type: EFieldType,
    value: any,
    name: string,
    options?: ICommonOption[],
  ) => {
    switch (type) {
      case EFieldType.singleSelect:
        return (
          <select
            className='PriceEscalationCard__input'
            value={value}
            onChange={event => {
              setFormData(prev => ({
                ...prev,
                [name]: event.target.value,
              }))
            }}
          >
            <option>Select one</option>
            {options?.map(option => (
              <option
                selected={option.value === value}
                value={option.value}
                key={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        )

      default:
        return (
          <input
            type={type}
            value={value}
            className='PriceEscalationCard__input'
            onChange={event => {
              setFormData(prev => ({
                ...prev,
                [name]: event.target.value,
              }))
            }}
          />
        )
    }
  }

  useEffect(() => {
    if (priceEscalation) {
      setFormData(priceEscalation)
      setEditingFields([])
    } else {
      setEditingFields([
        'amount',
        'escalationType',
        'escalationCadence',
        'date',
      ])
    }
  }, [priceEscalation])

  return (
    <div className='PriceEscalationCard__container'>
      {error && (
        <Alert style={{ fontSize: 12, marginBottom: 4 }} variant='danger'>
          {error}
        </Alert>
      )}
      {fields.map(({ label, value, name, type, options }) => (
        <div key={name} style={{ marginBottom: 4 }}>
          <span>{label}: </span>
          {editingFields.includes(name) ? (
            <>
              {renderEditField(type, value, name, options)}

              {priceEscalation && (
                <>
                  <span
                    style={{ marginLeft: 4 }}
                    className='icon'
                    onClick={onSubmitChange(name)}
                  >
                    <CheckMarkIcon color='var(--ion-color-success)' />
                  </span>
                  <span
                    style={{ marginLeft: 4 }}
                    className='icon'
                    onClick={() => {
                      onToggleField(name)()
                      setFormData(prev => ({
                        ...prev,
                        [name]: (priceEscalation as any)[name],
                      }))
                    }}
                  >
                    <CloseIcon color='var(--ion-color-danger)' />
                  </span>
                </>
              )}
            </>
          ) : (
            <span style={{ cursor: 'pointer' }} onClick={onToggleField(name)}>
              {options
                ? options.find(opt => opt.value === value)?.label || '-'
                : value || '-'}
            </span>
          )}
        </div>
      ))}
      {!priceEscalation && (
        <Button
          style={{ fontSize: 13, width: '100%' }}
          onClick={onCreate}
          disabled={isLoading}
        >
          Submit
        </Button>
      )}
    </div>
  )
}

export default PriceEscalationCard
