import * as types from './actionTypes'
import loadsSlice from '~/redux/reducers/data/loadsSlice'
import { apiClient } from '~/api/ApiClient'

export const updateLineItemTagLinks =
  (tagLinkData, taggableType) => async dispatch => {
    switch (taggableType) {
      case 'Load': {
        const newLoad = await apiClient.loads.getById(tagLinkData.id)
        dispatch(loadsSlice.actions.updateLoad(newLoad))
        break
      }
      case 'BillLine': {
        dispatch(updateBillLineTagLinks(tagLinkData))
        break
      }
      case 'Invoice': {
        dispatch(updateInvoiceTagLinks(tagLinkData))
        break
      }
      default: {
        break
      }
    }
  }

export const updateBillLineTagLinks = tagLinkData => ({
  type: types.UPDATE_BILL_LINE_TAG_LINKS,
  tagLinkData,
})

export const updateInvoiceTagLinks = tagLinkData => ({
  type: types.UPDATE_INVOICE_TAG_LINKS,
  tagLinkData,
})
