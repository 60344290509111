import _ from 'lodash'
import { createSelector } from 'reselect'
import { selectCurrentCompanies } from '~/redux/selectors'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { selectBuyerSellers } from './selectBuyerSellers'

const notSet = {
  value: null,
  label: 'Not Set',
}

const selectTerminalUiState = state => state.ui.terminals
const selectUser = state => state.session?.user
export const selectAllTerminals = state => state.data.terminals

export const selectTerminals = createSelector(selectAllTerminals, data =>
  columnHashToColumnsArray(data),
)

export const selectTerminalOptions = createSelector(
  selectTerminals,
  terminals => {
    return _.orderBy(terminals, 'label', 'desc').map(
      ({ id, code, name, companyId }) => ({
        value: id,
        label: `${code} - ${name}`,
        companyId,
        name,
      }),
    )
  },
)

export const selectTerminalsObject = createSelector(
  selectAllTerminals,
  terminals => terminals,
)

export const selectMyTerminals = createSelector(
  selectTerminals,
  selectUser,

  (terminals, myCompany) => {
    try {
      if (!terminals || !myCompany) {
        return []
      }

      return terminals
        .filter(terminal => {
          if (terminal.companyId === myCompany?.company?.id) {
            return true
          }
        })
        .sort((a, b) => a.name?.localeCompare(b.name))
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectCompanyTerminalsWithCheck = createSelector(
  selectMyTerminals,
  terminals => terminals.filter(({ checkUid }) => checkUid),
)

export const selectCompanyTerminalsWithoutCheck = createSelector(
  selectMyTerminals,
  terminals => terminals.filter(({ checkUid }) => !checkUid),
)

export const selectMyTerminalOptions = createSelector(
  selectMyTerminals,
  myTerminals =>
    myTerminals.map(({ id, name, code, checkUid, companyId }) => ({
      value: id,
      label: code,
      name,
      code,
      checkUid,
      companyId,
    })),
)

export const selectMyTerminalLocations = createSelector(
  state => state.data.locations,

  locations => columnHashToColumnsArray(locations),
)

export const selectTerminalLoading = createSelector(
  selectTerminalUiState,

  uiState => uiState.isLoading,
)

////////SELLER TERMINALS////////

export const selectSellerTerminals = createSelector(
  selectTerminals,
  selectBuyerSellers,

  (terminals, buyerSellers) => {
    if (!terminals || !buyerSellers) {
      return []
    }

    return terminals.filter(terminal => {
      for (let i = 0; i < buyerSellers.length; i++) {
        if (terminal.companyId === buyerSellers[i].sellerId) {
          return true
        }
      }
    })
  },
)

export const selectSortedSellerTerminals = createSelector(
  selectSellerTerminals,
  sellerTerminals => _.orderBy(sellerTerminals, 'name'),
)

export const selectSellerTerminalOptions = createSelector(
  selectSellerTerminals,
  selectCurrentCompanies,
  state => state.session.user,

  (sellerTerminals, companies, user) => {
    try {
      let myCompany = {}
      if (_.size(user.company) > 0) {
        myCompany = user.company
      }

      return sellerTerminals.map(({ id, code, name, companyId }) => {
        const findCompany = _.find(companies, { id: companyId })

        const label = code

        return {
          label,
          value: id,
          code,
          id,
          name,
          imageUrl: findCompany?.logo,
          companyId: companyId,
          hideAvatar: myCompany.id === companyId,
          isSellerTerminal: true,
        }
      })
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

////////BUYER TERMINALS///////

export const selectBuyerTerminals = createSelector(
  selectTerminals,
  selectBuyerSellers,
  selectUser,

  (terminals, buyerSellers, user) => {
    try {
      if (!terminals || !buyerSellers || !user.currentScope) {
        return []
      }

      if (user.currentScope == 'buyer') {
        return terminals.filter(
          terminal => terminal.companyId == user?.company?.id,
        )
      }
      if (user.currentScope == 'seller' || user.currentScope == 'fleet') {
        return terminals.filter(terminal => {
          return buyerSellers.map(bs => terminal.companyId == bs.buyerId)
        })
      }

      return []
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectBuyerFleetTerminals = createSelector(
  selectTerminals,
  selectBuyerSellers,
  selectUser,

  (terminals, buyerSellers, user) => {
    try {
      if (!terminals || !buyerSellers || !user.currentScope) {
        return []
      }

      if (user.currentScope == 'buyer' || user.currentScope == 'fleet') {
        return terminals.filter(
          terminal => terminal.companyId == user.company.id,
        )
      }
      if (user.currentScope == 'seller') {
        return terminals.filter(terminal => {
          return buyerSellers.map(bs => terminal.companyId == bs.buyerId)
        })
      }

      return []
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectBuyerDataTerminals = createSelector(
  selectTerminals,
  selectBuyerSellers,
  selectUser,

  (terminals, buyerSellers, user) => {
    if (!terminals || !buyerSellers || !user.currentScope) {
      return []
    }

    return terminals.filter(terminal => {
      return buyerSellers.map(bs => terminal.companyId == bs.buyerId)
    })
  },
)

export const selectSortedBuyerTerminals = createSelector(
  selectBuyerTerminals,
  buyerTerminals => _.orderBy(buyerTerminals, 'name'),
)

export const selectBuyerTerminalOptions = createSelector(
  selectBuyerTerminals,
  state => state.data.companies,
  state => state.session.user,
  (buyerTerminals, companiesHash, user) => {
    const companies = columnHashToColumnsArray(companiesHash)
    try {
      let myCompany = user.company || {}

      return buyerTerminals.map(({ id, code, name, companyId }) => {
        const findCompany = _.find(companies, { id: companyId })
        const label = code

        return {
          label,
          value: id,
          code,
          id,
          name,
          imageUrl: findCompany?.logo,
          companyId: companyId,
          hideAvatar: myCompany.id === companyId,
          isBuyerTerminal: true,
        }
      })
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectBuyerFleetTerminalOptions = createSelector(
  selectBuyerFleetTerminals,
  buyerTerminals => {
    try {
      return buyerTerminals.map(({ id, code, name, companyId }) => {
        const label = code ? `${code} - ${name}` : name

        return {
          label,
          value: id,
          code,
          id,
          name,
          companyId: companyId,
          isBuyerTerminal: true,
        }
      })
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectBuyerDataTerminalOptions = createSelector(
  selectBuyerDataTerminals,
  buyerTerminals => {
    try {
      return buyerTerminals.map(({ id, code, name, companyId }) => {
        const label = code ? `${code} - ${name}` : name

        return {
          label,
          value: id,
          code,
          id,
          name,
          companyId: companyId,
          isBuyerTerminal: true,
        }
      })
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectBuyerTerminalsByBuyerSeller = createSelector(
  selectBuyerTerminals,

  selectBuyerTerminals => {
    try {
      const newTerminals = selectBuyerTerminals
        .map(({ id, code, name }) => ({
          label: `${code} - ${name}`,
          value: id,
          id,
          code,
        }))
        .sort((a, b) => a.code?.localeCompare(b.code))

      newTerminals.unshift(notSet)

      return newTerminals
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)

export const selectCommonBuyerTerminalOptions = createSelector(
  selectBuyerTerminals,

  buyerTerminals => {
    const mapBuyerTerminals = buyerTerminals.map(buyerTerminal => {
      const { id, code, name } = buyerTerminal
      const label = code ? `${code} - ${name}` : name

      return { label, value: id, id }
    })

    mapBuyerTerminals.sort((a, b) => a.label?.localeCompare(b.label))

    return mapBuyerTerminals
  },
)

export const selectCommonBuyerTerminalOptionsWithNotSet = createSelector(
  selectCommonBuyerTerminalOptions,

  buyerTerminals => [
    {
      value: null,
      label: 'Not Set',
    },
    ...buyerTerminals,
  ],
)

export const selectCommonBuyerTerminalOptionsWithoutNotSet = createSelector(
  selectBuyerTerminals,

  buyerTerminals => {
    try {
      const mapBuyerTerminals = buyerTerminals.map(buyerTerminal => {
        const { id, code, name } = buyerTerminal
        const label = code ? `${code} - ${name}` : name

        return { label, value: id, id }
      })
      mapBuyerTerminals.sort((a, b) => a.label?.localeCompare(b.label))

      return mapBuyerTerminals
    } catch (error) {
      console.log('error', error)
      return []
    }
  },
)
