import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const DeleteIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        d='M2.69 6.705a.75.75 0 0 0-1.38.59l1.38-.59Zm12.897 6.624-.274-.698.274.698Zm-6.546.409a.75.75 0 1 0-1.257-.818l1.257.818Zm-2.67 1.353a.75.75 0 1 0 1.258.818l-1.258-.818ZM22.69 7.295a.75.75 0 0 0-1.378-.59l1.378.59ZM19 11.13l-.513-.547.513.547Zm.97 2.03a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM11.25 16.5a.75.75 0 0 0 1.5 0h-1.5Zm5.121-.591a.75.75 0 1 0 1.258-.818l-1.258.818Zm-10.84-4.25A.75.75 0 0 0 4.47 10.6l1.06 1.06Zm-2.561.44a.75.75 0 0 0 1.06 1.06L2.97 12.1ZM12 13.25c-3.224 0-5.539-1.605-7.075-3.26a13.637 13.637 0 0 1-1.702-2.28 11.707 11.707 0 0 1-.507-.946 3.903 3.903 0 0 1-.022-.049l-.004-.01-.001-.001L2 7a76 76 0 0 0-.69.296h.001l.001.003.003.006a3.837 3.837 0 0 0 .04.088 13.202 13.202 0 0 0 .58 1.084c.41.69 1.034 1.61 1.89 2.533C5.54 12.855 8.224 14.75 12 14.75v-1.5Zm3.313-.62c-.97.383-2.071.62-3.313.62v1.5c1.438 0 2.725-.276 3.862-.723l-.55-1.396Zm-7.529.29L6.371 15.09l1.258.818 1.412-2.171-1.257-.818ZM22 7l-.69-.296h.001v.002l-.007.013a8.017 8.017 0 0 1-.151.313 13.298 13.298 0 0 1-2.666 3.55l1.026 1.094a14.802 14.802 0 0 0 3.122-4.26l.039-.085.01-.024.004-.007v-.003h.001v-.001L22 7Zm-3.513 3.582c-.86.806-1.913 1.552-3.174 2.049l.549 1.396c1.473-.58 2.685-1.444 3.651-2.351l-1.026-1.094Zm-.017 1.077 1.5 1.5 1.06-1.06-1.5-1.5-1.06 1.06ZM11.25 14v2.5h1.5V14h-1.5Zm3.709-.262 1.412 2.171 1.258-.818-1.413-2.171-1.257.818Zm-10.49-3.14-1.5 1.5L4.03 13.16l1.5-1.5-1.06-1.06Z'
      />
    </svg>
  )
}
export default DeleteIcon
