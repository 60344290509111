import { createSlice } from '@reduxjs/toolkit'

const companyPhoneNumbersSlice = createSlice({
  name: 'companyPhoneNumbers',
  initialState: {
    loadingTable: false,
  },
  reducers: {
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
  },
})

export default companyPhoneNumbersSlice
