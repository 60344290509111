import { IonProgressBar } from '@ionic/react'
import type { IReusableTableRowData, ReusableTableInstance } from '../../types'

export interface IRTLinearProgressBarProps<
  TData extends IReusableTableRowData,
> {
  table: ReusableTableInstance<TData>
}

export const RTLinearProgressBar = <TData extends IReusableTableRowData>({
  table,
}: IRTLinearProgressBarProps<TData>) => {
  const { getState } = table
  const { isSaving, showProgressBars } = getState()

  const isShowing = showProgressBars !== false && (showProgressBars || isSaving)

  if (isShowing) {
    return <IonProgressBar type='indeterminate' />
  }

  return null
}
