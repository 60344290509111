import React from 'react'
import {
  IonCol,
  IonImg,
  IonRow,
  IonText,
  IonAvatar,
  IonLabel,
  IonChip,
} from '@ionic/react'

const DEFAULT_LOGO =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACYCAMAAAAvHNATAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAJcEhZcwAACxMAAAsTAQCanBgAAAA8UExURUdwTO3t7e7u7uzs7O3t7e3t7e3t7e/v7+zs7O7u7u3t7REREZubm0hISH9/fy8vL9nZ2bq6uh8fH2dnZxAlB0cAAAAKdFJOUwB4X5DFoe8ZQd81kq9CAAADm0lEQVR42u2c2a7qMAxFSZuZAi38/79eaJmHUjtOstE9+wmJSizZjmNSx6tVopxvWqN1CHFUCFobpbxfVZRXRtv4SUc+XwVqhukqq4vCOd8ugLoomEJsJKqz4fKzOUWmOtutcTmNZZhYo0wuNK9jorSHxBrRpK3mRLDEHepUFJQSQ/Mhiio0MuYyUVwS/vQ2ZlCy0VwbM6lNMpoLMZtCAlljY0ZZtjtVzCwFsxpfVmfVXC+7ReUM+5QlUIqLSlaOi0ZWkotCVpaLQKZjYS1cmyYWl4HI98w9oIlV9HXfdLYOmHVYC3Lx0mxjNbWAATbJIzryS5iZWFUG0pEzzqzryJmVqWJ1vd0AXASQw4v8c50BarB38a8xwF5M5mPENJlGAdOQEfZqMoMDZkAN9riXN0BgD+lfZJfsDptOxGSyuaLbrY/adaLhb4SwhNBuRXbqP6Nhs75TMto1/L0klgSal/DkDWu/lXJom74m77F6sVizqZ58xjppK4Hmk7LrO6yjegE0lVBYfMB6QtsMCSWGlcWSQLPMEPuCJYDmOf/aFmAlozX0LNYvw5rQrin30NOLMmLsH5ZijWiXp9dbevQTjbxZjnWPtiFHPzX2O6oBJn9SM5qjL8rRZPvFMdPvxxijRz95UU6/tFv6+FQ/9gXA4jBtN5tF2rEceSowGDXPYU3Ugf4bhrNT9jsa147+E8d8wdnChz2Fa9+zwFhVIsVmvAIjMMvX7j6kxwRy+eY+m3a8wJ/AIiZY/AP7A/t/wWDTBSyYxgTTuGAGE8wwX89nB1PMI5UCYB4TzDPfPRQA450MZwfjHo/lBtPco+HcYIbbPJAbTJEP7oZu1PjX8jB9nk6qz5+76ez19amBGvvE6N+umSKdQ1n64XAZME3vUCkDpugvIMqAOfrLt6FjihL8oX7/3+csBtUM8pAsKraYzicLQF8avMaeR08KvBQXVcDqArypAeiwnsuu2E1HSOHvfqOxDbcVELZ5EsVkboXZb2p+qaUZtgkct22+fvw3v3Y1o/JePnczyUM6EvnCFO4VM9hLebjXGHEvflbJZi3QZf/5IuzHrmPDXmDHvfKPOyQBd6wE7iCOMvmMOVgIdNgL8Hgc3IFCuCOYTu7MYjQrMFQOdcxXBqMFsRl8qKPkRP0JOhcQdJKizjWyM8mheSeJOu4KtSrnEFHuJFHblhoJawKJyq3KCXK07w3O6BmmOsOQb3Reqefx0W2T7r1/3Z/PsFrHmzcAAAAASUVORK5CYII=\n' +
  '\n'

const PaymentMethodCard = props => {
  const {
    bankName,
    accountNumber,
    bankLogo,
    canSetDefault,
    isDefault,
    onDelete,
    onSetDefault,
  } = props

  const isDefaultMethod = isDefault !== undefined && isDefault === true
  let logo = DEFAULT_LOGO

  if (bankLogo !== undefined) {
    if (bankLogo.type === 'url') {
      logo = bankLogo.logo
    } else if (bankLogo.type === 'base64') {
      logo = `data:image/png;base64,${bankLogo.logo}`
    }
  }

  return (
    <>
      <IonCol>
        <IonRow>
          <IonCol size='auto'>
            <IonAvatar>
              <IonImg
                src={logo}
                style={{ width: '50px', height: '50px', borderRadius: '20' }}
              />
            </IonAvatar>
          </IonCol>
          <IonCol size='3.5'>
            <IonRow>
              <IonText color='primary'>
                <h3>{bankName}</h3>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText>
                <h4>{accountNumber}</h4>
              </IonText>
            </IonRow>
            {isDefaultMethod && (
              <IonRow>
                <IonLabel>
                  <p>Default</p>
                </IonLabel>
              </IonRow>
            )}
          </IonCol>
          {canSetDefault && !isDefaultMethod && (
            <IonCol size='1.3'>
              <IonRow>
                <IonChip color='dark' onClick={onSetDefault}>
                  Set as default
                </IonChip>
              </IonRow>
            </IonCol>
          )}
          {onDelete && (
            <IonCol size='2'>
              <IonRow>
                <IonChip color={'danger'} onClick={onDelete}>
                  Delete
                </IonChip>
              </IonRow>
            </IonCol>
          )}
        </IonRow>
      </IonCol>
    </>
  )
}

export default PaymentMethodCard
