import { DragIcon } from '~/components/shared'

import type { DragEventHandler } from 'react'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'

export interface IRTGrabHandleButtonProps<TData extends IReusableTableRowData> {
  location?: 'column' | 'row'
  onDragEnd: DragEventHandler<HTMLDivElement>
  onDragStart: DragEventHandler<HTMLDivElement>
  table: ReusableTableInstance<TData>
}

const RTGrabHandleButton = <TData extends IReusableTableRowData>({
  location,
  table,
  ...rest
}: IRTGrabHandleButtonProps<TData>) => {
  return (
    <div
      draggable='true'
      {...rest}
      className='RTGrabHandleButton__container'
      style={{
        opacity: location === 'row' ? 1 : 0.5,
      }}
    >
      <DragIcon />
    </div>
  )
}

export default RTGrabHandleButton
