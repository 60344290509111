import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Button, SelectSearchDropdown } from '~/components/shared'

import './ToolbarSelectedRows.scss'
import clsx from 'clsx'

function ToolbarSelectedRows(props) {
  const { buttons, className, style } = props

  const renderButtons = useCallback(
    () =>
      buttons
        .filter(({ hide }) => hide !== true)
        .map(button => {
          switch (button.type) {
            case 'button': {
              return (
                <Button
                  key={`toolbar--${button.label}`}
                  size='small'
                  {...button}
                />
              )
            }

            case 'dropdown': {
              const { options, onChange, label, loading } = button

              return (
                <SelectSearchDropdown
                  clickaleOutsideToCloseMenu={true}
                  width={250}
                  subComponent={
                    <Button
                      mode='ios'
                      slot='start'
                      size='small'
                      fill='outline'
                      label={label}
                      loading={loading}
                    />
                  }
                  isContainNotSet
                  value={options}
                  onChange={onChange}
                  styling={false}
                  key={`toolbar--${button.label}`}
                />
              )
            }

            default: {
              return (
                <Button
                  mode='ios'
                  key={`toolbar--${button.label}`}
                  size='small'
                  {...button}
                />
              )
            }
          }
        }),
    [buttons],
  )

  return (
    <div className={clsx('ToolbarSelectedRows__root', className)} style={style}>
      {renderButtons()}
    </div>
  )
}

ToolbarSelectedRows.propTypes = {
  renderLabel: PropTypes.func,
  buttons: PropTypes.array,
}

ToolbarSelectedRows.defaultProps = {
  renderLabel: () => {},
  buttons: [],
}

export default ToolbarSelectedRows
