import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

import moment from 'moment'
export const DOCUMENT_SOURCES = {
  CAMERA: 'camera',
  PHOTO_LIBRARY: 'photoLibrary',
  EDITOR: 'editor',
  STORAGE: 'storage',
}
export const takePicture = async () => {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: true,
    CameraDirection: 'FRONT',
    source: CameraSource.Photo,
    resultType: CameraResultType.DataUrl,
  })
  const name = moment().toISOString()

  return {
    type: 'image/jpeg',
    fileType: 'image',
    image: image.dataUrl,
    name: `${name}.jpeg`,
    key: moment().format('x'),
    displayName: name,
    source: DOCUMENT_SOURCES.CAMERA,
  }
}

export const chooseFileFromDevice = files => {
  if (files.length < 1) return []

  return files.map(selectedFile => ({
    file: selectedFile,
    type: selectedFile.type,
    fileType: selectedFile.type == 'application/pdf' ? 'pdf' : 'image',
    size: selectedFile.size,
    name: selectedFile.name,
    displayName: selectedFile.name.split('.').slice(0, -1).join('.'),
    image: selectedFile.preview,
    key: `${moment().format('x')}_${Math.random() * 100}`,
    source: DOCUMENT_SOURCES.PHOTO_LIBRARY,
  }))
}

export const chooseFileFromStorage = files => {
  if (files.length < 1) return []

  return files.map(selectedFile => ({
    ...selectedFile,
    image: selectedFile.image || selectedFile.url,
    key: selectedFile.key || `${moment().format('x')}_${Math.random() * 100}`,
    source: selectedFile.source || DOCUMENT_SOURCES.STORAGE,
  }))
}
