import clsx from 'clsx'
import _ from 'lodash'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import Select from 'react-select'

const selectErrorStyle = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
}

const selectStyle = {
  control: base => ({
    ...base,
    color: 'black',
  }),
}

const selectWarningStyle = {
  control: base => ({
    ...base,
    borderColor: 'yellow',
  }),
}

export const FloatingSelect = ({
  name,
  placeholder,
  control,
  options = [],
  errors,
  components = {},
  className,
  isReadOnly,
  isWarning,
  companyId,
  onChange,
  isDisabled,
  styles,
  menuIsOpen,
}) => {
  const selectDisplay = _.get(errors, name)
    ? selectErrorStyle
    : isWarning
    ? selectWarningStyle
    : selectStyle

  const {
    field: { onChange: onChangeField, onBlur, value },
  } = useController({
    name,
    control,
  })

  const selectedValue = useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  )

  return (
    <div className={clsx(className, { isDisabled })}>
      <Select
        className={clsx('d-flex', { isReadOnly })}
        options={options}
        placeholder={placeholder}
        companyId={companyId}
        name={name}
        components={{
          ...components,
          IndicatorSeparator: null,
        }}
        styles={{ ...selectDisplay, ...styles }}
        onBlur={onBlur}
        isSearchable
        onChange={e => {
          onChangeField(e.value)
          onChange && onChange({ field: name, value: e.value })
        }}
        value={selectedValue}
        menuIsOpen={menuIsOpen}
      />
    </div>
  )
}
