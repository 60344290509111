import { trucksSlice } from '../reducers/data/trucks'
import { trucksUiSlice } from '../reducers/ui/trucks'

export const updateTrucksUi = payload => dispatch =>
  dispatch(trucksUiSlice.actions.update(payload))

export const initializeTrucks = trucks => dispatch =>
  dispatch(trucksSlice.actions.index(trucks))

export const updateTruck = truck => dispatch =>
  dispatch(trucksSlice.actions.update(truck))

export const deleteTruck = truckId => dispatch =>
  dispatch(trucksSlice.actions.delete({ id: truckId }))
