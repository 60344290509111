import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { selectSessionUser } from '~/redux/selectors'
import { produce } from 'immer'
import { buildGetUrl } from '~/utils/buildUrl'

import type {
  ICheckSignatory,
  IGetCheckSignatoriesParams,
  IGetCheckSignatoriesResponse,
} from '~/types/models/ICheck'
import type { IUser } from '~/types/models/IUser'

const useModifyCheckSignatories = (params: IGetCheckSignatoriesParams) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryClient = useQueryClient()

  const key = useMemo(
    () => [
      'checkSignatories',
      sessionUser?.id,
      buildGetUrl('/check_signatories', params),
    ],
    [params, sessionUser?.id],
  )

  const addCheckSignatory = useCallback(
    (payload: ICheckSignatory) => {
      queryClient.setQueryData<IGetCheckSignatoriesResponse | undefined>(
        key,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              draft.checkSignatories.push(payload)
            } else {
              draft = {
                checkSignatories: [payload],
              }
            }
          }),
      )
    },
    [key, queryClient],
  )

  return { addCheckSignatory }
}

export default useModifyCheckSignatories
