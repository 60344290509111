import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyUsers from './useModifyUsers'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IGetUserParams, IUser } from '~/types/models/IUser'
import buildFullName from '~/utils/buildFullName'
import { IPerson } from '~/types/models/IPerson'
import { IGetWorkersParams, IWorker } from '~/types/models/IWorker'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { IDriver } from '~/types/models/IDriver'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

export interface IUserData extends Omit<IUser, 'person' | 'worker'> {
  person: IPerson | undefined
  worker: IWorker | undefined
  driver: IDriver | undefined
}

type IExtraParams = Partial<{
  workerParams: Partial<IGetWorkersParams>
}>

const useQueryUsers = (
  params: Partial<IGetUserParams> & IExtraParams = {},
  options?: Partial<UseQueryOptions<IUserData[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const {
    addUser,
    updateUser,
    updateWorker,
    updatePerson,
    updateDriver,
    updateDriverFleet,
    removeWorker,
    assignTruckFleetToDriverFleet,
    assignDefaultTruckFleetToDriverFleet,
  } = useModifyUsers(params)

  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey: [
      'users',
      sessionUser?.id,
      buildGetUrl(apiClient.users.endpoint, params),
    ],
    async queryFn() {
      const { workerParams, ...userParams } = params
      const response = await apiClient.users.get(userParams)

      let peopleData: IPerson[] = []
      let workersData: IWorker[] = []
      let driverFleetsData: IDriverFleet[] = []
      let driversData: IDriver[] = []

      const userIds = response.map(({ id }) => id)
      const userAccessIds = response.map(({ userAccess }) => userAccess.id)
      if (userIds.length) {
        const [
          peopleResponse,
          workersResponse,
          driverFleetsResponse,
          driversResponse,
        ] = await Promise.all([
          apiClient.people.get({
            filters: {
              userId: userIds,
            },
          }),
          apiClient.workers.get({
            ...workerParams,
            filters: {
              userAccessId: userAccessIds,
              ...workerParams?.filters,
            },
          }),
          apiClient.driverFleets.get({ currentLoad: true }),
          apiClient.drivers.get(),
        ])
        peopleData = peopleResponse.people
        workersData = workersResponse.workers
        driverFleetsData = Array.isArray(driverFleetsResponse.driverFleets)
          ? driverFleetsResponse.driverFleets
          : []
        driversData = driversResponse.drivers
      }

      const usersData = response.map(user => {
        const personData = peopleData.find(({ userId }) => user.id === userId)
        const workerData = workersData.find(
          ({ userAccessId }) => userAccessId === user.userAccess?.id,
        )
        const driverFleetData = driverFleetsData.find(
          df => user?.id === df.user?.id,
        )

        const driverData = driversData.find(d => d.user?.id === user?.id)

        return {
          ...user,
          person: personData,
          worker: workerData,
          driverFleet: driverFleetData,
          driver: driverData,
        }
      })

      return usersData
    },
    enabled: Boolean(sessionUser?.id && sessionUser.userAccess),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const usersData = useMemo(() => data || [], [data])

  const userOptions = useMemo(
    () =>
      usersData.map(({ person, id, userAccess }) => ({
        value: id,
        label: buildFullName(person),
        userAccessId: userAccess.id,
      })),
    [usersData],
  )

  const workerUsers = useMemo(
    () => usersData.filter(({ worker }) => worker),
    [usersData],
  )

  const driverFleetUsers = useMemo(
    () => usersData.filter(({ driverFleet }) => driverFleet),
    [usersData],
  )

  const driverFleetsHavingTruck = useMemo(
    () =>
      driverFleetUsers.filter(
        ({ driverFleet }) => driverFleet?.currentTruckFleetId,
      ),
    [driverFleetUsers],
  )

  const driverFleetsNotHavingTruck = useMemo(
    () =>
      driverFleetUsers.filter(
        ({ driverFleet }) => !driverFleet?.currentTruckFleetId,
      ),
    [driverFleetUsers],
  )

  const driverFleetOptions = [
    {
      label: 'Available',
      options: [
        { value: null, label: 'Not set' },
        ...driverFleetsNotHavingTruck.map(({ person, driverFleet }) => ({
          value: driverFleet?.id,
          label: buildFullName(person),
        })),
      ],
    },
    {
      label: 'Assigned',
      options: driverFleetsHavingTruck.map(({ person, driverFleet }) => ({
        value: driverFleet?.id,
        label: buildFullName(person),
      })),
    },
  ]

  const workerOptions = useMemo(
    () =>
      workerUsers.map(({ person, worker }) => ({
        value: worker?.id,
        label: buildFullName(person),
      })),
    [workerUsers],
  )

  const findUserById = useCallback(
    (userId: number) => {
      return usersData.find(({ id }) => id === userId)
    },
    [usersData],
  )

  const findUserByUserAccessId = useCallback(
    (userId: number) => {
      return usersData.find(({ userAccess }) => userAccess.id === userId)
    },
    [usersData],
  )

  const findUserByDriverFleetId = useCallback(
    (driverFleetId: number) =>
      usersData.find(({ driverFleet }) => driverFleet?.id === driverFleetId),
    [usersData],
  )

  const findUserByWorkerId = useCallback(
    (workerId: number | null | undefined) =>
      usersData.find(({ worker }) => worker?.id === workerId),
    [usersData],
  )

  return {
    usersData,
    userOptions,
    isLoadingUsersData: isLoading,
    workerOptions,
    workerUsers,
    driverFleetUsers,
    driverFleetOptions,
    isUsersFetched: isFetched,
    findUserById,
    findUserByUserAccessId,
    findUserByWorkerId,
    addUser,
    updateUser,
    updateWorker,
    updatePerson,
    updateDriver,
    updateDriverFleet,
    removeWorker,
    assignTruckFleetToDriverFleet,
    findUserByDriverFleetId,
    assignDefaultTruckFleetToDriverFleet,
    refetchUsersData: refetch,
  }
}

export default useQueryUsers
