import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ISchedule } from '~/types/models/ISchedule'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySchedule = (
  scheduleId?: number,
  options?: Partial<UseQueryOptions<ISchedule>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['schedule', sessionUser?.id, scheduleId],
    queryFn() {
      return apiClient.schedules.getById(scheduleId as number)
    },
    enabled: Boolean(sessionUser?.id && scheduleId),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  return {
    scheduleData: data,
    isLoadingScheduleData: isLoading,
    refetchScheduleData: refetch,
  }
}

export default useQuerySchedule
