import { PushNotifications } from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import { apiClient } from '~/api/ApiClient'

class PushRegisterService {
  async registerPushNotification(user) {
    if (isMobileApp()) {
      await PushNotifications.requestPermissions()
      await PushNotifications.register()
      const { token } = await FCM.getToken()
      apiClient.notificationRegistrations.create({
        deviceToken: token,
        currentUser: user,
      })
    }
  }
}

export const pushRegisterService = new PushRegisterService()

export default PushRegisterService
