import { DEFAULT_ICON_SIZE } from './constants'
import type { ISvgIconProps } from './type'

const CloseIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM8.97 8.97a.75.75 0 0 1 1.06 0L12 10.94l1.97-1.97a.75.75 0 0 1 1.06 1.06L13.06 12l1.97 1.97a.75.75 0 0 1-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 0 1-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 0 1 0-1.06Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
export default CloseIcon
