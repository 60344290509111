import { CommonDialogV2 } from '~/components/shared'
import BuyerTerminalReferenceForm from './BuyerTerminalReferenceForm'

import './DialogBuyerTerminalReferenceForm.scss'

function DialogBuyerTerminalReferenceForm(props) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    ...dialogProps
  } = props


  return (
    <CommonDialogV2
      {...dialogProps}
      isHiddenOkButton
      title="Buyer Terminal Reference"
    >
      <BuyerTerminalReferenceForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogBuyerTerminalReferenceForm
