import React, { useState, useEffect, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import Select, { components } from 'react-select'
import { refreshCircleOutline } from 'ionicons/icons'
import { notSetOptionStyles } from '~/utils'
// import { selectMyCurrentCompany } from '~/redux/selectors'

import PropTypes from 'prop-types'

import '~/styles/multiSelectDropdown.scss'

const MultiSelectSearchDropdown = props => {
  const {
    field,
    values,
    data = [], // options
    className = 'documents-sortby-filter',
    onSubmit,
    showRefresh,
    children,
    isSingleSelect,
    customOption,
    renderAfterMenu,
    dropdownMenuStyle,
    onChangeShowMenu,
  } = props

  // const myCurrentCompany = useSelector(selectMyCurrentCompany)

  const [currentValues, setCurrentValues] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const [refreshDone, setRefreshDone] = useState(false)
  const selectRef = useRef(null)
  // const dispatch = useDispatch()

  const showRefreshIcon = refreshDone

  // const mapOptions = useMemo(() => {
  //   const uniqOptions = _.uniqBy(data, 'value')
  //   if (showRefresh) {
  //     const filterData = uniqOptions.filter(
  //       option => !_.isNil(option.value) || option.value !== 'null',
  //     )
  //     filterData.unshift({
  //       value: 'null',
  //       label: 'Not Set',
  //     })

  //     return filterData
  //   }

  //   return uniqOptions
  // }, [data, showRefresh])

  // const options = useMemo(() => {
  //   return _.uniqBy(currentData, 'value')
  // }, [currentData])

  useEffect(() => {
    if (values && !isSingleSelect) {
      let newData = []
      newData = data && data.filter(element => values.includes(element.value))
      setCurrentValues(newData)
    } else if (values) {
      const curValue = data.filter(element => element.value == values)
      setCurrentValues(curValue ? curValue[0] : null)
    }
  }, [values, data, isSingleSelect])

  const onChange = selectedOption => {
    if (isSingleSelect) {
      setCurrentValues(selectedOption)
      onSubmit(selectedOption.value)
      setShowMenu(false)
    } else {
      const itemSelected = []
      selectedOption.forEach(element => itemSelected.push(element.value))
      onSubmit(itemSelected, field)
    }
  }

  useEffect(() => {
    onChangeShowMenu && onChangeShowMenu(showMenu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu])

  useEffect(() => {
    const isInput = document.querySelector(`#input-${field}`)
    if (showMenu && isInput) {
      isInput.focus()
    }
  }, [showMenu, field])

  const onFilterClick = () => {
    console.log('onFilterClick')
    setRefreshDone(true)
  }

  const showSearch = data && data.length > 3

  const DropdownIndicator = props => {
    return (
      <>
        {showRefresh && !showRefreshIcon && (
          <span onClick={onFilterClick}>
            <IonIcon
              icon={refreshCircleOutline}
              style={{ fontSize: '20px', cursor: 'pointer' }}
            />
          </span>
        )}
        {components.DropdownIndicator && (
          <div onClick={() => setShowMenu(!showMenu)}>
            <components.DropdownIndicator {...props} />
          </div>
        )}
      </>
    )
  }

  const renderComponents = () => {
    const basicComponent = {
      DropdownIndicator,
      IndicatorSeparator: null,
    }

    if (customOption) {
      basicComponent.Option = customOption
    }

    return basicComponent
  }

  const customStyles = {
    valueContainer: provided => ({
      ...provided,
      paddingRight: 0,
    }),
    ...notSetOptionStyles,
  }

  return (
    <Dropdown
      className={className}
      onToggle={() => setShowMenu(!showMenu)}
      show={showMenu}
    >
      <Dropdown.Toggle
        id={`dropdown-${field}`}
        variant='link'
        size='lg'
        role='menu'
        bsPrefix='p-0'
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          width: '200px',
          padding: 0,
          border: 0,
          margin: '0',
          zIndex: '99999',
          ...dropdownMenuStyle,
        }}
        show={showMenu}
      >
        {showMenu && (
          <div>
            <div>
              <Select
                ref={selectRef}
                inputId={`input-${field}`}
                components={renderComponents()}
                classNamePrefix='react-select'
                isMulti={!isSingleSelect}
                options={data}
                value={currentValues}
                blurInputOnSelect
                menuIsOpen
                openMenuOnFocus
                isSearchable={showSearch}
                // isLoading={loading}
                placeholder={showSearch ? 'Search' : 'Select An option'}
                onChange={onChange}
                styles={customStyles}
              />
              <React.Fragment>
                {renderAfterMenu && renderAfterMenu()}
              </React.Fragment>
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

MultiSelectSearchDropdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.string,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  renderAfterMenu: PropTypes.func,
}

export default MultiSelectSearchDropdown
