import { PropsWithChildren } from 'react'
import type {
  RTHeader,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { getCommonMRTCellStyles } from '../../../utils/style.utils'
import { parseFromValuesOrFunc } from '../../../utils/utils'

import './styles.scss'
import clsx from 'clsx'

export interface IRTTableFooterCellProps<TData extends IReusableTableRowData>
  extends PropsWithChildren {
  footer: RTHeader<TData>
  staticColumnIndex?: number
  table: ReusableTableInstance<TData>
}

const RTTableFooterCell = <TData extends IReusableTableRowData>({
  footer,
  staticColumnIndex,
  table,
  ...rest
}: IRTTableFooterCellProps<TData>) => {
  const {
    getState,
    options: { enableColumnPinning },
  } = table
  const { density } = getState()
  const { column } = footer
  const { columnDef } = column
  const { columnDefType } = columnDef

  const isColumnPinned =
    enableColumnPinning &&
    columnDef.columnDefType !== 'group' &&
    column.getIsPinned()

  const args = { column, table }
  const tableCellProps = {
    ...args,
    ...rest,
  }

  const isPinned = column?.getIsPinned()

  return (
    <td
      colSpan={footer.colSpan}
      data-index={staticColumnIndex}
      data-pinned={!!isColumnPinned || undefined}
      className={clsx('RTTableFooterCell__container', columnDef.align)}
      {...(tableCellProps as any)}
      style={{
        padding:
          density === 'compact'
            ? '0.5rem'
            : density === 'comfortable'
            ? '1rem'
            : '1.5rem',
        verticalAlign: 'top',
        align: columnDefType === 'group' ? 'center' : 'left',

        boxShadow: column
          ? isPinned === 'left' && column.getIsLastColumn(isPinned)
            ? '-4px 0 4px -4px #e5e5e5 inset'
            : isPinned === 'right' && column.getIsFirstColumn(isPinned)
            ? '4px 0 4px -4px #e5e5e5 inset'
            : undefined
          : undefined,

        ...getCommonMRTCellStyles({
          column,
          header: footer,
          table,
          tableCellProps,
        }),
      }}
    >
      {tableCellProps.children ??
        (footer.isPlaceholder
          ? null
          : parseFromValuesOrFunc(columnDef.Footer, {
              column,
              footer,
              table,
            }) ??
            columnDef.footer ??
            null)}
    </td>
  )
}

export default RTTableFooterCell
