import { useCallback, useRef } from 'react'

import { Button, CommonDialogV2 } from '~/components/shared'
import ReactSignatureCanvas from 'react-signature-canvas'

import type { ISignatureModalProps } from './type'

import './styles.scss'

function SignatureModal(props: ISignatureModalProps) {
  const { onClose, onConfirm, ...dialogProps } = props

  const canvasRef = useRef<ReactSignatureCanvas>()

  const onReset = useCallback(() => {
    canvasRef.current?.clear()
  }, [])

  const onSaveSignature = useCallback(() => {
    const signature = canvasRef.current?.getTrimmedCanvas()
    let result: string | null = null
    if (signature && signature.width > 1) {
      result = signature.toDataURL()
    }
    onConfirm && onConfirm(result)
    onClose && onClose()
  }, [onClose, onConfirm])

  return (
    <CommonDialogV2
      {...dialogProps}
      onClose={onClose}
      className='SignatureModal__container'
      title='Signature'
      onOk={onReset}
      okButtonProps={{
        color: 'medium',
        label: 'Reset',
      }}
    >
      <ReactSignatureCanvas
        canvasProps={{
          width: 498,
          height: 400,
          style: {
            backgroundColor: '#e5e5e5',
          },
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={canvasRef as any}
      />

      <Button expand='full' style={{ margin: 0 }} onClick={onSaveSignature}>
        Confirm
      </Button>
    </CommonDialogV2>
  )
}
export default SignatureModal
