import { useCallback, useState } from 'react'

import NotesPopup from './NotesPopup'
import { Button } from '~/components/shared'
import { Dropdown } from 'react-bootstrap'

import { create, createOutline } from 'ionicons/icons'
import clsx from 'clsx'

import './styles.scss'

function NotesButton(props) {
  const {
    value,
    disabled,
    editable,
    loading,
    placement = 'bottom',
    className,
    onSave,
    size = 'small',
  } = props

  const [isShow, setIsShow] = useState(false)

  const onToggle = useCallback(
    (nextValue, { source }) => {
      console.log('source', source)
      setIsShow(nextValue)
    },
    [],
  )

  const onClosePopup = useCallback(
    () => {
      setIsShow(false)
    },
    [],
  )

  return (
    <Dropdown className={clsx('NotesButton__container', className)} show={isShow} onToggle={onToggle}>
      <Dropdown.Toggle
        color='concord'
        as={Button}
        fill='clear'
        size={size}
        className='ion-no-padding'
        icon={value ? create : createOutline}
        tooltipProps={{
          content: value || 'Add notes',
          placement
        }}
        loading={loading}
        isDisabled={disabled}
      />

      <Dropdown.Menu>
        <NotesPopup
          editable={editable}
          value={value}
          onClose={onClosePopup}
          onSave={onSave}
          isShow={isShow}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

// NotesButton.propTypes = {
//   value: PropTypes.string,
//   onSave: PropTypes.func,
//   editable: PropTypes.bool,
// }

NotesButton.defaultProps = {
  editable: true,
}

export default NotesButton
