import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import defaultModuleFilters from '~/constants/defaultModuleFilters'
import defaultSorts from '~/hooks/useStoreSorts/defaultSorts'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { formatOrderParams } from '~/utils/buildUrl'
import moment from 'moment'
import { selectBrowserTz } from '~/redux/selectors'

export const name = 'startTimes'

export const initialState = {
  startTimes: [],
  filters: defaultModuleFilters.startTimes,
  sorts: defaultSorts.startTimes,
  columns: [
    {
      label: 'Actions',
      field: 'actions',
      hide: false,
    },
    {
      label: 'Date',
      field: 'startTime',
      hide: false,
    },
    {
      label: 'Time',
      field: 'time',
      hide: false,
    },
    {
      label: 'Seen At',
      field: 'seenAt',
      hide: false,
    },
    {
      label: 'Confirmed At',
      field: 'confirmedAt',
    },
    {
      label: 'Driver',
      field: 'driverId',
    },
    { label: 'Terminal', field: 'terminalId', hide: false },
    {
      label: 'Clock In',
      field: 'shift.clockIn',
      hide: false,
    },
    {
      label: 'Clock Out',
      field: 'shift.clockOut',
      hide: false,
    },
    {
      label: 'In Service',
      field: 'shift.inService',
      hide: false,
    },
    {
      label: 'End Time',
      field: 'shift.endTime',
      hide: false,
    },
    {
      label: 'Start Terminal',
      field: 'shift.startTerminalId',
      hide: false,
    },
    {
      label: 'End Terminal',
      field: 'shift.endTerminalId',
      hide: false,
    },
    {
      label: 'Shift Notes',
      field: 'shift.notes',
      hide: false,
    },
    {
      label: 'Total Hours',
      field: 'shift.totalHrs',
      hide: false,
    },
    {
      label: 'Push Status',
      field: 'pushStatus',
      hide: false,
    },
    {
      label: 'Push Publish ID',
      field: 'pushPublishId',
      hide: false,
    },
  ],
  loading: true,
  toggleShowOrders: false,
  searchWord: '',
  viewMode: 'list', // list || grid
  loadingStartTimeIds: [],
}

const formatDate = (date, tz, isEndDate = false) => {
  let [years, months, days, hours, minutes, seconds = '00'] = moment(date)
    .format('YYYY-MM-DD-HH-mm')
    .split('-')

  if (isEndDate && Number(hours) === 0) {
    hours = '23'
    minutes = '59'
    seconds = '59'
    const endDay = moment().endOf('month').format('DD')
    if (Number(endDay) === Number(days)) {
      const newDate = moment(date).add({ days: -1 })
      years = newDate.format('YYYY')
      months = newDate.format('MM')
      days = newDate.format('DD')
    }
  }

  return `${years}-${months}-${days}T${hours}:${minutes}:${seconds} ${tz}`
}

export const actFetchStartTimes = createAsyncThunk(
  `${name}/fetchStartTimes`,
  async (_payload, { dispatch, getState }) => {
    dispatch(startTimesSlice.actions.setLoading(true))
    try {
      const { filters, sorts, searchWord } = getState().startTimes
      const { startDate, endDate } = getState().router.location.query
      const tz = selectBrowserTz(getState())
      const startTime = {}
      if (startDate) {
        startTime.startDate = formatDate(decodeURIComponent(startDate), tz)
      }
      if (endDate) {
        // startTime.endDate = decodeURIComponent(endDate)
        startTime.endDate = formatDate(decodeURIComponent(endDate), tz, true)
      }

      if (Object.keys(startTime).length) {
        startTime.showOriginal = true
      }

      const { startTimes } = await apiClient.startTimes.get({
        filters: {
          ...filters,
          startTime,
          searchWord: searchWord,
        },
        order: formatOrderParams(sorts),
      })
      dispatch(startTimesSlice.actions.setStartTimesData(startTimes))
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(startTimesSlice.actions.setLoading(false))
    }
  },
)

export const actUpdateStartTime = createAsyncThunk(
  `${name}/updateStartTime`,
  async (payload, { dispatch }) => {
    const { id, ...data } = payload
    dispatch(startTimesSlice.actions.toggleLoadingStartTimeIds(id))
    try {
      const { startTime } = await apiClient.startTimes.update(id, data)
      if (startTime.id) {
        toast.success(toastMessages.updateSuccess)
        dispatch(startTimesSlice.actions.updateStartTime(startTime))
      } else {
        toast.error(toastMessages.updateError)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(startTimesSlice.actions.toggleLoadingStartTimeIds(payload.id))
    }
  },
)

export const actDeleteStartTime = createAsyncThunk(
  `${name}/deleteStartTime`,
  async (payload, { dispatch }) => {
    const { id } = payload
    dispatch(startTimesSlice.actions.toggleLoadingStartTimeIds(id))
    try {
      const response = await apiClient.startTimes.delete(id)
      if (response.id) {
        toast.success(toastMessages.deleteSuccess)
        dispatch(startTimesSlice.actions.removeStartTime(response))
      } else {
        toast.error(toastMessages.deleteError)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(startTimesSlice.actions.toggleLoadingStartTimeIds(payload.id))
    }
  },
)

const startTimesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setStartTimesData(state, { payload }) {
      state.startTimes = payload
    },
    setStartTimeDate(state, { payload }) {
      state.filters.startTime.startDate = payload.startDate
      state.filters.startTime.endDate = payload.endDate
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    updateStartTime(state, { payload }) {
      const { id, ...data } = payload
      const index = state.startTimes.findIndex(startTime => startTime.id === id)
      if (index !== -1) {
        state.startTimes[index] = {
          ...state.startTimes[index],
          ...data,
        }
      }
    },
    addStartTimes(state, { payload }) {
      payload.forEach(startTime => {
        state.startTimes.push(startTime)
      })
    },
    removeStartTime(state, { payload }) {
      const index = state.startTimes.findIndex(({ id }) => payload.id === id)
      if (index !== -1) {
        state.startTimes.splice(index, 1)
      }
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setSorts(state, { payload }) {
      state.sorts = payload
    },
    setViewMode(state, { payload }) {
      state.viewMode = payload
    },
    setSearchWord(state, { payload }) {
      state.searchWord = payload
    },
    toggleLoadingStartTimeIds(state, { payload }) {
      const index = state.loadingStartTimeIds.indexOf(payload)
      if (index === -1) {
        state.loadingStartTimeIds.push(payload)
      } else {
        state.loadingStartTimeIds.splice(index, 1)
      }
    },
    setColumns(state, { payload }) {
      state.columns = payload
    },
  },
})

export default startTimesSlice
