import { createSlice } from '@reduxjs/toolkit'
import {
  INVOICE_PANELS,
  COMPARISON_TABLE_COLUMNS,
} from '~/constants/invoice-type'
import defaultModuleFilters from '~/constants/defaultModuleFilters'

export const invoiceUiSlice = createSlice({
  name: 'invoice',
  initialState: {
    currentInvoiceId: null,
    compareTableInvoiceId: null,
    comparisonTableColumns: COMPARISON_TABLE_COLUMNS,
    currentPanels: [INVOICE_PANELS.TICKETS],
    currentSortOptions: [],
    displaySummary: true,
    filters: defaultModuleFilters.invoice,
    isFormFieldOpen: false,
    isShowInvoice: false,
    loading: true,
    matchItems: [],
    matchSelected: null,
    compareInvoiceControl: {
      page: 1,
      perPage: 24,
      totalCount: 0,
      searchWord: '',
      source: 'Invoice',
    },
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
    },
    // For when/if we make the switch to redux
    updateCompareInvoiceControl: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.compareInvoiceControl[key] = value
      }
    },
    updateComparisonTableColumns: (state, action) => {
      state.comparisonTableColumns = action.payload
    },
  },
})

export default invoiceUiSlice
