import { useSelector } from 'react-redux'

import { IonLabel, IonItem, IonChip, IonIcon } from '@ionic/react'
import { Button } from '~/components/shared'

import { addOutline, closeCircleOutline, pencilOutline } from 'ionicons/icons'

import Iteminfo from '~/components/driver/load/ItemInfo'
import { selectCompanyTimeZone } from '~/redux/selectors'
import {
  formatDateFromISO,
  DATE_TIME_FORMAT_TOOLTIP,
} from '~/utils/convertDate'
import { useCallback } from 'react'
import { Unless } from 'react-if'

function ImageThumbnail({
  image,
  name,
  timestamp,
  onSubmit,
  isHiddenSubmitButton,
}) {
  const companyTimezone = useSelector(selectCompanyTimeZone)

  const handleSubmit = useCallback(
    event => {
      onSubmit && onSubmit(event, { isEditing: Boolean(image) })
    },
    [image, onSubmit],
  )

  return (
    <>
      <IonItem>
        <Iteminfo value={image} />
        <IonLabel>
          <h2>
            <b>{name}</b>
          </h2>
          {timestamp && (
            <div>
              <p>
                {formatDateFromISO(
                  timestamp,
                  DATE_TIME_FORMAT_TOOLTIP,
                  companyTimezone,
                )}
              </p>
            </div>
          )}
          {!timestamp && (
            <IonChip size='small' color='danger'>
              <IonIcon icon={closeCircleOutline} />
              <IonLabel>Incomplete</IonLabel>
            </IonChip>
          )}
        </IonLabel>
        <Unless condition={isHiddenSubmitButton}>
          <Button
            slot='end'
            size='small'
            color={image ? 'warning' : 'concord'}
            onClick={handleSubmit}
            icon={image ? pencilOutline : addOutline}
          />
        </Unless>
      </IonItem>
    </>
  )
}

export default ImageThumbnail
