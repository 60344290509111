import { useCallback, useMemo } from 'react'

import { Page, View, Document, StyleSheet, Text } from '@react-pdf/renderer'
import { PDFDataTable } from '~/components/shared'

import { formatCurrencyToDollar } from '~/utils/formatCurrency'

import {
  getArData,
  getDueDateDays,
  getTotal,
} from '~/containers/AccountsReceivableContainer/Table/utils'

const styles = StyleSheet.create({
  documentContainer: {
    padding: 8,
  },
  tableContainer: {
    marginTop: 8,
  },
  invoiceItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
  },
  invoiceLabel: {
    width: 70,
    textAlign: 'right',
    marginRight: 8,
  },
})

function PDFArTable(props) {
  const { customerInvoices, companies, terminals, asOfDate, view } = props

  const data = useMemo(
    () => getArData(customerInvoices, view),
    [customerInvoices, view],
  )

  const getCustomer = useCallback(
    customerId => {
      return companies[customerId]
    },
    [companies],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Customer',
        accessor: 'customerId',
        width: 100,
        valueGetter({ rowData }) {
          if (rowData.isTerminal) {
            return terminals[rowData.customerId]?.name || '-'
          }
          if (rowData.isInvoice) {
            return rowData.invoice?.num || rowData.invoice.id || '-'
          }
          const customer = getCustomer(rowData.customerId)
          return customer?.name
        },
        cellRenderer({ cellData, rowData }) {
          if (rowData.isTerminal || rowData.isInvoice) {
            const color = rowData.isTerminal ? 'green' : 'blue'
            return <Text style={{ marginLeft: 12, color }}>{cellData}</Text>
          }

          return <Text>{cellData}</Text>
        },
      },
      {
        Header: 'Total',
        accessor: 'total',
        width: 80,
        align: 'right',
        valueGetter({ rowData }) {
          const result = rowData.invoices?.reduce(
            (acc, invoice) => acc + parseFloat(invoice.total),
            0,
          )
          return formatCurrencyToDollar.format(result)
        },
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        width: 80,
        align: 'right',
        valueGetter({ rowData }) {
          const result = rowData.invoices?.reduce(
            (acc, invoice) => acc + parseFloat(invoice.balance),
            0,
          )
          return formatCurrencyToDollar.format(result)
        },
      },
      {
        Header: '< 30 Days Overdue',
        accessor: 'dueDayBefore',
        width: 125,
        align: 'center',
        cellRenderer({ rowData }) {
          const [arr1, arr2] = getDueDateDays(rowData, asOfDate)
          const days = [
            {
              label: 'Not yet Due:',
              value: getTotal(arr1),
            },
            {
              label: '1 to 30:',
              value: getTotal(arr2),
            },
          ]

          return (
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {days.map(({ label, value }) => (
                <View style={styles.invoiceItem} key={label}>
                  <Text style={styles.invoiceLabel}>{label} </Text>
                  <Text>{formatCurrencyToDollar.format(value)}</Text>
                </View>
              ))}
            </View>
          )
        },
      },
      {
        Header: '> 30 Days Overdue',
        accessor: 'dueDayAfter',
        width: 125,
        align: 'center',
        cellRenderer({ rowData }) {
          const [, , arr3, arr4, arr5] = getDueDateDays(rowData)
          const days = [
            {
              label: '31 to 60:',
              value: getTotal(arr3),
            },
            {
              label: '61 to 90:',
              value: getTotal(arr4),
            },
            {
              label: '90+:',
              value: getTotal(arr5),
            },
          ]
          return (
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {days.map(({ label, value }) => (
                <View style={styles.invoiceItem} key={label}>
                  <Text style={styles.invoiceLabel}>{label} </Text>
                  <Text>{formatCurrencyToDollar.format(value)}</Text>
                </View>
              ))}
            </View>
          )
        },
      },
      {
        Header: 'Payments',
        accessor: 'payments',
        width: 80,
        align: 'right',
        valueGetter({ rowData }) {
          const result = rowData.invoices?.reduce(
            (acc, invoice) =>
              acc + parseFloat(invoice.total) - parseFloat(invoice.balance),
            0,
          )
          return formatCurrencyToDollar.format(result)
        },
      },
    ],
    [asOfDate, getCustomer, terminals],
  )

  return (
    <Document>
      <Page size='A4' style={styles.documentContainer}>
        <View>
          <PDFDataTable
            styles={styles.tableContainer}
            columns={columns}
            data={data}
            itemHeight={55}
          />
        </View>
      </Page>
    </Document>
  )
}

PDFArTable.propTypes = {}

export default PDFArTable
