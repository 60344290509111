import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import WorkPayRuleForm from './WorkPayRuleForm'

import './styles.scss'

function DialogWorkPayRuleForm(props) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    workPayRuleOptions,
    workPayTypeId,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Work Pay Rule'
      isHiddenOkButton
      onClose={onClose}
    >
      <WorkPayRuleForm
        formData={formData}
        workPayRuleOptions={workPayRuleOptions}
        workPayTypeId={workPayTypeId}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
export default DialogWorkPayRuleForm
