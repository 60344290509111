import * as Yup from 'yup'

const schema = Yup.object({
  sellerSideType: Yup.string()
    .required('This field is required!')
    .typeError('This field is required!'),
  sellerSideId: Yup.number()
    .required('This field is required!')
    .typeError('This field is required!'),
  buyerSideType: Yup.string()
    .required('This field is required!')
    .typeError('This field is required!'),
  buyerSideId: Yup.number()
    .required('This field is required!')
    .typeError('This field is required!'),
  allowedHierarchyId: Yup.number()
    .required('This field is required!')
    .typeError('This field is required!'),
})

export default schema
