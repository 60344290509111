import moment from 'moment'

const getStartDateInMonthToToday = () => {
  const startDate = moment().startOf('month').toDate()
  const endDate = moment().toDate()

  return {
    startDate,
    endDate,
  }
}

export default getStartDateInMonthToToday
