import { useCallback } from 'react'
import {
  IonIcon,
  IonButton,
  IonReorderGroup,
  IonReorder,
  IonRow,
  IonCol,
  IonBadge,
  IonCheckbox,
} from '@ionic/react'
import { arrowUp, arrowDown } from 'ionicons/icons'
import {
  getSortFieldAndIndex,
  reorderSortField,
  enableSortField,
} from '~/utils/sortTables'

import clsx from 'clsx'
import _ from 'lodash'

import './ReorderCheckbox.scss'

const ReorderCheckbox = props => {
  const {
    canReorder = true,
    className,
    containerStyle,
    hasDirection,
    isCheckedKey,
    onSortChange,
    sortOptions = [],
    hideCheckbox,
  } = props

  const _onReorder = useCallback(
    e => {
      const sortField = _.get(sortOptions, e.detail.from)
      let value = e.detail.complete([...sortOptions])

      onSortChange(value, sortField)
    },
    [sortOptions, onSortChange],
  )

  const _onHandleChange = useCallback(
    ({ detail: { value } }) => {
      const sortField = _.find(sortOptions, { sortField: value })
      const newOptions = enableSortField(sortOptions, value, isCheckedKey)

      onSortChange(newOptions, sortField)
    },
    [sortOptions, onSortChange, isCheckedKey],
  )

  const _onHandleChangeIcon = useCallback(
    value => {
      const sortField = _.find(sortOptions, { sortField: value })
      const newOptions = reorderSortField(sortOptions, value)

      onSortChange(newOptions, sortField)
    },
    [sortOptions, onSortChange],
  )

  return (
    <IonReorderGroup
      disabled={false}
      onIonItemReorder={_onReorder}
      style={containerStyle}
      className={clsx('ReorderCheckbox__container', className)}
    >
      {sortOptions
        .filter(option => option && option)
        .map((column, index) => {
          const sort = getSortFieldAndIndex(
            sortOptions,
            column.sortField,
            isCheckedKey,
          )
          return (
            <IonRow key={index} className='ReorderCheckbox__row'>
              <IonCol className='p-0'>
                <div className='d-flex align-items-center justify-content-between'>
                  {!hideCheckbox && (
                    <IonCheckbox
                      labelPlacement='end'
                      justify='start'
                      value={column.sortField || column.columnName}
                      checked={column[isCheckedKey]}
                      style={{ marginLeft: 10, textTransform: 'capitalize' }}
                      onIonChange={_onHandleChange}
                    >
                      {column.label}
                    </IonCheckbox>
                  )}
                  {column.render && column.render()}

                  {hasDirection && (
                    <IonButton
                      fill='clear'
                      onClick={() => _onHandleChangeIcon(column.sortField)}
                    >
                      <IonIcon icon={column.isAsc ? arrowUp : arrowDown} />
                    </IonButton>
                  )}

                  {canReorder && column[isCheckedKey] && (
                    <div className='d-flex align-items-center'>
                      <IonBadge color='dark'>{sort.index + 1}</IonBadge>
                      <IonReorder />
                    </div>
                  )}
                </div>
              </IonCol>
            </IonRow>
          )
        })}
    </IonReorderGroup>
  )
}

ReorderCheckbox.defaultProps = {
  isCheckedKey: 'sorted',
}

export default ReorderCheckbox
