import React, { useEffect, useState, useCallback } from 'react'
import {
  IonIcon,
  IonButton,
  IonModal,
  IonToggle,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonRow,
  IonCol,
  IonContent,
  IonButtons,
} from '@ionic/react'
import { browsersOutline, exitOutline } from 'ionicons/icons'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import {
  ImageViewer,
  PdfPageSelector,
  ZoomButton,
  ButlerBlock,
} from '~/components/shared'
import usePdfPageSelector from '~/hooks/usePdfPageSelector'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import './PdfViewer.scss'

const PdfViewer = ({
  value,
  document,
  showPopOver,
  setShowPopOver,
  onOpenNewWindow,
  onOpenNewTab,
  imageProps,
  documentId,
  modelId,
}) => {
  const [multiplePdf, setMultiplePdf] = useState(true)

  const {
    setPageNumber,
    setNumPages,
    numPages,
    pageNumber,
    onBlur,
    onChange,
    onKeyPress,
    incrementPage,
    decrementPage,
  } = usePdfPageSelector()

  const [scale, setScale] = useState(1.5)
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  useEffect(() => {
    setPageNumber(1)
  }, [document, setPageNumber])

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages)
    },
    [setNumPages],
  )

  const onToggleClick = useCallback(() => {
    setMultiplePdf(!multiplePdf)
  }, [multiplePdf])

  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const onClosePopOver = useCallback(
    () => setShowPopOver(false),
    [setShowPopOver],
  )
  const upScale = useCallback(() => setScale(scale + 0.1), [scale])
  const downScale = useCallback(() => setScale(scale - 0.1), [scale])

  const renderPdfViewer = useCallback(() => {
    if (documentId && modelId) {
      return (
        <ButlerBlock
          modelId={modelId}
          documentId={documentId}
          className='PdfViewer__butlerBlock'
        />
      )
    }

    if (multiplePdf) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <IonToggle
              checked={!multiplePdf}
              style={{ padding: 5 }}
              onClick={onToggleClick}
            />
            <IonLabel style={{ fontSize: '15px' }}>Toggle Viewer</IonLabel>
          </div>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`}
          >
            <Viewer
              fileUrl={document}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </>
      )
    }

    return (
      <>
        <IonHeader translucent>
          <IonToolbar color='dark'>
            <IonRow className='ion-align-items-center'>
              <IonCol className='PdfViewer__controlPageContainer'>
                <PdfPageSelector
                  numPages={numPages}
                  pageNumber={pageNumber}
                  onBackPress={decrementPage}
                  onForwardPress={incrementPage}
                  onKeyPress={onKeyPress}
                  onInputBlur={onBlur}
                  onInputChange={onChange}
                />

                <div className='PdfViewer__divider' />

                <ZoomButton
                  onZoomIn={upScale}
                  onZoomOut={downScale}
                  value={scale.toFixed(1)}
                  zoomInButtonProps={{ disabled: scale > 2 }}
                  zoomOutButtonProps={{ disabled: scale < 0.5 }}
                />
              </IonCol>

              <IonCol className='ion-text-center'>
                <IonButtons style={{ float: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <IonToggle
                      checked={!multiplePdf}
                      style={{ padding: 5 }}
                      onClick={onToggleClick}
                    />
                    <IonLabel style={{ fontSize: '15px' }}>
                      Toggle Viewer
                    </IonLabel>
                  </div>
                  <IonButton
                    fill='solid'
                    color='primary'
                    onClick={onOpenNewTab}
                  >
                    <IonIcon icon={exitOutline} />
                  </IonButton>
                  <IonButton
                    fill='solid'
                    color='primary'
                    onClick={onOpenNewWindow}
                  >
                    <IonIcon icon={browsersOutline} />
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollX={true} scrollY={true}>
          <div className='PdfViewerDisplay'>
            <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
              <Page scale={scale} pageNumber={pageNumber} />
            </Document>
          </div>
        </IonContent>
      </>
    )
  }, [
    decrementPage,
    defaultLayoutPluginInstance,
    document,
    documentId,
    downScale,
    incrementPage,
    modelId,
    multiplePdf,
    numPages,
    onBlur,
    onChange,
    onDocumentLoadSuccess,
    onKeyPress,
    onOpenNewTab,
    onOpenNewWindow,
    onToggleClick,
    pageNumber,
    scale,
    upScale,
  ])

  return (
    <div>
      <ImageViewer
        imageProps={imageProps}
        isOpen={showPopOver && !value}
        onHidePreview={onClosePopOver}
        image={document}
      />
      <IonModal
        isOpen={showPopOver && value}
        canDismiss={true}
        onDidDismiss={onClosePopOver}
        className='PdfPreviewPopup'
      >
        {renderPdfViewer()}
      </IonModal>
    </div>
  )
}

PdfViewer.defaultProps = {
  imageProps: {},
}

export default PdfViewer
