import React from 'react'
import { IonIcon, IonLabel } from '@ionic/react'
import { flag } from 'ionicons/icons'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const styles = {
  popover: {
    maxWidth: 500,
    fontSize: '12px',
  },
}

const TooltipIcon = props => {
  const {
    iconStyle,
    icon,
    tooltipPlacement,
    toolTip,
    slot,
    onShowBillLineFlagModal,
    containerStyle,
    children,
    count,
  } = props

  const popover = (
    <Popover id='popover-basic' style={styles.popover}>
      <Popover.Header as='h3'>{`${count || 0} flags`}</Popover.Header>
      <Popover.Body style={styles.popover}>{toolTip}</Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger placement={tooltipPlacement} overlay={popover}>
      {children || (
        <div style={containerStyle}>
          <IonLabel onClick={onShowBillLineFlagModal}>
            <IonIcon
              slot={slot}
              style={iconStyle}
              icon={icon}
              className={'icon-inner'}
            />
          </IonLabel>
        </div>
      )}
    </OverlayTrigger>
  )
}

// TooltipIcon.propTypes = {
//   iconStyle: PropTypes.object,
//   icon: PropTypes.string,
//   tooltipPlacement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
//   toolTip: PropTypes.string,
//   onShowBillLineFlagModal: PropTypes.func,
//   billLine: PropTypes.object,
//   containerStyle: PropTypes.object,
// }

TooltipIcon.defaultProps = {
  icon: flag,
  tooltipPlacement: 'right',
}

export default TooltipIcon
