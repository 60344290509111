import { useSelector } from 'react-redux'
import { useQuery, UseQueryOptions } from 'react-query'
import { useMemo } from 'react'
import { useQueryCheckNetPaySplits } from '../useQueryCheckNetPaySplits'

import { selectDriverDetails } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import _ from 'lodash'

import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type {
  ICheckBankAccount,
  ICheckBankAccountWithPaySplit,
} from '~/types/models/ICheck'
import useModifyCheckBankAccounts from './useModifyCheckBankAccounts'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCheckBankAccounts = (
  options?: Partial<UseQueryOptions<ICheckBankAccount[]>>,
) => {
  const driverDetails: IDriverFleet | null = useSelector(selectDriverDetails)

  const {
    checkNetPaySplitsData,
    isLoadingCheckNetPaySplitsData,
    addCheckNetPaySplits,
  } = useQueryCheckNetPaySplits()

  const { addCheckBankAccount, deleteCheckBankAccount } =
    useModifyCheckBankAccounts()

  const workerUid = driverDetails?.worker.checkUid

  const { data, isLoading } = useQuery({
    queryKey: ['checkBankAccounts', workerUid],
    async queryFn() {
      const response = await apiClient.check.getBankAccounts({
        bankAccount: {
          employee: workerUid as string,
        },
      })
      return response.results
    },
    enabled: Boolean(workerUid),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const checkBankAccountsData = useMemo(() => data || [], [data])

  const ownCheckBankAccounts = useMemo(
    () =>
      checkBankAccountsData.filter(({ employee }) => employee === workerUid),
    [checkBankAccountsData, workerUid],
  )

  const checkBankAccountsWithPaySplit = useMemo<
    ICheckBankAccountWithPaySplit[]
  >(() => {
    const [firstNetPaySplit] = checkNetPaySplitsData
    if (firstNetPaySplit?.splits) {
      const mapData = ownCheckBankAccounts.map(bank => {
        const split = firstNetPaySplit.splits.find(
          ({ bankAccount }) => bankAccount === bank.id,
        )
        return { ...bank, split }
      })
      return _.orderBy(mapData, ['split.priority'], 'asc').map(
        (data, index) => ({
          ...data,
          index: index + 1,
        }),
      )
    }

    return ownCheckBankAccounts
  }, [checkNetPaySplitsData, ownCheckBankAccounts])

  return {
    checkBankAccountsData,
    isLoadingCheckBankAccountsData: isLoading,
    ownCheckBankAccounts,
    checkBankAccountsWithPaySplit,
    isLoadingCheckNetPaySplitsData,
    addCheckNetPaySplits,
    addCheckBankAccount,
    deleteCheckBankAccount,
  }
}

export default useQueryCheckBankAccounts
