import { CommonDialogV2 } from '~/components/shared'
import DataParserForm from './DataParserForm'

import type { IDialogDataParserFormProps } from './type'

function DialogDataParserForm(props: IDialogDataParserFormProps) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props
  return (
    <CommonDialogV2 {...dialogProps} title='Data Parser' isHiddenOkButton>
      <DataParserForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
    </CommonDialogV2>
  )
}
export default DialogDataParserForm
