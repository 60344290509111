import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSavedFiltersFromStorage } from '~/utils/translateSavedFilters'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import DEFAULT_SELLER_TO_BUYER_TERMINALS_COLUMNS from '~/constants/sellerToBuyerTerminals/columns'

import type {
  ISellerToBuyerTerminalReduxActUpdateUiColumn,
  ISellerToBuyerTerminalReduxActUpdateUiColumns,
  ISellerToBuyerTerminalReduxActUpdateUiFilter,
  ISellerToBuyerTerminalReduxUiSlice,
  ISellerToBuyerTerminalsReduxActUpdateUiPayload,
} from '~/types/models/ISellerToBuyerTerminal'

const savedFilters = getSavedFiltersFromStorage({
  moduleName: 'sellerToBuyerTerminals',
  defaultValues: defaultModuleFilters.sellerToBuyerTerminals,
})

export const sellerToBuyerTerminalsUiSlice = createSlice({
  name: 'sellerToBuyerTerminals',
  initialState: {
    columns: DEFAULT_SELLER_TO_BUYER_TERMINALS_COLUMNS,
    filters: savedFilters,
    initialLoad: true,
  } as ISellerToBuyerTerminalReduxUiSlice,
  reducers: {
    updateUi: (
      state,
      {
        payload,
      }: PayloadAction<ISellerToBuyerTerminalsReduxActUpdateUiPayload>,
    ) => {
      for (const [key, value] of Object.entries(payload)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-semi
        ;(state as any)[`${key}`] = value
      }
    },

    updateUiColumn: (
      state,
      { payload }: PayloadAction<ISellerToBuyerTerminalReduxActUpdateUiColumn>,
    ) => {
      let { column } = payload
      let updateIndex = state.columns.findIndex(
        columnState => columnState.columnName == column.columnName,
      )
      state.columns[updateIndex] = {
        ...state.columns[updateIndex],
        ...column,
      }
    },
    updateUiColumns: (
      state,
      { payload }: PayloadAction<ISellerToBuyerTerminalReduxActUpdateUiColumns>,
    ) => {
      payload.forEach(column => {
        let updateIndex = state.columns.findIndex(
          columnState => column.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state.columns.push({ ...column })

          return
        }

        state.columns[updateIndex] = {
          ...state.columns[updateIndex],
          ...column,
        }
      })
    },
    updateUiFilter: (
      state,
      { payload }: PayloadAction<ISellerToBuyerTerminalReduxActUpdateUiFilter>,
    ) => {
      for (const [key, value] of Object.entries(payload)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-semi
        ;(state.filters as any)[`${key}`] = value
      }
      state.initialLoad = false
    },
  },
})
