import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { RTToggleRowActionMenuButton } from '../../components/buttons/RTToggleRowActionMenuButton'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'

export const getRTRowActionsColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  return {
    Cell: ({ cell, row, staticRowIndex, table }) => (
      <RTToggleRowActionMenuButton
        cell={cell}
        row={row}
        staticRowIndex={staticRowIndex}
        table={table}
      />
    ),
    ...defaultDisplayColumnProps({
      header: '',
      id: ERTDisplayColumnId.actions,
      size: 40,
      tableOptions,
    }),
  }
}
