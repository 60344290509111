export enum EFixedPrice {
  materials = 2,
  delivery = 1,
  material_delivery = 3,
}

export enum EFixedPricePricedProductType {
  BuyerTerminalFromSellerTerminalProduct = 'BuyerTerminalFromSellerTerminalProduct',
  SellerProduct = 'SellerProduct',
}
