import useCheckComponentsForWorker from './useCheckComponentsForWorker'

import { CheckWorkerInfoSection } from '../CheckWorkerInfoSection'
import { CommonTab } from '~/components/shared'
import { CollectEmployeeInfo } from '../CollectEmployeeInfo'
import { ReciprocityForm } from '../ReciprocityForm'

import renderCheckSections from '~/containers/PayrollsContainer/utils/renderCheckSections'

import type { ICheckComponentsForWorkerProps } from './type'
import { ToggleSection } from '../../atom'
import { When } from 'react-if'
import { isStartWithEmp } from '~/utils/constants'
import TableEarningRates from '~/containers/CompanySubTabs/EarningRates/TableEarningRates'
import { EScope } from '~/types/enums/ECommonEnum'

function CheckComponentsForWorker(props: ICheckComponentsForWorkerProps) {
  const {
    isEmployee,
    workerUid,
    onboardingEmployeeComponents,
    ongoingEmployeeComponents,
    currentTab,
    onSelectStep,
    onChangeTab,
    currentScope,
    style,
  } = useCheckComponentsForWorker(props)

  return (
    <div style={{ marginTop: 12, ...style }}>
      <CheckWorkerInfoSection
        checkUid={workerUid}
        onSelectStep={onSelectStep}
      />
      <CommonTab
        currentTab={currentTab}
        onChangeTab={onChangeTab}
        tabs={[
          {
            name: 'onboarding',
            label: 'Onboarding',
            render() {
              return (
                <div>
                  {renderCheckSections(onboardingEmployeeComponents, workerUid)}
                </div>
              )
            },
          },
          {
            name: 'ongoing',
            label: 'Ongoing',
            isHidden: !isEmployee,
            render() {
              return (
                <div>
                  {renderCheckSections(ongoingEmployeeComponents, workerUid)}
                  <When
                    condition={
                      isStartWithEmp(workerUid) &&
                      currentScope !== EScope.driverFleet
                    }
                  >
                    <ToggleSection title='Employee Earning Rates'>
                      <TableEarningRates employeeUid={workerUid} />
                    </ToggleSection>
                  </When>
                  <ToggleSection title='Company-defined attributes'>
                    <CollectEmployeeInfo workerUid={workerUid} />
                  </ToggleSection>
                  <ToggleSection title='Reciprocity Elections'>
                    <ReciprocityForm workerUid={workerUid} />
                  </ToggleSection>
                </div>
              )
            },
          },
        ]}
      />
    </div>
  )
}
export default CheckComponentsForWorker
