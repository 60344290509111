import { useController } from 'react-hook-form'

import PropTypes from 'prop-types'

import { ConcordFormDateRangePicker } from '~/components/shared'

function ConcordDateRangePickerWithFormControl(props) {
  const { name, control, ...datePickerProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  return (
    <ConcordFormDateRangePicker
      {...datePickerProps}
      name={name}
      date={field.value || {}}
      onChange={selectedDate => {
        field.onChange(selectedDate)
      }}
      error={fieldState.error?.message}
    />
  )
}

ConcordDateRangePickerWithFormControl.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

ConcordDateRangePickerWithFormControl.defaultProps = {
  portalId: 'root-portal',
}

export default ConcordDateRangePickerWithFormControl
