export const types = {
  driver: 'Driver',
  company: 'Company',
  client: 'Client',
  fleet: 'Fleet',
}

export const scopeTypes = {
  fleet: 'fleet',
  seller: 'seller',
  buyer: 'buyer',
  driver: 'driver',
}
