import {
  mailUnreadOutline,
  callOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  pauseCircleOutline,
  playCircleOutline,
  lockClosedOutline,
} from 'ionicons/icons'
import moment from 'moment'

// defaultForm values
export const DEFAULT_ORDERS_FILTERS = {
  buyerId: [],
  buyerTerminalId: [],
  sellerId: [],
  sellerTerminalId: [],
  qty: undefined,
  lds: undefined,
  startTime: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  status: [],
}

export const DEFAULT_ORDER_TRACKING_FILTER = {
  startTime: {
    startDate: new Date(),
    endDate: new Date(),
  },
  buyerTerminalId: [],
  sellerTerminalId: [],
  fleetId: [],
  sellerId: [],
  buyerId: [],
}

export const viewOptions = [
  { name: 'List', value: 'list' },
  { name: 'Grid', value: 'grid' },
]

export const ORDER_STATUSES = {
  Requested: {
    icon: mailUnreadOutline,
    color: 'secondary',
  },
  'Will Call': {
    icon: callOutline,
    color: 'info',
  },
  Confirmed: {
    icon: lockClosedOutline,
    color: 'primary',
  },
  Active: {
    icon: playCircleOutline,
    color: 'success',
  },
  Hold: {
    icon: pauseCircleOutline,
    color: 'warning',
  },
  Complete: {
    icon: checkmarkCircleOutline,
    color: 'dark',
  },
  Canceled: {
    icon: closeCircleOutline,
    color: 'danger',
  },
}
