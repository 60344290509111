export const errorStyle = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
}

export const warningStyle = {
  control: base => ({
    ...base,
    borderColor: 'yellow',
  }),
}

export const menuWidthStyle = {
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  }),
}
