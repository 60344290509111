import { isExpiredAWSLink } from './utils'
import { apiClient } from '~/api/ApiClient'

const Model = {
  Document: 'Document',
  UploadedFile: 'UploadedFile',
  Load: 'Load',
}

export const getDocumentURL = async (document, model = Model.Document) => {
  if (!isExpiredAWSLink(document.url)) return document.url
  const newDocument = await apiClient.url.getById(
    document.id,
    {
      model
    }
  )

  return newDocument.url
}

export const getInvoiceURL = async (id, url) => {
  if (!isExpiredAWSLink(url)) return url
  const newInvoice = await apiClient.invoices.refresh(id)
  if (!newInvoice.invoice) return url

  return newInvoice.invoice.invoiceProve
}
