import _ from 'lodash'

const buildFullName = (user, defaultText = 'Unknown') => {
  try {
    const firstName = user.firstName || ''
    // const middleName = user.middleName || ''
    const lastName = user.lastName || ''

    if (_.isEmpty(firstName) && _.isEmpty(lastName)) return '-'
    return `${firstName} ${lastName}`.trim()
  } catch (error) {
    return defaultText
  }
}

export default buildFullName
