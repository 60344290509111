import { CompanyView } from '../CompanyView'
import { CommonDialogV2 } from '~/components/shared'

import type { IDialogCompanyViewProps } from './type'

import './styles.scss'

function DialogCompanyView(props: IDialogCompanyViewProps) {
  const { view, onClickSubpage, onGoBack, ...dialogProps } = props

  return (
    <CommonDialogV2 {...dialogProps}>
      <CompanyView
        view={view}
        onClickSubpage={onClickSubpage}
        onGoBack={onGoBack}
        isShowCreateButton
      />
    </CommonDialogV2>
  )
}
export default DialogCompanyView
