function buildNestedObject(
  obj: { [key: string]: any },
  skipCreateEmptyArray: boolean = false,
): Record<string, any> {
  const result: Record<string, any> = {}

  Object.keys(obj).forEach(key => {
    const value = obj[key]

    // If skipCreateEmptyArray is true and value is an empty array, skip the key
    if (skipCreateEmptyArray && Array.isArray(value) && value.length === 0) {
      return // Skip this key since the array is empty
    }

    const keys = key.split('.') // Split key by dots

    keys.reduce((acc: Record<string, any>, curKey: string, index: number) => {
      // If at the last key, assign the value
      if (index === keys.length - 1) {
        acc[curKey] = value
      } else {
        // Create nested objects if they don't exist
        if (!acc[curKey]) {
          acc[curKey] = {}
        }
      }
      return acc[curKey] // Move deeper into the object
    }, result)
  })

  return result
}

export default buildNestedObject
