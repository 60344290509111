import { useMemo } from 'react'

import type { IRTColumnDef } from '~/components/shared'

import moment from 'moment'
import { COMPANY_PAY_FREQUENCY_OPTIONS } from '~/utils/constants'

const TablePaySchedules = () => {
  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        size: 300,
      },
      {
        header: 'Pay Frequency',
        accessorKey: 'payFrequency',
        Cell({ cell }) {
          const cellValue = cell.getValue()
          return COMPANY_PAY_FREQUENCY_OPTIONS.find(
            ({ value }) => value === cellValue,
          )?.label
        },
      },
      {
        header: 'First Pay Day',
        accessorKey: 'firstPayday',
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          return moment(cellValue).format('DD-MM-YYYY')
        },
      },
      {
        header: 'First Period End',
        accessorKey: 'firstPeriodEnd',
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          return moment(cellValue).format('DD-MM-YYYY')
        },
      },
    ],
    [],
  )

  return { columns }
}

export default TablePaySchedules
