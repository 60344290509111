import React, { useCallback, useState, useEffect } from 'react'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import PropTypes from 'prop-types'

const SimpleDatePicker = props => {
  const {
    pickerStyle,
    style,
    editable = true,
    value,
    onChange,
    clearIcon = null,
    calendarIcon = null,
    titleFormat = 'ddd, MMM DD, YYYY',
    limittedYears,
    isLoadForm,
    placement,
    onCalendarClose,
    onCalendarOpen,
    isOpen,
    dateFormat,
    maxDate,
    containerStyle,
    ...pickerProps
  } = props

  const [title, setTitle] = useState(
    value ? moment(value).format(titleFormat) : undefined,
  )
  const [invalidDate, setInvalidDate] = useState(false)
  const onChangeHandler = useCallback(
    value => {
      const date = moment(value).format('YYYY-MM-DD')
      onChange && onChange(date)
    },
    [onChange],
  )

  const onOpenChange = useCallback(() => {
    let date = new Date()
    date.setDate(date.getDate() - 1)
    onChange && onChange(date)
  }, [onChange])

  useEffect(() => {
    if (value) {
      const date = moment(value)
      setTitle(date.format(titleFormat))
      limittedYears &&
        setInvalidDate(moment().diff(date, 'years') >= limittedYears)
    }
  }, [value, titleFormat, limittedYears])

  return (
    <span style={invalidDate ? { color: 'red' } : containerStyle}>
      <OverlayTrigger
        placement={placement || 'right'}
        overlay={
          title ? (
            <Tooltip id='tooltip-bottom' className='tooltip'>
              {title}
            </Tooltip>
          ) : (
            <br />
          )
        }
      >
        <DatePicker
          disabled={!editable}
          onChange={onChangeHandler}
          value={Date.parse(value) ? moment(value).toDate() : null}
          clearIcon={clearIcon}
          onCalendarOpen={(isLoadForm && onOpenChange) || onCalendarOpen}
          calendarIcon={calendarIcon}
          style={style}
          onCalendarClose={onCalendarClose}
          isOpen={isOpen}
          format={dateFormat}
          maxDate={maxDate}
          {...pickerProps}
        />
      </OverlayTrigger>
    </span>
  )
}

SimpleDatePicker.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  titleFormat: PropTypes.string,
  onCalendarClose: PropTypes.func,
  onCalendarOpen: PropTypes.func,
  dateFormat: PropTypes.string,
  maxDate: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default SimpleDatePicker
