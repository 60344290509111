import React, { useState, useCallback, useEffect } from 'react'
import useDateWithTimezone from '~/hooks/useDateWithTimezone'

import DatePicker from 'react-datepicker'
import DatePickerTimeInput from './DatePickerTimeInput'

import PropTypes from 'prop-types'

const DatePickerWithTimeInput = React.forwardRef(
  ({ selected, onChange, format = 'MMM dd, yyyy', ...props }, ref) => {
    const { setISODateWithTimezone, setJsDateWithTimezone } =
      useDateWithTimezone()

    const [selectedDate, setSelectedDate] = useState(null)

    const handleChange = useCallback(
      (v, e) => {
        const value = v ? setJsDateWithTimezone(v) : null
        setSelectedDate(value)
        onChange && onChange(value, e)
      },
      [onChange, setJsDateWithTimezone],
    )

    useEffect(() => {
      setSelectedDate(selected)
    }, [selected])

    return (
      <DatePicker
        showTimeInput
        selected={selectedDate ? setISODateWithTimezone(selectedDate) : null}
        onChange={handleChange}
        dateFormat={format}
        portalId='root-portal'
        customTimeInput={<DatePickerTimeInput />}
        ref={ref}
        {...props}
      />
    )
  },
)

DatePickerWithTimeInput.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  showTimeInput: PropTypes.bool,
  dateFormat: PropTypes.string,
}

export default DatePickerWithTimeInput
