import { useState, useCallback, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { DropDownFormSelect } from '~/components/shared/'
import _ from 'lodash'
import DialogMenuOptions from './DialogMenuOptions'
import { apiClient } from '~/api/ApiClient'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import clsx from 'clsx'

const UserGroupForm = (props) => {
  const {
    formData,
    afterCreate,
    afterUpdate,
    isLoading: isLoadingProps,
    className
  } = props

  const [formState, setFormState] = useState({
    name: '',
    code: '',
    scopeType: '',
    menuOptions: [],
  })
  const [isLoading, setIsLoading] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)

  // const [scopedMenuData, setScopedMenuData] = useState()
  // const [checkedMenuOptions, setCheckedMenuOptions] = useState([])
  const [showFormError, setShowFormError] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const meta = useMemo(
    () => ({ touched: true, error: !showFormError }),
    [showFormError],
  )
  const defaultForm = useMemo(
    () => ({
      name: '',
      code: '',
      scopeType: '',
      menuOptions: [],
    }),
    [],
  )
  const defaultScopeTypes = useMemo(
    () => [
      {
        label: 'Company',
        value: 'Company',
      },
      {
        label: 'Fleet',
        value: 'Fleet',
      },
      {
        label: 'Driver',
        value: 'Driver',
      },
      {
        label: 'Admin',
        value: 'Admin',
      },
    ],
    [],
  )

  const mapMenuOptions = useMemo(() => {
    const { menuOptions } = formState

    return menuOptions.map(option => {
      const replaceDash = (option || '').toString().replace(/_/g, ' ')

      return _.upperFirst(replaceDash)
    })
  }, [formState])

  // set the state if in 'Edit' mode
  useEffect(() => {
    if (formData) {
      const { name, code, scopeType, menuOptions } = formData

      setFormState(() => {
        let newState = { name, code, scopeType }
        newState.menuOptions = [...menuOptions]

        return newState
      })
    }

    return () => {
      setFormState(defaultForm)
    }
  }, [formData, defaultForm])

  const handleInputChange = e => {
    const value = e.target.value
    const field = e.target.name

    setFormState({
      ...formState,
      [field]: value,
    })
  }

  const handleScopeChange = useCallback(
    value => {
      setFormState({
        ...formState,
        scopeType: value,
        menuOptions: [],
      })
    },
    [formState],
  )

  const handleSubmitButton = useCallback(
    e => {
      e.preventDefault()
      const isFormValid = validateUserGroupForm(formState)
      if (isFormValid) {
        submitUserGroup(formState)
        setIsLoading(false)
      }
    },
    [formState, validateUserGroupForm, submitUserGroup],
  )

  const validateUserGroupForm = useCallback(form => {
    if (form.name === 'Concord Admin' && form.code === 'ADMIN') {
      setShowFormError(true)

      return true
    } else if (!form.name || !form.code || !form.scopeType) {
      setShowFormError(false)
      toast.error(toastMessages.formError)

      return false
    }
    setShowFormError(true)

    return true
  }, [])

  const submitUserGroup = useCallback(
    async formValues => {
      setIsLoading(true)
      try {
        if (formData) {
          const response = await apiClient.userGroups.update(formData.id, formValues)
          // fastReloadTable('edit', formData.index, response)
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        } else {
          const createForm = Object.assign(formValues)
          const response = await apiClient.userGroups.create(createForm)
          if (_.size(response.errors) > 0) {
            toast.error(_.get(response, 'errors[0]'))
          } else {
            afterCreate && afterCreate(response)
            toast.success(toastMessages.createSuccess)
          }
        }
      } catch (error) {
        toast.error(toastMessages.updateError)
      } finally {
        setIsLoading(false)
      }


    },
    [formData, afterUpdate, afterCreate],
  )

  const handleModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleSetMenuOptions = useCallback(newMenuOptions => {
    setFormState(prev => ({
      ...prev,
      menuOptions: newMenuOptions,
    }))
  }, [])

  useEffect(() => {
    if (typeof isLoadingProps === 'boolean') {
      setIsLoading(isLoadingProps)
    }
  }, [isLoadingProps])

  return (
    <>
      <form className={clsx('my-company-form', className)}>
        <div className='my-company-form-field'>
          <label className='my-company-form-label'>User Group Name</label>
          <input
            className='my-company-form-input'
            name='name'
            value={formState.name}
            onChange={handleInputChange}
            style={{
              border: !showFormError && !formState.name ? '1px solid red' : '',
            }}
          />
          {!showFormError && !formState.name && (
            <span className='my-company-form-input-error'>
              You must include either the User Group name and User Group code.
            </span>
          )}
        </div>
        <div className='my-company-form-field'>
          <label className='my-company-form-label'>User Group Code</label>
          <input
            className='my-company-form-input'
            name='code'
            value={formState.code}
            onChange={handleInputChange}
            style={{
              border: !showFormError && !formState.code ? '1px solid red' : '',
            }}
          />
          {!showFormError && !formState.code && (
            <span className='my-company-form-input-error'>
              You must include either the User Group name and User Group code.
            </span>
          )}
        </div>
        <div className='my-company-select-field'>
          <DropDownFormSelect
            label='Scope Type'
            data={defaultScopeTypes}
            meta={meta}
            input={{ value: formState.scopeType }}
            onChange={handleScopeChange}
          />
          {!showFormError && !formState.scopeType && (
            <span className='my-company-form-input-error'>
              You must include either the Scope type.
            </span>
          )}
        </div>
        <div style={{ marginBottom: 8 }}>
          <label className='my-company-form-label'>
            {formState.scopeType
              ? 'Selected Menu Options: '
              : 'Choose a scope type - '}
          </label>
          {(formState.scopeType || formState.name == 'Concord Admin') && (
            <span
              style={{ fontSize: 14, color: '#3880ff', cursor: 'pointer' }}
              onClick={() => setShowModal(true)}
            >
              &nbsp; EDIT HERE
            </span>
          )}
          {mapMenuOptions.map((option, index) => (
            <p
              key={index}
              style={{ fontSize: 13, paddingLeft: 8, marginTop: 8 }}
            >
              {option}
            </p>
          ))}
        </div>
        <button
          className={clsx('my-company-btn-primary my-company-submit-button', { isDisabled: isLoading })}
          onClick={handleSubmitButton}
        >
          Submit User Group
        </button>
      </form>
      <DialogMenuOptions
        companyId={currentCompany?.id}
        formState={formState}
        isOpen={showModal}
        onClose={handleModalClose}
        onSubmit={handleSetMenuOptions}
      />
    </>
  )
}

UserGroupForm.defaultProps = {
  setShowData: () => { },
  setCurrentRowData: () => { },
  setLoading: () => { },
}

export default UserGroupForm
