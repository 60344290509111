import * as types from './actionTypes'
import userAccessesUiSlice from '~/redux/reducers/ui/userAccesses'
import { apiClient } from '~/api/ApiClient'

export const fetchUserTerminalAccesses = terminalAccesses => ({
  terminalAccesses,
  type: types.FETCH_USER_TERMINAL_ACCESSES,
})

export const fetchUserGroupsSuccess = userGroups => ({
  userGroups,
  type: types.FETCH_USER_GROUPS,
})

export const getUserGroups = () => dispatch => {
  apiClient.userGroups
    .get()
    .then(response => {
      dispatch(fetchUserGroupsSuccess(response.userGroups))
    })
    .catch(() => {
      dispatch(fetchUserGroupsSuccess([]))
    })
}

export const getUserTerminalAccesses = () => dispatch => {
  apiClient.terminalAccesses
    .get()
    .then(terminalAccesses => {
      dispatch(fetchUserTerminalAccesses(terminalAccesses))
    })
    .catch(() => {
      dispatch(fetchUserTerminalAccesses([]))
    })
}

export const resetReduxStore = () => dispatch => {
  dispatch(userAccessesUiSlice.actions.resetStore())
}
