import React, { useCallback } from 'react'
import { IonButton, IonCheckbox } from '@ionic/react'

import PropTypes from 'prop-types'

const Checkbox = ({ label, color, onChange, value, checked, disabled }) => {
  const onChecked = useCallback(() => {
    onChange && onChange(value)
  }, [onChange, value])

  return (
    <IonButton
      size='small'
      onClick={onChecked}
      color={color}
      style={{ fontWeight: 800 }}
      disabled={disabled}
      className='p-0'
      fill='clear'
    >
      <IonCheckbox
        value={value}
        checked={checked}
        labelPlacement='end'
        style={{ marginLeft: 10, textTransform: 'capitalize' }}
      >
        {label}
      </IonCheckbox>
    </IonButton>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  color: 'primary',
}

export default Checkbox
