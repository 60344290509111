import { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useTableCompanyParserColumnMappingSubTab from './useTableCompanyParserColumnMappingSubTab'
import { modelOptions } from '~/hooks/useFilterOptions'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogParserColumnMappingForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'

function CompanyParserColumnMappingSubTab() {
  const windowSize = useWindowSize()

  const currentCompany = useSelector(selectMyCurrentCompany)

  const [parserColumnMappingData, setParserColumnMappingData] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogForm, setDialogForm] = useState({
    isOpen: false,
    formData: null,
  })

  const [columnFilters, setColumnFilters] = useState([
    { id: 'companyId', value: [currentCompany.id] },
  ])

  const filterState = useMemo(() => {
    const filter = {}
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  const { confirmation } = useConfirmationProvider()

  const { isLoadingBuyerSellers, allCompanyWithCurrentCompanyOptions } =
    useQueryBuyerSellers()

  const isTableLoading = isLoadingBuyerSellers || loading

  const filterOptions = [
    {
      label: 'Company',
      field: 'companyId',
      options: allCompanyWithCurrentCompanyOptions,
      type: EFieldType.multipleSelect,
    },
    {
      label: 'Model',
      field: 'model',
      options: modelOptions,
      type: EFieldType.multipleSelect,
    },
  ]

  const getApiCall = useCallback(() => {
    return apiClient.parserColumnMappings.get({
      filters: filterState,
    })
  }, [filterState])

  const getParserColumnMappings = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setParserColumnMappingData(response.parserColumnMappings)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const onCloseForm = useCallback(() => {
    setDialogForm({
      isOpen: false,
      formData: null,
    })
  }, [])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete parser #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.parserColumnMappings.delete(
            rowData.id,
          )
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setParserColumnMappingData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setDialogForm({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const handleEditCell = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const formValues = { [column.id]: value }
      const { errors } = await apiClient.parserColumnMappings.update(
        row.original.id,
        {
          parserColumnMapping: formValues,
        },
      )
      if (_.size(errors) > 0) {
        toast.error(toastMessages.useCompanyInfoParser.updateSuccess)
      } else {
        setParserColumnMappingData(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === row.original.id)
            if (index > -1) {
              draft[index][column.id] = value
              toast.success(toastMessages.updateSuccess)
            } else {
              toast.error(`Cannot find record #${row.original.id}`)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const { columns } = useTableCompanyParserColumnMappingSubTab()

  useEffect(() => {
    getParserColumnMappings()
  }, [getParserColumnMappings])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={parserColumnMappingData}
          enableTopToolbar
          manualFiltering
          enableRowActions
          enableCompanyView
          filterOptions={filterOptions}
          companyViewProps={{
            name: moduleName.company.parserColumnMappings,
            description:
              "Manage the rules we use to map data columns on invoices to Concord's invoices.",
            onClickTopButton: () =>
              setDialogForm({
                isOpen: true,
                formData: null,
              }),
          }}
          tableHeight={windowSize.height - 220}
          onColumnFiltersChange={setColumnFilters}
          state={{
            isLoading: isTableLoading,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 80,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              tooltipProps: {
                placement: 'top',
                content: 'Edit',
              },
              onClick() {
                handleOpenUpdateForm(row.original)
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              color: 'danger',
              tooltipProps: {
                placement: 'top',
                content: 'Delete',
              },
              onClick() {
                handleDeleteRow(row.original)
              },
            },
          ]}
          onCellEditEnd={handleEditCell}
        />
      </div>
      <DialogParserColumnMappingForm
        isOpen={dialogForm.isOpen}
        formData={dialogForm.formData}
        onClose={onCloseForm}
        afterCreate={newData => {
          onCloseForm()
          setParserColumnMappingData(prev => [newData, ...prev])
        }}
        afterUpdate={newData => {
          onCloseForm()
          setParserColumnMappingData(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(({ id }) => id === newData.id)
              if (index !== -1) {
                draft[index] = newData
              }
            }),
          )
        }}
      />
    </>
  )
}

export default CompanyParserColumnMappingSubTab
