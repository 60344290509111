import { createSlice } from '@reduxjs/toolkit'

export const fieldValidationsSlice = createSlice({
  name: 'fieldValidations',
  initialState: {},
  reducers: {
    updateFieldValidations: (state, action) => {
      const newState = {}
      action.payload.forEach(fv => {
        newState[fv.id] = fv
      })
      return newState
    },
  },
})

export default fieldValidationsSlice
