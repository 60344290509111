var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { IconButton, makeStyles } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { IconCell } from 'common/dataTable/IconCell/IconCell';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        borderRight: "1px solid ".concat(theme.palette.divider),
    },
    Icon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    IconButton: {
        padding: 0,
        '&.Disabled': {
            opacity: 0.2,
        },
    },
}); });
/**
 * Reusable display component for rendering the last cell in
 * each row of the extracted table editor, which contains the delete
 * row icon.  Takes in a callback function to delete the row.
 * @param Props
 */
export var DeleteRowIconCell = function (_a) {
    var deleteRow = _a.deleteRow, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var classes = useStyles();
    return (_jsx(IconCell, __assign({ className: classes.Root }, { children: _jsx(IconButton, __assign({ onClick: deleteRow, className: clsx(classes.IconButton, {
                Disabled: disabled,
            }), disabled: disabled, "data-testid": "delete-row-btn" }, { children: _jsx(DeleteOutlined, { className: classes.Icon }) })) })));
};
