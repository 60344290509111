import React from 'react'
import { RTTableContainer } from '../RTTableContainer'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { RTTopToolbar } from '../../toolbar/RTTopToolbar'
import { RTCompanyToolbar } from '../../toolbar/RTCompanyToolbar'
import { RTBottomToolbar } from '../../toolbar/RTBottomToolbar/RTBottomToolbar'
import clsx from 'clsx'

export interface IRTPaperProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
  style?: React.CSSProperties
}

const RTPaper = <TData extends IReusableTableRowData>({
  table,
  ...rest
}: IRTPaperProps<TData>) => {
  const {
    getState,
    options: {
      enableTopToolbar,
      enableCompanyView,
      enableBottomToolbar,
      className,
    },
    refs: { tablePaperRef },
  } = table
  const { isFullScreen } = getState()

  return (
    <div
      {...rest}
      ref={(ref: HTMLDivElement) => {
        tablePaperRef.current = ref
        if ((rest as any)?.ref) {
          //@ts-ignore
          rest.ref.current = ref
        }
      }}
      style={{
        ...(isFullScreen
          ? {
              bottom: 0,
              height: '100dvh',
              left: 0,
              margin: 0,
              maxHeight: '100dvh',
              maxWidth: '100dvw',
              padding: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              width: '100dvw',
              zIndex: 999,
              overflow: 'hidden',
              backgroundImage: 'unset',
            }
          : {}),
        ...rest?.style,
      }}
      className={clsx('RTPaper__container', className)}
    >
      {enableCompanyView && <RTCompanyToolbar table={table} />}
      {enableTopToolbar && <RTTopToolbar table={table} />}
      <RTTableContainer table={table} />
      {enableBottomToolbar && <RTBottomToolbar table={table} />}
    </div>
  )
}

export default RTPaper
