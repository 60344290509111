import { useState, useCallback, useEffect, useMemo } from 'react'
import useTableCompanyReferenceNameSubTab from './useTableCompanyReferenceNameSubTab'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogReferenceNameForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryCompanies } from '~/hooks/useQueryData'

function CompanyReferenceNameSubTab() {
  const [referenceNamesData, setReferenceNamesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginationState, setPaginationState] = useState({
    pageIndex: 0,
    pageSize: 24,
  })
  const [rowCount, setRowCount] = useState(0)
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const [formData, setFormData] = useState({
    isOpen: false,
    formData: undefined,
  })

  const filterData = useMemo(() => {
    const filter = {}

    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const { isLoadingCompaniesData } = useQueryCompanies()

  const isTableLoading = isLoadingCompaniesData || loading

  const getApiCall = useCallback(() => {
    const filters = {
      ...filterData,
      searchWord: globalFilter,
    }
    const params = {
      filters: filters,
      page: paginationState.pageIndex + 1,
      perPage: paginationState.pageSize,
    }

    return apiClient.referenceNames.get(params)
  }, [
    filterData,
    globalFilter,
    paginationState.pageIndex,
    paginationState.pageSize,
  ])

  const getReferenceNameData = useCallback(async () => {
    setLoading(true)
    try {
      const { referenceNames, count } = await getApiCall()
      setReferenceNamesData(referenceNames)
      setRowCount(count)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to reference name #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.referenceNames.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setReferenceNamesData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const onCloseForm = useCallback(() => {
    setFormData({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id

      const { errors } = await apiClient.referenceNames.update(rowId, {
        referenceName: {
          [columnField]: value,
        },
      })
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        setReferenceNamesData(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === rowId)
            if (index > -1) {
              draft[index][columnField] = value
              toast.success(toastMessages.updateSuccess)
            } else {
              toast.error(`Cannot find record #${rowId}`)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const { columns } = useTableCompanyReferenceNameSubTab()

  useEffect(() => {
    getReferenceNameData()
  }, [getReferenceNameData])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={referenceNamesData}
          enableTopToolbar
          tableHeight={windowSize.height - 280}
          enableCompanyView
          enableRowActions
          enablePagination
          enableBottomToolbar
          manualPagination
          manualFiltering
          rowCount={rowCount}
          companyViewProps={{
            name: moduleName.company.referenceNames,
            description:
              'Map names from your buyer or seller that reference your data (e.g. terminals, products, etc)',
            onClickTopButton() {
              setFormData({
                isOpen: true,
                formData: undefined,
              })
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 85,
              maxSize: 85,
            },
          }}
          state={{
            isLoading: isTableLoading,
            pagination: paginationState,
            globalFilter,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => {
                setFormData({
                  isOpen: true,
                  formData: row.original,
                })
              },
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={onCellEditEnd}
          onPaginationChange={setPaginationState}
          onGlobalFilterChange={setGlobalFilter}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>
      <DialogReferenceNameForm isOpen={formData.isOpen} onClose={onCloseForm} />
    </>
  )
}

export default CompanyReferenceNameSubTab
