import { CommonDialogV2 } from '~/components/shared'
import UserForm from './UserForm'

import './DialogUserForm.scss'

const DialogUserForm = (props) => {
  const { afterCreate, afterUpdate, formData, isOpen, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      isOpen={isOpen}
      title="User"
      isHiddenOkButton
    >
      <UserForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogUserForm
