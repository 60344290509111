import { IonLabel, IonChip, IonBadge } from '@ionic/react'
import { CompanyAvatar } from '~/components/shared'

import './CompanyChip.scss'

const CompanyChip = ({
  company,
  onClickMethod,
  badgeCount = 0,
  type,
  method = 'name',
  override = '',
}) => {
  const companyLabel = (company, override) => {
    if (override != '') {
      return override
    } else if (method == 'name') {
      return company?.name
    } else if (method == 'code') {
      return company?.code
    } else {
      return ''
    }
  }

  return (
    <IonChip
      title={`${type} - ${company?.code} ${company?.name}`}
      onClick={onClickMethod}
      color={type}
      style={{ padding: '4px 8px' }}
    >
      <CompanyAvatar
        company={{
          value: company?.id,
          label: company?.name,
          name: company?.name,
          image: company?.logo
        }}
        companyType={type}
        width={25}
        height={25}
      />
      <IonLabel className='ms-2'>{companyLabel(company, override)}</IonLabel>
      {badgeCount > 0 && (
        <IonBadge style={{ marginLeft: '5px' }} color='danger'>
          {badgeCount}
        </IonBadge>
      )}
    </IonChip>
  )
}

export default CompanyChip
