import { useCallback, useState, useMemo } from 'react'
import { useQueryGlCodes, useQueryPaymentTypes } from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { IRTColumnDef, RTCell } from '~/components/shared'

import { EFieldType, EYesNo } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { PAYMENT_TYPE_OPTIONS } from '~/utils/constants'
import { IPaymentType } from '~/types/models/IPaymentType'
import { useWindowSize } from 'react-use'

const usePaymentTypesContainer = () => {
  const [isOpenDialogForm, setIsOpenDialogForm] = useState(false)
  const [selectedData, setSelectedData] =
    useState<IPaymentType | undefined>(undefined)

  const windowSize = useWindowSize()

  const { confirmation } = useConfirmationProvider()

  const {
    paymentTypes,
    addPaymentType,
    updatePaymentType,
    removePaymentType,
    isLoadingPaymentTypesData,
  } = useQueryPaymentTypes()

  const { glCodeOptions, isLoadingGlCodes } = useQueryGlCodes()

  const isLoadingTable = isLoadingGlCodes || isLoadingPaymentTypesData

  const onOpenEditDialogForm = useCallback(
    (rowData: IPaymentType) => () => {
      setIsOpenDialogForm(true)
      setSelectedData(rowData)
    },
    [],
  )

  const onRemove = useCallback(
    (rowData: IPaymentType) => async () => {
      const result = await confirmation({
        message: 'Are you sure you want to delete this data',
      })
      if (result === EYesNo.Yes) {
        apiClient.paymentTypes.delete(rowData.id)
        removePaymentType(rowData.id)
        toast.success(toastMessages.deleteSuccess)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onOpenCreateDialogForm = useCallback(() => {
    setIsOpenDialogForm(true)
  }, [])

  const onCloseDialogForm = useCallback(() => {
    setIsOpenDialogForm(false)
    setSelectedData(undefined)
  }, [])

  const afterCreate = useCallback(
    (newGlCode: IPaymentType) => {
      addPaymentType(newGlCode)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const afterUpdate = useCallback(
    (newGlCode: IPaymentType) => {
      updatePaymentType(newGlCode.id, newGlCode)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onCellEditEnd = useCallback(
    async (
      value: any,
      cell: RTCell<IPaymentType, unknown>,
      selectedOpt?: any,
    ) => {
      try {
        const { column, row } = cell
        const response = await apiClient.paymentTypes.update(row.original.id, {
          paymentType: {
            [column.id]: selectedOpt?.value || value,
          },
        })
        if (response.errors.length) {
          toast.error(response.errors[0])
        } else {
          updatePaymentType(row.original.id, response)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const tableColumns = useMemo<IRTColumnDef<IPaymentType>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
        size: 200,
      },
      {
        header: 'GL Code',
        id: 'glCodeId',
        enableEditing: true,
        accessorFn(row) {
          return glCodeOptions.find(({ value }) => row.glCodeId === value)
            ?.label
        },
        size: 200,
        editSelectOptions: glCodeOptions,
        editVariant: EFieldType.singleSelect,
        filterSelectOptions: glCodeOptions,
        filterVariant: EFieldType.multipleSelect,
      },
      {
        header: 'Payment Type',
        accessorKey: 'paymentType',
        enableEditing: true,
        size: 200,
        editSelectOptions: PAYMENT_TYPE_OPTIONS,
        editVariant: EFieldType.singleSelect,
        filterSelectOptions: PAYMENT_TYPE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        accessorFn(row) {
          return PAYMENT_TYPE_OPTIONS.find(
            ({ value }) => row.paymentType === value,
          )?.label
        },
      },
    ],
    [glCodeOptions],
  )

  return {
    paymentTypes,
    isLoadingGlCodes,
    tableColumns,
    isOpenDialogForm,
    selectedData,
    windowSize,
    isLoadingTable,
    onCloseDialogForm,
    afterCreate,
    afterUpdate,
    onOpenCreateDialogForm,
    onCellEditEnd,
    onRemove,
    onOpenEditDialogForm,
  }
}

export default usePaymentTypesContainer
