import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'

import { IonList } from '@ionic/react'
import SideMenuLink from './SideMenuLink'
import SideMenuLinkMini from './SideMenuLinkMini'
import DriverCable from '~/components/driver/cable/DriverCable'

import '~/styles/side-menu.scss'

const AdminSideMenu = ({
  admin,
  isCollapsed,
  getSubtabs,
  toggleCompanySubmenu,
  onToggleMobileMenu,
}) => {
  const location = useLocation()
  const { currentUserMenuItems, adminMenu } = useNavigatedMenuItems()

  const { windowSize, isMobileScreen } = useBreakpoint()
  const heightStyle = useMemo(() => {
    const style = {}
    style.maxHeight = isMobileScreen
      ? windowSize.height
      : windowSize.height - 250

    if (!isMobileScreen) {
      style.height = windowSize.height - 250
    }

    return style
  }, [isMobileScreen, windowSize])

  const renderMenuItems = useCallback(() => {
    return currentUserMenuItems
      .filter(({ isHiddenOnMenu }) => isHiddenOnMenu !== true)
      .map(item => (
        <SideMenuLink
          {...item}
          key={item.value}
          onToggleMobileMenu={onToggleMobileMenu}
        />
      ))
  }, [currentUserMenuItems, onToggleMobileMenu])

  const renderMiniMenuItems = useCallback(() => {
    return currentUserMenuItems
      .filter(({ isHiddenOnMenu }) => isHiddenOnMenu !== true)
      .map(item => (
        <SideMenuLinkMini
          {...item}
          getSubtabs={getSubtabs}
          toggleCompanySubmenu={toggleCompanySubmenu}
          key={item.value}
          onToggleMobileMenu={onToggleMobileMenu}
        />
      ))
  }, [
    currentUserMenuItems,
    getSubtabs,
    onToggleMobileMenu,
    toggleCompanySubmenu,
  ])

  return (
    <>
      {!isCollapsed ? (
        <IonList
          className='menu-options'
          style={windowSize.height ? heightStyle : {}}
        >
          {admin &&
            adminMenu.map((item, key) => (
              <SideMenuLink
                {...item}
                key={key}
                location={location}
                onToggleMobileMenu={onToggleMobileMenu}
              />
            ))}
          {renderMenuItems()}
        </IonList>
      ) : (
        <IonList
          className='menu-options-mini'
          style={windowSize.height ? heightStyle : {}}
        >
          {admin &&
            adminMenu.map((item, key) => (
              <SideMenuLinkMini {...item} key={key} location={location} />
            ))}
          {renderMiniMenuItems()}
        </IonList>
      )}
      <DriverCable />
    </>
  )
}

export default AdminSideMenu
