import React from 'react'
import { Container, Image, Col, Row, Card } from 'react-bootstrap'

const CompanyCard = ({ logo, name, user }) => {
  return (
    <Card className='company-card'>
      <Container className='p-0'>
        <Row>
          <Col sm={3} className='p-0'>
            <Image className='avatar p-0' src={logo} thumbnail />
          </Col>
          <Col className='text ps-1' sm={9}>
            <p className='larger no-padding'>{name}</p>
            <p>{user}</p>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default CompanyCard
