import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyUserAccesses from './useModifyUserAccesses'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetUserAcessParams,
  IUserAccess,
} from '~/types/models/IUserAccess'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCompanies = (
  params: Partial<IGetUserAcessParams> = {},
  options?: Partial<UseQueryOptions<IUserAccess[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'userAccesses',
      sessionUser?.id,
      buildGetUrl(apiClient.userAccesses.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.userAccesses.get(params)
      return response.userAccesses
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addUserAccess, updateUserAccess, removeUserAccess } =
    useModifyUserAccesses(params)

  const userAccessesData = useMemo(() => data || [], [data])

  return {
    userAccessesData,
    isLoadingUserAccessesData: isLoading,
    refetchUserAccesses: refetch,
    addUserAccess,
    updateUserAccess,
    removeUserAccess,
  }
}

export default useQueryCompanies
