import { useCallback, useState, useEffect, useMemo } from 'react'
import useFuzzy from '~/hooks/useFuzzy'

import {
  MenuItem,
  SubMenu,
  ControlledMenu,
  useClick,
  useMenuState,
  FocusableItem,
} from '@szhsin/react-menu'

import _ from 'lodash'
import clsx from 'clsx'
import df from 'd-forest'

import './ButtonDropdownMenu.scss'

const ButtonDropdownSubMenu = props => {
  const { renderMenu, option, isActive } = props
  const { label, style, searchable, subTabs } = option

  const [filter, setFilter] = useState('')

  const { seachedList } = useFuzzy(subTabs, {
    keys: ['label', 'value'],
    initialSearchValue: filter,
  })

  return (
    <SubMenu
      className={clsx('ButtonDropdownMenu__subItem', { isActive })}
      label={label}
      menuStyle={style}
    >
      {searchable && (
        <FocusableItem>
          {({ ref }) => {
            return (
              <input
                ref={ref}
                type='text'
                placeholder='Type to filter'
                value={filter}
                onChange={e => setFilter(e.target.value)}
              />
            )
          }}
        </FocusableItem>
      )}
      {renderMenu(_.orderBy(seachedList, 'label'), option)}
    </SubMenu>
  )
}

function ButtonDropdownMenu(props) {
  const {
    menu,
    elementRef,
    children,
    selectedValue: selectedValueProps,
    onClickItem,
  } = props

  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const anchorProps = useClick(menuState.state, toggleMenu)

  const [selectedValue, setSelectedValue] = useState(null)

  const mapMenuLabel = useMemo(() => {
    return df.reduce(
      menu,
      (tab, subTab) => {
        let label = subTab.label
        if (subTab.label) {
          label = `${tab.label || tab} > ${subTab.label}`

          if (_.startsWith(label, ' > ')) {
            label = label.replace(' > ', '')
          }
        }

        return {
          label,
          value: subTab.value,
        }
      },
      '',
    )
  }, [menu])

  const selectedOption = useMemo(() => {
    const findSelectedOption = _.find(mapMenuLabel, { value: selectedValue })

    return findSelectedOption || {}
  }, [mapMenuLabel, selectedValue])

  const handleClickItem = useCallback(
    (option, parent) => () => {
      setSelectedValue(option.value)
      onClickItem && onClickItem(option, parent)
    },
    [onClickItem],
  )

  const mapMenu = useCallback(
    (menuOptions, parent) =>
      menuOptions.map(option => {
        const { label, subTabs, value } = option

        if (_.size(subTabs) > 0) {
          return (
            <ButtonDropdownSubMenu
              key={value}
              option={option}
              renderMenu={mapMenu}
            />
          )
        }

        return (
          <MenuItem
            className='ButtonDropdownMenu__menuItem'
            onClick={handleClickItem(option, parent)}
            key={value}
          >
            {label}
          </MenuItem>
        )
      }),
    [handleClickItem],
  )

  useEffect(() => {
    setSelectedValue(selectedValueProps)
  }, [selectedValueProps])

  return (
    <>
      {children({ anchorProps, selectedOption })}
      <ControlledMenu
        {...menuState}
        anchorRef={elementRef}
        onClose={() => toggleMenu(false)}
        className='ButtonDropdownMenu__menu'
      >
        {mapMenu(menu)}
      </ControlledMenu>
    </>
  )
}

ButtonDropdownMenu.propTypes = {}

export default ButtonDropdownMenu
