import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_FLEET_COLUMNS } from '~/constants/companySubTabs'

const fleetsSlice = createSlice({
  name: moduleName.company.fleets,
  initialState: {
    columns: DEFAULT_COMPANY_FLEET_COLUMNS,
  },
})

export default fleetsSlice
