import _ from 'lodash'
import { createSelector } from 'reselect'

export const selectPreprocessingTestDataState = state =>
  state.data.preProcessingTests
export const selectPreprocessingTestsData = createSelector(
  selectPreprocessingTestDataState,
  state => {
    const data = []
    _.forEach(state, value => {
      data.push(value)
    })

    return data
  },
)

export const selectPreprocessingTestsUiState = state =>
  state.ui.preProcessingTests
export const selectPreprocessingTestsTableLoading = createSelector(
  selectPreprocessingTestsUiState,
  state => state.tableLoading,
)
export const selectPreprocessingTestsFilters = createSelector(
  selectPreprocessingTestsUiState,
  state => state.filters,
)
export const selectPreprocessingTestsSearchWord = createSelector(
  selectPreprocessingTestsUiState,
  state => state.searchWord,
)
