import { useCallback, useMemo, useRef, useState } from 'react'

import {
  ConcordFormStructure,
  HierarchyForm,
  IConcordFormField,
  hierarchyScheme,
} from '~/components/shared'

import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { PRICING_TYPE_OPTIONS } from '~/utils/constants'
import {
  ICreatePricingPayload,
  IPricingFormValues,
  IUpdatePricingPayload,
} from '~/types/models/IPricing'
import clsx from 'clsx'
import { apiClient } from '~/api/ApiClient'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IPricingFormProps } from './type'

import './styles.scss'
import moment from 'moment'

const PricingForm = (props: IPricingFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'dateRange',
        label: 'Date Range',
        type: EFieldType.dateRange,
        isRequired: true,
      },
      {
        name: 'amount',
        label: 'Amount',
        type: EFieldType.number,
        isRequired: true,
      },
      {
        name: 'pricingType',
        label: 'Pricing Type',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: PRICING_TYPE_OPTIONS,
      },
      {
        name: 'hierarchyAttribites',
        label: '',
        isHidden: isUpdating,
        render({ name, errors, setValue }) {
          const hasError = errors && Object.keys(errors[name] || {}).length > 0
          return (
            <HierarchyForm
              className={clsx('PricingForm__hierarchyForm', { hasError })}
              isHiddenSubmitButton
              onChange={values => {
                setValue(name, values)
              }}
            />
          )
        },
      },
    ],
    [isUpdating],
  )

  const defaultValues = useMemo<IPricingFormValues>(
    () => ({
      amount: null,
      pricingType: null,
      dateRange: null,
      hierarchyAttribites: {
        sellerSideType: null,
        sellerSideId: null,
        buyerSideType: null,
        buyerSideId: null,
        allowedHierarchyId: null,
        rank: null,
      },
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        amount: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        pricingType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        hierarchyAttribites: Yup.lazy(() => {
          if (isUpdating) {
            return Yup.mixed()
          }
          return hierarchyScheme
        }),
      }),
    [isUpdating],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatDateRange = useCallback((dateRange: any) => {
    if (!dateRange) {
      return null
    }
    if (typeof dateRange === 'object' && dateRange.startDate) {
      const { startDate, endDate } = dateRange
      const startDateFormatted = moment(startDate).format('YYYY-MM-DD')
      const endDateFormatted = moment(endDate).format('YYYY-MM-DD')

      return `${startDateFormatted}...${endDateFormatted}`
    }

    if (typeof dateRange === 'string') {
      return dateRange
    }

    return null
  }, [])

  const onCreate = useCallback(
    async (formValues: IPricingFormValues) => {
      const { hierarchyAttribites, ...pricingFormValues } = formValues

      if (hierarchyAttribites) {
        const { errors, ...response } = await apiClient.hierarchies.create({
          hierarchy: {
            sellerSideType: hierarchyAttribites.sellerSideType,
            sellerSideId: hierarchyAttribites.sellerSideId,
            buyerSideType: hierarchyAttribites.buyerSideType,
            buyerSideId: hierarchyAttribites.buyerSideId,
            allowedHierarchyId: hierarchyAttribites.allowedHierarchyId,
            rank: hierarchyAttribites.rank,
          },
        })

        if (errors.length > 0) {
          setError(errors[0])
        } else {
          const payload: ICreatePricingPayload['pricing'] = {
            amount: pricingFormValues.amount,
            pricingType: pricingFormValues.pricingType,
            dateRange: null,
          }
          const dateRange = formatDateRange(pricingFormValues.dateRange)
          if (dateRange) {
            payload.dateRange = dateRange
          }
          const pricingResponse = await apiClient.pricings.create({
            pricing: {
              ...payload,
              hierarchyRowAttributes: {
                hierarchyId: response.id,
              },
            },
          })
          if (pricingResponse.errors.length > 0) {
            setError(errors[0])
          } else {
            afterCreate && afterCreate(pricingResponse)
            toast.success(toastMessages.createSuccess)
          }
        }
      }
    },
    [afterCreate, formatDateRange],
  )

  const onUpdate = useCallback(
    async (formValues: IPricingFormValues) => {
      if (formValues.id) {
        const dateRange = formatDateRange(formValues.dateRange)

        const payload: IUpdatePricingPayload['pricing'] = {
          amount: formValues.amount,
          pricingType: formValues.pricingType,
        }

        payload.dateRange = dateRange

        const { errors, ...response } = await apiClient.pricings.update(
          formValues.id,
          {
            pricing: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formatDateRange],
  )

  const handleSubmit = useCallback(
    async (formValues: IPricingFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  // useEffect(() => {
  //   if (!isUpdating) {
  //     formRef.current?.setValue('buyerId', paymentData.buyerId)
  //   }
  // }, [isUpdating, paymentData.buyerId])

  // useEffect(() => {
  //   if (!isUpdating) {
  //     formRef.current?.setValue('invoiceId', invoiceIdSelected)
  //   }
  // }, [isUpdating, invoiceIdSelected])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        ref={formRef}
        isLoading={isLoading}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default PricingForm
