import type { ReactNode } from 'react'
// import { RTExpandAllButton } from '../../components/buttons/RTExpandAllButton'
import { RTExpandButton } from '../../components/buttons/RTExpandButton'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

export const getRTRowExpandColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  const {
    defaultColumn,
    enableExpandAll,
    groupedColumnMode,
    // positionExpandColumn,
    renderDetailPanel,
    state: { grouping },
  } = tableOptions

  // const alignProps =
  //   positionExpandColumn === 'last'
  //     ? ({
  //         align: 'right',
  //       } as const)
  //     : undefined

  return {
    Cell: ({ cell, column, row, staticRowIndex, table }) => {
      const expandButtonProps = { row, staticRowIndex, table }
      const subRowsLength = row.subRows?.length
      if (groupedColumnMode === 'remove' && row.groupingColumnId) {
        return (
          <div
            style={{ alignItems: 'center', flexDirection: 'row' }}
            // gap="0.25rem"
          >
            <RTExpandButton {...expandButtonProps} />
            <span>{row.groupingValue as ReactNode}</span>
            {!!subRowsLength && <span>({subRowsLength})</span>}
          </div>
        )
      } else {
        return (
          <>
            <RTExpandButton {...expandButtonProps} />
            {column.columnDef.GroupedCell?.({ cell, column, row, table })}
          </>
        )
      }
    },
    Header: enableExpandAll
      ? ({ table }) => {
          return (
            <>
              {/* <RTExpandAllButton table={table} /> */}
              {groupedColumnMode === 'remove' &&
                grouping
                  ?.map(
                    groupedColumnId =>
                      table.getColumn(groupedColumnId).columnDef.header,
                  )
                  ?.join(', ')}
            </>
          )
        }
      : undefined,
    ...defaultDisplayColumnProps({
      header: 'expand',
      id: ERTDisplayColumnId.expand,
      size:
        groupedColumnMode === 'remove'
          ? defaultColumn?.size ?? 180
          : renderDetailPanel
          ? enableExpandAll
            ? 40
            : 70
          : 100,
      tableOptions,
    }),
  }
}
