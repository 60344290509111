import { useState, useEffect, useCallback, useRef, useMemo } from 'react'

import { Form } from 'react-bootstrap'
import { DatePickerWithTimeInput } from '~/components/shared'
import KeyValueFormFieldLayout from '../FieldLayout'

import moment from 'moment'
import _ from 'lodash'

import '../KeyValueForm.scss'
import './KeyValueFormDateField.scss'

const KeyValueFormDateField = props => {
  const {
    label,
    value,
    onChange,
    className,
    onConfirmUpdate,
    field,
    loading,
    hideConfirmButton,
    editable,
    dateFormat,
    showTimeInput,
    ...dateProps
  } = props

  const inputRef = useRef({})

  const [selectedDate, setSelectedDate] = useState(null)

  const isEdited = useMemo(() => {
    return value !== selectedDate
  }, [selectedDate, value])

  useEffect(() => {
    setSelectedDate(value)
  }, [value])

  const handleChangeDate = useCallback(
    (date, event) => {
      setSelectedDate(date)
      onChange && onChange(event, date)
    },
    [onChange],
  )

  const handleConfirmUpdateValue = useCallback(
    event => {
      const newValue = {
        field,
        value: selectedDate,
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
    },
    [field, onConfirmUpdate, selectedDate],
  )

  const renderValue = useCallback(() => {
    if (_.isNil(selectedDate)) {
      return null
    }

    if (selectedDate instanceof Date) {
      let format = 'MMM-DD YYYY'
      if (showTimeInput) {
        format = `${format} - HH:mm`
      }
      return moment(selectedDate).format(format)
    }

    return selectedDate
  }, [selectedDate, showTimeInput])

  const handleResetValue = useCallback(() => {
    setSelectedDate(value)
  }, [value])

  return (
    <KeyValueFormFieldLayout
      hideConfirmButton={hideConfirmButton}
      label={label}
      loading={loading}
      editable={editable}
      renderValue={renderValue}
      isEdited={isEdited}
      onConfirm={handleConfirmUpdateValue}
      onReset={handleResetValue}
    >
      <DatePickerWithTimeInput
        dateFormat={dateFormat}
        selected={selectedDate}
        onChange={handleChangeDate}
        customInput={
          <Form.Control className='KeyValueFormFieldLayout__input KeyValueFormDateField__input' />
        }
        ref={inputRef}
        showTimeInput={showTimeInput}
        {...dateProps}
      />
    </KeyValueFormFieldLayout>
  )
}

KeyValueFormDateField.defaultProps = {
  dateFormat: 'MMM-dd yyyy',
}

export default KeyValueFormDateField
