import tagsSlice from '~/redux/reducers/data/tags.js'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt.js'
import { apiClient } from '~/api/ApiClient'

export const getCompanyTags = () => async dispatch => {
  try {
    const response = await apiClient.tags.get()
    const unix = Math.round(+new Date() / 1000)
    dispatch(tagsSlice.actions.initializeTags(response))
    dispatch(
      lastFetchedAtSlice.actions.updateLastFetchedAt({
        model: 'tags',
        lastFetchedAt: unix,
      }),
    )
  } catch (error) {
    console.log('error', error)
  }
}

export const updateCompanyTag = tag => dispatch => {
  dispatch(tagsSlice.actions.updateTag(tag))
}

export const updateCompanytags = tags => dispatch => {
  dispatch(tagsSlice.actions.updateTags(tags))
}
