import useDialogCreateCheckCompany from './useDialogCreateCheckCompany'

import { CommonDialogV2, ConcordFormStructure } from '~/components/shared'

import { IDialogCreateCheckCompanyProps } from './type'

import './styles.scss'
import { Alert } from 'react-bootstrap'

function DialogCreateCheckCompany(props: IDialogCreateCheckCompanyProps) {
  const {
    isOpen,
    onClose,
    formFields,
    checkCompanyFormValues,
    onSubmitForm,
    schema,
    isLoading,
    isHiddenCloseButton,
    formRef,
    isUpdating,
    backendError,
  } = useDialogCreateCheckCompany(props)

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      isHiddenOkButton
      title='Check Company'
      isLoading={isLoading}
      isHiddenCloseButton={isHiddenCloseButton}
    >
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      <ConcordFormStructure
        ref={formRef}
        fields={formFields}
        isHiddenSearch
        isHiddenCancelButton
        formData={checkCompanyFormValues}
        schema={schema}
        onSubmit={onSubmitForm}
        isLoading={isLoading}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </CommonDialogV2>
  )
}
export default DialogCreateCheckCompany
