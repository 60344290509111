import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import moment from 'moment'

export interface IPayrollJournalTableProps {
  data: any
}

function W2PreviewReportTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        align: 'center',
        Cell({ row: { original } }) {
          return moment(original.createdAt).format('YYYY-MM-DD')
        },
      },
      {
        header: 'Fullname',
        accessorKey: 'employeeFullName',
      },
      {
        header: 'SSN',
        accessorKey: 'employeeSsn',
      },
      {
        header: 'Company Address',
        accessorKey: 'companyAddress',
        minWidth: 350,
        maxWidth: 350,
      },
      {
        header: 'Employee Address',
        accessorKey: 'employeeAddress',
        minWidth: 350,
        maxWidth: 350,
      },
      {
        header: 'Federal Taxable Wages',
        accessorKey: 'federalTaxableWages',
        align: 'right',
      },
      {
        header: 'Federal Income Tax Withheld',
        accessorKey: 'federalIncomeTaxWithheld',
        minWidth: 200,
        maxWidth: 200,
        align: 'right',
      },
      {
        header: 'Social Security Wages',
        accessorKey: 'socialSecurityWages',
        align: 'right',
      },
      {
        header: 'Social Security Tax Withheld',
        accessorKey: 'socialSecurityTaxWithheld',
        minWidth: 220,
        maxWidth: 220,
        align: 'right',
      },
      {
        header: 'Medicare Wages And Tips',
        accessorKey: 'medicareWagesAndTips',
        align: 'right',
        minWidth: 180,
        maxWidth: 180,
      },
      {
        header: 'Medicare Tax Withheld',
        accessorKey: 'medicareTaxWithheld',
        align: 'right',
      },
      {
        header: 'Social Security Tips',
        accessorKey: 'socialSecurityTips',
        align: 'right',
      },
      {
        header: 'Box 10 Items',
        accessorKey: 'box10Items',
        minWidth: 220,
        maxWidth: 220,
      },
      {
        header: 'Box 12 Items',
        accessorKey: 'box12Items',
        minWidth: 220,
        maxWidth: 220,
      },
      {
        header: 'Box 14 Items',
        accessorKey: 'box14Items',
        minWidth: 220,
        maxWidth: 220,
      },
      {
        header: 'State Items',
        accessorKey: 'stateItems',
        minWidth: 220,
        maxWidth: 220,
      },
      {
        header: 'Local Items',
        accessorKey: 'localItems',
      },
      {
        header: 'Company Federal Ein',
        accessorKey: 'companyFederalEin',
      },
    ],
    [],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default W2PreviewReportTable
