import { useState, useCallback, useEffect, useMemo, memo } from 'react'
import useDateWithTimezone from '~/hooks/useDateWithTimezone'

import MaskedTextInput from 'react-text-mask'
import { DatePickerWithTimeInput, ToolTipOverlay } from '~/components/shared'

import './styles.scss'

const EditInlineDatePicker = props => {
  const { value, onChange, ...datePickerProps } = props

  const [dateValue, setDateValue] = useState(null)

  const { formatISODate, MOMENT_FORMAT_DATE } = useDateWithTimezone()

  const DEFAULT_INPUT_MARK = useMemo(() => [/\d/, /\d/, '/', /\d/, /\d/], [])

  const tooltipMessage = useMemo(
    () =>
      value
        ? formatISODate(value, MOMENT_FORMAT_DATE.dateTooltipWithoutTime)
        : '',
    [MOMENT_FORMAT_DATE.dateTooltipWithoutTime, formatISODate, value],
  )

  const handleChangeDate = useCallback(
    selectedDate => {
      onChange && onChange(selectedDate)
    },
    [onChange],
  )

  useEffect(() => {
    setDateValue(value)
  }, [value])

  if (tooltipMessage) {
    return (
      <ToolTipOverlay content={tooltipMessage} placement='top'>
        <div className='EditInlineDatePicker__wrapper'>
          <DatePickerWithTimeInput
            className='EditInlineDatePicker__container'
            selected={dateValue}
            onChange={handleChangeDate}
            customInput={
              <MaskedTextInput showMask type='text' mask={DEFAULT_INPUT_MARK} />
            }
            {...datePickerProps}
          />
        </div>
      </ToolTipOverlay>
    )
  }

  return (
    <div className='EditInlineDatePicker__wrapper'>
      <DatePickerWithTimeInput
        className='EditInlineDatePicker__container'
        selected={dateValue}
        onChange={handleChangeDate}
        customInput={
          <MaskedTextInput showMask type='text' mask={DEFAULT_INPUT_MARK} />
        }
        {...datePickerProps}
      />
    </div>
  )
}

export default memo(EditInlineDatePicker)
