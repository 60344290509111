import { Button, Popover } from 'react-bootstrap'
import { ContainerSearchBar, InfoIcon } from '~/components/shared'
import Tooltip from 'rc-tooltip'
import { JsonEditor } from 'json-edit-react'

import './styles.scss'
import { useState } from 'react'

export interface IRTActionOcrButtonProps {
  ocrJson: string | null | undefined
}

function RTActionOcrButton(props: IRTActionOcrButtonProps) {
  const { ocrJson } = props

  const [searchKey, setSearchKey] = useState('')

  return (
    <Tooltip
      placement='bottom'
      showArrow={false}
      overlay={
        <Popover className='RTActionOcrButton__overlay'>
          <Popover.Body>
            <ContainerSearchBar
              searchBarPlaceholder='Search by key or value'
              searchBarValue={searchKey}
              onSearchBarChange={setSearchKey}
            />
            <JsonEditor
              searchText={searchKey}
              searchFilter='all'
              collapse={2}
              data={ocrJson as any}
            />
          </Popover.Body>
        </Popover>
      }
      overlayClassName='RTActionOcrButton__tooltip'
      destroyTooltipOnHide
    >
      <Button variant='info'>
        <InfoIcon />
      </Button>
    </Tooltip>
  )
}

export default RTActionOcrButton
