import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'
import { cogOutline } from 'ionicons/icons'
import _ from 'lodash'
import clsx from 'clsx'
import MultiselectOrderDropDown from '~/components/shared/MultiselectOrderDropdown'
import { createOrUpdateUserTableConfigurations } from '~/redux/actions/userTableConfigurationActions'
import { columnArrayToColumnHash } from '~/utils/userTableConfigurationUtils'

import queryString from 'query-string'

import '../ContainerFilter.scss'
import { PROFILE_SETTINGS } from '~/utils/constants'

const DisplayColumnsButton = ({
  columnsDisplayed,
  columnsFromUserTableConfigurations,
  moduleName,
  onChangeColumnsDisplayed,
  DEFAULT_COLUMNS,
}) => {
  const dispatch = useDispatch()
  const tableId = useSelector(
    state => state.data.userTableConfigurations[moduleName]?.id,
  )
  const router = useRouter()

  const mapColumns = useMemo(() => {
    if (_.size(columnsDisplayed) === 0) {
      return []
    }

    let columnsToMap = []
    if (columnsDisplayed.some(column => 'hideable' in column)) {
      columnsToMap = columnsDisplayed.filter(column => column.hideable)
    } else {
      columnsToMap = columnsDisplayed
    }

    return _.map(columnsToMap, col => ({
      ...col,
      sortField: col.field,
    }))
  }, [columnsDisplayed])

  const tooltipColumns = useMemo(() => {
    if (mapColumns.length === 0) {
      return ''
    }

    const mapLabel = _(mapColumns).filter('show', true).map('label').join(', ')
    const totalColumns = mapColumns.length
    const totalSelectedColumns = _(mapColumns).filter('show', true).size()

    return (
      <div>
        <span>
          <strong>
            COLUMNS - {`${totalSelectedColumns} / ${totalColumns}`}
          </strong>
        </span>
        <br />
        <span>{mapLabel}</span>
      </div>
    )
  }, [mapColumns])

  const disabledClearButtonColumns = useMemo(() => {
    if (columnsDisplayed.length === 0) {
      return true
    }

    return _.every(columnsDisplayed || [], ['show', false])
  }, [columnsDisplayed])

  const disabledSaveButtonColumns = useMemo(() => {
    const modifiedColumns = columnsDisplayed.map(cols => ({
      displayIndex: cols.displayIndex,
      show: cols.show,
      sortField: cols.sortField,
      field: cols.field,
    }))

    const filteredData = columnsFromUserTableConfigurations
      ?.filter(c => c.displayIndex || c.displayIndex == 0)
      ?.map(cols => ({
        displayIndex: cols.displayIndex,
        show: cols.show,
        sortField: cols.sortField,
        field: cols.field,
      })) || []

    const modifiedHash = columnArrayToColumnHash(modifiedColumns)
    const filteredHash = columnArrayToColumnHash(filteredData)

    return _.isEqual(filteredHash, modifiedHash)
  }, [columnsDisplayed, columnsFromUserTableConfigurations])

  const handleChangeColumns = useCallback(
    nextColumns => {
      const mapNextColumns = nextColumns.map(col => ({
        ...col,
        field: col.sortField,
      }))

      onChangeColumnsDisplayed && onChangeColumnsDisplayed(mapNextColumns)
    },
    [onChangeColumnsDisplayed],
  )

  const handleSaveColumns = useCallback(() => {
    dispatch(
      createOrUpdateUserTableConfigurations(
        moduleName,
        columnsDisplayed,
        columnsFromUserTableConfigurations,
        tableId,
      ),
    )
  }, [
    columnsDisplayed,
    columnsFromUserTableConfigurations,
    dispatch,
    moduleName,
    tableId,
  ])

  const handleClearColumns = useCallback(() => {
    const newColumns = columnsDisplayed.map(column => ({
      ...column,
      show: false,
    }))

    onChangeColumnsDisplayed && onChangeColumnsDisplayed(newColumns)
  }, [columnsDisplayed, onChangeColumnsDisplayed])

  const handleResetColumns = useCallback(() => {
    if (columnsFromUserTableConfigurations.length > 0) {
      onChangeColumnsDisplayed(columnsFromUserTableConfigurations)
    } else {
      const defaultDisplayColumns = DEFAULT_COLUMNS.map(col => ({
        show: col.show,
        displayIndex: col.displayIndex,
        field: col.field,
        sortField: col.sortField,
      }))
      onChangeColumnsDisplayed(defaultDisplayColumns)
    }
  }, [
    onChangeColumnsDisplayed,
    columnsFromUserTableConfigurations,
    DEFAULT_COLUMNS,
  ])

  const handleDirectToSettingsColumns = useCallback(() => {
    const url = queryString.stringifyUrl({
      url: '/profile',
      query: {
        sub_tab: moduleName,
        tab: PROFILE_SETTINGS.storedData,
        section: 'columns',
      },
    })

    router.push(url)
  }, [moduleName, router])

  return (
    <MultiselectOrderDropDown
      options={mapColumns}
      onChange={handleChangeColumns}
      icon={cogOutline}
      isButtonGroup
      isCheckedKey={'show'}
      hasDirection={false}
      onSave={handleSaveColumns}
      onClear={handleClearColumns}
      onReset={handleResetColumns}
      onGoPage={handleDirectToSettingsColumns}
      disableClearButton={disabledClearButtonColumns}
      disableSaveButton={disabledSaveButtonColumns}
      disableResetButton={disabledSaveButtonColumns}
      tooltipProps={{
        placement: 'bottom',
        content: tooltipColumns,
      }}
      className={clsx(
        'ColumnsButton__container',
        'ContainerFilter__buttonGroup',
      )}
    />
  )
}

export default DisplayColumnsButton
