import React from 'react'
import { IonItem, IonLabel, IonCheckbox } from '@ionic/react'

import PropTypes from 'prop-types'

const CheckboxField = ({ label, style, input, isHidden }) => {
  const _onChange = e => {
    input.onChange && input.onChange(e.detail.checked)
  }

  return (
    !isHidden && (
      <IonItem lines='full' style={style} className='item-input'>
        <IonLabel className='input-label' style={{ marginRight: 40 }}>
          {label}
        </IonLabel>
        <IonCheckbox
          value={input.value}
          checked={input.checked}
          onIonChange={_onChange}
        />
      </IonItem>
    )
  )
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  }),
}

CheckboxField.defaultProps = {
  input: {},
}

export default CheckboxField
