import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const FileXMarkIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m17 17 4 4m0-4-4 4M13 3H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 4.52 5 5.08 5 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 21 7.08 21 8.2 21H13m0-18 6 6m-6-6v4.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C13.76 9 14.04 9 14.6 9H19m0 0v5M9 17h4m-4-4h6M9 9h1'
      />
    </svg>
  )
}
export default FileXMarkIcon
