import { useCallback } from 'react'
import { ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu'

import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import './styles.scss'

export interface IRTCellActionMenuProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
}

const RTCellActionMenu = <TData extends IReusableTableRowData>({
  table,
}: IRTCellActionMenuProps<TData>) => {
  const {
    getState,
    options: { renderCellActionMenuItems },
    refs: { actionCellRef },
  } = table
  const { actionCell } = getState()
  const cell = actionCell!
  const { row } = cell
  const { column } = cell
  const { columnDef } = column

  const handleClose = () => {
    table.setActionCell(null)
    actionCellRef.current = null
  }

  const internalMenuItems: any = []

  const renderActionProps = {
    cell,
    closeMenu: handleClose,
    column,
    internalMenuItems,
    row,
    table,
  }

  const menuItems =
    columnDef.renderCellActionMenuItems?.(renderActionProps) ??
    renderCellActionMenuItems?.(renderActionProps)

  const renderMenuItems = useCallback((items: typeof menuItems): any => {
    return (items || [])
      .filter(({ isHidden }) => !isHidden)
      .map(({ label, icon, onClick, subMenu, bgColor, color, style }) => {
        if (subMenu) {
          return (
            <SubMenu
              key={label}
              label={label}
              className='RTCellActionMenu__item subMenu'
              menuStyle={{ backgroundColor: bgColor, color, ...style }}
            >
              {renderMenuItems(subMenu)}
            </SubMenu>
          )
        }
        return (
          <MenuItem
            key={label}
            onClick={event => {
              event.stopPropagation = true
              onClick && onClick()
            }}
            className='RTCellActionMenu__item'
            style={{ backgroundColor: bgColor, color, ...style }}
          >
            {icon && <span className='RTCellActionMenu__itemIcon'>{icon}</span>}
            <span>{label}</span>
          </MenuItem>
        )
      })
  }, [])

  return !!menuItems?.length || !!internalMenuItems?.length ? (
    <ControlledMenu
      // eslint-disable-next-line no-extra-boolean-cast
      state={!!cell ? 'open' : 'closed'}
      anchorPoint={{
        x: actionCellRef.current?.getBoundingClientRect()?.x || 0,
        y: actionCellRef.current?.getBoundingClientRect()?.bottom || 0,
      }}
      onClose={handleClose}
    >
      {renderMenuItems(menuItems)}
    </ControlledMenu>
  ) : null
}

export default RTCellActionMenu
