import _ from 'lodash'

export const notSetOptionStyles = {
  option: (provided, { label, data }) => {
    const isNotSet = _.toLower(label) === 'not set'

    return {
      ...provided,
      backgroundColor: isNotSet ? '#ED4337' : provided.backgroundColor,
      color: isNotSet ? 'white' : provided.color,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isNotSet ? '#ed584e' : '#e9ecef',
      },
      ...data.style,
    }
  },
  multiValue: (provided, { data }) => {
    const isNotSet = _.toLower(data.label) === 'not set'

    return {
      ...provided,
      backgroundColor: isNotSet ? '#ED4337' : 'hsl(0, 0%, 90%)',
      color: isNotSet ? 'white' : provided.color,
      '.react-select__multi-value__label': {
        color: isNotSet ? 'white' : provided.color,
      },
      ...data.style,
    }
  },
}
