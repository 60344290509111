import { useCallback } from 'react'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TooltipErrorMessage = props => {
  const { children, message } = props

  const renderOverlay = useCallback(
    overlayProps => (
      <Tooltip id='button-tooltip' {...overlayProps}>
        {message}
      </Tooltip>
    ),
    [message],
  )

  if (!message) {
    return children
  }

  return <OverlayTrigger overlay={renderOverlay}>{children}</OverlayTrigger>
}

export default TooltipErrorMessage
