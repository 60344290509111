import { createSelector } from 'reselect'
import buildFullName from '~/utils/buildFullName'

export const selectDriverFleets = state => state.data.driverFleets
const getPeople = state => state.data.people
export const getDrivers = state => state.data.drivers
const getDriversUi = state => state.ui.drivers

export const selectDriverFleetsList = createSelector(
  selectDriverFleets,
  driverFleets => Object.values(driverFleets) || [],
)

export const selectDriverFleetsFullList = createSelector(
  selectDriverFleets,
  getDrivers,
  getPeople,
  //selectUserState,?
  (driverFleets, drivers, people) =>
    Object.values(driverFleets).map(driverFleet => {
      const driver = drivers[driverFleet.driverId] || driverFleet?.driver || {}
      const person = people[driver.personId]

      return {
        ...driverFleet,
        driver,
        person: driverFleet.personId ? person : driverFleet.person,
      }
    }) || [],
)

export const selectDriverFleetFull = createSelector(
  [
    selectDriverFleets,
    getDrivers,
    getPeople,
    (_, driverFleetId) => driverFleetId,
  ],
  (driverFleets, drivers, people, driverFleetId) => {
    if (!driverFleetId) return {}
    const parent = driverFleets[driverFleetId]

    return {
      ...parent,
      driver: drivers[parent.driverId],
      person: people[parent.personId],
    }
  },
)

export const selectDriverFleetsOptions = createSelector(
  selectDriverFleetsList,
  driverFleets =>
    driverFleets.map(({ id, person, workerId }) => ({
      value: id,
      label: buildFullName(person),
      workerId,
    })),
)

export const selectDriverFleetsWithDriverNameOptions = createSelector(
  [selectDriverFleets, getDrivers, getPeople],
  driverFleets =>
    Object.values(driverFleets).map(driverFleet => ({
      value: driverFleet.id,
      label:
        driverFleet?.person?.firstName + ' ' + driverFleet?.person?.lastName,

      //       value: driverFleet.id,
      //       label: people[drivers[driverFleet.driverId].personId].firstName,
    })) || [],
)

export const selectDriverOptionsWithNotSet = createSelector(
  selectDriverFleetsOptions,
  driverOptions => [
    {
      label: 'Not set',
      value: null,
    },
    ...driverOptions,
  ],
)

export const selectDriversViewMode = createSelector(
  getDriversUi,
  state => state.viewMode,
)

export const selectDriverLoading = createSelector(
  getDriversUi,
  state => state.loading,
)

export const selectDriverLastUpdatedAt = createSelector(
  getDriversUi,
  state => state.lastUpdatedAt,
)
