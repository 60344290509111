import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompaniesLastFetchedAt } from '~/redux/selectors'
import { getCompanies } from '~/redux/actions/CommonData/companiesActions'

export const useInitializeCompaniesStore = () => {
  const dispatch = useDispatch()
  const lastFetchedAt = useSelector(selectCompaniesLastFetchedAt)

  useEffect(() => {
    if (!lastFetchedAt) {
      dispatch(getCompanies())
    }
  }, [dispatch, lastFetchedAt])
}
