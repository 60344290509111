import { useCallback, useEffect, useMemo, useState } from 'react'

import { IonHeader, IonToolbar } from '@ionic/react'
import { Document, Page, pdfjs } from 'react-pdf'
import { PdfPageSelector, ZoomButton } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'

import './PdfViewer.scss'

const PdfViewer = props => {
  const {
    pdfUrl,
    className,
    hideHeader,
    onChangeTotalPages,
    minPage,
    maxPage,
    defaultScale,
    onIncreasePage,
    onDecreasePage,
    forwardButtonProps,
    backButtonProps,
  } = props

  const [scale, setScale] = useState(1)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)

  const mapForwardButtonProps = useMemo(() => {
    if (_.isFunction(forwardButtonProps)) {
      return forwardButtonProps({ page })
    }

    return forwardButtonProps
  }, [forwardButtonProps, page])

  const mapBackButtonProps = useMemo(() => {
    if (_.isFunction(backButtonProps)) {
      return backButtonProps({ page })
    }

    return backButtonProps
  }, [backButtonProps, page])

  const handleLoadSuccess = useCallback(
    ({ numPages }) => {
      if (_.isNil(maxPage)) {
        setTotalPage(numPages)
      }
      onChangeTotalPages && onChangeTotalPages(numPages)
    },
    [maxPage, onChangeTotalPages],
  )

  const handleDecreasePage = useCallback(() => {
    setPage(prev => {
      const nextNumber = prev - 1
      onDecreasePage && onDecreasePage(nextNumber)

      return nextNumber
    })
  }, [onDecreasePage])

  const handleIncrementPage = useCallback(() => {
    setPage(prev => {
      const nextNumber = prev + 1
      onIncreasePage && onIncreasePage(nextNumber)

      return nextNumber
    })
  }, [onIncreasePage])

  const handleChangePage = useCallback(
    event => {
      const value = +event.target.value.replace(/[^0-9]/g, ' ')
      if (value <= totalPage) {
        setPage(value)
      }
    },
    [totalPage],
  )

  const handleZoomIn = useCallback(() => {
    setScale(prev => prev + 0.1)
  }, [])

  const handleZoomOut = useCallback(() => {
    setScale(prev => prev - 0.1)
  }, [])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  useEffect(() => {
    if (!_.isNil(minPage)) {
      setPage(minPage)
    }
  }, [minPage])

  useEffect(() => {
    if (!_.isNil(maxPage)) {
      setTotalPage(maxPage)
    }
  }, [maxPage])

  useEffect(() => {
    if (defaultScale) {
      setScale(defaultScale)
    }
  }, [defaultScale])

  return (
    <div className={clsx('PdfViewer__container', className)}>
      {!hideHeader && (
        <IonHeader translucent>
          <IonToolbar color='dark'>
            <div className='DocumentPdfViewer__header'>
              <PdfPageSelector
                numPages={totalPage}
                pageNumber={page}
                onBackPress={handleDecreasePage}
                onForwardPress={handleIncrementPage}
                onInputChange={handleChangePage}
                forwardButtonProps={mapForwardButtonProps}
                backButtonProps={mapBackButtonProps}
              />

              <div className='DocumentPdfViewer__divider' />

              <ZoomButton
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                value={scale.toFixed(1)}
                zoomInButtonProps={{ disabled: scale > 2 }}
                zoomOutButtonProps={{ disabled: scale < 0.8 }}
              />
            </div>
          </IonToolbar>
        </IonHeader>
      )}

      <div className='PdfViewer__pdfContainer'>
        <Document file={pdfUrl} onLoadSuccess={handleLoadSuccess}>
          <Page scale={scale} pageNumber={page} />
        </Document>
      </div>
    </div>
  )
}

export default PdfViewer
