import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetStartTimeResponse } from '~/types/models/IStartTime'
import { IBreak } from '~/types/models/IBreak'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryStartTime = (
  startTimeId: number,
  options?: Partial<UseQueryOptions<IGetStartTimeResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['startTime', sessionUser?.id, startTimeId],
    queryFn() {
      return apiClient.startTimes.getById(startTimeId as number)
    },
    enabled: Boolean(sessionUser?.id && startTimeId),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const startTimeData = useMemo(() => data?.startTime, [data?.startTime])

  const startTimeBreaksData = useMemo(() => {
    const breaksData: IBreak[] = []
    data?.breaks.forEach(value => {
      const breakTime = _.get(value, 'break')
      breaksData.push(breakTime)
    })

    return breaksData
  }, [data?.breaks])

  return {
    startTimeData,
    startTimeBreaksData,
    isLoadingStartTimeData: isLoading,
  }
}

export default useQueryStartTime
