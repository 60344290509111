import { PropsWithChildren, useCallback, useState } from 'react'
import PricingFormContext from './PricingFormContext'
import { DialogPricingForm } from '~/components/shared'

function PricingFormProvider(props: PropsWithChildren) {
  const { children } = props

  const [isOpenPricingForm, setIsOpenPricingForm] = useState(false)

  const onOpenPricingForm = useCallback(() => {
    setIsOpenPricingForm(true)
  }, [])

  const onClosePricingForm = useCallback(() => {
    setIsOpenPricingForm(false)
  }, [])

  return (
    <>
      <PricingFormContext.Provider
        value={{ isOpenPricingForm, onOpenPricingForm, onClosePricingForm }}
      >
        {children}
      </PricingFormContext.Provider>
      <DialogPricingForm
        isOpen={isOpenPricingForm}
        onClose={onClosePricingForm}
      />
    </>
  )
}

export default PricingFormProvider
