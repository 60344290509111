import {
  ECheckBusinessType,
  ECheckCompanyIndustryType,
  ECheckCompanyProcessingPeriod,
  ECheckPayFrequency,
} from '../enums/ECheck'
import {
  ICommonModel,
  ICommonOption,
  INumberString,
  ITimestamp,
} from './ICommonModel'

export interface IGetCheckSetupCompanyResponse {
  active: boolean
  address: {
    line1: string
    line2?: string | null
    city: string
    state: string
    postalCode: string
    country: string
  }
  feinId?: string
  bankAccounts: string[]
  businessType?: ECheckBusinessType
  contractor1099DeliveryMethod: null
  email: string
  employeeW2DeliveryMethod: null
  id: string
  implementation: {
    status: 'in_review' | 'completed' | 'needs_attention'
    remainingSteps: Record<string, { status: string }>
  }
  inGoodStanding: boolean
  industryType: ECheckCompanyIndustryType
  legalName: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  metadata: {}
  onboard: {
    status: 'blocking' | 'completed' | 'needs_attention'
    blockingSteps: string[]
    remainingSteps: string[]
    bankAccount?: string[]
    paymentMethod?: string[]
    ssn?: string[]
    setupParameters?: {
      name: string
      id: string
      status: 'blocking' | 'completed'
    }[]
    filingAuthorization?: {
      name: string
      id: string
      status: 'blocking' | 'completed'
    }[]
    withholdings?: {
      name: string
      id: string
      status: 'blocking' | 'completed'
    }[]
  }
  otherBusinessName: null | string
  payFrequency: ECheckPayFrequency
  phone: string
  principalPlaceOfBusiness: string
  processingPeriod: ECheckCompanyProcessingPeriod
  startDate: ITimestamp
  tradeName: string
  website: null | string
  dob?: string
  residence?: IGetCheckSetupCompanyResponse['address']
  ein?: string
  businessName?: string
  type?: string
}

export type ICheckCompanyFormData = Pick<
  IGetCheckSetupCompanyResponse,
  | 'tradeName'
  | 'legalName'
  | 'address'
  | 'email'
  | 'phone'
  | 'startDate'
  | 'payFrequency'
> & {
  id?: number
  businessType?: IGetCheckSetupCompanyResponse['businessType']
}

export interface ICreateCheckCompanyPayload {
  company: ICheckCompanyFormData
}

export interface IUpdateCheckCompanyPayload {
  company: Partial<ICheckCompanyFormData>
}

export interface ICreateCheckCompanyLinkPayload {
  signatory: {
    firstName: string
    lastName: string
    title: string
    email: string
    userAccessId: number
    signatory?: string // check uid
  }
}

export interface ICreateCheckCompanyLinkResponse {
  error?: string
  url: string
}

export interface ICheckSetupWorkerParams {
  workerId: number
}

export interface ICreateCheckEmployeeParams {
  driverFleetId: number
}

export interface ICheckEmployeeFormData {
  firstName: string
  lastName: string
  dob: ITimestamp
  startDate: ITimestamp
  residence: {
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
  }
}

export interface ICreateCheckEmployeePayload {
  driverFleet: ICheckEmployeeFormData
}

export interface IUpdateCheckEmployeePayload {
  employee: Partial<ICheckEmployeeFormData>
}

export interface ICreateWorkerLinkPayload {
  worker: {
    checkUid: string
  }
}

export interface ICreateWorkerLinkResponse {
  link: string
}

export interface IGetCheckSetupTerminalResponse {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  company: string
}

export interface IGetCheckSetupTerminalParams {
  terminalId: number
}

export interface ICheckTerminalFormData {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  company: string
}

export interface ICreateCheckTerminalPayload {
  terminal: ICheckTerminalFormData
}

export interface ICreateCheckTerminalParams {
  terminalId: number
}

export interface IRetrieveTerminalParams {
  locationUid: string
}

export interface IUpdateCheckTerminalParams {
  terminal: {
    address: Partial<{
      line1: string
      line2: string
      city: string
      state: string
      postalCode: string
      country: string
    }>
  }
}

export interface ICreateCheckPayrollPayload {
  payroll: {
    id: number
  }
}

export interface IPreviewCheckPayrollParams {
  payroll: {
    checkUid: string
  }
}

export interface IReopenCheckPayrollPayload {
  payroll: {
    checkUid: string
  }
}

export interface IGetPayrollPaperParams {
  payroll: {
    checkUid: string
  }
}

export interface IGetPayrollCashRequirementParams {
  payroll: {
    checkUid: string
  }
}

export interface IApprovePayrollPayload {
  payroll: {
    checkUid: string
  }
}

export interface ICreateNetPaySplitPayload {
  workerUid: string
  netPaySplit: {
    splits: {
      bankAccount: unknown
      priority: number
      amount: number
    }[]
  }
}

export interface ICheckBankAccountFormData {
  accountNumber: number
  routingNumber: number
  subType: 'checking' | 'savings'
}

export interface ICreateCheckBankAccountPayload {
  bankAccount: {
    employee: string
    rawBankAccount: ICheckBankAccountFormData
  }
}

export interface ICheckPayScheduleFormData {
  name: string
  payFrequency: Omit<ECheckPayFrequency, 'daily'>
  firstPayday: ITimestamp
  firstPeriodEnd: ITimestamp
  company: string
}

export interface ICreateCheckPaySchedulePayload {
  paySchedule: ICheckPayScheduleFormData
}

export interface IUpdateCheckPaySchedulePayload {
  paySchedule: Partial<ICheckPayScheduleFormData>
}

export interface IGetCheckEarningCodesParams {
  earningCode: {
    company: string
  }
}

export interface ICheckEarningCodeFormData {
  type: string
  company: string
  metadata: {
    workPayRuleId: string
  }
}

export interface ICreateCheckEarningCodePayload {
  workPayRuleId: number
  earningCode: ICheckEarningCodeFormData
}

export interface IRetryCheckPayment {
  checkPayment: {
    checkUid: string
  }
}

export interface IRefundCheckPayment {
  checkPayment: {
    checkUid: string
  }
}

export interface IGetCheckEarningRatesParams {
  earningRate: {
    employee: string
  }
}

export interface ICheckEarningRateFormData {
  metadata: {
    workerId: number
  }
  name: string
  period: 'hourly' | 'annually' | 'piece'
  amount: number
}

export interface IUpdateCheckEarningRatePayload {
  earningRate: Partial<ICheckEarningRateFormData>
}

export interface ICheckSignatory extends ICommonModel {
  companyId: number
  firstName: string
  middleName?: string
  lastName: string
  title: string
  email: string
  checkUid: string
  userAccessId: number
}

export interface IGetCheckSignatoriesParams {
  filters: Partial<{
    companyId: number
  }>
}

export interface IGetCheckSignatoriesResponse {
  checkSignatories: ICheckSignatory[]
}

export type ICreateCheckSignatoryPayload = Pick<
  ICheckSignatory,
  | 'companyId'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'title'
  | 'email'
  | 'userAccessId'
> & {
  jurisdiction?: string
  federalOnly?: boolean
}

export interface ICheckNetPaySplit {
  contractor: null
  deactivatedAt: null
  deactivatedReason: null
  employee: string
  id: string
  isDefault: boolean
  splits: {
    bankAccount: string
    priority: number | null
    amount: INumberString
    percentage: INumberString
  }[]
}

export interface IGetCheckNetPaySplitsResponse {
  results: ICheckNetPaySplit[]
}

export interface ICheckBankAccount {
  employee: string
  id: string
  metadata: unknown
  status: string
  rawBankAccount: {
    accountNumberLastFour: string
    institutionName: null
    routingNumber: string
    subtype: 'checking' | 'saving'
    type: string
  }
}

export interface IGetCheckBankAccountsResponse {
  results: ICheckBankAccount[]
}

export interface IGetCheckBankAccountsPayload {
  bankAccount: {
    employee: string // check uid
  }
}

export type ICheckBankAccountWithPaySplit = ICheckBankAccount & {
  split?: ICheckNetPaySplit['splits'][0]
  index?: number
}
export interface IGetCheckPayScheduleParams {
  paySchedule: {
    company: string
  }
}

export interface ICreateCompanyFillingAuthorizationPayload {
  signatory: {
    firstName: string
    middleName: string
    lastName: string
    title: string
    email: string
    userAccessId: number
    jurisdiction: string
    federalOnly: boolean
  }
}

export interface ICreateCompanyFillingAuthorizationResponse {
  body: string
  url: string
}

export interface ICreateRunPayrollParams {
  payroll?: {
    checkUid: string
  }
}

export interface ICreateRunPayrollResponse {
  body: string
  url: string
}

export interface ICheckCreateWorkerPayload {
  workerId: number
  worker: {
    firstName: string
    lastName: string
    email: string
    dob: ITimestamp
    company: string // check uid,
    startDate: string
    residence: {
      line1: string
      line2: string
      city: string
      state: string
      postalCode: string
    }
  }
}

export interface ICheckUpdateWorkerPayload {
  workerId: number
  worker: Partial<{
    firstName: string
    lastName: string
    email: string
    dob: ITimestamp
    company: string // check uid,
    startDate: string
    residence: {
      line1: string
      line2: string
      city: string
      state: string
      postalCode: string
    }
  }>
}

export interface ICheckCompanyDetails {}

export enum ECheckPayrollHistoryAccessMethod {
  authorizedAccessToPreviousProvider = 'authorized_access_to_previous_provider',
  providedCredentials = 'provided_credentials',
  providedReports = 'provided_reports',
}

export enum ECheckProductsActivelyUsed {
  timetracking = 'timetracking',
  payments = 'payments',
  scheduling = 'scheduling',
}

export interface ICheckEnrollmentProfile {
  accountContacts: string[]
  approvedForPaymentProcessing: boolean
  averageMonthlyRevenue: number // float
  contractorCount: number // int
  earliestKnownRevenue: ITimestamp
  employeeCount: number // int
  existingPayrollCustomerProcessingPeriod: ECheckCompanyProcessingPeriod
  expectedFirstPayday: ITimestamp
  firstPayrollOfYear: boolean
  fraudScore: number
  implementationServicesSubmissionComment: string
  missedPaymentsCount: number // float
  monthsOnPreviousPayrollProvider: number // int
  payPeriodAmount: number // string
  payingUser: boolean
  payrollHistoryAccessMethod: ECheckPayrollHistoryAccessMethod
  predictedFraud: boolean
  previousPayrollProvider: string
  productsActivelyUsed: ECheckProductsActivelyUsed[]
  socialMedia: string[]
  userSince: ITimestamp
}

export type ICheckEnrollmentProfileFormValues = Partial<ICheckEnrollmentProfile>

export interface IUpdateCheckEnrollmentProfilePayload {
  company: ICheckEnrollmentProfileFormValues
}

export interface IGetAppliedForIdsResponse {
  next: null
  previous: null
  results: {
    companyId: string
    legalName: string
    startDate: string
    jurisdiction: string
    requirementName: string
    requirementId: string
  }[]
}

export interface IGetRetrieveWorkerReciprocityElectionsParams {
  worker: {
    checkUid: string
  }
}
export interface IGetWorkerCompanyAttributesParams {
  worker: {
    checkUid: string
  }
}

export interface IWorkerReciprocityElection {
  optIn: boolean
  effectiveStart: string // string
  jurisdiction: string
  jurisdictionName: string
  applicable: boolean
}

export interface IUpdateWorkerReciprocityElectionPayload {
  worker: {
    checkUid: string
    reciprocityElections: Pick<
      IWorkerReciprocityElection,
      'optIn' | 'effectiveStart' | 'jurisdiction'
    >[]
  }
}

export interface IWorkerCompanyAttribute {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  type: 'string' | 'currency' | 'number' | 'select' | 'boolean' | 'percentage'
  label: string
  description: string
  effectiveStart: null | string
  effectiveStartRequired: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any
  options?: ICommonOption[] | null
}

export interface IGetWorkerCompanyAttributesResponse {
  companyDefinedAttributes: IWorkerCompanyAttribute[]
}

export interface IUpdateWorkerCompanyAttributesPayload {
  worker: {
    checkUid: string
    companyDefinedAttributes: Pick<
      IWorkerCompanyAttribute,
      'name' | 'effectiveStart' | 'value'
    >[]
  }
}

export interface ICheckAccessesGetResponse {
  results: {
    accessType: string
    company: string
    status: string
    scope: string[]
  }[]
}

export interface ICheckUsageOptionsGetResponse {
  categories: string[]
  resourceTypes: string[]
}

export type ICheckUsageSummariesGetParams = Partial<{
  periodStart: string
  periodEnd: string
  category: string
}>

export interface ICheckUsageSummariesGetResponse {
  result: {
    company: string
    category: string
    count: number
    periodStart: string
    periodEnd: string
  }[]
}

export type ICheckUsageRecordsGetParams = Partial<{
  periodStart: string
  periodEnd: string
  category: string
  resourceType: string
}>

export interface ICheckUsageRecordsGetResponse {
  result: {
    category: string
    company: string
    resourceType: string
    resource: string
    effectiveAt: string
  }[]
}
