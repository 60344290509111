import React, { useState, useCallback, useEffect } from 'react'

import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import PaymentMethodCard from '../PaymentMethodCard'
import { Button } from '~/components/shared'

import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'

import './CheckoutForm.scss'

function CheckoutForm(props) {
  const { toggleLoadingDialog, afterCheckout } = props

  const stripe = useStripe()
  const elements = useElements()

  const [isReadyForm, setIsReadyForm] = useState(false)
  const [paymentSources, setPaymentSources] = useState([])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      toggleLoadingDialog && toggleLoadingDialog(true)
      try {
        const { paymentIntent, error } = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
        })
        if (error?.message) {
          toast.error(error?.message)
        } else if (paymentIntent) {
          afterCheckout && afterCheckout(paymentIntent)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        toggleLoadingDialog && toggleLoadingDialog(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterCheckout, elements, stripe, toggleLoadingDialog],
  )

  const handleReadyPaymentSection = useCallback(
    elements => {
      elements.focus()
      elements.update({
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false,
        },
        // defaultValues: {
        //   billingDetails: {
        //     email: invoice?.buyer?.arEmail,
        //   },
        // },
      })
      setIsReadyForm(true)
    },
    [],
  )

  const getPaymentSources = useCallback(async () => {
    const response = await apiClient.fuse.getPaymentSources()
    console.log('Payment sources result', response)
    setPaymentSources(response)
  }, [])

  useEffect(() => {
    getPaymentSources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      id='payment-form'
      className='CheckoutForm__container'
      onSubmit={handleSubmit}
    >
      <LinkAuthenticationElement
        id='link-authentication-element'
        onChange={e => {
          console.log('e?.target?.value', e?.target?.value)
        }}
      />
      <PaymentElement onReady={handleReadyPaymentSection} />

      <div className={'PaymentMethods__paymentMethodList'}>
        {paymentSources.map(paymentSource => (
          <>
            <PaymentMethodCard
              id={`payment__method__card__${paymentSource.id}`}
              className={`PaymentMethods__paymentMethod__${paymentSource.id}`}
              bankName={paymentSource.bankName}
              accountNumber={`***********${paymentSource.last4}`}
            ></PaymentMethodCard>
          </>
        ))}
      </div>

      {isReadyForm && (
        <Button
          type='submit'
          style={{ marginTop: 8 }}
          expand='full'
        >
          Pay now
        </Button>
      )}
    </form>
  )
}

export default CheckoutForm
