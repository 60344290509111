import React, { useState, useEffect, useCallback } from 'react'
import { IonCol } from '@ionic/react'
import { CheckMarkIcon, ListSearchIcon, TooltipIcon } from '~/components/shared'
import { renderFlags } from '../../helpers'
import { ToolTipOverlay } from '~/components/shared'

import clsx from 'clsx'

const IndexCol = ({
  index,
  billLine,
  className,
  hideFlagIcon,
  hideMatchedIcon = false,
  onMouseEnterCell,
  onMouseLeaveCell,
  onShowBillLineFlagModal,
  onShowBillLineDetail,
  onUnMatchedClick,
  shouldDisplayIndex,
  startRange,
}) => {
  const [errors, setErrors] = useState(renderFlags(billLine))

  useEffect(() => {
    setErrors(renderFlags(billLine))
  }, [billLine])

  const showBillLineFlagModal = useCallback(() => {
    onShowBillLineFlagModal && onShowBillLineFlagModal(billLine)
  }, [billLine, onShowBillLineFlagModal])

  const showBillLineDetails = useCallback(() => {
    onShowBillLineDetail && onShowBillLineDetail(billLine)
  }, [billLine, onShowBillLineDetail])

  const mouseEnterCell = useCallback(() => {
    onMouseEnterCell && onMouseEnterCell('index')
  }, [onMouseEnterCell])
  const mouseLeaveCell = useCallback(() => {
    onMouseLeaveCell && onMouseLeaveCell()
  }, [onMouseLeaveCell])
  const unMatchedClick = useCallback(() => {
    !billLine.billLineId && onUnMatchedClick && onUnMatchedClick(billLine)
  }, [onUnMatchedClick, billLine])

  const MatchIcon = billLine?.billLineId ? CheckMarkIcon : ListSearchIcon

  return (
    <IonCol
      className={clsx(className, 'edit-col-cursor')}
      style={{ placeContent: 'center' }}
      onMouseEnter={mouseEnterCell}
      onMouseLeave={mouseLeaveCell}
      sizeSm='1'
    >
      {shouldDisplayIndex && (
        <div
          className='EditableTableRow__showIndex'
          color='white'
          onClick={showBillLineDetails}
        >
          {startRange ? startRange + index : index + 1}
        </div>
      )}

      {!!errors && !hideFlagIcon && (
        <TooltipIcon
          iconStyle={{ color: 'red' }}
          toolTip={errors}
          onShowBillLineFlagModal={showBillLineFlagModal}
          billLine={billLine}
          containerStyle={{ marginTop: 2 }}
        />
      )}
      {!hideMatchedIcon && (
        <ToolTipOverlay
          placement='top'
          content={
            billLine.billLineId ||
            (billLine.loadId && billLine.invoiceType == 'Invoice')
              ? 'Matched'
              : 'Unmatched'
          }
        >
          <div
            onClick={unMatchedClick}
            className={clsx('EditableTableRow__matchedIcon', {
              unmatch:
                !billLine.billLineId &&
                !(billLine.loadId && billLine.invoiceType == 'Invoice'),
            })}
          >
            <MatchIcon />
          </div>
        </ToolTipOverlay>
      )}
    </IonCol>
  )
}

export default IndexCol
