import { useMemo, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { IonPopover, IonSearchbar, IonLabel, IonToggle } from '@ionic/react'
import PropTypes from 'prop-types'
import { PlusIcon } from '~/components/shared'

import './styles.scss'

const HeaderCreateButton = ({ options }) => {
  const [searchKey, setSearchKey] = useState('')
  const [popoverState, setPopoverState] = useState({
    isOpen: false,
    event: null,
  })

  const openPopover = e => {
    setPopoverState({ isOpen: true, event: e })
  }

  const renderCreateOptions = useMemo(() => {
    if (options)
      return options.map(
        (
          { icon: Icon, title, onClick, keyStroke, isHidden, useToggle },
          index,
        ) => {
          if (isHidden) {
            return
          }

          if (
            searchKey.length > 0 &&
            !title.toLowerCase().includes(searchKey.toLowerCase())
          ) {
            return null
          }

          const onClickButton = () => {
            onClick && onClick()
            if (!useToggle) setPopoverState({ isOpen: false })
          }

          return (
            <Card key={index} className='mb-2'>
              <Card.Body
                key={title + '--' + index}
                onClick={!useToggle ? onClickButton : undefined}
                className={
                  !useToggle ? 'd-flex justify-content-between' : 'd-flex'
                }
                style={{ fontSize: 14, cursor: 'pointer' }}
              >
                {useToggle ? (
                  <>
                    <div style={{ width: '75%' }}>
                      <IonLabel>{title}</IonLabel>
                    </div>
                    <IonToggle color='secondary' onIonChange={onClickButton} />
                  </>
                ) : (
                  <>
                    <div
                      className='d-flex align-items-center gap-3'
                      style={{ width: '65%' }}
                    >
                      <Icon size={14} />
                      {title}
                    </div>
                    <div>{keyStroke}</div>
                  </>
                )}
              </Card.Body>
            </Card>
          )
        },
      )
  }, [options, searchKey])

  return (
    <>
      <Button onClick={e => openPopover(e)} size='lg'>
        <PlusIcon color='white' />
      </Button>
      <IonPopover
        {...popoverState}
        mode='ios'
        onDidDismiss={() => setPopoverState({ isOpen: false })}
        className='HeaderCreateButton__popover'
      >
        <Container>
          <Row>
            <IonSearchbar onIonChange={e => setSearchKey(e.detail.value)} />
          </Row>
          <Row className='mb-3'>
            <Col className='mb-2'>{renderCreateOptions}</Col>
          </Row>
        </Container>
      </IonPopover>
    </>
  )
}

HeaderCreateButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
}

export default HeaderCreateButton
