import { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCompanies } from '~/redux/selectors'
import { getCoordinates } from '~/utils/utils'

import type { ITerminalMarkerProps } from './type'
import type { ICompany } from '~/types/models/ICompany'

const useTerminalMarker = (props: ITerminalMarkerProps) => {
  const { terminal } = props
  const { location, coordinates, geofence } = terminal

  const [isOpenInfoBox, setIsOpenInfoBox] = useState(false)

  const companies: Record<number, ICompany> = useSelector(selectCompanies)

  const company = useMemo<ICompany | null>(
    () => companies[terminal.companyId],
    [companies, terminal.companyId],
  )

  const position = useMemo(() => {
    if (location) {
      return new google.maps.LatLng(
        parseFloat(location?.lat as string),
        parseFloat(location?.lng as string),
      )
    }

    return undefined
  }, [location])

  const polygonPoints = useMemo(
    () => getCoordinates(coordinates, geofence),
    [coordinates, geofence],
  )

  const onToggleOpenInfoBox = useCallback(() => {
    setIsOpenInfoBox(prev => !prev)
  }, [])

  return {
    position,
    onToggleOpenInfoBox,
    isOpenInfoBox,
    terminal,
    company,
    polygonPoints,
    location,
  }
}

export default useTerminalMarker
