function removeStartTimesNotExist(data, driversNeeded) {
  const startTimes = data.map(({ ticketTime }) => ticketTime)
  const result = {}
  startTimes.forEach(time => {
    result[time] = driversNeeded[time]
  })
  return result
}

export function calculateDriversNeeded(data) {
  const driversNeeded = {}
  let maxDriversNeeded = 0

  data.forEach(entry => {
    const startTime = entry.ticketTime
    const returnTime = entry.leaveSellerTime
    // Increment the count of drivers needed at start time
    driversNeeded[startTime] = (driversNeeded[startTime] || 0) + 1

    // Decrement the count of drivers needed at return time
    driversNeeded[returnTime] = (driversNeeded[returnTime] || 0) - 1
  })

  // Calculate cumulative drivers needed
  let currentDrivers = 0
  Object.keys(driversNeeded)
    .sort()
    .forEach(time => {
      currentDrivers += driversNeeded[time]
      if (currentDrivers > 0) {
        maxDriversNeeded = Math.max(maxDriversNeeded, currentDrivers)
        driversNeeded[time] = currentDrivers
      } else {
        delete driversNeeded[time]
      }
    })

  return removeStartTimesNotExist(data, driversNeeded)
}

export function calculateDriversNeededByScheduleId(scheduleLoads) {
  const scheduleIds = scheduleLoads.map(({ scheduleId }) => scheduleId)
  const uniqueIds = Array.from(new Set(scheduleIds))
  const result = {}
  uniqueIds.forEach(id => {
    const sortedScheduleLoads = scheduleLoads.filter(
      ({ scheduleId }) => Number(scheduleId) === Number(id),
    )
    result[id] = calculateDriversNeeded(sortedScheduleLoads)
  })

  return result
}
