var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles } from '@material-ui/core';
import { ColoredBlock } from 'documentLabeler/components/documentPanel/documentBlockLayer/ColoredBlock/ColoredBlock';
import { DragRectangle } from 'documentLabeler/components/documentPanel/documentBlockLayer/useDragBlockSelect/DragRectangle';
import { useDragBlockSelect } from 'documentLabeler/components/documentPanel/documentBlockLayer/useDragBlockSelect/useDragBlockSelect';
import { BlockUtils, } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/BlockUtils';
import { EndUserBlockRenderUtils } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/EndUserBlockRenderUtils';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { LabelingSelectionType } from 'documentLabeler/state/DocumentLabelerState';
import { BlockType, FieldType, } from 'common/types/DocumentLabelerTypes';
import { useCallback, useRef, useState } from 'react';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
import { DocumentLabelerState } from 'documentLabeler/state/DocumentLabelerState';
import { DELAY_TIME_TO_CALL_SAVE_CALLBACK } from 'utils/constants';
var useStyles = makeStyles(function () { return ({
    Root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    EndUserBlockLayerRoot: {
        height: '100%',
        width: '100%',
    },
}); });
/**
 * Component responsible for rendering the ColoredBlocksLayer over the DocumentDisplayer component
 * @param props: Props
 * @returns JSX
 */
export var DocumentBlockLayer = function (_a) {
    var _b, _c;
    var width = _a.width, docPageHeights = _a.docPageHeights;
    var classes = useStyles();
    var _d = useDocumentLabeler(), state = _d.state, dispatch = _d.dispatch;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _e = useState(null), timeoutId = _e[0], setTimeoutId = _e[1];
    var docInfo = state.docInfo, localState = state.localState;
    var fieldViewing = localState.fieldViewing;
    var blockRootRef = useRef(null);
    var _f = useState([]), unhighlightedBlocksToDisplay = _f[0], setUnhighlightedBlocksToDisplay = _f[1];
    // Used to render a rectangle when dragging
    var _g = useDragBlockSelect(), onDragStart = _g.onDragStart, onDrag = _g.onDrag, onDragEnd = _g.onDragEnd, dragRectangle = _g.dragRectangle;
    var isDragging = dragRectangle !== undefined;
    var selectedFieldColor = state.localState.activeField
        ? DocumentLabelerReducerUtils.getColorFromFieldId(state, state.localState.activeField.id)
        : null;
    var _h = useBBConfiguration(), displayOnly = _h.displayOnly, onSaveCallback = _h.onSaveCallback;
    var coloredBlocks = BlockUtils.getColoredBlocks(state);
    var coloredBlocksToDisplay = BlockUtils.getColoredBlocksToDisplay(state, coloredBlocks);
    var regions = BlockUtils.getColoredRegions(state);
    var regionsToDisplay = BlockUtils.getColoredRegionsToDisplay(state, regions);
    var filteredUnhighlightedBlocks = BlockUtils.getFilteredUnhighlightedBlocks(state.localState.activeField, coloredBlocks, docInfo.wordBlocks, state.localState.selectionType);
    var hasActiveFormField = ((_b = state.localState.activeField) === null || _b === void 0 ? void 0 : _b.type) !== FieldType.Table;
    var hasActiveTableWithActiveCell = ((_c = state.localState.activeField) === null || _c === void 0 ? void 0 : _c.type) === FieldType.Table &&
        Boolean(state.localState.activeField.activeCell);
    var canLabel = (hasActiveFormField || hasActiveTableWithActiveCell) && !displayOnly;
    /**
     * Responsible for handling the case of unselected field/cell.
     * @param ev
     * @param block
     */
    var handleOnColoredBlockClick = function (ev, block) {
        ev.stopPropagation();
        if (!state.localState.activeField) {
            var fieldId = BlockUtils.isTextOrCheckBoxBlock(block)
                ? block.sourceFieldId
                : block.tableId;
            dispatch({
                type: 'setActiveField',
                payload: {
                    id: fieldId,
                    type: block.sourceFieldType,
                },
            });
        }
        else if (BlockUtils.isCellLabel(block) &&
            !(state.localState.activeField.type === FieldType.Table &&
                state.localState.activeField.activeCell)) {
            dispatch({
                type: 'setActiveField',
                payload: {
                    id: block.tableId,
                    type: FieldType.Table,
                    activeCell: {
                        rowId: block.rowId,
                        columnId: block.columnId,
                    },
                },
            });
        }
    };
    var handleOnColoredRegionClick = function (ev, region) {
        ev.stopPropagation();
        dispatch({
            type: 'clearRegionFromField',
            payload: {
                id: region.sourceFieldId,
                type: region.sourceFieldType,
                activeCell: BlockUtils.isCellLabel(region) ? __assign({}, region) : undefined,
            },
        });
    };
    var handleOnMouseDown = function (evt) {
        var convertedMouseCoords = EndUserBlockRenderUtils.convertEventCoordsToRefSpace(blockRootRef, evt);
        var isHoveringOnWordBlock = unhighlightedBlocksToDisplay.length !== 0;
        var isHoveringOnFieldBlock = EndUserBlockRenderUtils.getBlocksUnderMouse(coloredBlocks.map(function (block) { return block.block; }), convertedMouseCoords, docPageHeights, width).length !== 0;
        var isHoveringOnFieldRegion = EndUserBlockRenderUtils.getRegionsUnderMouse(regions, convertedMouseCoords, docPageHeights, width).length !== 0;
        if (!isDragging &&
            !isHoveringOnWordBlock &&
            !isHoveringOnFieldBlock &&
            !isHoveringOnFieldRegion) {
            onDragStart(convertedMouseCoords);
        }
    };
    var handleAutoCallSaveCallback = useCallback(function () {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        var newTimeoutId = setTimeout(function () {
            onSaveCallback(DocumentLabelerState.convertInternalStateToOutputData(state));
            clearTimeout(timeoutId);
        }, DELAY_TIME_TO_CALL_SAVE_CALLBACK);
        setTimeoutId(newTimeoutId);
    }, [timeoutId]);
    var handleOnMouseUp = function (evt) {
        evt.stopPropagation();
        if (isDragging) {
            onDragEnd();
        }
        handleAutoCallSaveCallback();
        var shouldLabelRegion = state.localState.activeField &&
            (state.localState.selectionType === LabelingSelectionType.Region ||
                state.localState.activeField.type === FieldType.Signature);
        if (state.localState.activeField &&
            shouldLabelRegion &&
            dragRectangle &&
            canLabel) {
            var region = BlockUtils.getRegionFromRectangle(dragRectangle, width, docPageHeights);
            var activeRegion = BlockUtils.getActiveRegion(state, regionsToDisplay);
            if (activeRegion &&
                EndUserBlockRenderUtils.doRectanglesIntersect(EndUserBlockRenderUtils.convertBoundingBoxToRectCoords(activeRegion), EndUserBlockRenderUtils.convertBoundingBoxToRectCoords(region))) {
                // When the user clicks directly on the signature region
                // or draws an intersecting rectangle, we clear the region
                dispatch({
                    type: 'clearRegionFromField',
                    payload: state.localState.activeField,
                });
            }
            else {
                // otherwise we save the new region to the signature field
                dispatch({
                    type: 'addRegionToActiveField',
                    payload: {
                        region: region,
                    },
                });
            }
        }
        if (unhighlightedBlocksToDisplay.length > 0) {
            if (state.localState.activeField && canLabel) {
                dispatch({
                    type: 'addBlocksToActiveField',
                    payload: {
                        blocks: unhighlightedBlocksToDisplay,
                    },
                });
            }
            // Reset the unhighlighted blocks
            setUnhighlightedBlocksToDisplay([]);
        }
        else {
            var newMouseCoords = EndUserBlockRenderUtils.convertEventCoordsToRefSpace(blockRootRef, evt);
            var blocks = EndUserBlockRenderUtils.getBlocksUnderMouse(coloredBlocksToDisplay.map(function (block) { return block.block; }), newMouseCoords, docPageHeights, width);
            if (blocks.length > 0 && state.localState.activeField && canLabel) {
                var block = blocks[0];
                dispatch({
                    type: 'removeBlockFromField',
                    payload: {
                        blockId: block.id,
                        field: state.localState.activeField,
                    },
                });
            }
        }
    };
    var handleOnMouseMove = function (evt) {
        var newMouseCoords = EndUserBlockRenderUtils.convertEventCoordsToRefSpace(blockRootRef, evt);
        if (isDragging) {
            onDrag(newMouseCoords);
        }
        var newBlocksToDisplay = dragRectangle !== undefined
            ? EndUserBlockRenderUtils.getWordBlocksInsideRectangle(dragRectangle, filteredUnhighlightedBlocks, docPageHeights, width)
            : EndUserBlockRenderUtils.getBlocksUnderMouse(filteredUnhighlightedBlocks, newMouseCoords, docPageHeights, width);
        if (newBlocksToDisplay.length > 0 ||
            newBlocksToDisplay.length !== unhighlightedBlocksToDisplay.length) {
            setUnhighlightedBlocksToDisplay(newBlocksToDisplay);
        }
    };
    var renderColoredBlocksToDisplay = useCallback(function () {
        if (fieldViewing) {
            return null;
        }
        return coloredBlocksToDisplay.map(function (coloredBlock, index) { return (_jsx(ColoredBlock, { block: coloredBlock.block, color: coloredBlock.color, docPageHeights: docPageHeights, docRenderWidth: width, autoScroll: index === 0, opacity: BlockUtils.getColoredBlockOpacity(coloredBlock, state.localState.activeField), onClick: function (ev) { return handleOnColoredBlockClick(ev, coloredBlock); } }, "".concat(coloredBlock.block.id).concat(index))); });
    }, [
        docPageHeights,
        width,
        state.localState.activeField,
        handleOnColoredBlockClick,
        fieldViewing,
    ]);
    var renderColoredBlocksViewing = useCallback(function () {
        if (fieldViewing) {
            var id_1 = fieldViewing.id;
            var findColoredBlock = coloredBlocksToDisplay.find(function (block) { return block.sourceFieldId === id_1; });
            if (findColoredBlock) {
                return (_jsx(ColoredBlock, { block: findColoredBlock.block, color: findColoredBlock.color, docPageHeights: docPageHeights, docRenderWidth: width, isFieldViewing: true }));
            }
            return null;
        }
        return null;
    }, [fieldViewing, coloredBlocksToDisplay]);
    return (_jsx("div", __assign({ className: classes.Root, ref: blockRootRef, onMouseDown: handleOnMouseDown, onMouseMove: handleOnMouseMove, onMouseUp: handleOnMouseUp, "data-testid": "document-block-layer" }, { children: _jsxs(Box, __assign({ className: classes.EndUserBlockLayerRoot }, { children: [renderColoredBlocksViewing(), renderColoredBlocksToDisplay(), regionsToDisplay.map(function (coloredRegion, index) { return (_jsx(ColoredBlock, { block: __assign(__assign({}, coloredRegion), { boundingBox: coloredRegion.region, blockType: BlockType.Word, text: '' }), color: coloredRegion.color, docPageHeights: docPageHeights, docRenderWidth: width, autoScroll: index === 0, opacity: BlockUtils.getColoredBlockOpacity(coloredRegion, state.localState.activeField), onClick: function (ev) {
                        return handleOnColoredRegionClick(ev, coloredRegion);
                    } }, coloredRegion.id)); }), canLabel &&
                    selectedFieldColor &&
                    unhighlightedBlocksToDisplay.map(function (block) { return (_jsx(ColoredBlock, { block: block, color: selectedFieldColor, docPageHeights: docPageHeights, docRenderWidth: width }, block.id)); }), dragRectangle && canLabel && selectedFieldColor && (
                // can only draw rectangle if active field is set
                _jsx(DragRectangle, { dragCoords: dragRectangle, bgColor: selectedFieldColor }))] })) })));
};
