import { View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { useCallback, useMemo } from 'react'

import getInitName from '~/utils/getInitName'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  textContainer: {
    width: 30,
    height: 30,
    borderRadius: '50%s',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  seller: {
    backgroundColor: '#1190ff',
  },
  buyer: {
    backgroundColor: '#54d154',
  },
  text: {
    color: 'white',
    fontSize: 8,
  },
})

function PDFCompanyAvatar(props) {
  const { name, title, image, type = 'seller' } = props

  const initialName = useMemo(() => getInitName(name, '?'), [name])

  const renderImage = useCallback(() => {
    if (image) {
      return <Image style={styles.image} src={image} />
    }

    return (
      <View style={[styles.textContainer, styles[type]]}>
        <Text style={styles.text}>{initialName}</Text>
      </View>
    )
  }, [image, initialName, type])

  return (
    <View style={styles.container}>
      {renderImage()}
      <View
        style={{
          marginLeft: 6,
        }}
      >
        <Text style={{ fontSize: 11 }}>{name || 'Unknown'}</Text>
        <Text style={[{ fontSize: 11, marginTop: 4 }]}>{title}</Text>
      </View>
    </View>
  )
}

PDFCompanyAvatar.propTypes = {}

export default PDFCompanyAvatar
