import { useLocalStorage } from 'react-use'

import StorageContext from './StorageContext'

const StorageProvider = ({ children }) => {
  const [storedValue, setStoredValue] = useLocalStorage('concord_materials', {})

  return (
    <StorageContext.Provider value={{ storedValue, setStoredValue }}>
      {children}
    </StorageContext.Provider>
  )
}

export default StorageProvider
