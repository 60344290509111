import useTerminalMarker from './useTerminalMarker'

import { Unless, When } from 'react-if'
import { Marker, Polygon } from 'react-google-maps'
import { IonIcon } from '@ionic/react'

import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import { CompanyAvatar } from '~/components/shared'

import type { ITerminalMarkerProps } from './type'

import './styles.scss'

function TerminalMarker(props: ITerminalMarkerProps) {
  const {
    position,
    polygonPoints,
    isOpenInfoBox,
    terminal,
    location,
    company,
    icon,
    color,
    extraInfo,
    title,
    isHiddenGeofence,
    onToggleOpenInfoBox,
    onDragMarker,
  } = useTerminalMarker(props)

  return (
    <>
      <When condition={Boolean(position && !icon)}>
        <Marker
          position={position as google.maps.LatLng}
          onClick={onToggleOpenInfoBox}
          options={{
            draggable: true,
          }}
          draggable
          onDragEnd={onDragMarker}
        />
      </When>

      <Unless condition={isHiddenGeofence}>
        <Polygon
          path={polygonPoints}
          editable={false}
          draggable={false}
          options={{
            strokeColor: '#1090ff',
            fillColor: '#1090ff',
          }}
        />
      </Unless>

      <When condition={Boolean(position && icon)}>
        <InfoBox
          zIndex={1000}
          position={position}
          options={{
            closeBoxURL: '',
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(-10, 10),
          }}
        >
          <div
            onClick={onToggleOpenInfoBox}
            style={{
              fontWeight: 'bold',
              color: 'white',
              opacity: 1,
              padding: '2px 2px',
              borderRadius: '15px',
              width: '24px',
              height: '24px',
              fontSize: '20px',
              background: color,
            }}
          >
            <IonIcon icon={icon} color={color} />
          </div>
        </InfoBox>
      </When>

      <When condition={isOpenInfoBox}>
        <InfoBox
          position={position}
          zIndex={2000}
          options={{
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(25, -3),
            closeBoxURL: '',
            boxStyle: {
              minWidth: '120px',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              borderRadius: '4px',
            },
          }}
        >
          <div className='TerminalMarker__infoboxContent'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CompanyAvatar
                company={{
                  value: terminal.id as number,
                  label: terminal.name as string,
                  image: company?.logo,
                }}
                width={20}
                height={20}
              />
              <div className='terminalInfo'>
                <div>{title || terminal.name}</div>
                <div className='code'>{terminal.code}</div>
              </div>
            </div>
            <div className='address'>{location?.address}</div>
            {extraInfo}
          </div>
        </InfoBox>
      </When>
    </>
  )
}
export default TerminalMarker
