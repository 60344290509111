import { useMemo } from 'react'

import { Card } from 'react-bootstrap'
import { IonSpinner } from '@ionic/react'
import { FileUnknownIcon, ImageIcon, PdfIcon } from '~/components/shared'

import { isImage, isPdf } from '~/utils/checkFileExtension'

import './DownloadCard.scss'

function DownloadCard(props) {
  const { file } = props

  const FileIcon = useMemo(() => {
    if (isPdf(file.name)) {
      return PdfIcon
    }

    if (isImage(file.name)) {
      return ImageIcon
    }

    return FileUnknownIcon
  }, [file.name])

  const iconColor = useMemo(() => {
    if (isPdf(file.name)) {
      return 'red'
    }

    if (isImage(file.name)) {
      return 'green'
    }

    return 'black'
  }, [file.name])

  return (
    <Card className='DownloadCard__root'>
      <Card.Body className='DownloadCard__body'>
        <div className='DownloadCard__leftSection'>
          <FileIcon color={iconColor} className='DownloadCard__icon' />
          <span className='DownloadCard__fileName'>{file.name}</span>
        </div>
        <IonSpinner name='lines-small' />
      </Card.Body>
    </Card>
  )
}

DownloadCard.propTypes = {}

export default DownloadCard
