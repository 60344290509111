import { useContext } from 'react'

import ModalDownloadProgressContext from './Context'

/**
 * @typedef {{
 *  name: string;
 *  url: string;
 * }} FileDownloading
 */

/**
 * @typedef {{
 *  files: FileDownloading[]
 *  addFileDownloading: (file: FileDownloading) => void
 *  removeFileDownloading: (file: FileDownloading) => void
 *  downloadFile: (url: string, filename: string) => Promise<void>
 *  downloadMultipleFiles: (files: FileDownloading) => Promise<void>
 * }} UseModalDownloadProgress
 */

/**
 * @return {UseModalDownloadProgress}
 */
const useModalDownloadProgress = () => {
  const context = useContext(ModalDownloadProgressContext)

  return context
}

export default useModalDownloadProgress
