import { useState, useMemo, useCallback, useEffect } from 'react'
import useTableCompanyBuyerSellerProductSubTab from './useTableCompanyBuyerSellerProductSubTab'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogBuyerSellerProductForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { produce } from 'immer'
import { companyTabQuery } from '~/utils/constants'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'

function CompanyBuyerSellerProductSubTab() {
  const windowSize = useWindowSize()

  const [buyerSellerProductData, setBuyerSellerProductData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [currentRowData, setCurrentRowData] = useState()
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'view',
      value: companyTabQuery.noProductGroup,
    },
  ])

  const filterData = useMemo(() => {
    const data = {}
    columnFilters.forEach(({ id, value }) => {
      data[id] = value
    })
    return data
  }, [columnFilters])

  const { isLoadingBuyerSellers, buyerSellersData } = useQueryBuyerSellers()

  const buyerSellerIds = useMemo(
    () => buyerSellersData.map(({ id }) => id),
    [buyerSellersData],
  )

  const { confirmation } = useConfirmationProvider()

  const filterOptions = useMemo(
    () => [
      {
        label: 'View',
        field: 'view',
        options: [
          {
            label: 'No Product Group',
            value: companyTabQuery.noProductGroup,
          },
          {
            label: 'All Buyer Seller Products',
            value: companyTabQuery.allBuyerSellerProducts,
          },
        ],
        type: EFieldType.singleSelect,
      },
    ],
    [],
  )

  const getApiCall = useCallback(() => {
    let params = {}
    if (filterData.view === companyTabQuery.allBuyerSellerProducts) {
      params = { buyerSellerId: buyerSellerIds }
    } else if (filterData.view === companyTabQuery.noProductGroup) {
      params = { productGroupId: 'null' }
    }

    return apiClient.buyerSellerProducts.get(params)
  }, [buyerSellerIds, filterData.view])

  const getBuyerSellerProductData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setBuyerSellerProductData(response.buyerSellerProducts)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete buyer seller product #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.buyerSellerProducts.delete(
            rowData.id,
          )
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setBuyerSellerProductData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setIsOpenForm(true)
    setCurrentRowData(rowData)
  }, [])

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const formData = { [columnField]: value }
      const { errors } = await apiClient.buyerSellerProducts.update(
        rowId,
        formData,
      )
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        setBuyerSellerProductData(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === rowId)
            if (index > -1) {
              draft[index][columnField] = value
              toast.success(toastMessages.updateSuccess)
            } else {
              toast.error(`Cannot find record #${rowId}`)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const { columns } = useTableCompanyBuyerSellerProductSubTab({
    handleDeleteRow,
    handleOpenUpdateForm,
  })

  const onCloseForm = useCallback(() => {
    setIsOpenForm(false)
    setCurrentRowData(null)
  }, [])

  // const dataExported = mapExportDataFromColumns(
  //   columns,
  //   mapBuyerSellerProductData,
  // )

  useEffect(() => {
    getBuyerSellerProductData()
  }, [getBuyerSellerProductData])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          manualFiltering
          columns={columns}
          data={buyerSellerProductData}
          tableHeight={windowSize.height - 220}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 75,
              maxSize: 75,
            },
          }}
          companyViewProps={{
            name: moduleName.company.buyerSellerProducts,
            description:
              'View and manage products bought or sold between buyers and sellers.',
            onClickTopButton() {
              setIsOpenForm(true)
            },
          }}
          state={{
            isLoading: loading || isLoadingBuyerSellers,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenUpdateForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Delete',
                placement: 'top',
              },
            },
          ]}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogBuyerSellerProductForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        formData={currentRowData}
        afterCreate={newItem => {
          setBuyerSellerProductData(prev => [newItem, ...prev])
          onCloseForm()
        }}
        afterUpdate={newDate => {
          onCloseForm()
          setBuyerSellerProductData(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(({ id }) => id === newDate.id)
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  ...newDate,
                }
              }
            }),
          )
        }}
      />
    </>
  )
}

export default CompanyBuyerSellerProductSubTab
