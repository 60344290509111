export const costCodeConfigurationOptions = [
  {
    label: 'Buyer Terminal',
    value: 'buyer_terminal_id',
  },
  // {
  //   label: 'Fleet',
  //   value: 'fleet_id'
  // },
  // {
  //   label: 'Product',
  //   value: 'product_id'
  // },
  {
    label: 'Product Group',
    value: 'product_group_id',
  },
  // {
  //   label: 'Seller',
  //   value: 'seller_id'
  // },
  // {
  //   label: 'Seller Terminal',
  //   value: 'seller_terminal_id'
  // },
  {
    label: '___',
    value: '',
  },
]

export const mapNullsToString = configuration => {
  configuration.map((config, index) => {
    if (!config) {
      configuration[index] = ''
    }
  })

  return configuration
}
