import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'

import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IonItem, IonIcon, IonBadge } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import '~/styles/side-menu.scss'
import { selectUnreadChatMessage } from '~/redux/selectors'
import clsx from 'clsx'

const SideMenuLinkMini = ({
  label,
  icon,
  path,
  subtabs,
  getSubtabs,
  toggleCompanySubmenu,
  isDisabled
}) => {
  const router = useRouter()

  const unreadCount = useSelector(selectUnreadChatMessage)

  useEffect(() => {
    const subtabMenu = document.getElementById('company-subtabs')
    const subtabArrow = document.getElementById('company-subtabs-arrow')

    const sendSubmenuInfo = e => {
      getSubtabs(path, subtabs, e.pageY)
      toggleCompanySubmenu()
    }

    if (subtabMenu && subtabs) {
      subtabMenu.addEventListener('mouseenter', sendSubmenuInfo)
      subtabArrow.addEventListener('mouseenter', sendSubmenuInfo)
    }

    return () => {
      if (subtabMenu && subtabs) {
        subtabMenu.removeEventListener('mouseenter', sendSubmenuInfo)
        subtabArrow.addEventListener('mouseenter', sendSubmenuInfo)
      }
    }
  }, [path, subtabs, getSubtabs, toggleCompanySubmenu])

  const renderTooltip = props => {
    return (
      <Tooltip {...props}>
        <FormattedMessage id={label} />
      </Tooltip>
    )
  }
  const disableRender = () => {
    return <></>
  }

  return (
    <>
      <Link
        key={label}
        to={{
          pathname: path,
          state: { hideBackButton: true },
        }}
        style={{
          color: '#111111',
          display: 'flex',
          textDecoration: 'none',
        }}
        className={clsx({ isDisabled })}
      >
        <OverlayTrigger
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={!subtabs ? renderTooltip : disableRender}
        >
          <IonItem
            id={subtabs ? 'company-subtabs' : ''}
            className={
              path == router.location.pathname
                ? 'menuDropdown menu-item-mini'
                : 'hoverDropdown menu-item-mini'
            }
            lines='none'
          >
            <IonIcon
              color={path == router.location.pathname ? 'concord' : 'cloudgrey'}
              className='menu-icon-mini'
              icon={icon}
            />
          </IonItem>
        </OverlayTrigger>
        {subtabs && (
          <div id='company-subtabs-arrow' className='submenu-arrow-container'>
            <IonIcon
              color={path == router.location.pathname ? 'concord' : 'cloudgrey'}
              className='submenu-arrow'
              icon={chevronForwardOutline}
            />
          </div>
        )}
        {label == 'Chats' && unreadCount > 0 && (
          <IonBadge
            style={{ height: 20, alignSelf: 'center' }}
            slot={'end'}
            color='danger'
          >
            {unreadCount}
          </IonBadge>
        )}
      </Link>
    </>
  )
}
export default SideMenuLinkMini
