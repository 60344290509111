var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, IconButton, makeStyles, Typography, } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
var ADD_ROW = 'Add Row';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        borderRight: "1px solid ".concat(theme.palette.divider),
        gap: theme.spacing(4),
    },
    Expanded: {
        flex: 1,
    },
    Close: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.primary.main,
    },
    IconButton: {
        padding: 0,
        marginLeft: theme.spacing(2),
    },
    LeftContent: {
        display: 'flex',
        alignItems: 'center',
    },
}); });
/**
 * Reusable display component, responsible for displaying the
 * name of the table, edit button for editing the table name,
 * and close button for exiting the table editor.
 * @param Props
 */
export var DocumentLabelerTableHeader = function () {
    var classes = useStyles();
    var displayOnly = useBBConfiguration().displayOnly;
    var _a = useDocumentLabeler(), state = _a.state, dispatch = _a.dispatch;
    var selectedTable = DocumentLabelerReducerUtils.getSelectedTable(state);
    if (!state.localState.activeField ||
        state.localState.activeField.type !== FieldType.Table) {
        throw new Error('DocumentLabelerTableHeader cannot be rendered when there is no active table in the Document Labeler');
    }
    var handleOnTableViewClose = function () {
        dispatch({
            type: 'setActiveField',
            payload: undefined,
        });
    };
    var handleAddRow = function () {
        dispatch({
            type: 'addRowToTable',
            payload: {
                tableId: selectedTable.id,
            },
        });
    };
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(Box, __assign({ className: classes.LeftContent }, { children: _jsx(Typography, __assign({ variant: "subtitle1" }, { children: _jsx("b", { children: selectedTable.name }) })) })), _jsx(Box, { className: classes.Expanded }), !displayOnly && (_jsx(Button, __assign({ color: "primary", onClick: handleAddRow, "data-testid": "add-row-btn" }, { children: ADD_ROW }))), _jsx(IconButton, __assign({ className: classes.IconButton, onClick: handleOnTableViewClose, "data-testid": "close-table-btn" }, { children: _jsx(Close, { className: classes.Close }) }))] })));
};
