import loadsUiSlice from '../reducers/ui/loadsSlice'
import loadsDataSlice from '../reducers/data/loadsSlice'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
// root actions
export const onUpdate = payload => dispatch => {
  dispatch(loadsDataSlice.actions.updateLoad(payload))
}

export const initializeLoads = payload => dispatch => {
  dispatch(loadsDataSlice.actions.updateAll(payload))
}

export const onUpdateUi = payload => dispatch => {
  dispatch(loadsUiSlice.actions.updateUi(payload))
}

const onUpdateUiColumns = payload => dispatch => {
  dispatch(loadsUiSlice.actions.updateUiColumns(payload))
}

const onUpdateUiColumn = payload => dispatch => {
  dispatch(loadsUiSlice.actions.updateUiColumn(payload))
}

const onUpdateUiFilter = payload => dispatch => {
  dispatch(loadsUiSlice.actions.updateUiFilter(payload))
}

const onUpdateUiPerPage = payload => dispatch => {
  dispatch(loadsUiSlice.actions.updateUiPerPage(payload))
}

const onDeltaFetch = payload => dispatch => {
  dispatch(loadsDataSlice.actions.updateLoads(payload))
  dispatch(onUpdateUi({ lastUpdatedAt: new Date() }))
}

export const onFetch = payload => dispatch => {
  dispatch(onLoadingComplete())
  dispatch(loadsDataSlice.actions.updateAll(payload))
  let pagination = (({ loads, ...pagination }) => pagination)(payload)
  dispatch(
    onUpdateUi({
      dataLoaded: true,
      error: false,
      lastUpdatedAt: new Date(),
      ...pagination,
    }),
  )
}

const onLoadingComplete = () => dispatch => {
  dispatch(onUpdateUi({ loading: false }))
}

const onLoading = () => dispatch => {
  dispatch(onUpdateUi({ loading: true }))
}

const onFetchError = () => dispatch => {
  dispatch(onLoadingComplete())
  toast.error(toastMessages.loadsFetchFail)
}

const loadsActions = {
  onDeltaFetch,
  onFetch,
  onFetchError,
  onLoading,
  onUpdate,
  onUpdateUi,
  onUpdateUiColumn,
  onUpdateUiColumns,
  onUpdateUiFilter,
  onUpdateUiPerPage,
}

export default loadsActions
