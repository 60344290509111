import { useRef, useCallback, useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import {
  ControlledMenu,
  MenuItem,
  SubMenu,
  useMenuState,
} from '@szhsin/react-menu'
import { IonText, IonSpinner } from '@ionic/react'
import { ContainerSearchBar } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'

import './ContextMenu/ContextMenu.scss'

const useHandleContextMenu = props => {
  const { type, onRightClick, onToggleMenu, offsetY, searchable } = props

  const ref = useRef(null)
  const [menuProps, toggleMenu] = useMenuState({ transition: true })
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [searchBarValue, setSearchBarValue] = useState('')

  const isOpen = menuProps.state === 'open'

  const renderMenu = useCallback(
    menu =>
      menu
        .filter(({ hide }) => hide !== true)
        .filter(item => {
          const lowerSearchValue = _.toLower(searchBarValue)
          const lowerLabel = _.toLower(item.label)

          if (_.size(searchBarValue) > 0) {
            return lowerLabel.includes(lowerSearchValue)
          }

          return true
        })
        .map((item, index) => {
          const {
            label,
            classNameLabel,
            className,
            render,
            icon,
            labelProps,
            loading,
            href,
            disabled,
            color,
            subMenu,
            ...itemProps
          } = item

          if (subMenu)
            return (
              <SubMenu
                key={label || index}
                className={clsx(className, 'ContextMenu__itemContainer')}
                label={label}
              >
                {renderMenu(subMenu)}
              </SubMenu>
            )

          return (
            <MenuItem
              key={label || index}
              className={clsx(className, 'ContextMenu__itemContainer')}
              disabled={disabled || loading}
              {...itemProps}
            >
              {render && render()}
              {!render && (
                <>
                  {loading && (
                    <IonSpinner
                      className='ContextMenu__loadingItem'
                      name='lines-small'
                    />
                  )}
                  {!loading && icon}
                  {href && (
                    <Link
                      style={{ color: 'black', textDecoration: 'none' }}
                      to={href}
                    >
                      {label}
                    </Link>
                  )}
                  {!href && (
                    <IonText color={color} {...labelProps}>
                      {label}
                    </IonText>
                  )}
                </>
              )}
            </MenuItem>
          )
        }),
    [searchBarValue],
  )

  const handleChangeSearch = useCallback(nextValue => {
    setSearchBarValue(nextValue)
  }, [])

  const renderSearchbar = useCallback(() => {
    if (searchable) {
      return (
        <div style={{ padding: 12 }}>
          <ContainerSearchBar
            autoFocus
            onClick={event => {
              event.stopPropagation()
            }}
            searchBarValue={searchBarValue}
            onSearchBarChange={handleChangeSearch}
            searchBarParent="contextMenuSearchBar"
          />
        </div>
      )
    }
  }, [handleChangeSearch, searchBarValue, searchable])

  const renderRightClickMenu = useCallback(
    menu => (
      <ControlledMenu
        {...menuProps}
        transition
        anchorPoint={anchorPoint}
        onClose={event => {
          onToggleMenu && onToggleMenu(event, false)
          toggleMenu(false)
        }}
      >
        {renderMenu(menu)}
      </ControlledMenu>
    ),
    [anchorPoint, menuProps, onToggleMenu, renderMenu, toggleMenu],
  )

  const renderClickMenu = useCallback(
    menu => (
      <ControlledMenu
        {...menuProps}
        anchorRef={ref}
        onClose={handleClickMenu(false)}
        offsetY={offsetY}
      >
        {renderSearchbar()}
        <div className='ContextMenu__menuItem'>{renderMenu(menu)}</div>
      </ControlledMenu>
    ),
    [menuProps, handleClickMenu, offsetY, renderSearchbar, renderMenu],
  )

  const renderMenuType = useCallback(
    menu => {
      switch (type) {
        case 'leftClick':
          return renderClickMenu(menu)

        case 'rightClick':
          return renderRightClickMenu(menu)

        default:
          return renderClickMenu(menu)
      }
    },
    [renderClickMenu, renderRightClickMenu, type],
  )

  const handleClickMenu = useCallback(
    () => event => {
      if (_.isFunction(event.stopPropagation)) {
        event.stopPropagation()
      }
      if (type === 'leftClick') {
        if (menuProps.state === 'closed' || !menuProps.state) {
          toggleMenu(true)
          onToggleMenu && onToggleMenu(event, true)
        } else {
          toggleMenu(false)
          onToggleMenu && onToggleMenu(event, false)
        }
      }
    },
    [menuProps?.state, onToggleMenu, toggleMenu, type],
  )

  const handleRightClickMenu = event => {
    if (type === 'rightClick') {
      event.preventDefault()
      setAnchorPoint({ x: event.clientX, y: event.clientY })
      toggleMenu(true)
      onRightClick && onRightClick(event)
    }
  }

  useEffect(() => {
    if (isOpen) {
      const searchElement = document.querySelector(
        'div.contextMenuSearchBar input',
      )
      if (searchElement) {
        searchElement.focus()
      }
    }
  }, [isOpen])

  return {
    renderMenu,
    renderRightClickMenu,
    renderClickMenu,
    renderMenuType,
    handleClickMenu,
    handleRightClickMenu,
    ref,
    menuProps,
    isOpen,
  }
}

export default useHandleContextMenu
