import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICheckAccessesGetResponse } from '~/types/models/ICheck'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryCheckAccesses = (
  options?: Partial<UseQueryOptions<ICheckAccessesGetResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['checkAccesses', sessionUser?.id],
    queryFn() {
      return apiClient.check.getAccesses()
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const checkAccessesData = useMemo(() => data?.results || [], [data?.results])

  return {
    checkAccessesData,
    isCheckAccessesLoading: isLoading,
  }
}

export default useQueryCheckAccesses
