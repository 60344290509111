import { CommonDialogV2 } from '~/components/shared'
import BuyerSellerRegexpForm from './BuyerSellerRegexpForm'

import type { IDialogBuyerSellerRegexpFormProps } from './type'

import './styles.scss'

function DialogBuyerSellerRegexpForm(props: IDialogBuyerSellerRegexpFormProps) {
  const { formData, afterCreate, afterUpdate, hiddenFields, ...modalProps } =
    props

  return (
    <CommonDialogV2
      {...modalProps}
      title='Buyer Seller Regexp'
      isHiddenOkButton
    >
      <BuyerSellerRegexpForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        hiddenFields={hiddenFields}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogBuyerSellerRegexpForm
