import { createSelector } from 'reselect'
import { PHONE_TYPE_OPTIONS } from '~/utils/constants'

export const selectCompanyPhoneNumbersDataState = state =>
  state.data.companyPhoneNumbers

export const selectCompanyPhoneNumbersUiState = state =>
  state.ui.companyPhoneNumbers

export const selectCompanyPhoneNumbersData = createSelector(
  selectCompanyPhoneNumbersDataState,
  state => {
    const data = Object.values(state)
    const mapData = data.map(item => {
      const phoneTypes = []
      item.phoneTypes?.forEach(type => {
        const findType = PHONE_TYPE_OPTIONS.find(
          ({ numValue }) => type === numValue,
        )
        if (findType) {
          phoneTypes.push(findType.value)
        }
      })

      return { ...item, phoneTypes }
    })

    return mapData
  },
)

export const selectCompanyPhoneNumbersLoadingTable = createSelector(
  selectCompanyPhoneNumbersUiState,
  state => state.loadingTable,
)
