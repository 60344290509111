import { useCallback } from 'react'

import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonFooter,
  IonProgressBar,
} from '@ionic/react'
import { ToolTipOverlay, Button } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'
import { closeOutline } from 'ionicons/icons'

import './CommonDialog.scss'

const CommonDialog = props => {
  const {
    open,
    onClose,
    children,
    okText,
    resetText,
    closeText,
    onOk,
    onReset,
    title,
    loading,
    className,
    hideOkButton,
    hideCloseButton,
    hideResetButton,
    hideHeader,
    hideFooter,
    okProps,
    closeProps,
    resetProps,
    contentClassName,
    id,
    headerButtons,
    headerProps,
    ...dialogProps
  } = props

  const renderHeaderButtons = useCallback(() => {
    if (_.size(headerButtons) === 0) {
      return []
    }

    const buttonElements = headerButtons.map(
      ({ icon, tooltip, ...buttonProps }, index) => (
        <ToolTipOverlay content={tooltip} key={index} placement='top'>
          <Button key={index} fill='solid' {...buttonProps} icon={icon} />
        </ToolTipOverlay>
      ),
    )

    return <IonButtons slot='end'>{buttonElements}</IonButtons>
  }, [headerButtons])

  const renderTitle = useCallback(() => {
    if (_.isFunction(title)) {
      return title()
    }

    return title
  }, [title])

  return (
    <IonModal
      {...dialogProps}
      isOpen={open}
      onDidDismiss={onClose}
      cssClass={className}
      id={id}
    >
      {!hideHeader && (
        <IonHeader className='CommonDialog__header' {...headerProps}>
          <div className='CommonDialog__title'>{renderTitle()}</div>

          <div className='CommonDialog__icon'>
            {renderHeaderButtons()}

            {onOk && hideFooter && (
              <IonButtons>
                <Button
                  fill='solid'
                  color='concord'
                  onClick={onOk}
                  {...okProps}
                  disabled={loading || okProps.disabled}
                  label={okText}
                />
              </IonButtons>
            )}

            {onClose && hideCloseButton !== true && (
              <IonButtons>
                <Button
                  onClick={onClose}
                  disabled={loading || closeProps.disabled}
                  color='danger'
                  fill='solid'
                  style={{ paddingRight: 8 }}
                  title='Close Modal'
                  icon={closeOutline}
                />
              </IonButtons>
            )}
          </div>
        </IonHeader>
      )}

      {loading && <IonProgressBar type='indeterminate' />}

      <IonContent className='CommonDialog__content'>{children}</IonContent>

      {!hideFooter && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='end' className='CommonDialog__footerButtons'>
              {onReset && !hideResetButton && (
                <Button
                  color='cloudgrey'
                  fill='solid'
                  onClick={onReset}
                  {...resetProps}
                  disabled={loading || resetProps.disabled}
                  label={resetText}
                />
              )}

              {onClose && !hideCloseButton && (
                <Button
                  color='danger'
                  fill='solid'
                  onClick={onClose}
                  {...closeProps}
                  disabled={loading || closeProps.disabled}
                  label={closeText}
                />
              )}

              {onOk && !hideOkButton && (
                <Button
                  fill='solid'
                  color='concord'
                  onClick={onOk}
                  {...okProps}
                  className={clsx('CommonDialog__okButton', okProps.className)}
                  disabled={loading || okProps.disabled}
                  label={okText}
                />
              )}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  )
}

// CommonDialog.propTypes = {
//   open: PropTypes.bool,
//   okText: PropTypes.string,
//   closeText: PropTypes.string,
//   resetText: PropTypes.string,
//   onClose: PropTypes.func,
//   onOk: PropTypes.func,
//   onReset: PropTypes.func,
//   title: PropTypes.string,
//   loading: PropTypes.bool,
//   className: PropTypes.string,
//   hideOkButton: PropTypes.bool,
//   hideResetButton: PropTypes.bool,
//   hideCloseButton: PropTypes.bool,
//   hideHeader: PropTypes.bool,
//   hideFooter: PropTypes.bool,
//   okProps: PropTypes.object,
//   closeProps: PropTypes.object,
//   resetProps: PropTypes.object,
//   contentClassName: PropTypes.string,
//   headerProps: PropTypes.object,
//   headerButtons: PropTypes.arrayOf(
//     PropTypes.shape({
//       icon: PropTypes.string,
//       color: PropTypes.string.isRequired,
//       tooltip: PropTypes.string,
//       disabled: PropTypes.bool,
//       onClick: PropTypes.func,
//     }),
//   ),
// }

CommonDialog.defaultProps = {
  okText: 'Ok',
  closeText: 'Close',
  resetText: 'Reset',
  okProps: {},
  closeProps: {},
  resetProps: {},
  headerButtons: [],
  hideFooter: true,
}

export default CommonDialog
