import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetAllowedHierarchiesNewResponse } from '~/types/models/IAllowedHierarchy'
import { useMemo } from 'react'
import { ICommonOption } from '~/types/models/ICommonModel'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryAllowedHierarchiesNew = (
  options?: Partial<UseQueryOptions<IGetAllowedHierarchiesNewResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['allowedHierarchiesNew', sessionUser?.id],
    queryFn() {
      return apiClient.allowedHierarchies.new()
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const ahSellerSideOptions = useMemo(() => {
    if (data) {
      const options: ICommonOption[] = data.sellerSideTypes.map(type => ({
        value: type,
        label: _.startCase(type),
      }))
      return options
    }

    return []
  }, [data])

  const ahBuyerSideOptions = useMemo(() => {
    if (data) {
      const options: ICommonOption[] = data.buyerSideTypes.map(type => ({
        value: type,
        label: _.startCase(type),
      }))
      return options
    }

    return []
  }, [data])

  return {
    allowedHierarchiesNewData: data,
    ahSellerSideOptions,
    ahBuyerSideOptions,
    isLoadingAhNewData: isLoading,
  }
}

export default useQueryAllowedHierarchiesNew
