import { useCallback, useMemo } from 'react'
import {
  useQueryCheckEmployeeDetails,
  useQueryCheckContractorDetails,
} from '~/hooks/useQueryData'

import { isStartWithEmp } from '~/utils/constants'
import camelKeyToNormalString from '~/utils/camelKeyToNormalString'

import type { ICheckWorkerInfoSectionProps } from './type'
import buildFullName from '~/utils/buildFullName'
import buildFullAddress from '~/utils/buildFullAddress'
import _ from 'lodash'

const useCheckWorkerInfoSection = (props: ICheckWorkerInfoSectionProps) => {
  const { checkUid, onSelectStep } = props

  const isEmployee = useMemo(() => isStartWithEmp(checkUid), [checkUid])

  const {
    checkEmployeeDetails,
    refetchCheckEmployeeDetails,
    isLoadingCheckEmployee,
  } = useQueryCheckEmployeeDetails(checkUid)

  const {
    checkContractorDetails,
    refetchCheckContractorDetails,
    isLoadingCheckContractor,
  } = useQueryCheckContractorDetails(checkUid)

  const workerDetailsData = useMemo(() => {
    if (isEmployee) {
      return checkEmployeeDetails
    }
    return checkContractorDetails
  }, [checkContractorDetails, checkEmployeeDetails, isEmployee])

  const isLoading = useMemo(
    () => isLoadingCheckEmployee || isLoadingCheckContractor,
    [isLoadingCheckContractor, isLoadingCheckEmployee],
  )

  const refectCheckWorkerDetails = useMemo(() => {
    if (isEmployee) {
      return refetchCheckEmployeeDetails
    }
    return refetchCheckContractorDetails
  }, [isEmployee, refetchCheckContractorDetails, refetchCheckEmployeeDetails])

  const onClickStep = useCallback(
    (key: string) => () => {
      onSelectStep && onSelectStep(key)
    },
    [onSelectStep],
  )

  const renderCheckWorkerOnboardStatus = useMemo(() => {
    if (workerDetailsData?.onboard) {
      return (
        <ul>
          <li>
            <span className='key'>Status: </span>
            <span>{_.startCase(workerDetailsData.onboard?.status)}</span>
          </li>
          {workerDetailsData.onboard.remainingSteps.length > 0 && (
            <li>
              <span className='key'>Remaining steps: </span>
              <span>
                <ul>
                  {workerDetailsData.onboard.remainingSteps.map(
                    (step, index) => (
                      <li key={index}>
                        <span
                          className='hyperLink'
                          onClick={onClickStep(_.camelCase(step))}
                        >
                          {camelKeyToNormalString(step)}
                        </span>
                        {step === 'payment_method' &&
                          workerDetailsData.onboard.paymentMethod && (
                            <ul>
                              {workerDetailsData.onboard.paymentMethod.map(
                                (key, index) => (
                                  <li key={index}>{_.startCase(key)}</li>
                                ),
                              )}
                            </ul>
                          )}

                        {step === 'ssn' && workerDetailsData.onboard.ssn && (
                          <ul>
                            {workerDetailsData.onboard.ssn.map((key, index) => (
                              <li key={index}>{_.startCase(key)}</li>
                            ))}
                          </ul>
                        )}

                        {step === 'withholdings' &&
                          workerDetailsData.onboard.withholdings && (
                            <ul>
                              {workerDetailsData.onboard.withholdings.map(
                                ({ name, status }, index) => (
                                  <li key={index}>
                                    {name} - {_.startCase(status)}
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                      </li>
                    ),
                  )}
                </ul>
              </span>
            </li>
          )}
          {workerDetailsData.onboard.blockingSteps.length > 0 && (
            <li>
              <span className='key'>Blocking steps: </span>
              <span>
                <ul>
                  {workerDetailsData.onboard.blockingSteps.map(
                    (step, index) => (
                      <li key={index}>{camelKeyToNormalString(step)}</li>
                    ),
                  )}
                </ul>
              </span>
            </li>
          )}
        </ul>
      )
    }
    return null
  }, [workerDetailsData, onClickStep])

  const renderCheckWorkerInfo = useMemo(() => {
    if (workerDetailsData) {
      return [
        {
          label: 'Fullname',
          value: buildFullName(workerDetailsData),
        },
        {
          label: 'Email',
          value: workerDetailsData.email,
        },
        {
          label: 'Address',
          value: buildFullAddress(
            workerDetailsData.residence || workerDetailsData.address,
          ),
        },
        {
          label: 'EIN',
          value: workerDetailsData.ein,
          isHidden: isEmployee,
        },
        {
          label: 'Business name',
          value: workerDetailsData.businessName,
          isHidden: isEmployee,
        },
        {
          label: 'Type',
          value: workerDetailsData.type,
          isHidden: isEmployee,
        },
        {
          label: 'Date of birth',
          value: workerDetailsData.dob,
        },
        {
          label: 'Start date',
          value: workerDetailsData.startDate,
        },
        {
          label: 'Onboard',
          value: renderCheckWorkerOnboardStatus,
        },
      ]
    }

    return []
  }, [workerDetailsData, isEmployee, renderCheckWorkerOnboardStatus])

  return {
    renderCheckWorkerInfo,
    renderCheckWorkerOnboardStatus,
    workerDetailsData,
    isLoading,
    refectCheckWorkerDetails,
  }
}

export default useCheckWorkerInfoSection
