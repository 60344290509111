import React from 'react'

/// fix re-rendered issue by moving customInput into a separate file
const CustomInput = React.forwardRef((props, ref) => {
  return <input {...props} ref={ref} />
})

CustomInput.propTypes = {}

export default CustomInput
