import { CommonDialogV2 } from '~/components/shared'
import FlagableConfigurationsForm from './FlagableConfigurationsForm'

function DialogFlagableConfigurationsForm(props) {
  const { formData, afterCreate, afterUpdate, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title="Flagable Configurations"
      isHiddenOkButton
    >
      <FlagableConfigurationsForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogFlagableConfigurationsForm
