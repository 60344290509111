const DEFAULT_COMPANY_REFERENCE_NAME_COLUMNS = [
  {
    displayIndex: 0,
    sortIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    sortIndex: 1,
    label: 'Buyer',
    field: 'buyer.name',
    show: true,
    value: 'buyer.name',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'buyer.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    sortIndex: 2,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    sortIndex: 3,
    label: 'Item Type',
    field: 'itemType',
    show: true,
    value: 'itemType',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'itemType',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    sortIndex: 4,
    label: 'Item ID',
    field: 'itemId',
    show: true,
    value: 'itemId',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'itemId',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    sortIndex: 5,
    label: 'Reference Name',
    field: 'referenceName',
    show: true,
    value: 'referenceName',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'referenceName',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    sortIndex: 6,
    label: 'Reference Code',
    field: 'referenceCode',
    show: true,
    value: 'referenceCode',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'referenceCode',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 7,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_REFERENCE_NAME_COLUMNS
