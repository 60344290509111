import Immutable from 'immutable'

import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  terminal: undefined,
  loading: false,
  error: undefined,
  coordinates: [],
  updateSuccessfully: false,
})

const terminalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TERMINAL: {
      let newState = state.set('loading', true)
      newState = newState.set('error', undefined)
      newState = newState.set('terminal', undefined)
      newState = newState.set('coordinates', [])
      newState = newState.set('updateSuccessfully', false)

      return newState
    }

    case types.FETCH_TERMINAL_SUCCESS: {
      const { terminal } = action
      let newState = state.set('terminal', terminal)
      newState = newState.set('coordinates', terminal.coordinates)
      newState = newState.set('loading', false)

      return newState
    }

    case types.UPDATE_TERMINAL_COORDINATES: {
      let newState = state.set('loading', true)
      newState = newState.set('updateSuccessfully', false)

      return newState.set('error', undefined)
    }

    case types.UPDATE_TERMINAL_COORDINATES_SUCCESS: {
      const { coordinates } = action
      let newState = state.set('coordinates', coordinates)
      newState = newState.set('updateSuccessfully', true)
      newState = newState.set('loading', false)

      return newState
    }

    case types.UPDATE_TERMINAL_COORDINATES_FAILURE:
    case types.FETCH_TERMINAL_FAILURE: {
      const { error } = action
      const newState = state.set('error', error)

      return newState.set('loading', false)
    }

    case types.TERMINAL_SET_COORDINATES: {
      return state.set('coordinates', action.payload)
    }

    default: {
      return state
    }
  }
}

export default terminalReducer
