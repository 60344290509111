import { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useTableCompanyParserSubTab from './useTableCompanyParserSubTab'
import { useConfirmationProvider } from '~/contexts'
import { useWindowSize } from 'react-use'

import { DeleteIcon, EditIcon, ReusableTable } from '~/components/shared'

import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { moduleName } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers, useQueryDataParsers } from '~/hooks/useQueryData'
import useFilterViewOption from '~/hooks/useFilterViewOption'
import { DialogDataParserForm } from '~/components/shared'

function CompanyParserSubTab() {
  const { filterViewOption, getFilterData, ALL } = useFilterViewOption()

  const currentCompany = useSelector(selectMyCurrentCompany)

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'view',
      value: EScope.company,
    },
  ])

  const filterData = getFilterData(columnFilters)

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const { allCompanyOptions, isFetchedBuyerSellers, isLoadingBuyerSellers } =
    useQueryBuyerSellers()

  const filterOptions = [filterViewOption()]

  const companyId = useMemo(() => {
    if (filterData.view === ALL) {
      return [currentCompany.id, ...allCompanyOptions.map(({ value }) => value)]
    }
    if (filterData.view === EScope.company) {
      return [currentCompany.id]
    }
    return allCompanyOptions.map(({ value }) => value)
  }, [ALL, allCompanyOptions, currentCompany.id, filterData.view])

  const {
    dataParsers,
    isLoadingDataParsers,
    addDataParser,
    updateDataParser,
    removeDataParser,
  } = useQueryDataParsers(
    {
      filters: {
        companyId: companyId,
      },
    },
    { enabled: isFetchedBuyerSellers },
  )

  const isTableLoading = isLoadingBuyerSellers || isLoadingDataParsers

  const handleOpenUpdateForm = useCallback(rowData => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const handleDeleteRow = useCallback(async rowData => {
    setLoading(true)
    try {
      const result = await confirmation({
        message: `Are you sure you want to delete parser #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        apiClient.dataParsers.delete(rowData.id)
        removeDataParser(rowData.id)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.deleteError)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const onCellEditEnd = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const dataParser = { [columnField]: value }
      const response = await apiClient.dataParsers.update(rowId, dataParser)
      if (_.size(response.errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        toast.success(toastMessages.useDataParser.updateSuccess)
        updateDataParser(response.id, response)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { columns } = useTableCompanyParserSubTab()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableRowActions
          enableCompanyView
          enableColumnPinning
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 80,
              maxSize: 80,
            },
          }}
          companyViewProps={{
            name: moduleName.company.parsers,
            description:
              'Different parsing methodologies have better outcomes for different document types.',
            onClickTopButton() {
              setFormState({
                isOpen: true,
                formData: undefined,
              })
            },
          }}
          columns={columns}
          data={dataParsers}
          tableHeight={windowSize.height - 220}
          state={{
            isLoading: isTableLoading,
            columnFilters,
            showProgressBars: loading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenUpdateForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogDataParserForm
        isOpen={formState.isOpen}
        onClose={onCloseForm}
        formData={formState.formData}
        afterUpdate={newParser => {
          updateDataParser(newParser.id, newParser)
          onCloseForm()
        }}
        afterCreate={newParser => {
          addDataParser(newParser)
          onCloseForm()
        }}
      />
    </>
  )
}

CompanyParserSubTab.propTypes = {}

export default CompanyParserSubTab
