import { createSlice } from '@reduxjs/toolkit'

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {},
  reducers: {
    initializeCompanies: (_state, action) => {
      const newState = {}

      action.payload.companies.forEach((company, index) => {
        newState[company.id] = { ...company, index }
      })

      return newState
    },
    updateCompanies: (state, action) => {
      action.payload.forEach(company => {
        state[company.id] = {
          ...state[company.id],
          ...company,
        }
      })
    },
    updateCompany: (state, action) => {
      const { payload } = action
      if (payload?.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = payload
      }
    },
    addCompany(state, { payload }) {
      const company = {
        id: payload.id,
        code: payload.code,
        name: payload.name,
        request: true,
        attributes: payload,
        ticketTemplate: payload.ticketTemplate,
        invoiceTemplate: payload.invoiceTemplate,
        logo: {
          url: payload.logo || payload.imageUrl,
        },
      }
      state[payload.id] = company
    },
  },
})

export default companiesSlice
