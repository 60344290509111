import { Button } from 'react-bootstrap'
import { FloatingInput } from '../../FloatingForm/Input'
import { useMemo } from 'react'
import { DeleteIcon, PlusIcon } from '~/components/shared'

export const ExtrasFormRangesRow = ({
  name,
  control,
  index,
  hideRange,
  onAdd,
  onRemove,
}) => {
  const prefix = useMemo(() => `${name}[${index}]`, [name, index])

  return (
    <div className='pb-1 d-flex gap-1 align-items-center'>
      <FloatingInput
        control={control}
        inputType='number'
        label='QTY'
        name={`${prefix}.qty`}
        placeholder='QTY'
      />

      {!hideRange && (
        <FloatingInput
          control={control}
          inputType='number'
          label='Start Load'
          name={`${prefix}.startLoad`}
          placeholder='Start Load'
          min={1}
        />
      )}

      {!hideRange && (
        <FloatingInput
          control={control}
          inputType='number'
          label='End Load'
          name={`${prefix}.endLoad`}
          placeholder='End Load'
          min={1}
        />
      )}

      <Button onClick={onAdd} className='ms-auto'>
        <PlusIcon color='#fff' />
      </Button>
      <Button
        onClick={() => {
          onRemove(index)
        }}
        variant='danger'
      >
        <DeleteIcon color='#fff' />
      </Button>
    </div>
  )
}
