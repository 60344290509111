import posthog from 'posthog-js'

export const postHogEnabled = () => {
  return process.env.POST_HOG_ENABLE === 'TRUE'
}

export const postHogInit = () => {
  if (postHogEnabled()) {
    posthog.init(process.env.POST_HOG_KEY, {
      api_host: process.env.POST_HOG_API,
    })
  }
}

export const postHogCapture = (event, data) => {
  if (!postHogEnabled()) return
  posthog.capture(event, data)
}
