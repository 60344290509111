import { useMemo, useCallback } from 'react'

import clsx from 'clsx'

import './CompanyTableButtonTabs.scss'

function CompanyTableButtonTabs(props) {
  const { tabs, selectedTab, onChange } = props

  const handleChangeTab = useCallback(
    tab => () => {
      onChange && onChange(tab)
    },
    [onChange],
  )

  const subTabRendered = useMemo(() => {
    return tabs.map((tab, index) => (
      <button
        key={index}
        className={clsx('CompanyTableButtonTabs__item', {
          active: tab.value === selectedTab.value,
        })}
        onClick={handleChangeTab(tab)}
      >
        {tab.label}
      </button>
    ))
  }, [tabs, selectedTab.value, handleChangeTab])

  return (
    <div className='CompanyTableButtonTabs__container'>{subTabRendered}</div>
  )
}

CompanyTableButtonTabs.propTypes = {}

export default CompanyTableButtonTabs
