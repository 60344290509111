import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

export interface IPayrollJournalTableProps {
  data: any
}

function TaxLiabilityReportTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Payday',
        accessorKey: 'payday',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'Period Start',
        accessorKey: 'periodStart',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'Period End',
        accessorKey: 'periodEnd',
        align: 'center',
        minSize: 120,
        maxSize: 120,
      },
      {
        header: 'FICA',
        accessorKey: 'FICA',
        minSize: 160,
        maxSize: 160,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'FICA',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'Employer FICA Tax',
        accessorKey: 'Employer FICA Tax',
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'Employer FICA Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'Medicare',
        accessorKey: 'Medicare',
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'Medicare',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'Employer Medicare Tax',
        accessorKey: 'Employer Medicare Tax',
        minSize: 200,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'Employer Medicare Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York MCTMT Employer Payroll Tax',
        accessorKey: 'New York MCTMT Employer Payroll Tax',
        minSize: 250,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) =>
              description === 'New York MCTMT Employer Payroll Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York State Unemployment Tax',
        accessorKey: 'New York State Unemployment Tax',
        minSize: 230,
        maxSize: 230,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) =>
              description === 'New York State Unemployment Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York Reemployment Fund',
        accessorKey: 'New York Reemployment Fund',
        minSize: 230,
        maxSize: 230,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) =>
              description === 'New York Reemployment Fund',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'Additional Medicare',
        accessorKey: 'Additional Medicare',
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'Additional Medicare',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'Federal Income Tax',
        accessorKey: 'Federal Income Tax',
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'Federal Income Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York State Tax',
        accessorKey: 'New York State Tax',
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'New York State Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York Paid Family Leave Insurance',
        accessorKey: 'New York Paid Family Leave Insurance',
        minSize: 250,
        maxSize: 250,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) =>
              description === 'New York Paid Family Leave Insurance',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York SDI',
        accessorKey: 'New York SDI',
        minSize: 160,
        maxSize: 160,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'New York SDI',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
      {
        header: 'New York City Tax',
        accessorKey: 'New York City Tax',
        minSize: 160,
        maxSize: 160,
        Cell({ row: { original } }) {
          const obj = original.liabilities.find(
            ({ description }: any) => description === 'New York City Tax',
          )
          if (obj) {
            return `${obj.amount} - ${obj.payer}`
          }
          return '-'
        },
      },
    ],
    [],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default TaxLiabilityReportTable
