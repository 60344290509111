import { isValidElement, useMemo } from 'react'
import { IonIcon, IonButton } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import { ionicColor } from '../helpers'
import _ from 'lodash'
import { ToggleButton } from 'react-bootstrap'

const MultiselectOrderToggle = ({
  contentTooltip,
  icon,
  isButtonGroup,
  isMyCompanyPage,
  onToggleDropdown,
  tooltipPlacement = 'bottom',
  tooltipProps,
  showToolTip = true,
  tooltipTitle = 'SORT BY',
}) => {
  const tooltipRendered = useMemo(() => {
    if (showToolTip) {
      return (
        <div>
          <span>
            <strong>{tooltipTitle}</strong>
          </span>
          <br />
          <div style={{ textAlign: 'left' }}>{contentTooltip}</div>
        </div>
      )
    }

    return null
  }, [contentTooltip, showToolTip, tooltipTitle])

  const iconRendered = useMemo(() => {
    if (isValidElement(icon)) {
      return icon
    }

    if (_.isString(icon)) {
      return (
        <IonIcon
          className='Filter__icon'
          style={{ color: isMyCompanyPage ? 'black' : '' }}
          icon={icon}
          size='small'
          title=''
        />
      )
    }
  }, [isMyCompanyPage, icon])

  return (
    <ToolTipOverlay
      placement={tooltipPlacement}
      content={tooltipRendered}
      delay={{ show: 250, hide: 400 }}
      {...tooltipProps}
    >
      {isButtonGroup ? (
        <ToggleButton
          type='radio'
          name='narrow'
          variant='outline-primary'
          onClick={onToggleDropdown}
        >
          {iconRendered}
        </ToggleButton>
      ) : (
        <IonButton
          className='button-clear'
          style={{ '--box-shadow': 'none' }}
          color={isMyCompanyPage ? ionicColor.snowGrey : ionicColor.dark}
          onClick={onToggleDropdown}
        >
          {iconRendered}
        </IonButton>
      )}
    </ToolTipOverlay>
  )
}

export default MultiselectOrderToggle
