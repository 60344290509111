import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'

export const peopleSlice = createSlice({
  name: 'people',
  initialState: {},
  reducers: {
    index(_state, action) {
      const newState = {}
      action.payload.forEach(person => {
        newState[person.id] = person
      })
      return newState
    },
    add(state, { payload }) {
      state[payload.id] = payload
    },
    update(state, { payload }) {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export const selectPeople = state => state.data.people
export const selectPeopleList = state => Object.values(state.people || {})

export const fetchPeople = createAsyncThunk(
  'people/fetch',
  async (params = {}, { dispatch }) => {
    try {
      const { people } = await apiClient.people.get(params)
      dispatch(peopleSlice.actions.index(people))
    } catch (error) {
      toast.error(error.message)
    }
  },
)

export const updatePerson = createAsyncThunk(
  'people/update',
  async (person, { dispatch }) => {
    try {
      const updatedPerson = await apiClient.people.update(person.id, {
        person: person,
      })
      dispatch(peopleSlice.actions.update(updatedPerson))
      return updatedPerson
    } catch (error) {
      toast.error(error.message)
    }
  },
)
