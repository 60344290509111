import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IWorkForceFormProps } from './type'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { IWorkForceFormValues } from '~/types/models/IWorkForce'

const WorkForceForm = (props: IWorkForceFormProps) => {
  const { afterCreate, afterUpdate, formData, className, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Code',
        name: 'code',
        isRequired: true,
        size: 4,
      },
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
        size: 4,
      },
      {
        label: 'Order Color',
        name: 'orderColor',
        size: 4,
        type: EFieldType.color,
      },
      {
        label: 'Std Rate Per Hour',
        name: 'stdRatePerHr',
        type: EFieldType.number,
        size: 6,
        placeholder: '0.01',
      },
      {
        label: 'OT Rate Per Hour',
        name: 'otRatePerHr',
        type: EFieldType.number,
        size: 6,
        placeholder: '0.01',
      },
      {
        label: 'Active',
        name: 'active',
        type: EFieldType.checkbox,
      },
    ],
    [],
  )

  const defaultValues: IWorkForceFormValues = {
    code: '',
    name: '',
    orderColor: null,
    stdRatePerHr: null, // 0.01
    otRatePerHr: null, // 0.01
    active: true,
    companyId: currentCompany.id,
  }

  const schema = useMemo(
    () =>
      Yup.object({
        code: Yup.string().required('This field is required'),
        name: Yup.string().required('This field is required'),
        stdRatePerHr: Yup.number()
          .nullable()
          .min(0.01)
          .transform(value => (Number.isNaN(value) ? null : value)),
        otRatePerHr: Yup.number()
          .nullable()
          .min(0.01)
          .transform(value => (Number.isNaN(value) ? null : value)),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IWorkForceFormValues) => {
      const payload = _.pick(formValues, [
        'code',
        'name',
        'orderColor',
        'stdRatePerHr',
        'otRatePerHr',
        'active',
        'companyId',
      ])
      const { errors, ...response } = await apiClient.workForces.create({
        workForce: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IWorkForceFormValues) => {
      if (formData?.id) {
        const payload = _.pick(formValues, [
          'code',
          'name',
          'orderColor',
          'stdRatePerHr',
          'otRatePerHr',
          'active',
        ])
        const { errors, ...response } = await apiClient.workForces.update(
          formData.id,
          {
            workForce: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IWorkForceFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <ConcordFormStructure
        className={className}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
        {...formProps}
      />
    </div>
  )
}

export default WorkForceForm
