import { useCallback } from 'react'

import TimeField from 'react-simple-timefield'

import '../DatePickerWithTimeInput.scss'

const DatePickerTimeInput = ({ value, onChange }) => {
  const handleChangeTime = useCallback(
    (event, nextTime) => {
      onChange(nextTime)
    },
    [onChange],
  )

  return (
    <TimeField
      autoFocus
      className='DatePickerWithTimeInput__timeInput'
      onChange={handleChangeTime}
      value={value}
    />
  )
}

export default DatePickerTimeInput
