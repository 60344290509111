import { useState, useEffect, useMemo, useCallback } from 'react'

import ReactPaginate from 'react-paginate'
import { IonText, IonIcon } from '@ionic/react'
import { Dropdown } from 'react-bootstrap'
import { ToolTipOverlay, ToolbarSelectedRows } from '~/components/shared'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { PER_PAGE_FIELDS } from '~/constants/loads/filters'
import { chevronForwardOutline } from 'ionicons/icons'

import './styles.scss'

const FooterActionButtons = props => {
  const { buttonActions } = props

  const [isOpenButtons, setIsOpenButtons] = useState(true)

  const toggleShowHideAction = useCallback(() => {
    setIsOpenButtons(prev => !prev)
  }, [])

  return (
    <div
      className={clsx('DataGridFooter__actionButtons', {
        collapsed: !isOpenButtons,
      })}
    >
      <div
        className='DataGridFooter__collapseExpand'
        onClick={toggleShowHideAction}
      >
        <IonIcon
          icon={chevronForwardOutline}
          className={clsx('DataGridFooter__collapseExpandIcon', {
            isOpenButtons,
          })}
        />
      </div>
      <div className='DataGridFooter__buttonActions'>
        <ToolbarSelectedRows
          className={clsx('DataGridFooter__toolbarSelectedRows', {
            collapsed: !isOpenButtons,
          })}
          buttons={buttonActions}
        />
      </div>
    </div>
  )
}

const DataGridFooter = props => {
  const {
    perPage,
    totalItems,
    currentPage,
    onChangePage,
    onChangePerPage,
    renderTotalText,
    className,
    loading,
    selectedRows,
    buttonActions,
    renderElementNextToPerPage,
  } = props

  const [page, setPage] = useState(1)
  const [currentPerPage, setCurrentPerPage] = useState(12)

  const totalPage = useMemo(
    () => (!totalItems ? 1 : Math.ceil(totalItems / perPage)),
    [totalItems, perPage],
  )

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    setCurrentPerPage(perPage)
  }, [perPage])

  const renderDropdownItem = () =>
    PER_PAGE_FIELDS.map(item => {
      return (
        <Dropdown.Item
          key={item.value}
          value={item.value}
          onClick={handleChangePerPage(item.value)}
        >
          {item.label}
        </Dropdown.Item>
      )
    })

  const handleChangePage = ({ selected }) => {
    const nextPage = selected + 1
    setPage(nextPage)
    onChangePage && onChangePage(null, nextPage)
  }

  const handleChangePerPage = selected => event => {
    setCurrentPerPage(selected)
    onChangePerPage && onChangePerPage(event, selected)
  }

  const itemsOfTotalItems = useMemo(
    () =>
      perPage >= totalItems
        ? `${totalItems} of ${totalItems}`
        : `${perPage} of ${totalItems}`,
    [perPage, totalItems],
  )

  const isOpenActionButton = selectedRows.length > 0 && buttonActions.length > 0

  return (
    <div
      className={clsx('DataGridFooter__container', className, {
        disabled: loading,
      })}
    >
      {isOpenActionButton && (
        <FooterActionButtons buttonActions={buttonActions} />
      )}

      <div
        className={clsx('DataGridFooter__rightSection', {
          isOpenActionButton,
        })}
      >
        {renderTotalText && renderTotalText()}
        {!renderTotalText && <IonText>{itemsOfTotalItems}</IonText>}
      </div>

      <div
        className={clsx('DataGridFooter__paginate', {
          isOpenActionButton,
        })}
      >
        <ReactPaginate
          onPageChange={handleChangePage}
          forcePage={Number(page) - 1}
          pageRangeDisplayed={1}
          pageCount={totalPage}
          renderOnZeroPageCount={null}
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          breakLabel='...'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          containerClassName='pagination Pagination__container'
          activeClassName='active'
          previousLabel={
            <ToolTipOverlay content='Previous'>
              <div>&lt;</div>
            </ToolTipOverlay>
          }
          nextLabel={
            <ToolTipOverlay content='Next'>
              <div>&gt;</div>
            </ToolTipOverlay>
          }
        />
      </div>

      <div className='DataGridFooter__leftSection'>
        {renderElementNextToPerPage()}
        <div className='DataGridFooter__perPage'>
          <Dropdown direction='up' onChange={handleChangePerPage}>
            <Dropdown.Toggle onChange={handleChangePerPage}>
              <b>Per Page:</b> {currentPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu className='active'>
              {renderDropdownItem()}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

DataGridFooter.propTypes = {
  perPage: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  className: PropTypes.string,
  onChangePage: PropTypes.func,
  onChangePerPage: PropTypes.func,
  renderTotalText: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.oneOf(['dropdown', 'button']),
    }),
  ),
  renderElementNextToPerPage: PropTypes.func,
}

DataGridFooter.defaultProps = {
  selectedRows: [],
  buttonActions: [],
  renderElementNextToPerPage: () => {},
}

export default DataGridFooter
