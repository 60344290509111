import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_COMPANY_BUYER_COLUMNS } from '~/constants/companySubTabs'
import { moduleName } from '~/utils/constants'

const buyersSlice = createSlice({
  name: moduleName.company.buyers,
  initialState: {
    columns: DEFAULT_COMPANY_BUYER_COLUMNS,
  },
})

export default buyersSlice
