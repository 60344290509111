import { useContext } from 'react'

import LoadFormContext from './LoadFormContext'

const useLoadFormProvider = () => {
  const context = useContext(LoadFormContext)
  return context
}

export default useLoadFormProvider
