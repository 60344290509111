import { createSlice } from '@reduxjs/toolkit'

export const driversUiSlice = createSlice({
  name: 'drivers',
  initialState: {
    endOfTheList: false,
    loading: undefined,
    loadingMore: undefined,
    viewMode: 'list',
    lastUpdatedAt: null,
  },
  reducers: {
    // index: (state, action) => {
    //   const newState = {}
    //   action.payload.drivers.forEach(driver => {
    //     newState[driver.id] = driver
    //   })
    //   return newState
    // },
    updateUi: (state, action) => {
      state[action.payload.id] = action.payload //.id or something else?
    },
    updateUiMode: (state, { payload }) => {
      state.viewMode = payload
    },
  },
})
