import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const trucksSlice = createSlice({
  name: 'trucks',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(truck => {
        newState[truck.id] = truck
      })
      return newState
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export const fetchTrucks = createAsyncThunk(
  'trucks/fetchtrucks',
  async (params = {}, { dispatch }) => {
    try {
      const { trucks } = await apiClient.trucks.get(params)
      dispatch(trucksSlice.actions.index(trucks))
      return trucks
    } catch (error) {
      console.log(error)
    }
  },
)
