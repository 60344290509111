import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetShiftPayRowsParams,
  IGetShiftPayRowsResponse,
} from '~/types/models/IShiftPayRow'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryShiftPayRows = (
  params: IGetShiftPayRowsParams,
  options?: Partial<UseQueryOptions<IGetShiftPayRowsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'shiftPayRows',
      sessionUser?.id,
      buildGetUrl(apiClient.shiftPayRows.endpoint, params),
    ],
    queryFn() {
      return apiClient.shiftPayRows.get(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const shiftPayRowsData = useMemo(
    () => data?.shiftPayRows || [],
    [data?.shiftPayRows],
  )

  return {
    shiftPayRowsData,
    isLoadingShiftPayRowsData: isLoading,
  }
}

export default useQueryShiftPayRows
