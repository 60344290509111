import { ITimeRangeProps } from './type'

import './styles.scss'

const TimeRange: React.FC<ITimeRangeProps> = props => {
  const { value = { startTime: '', endTime: '' }, onChange } = props

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange &&
      onChange({
        startTime: event.target.value,
        endTime: value.endTime,
      })
  }

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange &&
      onChange({
        startTime: value.startTime,
        endTime: event.target.value,
      })
  }

  return (
    <div className='TimeRange__container'>
      <label htmlFor='startTime'>From:</label>
      <input
        type='time'
        id='startTime'
        value={value.startTime}
        onChange={handleStartTimeChange}
      />

      <label htmlFor='endTime' style={{ marginLeft: 8 }}>
        To:
      </label>
      <input
        type='time'
        id='endTime'
        value={value.endTime}
        onChange={handleEndTimeChange}
      />
    </div>
  )
}

export default TimeRange
