import React from 'react'
import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { receiptOutline, reader, helpOutline } from 'ionicons/icons'
import { DOCUMENT_DOC_TYPES } from './helpers'

const DocTypeCol = ({ docType, onShowDocType }) => {
  const label =
    typeof docType === 'number'
      ? DOCUMENT_DOC_TYPES.find(({ value }) => value === docType)?.label
      : docType

  const obj = {
    Load: {
      color: 'danger',
      icon: receiptOutline,
    },
    Invoice: {
      color: 'tertiary',
      icon: reader,
    },
    default: {
      color: 'primary',
      icon: helpOutline,
    },
  }

  const currentObj = obj[label] || obj.default

  return (
    <IonChip color={currentObj.color} onClick={onShowDocType}>
      <IonIcon icon={currentObj.icon} style={{ marginLeft: 1 }} />
      <IonLabel>{label}</IonLabel>
    </IonChip>
  )
}

export default DocTypeCol
