import React from 'react'

import { IonButton, IonIcon, IonButtons, IonLabel } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'

import { closeOutline } from 'ionicons/icons'
import clsx from 'clsx'

const ClearFiltersButton = React.forwardRef((props, ref) => {
  const { onFiltersReset, label, icon, className, tooltip } = props

  return (
    <ToolTipOverlay content={tooltip} placement='bottom'>
      <div className={clsx(' hide-md-down', className)} ref={ref}>
        <IonButtons size='lg'>
          <IonButton fill='clear' onClick={onFiltersReset}>
            {label && <IonIcon icon={icon} slot='end' />}
            {!label && <IonIcon icon={icon} />}
            {label && (
              <IonLabel className='container-filters-ionlabel'>
                {label}
              </IonLabel>
            )}
          </IonButton>
        </IonButtons>
      </div>
    </ToolTipOverlay>
  )
})

ClearFiltersButton.defaultProps = {
  label: 'Clear filters',
  icon: closeOutline,
}

export default ClearFiltersButton
