import { createSelector } from 'reselect'

export const selectcheckPayrollItemsDataState = state =>
  state.data.checkPayrollItems

export const selectcheckPayrollItemsUiState = state =>
  state.ui.checkPayrollItems

export const selectcheckPayrollItemsData = createSelector(
  selectcheckPayrollItemsDataState,
  state => Object.values(state),
)

export const selectcheckPayrollItemsLoading = createSelector(
  selectcheckPayrollItemsUiState,
  state => state.loading,
)
