export const ORDER_TRACKING_SORT_OPTIONS = [
  {
    label: 'Date',
    value: 'startTime',
    sortField: 'startTime',
    type: 'dateTime',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
  {
    label: 'Buyer Terminal',
    value: 'buyerTerminalId',
    sortField: 'buyerTerminalId',
    type: 'int',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
  {
    label: 'Seller Terminal',
    value: 'sellerTerminalId',
    sortField: 'sellerTerminalId',
    type: 'int',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
  {
    label: 'Fleet',
    value: 'companyId',
    sortField: 'companyId',
    type: 'int',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
  {
    label: 'Buyer',
    value: 'buyerId',
    sortField: 'buyerId',
    type: 'int',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
  {
    label: 'Seller',
    value: 'sellerId',
    sortField: 'sellerId',
    type: 'int',
    sorted: false,
    isAsc: true,
    isHidden: false,
  },
]
