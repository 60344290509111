import { useMemo, useCallback, useState } from 'react'
import { useQueryWorkers } from '~/hooks/useQueryData'

import { ConcordFormStructure } from '~/components/shared'

import * as Yup from 'yup'
import { FORM_FIELD_TYPE, isStartWithEmp } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import getErrorFromCheckResponse from '~/utils/getErrorFromCheckResponse'

const EarningRateForm = props => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const { workerOptionsWithCheckUid } = useQueryWorkers()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const periodOptions = useMemo(
    () => [
      {
        label: 'Hourly',
        value: 'hourly',
      },
      {
        label: 'Annually',
        value: 'annually',
      },
      {
        label: 'Piece',
        value: 'piece',
      },
    ],
    [],
  )

  const sortWorkerOptions = useMemo(
    () =>
      workerOptionsWithCheckUid
        .filter(({ checkUid }) => checkUid && isStartWithEmp(checkUid))
        .map(({ label, checkUid }) => ({
          label,
          value: checkUid,
        })),
    [workerOptionsWithCheckUid],
  )

  const fields = useMemo(
    () => [
      {
        label: 'Employee',
        name: 'employee',
        isRequired: true,
        options: sortWorkerOptions,
        isDisabled: isUpdating,
        type: FORM_FIELD_TYPE.singleSelect,
      },
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
      },
      {
        label: 'Period',
        name: 'period',
        isRequired: true,
        options: periodOptions,
        isDisabled: isUpdating,
        type: FORM_FIELD_TYPE.singleSelect,
      },
      {
        label: 'Amount',
        name: 'amount',
        isRequired: true,
        isDisabled: isUpdating,
        type: FORM_FIELD_TYPE.number,
      },
    ],
    [isUpdating, periodOptions, sortWorkerOptions],
  )

  const defaultValues = useMemo(
    () => ({
      employee: '',
      name: '',
      period: '',
      amount: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        employee: Yup.string().required('Employee is required!').nullable(),
        name: Yup.string()
          .required('Name is required!')
          .typeError('Name is required!'),
        period: Yup.string().required('Period is required!'),
        amount: Yup.number()
          .required('Amount is required!')
          .typeError('Amount is required!'),
      }),
    [],
  )

  const updateEarningRate = useCallback(
    async payload => {
      const { active, name, id, employeeId } = payload
      const { status } = await apiClient.check.updateEarningRate(id, {
        earningRate: {
          active,
          name,
          metadata: {
            workerId: employeeId,
          },
        },
      })
      if (status === 200) {
        afterUpdate && afterUpdate(payload)
        toast.success(toastMessages.updateSuccess)
      } else {
        toast.error(toastMessages.updateError)
      }
    },
    [afterUpdate],
  )

  const createEarningRate = useCallback(
    async payload => {
      const response = await apiClient.check.createEarningRate({
        earningRate: payload,
      })
      const error = getErrorFromCheckResponse(response)
      if (error) {
        setError(error)
      } else {
        afterCreate && afterCreate(response)
      }
    },
    [afterCreate],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await updateEarningRate(formData)
        } else {
          await createEarningRate(formData)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createEarningRate, isUpdating, updateEarningRate],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      error={error}
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default EarningRateForm
