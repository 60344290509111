import _ from 'lodash'

export const formatVariableName = (name: string) => {
  if (name) {
    if (_.startsWith(name, '@')) {
      return name
    }
    return `@${name}`
  }

  return ''
}
