import { IonIcon, IonText } from '@ionic/react'

import { caretUp, caretDown } from 'ionicons/icons'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './ZoomButton.scss'

const ZoomButton = props => {
  const {
    value,
    onZoomIn,
    onZoomOut,
    zoomInButtonProps,
    zoomOutButtonProps,
    className,
  } = props

  return (
    <div className={clsx('ZoomButton__container', className)}>
      <IonText style={{ fontSize: 12 }}>Zoom {Number(value) || ''}</IonText>

      <div className='ZoomButton__controlPage'>
        <span
          onClick={onZoomIn}
          className={clsx('icon', { disabled: zoomInButtonProps.disabled })}
        >
          <IonIcon icon={caretUp} className='ZoomButton__controlIcon' />
        </span>
        <span
          onClick={onZoomOut}
          className={clsx('icon', { disabled: zoomOutButtonProps.disabled })}
        >
          <IonIcon icon={caretDown} className='ZoomButton__controlIcon' />
        </span>
      </div>
    </div>
  )
}

ZoomButton.propTypes = {
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoomInButtonProps: PropTypes.object,
  zoomOutButtonProps: PropTypes.object,
}

ZoomButton.defaultProps = {
  value: '0',
  zoomInButtonProps: {},
  zoomOutButtonProps: {},
}

export default ZoomButton
