import { useCallback, useMemo, useState } from 'react'
import useTablePhoneNumbers from './useTablePhoneNumbers'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogPhoneNumberForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { moduleName } from '~/utils/constants'

import './TablePhoneNumbers.scss'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { useWindowSize } from 'react-use'
import {
  useQueryBuyerSellers,
  useQueryPhoneNumbers,
} from '~/hooks/useQueryData'
import useFilterViewOption from '~/hooks/useFilterViewOption'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'

function TablePhoneNumbers(props) {
  const { tableHeight } = props

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { filterViewOption, getFilterData, ALL } = useFilterViewOption()

  const { isLoadingBuyerSellers, allCompanyOptions } = useQueryBuyerSellers()

  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'view',
      value: EScope.company,
    },
  ])

  const filterData = getFilterData(columnFilters)
  const filterOptions = [filterViewOption()]

  const phoneableId = useMemo(() => {
    if (filterData.view === ALL) {
      return [currentCompany.id, ...allCompanyOptions.map(({ value }) => value)]
    }
    if (filterData.view === EScope.company) {
      return [currentCompany.id]
    }
    return allCompanyOptions.map(({ value }) => value)
  }, [ALL, allCompanyOptions, currentCompany.id, filterData.view])

  const {
    phoneNumbersData,
    isLoadingPhoneNumbersData,
    addPhoneNumber,
    updatePhoneNumber,
    removePhoneNumber,
  } = useQueryPhoneNumbers({
    filters: {
      phoneableId,
    },
  })
  const isTableLoading = isLoadingPhoneNumbersData || isLoadingBuyerSellers

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    formData: null,
  })

  const onOpenCreateDialog = useCallback(() => {
    setDialogState({
      isOpen: true,
      formData: null,
    })
  }, [])

  const onCloseDialog = useCallback(() => {
    setDialogState({
      isOpen: false,
      formData: null,
    })
  }, [])

  const onOpenEditDialog = useCallback(rowData => {
    setDialogState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const afterCreate = newPhone => {
    onCloseDialog()
    addPhoneNumber(newPhone)
  }

  const afterUpdate = phoneUpdated => {
    onCloseDialog()
    updatePhoneNumber(phoneUpdated.id, phoneUpdated)
  }

  const onRemovePhoneNumber = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete phone number ${rowData.number}`,
      })
      if (result === EYesNo.Yes) {
        try {
          const response = await apiClient.phoneNumbers.delete(rowData.id)
          if (response.id) {
            removePhoneNumber(response.id)
            toast.success(toastMessages.deleteSuccess)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(error.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { columns } = useTablePhoneNumbers()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={phoneNumbersData}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          companyViewProps={{
            name: moduleName.company.phoneNumbers,
            description: 'Manage your phone numbers.',
            onClickTopButton: onOpenCreateDialog,
          }}
          tableHeight={tableHeight || windowSize.height - 220}
          state={{
            isLoading: isTableLoading,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              maxSize: 80,
              minSize: 80,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => onOpenEditDialog(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => onRemovePhoneNumber(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>

      <DialogPhoneNumberForm
        isOpen={dialogState.isOpen}
        formData={dialogState.formData}
        onClose={onCloseDialog}
        afterUpdate={afterUpdate}
        afterCreate={afterCreate}
        phoneNumberData={phoneNumbersData}
      />
    </>
  )
}

TablePhoneNumbers.propTypes = {}

export default TablePhoneNumbers
