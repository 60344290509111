import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CompanyInfo, IRTColumnDef, ToolTipOverlay } from '~/components/shared'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { IBuyerTerminalReference } from '~/types/models/IBuyerTerminalReference'
import { ICompany } from '~/types/models/ICompany'

const useTableCompanyBuyerTerminalReferenceSubTab = () => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { findCompanyById } = useQueryCompanies({})
  const { terminalsData } = useQueryTerminals()

  const buyerTerminals = useMemo(
    () =>
      terminalsData.filter(({ companyId }) => currentCompany.id !== companyId),
    [currentCompany.id, terminalsData],
  )

  const columns = useMemo<IRTColumnDef<IBuyerTerminalReference>[]>(
    () => [
      {
        header: 'Buyer',
        accessorKey: 'buyerId',
        size: 250,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const company = findCompanyById(cellValue)

          return (
            <CompanyInfo
              defaultCompanyCode='Buyer'
              companyType={EScope.buyer}
              company={{
                label: company ? `${company.code} - ${company.name}` : '',
                name: company?.name,
                code: company?.code,
                value: company?.id,
                image: company?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Seller',
        accessorKey: 'sellerId',
        size: 250,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const company = findCompanyById(cellValue)

          return (
            <CompanyInfo
              defaultCompanyCode='Seller'
              companyType={EScope.seller}
              company={{
                label: company ? `${company.code} - ${company.name}` : '',
                name: company?.name,
                code: company?.code,
                value: company?.id,
                image: company?.logo,
              }}
            />
          )
        },
      },
      {
        header: 'Buyer Terminal',
        accessorKey: 'buyerTerminalId',
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const terminal = buyerTerminals.find(({ id }) => id === cellValue)

          return (
            <ToolTipOverlay content={terminal?.name} placement='top'>
              <span>{terminal?.code}</span>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
        minSize: 450,
      },
    ],
    [buyerTerminals, findCompanyById],
  )

  return { columns }
}

export default useTableCompanyBuyerTerminalReferenceSubTab
