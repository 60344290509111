import clsx from 'clsx'

import './ContainerFilterTooltipItem.scss'

const ContainerFilterTooltipItem = props => {
  const { label, value } = props

  const isAllOption = value === 'All'

  return (
    <li
      className={clsx('ContainerFilterTooltipItem__container', { isAllOption })}
    >
      <span className='ContainerFilterTooltipItem__label'>{label}&nbsp;</span>
      <span className='ContainerFilterTooltipItem__value'>{value}</span>
    </li>
  )
}

export default ContainerFilterTooltipItem
