import { useState, useEffect, useRef } from 'react'

import {
  IonRadioGroup,
  IonInput,
  IonRadio,
  IonList,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonButton,
  IonLabel,
  IonContent,
  IonIcon,
} from '@ionic/react'

import { lockClosed, timer, helpCircle, closeCircle } from 'ionicons/icons'
import { apiClient } from '~/api/ApiClient'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './FleetStatus.scss'

function FleetStatus(props) {
  const {
    orderFleet,
    currentUser,
    userType,
    dismissPopUp,
    callBack,
    className,
  } = props

  const [fleetStatus, setFleetStatus] = useState(null)
  const [OrderFleet, setOrderFleet] = useState({})
  const [fleetNote, setFleetNote] = useState('')
  const [error, setError] = useState(false)

  const handleFleetUpdate = async fleetStatus => {
    if (fleetStatus === 'Question') {
      setError(!fleetNote)
    }
    let orderFleet = Object.assign({}, OrderFleet)
    if (currentUser.currentScope === 'buyer') {
      orderFleet.buyerNote = fleetNote
    } else if (currentUser.currentScope === 'seller') {
      orderFleet.sellerNote = fleetNote
    } else if (userType == 'Driver' || userType == 'Fleet') {
      orderFleet.fleetNote = fleetNote
      orderFleet.status = fleetStatus
    }
    await setOrderFleet(orderFleet)

    if (!error) {
      apiClient.orderFleets.update(orderFleet.id, OrderFleet).then(response => {
        let orderFleet = Object.assign({}, OrderFleet)
        orderFleet.fleetNote = ''
        orderFleet.buyerNote = ''
        orderFleet.sellerNote = ''
        setOrderFleet(orderFleet)
        dismissPopUp()
        callBack(response.order)
      })
    }
  }

  const getNoteLabel = () => {
    if (currentUser.currentScope === 'buyer') {
      return 'Buyer Note'
    } else if (currentUser.currentScope === 'seller') {
      return 'Seller Note'
    } else if (userType == 'Driver' || userType == 'Fleet') {
      return 'Fleet Note'
    }
  }

  const getNoteValue = () => {
    if (currentUser.currentScope === 'buyer') {
      return fleetNote ? fleetNote : orderFleet.buyerNote
    } else if (currentUser.currentScope === 'seller') {
      return fleetNote ? fleetNote : orderFleet.sellerNote
    } else if (userType == 'Driver' || userType == 'Fleet') {
      return fleetNote ? fleetNote : orderFleet.fleetNote
    }
  }

  const currentNoteValue = useRef(getNoteValue())

  useEffect(() => {
    setFleetNote(currentNoteValue.current)
  }, [])

  return (
    <IonContent className={className}>
      {userType === 'Fleet' && (
        <IonList>
          <IonRadioGroup
            value={fleetStatus}
            onIonChange={e => {
              setFleetStatus(e.detail.value)
            }}
          >
            <IonItem>
              <IonIcon icon={timer} color='tertiary' slot='start' />
              <IonLabel>
                <IonText color='tertiary'>Pending</IonText>
              </IonLabel>
              <IonRadio value='Pending' />
            </IonItem>
            <IonItem>
              <IonIcon icon={lockClosed} color='success' slot='start' />
              <IonLabel>
                <IonText color='success'>Confirm</IonText>
              </IonLabel>
              <IonRadio value='Confirm' />
            </IonItem>
            <IonItem>
              <IonIcon icon={closeCircle} color='danger' slot='start' />
              <IonLabel>
                <IonText color='danger'>Reject</IonText>
              </IonLabel>
              <IonRadio value='Reject' />
            </IonItem>
            <IonItem>
              <IonIcon icon={helpCircle} color='warning' slot='start' />
              <IonLabel>
                <IonText color='warning'>Question</IonText>
              </IonLabel>
              <IonRadio value='Question' />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      )}
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              {getNoteLabel()}
            </IonLabel>
            <IonInput
              type='text'
              value={getNoteValue()}
              onIonInput={text => {
                setFleetNote(text.target.value)
              }}
            />
          </IonItem>
          {error && (
            <p className='error'>
              You must have Fleet Note for status Question
            </p>
          )}
        </IonCol>
      </IonRow>
      <IonButton
        color='primary'
        fill='outline'
        expand='block'
        onClick={async e => {
          e.preventDefault()
          handleFleetUpdate(fleetStatus)
        }}
      >
        Submit
      </IonButton>
    </IonContent>
  )
}

export default FleetStatus
