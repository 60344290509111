import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
// import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'
import { DEFAULT_COMPANY_PRODUCT_GROUP_COLUMNS } from '~/constants/companySubTabs'

const productGroupsSlice = createSlice({
  name: moduleName.company.productGroups,
  initialState: {
    columns: DEFAULT_COMPANY_PRODUCT_GROUP_COLUMNS,
  },
})

export default productGroupsSlice
