import React from 'react'
import { IonButton } from '@ionic/react'
import ImageLabelItem from './ImageLabelItem'
import PropTypes from 'prop-types'

const AccessImageLabelHeader = props => {
  const {
    accessesList,
    onToggleAccess,
    hasMultipleScopes,
    dropdownField,
    onUpdateFilter,
    companyId,
    currentScope,
  } = props

  return (
    <>
      {accessesList &&
        accessesList.map(access => (
          <ImageLabelItem
            key={access.id}
            item={access}
            companyId={companyId}
            currentScope={currentScope}
            hasMultipleScopes={hasMultipleScopes}
            dropdownField={dropdownField}
            onUpdateFilter={onUpdateFilter}
            rightItem={
              <>
                {accessesList.length > 1 && access.id != companyId && (
                  <IonButton
                    slot='end'
                    color='concord'
                    fill='outline'
                    onClick={() => {
                      onToggleAccess(access.id)
                    }}
                  >
                    CHANGE
                  </IonButton>
                )}
                <span>
                  {access.id == companyId && (
                    <>
                      <IonButton
                        slot='end'
                        color='success'
                        fill='clean'
                        disabled
                      >
                        CURRENT
                      </IonButton>
                    </>
                  )}
                </span>
              </>
            }
          />
        ))}
    </>
  )
}

AccessImageLabelHeader.propTypes = {
  accessesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  ),
  onToggleAccess: PropTypes.func,
  companyId: PropTypes.number,
  currentScope: PropTypes.oneOf(['fleet', 'seller', 'buyer']),
  hasMultipleScopes: PropTypes.bool,
  dropdownField: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      filterKey: PropTypes.string, // label
      value: PropTypes.number,
      item: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
  ),
  onUpdateFilter: PropTypes.func,
}

export default AccessImageLabelHeader
