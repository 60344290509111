import * as types from '../actions/actionTypes'
import { dashboardDateRange } from '~/utils/dashboardUtils'

export const initialState = {
  document: null,
  load: null,
  invoice: null,
  loading: null,
  createdDateRange: dashboardDateRange,
  modelType: 'document',
  filters: {
    createdDateRange: {
      startDate: null,
      endDate: null,
    },
    modelType: 'document',
  },
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.FETCH_DASHBOARD_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case types.FETCH_DASHBOARD_DOCUMENTS: {
      const { documents } = action

      return {
        ...state,
        document: documents,
        loading: false,
      }
    }

    case types.FETCH_DASHBOARD_LOADS: {
      const { loads } = action

      return {
        ...state,
        load: loads,
        loading: false,
      }
    }

    case types.FETCH_DASHBOARD_INVOICES: {
      const { invoices } = action

      return {
        ...state,
        invoice: invoices,
        loading: false,
      }
    }

    case types.UPDATE_DASHBOARD_DATE_FILTER: {
      const { createdDateRange } = action

      return {
        ...state,
        createdDateRange: createdDateRange,
      }
    }

    case types.UPDATE_DASHBOARD_MODEL_TYPE: {
      const { modelType } = action

      return {
        ...state,
        modelType: modelType,
      }
    }

    default: {
      return { ...state }
    }
  }
}

export default dashboardReducer
