import { ReusablePagination } from '~/components/shared'

import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'

const defaultRowsPerPage = [5, 10, 24, 50, 100]

export interface IRTTablePaginationProps<
  TData extends IReusableTableRowData,
> extends Partial<{
    SelectProps?: any
    disabled?: boolean
    rowsPerPageOptions?: { label: string; value: number }[] | number[]
    showRowsPerPage?: boolean
  }> {
  table: ReusableTableInstance<TData>
  showFirstButton?: boolean
  showLastButton?: boolean
}

const RTTablePagination = <TData extends IReusableTableRowData>({
  table,
  ...rest
}: IRTTablePaginationProps<TData>) => {
  const {
    pagination: { pageIndex = 0, pageSize = 50 },
  } = table.getState()

  const {
    showPaginationPages,
    showPerPageOptions,
    rowCount = 0,
  } = table.options

  const { rowsPerPageOptions = defaultRowsPerPage } = rest ?? {}

  return (
    <ReusablePagination
      pageIndex={pageIndex}
      pageSize={pageSize}
      rowCount={rowCount}
      showPaginationPages={showPaginationPages}
      showPerPageOptions={showPerPageOptions}
      onChangePageIndex={table.setPageIndex}
      onChangePageSize={table.setPageSize}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  )
}

export default RTTablePagination
