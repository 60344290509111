import { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useRouter } from '~/hooks/useRouter'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'

import {
  IonText,
  IonButton,
  IonChip,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSpinner,
} from '@ionic/react'
import { ContextMenu, ToolTipOverlay } from '~/components/shared'
import CompanyTableTabs from './Tabs'
import CompanyTableFilters from './FilterHeader'

import _ from 'lodash'
import clsx from 'clsx'
import { codeOutline, addOutline, closeOutline } from 'ionicons/icons'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { moduleName } from '~/utils/constants'

import './CompanyTable.scss'

function CompanyTable(props) {
  const {
    title,
    renderCountText,
    description,
    filterProps,
    buttonProps,
    renderAboveTable,
    FormComponent,
    tabs,
    currentTab,
    onChangeTab,
    renderTable,
    formProps = {},
    FilterComponent,
    extraButtons,
    className,
    smallButtons,
  } = props

  const isMobile = useIsMobile()
  const router = useRouter()
  const { myCompanyMenu } = useNavigatedMenuItems()

  const currentCompany = useSelector(selectMyCurrentCompany)

  const [isOpenForm, setIsOpenForm] = useState(false)

  const isOpeniningForm = useMemo(() => {
    if (typeof formProps?.isOpen === 'boolean') {
      return formProps.isOpen
    }
    return isOpenForm
  }, [formProps.isOpen, isOpenForm])

  const companySubTabs = useMemo(() => {
    return myCompanyMenu.subtabs.map(({ label, value }) => {
      let href
      if (value === moduleName.company.home) {
        href = `/companies/${currentCompany.id}`
      } else {
        href = `/companies/${currentCompany.id}?view=${value}`
      }
      const onClick = () => router.push(href)
      return { label, href, onClick }
    })
  }, [myCompanyMenu.subtabs, currentCompany.id, router])

  const renderTableOrForm = useCallback(() => {
    if (isOpeniningForm && FormComponent) {
      return <FormComponent {...formProps} />
    }

    if (renderTable()) {
      return renderTable()
    }

    return renderAboveTable()
  }, [FormComponent, formProps, isOpeniningForm, renderAboveTable, renderTable])

  const handleToggleForm = useCallback(() => {
    const nextValue = !isOpeniningForm
    // formProps?.onToggleForm && formProps?.onToggleForm(nextValue)
    // setIsOpenForm(nextValue)
    if (formProps?.onToggleForm) {
      formProps?.onToggleForm(nextValue)
    } else {
      setIsOpenForm(nextValue)
    }
  }, [formProps, isOpeniningForm])

  const renderTextButton = useCallback(() => {
    if (isOpeniningForm) {
      if (isMobile) {
        return <IonIcon icon={closeOutline} />
      }
      return 'Close'
    }

    if (isMobile) {
      return <IonIcon icon={addOutline} />
    }

    return buttonProps?.buttonText
  }, [buttonProps?.buttonText, isMobile, isOpeniningForm])

  // useEffect(() => {
  //   setIsOpenForm(Boolean(formProps.isOpen))
  // }, [formProps.isOpen])

  return (
    <div className={clsx('CompanyTable__container', className)}>
      <div className='CompanyTable__header'>
        <div className='CompanyTable__headerTitleContainer'>
          <div className='CompanyTable__headerTitle'>
            <ContextMenu
              className='CompanyTable__contextMenu'
              menu={_.sortBy(companySubTabs, 'label')}
              searchable
            >
              <div className='CompanyTable__wrapMainTitle'>
                <IonText className='CompanyTable__mainTitle'>{title}</IonText>
                <IonIcon
                  className='CompanyTable__titleDropdownIcon'
                  icon={codeOutline}
                />
              </div>
            </ContextMenu>

            {renderCountText() && (
              <IonChip className='CompanyTable__countData' color='primary'>
                {renderCountText()}
              </IonChip>
            )}
          </div>
          <IonText className='CompanyTable__headerDescription'>
            {description}
          </IonText>
        </div>
        <div className='CompanyTable__headerButtonContainer'>
          {smallButtons
            .filter(({ hide }) => hide !== true)
            .map((button, index) => (
              <IonButton
                {...button}
                key={index}
                className={clsx('CompanyTable__headerSmallButton', {
                  isMobile,
                })}
              >
                {button.loading ? (
                  <IonSpinner name='lines-small' />
                ) : (
                  <>
                    {button.label && <IonLabel>{button.label}</IonLabel>}
                    {button.icon && <IonIcon icon={button.icon} />}
                  </>
                )}
              </IonButton>
            ))}
          {buttonProps?.hide !== true && (
            <ToolTipOverlay
              content={isOpeniningForm ? 'Close' : buttonProps?.buttonText}
              allowToShow={isMobile}
            >
              <IonButton
                className={clsx('CompanyTable__headerButton', { isMobile })}
                color={isOpeniningForm ? 'danger' : 'concord'}
                onClick={handleToggleForm}
                disabled={buttonProps?.loading}
                {...buttonProps}
              >
                {renderTextButton()}
              </IonButton>
            </ToolTipOverlay>
          )}
          {extraButtons
            .filter(({ hide }) => hide !== true)
            .map((button, index) => (
              <IonButton
                {...button}
                key={index}
                className={clsx('CompanyTable__headerButton', { isMobile })}
              >
                {button.label && <IonLabel>{button.label}</IonLabel>}
                {button.icon && <IonIcon icon={button.icon} />}
              </IonButton>
            ))}
        </div>
      </div>
      <IonGrid className='CompanyTable__filterRoot'>
        <IonRow>
          {FilterComponent && <FilterComponent {...filterProps} />}
          {!FilterComponent && (
            <CompanyTableFilters
              subTabName={filterProps.moduleName}
              showSearch
              searchPlaceholder='Search'
              showFiltersReset
              {...filterProps}
            />
          )}
          {tabs.length > 0 && (
            <IonCol
              sizeXl={2}
              sizeLg={4}
              sizeMd={12}
              sizeSm={12}
              sizeXs={12}
              className='CompanyTable__wrapTabsSection'
            >
              <div className='CompanyTable__tabsSection'>
                <CompanyTableTabs
                  tabs={tabs}
                  currentTab={currentTab}
                  onChangeTab={onChangeTab}
                />
              </div>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      {renderTableOrForm()}
    </div>
  )
}

// CompanyTable.propTypes = {
//   tableProps: PropTypes.object,
//   title: PropTypes.string,
//   renderCountText: PropTypes.func,
//   description: PropTypes.string,
//   filterProps: PropTypes.object,
//   buttonProps: PropTypes.shape({
//     buttonText: PropTypes.string,
//   }),
//   renderAboveTable: PropTypes.func,
//   FormComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     }),
//   ),
//   currentTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   onChangeTab: PropTypes.func,
//   renderTable: PropTypes.func,
//   formProps: PropTypes.object,
//   FilterComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
// }

CompanyTable.defaultProps = {
  tableProps: {},
  filterProps: {},
  buttonProps: {},
  renderCountText: () => '',
  renderAboveTable: () => null,
  renderTable: () => null,
  tabs: [],
  extraButtons: [],
  smallButtons: [],
}

export default CompanyTable
