import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBuyerSellerRegexps from './useModifyBuyerSellerRegexps'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import {
  IBuyerSellerRegexp,
  IGetBuyerSellerRegexpsParams,
} from '~/types/models/IBuyerSellerRegexp'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBuyerSellerRegexps = (
  params: IGetBuyerSellerRegexpsParams = {},
  options?: Partial<UseQueryOptions<IBuyerSellerRegexp[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'buyerSellerRegexps',
      sessionUser?.id,
      buildGetUrl(apiClient.bundles.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.buyerSellerRegexps.get(params)
      return response.buyerSellerRegexps
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const buyerSellerRegexpsData = useMemo(() => data || [], [data])

  const {
    addBuyerSellerRegexp,
    updateBuyerSellerRegexp,
    removeBuyerSellerRegexp,
  } = useModifyBuyerSellerRegexps(params)

  return {
    buyerSellerRegexpsData,
    isBuyerSellerRegexpsDataLoading: isLoading,
    addBuyerSellerRegexp,
    updateBuyerSellerRegexp,
    removeBuyerSellerRegexp,
  }
}

export default useQueryBuyerSellerRegexps
