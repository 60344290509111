import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const userAccessesSlice = createSlice({
  name: 'userAccesses',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(ua => {
        newState[ua.id] = ua
      })
      return newState
    },
    add: (state, action) => {
      state[action.payload.id] = action.payload
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export const selectUserAccesses = state => state.data.userAccesses

export const selectUserAccessesList = createSelector(
  selectUserAccesses,
  userAccesses => Object.values(userAccesses),
)

export const fetchUserAccesses = createAsyncThunk(
  'userAccesses/fetch',
  async (params = {}, { dispatch }) => {
    try {
      const { userAccesses } = await apiClient.userAccesses.get(params)
      dispatch(userAccessesSlice.actions.index(userAccesses))
      return userAccesses
    } catch (error) {
      console.log(error)
    }
  },
)
