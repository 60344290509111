import { trashBinOutline } from 'ionicons/icons'

import type { IDeleteConfirm } from './type'

import './styles.scss'
import { Dropdown } from 'react-bootstrap'
import { useCallback, useState } from 'react'
import { IonIcon, IonText } from '@ionic/react'
import { apiClient } from '~/api/ApiClient'

function NoteConfirmDelete(props: IDeleteConfirm) {
  const { onConfirm, noteData } = props

  const [showMenu, setShowMenu] = useState(false)

  const onConfirmDelete = useCallback(async () => {
    setShowMenu(false)
    apiClient.notes.delete(noteData.id)
    onConfirm && onConfirm()
  }, [noteData, onConfirm])

  return (
    <Dropdown
      show={showMenu}
      onToggle={status => {
        setShowMenu(status)
      }}
      style={{
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    >
      <Dropdown.Toggle
        style={{
          backgroundColor: 'white',
          borderWidth: 0,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 0,
        }}
      >
        <IonIcon
          icon={trashBinOutline}
          color='danger'
          style={{ width: 15, height: 15 }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div style={{ margin: 10 }}>
          <IonText style={{ fontSize: 13, fontWeight: 500 }}>
            Are you sure to delete it?
          </IonText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <div
              onClick={() => setShowMenu(false)}
              style={{
                borderRadius: 4,
                padding: '4px 12px',
                border: '1px solid black',
              }}
            >
              No
            </div>
            <div
              onClick={onConfirmDelete}
              style={{
                borderRadius: 4,
                padding: '4px 12px',
                backgroundColor: '#3880ff',
                color: 'white',
                marginLeft: 10,
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default NoteConfirmDelete
