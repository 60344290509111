import { PARSER_NAME_IMPRA, PARSER_NAME_BUTLER } from '~/utils/constants'

import _ from 'lodash'

/**
 * @param {string} parserName
 */
export const isParserImpra = parserName =>
  _.lowerCase(parserName) === _.lowerCase(PARSER_NAME_IMPRA)

/**
 * @param {string} parserName
 */
export const isParserButler = parserName =>
  _.lowerCase(parserName) === _.lowerCase(PARSER_NAME_BUTLER)
