import React from 'react'
import { useRouter } from '~/hooks/useRouter'
import { dashboardFeatureCardOnClick } from '~/utils/featureCardUtils'
import '~/styles/dashboard.scss'

const FeatureCard = props => {
  const router = useRouter()

  const { cardData, modelType, createdDateRange } = props

  const onClick = () => {
    dashboardFeatureCardOnClick(
      cardData,
      modelType,
      router.history,
      createdDateRange,
    )
  }

  return (
    <>
      <div
        className={
          cardData.urlParams ? 'feature-card clickable-card' : 'feature-card'
        }
        onClick={cardData.urlParams ? onClick : () => {}}
      >
        <div className='feature-card-header'>
          <p className='feature-card-label'>{cardData.title}</p>
        </div>
        <div className='feature-card-body'>
          <p className='feature-card-font'>{cardData.data}</p>
        </div>
      </div>
    </>
  )
}

export default FeatureCard
