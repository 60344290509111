import { useState } from 'react'
/**
 * common hooks for image editing
 * @param {function} onEditComplete - callback function to be called when image is edited
 * @param {boolean} setIsEditing - setter for isEditing state
 */
const useImageEditing = (onEditComplete, setIsEditing) => {
  const [cropper, setCropper] = useState()
  const [isCropping, setIsCropping] = useState()
  const [scale, setScale] = useState(1)
  const [imageName, setImageName] = useState('')

  const onCropImage = () => {
    if (isCropping) {
      setIsCropping(false)
      setCropper(cropper.clear())
    } else {
      setIsCropping(true)
      setCropper(cropper.crop())
    }
  }

  const onMoveLeft = () => {
    setCropper(cropper.rotate(90))
  }

  const onMoveRight = () => {
    setCropper(cropper.rotate(-90))
  }

  const onZoom = type => {
    const _scale = type == '-' ? scale - 0.1 : scale + 0.1
    setScale(_scale)
    setCropper(cropper.scale(_scale, _scale))
  }

  const onDoneEditing = () => {
    const newList = {
      image: cropper.getCroppedCanvas().toDataURL(),
      source: 'editor',
      displayName: imageName,
    }
    onEditComplete && onEditComplete(newList)
    setIsEditing && setIsEditing(false)
    setIsCropping(false)
  }

  return {
    setCropper,
    imageName,
    setImageName,
    onCropImage,
    isCropping,
    onZoom,
    onMoveLeft,
    onMoveRight,
    onDoneEditing,
    setIsCropping,
  }
}

export default useImageEditing
