import { useContext } from 'react'
import TruckFormContext from './TruckFormContext'

const useTruckFormProvider = () => {
  const context = useContext(TruckFormContext)

  return context
}

export default useTruckFormProvider
