import React, { useMemo } from 'react'

import clsx from 'clsx'

import { ToolTipOverlay } from '~/components/shared'

import type { IHorizontalBarProps } from './type'

import './styles.scss'

function HorizontalBar(props: IHorizontalBarProps) {
  const { items, className, height = 5 } = props

  const renderItems = useMemo(
    () =>
      items.map((item, index) => {
        const { backgroundColor, percentage, tooltipProps } = item
        return (
          <React.Fragment key={index}>
            <ToolTipOverlay {...tooltipProps}>
              <div
                style={{
                  backgroundColor,
                  height,
                  width: `${percentage}%`,
                }}
              />
            </ToolTipOverlay>
          </React.Fragment>
        )
      }),
    [height, items],
  )

  return (
    <div className={clsx('HorizontalBar__container', className)}>
      {renderItems}
    </div>
  )
}
export default HorizontalBar
