import { useEffect, useState, useMemo } from 'react'
import { useRouter } from '~/hooks/useRouter'

import UseBrowserBackStackContext from './UseBrowserBackStackContext'
import _ from 'lodash'

function UseBrowserBackStackProvider(props) {
  const { children } = props

  const [backStack, setBackStack] = useState([])

  const router = useRouter()

  const previousPath = useMemo(() => {
    const filterPathName = _(backStack)
      .uniq()
      .filter(pathname => pathname !== router.pathname)
      .value()

    if (filterPathName.length === 0) {
      return ''
    }

    return _.last(filterPathName)
  }, [backStack, router.pathname])

  useEffect(() => {
    router.history.listen(location => {
      setBackStack(prev => [...prev, location.pathname])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UseBrowserBackStackContext.Provider value={{ backStack, previousPath }}>
      {children}
    </UseBrowserBackStackContext.Provider>
  )
}
UseBrowserBackStackProvider.propTypes = {}
export default UseBrowserBackStackProvider
