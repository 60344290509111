import _ from 'lodash'
import diffObjects from './diffObjects'

import type { INullOrUndefined } from '~/types/models/ICommonModel'

export interface IAddress {
  city: string
  country: string
  line1: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  line2?: any
  postalCode: string
  state: string
}

const areAddressesEqual = (
  address1: IAddress | INullOrUndefined,
  address2: IAddress | INullOrUndefined,
) => {
  const keys = ['city', 'country', 'line1', 'postalCode', 'state', 'line2']
  const payload1 = _.pick(address1, keys)
  const payload2 = _.pick(address2, keys)
  const diff = diffObjects(payload1, payload2)

  return (
    Object.keys(diff || {})
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(key => (diff as any)[key]) // ignore null and undefined comparison
      .filter(val => val).length === 0
  )
}

export default areAddressesEqual
