import { CommonDialogV2 } from '~/components/shared'
import PaymentTypeForm from './PaymentTypeForm'

import type { IDialogPaymentTypeFormProps } from './type'

function DialogPaymentTypeForm(props: IDialogPaymentTypeFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  return (
    <CommonDialogV2
      {...modalProps}
      title='Payment Type'
      isHiddenOkButton
      onClose={onClose}
    >
      <PaymentTypeForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogPaymentTypeForm
