import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryDriverFleets = (
  params = {},
  options?: Partial<UseQueryOptions<IDriverFleet[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'driverFleets',
      sessionUser?.id,
      buildGetUrl(apiClient.driverFleets.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.driverFleets.get(params)
      return response.driverFleets
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const driverFleetsData = data || []

  return {
    driverFleetsData,
    isDriverFleetsDataLoading: isLoading,
  }
}

export default useQueryDriverFleets
