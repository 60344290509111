import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const DeleteIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 1920 1920'
      fill={color}
      {...svgProps}
    >
      <path
        fillRule='evenodd'
        d='M1129.412 1242.353v112.941H338.824v-112.941h790.588Zm451.764-338.824v112.942H338.824V903.529h1242.352ZM621.176 0c93.403 0 169.412 76.01 169.412 169.412 0 26.09-6.437 50.484-16.94 72.62L999.98 468.255l-79.962 79.962-226.221-226.334c-22.137 10.504-46.645 16.942-72.622 16.942-93.402 0-169.411-76.01-169.411-169.412C451.765 76.009 527.775 0 621.176 0ZM344.471 225.882c25.976 128.753 140.047 225.883 276.705 225.883 13.553 0 27.106-1.13 39.53-3.389L920.47 708.141l239.435-239.435-242.824-242.824H1920v1129.412h-564.706V1920H0V225.882Zm1568.188 1242.365-444.424 444.31v-444.31h444.424Z'
      />
    </svg>
  )
}
export default DeleteIcon
