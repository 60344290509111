import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddresses } from '~/redux/reducers/data/addresses'
import { selectLocations } from '~/redux/selectors'

export const useInitializeReduxAddressesStore = () => {
  const dispatch = useDispatch()
  const locations = useSelector(selectLocations)

  const ids = useMemo(
    () =>
      Object.values(locations)
        .map(location => location?.id)
        .filter(id => id),
    [locations],
  )

  useEffect(() => {
    ids &&
      ids.length > 0 &&
      dispatch(fetchAddresses({ filters: { addressable_type: 'Terminal' } }))
  }, [ids, dispatch])
}
