import { useCallback } from 'react'

import { IonButtons, IonIcon } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import { Button } from '~/components'
import _ from 'lodash'

const CompanyUserGroupBody = ({ userGroups, onEditClick, onDeleteClick }) => {

  const getMenuOptions = useCallback(menuOptions => {
    return menuOptions
      .map(option => {
        const optionWithoutDash = (option || '').toString().replace(/_/g, ' ')

        return _.capitalize(optionWithoutDash)
      })
      .join(', ')
  }, [])

  const renderMenuOptions = useCallback(
    menuOptions => {
      const mapMenuOptions = getMenuOptions(menuOptions)

      return <span style={{ fontSize: 14 }}>{mapMenuOptions}</span>
    },
    [getMenuOptions],
  )

  return (
    <div
      style={{
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      {userGroups.map((group, index) => (
        <div
          className='body-grid'
          key={index}
          style={{
            border: '1px solid #f2f2f2',
            borderRadius: 4,
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1)',
            height: 'auto',
            marginBottom: 12,
            paddingLeft: 12,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <div style={{ width: '95%' }}>
            <div>
              <p style={{ fontSize: 16 }}>
                <b>Name:</b> {group.name}
              </p>
              <p style={{ fontSize: 16 }}>
                <b>Code:</b> {group.code}
              </p>
              <p style={{ fontSize: 16 }}>
                <b>Scope Type:</b> {group.scopeType}
              </p>
            </div>
            <div>
              <span style={{ fontSize: 16 }}>
                <b>Menu Options:</b>{' '}
              </span>
              {renderMenuOptions(group.menuOptions)}
            </div>
          </div>
          <div
            style={{
              width: '5%',
            }}
          >
            <div style={{ height: 40 }}>
              <IonButtons>
                <Button
                  label='Edit'
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEditClick(group, index)
                  }}
                />
              </IonButtons>
            </div>
            <div
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onDeleteClick(group, index)
              }}
            >
              <IonIcon
                style={{ height: 24, width: 48, cursor: 'pointer' }}
                color='danger'
                icon={trashOutline}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CompanyUserGroupBody
