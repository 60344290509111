import React, { useState, useEffect, useCallback } from 'react'
import { IonAlert, IonButton, IonIcon } from '@ionic/react'
import { costCodeConfigurationOptions } from './constants.js'
import { DropdownPicker } from '~/components/shared'
import { addOutline, trashOutline } from 'ionicons/icons'

const CostCodesConfigurationRow = ({
  costCodeConfiguration,
  updateCostCodeConfiguration,
}) => {
  const [configuration, setConfiguration] = useState(
    costCodeConfiguration.structure,
  )
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setConfiguration(costCodeConfiguration.structure)
  }, [costCodeConfiguration])

  const onChangeHandler = useCallback(
    (data, index) => {
      const { value } = data
      const newConfiguration = [...configuration]
      newConfiguration.splice(index, 1, value)

      setConfiguration(newConfiguration)
    },
    [configuration],
  )

  const onAddConfigurationNode = useCallback(() => {
    setConfiguration([...configuration, ''])
  }, [configuration])

  const onDeleteConfiguratioNode = useCallback(() => {
    const newConfiguration = [...configuration]
    newConfiguration.splice(-1, 1)

    setConfiguration(newConfiguration)
  }, [configuration])

  const onSubmitConfigurationChanges = useCallback(() => {
    setShowAlert(true)
  }, [])

  const onCancelConfigurationChanges = useCallback(() => {
    setConfiguration(costCodeConfiguration.structure)
  }, [costCodeConfiguration])

  const acceptConfigurationChanges = useCallback(() => {
    updateCostCodeConfiguration(configuration)
  }, [configuration, updateCostCodeConfiguration])

  return (
    <div style={{ display: 'flex', marginLeft: 40, marginTop: 8 }}>
      <p
        style={{
          fontWeight: 750,
          fontSize: 16,
          lineHeight: '32px',
          minWidth: 232,
        }}
      >
        Current Cost Code Configuration
      </p>
      <div style={{ display: 'flex', maxWidth: 774, flexWrap: 'wrap' }}>
        {configuration &&
          configuration.map((ccc, index) => {
            return (
              <div style={{ display: 'block' }} key={`cc_row_${index}`}>
                <DropdownPicker
                  value={ccc}
                  items={costCodeConfigurationOptions}
                  onValueChange={data => onChangeHandler(data, index)}
                  defaultLabel={'None'}
                />
              </div>
            )
          })}
      </div>
      <div style={{ display: 'flex' }}>
        <div
          onClick={onAddConfigurationNode}
          style={{
            color: '#1090FF',
            backgroundColor: 'white',
            cursor: 'pointer',
            height: 24,
            width: 24,
            marginTop: 4,
            marginLeft: 12,
          }}
        >
          <IonIcon className='cost-code-icon' icon={addOutline} />
        </div>
        <div
          onClick={onDeleteConfiguratioNode}
          style={{
            color: '#ff6961',
            backgroundColor: 'white',
            cursor: 'pointer',
            height: 24,
            width: 24,
            marginTop: 4,
            marginLeft: 12,
          }}
        >
          <IonIcon className='cost-code-icon' icon={trashOutline} />
        </div>
        {configuration !== costCodeConfiguration.structure && (
          <>
            <IonButton
              fill={'clear'}
              onClick={onSubmitConfigurationChanges}
              style={{
                height: 24,
                width: 66,
                marginLeft: 20,
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              Submit
            </IonButton>
            <IonButton
              fill={'clear'}
              onClick={onCancelConfigurationChanges}
              style={{
                height: 24,
                width: 66,
                marginLeft: 8,
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              Cancel
            </IonButton>
          </>
        )}
      </div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm'}
        message={
          'Do you accept the changes made you made to your cost codes configuration?'
        }
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlert(false)
            },
          },
          {
            text: 'Accept',
            handler: () => {
              acceptConfigurationChanges()
            },
          },
        ]}
      />
    </div>
  )
}

export default CostCodesConfigurationRow
