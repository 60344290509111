import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConcordFormLayout,
  ConcordFormTextField,
  IConcordFormLayoutProps,
} from '~/components/shared'
import { IonIcon } from '@ionic/react'

import { addOutline, closeCircle } from 'ionicons/icons'
import { produce } from 'immer'

import type { ISocialMediaFieldProps } from './type'

import './styles.scss'

function SocialMediaField(props: ISocialMediaFieldProps) {
  const { label = 'Social Media', value, error, onChange } = props

  const [fieldValues, setFieldValues] = useState<string[]>([])

  const extraIcons = useMemo<IConcordFormLayoutProps['extraIcons']>(
    () => [
      {
        icon: addOutline,
        color: 'concord',
        isHidden: fieldValues.some(value => value.length === 0),
        onClick() {
          onChange([...fieldValues, ''])
        },
      },
    ],
    [fieldValues, onChange],
  )

  const onInputChange = useCallback(
    (index: number) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event: any, { value }: any) => {
        const newValues = produce(fieldValues, draft => {
          draft[index] = value
        })
        setFieldValues(newValues)
        onChange(newValues)
      },
    [fieldValues, onChange],
  )

  const onRemoveField = useCallback(
    (index: number) => () => {
      const newValues = produce(fieldValues, draft => {
        draft.splice(index, 1)
      })
      onChange(newValues)
    },
    [fieldValues, onChange],
  )

  const renderFieldValues = useMemo(
    () =>
      fieldValues.map((value, index) => (
        <div key={index} className='SocialMediaField__textField'>
          <ConcordFormTextField
            placeholder='https://example.com'
            value={value}
            onChange={onInputChange(index)}
            error={error[index]?.message}
          />
          <span className='icon' onClick={onRemoveField(index)}>
            <IonIcon icon={closeCircle} color='danger' />
          </span>
        </div>
      )),
    [error, fieldValues, onInputChange, onRemoveField],
  )

  useEffect(() => {
    if (value) {
      if (value.length) {
        setFieldValues(value)
      } else {
        setFieldValues([''])
      }
    } else {
      setFieldValues([])
    }
  }, [value])

  return (
    <ConcordFormLayout label={label} extraIcons={extraIcons}>
      {renderFieldValues}

      {(error as any)?.message && (
        <span className='error form-text'>{(error as any)?.message}</span>
      )}
    </ConcordFormLayout>
  )
}

export default SocialMediaField
