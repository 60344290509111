import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IMixDetailFormProps } from './type'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { IMixDetailFormValues } from '~/types/models/IMixDetail'

const MixDetailForm = (props: IMixDetailFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    sellerProductId,
    isHiddenCancelButton = true,
    ...formProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Slump',
        name: 'slump',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 12.34',
      },
      {
        label: 'Slump Variance',
        name: 'slumpVariance',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 0.45',
      },
      {
        label: 'Air',
        name: 'air',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 1.23',
      },
      {
        label: 'Air Variance',
        name: 'airVariance',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 0.45',
      },
      {
        label: 'Max Temp',
        name: 'maxTemp',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 123.45',
      },
      {
        label: 'Load Temp',
        name: 'loadTemp',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 123.45',
      },
      {
        label: 'Min Temp',
        name: 'minTemp',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 123.45',
      },
      {
        label: 'Specific Gravity',
        name: 'specificGravity',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 123.45',
      },
      {
        label: 'Strength',
        name: 'strength',
        type: EFieldType.number,
        size: 4,
        placeholder: 'Ex: 1',
      },
    ],
    [],
  )

  const defaultValues = useMemo<IMixDetailFormValues>(
    () => ({
      sellerProductId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        slump: Yup.number()
          .typeError('Must be a number')
          .min(-999.99, 'Value too small')
          .max(999.99, 'Value too large')
          .nullable(),
        slumpVariance: Yup.number()
          .typeError('Must be a number')
          .min(-99.99, 'Value too small')
          .max(99.99, 'Value too large')
          .nullable(),
        air: Yup.number()
          .typeError('Must be a number')
          .min(-999.99, 'Value too small')
          .max(999.99, 'Value too large')
          .nullable(),
        airVariance: Yup.number()
          .typeError('Must be a number')
          .min(-99.99, 'Value too small')
          .max(99.99, 'Value too large')
          .nullable(),
        maxTemp: Yup.number()
          .typeError('Must be a number')
          .min(-9999.99, 'Value too small')
          .max(9999.99, 'Value too large')
          .nullable(),
        loadTemp: Yup.number()
          .typeError('Must be a number')
          .min(-9999.99, 'Value too small')
          .max(9999.99, 'Value too large')
          .nullable(),
        minTemp: Yup.number()
          .typeError('Must be a number')
          .min(-9999.99, 'Value too small')
          .max(9999.99, 'Value too large')
          .nullable(),
        specificGravity: Yup.number()
          .typeError('Must be a number')
          .min(-9999.99, 'Value too small')
          .max(9999.99, 'Value too large')
          .nullable(),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IMixDetailFormValues) => {
      const payload = _.pick(formValues, [
        'sellerProductId',
        'slump',
        'slumpVariance',
        'air',
        'airVariance',
        'maxTemp',
        'loadTemp',
        'minTemp',
        'specificGravity',
        'strength',
      ])
      const { errors, ...response } = await apiClient.mixDetails.create({
        mixDetail: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IMixDetailFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'sellerProductId',
          'slump',
          'slumpVariance',
          'air',
          'airVariance',
          'maxTemp',
          'loadTemp',
          'minTemp',
          'specificGravity',
          'strength',
        ])

        const { errors, ...response } = await apiClient.mixDetails.update(
          formValues.id,
          {
            mixDetail: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IMixDetailFormValues) => {
      const fields: (keyof IMixDetailFormValues)[] = [
        'slump',
        'slumpVariance',
        'air',
        'airVariance',
        'maxTemp',
        'loadTemp',
        'minTemp',
        'specificGravity',
        'strength',
      ]
      const hasValue = fields.some(field => formValues[field])
      if (hasValue) {
        setIsLoading(true)
        setError('')
        try {
          if (isUpdating) {
            await onUpdate(formValues)
          } else {
            await onCreate(formValues)
          }
        } catch (error) {
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      } else {
        setError('At least one field must be filled out')
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating && sellerProductId) {
      formRef.current?.setValue('sellerProductId', sellerProductId)
    }
  }, [sellerProductId, isUpdating])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton={isHiddenCancelButton}
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default MixDetailForm
