// redux-react-session actions
export const GET_SESSION_SUCCESS = '@@redux-react-session/GET_SESSION_SUCCESS'
export const GET_SESSION_USER_SUCCESS =
  '@@redux-react-session/GET_USER_SESSION_SUCCESS'

// action types
export const COMPANY_SET_FLEETS_DATA = 'company/COMPANY_SET_FLEETS_DATA'
export const COMPANY_FETCH_FLEET = 'company/COMPANY_FETCH_FLEET'
export const COMPANY_SET_LOADING = 'company/COMPANY_SET_LOADING'
export const COMPANY_SET_EDITING_REFERENCE_NAME =
  'company/COMPANY_SET_EDITING_REFERENCE_NAME'
export const COMPANY_SET_UPDATE_SUCCESS = 'company/SET_UPDATE_SUCCESS'
export const SET_ALL_COMPANIES = 'company/SET_ALL_COMPANIES'
export const FETCH_ALL_COMPANIES = 'company/FETCH_ALL_COMPANIES'
export const SET_DRIVER_FLEET_LOADS_SECTIONS = 'SET_DRIVER_FLEET_LOADS_SECTIONS'

export const FETCH_CURRENT_LOAD = 'FETCH_CURRENT_LOAD'
export const FETCH_CURRENT_LOAD_SUCCESS = 'FETCH_CURRENT_LOAD_SUCCESS'
export const FETCH_CURRENT_LOAD_FAILURE = 'FETCH_CURRENT_LOAD_FAILURE'
export const FETCH_LOADS = 'FETCH_LOADS'
export const FETCH_LOADS_SUCCESS = 'FETCH_LOADS_SUCCESS'

export const UPDATE_LOAD_FILTERS = 'UPDATE_LOAD_FILTERS'
export const RESET_LOAD_FILTERS = 'RESET_LOAD_FILTERS'
export const UPDATE_LOADS_PAGE = 'UPDATE_LOADS_PAGE'
export const LOADS_UPDATE_SEARCH_KEY = 'loads/UPDATE_SEARCH_KEY'

export const UPDATE_CURRENT_LOAD = 'UPDATE_CURRENT_LOAD'
export const UPDATE_CURRENT_LOAD_SUCCESS = 'UPDATE_CURRENT_LOAD_SUCCESS'

export const TOGGLE_ON_EDIT_SELLER_TERMINAL = 'TOGGLE_ON_EDIT_SELLER_TERMINAL'
export const TOGGLE_ON_ADD_SELLER_TERMINAL = 'TOGGLE_ON_ADD_SELLER_TERMINAL'
export const TOGGLE_OFF_SELLER_TERMINAL = 'TOGGLE_OFF_SELLER_TERMINAL'

export const TOGGLE_ON_EDIT_BUYER_TERMINAL = 'TOGGLE_ON_EDIT_BUYER_TERMINAL'
export const TOGGLE_ON_ADD_BUYER_TERMINAL = 'TOGGLE_ON_ADD_BUYER_TERMINAL'
export const TOGGLE_OFF_BUYER_TERMINAL = 'TOGGLE_OFF_BUYER_TERMINAL'

export const TOGGLE_ON_ADD_PRODUCT = 'TOGGLE_ON_ADD_PRODUCT'
export const TOGGLE_ON_EDIT_PRODUCT = 'TOGGLE_ON_EDIT_PRODUCT'
export const TOGGLE_OFF_PRODUCT = 'TOGGLE_OFF_PRODUCT'

export const TOGGLE_ON_ADD_FLEET = 'TOGGLE_ON_ADD_FLEET'
export const TOGGLE_OFF_ADD_FLEET = 'TOGGLE_OFF_ADD_FLEET'

export const TOGGLE_ON_ADD_SELLER = 'TOGGLE_ON_ADD_SELLER'
export const TOGGLE_OFF_ADD_SELLER = 'TOGGLE_OFF_ADD_SELLER'

export const CREATE_CONSUMER = 'CREATE_CONSUMER'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'

export const FETCH_CURRENT_TRUCK = 'FETCH_CURRENT_TRUCK'
export const FETCH_CURRENT_TRUCK_SUCCESS = 'FETCH_CURRENT_TRUCK_SUCCESS'
export const FETCH_CURRENT_TRUCK_FAILURE = 'FETCH_CURRENT_TRUCK_FAILURE'

export const TRUCKS_FETCH_START = 'TRUCKS_FETCH_START'
export const TRUCKS_FETCH_SUCCESS = 'TRUCKS_FETCH_SUCCESS'
export const TRUCKS_DELETE_START = 'TRUCKS_DELETE_START'
export const TRUCKS_DELETE_SUCCESS = 'TRUCKS_DELETE_SUCCESS'
export const TRUCKS_ADD_TRUCK = 'trucks/TRUCKS_ADD_TRUCK'
export const TRUCKS_UPDATE_TRUCK_BY_ID = 'trucks/TRUCKS_UPDATE_TRUCK_BY_ID'
export const TRUCKS_CHANGE_VIEW_MODE = 'TRUCKS_CHANGE_VIEW_MODE'
export const TRUCKS_CHANGE_DRIVER = 'TRUCKS_CHANGE_DRIVER'

export const FETCH_SCHEDULES = 'FETCH_SCHEDULES'
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS'

export const DRIVERS_FETCH_START = 'DRIVERS_FETCH_START' //'drivers/fetchStart';
export const DRIVERS_FETCH_SUCCESS = 'DRIVERS_FETCH_SUCCESS' //'drivers/fetchSuccess';
export const DRIVER_CREATE_START = 'DRIVER_CREATE_START' //'driver/createStart';
export const DRIVER_CREATE_SUCCESS = 'DRIVER_CREATE_SUCCESS' //'driver/createSuccess';
export const DRIVER_UPDATE_START = 'DRIVER_UPDATE_START' //'driver/updateStart';
export const DRIVER_UPDATE_SUCCESS = 'DRIVER_UPDATE_SUCCESS' //'driver/updateSuccess';
export const DRIVER_DELETE_START = 'DRIVER_DELETE_START' //'driver/deleteStart';
export const DRIVER_DELETE_SUCCESS = 'DRIVER_DELETE_SUCCESS' //'driver/deleteSuccess';
export const DELETE_DRIVER = 'DELETE_DRIVER'
export const DRIVER_UPDATE_STATUS_START = 'DRIVER_UPDATE_STATUS_START'
export const DRIVER_UPDATE_STATUS_SUCCESS = 'DRIVER_UPDATE_STATUS_SUCCESS'
export const DRIVER_UPDATE_TRUCK_START = 'DRIVER_UPDATE_TRUCK_START'
export const DRIVER_UPDATE_TRUCK_SUCCESS = 'DRIVER_UPDATE_TRUCK_SUCCESS'
export const DRIVERS_UPDATE_BY_ID = 'DRIVERS_UPDATE_BY_ID'
export const DRIVERS_CHANGE_VIEW_MODE = 'DRIVERS_CHANGE_VIEW_MODE'
export const DRIVERS_CHANGE_CURRENT_TRUCK = 'DRIVERS_CHANGE_CURRENT_TRUCK'
export const DRIVERS_CHANGE_DEFAULT_TRUCK = 'DRIVERS_CHANGE_DEFAULT_TRUCK'
export const UPDATE_DRIVER_FLEET = 'UPDATE_DRIVER_FLEET'

export const UPDATE_CURRENT_TRUCK = 'UPDATE_CURRENT_TRUCK'
export const UPDATE_CURRENT_TRUCK_SUCCESS = 'UPDATE_CURRENT_TRUCK_SUCCESS'

export const FETCH_CURRENT_LOCATION = 'FETCH_CURRENT_LOCATION'
export const FETCH_BREADCRUMB_LOADS = 'FETCH_BREADCRUMB_LOADS'
export const FETCH_BREADCRUMB_LOADS_SUCCESS = 'FETCH_BREADCRUMB_LOADS_SUCCESS'

export const FETCH_NOTES = 'FETCH_NOTES'
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS'
export const CREATE_NOTE = 'CREATE_NOTE'
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'

export const FETCH_PAST_LOAD = 'FETCH_PAST_LOAD'
export const FETCH_PAST_LOAD_SUCCESS = 'FETCH_PAST_LOAD_SUCCESS'

export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS'
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const SUSCRIBE_CHAT = 'SUSCRIBE_CHAT'
export const UNSUSCRIBE_CHAT = 'UNSUSCRIBE_CHAT'

export const TRUCKS_FETCH_DRIVERS_START = 'TRUCKS_FETCH_DRIVERS_START'
export const TRUCKS_FETCH_DRIVERS_SUCCESS = 'TRUCKS_FETCH_DRIVERS_SUCCESS'
export const TRUCKS_FETCH_DRIVERS_TRUCKS_START =
  'TRUCKS_FETCH_DRIVERS_TRUCKS_START'
export const TRUCKS_FETCH_DRIVERS_TRUCKS_SUCCESS =
  'TRUCKS_FETCH_DRIVERS_TRUCKS_SUCCESS'
export const TRUCKS_UPDATE_DRIVER_START = 'TRUCKS_UPDATE_DRIVER_START'
export const TRUCKS_UPDATE_DRIVER_SUCCESS = 'TRUCKS_UPDATE_DRIVER_SUCCESS'

export const ADD_NEW_TRUCK = 'ADD_NEW_TRUCK'
export const ADD_NEW_TRUCK_SUCCESS = 'ADD_NEW_TRUCK_SUCCESS'

export const TRUCK_DELETE_START = 'TRUCK_DELETE_START'
export const TRUCK_DELETE_SUCCESS = 'TRUCK_DELETE_SUCCESS'

export const FETCH_TERMINAL = 'FETCH_TERMINAL'
export const FETCH_TERMINAL_SUCCESS = 'FETCH_TERMINAL_SUCCESS'
export const FETCH_TERMINAL_FAILURE = 'FETCH_TERMINAL_FAILURE'
export const TERMINAL_SET_COORDINATES = 'terminal/SET_COORDINATES'

export const UPDATE_TERMINAL_COORDINATES = 'UPDATE_TERMINAL_COORDINATES'
export const UPDATE_TERMINAL_COORDINATES_SUCCESS =
  'UPDATE_TERMINAL_COORDINATES_SUCCESS'
export const UPDATE_TERMINAL_COORDINATES_FAILURE =
  'UPDATE_TERMINAL_COORDINATES_FAILURE'

export const UPDATE_DEFAULT_TERMINAL = 'UPDATE_DEFAULT_TERMINAL'
export const UPDATE_DEFAULT_TERMINAL_SUCCESS = 'UPDATE_DEFAULT_TERMINAL_SUCCESS'
export const UPDATE_DEFAULT_TERMINAL_FAILURE = 'UPDATE_DEFAULT_TERMINAL_FAILURE'

export const FETCH_DOCUMENT_DETAILS = 'FETCH_DOCUMENT_DETAILS'
export const FETCH_DOCUMENT_DETAILS_SUCCESS = 'FETCH_DOCUMENT_DETAILS_SUCCESS'
export const SET_DOCUMENT_LOADING = 'document/setLoading'

export const FETCH_PREPROCESSING_TESTS = 'FETCH_PREPROCESSING_TESTS'
export const FETCH_PREPROCESSING_TESTS_SUCCESS =
  'FETCH_PREPROCESSING_TESTS_SUCCESS'

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT'
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS'
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE'
export const RESET_UPLOADING_DOCUMENT = 'RESET_UPLOADING_DOCUMENT'
export const EDIT_SUB_PART = 'EDIT_SUB_PART'
export const COMPANY_SET_DATA_PARSER = 'COMPANY_SET_DATA_PARSER'

export const EDIT_COMPANY_FLEET = 'EDIT_COMPANY_FLEET'
export const EDIT_EMAIL_PARSER = 'EDIT_EMAIL_PARSER'
export const EDIT_INFO_PARSER = 'EDIT_INFO_PARSER'
export const EDIT_FLEET = 'EDIT_FLEET'

export const FETCH_INVOICE = 'FETCH_INVOICE'
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS'
export const FETCH_INVOICE_ERROR = 'FETCH_INVOICE_ERROR'
export const FETCH_INVOICES = 'FETCH_INVOICES'
export const INVOICES_SET_LOADING = 'invoices/setLoading'
export const INVOICES_SET_INVOICES_DATA = 'invoices/setInvoicesData'
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS'
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR'
export const ADD_INVOICES = 'ADD_INVOICES'
export const ADD_INVOICES_SUCCESS = 'ADD_INVOICES_SUCCESS'
export const ADD_INVOICES_ERROR = 'ADD_INVOICES_ERROR'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR'
export const UPDATE_BILL_LINE = 'UPDATE_BILL_LINE'
export const UPDATE_BILL_LINE_SUCCESS = 'UPDATE_BILL_LINE_SUCCESS'
export const UPDATE_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT =
  'invoices/UPDATE_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT'
export const GET_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT =
  'invoices/GET_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT'
export const INVOICES_SET_SORT_OPTIONS = 'invoices/SET_SORT_OPTIONS'
export const INVOICE_UPDATE_INVOICE_SELLER =
  'invoice/INVOICE_UPDATE_INVOICE_SELLER'
export const INVOICE_UPDATE_INVOICE_DETAIL =
  'invoice/INVOICE_UPDATE_INVOICE_DETAIL'
export const UPDATE_BILL_LINE_TAG_LINKS = 'UPDATE_BILL_LINE_TAG_LINKS'
export const UPDATE_INVOICE_TAG_LINKS = 'UPDATE_INVOICE_TAG_LINKS'

export const INVOICES_UPDATE_SELLER = 'invoices/INVOICES_UPDATE_SELLER'
export const UPDATE_BILL_LINE_ERROR = 'UPDATE_BILL_LINE_ERROR'
export const EDIT_DATA_PARSER = 'EDIT_DATA_PARSER'
export const EDIT_COMPANY_PRODUCTS = 'EDIT_COMPANY_PRODUCTS'
export const EDIT_BUYER_SELLER = 'EDIT_BUYER_SELLER'
export const EDIT_USER_ACCESS = 'EDIT_USER_ACCESS'
export const EDIT_SESSION_USER_ACCESS = 'EDIT SESSION USER ACCESS'
export const EDIT_AUTO_CHARGE = 'EDIT_AUTO_CHARGE'
export const EDIT_BTFSTP = 'EDIT_BTFSTP'
export const REMOVE_INVOICE_COMPARE = 'REMOVE_INVOICE_COMPARE'
export const EDIT_LOAD = 'EDIT_LOAD'
export const EDIT_DATA_CORRECTION = 'EDIT_DATA_CORRECTION'
export const EDIT_ROUTE_FORMS = 'EDIT_ROUTE_FORMS'
export const SAVE_LOAD_FORMS = 'SAVE_LOAD_FORMS'
export const EDIT_FIXED_PRICE = 'EDIT_FIXED_PRICE'
export const ONLOAD_PRODUCT_DETAILS = 'ONLOAD_PRODUCT_DETAILS'
export const LOAD_PRODUCT_DETAILS_SUCCESS = 'LOAD_PRODUCT_DETAILS_SUCCESS'
export const SET_PROJECTED_FILTER = 'SET_PROJECTED_FILTER'
export const OPEN_BILL_LINES_SEARCH_TABLE = 'OPEN_BILL_LINES_SEARCH_TABLE'
export const CLOSE_BILL_LINES_SEARCH_TABLE = 'CLOSE_BILL_LINES_SEARCH_TABLE'
export const OPEN_BILL_LINES_ASSOCIATION_CONFIRMATION =
  'OPEN_BILL_LINES_ASSOCIATION_CONFIRMATION'
export const CLOSE_BILL_LINES_ASSOCIATION_CONFIRMATION =
  'CLOSE_BILL_LINES_ASSOCIATION_CONFIRMATION'

export const TOGGLE_SHOW_ORDER = 'TOGGLE_SHOW_ORDER'
export const TOGGLE_SHOW_PRODUCT = 'TOGGLE_SHOW_PRODUCT'
export const UPDATE_CURRENT_ACCESS = 'UPDATE_CURRENT_ACCESS'
export const TOGGLE_DOCUMENT_GRID_MODE = 'TOGGLE_DOCUMENT_GRID_MODE'
export const IS_UPLOADING_DOCUMENT = 'IS_UPLOADING_DOCUMENT'
export const TOGGLE_SHOW_FLEET = 'TOGGLE_SHOW_FLEET'

export const UPDATE_APP_HEADER = 'UPDATE_APP_HEADER'

export const FETCH_DASHBOARD_DOCUMENTS = 'FETCH_DASHBOARD_DOCUMENTS'
export const FETCH_DASHBOARD_LOADS = 'FETCH_DASHBOARD_LOADS'
export const FETCH_DASHBOARD_INVOICES = 'FETCH_DASHBOARD_INVOICES'
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE'
export const UPDATE_DASHBOARD_DATE_FILTER = 'UPDATE_DASHBOARD_DATE_FILTER'
export const UPDATE_DASHBOARD_MODEL_TYPE = 'UPDATE_DASHBOARD_MODEL_TYPE'
export const UPDATE_SHOW_COLUMNS = 'UPDATE_SHOW_COLUMNS'
export const RESET_SHOW_COLUMNS = 'RESET_SHOW_COLUMNS'
export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS'
export const FETCH_USER_TERMINAL_ACCESSES = 'FETCH_USER_TERMINAL_ACCESSES'

export const UPDATE_DOCUMENTS_FILTERS = 'UPDATE_DOCUMENTS_FILTERS'
export const RESET_DOCUMENTS_FILTERS = 'RESET_DOCUMENTS_FILTERS'
export const RESET_FILTERS_WITH_INVOICE_DOC_TYPE =
  'RESET_FILTERS_WITH_INVOICE_DOC_TYPE'
export const UPDATE_DASHBOARD_DOCUMENTS_FILTERS =
  'UPDATE_DASHBOARD_DOCUMENTS_FILTERS'
export const UPDATE_DASHBOARD_LOADS_FILTERS = 'UPDATE_DASHBOARD_LOADS_FILTERS'
export const INVOICE_FILTER = 'INVOICE_FILTER'
export const SET_DEFAULT_FILTER_FOR_DOCUMENTS =
  'SET_DEFAULT_FILTER_FOR_DOCUMENTS'
export const ERROR_LOAD = 'ERROR_LOAD'
export const SET_INVOICE_FILTER_DATA = 'invoices/SET_INVOICE_FILTER_DATA'
export const INVOICES_RESET_FILTERS = 'invoices/INVOICES_RESET_FILTERS'

export const TOGGLE_SIDEBAR_VIEW = 'TOGGLE_SIDEBAR_VIEW'
export const UPDATE_SHOW_FILTERS = 'UPDATE_SHOW_FILTERS'
export const UPDATE_SORT_FILTERS = 'UPDATE_SORT_FILTERS'
export const DOCUMENTS_SHOW_FORM = 'DOCUMENTS_SHOW_FORM'
export const SET_INLINE_LOAD_DATA = 'SET_INLINE_LOAD_DATA'
export const SET_INLINE_LOAD_EDIT = 'SET_INLINE_LOAD_EDIT'
export const SET_INLINE_LOAD_DELETE = 'SET_INLINE_LOAD_DELETE'
export const APP_SET_CONTENT_SCROLL_Y = 'app/APP_SET_CONTENT_SCROLL_Y'
export const TOGGLE_BILL_LINES_FORM_FIELD = 'TOGGLE_BILL_LINES_FORM_FIELD'

export const CREATE_START_TIMES_DATE = 'CREATE_START_TIMES_DATE'
export const UPDATE_START_TIMES_DATE = 'UPDATE_START_TIMES_DATE'
export const UPDATE_START_TIMES_FILTERS = 'UPDATE_START_TIMES_FILTERS'
export const RESET_START_TIMES_FILTERS = 'RESET_START_TIMES_FILTERS'
export const FETCH_START_TIMES = 'FETCH_START_TIMES'
export const FETCH_START_TIMES_SUCCESS = 'FETCH_START_TIMES_SUCCESS'
export const START_TIMES_CHANGE_FILTERS = 'START_TIMES_CHANGE_FILTERS'
export const START_TIMES_CHANGE_SORTS = 'START_TIMES_CHANGE_SORTS'
export const START_TIMES_CHANGE_VIEW_MODE = 'START_TIMES_CHANGE_VIEW_MODE'

export const REMOVE_BUYER_SELLER_RELATIONSHIP =
  'REMOVE_BUYER_SELLER_RELATIONSHIP'
export const ADD_BUYER_SELLER_RELATIONSHIP = 'ADD_BUYER_SELLER_RELATIONSHIP'
export const TERMINAL_FETCH_TERMINAL_LOCATIONS =
  'TERMINAL_FETCH_TERMINAL_LOCATIONS'
export const TERMINAL_SET_LOADING = 'TERMINAL_SET_LOADING'

export const UPDATE_LOAD_PDF_URL = 'load/UPDATE_LOAD_PDF_URL'
export const LOAD_SET_SORT_OPTIONS = 'load/SET_SORT_OPTIONS'
export const LOAD_UPDATE_LOAD_BY_ID = 'load/UPDATE_LOAD_BY_ID'
export const UPDATE_LOAD_TAGS_LINKS = 'UPDATE_LOAD_TAGS_LINKS'
export const UPDATE_LOADS_CONTAINER_UI = 'UPDATE_LOADS_CONTAINER_UI'

// order actions
export const UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS'
export const RESET_ORDERS_FILTERS = 'RESET_ORDERS_FILTERS'
export const UPDATE_ORDERS_DATE = 'UPDATE_ORDERS_DATE'
export const CREATE_ORDERS_DATE = 'CREATE_ORDERS_DATE'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const ORDER_SET_ORDERS_DATA = 'order/setOrdersData'
export const ORDER_UPDATE_LOAD_STATUS = 'order/updateLoadStatus'
export const ORDER_UPDATE_TRACKING_DATA = 'order/updateTrackingData'
export const ORDER_UPDATE_START = 'order/updateOrderStart'
export const ORDER_UPDATE_SUCCESS = 'order/updateOrderSuccess'
export const ORDER_UPDATE_FAILURE = 'order/updateOrderFail'
export const ORDER_CREATE_LOAD_START = 'order/createLoadStart'
export const ORDER_CREATE_LOAD_SUCCESS = 'order/createLoadSuccess'
export const ORDER_CREATE_LOAD_FAILURE = 'order/createLoadFail'

export const UPDATE_ALL_DOCUMENT_FILTERS = 'UPDATE_ALL_DOCUMENT_FILTERS'
export const UPDATE_ALL_LOAD_FILTERS = 'UPDATE_ALL_LOAD_FILTERS'
export const FETCH_LOADS_CHANGE_SUCCESS = 'FETCH_LOADS_CHANGE_SUCCESS'

export const SET_DELETE_MODAL_ID = 'SET_DELETE_MODAL_ID'
export const RESET_DELETE_MODAL_ID = 'RESET_DELETE_MODAL_ID'

export const UPDATE_FILTER_CONTAINER = 'UPDATE_FILTER_CONTAINER'
export const RESET_FILTER_CONTAINER = 'RESET_FILTER_CONTAINER'
