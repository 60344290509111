import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IGetBundlesParams, IBundle } from '~/types/models/IBundle'

const useModifyBundles = (params: IGetBundlesParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'bundles',
      sessionUser?.id,
      buildGetUrl(apiClient.bundles.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addBundle = useCallback(
    (data: IBundle) => {
      queryClient.setQueryData<IBundle[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateBundle = useCallback(
    (id: number, data: IBundle) => {
      queryClient.setQueryData<IBundle[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeBundle = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBundle[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addBundle,
    updateBundle,
    removeBundle,
  }
}

export default useModifyBundles
