import React from 'react'
import { IonButton, IonIcon, IonSpinner, IonText } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import { When } from 'react-if'

import clsx from 'clsx'

import type { IButtonProps } from './type'

import './styles.scss'

const Button = React.forwardRef<HTMLIonButtonElement, IButtonProps>(
  (
    {
      label,
      labelProps,
      icon,
      iconProps,
      style,
      color = 'primary',
      onClick,
      tooltipProps,
      loading,
      buttonProps,
      children,
      badge,
      className,
      size,
      ...props
    },
    ref,
  ) => (
    <ToolTipOverlay {...tooltipProps}>
      <IonButton
        {...buttonProps}
        {...props}
        size={size}
        className={clsx('Button__container', className)}
        ref={ref}
        color={color}
        style={style}
        onClick={onClick}
        disabled={
          loading || buttonProps?.disabled || props.disabled || props.isDisabled
        }
      >
        {loading && <IonSpinner name='lines-small' />}
        {!loading && (
          <>
            {icon && <IonIcon size={size} icon={icon} {...iconProps} />}
            {(label || children) && (
              <IonText
                style={{ marginLeft: icon ? 5 : 0, verticalAlign: 'middle' }}
                {...labelProps}
              >
                {label || children}
              </IonText>
            )}
          </>
        )}
        <When condition={Boolean(badge)}>
          <div className='badgeContainer'>{badge}</div>
        </When>
      </IonButton>
    </ToolTipOverlay>
  ),
)

Button.defaultProps = {
  buttonProps: {},
}

export default Button
