import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import {
  DATA_PARSER_INVOICE_TYPES,
  DATA_PARSER_LOAD_TYPES,
  DOC_TYPE_OPTIONS,
} from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IDataParserFormProps } from './type'
import { IDataParserFormData } from '~/types/models/IDataParser'

function DataParserForm(props: IDataParserFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const { allCompaniesWithCurrentCompany, isLoadingBuyerSellers } =
    useQueryBuyerSellers()

  const formRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isUpdating = Boolean(formData?.id)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'companyId',
        label: 'Company',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: allCompaniesWithCurrentCompany.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
        })),
        isLoading: isLoadingBuyerSellers,
        isDisabled: isUpdating,
      },
      {
        name: 'documentType',
        label: 'Document Type',
        type: EFieldType.singleSelect,
        options: DOC_TYPE_OPTIONS,
        isRequired: true,
        onChange() {
          formRef.current?.setValue('parser', null)
        },
      },
      {
        name: 'parser',
        label: 'Parser',
        isRequired: true,
        type: EFieldType.singleSelect,
        isDisabled: ({ watch }) => !watch('documentType', null),
        options({ watch }) {
          const documentType = watch('documentType', null)
          if (documentType === 'Invoice') {
            return DATA_PARSER_INVOICE_TYPES
          }
          return DATA_PARSER_LOAD_TYPES
        },
      },
    ],
    [allCompaniesWithCurrentCompany, isLoadingBuyerSellers, isUpdating],
  )

  const defaultValues: IDataParserFormData = {
    companyId: currentCompany?.id,
    documentType: null,
    parser: null,
  }

  const schema = Yup.object({
    companyId: Yup.number()
      .required('This field required!')
      .typeError('This field required!'),
    documentType: Yup.string()
      .required('This field required!')
      .typeError('This field required!'),
    parser: Yup.string()
      .required('This field required!')
      .typeError('This field required!'),
  })

  const create = async (formValues: IDataParserFormData) => {
    const payload = _.pick(formValues, ['companyId', 'documentType', 'parser'])
    const response = await apiClient.dataParsers.create(payload)
    if (Object.keys(response.errors || {}).length > 0) {
      setBackendError(toastMessages.createError)
    } else {
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const update = async (formValues: IDataParserFormData) => {
    const payload = _.pick(formValues, ['documentType', 'parser'])

    const response = await apiClient.dataParsers.update(
      formData?.id as number,
      payload,
    )
    if (Object.keys(response.errors || {}).length > 0) {
      setBackendError(toastMessages.updateError)
    } else {
      afterUpdate && afterUpdate(response)
      toast.success(toastMessages.updateSuccess)
    }
  }

  const onSubmitForm = async (formValues: IDataParserFormData) => {
    setIsLoading(true)
    setBackendError('')
    try {
      if (isUpdating) {
        await update(formValues)
      } else {
        await create(formValues)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
      ref={formRef}
      isHiddenCancelButton
      onSubmit={onSubmitForm}
    />
  )
}

export default DataParserForm
