import { useMemo } from 'react'
import { Badge } from 'react-bootstrap'
import { FlagIcon } from '~/components/shared'
import { ELoadPrimaryParserStatusString } from '~/types/enums/ELoad'
import { ILoad } from '~/types/models/ILoad'
import ParserDataTooltipContent from '../ParserDataTooltipContent'
import Tooltip from 'rc-tooltip'

export interface ILoadStatusCellProps {
  load: ILoad
}

function LoadStatusCell(props: ILoadStatusCellProps) {
  const { load } = props

  const color = {
    [ELoadPrimaryParserStatusString.done]: 'success',
    [ELoadPrimaryParserStatusString.updated]: 'primary',
    [ELoadPrimaryParserStatusString.noCollection]: 'warning',
    [ELoadPrimaryParserStatusString.waiting]: 'secondary',
    [ELoadPrimaryParserStatusString.notSent]: '#ffd4e3',
    [ELoadPrimaryParserStatusString.error]: '#danger',
  }

  const tooltip = useMemo(() => {
    const { primaryParserStatus } = load
    if (primaryParserStatus === 'No Collection') {
      return (
        <div style={{ textAlign: 'left', fontSize: 12 }}>
          <div style={{ textAlign: 'center', marginBottom: 4 }}>
            <strong>No Collection</strong>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 4,
            }}
          >
            <i>
              Parsing service did not automatically match this ticket to a
              seller
            </i>
          </div>
          <ParserDataTooltipContent data={load} />
        </div>
      )
    }
    return <ParserDataTooltipContent data={load} />
  }, [load])

  return (
    <Tooltip
      placement='left'
      overlay={tooltip}
      overlayClassName='LoadIndexTable__tooltipOverlay'
    >
      <Badge
        bg={color[load.primaryParserStatus]}
        style={{
          fontSize: 13,
          background: color[load.primaryParserStatus],
          marginLeft: 4,
        }}
      >
        {load.flags?.length ? (
          <FlagIcon color='red' style={{ marginRight: 4 }} />
        ) : null}
        <span>{load.primaryParserStatus}</span>
      </Badge>
    </Tooltip>
  )
}

export default LoadStatusCell
