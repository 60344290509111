import { PropsWithChildren, useCallback, useState } from 'react'

import OrderFormContext from './OrderFormContext'

import type { IOrderFormProviderProps } from './type'
import {
  IOrderFormDialogProps,
  OrderFormDialog,
} from '~/components/shared/SchedulesTable/OrderFormDialog'
import { IOrder } from '~/types/models/IOrder'

function OrderFormProvider(props: PropsWithChildren<IOrderFormProviderProps>) {
  const { children } = props

  const [orderFormDialog, setOrderFormDialog] = useState({
    isOpen: false,
    orderData: undefined as IOrder | undefined,
    afterUpdate: undefined as (() => void) | undefined,
  })

  const onOpenOrderFormDialog = useCallback(
    (options: Pick<IOrderFormDialogProps, 'orderData' | 'afterUpdate'>) => {
      setOrderFormDialog({
        isOpen: true,
        orderData: options.orderData,
        afterUpdate: options.afterUpdate,
      })
    },
    [],
  )

  const onCloseOrderFormDialog = useCallback(() => {
    setOrderFormDialog({
      isOpen: false,
      orderData: undefined,
      afterUpdate: undefined,
    })
  }, [])

  return (
    <>
      <OrderFormContext.Provider
        value={{
          onOpenOrderFormDialog,
          onCloseOrderFormDialog,
        }}
      >
        {children}
      </OrderFormContext.Provider>
      <OrderFormDialog
        isOpen={orderFormDialog.isOpen}
        orderData={orderFormDialog.orderData}
        afterUpdate={() => {
          orderFormDialog.afterUpdate && orderFormDialog.afterUpdate()
          onCloseOrderFormDialog()
        }}
        onClose={onCloseOrderFormDialog}
      />
    </>
  )
}
export default OrderFormProvider
