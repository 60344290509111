import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure, Button } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { FORM_FIELD_TYPE, DOC_TYPE_OPTIONS } from '~/utils/constants'
import { shuffle } from 'ionicons/icons'
import {
  selectCommonBuyerOptions,
  selectCommonSellerOptions,
  selectMyCurrentCompany,
} from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

import './EmailParserForm.scss'

const EmailParserForm = (props) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isSelectingBuyer, setIsSelectingBuyer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const buyerOptions = useSelector(selectCommonBuyerOptions)
  const sellerOptions = useSelector(selectCommonSellerOptions)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const isUpdating = useMemo(
    () => Boolean(formData?.id),
    [formData?.id],
  )

  const onToggleBuyerSeller = useCallback(() => {
    setIsSelectingBuyer(prev => !prev)
  }, [])

  const fields = useMemo(
    () => [
      {
        name: 'isSelectingBuyer',
        label: '',
        type: FORM_FIELD_TYPE.custom,
        render() {
          return (
            <Button
              icon={shuffle}
              label={isSelectingBuyer ? 'Buyer' : 'Seller'}
              fill='clear'
              onClick={onToggleBuyerSeller}
              tooltipProps={{
                placement: 'top',
                content: isSelectingBuyer
                  ? 'Click to change to Seller'
                  : 'Click to change to Buyer',
              }}
              className='ion-no-padding EmailParserForm__buyerSellerButton'
            />
          )
        },
      },
      {
        name: 'sellerId',
        label: 'Seller',
        type: FORM_FIELD_TYPE.singleSelect,
        options: sellerOptions,
        size: 12,
        isHidden: isSelectingBuyer,
        isRequired: true,
      },
      {
        name: 'buyerId',
        label: 'Buyer',
        type: FORM_FIELD_TYPE.singleSelect,
        options: buyerOptions,
        size: 12,
        isHidden: !isSelectingBuyer,
        isRequired: true,
      },
      {
        name: 'docTypeValue',
        label: 'Doc Type Value',
        size: 6,
        type: FORM_FIELD_TYPE.singleSelect,
        options: DOC_TYPE_OPTIONS,
        isRequired: true,
      },
      {
        name: 'sellerRegex',
        label: 'Seller Regex',
        size: 6,
        isRequired: true,
      },
      {
        name: 'buyerRegex',
        label: 'Buyer Regex',
        size: 6,
        isRequired: true,
      },
      {
        name: 'docTypeRegex',
        label: 'Doc Type Regex',
        size: 6,
        isRequired: true,
      },
    ],
    [buyerOptions, isSelectingBuyer, onToggleBuyerSeller, sellerOptions],
  )

  const defaultValues = useMemo(
    () => ({
      sellerId: null,
      buyerId: null,
      docTypeValue: '',
      sellerRegex: '',
      buyerRegex: '',
      docTypeRegex: '',
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerId: Yup.lazy(() => {
          if (isSelectingBuyer) {
            return Yup.number().nullable()
          }

          return Yup.number('Seller is required!').typeError(
            'Seller is required!',
          )
        }),
        buyerId: Yup.lazy(() => {
          if (isSelectingBuyer) {
            return Yup.number('Buyer is required!').typeError(
              'Buyer is required!',
            )
          }
          return Yup.number().nullable()
        }),
        docTypeValue: Yup.string().required('Doc type value is required!'),
        sellerRegex: Yup.string().required('Seller regex is required!'),
        buyerRegex: Yup.string().required('Buyer regex is required!'),
        docTypeRegex: Yup.string().required('Doc type regex is required!'),
      }),
    [isSelectingBuyer],
  )

  const updateEmailParser = useCallback(
    async formValues => {
      const payload = _.pick(formValues, [
        'sellerId',
        'buyerId',
        'docTypeValue',
        'sellerRegex',
        'buyerRegex',
        'docTypeRegex',
      ])
      const { errors, ...response } = await apiClient.emailParsers.update(
        formValues.id,
        payload
      )
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate],
  )

  const createEmailParser = useCallback(
    async formValues => {
      const payload = { ...formValues }
      if (isSelectingBuyer) {
        payload.sellerId = currentCompany?.id
      } else {
        payload.buyerId = currentCompany?.id
      }
      const { emailParser } = await apiClient.emailParsers.create(payload)
      if (emailParser?.errors?.length) {
        toast.error(toastMessages.createError)
      } else {
        afterCreate && afterCreate(emailParser)
        toast.success(toastMessages.createSuccess)
      }
    },
    [currentCompany?.id, isSelectingBuyer, afterCreate],
  )

  const handleSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateEmailParser(formValues)
        } else {
          await createEmailParser(formValues)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createEmailParser, isUpdating, updateEmailParser],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      fields={fields}
      schema={schema}
      isLoading={isLoading}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default EmailParserForm
