import { useMemo } from 'react'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'

import singularize from '~/utils/singularize'

export interface IProps {
  subTab: string
}

const useTableCompanySellerBuyerSubTab = (props: IProps) => {
  const { subTab } = props

  const name = singularize(subTab) as EScope

  const columns = useMemo<IRTColumnDef<ICompany>[]>(
    () => [
      {
        header: name,
        accessorKey: 'name',
        size: 250,
        Cell({ row }) {
          const rowData = row.original
          return (
            <CompanyInfo
              companyType={name}
              defaultCompanyCode='Company'
              company={{
                value: rowData.id,
                name: rowData.name,
                code: rowData.code,
                image: rowData.logo,
                label: `${rowData.code} - ${rowData.name}`,
              }}
            />
          )
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 120,
      },
    ],
    [name],
  )

  return { columns }
}

export default useTableCompanySellerBuyerSubTab
