import React, { useState, useEffect, useCallback } from 'react'
import {
  IonButton,
  IonIcon,
  IonLabel,
  isPlatform,
  IonModal,
} from '@ionic/react'
import Dropzone from 'react-dropzone'
import { cloudUploadOutline } from 'ionicons/icons'
import {
  takePicture,
  chooseFileFromDevice,
  DOCUMENT_SOURCES,
  chooseFileFromStorage,
} from './_helper'
import {
  ImageEditing,
  PdfViewer,
  StorageFilesPicker,
} from '~/components/shared'
import DocumentPreview from './DocumentPreview'
import EditPreview from './EditPreview'
import { Stack } from 'react-bootstrap'
import clsx from 'clsx'

import './DocumentPicker.scss'

const DocumentPicker = props => {
  const {
    useCamera = true,
    useStorage = false,
    storageUrl = undefined,
    onPicked,
    editable = false,
    viewable,
    onEditing = undefined,
    dropZoneStyle,
    enablePdfWarning = true,
    pickedFiles,
    multiple = true,
    acceptedFiles,
    setCurrentStatus,
    setLoadForm,
    onCameraStart,
    onCameraEnd,
    dropZoneContainerStyle,
    showVerticalButton = false,
    submitInvoiceButton,
    deletable = true,
    showEditPreview = false,
    onUpdateEditingLoad,
    noMargin = false,
    imageProps,
    onSubmit,
    tooltipSubmitProps,
    submitButtonProps,
    className,
  } = props

  const [editedImage, setEditedImage] = useState()
  const [showPdfmessage, setShowPdfmessage] = useState()
  const [isEditing, setIsEditing] = useState()
  const [isCropping, setIsCropping] = useState()
  const [cropper, setCropper] = useState()
  const [selectedFiles, setSelectedFiles] = useState(pickedFiles || [])
  const [imageName, setImageName] = useState('')
  const [isPreviewPDF, setIsPreviewPDF] = useState(false)
  const [scale, setScale] = useState(1)

  const [showModal, setShowModal] = useState(false)

  const useFileInModal = showVerticalButton

  useEffect(() => {
    if (pickedFiles) {
      setSelectedFiles(pickedFiles)
    }
  }, [pickedFiles])

  const updateSeletedList = files => {
    setSelectedFiles(files)
    onPicked && onPicked(files)
    onUpdateEditingLoad && onUpdateEditingLoad(files[0])

    setCurrentStatus &&
      setCurrentStatus(() => {
        if (files.length > 0) {
          return false
        }

        return true
      })
  }

  const onTakePicture = async () => {
    onCameraStart && onCameraStart()
    setLoadForm && setLoadForm(false)
    const image = await takePicture()
    if (!image.image) return

    const _files = multiple ? [...selectedFiles, image] : [image]
    setLoadForm && setLoadForm(true)
    updateSeletedList(_files)
    onCameraEnd && onCameraEnd()
  }

  const onChooseFileFromDevice = files => {
    const _files = chooseFileFromDevice(files)
    if (_files.length < 1) {
      return
    }
    if (multiple) {
      const _unexistedFiles = _files.filter(
        file => !selectedFiles.find(f => f.name == file.name),
      )
      const _selectedFiles = [...selectedFiles, ..._unexistedFiles]
      enablePdfWarning &&
        setShowPdfmessage(
          _selectedFiles.find(file => file.type == 'application/pdf'),
        )
      updateSeletedList(_selectedFiles)
    } else {
      enablePdfWarning && setShowPdfmessage(_files[0].type == 'application/pdf')
      updateSeletedList([_files[0]])
    }
  }

  const onChooseFileFromStorage = files => {
    const _files = chooseFileFromStorage(files)
    if (_files.length < 1 || multiple) {
      updateSeletedList(_files)
    } else {
      updateSeletedList([_files[0]])
    }
  }

  const updateEditing = value => {
    setIsEditing(value)
    onEditing && onEditing(value)
  }

  const onCropImage = () => {
    if (isCropping) {
      setIsCropping(false)
      setCropper(cropper.clear())
    } else {
      setIsCropping(true)
      setCropper(cropper.crop())
    }
  }

  const onMoveLeft = () => {
    setCropper(cropper.rotate(90))
  }

  const onMoveRight = () => {
    setCropper(cropper.rotate(-90))
  }

  const onZoom = type => {
    const _scale = type == '-' ? scale - 0.1 : scale + 0.1
    setScale(_scale)
    setCropper(cropper.scale(_scale, _scale))
  }

  const onDoneEditing = () => {
    const newList = selectedFiles.map(file => {
      if (file.key === editedImage.key) {
        file.image = cropper.getCroppedCanvas().toDataURL()
        file.source = DOCUMENT_SOURCES.EDITOR
        file.displayName = imageName
      }

      return file
    })
    updateSeletedList(newList)
    if (useFileInModal) setShowModal(false)
    else {
      setEditedImage(undefined)
      updateEditing(false)
    }
    setIsCropping(false)
  }

  const onEditFile = file => {
    setEditedImage(file)
    updateEditing(true)
    setImageName(file.displayName)
    setShowModal(true)
  }
  const onOpenNewWindow = useCallback(async () => {
    const url = editedImage && editedImage.image
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
  }, [editedImage])

  const onOpenNewTab = useCallback(async () => {
    const url = editedImage && editedImage.image
    window.open(url, '_blank')
  }, [editedImage])

  const onPreview = async file => {
    setEditedImage(file)
    setIsPreviewPDF(true)
  }

  const onDeleteFile = file => {
    const newList = selectedFiles.filter(f => f.key !== file.key)
    updateSeletedList(newList)
  }

  const onChangeFileName = (file, value) => {
    const newList = selectedFiles.map(_file => {
      if (_file.key === file.key) {
        _file.displayName = value
      }

      return _file
    })
    updateSeletedList(newList)
  }

  const needHideTakeAndChooseFileButtons =
    showPdfmessage && selectedFiles.length > 0

  const onDismissModal = () => setShowModal(false)
  const onSetStatus = () => setCurrentStatus(true)
  return (
    <div className={clsx('DocumentPicker__container', className)}>
      <div className='DocumentPicker__uploadContainer'>
        {setCurrentStatus && !multiple && (
          <IonButton
            className='DocumentPicker__addDocument'
            onClick={onSetStatus}
          >
            Add document
          </IonButton>
        )}
        <Stack direction='horizontal' className='DocumentPicker__fileInput'>
          {useStorage && !needHideTakeAndChooseFileButtons && (
            <StorageFilesPicker
              storageUrl={storageUrl}
              onPickerFile={onChooseFileFromStorage}
              onTakePicture={onTakePicture}
              selectedFiles={selectedFiles}
              useCamera={useCamera}
              showVerticalButton={showVerticalButton}
            />
          )}

          {(multiple || (!multiple && selectedFiles.length == 0)) && (
            <Dropzone
              multiple={multiple}
              maxfiles={!multiple ? 1 : 0}
              accept={acceptedFiles}
              onDrop={onChooseFileFromDevice}
              className='DropZone'
              style={{ width: '100%', height: '100%', dropZoneContainerStyle }}
              activeStyle={{
                border: '5px solid green',
              }}
            >
              <IonButton
                className='drop-box'
                style={{ width: '100%', ...dropZoneStyle }}
                fill='clear'
              >
                <IonIcon style={{ fontSize: 50 }} icon={cloudUploadOutline} />
                <IonLabel style={{ width: '100%', maxWidth: '100%' }}>
                  {isPlatform('mobile')
                    ? 'Click to select files'
                    : 'Drop files here'}
                </IonLabel>
              </IonButton>
            </Dropzone>
          )}
        </Stack>
        {pickedFiles.length > 0 && submitInvoiceButton}
        {selectedFiles.length > 0 && (
          <div
            style={{ display: 'flex', overflow: 'auto', maxWidth: '100%' }}
            className='DocumentPicker__documentPreview'
          >
            {selectedFiles.map((document, index) =>
              showEditPreview ? (
                <EditPreview
                  className='DocumentPicker__editPreview'
                  key={index}
                  document={document}
                  viewable={viewable}
                  editable={editable}
                  onEdit={onEditFile}
                  onDelete={onDeleteFile}
                  onTextChange={onChangeFileName}
                  onPreview={onPreview}
                  customView={showVerticalButton}
                  deletable={deletable}
                  noMargin={noMargin}
                />
              ) : (
                <DocumentPreview
                  key={index}
                  document={document}
                  viewable={viewable}
                  editable={editable}
                  onEdit={onEditFile}
                  onDelete={onDeleteFile}
                  onTextChange={onChangeFileName}
                  onPreview={onPreview}
                  customView={showVerticalButton}
                  deletable={deletable}
                  onSubmit={onSubmit}
                  tooltipSubmitProps={tooltipSubmitProps}
                  submitButtonProps={submitButtonProps}
                  customViewClassName='DocumentPicker__customViewContainer'
                />
              ),
            )}
          </div>
        )}
      </div>
      {useFileInModal ? (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <ImageEditing
            onSetCropper={setCropper}
            editedImage={editedImage}
            isEditing={isEditing}
            imageName={imageName}
            onSetImageName={setImageName}
            onCropImage={onCropImage}
            isCropping={isCropping}
            onZoom={onZoom}
            onMoveLeft={onMoveLeft}
            onMoveRight={onMoveRight}
            onDoneEditing={onDoneEditing}
            updateEditing={updateEditing}
            setIsCropping={setIsCropping}
            onCancel={onDismissModal}
            useFileInModal={useFileInModal}
          />
        </IonModal>
      ) : (
        isEditing &&
        imageName && (
          <ImageEditing
            onSetCropper={setCropper}
            editedImage={editedImage}
            isEditing={isEditing}
            imageName={imageName}
            onSetImageName={setImageName}
            onCropImage={onCropImage}
            isCropping={isCropping}
            onZoom={onZoom}
            onMoveLeft={onMoveLeft}
            onMoveRight={onMoveRight}
            onDoneEditing={onDoneEditing}
            updateEditing={updateEditing}
            setIsCropping={setIsCropping}
          />
        )
      )}
      <PdfViewer
        imageProps={imageProps}
        value={
          !!editedImage &&
          (editedImage.fileType == 'pdf' ||
            editedImage.fileExtension === 'pdf' ||
            editedImage?.type === 'application/pdf')
        }
        document={editedImage && editedImage.image}
        showPopOver={isPreviewPDF}
        setShowPopOver={setIsPreviewPDF}
        onOpenNewTab={onOpenNewTab}
        onOpenNewWindow={onOpenNewWindow}
        modelId={editedImage?.modelId}
        documentId={editedImage?.documentId}
      />
    </div>
  )
}

DocumentPicker.defaultProps = {
  imageProps: {},
  pickedFiles: [],
}

export default DocumentPicker
