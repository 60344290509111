import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryWorkerCompanyAttributes } from '~/hooks/useQueryData'

import { FloatingInputV2 } from '~/components/shared/FloatingForm/FloatingInputV2'
import { FloatingSelectV2 } from '~/components/shared/FloatingForm/FloatingSelectV2'
import { ConcordFormCheckbox } from '~/components/shared'
import { Button } from '~/components'
import { Case, Default, Switch, When } from 'react-if'

import { produce } from 'immer'

import type { ICollectEmployeeInfoProps } from './type'
import type { IWorkerCompanyAttribute } from '~/types/models/ICheck'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import getErrorFromCheckResponse from '~/utils/getErrorFromCheckResponse'
import { Alert } from 'react-bootstrap'

function CollectEmployeeInfo(props: ICollectEmployeeInfoProps) {
  const { workerUid } = props

  const {
    workerCompanyAttributes,
    isLoadingWorkerCompanyAttributes,
    refectWorkerCompanyAttributes,
  } = useQueryWorkerCompanyAttributes({
    worker: {
      checkUid: workerUid,
    },
  })

  const [data, setData] = useState<IWorkerCompanyAttribute[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onChangeCheckbox = useCallback(
    (item: IWorkerCompanyAttribute, index: number) =>
      (event: Event, checked: boolean) => {
        setData(prev =>
          produce(prev, draft => {
            draft[index].value = checked
          }),
        )
      },
    [],
  )

  const onChangeEffectiveStart = useCallback(
    (item: IWorkerCompanyAttribute, index: number) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ value }: any) => {
        setData(prev =>
          produce(prev, draft => {
            draft[index].effectiveStart = value
          }),
        )
      },
    [],
  )

  const onChangeValues = useCallback(
    (item: IWorkerCompanyAttribute, index: number) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ value }: any) => {
        setData(prev =>
          produce(prev, draft => {
            draft[index].value = value
          }),
        )
      },
    [],
  )

  const renderFields = useMemo(
    () =>
      data.map((item, index) => {
        let type = item.type
        if (['number', 'currency', 'percent'].includes(type)) {
          type = 'number'
        }

        return (
          <div className='inputItemContainer' key={index}>
            <div className='inputs'>
              <Switch>
                <Case condition={type === 'boolean'}>
                  <ConcordFormCheckbox
                    label={item.label}
                    onChange={onChangeCheckbox(item, index)}
                    value={Boolean(item.value || item.defaultValue)}
                  />
                </Case>
                <Case condition={type === 'select'}>
                  <FloatingSelectV2
                    label={item.label}
                    options={item.options}
                    value={item.value || item.defaultValue}
                    onChange={onChangeValues(item, index)}
                    styles={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 999,
                      }),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      singleValue: (provided: any) => ({
                        ...provided,
                        fontSize: 14,
                        color: '#828282',
                      }),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      option: (provided: any) => ({
                        ...provided,
                        fontSize: 14,
                      }),
                    }}
                  />
                </Case>
                <Default>
                  <FloatingInputV2
                    label={item.label}
                    inputType={type}
                    value={item.value || item.defaultValue}
                    onChange={onChangeValues(item, index)}
                  />
                </Default>
              </Switch>
              <FloatingInputV2
                isRequired={item.effectiveStartRequired}
                label='Effective Start'
                inputType='date'
                className='effectiveStart'
                value={item.effectiveStart}
                onChange={onChangeEffectiveStart(item, index)}
              />
            </div>
            <When condition={Boolean(item.description)}>
              <div className='hintText'>{item.description}</div>
            </When>
          </div>
        )
      }),
    [data, onChangeCheckbox, onChangeEffectiveStart, onChangeValues],
  )

  const getValue = useCallback((item: IWorkerCompanyAttribute) => {
    if (item.value === null) {
      if (item.type === 'boolean') {
        return 'false'
      }
      return ''
    }
    return item.value.toString()
  }, [])

  const onSave = useCallback(async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const definedAttribues = data.map(item => ({
        name: item.name,
        value: getValue(item),
        effectiveStart: item.effectiveStart,
      }))
      const response = await apiClient.check.updateWorkerCompanyAttributes({
        worker: {
          checkUid: workerUid,
          companyDefinedAttributes: definedAttribues,
        },
      })
      const error = getErrorFromCheckResponse(response)
      if (error) {
        setErrorMessage(error)
      } else {
        toast.success(toastMessages.updateSuccess)
        refectWorkerCompanyAttributes()
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getValue, workerUid])

  useEffect(() => {
    setData(workerCompanyAttributes)
  }, [workerCompanyAttributes])

  return (
    <div className='CollectEmployeeInfo__container'>
      <When condition={Boolean(errorMessage)}>
        <Alert variant='danger' style={{ fontSize: 14 }}>
          {errorMessage}
        </Alert>
      </When>
      <When condition={data.length === 0 && !isLoadingWorkerCompanyAttributes}>
        <Alert variant='info' style={{ fontSize: 14 }}>
          No attributes were found!
        </Alert>
      </When>
      {renderFields}
      <Button
        label='Save'
        onClick={onSave}
        loading={isLoading || isLoadingWorkerCompanyAttributes}
        isDisabled={data.length === 0}
      />
    </div>
  )
}

export default CollectEmployeeInfo
