import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_ORDERS_FILTERS } from '~/constants/orders/constants'

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    loadedOrders: false,
    date: {
      startDate: null,
      endDate: null,
    },
    filters: DEFAULT_ORDERS_FILTERS,
    endOfTheList: false,
    loading: undefined,
    loadingMore: undefined,
    toggleShowOrders: false,
    loadingTable: false,
    searchWord: '',
    tableLoadingRowModel: [],
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
    setSearchWord(state, { payload }) {
      state.searchWord = payload
    },
    toggleTableLoadingRowModel(state, { payload }) {
      const index = state.tableLoadingRowModel.indexOf(payload)
      if (index === -1) {
        state.tableLoadingRowModel.push(payload)
      } else {
        state.tableLoadingRowModel.splice(index, 1)
      }
    },
  },
})

export default ordersSlice
