import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const ColorPaletteIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      fill={color}
      {...svgProps}
    >
      <path d='m441 336.2-.06-.05c-9.93-9.18-22.78-11.34-32.16-12.92l-.69-.12c-9.05-1.49-10.48-2.5-14.58-6.17-2.44-2.17-5.35-5.65-5.35-9.94s2.91-7.77 5.34-9.94l30.28-26.87c25.92-22.91 40.2-53.66 40.2-86.59s-14.25-63.68-40.2-86.6c-35.89-31.59-85-49-138.37-49C223.72 48 162 71.37 116 112.11c-43.87 38.77-68 90.71-68 146.24s24.16 107.47 68 146.23c21.75 19.24 47.49 34.18 76.52 44.42a266.17 266.17 0 0 0 86.87 15h1.81c61 0 119.09-20.57 159.39-56.4 9.7-8.56 15.15-20.83 15.34-34.56.21-14.17-5.37-27.95-14.93-36.84ZM112 208a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm40 135a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm40-199a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm64 271a48 48 0 1 1 48-48 48 48 0 0 1-48 48Zm72-239a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z' />
    </svg>
  )
}

export default ColorPaletteIcon
