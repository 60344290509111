import { object, bool, string, oneOfType } from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { routesPaths } from '~/constants/routesPaths'
import history from '~/utils/history'
import { UniversalLoadingPage } from '../shared'

const PrivateRoute = ({
  component: Component,
  exact = false,
  path,
  authenticated,
  alias,
}) => {
  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: routesPaths.login,
          search: `?back_url=${encodeURIComponent(history.location.pathname)}`,
        }}
      />
    )
  }

  return (
    <Route exact={exact} path={alias || path}>
      <Component fallback={<UniversalLoadingPage isWrappedByBody />} />
    </Route>
  )
}

PrivateRoute.propTypes = {
  component: object.isRequired,
  path: string.isRequired,
  authenticated: oneOfType([bool, object]),
  exact: bool,
  location: object,
}

export default PrivateRoute
