import _ from 'lodash'
import { Form } from 'react-bootstrap'
import './styles.scss'
import { IonLabel } from '@ionic/react'
import clsx from 'clsx'
import { useMemo } from 'react'

export const FloatingInputV2 = props => {
  const {
    name,
    label,
    inputType,
    errors,
    isReadOnly,
    style,
    onChange,
    value,
    className,
    isDisabled,
    isRequired,
    onBlur,
  } = props

  const labelMinWidth = useMemo(() => {
    const text = label || name || ''
    if (text.length <= 10) {
      return 65
    }
    return text.length * 6
  }, [label, name])

  return (
    <div
      className={clsx('FloatingSelectV2_root', className, {
        error: _.get(errors, name),
      })}
      style={style}
    >
      <IonLabel
        className='FloatingSelectV2_text'
        style={{ minWidth: labelMinWidth }}
      >
        <span>{label || name}</span>
        {isRequired && <span className='error asterisk'>*</span>}
      </IonLabel>
      <Form.Control
        disabled={isReadOnly}
        type={inputType}
        placeholder={label}
        className={clsx({ isDisabled })}
        onChange={e => {
          onChange &&
            onChange({
              value: e.target.value,
              field: name,
            })
        }}
        style={{
          height: 38,
          borderWidth: 0,
          backgroundColor: '#f1f1f154',
        }}
        value={value}
        onBlur={onBlur}
      />
    </div>
  )
}
