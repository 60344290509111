import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const CompanyIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='-2 0 16 16'
      fill={color}
      {...svgProps}
    >
      <path
        d='M10.5 0h-9A1.5 1.5 0 0 0 0 1.5V16h12V1.5A1.5 1.5 0 0 0 10.5 0ZM5 15v-3h2v3Zm6 0H8v-3.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V15H1V1.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5ZM3 3h2v2H3Zm4 0h2v2H7ZM3 7h2v2H3Zm4 0h2v2H7Z'
        data-name='Path 133'
      />
    </svg>
  )
}
export default CompanyIcon
