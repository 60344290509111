import { useState } from 'react'
import {
  useQueryOrderExtraRanges,
  useQueryOrderExtras,
} from '~/hooks/useQueryData'

import { Alert, Badge, Button, ListGroup } from 'react-bootstrap'
import {
  CloseIcon,
  CommonDialogV2,
  ICommonDialogProps,
  OrderExtraForm,
  PlusIcon,
  ToggleSection,
} from '~/components/shared'

import { EYesNo } from '~/types/enums/ECommonEnum'

import type { IOrderExtra } from '~/types/models/IOrderExtra'

import './styles.scss'
import { toastMessages } from '~/constants/toast-status-text'
import { useConfirmationProvider } from '~/contexts'
import { apiClient } from '~/api/ApiClient'
import { OrderExtraCard } from '../OrderExtraCard'

export interface IOrderExtrasDialogProps extends ICommonDialogProps {
  orderId?: number
}

function OrderExtrasDialog(props: IOrderExtrasDialogProps) {
  const { isOpen, onClose, orderId } = props

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [formData, setFormData] = useState<IOrderExtra | undefined>(undefined)
  const [backendError, setBackendError] = useState('')

  const { confirmation } = useConfirmationProvider()

  const {
    orderExtras,
    addOrderExtra,
    updateOrderExtra,
    removeOrderExtra,
    isLoadingOrderExtras,
  } = useQueryOrderExtras(
    {
      filters: {
        orderId,
      },
    },
    { enabled: Boolean(orderId) },
  )

  const {
    orderExtraRanges,
    refetchOrderExtraRangesData,
    updateOrderExtraRange,
  } = useQueryOrderExtraRanges(
    {
      filters: {
        orderExtraId: orderExtras.map(({ id }) => id),
      },
    },
    { enabled: Boolean(orderExtras.length > 0) },
  )

  const scheduleExtrasData = orderExtras.filter(({ scheduleId }) => scheduleId)
  const orderExtrasData = orderExtras.filter(({ scheduleId }) => !scheduleId)

  const sectors = [
    {
      label: 'Order Extras',
      data: orderExtrasData,
    },
    {
      label: 'Schedule Extras',
      data: scheduleExtrasData,
    },
  ]

  const onCloseForm = () => {
    setIsFormOpen(false)
    setFormData(undefined)
  }

  const onDeleteOrderExtra = async (orderExtra: IOrderExtra) => {
    setBackendError('')
    try {
      const result = await confirmation({
        message: 'Are you sure you want to delete this order extra',
      })
      if (result === EYesNo.Yes) {
        apiClient.orderExtras.delete(orderExtra.id)
        removeOrderExtra(orderExtra.id)
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    }
  }

  const renderOrderExtras = (data: IOrderExtra[]) =>
    data.map(orderExtra => {
      const ranges = orderExtraRanges.filter(
        ({ orderExtraId }) => orderExtraId === orderExtra.id,
      )

      return (
        <ListGroup.Item
          key={orderExtra.id}
          style={{ padding: 0, border: 'none' }}
        >
          <OrderExtraCard
            orderExtra={orderExtra}
            selected={formData?.id === orderExtra.id}
            ranges={ranges}
            orderId={orderId}
            onClickEdit={item => {
              setFormData(prev => {
                if (prev?.id === item.id) {
                  setIsFormOpen(false)
                  return undefined
                }
                setIsFormOpen(true)
                return item
              })
            }}
            onClickDelete={onDeleteOrderExtra}
            afterUpdateOrderExtraRange={newItem => {
              updateOrderExtraRange(newItem.id, newItem)
            }}
          />
        </ListGroup.Item>
      )
    })

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      isHiddenOkButton
      title='Order Extras'
      isLoading={isLoadingOrderExtras}
    >
      <div>
        <div style={{ marginBottom: 8 }}>
          <Button
            variant={isFormOpen ? 'danger' : 'primary'}
            onClick={() => {
              setIsFormOpen(prev => !prev)
              setFormData(undefined)
            }}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {isFormOpen ? (
              <CloseIcon color='white' />
            ) : (
              <PlusIcon color='white' />
            )}
          </Button>

          <Badge
            style={{ verticalAlign: 'middle', fontSize: 12, marginLeft: 4 }}
            bg='info'
          >
            <span>Order Extra: </span>
            {orderExtrasData.length}
          </Badge>

          <Badge
            style={{ verticalAlign: 'middle', fontSize: 12, marginLeft: 4 }}
            bg='info'
          >
            <span>Schedule Extra: </span>
            {scheduleExtrasData.length}
          </Badge>
        </div>

        {isFormOpen && orderId && (
          <div
            style={{
              border: '1px solid #e5e5e5',
              marginBottom: 8,
              borderRadius: 4,
            }}
          >
            <OrderExtraForm
              orderId={orderId}
              formData={formData}
              isHiddenCancelButton={false}
              cancelText='Close'
              onCancel={onCloseForm}
              afterCreate={orderExtra => {
                addOrderExtra(orderExtra)
                refetchOrderExtraRangesData()
                onCloseForm()
              }}
              afterUpdate={orderExtra => {
                updateOrderExtra(orderExtra.id, orderExtra)
                refetchOrderExtraRangesData()
                onCloseForm()
              }}
            />
          </div>
        )}
      </div>

      {backendError && (
        <Alert variant='error' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}

      <div className='OrderExtrasDialog__list'>
        {sectors.map(({ data, label }) => (
          <ToggleSection
            key={label}
            label={label}
            isOpenByDefault={data.length > 0}
            badges={[
              {
                label: data.length,
                color: 'info',
              },
            ]}
          >
            {data.length ? (
              <ListGroup style={{ maxHeight: 400, overflow: 'auto' }}>
                {renderOrderExtras(data)}
              </ListGroup>
            ) : (
              <Alert variant='info' style={{ fontSize: 13 }}>
                No Result Found!
              </Alert>
            )}
          </ToggleSection>
        ))}
      </div>
    </CommonDialogV2>
  )
}

export default OrderExtrasDialog
