export const determineStyling = code => {
  if (code.parentId === null) {
    return {
      rowClassName: 'cost-code-row body-grid',
      textClassName: 'cost-code-row-text',
      gridClassName: 'lg-grid-row',
      iconClassName: 'cost-code-icon-container',
      childCountClass: 'cc-child-count',
    }
  }

  if (code.treeIndex == 1) {
    return {
      rowClassName: 'cost-code-row second-row body-grid',
      textClassName: 'cc-second-row-text',
      gridClassName: 'md-grid-row',
      iconClassName: 'cost-code-icon-container md-icon-row',
      childCountClass: 'cc-child-count cc-md-child-count',
    }
  }

  return {
    rowClassName: 'cost-code-row third-row body-grid',
    textClassName: 'cc-third-row-text',
    gridClassName: 'sm-grid-row',
    iconClassName: 'cost-code-icon-container sm-icon-row',
    inputClassName: 'sm-input-row',
    childCountClass: 'cc-child-count cc-sm-child-count',
    actionButtonClass: 'sm-action-btn',
  }
}

export const determineFormStyling = level => {
  if (level == 1) {
    return {
      rowClassName: 'cost-code-row second-row body-grid',
      textClassName: 'cc-second-row-text',
      gridClassName: 'md-grid-row',
      iconClassName: 'cost-code-icon-container md-icon-row',
      childCountClass: 'cc-child-count cc-md-child-count',
    }
  }

  return {
    rowClassName: 'cost-code-row third-row body-grid',
    textClassName: 'cc-third-row-text',
    gridClassName: 'sm-grid-row',
    iconClassName: 'cost-code-icon-container sm-icon-row',
    inputClassName: 'sm-input-row',
    childCountClass: 'cc-child-count cc-sm-child-count',
    actionButtonClass: 'sm-action-btn',
  }
}
