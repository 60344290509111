import { useMemo, useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useQueryWorkPayRules } from '~/hooks/useQueryData'

import { ConcordFormStructure } from '~/components/shared'
import { Alert } from 'react-bootstrap'

import * as Yup from 'yup'
import { EARNING_CODE_TYPE_OPTIONS, FORM_FIELD_TYPE } from '~/utils/constants'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

import './EarningCodeForm.scss'

const EarningCodeForm = props => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { workPayRuleOptions } = useQueryWorkPayRules()
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const formRef = useRef()

  const fields = useMemo(
    () => [
      {
        label: 'Work Pay Rule',
        name: 'workPayRuleId',
        isRequired: true,
        options: workPayRuleOptions,
        type: FORM_FIELD_TYPE.singleSelect,
        onChange(event, { value, name, selectedOption }) {
          formRef.current?.setValue(name, value)
          formRef.current?.setValue('name', selectedOption?.label)
        },
      },
      {
        label: 'Name',
        name: 'name',
      },
      {
        label: 'Type',
        name: 'type',
        isRequired: true,
        options: EARNING_CODE_TYPE_OPTIONS,
        type: FORM_FIELD_TYPE.singleSelect,
      },
    ],
    [workPayRuleOptions],
  )

  const defaultValues = useMemo(
    () => ({
      workPayRuleId: null,
      payType: '',
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        workPayRuleId: Yup.number()
          .required('Work pay type is required!')
          .typeError('Work pay type is required!'),
        type: Yup.string().required('Earning code is required!'),
      }),
    [],
  )

  const updateEarningCode = useCallback(async payload => {
    console.log('payload', payload)
  }, [])

  const createEarningCode = useCallback(
    async payload => {
      const { error, ...response } = await apiClient.check.createEarningCode({
        earningCode: payload,
      })
      if (error?.message) {
        setError(error.message)
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      setError('')
      try {
        const payload = {
          type: formData.type,
          company: currentCompany.checkUid,
          workPayRuleId: formData.workPayRuleId,
          name: formData.name,
        }
        if (isUpdating) {
          await updateEarningCode(payload)
        } else {
          await createEarningCode(payload)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      createEarningCode,
      currentCompany.checkUid,
      isUpdating,
      updateEarningCode
    ],
  )

  return (
    <>
      {error && (
        <Alert variant='danger' style={{ fontSize: 14 }}>
          {error}
        </Alert>
      )}
      <ConcordFormStructure
        {...formProps}
        ref={formRef}
        fields={fields}
        defaultValues={defaultValues}
        formData={{
          ...formData,
          type: formData?.payType
        }}
        schema={schema}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </>
  )
}

EarningCodeForm.propTypes = {
  afterCreate: PropTypes.func,
  afterUpdate: PropTypes.func,
}

export default EarningCodeForm
