import { useMemo } from 'react'

import { ConcordFormStructure, FileField } from '~/components/shared'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import { driverSchema } from './schemas'

function DriverTab(props) {
  const defaultValues = useMemo(
    () => ({
      licenseNumber: null,
      licenseState: '',
      licenseExpiration: null,
      medicalNumber: '',
      medicalExpiration: null,
      mvrNumber: null,
      license: null,
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'licenseNumber',
        label: 'License #',
        type: FORM_FIELD_TYPE.number,
      },
      {
        name: 'licenseState',
        label: 'License State',
      },
      {
        name: 'licenseExpiration',
        label: 'License Expiration',
        type: FORM_FIELD_TYPE.date,
      },
      {
        name: 'license',
        label: 'License',
        render({ label, setValue, watch, name }) {
          const license = watch(name, null)
          return (
            <FileField
              label={label}
              value={[license]}
              placeholder='Accept: image, pdf'
              onChange={async ([file]) => {
                setValue(name, file)
              }}
            />
          )
        },
      },
      {
        name: 'medicalNumber',
        label: 'Medical #',
      },
      {
        name: 'medicalExpiration',
        label: 'Medical Expiration',
        type: FORM_FIELD_TYPE.date,
      },
      {
        name: 'mvrNumber',
        label: 'MVR #',
        type: FORM_FIELD_TYPE.number,
      },
    ],
    [],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      schema={driverSchema}
      submitText='Next'
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}

DriverTab.propTypes = {}

export default DriverTab
