export enum EPhoneNumberTypes {
  general,
  ap,
  ar,
  sales,
  qc,
  operations,
  hr,
  mgmt,
  purchasing,
  mobile,
  work,
  home,
}

export enum EPhoneNumberTypesString {
  general = 'general',
  ap = 'ap',
  ar = 'ar',
  sales = 'sales',
  qc = 'qc',
  operations = 'operations',
  hr = 'hr',
  mgmt = 'mgmt',
  purchasing = 'purchasing',
  mobile = 'mobile',
  work = 'work',
  home = 'home',
}

export enum EPhoneableType {
  person = 'Person',
  company = 'Company',
  bankAccount = 'BankAccount',
  creditReference = 'CreditReference',
}
