import { useMount } from 'react-use'
import { useCallback, useMemo, useState } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

function TaxElectionsTable() {
  const [taxElectionsData, setTaxElectionsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Tax #',
        accessorKey: 'taxId',
        minSize: 250,
        maxSize: 250,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        minSize: 450,
        maxSize: 450,
      },
      {
        header: 'Payer',
        accessorKey: 'payer',
        minSize: 120,
        maxSize: 120,
      },
    ],
    [],
  )

  const fetchTaxElections = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await apiClient.check.taxElections()
      setTaxElectionsData(response.results)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useMount(() => {
    fetchTaxElections()
  })

  return (
    <ReusableTable
      state={{
        isLoading: isLoading,
      }}
      data={taxElectionsData}
      columns={columns}
      tableHeight={350}
    />
  )
}
export default TaxElectionsTable
