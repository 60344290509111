import { useCallback } from 'react'

import { ConcordFormLayout } from '~/components/shared'
import InputColor from 'react-input-color'
import clsx from 'clsx'

import './ConcordFormColorPicker.scss'

function ConcordFormColorPicker(props) {
  const { label, name, onChange, value, className, ...inputProps } = props

  const handleChange = useCallback(
    ({ hex }) => {
      onChange && onChange({}, { name, value: hex })
    },
    [name, onChange],
  )

  return (
    <ConcordFormLayout
      label={label}
      className={clsx(className, 'ConcordFormColorPicker_root')}
    >
      <div className='ConcordFormColorPicker_wrapInput'>
        <InputColor
          initialValue={value}
          onChange={handleChange}
          placement='right'
          {...inputProps}
        />
      </div>
    </ConcordFormLayout>
  )
}

ConcordFormColorPicker.defaultProps = {
  value: '#000000',
}

export default ConcordFormColorPicker
