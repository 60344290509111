export enum EAllowedHierarchySellerSideType {
  SellerTerminal = 'SellerTerminal',
  TerminalGroup = 'TerminalGroup',
  MinorMarket = 'MinorMarket',
  Market = 'Market',
  Region = 'Region',
  Seller = 'Seller',
}

export enum EAllowedHierarchyBuyerSideType {
  Zone = 'Zone',
  Area = 'Area',
  BuyerTerminal = 'BuyerTerminal',
  Buyer = 'Buyer',
}
