import { CommonDialogV2 } from '~/components/shared'
import PhoneNumberForm from './PhoneNumberForm'

import './DialogPhoneNumberForm.scss'

function DialogPhoneNumberForm(props) {
  const {
    afterCreate,
    afterUpdate,
    phoneNumberData,
    isPhoneableTypeDisabled,
    isPhoneableTypeReadOnly,
    formData,
    ...dialogProps
  } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Phone number'
      isHiddenOkButton
    >
      <PhoneNumberForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        phoneNumberData={phoneNumberData}
        isPhoneableTypeDisabled={isPhoneableTypeDisabled}
        isPhoneableTypeReadOnly={isPhoneableTypeReadOnly}
        formData={formData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}
export default DialogPhoneNumberForm
