import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { components } from 'react-select'

import './styles.scss'

function CustomScheduleStatusOption(props) {
  const { data } = props
  const dataOpt = ORDER_STATUSES[data.value]
  return (
    <div
      className={clsx(
        'CustomScheduleStatusOption__container',
        `bg-${dataOpt.color}`,
        {
          'text-light': dataOpt.color === 'dark',
        },
      )}
    >
      <span
        style={{
          verticalAlign: 'middle',
          marginRight: 4,
          fontSize: 18,
          display: 'inline-block',
          marginTop: 3,
        }}
      >
        <IonIcon icon={dataOpt?.icon} />
      </span>
      <components.Option {...props} />
    </div>
  )
}

export default CustomScheduleStatusOption
