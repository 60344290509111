import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure } from '~/components/shared'

import * as Yup from 'yup'
import { FORM_FIELD_TYPE, COMPANY_PAY_FREQUENCY_OPTIONS, SEMIMONTHLY, MONTHLY } from '~/utils/constants'
import { selectMyCurrentCompany } from '~/redux/selectors'
import moment from 'moment'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

import './PayScheduleForm.scss'

const PayScheduleForm = (props) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const isUpdating = useMemo(() => formData?.id, [formData?.id])

  const currentCompany = useSelector(selectMyCurrentCompany)

  const formRef = useRef()

  const [watchFirstPayday, setWatchFirstPayday] = useState(null)
  const [watchPayFrequency, setWatchPayFrequency] = useState(null)

  const { min, max } = useMemo(() => {
    if (!watchFirstPayday || !watchPayFrequency) {
      return {
        min: null,
        max: null
      }
    }

    const max = moment(watchFirstPayday).toDate()

    if (watchPayFrequency === MONTHLY.value) {
      const min = moment(watchFirstPayday).add({ months: -1 }).toDate()

      return {
        max,
        min
      }
    }

    const min = moment(watchFirstPayday).add({ days: -11 }).toDate()
    return {
      min,
      max
    }
  }, [watchFirstPayday, watchPayFrequency])

  const firstPaydayFieldProps = useMemo(() => {
    if (watchPayFrequency === SEMIMONTHLY.value) {
      const includeDates = []
      includeDates.push(moment().date(15).toDate())
      includeDates.push(moment().endOf('month').toDate())

      return { includeDates }
    }

    return {}
  }, [watchPayFrequency])

  const fields = useMemo(() => [
    {
      name: 'name',
      label: 'Name',
      isRequired: true
    },
    {
      name: 'payFrequency',
      label: 'Pay Frequency',
      isRequired: true,
      type: FORM_FIELD_TYPE.singleSelect,
      options: COMPANY_PAY_FREQUENCY_OPTIONS,
      className: 'PayScheduleForm__dropdown'
    },
    {
      name: 'firstPayday',
      label: 'First Payday',
      size: 6,
      isRequired: true,
      type: FORM_FIELD_TYPE.date,
      ...firstPaydayFieldProps
    },
    {
      name: 'firstPeriodEnd',
      label: 'First Period End',
      size: 6,
      isRequired: true,
      type: FORM_FIELD_TYPE.date,
      maxDate: max,
      minDate: min
    }
  ], [firstPaydayFieldProps, max, min])

  const schema = useMemo(() =>
    Yup.object({
      name: Yup.string().required('Name is required!'),
      payFrequency: Yup.string().nullable().required('Pay Frequency is required!'),
      firstPayday: Yup.string().required('First Payday is required!'),
      firstPeriodEnd: Yup.string().required('First Period End is required!'),
    })
    , [])

  const defaultValues = useMemo(() => ({
    name: '',
    payFrequency: '',
    firstPayday: '',
    firstPeriodEnd: ''
  }), [])

  const createPaySchedule = useCallback(
    async (formValues) => {
      const { error, ...response } = await apiClient.check.createPaySchedule({
        paySchedule: {
          ...formValues,
          company: currentCompany.checkUid
        }
      })
      if (Array.isArray(error?.inputErrors)) {
        const [errorMessage] = error.inputErrors
        toast.error(errorMessage.message)
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate, currentCompany.checkUid],
  )

  const updatePaySchedule = useCallback(
    async ({ id, ...formValues }) => {
      const { body } = await apiClient.check.updatePaySchedules(id, {
        paySchedule: {
          ...formValues,
          company: currentCompany.checkUid
        }
      })
      const { error, ...response } = JSON.parse(body)
      if (Array.isArray(error?.input_errors)) {
        const [errorMessage] = error.input_errors
        toast.error(errorMessage.message)
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterUpdate, currentCompany.checkUid],
  )

  const handleSubmit = useCallback(
    async (formValues) => {
      setIsLoading(true)
      try {
        const payload = {
          ...formValues
        }

        if (payload.firstPayday) {
          payload.firstPayday = moment(payload.firstPayday).format('YYYY-MM-DD')
        }

        if (payload.firstPeriodEnd) {
          payload.firstPeriodEnd = moment(payload.firstPeriodEnd).format('YYYY-MM-DD')
        }

        if (isUpdating) {
          await updatePaySchedule(payload)
        } else {
          await createPaySchedule(payload)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createPaySchedule, isUpdating, updatePaySchedule],
  )

  useEffect(() => {
    if (formData) {
      setWatchFirstPayday(formData.firstPayday)
      setWatchPayFrequency(formData.payFrequency)
    }
  }, [formData])

  useEffect(() => {
    const subscription = formRef.current?.watch(
      (value) => {
        setWatchFirstPayday(value.firstPayday)
        setWatchPayFrequency(value.payFrequency)
      },
    )
    return () => subscription.unsubscribe()
  }, [])

  return (
    <ConcordFormStructure
      {...formProps}
      ref={formRef}
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default PayScheduleForm
