import React, { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { IonButton } from '@ionic/react'
import { Card } from 'react-bootstrap'
import { ToolTipOverlay, ConcordFormAddressInput } from '~/components/shared'

import _ from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import loadable from '@loadable/component'
import { apiClient } from '~/api/ApiClient'

const ConcordFormTextField = loadable(() =>
  import('~/components/shared/ConcordForm/TextField'),
)

function TerminalDropdownForm(props) {
  const {
    isLoading: isLoadingProps,
    onClose,
    onOpen,
    companyId,
    initialFormValues,
    isOpenForm: isOpenFormProps,
    onCreate,
    onUpdate,
  } = props

  const schema = Yup.object({
    name: Yup.string()
      .max(255, 'Maximum character is 255!')
      .required('This field is required!'),
    code: Yup.string()
      .max(255, 'Maximum character is 255!')
      .required('This field is required!'),
  })

  const { setValue, watch, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: '',
      code: '',
      id: null,
      locationAttributes: {
        fullAddress: {
          label: '',
          value: '',
        },
      },
    },
    resolver: yupResolver(schema),
  })

  const watchName = watch('name', '')
  const watchCode = watch('code', '')
  const watchId = watch('id', null)
  const watchFullAddress = watch('locationAttributes.fullAddress')

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenForm = useCallback(() => {
    setIsOpenForm(true)
    onOpen && onOpen()
  }, [onOpen])

  const handleCloseForm = useCallback(() => {
    setIsOpenForm(false)
    onClose && onClose()
    reset()
  }, [onClose, reset])

  const createTerminal = useCallback(
    async ({ locationAttributes, ...formData }) => {
      setIsLoading(true)

      try {
        const payload = {
          ...formData,
          companyId,
          locationAttributes: {
            fullAddress: locationAttributes.fullAddress.label,
          },
        }
        const response = await apiClient.terminals.create({
          terminal: payload,
        })
        if (response.id) {
          onCreate && onCreate(response)
          toast.success(toastMessages.createSuccess)
          handleCloseForm()
        } else {
          toast.error(toastMessages.createError)
        }
      } catch (error) {
        toast.error(toastMessages.createError)
      } finally {
        setIsLoading(false)
      }
    },
    [companyId, handleCloseForm, onCreate],
  )

  const updateTerminal = useCallback(
    async ({ locationAttributes, ...formData }) => {
      setIsLoading(true)
      try {
        const payload = _.pick(formData, ['code', 'name'])
        const response = await apiClient.terminals.update(formData.id, {
          ...payload,
          locationAttributes: {
            fullAddress: locationAttributes.fullAddress.label,
          },
        })
        if (_.size(response.errors) === 0) {
          onUpdate && onUpdate(response)
          toast.success(toastMessages.updateSuccess)
          handleCloseForm()
        } else {
          toast.error(toastMessages.updateError)
        }
      } catch (error) {
        toast.error(toastMessages.updateError)
      } finally {
        setIsLoading(false)
      }
    },
    [handleCloseForm, onUpdate],
  )

  const handleSubmitForm = handleSubmit(formData => {
    if (formData.id) {
      updateTerminal(formData)
    } else {
      createTerminal(formData)
    }
  })

  const handleChangeValues = useCallback(
    event => {
      const { value, name } = event.target
      setValue(name, value)
    },
    [setValue],
  )

  useEffect(() => {
    if (_.isBoolean(isOpenFormProps)) {
      setIsOpenForm(isOpenFormProps)
    }
  }, [isOpenFormProps])

  useEffect(() => {
    reset(initialFormValues)
  }, [initialFormValues, reset])

  useEffect(() => {
    setIsLoading(isLoadingProps)
  }, [isLoadingProps])

  if (!isOpenForm) {
    return (
      <ToolTipOverlay
        content='Set Seller / Buyer before creating terminal'
        allowToShow={!companyId}
        placement='bottom'
      >
        <div>
          <IonButton
            style={{ width: '100%' }}
            disabled={isLoading || !companyId}
            onClick={handleOpenForm}
          >
            Add new terminal
          </IonButton>
        </div>
      </ToolTipOverlay>
    )
  }

  return (
    <form>
      <Card style={{ marginTop: 8, height: 250 }}>
        <Card.Body>
          <ConcordFormTextField
            label='Terminal Code'
            name='code'
            error={formState?.errors?.code?.message}
            value={watchCode}
            onChange={handleChangeValues}
          />
          <ConcordFormTextField
            label='Terminal Name'
            name='name'
            error={formState?.errors?.name?.message}
            value={watchName}
            onChange={handleChangeValues}
          />
          <ConcordFormAddressInput
            label='Address'
            name='locationAttributes.fullAddress'
            value={watchFullAddress}
            onChange={selectedOption => {
              setValue('locationAttributes.fullAddress', selectedOption)
            }}
          />
        </Card.Body>
        <Card.Footer>
          <IonButton
            color='primary'
            onClick={handleSubmitForm}
            disabled={isLoading}
          >
            {_.isNil(watchId) ? 'Create' : 'Update'}
          </IonButton>
          <IonButton color='danger' onClick={handleCloseForm}>
            Close
          </IonButton>
        </Card.Footer>
      </Card>
    </form>
  )
}

TerminalDropdownForm.propTypes = {}

export default React.memo(TerminalDropdownForm)
