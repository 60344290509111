export enum EStartTimeColor {
  red = 'red',
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
  purple = 'purple',
  orange = 'orange',
  black = 'black',
  dark = 'dark',
  gray = 'gray',
  white = 'white',
}
