export enum EOffdayRequestType {
  vacation = 'vacation',
  injury = 'injury',
  sick = 'sick',
  other = 'other',
}

export enum EOffDayStatus {
  approved = 'approved',
  requested = 'requested',
  denied = 'denied',
}

export enum EOffdayView {
  byMonth = 'byMonth',
  byDay = 'byDay',
}
