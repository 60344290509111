import useSchedulesTable from './useSchedulesTable'

import {
  DialogCompanyForm,
  DialogTerminalForm,
  GraphIcon,
  ReusableTable,
  RTActionNoteButton,
  ScheduleLoadsTable,
} from '~/components/shared'
import { GraphChartOrderTrackingDetailDialog } from '~/containers/OrderTracking/OrderTrackingDetailColumn/GraphChartOrderTrackingDetailDialog'
import { OrderFormDialog } from './OrderFormDialog'

import type { ISchedulesTableProps } from './type'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import { ENoteableType } from '~/types/enums/ENote'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { DRIVER_TYPES } from '~/containers/OrderTracking/Utils/driverTypes'
import clsx from 'clsx'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { OrderExtrasDialog } from './OrderExtrasDialog'
import DialogTerminalDetails from '~/containers/TerminalContainer/DialogTerminalDetails'
import { ITerminal } from '~/types/models/ITerminal'

import './styles.scss'
import { EOrderStatus } from '~/types/enums/EOrder'

export interface IDndDriverCard {
  driverFleet: IDriverFleet
  truckFleet: ITruckFleet
}

function SchedulesTable(props: ISchedulesTableProps) {
  const {
    columns,
    lineGraphState,
    isOpenOrderForm,
    selectedSchedule,
    defaultTab,
    notesData,
    filterOptions,
    tableHeight,
    state,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    schedules,
    orderExtrasDialog,
    onColumnFiltersChange,
    afterUpdateOrder,
    getCellClassName,
    onCellEditEnd,
    onCloseScheduleLineGraph,
    onCloseOrderForm,
    onOpenScheduleLineGraph,
    afterCreateNote,
    afterUpdateNote,
    afterDeleteNote,
    renderDetailPanel,
    onSortingChange,
    onCloseOrderExtrasDialog,
    termialDetailsModal,
    setTermialDetailsModal,
    terminalForm,
    setTerminalForm,
    updateTerminal,
    addTerminal,
    companyForm,
    setCompanyForm,
    updateCompany,
    afterUpdateScheduleLoad,
  } = useSchedulesTable(props)

  return (
    <>
      <ReusableTable
        columns={columns}
        data={schedules}
        tableHeight={tableHeight}
        enableRowActions
        enableColumnPinning
        enableOuterDropping
        enableTopToolbar={enableTopToolbar}
        manualFiltering={manualFiltering}
        toolbarProps={toolbarProps}
        getCellClassName={getCellClassName}
        getRowClassName={({ row }) =>
          clsx(
            'SchedulesTable__row',
            (ORDER_STATUSES as any)[row.original.status]?.color,
          )
        }
        filterOptions={filterOptions}
        displayColumnDefOptions={{
          [ERTDisplayColumnId.actions]: {
            size: 80,
          },
        }}
        state={{
          ...state,
          isLoading: state?.isLoading,
        }}
        initialState={{
          columnPinning: {
            left: [
              ERTDisplayColumnId.actions,
              ERTDisplayColumnId.expand,
              'order.num',
            ],
          },
          outerDroppingTypesAccepted: [DRIVER_TYPES.WORKING],
          sorting: [
            {
              id: 'nextTicketTime',
              desc: true,
            },
          ],
        }}
        renderRowActions={({ row }) => {
          const rowData = row.original
          const notes = notesData.filter(
            ({ noteableId }) => noteableId === rowData.id,
          )

          return [
            {
              icon: <GraphIcon color='white' />,
              onClick: onOpenScheduleLineGraph(rowData),
            },

            {
              render: () => (
                <RTActionNoteButton
                  notesData={notes}
                  noteableId={rowData.id}
                  noteableType={ENoteableType.Schedule}
                  afterUpdateNote={afterUpdateNote}
                  afterCreateNote={afterCreateNote}
                  afterDeleteNote={afterDeleteNote}
                />
              ),
            },
          ]
        }}
        renderDetailPanel={params => {
          if (typeof renderDetailPanel === 'function') {
            return renderDetailPanel(params)
          }

          return (
            <ScheduleLoadsTable
              apiParams={{
                filters: {
                  scheduleId: [params.row.original.id],
                },
              }}
              afterUpdate={afterUpdateScheduleLoad}
            />
          )
        }}
        onCellEditEnd={onCellEditEnd}
        onColumnFiltersChange={onColumnFiltersChange}
        onSortingChange={onSortingChange}
        getOuterDroppingBorderStyle={({ row }) => {
          const rowData = row.original
          const status = (ORDER_STATUSES as any)[rowData.status]
          const borderColor = status?.color || 'success'
          const borderWith = rowData.status === EOrderStatus.Active ? 3 : 2

          return `${borderWith}px dashed var(--bs-${borderColor})`
        }}
      />
      <GraphChartOrderTrackingDetailDialog
        {...lineGraphState}
        onClose={onCloseScheduleLineGraph}
      />
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        orderData={selectedSchedule?.order}
        defaultTab={defaultTab}
        afterUpdate={afterUpdateOrder}
      />
      <OrderExtrasDialog
        isOpen={orderExtrasDialog.isOpen}
        onClose={onCloseOrderExtrasDialog}
        orderId={orderExtrasDialog.orderId}
      />

      <DialogTerminalDetails
        id={termialDetailsModal.id as number}
        isOpen={termialDetailsModal.isOpen}
        onClose={() => {
          setTermialDetailsModal({
            isOpen: false,
            id: null,
          })
        }}
      />

      <DialogTerminalForm
        formData={terminalForm.formData}
        isOpen={terminalForm.isOpen}
        onClose={() => {
          setTerminalForm({
            isOpen: false,
            formData: undefined,
          })
        }}
        afterCreate={(item: ITerminal) => {
          addTerminal(item)
          setTerminalForm({
            isOpen: false,
            formData: undefined,
          })
        }}
        afterUpdate={(item: ITerminal) => {
          updateTerminal(item.id, item)
          setTerminalForm({
            isOpen: false,
            formData: undefined,
          })
        }}
      />

      <DialogCompanyForm
        isOpen={companyForm.isOpen}
        formData={companyForm.formData as any}
        onClose={() => {
          setCompanyForm({
            isOpen: false,
            formData: undefined,
          })
        }}
        afterUpdate={item => {
          updateCompany(item.id, item)
          setCompanyForm({
            isOpen: false,
            formData: undefined,
          })
        }}
      />
    </>
  )
}
export default SchedulesTable
