const DEFAULT_COMPANY_TERMINAL_COLUMNS = [
  {
    displayIndex: 0,
    sortIndex: 0,
    label: 'Company',
    field: 'company.name',
    show: true,
    value: 'company.name',
    sortField: 'company.name',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    sortIndex: 1,
    label: 'Name',
    field: 'name',
    show: true,
    value: 'name',
    sortField: 'name',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    sortIndex: 2,
    label: 'Code',
    field: 'code',
    show: true,
    value: 'code',
    sortField: 'code',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    sortIndex: 3,
    label: 'Time zone',
    field: 'timeZone',
    show: true,
    value: 'timeZone',
    sortField: 'timeZone',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    sortIndex: 4,
    label: 'Phone #',
    field: 'callPhoneNumber',
    show: true,
    value: 'callPhoneNumber',
    sortField: 'callPhoneNumber',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    sortIndex: 5,
    label: 'Status',
    field: 'status',
    show: true,
    value: 'status',
    sortField: 'status',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    sortField: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_TERMINAL_COLUMNS
