import { IonButtons } from '@ionic/react'
import {
  Button,
  ConcordFormAddressInput,
  TerminalMap,
} from '~/components/shared'
import useTerminalContainer from './useTerminalContainer'

import './TerminalContainer.scss'

function TerminalDetails(props) {
  const {
    terminalData,
    location,
    geofence,
    coordinates,
    showUpdateButton,
    fullAddress,
    isLoading,
    onUpdateTerminal,
    onChangeAddress,
    onChangeLocation,
    onRevert,
    onDrawGeofence,
  } = useTerminalContainer(props)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        className='TerminalContainer__header'
      >
        <ConcordFormAddressInput
          containerClassName='addressInput'
          placeholder='Search address'
          keyword={fullAddress}
          onChange={onChangeAddress}
          alwaysShowInput
        />

        <IonButtons>
          {showUpdateButton && (
            <Button fill='solid' onClick={onUpdateTerminal} loading={isLoading}>
              Update
            </Button>
          )}
        </IonButtons>
      </div>

      <div className='TerminalContainer__mapContainer'>
        <TerminalMap
          location={location}
          coordinates={coordinates}
          geofence={geofence}
          defaultLocation={terminalData?.location}
          defaultCoordinates={terminalData?.coordinates}
          defaultGeofence={terminalData?.geofence}
          onChangeLocation={onChangeLocation}
          onRevert={onRevert}
          onDrawGeofence={onDrawGeofence}
        />
      </div>
    </div>
  )
}

export default TerminalDetails
