import { DateTime } from 'luxon'

// export const REGEX_CHECK_ISO_DATE = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?))?$/g
export const DATE_FORMAT = 'MM-dd-yyyy'
export const DATE_TIME_FORMAT = 'MM-dd-yyyy HH:mm:ss'
export const DATE_FORMAT_TOOLTIP = 'EEE, LLL dd, yyyy'
export const DATE_TIME_FORMAT_TOOLTIP = 'EEE, LLL dd, yyyy HH:mm:ss'
export const DATE_TIME_FORMAT_WITHOUT_SECOND = 'EEE, LLL dd, yyyy HH:mm'
export const DATE_TIME_ONLY_HOUR_MINUTE = 'HH:mm'
export const DATE_ONLY_DAY_MONTH = 'LLL dd'

export const DATE_ISO_TYPE = 'date-iso'
export const DATE_JS_TYPE = 'date-js'
export const DATE_STRING_TYPE = 'date-string'

// /**
//  * @param {string | Date} date
//  * @return {DATE_ISO_TYPE | DATE_STRING_TYPE | DATE_JS_TYPE}
//  */
// export const getDateType = (date) => {
//   if (REGEX_CHECK_ISO_DATE.test(date)) {
//     console.log('check date type = regex', date)

//     return DATE_ISO_TYPE
//   }
//   if (date instanceof Date) {
//     return DATE_JS_TYPE
//   }

//   console.log('check date type = string', date)

//   return DATE_STRING_TYPE
// }

/**
 * @param {DateTime} date
 * @return {Date}
 */
export const createJsDateFromLuxonDate = date => {
  const newDate = {
    day: date.toFormat('dd'),
    month: date.toFormat('MM'),
    year: date.toFormat('yyyy'),
    hour: date.toFormat('HH'),
    minute: date.toFormat('mm'),
    second: date.toFormat('ss'),
  }
  const result = DateTime.fromObject(newDate).toJSDate()

  return result
}

/**
 * @param {string} date Ex:
 * @param {string} timeZone
 * @returns {Date}
 */
export const addTimeZoneToDateISO = (date, timeZone, opts) => {
  if (!date) {
    return null
  }

  const newDate = DateTime.fromISO(date).setZone(timeZone, opts)
  const result = createJsDateFromLuxonDate(newDate)

  return result
}

/**
 * @param {string} date Ex:
 * @param {string} timeZone
 * @param {string} format: Ex: dd-MM-yyyy
 * @returns {Date}
 */
export const addTimeZoneToDateFormat = (date, timeZone, format, opts) => {
  const newDate = DateTime.fromFormat(date, format).setZone(timeZone, opts)
  const result = createJsDateFromLuxonDate(newDate)

  return result
}

/**
 * @param {Date} date Ex:
 * @param {string} timeZone
 * @returns {Date}
 */
export const addTimeZoneToDateJS = (date, timeZone, opts) => {
  const newDate = DateTime.fromJSDate(date).setZone(timeZone, opts)
  const result = createJsDateFromLuxonDate(newDate)

  return result
}

export const convertDateTimeToZonedTime = (date, timeZone, opts) => {
  const newDate = DateTime.fromJSDate(date)
    .setZone(timeZone, { keepLocalTime: true, ...opts })
    .toISO()

  return newDate
}

export const formatDateFromISO = (date, format, timeZone = '', opts = {}) => {
  if (!date) {
    return null
  }

  const luxonDate = DateTime.fromISO(date)

  if (timeZone) {
    luxonDate.setZone(timeZone, opts)
  }

  return luxonDate.toFormat(format)
}

// export const addTimeZoneToDate = (date, timeZone, format = DATE_FORMAT, opts = {}) => {
//   const dateType = getDateType(date)

//   switch(dateType) {
//     case DATE_JS_TYPE: {
//       return addTimeZoneToDateJS(date, timeZone, opts)
//     }
//     case DATE_ISO_TYPE: {
//       return addTimeZoneToDateISO(date, timeZone, opts)
//     }
//     default: {
//       return addTimeZoneToDateFormat(date, timeZone, format, opts)
//     }
//   }
// }

// export const formatDate = (date, format = DATE_FORMAT, timeZone = '') => {
//   if (!date) {
//     return ''
//   }

//   const dateType = getDateType(date)
//   let luxonDate

//   switch(dateType) {
//     case DATE_JS_TYPE: {
//       luxonDate = DateTime.fromJSDate(date)
//       break
//     }
//     case DATE_ISO_TYPE: {
//       luxonDate = DateTime.fromISO(date)
//       break
//     }
//     default: {
//       luxonDate = DateTime.fromJSDate(null)
//     }
//   }

//   if (timeZone) {
//     luxonDate.setZone(timeZone)
//   }

//   return luxonDate.toFormat(format)
// }
