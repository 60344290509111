import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const driversSlice = createSlice({
  name: 'drivers',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(driver => {
        newState[driver.id] = driver
      })
      return newState
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
    add: (state, { payload }) => {
      state[payload.id] = payload
    },
  },
})

export const selectDrivers = state => state.data.drivers

export const fetchDrivers = createAsyncThunk(
  'drivers/fetch',
  async (params = {}, { dispatch }) => {
    try {
      const { drivers } = await apiClient.drivers.get(params)
      dispatch(driversSlice.actions.index(drivers))
      return drivers
    } catch (error) {
      console.log(error)
    }
  },
)

export const updateDriver = createAsyncThunk(
  'drivers/update',
  async (newDriver, { dispatch }) => {
    try {
      const { driver } = await apiClient.drivers.update(newDriver.id, newDriver)
      dispatch(driversSlice.actions.update(driver))
      return driver
    } catch (error) {
      console.log(error)
    }
  },
)
