import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { ERegionName } from '~/types/enums/ERegion'

import type { IUser } from '~/types/models/IUser'
import type { IRegion } from '~/types/models/IRegion'

const useQueryRegions = (
  params = {},
  options?: Partial<UseQueryOptions<IRegion[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'regions',
      sessionUser?.id,
      buildGetUrl(`/${ERegionName.regions}`, params),
    ],
    async queryFn() {
      const response = await apiClient.regions.get(ERegionName.regions)
      return response
    },
    // enabled: Boolean(sessionUser?.id),
    enabled: false,
    staleTime: 600000, // 10 minutes
    retry: false,
    ...options,
  })

  // const { addHierarchy, updateHierarchy, removeHierarchy } =
  //   useModifyHierarchy(params)

  const regionsData = useMemo(() => data || [], [data])

  const regionOptions = useMemo(
    () =>
      regionsData.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [regionsData],
  )

  return {
    regionsData,
    isLoadingRegions: isLoading,
    regionOptions,
    // addHierarchy,
    // updateHierarchy,
    // removeHierarchy,
  }
}

export default useQueryRegions
