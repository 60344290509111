import './styles.scss'
import { ILoadProgress, ILoadProgressProps } from '../type'
import useLoadProgress from './useLoadProgress'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const LoadProgress: React.FC<ILoadProgressProps> = props => {
	const { data, renderContentTooltip } = useLoadProgress(props)

  return (
    <div className='LoadProgressView'>
			{data.map((item: ILoadProgress, index: number) => (
				<OverlayTrigger
					placement='bottom'
					key={item.color}
					overlay={<Tooltip>{renderContentTooltip(index)}</Tooltip>}>
					<div style={{
						width: `${((1 / data.length) * 100) - 3}%`,
						height: 8,
						borderRadius: 8,
						backgroundColor: '#e5e5e5'
					}}>
						<div style={{
							backgroundColor: item.color,
							height: 8,
							borderRadius: 8,
							width: `${item.percent}%`
						}} />
					</div>
				</OverlayTrigger>
			))}
		</div>
	)
}

export default LoadProgress
