import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { sessionService } from 'redux-react-session'
import { toast } from 'react-toastify'

import { updateUserAccessInfo } from '~/redux/actions/sessionActions'
import { selectLoadsHash, selectSessionUser } from '~/redux/selectors'
import { initializeLoads } from '~/redux/actions/loadsActions'
import usePusher from './usePusher'

const usePusherDriver = () => {
  const user = useSelector(selectSessionUser)
  const loads = useSelector(selectLoadsHash)
  const dispatch = useDispatch()

  const channelName = useMemo(
    () => (user?.driverFleet?.id ? `driver_${user?.driverFleet?.id}` : null),
    [user?.driverFleet?.id],
  )
  const events = useMemo(
    () => [
      {
        name: 'truck_change',
        handler: handleTruckEvent,
      },
      {
        name: 'load',
        handler: handleLoadEvent,
      },
    ],
    [handleTruckEvent, handleLoadEvent],
  )

  usePusher({ channelName, events })

  const handleTruckEvent = useCallback(() => {
    updateUserAccessInfo()
    toast.success('New truck info')
  }, [])

  const handleLoadEvent = useCallback(
    data => {
      if (!_.isEqual(loads[data.load.id], data.load)) {
        dispatch(initializeLoads({ loads: [data.load] }))
        updateDriverFleetLoads(data.load.id)
      }
    },
    [loads, dispatch, updateDriverFleetLoads],
  )

  const updateDriverFleetLoads = useCallback(
    loadId => {
      if (user.driverFleet.loadId !== loadId) {
        sessionService.saveUser({
          ...user,
          driverFleet: {
            ...user.driverFleet,
            loadId: loadId,
          },
        })
      }
    },
    [user],
  )

  return {}
}

export default usePusherDriver
