import applicationUiSlice from '~/redux/reducers/ui/application'

export const updateAppWarningBrowserPopup = payload => dispatch => {
  dispatch(
    applicationUiSlice.actions.updateAppUi({
      field: 'warningBrowserPopup',
      value: {
        isOpen: payload,
      },
    }),
  )
}
