import { useCallback, useMemo, useState } from 'react'
import './styles.scss'
import { IBundleItem } from '~/types/models/IBundleItem'
import { useQueryBundleItems } from '~/hooks/useQueryData'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { BundleItemCard } from '../BundleItemCard'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { produce } from 'immer'
import { ToolTipOverlay } from '~/components/shared'
import { Badge } from 'react-bootstrap'
import { IonIcon, IonSpinner } from '@ionic/react'
import { addOutline, arrowUndo, checkmark } from 'ionicons/icons'
import { When } from 'react-if'
import clsx from 'clsx'
import { useDeepCompareEffect } from 'react-use'

export interface IBundleItemsListProps {
  bundleId: number
  sellerProductId: number
}

function BundleItemsList(props: IBundleItemsListProps) {
  const { bundleId, sellerProductId } = props

  const [bundleItemsCloned, setBundleItemsCloned] = useState<IBundleItem[]>([])
  const [wasChangedRanking, setWasChangedRanking] = useState(false)
  const [isChangingRank, setIsChangingRank] = useState(false)
  const [isCreatingItem, setIsCreatingItem] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const {
    bundleItemsData,
    removeBundleItem,
    refetchBundleItems,
    addBundleItem,
    updateBundleItem,
  } = useQueryBundleItems(
    {
      filters: {
        bundleId: [bundleId],
      },
    },
    { enabled: Boolean(bundleId) },
  )

  const onRevertRanking = useCallback(() => {
    setBundleItemsCloned(bundleItemsData)
    setWasChangedRanking(false)
  }, [bundleItemsData])

  const onSaveRanking = useCallback(async () => {
    setIsChangingRank(true)
    try {
      const ids = bundleItemsCloned.map(({ id }) => id)
      await apiClient.rerank.rerank({
        modelName: 'BundleItem',
        rankings: ids,
        field: 'rank',
        update: false,
      })
      setWasChangedRanking(false)
      refetchBundleItems()
    } catch (error) {
      toast.error(toastMessages.serverError)
    } finally {
      setIsChangingRank(false)
    }
  }, [bundleItemsCloned, refetchBundleItems])

  const onClickRemoveBundleItem = useCallback(
    async (bundleItem: IBundleItem | undefined) => {
      if (bundleItem) {
        const result = await confirmation({
          message: 'Are you sure you want to remove this item?',
        })
        if (result === EYesNo.Yes) {
          apiClient.bundleItems.delete(bundleItem.id)
          removeBundleItem(bundleItem.id)
        }
      } else {
        setIsCreatingItem(false)
      }
    },
    [confirmation, removeBundleItem],
  )

  const findHighestRank = (objects: IBundleItem[]) => {
    if (objects.length === 0) {
      return 0
    }

    let highestRank: number | undefined = undefined

    for (const obj of objects) {
      if (obj.rank !== undefined) {
        if (highestRank === undefined || obj.rank > highestRank) {
          highestRank = obj.rank
        }
      }
    }

    if (highestRank === undefined) {
      return 0
    }

    return highestRank
  }

  const highestBundleItemRank = findHighestRank(bundleItemsData) + 1

  const renderBundleItems = useMemo(
    () =>
      bundleItemsCloned.map((item, index) => (
        <BundleItemCard
          key={item.id}
          bundleItem={item}
          index={index}
          sellerProductId={sellerProductId}
          onRemove={onClickRemoveBundleItem}
          canDrag={bundleItemsCloned.length > 1}
          onDrag={(dragIndex: number, hoverIndex: number) => {
            const newBundleItems = produce(bundleItemsCloned, draft => {
              const [movedCard] = draft.splice(dragIndex, 1)
              draft.splice(hoverIndex, 0, movedCard)
            })
            setBundleItemsCloned(newBundleItems)
            setWasChangedRanking(true)
          }}
          afterUpdate={item => {
            updateBundleItem(item.id, item)
          }}
        />
      )),
    [
      bundleItemsCloned,
      onClickRemoveBundleItem,
      sellerProductId,
      updateBundleItem,
    ],
  )

  useDeepCompareEffect(() => {
    setBundleItemsCloned(bundleItemsData)
  }, [bundleItemsData])

  return (
    <div className='BundleItemsList__container'>
      <div className='bundleItemLabel'>
        <span style={{ marginRight: 6 }}>Bundle Items:</span>
        <ToolTipOverlay content='Create' placement='top'>
          <Badge
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsCreatingItem(prev => !prev)
            }}
          >
            <IonIcon icon={addOutline} style={{ fontSize: 12 }} />
          </Badge>
        </ToolTipOverlay>

        <When condition={wasChangedRanking}>
          <ToolTipOverlay content='Save changes' placement='top'>
            <Badge
              bg='success'
              style={{ marginLeft: 4, cursor: 'pointer' }}
              onClick={onSaveRanking}
              className={clsx({ isDisabled: isChangingRank })}
            >
              {isChangingRank ? (
                <IonSpinner name='dots' style={{ width: 10, height: 10 }} />
              ) : (
                <IonIcon icon={checkmark} style={{ fontSize: 12 }} />
              )}
            </Badge>
          </ToolTipOverlay>
          <ToolTipOverlay content='Revert changes' placement='top'>
            <Badge
              bg='warning'
              style={{ marginLeft: 4, cursor: 'pointer' }}
              onClick={onRevertRanking}
            >
              <IonIcon icon={arrowUndo} style={{ fontSize: 12 }} />
            </Badge>
          </ToolTipOverlay>
        </When>
      </div>

      <div>
        {renderBundleItems}

        {isCreatingItem && (
          <BundleItemCard
            index={-1}
            defaultRank={highestBundleItemRank}
            sellerProductId={sellerProductId}
            bundleId={bundleId}
            onRemove={onClickRemoveBundleItem}
            canDrag={bundleItemsCloned.length > 1}
            onDrag={(dragIndex: number, hoverIndex: number) => {
              const newBundleItems = produce(bundleItemsCloned, draft => {
                const [movedCard] = draft.splice(dragIndex, 1)
                draft.splice(hoverIndex, 0, movedCard)
              })
              setBundleItemsCloned(newBundleItems)
              setWasChangedRanking(true)
            }}
            afterUpdate={item => {
              updateBundleItem(item.id, item)
            }}
            afterCreate={item => {
              setIsCreatingItem(false)
              addBundleItem(item)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default BundleItemsList
