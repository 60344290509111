import { useMemo } from 'react'

import { View, Text } from '@react-pdf/renderer'
import PDFCompanyInfo from './PDFCompanyInfo'

function PDFCompanyInfoWithTerminal(props) {
  const { company, terminal, title, rawTerminal, type } = props

  const hasTerminal = useMemo(() => {
    return terminal || rawTerminal
  }, [rawTerminal, terminal])
  return (
    <View>
      <PDFCompanyInfo title={title} type={type} {...company} />
      {hasTerminal && (
        <View
          style={{
            padding: 2,
            backgroundColor: 'white',
            height: 20,
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text>
            Terminal: {terminal?.code || terminal?.name || rawTerminal}
          </Text>
        </View>
      )}
    </View>
  )
}

PDFCompanyInfoWithTerminal.propTypes = {}

export default PDFCompanyInfoWithTerminal
