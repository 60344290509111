import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyDrivers from './useModifyDrivers'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IDriver } from '~/types/models/IDriver'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryDrivers = (
  params: {
    id?: number
  } = {},
  options?: Partial<UseQueryOptions<IDriver[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'drivers',
      sessionUser?.id,
      buildGetUrl(apiClient.drivers.endpoint, params),
    ],
    async queryFn() {
      if (params.id) {
        const response = await apiClient.drivers.getById(params.id)
        return [response.driver]
      }
      const response = await apiClient.drivers.get()
      return response.drivers
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const driversData = useMemo(() => data || [], [data])

  const { updateDriver } = useModifyDrivers(params)

  return {
    driversData,
    updateDriver,
    isDriversLoading: isLoading,
  }
}

export default useQueryDrivers
