import { IReduxColumn } from '~/types/models/ICommonModel'

const DEFAULT_ZONES_COLUMNS: IReduxColumn[] = [
  {
    displayIndex: 0,
    key: 'checkbox',
    label: 'Checkbox',
    field: 'checkbox',
    show: true,
    columnName: 'checkbox',
    sortField: 'checkbox',
    type: '',
    isField: false,
    sorted: false,
    isAsc: true,
    sortable: true,
    checkboxColumn: true,
    hideable: true,
    showCheckbox: true,
  },
]

export default DEFAULT_ZONES_COLUMNS
