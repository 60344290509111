/*global google*/
import { useRef, useEffect, useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import { GoogleMap, withGoogleMap, Polygon, Marker } from 'react-google-maps'
import { compose, withProps } from 'recompose'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'

import _ from 'lodash'
import { EYesNo } from '~/types/enums/ECommonEnum'

const GeofenceMapView = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_G_API_URL,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '93%' }} />,
    mapElement: <div style={{ minHeight: 600 }} />,
  }),
  withGoogleMap,
)(props => {
  const { points, currentLocation, zoom, geoRef, onChange, onPointerChange } =
    props

  const polygonRef = useRef()
  const [_points, setPoints] = useState(points || [])

  const { confirmation } = useConfirmationProvider()

  const getPath = useCallback(() => {
    if (polygonRef.current) {
      return polygonRef.current
        .getPath()
        .getArray()
        .map(location => location.toJSON())
    } else {
      return []
    }
  }, [polygonRef])

  const onMouseUp = useCallback(() => {
    const _coordinates = polygonRef.current
      .getPath()
      .getArray()
      .map(location => location.toJSON())
    if (points.length != _coordinates.length) {
      onChange && onChange(_coordinates)
    } else {
      const isUpdate = _coordinates.find(
        location =>
          !points.find(
            _location =>
              _location.lat == location.lat && _location.lng == location.lng,
          ),
      )
      if (isUpdate) {
        onChange && onChange(_coordinates)
      }
    }
  }, [onChange, points])

  const openConfirm = useCallback(async () => {
    const result = await confirmation({
      message:
        'This marker is not inside the current geofence, please create a new geofence by dragging and drawing it',
      buttons: [
        {
          text: 'OK',
          action: EYesNo.Yes,
        },
      ],
    })
    if (result === EYesNo.Yes) {
      setPoints([])
    }
  }, [confirmation])

  useEffect(() => {
    if (geoRef?.current) {
      geoRef.current = {
        getPath,
      }
    }
  }, [geoRef, getPath])

  useEffect(() => {
    setPoints(points)
  }, [currentLocation, points])

  useUpdateEffect(() => {
    if (_points.length === 0 || _.size(currentLocation) === 0) {
      return
    }

    const marker = new google.maps.Marker({
      position: currentLocation,
    })
    const poly = new google.maps.Polygon({ paths: _points })
    const isInside = google.maps.geometry.poly.containsLocation(
      marker.getPosition(),
      poly,
    )

    if (!isInside) {
      openConfirm()
    }
  }, [currentLocation, _points])

  return (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={currentLocation}
      center={currentLocation}
    >
      {!!currentLocation && (
        <Marker
          draggable
          position={currentLocation}
          onDragEnd={value => onPointerChange && onPointerChange(value.latLng)}
        />
      )}
      {_points && _points.length > 0 ? (
        <Polygon
          ref={polygonRef}
          path={_points}
          editable={true}
          draggable={false}
          defaultOptions={{
            strokeColor: '#1090ff',
            fillColor: '#1090ff',
          }}
          onMouseUp={onMouseUp}
        />
      ) : (
        <DrawingManager
          drawingMode={
            _points && _points.length > 0
              ? null
              : google.maps.drawing.OverlayType.POLYGON
          }
          onPolygonComplete={_polygon => {
            const points = _polygon
              .getPath()
              .getArray()
              .map(point => point.toJSON())
            setPoints(points)
            onChange && onChange(points)
            _polygon.visible = false
          }}
          defaultOptions={{
            drawingControl: false,
            polygonOptions: {
              clickable: true,
              editable: true,
              zIndex: 1,
            },
          }}
        />
      )}
    </GoogleMap>
  )
})

export default GeofenceMapView
