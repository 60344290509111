import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTerminals } from '~/redux/actions/CommonData/terminalsActions'
import { selectTerminalsLastFetchedAt } from '~/redux/selectors'

export const useInitializeReduxTerminals = () => {
  const dispatch = useDispatch()
  const lastUpdatedAt = useSelector(selectTerminalsLastFetchedAt)

  useEffect(() => {
    !lastUpdatedAt && dispatch(getTerminals())
  }, [lastUpdatedAt, dispatch])
}

// Leave this old code snipper for now. We currently get all terminals and filter down via Selectors, but
// we may want to give filtering flexibility in the future

/* 
const buyers = useSelector(selectBuyersFromBuyerSellers)
const company = useSelector(selectMyCurrentCompany)
const scope = useSelector(selectCurrentScope)
const lastFetchedAt = useSelector(selectBuyerTerminalsLastUpdatedAt)

const buyerIds = useMemo(() => buyers.map(b => b.buyer.id), [buyers])
const [fetched, setFetched] = useState(false)

useEffect(() => {
  if (scope === 'buyer') {
    if (!fetched && company.id && lastFetchedAt) {
      dispatch(getTerminals(company.id))

      setFetched(true)
    }
  }
  if (scope === 'seller') {
    if (!fetched && !lastFetchedAt) {
      dispatch(getTerminals(buyerIds))

      setFetched(true)
    }
  }
}, [buyerIds, lastFetchedAt, dispatch, fetched, scope, company.id])
*/
