// import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'
import { moduleName } from '~/utils/constants'

// const savedInfo = JSON.parse(
//   localStorage.getItem('concord_materials'),
// )?.companySubTabs?.users
// const savedFilters =
//   savedInfo?.filters?.data && Object.keys(savedInfo?.filters?.data).length > 0
//     ? savedInfo?.filters?.data
//     : null
// const savedColumns =
//   savedInfo?.columns?.data?.length > 0 ? savedInfo?.columns?.data : []
// const savedSorts =
//   savedInfo?.sorts?.data?.length > 0 ? savedInfo?.sorts?.data : []

// const getInitialColumns = () => {
//   const sortableColumns = savedColumns.filter(c => c.sortable)
//   if (
//     _.size(savedColumns) === 0 ||
//     sortableColumns.length > 0 ||
//     new Date() < new Date('2023-01-12')
//   ) {
//     return DEFAULT_COMPANY_USER_COLUMNS
//   }

//   return savedColumns.map(column => {
//     const sortedColumn = savedSorts.find(
//       sortColumn => sortColumn.field === column.field,
//     )
//     return {
//       ...column,
//       sorted: sortedColumn?.sorted,
//     }
//   })
// }

const usersSlice = createSlice({
  name: moduleName.company.users,
  initialState: {
    columns: DEFAULT_COMPANY_USER_COLUMNS,
  },
})

export default usersSlice
