import { createSlice } from '@reduxjs/toolkit'

export const terminalsUiSlice = createSlice({
  name: 'terminals',
  initialState: {
    selectedTerminal: null,
    selectedTerminalCoordinates: [],
    isLoading: false,
    columns: [],
    filters: {},
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
  },
})

export default terminalsUiSlice
