import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStorage from '~/hooks/useStorage'
import { useRouter } from '~/hooks/useRouter'

import { Link } from 'react-router-dom'
import { IonIcon, IonItem, IonLabel, IonSpinner } from '@ionic/react'
import { logOutOutline } from 'ionicons/icons'
import { logout } from '~/redux/actions/sessionActions'
import { selectLoginPageLoading } from '~/redux/selectors'

const StaticMenuFooter = ({ settings }) => {
  const router = useRouter()

  const dispatch = useDispatch()
  const user = useSelector(state => state.session.user)
  const loginPageLoading = useSelector(selectLoginPageLoading)

  const isSettingsAllowed = useMemo(
    () => user && user.menuOptions && user.menuOptions.includes('settings'),
    [user],
  )

  const [, setStorageData] = useStorage()

  const handleLogout = useCallback(() => {
    dispatch(logout({ router }))
    setStorageData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setStorageData])

  return (
    <div className='menu-footer'>
      {isSettingsAllowed && (
        <div className='footer-row'>
          <Link
            key={settings.label}
            to={settings.path}
            style={{ textDecoration: 'none' }}
          >
            <IonItem
              className={
                settings.path == router.pathname
                  ? 'menuDropdown menu-item'
                  : 'hoverDropdown menu-item'
              }
              lines='none'
            >
              <IonIcon
                color={
                  settings.path == router.pathname ? 'concord' : 'cloudgrey'
                }
                icon={settings.icon}
              />
              <IonLabel
                style={{ marginLeft: '12px' }}
                className={
                  settings.path == router.pathname
                    ? 'labelDropdown-select'
                    : 'labelDropdown'
                }
              >
               {settings.label}
              </IonLabel>
            </IonItem>
          </Link>
        </div>
      )}
      <div className='footer-row'>
        <IonItem
          style={{ cursor: 'pointer' }}
          className='hoverDropdown menu-item'
          lines='none'
          onClick={handleLogout}
          disabled={loginPageLoading}
        >
          {loginPageLoading && <IonSpinner name='lines-small' />}
          {!loginPageLoading && (
            <IonIcon color='cloudgrey' icon={logOutOutline} />
          )}
          <IonLabel style={{ marginLeft: '12px' }} className='labelDropdown'>
            Log Out
          </IonLabel>
        </IonItem>
      </div>
    </div>
  )
}
export default StaticMenuFooter
