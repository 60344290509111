import { useState, useMemo, useCallback, useEffect } from 'react'
import { useRouter } from '~/hooks/useRouter'
import { useSelector } from 'react-redux'
import { useConfirmationProvider } from '~/contexts'
import useFilterOptions from '~/hooks/useFilterOptions'
import useStoreFilters from '~/hooks/useStoreFilters'
import { useDeepCompareEffect } from 'react-use'

import {
  CompanyTable,
  DialogDataCorrectionMethodForm,
  Loading,
} from '~/components/shared'
import CompanyDataCorrectionList from '~/components/company/CompanyDataCorrectionList'

import _ from 'lodash'
import { produce } from 'immer'
import { companyTabQuery } from '~/utils/constants'
import { replaceUrl } from '~/utils/buildUrl'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import {
  selectSellersFromCompanies,
  selectBuyersFromCompanies,
} from '~/redux/selectors'
import getSearchedData from '~/utils/getSearchedData'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import filterWhere from 'filter-where'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

function CompanyDataCorrectionMethodSubTab(props) {
  const { companyId } = props

  const [dataCorrectionMethods, setDataCorrectionMethods] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [formData, setFormData] = useState(undefined)

  const [filterData, setFilterData] = useState(
    defaultModuleFilters[moduleName.company.dataCorrectionMethods],
  )

  const router = useRouter()
  const { confirmation } = useConfirmationProvider()

  const { filterOptions } = useFilterOptions(
    moduleName.company.dataCorrectionMethods,
  )
  const [storedFilters, setStoredFilters] = useStoreFilters(
    moduleName.company.dataCorrectionMethods,
  )

  const commonSellers = useSelector(selectSellersFromCompanies)
  const commonBuyers = useSelector(selectBuyersFromCompanies)

  const tabs = useMemo(
    () => [
      {
        label: 'My Company',
        value: companyTabQuery.myCompany,
      },
      {
        label: 'Seller',
        value: companyTabQuery.sellers,
      },
      {
        label: 'Buyer',
        value: companyTabQuery.buyers,
      },
    ],
    [],
  )

  const mapDataCorrectionMethods = useMemo(() => {
    let filteredData = dataCorrectionMethods

    if (filterData.companyId.length > 0) {
      filteredData = dataCorrectionMethods.filter(filterWhere(filterData))
    }

    return getSearchedData(filteredData, searchKey, [
      'company.name',
      'company.code',
      'columnName',
      'formatRegex',
      'fieldLength',
      'model',
    ])
  }, [dataCorrectionMethods, filterData, searchKey])

  const onCloseForm = () => {
    setIsFormOpen(false)
    setFormData(undefined)
  }

  const handleChangeTab = useCallback(
    ({ value }) => {
      const newUrl = replaceUrl(router.fullPath, { tab: value })
      router.push(newUrl)
    },
    [router],
  )

  const handleSearchKeyChange = useCallback(newSearchKey => {
    setSearchKey(newSearchKey)
  }, [])

  const companyIds = useMemo(() => {
    switch (router.query.tab) {
      case companyTabQuery.buyers: {
        return commonBuyers.map(buyer => buyer.id)
      }

      case companyTabQuery.sellers: {
        return commonSellers.map(seller => seller.id)
      }

      default: {
        return [companyId]
      }
    }
  }, [commonBuyers, commonSellers, companyId, router.query.tab])

  const getApiCall = useCallback(() => {
    return apiClient.dataCorrectionMethods.get({
      filters: {
        companyId: companyIds,
      },
    })
  }, [companyIds])

  const getDataCorrections = useCallback(async () => {
    if (companyIds.length === 0) {
      setDataCorrectionMethods([])

      return
    }
    setLoading(true)
    try {
      const response = await getApiCall()
      setDataCorrectionMethods(response.dataCorrectionMethods)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [companyIds.length, getApiCall])

  const handleToggleForm = useCallback(nextValue => {
    setIsFormOpen(nextValue)
    if (!nextValue) {
      setFormData(undefined)
    }
  }, [])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: 'Are you sure you want to delete this data correction',
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.dataCorrectionMethods.delete(
            rowData.id,
          )
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setDataCorrectionMethods(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setIsFormOpen(true)
    setFormData(rowData)
  }, [])

  const handleChangeFilters = useCallback(newFilters => {
    setFilterData(newFilters)
  }, [])

  const handleSaveDefaultFilters = useCallback(() => {
    setStoredFilters({ data: filterData })
  }, [filterData, setStoredFilters])

  const handleClearFilters = useCallback(newFilters => {
    setFilterData(newFilters)
  }, [])

  useEffect(() => {
    getDataCorrections()
  }, [getDataCorrections])

  useDeepCompareEffect(() => {
    setFilterData(storedFilters.data)
  }, [storedFilters.data])

  return (
    <>
      {loading && <Loading />}
      <CompanyTable
        description='Manage rules for correcting incorrectly parsed data from physical documents.'
        title='Data Correction Methods'
        renderCountText={() =>
          `${mapDataCorrectionMethods.length} Data Correction Methods`
        }
        tabs={tabs}
        currentTab={router.query.tab || companyTabQuery.myCompany}
        onChangeTab={handleChangeTab}
        buttonProps={{
          buttonText: 'Add Data Correction Method',
          loading,
        }}
        // FormComponent={DataCorrectionForm}
        formProps={{
          loading,
          isOpen: isFormOpen,
          onToggleForm: handleToggleForm,
          // company: mergeBuyerSellerOptions,
          // onSubmit: handleSubmit,
        }}
        renderTable={() => (
          <CompanyDataCorrectionList
            companyDataCorrections={mapDataCorrectionMethods}
            onEditClick={handleOpenUpdateForm}
            deleteParser={handleDeleteRow}
          />
        )}
        filterProps={{
          searchKey,
          moduleName: moduleName.company.dataCorrectionMethods,
          filterData,
          filterOptions,
          onChangeFilters: handleChangeFilters,
          onSaveDefaultFilters: handleSaveDefaultFilters,
          onResetFilterToDefault: handleClearFilters,
          onClearFilters: handleClearFilters,
          onSearchKeyChange: handleSearchKeyChange,
        }}
      />
      <DialogDataCorrectionMethodForm
        isOpen={isFormOpen}
        formData={formData}
        onClose={onCloseForm}
        afterCreate={newData => {
          onCloseForm()
          setDataCorrectionMethods(prev => [newData, ...prev])
        }}
        afterUpdate={newData => {
          onCloseForm()
          setDataCorrectionMethods(prev =>
            produce(prev, draft => {
              const index1 = draft.findIndex(({ id }) => id === newData.id)
              if (index1 !== -1) {
                draft[index1] = newData
              }
            }),
          )
        }}
      />
    </>
  )
}

export default CompanyDataCorrectionMethodSubTab
