import { useQueryLoad } from '~/hooks/useQueryData'
import ViewPDF from '../ViewPDF'
import ViewImage from '../ViewImage'
import LoadPrint from '~/components/order/LoadPrint'
import { useMemo } from 'react'
import { isParserButler } from '~/utils/checkParser'
import ButlerBlock from '../ButlerBlock'
import { When } from 'react-if'
import Skeleton from 'react-loading-skeleton'

export interface ITicketViewProps {
  loadId: number
}

function TicketView(props: ITicketViewProps) {
  const { loadId } = props

  const { loadData, isLoadDataFetched, isLoadingLoadData } = useQueryLoad(
    loadId,
    {
      enabled: Boolean(loadId),
    },
  )

  const renderElement = useMemo(() => {
    if (loadData && isLoadDataFetched) {
      if (
        isParserButler(loadData.parserName || '') &&
        loadData.uid &&
        loadData.modelId
      ) {
        return (
          <ButlerBlock modelId={loadData.modelId} documentId={loadData.uid} />
        )
      }

      if (loadData.ticketPdf) {
        return <ViewPDF link={loadData.ticketPdf} />
      }
      if (loadData.ticketImage) {
        return <ViewImage link={loadData.ticketImage} />
      }
      return <LoadPrint load={loadData} />
    }
    return null
  }, [isLoadDataFetched, loadData])

  return (
    <div>
      <When condition={isLoadingLoadData}>
        <div className='CheckSignatoryForm__loading'>
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
        </div>
      </When>
      {renderElement}
    </div>
  )
}

export default TicketView
