import { useState, useCallback } from 'react'

import { Alert } from 'react-bootstrap'

import * as yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import './styles.scss'
import { apiClient } from '~/api/ApiClient'
import { When } from 'react-if'
import { ConcordFormStructure } from '~/components/shared'

function WorkPayTypeForm(props) {
  const {
    afterCreate,
    afterUpdate,
    formData,
    companyId,
    className,
    ...formProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const schema = yup.object({
    code: yup.string().required('This field is required'),
    name: yup.string().required('This field is required'),
  })

  const defaultValues = {
    code: '',
    name: '',
  }

  const isUpdating = Boolean(formData?.id)

  const fields = [
    {
      label: 'Code',
      name: 'code',
      isRequired: true,
    },
    {
      label: 'Name',
      name: 'name',
      isRequired: true,
    },
  ]

  const createWorkPayType = useCallback(
    async formValues => {
      const { errors, workPayType } = await apiClient.workPayTypes.create({
        code: formValues.code,
        name: formValues.name,
        companyId,
      })
      if (errors?.errors?.length > 0) {
        setBackendError(errors?.errors?.[0] || toastMessages.createError)
      } else {
        afterCreate(workPayType)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate, companyId],
  )

  const updateWorkPayType = useCallback(
    async formData => {
      const { name, code, id } = formData
      const { workPayType, errors } = await apiClient.workPayTypes.update(id, {
        name,
        code,
      })
      if (errors?.errors?.length > 0) {
        setBackendError(errors?.errors?.[0] || toastMessages.createError)
      } else {
        afterUpdate(workPayType)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateWorkPayType(formValues)
        } else {
          await createWorkPayType(formValues)
        }
      } catch (error) {
        console.log('error', error)
        setBackendError(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [createWorkPayType, isUpdating, updateWorkPayType],
  )

  return (
    <div>
      <When condition={Boolean(backendError)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {backendError}
        </Alert>
      </When>
      <ConcordFormStructure
        className={className}
        isLoading={isLoading}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
        {...formProps}
      />
    </div>
  )
}
export default WorkPayTypeForm
