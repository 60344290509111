import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

const buyerSellerProductsSlice = createSlice({
  name: 'buyerSellerProducts',
  initialState: {},
  reducers: {
    initializeBuyerSellerProducts: (_state, action) => {
      const newState = {}

      action.payload.buyerSellerProducts.forEach((bsp, index) => {
        newState[bsp.id] = { ...bsp, index }
      })

      return newState
    },
    updateBuyerSellerProducts: (state, action) => {
      action.payload.forEach(bsp => {
        state[bsp.id] = {
          ...state[bsp.id],
          ...bsp,
        }
      })
    },
    updateBuyerSellerProduct: (state, { payload }) => {
      if (payload?.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = payload
      }
    },
  },
})

export const fetchBuyerSellerProducts = createAsyncThunk(
  'fetchBuyerSellerProducts',
  async (params, { dispatch }) => {
    try {
      const { buyerSellerProducts } = await apiClient.buyerSellerProducts.get(
        params,
      )

      dispatch(
        buyerSellerProductsSlice.actions.updateBuyerSellerProducts(
          buyerSellerProducts,
        ),
      )
    } catch (error) {
      console.log('error', error)
    }
  },
)

export const fetchBuyerSellerProduct = createAsyncThunk(
  'fetchBuyerSellerProduct',
  async (params, { dispatch }) => {
    try {
      const { buyerSellerProduct } =
        await apiClient.buyerSellerProducts.getById(params)

      dispatch(
        buyerSellerProductsSlice.actions.updateBuyerSellerProduct(
          buyerSellerProduct,
        ),
      )
    } catch (error) {
      console.log('error', error)
    }
  },
)

export default buyerSellerProductsSlice
