import { View, StyleSheet } from '@react-pdf/renderer'
import { useMemo } from 'react'
import PDFDataTableHeader from './PDFDataTableHeader'
import PDFDataTableRow from './PDFDataTableRow'
import PDFDataTableFooter from './PDFDataTableFooter'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#1090FF',
    fontSize: 11,
  },
})

function PDFDataTable(props) {
  const {
    data = [],
    columns = [],
    isHiddenFooter = true,
    renderFooterContent,
    renderFooter,
    styles: stylesProp,
    itemHeight,
  } = props

  const hasPinnedRow = useMemo(
    () => columns.some(({ pinnedRow }) => pinnedRow),
    [columns],
  )

  return (
    <View style={[styles.tableContainer, stylesProp]}>
      <PDFDataTableHeader columns={columns} data={data} />
      {hasPinnedRow && (
        <PDFDataTableHeader columns={columns} data={data} isPinnedColumns />
      )}
      <PDFDataTableRow data={data} columns={columns} itemHeight={itemHeight} />
      {!isHiddenFooter && (
        <PDFDataTableFooter
          data={data}
          renderFooterContent={renderFooterContent}
          renderFooter={renderFooter}
        />
      )}
    </View>
  )
}

PDFDataTable.propTypes = {}

export default PDFDataTable
