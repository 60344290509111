import { useState, useEffect, useCallback, memo, useMemo } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { IonSpinner } from '@ionic/react'
import { Form, Row, Col } from 'react-bootstrap'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import RegexField from '../RegexField'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import humps from 'humps'

import './TextField.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { CheckMarkIcon, CloseIcon } from '~/components/shared'

const TextField = props => {
  const {
    label,
    value,
    onChange,
    className,
    onConfirmUpdate,
    field,
    loading,
    id,
    name,
    hideConfirmButton,
    error,
    defaultValue,
    onResetChanges,
    countMatched,
    ...inputProps
  } = props

  const { confirmation } = useConfirmationProvider()

  const [textValue, setTextValue] = useState('')

  const isDisplayButtonInline = useMemo(
    () => defaultValue !== textValue && !hideConfirmButton,
    [defaultValue, hideConfirmButton, textValue],
  )

  const handleChange = useCallback(
    (event, { value }) => {
      setTextValue(value)
      onChange && onChange(event, value)
    },
    [onChange],
  )

  const confirmUpdateEmail = useCallback(
    event => {
      const newValue = {
        field,
        value: textValue,
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
    },
    [field, onConfirmUpdate, textValue],
  )

  const handleConfirmUpdateValue = useCallback(
    async event => {
      const name = humps.camelize(field)
      const elements = document.getElementsByClassName(
        `DialogEmailContent__${name}`,
      )

      if (elements.length === 0) {
        const regexName = _(field).upperFirst().replace(/_/g, ' ')
        const result = await confirmation({
          message: `${regexName} doesn't match the content of that email, are you sure you want to update it?`,
        })
        if (result === EYesNo.Yes) {
          confirmUpdateEmail(event)
        }
      } else {
        confirmUpdateEmail(event)
      }
    },
    [confirmUpdateEmail, confirmation, field],
  )

  const handleKeyPress = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleConfirmUpdateValue(event)
      }
    },
    [handleConfirmUpdateValue],
  )

  const handleResetValues = useCallback(() => {
    setTextValue(defaultValue)
    onResetChanges &&
      onResetChanges({
        field,
        resetValue: defaultValue,
        currentValue: textValue,
      })
  }, [defaultValue, field, onResetChanges, textValue])

  useEffect(() => {
    setTextValue(value)
  }, [value])

  return (
    <Form.Group
      as={Row}
      className={clsx('TextField__formContainer', className, {
        disabled: loading,
      })}
      controlId={`${id}-${label}`}
    >
      <Form.Label className='TextField__label' column sm={4} md={4} lg={4}>
        {label}
      </Form.Label>
      <Col sm={8} md={8} lg={8} className='TextField__inputContainer'>
        <div className='TextField__wrapInput'>
          <RegexField
            value={textValue}
            onChange={handleChange}
            className={clsx('TextField__input', { error })}
            onKeyDown={handleKeyPress}
            name={name || field}
            {...inputProps}
          />
        </div>
        {countMatched > 0 && (
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={tooltipProps => (
              <Tooltip id='button-tooltip test' {...tooltipProps}>
                {countMatched} word matched
              </Tooltip>
            )}
          >
            <div className='SectionEmailParsers__countMatched'>
              {`(${countMatched})`}
            </div>
          </OverlayTrigger>
        )}
        {isDisplayButtonInline && (
          <>
            {_.isNil(error) && (
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={tooltipProps => (
                  <Tooltip id='button-tooltip test' {...tooltipProps}>
                    Click to confirm
                  </Tooltip>
                )}
              >
                <Button
                  className='SectionEmailParsers__confirmButton'
                  onClick={handleConfirmUpdateValue}
                >
                  <CheckMarkIcon className='TextField__icon' />
                </Button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipProps => (
                <Tooltip id='button-tooltip test' {...tooltipProps}>
                  Reset
                </Tooltip>
              )}
            >
              <Button
                className='SectionEmailParsers__confirmButton'
                onClick={handleResetValues}
              >
                <CloseIcon className='TextField__icon delete' />
              </Button>
            </OverlayTrigger>
          </>
        )}

        {loading && <IonSpinner name='lines-small' />}
      </Col>
    </Form.Group>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onConfirmUpdate: PropTypes.func,
  field: PropTypes.string,
}

export default memo(TextField)
