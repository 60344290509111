import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { ConcordFormRadio } from '~/components/shared'

function ConcordRadioWithFormControl(props) {
  const { control, name, ...radioProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const handleChange = useCallback(
    (event, value) => {
      field.onChange(value)
    },
    [field],
  )

  return (
    <ConcordFormRadio
      {...radioProps}
      name={name}
      value={field.value}
      onChange={handleChange}
      error={fieldState.error?.message}
    />
  )
}

export default ConcordRadioWithFormControl
