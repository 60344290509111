import { useCallback } from 'react'

import { ConcordFormLayout } from '~/components/shared'

import TokenInput from 'react-customize-token-input'

import clsx from 'clsx'

import 'react-customize-token-input/dist/react-customize-token-input.css'
import './ConcordFormTagsInput.scss'
import { Form } from 'react-bootstrap'

function ConcordFormTagsInput(props) {
  const {
    label,
    value = [],
    onChange,
    error,
    placeholder,
    isRequired,
    isDisabled,
    hint,
    onGetTokenDisplayLabel,
    className,
  } = props

  const handleChange = useCallback(
    newValues => {
      onChange && onChange(newValues)
    },
    [onChange],
  )

  return (
    <ConcordFormLayout
      label={label}
      error={error}
      isRequired={isRequired}
      hint={hint}
      className={className}
    >
      <TokenInput
        tokenValues={value}
        className={clsx({ isDisabled })}
        onTokenValuesChange={handleChange}
        placeholder={value.length === 0 ? placeholder : ''}
        onGetTokenDisplayLabel={onGetTokenDisplayLabel}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

export default ConcordFormTagsInput
