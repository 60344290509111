import moment from 'moment'
import { REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS } from './constants'

export const getPricingDate = (dateRange: string | null) => {
  if (dateRange) {
    const matches = dateRange.match(REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS)
    if (matches) {
      const startDate = matches[1]
      const endDate = matches[3]

      if (!startDate || startDate === '-Infinity') {
        return null
      }

      return {
        startDate,
        endDate,
      }
    }
  }

  return null
}

const formatPricingDate = (dateRange: string) => {
  const pricingDate = getPricingDate(dateRange)
  if (pricingDate) {
    const { startDate, endDate } = pricingDate
    const format = 'MMM-D-YY'
    return `${moment(startDate).format(format)} to ${moment(endDate).format(
      format,
    )}`
  }

  return ''
}

export default formatPricingDate
