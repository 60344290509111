import { Alert, Badge, Form } from 'react-bootstrap'
import { CompanyInfo } from '~/components/shared/CompanyInfo'
import { ICompany } from '~/types/models/ICompany'

import './styles.scss'
import {
  useQueryAddresses,
  useQueryEmails,
  useQueryPhoneNumbers,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { EAddressableType } from '~/types/enums/EAddress'
import { EEmailableType } from '~/types/enums/EEmail'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import { useEffect, useState } from 'react'
import {
  ArrowForwardIcon,
  TerminalMarker1,
  ToolTipOverlay,
} from '~/components/shared'
import clsx from 'clsx'
import { IAddress } from '~/types/models/IAddress'
import { ITerminal } from '~/types/models/ITerminal'
import { IEmail } from '~/types/models/IEmail'
import { IPhoneNumber } from '~/types/models/IPhoneNumber'
import buildFullAddress from '~/utils/buildFullAddress'
import { formatPhoneNumber } from '~/utils/utils'
import { CompanyItemBadgeList } from '../CompanyItemBadgeList'

export interface ICompanyCardProps {
  company: ICompany
  isChecked?: boolean
  showExpandedDataByDefault?: boolean
  onSelect?: (company: ICompany) => void
}

function CompanyCard(props: ICompanyCardProps) {
  const { company, isChecked, showExpandedDataByDefault, onSelect } = props

  const [isExpanded, setIsExpanded] = useState(false)

  const { terminalsData, isFetchedTerminals } = useQueryTerminals(
    {
      filters: {
        companyId: company.id,
      },
    },
    { enabled: isExpanded },
  )

  const { addressesData, isFetchedAddressData, getAddressTypeLabels } =
    useQueryAddresses(
      {
        filters: {
          addressableId: company.id,
          addressableType: EAddressableType.company,
        },
      },
      { enabled: isExpanded },
    )

  const { emailsData, isEmailsFetched } = useQueryEmails(
    {
      filters: {
        emailableId: company.id,
        emailableType: EEmailableType.company,
      },
    },
    { enabled: isExpanded },
  )

  const { phoneNumbersData, isPhoneNumbersFetched } = useQueryPhoneNumbers(
    {
      filters: {
        phoneableId: company.id,
        phoneableType: EPhoneableType.company,
      },
    },
    { enabled: isExpanded },
  )

  const isFetched =
    isFetchedAddressData &&
    isPhoneNumbersFetched &&
    isEmailsFetched &&
    isFetchedTerminals

  const tabs = [
    {
      label: 'Addresses',
      data: addressesData,
      // canShowMapOnClick: true,
      render: (item: IAddress, selection: IAddress | null) => {
        const types = getAddressTypeLabels(item.addressTypes)
        return (
          <ToolTipOverlay content={types} placement='top'>
            <Badge
              bg={selection?.id === item.id ? 'info' : 'secondary'}
              style={{ padding: 6 }}
            >
              {buildFullAddress(item)}
            </Badge>
          </ToolTipOverlay>
        )
      },
      // fnFetchDefaultCenter: async (address: IAddress) => {
      //   const fullAddress = buildFullAddress(address)
      //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=AIzaSyD8vF1Sm2An_LVf_oUlafdzshwtWDjYHv0`
      //   const response = await fetch(url)
      //   const data = await response.json()
      //   return data.results[0].geometry.location
      // },
      // renderElementOnMap: (item: IAddress) => <AddressMarker address={item} />,
    },
    {
      label: 'Phones',
      data: phoneNumbersData,
      render: (item: IPhoneNumber) => {
        const types = item.phoneTypes
          .map(type => {
            return EPhoneNumberTypes[type]
          })
          .join(', ')
        return (
          <ToolTipOverlay content={types} placement='top'>
            <Badge bg='secondary' style={{ padding: 6 }}>
              <a href={`tel:${item.number}`} style={{ color: 'white' }}>
                {formatPhoneNumber(item.number)}
              </a>
            </Badge>
          </ToolTipOverlay>
        )
      },
    },
    {
      label: 'Emails',
      data: emailsData,
      render: (item: IEmail) => {
        const types = item.emailTypes
          .map(type => {
            return EPhoneNumberTypes[type]
          })
          .join(', ')
        return (
          <ToolTipOverlay content={types} placement='top'>
            <Badge bg='secondary' style={{ padding: 6 }}>
              <a href={`mailto:${item.email}`} style={{ color: 'white' }}>
                {formatPhoneNumber(item.email)}
              </a>
            </Badge>
          </ToolTipOverlay>
        )
      },
    },
    {
      label: 'Terminals',
      data: terminalsData,
      canShowMapOnClick: true,
      canShowAllItemsOnMap: true,
      renderElementOnMap: (terminal: ITerminal) => (
        <TerminalMarker1 key={terminal.id} terminal={terminal} />
      ),
      render: (item: ITerminal, selection: ITerminal | null) => {
        return (
          <ToolTipOverlay
            content={buildFullAddress(item.address)}
            placement='top'
          >
            <Badge
              bg={selection?.id === item.id ? 'info' : 'secondary'}
              style={{ padding: 6 }}
            >
              {item.code} - {item.name}
            </Badge>
          </ToolTipOverlay>
        )
      },
    },
  ]

  const tabFiltered = tabs.filter(({ data }) => data.length > 0)

  useEffect(() => {
    if (typeof showExpandedDataByDefault === 'boolean') {
      setIsExpanded(showExpandedDataByDefault)
    }
  }, [showExpandedDataByDefault])

  return (
    <div className='CompanyCard__container'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onClick={event => {
          event.stopPropagation()
          setIsExpanded(prev => !prev)
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Check
            style={{ marginRight: 8 }}
            checked={isChecked}
            onClick={event => {
              event.stopPropagation()
              onSelect && onSelect(company)
            }}
          />
          <CompanyInfo
            company={{
              value: company.id,
              label: company.name,
              name: company.name,
              image: company.logo,
              code: company.code,
            }}
          />
        </div>
        <span className={clsx('arrowIcon clickable', { isExpanded })}>
          <ArrowForwardIcon />
        </span>
      </div>

      {isExpanded && (
        <div style={{ marginTop: 8 }}>
          {tabFiltered.length === 0 && isFetched ? (
            <Alert style={{ fontSize: 13 }} variant='info'>
              No data found!
            </Alert>
          ) : null}

          {tabFiltered
            .filter(({ data }) => data.length > 0)
            .map(
              ({
                label,
                data,
                render,
                canShowMapOnClick,
                canShowAllItemsOnMap,
                renderElementOnMap,
              }) => (
                <CompanyItemBadgeList
                  key={label}
                  label={label}
                  data={data as any}
                  render={render as any}
                  canShowMapOnClick={canShowMapOnClick}
                  renderElementOnMap={renderElementOnMap as any}
                  canShowAllItemsOnMap={canShowAllItemsOnMap}
                />
              ),
            )}
        </div>
      )}
    </div>
  )
}

export default CompanyCard
