import moment from 'moment'

const getDateRangeToday = () => {
  return {
    startTime: moment().set({ hours: 0, minutes: 0, seconds: 0 }).toISOString(),
    endTime: moment()
      .set({ hours: 23, minutes: 59, seconds: 59 })
      .toISOString(),
  }
}

export default getDateRangeToday
