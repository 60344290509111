import userTableConfigurationsSlice from '~/redux/reducers/data/userTableConfigurationsSlice'
import documentsUiSlice from '~/redux/reducers/ui/documentsSlice'
import loadsUiSlice from '~/redux/reducers/ui/loadsSlice'
import invoicesUiSlice from '~/redux/reducers/ui/invoices'
import companySubTabsSlice from '~/redux/reducers/ui/companySubTabs'
import {
  mergeColumnChangesIntoColumnDefaults,
  columnHashToColumnsArray,
} from '~/utils/userTableConfigurationUtils'
import moment from 'moment'
import { apiClient } from '~/api/ApiClient'

export const onUpdateColumnsByTable = (payload, tableName) => dispatch => {
  switch (tableName) {
    case 'documents': {
      dispatch(documentsUiSlice.actions.updateUiColumns(payload))
      break
    }
    case 'loads': {
      dispatch(loadsUiSlice.actions.updateUiColumns(payload))
      break
    }
    case 'invoices': {
      dispatch(invoicesUiSlice.actions.updateUiColumns(payload))
      break
    }

    default: {
      dispatch(
        companySubTabsSlice.actions.updateSubTabUiColumns({
          data: payload,
          subTab: tableName,
        }),
      )
    }
  }
}

export const onUpdateColumnConfigurations =
  (payload, tableName) => dispatch => {
    dispatch(
      userTableConfigurationsSlice.actions.updateConfigurations({
        tableName,
        configurations: {
          columnConfigurations: payload,
        },
      }),
    )
  }

export const onUpdateFilterConfigurations =
  (payload, tableName) => dispatch => {
    dispatch(
      userTableConfigurationsSlice.actions.updateConfigurations({
        tableName,
        configurations: {
          filterConfigurations: payload,
        },
      }),
    )
  }

export const fetchUserTableConfigurations = tableName => dispatch => {
  apiClient.userTableConfigurations.get({
    tableName
  })
    .then(response => {
      if (!response) {
        //some tables need to verify that we have attempted to fetch filter configurations
        dispatch(
          userTableConfigurationsSlice.actions.initializeConfigurations({
            tableName,
            id: null,
            filterConfigurations: {
              updatedAt: moment(),
            },
          }),
        )
      } else {
        const columnArray = columnHashToColumnsArray(
          response.columnConfigurations,
        )

        dispatch(
          userTableConfigurationsSlice.actions.initializeConfigurations({
            tableName: response.tableName,
            id: response.id,
            columnConfigurations: columnArray,
            filterConfigurations: response.filterConfigurations || {},
          }),
        )
      }
    })
    .catch(err => console.log(err))
}

export const createOrUpdateUserTableConfigurations =
  (tableName, body, columnConfigurations, tableId) => dispatch => {
    const updatedBody = mergeColumnChangesIntoColumnDefaults(
      body,
      columnConfigurations,
    )

    if (columnConfigurations.length === 0) {
      postTableConfiguration(updatedBody, tableName, dispatch)
    } else {
      updateTableConfiguration(updatedBody, tableId, tableName, dispatch)
    }
  }

const postTableConfiguration = (columnConfigurations, tableName, dispatch) => {
  apiClient.userTableConfigurations.create({
    tableName,
    columnConfigurations
  })
    .then(response => {
      const configArray = columnHashToColumnsArray(
        response.columnConfigurations,
      )
      dispatch(
        userTableConfigurationsSlice.actions.initializeConfigurations({
          tableName: response.tableName,
          id: response.id,
          columnConfigurations: configArray,
        }),
      )
    })
    .catch(err => console.log(err))
}

const updateTableConfiguration = (
  columnConfigurations,
  tableId,
  tableName,
  dispatch,
) => {
  apiClient.userTableConfigurations.update(
    tableId,
    { columnConfigurations },
    { tableName },
  )
    .then(response => {
      const configArray = columnHashToColumnsArray(
        response.columnConfigurations,
      )
      dispatch(
        userTableConfigurationsSlice.actions.updateConfigurations({
          tableName: response.tableName,
          configurations: {
            columnConfigurations: configArray,
          },
        }),
      )
    })
    .catch(err => console.log(err))
}

export const updateTableAutoSave = (payload, tableName) => dispatch => {
  dispatch(
    userTableConfigurationsSlice.actions.updateTableAutoSave({
      tableName,
      autoSave: payload,
    }),
  )
}
