import React, { useMemo, useCallback } from 'react'
import useFuzzy from '~/hooks/useFuzzy'

import { Card } from 'react-bootstrap'
import FormatOptionLabel from '~/components/shared/ConcordForm/Dropdown/Company/FormatOptionLabel'
import { ToolTipOverlay } from '~/components/shared'

import _ from 'lodash'

import './RecommendedOptions.scss'

function RecommendedOptions(props) {
  const { keyword, options, searchKeys, onSelect, hideAvatar } = props

  const mapSearchKeys = useMemo(() => {
    const DEFAULT_SEARCH_KEYS = ['label']

    return [...DEFAULT_SEARCH_KEYS, ...(searchKeys || [])]
  }, [searchKeys])

  const { seachedList } = useFuzzy(options, {
    keys: mapSearchKeys,
    initialSearchValue: keyword,
  })

  const handleSelect = useCallback(
    (event, selectedOption) => {
      onSelect && onSelect(selectedOption)
    },
    [onSelect],
  )

  const renderOptions = useCallback(() => {
    // if (_.size(seachedList) === 0) {
    //   return (
    //     <div>No recommended options!</div>
    //   )
    // }

    return _(seachedList)
      .take(3)
      .value()
      .map(({ label, value, image, imageUrl, name, fuzzyOptions }) => (
        <div className='RecommendedOptions__wrapOption' key={value}>
          <FormatOptionLabel
            className='RecommendedOptions__option'
            label={label}
            key={value}
            value={value}
            name={name}
            imageUrl={image || imageUrl}
            onClick={handleSelect}
            hideAvatar={hideAvatar}
          />
          {/* <div /> */}
          {fuzzyOptions && (
            <ToolTipOverlay content='Matching percentage'>
              <div>{fuzzyOptions.percentageMatching}%</div>
            </ToolTipOverlay>
          )}
        </div>
      ))
  }, [seachedList, handleSelect, hideAvatar])

  if (!keyword || _.size(seachedList) === 0) {
    return null
  }

  return (
    <Card className='RecommendedOptions__root'>
      <Card.Header>Recommended Options</Card.Header>
      <Card.Body className='RecommendedOptions__body'>
        {renderOptions()}
      </Card.Body>
    </Card>
  )
}

RecommendedOptions.propTypes = {}

export default RecommendedOptions
