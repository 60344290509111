import { useState, useCallback, useEffect, useMemo } from 'react'
import { closeOutline, checkmarkOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { determineFormStyling } from './helpers'
import { apiClient } from '~/api/ApiClient'

const CostCodesInlineForm = ({
  editData,
  parent,
  parentId = null,
  parents,
  toggleShowForm,
  setData,
  setParents,
  parentStyles,
  useChildStyles = false,
}) => {
  // For validation styling changes
  const [showFormError, setShowFormError] = useState(true)
  const [formState, setFormState] = useState({
    code: '',
    name: '',
    parentId: null,
  })
  const styles = useMemo(
    () =>
      useChildStyles
        ? determineFormStyling(!parent.treeIndex ? 1 : 2)
        : parentStyles,
    [useChildStyles, parent, parentStyles],
  )
  const isInputValid = useMemo(
    () => !showFormError && (!formState.code || !formState.name),
    [showFormError, formState],
  )

  // If in 'edit' mode, use those. Else, use the node's parentId
  useEffect(() => {
    if (editData) {
      const { code, name, parentId } = editData

      setFormState({
        code,
        name,
        parentId,
      })
    } else {
      setFormState({
        parentId,
      })
    }
  }, [editData, parentId])

  // clear form data after leaving
  useEffect(() => {
    return () => {
      setFormState({
        code: '',
        name: '',
        parentId: null,
      })
    }
  }, [])

  const handleInputChange = e => {
    let value = e.target.value
    const field = e.target.name

    setFormState({
      ...formState,
      [field]: value,
    })
  }

  const handleSubmitButton = useCallback(
    e => {
      e.preventDefault()
      const isFormValid = validateCostCodeForm(formState)
      if (isFormValid) {
        submitCostCode(formState)
      }
    },
    [formState, submitCostCode],
  )

  const validateCostCodeForm = form => {
    if (!form.code || !form.name) {
      setShowFormError(false)
      toast.error(toastMessages.formError)

      return false
    }
    setShowFormError(true)

    return true
  }

  const submitCostCode = useCallback(
    formData => {
      if (editData) {
        apiClient.costCodes.update(editData.id, formData) //
          .then(response => {
            toggleShowForm()
            toast.success(toastMessages.useCostCodes.updateSuccess)
            if (parentId && editData.treeIndex) {
              response.treeIndex = 1
            }

            response.children = editData.children
            setData(response)
          })
          .catch(() => toast.error(toastMessages.updateError))
      } else {
        apiClient.costCodes.create(formData)
          .then(response => {
            toggleShowForm()
            toast.success(toastMessages.useCostCodes.createSuccess)
            let parentsCopy
            if (!response.parentId) {
              parentsCopy = JSON.parse(JSON.stringify(parents))
              parentsCopy.push(response)
              setParents(parentsCopy)

              return
            }
            parentsCopy = JSON.parse(JSON.stringify(parent))
            // Children field doesn't exist on childless nodes, can't be pushed into till this
            if (!parentsCopy.children) {
              parentsCopy.children = []
            }
            // Covers styling after re-render for the two layers below the highest level
            if (!parent.parentId) {
              response.treeIndex = 1
            }
            parentsCopy.children.push(response)
            setData(parentsCopy)
          })
          .catch(() => toast.error(toastMessages.createError))
      }
    },
    [editData, setData, parents, parent, parentId, toggleShowForm, setParents],
  )

  return (
    <>
      <div className={`${styles.rowClassName} body-grid cc-inline-form`}>
        <div
          className={`cc-check-icon-container ${styles.iconClassName}`}
          style={{ backgroundColor: '#ff6961' }}
          onClick={toggleShowForm}
        >
          <IonIcon className='cost-code-icon' icon={closeOutline} />
        </div>
        <div style={{ marginLeft: 20 }}>
          <form className={`body-grid ${styles.gridClassName}`}>
            <div>
              <input
                value={formState.code}
                onChange={handleInputChange}
                name='code'
                placeholder='Code...'
                className={`cc-inline-form-input ${styles.inputClassName}`}
                style={{ border: isInputValid ? '1px solid red' : '' }}
              ></input>
              {isInputValid && (
                <span className='my-company-form-input-error'>
                  You must include the cost code.
                </span>
              )}
            </div>
            <div style={{ marginLeft: 12 }}>
              <input
                value={formState.name}
                onChange={handleInputChange}
                name='name'
                placeholder='Name...'
                className={`cc-inline-form-input cc-name-input ${styles.inputClassName}`}
                style={{ border: isInputValid ? '1px solid red' : '' }}
              ></input>
              {isInputValid && (
                <span className='my-company-form-input-error'>
                  You must include the cost code name.
                </span>
              )}
            </div>
          </form>
        </div>

        <div
          className={`cc-check-icon-container ${styles.iconClassName}`}
          onClick={handleSubmitButton}
        >
          <IonIcon className='cost-code-icon' icon={checkmarkOutline} />
        </div>
      </div>
    </>
  )
}

export default CostCodesInlineForm
