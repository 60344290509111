import {
  documentRoutesPaths,
  companyRoutesPaths,
  invoicesPaths,
} from '~/constants/routesPaths'

export const dashboardSMCardHelper = dashboardData => {
  const model = dashboardData.model
  let newArray = []

  if (model === 'document') {
    const newData = parseDashboardDocumentData(dashboardData)
    newArray = cardArrayProps(dashboardData, model, newData)
  } else {
    newArray = cardArrayProps(dashboardData, model)
  }

  return newArray
}

export const dashboardFeatureCardOnClick = (
  queryParam,
  modelType,
  history,
  createdDateRange,
) => {
  const { startDate, endDate } = createdDateRange
  const urlDateString = `startDate=${startDate}&endDate=${endDate}`
  switch (modelType) {
    case 'document': {
      if (queryParam.totalDocs) {
        history.push(documentRoutesPaths.documents + '?' + urlDateString)
      } else {
        history.push(
          documentRoutesPaths.documents +
            '?' +
            queryParam.urlParams +
            '&' +
            urlDateString,
        )
      }
      break
    }
    case 'load': {
      if (queryParam.totalLoads) {
        history.push(companyRoutesPaths.loads + '?' + urlDateString)
      } else {
        history.push(
          companyRoutesPaths.loads +
            '?' +
            queryParam.urlParams +
            '&' +
            urlDateString,
        )
      }
      break
    }
    case 'invoice': {
      if (queryParam.totalInvoices) {
        history.push(invoicesPaths.index + '?' + urlDateString)
      } else {
        history.push(
          invoicesPaths.index +
            '?' +
            queryParam.urlParams +
            '&' +
            urlDateString,
        )
      }
      break
    }
    default:
      break
  }
}

const cardArrayProps = (dashboardData, model, newData = {}) => {
  if (model === 'document') {
    return [
      {
        title: 'Documents Uploaded',
        data: dashboardData.count,
        urlParams: 'status=All',
        totalDocs: true,
      },
      {
        title: 'New Documents',
        data: dashboardData.byStatusCount.new || 0,
        urlParams: 'status=New',
      },
      {
        title: 'Loads Processed',
        data: newData.Load.Used,
        urlParams: 'docType=load&status=Used',
      },
      {
        title: 'Loads with Issues',
        data: newData.Load.Issue,
        urlParams: 'docType=load&status=Issue',
      },
      {
        title: 'Invoices Processed',
        data: newData.Invoice.Used,
        urlParams: 'docType=invoice&status=Used',
      },
      {
        title: 'New Invoices',
        data: newData.Invoice.New,
        urlParams: 'docType=invoice&status=New',
      },
    ]
  } else if (model === 'load') {
    return [
      {
        title: 'Total Loads',
        data: dashboardData.count,
        urlParams: 'status=All',
        totalLoads: true,
      },
      {
        title: 'Total Quantity Sum',
        data: Math.trunc(dashboardData.sumByQty),
        urlParams: '',
      },
      {
        title: 'Loads with Missing Quantity',
        data: dashboardData.byQtyCount['0.0'] || 0,
        urlParams: 'qty=0...0',
      },
      {
        title: 'Sellers',
        data: Object.keys(dashboardData.bySellerIdCount).length,
        urlParams: '',
      },
      {
        title: 'Seller Terminals',
        data: Object.keys(dashboardData.bySellerTerminalIdCount).length,
        urlParams: '',
      },
      { title: 'Flag Count', data: '_____', urlParams: '' },
    ]
  } else {
    return [
      {
        title: 'Total Invoices',
        data: dashboardData.count,
        urlParams: 'status=All',
        totalInvoices: true,
      },
      {
        title: 'Invoice LD count',
        data: dashboardData.sumByLoadCount,
        urlParams: '',
      },
      {
        title: 'In-Progress Invoices',
        data: dashboardData.byStatusCount.progress || 0,
        urlParams: 'statuses=Progress',
      },
      {
        title: 'Rejected Invoices',
        data: dashboardData.byStatusCount.rejected || 0,
        urlParams: 'statuses=Rejected',
      },
      {
        title: 'Sellers',
        data: Object.keys(dashboardData.bySellerIdCount).length,
        urlParams: '',
      },
      {
        title: 'Average Selling Price(ASP)',
        data:
          Math.trunc(dashboardData.sumByTotal / dashboardData.sumByQty) || 0,
        urlParams: '',
      },
    ]
  }
}

const parseDashboardDocumentData = data => {
  const groupByHash = data['by["docType","status"]Count']

  const newHash = {
    Invoice: {
      New: groupByHash['["Invoice","New"]'] || 0,
      Used: groupByHash['["Invoice","Used"]'] || 0,
    },
    Load: {
      New: groupByHash['["Load","New"]'] || 0,
      Used: groupByHash['["Load","Used"]'] || 0,
      Issue: groupByHash['["Load","Issue"]'] || 0,
    },
    NotSet: {
      New: groupByHash['["NotSet","New"]'] || 0,
      Used: groupByHash['["NotSet","Used"]'] || 0,
    },
  }

  return newHash
}
