import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  ISellerToBuyerTerminal,
  ISellerToBuyerTerminalGetListParams,
} from '~/types/models/ISellerToBuyerTerminal'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

const useModifySellerToBuyerTerminals = (
  params: ISellerToBuyerTerminalGetListParams,
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'sellerToBuyerTerminals',
      sessionUser?.id,
      buildGetUrl(apiClient.sellerToBuyerTerminals.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addSellerToBuyerTerminals = useCallback(
    (data: ISellerToBuyerTerminal) => {
      queryClient.setQueryData<ISellerToBuyerTerminal[]>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateSellerToBuyerTerminals = useCallback(
    (id: number, data: ISellerToBuyerTerminal) => {
      queryClient.setQueryData<ISellerToBuyerTerminal[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(g => g.id === id)
              draft[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeSellerToBuyerTerminals = useCallback(
    (id: number) => {
      queryClient.setQueryData<ISellerToBuyerTerminal[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addSellerToBuyerTerminals,
    updateSellerToBuyerTerminals,
    removeSellerToBuyerTerminals,
  }
}

export default useModifySellerToBuyerTerminals
