import React, { useMemo } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import ReactSelect from 'react-select'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import './ContainerSubTab.scss'

const ContainerSubTab = props => {
  const {
    onChangeSegment,
    currentSegment,
    subTabList,
    requiresDetailValue,
    optionPrimaryKey,
    renderOptionLabel,
  } = props

  const { isScreenDown, isScreenOnly } = useBreakpoint()

  const sizeOfList = subTabList?.length || 0

  const displayDropdown = sizeOfList > 4 || isScreenDown('md')

  const formatOptions = useMemo(() => {
    if (sizeOfList === 0) {
      return []
    }

    return subTabList.map(option => ({
      label: renderOptionLabel(option),
      value: option[optionPrimaryKey],
    }))
  }, [sizeOfList, subTabList, renderOptionLabel, optionPrimaryKey])

  const valueOption = useMemo(
    () => formatOptions.find(option => option.value === currentSegment),
    [currentSegment, formatOptions],
  )

  const determineTabStyling = (index, length) => {
    let style = {}
    if (index === 0) {
      style.borderTopLeftRadius = 12
      style.borderBottomLeftRadius = 12
    }
    if (index == length - 1) {
      style.borderTopRightRadius = 12
      style.borderBottomRightRadius = 12
      style.borderRight = '1px solid #D0D5DD'
    }

    return style
  }

  // A few subtabs require 'e' and the rest require 'e.detail.value'
  const onChangeSegmentValue = e =>
    requiresDetailValue ? onChangeSegment(e.target.value) : onChangeSegment(e)

  const handleChangeSegmentValue = newValue => {
    const event = {
      target: {
        value: newValue.value,
      },
    }

    const value = requiresDetailValue ? newValue.value : event
    onChangeSegment(value)
  }

  /**
   * @param {boolean} condition
   * @returns {string}
   */
  const getActiveTab = condition =>
    clsx('ContainerSubtab__item', {
      active: condition,
    })

  const isCenterTab = useMemo(() => {
    if (isScreenOnly('xl')) {
      return false
    }

    if (isScreenDown('lg') || sizeOfList > 2) {
      return true
    }

    return false
  }, [isScreenDown, isScreenOnly, sizeOfList])

  if (subTabList && sizeOfList === 0) {
    return null
  }

  return (
    <div
      className={clsx('ContainerSubtab__container', {
        'justify-content-center': isCenterTab,
      })}
    >
      {displayDropdown && (
        <ReactSelect
          onChange={handleChangeSegmentValue}
          value={valueOption}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          isOptionSelected={option => {
            return option.value === currentSegment
          }}
          menuPortalTarget={document.body}
          options={formatOptions}
          isSearchable
          className='ContainerSubTab__dropdownContainer'
        />
      )}

      {!displayDropdown &&
        subTabList.map((tab, index) => {
          return (
            <button
              key={index}
              className={getActiveTab(currentSegment == tab[optionPrimaryKey])}
              style={determineTabStyling(index, subTabList.length)}
              value={tab[optionPrimaryKey]}
              onClick={onChangeSegmentValue}
            >
              {renderOptionLabel(tab)}
            </button>
          )
        })}
    </div>
  )
}

ContainerSubTab.propTypes = {
  subTabList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  optionPrimaryKey: PropTypes.string,
  renderOptionLabel: PropTypes.func,
  onChangeSegment: PropTypes.func,
  currentSegment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiresDetailValue: PropTypes.bool,
}

ContainerSubTab.defaultProps = {
  subTabList: [],
  optionPrimaryKey: 'value',
  renderOptionLabel: tab => tab.label,
}

export default ContainerSubTab
