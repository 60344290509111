import { useCommonTabs } from './useCommonTab'

import { Tabs } from 'react-bootstrap'

import clsx from 'clsx'

import type { ICommonTabProps } from './type'

import './styles.scss'

const CommonTab: React.FC<ICommonTabProps> = props => {
  const { className } = props

  const { existentTabs, eventKey, onSelectTab, renderTabs } =
    useCommonTabs(props)

  if (existentTabs.length === 0) {
    return null
  }

  return (
    <Tabs
      className={clsx('CommonTab__root', className)}
      justify
      activeKey={eventKey}
      onSelect={onSelectTab}
    >
      {renderTabs()}
    </Tabs>
  )
}

export default CommonTab
