import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IAddress, IGetAddressesParams } from '~/types/models/IAddress'
import useModifyAddresses from './useModifyAddresses'
import { EAddressType } from '~/types/enums/EAddress'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryAddresses = (
  params: IGetAddressesParams,
  options?: Partial<UseQueryOptions<IAddress[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addAddress, updateAddress, removeAddress } =
    useModifyAddresses(params)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'addresses',
      sessionUser?.id,
      buildGetUrl(apiClient.addresses.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.addresses.get(params)

      return response.addresses
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const addressesData = useMemo(() => data || [], [data])

  const findAddress = (condition: Partial<IAddress>) => _.find(data, condition)

  const getAddressTypeLabels = (addressTypes: EAddressType[]) => {
    const label: string[] = []
    addressTypes.filter(num => label.push(_.startCase(EAddressType[num])))
    return label.join(', ')
  }

  const addressOptions = useMemo(
    () =>
      addressesData.map(address => {
        const { street, city, country, state, addressTypes } = address
        const types = getAddressTypeLabels(addressTypes)

        return {
          value: address.id,
          label: `${street}, ${city}, ${country}, ${state} (${types})`,
          address,
        }
      }),
    [addressesData],
  )

  const residenceAddress = useMemo(
    () =>
      addressesData.find(({ addressTypes }) =>
        addressTypes.includes(EAddressType.residence),
      ),
    [addressesData],
  )

  const hrAddress = useMemo(
    () =>
      addressesData.find(({ addressTypes }) =>
        addressTypes.includes(EAddressType.hr),
      ),
    [addressesData],
  )

  const hasHrAddress = useMemo(() => Boolean(hrAddress), [hrAddress])

  return {
    addressesData,
    isLoadingAddressesData: isLoading,
    addressOptions,
    residenceAddress,
    hrAddress,
    hasHrAddress,
    isFetchedAddressData: isFetched,
    addAddress,
    updateAddress,
    removeAddress,
    refetchQueryAddresses: refetch,
    getAddressTypeLabels,
    findAddress,
  }
}

export default useQueryAddresses
