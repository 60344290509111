import { useMemo, useState } from 'react'
import {
  useQueryOffdays,
  useQueryTerminals,
  useQueryUsers,
} from '~/hooks/useQueryData'
import useFuzzy from '~/hooks/useFuzzy'
import {
  ConcordToolbar,
  ContainerSearchBar,
  DriverCard,
} from '~/components/shared'
import { ItemTypes } from '~/components/fleet/StartTimes/helpers'
import Skeleton from 'react-loading-skeleton'

import './styles.scss'
import { Alert, Form } from 'react-bootstrap'
import clsx from 'clsx'
import getFilteredData from '~/utils/getFilteredData'
import { EFieldType, EStatus } from '~/types/enums/ECommonEnum'
import buildNestedObject from '~/utils/buildNestedObject'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import _ from 'lodash'
import moment from 'moment'
export interface IDriverFleetListProps {
  assignedDriverFleetIds: number[]
  isBannerShown?: boolean
  bannerText?: string
  isListDisabled?: boolean
  date?: Date | string
  sortOptions?: any
  setSortOptions?: any
}

function DriverFleetList(props: IDriverFleetListProps) {
  const {
    assignedDriverFleetIds,
    isBannerShown,
    bannerText,
    isListDisabled,
    date,
    sortOptions,
    setSortOptions,
  } = props

  const [filterData, setFilterData] = useState({
    'worker.terminalId': [],
    offday: 'showOffDay',
  })
  const [showAll, setShowAll] = useState(false)

  const { driverFleetUsers, isLoadingUsersData } = useQueryUsers()
  const { terminalOptions } = useQueryTerminals()

  const { offdaysData, findOffDayByWorkerId, addOffday, updateOffday } =
    useQueryOffdays(
      {
        filters: {
          startDate: {
            startDate: moment(date).startOf('day').toISOString(),
            endDate: moment(date).endOf('day').toISOString(),
          },
        },
      },
      { enabled: Boolean(filterData.offday !== 'disabled' && date) },
    )

  const filterDataBuilt = buildNestedObject(
    _.pick(filterData, ['worker.terminalId']),
    true,
  )

  const driverFleetsFiltered: IUserData[] = getFilteredData(driverFleetUsers, {
    ...filterDataBuilt,
    worker: {
      status: EStatus.Active.toLowerCase(),
    },
  })

  const availableDriverFleets = useMemo(
    () =>
      showAll
        ? driverFleetsFiltered
        : driverFleetsFiltered.filter(
            ({ driverFleet }) =>
              !assignedDriverFleetIds.includes(driverFleet?.id as number),
          ),
    [assignedDriverFleetIds, driverFleetsFiltered, showAll],
  )

  const {
    searchValue,
    onSearch,
    seachedList: searchedDriverList,
  } = useFuzzy(availableDriverFleets, {
    keys: ['person.firstName', 'person.lastName'],
  })

  const sortedDriverList = useMemo(() => {
    const sortingOptions = sortOptions.filter(({ sorted }: any) => sorted)
    if (sortingOptions.length) {
      const fields = sortingOptions.map(({ sortField }: any) => sortField)
      const sortingDirections = sortingOptions.map(({ isAsc }: any) =>
        isAsc ? 'asc' : 'desc',
      )

      return _.orderBy(searchedDriverList, fields, sortingDirections)
    }

    return searchedDriverList
  }, [searchedDriverList, sortOptions])

  return (
    <div className='DriverFleetList__container'>
      <div className='headerContainer'>
        <ContainerSearchBar
          searchBarParent='searchBar'
          searchBarPlaceholder='Search Drivers'
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
        />

        <Form.Check
          type='switch'
          label='Show all'
          checked={showAll}
          onChange={event => {
            setShowAll(event.target.checked)
          }}
        />
      </div>

      <div className='driverFleetList'>
        {isBannerShown && (
          <Alert style={{ marginBottom: 8, fontSize: 13 }} variant='danger'>
            {bannerText}
          </Alert>
        )}
        {isLoadingUsersData && (
          <div>
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
          </div>
        )}

        <div
          style={{ marginBottom: 8, width: '100%' }}
          className={clsx({ isDisabled: isListDisabled })}
        >
          <ConcordToolbar
            isHiddenSearchBar
            filterData={filterData}
            filterOptions={[
              {
                label: 'Terminal',
                field: 'worker.terminalId',
                options: terminalOptions,
                type: EFieldType.multipleSelect,
              },
              {
                label: 'Offday',
                field: 'offday',
                type: EFieldType.singleSelect,
                options: [
                  {
                    label: 'Show offday',
                    value: 'showOffDay',
                  },
                  {
                    label: 'Disable',
                    value: 'disabled',
                  },
                  {
                    label: 'Hide worker with offday',
                    value: 'hide',
                  },
                ],
              },
            ]}
            sortOptions={sortOptions}
            onFilterChange={setFilterData}
            onSortChange={setSortOptions}
            filterSectionWidth={350}
          />
        </div>

        <div>
          {sortedDriverList
            .filter(({ worker }) => {
              if (filterData.offday !== 'hide') {
                return true
              }
              return !findOffDayByWorkerId(worker?.id as number)
            })
            .map((user, index) => (
              <div
                key={user.id}
                style={{ marginBottom: 8 }}
                className={clsx({ isDisabled: isListDisabled })}
              >
                <DriverCard
                  index={index + 1}
                  driverFleetId={user.driverFleet?.id as number}
                  dragType={ItemTypes.DRIVER}
                  isShowingDriverName
                  isHiddenStatusTime
                  offdaysData={
                    filterData.offday === 'showOffDay' ? offdaysData : []
                  }
                  className='DriverFleetList__driverCard'
                  afterCreateOffday={addOffday}
                  afterUpdateOffday={offday => {
                    updateOffday(offday.id, offday)
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default DriverFleetList
