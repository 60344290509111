export const openShow = {
  border: '2px solid #3880ff',
}

export const enabled = {
  border: '2px solid #10dc60',
}

export const ionicColor = {
  snowGrey: 'snowgrey',
  dark: 'dark',
}

export const changeColorBorder = (sortOption, toggleSort) => {
  if (toggleSort) return openShow

  if (sortOption.filter(element => element.sorted).length > 0) return enabled

  return {}
}
