import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import HierarchyForm from './HierarchyForm'

import type { IDialogHierarchyFormProps } from './type'

function DialogHierarchyForm(props: IDialogHierarchyFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    allowedHierarchyData,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Hierarchy'
      isHiddenOkButton
      onClose={onClose}
    >
      <HierarchyForm
        formData={formData}
        allowedHierarchyData={allowedHierarchyData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogHierarchyForm
