import React, { useEffect, useState } from 'react'

import ReactDatePicker from 'react-datepicker'

import clsx from 'clsx'

import './styles.scss'
import moment from 'moment'
import { toast } from 'react-toastify'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { ICompany } from '~/types/models/ICompany'
import { ClockIcon } from '../SvgIcons'
import { Form } from 'react-bootstrap'
import { DropdownWithCustomChildren } from '../ConcordForm'

const INTERVAL_OPTIONS = [
  {
    label: '1 min',
    value: 1,
  },
  {
    label: '2 min',
    value: 2,
  },
  {
    label: '5 min',
    value: 5,
  },
  {
    label: '10 min',
    value: 10,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '20 min',
    value: 20,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '1 hr',
    value: 60,
  },
]

export interface IReusableTimeFieldProps {
  className?: string
  inputClassName?: string
  isDisabled?: boolean
  isReadOnly?: boolean
  value?: string | Date | null
  placeholder?: string
  onChange?: (date: string | null) => void
  customInput?: JSX.Element
  portalId?: string
  onBlur?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
}

function ReusableTimeField(props: IReusableTimeFieldProps, ref: any) {
  const {
    className,
    inputClassName,
    isDisabled,
    isReadOnly,
    value,
    customInput,
    onChange,
    portalId,
    onBlur,
    onKeyDown,
  } = props

  const [time, setTime] = useState<Date | null | undefined>(null)
  const [interval, setInterval] = useState(5)

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const timeFormat = ['H:mm', 'h:mm aa'].includes(
    currentCompany.timeFormat as string,
  )
    ? currentCompany.timeFormat || 'H:mm'
    : 'H:mm'

  const getIntervalLabel = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes} min`
    } else {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours} hr${hours > 1 ? 's' : ''} ${
        remainingMinutes > 0 ? remainingMinutes + ' min' : ''
      }`.trim()
    }
  }

  useEffect(() => {
    if (typeof value === 'string') {
      try {
        setTime(moment(value).toDate())
      } catch (error) {
        console.log('error', error)
        toast.error('Invalid date format')
        setTime(null)
      }
    } else {
      setTime(value)
    }
  }, [value])

  return (
    <ReactDatePicker
      portalId={portalId}
      wrapperClassName={clsx('ReusableTimeField__datePicker', className)}
      className={clsx('ReusableTimeField__dateInput', inputClassName, {
        isDisabled,
        isReadOnly,
      })}
      popperClassName='ReusableTimeField__popperClassName'
      selected={time}
      onChange={newDate => {
        onChange && onChange(newDate ? newDate.toISOString() : null)
      }}
      ref={ref}
      showTimeCaption={false}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={interval}
      dateFormat={timeFormat}
      showPopperArrow={false}
      timeFormat={timeFormat}
      showIcon
      customInput={customInput || <Form.Control />}
      icon={<ClockIcon />}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      <DropdownWithCustomChildren
        options={INTERVAL_OPTIONS}
        isCreatable
        className='no-hover'
        value={interval}
        onChange={(event, { value }) => {
          if (!Number.isNaN(Number(value))) {
            setInterval(Number(value))
          }
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          Interval: {getIntervalLabel(interval)}
        </div>
      </DropdownWithCustomChildren>
    </ReactDatePicker>
  )
}

export default React.forwardRef(ReusableTimeField)
