import { createSlice } from '@reduxjs/toolkit'

const companiesUiSlice = createSlice({
  name: 'companies',
  initialState: {
    allCompanies: {},
    columns: [],
    companyDetail: undefined,
    dataParser: {},
    editingReferenceName: {},
    error: undefined,
    filters: {},
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    initializeAllCompanies: (state, action) => {
      const newState = {}
      action.payload.forEach(company => {
        newState[company.id] = company
      })

      state.allCompanies = newState
    },
  },
})

export default companiesUiSlice
