import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { IonLabel } from '@ionic/react'

import clsx from 'clsx'
import Select from 'react-select'

import {
  errorStyle,
  warningStyle,
  menuWidthStyle,
} from '~/components/shared/ReactSelect/components/selectUtils'

import './styles.scss'

const selectStyle = {
  control: base => ({
    ...base,
    color: 'black',
    backgroundColor: '#f7f7f7',
    borderWidth: '0px',
  }),
}

export const FloatingSelectV2 = props => {
  const {
    name,
    placeholder,
    options = [],
    components = {},
    isReadOnly,
    isWarning,
    companyId,
    isDisabled,
    label,
    menuIsOpen,
    styles,
    control,
    getOptionLabel,
    formatOptionLabel,
    onChange: onChangeProp,
    ...dropdownProps
  } = props

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const selectDisplay = error
    ? errorStyle
    : isWarning
    ? warningStyle
    : selectStyle

  const selectedValue = useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  )

  return (
    <div className={clsx('FloatingSelectV2_root', { isDisabled })}>
      <IonLabel className='FloatingSelectV2_text'>{label || name}</IonLabel>
      <Select
        getOptionLabel={getOptionLabel}
        menuIsOpen={menuIsOpen}
        className={clsx('d-flex', { isReadOnly })}
        options={options}
        placeholder={placeholder}
        companyId={companyId}
        name={name}
        menuPosition='fixed'
        components={{
          ...components,
          IndicatorSeparator: null,
        }}
        styles={{
          ...selectDisplay,
          ...menuWidthStyle,
          ...styles,
          menuPortal: provided => ({
            ...provided,
            zIndex: 999999,
          }),
        }}
        isSearchable
        onChange={e => {
          onChange && onChange(e.value)
          onChangeProp && onChangeProp(e.value)
        }}
        value={selectedValue}
        onBlur={onBlur}
        ref={ref}
        openMenuOnFocus
        formatOptionLabel={formatOptionLabel}
        {...dropdownProps}
      />
    </div>
  )
}
