import { useState, useEffect } from 'react'

import { IonText } from '@ionic/react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import './DocumentName.scss'

const DocumentName = props => {
  const { onChange, className } = props

  const [isEdit, setIsEdit] = useState(false)
  const [document, setDocument] = useState({})

  useEffect(() => {
    if (isEdit) {
      setDocument(props.document)
    }
  }, [props.document, isEdit])

  /**
   * @param {boolean} edit
   * @returns void
   */
  const handleChangeEdit = edit => event => {
    event?.stopPropagation()
    setIsEdit(edit)
  }

  /**
   * @param {Event} event
   */
  const handleChangeDocument = event => {
    const { value } = event.target
    setDocument(prev => ({ ...prev, name: value }))
  }

  /**
   * @param {Event} event
   */
  const handleSubmit = event => {
    event.preventDefault()
    handleChangeEdit(false)()

    if (!document.name) {
      setDocument(props.document)

      return
    }

    if (document.name !== props.document.name) {
      onChange && onChange(event, document)
    }
  }

  /**
   * @param {Event} event
   */
  const handleCheckKeyDown = event => {
    // press ESC
    if (event.which === 27) {
      setIsEdit(false)
      setDocument(props.document)
    }
  }

  if (isEdit) {
    return (
      <form
        onSubmit={handleSubmit}
        className={clsx('DocumentName__formContainer', className)}
      >
        <input
          className='DocumentName__formInput'
          autoFocus
          value={document.name}
          onChange={handleChangeDocument}
          onBlur={handleSubmit}
          onKeyDown={handleCheckKeyDown}
          onClick={event => event.stopPropagation()}
        />
      </form>
    )
  }

  return (
    <IonText
      className={clsx('DocumentName__textContainer truncate-text', className)}
      onClick={handleChangeEdit(true)}
    >
      {document.name || props.document.name}
    </IonText>
  )
}

DocumentName.propTypes = {
  document: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default DocumentName
