import { IonIcon, IonButtons, IonButton } from '@ionic/react'
import { components as ReactSelectComponents } from 'react-select'
import { ToolTipOverlay } from '~/components/shared'

import { trashBinOutline, createOutline } from 'ionicons/icons'

import './TerminalCustomOption.scss'

function TerminalCustomOption(props) {
  const { selectProps, data } = props
  const { onEdit, onDelete, deletableTerminal, editableTerminal } = selectProps

  const handleEdit = () => {
    onEdit && onEdit(data)
  }

  const handleDelete = () => {
    onDelete && onDelete(data)
  }

  return (
    <div className='TerminalCustomOption__root'>
      <ReactSelectComponents.Option {...props} />
      {(onDelete || onEdit || deletableTerminal || editableTerminal) && (
        <div className='TerminalCustomOption__icons'>
          <IonButtons>
            {onDelete && deletableTerminal && (
              <ToolTipOverlay content='Delete' placement='top'>
                <IonButton onClick={handleDelete}>
                  <IonIcon icon={trashBinOutline} color='crimson' />
                </IonButton>
              </ToolTipOverlay>
            )}

            {onEdit && editableTerminal && (
              <ToolTipOverlay content='Edit' placement='top'>
                <IonButton onClick={handleEdit}>
                  <IonIcon icon={createOutline} color='primary' />
                </IonButton>
              </ToolTipOverlay>
            )}
          </IonButtons>
        </div>
      )}
    </div>
  )
}

TerminalCustomOption.propTypes = {}

export default TerminalCustomOption
