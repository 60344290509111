import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IWorkType } from '~/types/models/IWorkType'

const useModifyGroups = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(() => ['workTypes', sessionUser?.id], [sessionUser])

  const addWorkType = useCallback(
    (data: IWorkType) => {
      queryClient.setQueryData<IWorkType[]>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateWorkType = useCallback(
    (id: number, data: IWorkType) => {
      queryClient.setQueryData<IWorkType[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(g => g.id === id)
            draft[index] = data
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeWorkType = useCallback(
    (id: number) => {
      queryClient.setQueryData<IWorkType[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addWorkType,
    updateWorkType,
    removeWorkType,
  }
}

export default useModifyGroups
