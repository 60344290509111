import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'

import _ from 'lodash'
import {
  clientRoutesPaths,
  driverRoutesPaths,
  companyRoutesPaths,
  adminRoutesPaths,
  fleetRoutesPaths,
  documentRoutesPaths,
  loadsCreatePaths,
} from '~/constants/routesPaths'
import { scopeTypes } from '~/constants/user-type'

const useDefaultPage = () => {
  const value = sessionStorage.getItem('prevValue')
  const { authenticated, user } = useSelector(state => state.session)
  const { userAccess, admin, currentScope } = user

  const isMobile = useIsMobile()

  const defaultPage = useMemo(() => {
    if (authenticated) {
      const lowerCaseScope = _.lowerCase(currentScope)
      if (lowerCaseScope) {
        if (
          lowerCaseScope === scopeTypes.buyer ||
          lowerCaseScope === scopeTypes.seller
        ) {
          if (isMobile) {
            return loadsCreatePaths.loadsCapture
          }

          return value || documentRoutesPaths.documents
        }
        if (lowerCaseScope === scopeTypes.driver) {
          return value || driverRoutesPaths.startTimes
        }
        if (lowerCaseScope === scopeTypes.fleet) {
          return value || fleetRoutesPaths.dispatchDashboard
        }

        return clientRoutesPaths.index
      } else {
        if (admin == true) {
          return adminRoutesPaths.index
        }
      }
      if (!_.isEmpty(user)) {
        if (!userAccess) {
          return companyRoutesPaths.requestAccess
        }
      }

      return 'loading'
    }

    return ''
  }, [admin, authenticated, currentScope, isMobile, user, userAccess, value])

  return { defaultPage }
}

export default useDefaultPage
