import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'
import { IDialogUserGroupFormProps } from './type'
import UserGroupForm from '~/components/company/CompanyUserGroupForm'

import './styles.scss'

function DialogUserGroupForm(props: IDialogUserGroupFormProps) {
  const { formData, afterCreate, afterUpdate, isLoading, ...dialogProps } =
    props

  return (
    <CommonDialogV2
      {...dialogProps}
      isHiddenOkButton
      title='User Group'
      isLoading={isLoading}
    >
      <UserGroupForm
        className='DialogUserGroupForm__form'
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        isLoading={isLoading}
      />
    </CommonDialogV2>
  )
}
export default DialogUserGroupForm
