import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import useTableCompanyEmails from './useTableCompanyEmails'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogEmailForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import { selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { moduleName } from '~/utils/constants'

import './CompanyTableEmails.scss'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryEmails } from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'

function CompanyTableEmails(props) {
  const { tableHeight } = props

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    formData: null,
  })

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const {
    emailsData,
    isLoadingEmailsData,
    addEmail,
    updateEmail,
    removeEmail,
  } = useQueryEmails({
    filters: {
      companyId: currentCompany.id,
    },
  })

  const onOpenEditDialog = useCallback(rowData => {
    setDialogState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const onOpenCreateDialog = useCallback(() => {
    setDialogState({
      isOpen: true,
      formData: null,
    })
  }, [])

  const onCloseDialogState = useCallback(() => {
    setDialogState({
      isOpen: false,
      formData: null,
    })
  }, [])

  const afterCreateCompanyEmail = useCallback(newEmail => {
    onCloseDialogState()
    addEmail(newEmail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const afterUpdateCompanyEmail = useCallback(emailUpdated => {
    onCloseDialogState()
    updateEmail(emailUpdated.id, emailUpdated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRemoveEmail = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete email #${rowData.email}`,
      })
      if (result === EYesNo.Yes) {
        try {
          const response = await apiClient.emails.delete(rowData.id)
          if (response.id) {
            removeEmail(response.id)
            toast.success(toastMessages.deleteSuccess)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(error.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { columns } = useTableCompanyEmails()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={emailsData}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          companyViewProps={{
            name: moduleName.company.emails,
            description: 'Manage your emails.',
            onClickTopButton: onOpenCreateDialog,
          }}
          tableHeight={tableHeight || windowSize.height - 220}
          state={{
            isLoading: isLoadingEmailsData,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 90,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => onOpenEditDialog(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => onRemoveEmail(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
        />
      </div>
      <DialogEmailForm
        isOpen={dialogState.isOpen}
        formData={dialogState.formData}
        companyEmailsData={emailsData}
        afterCreate={afterCreateCompanyEmail}
        afterUpdate={afterUpdateCompanyEmail}
        onClose={onCloseDialogState}
      />
    </>
  )
}

export default CompanyTableEmails
