import { useEffect, useState } from 'react'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'

const CROPPER_HEIGHT = 400

export const ImagePreview = props => {
  const { imagefile, saveCropper, cropstart, cropdata, cropend } = props

  const [init, setInit] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    setTimeout(() => {
      setData(cropdata)
      setInit(true)
    }, 250)
  }, [cropdata])

  if (!init || (imagefile || [])?.length === 0)
    return <div style={{ height: CROPPER_HEIGHT }} />

  return (
    imagefile?.length > 0 &&
    imagefile.map(({ name, preview }) => (
      <div key={name}>
        {cropstart ? (
          <Cropper
            src={data != null ? data : preview}
            style={{ height: CROPPER_HEIGHT, width: '100%' }}
            // Cropper.js options
            initialAspectRatio={1}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={instance => {
              saveCropper(instance)
            }}
            autoCrop={false}
            cropend={cropend}
            zoomable={false}
            dragMode='move'
          />
        ) : (
          <img src={data != null ? cropdata : preview} alt={name} />
        )}
      </div>
    ))
  )
}

// ImagePreview.propTypes = {
//   imagefile: PropTypes.array,
// }

export default ImagePreview
