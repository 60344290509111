import Immutable from 'immutable'
import { FORM_MODE } from '~/constants/form-mode'
import * as types from '../actions/actionTypes'
import { replaceDataWithUpdated } from '~/utils/utils'
import { produce } from 'immer'
import defaultModuleFilters from '~/constants/defaultModuleFilters'

export const initialState = Immutable.Map({
  currentLoad: {},
  loading: undefined,
  updating: false,
  pastLoad: [],
  filters: defaultModuleFilters.loads,
  dataLoaded: false,
  page: 1,
  error: false,
  harUrlParams: false,
  selectedSellerTerminal: undefined,
  showSellerTerminalForm: false,
  sellerTerminalFormMode: null,
  selectedBuyerTerminal: undefined,
  showBuyerTerminalForm: false,
  buyerTerminalFormMode: null,
  showProductForm: false,
  productFormMode: false,
  selectedProduct: undefined,
  selectedLoadId: null,
  formData: null,
  editLoadValue: { isEdit: false, loadId: '' },
  deleteObject: {},
  loads: [],
  sortOptions: [],
})

const loadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_LOAD_FILTERS: {
      const { field } = action

      return state.set('filters', {
        ...state.get('filters'),
        [field.key]: field.value,
      })
    }
    case types.RESET_LOAD_FILTERS: {
      return state.set('filters', initialState.get('filters'))
    }
    case types.UPDATE_ALL_LOAD_FILTERS: {
      const { field } = action

      return state.set('filters', field)
    }
    case types.UPDATE_DASHBOARD_LOADS_FILTERS: {
      const { filters } = action
      const newState = { ...defaultModuleFilters.loads }

      for (const prop in filters) {
        if (prop == 'createdAt' || prop == 'qty') {
          newState[prop] = filters[prop]
        } else {
          const newFilterArray = [...newState[prop], filters[prop]]
          newState[prop] = newFilterArray
        }
      }

      return state
        .set('filters', newState)
        .set('loading', false)
        .set('dataLoaded', false)
        .set('hasUrlParams', true)
    }
    case types.FETCH_PAST_LOAD: {
      return state.set('loading', true)
    }
    case types.FETCH_PAST_LOAD_SUCCESS: {
      const { pastLoad } = action
      const newState = state.set('pastLoad', pastLoad)

      return newState.set('loading', false)
    }
    case types.FETCH_CURRENT_LOAD: {
      return state.set('loading', true)
    }
    case types.FETCH_CURRENT_LOAD_SUCCESS: {
      const { currentLoad } = action
      const newState = state.set('currentLoad', currentLoad)

      return newState.set('loading', false)
    }
    case types.FETCH_CURRENT_LOAD_FAILURE: {
      return state.set('loading', false)
    }
    case types.EDIT_LOAD: {
      const { object } = action

      return state.set('currentLoad', object)
    }

    case types.FETCH_LOADS: {
      return state.set('loading', true).set('error', false)
    }
    case types.FETCH_LOADS_SUCCESS: {
      const { loads } = action

      return state
        .set('loads', loads.loads)
        .set('count', loads.count)
        .set('loading', false)
        .set('startRange', loads.startRange)
        .set('endRange', loads.endRange)
        .set('dataLoaded', true)
        .set('error', false)
    }
    case types.FETCH_LOADS_CHANGE_SUCCESS: {
      const { loads } = action
      const currentLoads = state.get('loads')
      const updatedLoads = replaceDataWithUpdated(loads.loads, currentLoads)

      return state
        .set('loads', updatedLoads)
        .set('loading', false)
        .set('error', false)
        .set('dataLoaded', true)
    }

    case types.ERROR_LOAD: {
      return state.set('loading', false).set('error', true)
    }

    case types.UPDATE_CURRENT_LOAD: {
      return state.set('updating', true)
    }
    case types.UPDATE_CURRENT_LOAD_SUCCESS: {
      const { currentLoad } = action
      const newState = state.set('currentLoad', currentLoad)

      return newState.set('updating', false)
    }
    case types.UPDATE_LOADS_PAGE: {
      const { page } = action

      return state.set('page', page)
    }
    case types.TOGGLE_ON_EDIT_SELLER_TERMINAL: {
      const { selectedSellerTerminal, selectedLoadId } = action

      return state
        .set('selectedSellerTerminal', {
          id: selectedSellerTerminal.value,
          value: selectedSellerTerminal.code,
          label: selectedSellerTerminal.label,
        })
        .set('showSellerTerminalForm', true)
        .set('selectedLoadId', selectedLoadId)
        .set('sellerTerminalFormMode', FORM_MODE.EDIT)
    }

    case types.TOGGLE_ON_ADD_SELLER_TERMINAL: {
      const { selectedLoadId } = action

      return state
        .set('showSellerTerminalForm', true)
        .set('sellerTerminalFormMode', FORM_MODE.ADD)
        .set('selectedLoadId', selectedLoadId)
        .set('selectedSellerTerminal', undefined)
    }

    case types.TOGGLE_OFF_SELLER_TERMINAL: {
      return state.set('showSellerTerminalForm', false)
    }

    case types.TOGGLE_ON_EDIT_BUYER_TERMINAL: {
      const { selectedBuyerTerminal, selectedLoadId } = action

      return state
        .set('selectedBuyerTerminal', {
          id: selectedBuyerTerminal.value,
          value: selectedBuyerTerminal.code,
          label: selectedBuyerTerminal.label,
        })
        .set('showBuyerTerminalForm', true)
        .set('selectedLoadId', selectedLoadId)
        .set('buyerTerminalFormMode', FORM_MODE.EDIT)
    }

    case types.TOGGLE_ON_ADD_BUYER_TERMINAL: {
      const { selectedLoadId } = action

      return state
        .set('showBuyerTerminalForm', true)
        .set('buyerTerminalFormMode', FORM_MODE.ADD)
        .set('selectedLoadId', selectedLoadId)
        .set('selectedBuyerTerminal', undefined)
    }

    case types.TOGGLE_OFF_BUYER_TERMINAL: {
      return state.set('showBuyerTerminalForm', false)
    }

    case types.TOGGLE_ON_ADD_PRODUCT: {
      const { selectedLoadId } = action

      return state
        .set('showProductForm', true)
        .set('selectedLoadId', selectedLoadId)
        .set('productFormMode', FORM_MODE.ADD)
        .set('selectedProduct', undefined)
    }

    case types.TOGGLE_ON_EDIT_PRODUCT: {
      const { selectedProduct, selectedLoadId } = action

      return state
        .set('selectedProduct', {
          id: selectedProduct.value,
          value: selectedProduct.code,
          label: selectedProduct.label,
        })
        .set('showProductForm', true)
        .set('selectedLoadId', selectedLoadId)
        .set('productFormMode', FORM_MODE.EDIT)
    }

    case types.TOGGLE_OFF_PRODUCT: {
      return state.set('showProductForm', false)
    }

    case types.TOGGLE_ON_ADD_FLEET: {
      const { selectedLoadId } = action

      return state
        .set('showFleetForm', true)
        .set('FleetFormMode', FORM_MODE.ADD)
        .set('selectedLoadId', selectedLoadId)
        .set('selectedFleet', undefined)
    }

    case types.TOGGLE_OFF_ADD_FLEET: {
      return state.set('showFleetForm', false)
    }

    case types.TOGGLE_ON_ADD_SELLER: {
      const { selectedLoadId } = action

      return state
        .set('showSellerForm', true)
        .set('SellerFormMode', FORM_MODE.ADD)
        .set('selectedLoadId', selectedLoadId)
        .set('selectedSeller', undefined)
    }

    case types.TOGGLE_OFF_ADD_SELLER: {
      return state.set('showSellerForm', false)
    }

    case types.SET_INLINE_LOAD_DATA: {
      const { formData } = action

      return state.set('formData', formData)
    }

    case types.SET_INLINE_LOAD_EDIT: {
      const { isEdit, loadId } = action

      return state.set('editLoadValue', { isEdit, loadId })
    }

    case types.SET_INLINE_LOAD_DELETE: {
      const { data, entry, showDelete } = action
      data.entry = entry
      data.showDelete = showDelete

      if (data.entry === 'sellerProductList') {
        const id = data.value.substring(2, data.value.length)
        data.value = parseInt(id)
      }

      return state.set('deleteObject', data)
    }

    case types.UPDATE_LOAD_PDF_URL: {
      const { loadId, pdfUrl } = action.payload

      const loads = state.get('loads')
      const index = loads.findIndex(({ id }) => id === loadId)
      loads[index].loadPdf = pdfUrl

      return state.set('loads', loads)
    }

    case types.LOADS_UPDATE_SEARCH_KEY: {
      const filters = state.get('filters')
      const newFilters = produce(filters, draft => {
        draft.searchKey = action.payload
      })

      return state.set('filters', newFilters)
    }

    case types.LOAD_SET_SORT_OPTIONS: {
      const { payload } = action

      return state.set('sortOptions', payload)
    }

    case types.LOAD_UPDATE_LOAD_BY_ID: {
      const { id, field, value } = action.payload
      const loads = state.get('loads')

      const newLoads = produce(loads, draft => {
        const index = draft.findIndex(load => load.id === id)
        if (index > -1) {
          draft[index][field] = value
        }
      })

      return state.set('loads', newLoads)
    }

    case types.UPDATE_LOAD_TAGS_LINKS: {
      const { tagLinkData } = action
      const loads = state.get('loads')

      const newLoads = produce(loads, draft => {
        const index = draft.findIndex(load => load.id === tagLinkData.id)
        if (index > -1) {
          draft[index].tagIds = tagLinkData.tagIds
        }
      })

      return state.set('loads', newLoads)
    }

    default: {
      return state
    }
  }
}

export default loadReducer
