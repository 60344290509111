const DEFAULT_COMPANY_SELLER_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: true,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Code',
    field: 'sellerCode',
    show: true,
    value: 'sellerCode',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'sellerCode',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Status',
    field: 'sellerStatus',
    show: true,
    value: 'sellerStatus',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'sellerStatus',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_SELLER_COLUMNS
