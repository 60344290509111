var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Confidence, FieldType, } from 'common/types/DocumentLabelerTypes';
import { FieldsPanelDisplayUtils } from 'documentLabeler/common/util/FieldsPanelDisplayUtils';
import { ZOOM_VALUES } from 'documentLabeler/constants/DocumentLabelerConstants';
export var LabelingSelectionType;
(function (LabelingSelectionType) {
    LabelingSelectionType["Block"] = "Block";
    LabelingSelectionType["Region"] = "Region";
})(LabelingSelectionType || (LabelingSelectionType = {}));
/** Generates initial state from initializer data */
var generateInitialState = function (initialState, rootRef) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var showPdf = true;
    var zoomMaxScale = ZOOM_VALUES.max;
    var zoomMinScale = ZOOM_VALUES.min;
    if (typeof ((_b = (_a = initialState.config) === null || _a === void 0 ? void 0 : _a.toolbarProps) === null || _b === void 0 ? void 0 : _b.showPdf) === 'boolean') {
        showPdf = (_c = initialState.config.toolbarProps) === null || _c === void 0 ? void 0 : _c.showPdf;
    }
    if (typeof ((_e = (_d = initialState.config) === null || _d === void 0 ? void 0 : _d.toolbarProps) === null || _e === void 0 ? void 0 : _e.zoomMaxScale) === 'number') {
        zoomMaxScale = (_f = initialState.config.toolbarProps) === null || _f === void 0 ? void 0 : _f.zoomMaxScale;
    }
    if (typeof ((_h = (_g = initialState.config) === null || _g === void 0 ? void 0 : _g.toolbarProps) === null || _h === void 0 ? void 0 : _h.zoomMinScale) === 'number') {
        zoomMinScale = (_j = initialState.config.toolbarProps) === null || _j === void 0 ? void 0 : _j.zoomMinScale;
    }
    return {
        docInfo: initialState.data,
        localState: {
            activeField: undefined,
            selectionType: LabelingSelectionType.Block,
            rootRef: rootRef,
            showPdf: showPdf,
            pdfScale: 1,
            zoomMaxScale: zoomMaxScale,
            zoomMinScale: zoomMinScale,
            fieldViewing: undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderedImgHeight: null,
            isModifiedField: true,
        },
    };
};
var convertInternalStateToOutputData = function (state) {
    var trainingSimpleFields = state.docInfo.results.fields.filter(function (field) {
        return field.type === FieldType.Text || field.type === FieldType.Checkbox;
    });
    var trainingSignatureFields = state.docInfo.results.fields.filter(function (field) { return field.type === FieldType.Signature; });
    var trainingTables = state.docInfo.results.tables.map(function (table) { return (__assign(__assign({}, table), { type: FieldType.Table, rows: table.rows })); });
    var trainingDocumentLabels = {
        modelId: state.docInfo.modelId,
        docId: state.docInfo.documentId,
        results: {
            fields: trainingSimpleFields,
            signatures: trainingSignatureFields,
            tables: trainingTables,
        },
        documentId: state.docInfo.documentId,
        fileName: state.docInfo.fileName,
        mimeType: state.docInfo.mimeType,
        tempDocUrl: state.docInfo.tempDocUrl,
        wordBlocks: state.docInfo.wordBlocks,
    };
    var extractedFormFields = state.docInfo.results.fields.map(function (field) { return ({
        fieldName: field.name,
        value: FieldsPanelDisplayUtils.getTextValueFromField(field),
        confidenceScore: field.confidenceScore,
    }); });
    var extractedTables = state.docInfo.results.tables.map(function (table) { return ({
        tableName: table.name,
        confidenceScore: table.confidenceScore,
        rows: table.rows.map(function (row) { return (__assign(__assign({}, row), { cells: row.cells.map(function (cell, idx) { return ({
                columnName: table.columns[idx].name,
                value: FieldsPanelDisplayUtils.getTextValueFromCell(cell),
                confidenceScore: cell.confidenceScore,
            }); }) })); }),
    }); });
    var extractionResult = __assign(__assign({}, state.docInfo), { documentId: state.docInfo.documentId, documentStatus: 'Completed', confidenceScore: Confidence.UserReviewed, formFields: extractedFormFields, tables: extractedTables });
    return {
        trainingDocumentLabels: trainingDocumentLabels,
        extractionResult: extractionResult,
    };
};
export var DocumentLabelerState = {
    generateInitialState: generateInitialState,
    convertInternalStateToOutputData: convertInternalStateToOutputData,
};
