import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useQueryCheckCompanyDetails,
  useQueryEnrollmentProfile,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'

import type { IConcordFormField } from '~/components/shared'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  ECheckPayrollHistoryAccessMethod,
  ECheckProductsActivelyUsed,
  ICheckEnrollmentProfileFormValues,
} from '~/types/models/ICheck'
import {
  CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
  REGEX_CHECK_URL,
} from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import humps from 'humps'
import { toastMessages } from '~/constants/toast-status-text'
import * as Yup from 'yup'
import moment from 'moment'
import { SocialMediaField } from './SocialMediaField'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'

const useEnrollmentProfileForm = () => {
  const {
    checkEnrollmentProfileData,
    isLoadingCheckEnrollmentProfile,
    renewEnrollmentProfile,
  } = useQueryEnrollmentProfile()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { usersData } = useQueryUsers(
    {
      filters: {
        companyId: currentCompany.id,
      },
    },
    { enabled: Boolean(currentCompany.id) },
  )

  const { checkCompanyDetails, isImplemtationComplete } =
    useQueryCheckCompanyDetails(currentCompany?.checkUid as string)

  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { width } = useWindowSize()

  const isBigscreen = useMemo(() => width > 1200, [width])

  const contactOptions = useMemo(
    () =>
      usersData.map(({ email }) => ({
        value: email,
        label: email,
      })),
    [usersData],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        userSince: Yup.string()
          .transform(val => {
            if (val) {
              return moment(val).format('YYYY-MM-DD')
            }
            return undefined
          })
          .nullable(),
        expectedFirstPayday: Yup.string()
          .transform(val => {
            if (val) {
              return moment(val).format('YYYY-MM-DD')
            }
            return undefined
          })
          .nullable(),
        earliestKnownRevenue: Yup.string()
          .transform(val => {
            if (val) {
              return moment(val).format('YYYY-MM-DD')
            }
            return undefined
          })
          .nullable(),
        socialMedia: Yup.array().of(
          Yup.string().matches(REGEX_CHECK_URL, 'Url is invalid'),
        ),
      }),
    [],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Employee Count',
        name: 'employeeCount',
        type: EFieldType.number,
        size: isBigscreen ? 3 : 12,
      },
      {
        label: 'Contractor Count',
        name: 'contractorCount',
        type: EFieldType.number,
        size: isBigscreen ? 3 : 12,
      },
      {
        label: 'Pay Period Amount',
        name: 'payPeriodAmount',
        type: EFieldType.number,
        size: isBigscreen ? 3 : 12,
      },
      {
        label: 'User Since',
        name: 'userSince',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.date,
      },
      {
        label: 'Expected First Payday',
        name: 'expectedFirstPayday',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.date,
      },
      {
        label: 'Average Monthly Revenue',
        name: 'averageMonthlyRevenue',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.number,
      },
      {
        label: 'Earliest Known Revenue',
        name: 'earliestKnownRevenue',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.date,
      },
      {
        label: 'Months On Previous Payroll Provider',
        name: 'monthsOnPreviousPayrollProvider',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.number,
      },
      {
        label: 'Products Actively Used',
        name: 'productsActivelyUsed',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.multipleSelect,
        options: [
          {
            label: 'Timetracking',
            value: ECheckProductsActivelyUsed.timetracking,
          },
          {
            label: 'Payments',
            value: ECheckProductsActivelyUsed.payments,
          },
          {
            label: 'Scheduling',
            value: ECheckProductsActivelyUsed.scheduling,
          },
        ],
      },
      {
        label: 'Fraud Score',
        name: 'fraudScore',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.number,
      },
      {
        label: 'Missed Payments Count',
        name: 'missedPaymentsCount',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.number,
      },
      {
        label: 'Payroll History Access Method',
        name: 'payrollHistoryAccessMethod',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.singleSelect,
        options: [
          {
            label: 'Authorized Access To Previous Provider',
            value:
              ECheckPayrollHistoryAccessMethod.authorizedAccessToPreviousProvider,
          },
          {
            label: 'Provided Credentials',
            value: ECheckPayrollHistoryAccessMethod.providedCredentials,
          },
          {
            label: 'Provided Reports',
            value: ECheckPayrollHistoryAccessMethod.providedReports,
          },
        ],
      },
      {
        label: 'Account Contacts',
        name: 'accountContacts',
        size: isBigscreen ? 6 : 12,
        type: EFieldType.multipleSelect,
        options: contactOptions,
      },
      {
        label: 'Existing Payroll Customer Processing Period',
        name: 'existingPayrollCustomerProcessingPeriod',
        size: isBigscreen ? 6 : 12,
        type: EFieldType.singleSelect,
        options: CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
      },
      {
        label: 'Social Media',
        name: 'socialMedia',
        size: isBigscreen ? 6 : 12,
        render(params) {
          const { watch, name, setValue, errors = {} } = params
          const value = watch(name, [])
          const error = errors[name]

          return (
            <SocialMediaField
              value={value}
              onChange={newValue => {
                setValue(name, newValue)
              }}
              error={error || []}
            />
          )
        },
      },
      {
        label: 'Implementation Services Submission Comment',
        name: 'implementationServicesSubmissionComment',
        size: isBigscreen ? 6 : 12,
        as: 'textarea',
        rows: 3,
      },
      {
        label: 'Approved For Payment Processing',
        name: 'approvedForPaymentProcessing',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.checkbox,
      },
      {
        label: 'First Payroll Of Year',
        name: 'firstPayrollOfYear',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.checkbox,
      },
      {
        label: 'Predicted Fraud',
        name: 'predictedFraud',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.checkbox,
      },
      {
        label: 'Paying User',
        name: 'payingUser',
        size: isBigscreen ? 3 : 12,
        type: EFieldType.checkbox,
      },
    ],
    [contactOptions, isBigscreen],
  )

  const onSubmitForm = useCallback(
    async (formValues: ICheckEnrollmentProfileFormValues) => {
      setIsLoading(true)
      try {
        const { body } = await apiClient.check.updateEnrollmentProfile({
          company: formValues,
        })
        const data = JSON.parse(body)
        if (data.error) {
          const { input_errors } = data.error
          if (input_errors?.length) {
            const { message } = input_errors[0]
            toast.error(message)
          }
        } else {
          const formattedData = humps.camelizeKeys(data)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renewEnrollmentProfile(formattedData as any)
          toast.success(toastMessages.updateSuccess)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (checkEnrollmentProfileData) {
      formRef.current?.reset({
        ...checkEnrollmentProfileData,
      })
    }
  }, [checkEnrollmentProfileData])

  return {
    fields,
    formRef,
    isLoading,
    isLoadingCheckEnrollmentProfile,
    schema,
    checkCompanyDetails,
    isImplemtationComplete,
    onSubmitForm,
  }
}

export default useEnrollmentProfileForm
