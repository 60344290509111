import { CommonDialogV2 } from '~/components/shared'
import BankAccountForm from './BankAccountForm'

import './DialogBankAccountForm.scss'

function DialogBankAccountForm(props) {
  const { formData, afterCreate, afterUpdate, onClose, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      onClose={onClose}
      isHiddenOkButton
      title='Email'
    >
      <BankAccountForm
        formData={formData}
        afterCreate={(data) => {
          afterCreate && afterCreate(data)
          onClose && onClose()
        }}
        afterUpdate={(data) => {
          afterUpdate && afterUpdate(data)
          onClose && onClose()
        }}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogBankAccountForm
