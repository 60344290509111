import { useCallback, useEffect, useState, memo } from 'react'

import { IonSpinner } from '@ionic/react'
import {
  Form,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'

import clsx from 'clsx'

import './DropdownField.scss'
import { CheckMarkIcon, CloseIcon } from '~/components/shared'

const DropdownField = props => {
  const {
    label,
    value,
    options,
    className,
    onChange,
    field,
    onConfirmUpdate,
    loading,
    id,
    name,
    hideConfirmButton,
    defaultValue,
    onResetChanges,
  } = props

  const [optionValue, setOptionValue] = useState(null)

  useEffect(() => {
    setOptionValue(value)
  }, [value])

  const handleChangeDropdown = useCallback(
    event => {
      const { value } = event.target
      setOptionValue(optionValue)
      onChange && onChange(event, value)
    },
    [onChange, optionValue],
  )

  const handleConfirmUpdateValue = useCallback(
    event => {
      const newValue = {
        field,
        value: optionValue,
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
    },
    [field, onConfirmUpdate, optionValue],
  )

  const handleResetValues = useCallback(() => {
    setOptionValue(defaultValue)
    onResetChanges &&
      onResetChanges({
        field,
        resetValue: defaultValue,
        currentValue: optionValue,
      })
  }, [defaultValue, field, onResetChanges, optionValue])

  return (
    <Form.Group
      className={clsx('DropdownField__formContainer', className, {
        disabled: loading,
      })}
      as={Row}
      controlId={`${id}-${label}`}
    >
      <Form.Label className='DropdownField__label' column sm={4} md={4} lg={4}>
        {label}
      </Form.Label>
      <Col sm={8} md={8} lg={8} className='DropdownField__inputContainer'>
        <Form.Select
          name={name || field}
          className='DropdownField__input DropdownFieldField__noneDropdownField'
          onChange={handleChangeDropdown}
        >
          {options.map(({ label, value }) => (
            <option value={value} key={value} selected={value === optionValue}>
              {label}
            </option>
          ))}
        </Form.Select>

        {defaultValue !== optionValue && !hideConfirmButton && (
          <>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipProps => (
                <Tooltip id='button-tooltip test' {...tooltipProps}>
                  Click to confirm
                </Tooltip>
              )}
            >
              <Button
                className='SectionEmailParsers__confirmButton'
                onClick={handleConfirmUpdateValue}
              >
                <CheckMarkIcon
                  size={18}
                  color='var(--ion-color-concord)'
                  style={{ marginLeft: 8 }}
                />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipProps => (
                <Tooltip id='button-tooltip test' {...tooltipProps}>
                  Reset
                </Tooltip>
              )}
            >
              <Button
                className='SectionEmailParsers__confirmButton'
                onClick={handleResetValues}
              >
                <CloseIcon
                  size={18}
                  color='var(--ion-color-danger)'
                  style={{ marginLeft: 8 }}
                />
              </Button>
            </OverlayTrigger>
          </>
        )}
        {loading && <IonSpinner name='lines-small' />}
      </Col>
    </Form.Group>
  )
}

DropdownField.defaultProps = {
  options: [],
}

export default memo(DropdownField)
