var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, TableBody, TableHead, TableRow } from '@material-ui/core';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { DocumentLabelerTableCell } from 'documentLabeler/components/tableLabeler/DocumentLabelerTableCell';
import { DocumentLabelerTableContainer } from 'documentLabeler/components/tableLabeler/DocumentLabelerTableContainer';
import { DocumentLabelerTableHeader } from 'documentLabeler/components/tableLabeler/DocumentLabelerTableHeader';
import { ExtractedDataTableContainer } from 'common/dataTable/ExtractedDataTableContainer';
import { DataTableFtux } from 'common/dataTable/ftux/DataTableFtux';
import { DeleteRowIconCell } from 'common/dataTable/IconCell/deleteRowIconCell/DeleteRowIconCell';
import { FieldsPanelDisplayUtils } from 'documentLabeler/common/util/FieldsPanelDisplayUtils';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
var COLUMN_NAME = 'Column Name';
var DRAG_OR_CLICK = 'Drag (or click) on value';
var GET_TABLE_FTUX_TEXT = function () {
    return 'Try adding a row to begin annotating your table!';
};
/**
 * Component Responsible for Displaying the Table Labeler in the Document Labeler
 */
export var TableLabeler = function () {
    var _a = useDocumentLabeler(), state = _a.state, dispatch = _a.dispatch;
    var _b = useBBConfiguration(), fieldDisplayNameFormatter = _b.fieldDisplayNameFormatter, displayOnly = _b.displayOnly;
    if (!state.localState.activeField ||
        state.localState.activeField.type !== FieldType.Table) {
        throw new Error('Table labeler cannot be rendered when there is no active table in the Document Labeler');
    }
    var activeCell = state.localState.activeField.activeCell;
    var selectedTable = DocumentLabelerReducerUtils.getSelectedTable(state);
    var activeRow = selectedTable === null || selectedTable === void 0 ? void 0 : selectedTable.rows.find(function (row) { return activeCell && row.id === activeCell.rowId; });
    var cell = activeRow === null || activeRow === void 0 ? void 0 : activeRow.cells.find(function (cell) { return cell.columnId === (activeCell === null || activeCell === void 0 ? void 0 : activeCell.columnId); });
    var columns = selectedTable.columns, rows = selectedTable.rows, activeTableId = selectedTable.id;
    var handleOnCellClick = function (columnId, rowId) {
        if ((activeRow === null || activeRow === void 0 ? void 0 : activeRow.id) !== rowId || (cell === null || cell === void 0 ? void 0 : cell.columnId) !== columnId) {
            dispatch({
                type: 'setActiveField',
                payload: {
                    id: activeTableId,
                    type: FieldType.Table,
                    activeCell: {
                        rowId: rowId,
                        columnId: columnId,
                    },
                },
            });
        }
    };
    var handleOnDeleteRow = function (rowId) {
        dispatch({
            type: 'removeRowFromTable',
            payload: {
                rowId: rowId,
                tableId: selectedTable.id,
            },
        });
    };
    var handleCellTextOverride = function (rowId, columnId, textOverride) {
        dispatch({
            type: 'setTableCellTextOverride',
            payload: {
                textOverride: textOverride,
                table: {
                    id: selectedTable.id,
                    type: FieldType.Table,
                    activeCell: {
                        rowId: rowId,
                        columnId: columnId,
                    },
                },
            },
        });
    };
    var getColumnDisplayName = function (column) {
        return fieldDisplayNameFormatter
            ? fieldDisplayNameFormatter(column)
            : column;
    };
    var shouldAddPlaceholderCell = columns.length > 0 && !displayOnly;
    var columnHeaderDisplay = columns
        .map(function (column, index) { return (_jsx(DocumentLabelerTableCell, { placeholder: COLUMN_NAME, textValue: getColumnDisplayName(column.name), header: true }, index)); })
        // placeholder column header for column of delete icons in table
        .concat(shouldAddPlaceholderCell
        ? [
            _jsx(DocumentLabelerTableCell, { placeholder: COLUMN_NAME, textValue: "", header: true, actionOnly: true }, columns.length),
        ]
        : []);
    var rowsToDisplay = rows.map(function (row) { return (_jsx(TableRow, { children: row.cells
            .map(function (cell) { return (_jsx(DocumentLabelerTableCell, { textValue: FieldsPanelDisplayUtils.getTextValueFromCell(cell), placeholder: DRAG_OR_CLICK, isActive: activeCell &&
                cell.columnId === activeCell.columnId &&
                (activeRow === null || activeRow === void 0 ? void 0 : activeRow.id) === row.id, displayActions: !displayOnly, onSaveValue: function (value) {
                return handleCellTextOverride(row.id, cell.columnId, value);
            }, onClick: function () { return handleOnCellClick(cell.columnId, row.id); } }, "".concat(row.id, "-").concat(cell.columnId, "}"))); })
            .concat(shouldAddPlaceholderCell
            ? [
                _jsx(DeleteRowIconCell, { deleteRow: function () { return handleOnDeleteRow(row.id); } }, row.id),
            ]
            : []) }, row.id)); });
    return (_jsxs(DocumentLabelerTableContainer, { children: [_jsx(DocumentLabelerTableHeader, {}), _jsx(Divider, {}), _jsxs(ExtractedDataTableContainer, __assign({ numColumns: columns.length }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: columnHeaderDisplay }) }), _jsx(TableBody, { children: rowsToDisplay.length !== 0 && rowsToDisplay }), rowsToDisplay.length === 0 && (_jsx(DataTableFtux, { ftuxText: GET_TABLE_FTUX_TEXT(), numColumns: columnHeaderDisplay.length }))] }))] }));
};
