export const DRIVER_TYPES = {
  COMPLETE: 'driver-complete',
  WORKING: 'driver-working',
  LOAD: 'driver-load',
  BREAK: 'driver-break',
  OFF: 'driver-off',
  UNASSIGNED: 'driver-unassigned',
  RETURNING: 'driver-returning',
  READY: 'READY',
}
