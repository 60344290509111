import { useCallback, useState, useMemo } from 'react'
import useDrag from './useDrag'

import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import PdfViewer from '../PdfViewer'
import { arrowDown, arrowForward } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { Button } from 'react-bootstrap'
import { ToolTipOverlay } from '~/components/shared'

import _ from 'lodash'
import clsx from 'clsx'

import './PdfSplittingView.scss'

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

  if (isThouchpad) {
    ev.stopPropagation()

    return
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}

function PdfSplittingView(props) {
  const {
    pages,
    pdfUrl,
    onClick,
    selectedPdf,
    index,
    onMergePrevious,
    onMergeNext,
    onDeletePart,
    isDeleted,
    canMergePrev,
    canMergeNext,
    totalPages,
  } = props
  const [minPage, maxPage] = pages

  const [toggleDocumentSplitter, setToggleDocumentSplitter] = useState(true)

  const { dragStart, dragStop, dragMove } = useDrag()

  const handleDrag = useCallback(
    ({ scrollContainer }) =>
      event =>
        dragMove(event, posDiff => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff
          }
        }),
    [dragMove],
  )

  const handleClickPdfViewer = useCallback(
    params => () => {
      onClick && onClick(params)
    },
    [onClick],
  )

  const getSelectedDocument = useCallback(
    ([minPage, maxPage], index) => _.isEqual(selectedPdf, { minPage, maxPage, index }),
    [selectedPdf],
  )

  const renderPdfViewers = useCallback(() => {
    const elements = []
    let count = minPage

    do {
      const className = clsx('DialogSplitInvoice__pdfItem', {
        selected: getSelectedDocument([count, maxPage], index),
      })
      elements.push(
        <>
          {index === -1 && (
            <div style={{ fontSize: 14 }}>
              {count === maxPage
                ? `Page ${count}`
                : `Page ${count} of ${maxPage}`}
            </div>
          )}
          <div
            className={className}
            onClick={handleClickPdfViewer({ minPage: count, maxPage, index })}
          >
            <PdfViewer
              pdfUrl={pdfUrl}
              minPage={count}
              maxPage={maxPage}
              hideHeader
              defaultScale={0.6}
            />
          </div>
        </>,
      )

      count++
    } while (count <= maxPage)

    return elements
  }, [
    getSelectedDocument,
    handleClickPdfViewer,
    index,
    maxPage,
    minPage,
    pdfUrl,
  ])

  const handleToggleThePagesInside = useCallback(() => {
    setToggleDocumentSplitter(prev => !prev)
  }, [])

  const handleMergePrevious = useCallback(() => {
    onMergePrevious && onMergePrevious({ index, minPage, maxPage })
  }, [index, maxPage, minPage, onMergePrevious])

  const handleMergeNext = useCallback(() => {
    onMergeNext && onMergeNext({ index, minPage, maxPage })
  }, [index, maxPage, minPage, onMergeNext])

  const handleToggleDeletePart = useCallback(() => {
    onDeletePart && onDeletePart({ index, minPage, maxPage })
  }, [index, maxPage, minPage, onDeletePart])

  const mergePrevButtonRendered = useMemo(() => {
    if (!onMergePrevious || minPage === 1) {
      return null
    }

    return (
      <Button
        onClick={handleMergePrevious}
        variant='secondary'
        disabled={!canMergePrev || isDeleted}
      >
        Merge to Previous Part
      </Button>
    )
  }, [onMergePrevious, minPage, handleMergePrevious, canMergePrev, isDeleted])

  const mergeNextButtonRendered = useMemo(() => {
    if (!onMergeNext || maxPage === totalPages) {
      return null
    }

    return (
      <Button
        onClick={handleMergeNext}
        variant='secondary'
        disabled={!canMergeNext || isDeleted}
      >
        Merge to Next Part
      </Button>
    )
  }, [
    onMergeNext,
    maxPage,
    totalPages,
    handleMergeNext,
    canMergeNext,
    isDeleted,
  ])

  const deleteButtonRendered = useMemo(() => {
    if (!onDeletePart) {
      return null
    }

    return (
      <Button
        variant={isDeleted ? 'info' : 'danger'}
        onClick={handleToggleDeletePart}
      >
        {isDeleted ? 'Restore' : 'Delete'}
      </Button>
    )
  }, [handleToggleDeletePart, isDeleted, onDeletePart])

  return (
    <div className={clsx('PdfSplittingView__container', { isDeleted })}>
      <div className='PdfSplittingView__header'>
        {index !== -1 && (
          <div className='PdfSplittingView__headerLeft'>
            <ToolTipOverlay
              content={
                toggleDocumentSplitter
                  ? 'Click to hide document viewer'
                  : 'Click to show document viewer'
              }
            >
              <span onClick={handleToggleThePagesInside} className='icon'>
                <IonIcon
                  icon={toggleDocumentSplitter ? arrowDown : arrowForward}
                  className={clsx('PdfSplitting__arrowIcon')}
                />
              </span>
            </ToolTipOverlay>
            <span className='PdfSplittingView__label'>
              Page {`${minPage} - ${maxPage}`}
            </span>
          </div>
        )}
        {toggleDocumentSplitter && (
          <div className='PdfSplittingView__headerRight'>
            {mergePrevButtonRendered}
            {mergeNextButtonRendered}
            {deleteButtonRendered}
          </div>
        )}
      </div>

      {toggleDocumentSplitter && (
        <ScrollMenu
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          wrapperClassName='PdfSplittingView__body'
        >
          {renderPdfViewers()}
        </ScrollMenu>
      )}
    </div>
  )
}

export default PdfSplittingView
