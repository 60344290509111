import React, { useState } from 'react'
import {
  IonRow,
  IonGrid,
  IonCol,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonCardHeader,
  IonButtons,
  IonAlert,
} from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { Button } from '~/components'
import ReactJson from 'react-json-view'
const CompanyDataCorrectionList = ({
  companyDataCorrections,
  onEditClick,
  deleteParser,
}) => {
  const [showAlert, setShowAlert] = useState(false)
  const [currentCharge, setCurrentCharge] = useState()
  const onTrashClick = object => {
    setCurrentCharge(object)
    setShowAlert(true)
  }

  return (
    <>
      <IonGrid
        style={{
          width: '100%',
          padding: '15px',
        }}
      >
        {companyDataCorrections &&
          companyDataCorrections.map(companyDataCorrection => (
            <IonRow key={companyDataCorrection.id}>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonItem>
                      <IonLabel
                        style={{ textAlign: 'center', fontSize: '1.2em' }}
                      >
                        {companyDataCorrection?.company?.name || ''} {'-'}
                        {companyDataCorrection?.model || ''} {'-'}{' '}
                        {companyDataCorrection?.columnName || ''}
                      </IonLabel>
                      <IonButtons>
                        <Button
                          label='Edit'
                          onClick={() => onEditClick(companyDataCorrection)}
                        />
                        <Button
                          icon={trashBin}
                          color='danger'
                          onClick={() => onTrashClick(companyDataCorrection)}
                        />
                      </IonButtons>
                    </IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4'>
                        <IonItem>
                          <IonLabel>
                            <IonRow>
                              <h2 style={{ fontWeight: 'bold' }}>
                                {'Correction Dict'}
                              </h2>
                              :{' '}
                              <ReactJson
                                src={
                                  companyDataCorrection?.correctionDict == '{}'
                                    ? {}
                                    : companyDataCorrection?.correctionDict
                                }
                                collapsed={true}
                                name={false}
                              />
                            </IonRow>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size='4'>
                        <IonItem>
                          <IonLabel>
                            <IonRow>
                              <h2 style={{ fontWeight: 'bold' }}>
                                {'Format Regex'}
                              </h2>
                              : {companyDataCorrection?.formatRegex}
                            </IonRow>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size='4'>
                        <IonItem>
                          <IonLabel>
                            <IonRow>
                              <h2 style={{ fontWeight: 'bold' }}>
                                {'Field Length'}
                              </h2>
                              : {companyDataCorrection?.fieldLength}
                            </IonRow>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size='4'>
                        <IonItem>
                          <IonLabel>
                            <IonRow>
                              <h2 style={{ fontWeight: 'bold' }}>
                                {'Fuzzy Matching Array'}
                              </h2>
                              :{' '}
                              <ReactJson
                                src={
                                  companyDataCorrection?.fuzzyMatchingArray
                                    .length == 0
                                    ? {}
                                    : companyDataCorrection?.fuzzyMatchingArray
                                }
                                collapsed={true}
                                name={false}
                              />
                            </IonRow>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          ))}
        {companyDataCorrections && companyDataCorrections.length == 0 && (
          <IonRow>
            <h3 style={{ color: 'red' }}>{'No Data Correction Found'}</h3>
          </IonRow>
        )}
      </IonGrid>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm'}
        message={'Are you sure you want to delete it?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlert(false)
            },
          },
          {
            text: 'Ok',
            handler: () => {
              deleteParser(currentCharge)
            },
          },
        ]}
      />
    </>
  )
}

export default CompanyDataCorrectionList
