import { useCallback, useMemo, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useMeasure } from 'react-use'

import {
  ToolTipOverlay,
  CompanyAvatar,
  TruckNumberSection,
  DropdownWithCustomChildren,
  IConcordFormOnChangeParams,
  MarkerPlusIcon,
  CustomTerminalOption,
  PlusIcon,
  DialogTerminalForm,
} from '~/components/shared'
import { IonBadge, IonIcon, IonChip, IonText } from '@ionic/react'

import moment from 'moment'
import buildFullName from '~/utils/buildFullName'

import { EScope } from '~/types/enums/ECommonEnum'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { DRIVER_TYPES } from '~/containers/OrderTracking/Utils/driverTypes'
import { apiClient } from '~/api/ApiClient'

import type {
  IDriverCardOnChangeParams,
  IDriverCardProps,
  IDriverDragItem,
} from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ITruckFleet } from '~/types/models/ITruckFleet'
import type { ITerminal } from '~/types/models/ITerminal'
import type { IDriver } from '~/types/models/IDriver'
import {
  useQueryCompanies,
  useQueryLoads,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import * as IonIcons from 'ionicons/icons'
import { useDispatch } from 'react-redux'
import { driverFleetsSlice } from '~/redux/reducers/data/driverFleets'
import buildObjectName from '~/utils/buildObjectName'
import { colord } from 'colord'
import { IOffday } from '~/types/models/IOffday'

const useDriverCard = (props: IDriverCardProps) => {
  const {
    driverFleetId,
    dragType = DRIVER_TYPES.UNASSIGNED,
    onDrag,
    onClose,
    isShowingDriverName,
    isDraggable = true,
    isCloseable = false,
    className,
    isHiddenStatusTime,
    onChangeNotes,
    isHiddenNotesButton = true,
    notes,
    isDriverLeft,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
    loadsData = [],
    offdaysData,
    renderNoteTooltip,
    startTimesData,
    afterUpdateOffday,
    afterCreateOffday,
  } = props

  const [createTerminalDialogState, setCreateTerminalDialogState] = useState({
    isOpen: false,
    terminal: undefined as ITerminal | undefined,
  })
  const [offdayForm, setOffdayForm] = useState({
    isOpen: false,
    formData: undefined as Partial<IOffday> | undefined,
  })

  const dispatch = useDispatch()

  const {
    driverFleetUsers,
    updateWorker,
    assignTruckFleetToDriverFleet,
    isUsersFetched,
  } = useQueryUsers()

  const { truckFleetsData, currentTruckFleetOptions, updateTruckFleet } =
    useQueryTruckFleets()
  const {
    terminalsData,
    companyTerminalOptions,
    findTerminalById,
    updateTerminal,
  } = useQueryTerminals()
  const { getLoadStatus } = useQueryLoadStatuses()
  const { findCompanyById } = useQueryCompanies({})

  const startTime = (startTimesData || []).find(
    st => st.driverFleetId === driverFleetId,
  )

  const driverFleetLoadIds = driverFleetUsers
    .map(({ driverFleet }) => driverFleet?.loadId)
    .filter(Boolean)

  const { findLoadById } = useQueryLoads(
    {
      filters: {
        id: driverFleetLoadIds as number[],
      },
    },
    {
      enabled:
        isUsersFetched && (loadsData || []).length === 0 && !isHiddenStatusTime,
    },
  )

  const [measureRef, { width }] = useMeasure()

  const smallWidth = useMemo(() => width <= 80, [width])

  const user = useMemo(
    () =>
      driverFleetUsers.find(
        ({ driverFleet }) => driverFleet?.id === driverFleetId,
      ),
    [driverFleetId, driverFleetUsers],
  )

  const driverFleet = useMemo(() => user?.driverFleet, [user?.driverFleet])
  const worker = user?.worker

  const workerTerminal = findTerminalById(worker?.terminalId)

  const currentLoadOfDriverFleet = useMemo(() => {
    if (loadsData?.length > 0) {
      return loadsData.find(({ id }) => driverFleet?.loadId === id)
    }
    return findLoadById(driverFleet?.loadId)
  }, [driverFleet?.loadId, findLoadById, loadsData])

  const offday = useMemo(() => {
    return (offdaysData || []).find(
      ({ workerId }) => workerId === user?.worker?.id,
    )
  }, [offdaysData, user?.worker?.id])

  const hasOffday = useMemo(() => Boolean(offday), [offday])

  const truckFleetId = useMemo(
    () => driverFleet?.currentTruckFleetId,
    [driverFleet?.currentTruckFleetId],
  )

  const truckFleet = useMemo<ITruckFleet | undefined>(
    () => truckFleetsData.find(({ id }) => id === truckFleetId),
    [truckFleetId, truckFleetsData],
  )

  const truckFleetTerminal = useMemo(() => {
    if (truckFleet?.terminalId) {
      return terminalsData.find(({ id }) => id === truckFleet.terminalId)
    }
    return null
  }, [terminalsData, truckFleet?.terminalId])

  const workerTerminalCompany = useMemo(() => {
    if (workerTerminal) {
      return findCompanyById(workerTerminal.companyId)
    }

    return null
  }, [findCompanyById, workerTerminal])

  const [, dragRef] = useDrag<IDriverDragItem>(
    () => ({
      type: dragType as string,
      item: {
        driverFleet: driverFleet as IDriverFleet,
        truckFleet,
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        const dragItem: IDriverCardOnChangeParams = {
          dropResult,
          item,
        }
        onDrag && onDrag(dragItem)
      },
    }),
    [driverFleet, dragType, truckFleet],
  )

  const fullName = useMemo(() => buildFullName(user?.person), [user?.person])

  const renderLoadStatus = useCallback(() => {
    if (currentLoadOfDriverFleet && driverFleet) {
      const start = moment(currentLoadOfDriverFleet.updatedAt)
      const end = moment()
      const duration = moment.duration(end.diff(start))

      let toolTipText = `${currentLoadOfDriverFleet.status} @ ${moment(
        currentLoadOfDriverFleet.updatedAt,
      ).format('H:mm')}`

      const status = getLoadStatus(currentLoadOfDriverFleet.status)
      return (
        <ToolTipOverlay content={toolTipText} placement='top'>
          <h4 className='align-self-center mb-0 mx-2 statusBadge'>
            <IonBadge
              className='d-flex align-items-center shimmer-div'
              color={status?.color}
            >
              <IonIcon icon={(IonIcons as any)[status?.icon || '']} />
              &nbsp;{parseInt(duration.asMinutes().toString()) + 'm'}
            </IonBadge>
          </h4>
        </ToolTipOverlay>
      )
    }

    return null
  }, [currentLoadOfDriverFleet, driverFleet, getLoadStatus])

  const onChangeTerminal = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: any,
      { value }: IConcordFormOnChangeParams,
    ) => {
      try {
        await apiClient.workers.update(driverFleet?.worker.id as number, {
          terminalId: value as number,
        })
        updateWorker(user?.worker?.id as number, {
          ...user?.worker,
          terminalId: value as number,
        })
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [driverFleet, updateWorker, user?.worker],
  )

  const onSaveNotes = useCallback(
    (notes: string) => {
      if (driverFleet) {
        onChangeNotes && onChangeNotes(driverFleet, notes)
      }
    },
    [driverFleet, onChangeNotes],
  )

  const renderWorkerTerminal = useMemo(() => {
    return (
      <DropdownWithCustomChildren
        noHover
        options={companyTerminalOptions}
        onChange={onChangeTerminal}
        className='DriverCard__terminalCreateButton'
        components={{
          Option: CustomTerminalOption,
        }}
        value={worker?.terminalId}
        showUpdateButtons
        renderForm={({ isOpen, optionSelected, onCloseForm }) => (
          <DialogTerminalForm
            formData={optionSelected?.item}
            isOpen={isOpen}
            onClose={onCloseForm}
            afterUpdate={(item: ITerminal) => {
              updateTerminal(item.id, item)
              onCloseForm()
            }}
          />
        )}
        getOptionLabel={opt => {
          const company = findCompanyById(opt.companyId)
          const labels = [company?.name, company?.code, opt.label]
            .filter(Boolean)
            .join(' - ')
          return labels
        }}
      >
        <ToolTipOverlay
          content={
            workerTerminal
              ? `Worker primary workplace: ${buildObjectName(workerTerminal)}`
              : "Add worker's terminal"
          }
          placement='top'
          allowToShow={Boolean(workerTerminal)}
        >
          <IonChip
            className='terminalAvatarContainer'
            style={{
              background:
                workerTerminal?.color ||
                colord('#1090ff').lighten(0.2).toRgbString(),
              color: 'white',
            }}
          >
            {workerTerminal ? (
              <>
                <div className='terminalAvatar'>
                  <CompanyAvatar
                    width={22}
                    height={22}
                    company={{
                      value: workerTerminal?.id as number,
                      label: workerTerminalCompany?.name as string,
                    }}
                    companyType={EScope.seller}
                  />
                </div>
                <IonText>{workerTerminal?.code}</IonText>
              </>
            ) : (
              <ToolTipOverlay content="Add worker's terminal" placement='top'>
                <span>
                  <MarkerPlusIcon color='var(--bs-danger)' size={18} />
                </span>
              </ToolTipOverlay>
            )}
          </IonChip>
        </ToolTipOverlay>
      </DropdownWithCustomChildren>
    )
  }, [
    companyTerminalOptions,
    findCompanyById,
    onChangeTerminal,
    updateTerminal,
    workerTerminal,
    workerTerminalCompany?.name,
    worker?.terminalId,
  ])

  const onClickCloseIcon = useCallback(() => {
    onClose &&
      onClose({
        driver: driverFleet?.driver as IDriver,
        driverFleet: driverFleet as IDriverFleet,
        truckFleet,
      })
  }, [driverFleet, onClose, truckFleet])

  const onChangeTruckFleetTerminal = useCallback(
    async (event: any, { value }: any) => {
      try {
        const res = await apiClient.truckFleets.update(
          truckFleet?.id as number,
          {
            truckFleet: {
              terminalId: value,
            },
          },
        )
        updateTruckFleet(res.id, res)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateSuccess)
      }
    },
    [truckFleet?.id, updateTruckFleet],
  )

  const onRightClickTerminal = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      setCreateTerminalDialogState({
        isOpen: true,
        terminal: truckFleetTerminal as ITerminal,
      })
    },
    [truckFleetTerminal],
  )

  const onCloseTerminalDialog = useCallback(() => {
    setCreateTerminalDialogState({
      isOpen: false,
      terminal: undefined,
    })
  }, [])

  const onChangeTruckFleet = useCallback(
    (event: any, { value }: any) => {
      apiClient.driverFleets.update(driverFleet?.id as number, {
        currentTruckFleetId: value,
      })
      assignTruckFleetToDriverFleet(value, driverFleet?.id as any)
      dispatch(
        driverFleetsSlice.actions.update({
          id: driverFleet?.id,
          currentTruckFleetId: value,
        }),
      )
      toast.success(toastMessages.updateSuccess)
    },
    [assignTruckFleetToDriverFleet, dispatch, driverFleet?.id],
  )

  const renderTruck = useCallback(() => {
    return (
      <IonChip
        color='fleet'
        style={{ background: truckFleet?.color || 'var(--ion-color-fleet)' }}
      >
        {truckFleet && (
          <DropdownWithCustomChildren
            className='no-hover DriverCard__truckSelector'
            options={companyTerminalOptions}
            value={truckFleetTerminal?.id}
            onChange={onChangeTruckFleetTerminal}
            showUpdateButtons
            renderForm={({ isOpen, optionSelected, onCloseForm }) => (
              <DialogTerminalForm
                formData={optionSelected?.item}
                isOpen={isOpen}
                onClose={onCloseForm}
                afterUpdate={(item: ITerminal) => {
                  updateTerminal(item.id, item)
                  onCloseForm()
                }}
              />
            )}
            isReadOnly={!truckFleet}
            components={{
              Option: CustomTerminalOption,
            }}
            getOptionLabel={opt => {
              const company = findCompanyById(opt.companyId)
              const labels = [company?.name, company?.code, opt.label]
                .filter(Boolean)
                .join(' - ')
              return labels
            }}
          >
            <CompanyAvatar
              company={{
                value: truckFleetTerminal?.id as number,
                label: truckFleetTerminal?.name || '-',
              }}
              className='DriverCard__truckTerminalAvatar'
              color={truckFleetTerminal?.color || 'black'}
              height={18}
              width={18}
              onContextMenu={onRightClickTerminal}
              tooltipMessage={
                truckFleetTerminal?.id
                  ? 'Next pickup terminal: ' + truckFleetTerminal?.name
                  : 'Set next pickup terminal'
              }
            />
          </DropdownWithCustomChildren>
        )}

        <DropdownWithCustomChildren
          className='no-hover DriverCard__truckSelector'
          options={currentTruckFleetOptions}
          value={truckFleet?.id}
          onChange={onChangeTruckFleet}
        >
          <span
            style={{
              marginLeft: truckFleet ? 4 : 0,
              color: truckFleet?.color
                ? colord(truckFleet.color).isDark()
                  ? 'white'
                  : 'black'
                : '#e5e5e5',
            }}
          >
            {truckFleet ? (
              <TruckNumberSection truckId={truckFleet?.truckId} />
            ) : (
              <ToolTipOverlay
                placement='top'
                content='Click to assign a truck to this driver'
              >
                <span>
                  <PlusIcon size={22} color='white' />
                </span>
              </ToolTipOverlay>
            )}
          </span>
        </DropdownWithCustomChildren>
      </IonChip>
    )
  }, [
    truckFleet,
    companyTerminalOptions,
    truckFleetTerminal?.id,
    truckFleetTerminal?.name,
    truckFleetTerminal?.color,
    onChangeTruckFleetTerminal,
    onRightClickTerminal,
    currentTruckFleetOptions,
    onChangeTruckFleet,
    updateTerminal,
    findCompanyById,
  ])

  const onShowOffdayForm = (event: any) => {
    event.stopPropagation()
    setOffdayForm({
      isOpen: true,
      formData: {
        workerId: worker?.id,
      },
    })
  }

  const onCloseOffdayForm = () => {
    setOffdayForm({
      isOpen: false,
      formData: undefined,
    })
  }

  return {
    renderLoadStatus,
    renderTruck,
    driverFleet,
    createTerminalDialogState,
    onCloseTerminalDialog,
    dragRef,
    fullName,
    onClickCloseIcon,
    driverFleetId,
    isShowingDriverName,
    isDraggable,
    isCloseable,
    workerTerminal,
    workerTerminalCompany,
    className,
    isHiddenStatusTime,
    measureRef,
    width,
    smallWidth,
    renderWorkerTerminal,
    onSaveNotes,
    isHiddenNotesButton,
    notes,
    isDriverLeft,
    hasOffday,
    offday,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
    renderNoteTooltip,
    startTime,
    onShowOffdayForm,
    offdayForm,
    onCloseOffdayForm,
    afterUpdateOffday,
    afterCreateOffday,
  }
}

export default useDriverCard
