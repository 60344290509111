var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useState } from 'react';
import { Box, makeStyles, Divider } from '@material-ui/core';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { FieldsPanelDisplayRow } from 'documentLabeler/components/fieldsPanel/fieldsPanelDisplayRow/FieldsPanelDisplayRow';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { FieldsPanelUtils } from 'documentLabeler/components/fieldsPanel/util/FieldsPanelUtils';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { FieldsPanelDisplayUtils } from 'documentLabeler/common/util/FieldsPanelDisplayUtils';
import { FieldsPanelHeader } from 'documentLabeler/components/fieldsPanel/fieldsPanelHeader/FieldsPanelHeader';
import clsx from 'clsx';
import { ID_DOCUMENT_LABELER_CONTENT_ROOT, ID_FIELDS_PANEL_HEADER_ROOT, } from 'documentLabeler/constants/DocumentLabelerConstants';
var DIVIDER_HEIGHT = 1;
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: theme.spacing(45),
        backgroundColor: theme.palette.common.white,
        '& > *': {
            width: '100%',
            boxSizing: 'border-box',
        },
    },
    FieldsContainer: {
        overflowY: 'auto',
        flex: 1,
        maxHeight: function (_a) {
            var heightOfFieldsPanel = _a.heightOfFieldsPanel;
            return heightOfFieldsPanel || 'auto';
        },
    },
}); });
/**
 * Component responsible for rendering and managing the Fields
 */
export var FieldsPanel = function () {
    var state = useDocumentLabeler().state;
    var _a = useState(null), heightOfFieldsPanel = _a[0], setHeightOfFieldsPanel = _a[1];
    var classes = useStyles({
        heightOfFieldsPanel: heightOfFieldsPanel,
    });
    var _b = DocumentLabelerReducerUtils.getAllColoredFields(state.docInfo), fields = _b.fields, tables = _b.tables;
    useLayoutEffect(function () {
        var elDocumentLabelerContent = document.getElementById(ID_DOCUMENT_LABELER_CONTENT_ROOT);
        var elFieldsPanelHeader = document.getElementById(ID_FIELDS_PANEL_HEADER_ROOT);
        if (elDocumentLabelerContent && elFieldsPanelHeader) {
            var calcHeight = (elDocumentLabelerContent === null || elDocumentLabelerContent === void 0 ? void 0 : elDocumentLabelerContent.offsetHeight) -
                ((elFieldsPanelHeader === null || elFieldsPanelHeader === void 0 ? void 0 : elFieldsPanelHeader.offsetHeight) + DIVIDER_HEIGHT);
            setHeightOfFieldsPanel(calcHeight);
        }
    }, []);
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(FieldsPanelHeader, {}), _jsx(Divider, {}), _jsxs(Box, __assign({ className: clsx(classes.FieldsContainer, 'FieldsPanel__fieldsContainer') }, { children: [fields.map(function (field) { return (_jsxs(Box, { children: [_jsx(FieldsPanelDisplayRow, __assign({}, field.info, { value: FieldsPanelDisplayUtils.getTextValueFromField(field.info), hasValue: FieldsPanelUtils.fieldHasLabeledValue(field.info), color: field.color })), _jsx(Divider, {})] }, field.info.id)); }), tables.map(function (table) { return (_jsxs(Box, { children: [_jsx(FieldsPanelDisplayRow, __assign({}, table.info, { type: FieldType.Table, value: FieldsPanelDisplayUtils.getTextValueFromTable(table.info), hasValue: FieldsPanelUtils.tableHasLabeledValue(table.info), color: table.color })), _jsx(Divider, {})] }, table.info.id)); })] }))] })));
};
