import { useContext } from 'react'

import ShowingPdfViewerContext from './ShowingPdfViewerContext'

const useShowingPdfViewer = () => {
  const context = useContext(ShowingPdfViewerContext)

  return context
}

export default useShowingPdfViewer
