import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import ReferenceNameForm from './ReferenceNameForm'

import type { IDialogReferenceNameFormProps } from './type'

function DialogReferenceNameForm(props: IDialogReferenceNameFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Reference Name'
      isHiddenOkButton
      onClose={onClose}
    >
      <ReferenceNameForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogReferenceNameForm
