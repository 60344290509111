import type { ReactNode } from 'react'
import {
  createRow as _createRow,
  flexRender as _flexRender,
  Renderable,
} from '@tanstack/react-table'
import type {
  RTColumnHelper,
  RTDisplayColumnDef,
  RTGroupColumnDef,
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../types'
import { getAllLeafColumnDefs, getColumnId } from './column.utils'

export const flexRender = _flexRender as (
  Comp: Renderable<any>,
  props: any,
) => JSX.Element | ReactNode

export function createRTColumnHelper<
  TData extends IReusableTableRowData,
>(): RTColumnHelper<TData> {
  return {
    accessor: (accessor, column) => {
      return typeof accessor === 'function'
        ? ({
            ...column,
            accessorFn: accessor,
          } as any)
        : {
            ...column,
            accessorKey: accessor,
          }
    },
    display: column => column as RTDisplayColumnDef<TData>,
    group: column => column as RTGroupColumnDef<TData>,
  }
}

export const createRow = <TData extends IReusableTableRowData>(
  table: ReusableTableInstance<TData>,
  originalRow?: TData,
  rowIndex = -1,
  depth = 0,
  subRows?: RTRow<TData>[],
  parentId?: string,
): RTRow<TData> =>
  _createRow(
    table as any,
    'mrt-row-create',
    originalRow ??
      Object.assign(
        {},
        ...getAllLeafColumnDefs(table.options.columns).map(col => ({
          [getColumnId(col)]: '',
        })),
      ),
    rowIndex,
    depth,
    subRows as any,
    parentId,
  ) as RTRow<TData>
