import { useQueryShifts } from '~/hooks/useQueryData/useQueryShifts'
import useShiftsTableColumns from './useShiftsTableColumns'
import { useWindowSize } from 'react-use'
import {
  useQueryTerminals,
  useQueryUsers,
  useQueryWorkPayTypes,
} from '~/hooks/useQueryData'

import { ReusableTable } from '~/components/shared'
import { useInitializeDrivers } from '~/hooks/useInitializeRedux/stores'
import { moduleName } from '~/utils/constants'

const ShiftsSubTab = () => {
  useInitializeDrivers()

  const { shiftsData, isLoadingShifts } = useQueryShifts()
  const { isLoadingUsersData } = useQueryUsers()
  const { isLoadingTerminals } = useQueryTerminals()
  const { isLoadingWorkPayTypes } = useQueryWorkPayTypes()

  const isLoadingTable =
    isLoadingShifts ||
    isLoadingUsersData ||
    isLoadingTerminals ||
    isLoadingWorkPayTypes

  const windowSize = useWindowSize()

  const { columns } = useShiftsTableColumns()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableCompanyView
          enableGrouping
          enableColumnPinning
          enableExpandAll
          initialState={{
            columnPinning: {
              left: ['driverFleetId'],
            },
            grouping: ['driverFleetId'],
          }}
          companyViewProps={{
            name: moduleName.company.shifts,
            description: 'Manage your company shifts.',
            isHiddenTopButton: true,
          }}
          data={shiftsData}
          columns={columns}
          state={{
            isLoading: isLoadingTable,
          }}
          tableHeight={windowSize.height - 220}
        />
      </div>
    </>
  )
}

export default ShiftsSubTab
