import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const LocationIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='-4 0 32 32'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 8a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-17C5.373 0 0 5.373 0 12c0 5.018 10.005 20.011 12 20 1.964.011 12-15.05 12-20 0-6.627-5.373-12-12-12Z'
      />
    </svg>
  )
}

export default LocationIcon
