import { useCallback } from 'react'

import { ConcordFormLayout } from '~/components/shared'
import { Form } from 'react-bootstrap'

import clsx from 'clsx'

import './styles.scss'

function ConcordFormTextField(props) {
  const {
    onChange,
    value,
    label,
    isDisabled,
    loading,
    className,
    error,
    isRequired,
    extraIcons,
    hint,
    isReadOnly,
    ...textFieldProps
  } = props

  const handleChange = useCallback(
    event => {
      const { value, name } = event.target
      onChange && onChange(event, { value, name })
    },
    [onChange],
  )

  return (
    <ConcordFormLayout
      label={label}
      className={clsx('ConcordFormTextField__container', className, {
        hasError: error,
        isReadOnly,
      })}
      error={error}
      isRequired={isRequired}
      extraIcons={extraIcons}
      hint={hint}
    >
      <Form.Control
        value={value}
        disabled={loading}
        onChange={handleChange}
        error={error}
        required={isRequired}
        className={clsx({ isDisabled })}
        {...textFieldProps}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

export default ConcordFormTextField
