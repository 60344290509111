import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IContestFormProps } from './type'
import type { IContestFormValues } from '~/types/models/IContest'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import moment from 'moment'

const ContestForm = (props: IContestFormProps) => {
  const { afterCreate, afterUpdate, formData, campaignId, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Date',
        name: 'dateRange',
        isRequired: true,
        type: EFieldType.dateRange,
      },
      {
        label: 'Reward At',
        name: 'rewardAt',
        isRequired: true,
        type: EFieldType.date,
      },
    ],
    [],
  )

  const defaultValues = useMemo<IContestFormValues>(
    () => ({
      dateRange: {
        startDate: new Date(),
        endDate: moment().add({ days: 1 }).toDate(),
      },
      rewardAt: null,
      campaignId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        campaignId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        rewardAt: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IContestFormValues) => {
      const payload = _.pick(formValues, [
        'campaignId',
        'rewardAt',
        'startDate',
        'endDate',
      ])

      payload.startDate = moment(formValues.dateRange?.startDate || '').format(
        'YYYY-MM-DD',
      )
      payload.endDate = moment(formValues.dateRange?.endDate || '').format(
        'YYYY-MM-DD',
      )
      const { errors, ...response } = await apiClient.contests.create({
        contest: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IContestFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'campaignId',
          'rewardAt',
          'startDate',
          'endDate',
        ])
        payload.startDate = moment(
          formValues.dateRange?.startDate || '',
        ).format('YYYY-MM-DD')
        payload.endDate = moment(formValues.dateRange?.endDate || '').format(
          'YYYY-MM-DD',
        )

        const { errors, ...response } = await apiClient.contests.update(
          formValues.id,
          {
            contest: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IContestFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating && campaignId) {
      formRef.current?.setValue('campaignId', campaignId)
    }
  }, [campaignId, isUpdating])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default ContestForm
