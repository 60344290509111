import { updateAppHeader } from '~/redux/actions/appActions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
/**
 *
 * @param {func} renderContent is a required function,
 * it should return a header setting and be triggered to update the header immediately.
 * So this function should be used with useCallback and added all dependencies
 * The header setting can be all components/common/Header props
 */

const HeaderContent = ({ renderContent }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Update header
    renderContent && dispatch(updateAppHeader(renderContent()))
  }, [dispatch, renderContent])

  useEffect(() => {
    // render header when open page
    renderContent && dispatch(updateAppHeader(renderContent()))

    return () => {
      // clean header when move to another page
      dispatch(updateAppHeader(undefined))
    }
  }, [dispatch, renderContent])

  return null
}

export default HeaderContent
