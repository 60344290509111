import { createSlice } from '@reduxjs/toolkit'
import defaultModuleFilters from '~/constants/defaultModuleFilters'

export const accountsReceivableUISlice = createSlice({
  name: 'accountsReceivable',
  initialState: {
    filters: defaultModuleFilters.invoices,
    asOfDate: null,
    expandedRow: null,
  },
  reducers: {
    update: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.filters[key] = value
      })
    },
  },
})
