import { useMemo } from 'react'
import { IRTColumnDef } from '~/components/shared'
import { useQueryProductTypes } from '~/hooks/useQueryData'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { EProductPrimary } from '~/types/enums/EProduct'
import { IProductType } from '~/types/models/IProductType'

const useTableCompanyEmailParserSubTab = () => {
  const { productTypeOptions } = useQueryProductTypes()

  const columns = useMemo<IRTColumnDef<IProductType>[]>(
    () => [
      {
        header: 'Product Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Product Name',
        accessorKey: 'name',
        enableEditing: true,
      },
      {
        header: 'Primary',
        accessorKey: 'primary',
        size: 120,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: Object.keys(EProductPrimary).map(field => ({
          value: field,
          label: field,
        })),
      },
      {
        header: 'Description',
        accessorKey: 'description',
        size: 300,
        enableEditing: true,
      },
      {
        header: 'Product Type',
        accessorKey: 'productTypeId',
        size: 300,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: productTypeOptions,
      },
    ],
    [productTypeOptions],
  )

  return { columns }
}

export default useTableCompanyEmailParserSubTab
