import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const workersSlice = createSlice({
  name: 'workers',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(worker => {
        newState[worker.id] = worker
      })
      return newState
    },
    add: (state, action) => {
      state[action.payload.id] = action.payload
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export const fetchWorkers = createAsyncThunk(
  'workers/fetchWorkers',
  async (params = {}, { dispatch }) => {
    try {
      const { workers } = await apiClient.workers.get(params)
      dispatch(workersSlice.actions.index(workers))
      return workers
    } catch (error) {
      console.log(error)
    }
  },
)

export const updateWorker = createAsyncThunk(
  'workers/updateWorker',
  async (worker, { dispatch }) => {
    try {
      const updatedWorker = await apiClient.workers.update(worker.id, worker)
      dispatch(workersSlice.actions.update(updatedWorker))
      return updatedWorker
    } catch (error) {
      console.log(error)
    }
  },
)
