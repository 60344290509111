import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_SELLER_COLUMNS } from '~/constants/companySubTabs'

const sellersSlice = createSlice({
  name: moduleName.company.sellers,
  initialState: {
    columns: DEFAULT_COMPANY_SELLER_COLUMNS,
  },
})

export default sellersSlice
