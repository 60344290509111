import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IBillLine, IGetBillLinesParams } from '~/types/models/IBillLine'
import useModifyBillLines from './useModifyBillLines'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBillLines = (
  params: Partial<IGetBillLinesParams & { id?: number }>,
  options?: Partial<UseQueryOptions<IBillLine[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'billLines',
      sessionUser?.id,
      buildGetUrl(apiClient.billLines.endpoint, params),
    ],
    async queryFn() {
      if (typeof params?.id === 'number') {
        const response = await apiClient.billLines.getById(params.id)
        return [response]
      }
      const response = await apiClient.billLines.get(params)
      return response.billLines
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addBillLine, updateBillLine, removeBillLine } =
    useModifyBillLines(params)

  const billLinesData = useMemo(() => data || [], [data])

  const billLineOptions = useMemo(
    () =>
      billLinesData.map(billLine => {
        let label = billLine.ticketNum
          ? `#${billLine.ticketNum} - ${billLine.lineDate}`
          : billLine.lineDate

        return {
          value: billLine.id,
          label: label,
        }
      }),
    [billLinesData],
  )

  return {
    billLinesData,
    billLineOptions,
    isLoadingBillLinesData: isLoading,
    isBillLinesFetched: isFetched,
    refetchQueryBillLines: refetch,
    addBillLine,
    updateBillLine,
    removeBillLine,
  }
}

export default useQueryBillLines
