import { useMemo, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { IonText } from '@ionic/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { selectCompanyTimeZone } from '~/redux/selectors'
import {
  formatDateFromISO,
  DATE_TIME_FORMAT_TOOLTIP,
} from '~/utils/convertDate'
import clsx from 'clsx'

import './ParserDataTooltipContent.scss'

const ItemComponent = props => {
  const { label, value, className } = props

  const [copied, setCopied] = useState(false)

  const onCopy = useCallback(() => {
    setCopied(true)
  }, [])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  return (
    <div className={clsx('ParserDataTooltipContent__item', className)}>
      <strong>{label}</strong>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <span className='ParserDataTooltipContent__itemValue'>{value}</span>
      </CopyToClipboard>
      {copied && (
        <IonText style={{ marginLeft: 6, fontWeight: 600 }} color='success'>
          Copied
        </IonText>
      )}
    </div>
  )
}

function ParserDataTooltipContent(props) {
  const { data, idKeyLabel, flags = [] } = props

  const flagsData = useMemo(() => data.flags || flags || [], [data.flags, flags])

  const companyTimezone = useSelector(selectCompanyTimeZone)

  const lastUpdatedAt = formatDateFromISO(
    data?.updatedAt,
    DATE_TIME_FORMAT_TOOLTIP,
    companyTimezone,
  )

  const createdAt = formatDateFromISO(
    data?.createdAt,
    DATE_TIME_FORMAT_TOOLTIP,
    companyTimezone,
  )

  const keyValuePair = useMemo(
    () => [
      {
        label: 'Created At',
        value: createdAt,
      },
      {
        label: 'Last Updated At',
        value: lastUpdatedAt,
      },
      {
        label: idKeyLabel,
        value: data?.id,
      },
      {
        label: 'Document ID',
        value: data?.documentId,
      },
      {
        label: 'Parser',
        value: data?.parserName,
      },
      {
        label: 'Model',
        value: data?.modelId,
      },
      {
        label: 'Collection',
        value: data?.collectionName,
      },
      {
        label: 'File ID',
        value: data?.uid,
      },
      {
        label: 'Error Message',
        value: data.ocrErrors?.message,
        hide: !data.ocrErrors?.message,
        className: 'color-red',
      },
      {
        render() {
          return (
            flagsData.length > 0 && (
              <div
                style={{ color: 'red' }}
                className='ParserDataTooltipContent__item'
              >
                <strong>Flags: </strong>
                {flagsData.length}
                <ul>
                  {flagsData.map((flag, index) => (
                    <li key={index}>{flag.message}</li>
                  ))}
                </ul>
              </div>
            )
          )
        },
      },
    ],
    [
      createdAt,
      data?.collectionName,
      data?.documentId,
      data?.id,
      data?.modelId,
      data.ocrErrors?.message,
      data?.parserName,
      data?.uid,
      idKeyLabel,
      lastUpdatedAt,
      flagsData
    ],
  )

  return (
    <div className='ParserDataTooltipContent__root'>
      {keyValuePair
        .filter(({ hide }) => hide !== true)
        .map(({ render, ...itemProps }) => {
          if (render) {
            return render()
          }
          return (
            <ItemComponent
              {...itemProps}
              key={itemProps.label}
              label={`${itemProps.label}: `}
            />
          )
        })}
    </div>
  )
}

ParserDataTooltipContent.defaultProps = {
  idKeyLabel: 'Load ID',
}

export default ParserDataTooltipContent
