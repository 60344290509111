import React from 'react'
import { IonLabel, IonChip, IonIcon } from '@ionic/react'
import { statusColor, statusIcon } from '~/utils/utils'
// import { STATUS_CODES } from 'http'
import { ToolTipOverlay } from '~/components/shared'
import moment from 'moment'

const StatusCol = ({ status, description, usedAt }) =>
(
  <>
    <ToolTipOverlay
      placement='top'
      content={
        status === 'Used' && usedAt
          ? moment(usedAt).format('MMM-D H:mm')
          : description
      }
    >
      <IonChip color={statusColor(status)}>
        <IonIcon icon={statusIcon(status)} />
        <IonLabel>{status}</IonLabel>
      </IonChip>
    </ToolTipOverlay>
  </>
)


export default StatusCol
