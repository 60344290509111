import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCheckWorkerInfoSection } from '../CheckWorkerInfoSection'

import { isStartWithEmp } from '~/utils/constants'
import { checkmarkCircle, warningOutline } from 'ionicons/icons'
import { apiClient } from '~/api/ApiClient'

import type { ICheckComponentsForWorkerProps } from './type'
import type { ICheckComponentProps } from '../CheckComponent/type'
import getCheckSectionIconProps from '~/containers/PayrollsContainer/utils/getCheckSectionIconProps'
import { produce } from 'immer'
import { ECheckStatus } from '~/types/enums/ECheck'
import { useSelector } from 'react-redux'
import { selectCurrentScope } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'

const useCheckComponentsForWorker = (props: ICheckComponentsForWorkerProps) => {
  const { workerUid, style } = props

  const [sectionOpenValues, setSectionOpenValues] = useState<string[]>([])
  const [currentTab, setCurrentTab] = useState('onboarding')

  const isEmployee = useMemo(() => isStartWithEmp(workerUid), [workerUid])

  const currentScope: EScope = useSelector(selectCurrentScope)

  const {
    renderCheckWorkerOnboardStatus,
    workerDetailsData,
    refectCheckWorkerDetails,
  } = useCheckWorkerInfoSection({
    checkUid: workerUid,
  })

  const isOnboardingBlocking = useMemo(
    () =>
      workerDetailsData?.onboard?.status &&
      ['blocking', 'needs_attention'].includes(
        workerDetailsData?.onboard?.status,
      ),
    [workerDetailsData?.onboard?.status],
  )

  const onSelectStep = useCallback(
    (key: string) => {
      if (isEmployee) {
        const el = document.getElementById(key)
        setSectionOpenValues(prev =>
          produce(prev, draft => {
            const index = draft.indexOf(key)
            if (!draft[index]) {
              draft.push(key)
            }
          }),
        )
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      } else {
        const el = document.getElementById('onboard')
        setSectionOpenValues(['onboard'])
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    [isEmployee],
  )

  const getEmployeeOrContractor = useCallback(
    (label: string) => {
      if (isEmployee) {
        return `Employee ${label}`
      }

      return `Contractor ${label}`
    },
    [isEmployee],
  )

  const onboardingEmployeeComponents = useMemo<ICheckComponentProps[]>(
    () => [
      {
        name: 'onboard',
        isOpen: sectionOpenValues.includes('onboard'),
        label: getEmployeeOrContractor('Onboard'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        icons: [
          {
            icon: isOnboardingBlocking ? warningOutline : checkmarkCircle,
            color: isOnboardingBlocking ? 'danger' : 'success',
            tooltip: {
              content: isOnboardingBlocking
                ? renderCheckWorkerOnboardStatus
                : '',
              placement: 'top',
            },
          },
        ],
        onEvent(event: string) {
          if (event === 'check-onboard-app-completed') {
            refectCheckWorkerDetails()
          }
        },
        apiMethod: isEmployee
          ? apiClient.check.createWorkerLink
          : apiClient.check.contractorOnboardComponent,
        getApiPayload: () => {
          return {
            worker: {
              checkUid: workerUid,
            },
          }
        },
      },
      {
        name: 'paymentMethod',
        isOpen: sectionOpenValues.includes('paymentMethod'),
        label: getEmployeeOrContractor('Payment Setup'),
        icons: [getCheckSectionIconProps(workerDetailsData, 'payment_method')],
        isOpenComponentOnMounted: true,
        isHidden: !isEmployee,
        checkUid: workerUid,
        apiMethod: apiClient.check.employeePaymentSetupComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
        onEvent(event) {
          if (
            event === 'check-component-employee-payment-setup-already-complete'
          ) {
            refectCheckWorkerDetails()
          }
        },
      },
      {
        name: 'taxDocuments',
        label: getEmployeeOrContractor('Tax Documents'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        apiMethod: isEmployee
          ? apiClient.check.employeeTaxDocumentsComponent
          : apiClient.check.contractorTaxDocumentsComponent,
        getApiPayload: () => {
          return {
            worker: {
              checkUid: workerUid,
            },
          }
        },
      },
      {
        name: 'ssn',
        label: getEmployeeOrContractor('SSN Setup'),
        isOpen: sectionOpenValues.includes('ssn'),
        icons: [getCheckSectionIconProps(workerDetailsData, 'ssn')],
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        isHidden: !isEmployee,
        apiMethod: apiClient.check.employeeSsnComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
        onEvent(event) {
          if (event === 'check-component-employee-ssn-setup-complete') {
            refectCheckWorkerDetails()
          }
        },
      },
      {
        name: 'withholdings',
        label: getEmployeeOrContractor('Withholdings Setup'),
        isOpen: sectionOpenValues.includes('withholdings'),
        icons: [getCheckSectionIconProps(workerDetailsData, 'withholdings')],
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        isHidden: !isEmployee,
        onEvent(event) {
          if (
            event === 'check-component-employee-ssn-setup-complete' ||
            event === 'check-component-employee-withholdings-setup-complete'
          ) {
            refectCheckWorkerDetails()
          }
        },
        apiMethod: apiClient.check.employeeWithholdingsSetupComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
      },
    ],
    [
      getEmployeeOrContractor,
      isEmployee,
      isOnboardingBlocking,
      refectCheckWorkerDetails,
      renderCheckWorkerOnboardStatus,
      sectionOpenValues,
      workerDetailsData,
      workerUid,
    ],
  )

  const ongoingEmployeeComponents = useMemo<ICheckComponentProps[]>(
    () => [
      {
        name: 'paystubs',
        label: getEmployeeOrContractor('Paystubs'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        apiMethod: apiClient.check.employeePaystubs,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
      },
      {
        name: 'profile',
        label: getEmployeeOrContractor('Profile'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        apiMethod: apiClient.check.employeeProfileComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
      },
      {
        name: 'postTaxDeductions',
        label: getEmployeeOrContractor('Post-Tax Deductions'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        apiMethod: apiClient.check.employeePostTaxDeductionsComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
      },
      {
        name: 'benefits',
        label: getEmployeeOrContractor('Benefits'),
        isOpenComponentOnMounted: true,
        checkUid: workerUid,
        apiMethod: apiClient.check.employeeBenefitsComponent,
        getApiPayload: () => ({
          worker: {
            checkUid: workerUid,
          },
        }),
      },
    ],
    [getEmployeeOrContractor, workerUid],
  )

  const onChangeTab = useCallback((newTab: string | number) => {
    setCurrentTab(newTab as string)
  }, [])

  useEffect(() => {
    if (workerDetailsData?.onboard?.status === ECheckStatus.completed) {
      setCurrentTab('ongoing')
    }
  }, [workerDetailsData?.onboard?.status])

  return {
    workerUid,
    isEmployee,
    onboardingEmployeeComponents,
    ongoingEmployeeComponents,
    currentTab,
    onChangeTab,
    onSelectStep,
    currentScope,
    style,
  }
}

export default useCheckComponentsForWorker
