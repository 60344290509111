import type { ISvgIconProps } from './type'

const ArrowLeftAndRightIcon = (props: ISvgIconProps) => {
  const { size = 18, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m9 8-4 3.692L9 16m6-8 4 3.692L15 16'
      />
    </svg>
  )
}
export default ArrowLeftAndRightIcon
