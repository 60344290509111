import { createSelector } from 'reselect'

export const selectTruckState = state => state.truck.get('currentTruck')

export const selectCurrentTruck = createSelector(selectTruckState, state =>
  state.get('currentTruck'),
)

export const selectCurrentTruckNoLoad = createSelector(
  [selectTruckState],

  truck => {
    if (Object.keys(truck).length !== 0) {
      return truck
    }

    return undefined
  },
)
