function convertToMilitaryTime(timeString: string) {
  // Split the time string into hours, minutes, and am/pm indicator
  const [time, period] = timeString.split(' ')
  const [hours, minutes] = time.split(':').map(Number)

  // Convert to military time
  let militaryHours = hours
  if (period === 'pm' && hours < 12) {
    militaryHours += 12
  } else if (period === 'am' && hours === 12) {
    militaryHours = 0
  }

  // Format military time
  const militaryTime = `${String(militaryHours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}`

  return militaryTime
}

export default convertToMilitaryTime
