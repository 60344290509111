export const defaultFilters = {
  buyerId: [],
  sellerTerminalId: [],
  buyerTerminalId: [],
  status: []
}

export const defaultColumns = [
  {
    header: '#',
    accessor: 'index',
    hideable: false,
    hide: false,
  },
  {
    header: 'Created At',
    accessor: 'createdAt',
    hideable: true,
    hide: false,
  },
  {
    header: 'Seller',
    accessor: 'senderCompanyId',
    hideable: true,
    hide: false,
  },
  {
    header: 'Buyer',
    accessor: 'buyerId',
    hideable: true,
    hide: false,
  },
  {
    header: 'Seller Terminal',
    accessor: 'sellerTerminalId',
    hideable: true,
    hide: false,
  },
  {
    header: 'Buyer Terminal',
    accessor: 'buyerTerminalId',
    hideable: true,
    hide: false,
  },
  {
    header: 'Price Escalations',
    accessor: 'priceEscalationId',
    hideable: true,
    hide: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    hideable: true,
    hide: false,
  },
  {
    header: 'Actions',
    accessor: 'actions',
    hideable: false,
    hide: false,
  }
]