import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const orderExtrasSlice = createSlice({
  name: 'orderExtras',
  initialState: {},
  reducers: {
    initialize: (state, action) => {
      action.payload.forEach(orderExtra => (state[orderExtra.id] = orderExtra))
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, { payload }) => {
      delete state[payload.id]
    },
  },
})

export const fetchOrderExtras = createAsyncThunk(
  'fetchOrderExtras',
  async (orderId, { dispatch }) => {
    try {
      const { orderExtras } = await apiClient.orderExtras.get({
        filters: { orderId },
      })
      dispatch(orderExtrasSlice.actions.initialize(orderExtras))
    } catch (error) {
      console.log('error', error)
    }
  },
)
