import { useMemo } from 'react'
import { Body } from '~/components'
import NotFoundPage from '../NotFoundPage'
import { useRouter } from '~/hooks/useRouter'
import { isMobileApp, isMobileWebApp } from '~/utils/getCurrentPlatform'

function NotFoundContainer() {
  const router = useRouter()

  const message = useMemo(() => {
    if (isMobileApp() || isMobileWebApp()) {
      return ''
    }
    const shortcut =
      navigator.userAgent.indexOf('Mac') != -1 ? '⌘ + K' : 'Ctrl + K'

    return `Press ${shortcut} to open the main pages of Concord Materials`
  }, [])

  return (
    <Body>
      <NotFoundPage
        message={
          <div>
            <div>Page not found!</div>
            {message && <div>{message}</div>}
          </div>
        }
        onGoBack={() => {
          router.push('/dispatch_dashboard')
        }}
        buttonText='Go back home'
      />
    </Body>
  )
}

export default NotFoundContainer
