import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDialogCreateEditCompany } from '~/hooks/useDialogCreateEditCompany'

import { Link } from 'react-router-dom'

import { USER_SCOPE } from '~/utils/constants'
import { selectCurrentScope } from '~/redux/selectors'

import type { IUseCompanyInfoProps } from './type'

export const useCompanyInfo = (props: IUseCompanyInfoProps) => {
  const { hideAnchor, unknownText, company } = props

  const currentUserScope = useSelector(selectCurrentScope)
  const isDriver =
    USER_SCOPE.driver === currentUserScope ||
    USER_SCOPE.driver_fleet === currentUserScope

  const { onOpenDialogCreateEditCompany } = useDialogCreateEditCompany()

  const onOpenEditCompany = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault()
      onOpenDialogCreateEditCompany({ companyId: company.value })
    },
    [company.value, onOpenDialogCreateEditCompany],
  )

  const renderAnchor = useCallback(() => {
    if (isDriver && company?.value) {
      return <span>{company?.name || unknownText}</span>
    }

    if (company?.value && !hideAnchor) {
      return (
        <Link to={`/companies/${company?.value}`} onClick={onOpenEditCompany}>
          {company?.name || company?.label || unknownText}
        </Link>
      )
    }

    if (company?.value && hideAnchor) {
      return <span>{company?.name || unknownText}</span>
    }

    return <span>{unknownText}</span>
  }, [
    company?.label,
    company?.name,
    company?.value,
    hideAnchor,
    isDriver,
    onOpenEditCompany,
    unknownText,
  ])

  return {
    renderAnchor,
  }
}
