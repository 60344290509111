import { memo, PropsWithChildren, useMemo } from 'react'
import type { VirtualItem } from '@tanstack/react-virtual'
import { RTTableBodyRow, MemoRTTableBodyRow } from './RTTableBodyRow'
import { useRTRowVirtualizer } from '../../hooks/useRTRowVirtualizer'
import { useRTRows } from '../../hooks/useRTRows'
import type {
  RTColumnVirtualizer,
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'

export interface IRTTableBodyProps<TData extends IReusableTableRowData>
  extends PropsWithChildren {
  columnVirtualizer?: RTColumnVirtualizer
  table: ReusableTableInstance<TData>
}

export const RTTableBody = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  table,
  ...rest
}: IRTTableBodyProps<TData>) => {
  const {
    getBottomRows,
    getIsSomeRowsPinned,
    getRowModel,
    getState,
    getTopRows,
    options: {
      enableStickyFooter,
      enableStickyHeader,
      layoutMode,
      memoMode,
      renderDetailPanel,
      renderEmptyRowsFallback,
      rowPinningDisplayMode,
      tableHeight,
    },
    refs: { tableFooterRef, tableHeadRef, tablePaperRef },
  } = table
  const { isFullScreen, rowPinning } = getState()

  const tableHeadHeight =
    ((enableStickyHeader || isFullScreen) &&
      tableHeadRef.current?.clientHeight) ||
    0
  const tableFooterHeight =
    (enableStickyFooter && tableFooterRef.current?.clientHeight) || 0

  const pinnedRowIds = useMemo(() => {
    if (!rowPinning.bottom?.length && !rowPinning.top?.length) return []
    return getRowModel()
      .rows.filter(row => row.getIsPinned())
      .map(r => r.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPinning, getRowModel().rows])

  const rows = useRTRows(table)

  const rowVirtualizer = useRTRowVirtualizer(table, rows)

  const { virtualRows } = rowVirtualizer ?? {}

  const commonRowProps = {
    columnVirtualizer,
    numRows: rows.length,
    table,
  }

  return (
    <>
      {!rowPinningDisplayMode?.includes('sticky') &&
        getIsSomeRowsPinned('top') && (
          <tbody
            {...rest}
            style={{
              display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
              position: 'sticky',
              top: tableHeadHeight - 1,
              zIndex: 1,
            }}
          >
            {getTopRows().map((row, staticRowIndex) => {
              const props = {
                ...commonRowProps,
                row,
                staticRowIndex,
              }
              return memoMode === 'rows' ? (
                <MemoRTTableBodyRow key={row.id} {...props} />
              ) : (
                <RTTableBodyRow key={row.id} {...props} />
              )
            })}
          </tbody>
        )}
      <tbody
        {...rest}
        style={{
          display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
          height: rowVirtualizer
            ? `${
                rowVirtualizer.getTotalSize() < (tableHeight || 0)
                  ? tableHeight
                  : rowVirtualizer.getTotalSize()
              }px`
            : undefined,
          minHeight: !rows.length ? tableHeight : undefined,
          position: 'relative',
          // ...(parseFromValuesOrFunc(tableBodyProps?.sx, theme) as any),
        }}
      >
        {rest?.children ??
          (!rows.length ? (
            <tr
              style={{
                display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
              }}
            >
              <td
                colSpan={table.getVisibleLeafColumns().length}
                style={{
                  display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
                }}
              >
                {renderEmptyRowsFallback?.({ table }) ?? (
                  <div
                    style={{
                      // color: "text.secondary",
                      color: 'black',
                      fontStyle: 'italic',
                      maxWidth: `min(100vw, ${
                        tablePaperRef.current?.clientWidth ?? 360
                      }px)`,
                      padding: '0 2rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{ fontSize: 18, marginTop: 12, fontWeight: 600 }}
                    >
                      No Results Found
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ) : (
            <>
              {(virtualRows ?? rows).map((rowOrVirtualRow, staticRowIndex) => {
                let row = rowOrVirtualRow as RTRow<TData>
                if (rowVirtualizer) {
                  if (renderDetailPanel) {
                    if (rowOrVirtualRow.index % 2 === 1) {
                      return null
                    } else {
                      staticRowIndex = rowOrVirtualRow.index / 2
                    }
                  } else {
                    staticRowIndex = rowOrVirtualRow.index
                  }
                  row = rows[staticRowIndex]
                }
                const props = {
                  ...commonRowProps,
                  pinnedRowIds,
                  row,
                  rowVirtualizer,
                  staticRowIndex,
                  virtualRow: rowVirtualizer
                    ? (rowOrVirtualRow as VirtualItem<any>)
                    : undefined,
                }
                const key = `${row.id}-${row.index}`
                return memoMode === 'rows' ? (
                  <MemoRTTableBodyRow key={key} {...props} />
                ) : (
                  <RTTableBodyRow key={key} {...props} />
                )
              })}
            </>
          ))}
      </tbody>
      {!rowPinningDisplayMode?.includes('sticky') &&
        getIsSomeRowsPinned('bottom') && (
          <tbody
            {...rest}
            style={{
              bottom: tableFooterHeight - 1,
              display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
              position: 'sticky',
              zIndex: 1,
              // ...(parseFromValuesOrFunc(tableBodyProps?.sx, theme) as any),
            }}
          >
            {getBottomRows().map((row, staticRowIndex) => {
              const props = {
                ...commonRowProps,
                row,
                staticRowIndex,
              }
              return memoMode === 'rows' ? (
                <MemoRTTableBodyRow key={row.id} {...props} />
              ) : (
                <RTTableBodyRow key={row.id} {...props} />
              )
            })}
          </tbody>
        )}
    </>
  )
}

export const MemoRTTableBody = memo(
  RTTableBody,
  (prev, next) => prev.table.options.data === next.table.options.data,
) as typeof RTTableBody
