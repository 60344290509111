import { useRef } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { DEFAULT_MAP_OPTIONS } from '~/constants/constants'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'

import './GoogleMapView.scss'

const GoogleMapView = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_G_API_URL,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className='GoogleMapView__containerElement' />,
    mapElement: (
      <div
        className='mapElementContainer'
        style={{ height: 'calc(100% - 58px)' }}
      />
    ),
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  const {
    defaultCenter,
    options = DEFAULT_MAP_OPTIONS,
    children,
    bounding = [], //latLngLiteral > latLng to prevent first render crash
    center,
    ...googleProps
  } = props

  const mapRef = useRef()

  useDeepCompareEffect(() => {
    if (bounding.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds()
      bounding.forEach(bound => {
        bounds.extend(bound)
      })
      mapRef.current.fitBounds(bounds)
    } else {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.extend(center || defaultCenter)
      mapRef.current.fitBounds(bounds)
      // mapRef.current.setState({
      //   center: center || defaultCenter,
      // })
    }
  }, [bounding, center, defaultCenter])

  return (
    <GoogleMap defaultZoom={10} options={options} ref={mapRef} {...googleProps}>
      {children}
    </GoogleMap>
  )
})

export default GoogleMapView
