import { useMemo, useCallback } from 'react'

import { View, Text } from '@react-pdf/renderer'
import { PDFCompanyInfoWithTerminal } from '~/components/shared'

import moment from 'moment-timezone'

function PDFInvoiceHeader(props) {
  const {
    seller,
    buyer,
    sellerTerminal,
    buyerTerminal,
    rawSellerTerminal,
    rawBuyerTerminal,
    totalQty,
    totalPrice,
    startDate,
    endDate,
    dueDate,
  } = props

  const formatDate = useCallback(date => {
    if (date) {
      return moment(date).format('M-D-YY')
    }

    return ''
  }, [])

  const sumaryInfo = useMemo(
    () => [
      {
        label: 'Quantity',
        value: totalQty,
      },
      {
        label: 'Total Price',
        value: totalPrice,
      },
      {
        label: 'Date range',
        value: `${formatDate(startDate)} to ${formatDate(endDate)}`,
      },
      {
        label: 'Due date',
        value: `${formatDate(dueDate)}`,
      },
    ],
    [dueDate, endDate, formatDate, startDate, totalPrice, totalQty],
  )

  return (
    <View
      style={{
        marginTop: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <PDFCompanyInfoWithTerminal
        company={seller}
        type='seller'
        title='Seller'
        terminal={sellerTerminal}
        rawTerminal={rawSellerTerminal}
      />

      <PDFCompanyInfoWithTerminal
        company={buyer}
        type='buyer'
        title='Buyer'
        terminal={buyerTerminal}
        rawTerminal={rawBuyerTerminal}
      />

      <View>
        {sumaryInfo.map(({ label, value }) => (
          <View
            key={label}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 6,
            }}
          >
            <View style={{ width: 100, textAlign: 'right', marginRight: 4 }}>
              <Text>{label}:</Text>
            </View>
            <View style={{ minWidth: 120 }}>
              <Text>{value}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

PDFInvoiceHeader.propTypes = {}

export default PDFInvoiceHeader
