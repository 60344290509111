import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyTags } from '~/redux/actions/CommonData/tagsActions'
import { selectTagsLastFetchedAt } from '~/redux/selectors'
import { selectCompanyId } from '~/redux/selectors/selectSessionUser'

export const useInitializeCompanyTags = () => {
  const companyId = useSelector(selectCompanyId)
  const lastUpdated = useSelector(selectTagsLastFetchedAt)
  const dispatch = useDispatch()

  useEffect(() => {
    if (companyId && !lastUpdated) {
      dispatch(getCompanyTags(companyId))
    }
  }, [companyId, lastUpdated, dispatch])
}
