import { createSlice } from '@reduxjs/toolkit'

export const name = 'workPayTypes'

export const initialState = {
  loadingTable: false,
}

const workPayTypeUiSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
  },
})

export default workPayTypeUiSlice
