import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const NotificationIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        d='M3 5a5 5 0 0 1 10 0v3l2 2v2H1v-2l2-2V5ZM8 16a3.001 3.001 0 0 1-2.83-2h5.66A3.001 3.001 0 0 1 8 16Z'
      />
    </svg>
  )
}
export default NotificationIcon
