import { createSlice } from '@reduxjs/toolkit'
import { FONT_SIZES } from '~/utils/constants'

const chatUiSlice = createSlice({
  name: 'chats',
  initialState: {
    newMessage: null,
    unreadCount: 0,
    fontSize: FONT_SIZES.MEDIUM,
    init: true,
  },
  reducers: {
    updateNewMessage: (state, { payload }) => {
      state.newMessage = payload
      state.init = false
    },
    updateUnreadCount: (state, { payload }) => {
      const currentUnread = state.unreadCount < 0 ? 0 : state.unreadCount
      const totalUnread = currentUnread + payload
      state.unreadCount = totalUnread < 0 ? 0 : totalUnread
      state.init = false
    },
    setUnreadCount: (state, { payload }) => {
      state.unreadCount = payload
      state.init = false
    },
    updateFontSize: (state, { payload }) => {
      state.fontSize = payload
    },
  },
})

export default chatUiSlice
