const isLocationInsideGeofence = (
  location: google.maps.LatLng,
  polyPath: google.maps.LatLngLiteral[],
) => {
  if (polyPath.length === 0) {
    return true
  }
  const marker = new google.maps.Marker({
    position: location,
  })
  const poly = new google.maps.Polygon({ paths: polyPath })
  return google.maps.geometry.poly.containsLocation(
    marker.getPosition() as google.maps.LatLng,
    poly,
  )
}

export default isLocationInsideGeofence
