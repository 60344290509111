export const RTDefaultIcons = {
  ArrowDownwardIcon: () => <span>ArrowDownwardIcon</span>,
  ArrowRightIcon: () => <span>ArrowDownwardIcon</span>,
  CancelIcon: () => <span>ArrowDownwardIcon</span>,
  ChevronLeftIcon: () => <span>ArrowDownwardIcon</span>,
  ChevronRightIcon: () => <span>ArrowDownwardIcon</span>,
  ClearAllIcon: () => <span>ArrowDownwardIcon</span>,
  CloseIcon: () => <span>ArrowDownwardIcon</span>,
  ContentCopy: () => <span>ArrowDownwardIcon</span>,
  DensityLargeIcon: () => <span>ArrowDownwardIcon</span>,
  DensityMediumIcon: () => <span>ArrowDownwardIcon</span>,
  DensitySmallIcon: () => <span>ArrowDownwardIcon</span>,
  DragHandleIcon: () => <span>ArrowDownwardIcon</span>,
  DynamicFeedIcon: () => <span>ArrowDownwardIcon</span>,
  EditIcon: () => <span>ArrowDownwardIcon</span>,
  ExpandMoreIcon: () => <span>ArrowDownwardIcon</span>,
  FilterAltIcon: () => <span>ArrowDownwardIcon</span>,
  FilterListIcon: () => <span>ArrowDownwardIcon</span>,
  FilterListOffIcon: () => <span>ArrowDownwardIcon</span>,
  FirstPageIcon: () => <span>ArrowDownwardIcon</span>,
  FullscreenExitIcon: () => <span>ArrowDownwardIcon</span>,
  FullscreenIcon: () => <span>ArrowDownwardIcon</span>,
  KeyboardDoubleArrowDownIcon: () => <span>ArrowDownwardIcon</span>,
  LastPageIcon: () => <span>ArrowDownwardIcon</span>,
  MoreHorizIcon: () => <span>ArrowDownwardIcon</span>,
  MoreVertIcon: () => <span>ArrowDownwardIcon</span>,
  PushPinIcon: () => <span>ArrowDownwardIcon</span>,
  RestartAltIcon: () => <span>ArrowDownwardIcon</span>,
  SaveIcon: () => <span>ArrowDownwardIcon</span>,
  SearchIcon: () => <span>ArrowDownwardIcon</span>,
  SearchOffIcon: () => <span>ArrowDownwardIcon</span>,
  SortIcon: () => <span>ArrowDownwardIcon</span>,
  SyncAltIcon: () => <span>ArrowDownwardIcon</span>,
  ViewColumnIcon: () => <span>ArrowDownwardIcon</span>,
  VisibilityOffIcon: () => <span>ArrowDownwardIcon</span>,
} as const

export type RTIcons = Record<keyof typeof RTDefaultIcons, any>
