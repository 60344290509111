import { createSelector } from 'reselect'
import { selectBuyerSellers } from './selectBuyerSellers'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

const notSet = {
  value: null,
  label: 'Not Set',
}

export const selectBuyerSellerProducts = state => state.data.buyerSellerProducts
const lineItem = (_state, lineItem) => lineItem
const skipNotSet = (_state, _lineItem, skipNotSet) => skipNotSet

export const selectBSPFactory = () => {
  const selectBuyerSellerProductsByBuyerSeller = createSelector(
    [selectBuyerSellerProducts, selectBuyerSellers, lineItem, skipNotSet],
    (buyerSellerProducts, buyerSellers, item, skipNotSet) => {
      if (buyerSellers.length == 0 || !item) {
        return []
      }

      const bspArray = columnHashToColumnsArray(buyerSellerProducts)
      const buyerId = item.buyerId
      const sellerId = item.sellerId
      const selectedBuyerSellerId = buyerSellers.find(
        bs => bs.buyerId == buyerId && bs.sellerId == sellerId,
      )
      const newBuyerSellerProducts = bspArray
        .map(bsp => {
          return {
            label: bsp.name,
            value: bsp.id,
            buyerSellerId: bsp.buyerSellerId,
          }
        })
        .filter(entry => entry.buyerSellerId == selectedBuyerSellerId?.id)
        .sort((a, b) => a.label?.localeCompare(b.label))

      if (!skipNotSet) {
        newBuyerSellerProducts.unshift(notSet)
      }

      return newBuyerSellerProducts
    },
  )

  return selectBuyerSellerProductsByBuyerSeller
}

export const selectBuyerSellerProductOptions = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => {
    const bspArray = columnHashToColumnsArray(buyerSellerProducts)

    const newArray = bspArray
      .map(bsp => {
        return {
          value: bsp.id,
          label: bsp.name,
          code: bsp.code,
          product_group_id: bsp.productGroupId,
          buyerSellerId: bsp.buyerSellerId,
          type: 'buyerSellerProductId',
        }
      })
      .sort((a, b) => (a.label || '').localeCompare(b.label))

    return newArray
  },
)

export const selectBuyerSellerProductsInfo = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => {
    const bspArray = columnHashToColumnsArray(buyerSellerProducts)
    const newArray = bspArray
      .map(bsp => bsp)
      .sort((a, b) => a?.name?.localeCompare(b?.name))

    return newArray
  },
)

export const selectCommonBuyerSellerProducts = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => columnHashToColumnsArray(buyerSellerProducts),
)
