import { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useTablePaySchedules from './useTablePaySchedules'
import { useWindowSize } from 'react-use'

import {
  DialogPayScheduleForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import { fetchCompanyPayrolls } from '~/redux/reducers/data/companyPayrolls'
import { apiClient } from '~/api/ApiClient'

import './TablePaySchedules.scss'
import { moduleName } from '~/utils/constants'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

function TablePaySchedules(props) {
  const { tableHeight, className } = props

  const currentUser = useSelector(state => state.session.user)
  const windowSize = useWindowSize()

  const [paySchedules, setPaySchedules] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })

  const { columns } = useTablePaySchedules()

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const afterCreate = useCallback(() => {
    fetchPaySchedules()
    onCloseForm()
  }, [fetchPaySchedules])

  const onOpenEditDialogForm = rowData => () => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }

  useEffect(() => {
    dispatch(fetchCompanyPayrolls())
  }, [dispatch])

  const fetchPaySchedules = useCallback(async () => {
    setLoading(true)
    const repsonse = await apiClient.check.getPaySchedules({
      paySchedule: {
        company: currentUser.company.checkUid,
      },
    })
    setLoading(false)
    setPaySchedules(repsonse.results)
  }, [currentUser.company?.checkUid])

  useEffect(() => {
    fetchPaySchedules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ margin: 12 }} className={className}>
        <ReusableTable
          data={paySchedules}
          columns={columns}
          tableHeight={tableHeight || windowSize.height - 220}
          enableTopToolbar
          enableCompanyView
          enableColumnPinning
          enableRowActions
          companyViewProps={{
            name: moduleName.company.paySchedules,
            description: 'Manage your Pay Schedules',
            onClickTopButton() {
              setFormState({
                isOpen: true,
                formData: undefined,
              })
            },
          }}
          state={{
            isLoading: loading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 30,
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: onOpenEditDialogForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
          ]}
        />
      </div>
      <DialogPayScheduleForm
        isOpen={formState.isOpen}
        formData={formState.formData}
        onClose={onCloseForm}
        afterCreate={afterCreate}
        afterUpdate={afterCreate}
      />
    </>
  )
}

export default TablePaySchedules
