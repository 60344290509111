import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../types'

export const isCellEditable = <TData extends IReusableTableRowData>({
  cell,
  table,
}: {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
}) => {
  const { enableEditing } = table.options
  const {
    column: { columnDef },
    row,
  } = cell
  const isPlaceholder = cell.getIsPlaceholder()
  const isGlobalEditable =
    typeof enableEditing === 'function' ? enableEditing(row) : enableEditing
  const isRowEditable =
    typeof columnDef.enableEditing === 'function'
      ? columnDef.enableEditing(row)
      : columnDef.enableEditing

  return !isPlaceholder && (isGlobalEditable || isRowEditable)
}

export const openEditingCell = <TData extends IReusableTableRowData>({
  cell,
  table,
}: {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
}) => {
  const {
    options: { editDisplayMode },
    refs: { editInputRefs },
  } = table
  const { column } = cell

  if (isCellEditable({ cell, table }) && editDisplayMode === 'cell') {
    table.setEditingCell(cell)
    queueMicrotask(() => {
      const textField = editInputRefs.current[column.id]
      if (textField) {
        textField.focus()
        textField.select?.()
      }
    })
  }
}
