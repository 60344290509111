import React, { useState, useEffect, useCallback, useMemo } from 'react'
import CostCodesRow from './CostCodesRow'
import CostCodesAddRow from './CostCodesAddRow'
import { apiClient } from '~/api/ApiClient'

const CostCodesBody = ({ searchKey, sortedCodes, costCodeStructure }) => {
  const [filteredCodes, setFilteredCodes] = useState([])
  const [buyerTerminals, setBuyerTerminals] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const [referenceData, setReferenceData] = useState({
    buyer_terminal_id: [],
    product_group_id: [],
    null: [],
  })

  useMemo(() => {
    setReferenceData({
      buyer_terminal_id: buyerTerminals,
      null: [],
      product_group_id: productGroups,
    })
  }, [buyerTerminals, productGroups])

  const getProductGroups = useCallback(async () => {
    const productGroupList = await apiClient.productGroups.get()
    setProductGroups(productGroupList)
  }, [setProductGroups])

  const getBuyerTerminals = useCallback(async () => {
    const buyerTerminalList = await apiClient.terminals.get({ owned: true })
    setBuyerTerminals(buyerTerminalList)
  }, [setBuyerTerminals])

  useEffect(() => {
    const list = sortedCodes.filter(cc => !cc.parentId)
    getBuyerTerminals()
    getProductGroups()
    setFilteredCodes(list)
  }, [sortedCodes, getBuyerTerminals, getProductGroups])

  const setParents = useCallback(value => {
    if (value) {
      setFilteredCodes(value)
    }
  }, [])

  return (
    <div className='cost-code-body'>
      <CostCodesAddRow
        codes={filteredCodes}
        setCodes={setParents}
        rowClassName='cost-code-row'
        gridClassName='lg-grid-row'
      />
      <div>
        {filteredCodes
          .sort((a, b) => parseInt(a.code) - parseInt(b.code))
          .map((cc, index) => {
            return (
              <CostCodesRow
                key={index}
                referenceData={referenceData}
                code={cc}
                parents={filteredCodes}
                setParents={setParents}
                fullCode={cc.code}
                searchKey={searchKey}
                costCodeStructure={costCodeStructure}
                level={0}
              />
            )
          })}
      </div>
    </div>
  )
}

export default CostCodesBody
