import { apiClient } from '~/api/ApiClient'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt'
import productGroupsSlice from '~/redux/reducers/data/productGroups'
import productGroupsUiSlice from '~/redux/reducers/ui/productGroups'

export const fetchProductGroups = () => dispatch => {
  dispatch(productGroupsUiSlice.actions.updateUi({ loading: true }))
  apiClient.productGroups.get()
    .then(groups => {
      const unix = Math.round(+new Date() / 1000)
      dispatch(productGroupsSlice.actions.initializeProductGroups(groups))
      dispatch(
        lastFetchedAtSlice.actions.updateLastFetchedAt({
          model: 'productGroups',
          lastFetchedAt: unix,
        }),
      )
    })
    .catch(error => console.log(error))
    .finally(() => {
      dispatch(productGroupsUiSlice.actions.updateUi({ loading: false }))
    })
}

export const updateProductGroup = payload => dispatch => {
  dispatch(productGroupsSlice.actions.updateProductGroup(payload))
}

export const deleteProductGroup = payload => dispatch => {
  dispatch(
    productGroupsSlice.actions.updateProductGroup({
      id: payload.id,
      deletedAt: payload.updatedAt,
    }),
  )
}

export const updateProductGroupsUi = payload => dispatch => {
  dispatch(productGroupsUiSlice.actions.updateUi(payload))
}
