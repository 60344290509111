import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IonButton, IonButtons, IonIcon, IonLabel } from '@ionic/react'
import Select from 'react-select'
import { closeOutline } from 'ionicons/icons'

const getItem = (items = [], value) => {
  const findItem = items.find(item => item.value === value)
  if (findItem) {
    return findItem
  }

  return {
    value: null,
    label: 'All',
  }
}

const DropdownPicker = ({
  field,
  value,
  filterKey,
  style,
  items = [],
  onValueChange,
  className = 'documents-sortby-filter',
  icon,
  isSortByFilter,
  ascendingSort,
  defaultLabel,
  itemStyle,
  isLoading,
  onLoadFilter,
  showSearchBar,
  showClearButton,
  hideOptions,
}) => {
  const [currentItem, setCurrentItem] = useState({
    value: null,
    label: defaultLabel || 'All',
  })
  const [isShowMenu, setIsShowMenu] = useState()

  const isAllOption = currentItem?.label === 'All'

  useEffect(() => {
    setCurrentItem(getItem(items, value))
  }, [value, items])

  const onItemSelected = useCallback(
    item => () => {
      setCurrentItem(item)
      onValueChange && onValueChange({ field, value: item.value })
    },
    [onValueChange, field],
  )

  const _onLoadFilter = useCallback(() => {
    onLoadFilter && onLoadFilter(field)
  }, [onLoadFilter, field])

  const onChange = useCallback(
    item => {
      setCurrentItem(item ? item : { value: null, label: defaultLabel })
      onValueChange && onValueChange({ field, value: item?.value })
      setIsShowMenu(false)
    },
    [defaultLabel, field, onValueChange],
  )

  const isEmptyItems = useMemo(() => {
    const diffItems = items.filter(({ value }) => value !== currentItem?.value)

    return diffItems?.length === 0
  }, [currentItem?.value, items])

  const renderButton = useCallback(() => {
    const renderLabel = () => {
      if (isEmptyItems) {
        return currentItem?.label || 'No Scope is selected'
      }

      return currentItem?.label || 'Select an option'
    }

    return (
      <IonButtons>
        <IonButton
          fill='clear'
          color={isAllOption ? 'medium' : 'primary'}
          onClick={_onLoadFilter}
        >
          <IonLabel
            style={{
              ...itemStyle,
              fontWeight: 'bold',
              fontSize: 'small',
            }}
          >
            {filterKey}: &nbsp;
          </IonLabel>
          <IonLabel
            style={{ fontSize: 'small', opacity: isAllOption ? 0.6 : 1 }}
            color='dark'
          >
            {renderLabel()}
          </IonLabel>
          {showClearButton && currentItem?.value && (
            <IonButton
              className='dropdown-toggle dropdown-toggle-split'
              onClick={() => onChange(null)}
            >
              <IonIcon color='medium' icon={closeOutline} />
            </IonButton>
          )}
        </IonButton>
      </IonButtons>
    )
  }, [
    _onLoadFilter,
    currentItem?.label,
    currentItem?.value,
    filterKey,
    isAllOption,
    isEmptyItems,
    itemStyle,
    onChange,
    showClearButton,
  ])

  return (
    <Dropdown
      className={className}
      onToggle={() => setIsShowMenu(!isShowMenu)}
      show={isShowMenu}
    >
      <Dropdown.Toggle
        id={`dropdown-${field}`}
        variant='link'
        size='lg'
        role='menu'
        bsPrefix='p-0'
        style={{ cursor: isEmptyItems ? 'default' : 'cursor' }}
      >
        {isSortByFilter ? (
          <IonButtons>
            <IonButton
              fill='clear'
              style={{ color: '#30BB50' }}
              title={`Sorting by ${currentItem?.label} ${
                ascendingSort
                  ? 'Low to High (Ascending)'
                  : 'High to Low (Descending)'
              }`}
            >
              {!!icon && <IonIcon icon={icon} slot='start' />}
              <IonLabel style={{ fontWeight: 'bold', fontSize: 'small' }}>
                Sort By:&nbsp;
              </IonLabel>
              <IonLabel style={{ fontSize: 'small' }} color='dark'>
                {currentItem?.label || 'Select an option'}
              </IonLabel>
            </IonButton>
          </IonButtons>
        ) : (
          renderButton()
        )}
      </Dropdown.Toggle>

      {!hideOptions && (
        <Dropdown.Menu className='dropDownMenu' style={style}>
          {showSearchBar ? (
            <Select
              classNamePrefix='react-select'
              autoFocus
              isMulti={false}
              options={items}
              value={currentItem}
              openMenuOnFocus={true}
              isSearchable={true}
              isLoading={isLoading}
              placeholder={currentItem?.value || 'Search'}
              onChange={onChange}
              isOptionSelected={option => option.value === currentItem.value}
              menuIsOpen
              blurInputOnSelect
              isFocused
              isClearable
            />
          ) : (
            items.map(item => (
              <Dropdown.Item
                active={item.value === currentItem.value}
                key={item.value}
                onClick={onItemSelected(item)}
              >
                {item.label}
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}

export default DropdownPicker
