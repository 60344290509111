import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useMainPage = () => {
  const session = useSelector(state => state.session)
  const { header } = useSelector(state => state.app)
  const [loading, setLoading] = useState(true)
  const [shouldTrackLocation, setTrackLocation] = useState(false)
  const { authenticated, checked, user } = session || {}

  useEffect(() => {
    if (checked) {
      setLoading(false)
    }
  }, [authenticated, checked])

  useEffect(() => {
    if (user && user.driver && user.driver.loadId) {
      setTrackLocation(true)
    }
  }, [user])

  return {
    loading,
    session,
    shouldTrackLocation,
    header,
  }
}

export default useMainPage
