import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import moment from 'moment'
import { checkmarkCircle, closeCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

export interface IPayrollJournalTableProps {
  data: any
}

function W4ExemptStatusReportTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Employee Fullname',
        accessorKey: 'employeeFullName',
      },
      {
        header: 'Document Type',
        accessorKey: 'documentType',
        align: 'center',
      },
      {
        header: 'Signer Name',
        accessorKey: 'signerName',
      },
      {
        header: 'Submission Date',
        accessorKey: 'submissionDate',
        align: 'center',
        Cell({ row: { original } }) {
          return moment(original.submissionDate).format('lll')
        },
      },
      {
        header: 'Exempt',
        accessorKey: 'exempt',
        align: 'center',
        Cell({ cell }) {
          const cellData = cell.getValue()
          const icon = cellData ? checkmarkCircle : closeCircle
          const color = cellData ? 'success' : 'danger'

          return <IonIcon icon={icon} color={color} />
        },
      },
    ],
    [],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default W4ExemptStatusReportTable
