import { moduleName } from '~/utils/constants'
import DEFAULT_INVOICES_COLUMNS from '~/constants/invoices/columns'
import DEFAULT_LOAD_COLUMNS from '~/constants/loads/columns'
import DEFAULT_DOCUMENTS_COLUMNS from '~/constants/documents/columns'

const DEFAULT_COMPANY_COLUMNS = {
  [moduleName.company.users]: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Profile',
      field: 'profile',
      show: true,
    },
    {
      label: 'Phone',
      field: 'phoneNumber',
      show: true,
    },
    {
      label: 'User Group(s)',
      field: 'userAccess.userGroupId',
      show: true,
    },
    {
      label: 'Status',
      field: 'userAccess.status',
      show: true,
    },
    {
      label: 'Default',
      field: 'userAccess.default',
      show: true,
    },
    {
      label: 'Disallowed Scopes',
      field: 'userAccess.disallowedScopes',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  parsers: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Company',
      field: 'company.name',
      show: true,
    },
    {
      label: 'Collection',
      field: 'collection',
      show: true,
    },
    {
      label: 'Document Type',
      field: 'documentType',
      show: true,
    },
    {
      label: 'Parser',
      field: 'parser',
      show: true,
    },
    {
      label: 'Uid',
      field: 'uid',
      show: true,
    },
    {
      label: 'Internal use only',
      field: 'internalUseOnly',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  buyers: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Name',
      field: 'buyerName',
      show: true,
    },
    {
      label: 'Code',
      field: 'buyerCode',
      show: true,
    },
    {
      label: 'Status',
      field: 'buyerStatus',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  sellers: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Name',
      field: 'sellerName',
      show: true,
    },
    {
      label: 'Code',
      field: 'sellerCode',
      show: true,
    },
    {
      label: 'Status',
      field: 'sellerStatus',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  fleets: [
    {
      label: 'ID',
      field: 'col7',
      show: true,
    },
    {
      label: 'Company',
      field: 'col1',
      show: true,
    },
    {
      label: 'Fleet',
      field: 'col2',
      show: true,
    },
    {
      label: 'Name',
      field: 'col3',
      show: true,
    },
    {
      label: 'Code',
      field: 'col4',
      show: true,
    },
    {
      label: 'Rank',
      field: 'col5',
      show: true,
    },
    {
      label: 'Menu',
      field: 'col6',
      show: true,
    },
  ],
  emailParsers: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Seller Regex',
      field: 'sellerRegex',
      show: true,
    },
    {
      label: 'Buyer Regex',
      field: 'buyerRegex',
      show: true,
    },
    {
      label: 'Doc Type Regex',
      field: 'docTypeRegex',
      show: true,
    },
    {
      label: 'DocType Value',
      field: 'docTypeValue',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  parserColumnMappings: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Company',
      field: 'company',
      show: true,
    },
    {
      label: 'Parsing Column',
      field: 'parsingColumn',
      show: true,
    },
    {
      label: 'Assign Column',
      field: 'assignColumn',
      show: true,
    },
    {
      label: 'Type',
      field: 'model',
      show: true,
    },
    {
      label: 'regexes',
      field: 'regexp',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  products: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Product Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Product Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Product Description',
      field: 'description',
      show: true,
    },
    {
      label: 'Price',
      field: 'price',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  autoCharges: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Application Level',
      field: 'applicationLevel',
      show: true,
    },
    {
      label: 'Allocated Per',
      field: 'allocatedPer',
      show: true,
    },
    {
      label: 'Product',
      field: 'buyerSellerProduct.name',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  fixedPrices: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Seller Terminal',
      field: 'sellerTerminalId',
      show: true,
    },
    {
      label: 'Buyer Terminal',
      field: 'buyerTerminalId',
      show: true,
    },
    {
      label: 'Item Type',
      field: 'itemType',
      show: true,
    },
    {
      label: 'Product Name',
      field: 'buyerSellerProduct.name',
      show: true,
    },
    {
      label: 'Price',
      field: 'price',
      show: true,
    },
    {
      label: 'Flat Rate',
      field: 'flatRate',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  sellerProducts: [
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Description',
      field: 'description',
      show: true,
    },
    {
      label: 'Product code - name',
      field: 'product',
      show: true,
    },
    {
      label: 'Status',
      field: 'status',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  terminals: [
    {
      label: 'Company',
      field: 'company',
      show: true,
    },
    {
      label: 'Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Time zone',
      field: 'timeZone',
      show: true,
    },
    {
      label: 'Phone #',
      field: 'callPhoneNumber',
      show: true,
    },
    {
      label: 'Status',
      field: 'status',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  buyerSellerProducts: [
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Status',
      field: 'status',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  buyerTerminalReferences: [
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Buyer Terminal',
      field: 'buyerTerminal',
      show: true,
    },
    {
      label: 'Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  referenceNames: [
    {
      label: 'ID',
      field: 'id',
      show: true,
    },
    {
      label: 'Buyer',
      field: 'buyer',
      show: true,
    },
    {
      label: 'Seller',
      field: 'seller',
      show: true,
    },
    {
      label: 'Item Type',
      field: 'itemType',
      show: true,
    },
    {
      label: 'Item ID',
      field: 'itemId',
      show: true,
    },
    {
      label: 'Reference Name',
      field: 'referenceName',
      show: true,
    },
    {
      label: 'Reference Code',
      field: 'referenceCode',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
  companyTags: [
    {
      label: 'Name',
      field: 'name',
      show: true,
    },
    {
      label: 'Code',
      field: 'code',
      show: true,
    },
    {
      label: 'Description',
      field: 'description',
      show: true,
    },
    {
      label: 'Models',
      field: 'models',
      show: true,
    },
    {
      label: 'Color',
      field: 'color',
      show: true,
    },
    {
      label: 'Menu',
      field: 'menu',
      show: true,
    },
  ],
}

const DEFAULT_START_TIMES_COLUMNS = [
  {
    label: 'Date',
    field: 'date',
    show: true,
  },
  {
    label: 'Time',
    field: 'time',
    show: true,
  },
  {
    label: 'Driver',
    field: 'driverId',
    show: true,
  },
  {
    label: 'Terminal',
    field: 'terminalId',
    show: true,
  },
  {
    label: 'Clock In',
    field: 'clockIn',
    show: true,
  },
  {
    label: 'Clock Out',
    field: 'clockOut',
    show: true,
  },
  {
    label: 'In Service',
    field: 'inService',
    show: true,
  },
  {
    label: 'End Time',
    field: 'endTime',
    show: true,
  },
  {
    label: 'Start Terminal',
    field: 'shift.startTerminalId',
    show: true,
  },
  {
    label: 'End Terminal',
    field: 'shift.endTerminalId',
    show: true,
  },
  {
    label: 'Notes',
    field: 'notes',
    show: true,
  },
  {
    label: 'Shift Notes',
    field: 'shift.notes',
    show: true,
  },
  {
    label: 'Total Hours',
    field: 'shift.totalHrs',
    show: true,
  },
]

const defaultColumns = {
  invoices: DEFAULT_INVOICES_COLUMNS,
  documents: DEFAULT_DOCUMENTS_COLUMNS,
  loads: DEFAULT_LOAD_COLUMNS,
  [moduleName.company.users]: DEFAULT_COMPANY_COLUMNS[moduleName.company.users],
  [moduleName.company.parsers]: DEFAULT_COMPANY_COLUMNS.parsers,
  [moduleName.company.buyers]: DEFAULT_COMPANY_COLUMNS.buyers,
  [moduleName.company.sellers]: DEFAULT_COMPANY_COLUMNS.sellers,
  [moduleName.company.fleets]: DEFAULT_COMPANY_COLUMNS.fleets,
  [moduleName.company.emailParsers]: DEFAULT_COMPANY_COLUMNS.emailParsers,
  [moduleName.company.parserColumnMappings]:
    DEFAULT_COMPANY_COLUMNS.parserColumnMappings,
  [moduleName.company.products]: DEFAULT_COMPANY_COLUMNS.products,
  [moduleName.company.autoCharges]: DEFAULT_COMPANY_COLUMNS.autoCharges,
  [moduleName.company.fixedPrices]: DEFAULT_COMPANY_COLUMNS.fixedPrices,
  [moduleName.company.sellerProducts]: DEFAULT_COMPANY_COLUMNS.sellerProducts,
  [moduleName.company.terminals]: DEFAULT_COMPANY_COLUMNS.terminals,
  [moduleName.company.buyerSellerProducts]:
    DEFAULT_COMPANY_COLUMNS.buyerSellerProducts,
  [moduleName.company.buyerTerminalReferences]:
    DEFAULT_COMPANY_COLUMNS.buyerTerminalReferences,
  [moduleName.company.referenceNames]: DEFAULT_COMPANY_COLUMNS.referenceNames,
  [moduleName.company.tags]: DEFAULT_COMPANY_COLUMNS.companyTags,
  [moduleName.startTimes]: DEFAULT_START_TIMES_COLUMNS,
  default: [],
}

export default defaultColumns
