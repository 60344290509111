import _ from 'lodash'

import * as types from '../actions/actionTypes'

export const initialState = {
  messages: [],
  conversations: [],
  guest: {},
  endOfTheList: false,
  loading: undefined,
  loadingMore: undefined,
}

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MESSAGES: {
      const { page } = action
      const newState = {
        loadingMore: true,
      }
      if (page === 1) {
        newState['endOfTheList'] = false
        newState['loading'] = true
      }

      return {
        ...state,
        ...newState,
      }
    }
    case types.FETCH_MESSAGES_SUCCESS: {
      const { messages, admin, totalMessages, page } = action
      const newState = {
        loading: false,
        loadingMore: false,
      }
      let allMessages
      if (page === 1) {
        allMessages = messages.reverse()
      } else {
        allMessages = [...messages.reverse(), ...state.messages]

        // Remove duplicates from pagination
        allMessages = _.orderBy(_.uniqBy(allMessages, 'id'), 'id')
      }
      newState['messages'] = allMessages
      newState['guest'] = admin

      if (allMessages.length === totalMessages) {
        newState['endOfTheList'] = true
      }

      return {
        ...state,
        ...newState,
      }
    }

    case types.CREATE_MESSAGE_SUCCESS: {
      const { message } = action
      const newList = state.messages.map(a => ({ ...a }))
      if (!newList.find(elem => elem.id === message.id)) {
        newList.push(message)
      }

      return {
        ...state,
        messages: newList,
      }
    }

    case types.FETCH_CONVERSATION_SUCCESS: {
      const { conversations } = action

      return {
        ...state,
        conversations,
      }
    }
    default: {
      return state
    }
  }
}

export default messagesReducer
