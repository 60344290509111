import { useCallback, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IProductGlCodeFormValues } from '~/types/models/IProductGlCode'
import type { IProductGlCodeFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryGlCodes,
  useQueryProductGroups,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { PRODUCTABLE_TYPE_OPTIONS } from '~/utils/constants'
import { EProductableType } from '~/types/enums/EProductGlCode'
import { ICommonOption } from '~/types/models/ICommonModel'

const ProductGlCodeForm = (props: IProductGlCodeFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { glCodeOptions } = useQueryGlCodes()

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const { productGroupOptions } = useQueryProductGroups()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'GL Code',
        name: 'glCodeId',
        options: glCodeOptions,
        type: EFieldType.singleSelect,
        size: 12,
        isRequired: true,
      },
      {
        label: 'Productable Type',
        name: 'productableType',
        options: PRODUCTABLE_TYPE_OPTIONS,
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        render({ label, name, control, setValue }) {
          return (
            <ConcordDropdownV2WithFormControl
              isRequired
              control={control}
              label={label}
              name={name}
              options={PRODUCTABLE_TYPE_OPTIONS}
              onChange={() => {
                setValue('productableId', null)
              }}
            />
          )
        },
      },
      {
        label: 'Productable #',
        name: 'productableId',
        options: [],
        type: EFieldType.singleSelect,
        size: 6,
        isRequired: true,
        render({ name, watch, control }) {
          const productableType = watch('productableType', '')
          let options: ICommonOption[] = []

          if (productableType === EProductableType.ProductGroup) {
            options = productGroupOptions
          } else if (productableType === EProductableType.SellerProduct) {
            options = sellerProductOptions
          }

          return (
            <ConcordDropdownV2WithFormControl
              isRequired
              control={control}
              label={productableType ? _.startCase(productableType) : ''}
              name={name}
              options={options}
              isDisabled={!productableType}
            />
          )
        },
      },
    ],
    [glCodeOptions, productGroupOptions, sellerProductOptions],
  )

  const defaultValues = useMemo<IProductGlCodeFormValues>(
    () => ({
      glCodeId: null,
      productableId: null,
      productableType: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        glCodeId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        productableId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        productableType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IProductGlCodeFormValues) => {
      const { errors, ...response } = await apiClient.productGlCodes.create({
        productGlCode: {
          glCodeId: formValues.glCodeId,
          productableType: formValues.productableType,
          productableId: formValues.productableId,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IProductGlCodeFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'glCodeId',
          'productableType',
          'productableId',
        ])
        const { errors, ...response } = await apiClient.productGlCodes.update(
          formValues.id,
          {
            productGlCode: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IProductGlCodeFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default ProductGlCodeForm
