import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { IonIcon, IonSpinner } from '@ionic/react'
import { When } from 'react-if'
import { ToolTipOverlay } from '~/components/shared'

import { caretDown, caretForward } from 'ionicons/icons'
import clsx from 'clsx'

import type { IToggleSectionProps } from './type'

import './styles.scss'

function ToggleSection(props: PropsWithChildren<IToggleSectionProps>) {
  const {
    title,
    children,
    className,
    onToggle,
    isOpen,
    name,
    controlIsOpen,
    icons = [],
    isLoading,
    renderCount,
  } = props

  const [isOpenSection, setIsOpenSection] = useState(false)

  const isOpenValue = useMemo(() => {
    if (controlIsOpen) {
      return isOpenSection
    }
    if (typeof isOpen === 'boolean') {
      return isOpen
    }
    return isOpenSection
  }, [controlIsOpen, isOpen, isOpenSection])

  const onToggleSection = useCallback(() => {
    const nextValue = !isOpenValue
    setIsOpenSection(nextValue)
    onToggle && onToggle(nextValue)
  }, [isOpenValue, onToggle])

  const renderIcons = useMemo(
    () =>
      icons
        .filter(({ isHidden }) => !isHidden)
        .map(({ icon, color, tooltipProps }, index) => (
          <ToolTipOverlay
            key={index}
            {...tooltipProps}
            className={clsx(
              'ToggleSection__iconTooltip',
              tooltipProps?.className,
            )}
          >
            <span className='headerIcon' style={{ marginLeft: 4 }}>
              <IonIcon icon={icon} color={color} />
            </span>
          </ToolTipOverlay>
        )),
    [icons],
  )

  useEffect(() => {
    if (controlIsOpen && typeof isOpen === 'boolean') {
      setIsOpenSection(isOpen)
    }
  }, [controlIsOpen, isOpen])

  return (
    <div
      id={name}
      className={clsx('ToggleSection__container', className, {
        isDisabled: isLoading,
      })}
    >
      <div className='tabHeader clickable' onClick={onToggleSection}>
        <span className='headerIcon'>
          <IonIcon icon={isOpenValue ? caretDown : caretForward} />
        </span>
        <span>{title}</span>
        <When condition={typeof renderCount === 'function'}>
          {renderCount && renderCount()}
        </When>
        {renderIcons}
        <When condition={isLoading}>
          <IonSpinner name='dots' class='ToggleSection__spinner' />
        </When>
      </div>

      <div className={clsx('childrenSection', { isOpenSection: isOpenValue })}>
        {children}
      </div>
    </div>
  )
}
export default ToggleSection
