import _ from 'lodash'
import moment from 'moment'

export const getToday = () => {
  const today = moment().toDate()

  return today
}

export const getDayToOneWeek = () => {
  const today = getToday()
  const toWeek = moment(today).add(1, 'weeks').toDate()

  return {
    today,
    toWeek,
  }
}

export const isSameYear = date1 => {
  const year1 = moment(date1).get('years')
  const year2 = moment().get('years')
  return year1 === year2
}

export const isTomorrow = date => {
  const today = getToday()
  const strToday = moment(today).format('YYYY-MM-DD')
  const strDate = moment(date).format('YYYY-MM-DD')
  const result = moment(strToday).add('days', 1).diff(strDate)

  return result === 0
}

export const isYesterday = date => {
  const today = getToday()
  const strToday = moment(today).format('YYYY-MM-DD')
  const strDate = moment(date).format('YYYY-MM-DD')
  const result = moment(strToday).add('days', -1).diff(strDate)

  return result === 0
}

export const isToday = date => {
  const today = getToday()
  const strToday = moment(today).format('YYYY-MM-DD')
  const strDate = moment(date).format('YYYY-MM-DD')

  return strToday === strDate
}

export const isDayInThePast = date => {
  const today = getToday()
  const strDate = moment(date).format('YYYY-MM-DD')
  const strYesterday = moment(today).add('days', -1).format('YYYY-MM-DD')
  const result = moment(strYesterday).diff(strDate)

  return result >= 0
}

export const isAfterTomorrow = date => {
  const today = getToday()
  const strTomorrow = moment(today).add('days', 1).format('YYYY-MM-DD')
  const strDate = moment(date).format('YYYY-MM-DD')
  const result = moment(strDate).diff(strTomorrow)

  return result > 0
}

/**
 * Compare current date with argument date
 * @param {string} currentDate ISO Date
 * @returns
 * 1 // future
 * -1 => past day
 * 0 today
 */
export const compareCurrentTimeAndToday = currentDate => {
  const today = getToday()
  const result = moment(currentDate).diff(today)

  if (result === 0) {
    return 0
  }

  return result > 1 ? 1 : -1
}

export const isDayInThisWeek = date => {
  const now = moment()
  const input = moment(date)
  const isThisWeek = now.isoWeek() == input.isoWeek()

  return isThisWeek
}

export const isDayInTheFututre = date => {
  if (isToday(date) || isDayInThePast(date)) {
    return false
  }

  const today = getToday()
  const strDate = moment(date).format('YYYY-MM-DD')
  const strFuture = moment(today).add('days', 1).format('YYYY-MM-DD')
  const result = moment(strDate).diff(strFuture)

  return result >= 0
}

export function getMinDate(data, field) {
  const result = _.orderBy(data, [field], 'asc')
  return result[0]
}

export function countMinutesFromTimeToNow(time) {
  let start = moment(time)
  let end = moment()
  const diffMinutes = start.diff(end, 'minutes')
  return diffMinutes
}

export default getDayToOneWeek
