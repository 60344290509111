import { ActionsCol } from '../Columns'
import TagLinkModal from '~/components/shared/TagLinkModal/TagLinkModal'

import './DropMenu.scss'

const EditableTableRowDropMenu = props => {
  const {
    billLine,
    onShowDetail,
    onDeleteBillLineClick,
    hasUseButton,
    onUseBillLineClick,
    matchedFields,
    isResolved,
    showContextMenu,
    onMouseEnterCell,
    onMouseLeaveCell,
    setNewLineItem,
  } = props

  return (
    <div>
      <ActionsCol
        billLine={billLine}
        isShortenButtons={false}
        onShowDetail={onShowDetail}
        onShowBillLineDetail={onShowDetail}
        onDeleteBillLineClick={onDeleteBillLineClick}
        hasUseButton={hasUseButton}
        onUseBillLineClick={onUseBillLineClick}
        matchedFields={matchedFields}
        isResolved={isResolved}
        onMouseEnterCell={onMouseEnterCell}
        onMouseLeaveCell={onMouseLeaveCell}
        style={{ display: 'flex' }}
      />
      <TagLinkModal
        lineItem={billLine}
        taggableType={'BillLine'}
        isOpen={showContextMenu}
        setNewLineItem={setNewLineItem}
      />
      {billLine?.load?.loadImage && (
        <div className='TicketImage__container'>
          <img src={billLine.load.loadImage} alt='load_image' />
        </div>
      )}
    </div>
  )
}

export default EditableTableRowDropMenu
