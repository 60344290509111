import { useMemo } from 'react'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'

import moment from 'moment'
import { PHONE_TYPE_OPTIONS } from '~/utils/constants'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { IPhoneNumber } from '~/types/models/IPhoneNumber'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import _ from 'lodash'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'

const useTablePhoneNumbers = () => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const { allCompaniesData } = useQueryBuyerSellers()

  const columns = useMemo<IRTColumnDef<IPhoneNumber>[]>(
    () => [
      {
        header: 'Phone Number',
        id: 'number',
        accessorFn(rowData) {
          return formatPhoneNumber(rowData.number)
        },
      },
      {
        header: 'Company',
        accessorKey: 'phoneableId',
        Cell({ cell, row }) {
          const cellValue = cell.getValue<number>()
          if (row.original.phoneableType === EPhoneableType.company) {
            let company: ICompany | undefined = undefined
            let companyType = EScope.fleet
            if (cellValue === currentCompany.id) {
              company = currentCompany
              companyType = currentScope
            } else {
              company = allCompaniesData.find(({ id }) => id === cellValue)
              companyType =
                currentScope === EScope.buyer ? EScope.seller : EScope.buyer
            }

            return (
              <CompanyInfo
                companyType={companyType}
                company={{
                  value: company?.id,
                  label: company ? `${company.code} - ${company.name}` : '',
                  name: company?.name,
                  code: company?.code,
                  image: company?.logo,
                }}
                hideAnchor
                searchableGoogle={false}
              />
            )
          }
          return cellValue
        },
      },
      {
        header: 'Phone Types',
        accessorKey: 'phoneTypes',
        size: 200,
        filterSelectOptions: PHONE_TYPE_OPTIONS.map(({ numValue, label }) => ({
          label,
          value: numValue,
        })),
        filterVariant: EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<EPhoneNumberTypes[]>()
          const phoneTypes = cellValue.map(num =>
            _.startCase(EPhoneNumberTypes[num]),
          )

          return phoneTypes.join(', ')
        },
      },
      {
        header: 'Created At',
        id: 'createdAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.createdAt).format('lll')
        },
      },
      {
        header: 'Updated At',
        id: 'updatedAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.updatedAt).format('lll')
        },
      },
    ],
    [allCompaniesData, currentCompany, currentScope],
  )

  return { columns }
}

export default useTablePhoneNumbers
