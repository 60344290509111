import { useMemo, useState, useEffect, useCallback } from 'react'
import { useUpdateEffect } from 'react-use'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'

import { Button, CommonDialogV2 } from '~/components/shared'
import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  IonIcon,
  IonButton,
} from '@ionic/react'

import _ from 'lodash'
import { produce } from 'immer'
import { useQueryNewGroup } from '~/hooks/useQueryData'

function DialogMenuOptions(props) {
  const { companyId, isOpen, formState, onClose, onSubmit, ...dialogProps } =
    props

  const [menuOptions, setMenuOptions] = useState([])
  const [selectedCheckbox, setSelectedCheckbox] = useState([]) // string[]

  const { newGroupData, isLoadingNewGroup } = useQueryNewGroup({
    enabled: Boolean(isOpen),
  })

  const { allPrivateMenuOptions, settingsMenu } = useNavigatedMenuItems()

  const defaultMenuOptions = useMemo(() => {
    return [...allPrivateMenuOptions, settingsMenu]
  }, [allPrivateMenuOptions, settingsMenu])

  const isConcordAdmin = useMemo(
    () => formState?.name === 'Concord Admin',
    [formState?.name],
  )

  const allRoutes = useMemo(() => {
    const routes = newGroupData?.allRoutes || []

    if (isConcordAdmin) {
      return [...routes, 'settings']
    }

    return routes
  }, [isConcordAdmin, newGroupData?.allRoutes])

  const getMenuOptionsBasedOnScope = useCallback(() => {
    if (formState?.scopeType) {
      let options = []
      if (isConcordAdmin) {
        allRoutes.forEach(route => {
          defaultMenuOptions.forEach(defaultOption => {
            if (route === defaultOption.value) {
              options.push(defaultOption)
            }
          })
        })
      } else {
        const scopedOptions =
          _.get(
            newGroupData || {},
            // {},
            `menuOptionsHash.${(formState?.scopeType || '').toLowerCase()}`,
          ) || []
        scopedOptions.forEach(scopedOption => {
          defaultMenuOptions.forEach(defaultOption => {
            if (scopedOption === defaultOption.value) {
              options.push(defaultOption)
            }
          })
        })
      }

      setMenuOptions(options)
    }
  }, [
    allRoutes,
    defaultMenuOptions,
    formState?.scopeType,
    isConcordAdmin,
    newGroupData,
  ])

  const handleChangeCheckbox = useCallback(event => {
    const { value } = event.target

    setSelectedCheckbox(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(value)
        if (index === -1) {
          draft.push(value)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const isOptionChecked = useCallback(
    option => {
      const result = selectedCheckbox.includes(option.value)

      return result
    },
    [selectedCheckbox],
  )

  const mapMenuOptions = useMemo(() => {
    const selectedOptions = []
    // _(menuOptions).filter((option) => isOptionChecked(option)).value()
    selectedCheckbox.forEach(field => {
      const option = _.find(menuOptions, { value: field })
      if (option) {
        selectedOptions.push(option)
      }
    })

    const unSelectedOptions = _(menuOptions)
      .filter(option => !isOptionChecked(option))
      .orderBy('label', 'asc')
      .value()
    const newMenuOptions = _.unionBy(
      [...selectedOptions, ...unSelectedOptions],
      'value',
    )

    return newMenuOptions
  }, [isOptionChecked, menuOptions, selectedCheckbox])

  const handleReorder = useCallback(
    event => {
      const newMenuOptions = event.detail.complete(mapMenuOptions)
      const newSelectedCheckbox = []
      newMenuOptions.forEach(({ value }) => {
        if (selectedCheckbox.includes(value)) {
          newSelectedCheckbox.push(value)
        }
      })
      setSelectedCheckbox(newSelectedCheckbox)
    },
    [mapMenuOptions, selectedCheckbox],
  )

  const renderMenuOptions = useCallback(() => {
    return mapMenuOptions.map(option => (
      <IonItem key={option.value}>
        {isOptionChecked(option) ? (
          <IonReorder slot='start' />
        ) : (
          <IonButton slot='start' style={{ marginRight: 27, opacity: 0 }}>
            <IonIcon slot='icon-only' icon={'reorderTwo'} />
          </IonButton>
        )}

        <IonLabel>{option.label}</IonLabel>
        <IonCheckbox
          value={option.value}
          checked={isOptionChecked(option)}
          onIonChange={handleChangeCheckbox}
        />
      </IonItem>
    ))
  }, [handleChangeCheckbox, isOptionChecked, mapMenuOptions])

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(selectedCheckbox)
    onClose && onClose()
  }, [onClose, onSubmit, selectedCheckbox])

  useEffect(() => {
    getMenuOptionsBasedOnScope()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.scopeType, newGroupData])

  useUpdateEffect(() => {
    if (isOpen) {
      setSelectedCheckbox(formState.menuOptions || [])
    }
  }, [formState.menuOptions, isOpen])

  return (
    <CommonDialogV2
      isOpen={isOpen}
      isLoading={isLoadingNewGroup}
      isHiddenHeader
      onClose={onClose}
      onOk={handleSubmit}
      {...dialogProps}
    >
      <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
        {renderMenuOptions()}
      </IonReorderGroup>
      {mapMenuOptions?.length > 0 && (
        <Button expand='full' onClick={handleSubmit}>
          Save
        </Button>
      )}
    </CommonDialogV2>
  )
}

DialogMenuOptions.defaultProps = {
  formState: {},
}

export default DialogMenuOptions
