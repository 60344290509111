import { useMemo } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { cogOutline } from 'ionicons/icons'
import MultiselectOrderDropdown from '../MultiselectOrderDropdown'

const ColumnsButton = props => {
  const { columnsDisplayed, onChangeColumnsDisplayed } = props

  const tooltipContentRendered = useMemo(() => {
    if (columnsDisplayed.length === 0) {
      return ''
    }
    const mapLabel = _(columnsDisplayed)
      .filter('sorted', true)
      .map('label')
      .join(', ')
    const totalColumns = columnsDisplayed.length
    const totalSelectedColumns = _(columnsDisplayed)
      .filter(['sorted', true])
      .size()

    return (
      <div>
        <span>
          <strong>
            COLUMNS - {`${totalSelectedColumns} / ${totalColumns}`}
          </strong>
        </span>
        <br />
        <span>{mapLabel}</span>
      </div>
    )
  }, [columnsDisplayed])

  return (
    <MultiselectOrderDropdown
      options={columnsDisplayed}
      onChange={onChangeColumnsDisplayed}
      buttonText='Columns'
      className='ColumnsDisplayed__container'
      icon={cogOutline}
      hasDirection={false}
      tooltipProps={{
        placement: 'right',
        content: tooltipContentRendered,
      }}
    />
  )
}

ColumnsButton.propTypes = {
  columnsDisplayed: PropTypes.array,
  onChangeColumnsDisplayed: PropTypes.func,
}

ColumnsButton.defaultProps = {
  columnsDisplayed: [],
}

export default ColumnsButton
