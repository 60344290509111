import React from 'react'
import { Container, Col, Row, Card } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import { globeOutline } from 'ionicons/icons'

const TerminalCard = ({ terminalCode, terminalName, terminalAddress }) => {
  return (
    <Card className='company-card'>
      <Container className='p-0'>
        <Row>
          <Col sm={3} className='p-0 my-auto'>
            <div className='icon'>
              <IonIcon icon={globeOutline} color='primary' />
            </div>
          </Col>
          <Col className='text ps-1' sm={9}>
            <p className='no-padding'>
              <span className='fw-bold'>Code: </span>
              <span>{terminalCode}</span>
            </p>
            <p className='no-padding'>
              <span className='fw-bold'>Name: </span>
              <span>{terminalName}</span>
            </p>
            <p className='no-padding'>
              <span className='fw-bold'>Address: </span>
              <span>{terminalAddress}</span>
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default TerminalCard
