import { useCallback, useMemo, useState, useEffect, memo } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { IonSpinner, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react'
import TextField from '../TextField'
import DropdownField from '../DropdownField'
import SellerBuyerAvatar from '../SellerBuyerAvatar'
import { Button } from 'react-bootstrap'

import { DateTime } from 'luxon'
import clsx from 'clsx'
import humps from 'humps'
import _ from 'lodash'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import './FormEmailParsers.scss'
import { apiClient } from '~/api/ApiClient'

const FormEmailParsers = props => {
  const {
    emailParser,
    onUpdate,
    onSubmit,
    loading,
    className,
    onSelect,
    isSelected,
    onChangeParser,
    index,
    onResetChangesParser,
  } = props

  const schema = yup.object({
    buyerRegex: yup.string().required('This field is required!').nullable(),
    sellerRegex: yup.string().required('This field is required!').nullable(),
    docTypeRegex: yup.string().required('This field is required!').nullable(),
    docTypeValue: yup.string().required('This field is required!').nullable(),
  })
  const [parserDefaultValues, setParserDefaultValues] = useState(emailParser)

  const { watch, handleSubmit, reset, setValue, formState } = useForm({
    defaultValues: {
      buyerRegex: '',
      sellerRegex: '',
      docTypeRegex: '',
      docTypeValue: 'Invoice',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const {
    id,
    updatedAt,
    createdAt,
    buyerRegex,
    sellerRegex,
    docTypeRegex,
    docTypeValue,
    buyer,
    seller,
  } = watch()

  const isCreation = id === -1

  const [isLoading, setIsLoading] = useState(false)

  const [countMatchedSellerRegex, setCountMatchedSellerRegex] = useState(0)
  const [countMatchedBuyerRegex, setCountMatchedBuyerRegex] = useState(0)
  const [countMatchedDocTypeRegex, setCountMatchedDocTypeRegex] = useState(0)

  const isEqualCreatedUpdatedAt = useMemo(() => {
    const lzCreatedAt = DateTime.fromISO(createdAt)
    const lzUpdatedAt = DateTime.fromISO(updatedAt)

    return lzCreatedAt.equals(lzUpdatedAt)
  }, [createdAt, updatedAt])

  const handleUpdateEmailParsers = useCallback(
    async (event, newValue) => {
      setIsLoading(true)
      try {
        const { field, value } = newValue
        const payload = {
          [field]: value
        }
        const { updatedAt, errors } = await apiClient.emailParsers.update(
          id,
          payload
        )
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          const key = humps.camelize(field)
          setValue(key, value)
          setParserDefaultValues(prev => ({
            ...prev,
            [key]: value,
            updatedAt,
          }))
          onUpdate && onUpdate({ id, [key]: value, updatedAt })
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setIsLoading(false)
      }
    },
    [id, onUpdate, setValue],
  )

  const handleChangeFormValues = useCallback(
    (event, value) => {
      const { name } = event.target
      const formatName = humps.camelize(name)
      setValue(formatName, value, { shouldValidate: true })

      onChangeParser && onChangeParser({ id, field: formatName, value, index })
    },
    [id, onChangeParser, setValue, index],
  )

  const docTypeOptions = useMemo(() => {
    const defaultOptions = [
      {
        label: 'Invoice',
        value: 'Invoice',
      },
      {
        label: 'Load',
        value: 'Load',
      },
    ]

    if (id) {
      defaultOptions.push({
        label: 'Not set',
        value: null,
      })
    }

    return defaultOptions
  }, [id])

  const handleSubmitForm = handleSubmit(formValues => {
    onSubmit && onSubmit(formValues)
  })

  const handleChooseParser = useCallback(
    event => {
      onSelect && onSelect(event, emailParser, !isSelected)
    },
    [emailParser, isSelected, onSelect],
  )

  const handleResetChanges = useCallback(
    ({ field, resetValue }) => {
      const formatName = humps.camelize(field)
      onResetChangesParser &&
        onResetChangesParser({
          id,
          index,
          field: formatName,
          value: resetValue,
        })
    },
    [id, index, onResetChangesParser],
  )

  useEffect(() => {
    reset({
      docTypeValue: 'Invoice',
      ...(emailParser || {}),
    })
  }, [emailParser, reset])

  useUpdateEffect(() => {
    if (!isSelected) {
      setCountMatchedSellerRegex(0)
    } else {
      const elements = document.getElementsByClassName(
        'DialogEmailContent__sellerRegex',
      )
      setCountMatchedSellerRegex(elements.length)
    }
  }, [isSelected, sellerRegex])

  useUpdateEffect(() => {
    if (!isSelected) {
      setCountMatchedBuyerRegex(0)
    } else {
      const elements = document.getElementsByClassName(
        'DialogEmailContent__buyerRegex',
      )
      setCountMatchedBuyerRegex(elements.length)
    }
  }, [isSelected, buyerRegex])

  useUpdateEffect(() => {
    if (!isSelected) {
      setCountMatchedDocTypeRegex(0)
    } else {
      const elements = document.getElementsByClassName(
        'DialogEmailContent__docTypeRegex',
      )
      setCountMatchedDocTypeRegex(elements.length)
    }
  }, [isSelected, docTypeRegex])

  return (
    <form
      onSubmit={handleSubmitForm}
      className={clsx('FormEmailParsers__container', className, {
        loading: isLoading || loading,
        isSelected,
      })}
    >
      {(isLoading || loading) && (
        <div className='FormEmailParsers__loadingOverlay'>
          <IonSpinner name='lines-small' />
        </div>
      )}
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ padding: 0 }}>
          <IonCol size={isSelected ? '8' : '9'} style={{ padding: 0 }}>
            {!_.isNil(seller) && (
              <SellerBuyerAvatar type='seller' user={seller} />
            )}
            {!_.isNil(buyer) && (
              <SellerBuyerAvatar
                type='buyer'
                user={buyer}
                style={{ marginTop: 8 }}
              />
            )}
          </IonCol>
          {onSelect && id !== -1 && (
            <IonCol size={isSelected ? '4' : '3'} style={{ padding: 0 }}>
              <IonButton
                className='FormEmailParsers__toggleSelectButton'
                onClick={handleChooseParser}
                color={isSelected ? 'tertiary' : 'primary'}
              >
                {isSelected ? 'UnSelect' : 'Select'}
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      <TextField
        className='FormEmailParsers__sellerRegexLabel'
        field='seller_regex'
        onConfirmUpdate={handleUpdateEmailParsers}
        id={id}
        value={sellerRegex}
        label='Seller Identifier'
        hideConfirmButton={isCreation}
        onChange={handleChangeFormValues}
        error={formState.errors?.sellerRegex?.message}
        defaultValue={parserDefaultValues.sellerRegex}
        onResetChanges={handleResetChanges}
        countMatched={countMatchedSellerRegex}
      />
      <TextField
        className='FormEmailParsers__buyerRegexLabel'
        field='buyer_regex'
        onConfirmUpdate={handleUpdateEmailParsers}
        id={id}
        value={buyerRegex}
        label='Buyer Identifier'
        hideConfirmButton={isCreation}
        onChange={handleChangeFormValues}
        error={formState.errors?.buyerRegex?.message}
        defaultValue={parserDefaultValues.buyerRegex}
        onResetChanges={handleResetChanges}
        countMatched={countMatchedBuyerRegex}
      />
      <TextField
        className='FormEmailParsers__docTypeRegexLabel'
        field='doc_type_regex'
        onConfirmUpdate={handleUpdateEmailParsers}
        id={id}
        value={docTypeRegex}
        label='Doc Type Identifier'
        hideConfirmButton={isCreation}
        onChange={handleChangeFormValues}
        error={formState.errors?.docTypeRegex?.message}
        defaultValue={parserDefaultValues.docTypeRegex}
        onResetChanges={handleResetChanges}
        countMatched={countMatchedDocTypeRegex}
      />

      <DropdownField
        id={id}
        field='doc_type_value'
        label='Doc Type'
        onConfirmUpdate={handleUpdateEmailParsers}
        onChange={handleChangeFormValues}
        value={docTypeValue}
        hideConfirmButton={isCreation}
        options={docTypeOptions}
        defaultValue={parserDefaultValues.docTypeValue}
        onResetChanges={handleResetChanges}
      />

      {isCreation && (
        <Button type='submit' className='FormEmailParsers__submitButton'>
          Create
        </Button>
      )}

      {createdAt && updatedAt && (
        <div className='FormEmailParsers__timezoneWrapper'>
          <div className='FormEmailParsers__timezone'>
            <span>
              <strong>Created</strong>:{' '}
            </span>
            <span>
              {DateTime.fromISO(createdAt).toFormat('EEE, LLL-dd HH:mm')}
            </span>
          </div>

          {!isEqualCreatedUpdatedAt && (
            <div className='FormEmailParsers__timezone updatedAt'>
              <span>
                <strong>Updated</strong>:{' '}
              </span>
              <span>
                {DateTime.fromISO(updatedAt).toFormat('EEE, LLL-dd HH:mm')}
              </span>
            </div>
          )}
        </div>
      )}
    </form>
  )
}

FormEmailParsers.defaultProps = {
  emailParser: {
    docTypeValue: 'Invoice',
  },
}

export default memo(FormEmailParsers)
