/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import type { IPaymentTypeFormProps } from './type'
import type { IPaymentTypeFormValues } from '~/types/models/IPaymentType'
import { useQueryGlCodes } from '~/hooks/useQueryData'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { PAYMENT_TYPE_OPTIONS } from '~/utils/constants'

const PaymentTypeForm = (props: IPaymentTypeFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { glCodeOptions } = useQueryGlCodes()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
      },
      {
        name: 'glCodeId',
        label: 'GL Code',
        isRequired: true,
        options: glCodeOptions,
        type: EFieldType.singleSelect,
      },
      {
        name: 'paymentType',
        label: 'Payment Type',
        isRequired: true,
        options: PAYMENT_TYPE_OPTIONS,
        type: EFieldType.singleSelect,
      },
    ],
    [glCodeOptions],
  )

  const defaultValues = useMemo<IPaymentTypeFormValues>(
    () => ({
      glCodeId: null,
      name: '',
      paymentType: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        glCodeId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        paymentType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        name: Yup.string().required('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IPaymentTypeFormValues) => {
      const { errors, ...response } = await apiClient.paymentTypes.create({
        paymentType: {
          name: formValues.name,
          glCodeId: formValues.glCodeId,
          paymentType: formValues.paymentType,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IPaymentTypeFormValues) => {
      if (formValues.id) {
        const { errors, ...response } = await apiClient.paymentTypes.update(
          formValues.id,
          {
            paymentType: {
              name: formValues.name,
              glCodeId: formValues.glCodeId,
              paymentType: formValues.paymentType,
            },
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.createSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IPaymentTypeFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default PaymentTypeForm
