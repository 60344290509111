import {
  checkmarkCircle,
  informationCircleOutline,
  warningOutline,
} from 'ionicons/icons'

import type { IGetCheckSetupCompanyResponse } from '~/types/models/ICheck'
import type { INullOrUndefined } from '~/types/models/ICommonModel'
import type { IToggleSectionIconProps } from '../components'

const getCheckSectionIconProps = (
  detailsData: IGetCheckSetupCompanyResponse | INullOrUndefined,
  name: string,
  extraTooltip?: React.ReactNode,
) => {
  const iconProps: IToggleSectionIconProps = {
    icon: '',
  }

  if (detailsData) {
    iconProps.isHidden = false
    const { remainingSteps, blockingSteps } = detailsData.onboard
    if (remainingSteps.includes(name) && blockingSteps.includes(name)) {
      iconProps.icon = warningOutline
      iconProps.color = 'danger'
      iconProps.tooltipProps = {
        content: 'Blocking',
      }
    } else if (remainingSteps.includes(name)) {
      iconProps.icon = informationCircleOutline
      iconProps.color = 'orange'
      iconProps.tooltipProps = {
        content: 'Not Complete',
      }
    } else {
      iconProps.icon = checkmarkCircle
      iconProps.color = 'success'
      iconProps.tooltipProps = {
        content: 'Completed',
      }
    }
  } else {
    iconProps.isHidden = true
  }

  if (extraTooltip) {
    iconProps.tooltipProps = {
      content: (
        <div>
          {iconProps.tooltipProps?.content && (
            <span>{iconProps.tooltipProps?.content}</span>
          )}
          {extraTooltip}
        </div>
      ),
    }
  }

  return iconProps
}

export default getCheckSectionIconProps
