import React from 'react'
import PropTypes from 'prop-types'

import { IonInput } from '@ionic/react'

function Placeholder(props) {
  const { error, touched } = props

  return (
    <div
      className={`placeholder-preview ${error && touched ? 'has-error' : ''}`}
    >
      <IonInput
        type='file'
        onClick={e => {
          e.preventDefault()
        }}
      />
    </div>
  )
}

Placeholder.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
}

export default Placeholder
