import { useMemo } from 'react'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'

import { IBuyerSellerProduct } from '~/types/models/IBuyerSellerProduct'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { EScope } from '~/types/enums/ECommonEnum'

const useTableCompanyBuyerSellerProductSubTab = () => {
  const { buyerSellersData } = useQueryBuyerSellers()

  const columns = useMemo<IRTColumnDef<IBuyerSellerProduct>[]>(
    () => [
      {
        header: 'Seller',
        id: 'sellerId',
        accessorFn(rowData) {
          const buyerSellerId = rowData.buyerSellerId
          const buyerSeller = buyerSellersData.find(
            ({ id }) => id === buyerSellerId,
          )
          return buyerSeller?.seller
            ? `${buyerSeller?.seller.code} ${buyerSeller?.seller.name}`
            : ''
        },
        size: 250,
        Cell({ row }) {
          const buyerSellerId = row.original.buyerSellerId
          const buyerSeller = buyerSellersData.find(
            ({ id }) => id === buyerSellerId,
          )
          const company = buyerSeller?.seller

          return (
            <CompanyInfo
              defaultCompanyCode='Seller'
              companyType={EScope.seller}
              company={{
                name: company?.name,
                code: company?.code,
                value: company?.id,
                image: company?.imageUrl,
                label: company
                  ? `${company?.code} - ${company?.name}`
                  : 'Unknown',
              }}
            />
          )
        },
      },
      {
        header: 'Buyer',
        id: 'buyerId',
        accessorFn(rowData) {
          const buyerSellerId = rowData.buyerSellerId
          const buyerSeller = buyerSellersData.find(
            ({ id }) => id === buyerSellerId,
          )
          return buyerSeller?.buyer
            ? `${buyerSeller?.buyer.code} ${buyerSeller?.buyer.name}`
            : ''
        },
        size: 250,
        Cell({ row }) {
          const buyerSellerId = row.original.buyerSellerId
          const buyerSeller = buyerSellersData.find(
            ({ id }) => id === buyerSellerId,
          )
          const company = buyerSeller?.buyer

          return (
            <CompanyInfo
              defaultCompanyCode='Buyer'
              companyType={EScope.buyer}
              company={{
                name: company?.name,
                code: company?.code,
                value: company?.id,
                image: company?.imageUrl,
                label: company
                  ? `${company?.code} - ${company?.name}`
                  : 'Unknown',
              }}
            />
          )
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
    ],
    [buyerSellersData],
  )

  return { columns }
}

export default useTableCompanyBuyerSellerProductSubTab
