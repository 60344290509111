import type { ISvgIconProps } from './type'

const ClockInIcon = (props: ISvgIconProps) => {
  const { size = 16, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M5.062 12A8.001 8.001 0 0 1 21 13a8 8 0 0 1-8 8H8m5-8V9m-2-6h4M3 15h5m-3 3h5'
      />
    </svg>
  )
}

export default ClockInIcon
