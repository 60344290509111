import { useContext } from 'react'
import StorageContext from './StorageContext'

const useStorage = () => {
  const { storedValue, setStoredValue } = useContext(StorageContext)

  return [storedValue, setStoredValue]
}

export { default as StorageProvider } from './StorageProvider'

export default useStorage
