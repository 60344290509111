import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { ICommonOption } from '~/types/models/ICommonModel'
import type { IUser } from '~/types/models/IUser'
import useModifyGroups from './useModifyGroups'
import { IGetGroupsParams, IGroup } from '~/types/models/IGroup'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryGroups = (
  params: IGetGroupsParams = { includeCoordinates: true },
  _options?: Partial<UseQueryOptions<IGroup[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addGroup, updateGroup, removeGroup } = useModifyGroups(params)
  const queryKey = useMemo(() => ['groups', sessionUser?.id], [sessionUser])

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { groups } = await apiClient.groups.get(params)
      return groups
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const groupsData = useMemo(() => data || [], [data])

  const groupsObj = useMemo(
    () => Object.fromEntries(groupsData.map(group => [group.id, group])),
    [groupsData],
  )

  const groupOptions = useMemo<ICommonOption[]>(() => {
    const options = groupsData.map(({ id, name, code }) => ({
      value: id,
      label: `${code} - ${name}`,
    }))

    return options
  }, [groupsData])

  return {
    groupsData,
    groupsObj,
    groupOptions,
    isLoadingGroups: isLoading,
    addGroup,
    updateGroup,
    removeGroup,
  }
}

export default useQueryGroups
