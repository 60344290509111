import companySubTabsSlice from '~/redux/reducers/ui/companySubTabs'

export const onUpdateUi = (payload, subTab) => dispatch => {
  dispatch(
    companySubTabsSlice.actions.updateSubTabUi({
      data: payload,
      subTab: subTab,
    }),
  )
}

export const onUpdateUiColumn = payload => dispatch => {
  dispatch(companySubTabsSlice.actions.updateSubTabUiColumn(payload))
}

export const onUpdateUiColumns = (payload, subTabName) => dispatch => {
  dispatch(
    companySubTabsSlice.actions.updateSubTabUiColumns({
      data: payload,
      subTab: subTabName,
    }),
  )
}
