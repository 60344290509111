import { addCircleOutline, closeCircle } from 'ionicons/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import {
  AddressForm,
  ConcordDropdownV2WithFormControl,
  ConcordFormAddressInput,
  ConcordFormCheckbox,
  ConcordFormStructure,
  DeleteIcon,
  IConcordFormField,
  IConcordFormFieldRenderParams,
  IRTColumnDef,
  ReusableTable,
} from '~/components/shared'
import {
  useQueryAddresses,
  useQueryUserGroups,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { EAddressableType, EAddressType } from '~/types/enums/EAddress'
import { EFieldType, ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import buildFullName from '~/utils/buildFullName'
import { sessionService } from 'redux-react-session'

import './styles.scss'
import { IAddress } from '~/types/models/IAddress'
import { IUser } from '~/types/models/IUser'
import moment from 'moment'
import { App as CapitalOSApp } from '@capitalos/react'
import uuid from 'react-uuid'
import { Unless, When } from 'react-if'
import { formatPhoneNumber } from '~/utils/utils'
import { IonIcon } from '@ionic/react'
import buildFullAddress from '~/utils/buildFullAddress'
import {
  ADDRESS_TYPE_OPTIONS,
  CORP_TYPE_OPTIONS,
  REGEX_US_PHONE_NUMBERS,
} from '~/utils/constants'
import { toast } from 'react-toastify'
import { produce } from 'immer'
import * as Yup from 'yup'
import { toastMessages } from '~/constants/toast-status-text'
import { Alert, Badge } from 'react-bootstrap'
import { ICommonOption } from '~/types/models/ICommonModel'

export type IGetInfoFieldsParams = {
  suffix: string
  isOptionDisabled?: (opt: any) => boolean
}

function CardsForm() {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const sessionUser: IUser = useSelector(selectSessionUser)

  const [isCreating, setIsCreating] = useState(false)
  const [isCreatingOwners, setIsCreatingOwners] = useState(false)
  const [token, setToken] = useState('')
  const [isHiddenForm, setIsHiddenForm] = useState(false)
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [isCreatingAddress, setIsCreatingAddress] = useState(false)

  const { usersData, updateUser, isLoadingUsersData } = useQueryUsers()
  const { userGroupOptions } = useQueryUserGroups()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const CREATE_USER_DATA_VALUE = -1
  const MANUALLY_PROVIDE_DATA_VALUE = -2

  const userAccessOptions = useMemo(() => {
    const options = usersData.map(user => {
      const { email, userAccess, person, phoneNumber } = user
      return {
        label: [buildFullName(person), email].filter(text => text).join(' - '),
        value: userAccess.id,
        person,
        phoneNumber,
        email,
        owner: userAccess.owner,
        admin: userAccess.admin,
      }
    })

    return [
      {
        label: '**Create User',
        value: CREATE_USER_DATA_VALUE,
      },
      {
        label: '**Manually Provide Data',
        value: MANUALLY_PROVIDE_DATA_VALUE,
      },
      ...options,
    ]
  }, [CREATE_USER_DATA_VALUE, MANUALLY_PROVIDE_DATA_VALUE, usersData])

  const personIds = usersData
    .map(({ person }) => person?.id)
    .filter(num => typeof num === 'number')

  const {
    addressesData,
    addressOptions,
    isFetchedAddressData,
    addAddress,
    getAddressTypeLabels,
  } = useQueryAddresses(
    {
      filters: {
        addressableType: [EAddressableType.company, EAddressableType.person],
        addressableId: [...(personIds as number[]), currentCompany.id],
      },
    },
    { enabled: !isLoadingUsersData },
  )

  const companyAddessOptions = addressesData
    .filter(
      ({ addressableType, addressableId }) =>
        addressableType === EAddressableType.company &&
        addressableId === currentCompany.id,
    )
    .map(address => {
      const { street, city, country, state, addressTypes } = address
      const types = getAddressTypeLabels(addressTypes)

      return {
        value: address.id,
        label: `${street}, ${city}, ${country}, ${state} (${types})`,
        address,
      }
    })

  const userSchema = Yup.object({
    userAccessId: Yup.number()
      .required('This field is required')
      .typeError('This field is required!'),

    addressId: Yup.number()
      .required('This field is required')
      .typeError('This field is required!'),

    firstName: Yup.string()
      .required('This field is required')
      .typeError('This field is required!'),

    lastName: Yup.string()
      .required('This field is required')
      .typeError('This field is required!'),

    phone: Yup.string()
      .required('This field is required!')
      .matches(REGEX_US_PHONE_NUMBERS, 'Phone number is invalid'),

    email: Yup.string()
      .required('This field is required')
      .typeError('This field is required!'),

    dateOfBirth: Yup.string()
      .required('This field is required')
      .typeError('This field is required!'),

    userAccess: Yup.object({
      userGroupId: Yup.lazy((_, { from }: any) => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.number()
              .required('This field is required')
              .typeError('This field is required!')
          }
        }
        return Yup.mixed()
      }),
    }),

    address: Yup.object({
      street: Yup.lazy((_, { from }: any) => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.string()
              .required('This field is required')
              .typeError('This field is required!')
          }
        }
        return Yup.mixed()
      }),
      city: Yup.lazy((_, { from }: any) => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.string()
              .required('This field is required')
              .typeError('This field is required!')
          }
        }
        return Yup.mixed()
      }),
      state: Yup.lazy((_, { from }: any) => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.string()
              .required('This field is required')
              .typeError('This field is required!')
          }
        }
        return Yup.mixed()
      }),
      zip: Yup.lazy((_, { from }: any) => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.string()
              .required('This field is required')
              .typeError('This field is required!')
          }
        }
        return Yup.mixed()
      }),
      addressTypes: Yup.lazy((_, { from }: any): any => {
        if (from?.length === 3) {
          const [, { value }] = from
          if (value.userAccessId === CREATE_USER_DATA_VALUE) {
            return Yup.lazy(() => {
              return Yup.array().min(1, 'At least one selected option')
            })
          }
          return Yup.mixed()
        }
        return Yup.mixed()
      }),
    }),
  })

  const schema = Yup.object({
    addressId: Yup.number()
      .required('This field is required')
      .typeError('This field is required!'),

    // owners: Yup.lazy((_, { parent }) => {
    //   if (parent.isOwner) {
    //     return Yup.array()
    //       .min(1, 'At least one owner selected')
    //       .typeError('At least one owner selected')
    //   }
    //   return Yup.mixed()
    // }),
    owners: Yup.mixed(),
    displayLabel: Yup.string()
      .required('This field is required')
      .typeError('This field is required'),
    controlPerson: userSchema,
  })

  const getAdminBadges = (adminField: boolean | string[]) => {
    if (Array.isArray(adminField)) {
      const el: React.ReactNode[] = []
      if (adminField.includes('admin')) {
        el.push(
          <Badge className='CardsForm__optBadge' bg='secondary'>
            Admin
          </Badge>,
        )
      }
      if (adminField.includes('payroll')) {
        el.push(
          <Badge className='CardsForm__optBadge' bg='success'>
            Payroll
          </Badge>,
        )
      }
      if (adminField.includes('card')) {
        el.push(
          <Badge
            className='CardsForm__optBadge'
            style={{ backgroundColor: 'var(--ion-color-export) !important' }}
          >
            Card
          </Badge>,
        )
      }
      return el
    }
    return null
  }

  const ownerDataColumns: IRTColumnDef<any>[] = [
    {
      header: 'Fullname',
      accessorKey: 'fullName',
      Cell({ row: { original } }) {
        return buildFullName(original)
      },
    },
    {
      header: 'Date of Birth',
      accessorKey: 'dateOfBirth',
      minSize: 150,
      maxSize: 150,
      Cell({ cell }) {
        const cellData = cell.getValue()
        if (cellData) {
          return moment(cellData).format('YYYY-MM-DD')
        }
        return ''
      },
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
      minSize: 120,
      maxSize: 120,
      Cell({ row: { original } }) {
        return formatPhoneNumber(original.phone)
      },
    },
    {
      header: 'Address',
      accessorKey: 'address',
      minSize: 300,
      maxSize: 300,
      Cell({ row: { original } }) {
        if (original.addressId) {
          const address = addressOptions.find(
            ({ value }) => value === original.addressId,
          )
          return address?.label || '-'
        }
        if (original.address) {
          return buildFullAddress(original.address)
        }

        return '-'
      },
    },
  ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createUser = async (formValues: any) => {
    const { user } = await apiClient.user.create({
      user: {
        email: formValues.email,
        phoneNumber: formValues.phone,
        password: formValues.password,
        userAccessesAttributes: [
          {
            userGroupId: formValues.userAccess.userGroupId,
            companyId: currentCompany.id,
          },
        ],
        personAttributes: {
          firstName: formValues.firstName,
          middleName: formValues.middleName,
          lastName: formValues.lastName,
          dob: moment(formValues.dateOfBirth).format('YYYY-MM-DD'),
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      },
    } as any)
    if (user.errors.length) {
      toast.error(user.errors[0])
      return {
        user: null,
        person: null,
        userAccess: null,
        address: null,
      }
    } else {
      const [peopleRes, userAccessRes] = await Promise.all([
        apiClient.people.get({
          filters: {
            userId: user.id,
          },
        }),
        apiClient.userAccesses.get({
          filters: {
            userId: user.id,
          },
        }),
      ])
      const person = peopleRes.people[0]
      const userAccess = userAccessRes.userAccesses[0]
      const { address: addressValues } = formValues
      const response = await apiClient.addresses.create({
        street: addressValues.street,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zip,
        country: addressValues.country,
        addressTypes: addressValues.addressTypes,
        addressableId: person?.id,
        addressableType: EAddressableType.person,
      })
      let address: IAddress | null = null
      if (response.errors.length) {
        toast.error(response.errors[0])
      } else {
        address = response
      }
      return {
        user,
        person,
        userAccess,
        address,
      }
    }
  }

  const getOwnersPayload = async (formValues: any) => {
    const owners: any[] = []
    const apiCalls: any = []
    ;(formValues || []).forEach((user: any) => {
      if (user.userAccessId === CREATE_USER_DATA_VALUE) {
        apiCalls.push(createUser(user))
      } else if (user.userAccessId === MANUALLY_PROVIDE_DATA_VALUE) {
        owners.push({
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          dateOfBirth: user.dateOfBirth,
          addressLine1: user.address.street,
          city: user.address.city,
          state: user.address.state,
          zipCode: user.address.zip,
        })
      } else {
        owners.push({
          addressId: user.addressId,
          userAccessId: user.userAccessId,
        })
      }
    })

    if (apiCalls.length) {
      const responses = await Promise.all(apiCalls)
      responses.forEach(({ address, userAccess }) => {
        if (address && userAccess) {
          owners.push({
            addressId: address.id,
            userAccessId: userAccess.id,
          })
        }
      })
    }

    return owners
  }

  // function generateRandomFourDigitNumber(): number {
  //   // Generate a random number between 1000 and 9999 (inclusive)
  //   const min = 1000
  //   const max = 9999
  //   const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
  //   return randomNum
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitForm = async (formValues: any) => {
    setIsLoadingForm(true)
    try {
      const { controlPerson, owners } = formValues
      const controlPersonPayload: any = {
        isOwner: Boolean(controlPerson.isOwner),
      }
      const createAccountUserPayload: any = {
        accountUser: {
          role: 'admin',
          displayLabel: formValues.displayLabel,
        },
      }

      if (controlPerson.userAccessId === CREATE_USER_DATA_VALUE) {
        const userCreateResponse = await createUser(formValues.controlPerson)
        controlPersonPayload.userAccessId = userCreateResponse.userAccess?.id
        controlPersonPayload.addressId = userCreateResponse.address?.id
        createAccountUserPayload.userAccessId =
          userCreateResponse.userAccess?.id
      } else if (controlPerson.userAccessId === MANUALLY_PROVIDE_DATA_VALUE) {
        controlPersonPayload.firstName = controlPerson.firstName
        controlPersonPayload.lastName = controlPerson.lastName
        controlPersonPayload.phone = controlPerson.phone
        controlPersonPayload.dateOfBirth = controlPerson.dateOfBirth
        controlPersonPayload.addressLine1 = controlPerson.address.street
        controlPersonPayload.city = controlPerson.address.city
        controlPersonPayload.state = controlPerson.address.state
        controlPersonPayload.zipCode = controlPerson.address.zip

        createAccountUserPayload.accountUser.email = controlPerson.email
        createAccountUserPayload.accountUser.firstName = controlPerson.firstName
        createAccountUserPayload.accountUser.lastName = controlPerson.lastName
        createAccountUserPayload.accountUser.phone = controlPerson.phone
      } else {
        controlPersonPayload.addressId = controlPerson.addressId
        controlPersonPayload.userAccessId = controlPerson.userAccessId
        createAccountUserPayload.userAccessId = controlPerson.userAccessId
        createAccountUserPayload.accountUser.userAccessId =
          controlPerson.userAccessId

        const user = usersData.find(({ userAccess }) => userAccess)
        const response = await apiClient.users.update(user?.id as number, {
          user: {
            phoneNumber: controlPerson.phone,
            email: controlPerson.email,
          },
        })
        const personResponse = await apiClient.people.update(
          user?.person?.id as number,
          {
            person: {
              firstName: controlPerson.firstName,
              lastName: controlPerson.lastName,
              dob: controlPerson.dateOfBirth
                ? moment(controlPerson.dateOfBirth).format('YYYY-MM-DD')
                : undefined,
            },
          },
        )
        updateUser(response.user.id, {
          ...response.user,
          person: personResponse,
        })
      }

      const ownersPayload = await getOwnersPayload(owners)
      await apiClient.cos.createAccount({
        account: {
          addressId: formValues.addressId,
          controlPerson: controlPersonPayload,
          owners: ownersPayload,
        },
      })

      const { error } = await apiClient.cos.createAccountUser(
        createAccountUserPayload,
      )
      await apiClient.companies.update(currentCompany.id, {
        company: {
          corpType: formValues.corpType,
          legalName: formValues.legalName,
        },
      })
      if (error) {
        toast.error(error.message)
      } else {
        const company = await apiClient.companies.getById(currentCompany.id)
        await sessionService.saveUser(
          produce(sessionUser, draft => {
            if (draft.company) {
              draft.company = company
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const findUserByUserAccessId = (userAccessId: number) =>
    usersData.find(({ userAccess }) => userAccess.id === userAccessId)

  const isHiddenPersonalInfoFields =
    (suffix: string) =>
    ({ watch }: IConcordFormFieldRenderParams) => {
      const userAccessId = watch(buildName(suffix, 'userAccessId'), null)
      return !userAccessId
    }

  const isHiddenAddressFields =
    (suffix: string) =>
    ({ watch }: IConcordFormFieldRenderParams) => {
      const userAccessId = watch(buildName(suffix, 'userAccessId'), null)

      return ![CREATE_USER_DATA_VALUE, MANUALLY_PROVIDE_DATA_VALUE].includes(
        userAccessId,
      )
    }

  const isHiddenAddressIdField =
    (suffix: string) =>
    ({ watch }: IConcordFormFieldRenderParams) => {
      const userAccessId = watch(buildName(suffix, 'userAccessId'), null)

      if (!userAccessId) {
        return true
      }

      return [CREATE_USER_DATA_VALUE, MANUALLY_PROVIDE_DATA_VALUE].includes(
        userAccessId,
      )
    }

  const afterCreateAddress = (suffix: string) => (newAddress: IAddress) => {
    formRef.current?.setValue(buildName(suffix, 'addressId'), newAddress.id)
    setIsCreating(false)
    addAddress(newAddress)
  }

  const buildName = (suffix: string, name: string) => {
    if (suffix) {
      return `${suffix}.${name}`
    }
    return name
  }

  const onSubmitOwnerForm =
    ({ setValue, watch }: IConcordFormFieldRenderParams) =>
    (formValues: any) => {
      const owners = watch('owners', [])
      const controlPerson = formRef.current?.getValues()?.controlPerson
      if (controlPerson?.userAccessId === formValues?.userAccessId) {
        formRef.current?.setValue('controlPerson.isOwner', true)
      }

      setValue('owners', [
        {
          ...formValues,
          id: formValues.id || uuid(),
        },
        ...owners,
      ])
      setIsCreatingOwners(false)
    }

  const getInfoFields = (params: IGetInfoFieldsParams): IConcordFormField[] => {
    const { suffix, isOptionDisabled } = params

    const fields: IConcordFormField[] = [
      {
        name: buildName(suffix, 'userAccessId'),
        label: 'User',
        isRequired: true,
        options: userAccessOptions,
        type: EFieldType.singleSelect,
        render({ control, name, label, setValue, clearErrors }) {
          return (
            <ConcordDropdownV2WithFormControl
              control={control}
              name={name}
              label={label}
              isRequired
              options={userAccessOptions}
              isOptionDisabled={isOptionDisabled}
              formatOptionLabel={(
                opt: ICommonOption<{
                  owner: boolean
                  admin: boolean | string[]
                }>,
              ) => {
                const { owner, admin } = opt

                return (
                  <div>
                    <span>{opt.label}</span>
                    {owner && <Badge style={{ marginLeft: 4 }}>Owner</Badge>}
                    {getAdminBadges(admin)}
                  </div>
                )
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={({ selectedOption }: any) => {
                if (
                  [
                    CREATE_USER_DATA_VALUE,
                    MANUALLY_PROVIDE_DATA_VALUE,
                  ].includes(selectedOption.value)
                ) {
                  setValue(buildName(suffix, 'firstName'), '')
                  setValue(buildName(suffix, 'lastName'), '')
                  setValue(buildName(suffix, 'dateOfBirth'), '')
                  setValue(buildName(suffix, 'phone'), '')
                  setValue(buildName(suffix, 'addressId'), -1)
                } else {
                  const { person, phoneNumber, email } = selectedOption
                  setValue(
                    buildName(suffix, 'firstName'),
                    person?.firstName || '',
                  )
                  setValue(
                    buildName(suffix, 'lastName'),
                    person?.lastName || '',
                  )
                  setValue(buildName(suffix, 'dateOfBirth'), person?.dob || '')

                  const address = addressOptions.find(
                    ({ address }) =>
                      address.addressableId === person?.id &&
                      address.addressableType === EAddressableType.person,
                  )
                  setValue(
                    buildName(suffix, 'addressId'),
                    address?.value || null,
                  )
                  if (phoneNumber) {
                    setValue(buildName(suffix, 'phone'), phoneNumber)
                  }
                  if (email) {
                    setValue(buildName(suffix, 'email'), email)
                  }
                }
                clearErrors([
                  buildName(suffix, 'firstName'),
                  buildName(suffix, 'lastName'),
                  buildName(suffix, 'dateOfBirth'),
                  buildName(suffix, 'phone'),
                  buildName(suffix, 'addressId'),
                ])
              }}
            />
          )
        },
      },
      {
        name: buildName(suffix, 'isOwner'),
        isHidden: suffix !== 'controlPerson',
        type: EFieldType.checkbox,
        label: 'Is Owner?',
        render({ label, name, setValue, watch }) {
          const isOwner = watch(name, false)
          const owners = watch('owners', [])
          const controlPerson = watch('controlPerson', {})
          const isDisable = !controlPerson?.userAccessId

          return (
            <ConcordFormCheckbox
              label={label}
              value={isOwner}
              isDisabled={isDisable}
              onChange={(event: any, checked: boolean) => {
                setValue(name, checked)
                if (checked) {
                  const newOwners = produce(owners, (draft: any) => {
                    draft.push({
                      ...controlPerson,
                      id: uuid(),
                      address: {},
                      userAccess: {},
                    })
                  })
                  setValue('owners', newOwners)
                } else {
                  const newOwners = produce(owners, (draft: any[]) => {
                    const index = draft.find(
                      ({ userAccessId }) =>
                        userAccessId === controlPerson.userAccessId,
                    )
                    if (index !== -1) {
                      draft.splice(index, 1)
                    }
                  })
                  setValue('owners', newOwners)
                }
              }}
            />
          )
        },
      },
      {
        name: buildName(suffix, 'firstName'),
        label: 'First Name',
        size: 6,
        isRequired: true,
        isHidden: isHiddenPersonalInfoFields(suffix),
      },
      {
        name: buildName(suffix, 'lastName'),
        label: 'Last Name',
        size: 6,
        isRequired: true,
        isHidden: isHiddenPersonalInfoFields(suffix),
      },
      {
        name: buildName(suffix, 'password'),
        label: 'Password',
        size: 12,
        isRequired: true,
        type: EFieldType.password,
        isHidden({ watch }) {
          const userAccessId = watch(buildName(suffix, 'userAccessId'))
          return userAccessId !== CREATE_USER_DATA_VALUE
        },
      },
      {
        name: buildName(suffix, 'phone'),
        label: 'Phone',
        size: 6,
        isRequired: true,
        isHidden: isHiddenPersonalInfoFields(suffix),
        type: EFieldType.number,
        isPhoneNumber: true,
      },
      {
        name: buildName(suffix, 'email'),
        label: 'Email',
        size: 6,
        isRequired: true,
        isHidden: isHiddenPersonalInfoFields(suffix),
      },
      {
        name: buildName(suffix, 'dateOfBirth'),
        label: 'Date of Birth',
        size: 6,
        isRequired: true,
        isHidden: isHiddenPersonalInfoFields(suffix),
        type: EFieldType.date,
        yearRange: [1950, moment().subtract(16, 'years').year()],
      },
      {
        name: buildName(suffix, 'userAccess.userGroupId'),
        label: 'User Group',
        size: 6,
        isRequired: true,
        isHidden: ({ watch }) =>
          watch(buildName(suffix, 'userAccessId')) !== CREATE_USER_DATA_VALUE,
        type: EFieldType.singleSelect,
        options: userGroupOptions,
      },
      {
        label: 'Search address',
        name: 'searchAddress',
        type: EFieldType.custom,
        size: 12,
        isHidden: isHiddenAddressFields(suffix),
        render({ setValue, clearErrors }) {
          return (
            <ConcordFormAddressInput
              label='Search Address'
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(selectedOption: any, { address }: any) => {
                Object.keys(address).forEach(key => {
                  if (key === 'postalCode') {
                    setValue(
                      buildName(suffix, 'address.zip'),
                      address.postalCode,
                    )
                  } else {
                    setValue(buildName(suffix, `address.${key}`), address[key])
                  }
                  clearErrors([
                    buildName(suffix, 'address.street'),
                    buildName(suffix, 'address.city'),
                    buildName(suffix, 'address.state'),
                    buildName(suffix, 'address.zip'),
                    buildName(suffix, 'address.country'),
                    buildName(suffix, 'address.addressTypes'),
                  ])
                })
              }}
            />
          )
        },
      },
      {
        name: buildName(suffix, 'address.street'),
        label: 'Street',
        size: 6,
        isRequired: true,
        isHidden: isHiddenAddressFields(suffix),
      },
      {
        name: buildName(suffix, 'address.city'),
        label: 'City',
        size: 6,
        isRequired: true,
        isHidden: isHiddenAddressFields(suffix),
      },
      {
        name: buildName(suffix, 'address.state'),
        label: 'State',
        size: 6,
        isRequired: true,
        isHidden: isHiddenAddressFields(suffix),
      },
      {
        name: buildName(suffix, 'address.zip'),
        label: 'Zip',
        size: 6,
        isRequired: true,
        isHidden: isHiddenAddressFields(suffix),
      },
      {
        name: buildName(suffix, 'address.country'),
        label: 'Country',
        size: 6,
        isHidden: isHiddenAddressFields(suffix),
      },
      {
        label: 'Address Types',
        name: buildName(suffix, 'address.addressTypes'),
        size: 6,
        type: EFieldType.multipleSelect,
        isHidden: isHiddenAddressFields(suffix),
        options: ADDRESS_TYPE_OPTIONS.filter(
          ({ isDriverOption }) => isDriverOption,
        ),
        isRequired: true,
      },
      {
        name: buildName(suffix, 'addressId'),
        label: 'Address',
        type: EFieldType.singleSelect,
        options: addressOptions,
        isRequired: true,
        size: 6,
        isHidden:
          isHiddenAddressIdField(suffix) || isHiddenPersonalInfoFields(suffix),
        render({ control, watch, name, label }) {
          const userAccessId = watch(buildName(suffix, 'userAccessId'), null)
          const user = findUserByUserAccessId(userAccessId)
          const options = addressOptions.filter(
            ({ address }) =>
              address.addressableId === user?.person?.id &&
              address.addressableType === EAddressableType.person,
          )

          return (
            <ConcordDropdownV2WithFormControl
              control={control}
              name={name}
              label={label}
              options={options}
              isRequired
              extraIcons={[
                {
                  icon: isCreating ? closeCircle : addCircleOutline,
                  color: isCreating ? 'danger' : 'concord',
                  onClick() {
                    setIsCreating(prev => !prev)
                  },
                },
              ]}
            />
          )
        },
      },
      {
        name: buildName(suffix, 'addressForm'),
        isHidden({ watch }) {
          const userAccessId = watch(buildName(suffix, 'userAccessId'), null)
          return !(userAccessId && isCreating)
        },
        render({ watch }) {
          const userAccessId = watch(buildName(suffix, 'userAccessId'), null)
          const user = findUserByUserAccessId(userAccessId)

          return (
            <AddressForm
              className='CardsForm__form'
              addressableType={EAddressableType.person}
              addressableId={user?.person?.id}
              onCancel={() => {
                setIsCreating(prev => !prev)
              }}
              cancelText='Close'
              afterCreate={afterCreateAddress(suffix)}
              formData={{
                addressTypes: [EAddressType.residence],
              }}
            />
          )
        },
      },
    ]

    return fields
  }

  const isHiddenCompanyAddressForm = useMemo(() => {
    if (companyAddessOptions.length === 0) {
      return false
    }
    return !isCreatingAddress
  }, [companyAddessOptions.length, isCreatingAddress])

  const fields: IConcordFormField[] = [
    {
      label: 'Display Label',
      name: 'displayLabel',
      placeholder: 'Ex: AVJR',
      isRequired: true,
      size: 4,
    },
    {
      label: 'Legal Name',
      name: 'legalName',
      size: 4,
    },
    {
      label: 'Corp Type',
      name: 'corpType',
      size: 4,
      type: EFieldType.singleSelect,
      options: CORP_TYPE_OPTIONS,
    },
    {
      label: 'Address',
      name: 'addressId',
      isRequired: true,
      type: EFieldType.singleSelect,
      options: companyAddessOptions,
      size: 12,
      isHidden: companyAddessOptions.length === 0 || !isFetchedAddressData,
      extraIcons: [
        {
          icon: isCreatingAddress ? closeCircle : addCircleOutline,
          color: isCreatingAddress ? 'danger' : 'concord',
          onClick() {
            setIsCreatingAddress(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'addressForm',
      isHidden: isHiddenCompanyAddressForm || !isFetchedAddressData,
      containerClassName: 'CardsForm__addressFormContainer',
      render({ setValue }) {
        return (
          <AddressForm
            className='CardsForm__addressForm'
            addressableType={EAddressableType.company}
            addressableId={currentCompany.id}
            onCancel={() => {
              setIsCreatingAddress(prev => !prev)
            }}
            cancelText='Close'
            isHiddenCancelButton={addressesData.length === 0}
            afterCreate={(newAddress: IAddress) => {
              setIsCreatingAddress(false)
              addAddress(newAddress)
              setValue('addressId', newAddress.id)
            }}
            formData={{
              addressTypes: [EAddressType.hr],
            }}
          />
        )
      },
    },
    {
      name: 'controlPerson',
      label: 'Control Person',
      fields: getInfoFields({
        suffix: 'controlPerson',
      }),
    },
    {
      name: 'owners',
      label: '',
      isHidden({ watch }) {
        const userAccessId = watch('controlPerson.userAccessId')
        return !userAccessId
      },
      render(params) {
        const { watch, error } = params
        const owners = watch('owners', [])

        return (
          <div>
            <div className='CardsForm__ownersSection'>
              <div style={{ marginBottom: 12 }}>
                <div className='headerContainer'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 600, fontSize: 15 }}>
                      Owners
                    </span>
                    {owners.length > 0 && (
                      <Badge style={{ marginLeft: 4 }} bg='danger'>
                        {owners.length}
                      </Badge>
                    )}
                    <span
                      className='createIcon icon'
                      onClick={() => {
                        setIsCreatingOwners(prev => !prev)
                      }}
                    >
                      <IonIcon
                        icon={isCreatingOwners ? closeCircle : addCircleOutline}
                        color={isCreatingOwners ? 'danger' : 'concord'}
                      />
                    </span>
                  </div>
                </div>

                {error && (
                  <div
                    className='error'
                    style={{
                      verticalAlign: 'middle',
                      marginLeft: 4,
                      fontSize: '12px',
                    }}
                  >
                    {error}
                  </div>
                )}
              </div>

              <When condition={isCreatingOwners}>
                <ConcordFormStructure
                  isHiddenCancelButton
                  fields={getInfoFields({
                    suffix: '',
                    isOptionDisabled: opt => {
                      return Boolean(
                        owners.find(
                          ({ userAccessId }: any) => opt.value === userAccessId,
                        ),
                      )
                    },
                  })}
                  schema={userSchema}
                  onSubmit={onSubmitOwnerForm(params)}
                  submitText='Add'
                  className='CardsForm__form ownersForm'
                />
              </When>

              <ReusableTable
                tableHeight={300}
                data={owners}
                columns={ownerDataColumns}
                enableRowActions
                enableColumnPinning
                initialState={{
                  columnPinning: {
                    left: [ERTDisplayColumnId.actions],
                  },
                }}
                displayColumnDefOptions={{
                  [ERTDisplayColumnId.actions]: {
                    minSize: 60,
                    maxSize: 60,
                  },
                }}
                renderRowActions={({ row: { original, index } }) => [
                  {
                    icon: <DeleteIcon color='white' />,
                    color: 'danger',
                    onClick() {
                      const formData = formRef.current?.getValues()
                      const controlPerson = formData?.controlPerson
                      if (
                        controlPerson?.userAccessId === original.userAccessId
                      ) {
                        formRef.current?.setValue(
                          'controlPerson.isOwner',
                          false,
                        )
                      }
                      formRef.current?.setValue(
                        'owners',
                        produce(formData.owners, (draft: any) => {
                          draft.splice(index, 1)
                        }),
                      )
                    },
                  },
                ]}
              />
            </div>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (currentCompany.capitalosUid) {
      apiClient.cos
        .login({
          userAccessId: sessionUser.userAccess.id,
        })
        .then(({ token, error }) => {
          if (error) {
            toast.error(error.message)
          } else {
            setToken(token)
          }
        })
      setIsHiddenForm(true)
    } else {
      setIsHiddenForm(false)
    }
  }, [currentCompany.capitalosUid, sessionUser.userAccess?.id])

  return (
    <div>
      <Unless condition={isHiddenForm}>
        <div className='CardsForm__container'>
          <Alert variant='info' style={{ fontSize: 13 }}>
            <div style={{ fontWeight: 600 }}>
              Sign up for embedded credit cards to pay vendors
            </div>
            <ul>
              <li>Get instant cashback</li>
              <li>
                Provide safe cards to your employees for corporate purchases
              </li>
              <li>Reconcile expenses easily</li>
              <li>Both virtual and physical cards available</li>
              <li>
                Lock cards for only certain purchases types, vendors or limits
              </li>
            </ul>
          </Alert>
          <ConcordFormStructure
            fields={fields}
            ref={formRef}
            onSubmit={onSubmitForm}
            formData={{
              legalName: currentCompany.legalName,
              corpType: currentCompany.corpType,
              owners: [],
              phoneNumber: sessionUser.phoneNumber,
              controlPerson: {
                address: {
                  addressTypes: [],
                },
                userAccess: {
                  userGroupId: [],
                },
              },
            }}
            schema={schema}
            isLoading={isLoadingForm}
            isHiddenCancelButton
          />
        </div>
      </Unless>
      {token ? <CapitalOSApp token={token} /> : null}
    </div>
  )
}

export default CardsForm
