import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCompanyTimeZone } from '~/redux/selectors'
import moment from 'moment'
import momentTz from 'moment-timezone'
import ct from 'countries-and-timezones'

const useFormatDateToTz = () => {
  const companyTimezone = useSelector(selectCompanyTimeZone)

  const getDateFromISODateWithTz = useCallback(
    (date: string) => {
      const dateWithoutZone = momentTz
        .tz(date, companyTimezone)
        .format('YYYY-MM-DDTHH:mm:ss.SSS')
      const localZone = moment(dateWithoutZone).format('Z')
      const dateWithLocalZone = [dateWithoutZone, localZone].join('')
      return new Date(dateWithLocalZone)
    },
    [companyTimezone],
  )

  const getDateFromJsDateWithTz = useCallback(
    (date: Date) => {
      const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
      const otherZone = momentTz.tz(date, companyTimezone).format('Z')
      const dateWithOtherZone = [dateWithoutZone, otherZone].join('')

      return new Date(dateWithOtherZone)
    },
    [companyTimezone],
  )

  const getISODateFromISODateWithTz = useCallback(
    (date: string) => {
      const dateWithoutZone = momentTz
        .tz(date, companyTimezone)
        .format('YYYY-MM-DDTHH:mm:ss.SSS')
      const timezone = ct.getTimezone(companyTimezone)
      return `${dateWithoutZone}${timezone.dstOffsetStr || '-04:00'}`
    },
    [companyTimezone],
  )

  const formatDateWithTz = useCallback(
    (date: string | Date, format: string) => {
      let dateFormatted: Date | null = null
      if (date instanceof Date) {
        dateFormatted = getDateFromJsDateWithTz(date)
      } else {
        dateFormatted = getDateFromISODateWithTz(date)
      }
      return moment(dateFormatted).format(format)
    },
    [getDateFromISODateWithTz, getDateFromJsDateWithTz],
  )

  return {
    formatDateWithTz,
    getDateFromISODateWithTz,
    getISODateFromISODateWithTz,
  }
}

export default useFormatDateToTz
