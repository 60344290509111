import { peopleSlice } from '../reducers/data/people'

export const initializePeople = people => dispatch =>
  dispatch(peopleSlice.actions.index(people))

export const updatePerson = person => dispatch =>
  dispatch(peopleSlice.actions.update(person))

export const deletePerson = person => dispatch =>
  dispatch(peopleSlice.actions.delete(person))
