import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const FilterIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        d='M0 3h16V1H0v2ZM2 7h12V5H2v2ZM4 11h8V9H4v2ZM10 15H6v-2h4v2Z'
      />
    </svg>
  )
}

export default FilterIcon
