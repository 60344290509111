import { createSlice } from '@reduxjs/toolkit'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import DEFAULT_DOCUMENT_COLUMNS from '~/constants/documents/columns'
import { getSavedFiltersFromStorage } from '~/utils/translateSavedFilters'

const savedFilters = getSavedFiltersFromStorage({
  moduleName: 'documents',
  defaultValues: defaultModuleFilters.documents,
})
const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    columns: DEFAULT_DOCUMENT_COLUMNS,
    filters: savedFilters,
    hasUrlParams: false,
    isUploading: false,
    initialLoad: true,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateUiColumn: (state, action) => {
      let columns = state['columns']
      let { column } = action.payload
      let updateIndex = columns.findIndex(
        columnState => columnState.columnName == column.columnName,
      )
      state['columns'][updateIndex] = {
        ...state['columns'][updateIndex],
        ...column,
      }
    },
    updateUiColumns: (state, action) => {
      let columns = state['columns']

      action.payload.forEach(column => {
        let updateIndex = columns.findIndex(
          columnState => column.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state['columns'].push({ ...column })

          return
        }

        state['columns'][updateIndex] = {
          ...state['columns'][updateIndex],
          ...column,
        }
      })
    },
    updateUiFilter: (state, action) => {
      state.filters = action.payload
      state['initialLoad'] = false
    },
    updateSearchWord: (state, { payload }) => {
      state.filters.searchWord = payload
    },
  },
})

export default documentsSlice
