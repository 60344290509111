import React from 'react'
import { components } from 'react-select'

const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
    getClassNames: props.getClassNames,
  }

  return (
    <components.ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, child => {
        return child ? (
          child
        ) : props.hasValue ? (
          <components.SingleValue
            {...commonProps}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {selectProps.getOptionLabel(props.getValue()[0])}
          </components.SingleValue>
        ) : (
          <components.Placeholder
            {...commonProps}
            key='placeholder'
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}
          >
            {selectProps.placeholder}
          </components.Placeholder>
        )
      })}
    </components.ValueContainer>
  )
}

export default CustomValueContainer
