import { Button } from '~/components'
import { ArrowLeftAndRightIcon, ContextMenu } from '~/components/shared'

import './styles.scss'
import _ from 'lodash'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'
import { useMemo } from 'react'
import { moduleName } from '~/utils/constants'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useRouter } from '~/hooks/useRouter'
import { IonChip } from '@ionic/react'
import { IReusableTableRowData, ReusableTableInstance } from '../../../types'
import pluralize, { singular } from 'pluralize'

export interface IRTCompanyToolbarProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
}

const RTCompanyToolbar = <TData extends IReusableTableRowData>(
  props: IRTCompanyToolbarProps<TData>,
) => {
  const { table } = props

  const { companyViewProps, data } = table.options

  const { myCompanyMenu } = useNavigatedMenuItems()
  const currentCompany = useSelector(selectMyCurrentCompany)
  const router = useRouter()

  const companySubTabs = useMemo(() => {
    return myCompanyMenu.subtabs.map(({ label, value }) => {
      let href = ''
      if (value === moduleName.company.home) {
        href = `/companies/${currentCompany.id}`
      } else {
        href = `/companies/${currentCompany.id}?view=${value}`
      }
      const onClick = () => router.push(href)
      return { label, href, onClick }
    })
  }, [myCompanyMenu.subtabs, currentCompany.id, router])

  return (
    <>
      <div className='RTCompanyToolbar__container'>
        <div className='RTCompanyToolbar__left'>
          <div className='RTCompanyToolbar__heading'>
            <ContextMenu
              className='RTCompanyToolbar__contextMenu'
              menu={_.sortBy(companySubTabs, 'label')}
              searchable
            >
              <div className='RTCompanyToolbar__heading'>
                <span style={{ textTransform: 'capitalize' }}>
                  {_.startCase(companyViewProps?.name)}
                </span>
                <ArrowLeftAndRightIcon className='RTCompanyToolbar__headingIcon' />
              </div>
            </ContextMenu>

            <IonChip color='primary' className='RTCompanyToolbar__chip'>
              {data.length}{' '}
              {pluralize(
                _.startCase(companyViewProps?.name) || 'Unknown',
                data.length,
              )}
            </IonChip>
          </div>

          {companyViewProps?.description && (
            <div className='RTCompanyToolbar__description'>
              {companyViewProps?.description}
            </div>
          )}
        </div>
        {!companyViewProps?.isHiddenTopButton && (
          <div className='RTCompanyToolbar__right'>
            <Button onClick={companyViewProps?.onClickTopButton}>
              {companyViewProps?.buttonText || (
                <>
                  <span>Create</span>{' '}
                  <span style={{ textTransform: 'uppercase' }}>
                    {_.startCase(singular(companyViewProps?.name || ''))}
                  </span>
                </>
              )}
            </Button>
          </div>
        )}
      </div>
      {companyViewProps?.renderForm && companyViewProps.renderForm()}
    </>
  )
}

export default RTCompanyToolbar
