import { useCallback, useEffect, useMemo } from 'react'

import _ from 'lodash'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { loadButlerBlocks } from 'butler-blocks'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

import './ButlerBlock.scss'

const butlerBlocks = loadButlerBlocks(process.env.REACT_APP_BUTLER_API_KEY)

function ButlerBlock(props) {
  const {
    modelId,
    documentId,
    style,
    className,
    toolbarProps,
    onSave,
    butlerOptions,
    ...elementProps
  } = props

  const myDocument = useMemo(
    () => ({
      modelId,
      documentId,
    }),
    [documentId, modelId],
  )

  // const myDocument = {
  //   modelId: '4f3033b9-d673-4ee9-b476-be6ce6016808',
  //   documentId: '47bcfb6c-6f92-4da3-9f2a-214ceb6e1912'
  // };

  const elementStyle = useMemo(() => {
    const DEFAULT_STYLE = {}

    return _.merge(DEFAULT_STYLE, style)
  }, [style])

  const fetchDocumentData = useCallback(async (modelId, documentId) => {
    const extractionResultsResponse =
      await butlerBlocks.api.getExtractionResults(modelId, documentId)
    const { data } = extractionResultsResponse
    return data
  }, [])

  // Step 4: Handle saving labels

  // Define a submit labels function, which will pass the output of the
  // document labeler to the API to help train your model!
  const submitLabels = useCallback(
    async trainingDocumentLabels => {
      await butlerBlocks.api.submitDocumentLabels(
        myDocument.modelId,
        myDocument.documentId,
        trainingDocumentLabels.results,
      )
    },
    [myDocument.documentId, myDocument.modelId],
  )

  // This function defines what action to take when the user clicks
  // the save button in the document labeler
  const handleSaveCallback = useCallback(
    async docInfo => {
      try {
        submitLabels(docInfo.trainingDocumentLabels)
        onSave && onSave(docInfo)
      } catch (error) {
        console.log('error', error)
        toast.error(
          error.message ||
          'An error has occurred while trying to update Orc_json field.',
        )
      }
    },
    [onSave, submitLabels],
  )

  const butlerBlockId = useMemo(() => `ButlerBlock__${uuid()}`, [])

  // Step 5: Initialize your Document Labeler!
  // This function will inject the Butler Document Labeler into the
  // div element you specified earlier with the fetched document data
  const initializeDocLabeler = useCallback(
    async ({ modelId, documentId }) => {
      try {
        // using the function we defined earlier to fetch document data
        const data = await fetchDocumentData(modelId, documentId)
        // Note: the first parameter for this function should be the Id
        // that you specified in your html div element
        butlerBlocks.createDocLabeler(butlerBlockId, data, {
          onSaveCallback: handleSaveCallback,
          showToolbar: true,
          toolbarProps,
          saveActionButtonText: 'Confirm',
          hideSaveButton: !onSave,
          ...butlerOptions,
          // fieldDisplayNameFormatter: (fieldName) => {
          //   switch (fieldName) {
          //     case 'po_number':
          //       return 'PO Number';
          //     case 'shipped_date':
          //       return 'Shipped Date';
          //     case 'part_number':
          //       return 'Part Number';
          //     default:
          //       return fieldName;
          //   }
          // },
          // displayOnly: true,
          // onLabelUpdate: (docInfo: DocumentLabelerOutputDataDto) => {
          //  console.log('Label changed', docInfo)
          // }
        })
      } catch (error) {
        console.log('error', error)
        toast.error('There was an error while getting butler element.')
      }
    },
    [
      butlerBlockId,
      butlerOptions,
      fetchDocumentData,
      handleSaveCallback,
      onSave,
      toolbarProps,
    ],
  )

  useEffect(() => {
    if (modelId && documentId) {
      initializeDocLabeler(myDocument)
    } else {
      // console.log(`modelId: ${modelId}, documentId: ${documentId}`)
      // throw new Error('modelId and documentId must exist!')
      toast.error('modelId and documentId must exist!')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-undef
  }, [modelId, documentId])

  return (
    <div
      {...elementProps}
      id={butlerBlockId}
      style={elementStyle}
      className={clsx('ButlerBlock__container', className)}
    />
  )
}

ButlerBlock.propTypes = {
  documentId: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  butlerOptions: PropTypes.object,
}

export default ButlerBlock
