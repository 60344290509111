
import { CommonDialogV2 } from '~/components/shared'
import EarningCodeForm from './EarningCodeForm'

import './DialogEarningCodeForm.scss'

function DialogEarningCodeForm(props) {
  const { afterCreate, afterUpdate, formData, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title="Earning Code"
      isHiddenOkButton
    >
      <EarningCodeForm
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={formData}
        isHiddenCancelButton
      />
    </CommonDialogV2>
  )
}

export default DialogEarningCodeForm
