import { RTDefaultDisplayColumn } from '../useRTTableOptions'
import type {
  IRTColumnDef,
  IReusableTableRowData,
  RTStatefulTableOptions,
} from '../../types'
import { defaultDisplayColumnProps } from '../../utils/displayColumn.utils'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

export const getRTRowSpacerColumnDef = <TData extends IReusableTableRowData>(
  tableOptions: RTStatefulTableOptions<TData>,
): IRTColumnDef<TData> => {
  return {
    ...defaultDisplayColumnProps({
      id: ERTDisplayColumnId.spacer,
      size: 0,
      tableOptions,
    }),
    grow: true,
    ...RTDefaultDisplayColumn,
  }
}
