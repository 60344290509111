import { useMemo } from 'react'
import { Accordion } from '~/components/shared'
import { PayrollJournal } from '../PayrollJournal'
import { PayrollSummary } from '../PayrollSummary'
import { TaxLiabilityReport } from '../TaxLiabilityReport'
import { Paydays } from '../Paydays'
import { TaxElections } from '../TaxElections'
import { ContractorPaymentReports } from '../ContractorPaymentReports'
import { W2PreviewReport } from '../W2PreviewReport'
import { W4ExemptStatusReport } from '../W4ExemptStatusReport'
import { FederalEINVerifications } from '../FederalEINVerifications'
import { TaxDeposits } from '../TaxDeposits'

function ReportsSection() {
  const items = useMemo(
    () => [
      {
        title: 'Payroll Journal',
        eventKey: 'payrollJournal',
        component: <PayrollJournal />,
      },
      {
        title: 'Payroll Summary',
        eventKey: 'payrollSummary',
        component: <PayrollSummary />,
      },
      {
        title: 'Tax Liability Report',
        eventKey: 'taxLiabilityReport',
        component: <TaxLiabilityReport />,
      },
      {
        title: 'Paydays',
        eventKey: 'paydays',
        component: <Paydays />,
      },
      {
        title: 'Tax Elections',
        eventKey: 'taxElections',
        component: <TaxElections />,
      },
      {
        title: 'Contractor Payments Report',
        eventKey: 'contractorPaymentsReport',
        component: <ContractorPaymentReports />,
      },
      {
        title: 'W2 Preview Report',
        eventKey: 'w2PreviewReport',
        component: <W2PreviewReport />,
      },
      {
        title: 'W4 Exempt Status Report',
        eventKey: 'w4ExemptStatusReport',
        component: <W4ExemptStatusReport />,
      },
      {
        title: 'Federal EIN Verifications',
        eventKey: 'federalEinVerifications',
        component: <FederalEINVerifications />,
      },
      {
        title: 'Tax Deposits',
        eventKey: 'taxDeposits',
        component: <TaxDeposits />,
      },
    ],
    [],
  )

  return <Accordion items={items} />
}

export default ReportsSection
