import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'
import { getBuyerSellers } from '~/redux/actions/CommonData/buyerSellerActions'

const buyerSellerSlice = createSlice({
  name: 'buyerSellers',
  initialState: {},
  reducers: {
    initializeBuyerSellers: (state, { payload }) => {
      payload.forEach(buyerSeller => {
        state[buyerSeller.id] = buyerSeller
      })
    },
    updateBuyerSeller: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    deleteBuyerSeller: (state, { id }) => {
      delete state[id]
    },
  },
  extraReducers: builder => {
    builder.addCase(getBuyerSellers.fulfilled, (state, { payload }) => {
      payload.forEach(buyerSeller => {
        state[buyerSeller.id] = buyerSeller
      })
    })
  },
})

export const createBuyerSeller = createAsyncThunk(
  'buyerSellers/createBuyerSeller',
  async (buyerSeller, { dispatch }) => {
    try {
      const newBuyerSeller = await apiClient.buyerSellers.create(buyerSeller)
      dispatch(buyerSellerSlice.actions.updateBuyerSeller(newBuyerSeller))
      return newBuyerSeller
    } catch (error) {
      console.log(error)
    }
  },
)

export default buyerSellerSlice
