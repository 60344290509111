import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import clsx from 'clsx'

import type { IToolTipOverlayProps } from './type'

import './styles.scss'

const ToolTipOverlay: React.FC<IToolTipOverlayProps> = props => {
  const {
    content,
    children,
    placement,
    className,
    allowToShow,
    ...overlayProps
  } = props

  if (!allowToShow) {
    return children
  }

  return content ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className={clsx('tooltip', className)}> {content} </Tooltip>
      }
      {...overlayProps}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )
}

ToolTipOverlay.defaultProps = {
  allowToShow: true,
  placement: 'auto',
}

export default ToolTipOverlay
