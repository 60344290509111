import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import moment from 'moment'
import {
  IonItem,
  IonLabel,
  IonDatetime,
  IonPopover,
  IonButtons,
  IonButton,
} from '@ionic/react'

import { parseInputErrors } from '~/utils/utils'
import './DatePickerMobile.scss'

function DatePickerMobile(props) {
  const {
    input,
    displayFormat = 'YYYY-MM-DD',
    min,
    max,
    label,
    type,
    error,
    touched,
    value,
    minuteValues,
    presentation = 'date',
  } = props

  const datetime = useRef(null)

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(value || input.value)

  const bindValue = useCallback(
    event => {
      const dateFormat = moment(event.detail.value).format(displayFormat)
      setDate(dateFormat)
      input.onChange(dateFormat)
    },
    [input, displayFormat],
  )

  const onClickOpenPickDate = useCallback(() => {
    setOpen(true)
  }, [])

  const onCloseDatePicker = useCallback(() => {
    setOpen(false)
  }, [])

  const onConfirm = useCallback(() => {
    setOpen(false)
    datetime.current?.confirm()
  }, [datetime])

  useEffect(() => {
    if (value) {
      setDate(value)
    }
  }, [value])

  return (
    <div>
      <IonItem button onClick={onClickOpenPickDate}>
        <IonLabel>
          <p>{label}</p>
          {date && <h3>{moment(date).format('MMM DD, YYYY')}</h3>}
        </IonLabel>
      </IonItem>
      {touched && error && (
        <IonItem lines='none'>
          <IonLabel color='danger'>
            <FormattedMessage
              id={parseInputErrors(error)}
              defaultMessage={parseInputErrors(error)}
            />
          </IonLabel>
        </IonItem>
      )}
      <IonPopover
        className='datetime-picker-popover'
        isOpen={open}
        onDidDismiss={onCloseDatePicker}
      >
        <IonDatetime
          ref={datetime}
          type={type}
          min={min}
          max={max}
          //value={value || input.value}
          displayFormat={displayFormat}
          onIonChange={bindValue}
          showDefaultButtons
          presentation={presentation}
          minuteValues={minuteValues}
        >
          <IonButtons slot='buttons'>
            <IonButton color='primary' onClick={onConfirm}>
              Done
            </IonButton>
          </IonButtons>
        </IonDatetime>
      </IonPopover>
    </div>
  )
}

DatePickerMobile.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  displayFormat: PropTypes.string,
  meta: PropTypes.object,
  min: PropTypes.string,
  max: PropTypes.string,
}

DatePickerMobile.defaultProps = {
  displayFormat: 'MMM D, YYYY',
}

export default DatePickerMobile
