export enum EPriceEscalationCadence {
  quarterly = 'quarterly',
  annual = 'annual',
  one_time = 'one_time',
}

export enum EPriceEscalationType {
  dollar = 'dollar',
  percent = 'percent',
}
