import { createSlice } from '@reduxjs/toolkit'
import { defaultFilters, defaultColumns } from '~/constants/salesProposals/constants.js'

export const salesProposalsUiSlice = createSlice({
  name: 'salesProposals',
  initialState: {
    loading: false,
    viewMode: 'index',
    addProductRow: false,
    filters: defaultFilters,
    columns: defaultColumns,
  },
  reducers: {
    updateUi: (state, action) => {
      state[action.payload.key] = action.payload.value
    },
    updateFilters: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.filters[`${key}`] = value
      }
    },
    resetFilters: (state) => {
      state.filters = defaultFilters
    }
  },
})

export default salesProposalsUiSlice
