import { ContextMenu, VerticalThreedotsIcon } from '~/components/shared'

import './MenuButton.scss'

function MenuButton(props) {
  const { menu } = props

  return (
    <ContextMenu className='MenuButton__contextMenu' menu={menu}>
      <div className='MenuButton__container'>
        <VerticalThreedotsIcon />
      </div>
    </ContextMenu>
  )
}

MenuButton.propTypes = {}

export default MenuButton
