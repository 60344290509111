import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IUserGroup } from '~/types/models/IUserGroup'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryUserGroups = (
  options?: Partial<UseQueryOptions<IUserGroup[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['userGroups', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.userGroups.get()
      return response.userGroups
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const userGroups = useMemo(() => data || [], [data])

  const userGroupOptions = useMemo(
    () =>
      userGroups.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [userGroups],
  )

  const findUserGroupById = (id: number) =>
    userGroups.find(item => item.id === id)

  return {
    userGroups,
    userGroupOptions,
    isLoadingUserGroup: isLoading,
    findUserGroupById,
  }
}

export default useQueryUserGroups
