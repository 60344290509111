import React, { useCallback } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#1090FF'

const PDFDataTableHeader = props => {
  const { columns, data, isPinnedColumns } = props

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: '#1090FF',
      backgroundColor: isPinnedColumns ? 'white' : '#1090FF',
      borderBottomWidth: isPinnedColumns ? 0 : 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
      flexGrow: 1,
    },
    column: {
      borderRightColor: borderColor,
      borderRightWidth: isPinnedColumns ? 0 : 1,
      paddingLeft: 8,
      paddingRight: 8,
    },
    noColumn: {
      borderRightWidth: 0,
    },
    alignRight: {
      textAlign: 'right',
    },
    alignCenter: {
      textAlign: 'center',
    },
  })

  const renderPinnedRow = useCallback(
    col => {
      if (isPinnedColumns) {
        if (col.pinnedRow) {
          return col.pinnedRow({ data })
        }

        return ''
      }

      return col.Header
    },
    [data, isPinnedColumns],
  )

  const renderHeaders = useCallback(() => {
    return columns.map((col, index) => {
      const isLastCol = columns.length === index + 1

      return (
        <Text
          key={col.accessor}
          style={[
            styles.column,
            { width: col.width },
            isLastCol && styles.noColumn,
            col.align === 'right' && styles.alignRight,
            col.align === 'center' && styles.alignCenter,
          ]}
        >
          {renderPinnedRow(col)}
        </Text>
      )
    })
  }, [
    columns,
    renderPinnedRow,
    styles.alignCenter,
    styles.alignRight,
    styles.column,
    styles.noColumn,
  ])

  return <View style={styles.container}>{renderHeaders()}</View>
}

export default PDFDataTableHeader
