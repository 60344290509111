import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const RefundIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width={size}
      height={size}
      viewBox='0 0 297 297'
      fill={color}
      {...svgProps}
    >
      <path d='M286.776 37.828H27.093c-5.646 0-10.224 4.578-10.224 10.224s4.578 10.224 10.224 10.224h249.46v180.449H58.787c-21.14 0-38.339-17.199-38.339-38.339 0-16.816 10.887-31.127 25.978-36.283l-7.342 10.485c-3.239 4.626-2.115 11.001 2.51 14.239a10.176 10.176 0 0 0 5.856 1.85c3.223 0 6.395-1.521 8.384-4.36l21.477-30.671a10.217 10.217 0 0 0 1.693-7.64 10.222 10.222 0 0 0-4.204-6.6L44.128 119.93c-4.625-3.238-11-2.115-14.238 2.511-3.239 4.625-2.115 11 2.511 14.238l10.262 7.186C18.065 150.894 0 173.562 0 200.386c0 32.415 26.371 58.787 58.787 58.787h227.99c5.646 0 10.224-4.578 10.224-10.224V48.052c-.001-5.646-4.579-10.224-10.225-10.224z' />
      <path d='M138.666 173.754c0-5.646-4.578-10.224-10.224-10.224s-10.224 4.578-10.224 10.224c0 16.717 14.384 30.761 33.671 34.495v9.89c0 5.646 4.578 10.224 10.224 10.224s10.224-4.578 10.224-10.224v-9.89c19.287-3.734 33.671-17.778 33.671-34.495s-14.384-30.762-33.671-34.496v-29.421c7.687 2.528 13.224 7.719 13.224 13.41 0 5.646 4.578 10.224 10.224 10.224s10.224-4.578 10.224-10.224c0-16.717-14.384-30.761-33.671-34.495v-9.89c0-5.646-4.578-10.224-10.224-10.224s-10.224 4.578-10.224 10.224v9.89c-19.287 3.734-33.671 17.778-33.671 34.495s14.384 30.762 33.671 34.496v29.421c-7.687-2.529-13.224-7.72-13.224-13.41zm46.895 0c0 5.69-5.537 10.881-13.224 13.41v-26.82c7.687 2.528 13.224 7.719 13.224 13.41zm-46.895-50.508c0-5.69 5.537-10.881 13.224-13.41v26.82c-7.687-2.528-13.224-7.719-13.224-13.41z' />
    </svg>
  )
}
export default RefundIcon
