import billLinesSlice from '~/redux/reducers/data/billLines'

export const updateSingleBillLine = payload => dispatch => {
  dispatch(billLinesSlice.actions.updateBillLine(payload))
}

export const updateMultipleBillLines = payload => dispatch => {
  dispatch(billLinesSlice.actions.updateBillLines({ billLines: payload }))
}

export const deleteSingleBillLine = payload => dispatch => {
  dispatch(billLinesSlice.actions.deleteBillLine(payload))
}
