import { useState, useEffect, useCallback, useRef, memo } from 'react'
import { useClickAway } from 'react-use'
import clsx from 'clsx'
import ReactNumberFormat from 'react-number-format'

import './styles.scss'

const EditInlineTextField = props => {
  const {
    value,
    matched = true,
    onChange,
    defaultValue,
    name,
    className,
    children,
    ...numberFieldProps
  } = props

  const color = matched ? '#1090ff' : 'orange'

  const [numberValue, setNumberValue] = useState('')
  const inputRef = useRef()

  const handleChange = useCallback(({ value }) => {
    setNumberValue(value)
  }, [])

  const handleSubmitForm = useCallback(
    event => {
      event.preventDefault()
      onChange && onChange(event, { value: numberValue, name })
      inputRef.current.blur()
    },
    [onChange, numberValue, name],
  )

  const handleKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Escape') {
        setNumberValue(defaultValue)
      }
    },
    [defaultValue],
  )

  useClickAway(inputRef, () => {
    setNumberValue(defaultValue)
  })

  useEffect(() => {
    setNumberValue(value)
  }, [value])

  return (
    <form
      onSubmit={handleSubmitForm}
      onKeyDown={handleKeyDown}
      className='NumberFieldEditInline__form'
    >
      <ReactNumberFormat
        style={{ color }}
        getInputRef={inputRef}
        className={clsx('NumberFieldEditInline__input', className)}
        value={numberValue}
        onValueChange={handleChange}
        {...numberFieldProps}
      />
      {children}
    </form>
  )
}

export default memo(EditInlineTextField)
