import { useCallback, useEffect, useState, memo } from 'react'

import { IonLabel, IonCheckbox } from '@ionic/react'

import clsx from 'clsx'

import './ConcordFormCheckbox.scss'
import '../ConcordForm.scss'

const ConcordFormCheckbox = props => {
  const { onChange, value, className, label, isDisabled, isReadOnly } = props

  const [checkboxValue, setCheckboxValue] = useState(false)

  const handleChange = useCallback(
    event => {
      const { checked } = event.target
      onChange && onChange(event, checked)
      setCheckboxValue(checked)
    },
    [onChange],
  )

  useEffect(() => {
    setCheckboxValue(Boolean(value))
  }, [value])

  return (
    <div
      className={clsx(
        'ConcordFormCheckbox__container',
        'ConcordForm__container',
        className,
      )}
    >
      <IonLabel
        className={clsx('ConcordFormCheckbox__label ConcordForm__label')}
      >
        {label}
      </IonLabel>
      <IonCheckbox
        className={clsx('ConcordFormCheckbox__checkbox', {
          isDisabled: isDisabled || isReadOnly,
        })}
        checked={checkboxValue}
        onIonChange={handleChange}
        mode='md'
      />
    </div>
  )
}

export default memo(ConcordFormCheckbox)
