import {
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonIcon,
  IonButtons,
} from '@ionic/react'
import clsx from 'clsx'
import { NoteButton } from '~/components/shared'

import { ENoteableType } from '~/types/enums/ENote'

const LoadSection = props => {
  const {
    color,
    // onClick,
    notesData,
    icon,
    children,
    title,
    rightText,
    onChangeNotes,
    load,
    onClickRightText,
  } = props

  return (
    <>
      <IonItemGroup className='load-section'>
        <IonItemDivider color={color ? color : 'dark'}>
          <IonLabel>
            <b>{title}</b>
          </IonLabel>
          {rightText && (
            <IonLabel
              className={clsx({
                clickable: Boolean(onClickRightText),
              })}
              style={{ fontWeight: 600 }}
              slot='end'
              onClick={onClickRightText}
            >
              {rightText}
            </IonLabel>
          )}
          {icon && <IonIcon slot='end' icon={icon} />}

          {notesData && (
            <IonButtons slot='end'>
              <NoteButton
                notesData={notesData}
                onChangeNotes={onChangeNotes}
                noteableId={load?.id}
                noteableType={ENoteableType.Load}
              />
            </IonButtons>
          )}
        </IonItemDivider>
        {children}
      </IonItemGroup>
    </>
  )
}

export default LoadSection
