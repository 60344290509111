import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const MarkerPlusIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        d='M20.619 8.7c-1.04-4.63-5.08-6.7-8.62-6.7h-.01c-3.53 0-7.56 2.07-8.61 6.69-1.18 5.16 1.98 9.53 4.84 12.29a5.436 5.436 0 0 0 3.78 1.53c1.36 0 2.72-.51 3.77-1.53 2.86-2.76 6.02-7.12 4.85-12.28Zm-5.87 3.05h-2v2c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-2h-2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2v-2c0-.41.34-.75.75-.75s.75.34.75.75v2h2c.41 0 .75.34.75.75s-.34.75-.75.75Z'
      />
    </svg>
  )
}

export default MarkerPlusIcon
