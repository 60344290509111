import { Button } from 'react-bootstrap'
import {
  IReusableTableRowData,
  ReusableTableInstance,
  RTCell,
  VerticalThreedotsIcon,
} from '~/components/shared'

export interface IRTActionButtonMenuProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
  cell: RTCell<TData>
}

const RTActionButtonMenu = <TData extends IReusableTableRowData>(
  props: IRTActionButtonMenuProps<TData>,
) => {
  const { table, cell } = props

  return (
    <Button
      variant='secondary'
      style={{ fontSize: 13 }}
      onClick={e => {
        table.setActionCell(cell)
        ;(table as any).refs.actionCellRef.current = e.currentTarget
      }}
    >
      <VerticalThreedotsIcon color='white' />
    </Button>
  )
}

export default RTActionButtonMenu
