import React from 'react'
import { IonButton, IonIcon, IonButtons, IonLabel } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

const ClearFiltersButton = ({ onFiltersReset }) => {
  return (
    <div className='documents-container-clear hide-md-down'>
      <IonButtons size='lg'>
        <IonButton
          fill='clear'
          onClick={() => {
            onFiltersReset && onFiltersReset()
          }}
        >
          <IonIcon icon={closeOutline} slot='end'></IonIcon>
          <IonLabel className='container-filters-ionlabel'>
            Clear filters
          </IonLabel>
        </IonButton>
      </IonButtons>
    </div>
  )
}

export default ClearFiltersButton
