import React, { useState } from 'react'
import { string } from 'prop-types'

import { IonThumbnail } from '@ionic/react'
import { ImageViewer } from '~/components/shared'

const ItemInfo = ({ value, classes }) => {
  const [modal, setModal] = useState({ open: false, image: null })

  return (
    <IonThumbnail className={classes || ''} slot='start'>
      <img
        alt='thumb'
        style={{ 'object-fit': 'contain' }}
        src={
          value ||
          'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1528978351651x128348828991875060%2FImage_placeholder.png?w=384&h=230&auto=compress&dpr=2&fit=max' ||
          'https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg'
        }
        onClick={() =>
          setModal({
            open: true,
            image: value,
          })
        }
      />
      <ImageViewer
        isOpen={modal.open}
        image={modal?.image}
        onHidePreview={() => setModal({ open: false, image: null })}
      />
    </IonThumbnail>
  )
}

ItemInfo.propTypes = {
  value: string,
  classes: string,
}

export default ItemInfo
