import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const BankIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width={size}
      height={size}
      viewBox='0 0 64 64'
      {...svgProps}
    >
      <g fill={color}>
        <circle cx={32} cy={14} r={3} />
        <path d='M4 25h56a4 4 0 0 0 2.077-7.419l-28-17a3.997 3.997 0 0 0-4.153 0l-28 17a3.998 3.998 0 0 0-1.775 4.497A3.998 3.998 0 0 0 4 25zM32 9c2.762 0 5 2.238 5 5s-2.238 5-5 5-5-2.238-5-5 2.238-5 5-5zM34 27h8v25h-8zM46 27h8v25h-8zM22 27h8v25h-8zM10 27h8v25h-8zM4 58h56a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4zM63.445 60H.555A3.958 3.958 0 0 0 0 62v2h64v-2c0-.732-.211-1.409-.555-2z' />
      </g>
    </svg>
  )
}
export default BankIcon
