import { useCallback, useState, memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMeasure } from 'react-use'

import { IonSpinner } from '@ionic/react'
import {
  ConcordCompanyDropdown,
  ToolTipOverlay,
  ConcordLoadTerminalDropdown,
  ConcordLoadBuyerSellerProductDropdown,
} from '~/components/shared'
import { Popover } from 'react-bootstrap'

import clsx from 'clsx'
import { toast } from 'react-toastify'
import loadsSlice from '~/redux/reducers/data/loadsSlice'
import { LOAD_COLUMN_NAMES } from '~/utils/constants'
import { selectIsScopeBuyer } from '~/redux/selectors'
import { USER_SCOPE } from '~/utils/constants'
import { toastMessages } from '~/constants/toast-status-text'
import { selectCurrentCompanies } from '~/redux/selectors'

import './DropDownFormField.scss'
import { apiClient } from '~/api/ApiClient'

const DropDownFormField = props => {
  const [loadingState, setLoadingState] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const dispatch = useDispatch()

  const [containerRef, { height }] = useMeasure()

  const {
    onBlur,
    onFocus,
    showMenu,
    // load,
    currentLoad,
    value,
    fillColor,
    columnName,
    loading,
    showCreateReference,
    // name,
    onExpandRow,
    onRowSelected,
    rowIndex,
    onContextMenu,
    children,
  } = props

  const isScopeBuyer = useSelector(selectIsScopeBuyer)
  const filters = useSelector(state => state.ui.loads.filters)
  const allCompanies = useSelector(selectCurrentCompanies)

  const sellerBuyerTerminalProps = useMemo(() => {
    let companyId = currentLoad.sellerId
    let value = {}
    let rawValue =
      currentLoad.sellerTerminalCode || currentLoad.sellerTerminalName
    let key = 'sellerTerminal'

    if (columnName === LOAD_COLUMN_NAMES.buyerTerminal) {
      companyId = currentLoad.buyerId
      rawValue = currentLoad.buyerTerminalCode || currentLoad.buyerTerminalName
      key = 'buyerTerminal'
      if (currentLoad.buyerTerminal) {
        value = {
          value: currentLoad.buyerTerminal.id,
          label: currentLoad.buyerTerminal.name,
          code: currentLoad.buyerTerminal.code,
          name: currentLoad.buyerTerminal.name,
        }
      }
    } else if (
      columnName === LOAD_COLUMN_NAMES.sellerTerminal &&
      currentLoad.sellerTerminal
    ) {
      value = {
        value: currentLoad.sellerTerminal.id,
        label: currentLoad.sellerTerminal.name,
        code: currentLoad.sellerTerminal.code,
        name: currentLoad.sellerTerminal.name,
      }
    }

    return {
      companyId,
      value,
      rawValue,
      keyword: currentLoad[`${key}Name`] || currentLoad[`${key}Code`],
      updatedKey: `${key}Id`,
      key,
    }
  }, [currentLoad, columnName])

  const handleUpdateTerminal = useCallback(
    updatedTerminal => {
      const { key } = sellerBuyerTerminalProps
      if (updatedTerminal.id === currentLoad[key]?.id) {
        dispatch(
          loadsSlice.actions.updateLoad({
            [key]: updatedTerminal,
            [`${key}Code`]: updatedTerminal.code,
            [`${key}Name`]: updatedTerminal.name,
            id: currentLoad.id,
          }),
        )
      }
    },
    [currentLoad, dispatch, sellerBuyerTerminalProps],
  )

  const handleUpdateLoad = useCallback(
    key => async selectedOption => {
      setLoadingState(true)
      showCreateReference(
        selectedOption.name,
        selectedOption.value,
        selectedOption.code,
      )
      try {
        const response = await apiClient.loads.update(currentLoad.id, {
          [key]: selectedOption.value,
        })
        if (response.id) {
          dispatch(loadsSlice.actions.updateLoad(response))
          toast.success('Update load successfully!')
        } else {
          toast.error(toastMessages.updateError)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setLoadingState(false)
      }
    },
    [currentLoad.id, dispatch, showCreateReference],
  )

  const handleDeleteTerminal = useCallback(
    ({ id }) => {
      const { key } = sellerBuyerTerminalProps
      if (id === currentLoad[key]?.id) {
        handleUpdateLoad(sellerBuyerTerminalProps.updatedKey)({ value: null })
      }
    },
    [currentLoad, handleUpdateLoad, sellerBuyerTerminalProps],
  )

  const handleDeleteProduct = useCallback(
    ({ id }) => {
      if (id === currentLoad.buyerSellerProduct?.id) {
        handleUpdateLoad('buyerSellerProductId')({ value: null })
      }
    },
    [currentLoad, handleUpdateLoad],
  )

  const handleUpdateProduct = useCallback(
    updatedProduct => {
      if (updatedProduct.id === currentLoad.buyerSellerProduct?.id) {
        dispatch(
          loadsSlice.actions.updateLoad({
            buyerSellerProduct: updatedProduct,
            buyerSellerProductId: updatedProduct.id,
            id: currentLoad.id,
          }),
        )
      }
    },
    [currentLoad, dispatch],
  )

  const handleOpenDropdown = useCallback(() => {
    setShowDropdown(true)
    onExpandRow && onExpandRow(true)
    onRowSelected && onRowSelected(rowIndex - 1)
  }, [onExpandRow, onRowSelected, rowIndex])

  const rawValue = useMemo(() => {
    switch (columnName) {
      case LOAD_COLUMN_NAMES.sellerBuyer: {
        if (isScopeBuyer) {
          return {
            code: currentLoad.sellerCode,
            name: currentLoad.sellerName,
          }
        }

        return {
          code: currentLoad.buyerCode,
          name: currentLoad.buyerName,
        }
      }

      case LOAD_COLUMN_NAMES.sellerTerminal: {
        return {
          code: currentLoad.sellerTerminalCode,
          name: currentLoad.sellerTerminalName,
        }
      }

      case LOAD_COLUMN_NAMES.buyerTerminal: {
        return {
          code: currentLoad.buyerTerminalCode,
          name: currentLoad.buyerTerminalName,
        }
      }

      case LOAD_COLUMN_NAMES.buyerSellerProduct: {
        return {
          code: currentLoad.productCode,
          name: currentLoad.productName,
        }
      }

      case LOAD_COLUMN_NAMES.fleet: {
        return {
          code: currentLoad.fleetCode,
          name: currentLoad.fleetName,
        }
      }

      default:
        return {}
    }
  }, [columnName, isScopeBuyer, currentLoad])

  const tooltipOfRawValueRendered = useMemo(() => {
    if (value) {
      return null
    }

    if (rawValue.code || rawValue.name) {
      return (
        <div style={{ textAlign: 'left', fontSize: 12 }}>
          <span>Code: </span>
          <strong>{rawValue.code}</strong>
          <p />
          <span>Name: </span>
          <strong>{rawValue.name}</strong>
        </div>
      )
    }

    return null
  }, [rawValue, value])

  const sellerBuyerProps = useMemo(() => {
    let rawValue = currentLoad.buyerCode || currentLoad.buyerName
    let value = {
      value: currentLoad.buyer?.id,
      label: currentLoad.buyer?.name,
      name: currentLoad.buyer?.name,
    }
    let key = 'buyer'
    let updatedKey = 'buyerId'
    if (isScopeBuyer) {
      rawValue = currentLoad.sellerCode || currentLoad.sellerName
      value = {
        value: currentLoad.seller?.id,
        label: currentLoad.seller?.name,
        name: currentLoad.seller?.name,
      }
      key = 'seller'
      updatedKey = 'sellerId'
    }

    return {
      rawValue,
      value,
      key,
      updatedKey,
      keyword: currentLoad[`${key}Name`] || currentLoad[`${key}Code`],
    }
  }, [currentLoad, isScopeBuyer])

  // const exceptOptionTypes = useMemo(
  //   () => [
  //     LOAD_COLUMN_NAMES.sellerBuyer,
  //     LOAD_COLUMN_NAMES.sellerTerminal,
  //     LOAD_COLUMN_NAMES.buyerTerminal,
  //     LOAD_COLUMN_NAMES.buyerSellerProduct,
  //   ],
  //   [],
  // )

  const handleContextMenu = e => {
    onContextMenu && onContextMenu(e, columnName)
  }

  const renderFleetDropdown = useCallback(() => {
    const fleet = allCompanies.find(({ id }) => id === currentLoad.fleetId)

    return (
      <ConcordCompanyDropdown
        className={clsx(
          'remove-border-from-common-seller-dropdown DropDownFormField__sellerDropdown',
        )}
        rawValue={currentLoad.fleetCode || currentLoad.fleetName}
        value={{
          label: fleet?.name,
          value: fleet?.id,
        }}
        visibleData={filters?.visibleData}
        onChange={handleUpdateLoad('fleetId')}
        onOpenDropdown={handleOpenDropdown}
        onCloseDropdown={() => {
          setShowDropdown(false)
        }}
        type={USER_SCOPE.fleet}
        showRecommendedOptions={!fleet?.id}
        recommendedOptionsProps={{
          keyword: currentLoad.fleetCode || currentLoad.fleetName,
        }}
        buyerId={currentLoad.buyer?.id}
        showDropdown={showDropdown}
        styles={{
          menuList: provided => ({
            ...provided,
            maxHeight: 200,
          }),
        }}
      />
    )
  }, [
    allCompanies,
    currentLoad.buyer?.id,
    currentLoad.fleetCode,
    currentLoad.fleetId,
    currentLoad.fleetName,
    filters?.visibleData,
    handleOpenDropdown,
    handleUpdateLoad,
    showDropdown,
  ])

  return (
    <ToolTipOverlay
      className='DropDownFormField__tooltip'
      content={tooltipOfRawValueRendered}
      placement='top'
      show={showDropdown || showTooltip || showMenu}
      overlay={
        <Popover style={{ maxWidth: 500 }}>
          <Popover.Body>{tooltipOfRawValueRendered}</Popover.Body>
        </Popover>
      }
    >
      <td
        className={clsx('editable DropDownFormField__cellContainer', {
          fullHeight: fillColor,
        })}
        // onClick={e => {
        //   if (!exceptOptionTypes.includes(columnName)) {
        //     onClickInput(e)
        //   }
        // }}
        ref={containerRef}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => {
          if (!showDropdown) {
            setShowTooltip(false)
          }
        }}
        style={{ verticalAlign: 'middle' }}
        onContextMenu={e => handleContextMenu(e)}
      >
        {loadingState && (
          <div className='DropDownFormField__cellLoading'>
            <IonSpinner name='lines-small' />
          </div>
        )}

        <div
          style={{ height }}
          className={clsx('DropDownFormField__cellValue', {
            loading: loadingState || loading,
          })}
        >
          {columnName === LOAD_COLUMN_NAMES.sellerBuyer && (
            <ConcordCompanyDropdown
              className={clsx(
                'remove-border-from-common-seller-dropdown DropDownFormField__sellerDropdown',
              )}
              rawValue={sellerBuyerProps.rawValue}
              value={sellerBuyerProps.value}
              visibleData={filters?.visibleData}
              onChange={handleUpdateLoad(sellerBuyerProps.updatedKey)}
              onOpenDropdown={handleOpenDropdown}
              onCloseDropdown={() => {
                setShowDropdown(false)
              }}
              type={isScopeBuyer ? USER_SCOPE.seller : USER_SCOPE.buyer}
              addableCompany={isScopeBuyer}
              showRecommendedOptions={!sellerBuyerProps.value}
              recommendedOptionsProps={{
                keyword: sellerBuyerProps.keyword,
              }}
              buyerId={currentLoad.buyer?.id}
              showDropdown={showDropdown}
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 200,
                }),
              }}
            />
          )}

          {[
            LOAD_COLUMN_NAMES.sellerTerminal,
            LOAD_COLUMN_NAMES.buyerTerminal,
          ].includes(columnName) && (
              <ConcordLoadTerminalDropdown
                className='DropDownFormField__sellerDropdown remove-border-from-common-seller-dropdown'
                loadId={currentLoad.id}
                visibleData={filters?.visibleData}
                rawValue={sellerBuyerTerminalProps.rawValue}
                companyId={sellerBuyerTerminalProps.companyId}
                showDropdown={showDropdown}
                value={sellerBuyerTerminalProps.value}
                showRecommendedOptions={!sellerBuyerTerminalProps.value.value}
                recommendedOptionsProps={{
                  keyword: sellerBuyerTerminalProps.keyword,
                  hideAvatar: true,
                }}
                onOpenDropdown={handleOpenDropdown}
                onChange={handleUpdateLoad(sellerBuyerTerminalProps.updatedKey)}
                onCloseDropdown={() => {
                  setShowDropdown(false)
                }}
                onUpdate={handleUpdateTerminal}
                onDelete={handleDeleteTerminal}
              />
            )}
          {columnName === LOAD_COLUMN_NAMES.buyerSellerProduct && (
            <ConcordLoadBuyerSellerProductDropdown
              className='DropDownFormField__sellerDropdown remove-border-from-common-seller-dropdown'
              visibleData={filters?.visibleData}
              rawValue={currentLoad.productCode || currentLoad.productName}
              showRecommendedOptions={!currentLoad.buyerSellerProduct?.id}
              recommendedOptionsProps={{
                keyword: currentLoad.productCode || currentLoad.productName,
                hideAvatar: true,
              }}
              onChange={handleUpdateLoad('buyerSellerProductId')}
              onOpenDropdown={handleOpenDropdown}
              onCloseDropdown={() => {
                setShowDropdown(false)
              }}
              onUpdate={handleUpdateProduct}
              onDelete={handleDeleteProduct}
              value={{
                label: currentLoad.buyerSellerProduct?.name,
                value: currentLoad.buyerSellerProduct?.id,
                code: currentLoad.productCode,
                name: currentLoad.productName,
              }}
              loadId={currentLoad.id}
              buyerId={currentLoad.buyerId}
              sellerId={currentLoad.sellerId}
              sellerTerminalId={currentLoad.sellerTerminalId}
              buyerTerminalId={currentLoad.buyerTerminalId}
            />
          )}
          {columnName === LOAD_COLUMN_NAMES.fleet && renderFleetDropdown()}
        </div>
        {children}
      </td>
    </ToolTipOverlay>
  )
}

DropDownFormField.defaultProps = {
  menuIsOpen: true,
}

export default memo(DropDownFormField)
