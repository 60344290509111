const DEFAULT_COMPANY_PARSER_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Company',
    field: 'company.name',
    show: true,
    value: 'company.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'company.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Collection',
    field: 'collection',
    show: true,
    value: 'collection',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'collection',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Document Type',
    field: 'documentType',
    show: true,
    value: 'documentType',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'documentType',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Parser',
    field: 'parser',
    show: true,
    value: 'parser',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'parser',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    label: 'Uid',
    field: 'uid',
    show: true,
    value: 'uid',
    type: 'string',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'uid',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Internal use only',
    field: 'internalUseOnly',
    show: true,
    value: 'internalUseOnly',
    type: 'string',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'internalUseOnly',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 7,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_PARSER_COLUMNS
