import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLocations } from '~/redux/reducers/data/locations'
import { selectTerminals } from '~/redux/selectors'

export const useInitializeReduxLocationsStore = () => {
  const dispatch = useDispatch()
  const terminals = useSelector(selectTerminals)

  const ids = useMemo(
    () => terminals.map(terminal => terminal.location?.id).filter(id => id), //at some point location.id will become locationId
    [terminals],
  )

  useEffect(() => {
    ids && ids.length > 0 && dispatch(fetchLocations({ filters: { id: ids } }))
  }, [ids, dispatch])
}
