import _ from 'lodash'

export enum EHierarchableType {
  Pricing = 'Pricing',
  Setting = 'Setting',
  Tax = 'Tax',
  PaymentTerm = 'PaymentTerm',
  ProductTaxOverride = 'ProductTaxOverride',
  ProductGlCode = 'ProductGlCode',
  AutoCharge = 'AutoCharge',
}

export const hierarchableTypeOptions = Object.keys(EHierarchableType).map(
  key => {
    const value = key
    const label = _.startCase(key)
    return {
      value,
      label,
    }
  },
)
