import { useController, useFormContext } from 'react-hook-form'

import { ConcordFormTagsInput } from '~/components/shared'

function ConcordTagsInputWithFormControl(props) {
  const { control, name, onChange, ...tagsInputProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const methods = useFormContext()

  const handleChange = value => {
    if (typeof onChange === 'function') {
      onChange && onChange(value, { ...methods, name })
    } else {
      field.onChange(value)
    }
  }

  return (
    <ConcordFormTagsInput
      {...tagsInputProps}
      name={name}
      value={field.value}
      onChange={handleChange}
      error={fieldState.error?.message}
    />
  )
}

export default ConcordTagsInputWithFormControl
