import { useState } from 'react'

import { Alert, Button, Form, ListGroup } from 'react-bootstrap'
import { CompanyForm, PlusIcon } from '~/components/shared'
import { IonSearchbar, IonSpinner } from '@ionic/react'

import clsx from 'clsx'

import './styles.scss'
import { useQueryCompanies } from '~/hooks/useQueryData'
import { ICompany, ICompanyFormValues } from '~/types/models/ICompany'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { EBuyerSellerRelationship } from '~/types/enums/EBuyerSeller'
import { toast } from 'react-toastify'
import { CompanyCard } from '../CompanyForm/CompanyCard'

export interface IBuyerSellerFormProps {
  afterCreate?: (company: ICompany, extraParams: any) => void
  formData?: ICompanyFormValues
  hiddenFields?: string[]
}

function BuyerSellerForm(props: IBuyerSellerFormProps) {
  const { afterCreate, formData, hiddenFields } = props

  const [type, setType] = useState('')
  const [keyword, setKeyword] = useState('')
  const [companySelected, setCompanySelected] = useState<ICompany | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [backendError, setBackendError] = useState('')

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const {
    companiesDataWithoutBuyerSeller,
    isLoadingCompaniesData,
    isCompaniesDataFetched,
  } = useQueryCompanies(
    {
      filters: {
        searchKey: keyword,
      },
      all: true,
      perPage: 1000,
      enableFetchingBuyerSeller: true,
    },
    { enabled: keyword.length >= 3 },
  )

  const onSubmitBuyerSeller = async () => {
    if (companySelected) {
      setIsSubmitting(true)
      setBackendError('')
      try {
        const { errors, ...response } = await apiClient.buyerSellers.create({
          sellerId:
            currentScope === EScope.buyer
              ? companySelected.id
              : currentCompany.id,
          buyerId:
            currentScope === EScope.buyer
              ? currentCompany.id
              : companySelected.id,
          relationship: EBuyerSellerRelationship.Seller,
        })
        if (errors.length > 0) {
          setBackendError(errors[0])
        } else {
          const company =
            currentScope === EScope.buyer ? response.seller : response.buyer
          afterCreate && afterCreate(company, { buyerSeller: response })
          toast.success(toastMessages.createSuccess)
        }
      } catch (error) {
        console.log('error', error)
        setBackendError(toastMessages.serverError)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const options = [
    {
      label: 'Connect to Existing Company',
      value: 'connect',
      render: () => (
        <div>
          {backendError && (
            <Alert variant='danger' style={{ margin: '8px 0', fontSize: 13 }}>
              {backendError}
            </Alert>
          )}

          <IonSearchbar
            placeholder='Search Code, Name (at least 3 characters)'
            className='BuyerSellerForm__searchbar'
            mode='md'
            autoFocus
            value={keyword}
            debounce={1000}
            onIonInput={event => {
              setKeyword(event.detail.value as string)
              setCompanySelected(null)
            }}
          />
          {isLoadingCompaniesData ? (
            <div style={{ display: 'flex', justifyItems: 'center' }}>
              <IonSpinner name='lines-small' />
            </div>
          ) : (
            <div>
              <ListGroup className='BuyerSellerForm__companyList'>
                {companiesDataWithoutBuyerSeller.map(company => (
                  <ListGroup.Item
                    key={company.id}
                    className='d-flex  align-items-center'
                  >
                    <CompanyCard
                      company={company}
                      isChecked={company.id === companySelected?.id}
                      onSelect={() => {
                        setCompanySelected(company)
                      }}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {companiesDataWithoutBuyerSeller.length > 0 && (
                <Button
                  disabled={!companySelected || isSubmitting}
                  style={{ marginTop: 8, fontSize: 14, width: '100%' }}
                  onClick={onSubmitBuyerSeller}
                >
                  Submit
                </Button>
              )}
            </div>
          )}

          {isCompaniesDataFetched &&
          companiesDataWithoutBuyerSeller.length === 0 ? (
            <div style={{ fontSize: 14, textAlign: 'center' }}>
              <div style={{ marginBottom: 8, fontWeight: 600 }}>
                No Results Found
              </div>
              <Button
                style={{ fontSize: 14 }}
                onClick={event => {
                  event.stopPropagation()
                  setType('create')
                }}
              >
                <PlusIcon color='white' style={{ marginRight: 4 }} />
                <span style={{ verticalAlign: 'middle' }}>
                  Create New{' '}
                  <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>
                    {keyword}
                  </span>{' '}
                  Company
                </span>
              </Button>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      label: 'Create New Company',
      value: 'create',
      render: () => (
        <CompanyForm
          afterCreate={afterCreate}
          formData={{
            ...formData,
            code: keyword,
            name: keyword,
            legalName: keyword,
          }}
          hiddenFields={hiddenFields}
          canSkipPhoneNumberSetup
          canSkipEmailSetup
          canSkipAddressSetup
        />
      ),
    },
  ]

  const onSelectOpt = (value: string) => () => {
    setType(value)
    setKeyword('')
    setBackendError('')
    setCompanySelected(null)
  }

  return (
    <div className='BuyerSellerForm__container' id='BuyerSellerForm__container'>
      {options.map(({ label, value, render }) => (
        <div
          key={value}
          className={clsx('BuyerSellerForm__radioContainer', {
            isSelected: type === value,
          })}
        >
          <div onClick={onSelectOpt(value)}>
            <Form.Check type='radio' label={label} checked={type === value} />
          </div>

          {type === value && <div>{render()}</div>}
        </div>
      ))}
    </div>
  )
}

export default BuyerSellerForm
