import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_COLUMNS } from '~/constants/companySubTabs'

const parserColumnMappingsSlice = createSlice({
  name: moduleName.company.parserColumnMappings,
  initialState: {
    columns: DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_COLUMNS,
  },
})

export default parserColumnMappingsSlice
