import { useMemo, useCallback, useState } from 'react'

import { IonList } from '@ionic/react'
import { Form } from 'react-bootstrap'
import { KeyValueField } from '~/components/shared'
import { ContainerSearchBar } from '~/components/shared'
import ReactJson from 'react-json-view'

import _ from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import './KeyValueList.scss'

const KeyValueList = props => {
  const {
    rowData,
    rowItems,
    onConfirmUpdate,
    getLoadingField,
    className,
    editable,
    style,
    viewableHash,
  } = props

  const [searchKey, setSearchKey] = useState('')
  const [viewingHash, setViewingHash] = useState(false)

  const getOptionLabel = useCallback(option => {
    if (!option) {
      return null
    }
    const { code, name } = option

    if (!code) {
      return name
    }

    return `${code} - ${name}`
  }, [])

  const getRowValue = useCallback(
    row => {
      const { field, valueFormatter, valueGetter } = row
      let value = _.get(rowData, field)

      if (_.isFunction(valueGetter)) {
        value = valueGetter()
      }

      if (_.isFunction(valueFormatter)) {
        return valueFormatter(value)
      }

      return value
    },
    [rowData],
  )

  const mapRowItems = useMemo(() => {
    const rowItemsWithValue = rowItems.map(row => {
      const { options, type, getOptionValue, label } = row
      let value = getRowValue(row)
      let hide = false

      if (type === 'dropdown') {
        if (typeof value === 'string' || typeof value === 'number') {
          const findOption = options.find(
            option => getOptionValue(option) === value,
          )
          value = getOptionLabel(findOption)
        }
      }

      if (searchKey) {
        const lowerSearchKey = _.toLower(searchKey)
        const matchLabel = _.toLower(label).includes(lowerSearchKey)
        const matchValue = _.toString(value)
          .toLowerCase()
          .includes(lowerSearchKey)
        hide = !(matchLabel || matchValue)
      }

      return { ...row, hide }
    })

    return rowItemsWithValue
  }, [rowItems, getRowValue, getOptionLabel, searchKey])

  const handleChangeSearch = useCallback(newSearchKey => {
    setSearchKey(newSearchKey)
  }, [])

  const itemRendered = useMemo(() => {
    return mapRowItems
      .filter(({ hide }) => {
        if (_.isNil(hide)) {
          return true
        }

        return !hide
      })
      .map((row, index) => {
        const value = getRowValue(row)

        return (
          <div key={index} className='KeyValueList__fieldContainer'>
            <KeyValueField
              {...row}
              editable={editable}
              className='mt-3 KeyValueList__input'
              value={value}
              onConfirmUpdate={onConfirmUpdate}
              loading={getLoadingField(row.field)}
            />
          </div>
        )
      })
  }, [mapRowItems, getRowValue, editable, onConfirmUpdate, getLoadingField])

  return (
    <div
      className={clsx('KeyValueList__wrapContainer', className)}
      style={style}
    >
      <div className='KeyValueList__searchBarRoot'>
        <ContainerSearchBar
          searchBarValue={searchKey}
          onSearchBarChange={handleChangeSearch}
          className='KeyValueList__searchBar'
        />
        {viewableHash && (
          <Form.Switch
            label='View Data as hash'
            className='KeyValueList__viewHash'
            checked={viewingHash}
            onChange={event => {
              const { checked } = event.target
              setViewingHash(checked)
            }}
          />
        )}
      </div>
      {!viewingHash && (
        <IonList className='KeyValueList__container'>
          <Form className='KeyValueList__form'>{itemRendered}</Form>
        </IonList>
      )}
      {viewingHash && (
        <div style={{ marginTop: 8 }}>
          <ReactJson src={rowData || {}} name={false} collapsed={1} />
        </div>
      )}
    </div>
  )
}

KeyValueList.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        'dropdown',
        'number',
        'date',
        'date-time',
        'regex',
        'custom',
      ]),
      render: PropTypes.func,
    }),
  ).isRequired,
  onConfirmUpdate: PropTypes.func,
  getLoadingField: PropTypes.func,
  className: PropTypes.string,
  editable: PropTypes.bool,
  style: PropTypes.object,
  viewableHash: PropTypes.bool,
}

KeyValueList.defaultProps = {
  getLoadingField: () => false,
  editable: true,
}

export default KeyValueList
