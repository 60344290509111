import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useQueryUoms } from '~/hooks/useQueryData'

import moment from 'moment'
import _ from 'lodash'
import { DatePickerMobile, SimpleDatePicker } from '~/components/shared'

import {
  IonRow,
  IonCol,
  IonCardContent,
  IonLabel,
  IonButton,
  IonItem,
  IonText,
  IonInput,
  IonToggle,
  isPlatform,
  IonIcon,
  IonRadioGroup,
  IonList,
  IonRadio,
  IonPopover,
} from '@ionic/react'

import {
  timer,
  callOutline,
  lockClosedOutline,
  thumbsUpOutline,
  pauseCircleOutline,
  checkboxOutline,
  closeCircleOutline,
  arrowDownCircleOutline,
} from 'ionicons/icons'

import { Loading } from '~/components/shared'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import { defaultStatuses } from '~/components/fleet/constants'
import './OrderPartialForm.scss'
import { format } from 'date-fns'
import { apiClient } from '~/api/ApiClient'
//const date = new Date();

const OrderPartialForm = ({ preorder, handleUpdate, closePartialForm }) => {
  const [Order, setOrder] = useState({
    load_size: preorder.loadSize,
    lds: preorder.lds,
    min_lds: preorder.minLds,
    qty: preorder.qty,
    min_qty: preorder.minQty,
  })

  const [errors, setErrors] = useState({})
  //const [customDate, setCustomDate] = useState(new Date(Date.now() + 3600 * 1000 * 24 * 3));
  //const [show_search_date, setShow_search_date] = useState(false);
  //const [ startDate, setStartDate] = useState(date);
  const [showPopOver, setShowPopOver] = useState(false)
  const [orderLoading, setOrderLoading] = useState(false)

  const currentOrder = useRef(Order)
  const currentFob = useRef(preorder.fob)

  const { currentUom } = useQueryUoms()

  useEffect(() => {
    let order = Object.assign({}, currentOrder.current)
    order.fob = currentFob.current
    setOrder(order)
  }, [])

  const handleUpdateOrder = async (order, preorder) => {
    await setOrderLoading(true)
    await handleValidations(order, preorder)
    if (_.isEmpty(errors)) {
      let submittedOrder = await apiClient.orders.update(
        preorder.id,
        {
          order,
          preorder,
        },
        {
          orderId: order.id,
        },
      )
      if (submittedOrder) {
        await handleUpdate(submittedOrder)
        order.id = ''
        await closePartialForm(false)
      }
    }
    await setOrderLoading(false)
  }

  const handleValidations = (order, preorder) => {
    let errors = {}
    setErrors(errors)
    if (
      parseInt(
        order.earliest_arrival_time || preorder.earliestArrivalTime,
        10,
      ) > parseInt(order.latest_arrival_time || preorder.latestArrivalTime, 10)
    ) {
      errors.latestArrivalTime =
        'Latest Arrival Time Must Be After Earliest Arrival Time'
    }
    if (!order.lds || !preorder.lds) {
      errors.lds = 'Total LDs is Required'
    }
    if (!order.min_lds || !preorder.minLds) {
      errors.min_lds = 'Minimum LDs is Required'
    }
    if (!order.load_size || !preorder.loadSize) {
      errors.loadSize = 'Load Size is Required'
    }
    if (!order.qty || !preorder.qty) {
      errors.qty = `Total ${currentUom?.code} is Required`
    }
    if (!order.min_qty || !preorder.minQty) {
      errors.min_qty = `Minimum ${currentUom?.code} is Required`
    }
    if (parseFloat(order.lds) < parseFloat(order.min_lds)) {
      errors.lds = 'Totals LDs must be greater than Minimum LDs'
    }
    setErrors(errors)
  }

  const orderStatusColor = status => {
    switch (status) {
      case 'Requested':
        return 'medium'
      case 'Will Call':
        return 'secondary'
      case 'Confirmed':
        return 'primary'
      case 'Active':
        return 'success'
      case 'Hold':
        return 'warning'
      case 'Complete':
        return 'dark'
      case 'Canceled':
        return 'danger'
      default:
        return 'medium'
    }
  }

  const orderStatusIcon = status => {
    switch (status) {
      case 'Requested':
        return timer
      case 'Will Call':
        return callOutline
      case 'Confirmed':
        return lockClosedOutline
      case 'Active':
        return thumbsUpOutline
      case 'Hold':
        return pauseCircleOutline
      case 'Complete':
        return checkboxOutline
      case 'Canceled':
        return closeCircleOutline
      default:
        return timer
    }
  }

  const onShowPopOver = () => {
    setShowPopOver(true)
  }

  const onHidePopOver = () => {
    setShowPopOver(false)
  }

  return (
    <IonCardContent size='5' style={{ padding: '5px' }}>
      <IonRow>
        <IonCol size='6' className='datepickernotcentre'>
          <div style={{ position: 'absolute', zIndex: '2' }}>
            <IonLabel
              style={{ fontSize: 14, color: 'gray', marginLeft: '10px' }}
            >
              {' '}
              Date{' '}
            </IonLabel>

            <SimpleDatePicker
              value={
                Order.id == preorder.id && Order.start_date
                  ? Order.start_date
                  : moment(preorder.startTime).toDate()
              }
              onChange={async date => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                order.start_date = date
                order.new_date = date
                setOrder(order)
              }}
            />
          </div>
        </IonCol>
        <IonCol size='6' style={{ zIndex: '1' }}>
          <IonRow>
            <IonCol>
              <IonItem>
                {!isPlatform('mobile') && (
                  <IonLabel style={{ fontSize: 14, color: 'gray' }}>
                    Status
                  </IonLabel>
                )}
                <IonText
                  color={orderStatusColor(
                    Order && Order.status ? Order.status : preorder.status,
                  )}
                >
                  {Order && Order.status ? Order.status : preorder.status}
                </IonText>

                <span onClick={onShowPopOver}>
                  <IonIcon
                    icon={arrowDownCircleOutline}
                    color='black'
                    slot='end'
                  />
                </span>
                <IonPopover
                  style={{
                    width: 'auto',
                    top: '-2%',
                    left: '10px',
                  }}
                  isOpen={showPopOver}
                  onDidDismiss={onHidePopOver}
                  className='popoverStatus'
                  mode='ios'
                  keepContentsMounted
                >
                  <IonList>
                    <IonRadioGroup
                      value={
                        Order && Order.status ? Order.status : preorder.status
                      }
                      onIonChange={e => {
                        let order = Object.assign({}, Order)
                        order.status = e.target.value
                        setOrder(order)
                      }}
                    >
                      {defaultStatuses.map((status, index) => (
                        <IonItem key={index}>
                          <IonIcon
                            icon={orderStatusIcon(status.value)}
                            color={orderStatusColor(status.value)}
                            slot='start'
                          />
                          <IonLabel>
                            <IonText color={orderStatusColor(status.value)}>
                              {status.label}
                            </IonText>
                          </IonLabel>
                          <IonRadio
                            value={status.value}
                            onClick={onHidePopOver}
                          />
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </IonList>
                </IonPopover>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <DatePickerMobile
              label='Earliest Arrival'
              value={
                Order.id == preorder.id && Order.earliest_arrival_time
                  ? Order.earliest_arrival_time
                  : moment(preorder.startTime).format('HH:mm')
              }
              onIonChange={e => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                order.earliest_arrival_time = format(e.detail.value, 'HH:mm')
                setOrder(order)
              }}
              displayFormat='HH:mm'
              minuteValues='0,15,30,45'
              presentation={'time'}
            />
          </IonItem>
          {errors && errors.latestArrivalTime && (
            <h3 className='error'>{errors.latestArrivalTime}</h3>
          )}
        </IonCol>
        <IonCol>
          <IonItem>
            <DatePickerMobile
              label='Latest Arrival'
              value={
                Order.id == preorder.id && Order.latest_arrival_time
                  ? Order.latest_arrival_time
                  : moment(preorder.endTime).format('HH:mm')
              }
              onIonChange={e => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                order.latest_arrival_time = format(e.detail.value, 'HH:mm')
                setOrder(order)
              }}
              displayFormat='HH:mm'
              minuteValues='0,15,30,45'
              presentation={'time'}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonToggle
              checked={Order.fob}
              value='fob'
              color='secondary'
              style={{
                paddingLeft: 0,
                marginTop: '5px',
                marginBottom: '16px',
              }}
              onIonChange={e => {
                let order = Object.assign({}, Order)
                order.fob = e.detail.checked
                setOrder(order)
              }}
            />
            <IonLabel style={{ marginTop: 0, marginBottom: '10px' }}>
              <IonText color={Order.fob ? 'secondary' : 'dark'}>
                <b>{Order.fob ? 'FOB' : 'Delivery'}</b>
              </IonText>
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              LD Size
            </IonLabel>
            <IonInput
              type='number'
              inputmode='decimal'
              value={
                Order.id == preorder.id ? Order.load_size : preorder.loadSize
              }
              onIonInput={text => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                const value = text.target.value == '' ? '0' : text.target.value
                order.min_qty = (order.min_lds || '0') * value
                order.qty = (order.lds || '0') * value
                order.load_size = text.target.value
                setOrder(order)
              }}
            />
          </IonItem>
          {errors && errors.loadSize && (
            <h3 className='error'>{errors.loadSize}</h3>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              Total LDs
            </IonLabel>
            <IonInput
              type='number'
              inputmode='decimal'
              name='lds'
              value={Order.id == preorder.id ? Order.lds : preorder.lds}
              onIonInput={text => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                const loadSize = order.load_size || '0'
                order.qty = loadSize * text.target.value
                order.lds = text.target.value
                order.based_on_lds = true
                setOrder(order)
              }}
            />
          </IonItem>
          {errors && errors.lds && <h3 className='error'>{errors.lds}</h3>}
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              Minimum LDs
            </IonLabel>
            <IonInput
              type='number'
              inputmode='decimal'
              name='min_lds'
              value={Order.id == preorder.id ? Order.min_lds : preorder.minLds}
              onIonInput={text => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                const loadSize = order.load_size || '0'
                order.min_qty = loadSize * text.target.value
                order.min_lds = text.target.value
                order.based_on_lds = true
                setOrder(order)
              }}
            />
          </IonItem>
          {errors && errors.min_lds && (
            <h3 className='error'>{errors.min_lds}</h3>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              Total {currentUom?.code}
            </IonLabel>
            <IonInput
              type='number'
              inputmode='decimal'
              value={Order.id == preorder.id ? Order.qty : preorder.qty}
              onIonInput={text => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                order.lds = order.load_size
                  ? text.target.value / order.load_size
                  : '0'
                order.qty = text.target.value
                order.based_on_lds = false
                setOrder(order)
              }}
            />
          </IonItem>
          {errors && errors.qty && <h3 className='error'>{errors.qty}</h3>}
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel className='input-label floating' position='floating'>
              Minimum {currentUom?.code}
            </IonLabel>
            <IonInput
              type='number'
              inputmode='decimal'
              name='min_qty'
              value={Order.id == preorder.id ? Order.min_qty : preorder.minQty}
              onIonInput={text => {
                let order = Object.assign({}, Order)
                order.id = preorder.id
                order.min_lds = order.load_size
                  ? text.target.value / order.load_size
                  : '0'
                order.min_qty = text.target.value
                order.based_on_lds = false
                setOrder(order)
              }}
            />
          </IonItem>
          {errors && errors.min_qty && (
            <h3 className='error'>{errors.min_qty}</h3>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            expand='block'
            type='submit'
            onClick={async e => {
              e.persist()
              let order = Object.assign({}, Order)
              let startTimeDefault = moment(
                order.start_date || preorder.startTime,
              ).format('YYYY-MM-DD')
              let earliestArrivalTime =
                order.earliest_arrival_time ||
                moment(preorder.startTime).format('HH:mm')
              let latestArrivalTime =
                order.latest_arrival_time ||
                moment(preorder.endTime).format('HH:mm')
              let earliestArrivalDateTime =
                moment(startTimeDefault).format('YYYY-MM-DD') +
                ' ' +
                earliestArrivalTime +
                preorder.buyerTerminalTimezone
              let latestArrivalDateTime =
                moment(startTimeDefault).format('YYYY-MM-DD') +
                ' ' +
                latestArrivalTime +
                preorder.buyerTerminalTimezone
              let calcLoadSize = order.load_size
              order.start_time = earliestArrivalDateTime
              order.order_products_attributes = [
                {
                  id: preorder.currentOrderProduct.id,
                  load_size: calcLoadSize,
                  lds: order.lds,
                  qty: order.qty,
                  min_lds: order.min_lds,
                  min_qty: order.min_qty,
                },
              ]
              order.end_time = latestArrivalDateTime

              setOrder(order)
              await handleUpdateOrder(Order, preorder)
            }}
            disabled={orderLoading}
          >
            {orderLoading ? (
              <Loading />
            ) : (
              <IonLabel>Update Order Request</IonLabel>
            )}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCardContent>
  )
}

const mapState = state => ({
  currentUser: state.session.user,
  currentUserSession: state.session,
})

export default connect(mapState)(OrderPartialForm)
