import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useCompanySubTabUi from '~/hooks/useCompanySubTab/useCompanySubTabUi'

import { onUpdateUiColumns } from '~/redux/actions/CompanySubTabs/subTabUiActions'
import { IonCol } from '@ionic/react'
import ContainerFilterV3 from '~/components/shared/ContainerFilterV2/ContainerFilterV3'

const CompanyTableFilters = props => {
  const { subTabName, showSearch, showFiltersReset, ...filterProps } = props

  const {
    handleUpdateSortOptions,
    handleUpdateColumns,
    columnsFromUserTableConfigurations,
  } = useCompanySubTabUi(subTabName)

  const dispatch = useDispatch()

  useEffect(() => {
    if (columnsFromUserTableConfigurations?.length > 0) {
      dispatch(
        onUpdateUiColumns(columnsFromUserTableConfigurations, subTabName),
      )
    }
  }, [columnsFromUserTableConfigurations, subTabName, dispatch])

  return (
    <IonCol sizeXl={10} sizeLg={8} sizeMd={12} sizeSm={12} sizeXs={12}>
      <div className='CompanyTable__filterSection'>
        <ContainerFilterV3
          columnsFromUserTableConfigurations={
            columnsFromUserTableConfigurations
          }
          onChangeColumnsDisplayed={handleUpdateColumns}
          onSortChange={handleUpdateSortOptions}
          showFiltersReset
          showSearch={showSearch}
          searchPlaceholder='Search'
          {...filterProps}
        />
      </div>
    </IonCol>
  )
}

CompanyTableFilters.defaultProps = {
  showSearch: true,
}

export default CompanyTableFilters
