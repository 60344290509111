import type { ISvgIconProps } from './type'

const ViewMapIcon = (props: ISvgIconProps) => {
  const { size = 16, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      width={size}
      height={size}
      data-name='Layer 1'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <defs>
        <style>
          {'.cls-1{fill:none;stroke-miterlimit:10;stroke-width:1.91px}'}
        </style>
      </defs>
      <path
        d='m17.17 20.03 5.33 1.52V4.36l-6.68-1.9-7.64 1.9-6.68-1.9v17.18l6.68 1.91 6.25-1.57'
        stroke={color}
        className='cls-1'
      />
      <path
        d='M19.64 15.58c0 2.39-3.82 6-3.82 6S12 18 12 15.58A3.7 3.7 0 0 1 15.82 12a3.7 3.7 0 0 1 3.82 3.58Z'
        stroke={color}
        className='cls-1'
      />
      <circle
        cx={15.82}
        cy={15.82}
        r={0.95}
        style={{
          fill: color,
        }}
      />
      <path
        d='M8.18 4.36v17.19M15.82 2.45V12'
        className='cls-1'
        stroke={color}
      />
    </svg>
  )
}
export default ViewMapIcon
