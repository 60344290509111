import { useRef, useMemo } from 'react'
import { useClickAway } from 'react-use'

import { Modal, Alert } from 'react-bootstrap'
import { IonIcon, IonButton } from '@ionic/react'

import {
  checkmarkCircleOutline,
  alertCircleOutline,
  timeOutline,
} from 'ionicons/icons'

import './PopupResultCheckout.scss'

function PopupResultCheckout(props) {
  const { show, onClose, status } = props

  const ref = useRef()

  const contentPopup = useMemo(() => {
    const content = {
      title: '',
      subTitle: '',
      icon: alertCircleOutline,
      color: 'danger',
    }

    switch (status) {
      case 'succeeded': {
        content.icon = checkmarkCircleOutline
        content.subTitle = 'Your purchase was successfull!'
        content.title = 'Thank you'
        content.color = 'lightgreen'
        break
      }

      case 'processing': {
        content.icon = timeOutline
        content.subTitle =
          "Your purchase is in progress, we will send you an email when it's done"
        content.title = 'Thank you'
        content.color = 'orange'
        break
      }

      default: {
        content.icon = alertCircleOutline
        content.title = 'Error!'
        content.subTitle = 'Something went wrong, please try again!'
      }
    }

    return content
  }, [status])

  useClickAway(ref, () => {
    onClose && onClose()
  })

  if (show) {
    return (
      <Modal.Dialog
        className='PopupResultCheckout__container'
        ref={ref}
        centered
      >
        <Modal.Body className='PopupResultCheckout__body'>
          <Alert show variant='light' className='PopupResultCheckout__content'>
            <IonIcon
              className='PopupResultCheckout__icon'
              icon={contentPopup.icon}
              color={contentPopup.color}
            />
            <h2>{contentPopup.title}</h2>
            <h4>{contentPopup.subTitle}</h4>
            <hr />
            <div className='d-flex justify-content-end'>
              <IonButton
                color={contentPopup.color}
                fill='outline'
                className='PopupResultCheckout__button'
                onClick={onClose}
              >
                Got it!
              </IonButton>
            </div>
          </Alert>
        </Modal.Body>
      </Modal.Dialog>
    )
  }

  return null
}

PopupResultCheckout.propTypes = {}

export default PopupResultCheckout
