import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useRouter } from '~/hooks/useRouter'
import useDefaultPage from '~/hooks/useDefaultPage'
import { useDeepCompareEffect } from 'react-use'

import { IonPage } from '@ionic/react'

import _ from 'lodash'
import {
  driverRoutesPaths,
  fleetRoutesPaths,
  documentRoutesPaths,
  loadsCreatePaths,
} from '~/constants/routesPaths'
import { PROFILE_SETTINGS, USER_SCOPE } from '~/utils/constants'
import {
  selectSessionUser,
  selectCurrentScope,
  selectUsersDriverFleet,
  selectIsSigningIn,
} from '~/redux/selectors'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import useStoreGeneral from '~/hooks/useStoreGeneral'
import { EStatus } from '~/types/enums/ECommonEnum'

const AuthenticationPage = ({ children, className }) => {
  const { authenticated, ...rest } = useSelector(state => state.session)
  const isSigningIn = useSelector(selectIsSigningIn)

  const currentScope = useSelector(selectCurrentScope)
  const user = useSelector(selectSessionUser)
  const driverFleet = useSelector(selectUsersDriverFleet)

  const router = useRouter()
  const { defaultPage } = useDefaultPage()
  const { localStoredData } = useStoreGeneral()

  const isMobile = useIsMobile()

  const storeDefaultPage = useMemo(() => {
    try {
      const data = localStorage.getItem('concord_materials')
      const decodeData = JSON.parse(data)
      let defaultPage = _.get(decodeData, 'general.others.data.defaultPage')
      if (defaultPage === PROFILE_SETTINGS.lastPage) {
        defaultPage = _.get(decodeData, 'general.others.data.lastPage')
      }

      return defaultPage
    } catch (error) {
      return ''
    }
  }, [])

  const isCheckAuthenticated = useMemo(() => {
    if (isMobileApp()) {
      if (localStoredData.isUsingBiometric) {
        return isSigningIn
      }

      return true
    }

    return authenticated
  }, [authenticated, isSigningIn, localStoredData])

  useDeepCompareEffect(() => {
    if (isCheckAuthenticated && _.size(user) > 0) {
      let url = ''
      const lowerCaseScope = _.lowerCase(currentScope)
      switch (lowerCaseScope) {
        case USER_SCOPE.seller:
        case USER_SCOPE.buyer: {
          if (isMobile) {
            url = loadsCreatePaths.loadsCapture
          } else {
            url = documentRoutesPaths.documents
          }
          break
        }

        case _.lowerCase(USER_SCOPE.driver_fleet):
        case USER_SCOPE.driver: {
          if (driverFleet) {
            url = driverRoutesPaths.startTimes
          } else {
            url = driverRoutesPaths.driverProfile
          }
          break
        }

        case USER_SCOPE.fleet: {
          url = fleetRoutesPaths.dispatchDashboard
          break
        }

        default: {
          url = '/sign_up'
        }
      }

      if (user.userAccess?.status === EStatus.Pending) {
        url = '/sign_up'
      }

      if (router.query.back_url) {
        url = decodeURIComponent(router.query.back_url)
      } else if (storeDefaultPage) {
        url = storeDefaultPage
      }

      router.push(url)
    }
  }, [
    defaultPage,
    isCheckAuthenticated,
    currentScope,
    rest,
    user,
    storeDefaultPage,
    router.query.back_url,
    driverFleet,
  ])

  return <IonPage className={className}>{children}</IonPage>
}

export default AuthenticationPage
