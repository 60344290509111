import { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import useTableCompanyTerminalSubTab from './useTableCompanyTerminalSubTab'
import { useConfirmationProvider } from '~/contexts'

import {
  CheckHqIcon,
  DeleteIcon,
  DialogTerminalForm,
  EditIcon,
  ReusableTable,
  ViewMapIcon,
  DialogCheckWorkplace,
} from '~/components/shared'

import _ from 'lodash'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import {
  useInitializeCompaniesStore,
  useInitializeReduxTerminals,
} from '~/hooks/useInitializeRedux/stores'
import { useQueryBuyerSellers, useQueryTerminals } from '~/hooks/useQueryData'
import { useWindowSize } from 'react-use'
import DialogTerminalDetails from '~/containers/TerminalContainer/DialogTerminalDetails'

function CompanyTerminalSubTab() {
  useInitializeCompaniesStore()
  useInitializeReduxTerminals()

  const windowSize = useWindowSize()

  const currentUser = useSelector(selectSessionUser)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const [formState, setFormState] = useState({
    isOpen: false,
    formData: undefined,
  })
  const [checkFormState, setCheckFormState] = useState({
    isOpen: false,
    formData: undefined,
  })
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'view',
      value: EScope.company,
    },
  ])
  const [terminalDetailsModal, setTerminalDetailsModal] = useState({
    isOpen: false,
    id: null,
  })

  const filterData = useMemo(() => {
    const filter = {}
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  const {
    isLoadingBuyerSellers,
    sellerRelationshipOptions,
    buyerRelationshipOptions,
  } = useQueryBuyerSellers()

  const companyId = useMemo(() => {
    if (filterData.view === 'all') {
      return undefined
    } else if (filterData.view === EScope.buyer) {
      return buyerRelationshipOptions.map(({ value }) => value)
    } else if (filterData.view === EScope.seller) {
      return sellerRelationshipOptions.map(({ value }) => value)
    }
    return [currentCompany.id]
  }, [
    buyerRelationshipOptions,
    currentCompany.id,
    filterData.view,
    sellerRelationshipOptions,
  ])

  const {
    terminalsData,
    isLoadingTerminals,
    updateTerminal,
    addTerminal,
    removeTerminal,
    refetchTerminalsData,
  } = useQueryTerminals(
    {
      filters: {
        companyId,
      },
    },
    {
      enabled: companyId ? companyId.length > 0 : true,
    },
  )

  const isLoadingTable = isLoadingTerminals || isLoadingBuyerSellers

  const { confirmation } = useConfirmationProvider()

  const viewFilterOptions = useMemo(() => {
    const opts = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'My Company',
        value: EScope.company,
      },
    ]

    if (currentUser.currentScope === EScope.buyer) {
      opts.push({
        label: 'Sellers',
        value: EScope.seller,
      })
    } else {
      opts.push({
        label: 'Buyers',
        value: EScope.buyer,
      })
    }

    return opts
  }, [currentUser.currentScope])

  const filterOptions = [
    {
      label: 'Terminals of',
      field: 'view',
      type: EFieldType.singleSelect,
      options: viewFilterOptions,
    },
  ]

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete terminal #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        try {
          const { errors } = await apiClient.terminals.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            removeTerminal(rowData.id)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleOpenUpdateForm = useCallback(async rowData => {
    setFormState({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const onCellEditEnd = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id
        const completeForm = { [columnField]: value }
        const { errors, ...terminal } = await apiClient.terminals.update(
          rowId,
          completeForm,
        )
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          updateTerminal(rowId, terminal)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onToggleForm = useCallback(nextValue => {
    setFormState({
      isOpen: nextValue,
      formData: undefined,
    })
  }, [])

  const afterCreateTerminal = useCallback(
    newItem => {
      addTerminal(newItem)
      onToggleForm(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggleForm],
  )

  const afterUpdateTerminal = useCallback(
    updatedItem => {
      updateTerminal(updatedItem.id, updatedItem)
      onToggleForm(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggleForm],
  )

  const { columns } = useTableCompanyTerminalSubTab()

  const renderRowActions = useCallback(
    ({ row }) => {
      const rowData = row.original
      const isWorkplaceCreated = Boolean(rowData.checkUid)

      return [
        {
          icon: <EditIcon color='white' />,
          onClick: () => handleOpenUpdateForm(rowData),
          tooltipProps: {
            content: 'Edit',
            placement: 'top',
          },
        },
        {
          icon: <ViewMapIcon color='white' />,
          color: 'success',
          tooltipProps: {
            content: 'View On Map',
            placement: 'top',
          },
          onClick() {
            setTerminalDetailsModal({
              isOpen: true,
              id: rowData.id,
            })
          },
        },
        {
          icon: <CheckHqIcon color='white' />,
          color: isWorkplaceCreated ? 'primary' : 'secondary',
          tooltipProps: {
            content: 'Check Workplace',
            placement: 'top',
          },
          isHidden: !isWorkplaceCreated,
          onClick: () => {
            setCheckFormState({
              isOpen: true,
              formData: rowData,
            })
          },
        },
        {
          icon: <DeleteIcon color='white' />,
          onClick: () => handleDeleteRow(rowData),
          color: 'danger',
          tooltipProps: {
            content: 'Remove',
            placement: 'top',
          },
        },
      ]
    },
    [handleDeleteRow, handleOpenUpdateForm],
  )

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableCompanyView
          enableTopToolbar
          enableGlobalFilter
          enableRowActions
          enableColumnPinning
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: currentCompany.checkUid ? 135 : 115,
            },
          }}
          companyViewProps={{
            name: moduleName.company.terminals,
            description:
              'Locations where products are brought from or sold to.',
            onClickTopButton() {
              onToggleForm(true)
            },
          }}
          filterOptions={filterOptions}
          columns={columns}
          data={terminalsData}
          tableHeight={windowSize.height - 220}
          state={{
            isLoading: isLoadingTable,
            columnFilters,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={renderRowActions}
          onColumnFiltersChange={setColumnFilters}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogTerminalForm
        isOpen={formState.isOpen}
        formData={formState.formData}
        onClose={() => onToggleForm(false)}
        afterCreate={afterCreateTerminal}
        afterUpdate={afterUpdateTerminal}
      />
      <DialogCheckWorkplace
        isOpen={checkFormState.isOpen}
        terminal={checkFormState.formData}
        onClose={() => {
          setCheckFormState({
            isOpen: false,
            formData: undefined,
          })
        }}
        afterCreate={terminal => {
          afterUpdateTerminal(terminal)
          setCheckFormState({
            isOpen: false,
            formData: undefined,
          })
        }}
        afterUpdate={terminal => {
          afterUpdateTerminal(terminal)
          setCheckFormState({
            isOpen: false,
            formData: undefined,
          })
        }}
      />

      <DialogTerminalDetails
        id={terminalDetailsModal.id}
        isOpen={terminalDetailsModal.isOpen}
        onClose={() => {
          setTerminalDetailsModal({
            isOpen: false,
            id: null,
          })
          refetchTerminalsData()
        }}
      />
    </>
  )
}

export default CompanyTerminalSubTab
