import { produce } from 'immer'
import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type {
  IGetProductGroupsResponse,
  IProductGroup,
} from '~/types/models/IProductGroup'
import type { IUser } from '~/types/models/IUser'

const useModifyProductGroups = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['productGroups', sessionUser.id],
    [sessionUser.id],
  )

  const addProductGroup = useCallback(
    (data: IProductGroup) => {
      queryClient.setQueryData<IGetProductGroupsResponse | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateProductGroup = useCallback(
    (data: IProductGroup) => {
      queryClient.setQueryData<IGetProductGroupsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === data.id)
              if (draft[index]) {
                draft[index] = {
                  ...draft[index],
                  ...data,
                }
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const deleteProductGroup = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGetProductGroupsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              console.log('index', index)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return { addProductGroup, updateProductGroup, deleteProductGroup }
}

export default useModifyProductGroups
