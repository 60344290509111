import React from 'react'
import { Dropdown } from 'react-bootstrap'
import ReactSelect, { components as ReactSelectComponents } from 'react-select'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { RecommendedOptions } from '~/components/shared'

import '~/styles/multiSelectDropdown.scss'
import './DropDownSelect.scss'
const Control = props => {
  const { selectProps, options } = props
  const { recommendedOptionsProps, onChange } = selectProps

  return (
    <>
      <ReactSelectComponents.Control {...props} />
      {recommendedOptionsProps?.show && (
        <RecommendedOptions
          onSelect={onChange}
          options={options}
          keyword={recommendedOptionsProps.keyword}
        />
      )}
    </>
  )
}

const DropDownSelect = props => {
  const {
    showMenu,
    load,
    value,
    label,
    isLoading,
    currentField,
    selectOptions,
    onChange,
    onCloseDropdown,
    customStyles,
    extraComponents,
    formatOptionLabel,
    formComponents,
    children,
    menuIsOpen,
    inputValue,
    handleChangeInputValues,
    className,
    recommendedOptionsProps,
    ...dropdownProps
  } = props

  return (
    <Dropdown
      className={clsx('documents-sortby-filter', className)}
      onToggle={onCloseDropdown}
      show={showMenu}
    >
      <Dropdown.Toggle
        id={`dropdown-${label}`}
        variant='link'
        size='lg'
        role='menu'
        bsPrefix='p-0'
        className='DropDownSelect__toggleButton'
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          padding: 0,
          border: 0,
          margin: 0,
          height: 0,
          zIndex: 9999,
        }}
        show={showMenu}
      >
        {showMenu && (
          <div
            className='DropdownSelect__selectFieldContainer'
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <ReactSelect
              {...dropdownProps}
              value={{ value: value, label: label }}
              autoFocus
              blurInputOnSelect
              menuIsOpen={menuIsOpen}
              openMenuOnFocus
              isLoading={isLoading}
              isOptionSelected={option => option.value === value}
              defaultMenuIsOpen={true}
              options={selectOptions}
              onChange={onChange}
              styles={{
                menu: provided => ({
                  ...provided,
                  position: 'static',
                  marginBottom: 0,
                }),
                ...customStyles,
              }}
              // classNamePrefix="react-select-load"
              components={{
                Control,
                ...extraComponents,
              }}
              formatOptionLabel={formatOptionLabel}
              inputValue={inputValue}
              onInputChange={handleChangeInputValues}
              recommendedOptionsProps={recommendedOptionsProps}
            />
            {formComponents}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

DropDownSelect.propTypes = {
  showMenu: PropTypes.bool,
  load: PropTypes.object,
  value: PropTypes.number,
  label: PropTypes.string,
  selectOptions: PropTypes.array,
  onChange: PropTypes.func,
  onCloseDropdown: PropTypes.func,
  customStyles: PropTypes.object,
  extraComponents: PropTypes.object,
  formatOptionLabel: PropTypes.func,
  formComponents: PropTypes.object,
  children: PropTypes.object,
}

DropDownSelect.defaultProps = {
  menuIsOpen: true,
}

export default DropDownSelect
