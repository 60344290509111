import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifySalesProposals from './useModifySalesProposals'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetSalesProposalsParams,
  ISalesProposal,
} from '~/types/models/ISalesProposal'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
// import { ESalesProposalStatus } from '~/types/enums/ESalesProposal'
// import sleep from '~/utils/sleep'

const useQuerySalesProposals = (
  params: IGetSalesProposalsParams = {},
  options?: Partial<UseQueryOptions<ISalesProposal[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addSalesProposal, updateSalesProposal, removeSalesProposal } =
    useModifySalesProposals(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'salesProposals',
      sessionUser?.id,
      buildGetUrl(apiClient.salesProposals.endpoint, params),
    ],
    async queryFn() {
      // await sleep(1000)
      // return [
      //   {
      //     id: 1,
      //     attention: 'test 123',
      //     status: ESalesProposalStatus.approved,
      //     date: '2024-09-16',
      //     buyerId: 648,
      //     sellerToBuyerTerminalId: 28,
      //     senderCompanyId: 362,
      //     buyerTerminalId: 1456,
      //     sellerTerminalId: 1536,
      //   },
      //   {
      //     id: 2,
      //     attention: 'test 123',
      //     status: ESalesProposalStatus.inProgress,
      //     date: '2024-09-16',
      //     buyerId: 648,
      //     sellerToBuyerTerminalId: 28,
      //     senderCompanyId: 362,
      //     buyerTerminalId: 1456,
      //     sellerTerminalId: 1648,
      //   },
      //   {
      //     id: 3,
      //     attention: 'test 123',
      //     status: ESalesProposalStatus.rejected,
      //     date: '2024-09-16',
      //     buyerId: 648,
      //     sellerToBuyerTerminalId: 28,
      //     senderCompanyId: 362,
      //     buyerTerminalId: 1456,
      //     sellerTerminalId: 1081,
      //   },
      // ]
      const response = await apiClient.salesProposals.get(params)
      return response.salesProposals
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const salesProposalsData = data || []

  return {
    salesProposalsData,
    isSalesProposalsLoading: isLoading,
    refetchSalesProposals: refetch,
    addSalesProposal,
    updateSalesProposal,
    removeSalesProposal,
  }
}

export default useQuerySalesProposals
