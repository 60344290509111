import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'
import {
  onUpdateUi,
  onUpdateUiFilter,
  onUpdateUiColumn,
  onUpdateUiColumns,
} from '~/redux/actions/documentsActions'
import { DEBOUNCE_WAIT_NUMBER_FOR_SEARCHING } from '~/utils/constants'

const useDocumentsUiControls = () => {
  const dispatch = useDispatch()

  const onUpdateColumn = useCallback(
    column => {
      dispatch(onUpdateUiColumn(column))
    },
    [dispatch],
  )

  const onUpdateFilter = useCallback(
    ({ field, value }) => dispatch(onUpdateUiFilter({ [field]: value })),
    [dispatch],
  )

  const onUpdatePage = useCallback(
    page => dispatch(onUpdateUi({ page: page })),
    [dispatch],
  )

  const onUpdateFilterSearchKey = debounce(searchKey => {
    dispatch(onUpdateUiFilter({ searchWord: searchKey }))
  }, DEBOUNCE_WAIT_NUMBER_FOR_SEARCHING)

  const onUpdateSortOptions = useCallback(
    sortOptions => dispatch(onUpdateUiColumns(sortOptions)),
    [dispatch],
  )

  const onUpdateColumns = useCallback(
    columns => {
      dispatch(onUpdateUiColumns(columns))
    },
    [dispatch],
  )

  const onUpdateFilters = useCallback(
    filters => {
      dispatch(onUpdateUiFilter(filters))
    },
    [dispatch],
  )

  return {
    onUpdateColumn,
    onUpdateColumns,
    onUpdateFilter,
    onUpdatePage,
    onUpdateFilters,
    onUpdateSortOptions,
    onUpdateFilterSearchKey,
  }
}

export default useDocumentsUiControls
