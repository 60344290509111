import { useCallback, useMemo, useState } from 'react'
import { useQueryBundles } from '~/hooks/useQueryData'

import { BundleBadge } from '../BundleBadge'

import { apiClient } from '~/api/ApiClient'

import type { IBundleFormWithBadgesProps } from './type'
import type { IBundle } from '~/types/models/IBundle'
import type { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'

const useBundleFormWithBadges = (props: IBundleFormWithBadgesProps) => {
  const { sellerProductId } = props

  const [isCreatingBundle, setIsCreatingBundle] = useState(false)
  const [sellerTerminalProdState, setSellerTerminalProdState] = useState({
    isOpen: false,
    formData: undefined as ISellerTerminalProduct | undefined,
  })

  const { bundlesData, addBundle, updateBundle, removeBundle } =
    useQueryBundles({
      filters: {
        sellerProductId,
      },
    })

  const onOpenCreateForm = useCallback(() => {
    setIsCreatingBundle(true)
  }, [])

  const onOpenSellerTerProdCreateForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: true,
      formData: undefined,
    })
  }, [])

  const onCloseForm = useCallback(() => {
    setIsCreatingBundle(false)
  }, [])

  const onCloseSellerTerProdForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const afterCreateBundle = useCallback(
    (newBundle: IBundle) => {
      addBundle(newBundle)
      onCloseForm()
    },
    [addBundle, onCloseForm],
  )

  const afterUpdateBundle = useCallback(
    (bundleUpdated: IBundle) => {
      updateBundle(bundleUpdated.id, bundleUpdated)
      onCloseForm()
    },
    [onCloseForm, updateBundle],
  )

  const onRemoveBundle = useCallback(
    (bundle: IBundle) => {
      apiClient.bundles.delete(bundle.id)
      removeBundle(bundle.id)
    },
    [removeBundle],
  )

  const renderBundleBadges = useMemo(
    () =>
      bundlesData.map(bundle => {
        return (
          <BundleBadge
            key={bundle.id}
            bundleData={bundle}
            sellerProductId={sellerProductId}
            onRemove={onRemoveBundle}
            afterUpdateBundle={afterUpdateBundle}
            afterCreateBundle={afterCreateBundle}
          />
        )
      }),
    [
      bundlesData,
      sellerProductId,
      onRemoveBundle,
      afterUpdateBundle,
      afterCreateBundle,
    ],
  )

  return {
    isCreatingBundle,
    sellerProductId,
    sellerTerminalProdState,
    renderBundleBadges,
    onOpenCreateForm,
    onCloseForm,
    afterCreateBundle,
    afterUpdateBundle,
    onCloseSellerTerProdForm,
    onOpenSellerTerProdCreateForm,
  }
}

export default useBundleFormWithBadges
