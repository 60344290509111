import { useState } from 'react'
import Select from 'react-select'
import { menuWidthStyle } from './selectUtils'

const selectStyles = {
  valueContainer: base => ({ ...base, paddingRight: 0 }),
  dropdownIndicator: base => ({ ...base, paddingLeft: 2 }),
  ...menuWidthStyle,
}

export const LoadingSelectCell = ({ onChange, ...props }) => {
  const [loading, setLoading] = useState(false)

  const handleChange = async value => {
    setLoading(true)
    await onChange(value)
    setLoading(false)
  }

  return (
    <Select
      {...props}
      onChange={handleChange}
      isLoading={loading}
      styles={selectStyles}
      menuPortalTarget={document.body}
      menuPosition='fixed'
    />
  )
}
