const loadCheckComponent = (componentLink, componentDivId, options = {}) => {
  const { onEvent, parentId = 'check-component-embedded-div' } = options
  try {
    const handler = window.CheckComponent.create({
      link: componentLink,
      divId: parentId,
      onEvent,
    })
    handler.open()
    const componentElement = document.getElementById(parentId)
    if (componentElement) {
      componentElement.style.minHeight = 500
      const componentHolder = document.getElementById(componentDivId)
      componentHolder.appendChild(componentElement)
    }
    return handler
  } catch (err) {
    console.log('err', err)
    // handle error
  }
}

export default loadCheckComponent
