import { useCallback, useEffect, useMemo, useState } from 'react'

import { IonCol, IonText, IonRow, IonBadge } from '@ionic/react'
import { Form } from 'react-bootstrap'
import { ToolTipOverlay } from '~/components/shared'

import './ConcordFormGroupStructure.scss'

function ConcordFormGroupStructure(props) {
  const { children, label, fields, formValues, errors, showByDefault } = props

  const [isShowingSection, setIsShowingSection] = useState(false)

  const nameOfFields = useMemo(() => fields.map(({ name }) => name), [fields])

  const numberOfFilledFields = useMemo(() => {
    let count = 0
    Object.keys(formValues).forEach(formField => {
      if (nameOfFields.includes(formField)) {
        if (formValues[formField]) {
          count++
        }
      }
    })

    return count
  }, [nameOfFields, formValues])

  const errorMessages = useMemo(() => {
    const errorField = Object.keys(errors)
    const messages = []
    errorField.forEach(field => {
      if (nameOfFields.includes(field)) {
        messages.push(errors[field].message)
      }
    })

    return messages
  }, [errors, nameOfFields])

  const handleToggleShowHideSection = useCallback(event => {
    const { checked } = event.target
    setIsShowingSection(checked)
  }, [])

  useEffect(() => {
    if (typeof showByDefault === 'boolean') {
      setIsShowingSection(showByDefault)
    } else {
      setIsShowingSection(true)
    }
  }, [showByDefault])

  return (
    <IonCol size={12} className='ConcordFormGroupStructure__container'>
      <div className='ConcordFormGroupStructure__headerContainer'>
        <div className='ConcordFormGroupStructure__labelContainer'>
          <IonText className='ConcordFormGroupStructure__label'>
            {label}
          </IonText>
          <ToolTipOverlay
            content={`${numberOfFilledFields} filled out fields out of ${fields.length} total fields`}
            placement='top'
          >
            <IonBadge className='ConcordFormGroupStructure__badge'>
              {numberOfFilledFields} / {fields.length}
            </IonBadge>
          </ToolTipOverlay>

          {errorMessages.length > 0 && (
            <ToolTipOverlay
              content={
                <ul style={{ textAlign: 'left' }}>
                  {errorMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              }
              placement='top'
            >
              <IonBadge
                className='ConcordFormGroupStructure__badge'
                color='danger'
              >
                {errorMessages.length > 1
                  ? `${errorMessages.length} errors`
                  : `${errorMessages.length} error`}
              </IonBadge>
            </ToolTipOverlay>
          )}
        </div>
        <Form>
          <Form.Check
            type='switch'
            label={isShowingSection ? 'Show' : 'Hide'}
            onChange={handleToggleShowHideSection}
            checked={isShowingSection}
          />
        </Form>
      </div>
      {isShowingSection && <IonRow>{children}</IonRow>}
    </IonCol>
  )
}

export default ConcordFormGroupStructure
