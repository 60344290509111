import React, { useState, useCallback } from 'react'
import { IonCol, IonButton, IonIcon, IonLabel } from '@ionic/react'
import { readerOutline, eye, trash } from 'ionicons/icons'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { CloseIcon, VerticalThreedotsIcon } from '~/components/shared/SvgIcons'

const ActionsCol = ({
  billLine,
  className,
  isShortenButtons,
  onShowDetail,
  onShowBillLineDetail,
  onDeleteBillLineClick,
  hasUseButton = false,
  onUseBillLineClick,
  isResolved,
  onMouseEnterCell,
  onMouseLeaveCell,
  style,
}) => {
  const [displayActions, setDisplayActions] = useState(false)
  const onToggleActions = useCallback(() => {
    setDisplayActions(!displayActions)
  }, [displayActions])

  const showBillLineDetail = useCallback(() => {
    onShowBillLineDetail && onShowBillLineDetail(billLine)
  }, [onShowBillLineDetail, billLine])

  const mouseEnterCell = useCallback(() => {
    onMouseEnterCell && onMouseEnterCell('actions')
  }, [onMouseEnterCell])

  const mouseLeaveCell = useCallback(() => {
    setDisplayActions(false)
  }, [setDisplayActions])

  const renderActions = useCallback(
    () => (
      <>
        {onShowDetail && !!billLine.loadId && (
          <OverlayTrigger overlay={<Tooltip>Loads</Tooltip>}>
            <Link to={`/loads/${billLine.loadId}`}>
              <div className='ActionButton'>
                <IonButton color='dark' size='small' expand='block'>
                  <IonIcon icon={readerOutline} color='light' />
                </IonButton>
              </div>
            </Link>
          </OverlayTrigger>
        )}
        {onShowDetail && (
          <OverlayTrigger overlay={<Tooltip>Details</Tooltip>}>
            <div className='ActionButton'>
              <IonButton
                onClick={showBillLineDetail}
                color='primary'
                size='small'
                expand='block'
              >
                <IonIcon icon={eye} />
              </IonButton>
            </div>
          </OverlayTrigger>
        )}
        {!isResolved && (
          <>
            {hasUseButton && (
              <OverlayTrigger overlay={<Tooltip>Use</Tooltip>}>
                <div className='ActionButton'>
                  <IonButton
                    onClick={onUseBillLineClick}
                    color='primary'
                    size='small'
                    expand='block'
                  >
                    <IonLabel>Use</IonLabel>
                  </IonButton>
                </div>
              </OverlayTrigger>
            )}

            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
              <div className='ActionButton'>
                <IonButton
                  onClick={onDeleteBillLineClick}
                  color='danger'
                  size='small'
                  expand='block'
                >
                  <IonIcon icon={trash} size='small' />
                </IonButton>
              </div>
            </OverlayTrigger>
          </>
        )}
      </>
    ),
    [
      onShowDetail,
      showBillLineDetail,
      onDeleteBillLineClick,
      billLine,
      hasUseButton,
      onUseBillLineClick,
      isResolved,
    ],
  )

  return (
    <IonCol
      className={className}
      onMouseEnter={mouseEnterCell}
      onMouseLeave={onMouseLeaveCell}
      style={{ background: 'none', borderLeft: 'none', ...style }}
      sizeSm={'1'}
    >
      {isShortenButtons ? (
        <>
          <div onMouseLeave={mouseLeaveCell} style={{ zIndex: '9999' }}>
            {displayActions && renderActions()}
          </div>
          <div className='ActionButton'>
            <Button onClick={onToggleActions} variant='none' size='small'>
              {displayActions ? <CloseIcon /> : <VerticalThreedotsIcon />}
            </Button>
          </div>
        </>
      ) : (
        renderActions()
      )}
    </IonCol>
  )
}

export default ActionsCol
