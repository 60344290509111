import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { NoteIcon, ToolTipOverlay } from '~/components/shared'
import { ControlledMenu, MenuItem } from '@szhsin/react-menu'

import './styles.scss'

export interface INoteButtonProps {
  canEdit?: boolean
  note?: string
  onSave?: (note: string) => void
  style?: React.CSSProperties
  renderNoteTooltip?: (params: {
    note?: string
  }) => React.ReactNode | null | undefined
}

function NoteButton(props: INoteButtonProps) {
  const { note = '', canEdit = true, onSave, style, renderNoteTooltip } = props

  const [noteValue, setNoteValue] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [defaultNoteValues, setDefaultNoteValues] = useState('')

  const ref = useRef<any>()

  const isNoteChanged = defaultNoteValues !== noteValue

  const handleChangeNotes = useCallback((event: any) => {
    setNoteValue(event.target.value)
  }, [])

  const onClickSave = () => {
    setIsVisible(false)
    if (isNoteChanged) {
      onSave && onSave(noteValue)
    }
  }

  useEffect(() => {
    setNoteValue(note)
    setDefaultNoteValues(note)
  }, [note])

  return (
    <>
      <ToolTipOverlay
        placement='top'
        content={
          renderNoteTooltip ? renderNoteTooltip({ note: noteValue }) : ''
        }
        className='pull-content-in-tooltip-left'
      >
        <Button
          variant='secondary'
          style={{
            position: 'relative',
            background: 'var(--bs-gray-500)',
            borderColor: 'var(--bs-gray-500)',
            ...style,
          }}
          ref={ref}
          onClick={() => {
            setIsVisible(prev => !prev)
          }}
        >
          <NoteIcon color='white' />
          {noteValue.length > 0 && <div className='NoteButton__dot' />}
        </Button>
      </ToolTipOverlay>
      <ControlledMenu
        anchorRef={ref}
        state={isVisible ? 'open' : 'closed'}
        className='NoteButton__menuContainer'
      >
        <MenuItem className='NoteButton__overplay'>
          <Form>
            <Form.Group>
              <Form.Control
                as='textarea'
                rows={3}
                value={noteValue}
                onChange={handleChangeNotes}
              />
            </Form.Group>
            {canEdit && (
              <Button
                style={{
                  width: '100%',
                  marginTop: 4,
                  fontSize: 12,
                }}
                onClick={onClickSave}
                variant={isNoteChanged ? 'primary' : 'secondary'}
              >
                {isNoteChanged ? 'Save' : 'Close'}
              </Button>
            )}
          </Form>
        </MenuItem>
      </ControlledMenu>
    </>
  )
}

export default NoteButton
