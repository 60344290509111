import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IPayroll } from '~/types/models/IPayroll'

const useModifyPayrolls = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'payrolls',
      sessionUser?.id,
      buildGetUrl(apiClient.payrolls.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addPayroll = useCallback(
    (data: IPayroll) => {
      queryClient.setQueryData<IPayroll[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updatePayroll = useCallback(
    (id: number, data: IPayroll) => {
      queryClient.setQueryData<IPayroll[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePayroll = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPayroll[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPayroll,
    updatePayroll,
    removePayroll,
  }
}

export default useModifyPayrolls
