import React, { useState, useCallback, useEffect, useMemo } from 'react'
import {
  addOutline,
  removeOutline,
  caretForward,
  trashOutline,
} from 'ionicons/icons'
import { IonIcon, IonAlert } from '@ionic/react'
import CostCodesInlineForm from './CostCodesInlineForm'
import { determineStyling } from './helpers'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { COST_CODE_REFERENCE } from '~/constants/cost-code-type'
import { DropdownPicker, Loading } from '~/components/shared'
import { apiClient } from '~/api/ApiClient'

const CostCodesRow = ({
  code,
  parents,
  fullCode,
  searchKey,
  setParents,
  level,
  referenceData,
  costCodeStructure,
}) => {
  const [referenceOptions, setReferenceOptions] = useState([])

  const referenceKey = useMemo(() => {
    try {
      return costCodeStructure[level]
    } catch (error) {
      return ''
    }
  }, [costCodeStructure, level])

  const displayReferenceKey = useMemo(() => {
    try {
      return COST_CODE_REFERENCE[referenceKey]
    } catch (error) {
      return ''
    }
  }, [referenceKey])
  // State changes need to happen on the local level. Don't want parent changes to cause child re-renders
  const [currentNode, setCurrentNode] = useState(code)
  // Show dropdown with carat and minus
  const [showChildren, setShowChildren] = useState(false)
  // Show the 'create' form for new entries
  const [showChildCreateForm, setshowChildCreateForm] = useState(false)
  // Show the 'edit' form for inline editing
  const [showEditForm, setShowEditForm] = useState(false)
  // Controls 'loading' spinner after API calls
  const [loading, setLoading] = useState(false)
  // Controls 'Delete' modal confirmation
  const [showAlert, setShowAlert] = useState(false)
  // Controls row the modal deletes (by id)
  const [currentRowData, setCurrentRowData] = useState()
  // Controls validity of searchbar text
  const [isValidText, setIsValidText] = useState(true)

  const onReferenceChange = useCallback(
    data => {
      apiClient.costCodes.update(
        code.id,
        { [data.field]: data.value },
      ) //
        .catch(() => toast.error(toastMessages.updateError))
    },
    [code],
  )

  const hasChildren = useMemo(
    () =>
      currentNode.children && currentNode.children.length > 0 ? true : false,
    [currentNode],
  )
  const styles = useMemo(() => determineStyling(currentNode), [currentNode])
  useEffect(() => {
    if (referenceData && referenceKey) {
      setReferenceOptions(
        referenceData[referenceKey].map(r => {
          return {
            label: r.name,
            value: r.id,
          }
        }),
      )
    }
  }, [referenceData, referenceKey])

  useEffect(() => {
    setCurrentNode(code)
  }, [code])

  useEffect(() => {
    matchSearchText(searchKey)
  }, [searchKey, matchSearchText])

  const matchSearchText = useCallback(
    text => {
      const lowerCaseText = text.toLowerCase()
      const lowerCaseTitle = currentNode.code.toLowerCase()
      const lowerCaseDescription = currentNode.name.toLowerCase()
      const lowerCaseFullCode = fullCode.toLowerCase()

      if (
        lowerCaseTitle.includes(lowerCaseText) ||
        lowerCaseDescription.includes(lowerCaseText) ||
        lowerCaseFullCode.includes(lowerCaseText)
      ) {
        setIsValidText(false)

        return
      }
      setIsValidText(true)
    },
    [currentNode, fullCode],
  )

  const onDeleteCostCode = useCallback(
    id => {
      setLoading(true)
      apiClient.costCodes.delete(id)
        .then(response => {
          setCurrentRowData(null)
          toast.success(toastMessages.deleteSuccess)
          let parentsCopy
          let replaceIndex
          if (!response.parentId) {
            parentsCopy = JSON.parse(JSON.stringify(parents))
            replaceIndex = parentsCopy.findIndex(cc => cc.id == response.id)
            parentsCopy.splice(replaceIndex, 1)
          } else {
            parentsCopy = JSON.parse(JSON.stringify(parents))
            replaceIndex = parentsCopy.children.findIndex(
              cc => cc.id == response.id,
            )
            parentsCopy.children.splice(replaceIndex, 1)
          }

          setParents(parentsCopy)
        })
        .catch(() => toast.error(toastMessages.deleteError))
      setLoading(false)
    },
    [parents, setParents, setLoading, setCurrentRowData],
  )

  // show or hide the dropdown
  const toggleChild = useCallback(() => {
    setShowChildren(!showChildren)
  }, [showChildren, setShowChildren])

  // Opens up the dropdown and opens the 'create' form
  const enableshowChildCreateForm = useCallback(() => {
    setShowChildren(true)
    setshowChildCreateForm(true)
  }, [])

  // Closes the 'edit' form view AND the 'create' form
  const toggleEditFormOff = useCallback(() => {
    setShowEditForm(false)
    setshowChildCreateForm(false)
  }, [])

  // Opens the 'edit' form view
  const enableEditForm = useCallback(() => {
    setShowEditForm(true)
  }, [])

  // Controls the 'Trashcan' icon click to open modal
  const onDeleteClick = useCallback(value => {
    setCurrentRowData(value)
    setShowAlert(true)
  }, [])

  // Change selected node or its children
  const changeCurrentCostCode = useCallback((value = null) => {
    if (value) {
      setCurrentNode(value)
    }
  }, [])

  return (
    <>
      {!showEditForm ? (
        <div className={styles.rowClassName}>
          <div
            onClick={toggleChild}
            className={styles.iconClassName}
            style={{
              backgroundColor:
                !hasChildren || showChildren ? '#bebebe' : '#1090FF',
              cursor: !hasChildren ? 'default' : 'pointer',
            }}
          >
            <IonIcon
              className='cost-code-icon'
              icon={!hasChildren || showChildren ? removeOutline : caretForward}
            />
          </div>
          <div
            onClick={enableEditForm}
            style={{
              marginLeft: '28px',
            }}
          >
            <span
              className={styles.textClassName}
              style={{ color: !isValidText ? '' : 'rgb(227, 225, 229)' }}
            >
              {currentNode.code} - {currentNode.name}
            </span>
          </div>
          <span
            className={styles.textClassName}
            style={{
              marginLeft: 20,
              color: !isValidText ? '' : 'rgb(227, 225, 229)',
            }}
          >
            {fullCode}
          </span>
          <span
            className={styles.childCountClass}
            style={{
              backgroundColor: !isValidText ? '#30bb50' : 'rgb(227, 225, 229)',
            }}
          >
            {hasChildren ? currentNode.children.length : 0}
          </span>
          <div style={{ fontSize: '12px', margin: '12px', color: 'blue' }}>
            {displayReferenceKey}
          </div>
          {referenceKey != null && (
            <div style={{ paddingTop: '4px' }}>
              <DropdownPicker
                items={referenceOptions}
                onValueChange={onReferenceChange}
                field={'reference_id'}
                value={code.referenceId}
                defaultLabel={'None'}
              />
            </div>
          )}
          {!showChildCreateForm && (
            <>
              <div
                className='cc-action-button'
                onClick={enableshowChildCreateForm}
                style={{
                  color: '#1090FF',
                  marginLeft: 'auto',
                }}
              >
                <IonIcon className='cost-code-icon' icon={addOutline} />
              </div>
              {!hasChildren && (
                <div
                  className={`${styles.actionButtonClass} cc-action-button`}
                  onClick={() => onDeleteClick(currentNode)}
                  style={{
                    color: '#ff6961',
                    backgroundColor: 'white',
                  }}
                >
                  <IonIcon className='cost-code-icon' icon={trashOutline} />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        //  Same line edit form
        <CostCodesInlineForm
          editData={currentNode}
          parentId={currentNode.parentId}
          setData={changeCurrentCostCode}
          parentStyles={styles}
          toggleShowForm={toggleEditFormOff}
        />
      )}
      {/*  For the 'create' form.  No 'editData' */}
      {showChildCreateForm && (
        <CostCodesInlineForm
          parent={currentNode}
          parentId={currentNode.id}
          parents={currentNode.children}
          setData={changeCurrentCostCode}
          setParents={changeCurrentCostCode}
          parentStyles={styles}
          useChildStyles={true}
          toggleShowForm={toggleEditFormOff}
        />
      )}
      {showChildren &&
        hasChildren &&
        currentNode.children
          .sort((a, b) => parseInt(a.code) - parseInt(b.code))
          .map(cc => {
            return (
              <CostCodesRow
                code={cc}
                key={cc.code}
                fullCode={`${fullCode}.${cc.code}`}
                parents={currentNode}
                searchKey={searchKey}
                setParents={changeCurrentCostCode}
                level={level + 1}
                costCodeStructure={costCodeStructure}
                referenceData={referenceData}
              />
            )
          })}
      {loading && <Loading />}
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm'}
        message={'Are you sure you want to delete it?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlert(false)
            },
          },
          {
            text: 'Ok',
            handler: () => {
              onDeleteCostCode(currentRowData.id)
            },
          },
        ]}
      />
    </>
  )
}

export default CostCodesRow
