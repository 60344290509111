import { combineReducers } from 'redux'
import addressesTypeUiSlice from './addresses'
import applicationUiSlice from './application'
import buyerSellersUiSlice from './buyerSellers'
import companiesUiSlice from './companies'
import companyBankAccountsUiSlice from './companyBankAccounts'
import companyEmailsUiSlice from './companyEmails'
import companyPhoneNumbersUiSlice from './companyPhoneNumbers'
import companySubTabsSlice from './companySubTabs/'
import dashboardSlice from './dashboardSlice'
import documentsSlice from './documentsSlice'
import fleetsUiSlice from './fleets.js'
import invoiceSlice from './invoice.js'
import invoicesSlice from './invoices.js'
import loadsSlice from './loadsSlice.js'
import ordersUiSlice from './orders'
import orderTrackingSlice from './orderTrackingSlice'
import preProcessingTestUiSlice from './preProcessingTestUiSlice'
import productsUiSlice from './products.js'
import productGroupsUiSlice from './productGroups.js'
import salesProposalsUiSlice from './salesProposals'
import sellerProductsUiSlice from './sellerProducts'
import sessionsUiSlice from './sessions.js'
import terminalsUiSlice from './terminals.js'
import userAccessesUiSlice from './userAccesses.js'
import workPayTypeUiSlice from './workPayTypes'
import payrollsUiSlice from './companyPayrolls'
import checkPayrollItemsUiSlice from './checkPayrollItems'
import billLinesSlice from '../ui/billLines'
import { driverUiSlice } from './driver'
import { driversUiSlice } from './drivers'
import { trucksUiSlice } from './trucks'
import chatUiSlice from './chat'
import { accountsReceivableUISlice } from './accountsReceivable'
import { zonesUiSlice } from './zones'
import { sellerToBuyerTerminalsUiSlice } from './sellerToBuyerTerminals'

export default combineReducers({
  accountsReceivable: accountsReceivableUISlice.reducer,
  addresses: addressesTypeUiSlice.reducer,
  application: applicationUiSlice.reducer,
  billLines: billLinesSlice.reducer,
  buyerSellers: buyerSellersUiSlice.reducer,
  companies: companiesUiSlice.reducer,
  companyBankAccounts: companyBankAccountsUiSlice.reducer,
  companyEmails: companyEmailsUiSlice.reducer,
  companyPhoneNumbers: companyPhoneNumbersUiSlice.reducer,
  companySubTabs: companySubTabsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  documents: documentsSlice.reducer,
  driver: driverUiSlice.reducer,
  drivers: driversUiSlice.reducer,
  fleets: fleetsUiSlice.reducer,
  invoice: invoiceSlice.reducer,
  invoices: invoicesSlice.reducer,
  loads: loadsSlice.reducer,
  orders: ordersUiSlice.reducer,
  orderTracking: orderTrackingSlice.reducer,
  preProcessingTests: preProcessingTestUiSlice.reducer,
  productGroups: productGroupsUiSlice.reducer,
  products: productsUiSlice.reducer,
  salesProposals: salesProposalsUiSlice.reducer,
  sellerProducts: sellerProductsUiSlice.reducer,
  sessions: sessionsUiSlice.reducer,
  terminals: terminalsUiSlice.reducer,
  trucks: trucksUiSlice.reducer,
  userAccesses: userAccessesUiSlice.reducer,
  workPayTypes: workPayTypeUiSlice.reducer,
  companyPayrolls: payrollsUiSlice.reducer,
  checkPayrollItems: checkPayrollItemsUiSlice.reducer,
  chats: chatUiSlice.reducer,
  zones: zonesUiSlice.reducer,
  sellerToBuyerTerminals: sellerToBuyerTerminalsUiSlice.reducer
})
