import { useMemo } from 'react'

import { TerminalMarker1 } from '../TerminalMarker'

import type { ITerminalMarkerListProps } from './type'

function TerminalMarkerList(props: ITerminalMarkerListProps) {
  const { terminals } = props

  const renderTerminalMarkers = useMemo(
    () =>
      terminals.map(terminal => (
        <TerminalMarker1 key={terminal.id} terminal={terminal} />
      )),
    [terminals],
  )

  return renderTerminalMarkers
}
export default TerminalMarkerList
