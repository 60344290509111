import queryString from 'query-string'
import { isImage, isPdf } from '~/utils/checkFileExtension'

const getFileNameFromAWSLink = (awsLink, defaultName = 'unknown') => {
  try {
    const { query } = queryString.parseUrl(awsLink)
    const hashname = query['response-content-disposition'] || ''
    const [, filename] = hashname.split('\'\'') || []
    const contentType = query['response-content-type']
    const [, extension] = contentType.split('/')

    if (filename) {
      const decodeFileName = decodeURI(filename)
      if (isPdf(decodeFileName) || isImage(decodeFileName)) {
        return decodeFileName
      }
      const [name] = decodeFileName.split('.')

      return `${name}.${extension}`
    }

    if (extension) {
      return `${defaultName}.${extension}`
    }

    return null
  } catch (error) {
    console.log('error getFileNameFromAWSLink', error)

    return null
  }
}

export default getFileNameFromAWSLink
