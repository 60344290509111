import { useRef, useState, useMemo, useEffect } from 'react'
import { When } from 'react-if'

import {
  CommonDialogV2,
  ConcordFormDropdownV2,
  GoogleMap,
  TerminalMarker1,
} from '~/components/shared'
import TerminalForm from './TerminalForm'

import { eyeOffOutline, eyeOutline, mapOutline } from 'ionicons/icons'
import seperateAddress from '~/utils/seperateAddress'
import buildFullAddress from '~/utils/buildFullAddress'

import './styles.scss'

function DialogTerminalForm(props) {
  const { formData, shouldHideOptionalFields } = props

  const formRef = useRef()

  const [addressOptions, setAddressOptions] = useState([])
  const [isShowingMap, setIsShowingMap] = useState(false)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isHideOptionalFields, setIsHideOptionalFields] = useState(false)

  const hideOptionalTooltip = useMemo(
    () =>
      isHideOptionalFields ? 'Show optional fields' : 'Hide optional fields',
    [isHideOptionalFields],
  )

  useEffect(() => {
    shouldHideOptionalFields &&
      setIsHideOptionalFields(shouldHideOptionalFields ? true : false)
  }, [shouldHideOptionalFields])

  const extraButtons = useMemo(
    () => [
      {
        icon: isHideOptionalFields ? eyeOutline : eyeOffOutline,
        tooltipProps: {
          content: hideOptionalTooltip,
          placement: 'top',
        },
        onClick: () => setIsHideOptionalFields(!isHideOptionalFields),
      },
      {
        icon: mapOutline,
        tooltipProps: {
          content: 'View location on map',
          placement: 'top',
        },
        isHidden: !formData?.id,
        onClick() {
          const nextValue = !isShowingMap
          setIsShowingMap(nextValue)
        },
      },
    ],
    [formData?.id, hideOptionalTooltip, isHideOptionalFields, isShowingMap],
  )

  const propsMemo = useMemo(() => {
    return {
      ...props,
      isHideOptionalFields,
    }
  }, [props, isHideOptionalFields])

  return (
    <CommonDialogV2
      {...props}
      title='Terminal'
      isHiddenOkButton
      extraButtons={extraButtons}
      onClose={() => {
        props.onClose && props.onClose()
        setIsShowingMap(false)
        setAddressOptions([])
        setIsOpenDropdown(false)
      }}
    >
      {isShowingMap && (
        <div className='DialogTerminalForm__mapView'>
          <GoogleMap
            defaultCenter={{
              lat: Number(formData?.location?.lat),
              lng: Number(formData?.location?.lng),
            }}
            zoom={14}
          >
            {formData && (
              <TerminalMarker1
                terminal={formData}
                onMoveMarker={async ({ lat, lng }) => {
                  const response =
                    await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true/false&key=AIzaSyD8vF1Sm2An_LVf_oUlafdzshwtWDjYHv0
                `)
                  const data = await response.json()
                  if (data.results?.length && data.status === 'OK') {
                    const options = data.results.map(
                      ({ address_components, place_id }) => {
                        const address = seperateAddress(address_components)
                        return {
                          value: place_id,
                          label: buildFullAddress(address),
                          address,
                        }
                      },
                    )
                    setAddressOptions(options)
                    setIsOpenDropdown(true)
                  } else {
                    setAddressOptions([])
                    setIsOpenDropdown(false)
                  }
                }}
              />
            )}
          </GoogleMap>

          <When condition={addressOptions.length}>
            <div style={{ marginTop: 12 }}>
              <ConcordFormDropdownV2
                menuIsOpen={isOpenDropdown}
                options={addressOptions}
                onMenuOpen={() => {
                  setIsOpenDropdown(prev => !prev)
                }}
                onMenuClose={() => {
                  setIsOpenDropdown(prev => !prev)
                }}
                onChange={(_event, { selectedOption }) => {
                  const { address } = selectedOption
                  setIsOpenDropdown(false)
                  formRef.current.setValue(
                    'locationAttributes.addressAttributes',
                    {
                      city: address.city,
                      country: address.country,
                      zip: address.postalCode,
                      street: address.street,
                      state: address.state,
                    },
                  )
                }}
              />
            </div>
          </When>
        </div>
      )}
      <TerminalForm
        {...propsMemo}
        ref={formRef}
        isHiddenCancelButton
        formData={formData}
      />
    </CommonDialogV2>
  )
}

export default DialogTerminalForm
