import { useCallback } from 'react'

import Dropzone from 'react-dropzone'
import { ConcordFormLayout } from '~/components/shared'

import clsx from 'clsx'

import './styles.scss'
import FileCard from './FileCard'
import { produce } from 'immer'

// interface IProps {
//   value: File[]
//   label: React.ReactNode
//   isLoading?: boolean
//   isRequired?: boolean
//   placeholder?: string
//   acceptedFiles?: string
//   onChange?: (files: File[]) => void
//   className?: string
//   style?: React.CSSProperties
// }

const FileInputField = props => {
  const {
    value = [],
    onChange,
    className,
    style,
    label,
    isLoading,
    isRequired,
    placeholder: placeholderProp,
    acceptedFiles = 'image/jpeg, image/png, image/gif, image/bmp, application/pdf',
    error,
    ...otherProps
  } = props

  const filesFiltered = (value || []).filter(file => file)

  const handleDrop = useCallback(
    files => {
      onChange && onChange([...files, ...filesFiltered])
    },
    [filesFiltered, onChange],
  )

  return (
    <ConcordFormLayout
      label={label}
      isLoading={isLoading}
      isRequired={isRequired}
      error={error}
      {...otherProps}
    >
      <section
        className={clsx(className, 'FileField__container')}
        style={style}
      >
        <Dropzone
          maxFiles={1}
          onDrop={handleDrop}
          className={clsx('FileField__placeholder', { isDisabled: isLoading })}
          accept={acceptedFiles}
        >
          <div>{placeholderProp || 'Select Files'}</div>
        </Dropzone>
      </section>
      {error && <div className='error form-text'>{error}</div>}

      {filesFiltered.map((file, index) => (
        <FileCard
          key={index}
          file={file}
          onRemove={() => {
            const newFiles = produce(filesFiltered, draft => {
              draft.splice(index, 1)
            })
            onChange && onChange(newFiles)
          }}
          onEdit={file => {
            const newFiles = produce(filesFiltered, draft => {
              draft[index] = file
            })
            onChange && onChange(newFiles)
          }}
        />
      ))}
    </ConcordFormLayout>
  )
}

// FileInputField.propTypes = {
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object,
//     PropTypes.number,
//   ]),
//   onChange: PropTypes.func,
// }

export default FileInputField
