import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelectorWithParams } from '~/hooks/useSelectorWithParams'
import { fetchUserTableConfigurations } from '~/redux/actions/userTableConfigurationActions'
import { selectUserTableConfigurationById } from '~/redux/selectors'

export const useInitializeUserTableConfigurations = tableName => {
  const dispatch = useDispatch()
  const { id } = useSelectorWithParams(
    selectUserTableConfigurationById,
    tableName,
  )

  useEffect(() => {
    !id && dispatch(fetchUserTableConfigurations(tableName))
  }, [dispatch, id, tableName])
}
