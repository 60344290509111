import { useState, useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { useSelector } from 'react-redux'
import { useConfirmationProvider } from '~/contexts/ConfirmationProvider'

import { IonGrid, IonRow, IonCol } from '@ionic/react'
import {
  CommonDialog,
  ConcordCompanyDropdown,
  CompanyAvatar,
} from '~/components/shared'
import SectionEmailParsers from '../SectionEmailParsers'
import EmailParserContent from './EmailParserContent'

import _ from 'lodash'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { selectCurrentScope } from '~/redux/selectors'
import { produce } from 'immer'
import { toastMessages } from '~/constants/toast-status-text'
import { toast } from 'react-toastify'
import humps from 'humps'
import { refreshOutline, checkmarkOutline } from 'ionicons/icons'

import './DialogEmailContent.scss'
import { apiClient } from '~/api/ApiClient'
import { BASE_URL_WITHOUT_API_V1 } from '~/utils/constants'
import { EYesNo } from '~/types/enums/ECommonEnum'

const DialogEmailContent = props => {
  const {
    isOpen,
    document: concordDocument,
    onClose,
    onRerunEmail,
    onUpdateSeller,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [idParserSelected, setIdParserSelected] = useState(null)
  const [emailParsers, setEmailParsers] = useState([])
  const [renewedEmail, setRenewedEmail] = useState(false)

  const [data, setData] = useState({})
  const [openEmailParserState, setOpenEmailParserState] = useState(false)
  const [loadParsers, setLoadParsers] = useState([]) // number[]

  const currentScope = useSelector(selectCurrentScope)

  const { confirmation } = useConfirmationProvider()

  const emailContent = useMemo(() => {
    const content = data.content
    if (!content) {
      return ''
    }

    const escapeLt = content.replace(/</g, '&lt;')
    const escapeGt = escapeLt.replace(/>/g, '&gt;')
    const replaceSpace = escapeGt.replace(/\n/g, '<br />')

    return replaceSpace
  }, [data.content])

  const delayTime = useMemo(() => {
    const createdAt = DateTime.fromISO(data.createdAt)
    const timeReceived = DateTime.fromISO(data.timeReceived)
    const diff = createdAt.diff(timeReceived, ['minutes'])
    const result = diff.toObject()
    const roundMinutes = Math.round(result.minutes)

    if (roundMinutes > 1) {
      return `${roundMinutes} mins`
    }

    return `${roundMinutes} min`
  }, [data.createdAt, data.timeReceived])

  const selectedParser = useMemo(() => {
    const defaultParserSelected = {
      id: -1,
      docTypeValue: 'Invoice',
    }
    if (_.isNil(idParserSelected)) {
      return defaultParserSelected
    }
    const findParser = emailParsers.find(({ id }) => id === idParserSelected)

    return findParser || defaultParserSelected
  }, [emailParsers, idParserSelected])

  const isCreating = useMemo(() => {
    const index = emailParsers.findIndex(({ id }) => id === -1)

    return index !== -1
  }, [emailParsers])

  const regexToHighLightText = useMemo(() => {
    const { sellerRegex, buyerRegex, docTypeRegex } = selectedParser

    const getSellerRegex = _.size(sellerRegex) > 1 ? sellerRegex : null
    const getBuyerRegex = _.size(buyerRegex) > 1 ? buyerRegex : null
    const getDocTypeRegex = _.size(docTypeRegex) > 1 ? docTypeRegex : null

    return [
      {
        regex: getSellerRegex,
        className: 'DialogEmailContent__sellerRegex',
      },
      {
        regex: getBuyerRegex,
        className: 'DialogEmailContent__buyerRegex',
      },
      {
        regex: getDocTypeRegex,
        className: 'DialogEmailContent__docTypeRegex',
      },
    ]
  }, [selectedParser])

  const getParserEmailContent = useCallback(async () => {
    setIsLoading(true)
    if (!concordDocument.parsedEmailId) {
      return
    }
    try {
      const { parsedEmail } = await apiClient.parsedEmails.getById(
        concordDocument.parsedEmailId,
      )
      setData(parsedEmail)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }, [concordDocument.parsedEmailId])

  const handleSetTextToRegex = useCallback(
    (name, selectionText) => {
      setEmailParsers(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === idParserSelected)
          if (index !== -1) {
            draft[index][name] = selectionText
          }
        }),
      )
    },
    [idParserSelected],
  )

  const handleSelectEmailParser = useCallback(
    (event, parserSelected, nextSelected) => {
      if (nextSelected) {
        setIdParserSelected(parserSelected.id)
      } else {
        setIdParserSelected(null)
      }
    },
    [],
  )

  const getFilterParams = useCallback(() => {
    const filters = {}
    filters.sellerId = concordDocument.sellerId
    if (currentScope === 'buyer') {
      filters.buyerId = concordDocument.buyerId
    } else if (currentScope === 'seller') {
      if (concordDocument.docType) {
        filters.docTypeValue = concordDocument.docType
      }
    }
    return filters
  }, [
    currentScope,
    concordDocument.buyerId,
    concordDocument.docType,
    concordDocument.sellerId,
  ])

  const handleOpenEmailParsers = useCallback(async () => {
    setIsLoading(true)
    try {
      const queryParams = getFilterParams()
      const { emailParsers } = await apiClient.emailParsers.get(queryParams)
      setEmailParsers(emailParsers)
      setOpenEmailParserState(true)

      if (emailParsers.length === 1) {
        setIdParserSelected(emailParsers[0].id)
      }
    } catch {
      toast.error('An error has occurred while fetching data.')
    } finally {
      setIsLoading(false)
    }
  }, [getFilterParams])

  const handleChangeEmailParsersLoading = useCallback(loading => {
    setIsLoading(loading)
  }, [])

  const handleUpdateParser = useCallback(({ id, ...params }) => {
    setEmailParsers(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(parser => parser.id === id)
        if (index !== -1) {
          draft[index] = { ...draft[index], ...params }
        }
      }),
    )
  }, [])

  const handleCloseEmailParsers = useCallback(() => {
    setOpenEmailParserState(false)
  }, [])

  const handleChangeParser = useCallback(({ id, field, value, index }) => {
    setEmailParsers(prev =>
      produce(prev, draft => {
        draft[index][field] = value
      }),
    )
    setIdParserSelected(id)
  }, [])

  const handleResetChangesParser = useCallback(({ field, value, index }) => {
    setEmailParsers(prev =>
      produce(prev, draft => {
        draft[index][field] = value
      }),
    )
  }, [])

  const handleClickCreateParser = useCallback(() => {
    const defaultParser = {
      id: -1,
      docTypeValue: 'Invoice',
      sellerId: concordDocument.sellerId,
      buyerId: concordDocument.buyerId,
    }

    if (isCreating) {
      setEmailParsers(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === -1)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }),
      )
      setIdParserSelected(null)
    } else {
      setEmailParsers(prev =>
        produce(prev, draft => {
          draft.unshift(defaultParser)
        }),
      )
      setIdParserSelected(-1)
    }
  }, [concordDocument, isCreating])

  const handleChangeLoadParsers = useCallback(id => {
    setLoadParsers(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(id)
        if (index === -1) {
          draft.push(id)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const createParser = useCallback(
    async ({ id, ...formValues }) => {
      handleChangeLoadParsers(id)
      try {
        const omitValues = _.omit(formValues, ['seller', 'buyer'])
        const payload = humps.decamelizeKeys({
          ...omitValues,
          sellerId: concordDocument.sellerId,
          buyerId: concordDocument.buyerId,
        })
        const { emailParser } = await apiClient.emailParsers.create(payload)

        if (emailParser.id) {
          setEmailParsers(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(parser => parser.id === -1)
              const payload = {
                ...emailParser,
                buyer: formValues.buyer,
                seller: formValues.seller,
              }

              if (index !== -1) {
                draft[index] = payload
              } else {
                draft.unshift(payload)
              }
            }),
          )
          setIdParserSelected(emailParser.id)
        } else {
          toast.error(toastMessages.createError)
        }
      } catch {
        toast.error(toastMessages.createError)
      } finally {
        handleChangeLoadParsers(id)
      }
    },
    [
      concordDocument.buyerId,
      concordDocument.sellerId,
      handleChangeLoadParsers,
    ],
  )

  const handleCreateParser = useCallback(
    async formValues => {
      const classRegex = ['sellerRegex', 'buyerRegex', 'docTypeRegex']
      const isRegexMatched = classRegex.some(className => {
        const elements = document.getElementsByClassName(
          `DialogEmailContent__${className}`,
        )

        return elements.length > 0
      })

      if (isRegexMatched) {
        createParser(formValues)

        return
      }

      const result = await confirmation({
        message: `All regex dont match any content of email #${concordDocument.parsedEmailId}, are you sure you want to create it?`,
      })

      if (result === EYesNo.Yes) {
        createParser(formValues)
      }
    },
    [concordDocument.parsedEmailId, confirmation, createParser],
  )

  const handleRerunEmail = useCallback(async () => {
    if (renewedEmail) {
      return
    }

    setIsLoading(true)
    try {
      const emailRerun = await apiClient.emails.getById(
        data.id,
        {},
        {
          baseUrl: BASE_URL_WITHOUT_API_V1,
        },
      )
      setRenewedEmail(true)
      onRerunEmail && onRerunEmail(emailRerun)
    } catch (error) {
      toast.error(toastMessages.createError)
    } finally {
      setIsLoading(false)
    }
  }, [data.id, onRerunEmail, renewedEmail])

  const handleChangeSeller = useCallback(
    async selectedSeller => {
      onUpdateSeller && onUpdateSeller(concordDocument, selectedSeller)
    },
    [concordDocument, onUpdateSeller],
  )

  useUpdateEffect(() => {
    if (isOpen) {
      getParserEmailContent()
    } else {
      setData({})
      setEmailParsers([])
      setOpenEmailParserState(false)
      setRenewedEmail(false)
    }
  }, [isOpen])

  useUpdateEffect(() => {
    if (!openEmailParserState) {
      setIdParserSelected(null)
    }
  }, [openEmailParserState])

  useUpdateEffect(() => {
    getParserEmailContent()
  }, [concordDocument.sellerId, getParserEmailContent])

  return (
    <CommonDialog
      id='DocumentEmailContent__dialog'
      title={() => (
        <div className='DocumentEmailContent__dialog'>
          <span>Email Content</span>
          <ConcordCompanyDropdown
            buyerId={concordDocument.buyerId}
            value={concordDocument.seller}
            className='remove-border-from-common-seller-dropdown'
            onChange={handleChangeSeller}
            formProps={{
              docType: concordDocument.docType,
              documentId: concordDocument.id,
            }}
            getOptionLabel={option => (
              <CompanyAvatar
                width={30}
                height={30}
                tooltipMessage={concordDocument?.seller?.label}
                companyType='seller'
                company={option}
              />
            )}
          />
        </div>
      )}
      open={isOpen}
      onClose={onClose}
      loading={isLoading}
      className='DialogEmailContent__wrapDialog'
      okText={
        openEmailParserState ? 'Close Email Parsers' : 'Open Email Parsers'
      }
      okProps={{
        color: 'fleet',
      }}
      onOk={
        openEmailParserState ? handleCloseEmailParsers : handleOpenEmailParsers
      }
      headerButtons={[
        {
          icon: renewedEmail ? checkmarkOutline : refreshOutline,
          color: renewedEmail ? 'success' : 'concord',
          tooltip: renewedEmail ? '' : 'Re-run JSON Parsing',
          disabled: isLoading,
          onClick: handleRerunEmail,
        },
      ]}
    >
      <IonGrid style={{ padding: 0 }}>
        <IonRow>
          <EmailParserContent
            data={data}
            openEmailParserState={openEmailParserState}
            regexToHighLightText={regexToHighLightText}
            delayTime={delayTime}
            concordDocument={concordDocument}
            emailContent={emailContent}
            onSetTextToRegex={handleSetTextToRegex}
            idParserSelected={idParserSelected}
          />

          {openEmailParserState && (
            <IonCol
              size={5}
              style={{ padding: 0, borderLeft: '1px solid #dfdfdf' }}
            >
              <SectionEmailParsers
                concordDocument={concordDocument}
                onLoading={handleChangeEmailParsersLoading}
                onSelect={handleSelectEmailParser}
                selectedParser={selectedParser}
                onUpdate={handleUpdateParser}
                emailParsers={emailParsers}
                isLoading={isLoading}
                onChangeParser={handleChangeParser}
                onResetChangesParser={handleResetChangesParser}
                onClickCreateParser={handleClickCreateParser}
                isCreating={isCreating}
                onSubmitCreateParser={handleCreateParser}
                loadParsers={loadParsers}
              />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </CommonDialog>
  )
}

DialogEmailContent.propTypes = {
  isOpen: PropTypes.bool,
  document: PropTypes.object,
  onClose: PropTypes.func,
  onRerunEmail: PropTypes.func,
}

export default DialogEmailContent
