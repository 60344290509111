import { driversSlice } from '../reducers/data/drivers'
import { driversUiSlice } from '../reducers/ui/drivers'

export const initializeDrivers = payload => dispatch =>
  dispatch(driversSlice.actions.index(payload))

export const updateDriver = payload => dispatch =>
  dispatch(driversSlice.actions.update(payload))

export const deleteDriver = payload => dispatch =>
  dispatch(driversSlice.actions.delete(payload))

export const updateDriversUi = payload => dispatch =>
  dispatch(driversUiSlice.actions.updateUi(payload))
