import { createSelector } from 'reselect'

export const selectOrderExtras = state => state.data.orderExtras
export const selectOrderExtraRanges = state => state.data.orderExtraRanges

export const selectOrderExtrasList = createSelector(selectOrderExtras, state =>
  Object.values(state),
)

export const selectOrderExtraRangesList = createSelector(
  selectOrderExtraRanges,
  state => Object.values(state),
)
