import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IPricing } from '~/types/models/IPricing'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useModifyPricing = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['pricings', sessionUser?.id],
    [sessionUser?.id],
  )

  const addPricing = useCallback(
    (data: IPricing) => {
      queryClient.setQueryData<IPricing[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updatePricing = useCallback(
    (id: number, data: IPricing) => {
      queryClient.setQueryData<IPricing[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePricing = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPricing[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPricing,
    updatePricing,
    removePricing,
  }
}

export default useModifyPricing
