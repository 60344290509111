import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import companyPhoneNumbersUiSlice from '~/redux/reducers/ui/companyPhoneNumbers'

export const fetchCompanyPhoneNumbers = createAsyncThunk(
  'fetchCompanyPhoneNumbers',
  async (payload, { dispatch }) => {
    dispatch(companyPhoneNumbersUiSlice.actions.setLoadingTable(true))
    try {
      const { phoneNumbers } = await apiClient.phoneNumbers.get()
      dispatch(companyPhoneNumbersSlice.actions.index(phoneNumbers))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(companyPhoneNumbersUiSlice.actions.setLoadingTable(false))
    }
  },
)

const companyPhoneNumbersSlice = createSlice({
  name: 'companyPhoneNumbers',
  initialState: {},
  reducers: {
    index(_state, { payload }) {
      const newState = {}
      payload.forEach(item => {
        newState[item.id] = item
      })

      return newState
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export default companyPhoneNumbersSlice
