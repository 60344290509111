import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import {
  Button,
  CheckMarkIcon,
  CloseIcon,
  ConcordFormStructure,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  IConcordFormField,
  IDataCorrectionMethodFormProps,
  PlusIcon,
} from '~/components/shared'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { PARSER_COLUMN_MODEL_OPTIONS } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IDataCorrectionMethodFormData } from '~/types/models/IDataCorrectionMethod'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { JsonEditor } from 'json-edit-react'
import { decamelizeKeys } from 'humps'

function DataCorrectionMethodForm(props: IDataCorrectionMethodFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [correctionDict, setCorrectionDict] = useState<Record<string, any>>({
    gsub_correction: {},
    gsub_with_regexp_correction: {},
    remove_whitespace: true,
  })

  const { allCompaniesWithCurrentCompany, isLoadingBuyerSellers } =
    useQueryBuyerSellers()

  const formRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isUpdating = Boolean(formData?.id)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'companyId',
        label: 'Company',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: allCompaniesWithCurrentCompany.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
        })),
        isLoading: isLoadingBuyerSellers,
        isDisabled: isUpdating,
      },
      {
        name: 'model',
        label: 'Model',
        type: EFieldType.singleSelect,
        options: PARSER_COLUMN_MODEL_OPTIONS,
        isRequired: true,
      },
      {
        name: 'formatRegex',
        label: 'Format Regex',
      },
      {
        name: 'fieldLength',
        label: 'Field Length',
        type: EFieldType.number,
      },
      {
        name: 'columnName',
        label: 'Column Name',
        placeholder: 'Ex: product_name',
      },
      {
        name: 'fuzzyMatchingArray',
        label: 'Fuzzy Matching Array',
        type: EFieldType.tags,
        placeholder: 'Type and Press Enter to add a new item',
      },
    ],
    [allCompaniesWithCurrentCompany, isLoadingBuyerSellers, isUpdating],
  )

  const defaultValues: IDataCorrectionMethodFormData = {
    companyId: currentCompany?.id,
    model: '',
    formatRegex: '',
    fieldLength: null,
    columnName: '',
    correctionDict: {},
    fuzzyMatchingArray: [],
  }

  const schema = Yup.object({
    companyId: Yup.number()
      .required('This field required!')
      .typeError('This field required!'),
    model: Yup.string()
      .required('This field required!')
      .typeError('This field required!'),
    fuzzyMatchingArray: Yup.array().min(1, 'This field required!'),
  })

  const create = async (formValues: IDataCorrectionMethodFormData) => {
    const payload = {
      ...formValues,
      correctionDict,
    }
    const response = await apiClient.dataCorrectionMethods.create(payload)
    if (response.errors.length > 0) {
      setBackendError(response.errors[0])
    } else {
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const update = async (formValues: IDataCorrectionMethodFormData) => {
    const payload = _.pick(formValues, [
      'model',
      'formatRegex',
      'fieldLength',
      'columnName',
      'correctionDict',
      'fuzzyMatchingArray',
    ])
    const response = await apiClient.dataCorrectionMethods.update(
      formData?.id as number,
      payload,
    )
    if (response.errors.length > 0) {
      setBackendError(response.errors[0])
    } else {
      afterUpdate && afterUpdate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const onSubmitForm = async () => {
    formRef.current.handleSubmit(
      async (formValues: IDataCorrectionMethodFormData) => {
        setIsLoading(true)
        setBackendError('')
        try {
          if (isUpdating) {
            await update(formValues)
          } else {
            await create(formValues)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      },
    )()
  }

  useEffect(() => {
    if (isUpdating) {
      const correctionDict = decamelizeKeys(formData?.correctionDict)
      setCorrectionDict(correctionDict as any)
    }
  }, [formData?.correctionDict, isUpdating])

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='5'>
          <ConcordFormStructure
            error={backendError}
            fields={fields}
            defaultValues={defaultValues}
            formData={formData}
            schema={schema}
            isLoading={isLoading}
            submitText={isUpdating ? 'Update' : 'Create'}
            ref={formRef}
            isHiddenSubmitButton
            isHiddenCancelButton
          />
        </IonCol>
        <IonCol size='7'>
          <JsonEditor
            data={correctionDict}
            setData={setCorrectionDict as any}
            rootName='correction_dict'
            icons={{
              add: <PlusIcon color='var(--ion-color-concord)' />,
              copy: <CopyIcon color='var(--ion-color-concord)' />,
              edit: <EditIcon color='var(--ion-color-warning)' />,
              delete: <DeleteIcon color='var(--ion-color-danger)' />,
              ok: <CheckMarkIcon color='var(--ion-color-success)' />,
              cancel: <CloseIcon color='var(--ion-color-danger)' />,
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol style={{ paddingTop: 0 }}>
          <Button
            label={isUpdating ? 'Update' : 'Create'}
            expand='full'
            onClick={onSubmitForm}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default DataCorrectionMethodForm
