import { useState, useEffect, useCallback } from 'react'
import { IonRow, IonCol, IonLabel, IonButton } from '@ionic/react'
import DatePicker from '~/components/shared/DatePicker'
import { DocumentItem, DropdownPicker } from '~/components/shared'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

const LoadForm = ({
  document,
  hidePopup,
  afterCreateLoad,
  afterDeleteDocument,
}) => {
  const [loading, setLoading] = useState(false)
  const [sellerTerminal, setSellerTerminal] = useState()
  const [buyerTerminal, setBuyerTerminal] = useState()
  const [buyerSellerProduct, setBuyerSellerProduct] = useState()
  const [sellerTerminalsList, setSellerTerminalsList] = useState()
  const [buyerTerminalsList, setBuyerTerminalsList] = useState()
  const [buyerSellerProductsList, setBuyerSellerProductsList] = useState()
  const [startDate, setStartDate] = useState(null)
  const [clear, setClear] = useState(false)
  const [dropDownFields, setDropDownFields] = useState()

  useEffect(() => {
    const filters = [
      {
        field: 'sellerTerminal',
        filterKey: 'Seller Terminal',
        value: null,
        items: sellerTerminalsList,
        label: '',
      },
      {
        field: 'buyerTerminal',
        filterKey: 'Buyer Terminal',
        value: null,
        items: buyerTerminalsList,
        label: '',
      },
      {
        field: 'buyerSellerProduct',
        filterKey: 'Product',
        value: null,
        items: buyerSellerProductsList,
        label: '',
      },
    ]
    setDropDownFields(filters)
  }, [sellerTerminalsList, buyerTerminalsList, buyerSellerProductsList])

  const getListSelection = useCallback(
    async field => {
      setLoading(true)
      switch (field) {
        case 'sellerTerminal': {
          if (!sellerTerminalsList) {
            const response = await apiClient.terminals.get({
              filters: {
                companyId: document.sellerId,
              },
            })
            const sellerTerminalList = response.map(c => ({
              value: c.id,
              label: c.name,
            }))
            setSellerTerminalsList(sellerTerminalList)
          }
          break
        }
        case 'buyerTerminal': {
          if (!buyerTerminalsList) {
            const response = await apiClient.terminals.get({
              filters: { companyId: document.buyerId },
            })
            const buyerTerminalList = response.map(c => ({
              value: c.id,
              label: c.name,
            }))
            setBuyerTerminalsList(buyerTerminalList)
          }
          break
        }
        case 'buyerSellerProduct': {
          if (!buyerSellerProductsList) {
            const response = await apiClient.buyerSellerProducts.get({
              buyerId: document.buyerId,
              sellerId: document.sellerId,
            })
            const buyerSellerProducts = response?.buyerSellerProducts?.map(
              c => ({
                value: c.id,
                label: c.name,
              }),
            )
            setBuyerSellerProductsList(buyerSellerProducts)
          }
          break
        }
        default:
          break
      }
      setLoading(false)
    },
    [
      buyerSellerProductsList,
      buyerTerminalsList,
      document.buyerId,
      document.sellerId,
      sellerTerminalsList,
    ],
  )

  const handleSubmit = async () => {
    setLoading(true)
    let loadBody = {
      load: {
        date: startDate,
        buyerTerminalId: buyerTerminal,
        sellerTerminalId: sellerTerminal,
        buyerSellerProductId: buyerSellerProduct,
      },
    }
    try {
      const result = await apiClient.documents.createLoad(document.id, loadBody)
      toast.success(toastMessages.createSuccess)
      afterCreateLoad && afterCreateLoad(result)
    } catch (error) {
      toast.error(toastMessages.createError)
    }

    setLoading(false)
    hidePopup(false)
  }

  const onChangeDate = date => {
    setStartDate(date)
  }

  const onUpdateFilter = filter => {
    switch (filter.field) {
      case 'sellerTerminal':
        setSellerTerminal(filter.value)
        break
      case 'buyerTerminal':
        setBuyerTerminal(filter.value)
        break
      case 'buyerSellerProduct':
        setBuyerSellerProduct(filter.value)
        break
      default:
        break
    }
  }

  const onLoadFilter = useCallback(
    field => {
      getListSelection(field)
    },
    [getListSelection],
  )

  const onClear = () => setClear(true)

  return (
    <>
      <div
        className='customCalendar'
        style={{
          display: 'flex',
          marginLeft: 0,
          paddingLeft: 0,
          marginTop: 10,
          borderBottom: '1px solid lightgrey',
        }}
      >
        <IonCol size={'5.3'}>
          <IonButton fill='clear' color='primary'>
            <IonLabel
              style={{
                fontWeight: 'bold',
                fontSize: 'small',
                textAlign: 'left',
              }}
            >
              SELECT A DATE:
            </IonLabel>
          </IonButton>
        </IonCol>
        <IonCol style={{ zIndex: 999 }}>
          <DatePicker
            date={startDate}
            onChangeDate={onChangeDate}
            alignItem={'start'}
            isLoadForm={true}
            setClear={setClear}
            clear={clear}
            containerStyle={{
              fontSize: 13,
            }}
          />
        </IonCol>
        <IonCol>
          <IonButton fill='clear' color='dark' onClick={onClear}>
            X
          </IonButton>
        </IonCol>
      </div>

      {dropDownFields &&
        dropDownFields.map(({ field, filterKey, value, items, icon }) => (
          <div
            key={field}
            style={{
              width: 600,
              margin: '10px 0px',
              paddingLeft: 10,
              borderBottom: '1px solid lightgrey',
              zIndex: 0,
            }}
          >
            <DropdownPicker
              field={field}
              filterKey={filterKey}
              value={value}
              items={items}
              icon={icon}
              itemStyle={{ width: 300, textAlign: 'left' }}
              style={{
                width: 400,
                padding: 0,
                border: 0,
                margin: '0',
                overflow: 'visible',
              }}
              defaultLabel={' '}
              onValueChange={onUpdateFilter}
              onLoadFilter={onLoadFilter}
              isLoading={loading}
              showSearchBar={true}
              showClearButton={true}
            />
          </div>
        ))}
      <IonRow>
        <IonCol>
          <IonButton expand='block' color='success' onClick={handleSubmit}>
            Create Load
          </IonButton>
        </IonCol>
      </IonRow>
      <DocumentItem
        document={document}
        afterCreateLoad={afterCreateLoad}
        afterDeleteDocument={afterDeleteDocument}
      />
    </>
  )
}

export default LoadForm
