import { useContext } from 'react'

import ConfirmationContext from './ConfirmationContext'

const useConfirmationProvider = () => {
  const context = useContext(ConfirmationContext)
  return context
}

export default useConfirmationProvider
