import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IAllowedHierarchy,
  IGetAllowedHierarchiesParams,
} from '~/types/models/IAllowedHierarchy'

const useModifyAllowedHierarchy = (
  params: Partial<IGetAllowedHierarchiesParams> = {},
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'allowedHierarchies',
      sessionUser?.id,
      buildGetUrl(apiClient.allowedHierarchies.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const renewAllAllowedHierarchies = useCallback(
    (data: IAllowedHierarchy[]) => {
      queryClient.setQueryData<IAllowedHierarchy[] | undefined>(
        queryKey,
        () => {
          return data
        },
      )
    },
    [queryClient, queryKey],
  )

  const addAllowedHierarchy = useCallback(
    (data: IAllowedHierarchy) => {
      queryClient.setQueryData<IAllowedHierarchy[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateAllowedHierarchy = useCallback(
    (id: number, data: IAllowedHierarchy) => {
      queryClient.setQueryData<IAllowedHierarchy[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft[index] = data
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeAllowedHierarchy = useCallback(
    (id: number) => {
      queryClient.setQueryData<IAllowedHierarchy[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addAllowedHierarchy,
    updateAllowedHierarchy,
    removeAllowedHierarchy,
    renewAllAllowedHierarchies,
  }
}

export default useModifyAllowedHierarchy
