import { useCallback, useState, useEffect, useMemo } from 'react'

import { CompanyTable, Loading } from '~/components/shared'
import CostCodesBody from '~/components/my-company/cost-codes/CostCodesBody'
import CostCodesConfigurationRow from '~/components/my-company/cost-codes/CostCodesConfigurationRow'

import { produce } from 'immer'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { moduleName } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'

function CompanyCostCodeSubTab() {
  const [loading, setLoading] = useState(false)
  const [companyCostCodes, setCompanyCostCodes] = useState([])
  const [costCodeConfiguration, setCostCodeConfiguration] = useState({})
  const [searchKey, setSearchKey] = useState('')

  const sortedCodes = useMemo(() => {
    const indexMap = {}
    const topLevelIndexes = []

    return produce(companyCostCodes, draft => {
      draft.forEach((cc, i) => {
        indexMap[cc.id] = i
        if (!cc.parentId) {
          topLevelIndexes.push(cc.id)
        }
      })

      draft.forEach(cc => {
        if (cc.parentId !== null) {
          draft[indexMap[cc.parentId]].children = [
            ...(draft[indexMap[cc.parentId]].children || []),
            cc,
          ]
          if (topLevelIndexes.includes(cc.parentId)) {
            draft[indexMap[cc.id]].treeIndex = 1
          }
        }
      })
    })
  }, [companyCostCodes])

  const getCompanyCostCodes = useCallback(async () => {
    setLoading(true)
    try {
      // const params = { filters: { parent_id: 'null' } }
      const { costCodes, costCodeConfiguration } =
        await apiClient.costCodes.get()
      setCompanyCostCodes(costCodes)
      setCostCodeConfiguration(costCodeConfiguration || {})
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleSearchKeyChange = useCallback(nextSearchKey => {
    setSearchKey(nextSearchKey)
  }, [])

  const updateCostCodeConfiguration = useCallback(
    data => {
      apiClient.costCodeConfigurations
        .update(costCodeConfiguration.id, { structure: data })
        .then(response => {
          setCostCodeConfiguration(response)
          toast.success(toastMessages.updateSuccess)
        })
        .catch(err => {
          console.log('err', err)
          toast.error(toastMessages.updateError)
        })
    },
    [costCodeConfiguration?.id],
  )

  useEffect(() => {
    getCompanyCostCodes()
  }, [getCompanyCostCodes])

  return (
    <>
      {loading && <Loading />}
      <CompanyTable
        title='Cost Codes'
        description='View, edit, and delete company cost codes.'
        buttonProps={{
          hide: true,
        }}
        filterProps={{
          searchKey,
          onSearchKeyChange: handleSearchKeyChange,
          moduleName: moduleName.company.costCodes,
        }}
        renderTable={() => (
          <>
            <CostCodesConfigurationRow
              costCodeConfiguration={costCodeConfiguration}
              updateCostCodeConfiguration={updateCostCodeConfiguration}
            />
            <CostCodesBody
              searchKey={searchKey}
              sortedCodes={sortedCodes}
              costCodeStructure={costCodeConfiguration.structure}
            />
          </>
        )}
      />
    </>
  )
}

CompanyCostCodeSubTab.defaultProps = {
  // currentCompany: {}
}

export default CompanyCostCodeSubTab
