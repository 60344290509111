import React, { useCallback } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import _ from 'lodash'

const borderColor = '#1090FF'
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#1090FF',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  rowItem: {
    borderRightColor: borderColor,
    paddingLeft: 8,
    paddingRight: 8,
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
})

const PDFDataTableRow = ({ data, columns, itemHeight = 24 }) => {
  const renderRows = useCallback(() => {
    return data.map((item, index) => {
      const isLastRow = data.length === index + 1

      return (
        <View
          style={[
            styles.row,
            { borderBottomWidth: isLastRow ? 0 : 1, height: itemHeight },
          ]}
          key={item.id}
        >
          {columns.map((col, colIndex) => {
            const { accessor, width, valueGetter, cellRenderer } = col
            const isLastCell = columns.length === colIndex + 1

            let value = _.get(item, accessor)
            if (valueGetter) {
              value = valueGetter({ rowData: item, index })
            }
            const result = cellRenderer
              ? cellRenderer({
                  rowData: item,
                  rowIndex: index,
                  cellData: value,
                })
              : value

            return (
              <View
                key={`col-${item.id}-${col.accessor}`}
                style={[
                  styles.rowItem,
                  { width, borderRightWidth: isLastCell ? 0 : 1 },
                  col.align === 'right' && styles.alignRight,
                  col.align === 'center' && styles.alignCenter,
                ]}
              >
                {cellRenderer ? result : <Text>{result || ''}</Text>}
              </View>
            )
          })}
        </View>
      )
    })
  }, [columns, data, itemHeight])

  return <View style={{ width: '100%' }}>{renderRows()}</View>
}

export default PDFDataTableRow
