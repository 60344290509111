import { useMemo, useCallback } from 'react'
import _ from 'lodash'
// import { produce } from 'immer'
import moment from 'moment'

import useFilterOptions from '~/hooks/useFilterOptions'
import defaultModuleFilters from '~/constants/defaultModuleFilters'
import {
  selectAllUserTableConfigurations,
  selectUserTableConfiguration,
} from '~/redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { onUpdateFilterConfigurations } from '~/redux/actions/userTableConfigurationActions'
import useStorage from '../useStorage'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

const useStoreData = moduleName => {
  const dispatch = useDispatch()
  const [localStoredData, setLocalStoredData] = useStorage()

  const selectFilterByTableName = useMemo(
    () => selectUserTableConfiguration(moduleName),
    [moduleName],
  )
  const { id } = useSelector(selectFilterByTableName)

  const storedData = useSelector(selectAllUserTableConfigurations)

  const { filterColumns } = useFilterOptions(moduleName)
  const filterDefaultValues = useMemo(
    () => defaultModuleFilters[moduleName] || {},
    [moduleName],
  )

  const DEFAULT_STRUCTURE_DATA = useMemo(
    () => ({
      filters: {
        data: {},
        order: [],
        mode: 'horizontal',
        autoSave: false,
        updatedAt: null,
      },
      sorts: {
        data: [],
        autoSave: false,
        updatedAt: null,
      },
      columns: {
        data: [],
        autoSave: false,
        updatedAt: null,
      },
      others: {
        updatedAt: null,
      },
    }),
    [],
  )

  const moduleStoreData = useMemo(() => {
    try {
      return storedData[moduleName] || DEFAULT_STRUCTURE_DATA
    } catch (error) {
      console.log('error', error)

      return DEFAULT_STRUCTURE_DATA
    }
  }, [storedData, moduleName, DEFAULT_STRUCTURE_DATA])

  const storedFilters = useMemo(() => {
    const { filters } = DEFAULT_STRUCTURE_DATA

    try {
      const savingData = _.get(moduleStoreData, 'filterConfigurations')

      if (_.isPlainObject(savingData)) {
        if (_.isNil(savingData.data) || _.size(savingData.data) === 0) {
          return {
            ...savingData,
            data: filterDefaultValues,
          }
        }

        return savingData
      }

      return {
        ...filters,
        data: filterDefaultValues,
      }
    } catch (error) {
      return {
        ...filters,
        data: filterDefaultValues,
      }
    }
  }, [DEFAULT_STRUCTURE_DATA, filterDefaultValues, moduleStoreData])

  const storedOthers = useMemo(() => {
    const { others } = DEFAULT_STRUCTURE_DATA
    try {
      const savingData = _.get(moduleStoreData, 'others')
      if (_.isPlainObject(savingData)) {
        return savingData
      }

      return others
    } catch (error) {
      return others
    }
  }, [DEFAULT_STRUCTURE_DATA, moduleStoreData])

  const saveFilters = useCallback(
    async newFilters => {
      const filterData = {
        ...storedFilters,
        ...newFilters,
        updatedAt: moment().format('YYYY-MM-DD'),
      }

      if (id) {
        await apiClient.userTableConfigurations
          .update(
            id,
            {
              tableName: moduleName,
              filterConfigurations: filterData,
            },
            {
              tableName: moduleName,
            },
          )
          .then(response => {
            dispatch(
              onUpdateFilterConfigurations(
                response.filterConfigurations,
                moduleName,
              ),
            )
          })
      } else {
        await apiClient.userTableConfigurations
          .create({
            tableName: moduleName,
            filterConfigurations: filterData,
          })
          .then(response => {
            dispatch(
              onUpdateFilterConfigurations(
                response.filterConfigurations,
                moduleName,
              ),
            )
          })
      }
    },
    [storedFilters, moduleName, id, dispatch],
  )

  const saveSortsData = useCallback(() => {
    // const newModuleStoredData = produce(moduleStoreData, draft => {
    //   draft.sorts = newSortsData
    // })
    // setStoredData(newStoredData)
  }, [])

  const localStoredOthers = useMemo(() => {
    const { others } = DEFAULT_STRUCTURE_DATA
    try {
      const savingData = _.get(
        localStoredData[moduleName] || DEFAULT_STRUCTURE_DATA,
        'others',
      )
      if (_.isPlainObject(savingData)) {
        return savingData
      }

      return others
    } catch (error) {
      return others
    }
  }, [DEFAULT_STRUCTURE_DATA, localStoredData, moduleName])

  const saveOthersData = useCallback(
    newOthersData => {
      const othersData = {
        ...localStoredOthers,
        ...newOthersData,
        updatedAt: moment().format('YYYY-MM-DD'),
      }
      const newModuleOthersData = produce(
        localStoredData[moduleName] || DEFAULT_STRUCTURE_DATA,
        draft => {
          draft.others = othersData
        },
      )

      const newStoredData = {
        ...localStoredData,
        [moduleName]: newModuleOthersData,
      }

      setLocalStoredData(newStoredData)
      toast.success(toastMessages.updateSuccess)
    },
    [
      moduleName,
      setLocalStoredData,
      localStoredData,
      DEFAULT_STRUCTURE_DATA,
      localStoredOthers,
    ],
  )

  return {
    storedData,
    storedFilters,
    filterColumns,
    moduleStoreData,
    storedOthers,
    saveFilters,

    saveSortsData,

    DEFAULT_STRUCTURE_DATA,
    saveOthersData,
    localStoredOthers,
  }
}

export default useStoreData
