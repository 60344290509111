import { useMemo } from 'react'

import { IRTColumnDef, ReusableTable } from '~/components/shared'

import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import buildFullName from '~/utils/buildFullName'

export interface IPayrollJournalTableProps {
  data: any
}

function ContractorPaymentReportsTable(props: IPayrollJournalTableProps) {
  const { data } = props

  const columns = useMemo<IRTColumnDef<any>[]>(
    () => [
      {
        header: 'Contractor',
        accessorKey: 'contractor',
        Cell({ row: { original } }) {
          return buildFullName(original)
        },
      },
      {
        header: 'Business Name',
        accessorKey: 'businessName',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        align: 'right',
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(original.amount)
        },
      },
      {
        header: 'Period Start',
        accessorKey: 'periodStart',
        align: 'center',
      },
      {
        header: 'Period End',
        accessorKey: 'periodEnd',
        align: 'center',
      },
      {
        header: 'Payday',
        accessorKey: 'payday',
        align: 'center',
      },
      {
        header: 'Reimbursement Amount',
        accessorKey: 'reimbursementAmount',
        align: 'right',
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(original.reimbursementAmount)
        },
      },
      {
        header: 'Net Pay',
        accessorKey: 'netPay',
        align: 'right',
        minWidth: 120,
        maxWidth: 120,
        Cell({ row: { original } }) {
          return formatCurrencyToDollar.format(original.netPay)
        },
      },
    ],
    [],
  )

  return <ReusableTable data={data} columns={columns} tableHeight={350} />
}
export default ContractorPaymentReportsTable
