import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

import { CommonTab } from '~/components/shared'
import PersonTab from './PersonTab'
import DriverTab from './DriverTab'
import UserTab from './UserTab'
import UserAccessTab from './UserAccessTab'
import { workerFormDataSchema } from './schemas'
import { WorkerTab } from './WorkerTab'
import { DriverFleetTab } from './DriverFleetTab'
import { WORKER_STATUSES } from '~/utils/constants'

import './WorkerForm.scss'

const defaultFormData = {
  person: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    initials: '',
    dob: null,
  },
  driver: {
    licenseNumber: null,
    licenseState: '',
    licenseExpiration: '',
    medicalNumber: '',
    medicalExpiration: '',
    mvrNumber: null,
  },
  user: {
    email: '',
    phoneNumber: '',
    allowedCommunicationChannels: [],
  },
  userAccess: {
    userGroupId: '',
  },
  driverFleet: {
    rank: null,
    color: '#3880ff',
  },
  worker: {
    terminalId: null,
    workerType: '',
    workPayTypeId: '',
    taxType: '',
    buisnessName: '',
    ein: '',
    status: WORKER_STATUSES.active,
    statusReason: '',
    expectedReturn: null,
  },
}

const WorkerForm = props => {
  const { defaultValues = {}, onSubmit, isLoading } = props

  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(
    !_.isEmpty(defaultValues) ? defaultValues : defaultFormData,
  )

  const [currentTab, setCurrentTab] = useState('worker')

  const handleSubmitForm = useCallback(
    (key, nextTab) => formData => {
      setFormData(prev => ({
        ...prev,
        [key]: formData,
      }))
      if (nextTab) {
        setCurrentTab(nextTab)
      }
    },
    [],
  )
  const onChangeTab = useCallback(nextTab => {
    setCurrentTab(nextTab)
  }, [])

  const handleFinish = useCallback(
    async formValues => {
      try {
        const payload = {
          ...formData,
          userAccess: formValues,
        }

        const validatedPayload = await workerFormDataSchema.validate(payload, {
          abortEarly: false,
          stripUnknown: true,
        })
        onSubmit && onSubmit(validatedPayload)
      } catch (error) {
        const errors = {}
        error.inner.forEach(err => {
          const [tab] = err.path.split('.')
          if (Array.isArray(errors[tab])) {
            errors[tab].push(err.message)
          } else {
            errors[tab] = [err.message]
          }
        })
        setErrors(errors)
      }
    },
    [formData, onSubmit],
  )

  const isWorkerDriver = useMemo(() => {
    return formData?.worker?.workerType === 'driver'
  }, [formData?.worker?.workerType])

  const isWorkerContractor = useMemo(
    () => formData?.worker?.taxType === 'contractor',
    [formData?.worker?.taxType],
  )

  const driverTabs = useMemo(() => {
    return [
      {
        name: 'driver',
        label: 'Driver',
        errors: errors.driver,
        render() {
          return (
            <DriverTab
              onSubmit={handleSubmitForm('driver', 'driverFleet')}
              onChange={handleSubmitForm('driver')}
              isHiddenCancelButton={false}
              cancelText='Back'
              onCancel={() => onChangeTab('person')}
              defaultValues={defaultValues.driver}
            />
          )
        },
      },
      {
        name: 'driverFleet',
        label: 'Driver Fleet',
        errors: errors.driverFleet,
        render: () => {
          return (
            <DriverFleetTab
              onSubmit={handleSubmitForm('driverFleet', 'user')}
              onChange={handleSubmitForm('driverFleet')}
              onCancel={() => onChangeTab('userAccess')}
              defaultValues={defaultValues.driverFleet}
              isLoading={isLoading}
            />
          )
        },
      },
    ]
  }, [
    defaultValues.driver,
    defaultValues.driverFleet,
    errors.driver,
    errors.driverFleet,
    handleSubmitForm,
    isLoading,
    onChangeTab,
  ])

  const tabs = useMemo(() => {
    return [
      {
        name: 'worker',
        label: 'Worker',
        errors: errors.worker,
        render: () => (
          <WorkerTab
            onSubmit={handleSubmitForm('worker', 'person')}
            onChange={handleSubmitForm('worker')}
            defaultValues={defaultValues.worker}
            isHiddenCancelButton
            isWorkerContractor={isWorkerContractor}
          />
        ),
      },
      {
        name: 'person',
        label: 'Person',
        errors: errors.person,
        render() {
          return (
            <PersonTab
              onSubmit={handleSubmitForm('person', 'driver')}
              onChange={handleSubmitForm('person')}
              defaultValues={defaultValues.person}
              cancelText='Back'
              onCancel={() => onChangeTab('worker')}
            />
          )
        },
      },
      ...(isWorkerDriver ? driverTabs : []),
      {
        name: 'user',
        label: 'Contact Info',
        errors: errors.user,
        render() {
          return (
            <UserTab
              onSubmit={handleSubmitForm('user', 'userAccess')}
              onChange={handleSubmitForm('user')}
              isHiddenCancelButton={false}
              cancelText='Back'
              onCancel={() => onChangeTab('driverFleet')}
              defaultValues={defaultValues.user}
            />
          )
        },
      },
      {
        name: 'userAccess',
        label: 'User Access',
        errors: errors.userAccess,
        render: () => (
          <UserAccessTab
            onSubmit={handleFinish}
            isHiddenCancelButton={false}
            cancelText='Back'
            submitText={defaultValues?.user?.id ? 'Update' : 'Create'}
            onCancel={() => onChangeTab('user')}
            isLoading={isLoading}
            defaultValues={defaultValues.userAccess}
          />
        ),
      },
    ]
  }, [
    defaultValues.person,
    defaultValues.user,
    defaultValues.userAccess,
    defaultValues.worker,
    driverTabs,
    errors.person,
    errors.user,
    errors.userAccess,
    errors.worker,
    handleFinish,
    handleSubmitForm,
    isLoading,
    isWorkerContractor,
    isWorkerDriver,
    onChangeTab,
  ])

  return (
    <CommonTab tabs={tabs} currentTab={currentTab} onChangeTab={onChangeTab} />
  )
}

export default WorkerForm
