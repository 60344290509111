import { useState, useCallback } from 'react'

import ModalDownloadProgressContext from './Context'
import ModalDownloadProgress from './Modal'

import { produce } from 'immer'
import { toast } from 'react-toastify'

function ModalDownloadProgressProvider(props) {
  const { children } = props

  const [files, setFiles] = useState([]) // { name: string }[]

  const addFileDownloading = useCallback(file => {
    setFiles(prev => [...prev, file])
  }, [])

  const removeFileDownloading = useCallback(file => {
    const { name } = file
    setFiles(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(fileState => fileState.name === name)

        if (index !== -1) {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  /**
   * @param {string} url
   * @param {string} filename
   */
  const downloadFile = useCallback(
    async (url, filename) => {
      addFileDownloading({ name: filename })
      try {
        const response = await fetch(url)
        const parseUrl = await response.blob()
        const a = document.createElement('a')
        a.href = URL.createObjectURL(parseUrl)
        a.setAttribute('download', filename)
        a.click()
      } catch (error) {
        console.log('error', error)
        toast.error('An error has occurred while downloading the PDF(s).')
      } finally {
        removeFileDownloading({ name: filename })
      }
    },
    [addFileDownloading, removeFileDownloading],
  )

  /**
   * @param {{ name: string }[]} files
   */
  const downloadMultipleFiles = useCallback(
    async files => {
      const filesDownloading = []
      files.forEach(({ name, url }) => {
        filesDownloading.push(downloadFile(url, name))
      })

      await Promise.race(filesDownloading)
    },
    [downloadFile],
  )

  const providerValue = {
    addFileDownloading,
    removeFileDownloading,
    files,
    downloadFile,
    downloadMultipleFiles,
  }

  return (
    <ModalDownloadProgressContext.Provider value={providerValue}>
      {children}
      <ModalDownloadProgress />
    </ModalDownloadProgressContext.Provider>
  )
}

ModalDownloadProgressProvider.propTypes = {}

export default ModalDownloadProgressProvider
