import React from 'react'
import useTruckInfoBoxList from './useTruckInfoBoxList'

import { ScheduleCardOnMap } from '../ScheduleCardOnMap'

import type { ITruckInfoBoxListProps } from './type'
import { When } from 'react-if'
import { ITruck } from '~/types/models/ITruck'

function TruckInfoBoxList(props: ITruckInfoBoxListProps) {
  const { renderTrucks, selectedSchedule } = useTruckInfoBoxList(props)

  return (
    <>
      {renderTrucks}
      <When
        condition={
          selectedSchedule.isOpen && selectedSchedule.driverFleet?.loadId
        }
      >
        <ScheduleCardOnMap
          loadId={selectedSchedule.driverFleet?.loadId as number}
          truck={selectedSchedule.truck as ITruck}
        />
      </When>
    </>
  )
}
export default React.memo(TruckInfoBoxList)
