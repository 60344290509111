import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { selectDriverDetails } from '~/redux/selectors'

import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ICheckBankAccount } from '~/types/models/ICheck'
import { produce } from 'immer'

const useModifyCheckBankAccounts = () => {
  const driverDetails: IDriverFleet | null = useSelector(selectDriverDetails)

  const workerUid = driverDetails?.worker.checkUid

  const queryClient = useQueryClient()

  const key = useMemo(() => ['checkBankAccounts', workerUid], [workerUid])

  const addCheckBankAccount = useCallback(
    (payload: ICheckBankAccount) => {
      queryClient.setQueryData<ICheckBankAccount[] | undefined>(
        key,
        oldData => {
          if (oldData) {
            return [payload, ...oldData]
          }
          return oldData
        },
      )
    },
    [key, queryClient],
  )
  const deleteCheckBankAccount = useCallback(
    (bankId: string) => {
      queryClient.setQueryData<ICheckBankAccount[] | undefined>(key, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(({ id }) => id === bankId)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [key, queryClient],
  )

  return { addCheckBankAccount, deleteCheckBankAccount }
}

export default useModifyCheckBankAccounts
