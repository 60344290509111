import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { Badge } from 'react-bootstrap'
import { IonIcon, IonSpinner } from '@ionic/react'

import {
  checkmark,
  chevronDown,
  chevronUp,
  close,
  closeOutline,
  layers,
  mapOutline,
} from 'ionicons/icons'

import type { IBundleBadgeProps } from './type'
import { ToolTipOverlay, AutoWidthInput } from '~/components/shared'

import './styles.scss'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { Unless, When } from 'react-if'
import { IBundle } from '~/types/models/IBundle'
import { useDrop } from 'react-dnd'
import { SellerTerminalProductsList } from '../SellerTerminalProductsList'
import { BundleItemsList } from '../BundleItemsList'

function BundleBadge(props: IBundleBadgeProps) {
  const {
    bundleData,
    sellerProductId,
    onRemove,
    afterUpdateBundle,
    afterCreateBundle,
  } = props

  const { confirmation } = useConfirmationProvider()

  const [isOpenBundleItems, setIsOpenBundleItems] = useState(false)
  const [isShowTerminalProducts, setIsShowTerminalProducts] = useState(false)

  const [isShowingBundleInputs, setIsShowingBundleInputs] = useState(false)
  const [bundleFormValues, setBundleFormValues] = useState({
    name: '',
    num: null as number | null,
  })
  const [isLoadingBundleForm, setIsLoadingBundleForm] = useState(false)
  const [error, setError] = useState('')

  const [{ canDrop, isOver, item }, drop] = useDrop(() => ({
    accept: 'BUNDLE_CARD',
    drop: () => ({ bundle: bundleData }),
    collect: monitor => {
      const item: ISellerTerminalProduct = monitor.getItem()
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        item,
      }
    },
  }))

  const style = useMemo(() => {
    if (item && item.bundleId === bundleData?.id) {
      return {}
    }
    const isActive = canDrop && isOver

    if (isActive) {
      return {
        borderColor: 'var(--ion-color-success)',
        boxShadow: 'rgba(9,199,41, 0.2) 0px 2px 8px 0px',
      }
    }

    if (canDrop) {
      return {
        borderColor: 'var(--ion-color-concord)',
        boxShadow: 'rgb(6, 59, 226, 0.2) 0px 2px 8px 0px',
      }
    }

    return {}
  }, [bundleData?.id, canDrop, isOver, item])

  const onToggleBundleInputs = useCallback(() => {
    setIsShowingBundleInputs(prev => !prev)
  }, [])

  const createBundle = useCallback(async () => {
    setIsLoadingBundleForm(true)
    try {
      const { errors, ...response } = await apiClient.bundles.create({
        bundle: {
          sellerProductId,
          num: bundleFormValues.num,
          name: bundleFormValues.name,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreateBundle && afterCreateBundle(response)
        toast.success(toastMessages.createSuccess)
        onToggleBundleInputs()
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.createError)
    } finally {
      setIsLoadingBundleForm(false)
    }
  }, [
    afterCreateBundle,
    bundleFormValues.name,
    bundleFormValues.num,
    onToggleBundleInputs,
    sellerProductId,
  ])

  const updateBundle = useCallback(async () => {
    setIsLoadingBundleForm(true)
    try {
      if (bundleData?.id) {
        const { errors, ...response } = await apiClient.bundles.update(
          bundleData.id,
          {
            bundle: {
              name: bundleFormValues.name,
              num: bundleFormValues.num,
            },
          },
        )
        if (errors.length) {
          setError(errors[0])
        } else {
          onToggleBundleInputs()
          afterUpdateBundle && afterUpdateBundle(response)
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsLoadingBundleForm(false)
    }
  }, [
    afterUpdateBundle,
    bundleData?.id,
    bundleFormValues.name,
    bundleFormValues.num,
    onToggleBundleInputs,
  ])

  const onSubmitBundleForm = useCallback(() => {
    setError('')
    if (bundleData?.id) {
      updateBundle()
    } else {
      createBundle()
    }
  }, [bundleData?.id, createBundle, updateBundle])

  const onChangeBundleInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const { name, value } = event.target
      setBundleFormValues(prev => ({
        ...prev,
        [name]: value,
      }))
    },
    [],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const onClickRemove = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to remove this item?',
    })
    if (result === EYesNo.Yes) {
      onRemove && onRemove(bundleData as IBundle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onToggleShowHideBundleItems = useCallback(() => {
    setIsOpenBundleItems(prev => !prev)
  }, [])

  const onToggleShowHideTerminalProducts = useCallback(() => {
    setIsShowTerminalProducts(prev => !prev)
  }, [])

  useEffect(() => {
    setBundleFormValues({
      name: bundleData?.name || '',
      num: bundleData?.num || null,
    })

    if (!bundleData?.id) {
      setIsShowingBundleInputs(true)
    }
  }, [bundleData])

  return (
    <div className='BundleBadge__container' ref={drop} style={style}>
      {isLoadingBundleForm && (
        <div className='loadingSpinner'>
          <IonSpinner name='dots' />
        </div>
      )}

      <Badge bg='secondary' className='badgeItem'>
        <Unless condition={isShowingBundleInputs}>
          <span onClick={onToggleBundleInputs}>
            <span>#{bundleFormValues.num}</span>
            {bundleFormValues.name && <span> - {bundleFormValues.name}</span>}
          </span>
        </Unless>

        <When condition={isShowingBundleInputs}>
          <span>Num: </span>
          <AutoWidthInput
            autoFocus
            className='autoWidthInput'
            placeholder='Num'
            type='number'
            value={bundleFormValues.num || ''}
            name='num'
            onChange={onChangeBundleInput}
          />{' '}
          - <span>Name: </span>
          <AutoWidthInput
            className='autoWidthInput'
            placeholder='Name'
            name='name'
            value={bundleFormValues.name}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChangeBundleInput}
          />
          <span className='submitBundleIcon' onClick={onSubmitBundleForm}>
            <IonIcon icon={checkmark} color='success' />
          </span>
          {bundleData?.id && (
            <span className='closeBundleInputs' onClick={onToggleBundleInputs}>
              <IonIcon icon={closeOutline} color='danger' />
            </span>
          )}
        </When>
      </Badge>

      <When condition={Boolean(bundleData?.id)}>
        <ToolTipOverlay content='Terminals' placement='top'>
          <Badge
            bg={isShowTerminalProducts ? 'info' : 'secondary'}
            className='badgeItem icon'
            onClick={onToggleShowHideTerminalProducts}
          >
            <IonIcon icon={mapOutline} />
            <IonIcon
              style={{ marginLeft: 4 }}
              icon={isShowTerminalProducts ? chevronUp : chevronDown}
            />
          </Badge>
        </ToolTipOverlay>

        <ToolTipOverlay content='Bundle Items' placement='top'>
          <Badge
            bg={isOpenBundleItems ? 'success' : 'secondary'}
            className='badgeItem icon'
            onClick={onToggleShowHideBundleItems}
          >
            <IonIcon icon={layers} />
            <IonIcon
              style={{ marginLeft: 4 }}
              icon={isOpenBundleItems ? chevronUp : chevronDown}
            />
          </Badge>
        </ToolTipOverlay>

        <ToolTipOverlay content='Remove this Bundle' placement='top'>
          <Badge bg='danger' className='badgeItem icon' onClick={onClickRemove}>
            <IonIcon icon={close} />
          </Badge>
        </ToolTipOverlay>
      </When>

      {error && <span className='error'>{error}</span>}

      {/* <div className='removeIcon icon' onClick={onClickRemove}>
        <IonIcon icon={close} />
      </div> */}

      {isShowTerminalProducts && bundleData ? (
        <SellerTerminalProductsList
          bundleId={bundleData.id}
          sellerProductId={sellerProductId}
        />
      ) : null}

      {isOpenBundleItems && bundleData ? (
        <BundleItemsList
          bundleId={bundleData.id}
          sellerProductId={sellerProductId}
        />
      ) : null}
    </div>
  )
}

export default BundleBadge
