import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
// import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'
import { DEFAULT_COMPANY_REFERENCE_NAME_COLUMNS } from '~/constants/companySubTabs'

const referenceNamesSlice = createSlice({
  name: moduleName.company.referenceNames,
  initialState: {
    columns: DEFAULT_COMPANY_REFERENCE_NAME_COLUMNS,
  },
})

export default referenceNamesSlice
