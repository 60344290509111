import { useContext } from 'react'

import DialogCreateEditCompanyContext from './DialogCreateEditCompanyContext'

const useDialogCreateEditCompany = () => {
  const context = useContext(DialogCreateEditCompanyContext)

  return context
}

export default useDialogCreateEditCompany
