import { CommonDialogV2 } from '~/components/shared'
import VerticalCommonFilter from '~/components/shared/ContainerFilterV2/VerticalCommonFilter'

import './FilterModalPopup.scss'

const FilterModalPopup = props => {
  const {
    open,
    onClose,
    onReset,
    filterData,
    filterOptions,
    onChangeFilters,
    pluralModelName,
    ...dialogProps
  } = props

  return (
    <CommonDialogV2
      isOpen={open}
      onClose={onClose}
      title='Search Filters'
      isHiddenOkButton
      className='FilterModalPopup__container'
      {...dialogProps}
    >
      <VerticalCommonFilter
        filterData={filterData}
        filterOptions={filterOptions}
        onChangeFilters={onChangeFilters}
        pluralModelName={pluralModelName}
      />
    </CommonDialogV2>
  )
}

export default FilterModalPopup
