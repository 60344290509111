import type { MouseEvent } from 'react'
import type {
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import clsx from 'clsx'
import { ArrowForwardIcon } from '~/components/shared'

export interface IRTExpandButtonProps<TData extends IReusableTableRowData> {
  row: RTRow<TData>
  staticRowIndex?: number
  table: ReusableTableInstance<TData>
}

export const RTExpandButton = <TData extends IReusableTableRowData>({
  row,
  table,
}: IRTExpandButtonProps<TData>) => {
  const {
    getState,
    options: { positionExpandColumn, renderDetailPanel },
  } = table
  const { density } = getState()

  const canExpand = row.getCanExpand()
  const isExpanded = row.getIsExpanded()

  const handleToggleExpand = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    row.toggleExpanded()
  }

  const detailPanel = !!renderDetailPanel?.({ row, table })

  return (
    <div
      aria-label='expand'
      role='button'
      onClick={handleToggleExpand}
      style={{
        height: density === 'compact' ? '1.75rem' : '2.25rem',
        opacity: !canExpand && !detailPanel ? 0.3 : 1,
        [positionExpandColumn === 'last' ? 'mr' : 'ml']: `${row.depth * 16}px`,
        width: density === 'compact' ? '1.75rem' : '2.25rem',
      }}
      className={clsx({ isDisabled: !canExpand && !detailPanel })}
    >
      <ArrowForwardIcon
        style={{
          transform: `rotate(${
            !canExpand && !renderDetailPanel
              ? positionExpandColumn === 'last'
                ? 90
                : 0
              : isExpanded
              ? 90
              : 0
          }deg)`,
          transition: 'transform 150ms',
        }}
      />
    </div>
  )
}
