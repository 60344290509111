import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { IGetNewCalculationResponse } from '~/types/models/ICalculation'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryNewCalculationData = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params = {},
  options?: Partial<UseQueryOptions<IGetNewCalculationResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['new_calculations', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.calculations.new()
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const modelOptions = (data?.modelOptions || []).map(model => ({
    value: model,
    label: model,
  }))

  const allowedOperators = data?.allowedOperators || []

  const escapeRegexChars = (chars: string[]) => {
    return chars.map(char => {
      // eslint-disable-next-line no-useless-escape
      return char.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
    })
  }

  const buildRegex = (specialChars: string[]) => {
    // Escape the special characters
    const escapedChars = escapeRegexChars(specialChars)

    // Join the escaped characters into a regex pattern
    const pattern = escapedChars.join('|')

    // Build the final regex with the required constraints, including space and @
    return new RegExp(`^(?=.*[a-zA-Z0-9])[a-zA-Z0-9${pattern}\\s@]*$`)
  }

  return {
    modelOptions,
    isLoadingNewCalculationData: isLoading,
    allowedOperators,
    regexAllowedOperators: buildRegex(allowedOperators),
  }
}

export default useQueryNewCalculationData
