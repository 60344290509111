import { useEffect, useCallback, useState } from 'react'
import { Geolocation } from '@capacitor/geolocation'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  updateBackendCurrentLocation,
  fetchCurrentLocation,
} from '~/redux/actions/breadcrumbLoadActions'
import useInterval from '~/hooks/useInterval'

// const { Geolocation } = Plugins;

const LocationTracking = () => {
  const dispatch = useDispatch()
  const breadcrumbLoad = useSelector(state => state.breadcrumbLoad)
  const { user } = useSelector(state => state.session)
  const currentLocation = breadcrumbLoad.get('currentLocation')
  const [_syncLocation, setSyncLocation] = useState(currentLocation)

  const updateCurrentLocation = useCallback(
    locationObject => {
      const coord = locationObject.coords
      const location = {
        accuracy: coord.accuracy,
        altitude: coord.altitude,
        altitudeAccuracy: coord.altitudeAccuracy,
        latitude: coord.latitude,
        longitude: coord.longitude,
        speed: coord.speed,
        timestamp: locationObject.timestamp,
      }
      // Avoid over refreshing Virtual DOM
      if (!_.isEqual(location, currentLocation)) {
        dispatch(fetchCurrentLocation(location)) //Update location to root state
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentLocation, dispatch],
  )

  const updateDatabaseCurrentLocation = useCallback(() => {
    if (
      !_.isEqual(_syncLocation, currentLocation) &&
      user &&
      user.driver &&
      user.driver.loadId
    ) {
      dispatch(
        updateBackendCurrentLocation(currentLocation, {
          id: user.driver.loadId,
          currentStatus: user.driver.loadStatus,
        }),
      )
      setSyncLocation(currentLocation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, _syncLocation, user])

  useInterval(() => {
    updateDatabaseCurrentLocation()
  }, 30000)

  useEffect(() => {
    const geo = Geolocation.watchPosition(
      { timeout: 5000, enableHighAccuracy: true },
      (position, err) => {
        if (!err) {
          updateCurrentLocation(position)
        }
      },
    )

    return () => {
      //Clear watch when unmount
      Geolocation.clearWatch({ id: geo })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default LocationTracking
