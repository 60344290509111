import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IProductTaxOverrideFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { useQuerySellerProducts, useQueryTaxes } from '~/hooks/useQueryData'
import { IProductTaxOverrideFormValues } from '~/types/models/IProductTaxOverride'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import * as Yup from 'yup'

const ProductTaxOverrideForm = (props: IProductTaxOverrideFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { taxOptions } = useQueryTaxes()
  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Tax',
        name: 'taxId',
        type: EFieldType.singleSelect,
        options: [
          {
            label: 'Tax exempt',
            value: 'null',
          },
          ...taxOptions,
        ],
      },
      {
        label: 'Seller Product',
        name: 'sellerProductId',
        type: EFieldType.singleSelect,
        options: sellerProductOptions,
        isRequired: true,
      },
    ],
    [sellerProductOptions, taxOptions],
  )

  const defaultValues = useMemo<IProductTaxOverrideFormValues>(
    () => ({
      taxId: null,
      sellerProductId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerProductId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IProductTaxOverrideFormValues) => {
      const { errors, ...response } =
        await apiClient.productTaxOverrides.create({
          productTaxOverride: {
            taxId: formValues.taxId === 'null' ? null : formValues.taxId,
            sellerProductId: formValues.sellerProductId,
          },
        })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IProductTaxOverrideFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['taxId', 'sellerProductId'])
        const { errors, ...response } =
          await apiClient.productTaxOverrides.update(formValues.id, {
            productTaxOverride: payload,
          })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IProductTaxOverrideFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (formData?.taxId === null) {
      formRef.current?.setValue('taxId', 'null')
    }
  }, [formData])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default ProductTaxOverrideForm
