import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'
import _ from 'lodash'
import MultiselectOrderDropDown from '~/components/shared/MultiselectOrderDropdown'
import queryString from 'query-string'

import { createOrUpdateUserTableConfigurations } from '~/redux/actions/userTableConfigurationActions'
import { columnArrayToColumnHash } from '~/utils/userTableConfigurationUtils'

import '../ContainerFilter.scss'
import { PROFILE_SETTINGS } from '~/utils/constants'

const SortOptionsButton = ({
  sortOptions,
  columnsFromUserTableConfigurations,
  moduleName,
  onSortChange,
  DEFAULT_COLUMNS,
}) => {
  const dispatch = useDispatch()
  const tableId = useSelector(
    state => state.data.userTableConfigurations[moduleName]?.id,
  )
  const router = useRouter()

  const disabledClearButtonSortOptions = useMemo(() => {
    if (sortOptions.length === 0) {
      return true
    }

    return _.every(sortOptions || [], ['sorted', false])
  }, [sortOptions])

  const disabledSaveButtonSortOptions = useMemo(() => {
    const modifiedSortOptions = sortOptions.map(cols => ({
      sortIndex: cols.sortIndex,
      isAsc: cols.isAsc,
      sorted: cols.sorted,
      sortField: cols.sortField,
      field: cols.field,
    }))

    const filteredData = columnsFromUserTableConfigurations
      ?.filter(c => c.sortIndex || c.sortIndex == 0)
      ?.map(cols => ({
        sortIndex: cols.sortIndex,
        isAsc: cols.isAsc,
        sorted: cols.sorted,
        sortField: cols.sortField,
        field: cols.field,
      })) || []

    const modifiedSortHash = columnArrayToColumnHash(modifiedSortOptions)
    const filteredHash = columnArrayToColumnHash(filteredData)

    return _.isEqual(filteredHash, modifiedSortHash)
  }, [sortOptions, columnsFromUserTableConfigurations])

  const handleSaveSortData = useCallback(() => {
    dispatch(
      createOrUpdateUserTableConfigurations(
        moduleName,
        sortOptions,
        columnsFromUserTableConfigurations,
        tableId,
      ),
    )
  }, [
    sortOptions,
    columnsFromUserTableConfigurations,
    dispatch,
    moduleName,
    tableId,
  ])

  const handleClearSortData = useCallback(() => {
    const newSorts = sortOptions.map(sortItem => ({
      ...sortItem,
      sorted: false,
    }))
    onSortChange && onSortChange(newSorts)
  }, [onSortChange, sortOptions])

  const handleResetSortData = useCallback(() => {
    if (columnsFromUserTableConfigurations.length > 0) {
      onSortChange(columnsFromUserTableConfigurations)
    } else {
      const defaultSortOptions = DEFAULT_COLUMNS.map(col => ({
        sorted: col.sorted,
        sortIndex: col.sortIndex,
        isAsc: col.isAsc,
        field: col.field,
        sortField: col.sortField,
      }))
      onSortChange(defaultSortOptions)
    }
  }, [onSortChange, columnsFromUserTableConfigurations, DEFAULT_COLUMNS])

  const handleSortOptionsDirectToSettings = useCallback(() => {
    const url = queryString.stringifyUrl({
      url: '/profile',
      query: {
        sub_tab: moduleName,
        tab: PROFILE_SETTINGS.storedData,
        section: 'sorts',
      },
    })

    router.push(url)
  }, [moduleName, router])

  return (
    <MultiselectOrderDropDown
      options={sortOptions}
      onChange={onSortChange}
      isButtonGroup
      onSave={handleSaveSortData}
      onClear={handleClearSortData}
      onReset={handleResetSortData}
      onGoPage={handleSortOptionsDirectToSettings}
      disableClearButton={disabledClearButtonSortOptions}
      disableSaveButton={disabledSaveButtonSortOptions}
      disableResetButton={disabledSaveButtonSortOptions}
    />
  )
}

export default SortOptionsButton
