import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyRedemptions from './useModifyRedemptions'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetRedemptionsParams,
  IRedemption,
} from '~/types/models/IRedemption'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryRedemption = (
  params: IGetRedemptionsParams,
  options?: Partial<UseQueryOptions<IRedemption[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'redemptions',
      sessionUser?.id,
      buildGetUrl(apiClient.redemptions.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.redemptions.get(params)
      return response.redemptions
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const redemptionsData = useMemo(() => data || [], [data])

  const { addRedemption, updateRedemption, removeRedemption } =
    useModifyRedemptions(params)

  return {
    redemptionsData,
    isLoadingRedemptions: isLoading,
    addRedemption,
    updateRedemption,
    removeRedemption,
  }
}

export default useQueryRedemption
