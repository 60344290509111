import { useState, useCallback, useMemo } from 'react'
import { useConfirmationProvider } from '~/contexts'

import {
  ConcordFormLayout,
  ConcordFormStructure,
  EditIcon,
  DeleteIcon,
  ReusableTable,
} from '~/components/shared'
import { Form } from 'react-bootstrap'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { produce } from 'immer'

import './ConfigurationItemDataTable.scss'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { When } from 'react-if'

function ConfigurationItemDataTable(props) {
  const { label, data, error, onChange, labelProps, isLoading } = props

  const { confirmation } = useConfirmationProvider()

  const [formData, setFormData] = useState({
    ticketNum: null,
    qty: null,
    price: null,
    lineDate: '',
    discount: null,
    netPrice: null,
  })
  const [isOpenForm, setIsOpenForm] = useState(false)

  const formFields = useMemo(
    () => [
      {
        label: 'Ticket #',
        name: 'ticketNum',
        type: FORM_FIELD_TYPE.number,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Quantity',
        name: 'qty',
        type: FORM_FIELD_TYPE.number,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Price',
        name: 'price',
        type: FORM_FIELD_TYPE.number,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Line Date',
        name: 'lineDate',
        selectedDateFormatter: 'YYYY-MM-DD',
        type: FORM_FIELD_TYPE.date,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Discount',
        name: 'discount',
        type: FORM_FIELD_TYPE.number,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Net price',
        name: 'netPrice',
        type: FORM_FIELD_TYPE.number,
        size: 4,
        isRequired: true,
      },
    ],
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        ticketNum: Yup.number()
          .required('Ticket # is required!')
          .typeError('Ticket # is required!')
          .nullable(),
        qty: Yup.number()
          .required('Quantity is required!')
          .typeError('Quantity is required!')
          .nullable(),
        price: Yup.number()
          .required('Price is required!')
          .typeError('Price is required!')
          .nullable(),
        lineDate: Yup.string()
          .required('Line date is required!')
          .typeError('Line date is required!')
          .nullable(),
        discount: Yup.number()
          .required('Discount is required!')
          .typeError('Discount is required!')
          .nullable(),
        netPrice: Yup.number()
          .required('Net price is required!')
          .typeError('Net price is required!')
          .nullable(),
      }),
    [],
  )

  const onDeleteItem = useCallback(
    ({ rowIndex }) =>
      async () => {
        const result = await confirmation({
          message: 'Are you sure you want to delete this item?',
        })

        if (result === EYesNo.Yes) {
          const newItems = produce(data, draft => {
            delete draft[rowIndex]
          })
          onChange && onChange(newItems)
        }
      },
    [confirmation, data, onChange],
  )

  const onOpenEdit = useCallback(
    rowData => () => {
      setFormData(rowData)
      setIsOpenForm(true)
    },
    [],
  )

  const dataGridColumns = useMemo(
    () => [
      {
        header: 'Ticket #',
        accessorKey: 'ticketNum',
      },
      {
        header: 'Quantity',
        accessorKey: 'qty',
      },
      {
        header: 'Price',
        accessorKey: 'price',
      },
      {
        header: 'Line date',
        accessorKey: 'lineDate',
      },
      {
        header: 'Discount',
        accessorKey: 'discount',
      },
      {
        header: 'Net price',
        accessorKey: 'netPrice',
      },
    ],
    [],
  )

  const onClickCreateIcon = useCallback(() => {
    setIsOpenForm(true)
  }, [])

  const onClosePopover = useCallback(() => {
    setIsOpenForm(false)
    setFormData({
      ticketNum: null,
      qty: null,
      price: null,
      lineDate: '',
      discount: null,
      netPrice: null,
    })
  }, [])

  const handleSubmit = useCallback(
    formData => {
      let newValues = []
      if (formData.id) {
        newValues = produce(data, draft => {
          const index = draft.findIndex(({ id }) => id === formData.id)
          if (draft[index]) {
            draft[index] = formData
          }
        })
      } else {
        newValues = [{ ...formData, id: uuidv4() }, ...data]
      }
      onChange && onChange(newValues)
      setIsOpenForm(false)
      setFormData({
        ticketNum: null,
        qty: null,
        price: null,
        lineDate: '',
        discount: null,
        netPrice: null,
      })
    },
    [data, onChange],
  )

  return (
    <>
      <ConcordFormLayout
        isRequired
        label={label}
        isHiddenCreateIcon={false}
        onClickCreateIcon={onClickCreateIcon}
        error={error}
        {...labelProps}
      >
        <div style={{ marginBottom: 8 }}>
          {error && <Form.Text>{error}</Form.Text>}
        </div>

        <When condition={isOpenForm}>
          <div className='ConfigurationItemDataTable__formContainer'>
            <ConcordFormStructure
              fields={formFields}
              isHiddenSearch
              schema={schema}
              formData={formData}
              submitButtonProps={{
                size: 'small',
              }}
              cancelButtonProps={{
                size: 'small',
              }}
              onSubmit={handleSubmit}
              isHiddenSubmitButton={false}
              onCancel={onClosePopover}
            />
          </div>
        </When>

        <ReusableTable
          columns={dataGridColumns}
          data={data}
          tableHeight={200}
          state={{
            isLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 80,
              maxSize: 80,
            },
          }}
          enableRowActions
          enableColumnPinning
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: onOpenEdit(row.original),
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: onDeleteItem({
                id: row.original.id,
                rowIndex: row.index,
              }),
            },
          ]}
        />
      </ConcordFormLayout>
    </>
  )
}

export default ConfigurationItemDataTable
