import { useCallback, useMemo, useRef, useState } from 'react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { usePayrollReportFormValuesProvider } from '~/contexts'
import { useQueryPayrolls } from '~/hooks/useQueryData'

import { ApiClientRequestOption, apiClient } from '~/api/ApiClient'

import {
  Button,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import PayrollJournalTable from './PayrollJournalTable'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { toast } from 'react-toastify'
import moment from 'moment'

function PayrollJournalForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const isMobile = useIsMobile()

  const { reportFormValues, onChangeReportFormValues } =
    usePayrollReportFormValuesProvider()

  const formData = useMemo(
    () => ({
      content: reportFormValues.content,
      date: reportFormValues.dateRange,
      payroll: reportFormValues.payroll,
      includeTaxableWages: false,
      includeEmployeeId: reportFormValues.includeEmployeeId,
      includeContractors: reportFormValues.includeContractors,
      groupBy: null,
    }),
    [
      reportFormValues.content,
      reportFormValues.dateRange,
      reportFormValues.includeContractors,
      reportFormValues.includeEmployeeId,
      reportFormValues.payroll,
    ],
  )

  const [summaryData, setSummaryData] = useState([])
  const [isShowTable, setIsShowTable] = useState(false)

  const { payrollsOptions } = useQueryPayrolls()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'date',
        label: 'Date',
        type: EFieldType.dateRange,
        size: isMobile ? 12 : 3,
        isRequired: true,
      },
      {
        name: 'payroll',
        label: 'Payroll',
        options: payrollsOptions,
        type: EFieldType.multipleSelect,
        size: isMobile ? 12 : 6,
      },
      {
        name: 'groupBy',
        label: 'Group By Workplace',
        size: 12,
        type: EFieldType.checkbox,
      },
      {
        name: 'content',
        label: 'Content',
        size: 12,
        type: EFieldType.radio,
        options: [
          {
            label: 'Table',
            value: 'table',
          },
          {
            label: 'CSV',
            value: 'csv',
          },
        ],
      },
      {
        name: 'includeTaxableWages',
        label: 'Include Taxable Wages',
        size: 12,
        type: EFieldType.checkbox,
      },
      {
        name: 'includeEmployeeId',
        label: 'Include Employee Id',
        size: 12,
        type: EFieldType.checkbox,
      },
      {
        name: 'includeContractors',
        label: 'Include Contractors',
        size: 12,
        type: EFieldType.checkbox,
      },
    ],
    [isMobile, payrollsOptions],
  )

  const onSubmit = useCallback(() => {
    formRef.current.handleSubmit(async (formData: any) => {
      try {
        const params: Partial<ApiClientRequestOption> = {
          query: {},
        }
        params.query.qsParams = {
          start: moment(formData.date.startDate).format('YYYY-MM-DD'),
          end: moment(formData.date.endDate).format('YYYY-MM-DD'),
          payroll: formData.payroll,
          includeTaxableWages: formData.includeTaxableWages,
          includeEmployeeId: formData.includeEmployeeId,
          includeContractors: formData.includeContractors,
        }
        if (formData.groupBy) {
          params.query.qsParams.groupBy = 'workplace'
        }
        if (formData.content === 'csv') {
          params.query.csv = true
          params.isCsv = true
        }
        onChangeReportFormValues({
          dateRange: formData.date,
          payroll: formData.payroll,
          includeEmployeeId: formData.includeEmployeeId,
          includeContractors: formData.includeContractors,
          content: formData.content,
        })
        const response = await apiClient.check.getPayrollJournal(params)
        if (response.message) {
          toast.error(response.message)
        } else {
          setSummaryData(response.results)
          setIsShowTable(true)
        }
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [onChangeReportFormValues])

  return (
    <div>
      <ConcordFormStructure
        fields={fields}
        isHiddenCancelButton
        isHiddenSubmitButton
        ref={formRef}
        formData={formData}
      />
      <Button label='Submit' onClick={onSubmit} />
      <When condition={isShowTable}>
        <PayrollJournalTable data={summaryData} />
      </When>
    </div>
  )
}
export default PayrollJournalForm
