import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import useFuzzy from '~/hooks/useFuzzy'

import {
  IonList,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react'
import { CompanyAvatar, CommonDialogV2 } from '~/components/shared'
import { sessionService } from 'redux-react-session'
import { selectSessionUser, selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'

import './DriverFleetModal.scss'
import { useQueryCompanies, useQueryDriverFleets } from '~/hooks/useQueryData'

const DriverFleetModal = ({ showModal, dismissModal }) => {
  const sessionUser = useSelector(selectSessionUser)
  const [selectedDriverFleetId, setSelectedDriverFleetId] = useState(null)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { driverFleetsData, isDriverFleetsDataLoading } = useQueryDriverFleets()
  const { findCompanyById, isLoadingCompaniesData } = useQueryCompanies({})

  const companyDriverFleets = driverFleetsData
    .map(({ fleetId }) => findCompanyById(fleetId))
    .filter(Boolean)

  const {
    seachedList: searchedDriverFleets,
    searchValue,
    onSearch,
  } = useFuzzy(companyDriverFleets, {
    keys: ['name', 'code'],
  })

  const onChangeFleet = useCallback(async () => {
    try {
      const companyFound = findCompanyById(selectedDriverFleetId)
      if (companyFound) {
        await sessionService.saveUser({
          ...sessionUser,
          currentFleet: companyFound,
        })
      }
      window.location.reload()
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    }
  }, [findCompanyById, selectedDriverFleetId, sessionUser])

  useEffect(() => {
    if (currentCompany?.id) {
      setSelectedDriverFleetId(currentCompany?.id)
    }
  }, [currentCompany?.id])

  return (
    <CommonDialogV2
      isOpen={showModal}
      onClose={dismissModal}
      title="Driver's Fleet"
      onOk={onChangeFleet}
      okText='Submit'
      isLoading={isLoadingCompaniesData || isDriverFleetsDataLoading}
      size='sm'
    >
      <IonList className='DriverFleetModal__list'>
        <IonSearchbar
          value={searchValue}
          onIonChange={e => onSearch(e.detail.value)}
        />

        <IonRadioGroup
          value={selectedDriverFleetId}
          onIonChange={e => {
            setSelectedDriverFleetId(e.detail.value)
          }}
        >
          {searchedDriverFleets &&
            searchedDriverFleets.map(({ code, name, id }) => (
              <IonItem key={id}>
                <IonRadio slot='start' value={id} />
                <CompanyAvatar
                  company={{
                    value: id,
                    label: `${code} - ${name}`,
                  }}
                />
                <IonLabel className='ms-2'>{`${code} - ${name}`}</IonLabel>
              </IonItem>
            ))}
        </IonRadioGroup>
      </IonList>
      {/* <Button
        expand='full'
        onClick={onChangeFleet}
        label='Submit'
        loading={loading}
      /> */}
    </CommonDialogV2>
  )
}

export default DriverFleetModal
