import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
// import { DEFAULT_COMPANY_USER_COLUMNS } from '~/constants/companySubTabs'
import { DEFAULT_COMPANY_BUYER_SELLER_PRODUCT_COLUMNS } from '~/constants/companySubTabs'

const buyerSellerProductsSlice = createSlice({
  name: moduleName.company.buyerSellerProducts,
  initialState: {
    columns: DEFAULT_COMPANY_BUYER_SELLER_PRODUCT_COLUMNS,
  },
})

export default buyerSellerProductsSlice
