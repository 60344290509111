import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import checkPayrollItemsUiSlice from '~/redux/reducers/ui/checkPayrollItems'
import { apiClient } from '~/api/ApiClient'

export const fetchCheckPayrollItems = createAsyncThunk(
  'fetchCheckPayrollItems',
  async ({ payrollId, reset = false }, { dispatch }) => {
    reset && dispatch(checkPayrollItemsSlice.actions.index([]))
    dispatch(checkPayrollItemsUiSlice.actions.setLoading(true))
    try {
      const { checkPayrollItems } = await apiClient.checkPayrollItems.get({
        filters: { payrollId },
      })
      dispatch(checkPayrollItemsSlice.actions.index(checkPayrollItems))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(checkPayrollItemsUiSlice.actions.setLoading(false))
    }
  },
)

const checkPayrollItemsSlice = createSlice({
  name: 'checkPayrollItems',
  initialState: {},
  reducers: {
    index(_state, { payload }) {
      const newState = {}
      payload.forEach(item => {
        newState[item.id] = item
      })

      return newState
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export default checkPayrollItemsSlice
