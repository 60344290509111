import { useMemo } from 'react'

import { ConcordFormStructure } from '~/components/shared'

import { personSchema } from './schemas'
import { EFieldType } from '~/types/enums/ECommonEnum'
import moment from 'moment'

function PersonTab(props) {
  const defaultValues = useMemo(
    () => ({
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      initials: '',
      dob: null,
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'firstName',
        label: 'First Name',
        isRequired: true,
      },
      {
        name: 'middleName',
        label: 'Middle Name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        isRequired: true,
      },
      {
        name: 'suffix',
        label: 'Suffix',
      },
      {
        name: 'initials',
        label: 'Initials',
      },
      {
        name: 'dob',
        label: 'Date of birth',
        type: EFieldType.date,
        yearRange: [1950, moment().subtract(16, 'years').year()],
      },
    ],
    [],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      submitText='Next'
      schema={personSchema}
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}

PersonTab.propTypes = {}

export default PersonTab
