import Immutable from 'immutable'

import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  notes: [],
  page: 1,
  creating: undefined,
  loading: undefined,
})

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_NOTES:
    case types.UPDATE_NOTE:
    case types.DELETE_NOTE: {
      return state.set('loading', true)
    }
    case types.FETCH_NOTES_SUCCESS: {
      const { notes, page } = action
      let newState = state.set('notes', notes)
      newState = newState.set('page', page + 1)

      return newState.set('loading', false)
    }
    case types.CREATE_NOTE: {
      return state.set('creating', true)
    }
    case types.CREATE_NOTE_SUCCESS: {
      const { notes } = action
      const newState = state.set('notes', notes)

      return newState.set('creating', false)
    }

    case types.UPDATE_NOTE_SUCCESS: {
      const updatedNote = action.note
      const newNotes = state
        .get('notes')
        .map(note => (note.id === updatedNote.id ? updatedNote : note))
      const newState = state.set('notes', newNotes)

      return newState.set('loading', false)
    }

    case types.DELETE_NOTE_SUCCESS: {
      const newNotes = state
        .get('notes')
        .filter(note => note.id !== action.note.id)
      const newState = state.set('notes', newNotes)

      return newState.set('loading', false)
    }
    default: {
      return state
    }
  }
}

export default notesReducer
