import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { PAYROLL_ACTION } from '~/utils/constants'

export const fetchStatusPayrollItem = createAsyncThunk(
  'fetchStatusPayrollItem',
  async ({ payrollId }, { dispatch }) => {
    dispatch(payrollItemSlice.actions.addDetailPayroll())
    dispatch(payrollItemSlice.actions.updateActionApprove(false))
    try {
      const payrollItems = await apiClient.payrolls.getById(payrollId)
      dispatch(payrollItemSlice.actions.addDetailPayroll(payrollItems))
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    }
  },
)

export const sendPayroll = createAsyncThunk(
  'sendPayroll',
  async ({ payrollId }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.SEND,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.createPayroll({
        payroll: {
          id: payrollId
        }
      })
      if (payrollResponse.message) {
        toast.error(payrollResponse.message || toastMessages.serverError)
      } else {
        dispatch(payrollItemSlice.actions.updatePayroll(payrollResponse))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.SEND,
          status: false,
        }),
      )
    }
  },
)

export const previewPayroll = createAsyncThunk(
  'previewPayroll',
  async ({ checkUid }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.PREVIEW,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.previewPayroll({
        payroll: {
          checkUid
        }
      })
      if (payrollResponse.error) {
        toast.error(payrollResponse.error.message)
      } else {
        dispatch(payrollItemSlice.actions.updatePayroll(payrollResponse))
        dispatch(payrollItemSlice.actions.updateActionApprove(true))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.PREVIEW,
          status: false,
        }),
      )
    }
  },
)

export const reopenPayroll = createAsyncThunk(
  'reopenPayroll',
  async ({ checkUid }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.REOPEN,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.reopenPayroll({
        payroll: {
          checkUid
        }
      })
      if (payrollResponse?.message) {
        toast.error(payrollResponse?.message)
      } else {
        dispatch(payrollItemSlice.actions.addDetailPayroll(payrollResponse))
        dispatch(payrollItemSlice.actions.updateActionApprove(false))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.REOPEN,
          status: false,
        }),
      )
    }
  },
)

export const getPayrollPaperCheck = createAsyncThunk(
  'getPayrollPaperCheck',
  async ({ checkUid }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.PAPER,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.getPayrollPaper({
        payroll: {
          checkUid
        }
      })
      if (payrollResponse.error) {
        toast.error(payrollResponse.error.message)
      } else {
        dispatch(payrollItemSlice.actions.updatePaperUrl(payrollResponse.url))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.PAPER,
          status: false,
        }),
      )
    }
  },
)

export const payrollCashRequirement = createAsyncThunk(
  'payrollCashRequirement',
  async ({ checkUid }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.CASH,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.getPayrollCashRequirement({
        payroll: {
          checkUid
        }
      })
      if (payrollResponse.error || payrollResponse.message) {
        toast.error(
          payrollResponse.error?.message ||
          payrollResponse.error ||
          payrollResponse.message,
        )
      } else {
        dispatch(payrollItemSlice.actions.updatePayroll(payrollResponse))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.CASH,
          status: false,
        }),
      )
    }
  },
)

export const approvePayroll = createAsyncThunk(
  'approvePayroll',
  async ({ checkUid }, { dispatch }) => {
    dispatch(
      payrollItemSlice.actions.setLoading({
        type: PAYROLL_ACTION.APPROVE,
        status: true,
      }),
    )
    try {
      const payrollResponse = await apiClient.check.approvePayroll({
        payroll: {
          checkUid
        }
      })
      if (payrollResponse.error) {
        toast.error(payrollResponse.error.message)
      } else {
        dispatch(payrollItemSlice.actions.updatePayroll(payrollResponse))
        dispatch(payrollItemSlice.actions.updateActionApprove(false))
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      dispatch(
        payrollItemSlice.actions.setLoading({
          type: PAYROLL_ACTION.APPROVE,
          status: false,
        }),
      )
    }
  },
)

const payrollItemSlice = createSlice({
  name: 'payrollItemSlice',
  initialState: {
    payrollItemStatus: null,
    isApprove: false,
    paperUrl: null,
    reopen: false,
  },
  reducers: {
    addDetailPayroll: (state, { payload }) => {
      state['payrollItemStatus'] = payload
    },
    updatePayroll: (state, { payload }) => {
      state['payrollItemStatus'] = { ...state.payrollItemStatus, ...payload }
    },
    setLoading: (state, { payload }) => {
      state[payload.type] = payload.status
    },
    updateActionApprove: (state, { payload }) => {
      state.isApprove = payload
    },
    updatePaperUrl: (state, { payload }) => {
      state.paperUrl = payload
    },
  },
})

export default payrollItemSlice
