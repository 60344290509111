import { createSlice } from '@reduxjs/toolkit'
import { moduleName } from '~/utils/constants'
import { DEFAULT_COMPANY_PRODUCT_COLUMNS } from '~/constants/companySubTabs'

const productsSlice = createSlice({
  name: moduleName.company.products,
  initialState: {
    columns: DEFAULT_COMPANY_PRODUCT_COLUMNS,
  },
})

export default productsSlice
