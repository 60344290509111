import { ToolTipOverlay } from '~/components/shared'
import KeyValueFormFieldLayout from '../FieldLayout'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import '../KeyValueForm.scss'
import './KeyValueFormCustomField.scss'

const KeyValueFormCustomField = props => {
  const { label, className, loading, render, value, error } = props

  return (
    <KeyValueFormFieldLayout
      label={label}
      loading={loading}
      className={className}
    >
      <ToolTipOverlay content={error} placement='bottom'>
        <div className={clsx('KeyValueFormCustomField__wrap', { error })}>
          {render && render({ value, label })}
        </div>
      </ToolTipOverlay>
    </KeyValueFormFieldLayout>
  )
}

KeyValueFormCustomField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
}

export default KeyValueFormCustomField
