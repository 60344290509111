import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Chart } from 'react-google-charts'
import {
  selectBuyerTerminals,
  selectBuyerSellerProductOptions,
} from '~/redux/selectors'
import { DropdownPicker } from '~/components/shared'
import { formatMetaData, formatProductQtyChart } from '~/utils'
import parseBuyerTerminals from './helpers'
import '~/styles/dashboard.scss'

const BarGraphCardForProductSumOfQty = ({ options }) => {
  const dashboardLoadData = useSelector(state => state.dashboard.load)
  const buyerSellerProducts = useSelector(selectBuyerSellerProductOptions)
  const buyerTerminals = useSelector(selectBuyerTerminals)
  const [barGraphData, setBarGraphData] = useState([])
  const [selectedBuyerTerminal, setSelectedBuyerTerminal] = useState({
    label: '',
    value: '',
  })
  const [buyerTerminalsList, setBuyerTerminalsList] = useState([])

  useEffect(() => {
    parseAndMapTerminals(dashboardLoadData)
  }, [dashboardLoadData, parseAndMapTerminals])

  useEffect(() => {
    updateBarGraphData(selectedBuyerTerminal)
  }, [selectedBuyerTerminal, updateBarGraphData])

  const parseAndMapTerminals = useCallback(
    dashboardLoadData => {
      if (dashboardLoadData && buyerTerminals) {
        const filterFn = (v, i, s) => s.indexOf(v) === i
        const sortFn = (a, b) => a.label.localeCompare(b.label)

        const formattedTerminals = formatMetaData(buyerTerminals)
        const terminalIds = parseBuyerTerminals(
          dashboardLoadData[options.dashboardKey],
        )
          .filter(filterFn)
          .map(entry => {
            if (entry === 'nil') {
              return { value: 'nil', label: 'Not Set' }
            }

            return formattedTerminals.find(terminal => terminal.value == entry)
          })
          .sort(sortFn)

        setBuyerTerminalsList(terminalIds)
        if (terminalIds[0]) {
          setSelectedBuyerTerminal(terminalIds[0])
        }
      }
    },
    [options, buyerTerminals],
  )

  const updateBarGraphData = useCallback(
    buyerTerminal => {
      if (dashboardLoadData && buyerSellerProducts) {
        let formattedData = formatProductQtyChart(
          dashboardLoadData[options.dashboardKey],
          buyerSellerProducts,
          buyerTerminal,
        )
        if (!formattedData) {
          formattedData = [
            ['No data', 'No data'],
            ['', 0],
          ]
        }

        setBarGraphData(formattedData)
      }
    },
    [dashboardLoadData, options, buyerSellerProducts],
  )

  const onChangeSelectedTerminal = useCallback(value => {
    setSelectedBuyerTerminal(value)
  }, [])

  return (
    <div className='GraphCard__container'>
      <div className='graph-card'>
        <div style={{ height: 34 }} className='body-grid'>
          <div
            style={{
              border: '2px solid #3880ff',
              borderRadius: 8,
              marginLeft: 4,
            }}
          >
            <DropdownPicker
              field={'buyerTerminal'}
              value={selectedBuyerTerminal.value}
              items={buyerTerminalsList}
              onValueChange={onChangeSelectedTerminal}
              filterKey='Viewing data for Terminal'
            />
          </div>
        </div>
        <Chart
          chartType='ColumnChart'
          loader={<div>Loading Chart</div>}
          data={barGraphData}
          options={options}
          chartEvents={[
            {
              eventName: 'ready',
              callback: () => {},
            },
            {
              eventName: 'select',
              callback: () => {},
            },
          ]}
        />
      </div>
    </div>
  )
}

export default BarGraphCardForProductSumOfQty
