var REGION_SELECTED = 'Region Selected';
var EMPTY = '';
export var DocumentLabelerConstants = {
    REGION_SELECTED: REGION_SELECTED,
    EMPTY: EMPTY,
};
export var ZOOM_VALUES = {
    min: 0.7,
    max: 2.5,
};
export var ID_DOCUMENT_LABELER_CONTENT_ROOT = 'DocumentLabelerContent__root';
export var ID_FIELDS_PANEL_HEADER_ROOT = 'FieldsPanelHeader__root';
export var ID_DOCUMENT_PANEL_TOOLBAR__ROOT = 'DocumentPanelToolbar__root';
