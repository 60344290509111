import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ICalculationFormProps } from './type'
import {
  ICalculationFormValues,
  ICalculationVariable,
} from '~/types/models/ICalculation'

import './styles.scss'
import { CalculationVarsSection } from './CalculationVarsSection'
import { formatVariableName } from '~/utils/formatVariableName'
import { useQueryNewCalculationData } from '~/hooks/useQueryData'

const CalculationForm = (props: ICalculationFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { regexAllowedOperators, allowedOperators } =
    useQueryNewCalculationData()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()
  const fields: IConcordFormField[] = [
    {
      label: 'Name',
      name: 'name',
      isRequired: true,
      size: 12,
    },
    {
      label: (
        <>
          <span>
            Formula{' '}
            <span className='error' style={{ verticalAlign: 'top' }}>
              *
            </span>
          </span>
          <span className='hintText' style={{ marginLeft: 4 }}>
            Ex: (@first * 2) + (@second / @third)
          </span>
        </>
      ),
      name: 'formula',
      size: 12,
      onBlur(value, { getValues, setValue }) {
        const regex = /@\w+/g
        const matches: string[] = _.uniq(value.match(regex) || [])
        const formValues = getValues()

        const newCalVars: ICalculationVariable[] = matches.map(text => {
          const findCalVar = formValues.calculationVariablesAttributes.find(
            ({ name }: ICalculationVariable) => name === text,
          )
          if (findCalVar) {
            return findCalVar
          }
          return {
            model: '',
            filters: {
              '': '',
            },
            name: text,
            calculationMethod: [],
          }
        })
        setValue('calculationVariablesAttributes', newCalVars)
      },
    },
    {
      label: 'Calculation Variables',
      name: 'calculationVariablesAttributes',
      render({ name, watch, setValue, errors, clearErrors }) {
        const calVars = watch(name, [])

        return (
          <CalculationVarsSection
            data={calVars}
            onChange={newData => {
              setValue(name, newData)
            }}
            errors={_.get(errors, 'calculationVariablesAttributes') || []}
            clearErrors={clearErrors}
          />
        )
      },
    },
  ]

  const defaultValues = useMemo<ICalculationFormValues>(
    () => ({
      name: '',
      formula: '',
      calculationVariablesAttributes: [],
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        formula: Yup.string()
          .required('This field is required')
          .matches(
            regexAllowedOperators,
            `Invalid format, only accept: ${allowedOperators.join(', ')} `,
          ),
        calculationVariablesAttributes: Yup.lazy(() => {
          if (isUpdating) {
            return Yup.mixed()
          }
          return Yup.array().of(
            Yup.object({
              model: Yup.string().required('This field is required!'),
              calculationMethod: Yup.array().min(1, 'This field is required!'),
              filters: Yup.object().test(
                'filters-length',
                'Each filter must have value',
                val => {
                  return Object.keys(val || {}).every(
                    field => val[field as keyof typeof val],
                  )
                },
              ),
            }),
          )
        }),
      }),
    [allowedOperators, isUpdating, regexAllowedOperators],
  )

  const onCreate = useCallback(
    async (formValues: ICalculationFormValues) => {
      const payload = _.pick(formValues, [
        'name',
        'formula',
        'calculationVariablesAttributes',
      ])
      payload.calculationVariablesAttributes =
        formValues.calculationVariablesAttributes.map(val => ({
          ...val,
          name: formatVariableName(val.name),
        }))

      const { errors, ...response } = await apiClient.calculations.create({
        calculation: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ICalculationFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['name', 'formula'])

        const { errors, ...response } = await apiClient.calculations.update(
          formValues.id,
          {
            calculation: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: ICalculationFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default CalculationForm
