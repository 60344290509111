import { useMemo, useCallback } from 'react'
import useStoreData from '~/hooks/useStoreData'

import _ from 'lodash'
import defaultColumns from './defaultColumns'
import moment from 'moment'

const useStoreColumns = moduleName => {
  const { moduleStoreData, DEFAULT_STRUCTURE_DATA, saveColumnsData } =
    useStoreData(moduleName)
  const columnsDefaultValues = useMemo(() => {
    return _.get(defaultColumns, moduleName) || _.get(defaultColumns, 'default')
  }, [moduleName])

  const storedColumns = useMemo(() => {
    const { columns } = DEFAULT_STRUCTURE_DATA
    try {
      const savingData = _.get(moduleStoreData, 'columns')

      if (_.isPlainObject(savingData)) {
        if (_.isNil(savingData.data) || _.size(savingData.data) === 0) {
          return {
            ...savingData,
            data: columnsDefaultValues,
          }
        }

        return savingData
      }

      return {
        ...columns,
        data: columnsDefaultValues,
      }
    } catch (error) {
      console.log('error', error)

      return {
        ...columns,
        data: columnsDefaultValues,
      }
    }
  }, [DEFAULT_STRUCTURE_DATA, columnsDefaultValues, moduleStoreData])

  const handleSaveColumns = useCallback(
    newColumnsData => {
      const columnsData = {
        ...storedColumns,
        ...newColumnsData,
        updatedAt: moment().format('YYYY-MM-DD'),
      }
      saveColumnsData(columnsData)
    },
    [saveColumnsData, storedColumns],
  )

  return [storedColumns, handleSaveColumns]
}

export default useStoreColumns
