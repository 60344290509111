import Immutable from 'immutable'
import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  modalObject: {},
})

const loadDeleteFieldModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DELETE_MODAL_ID: {
      const { modalObject } = action

      return state.set('modalObject', modalObject)
    }

    case types.RESET_DELETE_MODAL_ID: {
      return state.set('modalObject', {})
    }

    default: {
      return state
    }
  }
}

export default loadDeleteFieldModalReducer
