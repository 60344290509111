import { driverFleetsSlice } from '../reducers/data/driverFleets'
import { driversUiSlice } from '../reducers/ui/drivers'

export const initializeDriverFleets = payload => dispatch =>
  dispatch(driverFleetsSlice.actions.index(payload))

export const updateDriverFleet = payload => dispatch =>
  dispatch(driverFleetsSlice.actions.update(payload))

export const deleteDriverFleet = payload => dispatch =>
  dispatch(driverFleetsSlice.actions.delete(payload))

export const updateDriversUi = payload => dispatch =>
  dispatch(driversUiSlice.actions.updateUi(payload))

export const updateDriversUiViewMode = payload => dispatch =>
  dispatch(driversUiSlice.actions.updateUiMode(payload))
