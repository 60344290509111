import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

const selectSalesProposalsData = state => state.data.salesProposals
const selectSalesProposalsUiState = state => state.ui.salesProposals
const selectSalesProposalId = (state, id) => id

export const selectSalesProposalsArray = createSelector(
  selectSalesProposalsData,

  data => columnHashToColumnsArray(data),
)

export const selectSalesProposalByIdFactory = () => {
  const selectSalesProposalById = createSelector(
    [selectSalesProposalsData, selectSalesProposalId],

    (data, id) => data[id],
  )

  return selectSalesProposalById
}

export const selectSalesProposalsLoading = createSelector(
  selectSalesProposalsUiState,

  uiState => uiState.loading,
)

export const selectSalesProposalsFilters = createSelector(
  selectSalesProposalsUiState,

  uiState => uiState.filters,
)

export const selectSalesProposalViewMode = createSelector(
  selectSalesProposalsUiState,

  uiState => uiState.viewMode,
)

export const selectSalesProposalAddProductRow = createSelector(
  selectSalesProposalsUiState,

  uiState => uiState.addProductRow,
)

export const selectSalesProposalsColumns = createSelector(
  selectSalesProposalsUiState,

  uiState => uiState.columns,
)
