import { createSelector } from 'reselect'

export const selectChatState = state => state.ui.chats

export const selectChatNewMessage = createSelector(
  selectChatState,
  state => state.newMessage,
)

export const selectUnreadChatMessage = createSelector(
  selectChatState,
  state => state.unreadCount,
)

export const selectFontSizeMessage = createSelector(
  selectChatState,
  state => state.fontSize,
)

export const selectIsChatInit = createSelector(
  selectChatState,
  state => state.init,
)
