import UomForm from './UomForm'
import { CommonDialogV2 } from '~/components/shared'

import './DialogUomForm.scss'

function DialogUomForm(props) {
  const { formData, afterCreate, ...dialogProps } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Uom'
      isHiddenOkButton
      className='DialogUomForm__container'
    >
      <UomForm
        isHiddenCancelButton
        formData={formData}
        afterCreate={afterCreate}
      />
    </CommonDialogV2>
  )
}
export default DialogUomForm
