import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInterval } from 'react-use'

import { Geolocation, Position } from '@capacitor/geolocation'
import moment from 'moment'

const DEFAULT_INTERVAL = 60000 // 1 minutes

export type IUseGeolocationOptions = {
  interval: number
  isEnabled: boolean
}

const useGeolocation = (options: Partial<IUseGeolocationOptions> = {}) => {
  const { isEnabled = true } = options

  const interval =
    typeof options.interval === 'number' ? options.interval : DEFAULT_INTERVAL

  const [geoLocation, setGeoLocation] = useState<Position | null>(null)

  const getGeolocation = useCallback(async () => {
    try {
      const result = await Geolocation.getCurrentPosition()
      setGeoLocation(result)
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const locationAttributes = useMemo(() => {
    if (geoLocation?.coords) {
      const { coords } = geoLocation
      return {
        lat: coords.latitude,
        lng: coords.longitude,
        speed: coords.speed,
        bearing: coords.heading,
        source: 'App',
        time: moment(geoLocation.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        accuracy: coords.accuracy,
        altitude: coords.altitude,
        altitudeAccuracy: coords.altitudeAccuracy,
      }
    }

    return undefined
  }, [geoLocation])

  useInterval(() => {
    if (isEnabled) {
      getGeolocation()
    }
  }, interval)

  useEffect(() => {
    if (isEnabled) {
      getGeolocation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])

  return { geoLocation, locationAttributes }
}

export default useGeolocation
