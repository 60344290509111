import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { string, func, bool } from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import {
  IonButton,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonButtons,
  IonText,
} from '@ionic/react'
import { cameraOutline, imageOutline } from 'ionicons/icons'
import { Loading } from '~/components/shared'
import useLoadDocument from '~/components/company/DocumentList/loadDocumentHook'
import InvoiceDocumentList from '~/components/company/DocumentList/InvoiceDocumentList'
import { updateCompleteFilters } from '~/redux/actions/documentsActions'

const VerticalButtons = ({
  useCamera,
  onTakePicture,
  onDisplayFilesFromStorage,
}) => {
  return (
    <>
      <IonButton
        fill='outline'
        color='primary'
        size='small'
        onClick={onDisplayFilesFromStorage}
        style={{ width: 145 }}
      >
        <IonIcon icon={imageOutline} />
        &nbsp;Assign Document
      </IonButton>

      {useCamera && (
        <IonButton
          fill='outline'
          color='primary'
          size='small'
          onClick={onTakePicture}
          style={{ width: 145 }}
        >
          <IonIcon icon={cameraOutline} />
          &nbsp;Take a Photo
        </IonButton>
      )}
    </>
  )
}

const HorizontalButtons = ({
  useCamera,
  onTakePicture,
  onDisplayFilesFromStorage,
}) => {
  const { isMobileSmallScreen } = useBreakpoint()

  return (
    <>
      {useCamera && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: isMobileSmallScreen ? 'space-around' : 'start',
          }}
        >
          <IonButton
            fill='outline'
            color='primary'
            size='small'
            onClick={onTakePicture}
          >
            <IonIcon icon={cameraOutline} />
            &nbsp;Take a Photo
          </IonButton>
        </div>
      )}
      {/* <IonText style={{ padding: '5px 0', fontSize: '15px' }} color="primary">
        &nbsp;or&nbsp;
      </IonText> */}
      <IonButton
        fill='outline'
        color='primary'
        size='small'
        onClick={onDisplayFilesFromStorage}
      >
        <IonIcon icon={imageOutline} />
        &nbsp;Assign Document
      </IonButton>
      {!isMobileSmallScreen && (
        <IonText style={{ padding: '5px 0', fontSize: '15px' }} color='primary'>
          &nbsp;from Storage.
        </IonText>
      )}
    </>
  )
}

const StorageFilesPicker = ({
  storageUrl,
  onPickerFile,
  onTakePicture,
  selectedFiles,
  useCamera,
  showVerticalButton = false,
}) => {
  const { isLoading, loadDocumentRef } = useLoadDocument(
    useLocation(),
    false,
    false,
  )

  const dispatch = useDispatch()
  const [savedFilters, setSavedFilters] = useState()
  const [displayStorage, setDisplayStorage] = useState(false)

  const onDisplayFilesFromStorage = useCallback(async () => {
    setDisplayStorage(true)
  }, [])

  const hideStorage = useCallback(() => {
    setDisplayStorage(false)
  }, [])

  const onDoneSelection = useCallback(
    docs => {
      onPickerFile && onPickerFile(docs)
      hideStorage()
    },
    [onPickerFile, hideStorage],
  )

  const hideModal = useCallback(() => {
    dispatch(updateCompleteFilters(savedFilters))
  }, [savedFilters, dispatch])

  return (
    <div
      className='StorageFilesPicker__root'
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: `${showVerticalButton ? 'column' : 'row'}`,
        marginRight: 10,
        width: 145,
      }}
    >
      {selectedFiles.length == 0 ? (
        showVerticalButton ? (
          <VerticalButtons
            useCamera={useCamera}
            onTakePicture={onTakePicture}
            onDisplayFilesFromStorage={onDisplayFilesFromStorage}
          />
        ) : (
          <HorizontalButtons
            useCamera={useCamera}
            onTakePicture={onTakePicture}
            onDisplayFilesFromStorage={onDisplayFilesFromStorage}
          />
        )
      ) : (
        !showVerticalButton && (
          <HorizontalButtons
            useCamera={useCamera}
            onTakePicture={onTakePicture}
            onDisplayFilesFromStorage={onDisplayFilesFromStorage}
          />
        )
      )}

      <IonModal
        isOpen={displayStorage}
        canDismiss={true}
        onDidDismiss={hideModal}
        cssClass='modal-full-height'
      >
        <IonHeader>
          <IonToolbar>
            <div style={{ width: 260 }}>
              <IonTitle>Choose a Document</IonTitle>
            </div>
            <IonButtons slot='end'>
              <IonButton
                color='danger'
                onClick={hideStorage}
                title='Close Modal'
              >
                {' '}
                Close{' '}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {displayStorage && (
          <InvoiceDocumentList
            documentRef={loadDocumentRef}
            isGrid={false}
            storageUrl={storageUrl}
            onDoneSelection={onDoneSelection}
            setSavedFilters={setSavedFilters}
          />
        )}
        {isLoading && <Loading />}
      </IonModal>
    </div>
  )
}

StorageFilesPicker.propTypes = {
  storageUrl: string, //the get documents API
  onPickerFile: func, //listen file picker
  onTakePicture: func,
  showVerticalButton: bool,
  useCamera: bool,
}

export default StorageFilesPicker
