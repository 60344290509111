const DEFAULT_COMPANY_PRODUCT_COLUMNS = [
  {
    displayIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    label: 'Seller',
    field: 'seller.name',
    show: true,
    value: 'seller.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'seller.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    label: 'Buyer',
    field: 'buyer.name',
    show: true,
    value: 'buyer.name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'buyer.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    label: 'Product Code',
    field: 'code',
    show: true,
    value: 'code',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'code',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    label: 'Product Name',
    field: 'name',
    show: true,
    value: 'name',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    label: 'Product Description',
    field: 'description',
    show: true,
    value: 'description',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 5,
    isAsc: true,
    sortField: 'description',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    label: 'Price',
    field: 'price',
    show: true,
    value: 'price',
    type: 'int',
    sorted: false,
    sortable: true,
    sortIndex: 6,
    isAsc: true,
    sortField: 'price',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 7,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_PRODUCT_COLUMNS
