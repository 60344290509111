import { EStatus } from '~/types/enums/ECommonEnum'
import {
  EOrderType,
  EQtyType,
  ESellerProductUsage,
} from '~/types/enums/ESellerProduct'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sellerProductDefaultValues: any = {
  code: '',
  name: '',
  description: '',
  status: EStatus.Active.toLowerCase() as Lowercase<EStatus>,
  sellerId: null,
  productGroupId: null,
  productUsage: ESellerProductUsage.product,
  standardProduct: false,
  qtyType: EQtyType.per_qty,
  orderType: EOrderType.primary,
  materialType: null,
}
