import { IonIcon, IonSpinner } from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import {
  CustomTerminalOption,
  DropdownWithCustomChildren,
  GoogleMap,
  TerminalMarker1,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import {
  useQuerySellerTerminalProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { EStatus, EYesNo } from '~/types/enums/ECommonEnum'
import { SellerTerminalProdCard } from '../SellerTerminalProdCard'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { useConfirmationProvider } from '~/contexts'
import './styles.scss'

export interface ISellerTerminalProductsListProps {
  bundleId: number
  sellerProductId: number
}

function SellerTerminalProductsList(props: ISellerTerminalProductsListProps) {
  const { bundleId, sellerProductId } = props

  const [isCreating, setIsCreating] = useState(false)
  const [terminalIdSelected, setTerminalIdSelected] =
    useState<null | number>(null)

  const { sellerTerminalOptions, findTerminalById } = useQueryTerminals()
  const { confirmation } = useConfirmationProvider()

  const terminalSelected = useMemo(() => {
    if (terminalIdSelected) {
      return findTerminalById(terminalIdSelected)
    }
    return undefined
  }, [findTerminalById, terminalIdSelected])

  const terminalLocation = useMemo<google.maps.LatLng>(() => {
    if (terminalSelected) {
      return new google.maps.LatLng(
        Number(terminalSelected.location?.lat || 0),
        Number(terminalSelected.location?.lng || 0),
      )
    }
    return new google.maps.LatLng(0, 0)
  }, [terminalSelected])

  const {
    sellerTerminalProductsData,
    removeSellerTerminalProduct,
    addSellerTerminalProduct,
    refecthSellerTerminalProducts,
  } = useQuerySellerTerminalProducts(
    {
      filters: {
        sellerProductId,
      },
    },
    { enabled: Boolean(sellerProductId) },
  )

  const onClickRemoveTerminalProd = useCallback(
    async (terminalProd: ISellerTerminalProduct) => {
      const result = await confirmation({
        message: 'Are you sure you want to remove this item?',
      })
      if (result === EYesNo.Yes) {
        apiClient.sellerTerminalProducts.delete(terminalProd.id)
        removeSellerTerminalProduct(terminalProd.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const existentTerminalIds = sellerTerminalProductsData.map(
    ({ terminalId }) => terminalId,
  )

  const onSelectTerminal = useCallback(
    async (event: any, { selectedOption }: any) => {
      setIsCreating(true)
      try {
        const { errors, ...response } =
          await apiClient.sellerTerminalProducts.create({
            sellerTerminalProduct: {
              bundleId,
              sellerProductId: sellerProductId,
              terminalId: selectedOption.value,
              status: EStatus.Active,
            },
          })
        if (errors.length > 0) {
          toast.error(errors[0])
        } else {
          addSellerTerminalProduct(response)
          setTerminalIdSelected(response.terminalId)
          toast.success(toastMessages.createSuccess)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsCreating(false)
      }
    },
    [addSellerTerminalProduct, bundleId, sellerProductId],
  )

  return (
    <div className='SellerTerminalProductsList__container'>
      <div className='terminalSection'>
        <div className='dropdownLabel'>Seller Terminal Products: </div>
        <DropdownWithCustomChildren
          options={sellerTerminalOptions}
          onChange={onSelectTerminal}
          className='terminalDropdown'
          isLoading={isCreating}
          isOptionDisabled={opt => existentTerminalIds.includes(opt.value)}
          components={{
            Option: CustomTerminalOption,
          }}
        >
          <Badge>
            {isCreating ? (
              <IonSpinner name='dots' style={{ width: 10, height: 10 }} />
            ) : (
              <IonIcon icon={addOutline} />
            )}
          </Badge>
        </DropdownWithCustomChildren>
      </div>
      <div className='terminalsList'>
        {sellerTerminalProductsData.map(terminalProd => {
          return (
            <SellerTerminalProdCard
              key={terminalProd.id}
              sellerTerminalProduct={terminalProd}
              isSelected={terminalIdSelected === terminalProd.terminalId}
              onClick={({ terminalId }) => {
                setTerminalIdSelected(prev => {
                  if (prev === terminalId) {
                    return null
                  }
                  return terminalId
                })
              }}
              onRemove={terminal => {
                onClickRemoveTerminalProd(terminal)
              }}
              afterDrag={() => {
                refecthSellerTerminalProducts()
              }}
            />
          )
        })}
      </div>

      {terminalSelected && (
        <div className='mapContainer'>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <GoogleMap defaultCenter={terminalLocation as any} zoom={15}>
            <TerminalMarker1 terminal={terminalSelected} />
          </GoogleMap>
        </div>
      )}
    </div>
  )
}

export default SellerTerminalProductsList
