import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import RewardForm from './RewardForm'

import type { IDialogRewardProps } from './type'

function DialogRewardForm(props: IDialogRewardProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    contestId,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Reward'
      isHiddenOkButton
      onClose={onClose}
    >
      <RewardForm
        formData={formData}
        contestId={contestId}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogRewardForm
