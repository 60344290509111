import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { updateUserInfo } from '~/redux/actions/sessionActions'
import { resetReduxStore } from '~/redux/actions/userAccessActions'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useRouter } from '~/hooks/useRouter'
import { scopeTypes } from '~/constants/user-type'
import {
  clientRoutesPaths,
  driverRoutesPaths,
  companyRoutesPaths,
  adminRoutesPaths,
  fleetRoutesPaths,
  documentRoutesPaths,
  loadsCreatePaths,
} from '~/constants/routesPaths'
import { useIsMobile } from '~/hooks/useIsMobile'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'

const useAccesses = ({ onClosePopover } = {}) => {
  const session = useSelector(state => state.session)
  const [requestList, setRequestList] = useState([])
  const [accessesList, setAccessesList] = useState()
  const [userAccess, setUserAccess] = useState(session.user.userAccess)
  const [currentAccess, setCurrentAccess] = useState()
  const [firstLoad, setFirstLoad] = useState(true)
  const updateSessionRef = useRef()
  const dispatch = useDispatch()
  const myCompany = useSelector(selectMyCurrentCompany)
  const router = useRouter()
  const isMobile = useIsMobile()

  const loadAccessInfo = useCallback(async () => {
    if (firstLoad && session.user.currentScope) {
      setFirstLoad(false)
      const response = await apiClient.accessRequests.get()
      const access = await apiClient.userAccesses.get()
      if (!response.error) {
        const { accessRequests, accessList, userable } = response
        accessRequests && setRequestList(accessRequests)
        const newList = accessList
          ? accessList.sort((a, b) => (a.code > b.code ? 1 : -1))
          : []
        const listWithAccess = newList.map(item => {
          const accessValue = access.userAccesses.find(
            access => access.companyId === item.id,
          )

          return {
            ...item,
            currentUserAccess: accessValue,
          }
        })
        setAccessesList(listWithAccess)
        updateSessionRef.current(newList, session)
        userable && setUserAccess(userable)
      }
    }
  }, [session, firstLoad])

  const onUpdateSession = useCallback((accessables, sess) => {
    if (Object.keys(sess.user).length > 0) {
      dispatch(
        updateUserInfo({
          ...sess.user,
          userAccessables: accessables,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userScope = useRef(session.user.currentScope)

  const getDefaultPath = useCallback(
    user => {
      const { currentScope, admin } = user
      const lowerCaseScope = _.lowerCase(currentScope)

      if (lowerCaseScope) {
        if (
          lowerCaseScope === scopeTypes.buyer ||
          lowerCaseScope === scopeTypes.seller
        ) {
          if (isMobile) {
            return loadsCreatePaths.loadsCapture
          }

          return documentRoutesPaths.documents
        }
        if (lowerCaseScope === scopeTypes.driver) {
          return driverRoutesPaths.index
        }
        if (lowerCaseScope === scopeTypes.fleet) {
          return fleetRoutesPaths.assignLoads
        }

        return clientRoutesPaths.index
      } else {
        if (admin == true) {
          return adminRoutesPaths.index
        }
      }

      return companyRoutesPaths.requestAccess
    },
    [isMobile],
  )

  const onUpdateFilter = useCallback(
    async ({ value }) => {
      if (userScope.current !== value) {
        const data = await apiClient.companies.toggleAccess(
          myCompany.id,
          { newUserAccessId: userAccess?.id },
          { scope: value },
        )
        userScope.current = value
        batch(() => {
          dispatch(
            updateUserInfo({
              ...session.user,
              ...data,
            }),
          )
          dispatch(resetReduxStore())
        })
        const defaultPath = getDefaultPath(data)
        router.push(defaultPath)
      }
    },
    [
      dispatch,
      getDefaultPath,
      myCompany.id,
      router,
      session.user,
      userAccess?.id,
    ],
  )

  useEffect(() => {
    updateSessionRef.current = onUpdateSession
  }, [onUpdateSession])

  useEffect(() => {
    loadAccessInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDeleteRequest = useCallback(
    companyId => {
      apiClient.companies
        .createAccessRequest(companyId, session.user)
        .then(() => {
          setRequestList(requestList.filter(r => r.companyId != companyId))
        })
    },
    [session, requestList],
  )

  const onToggleAccess = useCallback(
    async accessId => {
      onClosePopover && onClosePopover()
      const accesses = await apiClient.companies.toggleAccess(accessId, {
        newUserAccessId: userAccess?.id,
      })
      setUserAccess(accesses.userAccess)
      dispatch(updateUserInfo(accesses))
      dispatch(resetReduxStore())
      toast.success(toastMessages.accessChanges)
    },
    [dispatch, onClosePopover, userAccess?.id],
  )

  const onToggleDefault = useCallback(
    async accessId => {
      const defaultAccess = accessesList.filter(
        sub => sub.currentUserAccess.default == 'Yes',
      )[0]
      const accessToNo = await apiClient.userAccesses.update(
        defaultAccess.currentUserAccess?.id,
        { default: 'No' },
      )
      const accessToYes = await apiClient.userAccesses.update(accessId, {
        default: 'Yes',
      })

      const listAfterToggle = accessesList.map(item => {
        if (item.currentUserAccess.id == defaultAccess.currentUserAccess?.id) {
          return {
            ...item,
            currentUserAccess: accessToNo['userAccess'],
          }
        } else if (item.currentUserAccess.id == accessId) {
          return {
            ...item,
            currentUserAccess: accessToYes['userAccess'],
          }
        } else {
          return item
        }
      })
      setAccessesList(listAfterToggle)
    },
    [accessesList],
  )

  useEffect(() => {
    accessesList &&
      session?.user?.company?.id &&
      setCurrentAccess(
        accessesList?.find(access => access?.id == session.user.company.id),
      )
  }, [accessesList, session])

  return {
    session,
    requestList,
    accessesList,
    userAccess,
    onDeleteRequest,
    onToggleAccess,
    onUpdateFilter,
    onToggleDefault,
    currentAccess,
  }
}

export default useAccesses
