import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  CompanyInfo,
  GoogleMap,
  TerminalForm,
  TerminalMarker1,
} from '~/components/shared'
import { IonButtons } from '@ionic/react'
import {
  addCircle,
  closeCircle,
  location,
  locationOutline,
} from 'ionicons/icons'

import type { ICompanyTerminalsCardProps } from './type'

import './styles.scss'
import { ITerminal } from '~/types/models/ITerminal'
import { ListGroup } from 'react-bootstrap'
import buildFullAddress from '~/utils/buildFullAddress'
import clsx from 'clsx'
import tzLookup from 'tz-lookup'
import { EStatus } from '~/types/enums/ECommonEnum'
import _ from 'lodash'

function CompanyTerminalsCard(props: ICompanyTerminalsCardProps) {
  const {
    company,
    scope,
    terminalsData,
    savedRelationship,
    afterCreateTerminal,
  } = props

  const [isOpenTerminalForm, setIsOpenTerminalForm] = useState(false)
  const [isShowTerminals, setIsShowTerminals] = useState(false)
  const [terminalSelected, setTerminalSelected] =
    useState<ITerminal | null>(null)

  const terminalLocation = useMemo(() => {
    if (terminalSelected) {
      return new window.google.maps.LatLng(
        Number(terminalSelected.location?.lat || 0),
        Number(terminalSelected.location?.lng || 0),
      )
    }
    return new window.google.maps.LatLng(0, 0)
  }, [terminalSelected])

  const terminalDefaultValues = useMemo(() => {
    const defaultValues: any = {
      companyId: company.id,
      status: EStatus.Active,
    }

    if (savedRelationship[company.id] && terminalsData.length === 0) {
      const { location, address } = savedRelationship[company.id]
      const tz = tzLookup(Number(location.lat), Number(location.lng))
      defaultValues.locationAttributes = {
        lat: location?.lat,
        lng: location?.lng,
        addressAttributes: address ? _.omit(address, 'id') : undefined,
        default: true,
      }
      defaultValues.timeZone = tz
    }

    return defaultValues
  }, [company.id, savedRelationship, terminalsData.length])

  const onToggleShowHide = () => {
    setIsOpenTerminalForm(prev => !prev)
  }

  const onClickTerminal = (terminal: ITerminal) => () => {
    setTerminalSelected(prev => {
      if (prev?.id === terminal?.id) {
        return null
      }
      return terminal
    })
  }

  useEffect(() => {
    setIsOpenTerminalForm(terminalsData.length === 0)
  }, [terminalsData.length])

  useEffect(() => {
    if (!isShowTerminals) {
      setTerminalSelected(null)
    }
  }, [isShowTerminals])

  return (
    <div className='CompanyTerminalsCard__container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CompanyInfo
          company={{
            ...company,
            value: company?.id,
            label: `${company?.code} - ${company?.name}`,
          }}
          hideAnchor
          searchableGoogle={false}
          companyType={scope}
        />
        <IonButtons>
          <Button
            icon={isShowTerminals ? location : locationOutline}
            color={terminalsData.length ? 'danger' : 'medium'}
            badge={terminalsData.length}
            onClick={() => {
              setIsShowTerminals(prev => !prev)
            }}
            isDisabled={terminalsData.length === 0}
          />
          <Button
            color={isOpenTerminalForm ? 'danger' : 'concord'}
            icon={isOpenTerminalForm ? closeCircle : addCircle}
            tooltipProps={{
              content: isOpenTerminalForm
                ? 'Close Terminal'
                : 'Create Terminal',
              placement: 'top',
            }}
            onClick={onToggleShowHide}
          />
        </IonButtons>
      </div>
      {isOpenTerminalForm && (
        <div className='CompanyTerminalsCard__terminalForm'>
          <TerminalForm
            afterCreate={(terminal: ITerminal) => {
              afterCreateTerminal(terminal)
              setIsOpenTerminalForm(false)
            }}
            onCancel={() => {
              setIsOpenTerminalForm(false)
            }}
            defaultValues={terminalDefaultValues}
            hiddenFields={['companyId']}
          />
        </div>
      )}

      {terminalsData.length > 0 && isShowTerminals ? (
        <div style={{ borderTop: '1px dashed #e5e5e5', marginTop: 8 }}>
          <ListGroup variant='flush'>
            {terminalsData.map(terminal => (
              <ListGroup.Item
                key={terminal.id}
                className={clsx('CompanyTerminalsCard__terminal clickable', {
                  isSelected: terminal.id === terminalSelected?.id,
                })}
                onClick={onClickTerminal(terminal)}
              >
                <div className='description'>
                  <div className='name'>
                    <span className='terminalName'>
                      {terminal.code} - {terminal.name}
                    </span>
                  </div>
                  <div className='address'>
                    {buildFullAddress(terminal.address as any)}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ) : null}

      {terminalSelected && (
        <div className='CompanyTerminalsCard__googleMap'>
          <GoogleMap defaultCenter={terminalLocation as any} zoom={15}>
            <TerminalMarker1 terminal={terminalSelected} />
          </GoogleMap>
        </div>
      )}
    </div>
  )
}

export default React.memo(CompanyTerminalsCard)
