import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const locationSlice = createSlice({
  name: 'locations',
  initialState: {},
  reducers: {
    initializeLocations: (state, action) => {
      action.payload.forEach(terminal => (state[terminal.id] = terminal))
    },
    updateLocations: (state, action) => {
      action.payload.forEach(terminal => {
        state[terminal.id] = terminal
        if (terminal.deletedAt !== null) delete state[terminal.id]
      })
    },
    updateLocation: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
      }
    },
    deleteLocation: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
  },
})

export const fetchLocations = createAsyncThunk(
  'fetchLocations',
  async (params = {}, { dispatch }) => {
    try {
      const { locations } = await apiClient.locations.get(params)
      dispatch(locationSlice.actions.initializeLocations(locations))
    } catch (error) {
      console.log('error', error)
    }
  },
)
