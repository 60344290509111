import { createSelector } from 'reselect'

const selectFlagsState = (state, invoice) => ({
  flags: state.data.flags,
  id: invoice.id,
})
const selectFlagHash = state => state.data.flags

export const selectFlagsByInvoiceId = createSelector(
  selectFlagsState,
  ({ flags, id }) => {
    return Object.entries(flags).filter(([, value]) => value.flagableId == id)
  },
)

export const selectFlagsByBillLine = createSelector(
  [selectFlagHash, state => state.data.billLines],

  (flags, billLines) => {
    if (
      Object.keys(flags).length === 0 ||
      Object.keys(billLines).length === 0
    ) {
      return []
    }

    return Object.entries(flags).map(([, flagValue]) => {
      Object.entries(billLines).filter(([lineKey]) => {
        return flagValue.flagableId == lineKey
      })
    })
  },
)
