import PropTypes from 'prop-types'

const ContextMenu = props => {
  const {
    menu,
    show,
    setShow,
    x,
    y,
    menuHeight = 128,
    menuWidth = 162,
    clickToClose,
  } = props

  const getHeight = () => {
    if (menu?.props?.document?.docType == 'Load') {
      return 48 + menuHeight
    }

    return menuHeight
  }

  const handleClick = () => {
    if (clickToClose) {
      setShow && setShow(false)
    }
  }

  // const handleMouseLeave = () => {
  //   setShow && setShow(false)
  // }

  if (!show) {
    return null
  }

  return (
    <div
      className='menu-container'
      style={{
        position: 'fixed',
        top:
          y + getHeight() > window.innerHeight
            ? window.innerHeight - getHeight()
            : y - 8,
        left:
          x + menuWidth > window.innerWidth
            ? window.innerWidth - menuWidth
            : x - 8,
        zIndex: 9999,
      }}
      // onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {menu}
    </div>
  )
}

ContextMenu.propTypes = {
  menu: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
  menuHeight: PropTypes.number,
  menuWidth: PropTypes.number,
  clickToClose: PropTypes.bool,
}

ContextMenu.defaultProps = {
  clickToClose: true,
}

export default ContextMenu
