import React, { useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import { IonButton, IonIcon } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'

import { cloudDownloadOutline } from 'ionicons/icons'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { apiClient } from '~/api/ApiClient'

const CsvExportButton = ({ onGetParamsFilter, title, iconProps }) => {
  const { isMobileScreen } = useBreakpoint()

  const onCsvClick = useCallback(async () => {
    const params = _.isFunction(onGetParamsFilter) ? onGetParamsFilter() : ''
    apiClient.invoices.exportCsv(params)
  }, [onGetParamsFilter])

  return (
    <ToolTipOverlay
      content='Export to CSV'
      placement='bottom'
      allowToShow={isMobileScreen}
    >
      <IonButton
        onClick={onCsvClick}
        fill='outline'
        color='export'
        style={{ height: 40 }}
      >
        {isMobileScreen && <IonIcon icon={cloudDownloadOutline} />}
        {!isMobileScreen && (
          <>
            <IonIcon
              color='export'
              icon={cloudDownloadOutline}
              slot='start'
              {...iconProps}
            />
            <span style={{ color: '#7044ff' }}>{title}</span>
          </>
        )}
      </IonButton>
    </ToolTipOverlay>
  )
}

CsvExportButton.propTypes = {
  onGetParamsFilter: PropTypes.func,
  title: PropTypes.string,
  iconProps: PropTypes.object,
}

CsvExportButton.defaultProps = {
  title: 'Export to CSV',
  iconProps: {},
}

export default CsvExportButton
