import { useState, useEffect, useCallback } from 'react'

import { IonCard } from '@ionic/react'
import PlacesAutocomplete from 'react-places-autocomplete'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import './AddressTextBox.scss'

const AddressTextBox = props => {
  const { value, onChange, onSelect, className, containerClassName } = props

  const [textValue, setTextValue] = useState('')

  const handleChange = useCallback(
    newTextValue => {
      setTextValue(newTextValue)
      onChange && onChange(newTextValue)
    },
    [onChange],
  )

  const handleSelect = async (address, placeId, suggestion) => {
    onSelect && onSelect(address, placeId, suggestion)
  }

  useEffect(() => {
    setTextValue(value || '')
  }, [value])

  return (
    <PlacesAutocomplete
      value={textValue}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ types: ['address'] }}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div style={{ width: '100%' }} className={containerClassName}>
          <input
            {...getInputProps({
              placeholder: 'Search Address ...',
              className: clsx('location-search-input', className),
            })}
          />
          <IonCard className='autocomplete-dropdown-container'>
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' }

              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </IonCard>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

AddressTextBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
}

export default AddressTextBox
