import { useCallback, useState, useEffect, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { checkmarkCircleOutline, closeOutline } from 'ionicons/icons'
import _ from 'lodash'
import getLabelValueRangeFilter from '~/utils/getLabelValueRangeFilter'
import NumberFormatField from 'react-number-format'

import './RangeSelectFilter.scss'

const RangeSelectFilter = props => {
  const { field, value, label, onValueChange, className } = props

  const [rangeValue, setRangeValue] = useState({})

  useEffect(() => {
    if (_.size(value) > 0) {
      setRangeValue(value)
    }
  }, [value])

  const onResetValue = useCallback(() => {
    setRangeValue({})
    onValueChange && onValueChange({ field, value: undefined })
  }, [onValueChange, field])

  const onItemSelected = useCallback(() => {
    onValueChange && onValueChange({ field, value: rangeValue })
  }, [field, onValueChange, rangeValue])

  const isResetValue = _.size(value) !== 0
  const isDisplayConfirmButton = useMemo(() => {
    const propValue = value || {}
    if (_.isEqual(rangeValue, propValue)) {
      return false
    }

    return true
  }, [rangeValue, value])

  const labelValue = useMemo(() => getLabelValueRangeFilter(value), [value])

  const isAllOption = labelValue === 'All'

  const handleChangeValue = useCallback(({ value }, { event }) => {
    const { name } = event.target
    setRangeValue(prev => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        id={`dropdown-${field}`}
        variant='link'
        size='lg'
        role='menu'
        bsPrefix='p-0'
        style={{ textDecoration: 'none' }}
      >
        <IonButtons>
          <IonButton fill='clear' color={isAllOption ? 'medium' : 'primary'}>
            <IonLabel
              style={{
                fontWeight: 'bold',
                fontSize: 'small',
                textTransform: 'uppercase',
              }}
            >
              {label}:&nbsp;
            </IonLabel>
            <IonLabel
              style={{ fontSize: 'small', opacity: isAllOption ? 0.6 : 1 }}
              color='dark'
            >
              {labelValue}
            </IonLabel>
          </IonButton>
        </IonButtons>
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: '250px' }}>
        <IonItem lines='none'>
          {/* Number fields  */}
          <IonRow>
            <IonCol size={6}>
              <IonLabel className='RangeSelectFilter__numberLabelField'>
                Min
              </IonLabel>
              <NumberFormatField
                value={rangeValue?.lower}
                decimalScale={0}
                allowNegative={false}
                className='RangeSelectFilter__numberField'
                name='lower'
                onValueChange={handleChangeValue}
              />
            </IonCol>
            <IonCol size={6}>
              <IonLabel className='RangeSelectFilter__numberLabelField'>
                Max
              </IonLabel>
              <NumberFormatField
                value={rangeValue?.upper}
                name='upper'
                allowNegative={false}
                className='RangeSelectFilter__numberField'
                onValueChange={handleChangeValue}
              />
            </IonCol>
          </IonRow>

          {isDisplayConfirmButton && (
            <IonCol>
              <IonButton
                onClick={onItemSelected}
                color='success'
                style={{ marginTop: '25px' }}
              >
                <IonIcon icon={checkmarkCircleOutline} />
              </IonButton>
            </IonCol>
          )}
          {isResetValue && (
            <IonCol>
              <IonButton
                onClick={onResetValue}
                fill='solid'
                color='danger'
                style={{ marginTop: '25px' }}
              >
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonCol>
          )}
        </IonItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RangeSelectFilter
