import { useMemo, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import {
  DeleteIcon,
  EditIcon,
  FileUnknownIcon,
  PdfIcon,
} from '~/components/shared'

import './styles.scss'
import queryString from 'query-string'
import 'cropperjs/dist/cropper.css'
import DialogImageModifier from '../FormData/PaymentsForm/DialogImageModifier'

interface IFileCardProps {
  file: File | string | null
  onRemove?: (file: IFileCardProps['file']) => void
  onEdit?: (file: IFileCardProps['file']) => void
}

function FileCard(props: IFileCardProps) {
  const { file, onRemove, onEdit } = props

  const [isOpenImageModifier, setIsOpenImageModifier] = useState(false)

  const fileType = useMemo(() => {
    if (file instanceof File) {
      const [, type] = file.type.split('/')
      return type
    }

    if (typeof file === 'string') {
      const parsedParams = queryString.parse(file)
      if (parsedParams['response-content-type']?.includes('pdf')) {
        return 'pdf'
      }

      if (
        ['png', 'jpg', 'jpeg'].some(type =>
          parsedParams['response-content-type']?.includes(type),
        )
      ) {
        return 'png'
      }
    }
    return 'unknown'
  }, [file])

  const renderView = () => {
    if (fileType === 'pdf') {
      return (
        <div className='pdfContainer'>
          <PdfIcon size={40} />
        </div>
      )
    }

    if (typeof file === 'string') {
      return (
        <div className='imageContainer'>
          <img src={file} alt='image' />
        </div>
      )
    }

    if (file instanceof File) {
      return (
        <div className='imageContainer'>
          <img src={(file as any).preview} alt='image' />
        </div>
      )
    }

    return (
      <div className='pdfContainer'>
        <FileUnknownIcon size={40} />
      </div>
    )
  }

  return (
    <>
      <div className='FileCard__container'>
        <div className='fileHeaderContainer'>
          <ButtonGroup>
            {fileType !== 'pdf' && (
              <Button
                variant='warning'
                onClick={() => {
                  setIsOpenImageModifier(true)
                }}
              >
                <EditIcon color='white' />
              </Button>
            )}
            <Button
              variant='danger'
              onClick={() => {
                onRemove && onRemove(file)
              }}
            >
              <DeleteIcon color='white' />
            </Button>
          </ButtonGroup>
        </div>
        {renderView()}
      </div>

      <DialogImageModifier
        isOpen={isOpenImageModifier}
        onClose={() => {
          setIsOpenImageModifier(false)
        }}
        image={file}
        onSave={file => {
          onEdit && onEdit(file)
          setIsOpenImageModifier(false)
        }}
      />
    </>
  )
}

export default FileCard
