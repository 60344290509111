import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IProductType } from '~/types/models/IProductType'
import { useMemo } from 'react'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryProductTypes = (
  params = {},
  options?: Partial<UseQueryOptions<IProductType[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'productTypes',
      sessionUser?.id,
      buildGetUrl(apiClient.productTypes.endpoint, params),
      sessionUser?.currentScope,
    ],
    async queryFn() {
      const response = await apiClient.productTypes.get()
      return response.productTypes
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const productTypesData = useMemo(() => data || [], [data])

  const productTypeOptions = useMemo(
    () =>
      productTypesData.map(({ id, code, name }) => ({
        value: id,
        label: [code, name].filter(text => text).join(' - '),
      })),
    [productTypesData],
  )

  return {
    productTypesData: data || [],
    productTypeOptions,
    isProductTypesLoading: isLoading,
  }
}

export default useQueryProductTypes
