import { useCallback, useMemo } from 'react'

import { IonCardHeader, IonCardTitle, IonIcon } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'

import * as Icon from 'ionicons/icons'
import _ from 'lodash'
import moment from 'moment'
import clsx from 'clsx'

import type { ISensorCardProps } from './type'

import './styles.scss'

function SensorCard(props: ISensorCardProps) {
  const { sensorData, className, onClick } = props

  const mostRecentItem = useMemo(() => {
    if (sensorData.data.length > 0) {
      return sensorData.data.reduce((mostRecent, currentItem) => {
        const mostRecentTimestamp = new Date(mostRecent.timestamp)
        const currentItemTimestamp = new Date(currentItem.timestamp)

        return mostRecentTimestamp > currentItemTimestamp
          ? mostRecent
          : currentItem
      })
    }
    return null
  }, [sensorData.data])

  const onClickCard = useCallback(() => {
    onClick && onClick(sensorData)
  }, [onClick, sensorData])

  return (
    <div
      className={clsx('SensorCard__container', className)}
      style={{ color: sensorData.font, background: sensorData.background }}
      onClick={onClickCard}
    >
      <div className='headerContainer'>
        <div className='icon'>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <IonIcon icon={(Icon as any)[_.camelCase(sensorData.icon)]} />
        </div>
        <IonCardHeader class='titleContainer'>
          <IonCardTitle style={{ color: sensorData.font }}>
            {sensorData.name}
          </IonCardTitle>
        </IonCardHeader>
      </div>
      <div className='bodyContainer'>
        <div className='valueContainer'>
          <div className='value'>{mostRecentItem?.value}</div>
          <div className='unit'>{sensorData.unit}</div>
        </div>
        <ToolTipOverlay
          content={moment(mostRecentItem?.timestamp).format('MMM-D HH:mm')}
          placement='top'
        >
          <div className='timestamp'>
            {mostRecentItem ? moment(mostRecentItem.timestamp).fromNow() : ''}
          </div>
        </ToolTipOverlay>
      </div>
    </div>
  )
}

export default SensorCard
