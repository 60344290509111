import { createSlice } from '@reduxjs/toolkit'

const companyEmailsUiSlice = createSlice({
  name: 'companyEmails',
  initialState: {
    loadingTable: false,
    filters: {
      anyOfEmailTypes: [],
    },
  },
  reducers: {
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
})

export default companyEmailsUiSlice
