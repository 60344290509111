import { useMemo } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import qs from 'qs'
import { replaceUrl } from '~/utils/buildUrl'

export function useRouter() {
  const params = useParams<{ id: string }>()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const query = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search],
  )

  const fullPath = useMemo(() => {
    const url = replaceUrl(location.pathname, query)

    return url
  }, [location.pathname, query])

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      params,
      query,
      match,
      location,
      history,
      fullPath,
    }
  }, [history, location, params, query, match, fullPath])
}
