import moment from 'moment'

export const getTitle = (date, format = 'ddd, MMM-D', defaultValue = 'ALL') => {
  if (date === null) {
    return 'Not Set'
  }

  const { startDate, endDate } = date || {}

  if (!startDate && !endDate) {
    return defaultValue
  } else if (
    moment(startDate).format('YYYY-MM-DD') ==
    moment(endDate).format('YYYY-MM-DD')
  ) {
    return moment(startDate).format(format)
  }

  return `${startDate ? moment(startDate).format(format) : '...'} → ${
    endDate ? moment(endDate).format(format) : '...'
  }`
}

export const getTitleRange = date => {
  if (date === null || date === undefined) {
    return 'Not Set'
  }
  const { startDate, endDate } = date
  if (!startDate && !endDate) {
    return 'All'
  } else if (
    moment(startDate).format('YYYY-MM-DD') ==
    moment(endDate).format('YYYY-MM-DD')
  ) {
    return moment(startDate).format('ddd, MMM-D')
  }

  return `${startDate ? moment(startDate).format('MMM-D') : '...'} → ${
    endDate ? moment(endDate).format('MMM-D') : 'Now'
  }`
}
