import { useState, useMemo, useCallback } from 'react'

import { IonAvatar } from '@ionic/react'

import clsx from 'clsx'
import getInitName from '~/utils/getInitName'

import type { IUseCompanyAvatar } from './type'
import { isExpiredAWSLink } from '~/utils/utils'
import { Badge } from 'react-bootstrap'
import { colord } from 'colord'

export const useCompanyAvatar = (props: IUseCompanyAvatar) => {
  const {
    company,
    companyType,
    showImagePopup,
    width,
    height,
    noBorder,
    isChip,
    className,
    color,
    ...containerProps
  } = props

  const [showPopup, setShowPopup] = useState(false)
  const [hasImageError, setHasImageError] = useState(false)

  const companyName = useMemo(() => {
    const { label, name } = company

    if (name) {
      return name
    }

    if (!label) {
      return ''
    }

    if (typeof label === 'string') {
      const [suffix, splitName] = label.split(' - ')

      return splitName || suffix
    }
    return 'Unknown'
  }, [company])

  const handleOpenImagePopup = useCallback(() => {
    if (showImagePopup) {
      setShowPopup(true)
    }
  }, [showImagePopup])

  const handleCloseImagePopup = useCallback(() => {
    setShowPopup(false)
  }, [])

  const renderAvatar = useMemo(() => {
    if (company?.image && !isExpiredAWSLink(company?.image) && !hasImageError) {
      return (
        <div
          className={clsx('CompanyAvatar__wrapAvatar', companyType, {
            showImagePopup,
            noBorder,
          })}
          onClick={handleOpenImagePopup}
        >
          <IonAvatar
            className='CompanyAvatar__avatar'
            style={{ width, height }}
          >
            <img
              alt=''
              src={company?.image}
              onError={() => {
                setHasImageError(true)
              }}
            />
          </IonAvatar>
        </div>
      )
    }

    const missingSymbols = ['+', '-', '?']

    const initialsName = missingSymbols.includes(companyName)
      ? '+'
      : getInitName(companyName, '+')

    return (
      <div
        className={clsx('CompanyAvatar__wrapInitialName', companyType)}
        style={{
          width: noBorder ? width : (width as number) + 2,
          height: noBorder ? height : (height as number) + 2,
          background: color,
          borderColor: color,
          color: color ? (colord(color).isDark() ? 'white' : 'black') : 'white',
        }}
      >
        <div className='CompanyAvatar__initialName'>{initialsName}</div>
      </div>
    )
  }, [
    company?.image,
    hasImageError,
    companyName,
    companyType,
    noBorder,
    width,
    height,
    showImagePopup,
    handleOpenImagePopup,
    color,
  ])
  const companyAvatarRendered = useMemo(() => {
    if (isChip) {
      return (
        <Badge pill className={className} {...containerProps}>
          {company?.code}
        </Badge>
      )
    }
    return (
      <div
        {...containerProps}
        className={clsx('CompanyAvatar__container', className)}
      >
        {renderAvatar}
      </div>
    )
  }, [isChip, containerProps, className, renderAvatar, company?.code])

  return {
    companyAvatarRendered,
    showPopup,
    handleCloseImagePopup,
  }
}
