import { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { Button } from '~/components/shared'
import { IonButtons, IonText } from '@ionic/react'
import { Popover } from 'react-bootstrap'
import { When } from 'react-if'

import _ from 'lodash'
import { addCircleOutline, closeCircle } from 'ionicons/icons'
import { ENoteableType } from '~/types/enums/ENote'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { toast } from 'react-toastify'

import type { INoteButtonProps } from './type'
import type { IUser } from '~/types/models/IUser'
import type { INote, INoteFormData } from '~/types/models/INote'
import type { ICompany } from '~/types/models/ICompany'
import { NoteItem, type INoteOnSubmitParams } from './NoteItem'
import { produce } from 'immer'

const useNoteButton = (props: INoteButtonProps) => {
  const {
    notesData,
    noteableId,
    noteableType,
    size,
    tooltipProps,
    afterCreateNote,
    afterUpdateNote,
    afterDeleteNote,
    onChangeNotes
  } = props

  const [isCreating, setIsCreating] = useState(false)

  const sessionUser: IUser = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const onUpdateNote = useCallback(
    async ({ note, id }: INoteOnSubmitParams) => {
      const { errors, ...response } = await apiClient.notes.update(
        id as number,
        {
          note: {
            note,
          },
        },
      )
      if (response.id) {
        const newNotes = produce(notesData, draft => {
          const index = draft.findIndex((currentNote) => currentNote.id === response.id)
          if (index !== -1) {
            draft[index] = response
          }
        })
        onChangeNotes && onChangeNotes(newNotes)
        afterUpdateNote && afterUpdateNote(response)
        toast.success(toastMessages.createSuccess)
        setIsCreating(false)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [afterUpdateNote, notesData, onChangeNotes],
  )

  const onDeleteNote = useCallback(
    async (note: INote) => {
      afterDeleteNote && afterDeleteNote(note)
      const newNotes = produce(notesData, draft => {
        const index = draft.findIndex(({ id }) => id === note.id)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      })
      onChangeNotes && onChangeNotes(newNotes)
      toast.success(toastMessages.deleteSuccess)
      apiClient.notes.delete(note.id)
    },
    [afterDeleteNote, notesData, onChangeNotes],
  )

  const renderNotes = useMemo(
    () =>
      _.orderBy(notesData, ['createdAt'], ['desc']).map(note => (
        <NoteItem
          key={note.id}
          noteData={note}
          onSubmit={onUpdateNote}
          onDelete={onDeleteNote}
        />
      )),
    [notesData, onDeleteNote, onUpdateNote],
  )

  const onToggleCreate = useCallback(() => {
    setIsCreating(prev => !prev)
  }, [])

  const onCreateNote = useCallback(
    async ({ note }: INoteOnSubmitParams) => {
      const payload: INoteFormData = {
        noteableId: noteableId as number,
        noteableType: noteableType as ENoteableType,
        userAccessId: sessionUser.userAccess.id,
        companyId: currentCompany.id,
        note,
      }
      const { errors, ...response } = await apiClient.notes.create({
        note: payload,
      })
      if (response.id) {
        afterCreateNote && afterCreateNote(response)
        onChangeNotes && onChangeNotes([...notesData, response])
        toast.success(toastMessages.createSuccess)
        setIsCreating(false)
      } else {
        toast.error(toastMessages.createError)
      }
    },
    [
      afterCreateNote,
      currentCompany.id,
      noteableId,
      noteableType,
      notesData,
      onChangeNotes,
      sessionUser.userAccess.id
    ],
  )

  const Overlay = useMemo(() => {
    return (
      <Popover className='NoteButton__overlay'>
        <Popover.Body>
          <div className='header'>
            <IonText style={{ fontSize: 13, fontWeight: 500 }}>Notes</IonText>
            <IonButtons style={{ justifyContent: 'flex-end' }}>
              <Button
                icon={isCreating ? closeCircle : addCircleOutline}
                color={isCreating ? 'danger' : 'concord'}
                tooltipProps={{
                  content: isCreating ? 'Close' : 'Add',
                  placement: 'top',
                }}
                onClick={onToggleCreate}
              />
            </IonButtons>
          </div>
          <When condition={isCreating}>
            <NoteItem
              noteData={{
                id: 0,
                note: '',
                noteableId: noteableId as number,
                noteableType: noteableType as ENoteableType,
                userAccessId: sessionUser.userAccess.id,
                companyId: currentCompany.id,
                showAscurrent: false,
                shareable: null,
              }}
              isUpdating
              isHiddenCloseButton
              onSubmit={onCreateNote}
            />
          </When>
          {renderNotes}
        </Popover.Body>
      </Popover>
    )
  }, [
    currentCompany.id,
    isCreating,
    noteableId,
    noteableType,
    onCreateNote,
    onToggleCreate,
    renderNotes,
    sessionUser?.userAccess?.id
  ])

  return {
    Overlay,
    notesData,
    size,
    tooltipProps
  }
}

export default useNoteButton
