import { useReusableTable } from '../hooks/useReusableTable'
import { RTPaper } from './table/RTPaper'
import type { IReusableTableRowData, RTTableOptions } from '../types'

export type IReusableTableProps<TData extends IReusableTableRowData> =
  RTTableOptions<TData>

export const ReusableTable = <TData extends IReusableTableRowData>(
  props: IReusableTableProps<TData>,
) => {
  const table = useReusableTable(props)

  return <RTPaper table={table} />
}
