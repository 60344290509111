import _ from 'lodash'

const resetObjValues = obj => {
  if (!obj) {
    return {}
  }

  const newObj = {}
  _.forEach(obj, (value, key) => {
    if (key === 'visibleData') {
      newObj[key] = value
    } else if (key === 'ascendingSort') {
      newObj.ascendingSort = value
    } else if (key === 'order_by') {
      newObj.order_by = value
    } else if (key === 'perPage') {
      newObj[key] = 24
    } else if (_.isString(value)) {
      newObj[key] = ''
    } else if (_.isNumber(value)) {
      newObj[key] = null
    } else if (_.isPlainObject(value)) {
      newObj[key] = {}
    } else if (_.isArray(value)) {
      newObj[key] = []
    } else {
      newObj[key] = null
    }
  })

  return newObj
}

export default resetObjValues
