import type { ISvgIconProps } from './type'

const PayrollIcon = (props: ISvgIconProps) => {
  const { size = 16, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M14 14h3m-3-4h3m-8-.5v-1m0 1h2m-2 0c-1.799 0-1.998.432-2 1.332-.002.9 0 1.168 2 1.168s2 .206 2 1.167c0 .722 0 1.333-2 1.333m0 0v1m0-1H7M6.2 19h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 17.48 21 16.92 21 15.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 5 18.92 5 17.8 5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 6.52 3 7.08 3 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 19 5.08 19 6.2 19Z'
      />
    </svg>
  )
}
export default PayrollIcon
