import { useCallback, useState } from 'react'
import { IReusableTableRowData, ReusableTableInstance } from '../../../types'
import './styles.scss'
import clsx from 'clsx'
import { RTTableFooterActionButtons } from '../RTTableFooterActionButtons'
import { ArrowBackIcon } from '~/components/shared'

export interface IRTTableFooterActionsProps<
  TData extends IReusableTableRowData,
> {
  table: ReusableTableInstance<TData>
}

const RTTableFooterActions = <TData extends IReusableTableRowData>(
  props: IRTTableFooterActionsProps<TData>,
) => {
  const { table } = props

  const [isOpenButtons, setIsOpenButtons] = useState(true)

  const onToggleShowHide = useCallback(() => {
    setIsOpenButtons(prev => !prev)
  }, [])

  return (
    <div
      className={clsx('RTTableFooterActions__container', {
        isCollapsed: !isOpenButtons,
      })}
    >
      <div
        className='RTTableFooterActions__collapseExpand'
        onClick={onToggleShowHide}
      >
        <ArrowBackIcon
          className={clsx('RTTableFooterActions__collapseExpandIcon', {
            isOpenButtons,
          })}
        />
      </div>

      <div className='RTTableFooterActions__buttonActions'>
        <RTTableFooterActionButtons
          className={clsx('RTTableFooterActions__toolbarSelectedRows', {
            isCollapsed: !isOpenButtons,
          })}
          table={table}
        />
      </div>
    </div>
  )
}

export default RTTableFooterActions
