import { usePagination } from 'pagination-react-js'

import './styles.scss'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  DoubleArrowBackIcon,
  DoubleArrowForwardIcon,
} from '../SvgIcons'

export interface IReusablePaginationProps {
  pageIndex: number
  pageSize: number
  rowCount: number
  showPaginationPages?: boolean
  showPerPageOptions?: boolean
  onChangePageIndex?: (pageIndex: number) => void
  onChangePageSize?: (pageSize: number) => void
  rowsPerPageOptions?: { label: string; value: number }[] | number[]
  style?: React.CSSProperties
}

const defaultRowsPerPage = [5, 10, 24, 50, 100]

const PaginationItem = ({
  children,
  label,
  isActive,
  onClick,
  isDisabled,
  rel,
  isThreedot,
}: any) => {
  return (
    <li
      className={clsx('ReusablePagination__paginationItem', {
        isActive,
        isDisabled,
        isThreedot,
      })}
      aria-current={isActive ?? 'page'}
      aria-label={label}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </li>
  )
}

function ReusablePagination(props: IReusablePaginationProps) {
  const {
    pageIndex,
    pageSize,
    rowCount,
    showPaginationPages = true,
    showPerPageOptions = true,
    rowsPerPageOptions = defaultRowsPerPage,
    style,
    onChangePageIndex,
    onChangePageSize,
  } = props

  const { pageNumbers, setActivePage, setRecordsPerPage } = usePagination({
    activePage: 1,
    recordsPerPage: 5,
    totalRecordsLength: rowCount,
    offset: 2,
    navCustomPageSteps: { prev: 2, next: 3 },
    permanentFirstNumber: true,
    permanentLastNumber: true,
  })

  const lastRowIndex = Math.min(pageIndex * pageSize + pageSize, rowCount)

  const disableBack = pageIndex <= 0
  const disableNext = lastRowIndex >= rowCount

  const onMovePageTo = (page: number | boolean) => () => {
    if (typeof page === 'number') {
      onChangePageIndex && onChangePageIndex(page - 1)
    }
  }

  useEffect(() => {
    setActivePage(pageIndex + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    setRecordsPerPage(pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  return (
    <div className='ReusablePagination__container' style={style}>
      <div />
      {showPaginationPages && (
        <ul className='ReusablePagination__paginationList'>
          <PaginationItem
            label={`Goto first page ${pageNumbers.firstPage}`}
            rel='first'
            isDisabled={disableBack}
            onClick={onMovePageTo(pageNumbers.firstPage)}
          >
            <DoubleArrowBackIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto previous page ${pageNumbers.previousPage}`}
            rel='prev'
            isDisabled={disableBack}
            onClick={onMovePageTo(pageNumbers.previousPage)}
          >
            <ArrowBackIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto first page ${pageNumbers.firstPage}`}
            isActive={pageNumbers.firstPage === pageNumbers.activePage}
            onClick={onMovePageTo(pageNumbers.firstPage)}
          >
            {pageNumbers.firstPage}
          </PaginationItem>

          {pageNumbers.customPreviousPage && (
            <PaginationItem
              label={`Goto page ${pageNumbers.customPreviousPage}`}
              isThreedot
            >
              &middot;&middot;&middot;
            </PaginationItem>
          )}

          {pageNumbers.navigation.map(navigationNumber => {
            const isFirstOrLastPage =
              navigationNumber === pageNumbers.firstPage ||
              navigationNumber === pageNumbers.lastPage

            return isFirstOrLastPage ? null : (
              <PaginationItem
                label={`Goto page ${navigationNumber}`}
                key={navigationNumber}
                isActive={navigationNumber === pageNumbers.activePage}
                onClick={onMovePageTo(navigationNumber)}
              >
                {navigationNumber}
              </PaginationItem>
            )
          })}

          {pageNumbers.customNextPage && (
            <PaginationItem
              isThreedot
              label={`Goto page ${pageNumbers.customNextPage}`}
            >
              &middot;&middot;&middot;
            </PaginationItem>
          )}

          {pageNumbers.firstPage !== pageNumbers.lastPage && (
            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              isActive={pageNumbers.lastPage === pageNumbers.activePage}
              onClick={onMovePageTo(pageNumbers.lastPage)}
            >
              {pageNumbers.lastPage}
            </PaginationItem>
          )}

          <PaginationItem
            label={`Goto next page ${pageNumbers.nextPage}`}
            rel='next'
            isDisabled={disableNext}
            onClick={onMovePageTo(pageNumbers.nextPage)}
          >
            <ArrowForwardIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto last page ${pageNumbers.lastPage}`}
            rel='last'
            isDisabled={disableNext}
            onClick={onMovePageTo(pageNumbers.lastPage)}
          >
            <DoubleArrowForwardIcon />
          </PaginationItem>
        </ul>
      )}

      {showPerPageOptions && (
        <div className='ReusablePagination__perPage'>
          <label>Rows per page</label>
          <select
            value={pageSize}
            onChange={(event: any) => {
              onChangePageSize && onChangePageSize(+(event.target.value as any))
            }}
          >
            {(rowsPerPageOptions as number[]).map(opt => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}

export default ReusablePagination
