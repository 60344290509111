import { useMemo } from 'react'

import HighLightText from '../HighLightText'

const MultipleHighLightText = props => {
  const { regexes, children } = props

  const elementHighLightText = useMemo(() => {
    let elements = children

    regexes.forEach(({ regex, className }) => {
      elements = (
        <HighLightText regex={regex} className={className}>
          {elements}
        </HighLightText>
      )
    })

    return elements
  }, [children, regexes])

  return <span>{elementHighLightText}</span>
}

export default MultipleHighLightText
