import React from 'react'
import { useSelector } from 'react-redux'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import BodyContent from './BodyContent'

import clsx from 'clsx'

import type { IBodyProps } from './type'
import { selectSessionAuthenticated } from '~/redux/selectors'

const Body = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBodyProps>
>((props, ref) => {
  const { children, loading, toastProps, className, wrapClassName } = props
  // dont know if scrollX and scrollY will cause error at some place if so please remove
  const authenticated: boolean = useSelector(selectSessionAuthenticated)
  const isCollapsed: boolean = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.sidebar.isCollapsed,
  )

  const { isMobileScreen } = useBreakpoint()

  return (
    <div
      className={clsx('body-grid safe-area-inset-top', wrapClassName)}
      ref={ref}
    >
      {authenticated && !isMobileScreen && (
        <div
          className={clsx(
            'Body__menuSpace d-print-none',
            isCollapsed ? 'menu-column-mini-space' : 'menu-column-space',
          )}
        />
      )}
      <BodyContent
        className={className}
        loading={loading}
        toastProps={toastProps}
      >
        {children}
      </BodyContent>
    </div>
  )
})

export default Body
