import * as types from '../actions/actionTypes'
import { produce } from 'immer'

export const initialState = {
  document: null,
  preProcessingTests: [],
  loading: false,
  uploadSuccess: false,
  uploadedFiles: [],
  error: undefined,
}

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DOCUMENT_DETAILS_SUCCESS: {
      const { document } = action

      return {
        ...state,
        document,
        loading: false,
      }
    }
    case types.FETCH_DOCUMENT_DETAILS: {
      return {
        ...state,
        loading: true,
      }
    }

    case types.FETCH_PREPROCESSING_TESTS_SUCCESS: {
      const { preProcessingTests } = action

      return {
        ...state,
        preProcessingTests,
        loading: false,
      }
    }

    case types.FETCH_PREPROCESSING_TESTS: {
      return {
        ...state,
        loading: true,
      }
    }

    case types.UPLOAD_DOCUMENT: {
      return {
        ...state,
        uploadSuccess: false,
        error: undefined,
        loading: true,
      }
    }
    case types.UPLOAD_DOCUMENT_FAILURE: {
      const { error } = action

      return {
        ...state,
        error,
        loading: false,
      }
    }
    case types.UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        uploadedFiles: action.documents,
        uploadSuccess: true,
        loading: false,
      }
    }
    case types.RESET_UPLOADING_DOCUMENT: {
      return {
        ...state,
        uploadSuccess: false,
        uploadedFiles: [],
      }
    }

    case types.SET_DOCUMENT_LOADING: {
      return produce(state, draft => {
        draft.loading = action.payload
      })
    }

    default: {
      return state
    }
  }
}

export default documentReducer
