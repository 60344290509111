import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const NewTabIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      id='_x32_'
      viewBox='0 0 512 512'
      width={size}
      height={size}
      {...svgProps}
    >
      <style>{`.st0{fill:${color}}`}</style>
      <path
        d='M464 0H48C21.492 0 0 21.492 0 48v416c0 26.508 21.492 48 48 48h416c26.508 0 48-21.492 48-48V48c0-26.508-21.492-48-48-48zm-19.336 35c10.492 0 19 8.508 19 19s-8.508 19-19 19-19-8.508-19-19 8.508-19 19-19zm-70.5 0c10.492 0 19 8.508 19 19s-8.508 19-19 19-19-8.508-19-19 8.508-19 19-19zm-70.5 0c10.492 0 19 8.508 19 19s-8.508 19-19 19-19-8.508-19-19 8.508-19 19-19zM472 464c0 4.406-3.586 8-8 8H48c-4.414 0-8-3.594-8-8V104h432v360z'
        className='st0'
      />
      <path
        d='M112 192h288v32H112zM112 272h288v32H112zM112 352h152v32H112z'
        className='st0'
      />
    </svg>
  )
}
export default NewTabIcon
