import { useCallback, useMemo, useState } from 'react'
import { useQueryRetrieveWorkerReciprocityElections } from '~/hooks/useQueryData'

import { ReciprocityInput } from '../ReciprocityInput'
import { Button } from '~/components'

import { produce } from 'immer'

import type { IWorkerReciprocityElection } from '~/types/models/ICheck'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IReciprocityFormProps } from './type'
import { apiClient } from '~/api/ApiClient'
import getErrorFromCheckResponse from '~/utils/getErrorFromCheckResponse'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'
import { useDeepCompareEffect } from 'react-use'

function ReciprocityForm(props: IReciprocityFormProps) {
  const { workerUid } = props

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    workerReciprocityElections,
    isLoadingWorkerReciprocityElections,
    refetchworkerReciprocityElections,
  } = useQueryRetrieveWorkerReciprocityElections({
    worker: {
      checkUid: workerUid,
    },
  })

  const [reciprocityData, setReciprocityData] = useState<
    IWorkerReciprocityElection[]
  >([])

  const onChangeReciprocity = useCallback(
    (data: IWorkerReciprocityElection) => {
      setReciprocityData(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(
            ({ jurisdiction }) => jurisdiction === data.jurisdiction,
          )
          if (draft[index]) {
            draft[index] = data
          }
        }),
      )
    },
    [],
  )

  const renderInputs = useMemo(
    () =>
      reciprocityData.map(val => {
        return (
          <ReciprocityInput
            key={val.jurisdiction}
            data={val}
            onChange={onChangeReciprocity}
          />
        )
      }),
    [reciprocityData, onChangeReciprocity],
  )

  const onSave = useCallback(async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const reciprocityElections = reciprocityData.map(item => ({
        optIn: item.optIn,
        effectiveStart: item.effectiveStart,
        jurisdiction: item.jurisdiction,
      }))
      const response = await apiClient.check.updateWorkerReciprocityElections({
        worker: {
          checkUid: workerUid,
          reciprocityElections,
        },
      })
      const error = getErrorFromCheckResponse(response)
      if (error) {
        setErrorMessage(error)
      } else {
        toast.success(toastMessages.updateSuccess)
        refetchworkerReciprocityElections()
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reciprocityData])

  useDeepCompareEffect(() => {
    setReciprocityData(workerReciprocityElections)
  }, [workerReciprocityElections])

  return (
    <div className='ReciprocityForm__container'>
      <When condition={Boolean(errorMessage)}>
        <Alert variant='danger' style={{ fontSize: 14 }}>
          {errorMessage}
        </Alert>
      </When>
      <When
        condition={
          reciprocityData.length === 0 && !isLoadingWorkerReciprocityElections
        }
      >
        <Alert variant='info' style={{ fontSize: 14 }}>
          No Reciprocity Elections were found!
        </Alert>
      </When>

      <div role='form' className='formContainer'>
        {renderInputs}
      </div>
      <Button
        onClick={onSave}
        loading={isLoading || isLoadingWorkerReciprocityElections}
        isDisabled={reciprocityData.length === 0}
      >
        Save
      </Button>
    </div>
  )
}
export default ReciprocityForm
