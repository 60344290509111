import { useMemo } from 'react'
import { CheckMarkIcon, CloseIcon, IRTColumnDef } from '~/components/shared'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { IProductGroup } from '~/types/models/IProductGroup'
import { ORDER_TYPE_OPTIONS } from '~/utils/constants'

const useTableCompanyProductGroupSubTab = () => {
  const columns = useMemo<IRTColumnDef<IProductGroup>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Type',
        accessorKey: 'orderType',
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: ORDER_TYPE_OPTIONS,
      },
      {
        header: 'Active',
        accessorKey: 'active',
        Cell({ cell }) {
          const cellValue = cell.getValue<boolean>()

          return cellValue ? (
            <CheckMarkIcon color='var(--ion-color-success)' />
          ) : (
            <CloseIcon color='var(--ion-color-danger)' />
          )
        },
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyProductGroupSubTab
