import { IOrderExtra } from '~/types/models/IOrderExtra'
import './styles.scss'
import clsx from 'clsx'
import { IOrderExtraRange } from '~/types/models/IOrderExtraRange'
import {
  useQueryAutoExtras,
  useQuerySchedules,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import {
  DeleteIcon,
  EditIcon,
  ToggleSection,
  ToolTipOverlay,
} from '~/components/shared'
import { Badge, Button } from 'react-bootstrap'
import { EApplicationType } from '~/types/models/IAutoExtra'
import { OrderExtraRangeCard } from '../OrderExtraRangeCard'
import _ from 'lodash'

interface IOrderExtraCardProps {
  orderExtra: IOrderExtra
  selected?: boolean
  ranges: IOrderExtraRange[]
  orderId?: number

  onClickEdit: (orderExtra: IOrderExtra) => void
  onClickDelete: (orderExtra: IOrderExtra) => void

  afterUpdateOrderExtraRange: (orderExtraRange: IOrderExtraRange) => void
}

function OrderExtraCard(props: IOrderExtraCardProps) {
  const {
    orderExtra,
    selected,
    ranges,
    orderId,
    onClickEdit,
    onClickDelete,
    afterUpdateOrderExtraRange,
  } = props

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { sellerProductId, scheduleId, autoExtraId } = orderExtra

  const { findScheduleById, formatScheduleLabel } = useQuerySchedules(
    {
      filters: {
        orderId: [orderId] as number[],
      },
    },
    { enabled: Boolean(orderId) },
  )

  const { findSellerProductById } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const { findAutoExtraById, getAutoExtraLabel } = useQueryAutoExtras({
    filters: {
      applicationType: [EApplicationType.schedule, EApplicationType.order],
    },
  })

  const sellerProduct = findSellerProductById(sellerProductId)

  const sellerProductBagdes = [
    {
      field: 'qtyType',
      label: 'Qty Type',
    },
    {
      field: 'productUsage',
      label: 'Product Usage',
    },
    {
      field: 'orderType',
      label: 'Order Type',
    },
  ]

  const renderOrderExtraRanges = (ranges: IOrderExtraRange[]) => {
    if (ranges.length <= 1) {
      return ranges.map(range => (
        <OrderExtraRangeCard
          key={range.id}
          orderExtraRange={range}
          afterUpdate={afterUpdateOrderExtraRange}
        />
      ))
    }

    return (
      <ToggleSection label='Ranges' isOpenByDefault>
        {ranges.map(range => (
          <OrderExtraRangeCard
            key={range.id}
            orderExtraRange={range}
            afterUpdate={afterUpdateOrderExtraRange}
          />
        ))}
      </ToggleSection>
    )
  }

  return (
    <div className={clsx('OrderExtraCard__container', { selected })}>
      <div>
        <span style={{ fontWeight: 'bold' }}>{sellerProduct?.name}</span>

        {autoExtraId && (
          <ToolTipOverlay
            content={getAutoExtraLabel(findAutoExtraById(autoExtraId)) || '-'}
            placement='top'
          >
            <Badge
              bg='success'
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
            >
              Auto Extra
            </Badge>
          </ToolTipOverlay>
        )}

        {sellerProductBagdes
          .filter(({ field }) => (sellerProduct as any)?.[field])
          .map(({ field }) => (
            <Badge
              key={field}
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
            >
              {_.startCase((sellerProduct as any)?.[field])}
            </Badge>
          ))}
      </div>
      {scheduleId && (
        <div>
          <span style={{ fontWeight: 600 }}>Schedule: </span>
          <span>
            {formatScheduleLabel(findScheduleById(scheduleId)) || '-'}
          </span>
        </div>
      )}

      {ranges.length > 0 && (
        <div>
          {ranges.length <= 1 && (
            <span style={{ fontWeight: 600 }}>Ranges: </span>
          )}
          {renderOrderExtraRanges(ranges)}
        </div>
      )}

      <div className='OrderExtraCard__buttons'>
        <Button
          variant='warning'
          onClick={() => {
            onClickEdit(orderExtra)
          }}
        >
          <EditIcon color='white' />
        </Button>
        <Button
          variant='danger'
          style={{ marginLeft: 4 }}
          onClick={() => {
            onClickDelete(orderExtra)
          }}
        >
          <DeleteIcon color='white' />
        </Button>
      </div>
    </div>
  )
}

export default OrderExtraCard
