import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectCurrentScope, selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import { produce } from 'immer'

import type { IUser } from '~/types/models/IUser'
import type { IBuyerSeller } from '~/types/models/IBuyerSeller'
import { ICompany } from '~/types/models/ICompany'
import { EScope } from '~/types/enums/ECommonEnum'

const useModifyAddresses = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const queryKey = useMemo(
    () => [
      'buyerSellers',
      sessionUser?.id,
      buildGetUrl(apiClient.buyerSellers.endpoint, params),
      sessionUser?.currentScope,
    ],
    [params, sessionUser?.currentScope, sessionUser?.id],
  )

  const addBuyerSeller = useCallback(
    (data: IBuyerSeller) => {
      queryClient.setQueryData<IBuyerSeller[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateBuyerSeler = useCallback(
    (id: number, data: IBuyerSeller) => {
      queryClient.setQueryData<IBuyerSeller[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updateBuyerSellerByCompany = useCallback(
    (company: ICompany) => {
      queryClient.setQueryData<IBuyerSeller[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const field = currentScope === EScope.buyer ? 'seller' : 'buyer'
            const index = draft.findIndex(
              item => item[field]?.id === company.id,
            )
            if (draft[index]) {
              draft[index][field] = company
            }
          }
        }),
      )
    },
    [currentScope, queryClient, queryKey],
  )

  const removeBuyerSeller = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBuyerSeller[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addBuyerSeller,
    updateBuyerSeler,
    removeBuyerSeller,
    updateBuyerSellerByCompany,
  }
}

export default useModifyAddresses
