import { useState, useCallback } from 'react'

import CheckoutForm from './CheckoutForm'
import { CommonDialogV2 } from '~/components/shared'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PopupResultCheckout from './PopupResultCheckout'

import _ from 'lodash'

import './DialogInvoicePayments.scss'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { toast } from 'react-toastify'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)

function DialogInvoicePayments(props) {
  const { isOpen, onClose, clientSecret, amount, fees, ...dialogProps } = props

  const [isLoading, setIsLoading] = useState(false)
  const [popupResultCheckout, setPopupResultCheckout] = useState({
    show: false,
    status: '',
  })

  const toggleLoadingDialog = useCallback(
    nextLoading => {
      const newLoading = _.isNil(nextLoading) ? isLoading : nextLoading
      setIsLoading(newLoading)
    },
    [isLoading],
  )

  const appearance = {
    theme: 'stripe',
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
  }

  const options = {
    clientSecret,
    appearance,
  }

  const afterCheckout = useCallback(
    ({ status }) => {
      onClose && onClose()
      setPopupResultCheckout(() => ({
        show: true,
        status,
      }))
    },
    [onClose],
  )

  const togglePopupResultCheckout = useCallback(
    nextValue => {
      const newShowPopupResultCheckout = _.isNil(nextValue)
        ? popupResultCheckout?.show
        : nextValue
      setPopupResultCheckout(prev => ({
        ...prev,
        show: newShowPopupResultCheckout,
      }))
    },
    [popupResultCheckout?.show],
  )

  return (
    <>
      <CommonDialogV2
        {...dialogProps}
        className='DialogInvoicePayments__dialogContainer'
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        isHiddenOkButton
        title='Payment'
      >
        <div>
          <div style={{ margin: '8px 0', fontSize: 14 }}>
            <div>
              <span style={{ fontWeight: 600 }}>Amount: </span>
              <span>{formatCurrencyToDollar.format(Number(amount))}</span>
            </div>
            <div style={{ marginTop: 8 }}>
              <span style={{ fontWeight: 600 }}>Fee: </span>
              <span>{formatCurrencyToDollar.format(Number(fees))}</span>
            </div>
          </div>

          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                toggleLoadingDialog={toggleLoadingDialog}
                afterCheckout={obj => {
                  afterCheckout && afterCheckout(obj)
                  toast.success('Paid successfully!')
                  onClose && onClose()
                }}
              />
            </Elements>
          )}
        </div>
      </CommonDialogV2>
      <PopupResultCheckout
        {...popupResultCheckout}
        onClose={() => togglePopupResultCheckout(false)}
      />
    </>
  )
}

export default DialogInvoicePayments
