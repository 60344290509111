import * as types from '../actions/actionTypes'
import Immutable from 'immutable'
import defaultModuleFilters from '~/constants/defaultModuleFilters'

export const initialState = Immutable.Map({
  filters: defaultModuleFilters.documents,
  hasUrlParams: false,
  isUploading: false,
})

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_DOCUMENTS_FILTERS: {
      return state.set('filters', defaultModuleFilters.documents)
    }

    case types.RESET_FILTERS_WITH_INVOICE_DOC_TYPE: {
      return state.set('filters', {
        ...defaultModuleFilters.documents,
        docType: [2],
      })
    }

    case types.UPDATE_ALL_DOCUMENT_FILTERS: {
      const { field } = action

      return state.set('filters', field)
    }

    case types.UPDATE_DOCUMENTS_FILTERS: {
      const { field } = action

      return state.set('filters', {
        ...state.get('filters'),
        [field.key]: field.value,
      })
    }

    case types.UPDATE_DASHBOARD_DOCUMENTS_FILTERS: {
      const { filters } = action
      const newState = { ...defaultModuleFilters.documents }

      for (const prop in filters) {
        if (prop == 'createdAt') {
          newState[prop] = filters[prop]
        } else {
          const newFilterArray = [...newState[prop]]
          newFilterArray.push(filters[prop])
          newState[prop] = newFilterArray
        }
      }

      return state.set('filters', newState).set('hasUrlParams', true)
    }

    case types.SET_DEFAULT_FILTER_FOR_DOCUMENTS: {
      return initialState
    }

    case types.DOCUMENTS_SHOW_FORM: {
      const { showForm } = action

      if (!showForm) {
        return state.set('isUploading', false)
      }

      return state.set('isUploading', !state.get('isUploading'))
    }

    default: {
      return state
    }
  }
}

export default documentsReducer
