import { useMemo, useCallback } from 'react'
import useStoreData from '~/hooks/useStoreData'
import useDefaultPage from '~/hooks/useDefaultPage'

import _ from 'lodash'
import { produce } from 'immer'

const useStoreGeneral = () => {
  const { storedOthers, saveOthersData, localStoredOthers } =
    useStoreData('general')

  const { defaultPage } = useDefaultPage()

  const DEFAULT_STRUCTURE = useMemo(
    () => ({
      updatedAt: null,
      data: {
        menu: 'wide',
        defaultPage: '',
      },
    }),
    [],
  )

  const storedData = useMemo(() => {
    if (_.size(storedOthers?.data) === 0) {
      return DEFAULT_STRUCTURE
    }

    return storedOthers?.data
  }, [DEFAULT_STRUCTURE, storedOthers?.data])

  const localStoredData = useMemo(() => {
    if (_.size(localStoredOthers?.data) === 0) {
      return DEFAULT_STRUCTURE
    }

    return localStoredOthers?.data
  }, [DEFAULT_STRUCTURE, localStoredOthers?.data])

  const storedMenu = useMemo(() => {
    return localStoredData.menu || ''
  }, [localStoredData])

  const storedDefaultPage = useMemo(() => {
    return localStoredData?.defaultPage || defaultPage
  }, [defaultPage, localStoredData?.defaultPage])

  const setStoredMenu = useCallback(
    value => {
      const newValues = produce(localStoredData, draft => {
        draft.menu = value
      })
      saveOthersData && saveOthersData({ data: newValues })
    },
    [saveOthersData, localStoredData],
  )

  const setStoredData = useCallback(
    callback => {
      let data = callback
      if (_.isFunction(callback)) {
        data = callback(localStoredData?.data)
      }
      saveOthersData && saveOthersData({ data })
    },
    [saveOthersData, localStoredData],
  )

  return {
    storedData,
    storedMenu,
    storedDefaultPage,
    setStoredMenu,
    setStoredData,
    localStoredData,
  }
}

export default useStoreGeneral
