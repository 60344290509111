var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button as MuiButton, makeStyles, CircularProgress, } from '@material-ui/core';
import clsx from 'clsx';
var useStyles = makeStyles(function () { return ({
    Root: {
        position: 'relative',
    },
    Button: {},
    ButtonProgress: {
        position: 'absolute',
        top: '30%',
        left: '40%',
    },
}); });
export var Button = function (props) {
    var loading = props.loading, disabled = props.disabled, className = props.className, children = props.children, containerClassName = props.containerClassName, buttonProps = __rest(props, ["loading", "disabled", "className", "children", "containerClassName"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: clsx(classes.Root, containerClassName) }, { children: [_jsx(MuiButton, __assign({ variant: "contained", color: "primary", className: clsx(classes.Button, className), disabled: loading || disabled }, buttonProps, { children: children })), loading && (_jsx(CircularProgress, { size: 16, className: classes.ButtonProgress }))] })));
};
