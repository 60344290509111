import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentCompanies } from '~/redux/selectors'

const useFindCompany = () => {
  const currentCompanies = useSelector(selectCurrentCompanies)

  const findCompany = useCallback(
    id => {
      const company = currentCompanies.find(company => company.id === id)
      if (company) {
        const { id, code, name, logo, legalName } = company

        return {
          id,
          code,
          name,
          logo,
          legalName,
        }
      }

      return {
        id,
        code: 'Unknown',
        name: 'Unknown',
        logo: null,
        legalName: 'Unknown',
      }
    },
    [currentCompanies],
  )

  return { findCompany }
}

export default useFindCompany
