import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

export const driverFleetsSlice = createSlice({
  name: 'driverFleets',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(driver => {
        newState[driver.id] = driver
      })
      return newState
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
    add: (state, { payload }) => {
      state[payload.id] = payload
    },
  },
})

export const fetchDriverFleets = createAsyncThunk(
  'driverFleets/fetchDriverFleets',
  async (params = {}, { dispatch }) => {
    try {
      const { driverFleets } = await apiClient.driverFleets.get(params)
      dispatch(driverFleetsSlice.actions.index(driverFleets))
    } catch (error) {
      console.log(error)
    }
  },
)

export const updateDriverFleet = createAsyncThunk(
  'driverFleets/updateDriverFleet',
  async (driverFleet, { dispatch }) => {
    try {
      const updatedDriverFleet = await apiClient.driverFleets.update(
        driverFleet.id,
        driverFleet,
      )
      dispatch(driverFleetsSlice.actions.update(updatedDriverFleet))
      return updatedDriverFleet
    } catch (error) {
      console.log(error)
    }
  },
)
