import defaultModuleFilters from '~/constants/defaultModuleFilters'
import { createSlice } from '@reduxjs/toolkit'
import DEFAULT_LOAD_COLUMNS from '~/constants/loads/columns'

export const loadsSlice = createSlice({
  name: 'loads',
  initialState: {
    buyerTerminalFormMode: null,
    currentLoad: {},
    columns: DEFAULT_LOAD_COLUMNS,
    dataLoaded: false,
    deleteObject: {},
    editLoadValue: { isEdit: false, loadId: '' },
    error: false,
    filters: defaultModuleFilters.loads,
    formData: null,
    harUrlParams: false,
    isSelecting: false,
    lastUpdatedAt: new Date(2000, 1, 1),
    loading: false,
    page: 1,
    pastLoad: [],
    perPage: 24,
    productFormMode: false,
    selectedBuyerTerminal: undefined,
    selectedLoadId: null,
    selectedProduct: undefined,
    selectedSellerTerminal: undefined,
    sellerTerminalFormMode: null,
    showBuyerTerminalForm: false,
    showProductForm: false,
    showSellerTerminalForm: false,
    updating: false,
    pageCount: 0,
    filtersConfigured: false,
    filtersInitialized: false,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
      state['dataLoaded'] = false // Whenever these control states change, dataLoaded should be false to allow for refetch data
    },
    updateSearchWord: (state, { payload }) => {
      state.filters.searchWord = payload
    },
    updateUiColumn: (state, action) => {
      let columns = state['columns']
      let { column } = action.payload
      // Object.entries(action.payload).forEach((column) => {
      let updateIndex = columns.findIndex(
        columnState => columnState.columnName == column.columnName,
      )
      state['columns'][updateIndex] = {
        ...state['columns'][updateIndex],
        ...column,
      }
    },
    updateUiColumns: (state, action) => {
      let columns = state['columns']
      action.payload.forEach(column => {
        let updateIndex = columns.findIndex(
          columnState => column.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state['columns'].push({ ...column })

          return
        }

        state['columns'][updateIndex] = {
          ...state['columns'][updateIndex],
          ...column,
        }
      })
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
      state['dataLoaded'] = false
    },
    updateUiPerPage: (state, action) => {
      let maxPage = Math.ceil(state['count'] / action.payload)
      if (state['page'] > maxPage) {
        state['page'] = maxPage
        state['pageCount'] = maxPage
      }
      state['perPage'] = action.payload
      state['dataLoaded'] = false
    },
  },
})

export default loadsSlice
