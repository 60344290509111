import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStorage from '~/hooks/useStorage'
import { useRouter } from '~/hooks/useRouter'

import { Link } from 'react-router-dom'
import { IonIcon, IonItem, IonSpinner } from '@ionic/react'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { logOutOutline } from 'ionicons/icons'
import { logout } from '~/redux/actions/sessionActions'
import { selectLoginPageLoading } from '~/redux/selectors'

const StaticMenuFooterMini = ({ settings }) => {
  const user = useSelector(state => state.session.user)
  const loginPageLoading = useSelector(selectLoginPageLoading)
  const router = useRouter()

  const isSettingsAllowed = useMemo(
    () => user && user.menuOptions && user.menuOptions.includes('settings'),
    [user],
  )
  const dispatch = useDispatch()
  const [, setStorageData] = useStorage()

  const handleLogout = useCallback(() => {
    dispatch(logout({ router }))
    setStorageData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setStorageData])

  return (
    <div className='menu-footer-mini'>
      <div className='footer-bar-mini'></div>
      {isSettingsAllowed && (
        <div className='footer-row-mini'>
          <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip>
                <FormattedMessage id={'Settings'} />
              </Tooltip>
            }
          >
            <Link
              key={settings.label}
              to={settings.path}
              style={{ textDecoration: 'none' }}
            >
              <IonItem
                className={
                  settings.path == location.pathname
                    ? 'menuDropdown menu-item-mini'
                    : 'hoverDropdown menu-item-mini'
                }
                lines='none'
              >
                <IonIcon
                  color={
                    settings.path == location.pathname ? 'concord' : 'cloudgrey'
                  }
                  className='menu-icon-mini'
                  icon={settings.icon}
                />
              </IonItem>
            </Link>
          </OverlayTrigger>
        </div>
      )}
      <div className='footer-row-mini'>
        <OverlayTrigger
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              <FormattedMessage id={'Logout'} />
            </Tooltip>
          }
        >
          <IonItem
            style={{ cursor: 'pointer' }}
            className='hoverDropdown menu-item-mini'
            lines='none'
            onClick={handleLogout}
            disabled={loginPageLoading}
          >
            {loginPageLoading && <IonSpinner name='lines' />}
            {!loginPageLoading && (
              <IonIcon
                color='cloudgrey'
                className='menu-icon-mini'
                icon={logOutOutline}
              />
            )}
          </IonItem>
        </OverlayTrigger>
      </div>
    </div>
  )
}
export default StaticMenuFooterMini
