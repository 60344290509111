import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const EditIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill={color}
      viewBox='0 -0.5 21 21'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M18.9 18.01H2.1V2.097h8.4V.106H0V20h21v-9.947h-2.1v7.958ZM6.3 9.95 16.63 0 21 4.115l-10.666 9.917H6.3V9.95Z'
      />
    </svg>
  )
}
export default EditIcon
