import moment from 'moment'
import { INullOrUndefined } from '~/types/models/ICommonModel'
import { IOffday } from '~/types/models/IOffday'

export const showEndingOffDay = (offday: INullOrUndefined | IOffday) => {
  if (offday) {
    const monthStartDate = moment(offday.startDate).format('MM')
    const monthEndDate = moment(offday.endDate).format('MM')

    if (monthStartDate !== monthEndDate) {
      return moment(offday.endDate).format('ddd, MMM-Do')
    }

    return moment(offday.endDate).format('ddd-Do')
  }
  return null
}

export const offdayFormatted = (offday: IOffday) => {
  const startDateFormatted = moment(offday.startDate).format('ddd, MMM-Do')
  const endDateFormatted = moment(offday.endDate).format('ddd, MMM-Do')
  return {
    startDateFormatted,
    endDateFormatted,
  }
}

export const showOffDayRange = (offday: INullOrUndefined | IOffday) => {
  if (offday) {
    const { startDateFormatted, endDateFormatted } = offdayFormatted(offday)
    if (startDateFormatted !== endDateFormatted) {
      return `Driver Off from ${startDateFormatted} to ${endDateFormatted}`
    }
    return `Driver Off on ${startDateFormatted}`
  }
  return null
}
