/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useQueryCheckCompanyDetails } from '~/hooks/useQueryData'
import { usePayrollReportFormValuesProvider } from '~/contexts'

import {
  Button,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import W4ExemptStatusReportTable from './W4ExemptStatusReportTable'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { ApiClientRequestOption, apiClient } from '~/api/ApiClient'

function W4ExemptStatusReportForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const isMobile = useIsMobile()

  const { reportFormValues, onChangeReportFormValues } =
    usePayrollReportFormValuesProvider()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { yearOptions } = useQueryCheckCompanyDetails(
    currentCompany.checkUid as string,
  )

  const formData = useMemo(
    () => ({
      content: reportFormValues.content,
      year: yearOptions.length === 1 ? yearOptions[0].value : '',
    }),
    [reportFormValues.content, yearOptions],
  )

  const [summaryData, setSummaryData] = useState([])
  const [isShowTable, setIsShowTable] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'year',
        label: 'Year',
        type: EFieldType.singleSelect,
        size: isMobile ? 12 : 3,
        isRequired: true,
        options: yearOptions,
      },
      {
        name: 'content',
        label: 'Content',
        size: 12,
        type: EFieldType.radio,
        options: [
          {
            label: 'Table',
            value: 'table',
          },
          {
            label: 'CSV',
            value: 'csv',
          },
        ],
      },
    ],
    [isMobile, yearOptions],
  )

  const onSubmit = useCallback(() => {
    formRef.current.handleSubmit(async ({ content, ...formData }: any) => {
      try {
        const params: Partial<ApiClientRequestOption> = {
          query: {},
        }
        params.query.qsParams = {
          year: formData.year,
        }
        if (content === 'csv') {
          params.query.csv = true
          params.isCsv = true
        }
        onChangeReportFormValues({
          content,
        })
        const response = await apiClient.check.w4ExemptStatusReport(params)
        setSummaryData(response.results)
        setIsShowTable(true)
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [onChangeReportFormValues])

  return (
    <div>
      <ConcordFormStructure
        fields={fields}
        isHiddenCancelButton
        isHiddenSubmitButton
        ref={formRef}
        formData={formData}
      />
      <Button label='Submit' onClick={onSubmit} />
      <When condition={isShowTable}>
        <W4ExemptStatusReportTable data={summaryData} />
      </When>
    </div>
  )
}
export default W4ExemptStatusReportForm
