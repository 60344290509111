import {
  ConcordFormLayout,
  DeleteIcon,
  PlusIcon,
  RefreshIcon,
} from '~/components/shared'
import { Button, Form, InputGroup } from 'react-bootstrap'

import { v4 as uuidv4 } from 'uuid'

import type { IOrderExtraRangeFormData } from '~/types/models/IOrderExtraRange'

import './styles.scss'
import { produce } from 'immer'
import clsx from 'clsx'

export interface IOrderExtraRangesProps {
  orderExtraRanges: (Omit<IOrderExtraRangeFormData, 'id'> & {
    id: number | string
  })[]
  errors: Record<
    'qty' | 'startLoad' | 'endLoad',
    {
      message: string
    }
  >[]
  onChange: (
    orderExtraRanges: IOrderExtraRangesProps['orderExtraRanges'],
  ) => void
}

function OrderExtraRanges(props: IOrderExtraRangesProps) {
  const { orderExtraRanges, onChange, errors = [] } = props

  const onChangeLoad = (id: number | string, name: string) => (event: any) => {
    const value = event.target.value
    onChange(
      produce(orderExtraRanges, draft => {
        const index = draft.findIndex(({ id: itemId }) => itemId === id)
        if (draft[index]) {
          ;(draft[index] as any)[name] = value
        }
      }),
    )
  }

  const onDelete = (id: number | string) => () => {
    onChange(
      produce(orderExtraRanges, draft => {
        const index = draft.findIndex(({ id: itemId }) => itemId === id)
        if (index !== -1) {
          if (typeof draft[index].id === 'number') {
            ;(draft[index] as any)._destroy = true
          } else {
            draft.splice(index, 1)
          }
        }
      }),
    )
  }

  const onRefresh = (id: number | string) => () => {
    onChange(
      produce(orderExtraRanges, draft => {
        const index = draft.findIndex(({ id: itemId }) => itemId === id)
        if (index !== -1) {
          ;(draft[index] as any)._destroy = undefined
        }
      }),
    )
  }

  return (
    <ConcordFormLayout
      label='Ranges'
      extraIcons={[
        {
          Icon: <PlusIcon color='var(--ion-color-concord)' />,
          onClick: () => {
            onChange([
              ...orderExtraRanges,
              {
                id: uuidv4(),
                startLoad: 1,
                endLoad: null,
                orderExtraId: null,
                qty: null,
              },
            ])
          },
        },
      ]}
    >
      {orderExtraRanges.map(
        ({ id, startLoad, endLoad, qty, _destroy }, index) => (
          <div className='OrderExtraRanges__item' key={id}>
            <div>
              <InputGroup className={clsx({ isDisabled: _destroy })}>
                <InputGroup.Text id='startLoad'>Qty</InputGroup.Text>
                <Form.Control
                  aria-describedby='startLoad'
                  type='number'
                  value={qty as number}
                  onChange={onChangeLoad(id, 'qty')}
                  min={1}
                />
              </InputGroup>
              {errors[index]?.qty?.message && (
                <span className='error' style={{ fontSize: 11 }}>
                  {errors[index]?.qty?.message}
                </span>
              )}
            </div>
            <div>
              <InputGroup className={clsx({ isDisabled: _destroy })}>
                <InputGroup.Text id='startLoad'>Start Load</InputGroup.Text>
                <Form.Control
                  aria-describedby='startLoad'
                  type='number'
                  value={startLoad as number}
                  onChange={onChangeLoad(id, 'startLoad')}
                  min={1}
                />
              </InputGroup>
              {errors[index]?.startLoad?.message && (
                <span className='error' style={{ fontSize: 11 }}>
                  {errors[index]?.startLoad?.message}
                </span>
              )}
            </div>
            <div>
              <InputGroup className={clsx({ isDisabled: _destroy })}>
                <InputGroup.Text id='endLoad'>End Load</InputGroup.Text>
                <Form.Control
                  aria-describedby='endLoad'
                  type='number'
                  value={endLoad as number}
                  onChange={onChangeLoad(id, 'endLoad')}
                />
              </InputGroup>
              {errors[index]?.endLoad?.message && (
                <span className='error' style={{ fontSize: 11 }}>
                  {errors[index]?.endLoad?.message}
                </span>
              )}
            </div>
            <div>
              {_destroy ? (
                <Button
                  variant='success'
                  style={{ marginLeft: 4, minHeight: 30 }}
                  onClick={onRefresh(id)}
                >
                  <RefreshIcon color='white' />
                </Button>
              ) : (
                <Button
                  variant='danger'
                  style={{ marginLeft: 4, minHeight: 30 }}
                  onClick={onDelete(id)}
                >
                  <DeleteIcon color='white' />
                </Button>
              )}
            </div>
          </div>
        ),
      )}
    </ConcordFormLayout>
  )
}

export default OrderExtraRanges
