import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'

export const truckFleetsSlice = createSlice({
  name: 'truckFleets',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}
      action.payload.forEach(truck => {
        newState[truck.id] = truck
      })
      return newState
    },
    update: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      }
    },
    delete: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export const fetchTruckFleets = createAsyncThunk(
  'truckFleets/fetchTruckFleets',
  async (params = {}, { dispatch }) => {
    try {
      const { truckFleets } = await apiClient.truckFleets.get(params)
      dispatch(truckFleetsSlice.actions.index(truckFleets))
      return truckFleets
    } catch (error) {
      toast.error(error.message)
    }
  },
)
