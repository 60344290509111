var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
import { Box, makeStyles, Button, ButtonGroup } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown, ArrowDownward, ArrowUpward, } from '@material-ui/icons';
import clsx from 'clsx';
import { ID_DOCUMENT_PANEL_TOOLBAR__ROOT } from 'documentLabeler/constants/DocumentLabelerConstants';
var useStyles = makeStyles(function (theme) {
    return {
        Root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1.5),
        },
        Box: {
            position: 'relative',
            '&:not(:last-child)': {
                marginRight: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
                '&:after': {
                    position: 'absolute',
                    content: "''",
                    // top: 16,
                    right: 0,
                    width: 2.5,
                    height: 18,
                    backgroundColor: '#e5e5e5',
                },
            },
        },
        ZoomContainer: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
        },
        ZoomTitle: {
            marginTop: theme.spacing(0.5),
        },
        ZoomButtonsContainer: {},
        ZoomButton: {
            padding: 0,
            display: 'block',
            height: theme.spacing(2),
            minWidth: 35,
            border: 'none',
            '&:hover': {
                background: 'transparent',
                border: 'none',
            },
            '&:ntn-child(2)': {
                marginBottom: theme.spacing(0.5),
            },
            '& .DocumentPanelToolbar__zoomIcon': {
                cursor: 'pointer',
                color: theme.palette.common.black,
                padding: 0,
            },
            '&.disabled': {
                opacity: 0.5,
                userSelect: 'none',
                pointerEvents: 'none',
            },
        },
        ShowHidePdf: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 4,
            cursor: 'pointer',
        },
        ShowHidePdfLabel: {
            fontFamily: 'auto',
            lineHeight: 'initial',
            letterSpacing: 0,
        },
        ZoomIcon: {},
        ZoomCurrentValue: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
        ShowHideCheckbox: {
            '&:hover': {
                backgroundColor: 'transparent !important',
            },
        },
        ShowHideLabel: {},
        ShowHideIcon: {
            marginLeft: theme.spacing(1),
            width: 16,
            height: 16,
            color: theme.palette.common.black,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.common.black,
            borderRadius: '50%',
        },
    };
});
var DocumentPanelToolbar = function (props) {
    var onZoomIn = props.onZoomIn, onZoomOut = props.onZoomOut;
    var classes = useStyles();
    var _a = useBBConfiguration(), showToolbar = _a.showToolbar, toolbarProps = _a.toolbarProps;
    var _b = useDocumentLabeler(), state = _b.state, dispatch = _b.dispatch;
    var _c = state.localState, showPdf = _c.showPdf, pdfScale = _c.pdfScale;
    var handleChangeShowHidePdf = useCallback(function () {
        dispatch({
            type: 'setShowHidePdf',
            payload: !showPdf,
        });
        (toolbarProps === null || toolbarProps === void 0 ? void 0 : toolbarProps.onToggleShowPdf) && toolbarProps.onToggleShowPdf(!showPdf);
    }, [toolbarProps, showPdf]);
    var handleIncreaseScale = useCallback(function (event) {
        event.stopPropagation();
        dispatch({
            type: 'increasePdfScale',
        });
        onZoomIn && onZoomIn(event);
    }, []);
    var handleDecreaseScale = useCallback(function (event) {
        event.stopPropagation();
        dispatch({
            type: 'decreasePdfScale',
        });
        onZoomOut && onZoomOut(event);
    }, []);
    var renderShowHideLabelAndIcon = useCallback(function () {
        var label = showPdf ? 'Hide Document' : 'Show Document';
        var Icon = showPdf ? ArrowUpward : ArrowDownward;
        return (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: classes.ShowHideLabel }, { children: label })), _jsx(Icon, { className: classes.ShowHideIcon })] }));
    }, []);
    if (showToolbar) {
        return (_jsxs(Box, __assign({ id: ID_DOCUMENT_PANEL_TOOLBAR__ROOT, className: clsx(classes.Root, 'DocumentPanelToolbar__root') }, { children: [_jsx(Box, __assign({ className: clsx(classes.Box, classes.ShowHidePdf), onClick: handleChangeShowHidePdf }, { children: renderShowHideLabelAndIcon() })), showPdf && (_jsxs(Box, __assign({ className: clsx(classes.ZoomContainer, classes.Box, 'DocumentPanelToolbar__zoomContainer') }, { children: [_jsx("span", __assign({ className: classes.ZoomTitle }, { children: "Zoom" })), _jsx("span", __assign({ className: classes.ZoomCurrentValue }, { children: pdfScale.toFixed(1) })), _jsx(Box, __assign({ className: clsx(classes.ZoomButtonsContainer, 'DocumentPanelToolbar__zoomButtons') }, { children: _jsxs(ButtonGroup, __assign({ orientation: "vertical", color: "primary", disableRipple: true, "aria-label": "vertical outlined primary button group" }, { children: [_jsx(Button, __assign({ disableRipple: true, className: clsx('DocumentPanelToolbar__zoomButton', classes.ZoomButton), onClick: handleIncreaseScale }, { children: _jsx(ArrowDropUp, { className: clsx(classes.ZoomIcon, 'DocumentPanelToolbar__zoomIcon DocumentPanelToolbar__zoomUpIcon') }) })), _jsx(Button, __assign({ disableRipple: true, onClick: handleDecreaseScale, className: clsx('DocumentPanelToolbar__zoomButton', classes.ZoomButton) }, { children: _jsx(ArrowDropDown, { className: clsx(classes.ZoomIcon, 'DocumentPanelToolbar__zoomIcon DocumentPanelToolbar__zoomDownIcon') }) }))] })) }))] })))] })));
    }
    return _jsx("div", {});
};
export { DocumentPanelToolbar };
