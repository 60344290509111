import { createContext } from 'react'

import type { IOrderFormProviderValues } from './type'

const OrderFormContext = createContext<IOrderFormProviderValues>({
  onOpenOrderFormDialog() {},
  onCloseOrderFormDialog() {},
})

export default OrderFormContext
