import Immutable from 'immutable'
import * as types from '../actions/actionTypes'

export const initialState = Immutable.Map({
  userGroups: [],
  terminalAccesses: [],
})

const userAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_GROUPS: {
      const { userGroups } = action

      return state.set('userGroups', userGroups)
    }

    case types.FETCH_USER_TERMINAL_ACCESSES: {
      const { terminalAccesses } = action

      return state.set('terminalAccesses', terminalAccesses)
    }

    default: {
      return state
    }
  }
}

export default userAccessReducer
