import { createSlice } from '@reduxjs/toolkit'

const billLinesSlice = createSlice({
  name: 'billLines',
  initialState: {},
  reducers: {
    updateBillLines: (state, action) => {
      action.payload.billLines.forEach(billLine => {
        state[billLine.id] = { ...billLine }
      })

      return state
    },
    updateBillLine: (state, action) => {
      state[action.payload.id] = action.payload
    },
    deleteBillLine: (state, action) => {
      delete state[action.payload.id]
    },
  },
})

export default billLinesSlice
