export const parseBuyerTerminals = dashboardData => {
  const buyerTerminals = []

  for (const key in dashboardData) {
    const newKey = key.slice(1, key.length - 1).split(',')

    buyerTerminals.push(newKey[0])
  }

  return buyerTerminals
}

export default parseBuyerTerminals
