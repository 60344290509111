import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryAreas,
  useQueryMarkets,
  useQueryMinorMarkets,
  useQueryRegions,
  useQueryTerminalGroups,
  useQueryZones,
} from '~/hooks/useQueryData'

import {
  useInitializeCompaniesStore,
  useInitializeReduxTerminals,
} from './useInitializeRedux/stores'

import {
  selectBuyerOptionsWithCurrentCompany,
  selectBuyerTerminalOptions,
  selectSellerOptionsWithCurrentCompany,
  selectSellerTerminalOptions,
} from '~/redux/selectors'

import {
  EAllowedHierarchyBuyerSideType,
  EAllowedHierarchySellerSideType,
} from '~/types/enums/EAllowedHierarchy'
import { ICommonOption } from '~/types/models/ICommonModel'

const useGetSideOptions = () => {
  useInitializeCompaniesStore()
  useInitializeReduxTerminals()

  const sellerOptions = useSelector(selectSellerOptionsWithCurrentCompany)
  const buyerOptions = useSelector(selectBuyerOptionsWithCurrentCompany)
  const sellerTerminalOptions = useSelector(selectSellerTerminalOptions)
  const buyerTerminalOptions = useSelector(selectBuyerTerminalOptions)

  const { terminalGroupOptions } = useQueryTerminalGroups()
  const { marketOptions } = useQueryMarkets()
  const { minorMarketOptions } = useQueryMinorMarkets()
  const { regionOptions } = useQueryRegions()
  const { areaOptions } = useQueryAreas()
  const { zoneOptions } = useQueryZones()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSellerSideOptions = useCallback(
    (sideType: string): ICommonOption[] => {
      switch (sideType) {
        case EAllowedHierarchySellerSideType.Market: {
          return marketOptions
        }
        case EAllowedHierarchySellerSideType.MinorMarket: {
          return minorMarketOptions
        }
        case EAllowedHierarchySellerSideType.Region: {
          return regionOptions
        }
        case EAllowedHierarchySellerSideType.Seller: {
          return sellerOptions
        }
        case EAllowedHierarchySellerSideType.SellerTerminal: {
          return sellerTerminalOptions
        }
        case EAllowedHierarchySellerSideType.TerminalGroup: {
          return terminalGroupOptions
        }
        default:
          return []
      }
    },
    [
      marketOptions,
      minorMarketOptions,
      regionOptions,
      sellerOptions,
      sellerTerminalOptions,
      terminalGroupOptions,
    ],
  )

  const getBuyerSideOptions = useCallback(
    (sideType: string): ICommonOption[] => {
      switch (sideType) {
        case EAllowedHierarchyBuyerSideType.Area: {
          return areaOptions
        }
        case EAllowedHierarchyBuyerSideType.Buyer: {
          return buyerOptions
        }
        case EAllowedHierarchyBuyerSideType.BuyerTerminal: {
          return buyerTerminalOptions
        }
        case EAllowedHierarchyBuyerSideType.Zone: {
          return zoneOptions
        }
        default:
          return []
      }
    },
    [areaOptions, buyerOptions, buyerTerminalOptions, zoneOptions],
  )

  return {
    getSellerSideOptions,
    getBuyerSideOptions,
  }
}

export default useGetSideOptions
