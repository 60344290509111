import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import {
  ConcordFormStructure,
  IConcordFormField,
  IParserColumnMappingFormProps,
} from '~/components/shared'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { IParserColumnMappingFormData } from '~/types/models/IParserColumnMapping'
import {
  PARSER_COLUMN_COLUMN_OPTIONS,
  PARSER_COLUMN_MODEL_OPTIONS,
} from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'

function ParserColumnMappingForm(props: IParserColumnMappingFormProps) {
  const { formData, afterCreate, afterUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const { allCompaniesWithCurrentCompany, isLoadingBuyerSellers } =
    useQueryBuyerSellers()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isUpdating = Boolean(formData?.id)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'companyId',
        label: 'Company',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: allCompaniesWithCurrentCompany.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
        })),
        isLoading: isLoadingBuyerSellers,
      },
      {
        name: 'model',
        label: 'Model',
        type: EFieldType.singleSelect,
        options: PARSER_COLUMN_MODEL_OPTIONS,
        isRequired: true,
      },
      {
        name: 'sourceColumns',
        label: 'Source Columns',
        type: EFieldType.multipleSelect,
        options: PARSER_COLUMN_COLUMN_OPTIONS,
        isRequired: true,
      },
      {
        name: 'destinationColumn',
        label: 'Destination Column',
        type: EFieldType.singleSelect,
        options: PARSER_COLUMN_COLUMN_OPTIONS,
        isRequired: true,
      },
      {
        name: 'regexp',
        label: 'Regexp',
        isRequired: true,
      },
      {
        name: 'headerMatchingArray',
        label: 'Header Matching Array',
        isRequired: true,
        type: EFieldType.tags,
        placeholder: 'Press Enter to add an item',
      },
      {
        name: 'arrayValueDelimiter',
        label: 'Array Value Delimiter',
      },
    ],
    [allCompaniesWithCurrentCompany, isLoadingBuyerSellers],
  )

  const defaultValues: IParserColumnMappingFormData = {
    companyId: currentCompany?.id,
    model: null,
    sourceColumns: [],
    destinationColumn: '',
    regexp: '',
    headerMatchingArray: [],
    arrayValueDelimiter: '',
  }

  const schema = Yup.object({
    model: Yup.string()
      .required('Model is required!')
      .typeError('Model is required!'),
    sourceColumns: Yup.array().min(1, 'Source columns is required'),
    destinationColumn: Yup.string()
      .required('Model is required!')
      .typeError('Model is required!'),
    regexp: Yup.string()
      .required('Model is required!')
      .typeError('Model is required!'),
    headerMatchingArray: Yup.array().min(1, 'Source columns is required'),
  })

  const create = async (formValues: IParserColumnMappingFormData) => {
    const response = await apiClient.parserColumnMappings.create({
      parserColumnMapping: formValues,
    })
    console.log('response', response)
    const errorKeys = Object.keys(response.errors || {})
    if (errorKeys.length > 0) {
      const message = response.errors[errorKeys[0]][0]
      const key = errorKeys[0]
      setBackendError(`${_.startCase(key)}: ${message}`)
    } else {
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    }
  }

  const update = async (formValues: IParserColumnMappingFormData) => {
    const payload = _.pick(formValues, [
      'model',
      'sourceColumns',
      'destinationColumn',
      'regexp',
      'headerMatchingArray',
      'arrayValueDelimiter',
    ])
    const response = await apiClient.parserColumnMappings.update(
      formData?.id as number,
      {
        parserColumnMapping: payload,
      },
    )
    const errorKeys = Object.keys(response.errors)
    if (errorKeys.length > 0) {
      const message = response.errors[errorKeys[0]][0]
      const key = errorKeys[0]
      setBackendError(`${_.startCase(key)}: ${message}`)
    } else {
      afterUpdate && afterUpdate(response)
      toast.success(toastMessages.updateSuccess)
    }
  }

  const onSubmitForm = async (formValues: IParserColumnMappingFormData) => {
    setIsLoading(true)
    setBackendError('')
    try {
      if (isUpdating) {
        await update(formValues)
      } else {
        await create(formValues)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      onSubmit={onSubmitForm}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default ParserColumnMappingForm
