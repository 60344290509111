import { useState } from 'react'
import { TestResultForm } from '../../TestResult/TestResultForm'
import { ITestResult } from '~/types/models/ITestResult'
import useQueryTestResults from '~/hooks/useQueryData/useQueryTestResults/useQueryTestResults'
import { IonButton } from '@ionic/react'

interface Props {
  sellerProductId?: number
}

export const TestResultsList = ({ sellerProductId }: Props) => {
  const [isCreating, setIsCreating] = useState(false)

  const { testResults, updateTestResult, addTestResult } = useQueryTestResults(
    { filters: { sellerProductId } },
    { enabled: !!sellerProductId },
  )

  const handleUpdateTestResult = (testResult: ITestResult) => {
    updateTestResult(testResult.id, testResult)
  }

  const handleCreateTestResult = (testResult: ITestResult) => {
    addTestResult(testResult)
    setIsCreating(false)
  }

  return (
    <div>
      {testResults.map(testResult => (
        <div key={testResult.id}>
          <TestResultForm
            testResult={testResult}
            sellerProductId={sellerProductId}
            onUpdateTestResult={handleUpdateTestResult}
            onCreateTestResult={addTestResult}
            key={testResult.id}
          />
          <hr className='m-0' />
        </div>
      ))}
      {isCreating && (
        <TestResultForm
          sellerProductId={sellerProductId}
          onCreateTestResult={handleCreateTestResult}
        />
      )}

      <IonButton
        expand='full'
        color={isCreating ? 'danger' : 'success'}
        onClick={() => setIsCreating(!isCreating)}
      >
        {isCreating ? 'Cancel' : 'Add Test Result'}
      </IonButton>
    </div>
  )
}
