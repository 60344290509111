export enum EBuyerSellerRegexpModel {
  Load = 'Load',
  Invoice = 'Invoice',
  BillLine = 'BillLine',
}

export const buyerSellerRegexpModelOptions = Object.keys(
  EBuyerSellerRegexpModel,
).map((key, index) => ({
  value: index,
  label: key,
}))

export enum EBuyerSellerRegexpField {
  seller = 'seller',
  seller_terminal = 'seller_terminal',
  seller_product = 'seller_product',
  buyer_terminal = 'buyer_terminal',
  fleet = 'fleet',
  date = 'date',
  ticket_num = 'ticket_num',
  ticket_time = 'ticket_time',
  load_time = 'load_time',
}
