import { useCompanyInfo } from './useCompanyInfo'

import { IonText } from '@ionic/react'
import { CompanyAvatar, GoogleSearchCompanyLogo } from '~/components/shared'

import clsx from 'clsx'
import { EScope } from '~/types/enums/ECommonEnum'

import type { ICompanyInfoProps } from './type'

import './styles.scss'

const CompanyInfo: React.FC<ICompanyInfoProps> = props => {
  const {
    company,
    companyType = EScope.buyer,
    defaultCompanyCode,
    searchableGoogle,
    hideSubtitle,
    className,
    isHiddenInfo,
    onClickCompanyName,
    style,
    ...avatarProps
  } = props

  const { renderAnchor } = useCompanyInfo(props)

  return (
    <div className={clsx('CompanyInfo__container', className)} style={style}>
      <CompanyAvatar
        companyType={companyType}
        showImagePopup
        company={company}
        {...avatarProps}
      />
      {isHiddenInfo !== true && (
        <div className='CompanyInfo__info'>
          <span className='CompanyInfo__companyName'>
            <IonText
              className='CompanyInfo__name'
              style={{ marginRight: 4 }}
              onClick={onClickCompanyName}
            >
              {renderAnchor()}
            </IonText>
            {!company?.image && company?.value && searchableGoogle && (
              <GoogleSearchCompanyLogo companyName={company?.name} />
            )}
          </span>
          {!hideSubtitle && (
            <IonText
              color={companyType.toString()}
              className='CompanyInfo__subtitle'
            >
              {company?.code || defaultCompanyCode}
            </IonText>
          )}
        </div>
      )}
    </div>
  )
}

CompanyInfo.defaultProps = {
  defaultCompanyCode: 'Company',
  searchableGoogle: true,
  hideAnchor: false,
  hideSubtitle: false,
  unknownText: 'Unknown',
  isHiddenInfo: false,
}

export default CompanyInfo
