import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

import {
  ConcordFormRadio,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryOrderExtraRanges,
  useQuerySchedules,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'

import { IOrderExtraFormProps } from './type'
import { IOrderExtraFormData } from '~/types/models/IOrderExtra'
import { OrderExtraRanges } from './OrderExtraRanges'
import _ from 'lodash'
import { IOrderExtraRangeFormData } from '~/types/models/IOrderExtraRange'

const OrderExtraForm = (props: IOrderExtraFormProps) => {
  const { afterCreate, afterUpdate, formData, orderId, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState<'order' | 'schedule'>('order')

  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { orderExtraRanges, isOrderExtraRangesLoading } =
    useQueryOrderExtraRanges(
      {
        filters: {
          orderExtraId: [formData?.id] as number[],
        },
      },
      { enabled: Boolean(formData?.id) },
    )

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const { scheduleOptions, isLoadingSchedulesData } = useQuerySchedules(
    {
      filters: {
        orderId: [orderId],
      },
    },
    { enabled: Boolean(orderId) },
  )

  const areRangesOverlapping = (
    range1: IOrderExtraRangeFormData,
    range2: IOrderExtraRangeFormData,
  ) => {
    if (
      range1.startLoad === null ||
      range1.endLoad === null ||
      range2.startLoad === null ||
      range2.endLoad === null
    ) {
      return false
    }
    return (
      range1.startLoad <= range2.endLoad && range1.endLoad >= range2.startLoad
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasOverlappingRanges = (ranges: IOrderExtraRangeFormData[]) => {
    for (let i = 0; i < ranges.length; i++) {
      if (ranges[i]._destroy) continue
      for (let j = i + 1; j < ranges.length; j++) {
        if (ranges[j]._destroy) continue
        if (areRangesOverlapping(ranges[i], ranges[j])) {
          return true
        }
      }
    }
    return false
  }

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'type',
        type: EFieldType.radio,
        render({ setValue }) {
          console.log('type', type)
          return (
            <ConcordFormRadio
              label='Type'
              value={type}
              options={[
                {
                  label: 'Order',
                  value: 'order',
                },
                {
                  label: 'Schedule',
                  value: 'schedule',
                },
              ]}
              onChange={(event, value) => {
                setType(value)
                setValue('scheduleId', null)
              }}
            />
          )
        },
      },
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        options: sellerProductOptions,
        isRequired: true,
        type: EFieldType.singleSelect,
        size: 12,
      },
      {
        name: 'scheduleId',
        label: 'Schedule',
        options: [
          {
            label: 'Not set',
            value: null,
          },
          ...scheduleOptions,
        ],
        isLoading: isLoadingSchedulesData,
        type: EFieldType.singleSelect,
        size: 12,
        isHidden: type === 'order',
        isRequired: true,
      },
      {
        name: 'Range',
        isLoading: isOrderExtraRangesLoading,
        render({ watch, setValue, errors }) {
          const orderExtraRanges = watch('orderExtraRangesAttributes', [])

          return (
            <OrderExtraRanges
              orderExtraRanges={orderExtraRanges}
              onChange={newData => {
                setValue('orderExtraRangesAttributes', newData as any)
              }}
              errors={errors?.orderExtraRangesAttributes || []}
            />
          )
        },
      },
    ],
    [
      isLoadingSchedulesData,
      isOrderExtraRangesLoading,
      scheduleOptions,
      sellerProductOptions,
      type,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerProductId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        scheduleId: Yup.lazy(() => {
          if (type === 'schedule') {
            return Yup.number()
              .required('This field is required!')
              .typeError('This field is required!')
          }
          return Yup.number().nullable()
        }),
        orderExtraRangesAttributes: Yup.array().of(
          Yup.object().shape({
            qty: Yup.number()
              .required('This field is required!')
              .typeError('Should be number')
              .min(1, 'Minimum is 1'),
            startLoad: Yup.number()
              .required('This field is required!')
              .typeError('Should be number')
              .min(1, 'Minimum is 1'),
            endLoad: Yup.number()
              .nullable()
              .transform(num => {
                if (isNaN(num)) {
                  return null
                }
                return num
              })
              .test('positive-end-load', 'Should be positive', val => {
                if (val) {
                  return val > 0
                }
                return true
              })
              .test(
                'end-load-is-greater-than-start-load',
                'Should be greater than Start load',
                (val, { parent }) => {
                  if (!val) {
                    return true
                  }
                  return (val || 0) >= parent.startLoad
                },
              ),
          }),
        ),
      }),
    [type],
  )

  const defaultValues = useMemo<IOrderExtraFormData>(
    () => ({
      orderId: null,
      sellerProductId: null,
      scheduleId: null,
    }),
    [],
  )

  const createOrderExtra = useCallback(
    async (payload: IOrderExtraFormData) => {
      const { errors, ...res } = await apiClient.orderExtras.create({
        orderExtra: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const updateOrderExtra = useCallback(
    async (payload: IOrderExtraFormData) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.orderExtras.update(
          formData.id,
          {
            orderExtra: _.pick(payload, [
              'orderExtraRangesAttributes',
              'sellerProductId',
              'scheduleId',
            ]),
          },
        )

        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IOrderExtraFormData) => {
      if (hasOverlappingRanges(formValues.orderExtraRangesAttributes || [])) {
        setError('Ranges cannot overlap')
        return
      }

      setIsLoading(true)
      setError('')

      const payload: any = {
        ...formValues,
      }

      payload.orderExtraRangesAttributes = (
        formValues.orderExtraRangesAttributes || []
      ).map(({ id, startLoad, endLoad, _destroy, qty }) => ({
        id: typeof id === 'number' ? id : undefined,
        startLoad,
        endLoad,
        orderExtraId: formValues.id || undefined,
        _destroy,
        qty,
      }))

      try {
        if (isUpdating) {
          await updateOrderExtra(payload)
        } else {
          await createOrderExtra(payload)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [createOrderExtra, hasOverlappingRanges, isUpdating, updateOrderExtra],
  )

  useEffect(() => {
    if (!isUpdating) {
      formRef.current?.setValue('orderId', orderId)
      setType('order')
    } else {
      if (formData?.scheduleId) {
        setType('schedule')
      } else {
        setType('order')
      }
    }
  }, [formData, isUpdating, orderId])

  useDeepCompareEffect(() => {
    if (isUpdating) {
      formRef.current?.setValue('orderExtraRangesAttributes', orderExtraRanges)
    }
  }, [orderExtraRanges, isUpdating])

  useEffect(() => {
    if (scheduleOptions.length === 1 && type === 'schedule') {
      formRef.current?.setValue('scheduleId', scheduleOptions[0].value)
    }
  }, [type, scheduleOptions])

  return (
    <ConcordFormStructure
      error={error}
      isLoading={isLoading}
      ref={formRef}
      formData={formData}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
      {...formProps}
    />
  )
}

export default OrderExtraForm
