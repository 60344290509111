import { components } from 'react-select'
import { Button } from 'react-bootstrap'
import { CompanyAvatar, EditIcon } from '~/components/shared'

export const EditAvatarOption = onClickEdit => props => {
  const { data } = props

  const onClick = e => {
    e.stopPropagation()
    onClickEdit && onClickEdit(props.data.value)
  }

  return (
    <components.Option
      {...props}
      className='d-flex flex-row align-items-center'
    >
      <CompanyAvatar
        company={{
          value: data?.value,
          label: data?.label,
          image: data?.image,
          name: data?.label,
        }}
      />
      &nbsp;
      {data?.label}
      <Button onClick={onClick} variant='light' className='ms-auto'>
        <EditIcon />
      </Button>
    </components.Option>
  )
}
