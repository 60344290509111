import React, { useCallback } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import { ToolTipOverlay } from '~/components/shared'
// import { sessionService } from 'redux-react-session';
import PropTypes from 'prop-types'
import { apiClient } from '~/api/ApiClient'

const XmlExportButton = props => {
  const {
    id,
    status,
    saveInvoice,
    onGetParamsFilter,
    title,
    iconProps,
    className,
  } = props

  const { isMobileScreen } = useBreakpoint()

  const onXmlClick = useCallback(async () => {
    if (status === 'Approved') {
      saveInvoice({ id, status: 'Exported' })
    }
    apiClient.invoices.exportXml(onGetParamsFilter())
  }, [id, status, saveInvoice, onGetParamsFilter])

  return (
    <ToolTipOverlay
      content={title}
      placement='bottom'
      allowToShow={isMobileScreen}
    >
      <IonButton
        onClick={onXmlClick}
        fill='outline'
        color='export'
        style={{ height: 40 }}
        className={className}
      >
        {isMobileScreen && <IonIcon icon={cloudDownloadOutline} />}
        {!isMobileScreen && (
          <>
            <IonIcon
              color='export'
              icon={cloudDownloadOutline}
              slot='start'
              {...iconProps}
            />
            <span style={{ color: '#7044ff' }}>{title}</span>
          </>
        )}
      </IonButton>
    </ToolTipOverlay>
  )
}

XmlExportButton.propTypes = {
  id: PropTypes.number,
  status: PropTypes.oneOf(['', 'Approved']),
  saveInvoice: PropTypes.func,
  onGetParamsFilter: PropTypes.func,
  title: PropTypes.string,
  iconProps: PropTypes.object,
}

XmlExportButton.defaultProps = {
  title: 'Export to Xml',
  iconProps: {},
}

export default XmlExportButton
