import useDriverAvatar from './useDriverAvatar'

import { CompanyInfo } from '~/components/shared'

import { EScope } from '~/types/enums/ECommonEnum'

import type { IDriverAvatarProps } from './type'

function DriverAvatar(props: IDriverAvatarProps) {
  const {
    fullName,
    onRightClickAvatar,
    infoProps,
    toolTipMessage,
    onOpenWorkerFormData,
    driverFormData,
    driverFleet,
  } = useDriverAvatar(props)

  return (
    <CompanyInfo
      company={{ label: fullName as string, name: fullName }}
      tooltipMessage={toolTipMessage}
      onContextMenu={onRightClickAvatar}
      companyType={EScope.driver}
      color={driverFleet?.color}
      onClick={event => {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
        if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
          if (driverFormData) {
            onOpenWorkerFormData(driverFormData)
          }
        }
      }}
      {...infoProps}
    />
  )
}

export default DriverAvatar
