import clsx from 'clsx'
import { useController } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

export const FloatingInput = props => {
  const {
    name,
    label,
    control,
    inputType,
    className,
    isReadOnly,
    style,
    disabled,
    handleBlur,
  } = props

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const redStyle = {
    height: 41,
    backgroundColor: '#f1f1f154',
    ...style,
    ...(error && { borderColor: 'red' }),
  }

  const onBlurHandler = () => {
    onBlur()
    handleBlur && handleBlur()
  }

  return (
    <FloatingLabel
      controlId={name}
      style={error && { color: 'red' }}
      label={label}
    >
      <Form.Control
        className={clsx(className, { isReadOnly })}
        placeholder=''
        type={inputType}
        onChange={e => onChange(e.target.value)}
        style={redStyle}
        value={value}
        onBlur={onBlurHandler}
        disabled={disabled}
        {...props}
      />
    </FloatingLabel>
  )
}
