import { createSlice } from '@reduxjs/toolkit'

export const driverUiSlice = createSlice({
  name: 'driver',
  initialState: {
    currentLoad: {},
    loading: undefined,
    updating: false,
    pastLoad: {}, //[],
    dataLoaded: false,
    // page: 1,
    error: false,
    harUrlParams: false,
    // selectedSellerTerminal: undefined,
    // showSellerTerminalForm: false,
    // sellerTerminalFormMode: null,
    // selectedBuyerTerminal: undefined,
    // showBuyerTerminalForm: false,
    // buyerTerminalFormMode: null,
    // showProductForm: false,
    // productFormMode: false,
    // selectedProduct: undefined,
    selectedLoadId: null,
    formData: null,
    editLoadValue: { isEdit: false, loadId: '' },
    deleteObject: {},
    // loads: [],
    // sortOptions: [],
  },
  reducers: {
    updateDriverUi: (state, action) => {
      state[action.payload.id] = action.payload
    },
    updateLoad: (state, action) => {
      state.pastLoad = state.currentLoad
      state.currentLoad = action.payload
    },
  },
})
