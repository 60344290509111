import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure, ConcordFormMultipleDropdownSelector } from '~/components/shared'

import _ from 'lodash'
import { selectSessionUser } from '~/redux/selectors'
import { FORM_FIELD_TYPE } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'

const STATUS_OPTIONS = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Pending', value: 'Pending' },
]

const UserAccessForm = (props) => {
  const { afterUpdate, formData, ...formProps } = props

  const sessionUser = useSelector(selectSessionUser)

  const [userGroups, setUserGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [allowedViewsValue, setAllowedViewsValue] = useState([])

  const isAdminGroup = useMemo(
    () => sessionUser?.userGroup?.id,
    [sessionUser?.userGroup?.id],
  )

  const userGroupOptions = useMemo(
    () =>
      userGroups.map(({ id, name, code }) => ({
        value: id,
        label: `${code} - ${name}`,
      })),
    [userGroups],
  )

  const handleChangeAllowedViews = useCallback(
    (event, { value }) => {
      setAllowedViewsValue(value)
    },
    [],
  )

  const allowViewOptions = useMemo(() => {
    return (sessionUser?.scopeOptions || []).map((scope) => ({
      value: scope,
      label: _.upperFirst(scope)
    }))
  }, [sessionUser?.scopeOptions])

  const fields = useMemo(
    () => [
      {
        name: 'userGroupId',
        label: 'User Groups',
        options: userGroupOptions,
        isHidden: isAdminGroup,
        type: FORM_FIELD_TYPE.singleSelect,
        isRequired: true,
      },
      {
        name: 'status',
        label: 'Status',
        options: STATUS_OPTIONS,
        type: FORM_FIELD_TYPE.singleSelect,
      },
      {
        name: 'allowedViews',
        label: 'Allowed Views',
        type: FORM_FIELD_TYPE.custom,
        render({ label }) {
          return (
            <ConcordFormMultipleDropdownSelector
              label={label}
              isClearable={false}
              options={allowViewOptions}
              value={allowedViewsValue}
              removable={allowedViewsValue.length > 1}
              onChange={handleChangeAllowedViews}
            />
          )
        }
      },
      {
        name: 'default',
        label: 'Default',
        type: FORM_FIELD_TYPE.radio,
        options: [
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ],
      },
      {
        name: 'receiveOrderEmails',
        label: 'Receive Order Emails',
        type: FORM_FIELD_TYPE.radio,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
      },
    ],
    [
      allowViewOptions,
      allowedViewsValue,
      handleChangeAllowedViews,
      isAdminGroup,
      userGroupOptions
    ],
  )

  const defaultValues = useMemo(
    () => ({
      receiveOrderEmails: false,
      default: 'Yes',
      disallowedScopes: [],
      status: 'Active',
      userGroupId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        userGroupId: Yup.number()
          .test('requiredUserGroupId', 'User Group ID is required', (value) => {
            if (allowViewOptions.length <= 1) {
              return true
            }

            return typeof value === 'number'
          })
          .nullable(),
      }),
    [allowViewOptions.length],
  )

  const getUserGroups = useCallback(async () => {
    const { userGroups } = await apiClient.userGroups.get()
    setUserGroups(userGroups)
  }, [])

  const getDisallowedScopes = useCallback(
    () => {
      const disallowedScopes = []
      allowViewOptions.forEach(({ value }) => {
        if (allowedViewsValue.includes(value) === false) {
          disallowedScopes.push(value)
        }
      })

      return disallowedScopes
    },
    [allowViewOptions, allowedViewsValue],
  )

  const handleSubmit = useCallback(async formValues => {
    setIsLoading(true)
    try {
      const userAccessId = formData?.id
      const disallowedScopes = getDisallowedScopes()

      const { userAccess } = await apiClient.userAccesses.update(
        userAccessId,
        {
          ...formValues,
          disallowedScopes
        },
      )
      afterUpdate && afterUpdate(userAccess)
      toast.success(toastMessages.updateSuccess)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
    setIsLoading(false)
  }, [afterUpdate, formData?.id, getDisallowedScopes])

  useEffect(() => {
    getUserGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const values = formData || defaultValues
    const allowedViews = []

    sessionUser?.scopeOptions?.forEach((scope) => {
      if (!values.disallowedScopes?.includes(scope)) {
        allowedViews.push(scope)
      }
    })
    setAllowedViewsValue(allowedViews)
  }, [defaultValues, formData, sessionUser?.scopeOptions])

  return (
    <ConcordFormStructure
      {...formProps}
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      isHiddenCancelButton
      onSubmit={handleSubmit}
      schema={schema}
      isLoading={isLoading}
    />
  )
}

export default UserAccessForm
