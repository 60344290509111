import { useEffect } from 'react'
import { useTrucks } from '~/hooks/useTrucks'

export const useInitializeTrucks = () => {
  const { fetchTrucks, fetchTruckFleets } = useTrucks()

  useEffect(() => {
    fetchTrucks()
    fetchTruckFleets()
  }, [fetchTruckFleets, fetchTrucks])
}
