import { useState, useCallback, useMemo, useEffect } from 'react'

import { DateRangePicker } from 'react-date-range'
import { Dropdown, Button } from 'react-bootstrap'

import clsx from 'clsx'
import { getTitleRange } from '~/utils/getRangeDate'

import './DateRangeDropdownPicker.scss'

function DateRangeDropdownPicker(props) {
  const {
    date,
    label,
    onChange,
    className,
    children,
    showDropdown: showDropdownProps,
    onToggleDropdown,
    ...datePickerProps
  } = props

  const [dateValue, setDateValue] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)

  const labelRendered = useMemo(() => {
    return getTitleRange(date)
  }, [date])

  const handleChangeDate = useCallback(selectedDate => {
    setDateValue(selectedDate.selection)
  }, [])

  const handleToggleDropdown = useCallback(
    nextShow => {
      setShowDropdown(nextShow)
      onToggleDropdown && onToggleDropdown(nextShow)
      if (nextShow === false) {
        setDateValue(date)
      }
    },
    [date, onToggleDropdown],
  )

  const handleOk = useCallback(() => {
    onChange && onChange(dateValue)
    setShowDropdown(false)
  }, [dateValue, onChange])

  const handleResetDate = useCallback(() => {
    setDateValue({
      ...date,
      key: 'selection',
    })
  }, [date])

  const renderLabelButton = useCallback(() => {
    if (children) {
      return children
    }

    return (
      <>
        <span>{label ? `${label}: ` : ''}</span>
        <div className='DateRangeDropdownPicker__filterLabel'>
          {labelRendered}
        </div>
      </>
    )
  }, [children, label, labelRendered])

  useEffect(() => {
    setDateValue({
      ...date,
      key: 'selection',
    })
  }, [date])

  useEffect(() => {
    if (typeof showDropdownProps === 'boolean') {
      setShowDropdown(showDropdownProps)
    }
  }, [showDropdownProps])

  return (
    <Dropdown
      className={clsx('DateRangeDropdownPicker__root', className)}
      show={showDropdown}
      onToggle={handleToggleDropdown}
    >
      <Dropdown.Toggle className='DateRangeDropdownPicker__labelButton'>
        {renderLabelButton()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <DateRangePicker
          {...datePickerProps}
          ranges={[dateValue]}
          onChange={handleChangeDate}
          className=''
        />
        <div className='DateRangeDropdownPicker__buttons'>
          <Button variant='danger' onClick={() => handleToggleDropdown(false)}>
            Close
          </Button>
          <Button variant='warning' onClick={handleResetDate}>
            Reset
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
DateRangeDropdownPicker.defaultProps = {
  // label: 'Date',
}

export default DateRangeDropdownPicker
