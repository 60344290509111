import React, { PropsWithChildren, useMemo } from 'react'

import { Form, Spinner } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

import clsx from 'clsx'
import { addCircleOutline } from 'ionicons/icons'

import type { IConcordFormLayoutProps } from './type'

import './styles.scss'
import _ from 'lodash'

function ConcordFormLayout(
  props: PropsWithChildren<IConcordFormLayoutProps>,
  ref: any,
) {
  const {
    label,
    children,
    className,
    error,
    isDisabled,
    isRequired,
    isLoading,
    onClickCreateIcon,
    isHiddenCreateIcon = true,
    extraIcons = [],
    hint,
    style,
  } = props

  const renderExtraIcons = useMemo(
    () =>
      extraIcons
        .filter(({ isHidden }) => !isHidden)
        .map(({ onClick, color, icon, size, tooltipProps, Icon }, index) => (
          <ToolTipOverlay key={index} {...tooltipProps}>
            <span
              className='ConcordFormLayout__createIcon icon'
              onClick={onClick}
            >
              {Icon}
              {icon && <IonIcon color={color} icon={icon} size={size} />}
            </span>
          </ToolTipOverlay>
        )),
    [extraIcons],
  )

  return (
    <Form.Group
      className={clsx('ConcordFormLayout__container', className, {
        hasError: Boolean(error),
        isDisabled,
      })}
      style={style}
      ref={ref}
    >
      {!_.isNil(label) && (
        <Form.Label className='ConcordFormLayout__label'>
          <span>{label}</span>
          {isRequired && (
            <span className='ConcordFormLayout__asterisk'>&nbsp;*</span>
          )}
          {isHiddenCreateIcon !== true && (
            <span
              className='ConcordFormLayout__createIcon icon'
              onClick={onClickCreateIcon}
            >
              <IonIcon color='concord' icon={addCircleOutline} />
            </span>
          )}
          {renderExtraIcons}
          {isLoading && (
            <Spinner
              className='spinner'
              animation='border'
              variant='secondary'
            />
          )}
        </Form.Label>
      )}
      {children}
      {hint && <div className='hintText'>{hint}</div>}
    </Form.Group>
  )
}

export default React.forwardRef(ConcordFormLayout)
