import { useCallback, useMemo } from 'react'
import { useTruckFormProvider } from '~/contexts'

import type { ITruckNumberSectionProps } from './type'
import { useQueryTerminals, useQueryTruckFleets } from '~/hooks/useQueryData'
import buildObjectName from '~/utils/buildObjectName'

const useTruckNumberSection = (props: ITruckNumberSectionProps) => {
  const { truckId } = props

  const { truckFleetsData } = useQueryTruckFleets()

  const { findTerminalById } = useQueryTerminals()

  const { onOpenTruckForm } = useTruckFormProvider()

  const truckFleet = useMemo(
    () => truckFleetsData.find(tf => tf.truckId === truckId),
    [truckFleetsData, truckId],
  )

  const truck = truckFleet?.truck

  const parkTerminal = findTerminalById(truckFleet?.truck?.parkTerminalId)
  const terminal = findTerminalById(truckFleet?.terminalId)

  const truckTooltipContent = useMemo(() => {
    return (
      <div>
        <div>
          {terminal && terminal.id === parkTerminal?.id
            ? 'Park & Current Terminal'
            : 'Current Terminal'}
          : {buildObjectName(terminal)}
        </div>
        {parkTerminal && parkTerminal.id !== terminal?.id ? (
          <div>Park Terminal: {buildObjectName(parkTerminal)}</div>
        ) : null}
      </div>
    )
  }, [parkTerminal, terminal])

  const onRightClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (truck) {
        event.preventDefault()
        onOpenTruckForm(
          {
            truck,
            truckFleet: truckFleet || null,
          },
          {
            defaultTab: 'truckFleet',
          },
        )
      }
    },
    [onOpenTruckForm, truck, truckFleet],
  )

  return {
    truck,
    onRightClick,
    truckTooltipContent,
  }
}

export default useTruckNumberSection
