import { CommonDialogV2 } from '~/components/shared'

import './styles.scss'
import { IDialogWorkPayTypesProps } from './type'
import WorkPayTypeForm from './WorkPayTypeForm'
import { useMemo } from 'react'
import { IWorkPayType } from '~/types/models/IWorkPayType'

function DialogWorkPayTypeForm(props: IDialogWorkPayTypesProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    companyId,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Work Pay Type'
      isHiddenOkButton
      onClose={onClose}
    >
      <WorkPayTypeForm
        formData={formData}
        companyId={companyId}
        afterCreate={(formData: IWorkPayType) => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={(formData: IWorkPayType) => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
export default DialogWorkPayTypeForm
