var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { Box, makeStyles } from '@material-ui/core';
import { FieldsPanel } from 'documentLabeler/components/fieldsPanel/FieldsPanel';
import { DocumentPanel } from 'documentLabeler/components/documentPanel/DocumentPanel';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { TableLabeler } from 'documentLabeler/components/tableLabeler/TableLabeler';
import clsx from 'clsx';
import { ID_DOCUMENT_LABELER_CONTENT_ROOT } from 'documentLabeler/constants/DocumentLabelerConstants';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        overflow: 'auto',
    },
    FieldsPanelContainer: {
        width: function (_a) {
            var localState = _a.localState;
            return localState.showPdf ? theme.spacing(45) : '100%';
        },
        display: 'flex',
    },
    DocumentDisplayerContainer: {
        flex: 1,
        display: 'flex',
        padding: theme.spacing(0, 2),
    },
    Content: {
        display: 'flex',
    },
}); });
/**
 * Top level component containing the Embedded Document Labeler.  Takes in document info
 * as the data property, as well as an onSaveCallback to be executed when the save button
 * is clicked.  Handles positioning and rendering of the sub components
 * @param Props
 */
export var DocumentLabelerContent = function () {
    var state = useDocumentLabeler().state;
    var classes = useStyles(state);
    return (_jsx(Box, __assign({ id: ID_DOCUMENT_LABELER_CONTENT_ROOT, className: clsx(classes.Root, 'DocumentLabelerContent__root') }, { children: _jsxs(Box, __assign({ id: "DocumentLabelerContent__content", className: clsx(classes.Content, 'DocumentLabelerContent__content') }, { children: [state.localState.showPdf && (_jsx(Box, __assign({ className: clsx(classes.DocumentDisplayerContainer, 'DocumentLabelerContent__documentDisplayerContainer') }, { children: _jsx(DocumentPanel, {}) }))), _jsx(Box, __assign({ className: clsx(classes.FieldsPanelContainer, 'DocumentLabelerContent__fieldsPanelContainer') }, { children: _jsx(FieldsPanel, {}) })), state.localState.activeField &&
                    state.localState.activeField.type === FieldType.Table && (_jsx(TableLabeler, {}))] })) })));
};
