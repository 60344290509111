export enum EPaymentType {
  creditCard = 'credit_card',
  ach = 'ach',
  check = 'check',
  wire = 'wire',
  lockbox = 'lockbox',
  debitCard = 'debit_card',
  cash = 'cash',
  zelle = 'zelle',
  venmo = 'venmo',
}
