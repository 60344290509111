import { useState, useEffect, useCallback, useRef } from 'react'

import { Form } from 'react-bootstrap'
import { ToolTipOverlay } from '~/components/shared'
import KeyValueFormFieldLayout from '../FieldLayout'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './KeyValueFormTextField.scss'

const KeyValueFormTextField = props => {
  const {
    label,
    value,
    onChange,
    className,
    onConfirmUpdate,
    field,
    loading,
    editable,
    hideConfirmButton,
    error,
    ediable,
    ...inputProps
  } = props

  const inputRef = useRef()

  const [textValue, setTextValue] = useState('')

  const isEdited = _.toString(textValue) !== _.toString(value)

  useEffect(() => {
    setTextValue(value)
  }, [value])

  const handleChange = useCallback(
    event => {
      const { value: eventValue } = event.target
      setTextValue(eventValue)
      onChange && onChange(event, eventValue)
    },
    [onChange],
  )

  const handleConfirmUpdateValue = useCallback(
    event => {
      const newValue = {
        field,
        value: textValue,
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
      inputRef.current.blur()
    },
    [field, onConfirmUpdate, textValue],
  )

  const handleKeyPress = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleConfirmUpdateValue(event)
      } else if (event.keyCode === 27) {
        inputRef.current.blur()
      }
    },
    [handleConfirmUpdateValue],
  )

  return (
    <KeyValueFormFieldLayout
      hideConfirmButton={hideConfirmButton}
      label={label}
      loading={loading}
      editable={editable}
      renderValue={() => textValue}
      isEdited={isEdited}
      error={error}
      onConfirm={handleConfirmUpdateValue}
    >
      <ToolTipOverlay content={error} placement='bottom'>
        <Form.Control
          value={textValue}
          onChange={handleChange}
          className={clsx(
            'KeyValueFormFieldLayout__input KeyValueFormTextField__input',
            { error },
          )}
          onKeyDown={handleKeyPress}
          ref={inputRef}
          {...inputProps}
        />
      </ToolTipOverlay>
    </KeyValueFormFieldLayout>
  )
}

KeyValueFormTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onConfirmUpdate: PropTypes.func,
  field: PropTypes.string,
}

export default KeyValueFormTextField
