import { produce } from 'immer'
import * as types from '../actions/actionTypes'

export const initialState = {
  loading: false,
  companyDetail: undefined,
  error: undefined,
  updateSuccess: false,
  fleets: [],
  editingReferenceName: {},
  dataParser: {},
  allCompanies: [],
}

const myCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_SET_DATA_PARSER: {
      return produce(state, draft => {
        draft.dataParser = action.payload
      })
    }

    case types.EDIT_SUB_PART: {
      return {
        ...state,
        subPart: action.object,
      }
    }

    case types.EDIT_DATA_PARSER: {
      return {
        ...state,
        dataParser: action.object,
      }
    }
    case types.EDIT_COMPANY_FLEET: {
      return {
        ...state,
        companyFleet: action.object,
      }
    }
    case types.EDIT_ROUTE_FORMS: {
      return {
        ...state,
        routeFormsValue: action.object,
      }
    }
    case types.EDIT_EMAIL_PARSER: {
      return {
        ...state,
        emailParser: action.object,
      }
    }
    case types.SAVE_LOAD_FORMS: {
      return {
        ...state,
        saveLoadForms: action.object,
      }
    }
    case types.EDIT_INFO_PARSER: {
      return {
        ...state,
        infoParser: action.object,
      }
    }
    case types.EDIT_FLEET: {
      return {
        ...state,
        fleet: action.object,
      }
    }
    case types.EDIT_COMPANY_PRODUCTS: {
      return {
        ...state,
        companyProduct: action.object,
      }
    }
    case types.EDIT_BUYER_SELLER: {
      return {
        ...state,
        buyerSeller: action.object,
      }
    }
    case types.EDIT_USER_ACCESS: {
      return {
        ...state,
        userAccess: action.object,
      }
    }
    case types.EDIT_AUTO_CHARGE: {
      return {
        ...state,
        autoCharge: action.object,
      }
    }
    case types.EDIT_DATA_CORRECTION: {
      return {
        ...state,
        dataCorrection: action.object,
      }
    }
    case types.EDIT_BTFSTP: {
      return {
        ...state,
        companyBTFSTP: action.object,
      }
    }
    case types.EDIT_FIXED_PRICE: {
      return {
        ...state,
        fixedPrice: action.object,
      }
    }

    case types.COMPANY_SET_FLEETS_DATA: {
      const { payload } = action

      return produce(state, draft => {
        draft.fleets = payload
      })
    }

    case types.COMPANY_SET_LOADING: {
      return produce(state, draft => {
        draft.loading = action.payload
      })
    }

    case types.COMPANY_SET_EDITING_REFERENCE_NAME: {
      return produce(state, draft => {
        draft.editingReferenceName = action.payload
      })
    }

    case types.COMPANY_SET_UPDATE_SUCCESS: {
      return produce(state, draft => {
        draft.updateSuccess = action.payload
      })
    }

    case types.SET_ALL_COMPANIES: {
      return produce(state, draft => {
        draft.allCompanies = action.payload
      })
    }

    default: {
      return state
    }
  }
}

export default myCompanyReducer
