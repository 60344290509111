import { ColumnFiltersState } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentScope } from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'

const useFilterViewOption = () => {
  const currentScope: EScope = useSelector(selectCurrentScope)

  const ALL = 'all'

  const options = useMemo(() => {
    const opts = [
      {
        label: 'All',
        value: ALL,
      },
      {
        label: 'My Company',
        value: EScope.company,
      },
    ]
    if (currentScope === EScope.buyer) {
      opts.push({
        label: 'Sellers',
        value: EScope.seller,
      })
    } else {
      opts.push({
        label: 'Buyers',
        value: EScope.buyer,
      })
    }

    return opts
  }, [currentScope])

  const filterViewOption = useCallback(
    (label: string = 'Company of') => ({
      label,
      field: 'view',
      type: EFieldType.singleSelect,
      options: options,
    }),
    [options],
  )

  const getFilterData = useCallback((columnFilters: ColumnFiltersState) => {
    const filter = {} as Record<string, any>
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [])

  return { filterViewOption, ALL, getFilterData }
}

export default useFilterViewOption
