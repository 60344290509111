import { useWindowSize } from 'react-use'

import AddressTable from '~/containers/CompanySubTabs/Address/AddressTable'

import { ADDRESSABLE_TYPE } from '~/utils/constants'

function CompanyAddressTab(props) {
  const { companyId, ...tableProps } = props

  const windowSize = useWindowSize()

  return (
    <AddressTable
      tableHeight={windowSize.height - 300}
      addressableType={ADDRESSABLE_TYPE.company}
      addressableId={companyId}
      {...tableProps}
    />
  )
}
export default CompanyAddressTab
