import { createSlice } from '@reduxjs/toolkit'

export const applicationUiSlice = createSlice({
  name: 'application',
  initialState: {
    warningBrowserPopup: {
      isOpen: false,
    },
  },
  reducers: {
    updateAppUi: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
  },
})

export default applicationUiSlice
