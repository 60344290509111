import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'
import { useIsMobile } from '~/hooks/useIsMobile'

import { IonAvatar, IonChip, IonLabel } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import DriverFleetModal from '~/components/driver/DriverFleetModal'

import clsx from 'clsx'
import {
  selectMyCurrentCompany,
  selectCurrentScope,
  selectIsScopeDriver,
  selectDriverDetails,
  selectTrucksList,
} from '~/redux/selectors'
import getInitName from '~/utils/getInitName'

import './HeaderCompanyChip.scss'
import _ from 'lodash'
import { isExpiredAWSLink } from '~/utils/utils'

function HeaderCompanyChip() {
  const router = useRouter()
  const isMobile = useIsMobile()

  const [showDriverFleetModal, setShowDriverFleetModal] = useState(false)
  const [isImageError, setIsImageError] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)
  const userCurrentScope = useSelector(selectCurrentScope)
  const isDriver = useSelector(selectIsScopeDriver)
  const driverDetails = useSelector(selectDriverDetails)
  const trucksForDriver = useSelector(selectTrucksList)

  const mapTruck = useMemo(() => {
    return _.find(trucksForDriver, { id: driverDetails.currentTruckId }) || {}
  }, [driverDetails.currentTruckId, trucksForDriver])

  const companyAvatar = currentCompany.avatar || currentCompany.imageUrl

  const onClickCompanyChip = useCallback(() => {
    if (isDriver) {
      setShowDriverFleetModal(true)
    } else {
      router.push(`/companies/${currentCompany.id}/edit`)
    }
  }, [currentCompany.id, isDriver, router])

  const renderAvatar = useCallback(() => {
    if (companyAvatar && !isExpiredAWSLink(companyAvatar) && !isImageError) {
      return (
        <IonAvatar
          style={{ width: 24, height: 24 }}
          onClick={onClickCompanyChip}
        >
          <img
            src={companyAvatar}
            alt='company_avatar'
            onError={() => {
              setIsImageError(true)
            }}
          />
        </IonAvatar>
      )
    }

    return (
      <div
        className={clsx(
          'HeaderCompanyChip__initialNameAvatar',
          userCurrentScope,
        )}
        onClick={onClickCompanyChip}
      >
        {getInitName(currentCompany.name)}
      </div>
    )
  }, [
    companyAvatar,
    currentCompany.name,
    onClickCompanyChip,
    userCurrentScope,
    isImageError,
  ])

  return (
    <>
      {!isMobile && (
        <IonChip color={userCurrentScope} onClick={onClickCompanyChip}>
          {renderAvatar()}
          <IonLabel style={{ fontWeight: 600 }}>{currentCompany.name}</IonLabel>
        </IonChip>
      )}
      {isMobile && renderAvatar()}
      {isDriver && !isMobile && (
        <ToolTipOverlay content='Truck' placement='bottom'>
          <h2 className='DriverFleetsShow__truck'>{mapTruck?.name}</h2>
        </ToolTipOverlay>
      )}
      <DriverFleetModal
        showModal={showDriverFleetModal}
        dismissModal={() => setShowDriverFleetModal(false)}
      />
    </>
  )
}
HeaderCompanyChip.propTypes = {}
export default HeaderCompanyChip
