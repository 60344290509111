import React, { useEffect, useCallback } from 'react'

import { Link } from 'react-router-dom'
import { IonItem, IonLabel } from '@ionic/react'

const SubtabHoverMenu = ({ subtabs, y, url, toggleSubmenuOff }) => {

  const getSubtabUrl = useCallback(
    ({ value, path: subpath }) => {
      if (value === 'home' || subpath) {
        return null
      }
      return `view=${value}`
    },
    [],
  )

  useEffect(() => {
    const subtabMenu = document.getElementById('subtab-menu')
    document.body.addEventListener('click', toggleSubmenuOff)

    if (subtabMenu) {
      subtabMenu.addEventListener('mouseleave', toggleSubmenuOff)
    }

    return () => {
      subtabMenu.removeEventListener('mouseleave', toggleSubmenuOff)
      document.body.removeEventListener('click', toggleSubmenuOff)
    }
  }, [toggleSubmenuOff])

  return (
    <div
      className='subtab-menu-mini'
      id='subtab-menu'
      style={{ top: y }}
      onClick={toggleSubmenuOff}
    >
      {subtabs &&
        subtabs.map(({ label, value, ...rest }) => (
          <Link
            key={value}
            to={{
              pathname: url,
              search: getSubtabUrl({ value, label, ...rest })
            }}
            style={{ textDecoration: 'none' }}
          >
            <IonItem className='subtab-menu-row' lines='none'>
              <IonLabel className='subtab-menu-text'>{label}</IonLabel>
            </IonItem>
          </Link>
        ))}
    </div>
  )
}
export default SubtabHoverMenu
