import { createSlice } from '@reduxjs/toolkit'

const productGroupsSlice = createSlice({
  name: 'productGroups',
  initialState: {},
  reducers: {
    initializeProductGroups: (state, action) => {
      const newState = {}

      action.payload.forEach(productGroup => {
        newState[productGroup.id] = productGroup
      })

      return newState
    },
    updateProductGroups: (state, action) => {
      action.payload.forEach(productGroup => {
        if (state[productGroup.id]) {
          state[productGroup.id] = productGroup
          if (productGroup.deletedAt !== null) delete state[productGroup.id]
        }
      })
    },
    updateProductGroup: (state, { payload }) => {
      if (payload.deletedAt) {
        delete state[payload.id]
      } else {
        state[payload.id] = payload
      }
    },
  },
})

export default productGroupsSlice
