// import { sessionService } from 'redux-react-session';
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import * as types from './actionTypes'

export const fetchCurrentLocation = location => ({
  type: types.FETCH_CURRENT_LOCATION,
  location,
})

export const fetchBreadcrumbLoads = () => ({
  type: types.FETCH_BREADCRUMB_LOADS,
})

export const fetchBreadcrumbLoadsSuccess = breadcrumbs => ({
  breadcrumbs,
  type: types.FETCH_BREADCRUMB_LOADS_SUCCESS,
})

export const updateStoreCurrentLocation = location => dispatch => {
  const currentLocation = {
    accuracy: location.accuracy,
    altitude: location.altitude,
    altitudeAccuracy: location.altitudeAccuracy,
    latitude: location.latitude,
    longitude: location.longitude,
    speed: location.speed,
  }
  dispatch(fetchCurrentLocation(currentLocation))
}

export const updateBackendCurrentLocation = (location, load) => async () => {
  try {
    const breadcrumbLoad = {
      loadId: load.id,
      loadTimeId: null,
      driverFleetId: load.driverFleetId,
      truckFleetId: load.truckFleetId,
      locationAttributes: {
        eventType: 'gps_ping',
        source: 'Concord',
        lat: location.latitude,
        lng: location.longitude,
        time: new Date().toISOString(),
      },
    }
    if (
      !_.isEmpty(load) &&
      load.currentStatus != 'Delivery Complete' &&
      location.latitude
    ) {
      await apiClient.breadcrumbLoads.create({ breadcrumbLoad })
    }
    // sessionService.saveUser(response);
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
  }
}

export const getBreadcrumbLoads = loadId => dispatch => {
  dispatch(fetchBreadcrumbLoads())
  apiClient.breadcrumbLoads.get(loadId).then(({ breadcrumbLoads }) => {
    dispatch(fetchBreadcrumbLoadsSuccess(breadcrumbLoads))
  })
}
