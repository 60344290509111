import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany, selectCurrentScope } from '~/redux/selectors'

import { Popover } from 'react-bootstrap'
import { IonRow, IonCol, IonIcon, IonGrid } from '@ionic/react'
import ImageLabelItem from '~/components/shared/Items/ImageLabelItem'
import AccessImageLabelHeader from '~/components/shared/Items/AccessImageLabelHeader'

// import PropTypes from 'prop-types'
import { caretDown, caretUp } from 'ionicons/icons'

const HeaderPopOver = props => {
  const {
    showOverlay,
    showAccessList,
    accessesList,
    setShowAccessList,
    session,
    onUpdateFilter,
    onToggleAccess,
    userAccess,
  } = props

  const currentUserCompany = useSelector(selectMyCurrentCompany)
  const currentUserScope = useSelector(selectCurrentScope)

  const scopeOptions = useMemo(() => {
    if (session?.user?.id) {
      const optionsArray = []
      session.user.scopeOptions.map(option => {
        const optionLabel = option.charAt(0).toUpperCase() + option.slice(1)
        optionsArray.push({ label: optionLabel, value: option })
      })

      return optionsArray
    }

    return []
  }, [session.user?.id, session.user.scopeOptions])

  const dropdownField = useMemo(
    () => [
      {
        field: 'currentScope',
        filterKey: 'Viewing as',
        value: session?.user?.currentScope,
        items: scopeOptions,
      },
    ],
    [scopeOptions, session?.user?.currentScope],
  )

  if (!showOverlay) return null

  return (
    <>
      <Popover
        style={{
          position: 'fixed',
          top: '7%',
          left: '5%',
          maxWidth: 600,
        }}
      >
        <Popover.Body style={{ minWidth: 480 }}>
          <IonRow>
            {accessesList?.length > 1 && (
              <IonCol
                size='1.3'
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <span onClick={() => setShowAccessList(prev => !prev)}>
                  <IonIcon icon={caretUp} style={{ fontSize: '30px' }} />
                </span>

                <span onClick={() => setShowAccessList(prev => !prev)}>
                  <IonIcon icon={caretDown} style={{ fontSize: '30px' }} />
                </span>
              </IonCol>
            )}
            <IonCol>
              <ImageLabelItem
                item={currentUserCompany}
                hasMultipleScopes={true}
                dropdownField={dropdownField}
                onUpdateFilter={onUpdateFilter}
                killStyling={true}
                companyId={currentUserCompany?.id}
                currentScope={currentUserScope}
                onToggleAccess={onToggleAccess}
                fromPopover={true}
              />
            </IonCol>
          </IonRow>
        </Popover.Body>
      </Popover>
      {showAccessList && (
        <Popover
          style={{
            position: 'fixed',
            top: '20%',
            left: '5%',
            maxWidth: 600,
            maxHeight: '50%',
            overflowY: 'scroll',
          }}
        >
          <Popover.Body style={{ width: 505 }}>
            <IonGrid>
              <AccessImageLabelHeader
                accessesList={accessesList}
                userAccess={userAccess}
                onToggleAccess={onToggleAccess}
                companyId={currentUserCompany?.id}
                currentScope={session.user?.currentScope}
                dropdownField={dropdownField}
                onUpdateFilter={onUpdateFilter}
              />
            </IonGrid>
          </Popover.Body>
        </Popover>
      )}
    </>
  )
}

// HeaderPopOver.propTypes = {
//   showOverlay: PropTypes.bool,
//   showAccessList: PropTypes.bool,
//   accessesList: PropTypes.array,
//   setShowAccessList: PropTypes.func,
//   currentAccess: PropTypes.object,
//   session: PropTypes.object,
//   onUpdateFilter: PropTypes.func,
//   onToggleAccess: PropTypes.func,
//   userAccess: PropTypes.object
// }

export default React.forwardRef(HeaderPopOver)
