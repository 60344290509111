import { createSlice } from '@reduxjs/toolkit'

export const productsUiSlice = createSlice({
  name: 'products',
  initialState: {
    selectedProduct: null,
    columns: [],
    filters: {},
    tableLoading: false,
    tableLoadingRowModel: [], // number[]
    formLoading: false,
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    setTableLoading(state, { payload }) {
      state.tableLoading = payload
    },
    toggleTableLoadingRowModel(state, { payload }) {
      const index = state.tableLoadingRowModel.indexOf(payload)
      if (index === -1) {
        state.tableLoadingRowModel.push(payload)
      } else {
        state.tableLoadingRowModel.splice(index, 1)
      }
    },
    setFormLoading(state, { payload }) {
      state.formLoading = payload
    },
  },
})

export default productsUiSlice
