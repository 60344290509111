import { useEffect } from 'react'
import Pusher from 'pusher-js'
import PropTypes from 'prop-types'
import _ from 'lodash'

const usePusher = ({ channelName, events }) => {
  useEffect(() => {
    if (!channelName || !events) return

    const isTest = process.env.NODE_ENV === 'development' || null

    if (process.env.REACT_APP_PUSHER_API_KEY) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      })

      const envChannel = `${isTest ? 'TEST_' : ''}${channelName}`
      const channel = pusher.subscribe(envChannel)

      events &&
        events.forEach(event => {
          channel.bind(event.name, data => {
            const camelized = camelize(data)
            event.handler && event.handler(camelized)
          })
        })

      return () => {
        pusher.unsubscribe(channelName)
      }
    }
  }, [channelName, events])

  return {}
}

const camelize = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key)

    acc[camelKey] = _.isObject(value) ? camelize(value) : value
  })

usePusher.propTypes = {
  channelName: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func,
    }),
  ),
}

usePusher.defaultProps = {
  channelName: 'channel',
  events: [
    {
      name: 'event',
      handler: res => console.log(res),
    },
  ],
}

export default usePusher
