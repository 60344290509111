import { IonGrid, IonIcon } from '@ionic/react'
import { Accordion } from '~/components/shared'
import { CalculationVarItem } from '../CalculationVarItem'

import { produce } from 'immer'
import { formatVariableName } from '~/utils/formatVariableName'

import type { ICalculationVarsSectionProps } from './type'
import type { ICalculationVariable } from '~/types/models/ICalculation'

import './styles.scss'
import { checkmarkCircle, closeCircle } from 'ionicons/icons'

function CalculationVarsSection(props: ICalculationVarsSectionProps) {
  const { data, errors, clearErrors, onChange } = props

  const onChangeData = (
    index: number,
    field: keyof ICalculationVariable,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ) => {
    const newData = produce(data, draft => {
      draft[index][field] = value
    })
    onChange && onChange(newData)
  }

  const onAddFilter = (index: number) => () => {
    const newData = produce(data, draft => {
      if (!draft[index].filters['']) {
        draft[index].filters[''] = ''
      }
    })
    onChange && onChange(newData)
  }

  const onChangeModel =
    (index: number) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any, { value }: any) => {
      onChangeData(index, 'model', value)
      clearErrors &&
        clearErrors(`calculationVariablesAttributes.${index}.model`)
    }

  const onChangeFilters =
    (index: number) => (newFilters: ICalculationVariable['filters']) => {
      const newData = produce(data, draft => {
        draft[index].filters = newFilters
      })
      onChange && onChange(newData)
      clearErrors &&
        clearErrors(`calculationVariablesAttributes.${index}.filters`)
    }

  const onChangeCalculationMethods = (index: number) => (values: string[]) => {
    onChangeData(index, 'calculationMethod', values)
    clearErrors &&
      clearErrors(`calculationVariablesAttributes.${index}.calculationMethod`)
  }

  const accordionItems = data.map((item, index) => {
    const error = errors[index]

    const hasFilter = Object.keys(item.filters || {}).every(text =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Boolean(text && (item.filters as any)[text]),
    )
    const hasMethods = item.calculationMethod.length
    const isPassed = hasFilter && hasMethods && item.model.length

    return {
      title: (
        <div>
          <span>{formatVariableName(item.name)}</span>

          <span
            style={{
              verticalAlign: 'middle',
              marginLeft: 4,
              height: 14,
              display: 'inline-block',
            }}
          >
            <IonIcon
              icon={isPassed ? checkmarkCircle : closeCircle}
              color={isPassed ? 'success' : 'danger'}
            />
          </span>
        </div>
      ),
      eventKey: item.name,
      component: (
        <CalculationVarItem
          data={item}
          error={error}
          onChangeModel={onChangeModel(index)}
          onChangeCalculationMethods={onChangeCalculationMethods(index)}
          onAddFilter={onAddFilter(index)}
          onChangeFilters={onChangeFilters(index)}
        />
      ),
    }
  })

  const initialActiveTabs = data.map(({ name }) => name)

  if (data.length === 0) {
    return null
  }

  return (
    <IonGrid className='CalculationVarsSection__container'>
      <Accordion
        items={accordionItems}
        initialActiveTabs={initialActiveTabs}
        alwaysOpen
        className='CalculationVarsSection__accordion'
      />
    </IonGrid>
  )
}

export default CalculationVarsSection
