import { useState, useEffect, useCallback, useRef } from 'react'

import { IonButton } from '@ionic/react'

import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import './NotesPopup.scss'

function NotesPopup(props) {
  const { value, onSave, onClose, editable, isShow } = props

  const inputRef = useRef()

  const [textValue, setTextValue] = useState('')

  const handleChangeNotes = useCallback(event => {
    setTextValue(event.target.value)
  }, [])

  const handleSaveNotes = useCallback(
    event => {
      onSave && onSave(textValue, event)
      onClose && onClose(event)
    },
    [onClose, onSave, textValue],
  )

  useEffect(() => {
    setTextValue(value)
  }, [value])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        inputRef.current?.setFocus()
      }, 500)
    }
  }, [isShow])

  return (
    <Form className='NotesPopup__formContainer'>
      <Form.Group className='mb-3'>
        <Form.Control
          as='textarea'
          rows={2}
          value={textValue}
          onChange={handleChangeNotes}
          ref={inputRef}
        />
      </Form.Group>
      {editable && (
        <IonButton size='small' expand='full' onClick={handleSaveNotes}>
          Save
        </IonButton>
      )}
    </Form>
  )
}

NotesPopup.propTypes = {
  event: PropTypes.object,
  isOpen: PropTypes.bool,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  editable: PropTypes.bool,
}

NotesPopup.defaultProps = {
  editable: true,
}

export default NotesPopup
