import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDialogCreateEditCompany } from '~/hooks/useDialogCreateEditCompany'

import { IonIcon } from '@ionic/react'
import { ConcordFormDropdown, ToolTipOverlay } from '~/components/shared'
import CompanyCreateEditForm from '~/components/shared/ConcordForm/Dropdown/Company/CreateEditForm'
import CompanyFormatOptionLabel from '~/components/shared/ConcordForm/Dropdown/Company/FormatOptionLabel'

import _ from 'lodash'
import { createOutline } from 'ionicons/icons'
import {
  selectAllSellerCompaniesOptions,
  selectCommonBuyerOptions,
  selectCompanySellerOptions,
  selectSellerRelationshipOptions,
  selectAllFleetCompaniesOptions,
  selectCompanyFleetOptions,
  selectFleetRelationshipOptions,
} from '~/redux/selectors'
import { concordDropdownType, USER_SCOPE } from '~/utils/constants'
import PropTypes from 'prop-types'
import { reAddBuyerSellerRelationship } from '~/redux/actions/CommonData/buyerSellerActions'
import { CompanyCustomOption } from './CompanyCustomOption'

function ConcordCompanyDropdown(props) {
  const {
    formProps,
    onEditCompany,
    type,
    buyerId,
    onChange,
    value,
    addableCompany,
    editableCompany,
    ...dropdownProps
  } = props
  const isSellerType = useMemo(() => type === USER_SCOPE.seller, [type])

  const { onOpenDialogCreateEditCompany, onCloseDialogCreateEditCompany } =
    useDialogCreateEditCompany()

  const dispatch = useDispatch()
  const commonBuyerOptions = useSelector(selectCommonBuyerOptions)

  const allSellerCompaniesOptions = useSelector(
    isSellerType
      ? selectAllSellerCompaniesOptions
      : selectAllFleetCompaniesOptions,
  )

  const companySellerOptions = useSelector(
    isSellerType ? selectCompanySellerOptions : selectCompanyFleetOptions,
  )
  const companyFleetOptions = useSelector(selectCompanyFleetOptions)

  const sellerRelationshipOptions = useSelector(
    isSellerType
      ? selectSellerRelationshipOptions
      : selectFleetRelationshipOptions,
  )

  const fleetRelationshipOptions = useSelector(selectFleetRelationshipOptions)

  const mapOptions = useMemo(() => {
    if (type === USER_SCOPE.buyer) {
      return commonBuyerOptions
    }

    const filterSellerRelationshipOptions = sellerRelationshipOptions.filter(
      sellerRelationship =>
        sellerRelationship.buyerId === buyerId &&
        sellerRelationship.sellerId === value?.value,
    )

    const filterFleetRelationshipOptions = fleetRelationshipOptions.filter(
      sellerRelationship =>
        sellerRelationship.buyerId === buyerId &&
        sellerRelationship.sellerId === value?.value,
    )

    const labelCurrent = isSellerType ? 'Sellers' : 'Fleets'
    const currentCompanies = {
      label: `Current ${labelCurrent}`,
      options: filterSellerRelationshipOptions,
    }

    const otherSellers = _.differenceBy(
      companySellerOptions,
      filterSellerRelationshipOptions,
      'value',
    ).map(option => ({ ...option, otherOption: true }))

    const otherFleets = _.differenceBy(
      companyFleetOptions,
      filterFleetRelationshipOptions,
      'value',
    ).map(option => ({ ...option, otherOption: true }))

    const labelOther = isSellerType ? 'Sellers' : 'Fleets'
    const otherSellerGroupOptions = {
      label: `My ${labelOther}`,
      options: otherSellers,
    }

    const labelAll = isSellerType ? 'Sellers' : 'Fleets'
    const allCompaniesGroupOptions = {
      label: `All ${labelAll}`,
      options: _(allSellerCompaniesOptions)
        .differenceBy(otherSellers, 'value')
        .differenceBy(filterSellerRelationshipOptions, 'value')
        .value(),
    }
    const groupOptions = [
      currentCompanies,
      otherSellerGroupOptions,
      allCompaniesGroupOptions,
    ]

    if (isSellerType) {
      const fleetOptions = {
        label: 'My Fleets',
        options: otherFleets,
      }
      groupOptions.push(fleetOptions)
    }

    return groupOptions
  }, [
    type,
    sellerRelationshipOptions,
    fleetRelationshipOptions,
    isSellerType,
    companySellerOptions,
    companyFleetOptions,
    allSellerCompaniesOptions,
    commonBuyerOptions,
    buyerId,
    value?.value,
  ])

  const renderElementBelowDropdown = useCallback(
    ({ handleToggleReactSelect, inputDropdownValue }) => {
      if (!addableCompany) {
        return null
      }

      return (
        <CompanyCreateEditForm
          {...formProps}
          onChange={onChange}
          companyOptions={mapOptions}
          handleToggleReactSelect={handleToggleReactSelect}
          type={type}
          inputDropdownValue={inputDropdownValue}
        />
      )
    },
    [addableCompany, formProps, mapOptions, onChange, type],
  )

  const renderIndicatorElement = useCallback(() => {
    if (!value?.value || !editableCompany) {
      return null
    }
    return (
      <ToolTipOverlay content='Edit company' placement='top'>
        <span
          onClick={handleClickEditCompany}
          className='ConcordFormDropdown__editCompanyIcon icon'
        >
          <IonIcon icon={createOutline} />
        </span>
      </ToolTipOverlay>
    )
  }, [editableCompany, handleClickEditCompany, value?.value])

  const afterEditCompany = useCallback(
    updatedCompany => {
      onCloseDialogCreateEditCompany()
      formProps?.onUpdateCompany && formProps?.onUpdateCompany(updatedCompany)
    },
    [formProps, onCloseDialogCreateEditCompany],
  )

  const handleClickEditCompany = useCallback(() => {
    onOpenDialogCreateEditCompany({ companyId: value?.value, afterEditCompany })
  }, [afterEditCompany, onOpenDialogCreateEditCompany, value?.value])

  const handleChange = useCallback(
    (selectedOption, meta) => {
      dispatch(
        reAddBuyerSellerRelationship({
          payload: {
            buyerId,
            sellerId: selectedOption.value,
            relationship: isSellerType ? 'Seller' : 'Fleet',
          },
        }),
      )
      onChange && onChange(selectedOption, meta)
    },
    [buyerId, dispatch, isSellerType, onChange],
  )

  return (
    <>
      <ConcordFormDropdown
        value={value}
        options={mapOptions}
        type={concordDropdownType.companyCreateEdit}
        renderIndicatorElement={renderIndicatorElement}
        renderElementBelowDropdown={renderElementBelowDropdown}
        onChange={handleChange}
        formatOptionLabel={CompanyFormatOptionLabel}
        {...dropdownProps}
        components={{
          Option: CompanyCustomOption,
        }}
      />
    </>
  )
}

ConcordCompanyDropdown.propTypes = {
  type: PropTypes.oneOf(['seller', 'buyer', 'fleet']),
  value: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    logo: PropTypes.string,
  }),
}

ConcordCompanyDropdown.defaultProps = {
  type: USER_SCOPE.seller,
  addableCompany: true,
  editableCompany: true,
  changeable: true,
}

export default React.memo(ConcordCompanyDropdown)
