import useModifyContests from './useModifyContests'
import useQueryContests from './useQueryContests'

import type { IContest, IGetContestsParams } from '~/types/models/IContest'
import type { UseQueryOptions } from 'react-query'
import { useQueryPrizes } from '../useQueryPrizes'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ICalculation } from '~/types/models/ICalculation'
import { apiClient } from '~/api/ApiClient'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectUsersDriverFleet } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { IPrize } from '~/types/models/IPrize'
import moment from 'moment'

const useQueryContestsAndPrizes = (
  params?: IGetContestsParams,
  options?: Partial<UseQueryOptions<IContest[]>>,
) => {
  const mappedParams = {
    campaign: true,
    filters: {
      active: '',
    },
    ...params,
  }

  const { contestsData, isLoadingContests } = useQueryContests(
    mappedParams,
    options,
  )

  const driverFleet: IDriverFleet = useSelector(selectUsersDriverFleet)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const [calculationsData, setCalculationsData] = useState<ICalculation[]>([])

  const campaignIds = _(contestsData).map('campaignId').uniq().value()

  const { prizesData, isLoadingPrizes } = useQueryPrizes(
    {
      filters: {
        campaignId: campaignIds,
      },
    },
    { enabled: campaignIds.length > 0 },
  )

  const contestsOngoing = useMemo(() => {
    const today = moment().startOf('day')

    return contestsData
      .filter(item => {
        const startDate = moment(item.startDate)
        const endDate = moment(item.endDate)

        return startDate.isSameOrBefore(today) && endDate.isSameOrAfter(today)
      })
      .map(item => {
        const prizes = prizesData.filter(
          ({ campaignId }) => campaignId === item.campaignId,
        )

        return {
          ...item,
          prizes,
        }
      })
  }, [contestsData, prizesData])

  const getCalculations = useCallback(async () => {
    const apiCalls: Promise<ICalculation>[] = []
    contestsOngoing.forEach(contest => {
      if (contest.campaign) {
        apiCalls.push(
          apiClient.calculations.getById(contest.campaign.calculationId, {
            calculationVariables: true,
            args: {
              st: contest.startDate,
              ed: contest.endDate,
              driverFleetId: driverFleet.id,
            },
          }),
        )
      }
    })
    if (apiCalls.length) {
      const calculations = await Promise.all(apiCalls)
      setCalculationsData(calculations)
    }
  }, [contestsOngoing, driverFleet.id])

  const { addContest, updateContest, removeContest } =
    useModifyContests(mappedParams)

  const getCurrentPrize = (
    prizes: IPrize[],
    result: null | number | undefined,
  ) => {
    const prizesSorted = _.orderBy(prizes, ['qty'], ['asc'])
    const remainedPrizes = prizesSorted.filter(
      ({ qty }) => Number(qty) <= Number(result || 0),
    )

    if (remainedPrizes.length === prizes.length) {
      return _.last(remainedPrizes)
    }

    return prizesSorted.find(({ qty }) => Number(qty) > Number(result || 0))
  }

  const getPrizesUntilId = (
    prizes: IPrize[],
    result: null | number | undefined,
  ) => {
    const currentPrize = getCurrentPrize(prizes, result)
    if (currentPrize) {
      const index = prizes.findIndex(item => item.id === currentPrize.id)
      if (index === -1) {
        return []
      }
      return prizes.slice(0, index)
    }
    return []
  }

  const getNextPrize = (
    prizes: IPrize[],
    result: null | number | undefined,
  ) => {
    const currentPrize = getCurrentPrize(prizes, result)
    return _.sortBy(prizes, ['qty'], ['asc']).find(
      ({ qty }) => Number(qty) > Number(currentPrize?.qty || 0),
    )
  }

  useEffect(() => {
    if (contestsOngoing.length && currentScope === EScope.driverFleet) {
      getCalculations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestsOngoing, currentScope])

  return {
    contestsData,
    prizesData,
    isLoadingContests,
    isLoadingPrizes,
    addContest,
    updateContest,
    removeContest,
    contestsOngoing,
    calculationsData,
    getCurrentPrize,
    getPrizesUntilId,
    getNextPrize,
  }
}

export default useQueryContestsAndPrizes
