import { createSelector } from 'reselect'
import { EMAIL_TYPE_OPTIONS } from '~/utils/constants'

export const selectCompanyEmailsDataState = state => state.data.companyEmails
export const selectCompanyEmailsUiState = state => state.ui.companyEmails

export const selectCompanyEmailsData = createSelector(
  selectCompanyEmailsDataState,
  state => {
    const data = Object.values(state)
    const mapData = data.map(item => {
      const emailTypes = []
      item.emailTypes?.forEach(type => {
        const findType = EMAIL_TYPE_OPTIONS.find(
          ({ numValue }) => type === numValue,
        )
        if (findType) {
          emailTypes.push(findType.value)
        }
      })

      return { ...item, emailTypes }
    })

    return mapData
  },
)

export const selectCompanyEmailsLoadingTable = createSelector(
  selectCompanyEmailsUiState,
  stateUi => stateUi.loadingTable,
)

export const selectCompanyEmailsFilters = createSelector(
  selectCompanyEmailsUiState,
  stateUi => stateUi.filters,
)
