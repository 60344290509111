import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCompanyTimeZone } from '~/redux/selectors'

import moment from 'moment'
import momentTz from 'moment-timezone'

const useDateWithTimezone = () => {
  const companyTimezone = useSelector(selectCompanyTimeZone)

  const MOMENT_FORMAT_DATE = useMemo(
    () => ({
      dateTooltipWithoutTime: 'ddd, MMM DD, YYYY',
      dateTooltipWithTime: 'ddd, MMM DD, YYYY HH:mm:ss',
      dateWithTime: 'MM-dd-YYYY HH:mm:ss',
      dateWithoutTime: 'MM-dd-YYYY',
    }),
    [],
  )

  /**
   * @param {string} date
   * @return {Date}
   */
  const setISODateWithTimezone = useCallback(
    date => {
      const dateWithoutZone = momentTz
        .tz(date, companyTimezone)
        .format('YYYY-MM-DDTHH:mm:ss.SSS')
      const localZone = moment(dateWithoutZone).format('Z')
      const dateWithLocalZone = [dateWithoutZone, localZone].join('')

      return new Date(dateWithLocalZone)
    },
    [companyTimezone],
  )

  /**
   * @param {Date} date
   * @return {Date}
   */
  const setJsDateWithTimezone = useCallback(
    date => {
      const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
      const otherZone = momentTz.tz(date, companyTimezone).format('Z')
      const dateWithOtherZone = [dateWithoutZone, otherZone].join('')

      return new Date(dateWithOtherZone)
    },
    [companyTimezone],
  )

  /**
   * @param {string} date
   * @param {string} format
   * @return {string}
   */
  const formatISODate = useCallback(
    (date, format) => {
      const dateTz = setISODateWithTimezone(date)

      return moment(dateTz).format(format)
    },
    [setISODateWithTimezone],
  )

  /**
   * @param {Date} date
   * @param {string} format
   * @return {string}
   */
  const formatJsDate = useCallback(
    (date, format) => {
      const dateTz = setJsDateWithTimezone(date)

      return moment(dateTz).format(format)
    },
    [setJsDateWithTimezone],
  )

  return {
    setISODateWithTimezone,
    setJsDateWithTimezone,
    formatISODate,
    formatJsDate,
    MOMENT_FORMAT_DATE,
  }
}

export default useDateWithTimezone
