import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const LightBulbIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      stroke={color}
      strokeWidth={3}
      viewBox='0 0 64 64'
      {...svgProps}
    >
      <path d='m50.4 24.38 7.9-1.24M47.93 17.11l4.94-2.91M42.89 11.73l3.32-7.22M33.45 10.69l-.04-5.73M24.29 12.09l-2.67-7.58M17.99 17.03l-5.03-2.74M15.78 23.97l-7.75-1.31M26.22 45.47c0-5.16-3.19-9.49-4.91-12.69A12.24 12.24 0 0 1 19.85 27c0-6.79 6.21-12.3 13-12.3M39.48 45.47c0-5.16 3.19-9.49 4.91-12.69A12.24 12.24 0 0 0 45.85 27c0-6.79-6.21-12.3-13-12.3' />
      <rect width={18.93} height={4.25} x={23.63} y={45.19} rx={2.12} />
      <rect width={16.61} height={4.25} x={24.79} y={49.43} rx={2.12} />
      <path d='M36.32 53.68v.84a3.23 3.23 0 1 1-6.44 0v-.84M24.57 26.25a7.5 7.5 0 0 1 7.88-7.11' />
    </svg>
  )
}

export default LightBulbIcon
