import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const DocumentPlusIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M4.172 3.172C3 4.343 3 6.229 3 10v4c0 3.771 0 5.657 1.172 6.828C5.343 22 7.229 22 11 22h2c3.771 0 5.657 0 6.828-1.172C21 19.657 21 17.771 21 14v-4c0-3.771 0-5.657-1.172-6.828C18.657 2 16.771 2 13 2h-2C7.229 2 5.343 2 4.172 3.172ZM12.75 6a.75.75 0 0 0-1.5 0v1.25H10a.75.75 0 1 0 0 1.5h1.25V10a.75.75 0 0 0 1.5 0V8.75H14a.75.75 0 0 0 0-1.5h-1.25V6ZM8 13.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H8Zm1 4a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H9Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default DocumentPlusIcon
