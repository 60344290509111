import { useDispatch } from 'react-redux'
import {
  updateDriverFleet as updateDriverFleetAction,
  initializeDriverFleets,
  updateDriversUi,
} from '~/redux/actions/driverFleetsActions'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { useCallback } from 'react'
import {
  initializeDrivers,
  updateDriver as updateDriverAction,
  updateDriversUi as updateDriversUiAction,
} from '~/redux/actions/driversActions'
import { initializePeople } from '~/redux/actions/peopleActions'
import { apiClient } from '~/api/ApiClient'

export const useDrivers = () => {
  const dispatch = useDispatch()

  const fetchDriverFleets = useCallback(async () => {
    try {
      dispatch(updateDriversUi({ loading: 'loading' }))

      const { driverFleets } = await apiClient.driverFleets.get({
        currentLoad: true
      })
      dispatch(initializeDriverFleets(driverFleets))

      dispatch(updateDriversUi({ loading: 'success' }))
    } catch (err) {
      dispatch(updateDriversUi({ loading: 'fail' }))
    }
  }, [dispatch])

  const updateDriverFleet = useCallback(
    async driverFleet => {
      try {
        dispatch(updateDriversUi({ loading: 'loading' }))
        const res = await apiClient.driverFleets.update(
          driverFleet.id,
          driverFleet,
        )
        dispatch(updateDriverFleetAction(res))
        dispatch(updateDriversUi({ loading: 'success' }))
        toast.success(toastMessages.updateSuccess)
        return res
      } catch (err) {
        toast.error(toastMessages.updateError)
        dispatch(updateDriversUi({ loading: 'fail' }))
      }
    },
    [dispatch],
  )

  const fetchDrivers = useCallback(async () => {
    try {
      dispatch(updateDriversUiAction({ loading: 'loading' }))
      const { drivers } = await apiClient.drivers.get()
      dispatch(initializeDrivers(drivers))

      dispatch(updateDriversUi({ loading: 'success' }))
    } catch (err) {
      console.log(err)
      dispatch(updateDriversUi({ loading: 'fail' }))
    }
  }, [dispatch])

  const updateDriver = useCallback(
    async (id, driver) => {
      try {
        dispatch(updateDriversUi({ loading: 'loading' }))
        const res = await apiClient.drivers.update(id, { driver })
        dispatch(updateDriverAction(res))

        dispatch(updateDriversUi({ loading: 'success' }))
        toast.success(toastMessages.updateSuccess)
      } catch (err) {
        dispatch(updateDriversUi({ loading: 'fail' }))
        toast.error(toastMessages.updateError)
      }
    },
    [dispatch],
  )

  const fetchPeople = useCallback(async () => {
    try {
      dispatch(updateDriversUi({ loading: 'loading' })) //peopleui?
      const { people } = await apiClient.people.get()
      dispatch(initializePeople(people))
    } catch (err) {
      dispatch(updateDriversUi({ loading: 'fail' })) //peopleui?
    }
  }, [dispatch])

  return {
    fetchDriverFleets,
    updateDriverFleet,
    fetchDrivers,
    updateDriver,
    fetchPeople,
  }
}
