import { useMemo } from 'react'

import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

import type { IBreadcrumbDotProps } from './type'

import './styles.scss'

function BreadcrumbDot(props: IBreadcrumbDotProps) {
  const { breadcrumbLoad } = props

  const position = useMemo(
    () =>
      new google.maps.LatLng(
        Number(breadcrumbLoad.lat || 0),
        Number(breadcrumbLoad.lng || 0),
      ),
    [breadcrumbLoad.lat, breadcrumbLoad.lng],
  )

  return (
    <InfoBox
      zIndex={1000}
      position={position}
      options={{
        closeBoxURL: '',
        enableEventPropagation: true,
        alignBottom: true,
        pixelOffset: new window.google.maps.Size(-12, 10),
      }}
    >
      <div className='BreadcrumbDot__container' />
    </InfoBox>
  )
}
export default BreadcrumbDot
