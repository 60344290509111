import { REGEX_SPLIT_REGEX_TO_STRING } from '~/utils/constants'

export const getRegex = regex => {
  try {
    if (!regex) {
      return new RegExp('')
    }
    const [, , regexStr, flags] = regex.match(REGEX_SPLIT_REGEX_TO_STRING)

    return new RegExp(regexStr, flags)
  } catch (error) {
    console.log('error', error)
    return new RegExp('')
  }
}
