import { createSelector } from 'reselect'
import _ from 'lodash'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

const buyerId = (state, buyer) => buyer
const sellerId = (state, buyer, seller) => seller

export const selectBuyerSellers = state =>
  columnHashToColumnsArray(state.data.buyerSellers)

const selectCompanies = state => state.data.companies

export const selectABuyerSeller = createSelector(
  selectBuyerSellers,
  buyerId,
  sellerId,

  (buyerSellers, buyer, seller) => {
    if (!buyerSellers || !buyer || !seller) {
      return
    }

    return buyerSellers.find(bs => {
      return bs.buyer.id == buyer && bs.seller.id == seller
    })
  },
)

export const selectBuyerSellerOptions = createSelector(
  selectBuyerSellers,

  buyerSellers => {
    return _(buyerSellers)
      .map(({ seller }) => ({
        value: seller?.id,
        label: seller?.name,
      }))
      .sortBy('label')
      .value()
  },
)

export const selectSellerRelationshipOptions = createSelector(
  selectBuyerSellers,

  buyerSellers => {
    return _(buyerSellers)
      .filter(({ relationship }) => relationship === 'Seller')
      .map(({ seller, buyerId, sellerId }) => ({
        value: sellerId,
        label: `${seller?.code} - ${seller?.name}`,
        name: seller?.name,
        code: seller?.code,
        image: seller?.imageUrl,
        buyerId,
        sellerId,
      }))
      .sortBy('label')
      .value()
  },
)

export const selectFleetRelationshipOptions = createSelector(
  selectBuyerSellers,

  buyerSellers => {
    return _(buyerSellers)
      .filter(
        ({ relationship }) =>
          relationship === 'Fleet' || relationship === 'Both',
      )
      .map(({ seller, sellerId, buyerId }) => ({
        value: sellerId,
        label: `${seller?.code} - ${seller?.name}`,
        name: seller?.name,
        code: seller?.code,
        image: seller?.imageUrl,
        buyerId,
        sellerId,
      }))
      .sortBy('label')
      .value()
  },
)

export const selectSellersFromBuyerSellers = createSelector(
  selectBuyerSellers,
  selectCompanies,

  buyerSellers => {
    return buyerSellers.filter(bs => bs.relationship == 'Seller')
  },
)

export const selectBuyersFromBuyerSellers = createSelector(
  selectBuyerSellers,
  selectCompanies,

  buyerSellers => {
    return buyerSellers.filter(bs => bs.relationship == 'Buyer')
  },
)
