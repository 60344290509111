import React, { useState, useEffect } from 'react'
import {
  IonItem,
  IonRow,
  IonButtons,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonAlert,
} from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { Button } from '~/components'
const CompanyBTFSTPsubList = ({
  companyBTFSTPs,
  onEditClick,
  deleteParser,
  seller,
}) => {
  const values = [
    {
      label: 'Seller Product',
      currentValue: companyBTFSTPs?.sellerProduct?.name,
    },
    {
      label: 'Product',
      currentValue: companyBTFSTPs?.buyerSellerProduct?.name,
    },
    {
      label: 'FOB Price',
      currentValue: companyBTFSTPs?.buyerFromSellerTerminalProduct?.fobPrice,
    },
    {
      label: 'Net Price',
      currentValue:
        companyBTFSTPs?.buyerFromSellerTerminalProduct?.fobPrice -
        companyBTFSTPs?.buyerFromSellerTerminalProduct?.discount,
    },
    {
      label: 'FOB Discount',
      currentValue: companyBTFSTPs?.buyerFromSellerTerminalProduct?.discount,
    },
    {
      label: 'Delivered Price',
      currentValue: companyBTFSTPs?.deliveredPrice,
    },
    {
      label: 'Delivered Net Price',
      currentValue: companyBTFSTPs?.deliveredPrice - companyBTFSTPs?.discount,
    },
    {
      label: 'Delivered Discount',
      currentValue: companyBTFSTPs?.discount,
    },
    {
      label: 'Load Size',
      currentValue: companyBTFSTPs?.loadSize,
    },
    {
      label: 'Qty',
      currentValue: companyBTFSTPs?.qty,
    },
    {
      label: 'LDs',
      currentValue: companyBTFSTPs?.lds,
    },
    { label: 'Delivery Method', currentValue: companyBTFSTPs?.deliveryMethod },
    { label: 'Billing Method', currentValue: companyBTFSTPs?.billingMethod },
    {
      label: 'Weekend Type',
      currentValue: companyBTFSTPs?.buyerTerminalProductSchedule?.weekendType,
    },
    {
      label: 'Reminder Cadence',
      currentValue:
        companyBTFSTPs?.buyerTerminalProductSchedule?.reminderCadence,
    },
    {
      label: 'Final Deadline',
      currentValue: companyBTFSTPs?.buyerTerminalProductSchedule?.finalDeadline,
    },
    {
      label: 'Auto Assign Fleet',
      currentValue: companyBTFSTPs?.autoAssignFleet?.name,
    },
  ]
  const [showAlert, setShowAlert] = useState(false)
  const [currentCharge, setCurrentCharge] = useState()
  const [sellerName, setSellerName] = useState('')
  const onTrashClick = companyBTFSTPs => {
    setCurrentCharge(companyBTFSTPs)
    setShowAlert(true)
  }

  useEffect(() => {
    if (seller) {
      let sellerName
      seller.map(sub => {
        if (sub.value == companyBTFSTPs?.sellerTerminal?.companyId)
          sellerName = sub.label
      })
      setSellerName(sellerName)
    }
  }, [seller, companyBTFSTPs?.sellerTerminal?.companyId])

  return (
    <>
      <IonCard style={{ marginTop: 0 }}>
        <IonCardHeader>
          <IonItem>
            <IonLabel style={{ textAlign: 'center', fontSize: '1.2em' }}>
              {sellerName} - {companyBTFSTPs?.sellerTerminal?.name || ''} {'->'}
              {companyBTFSTPs?.buyerTerminal?.name || ''}
            </IonLabel>
            <IonButtons>
              <Button
                label='Edit'
                onClick={() => onEditClick(companyBTFSTPs)}
              />
              <Button
                icon={trashBin}
                color='danger'
                onClick={() => onTrashClick(companyBTFSTPs)}
              />
            </IonButtons>
          </IonItem>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            {values.map((sub, index) => (
              <IonCol
                key={index}
                sizeXl='4'
                sizeLg='6'
                sizeMd='6'
                sizeSm='12'
                sizeXs='12'
              >
                <IonItem>
                  <IonLabel>
                    <IonRow>
                      <h2 style={{ fontWeight: 'bold' }}>{sub.label}</h2>:{' '}
                      {sub.currentValue}
                    </IonRow>
                  </IonLabel>
                </IonItem>
              </IonCol>
            ))}
          </IonRow>
        </IonCardContent>
      </IonCard>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm'}
        message={'Are you sure you want to delete it?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlert(false)
            },
          },
          {
            text: 'Ok',
            handler: () => {
              deleteParser(currentCharge)
            },
          },
        ]}
      />
    </>
  )
}

export default CompanyBTFSTPsubList
