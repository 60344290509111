var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card, makeStyles, Slide } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        zIndex: 100,
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: '1px 0px 1px 1px',
        position: 'absolute',
        bottom: theme.spacing(0),
        left: theme.spacing(36),
        width: 'calc(100% - 650px)',
        margin: 'auto',
        overflowX: 'scroll',
    },
}); });
/**
 * Container component for the Table Labeler
 * @param Props
 */
export var DocumentLabelerTableContainer = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    return (_jsx(Slide, __assign({ direction: "up", in: true }, { children: _jsx(Card, __assign({ className: classes.Root, elevation: 4, "data-testid": "table-labeler" }, { children: children })) })));
};
