import useCheckWorkerInfoSection from './useCheckWorkerInfoSection'

import { InfoSection } from '../InfoSection'

import type { ICheckWorkerInfoSectionProps } from './type'

function CheckWorkerInfoSection(props: ICheckWorkerInfoSectionProps) {
  const { renderCheckWorkerInfo, isLoading } = useCheckWorkerInfoSection(props)

  return (
    <InfoSection
      data={renderCheckWorkerInfo}
      isLoading={isLoading}
      color='info'
    />
  )
}
export default CheckWorkerInfoSection
