import { createSlice } from '@reduxjs/toolkit'

export const fixedPricesSlice = createSlice({
  name: 'fixedPrices',
  initialState: {},
  reducers: {
    updateFixedPrices: (state, action) => {
      action.payload.fixedPrices.forEach(fp => {
        state[fp.id] = { ...fp }
      })
    },
    updateFixedPrice: (state, action) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default fixedPricesSlice
