import React, { useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStorage from '~/hooks/useStorage'
import { useRouter } from '~/hooks/useRouter'

import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonText,
  IonFooter,
} from '@ionic/react'
import AdminSideMenu from '~/components/admin/AdminSideMenu'
import SideMenuLink from '~/components/admin/AdminSideMenu/SideMenuLink'
import { cogOutline, logOutOutline } from 'ionicons/icons'
import { logout } from '~/redux/actions/sessionActions'
import { selectLoginPageLoading, selectSessionUser } from '~/redux/selectors'

const Menu = ({ session }) => {
  const menuRef = useRef()
  const dispatch = useDispatch()
  const loginPageLoading = useSelector(selectLoginPageLoading)
  const sessionUser = useSelector(selectSessionUser)
  const router = useRouter()

  const [, setStorageData] = useStorage()

  const isSettingsAllowed = useMemo(
    () => (sessionUser.menuOptions || []).includes('settings'),
    [sessionUser],
  )

  const onToggleMenu = () => {
    menuRef?.current?.toggle()
  }

  const currentUser = session?.user
  const currentScope = currentUser?.currentScope

  const handleLogout = useCallback(
    event => {
      event.preventDefault()
      dispatch(logout({ router }))
      setStorageData({})
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, setStorageData],
  )

  return (
    <IonMenu
      side='start'
      contentId='menu'
      autoHide='true'
      ref={menuRef}
      className='d-print-none'
    >
      <IonHeader>
        <IonToolbar translucent>
          <IonTitle>
            <IonText color='primary'>Concord</IonText>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AdminSideMenu
          onToggleMobileMenu={onToggleMenu}
          currentUser={currentUser}
          currentScope={currentScope}
        />
      </IonContent>
      <IonFooter className='logout-wrapper'>
        {isSettingsAllowed && (
          <SideMenuLink
            onToggleMobileMenu={onToggleMenu}
            icon={cogOutline}
            label='Settings'
            name='settings'
            value='settings'
            path='/profile'
          />
        )}
        <SideMenuLink
          icon={logOutOutline}
          label='Log Out'
          name='logout'
          value='logout'
          path='/logout'
          onToggleMobileMenu={handleLogout}
          loading={loginPageLoading}
        />
      </IonFooter>
    </IonMenu>
  )
}
export default Menu
