import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const userTableConfigurationsSlice = createSlice({
  name: 'userTableConfigurations',
  initialState: {
    documents: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    loads: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    invoices: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_buyer_seller_products: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_buyer_terminal_references: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_buyers: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_email_parsers: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_fixed_prices: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_fleets: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_parser_column_mappings: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_parsers: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_products: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_reference_names: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_seller_products: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_seller_setup: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_sellers: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_setup: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_tags: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_terminals: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    company_users: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
    order_tracking: {
      autoSave: false,
      id: null,
      columnConfigurations: [],
    },
  },
  reducers: {
    initializeConfigurations: (state, { payload }) => {
      if (payload?.id) {
        state[payload.tableName].id = payload.id
      }
      if (
        payload.columnConfigurations &&
        payload.columnConfigurations.length > 0
      ) {
        state[payload.tableName].columnConfigurations =
          payload.columnConfigurations
      }
      state[payload.tableName].filterConfigurations =
        payload.filterConfigurations || {}
    },
    updateConfigurations: (state, { payload }) => {
      const { tableName, configurations } = payload

      for (const configName in configurations) {
        if (_.isArray(configurations[configName])) {
          let columns = state[tableName][configName]
          configurations[configName].forEach(column => {
            let updateIndex = columns.findIndex(
              columnState => column.sortField == columnState.sortField,
            )

            if (updateIndex == -1) {
              state[tableName][configName].push({ ...column })

              return
            }

            state[tableName][configName][updateIndex] = {
              ...state[tableName][configName][updateIndex],
              ...column,
            }
          })
        } else {
          state[tableName][configName] = configurations[configName]
        }
      }
    },
    updateTableAutoSave: (state, { payload }) => {
      state[payload.tableName].autoSave = payload.autoSave
    },
  },
})

export default userTableConfigurationsSlice
