import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import useModifyParsedEmails from './useModifyParsedEmails'
import {
  IGetParsedEmailsParams,
  IGetParsedEmailsResponse,
} from '~/types/models/IParsedEmail'
import { buildGetUrl } from '~/utils/buildUrl'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryParsedEmails = (
  params: IGetParsedEmailsParams & { id?: number } = {},
  options?: Partial<UseQueryOptions<IGetParsedEmailsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'parsedEmails',
      sessionUser?.id,
      buildGetUrl(apiClient.parsedEmails.endpoint, params),
    ],
    async queryFn() {
      if (params.id) {
        const response = await apiClient.parsedEmails.getById(params.id)
        return {
          count: 1,
          countOnPage: 1,
          parsedEmails: [response.parsedEmail],
          endRange: 1,
          page: 1,
          pageCount: 1,
          perPage: 1,
          startRange: 1,
        }
      }
      const response = await apiClient.parsedEmails.get(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { updateParsedEmail, removeParsedEmail } = useModifyParsedEmails()

  const parsedEmailsData = useMemo(() => data?.parsedEmails || [], [data])

  const totalParsedEmailsData = data?.count || 0

  return {
    parsedEmailsData,
    isParsedEmailsLoading: isLoading,
    updateParsedEmail,
    removeParsedEmail,
    totalParsedEmailsData,
  }
}

export default useQueryParsedEmails
