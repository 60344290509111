import type { CSSProperties } from 'react'
import type {
  RTColumn,
  RTHeader,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../types'

export const parseCSSVarId = (id: string) => id.replace(/[^a-zA-Z0-9]/g, '_')

export const commonCellBeforeAfterStyles = {
  content: '""',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: -1,
}

export const getCommonPinnedCellStyles = <TData extends IReusableTableRowData>({
  column,
}: {
  column?: RTColumn<TData>
  table: ReusableTableInstance<TData>
}) => {
  const isPinned = column?.getIsPinned()

  return {
    '&[data-pinned="true"]': {
      '&:before': {
        backgroundColor: '#e5e5e5',
        boxShadow: column
          ? isPinned === 'left' && column.getIsLastColumn(isPinned)
            ? '-4px 0 4px -4px #e5e5e5 inset'
            : isPinned === 'right' && column.getIsFirstColumn(isPinned)
            ? '4px 0 4px -4px #e5e5e5 inset'
            : undefined
          : undefined,
        ...commonCellBeforeAfterStyles,
      },
    },
  }
}

export const getCommonMRTCellStyles = <TData extends IReusableTableRowData>({
  column,
  header,
  table,
  tableCellProps,
}: {
  column: RTColumn<TData>
  header?: RTHeader<TData>
  table: ReusableTableInstance<TData>
  tableCellProps: any
}) => {
  const {
    getState,
    options: { enableColumnVirtualization, layoutMode },
  } = table
  const { draggingColumn } = getState()
  const { columnDef } = column
  const { columnDefType } = columnDef

  const isColumnPinned =
    columnDef.columnDefType !== 'group' && column.getIsPinned()

  const widthStyles: CSSProperties = {
    minWidth: `max(calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id,
    )}-size) * 1px), ${columnDef.minSize ?? 30}px)`,
    width: `calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id,
    )}-size) * 1px)`,
  }

  if (layoutMode === 'grid') {
    widthStyles.flex = `${
      [0, false].includes(columnDef.grow!)
        ? 0
        : `var(--${header ? 'header' : 'col'}-${parseCSSVarId(
            header?.id ?? column.id,
          )}-size)`
    } 0 auto`
  } else if (layoutMode === 'grid-no-grow') {
    widthStyles.flex = `${+(columnDef.grow || 0)} 0 auto`
  }

  const pinnedStyles = isColumnPinned
    ? {
        left:
          isColumnPinned === 'left'
            ? `${column.getStart('left')}px`
            : undefined,
        opacity: 0.97,
        position: 'sticky',
        right:
          isColumnPinned === 'right'
            ? `${column.getAfter('right')}px`
            : undefined,
      }
    : {}

  return {
    backgroundColor: 'inherit',
    backgroundImage: 'inherit',
    display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
    justifyContent:
      columnDefType === 'group'
        ? 'center'
        : layoutMode?.startsWith('grid')
        ? tableCellProps.align
        : undefined,
    opacity:
      table.getState().draggingColumn?.id === column.id ||
      table.getState().hoveredColumn?.id === column.id
        ? 0.5
        : 1,
    position: 'relative',
    transition: enableColumnVirtualization
      ? 'none'
      : 'padding 150ms ease-in-out',
    zIndex:
      column.getIsResizing() || draggingColumn?.id === column.id
        ? 2
        : columnDefType !== 'group' && isColumnPinned
        ? 1
        : 0,
    ...pinnedStyles,
    ...widthStyles,
  }
}

export const getCommonToolbarStyles = (): React.CSSProperties => ({
  alignItems: 'flex-start',
  display: 'grid',
  flexWrap: 'wrap-reverse',
  minHeight: '3.5rem',
  overflow: 'hidden',
  position: 'relative',
  transition: 'all 150ms ease-in-out',
  zIndex: 1,
})

export const getCommonTooltipProps = (placement?: any) => ({
  disableInteractive: true,
  enterDelay: 1000,
  enterNextDelay: 1000,
  placement,
})
