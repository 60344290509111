var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Document } from 'react-pdf';
import { Box, makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function () { return ({
    DocumentContainer: {
        // Enforces that the canvas always only takes 100% of the available space
        // This prevents horizontal scroll
        // NOTE: The !important is needed to override the underlying react-pdf
        //       canvas width
        '& .react-pdf__Page__canvas, & .react-pdf__Page__textContent': {
            width: '100% !important',
        },
    },
    ImageView: {
        userSelect: 'none',
    },
}); });
/**
 * Reusable component that displays a document based off the type of
 * document that it is passed. Used for rendering doc extraction images.
 * @param props
 */
export var DocumentDisplayer = function (_a) {
    var document = _a.document, loaders = _a.loaders, pages = _a.pages;
    var classes = useStyles();
    return (_jsx(Box, __assign({ className: classes.DocumentContainer }, { children: _jsx(Document, __assign({ file: document, onLoadSuccess: loaders.onPdfDocumentLoadSuccess }, { children: pages })) })));
};
