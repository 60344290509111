import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import {
  ConcordFormDropdownV2,
  ConcordFormRadio,
  ConcordFormStructure,
  FileField,
  IConcordFormField,
  IDocumentFormProps,
} from '~/components/shared'
import { useQueryBuyerSellers, useQueryTerminals } from '~/hooks/useQueryData'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { REGEX_CHECK_URL } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { SocialMediaField } from '../EnrollmentProfileForm/SocialMediaField'
import { IDocumentFormData } from '~/types/models/IDocument'
import isBase64 from 'is-base64'
import { IUser } from '~/types/models/IUser'
import moment from 'moment'
import { toBase64 } from '~/utils'

function DocumentForm(props: IDocumentFormProps) {
  const { formData, afterCreate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const currentUser: IUser = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const {
    sellerCompanyOptionsWithCurrentCompany,
    buyerCompanyOptionsWithCurrentCompany,
    isLoadingBuyerSellers,
  } = useQueryBuyerSellers()

  const { isLoadingTerminals, terminalsData } = useQueryTerminals()

  const formRef = useRef<any>()

  const isUpdating = Boolean(formData?.id)

  function getFileTypeFromBase64(
    base64String: string | undefined,
  ): string | null | undefined {
    if (!base64String) {
      return undefined
    }
    const match = base64String.match(/^data:(.*?);base64,/)

    if (match && match[1]) {
      const mimeType = match[1]

      const [type, subtype] = mimeType.split('/')

      const fileType = subtype ? subtype : type

      if (fileType === 'pdf') {
        return 'pdf'
      }

      return 'image'
    }

    return null
  }

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'inputType',
        label: 'Select File Input Type',
        render({ name, label, setValue, watch }) {
          const value = watch(name, null)
          return (
            <ConcordFormRadio
              value={value}
              label={label as string}
              isRequired
              options={[
                {
                  label: 'File',
                  value: 'file',
                },
                {
                  label: 'URL',
                  value: 'url',
                },
              ]}
              onChange={(event, value) => {
                setValue(name, value)
                setValue('file', [])
                setValue('url', [])
              }}
            />
          )
        },
        type: EFieldType.radio,
        isRequired: true,
      },
      {
        name: 'file',
        label: 'Select File(s)',
        type: EFieldType.custom,
        isRequired: true,
        isHidden: ({ watch }) => watch('inputType') !== 'file',
        render({ label, name, watch, setValue, error, clearErrors }) {
          const file = watch(name, null)

          return (
            <FileField
              error={error}
              label={label}
              value={file}
              isRequired
              placeholder='Accept: image, pdf'
              onChange={async (newFiles: File[]) => {
                setValue(name, newFiles)
                clearErrors(['file'])
              }}
            />
          )
        },
      },
      {
        name: 'url',
        label: 'URL(s)',
        isRequired: true,
        isHidden: ({ watch }) => watch('inputType') !== 'url',
        render(params) {
          const {
            watch,
            name,
            setValue,
            errors = {},
            label,
            clearErrors,
          } = params
          const value = watch(name, [])
          const error = errors[name]

          return (
            <SocialMediaField
              label={label}
              value={value}
              onChange={newValue => {
                setValue(name, newValue)
                clearErrors(['url'])
              }}
              error={error || []}
            />
          )
        },
      },
      {
        name: 'buyerId',
        label: 'Buyer',
        type: EFieldType.singleSelect,
        options: buyerCompanyOptionsWithCurrentCompany,
        isLoading: isLoadingBuyerSellers,
        isReadOnly: EScope.buyer === currentScope,
        isRequired: true,
        onChange() {
          formRef.current?.setValue('buyerTerminalId', null)
        },
      },
      {
        name: 'sellerId',
        label: 'Seller',
        type: EFieldType.singleSelect,
        options: sellerCompanyOptionsWithCurrentCompany,
        isLoading: isLoadingBuyerSellers,
        isReadOnly: EScope.seller === currentScope,
        isRequired: true,
        onChange() {
          formRef.current?.setValue('sellerTerminalId', null)
        },
      },
      {
        name: 'buyerTerminalId',
        label: 'Buyer Terminal',
        render({ label, name, watch, error, setValue }) {
          const value = watch(name, null)
          const buyerId = watch('buyerId', null)
          const options = terminalsData.filter(
            ({ companyId }) => companyId === buyerId,
          )

          return (
            <ConcordFormDropdownV2
              options={options.map(({ id, code, name }) => ({
                value: id,
                label: `${code} - ${name}`,
              }))}
              value={value}
              error={error}
              label={label}
              isLoading={isLoadingTerminals}
              onChange={(event, { value }) => {
                setValue(name, value)
              }}
            />
          )
        },
      },
      {
        name: 'sellerTerminalId',
        label: 'Seller Terminal',
        render({ label, name, watch, error, setValue, errors }) {
          console.log('errors', errors)
          const value = watch(name, null)
          const sellerId = watch('sellerId', null)
          const options = terminalsData.filter(
            ({ companyId }) => companyId === sellerId,
          )

          return (
            <ConcordFormDropdownV2
              options={options.map(({ id, code, name }) => ({
                value: id,
                label: `${code} - ${name}`,
              }))}
              value={value}
              error={error}
              label={label}
              isLoading={isLoadingTerminals}
              onChange={(event, { value }) => {
                setValue(name, value)
              }}
            />
          )
        },
      },
      {
        name: 'docType',
        label: 'Document Type',
        isRequired: true,
        type: EFieldType.radio,
        options: [
          {
            label: 'Invoice',
            value: 'Invoice',
          },
          {
            label: 'Load',
            value: 'Load',
          },
        ],
      },
    ],
    [
      buyerCompanyOptionsWithCurrentCompany,
      currentScope,
      isLoadingBuyerSellers,
      isLoadingTerminals,
      sellerCompanyOptionsWithCurrentCompany,
      terminalsData,
    ],
  )

  const defaultValues: IDocumentFormData = {
    buyerId: null,
    sellerId: null,
    fileType: null,
    docType: null,
    name: '',
    userAccessId: null,
    file: [],
    url: [],
    buyerTerminalId: null,
    sellerTerminalId: null,
    inputType: 'file',
  }

  const schema = Yup.object({
    buyerId: Yup.number()
      .required('This field required!')
      .typeError('This field required!'),
    sellerId: Yup.number()
      .required('This field required!')
      .typeError('This field required!'),
    docType: Yup.string()
      .required('This field required!')
      .typeError('This field required!'),
    file: Yup.array().when('inputType', ([inputType], schema) => {
      if (inputType === 'file') {
        return schema.min(1, 'This field is required!')
      }
      return schema
    }),
    url: Yup.array()
      .of(Yup.string().matches(REGEX_CHECK_URL, 'Url is invalid'))
      .when('inputType', ([inputType], schema) => {
        if (inputType === 'url') {
          return schema.min(1, 'This field is required!')
        }
        return schema
      }),
  })

  const create = async (formValues: IDocumentFormData) => {
    const { inputType, file, url, ...payload } = formValues
    let apiCalls: any[] = []

    if (inputType === 'file') {
      apiCalls = (file as string[] | File[]).map(async fileInputted => {
        let file: string | undefined = undefined

        if (isBase64(fileInputted as string)) {
          file = fileInputted as string
        } else if (fileInputted instanceof File) {
          file = await toBase64(fileInputted)
        }

        return apiClient.documents.create({
          buyerId: payload.buyerId,
          sellerId: payload.sellerId,
          buyerTerminalId: payload.buyerTerminalId,
          sellerTerminalId: payload.sellerTerminalId,
          file,
          fileType: getFileTypeFromBase64(file),
          docType: payload.docType,
          userAccessId: currentUser.userAccess.id,
          name:
            fileInputted instanceof File
              ? fileInputted.name
              : `Document ${moment().format('YYYY-MM-DD H:m:s')}`,
        })
      })
    } else {
      apiCalls = (url as string[]).map(async urlInputted => {
        return apiClient.documents.create({
          buyerId: payload.buyerId,
          sellerId: payload.sellerId,
          buyerTerminalId: payload.buyerTerminalId,
          sellerTerminalId: payload.sellerTerminalId,
          url: urlInputted,
          docType: payload.docType,
          userAccessId: currentUser.userAccess.id,
          name: `Document ${moment().format('YYYY-MM-DD H:m:s')}`,
        })
      })
    }

    Promise.all(apiCalls)
    afterCreate && afterCreate()
  }

  const onSubmitForm = async (formValues: IDocumentFormData) => {
    setIsLoading(true)
    setBackendError('')
    try {
      await create(formValues)
    } catch (error) {
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentScope === EScope.seller) {
      formRef.current?.setValue('sellerId', currentCompany.id)
    } else {
      formRef.current?.setValue('buyerId', currentCompany.id)
    }
  }, [currentCompany.id, currentScope])

  return (
    <ConcordFormStructure
      error={backendError}
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      schema={schema}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
      ref={formRef}
      isHiddenCancelButton
      onSubmit={onSubmitForm}
    />
  )
}

export default DocumentForm
