import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordFormStructure,
  IConcordFormField,
  FileField,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import type { ILoadFormProps } from './type'
import './styles.scss'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryBuyerSellerProducts,
  useQueryBuyerSellers,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { toBase64 } from '~/utils'
import * as Yup from 'yup'

const LoadForm = (props: ILoadFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const {
    sellerCompanyOptionsWithCurrentCompany,
    buyerCompanyOptionsWithCurrentCompany,
    isLoadingBuyerSellers,
    sellerCompaniesWithCurrentCompany,
    buyerSellersData,
  } = useQueryBuyerSellers()

  const { terminalOptions, isLoadingTerminals } = useQueryTerminals()

  const { buyerSellerProducts, isLoadingBuyerSellerProducts } =
    useQueryBuyerSellerProducts({
      sellerId: sellerCompaniesWithCurrentCompany.map(({ id }) => id),
      filters: {
        joinsSellerProduct: true,
      },
    })

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const formRef = useRef<any>()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Images Or PDFs',
        name: 'images',
        type: EFieldType.custom,
        render({ setValue, watch, name, error, clearErrors }) {
          const images = watch(name, [])
          return (
            <FileField
              label='Load Images'
              error={error}
              value={images}
              onChange={(files: File[]) => {
                setValue(name, files)
                clearErrors(name)
              }}
            />
          )
        },
      },
      {
        name: 'showPreview',
        label: 'Show Preview After Parsing',
        type: EFieldType.checkbox,
      },
      {
        name: 'openLoad',
        label: 'Open Load Page',
        type: EFieldType.checkbox,
      },
      {
        name: 'buyerId',
        label: 'Buyer',
        type: EFieldType.singleSelect,
        options: buyerCompanyOptionsWithCurrentCompany,
        isReadOnly: currentScope === EScope.buyer,
        isLoading: isLoadingBuyerSellers,
        isRequired: true,
        size: 6,
        onChange() {
          formRef.current?.setValue('buyerTerminalId', null)
          formRef.current?.setValue('buyerSellerProductId', null)
        },
      },
      {
        name: 'buyerTerminalId',
        label: 'Buyer Terminal',
        type: EFieldType.singleSelect,
        size: 6,
        isLoading: isLoadingTerminals,
        isRequired: true,
        isDisabled({ watch }) {
          return !watch('buyerId')
        },
        hint({ watch }) {
          if (!watch('buyerId')) {
            return 'Select Buyer first'
          }
        },
        options({ watch }) {
          const buyerId = watch('buyerId', null)
          return terminalOptions.filter(
            ({ companyId }) => companyId === buyerId,
          )
        },
      },
      {
        name: 'sellerId',
        label: 'Seller',
        type: EFieldType.singleSelect,
        options: sellerCompanyOptionsWithCurrentCompany,
        isReadOnly: currentScope === EScope.seller,
        isLoading: isLoadingBuyerSellers,
        isRequired: true,
        size: 6,
        onChange() {
          formRef.current?.setValue('sellerTerminalId', null)
          formRef.current?.setValue('buyerSellerProductId', null)
        },
      },
      {
        name: 'sellerTerminalId',
        label: 'Seller Terminal',
        type: EFieldType.singleSelect,
        size: 6,
        isLoading: isLoadingTerminals,
        isRequired: true,
        isDisabled({ watch }) {
          return !watch('sellerId')
        },
        hint({ watch }) {
          if (!watch('sellerId')) {
            return 'Select Buyer first'
          }
        },
        options({ watch }) {
          const sellerId = watch('sellerId', null)
          return terminalOptions.filter(
            ({ companyId }) => companyId === sellerId,
          )
        },
      },
      {
        name: 'buyerSellerProductId',
        label: 'Product',
        type: EFieldType.singleSelect,
        isLoading: isLoadingBuyerSellerProducts,
        isRequired: true,
        hint({ watch }) {
          const buyerId = watch('buyerId', null)
          const sellerId = watch('sellerId', null)
          if (!buyerId) {
            return 'Select Buyer first'
          }
          if (!sellerId) {
            return 'Select Seller first'
          }
        },
        isDisabled({ watch }) {
          const buyerId = watch('buyerId', null)
          const sellerId = watch('sellerId', null)
          return !buyerId || !sellerId
        },
        options({ watch }) {
          const buyerId = watch('buyerId', null)
          const sellerId = watch('sellerId', null)
          const companyId = currentScope === EScope.buyer ? sellerId : buyerId
          const relationshipField =
            currentScope === EScope.buyer ? 'seller' : 'buyer'
          const relationship = buyerSellersData.find(
            item =>
              (item as any)[currentScope]?.id === currentCompany.id &&
              item[relationshipField]?.id === companyId,
          )
          const product = buyerSellerProducts.filter(
            ({ buyerSellerId }) => buyerSellerId === relationship?.id,
          )
          return product.map(
            ({ id, sellerProductName, sellerProductCode }) => ({
              value: id,
              label: sellerProductCode
                ? `${sellerProductCode} - ${sellerProductName}`
                : 'Unknown',
            }),
          )
        },
      },
      {
        label: 'Date',
        name: 'date',
        type: EFieldType.date,
        isRequired: true,
        size: 6,
      },
      {
        label: 'Ticket #',
        name: 'num',
        type: EFieldType.number,
        size: 6,
      },
      {
        label: 'Qty',
        name: 'qty',
        type: EFieldType.number,
        size: 4,
        isRequired: true,
      },
      {
        label: 'Loaded Qty',
        name: 'preDumpQty',
        type: EFieldType.number,
        size: 4,
      },
      {
        label: 'Empty Qty',
        name: 'postDumpQty',
        type: EFieldType.number,
        size: 4,
      },
    ],
    [
      buyerCompanyOptionsWithCurrentCompany,
      buyerSellerProducts,
      buyerSellersData,
      currentCompany.id,
      currentScope,
      isLoadingBuyerSellerProducts,
      isLoadingBuyerSellers,
      isLoadingTerminals,
      sellerCompanyOptionsWithCurrentCompany,
      terminalOptions,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        images: Yup.array().min(1, 'This field is required!'),
        buyerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        buyerTerminalId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerTerminalId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        buyerSellerProductId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        qty: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!')
          .min(0.01),
        preDumpQty: Yup.number().min(0.01).nullable(),
        postDumpQty: Yup.number().min(0.01).nullable(),
      }),
    [],
  )

  const handleSubmit = useCallback(
    async (formValues: any) => {
      setIsLoading(true)
      setError('')
      try {
        const apiCalls = formValues.images.map(async (image: File | string) =>
          apiClient.loads.create({
            load: {
              ...formValues,
              loadImage: image instanceof File ? await toBase64(image) : image,
            } as any,
            loadImageTimestamp: new Date().toISOString(),
            s3File: await toBase64(formValues.images[0]),
          } as any),
        )
        await Promise.all(apiCalls)
        afterCreate && afterCreate(formValues)
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [afterCreate],
  )

  useEffect(() => {
    if (!isUpdating) {
      if (currentScope === EScope.buyer) {
        formRef.current?.setValue('buyerId', currentCompany.id)
      } else {
        formRef.current?.setValue('sellerId', currentCompany.id)
      }
    }
  }, [currentCompany.id, currentScope, isUpdating])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        defaultValues={{
          images: [],
        }}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default LoadForm
