import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import ordersUiSlice from '~/redux/reducers/ui/orders'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'

export const actFetchOrders = createAsyncThunk(
  'actFetchOrders',
  async (payload, { dispatch, getState }) => {
    dispatch(ordersUiSlice.actions.setLoadingTable(true))
    try {
      const { filters } = getState().ui.orders
      const { orders } = await apiClient.orders.get({
        filters: filters,
      })
      dispatch(ordersSlice.actions.index(orders))
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(ordersUiSlice.actions.setLoadingTable(false))
    }
  },
)

export const actDeleteOrder = createAsyncThunk(
  'actDeleteOrder',
  async (payload, { dispatch }) => {
    dispatch(ordersUiSlice.actions.toggleTableLoadingRowModel(payload.id))
    try {
      const response = await apiClient.orders.delete(payload.id)
      if (response.id) {
        dispatch(ordersSlice.actions.deleteOrder(response.id))
      } else {
        toast.error(toastMessages.deleteError)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.deleteError)
    } finally {
      dispatch(ordersUiSlice.actions.toggleTableLoadingRowModel(payload.id))
    }
  },
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {},
  reducers: {
    index: (_state, action) => {
      const newState = {}

      if (action.payload)
        action.payload.forEach(order => {
          newState[order.id] = { ...order }
        })

      return newState
    },
    updateOrder: (state, { payload }) => {
      if (state[payload.id]) {
        state[payload.id] = {
          ...state[payload.id],
          ...payload,
        }
        if (!_.isNil(payload.deletedAt)) {
          delete state[payload.id]
        }
      } else {
        state[payload.id] = payload
      }
    },
    addMoreOrders(state, { payload }) {
      payload.forEach(order => {
        state[order.id] = order
      })
    },
    deleteOrder: (state, { payload }) => {
      try {
        if (payload) {
          delete state[payload]
        } else {
          toast.error(toastMessages.deleteError)
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
    incrementOrder: (state, { payload }) => {
      const { id, ...rest } = payload

      for (const [key, value] of Object.entries(rest)) {
        if (_.isString(value)) {
          state[id][key] = (
            parseFloat(state[id][key]) + parseFloat(value)
          ).toFixed(1)
        } else {
          state[id][key] += value
        }

        if (parseFloat(state[id][key]) >= parseFloat(state[id].qty))
          state[id].status = 'Complete'
      }
    },
  },
})

export default ordersSlice
