import { ConcordFormLayout, ReusableDatePicker } from '~/components/shared'
import { Form } from 'react-bootstrap'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import './ConcordFormDatePicker.scss'

function ConcordFormDatePicker(props) {
  const {
    onChange,
    label,
    loading,
    className,
    error,
    date,
    isRequired,
    placeholder,
    isDisabled,
    minDate,
    maxDate,
    isReadOnly,
    dateFormat,
    yearRange,
  } = props

  return (
    <ConcordFormLayout
      label={label}
      className={clsx(className, 'ConcordFormDatePicker__root', {
        disabled: loading,
        isReadOnly,
      })}
      error={error}
      loading={loading}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <ReusableDatePicker
        isDisabled={isDisabled || loading}
        onChange={onChange}
        placeholder={placeholder}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        yearRange={yearRange}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

ConcordFormDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

export default ConcordFormDatePicker
