import { useState, useMemo, useCallback, useEffect } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { CompanyTable, Loading } from '~/components/shared'
import CompanyUserGroupBody from '~/components/company/CompanyUserGroupBody'
import UserGroupForm from '~/components/company/CompanyUserGroupForm'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import getSearchedData from '~/utils/getSearchedData'
import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'

function CompanyUserGroupSubTab() {
  const [companyUserGroups, setCompanyUserGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [currentRowData, setCurrentRowData] = useState()

  const { confirmation } = useConfirmationProvider()

  const mapCompanyUserGroups = useMemo(() => {
    return getSearchedData(companyUserGroups, searchKey, ['name', 'code'])
  }, [companyUserGroups, searchKey])

  const handleSearchKeyChange = useCallback(newSearchKey => {
    setSearchKey(newSearchKey)
  }, [])

  const getApiCall = useCallback(() => {
    return apiClient.userGroups.get()
  }, [])

  const getUserGroupData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setCompanyUserGroups(response.userGroups)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleToggleForm = useCallback(nextValue => {
    setIsOpenForm(nextValue)
    if (!nextValue) {
      setCurrentRowData()
    }
  }, [])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete user group #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.userGroups.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setCompanyUserGroups(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setIsOpenForm(true)
    setCurrentRowData(rowData)
  }, [])

  const updateRow = useCallback(
    async formValues => {
      setLoading(true)
      try {
        setCompanyUserGroups(prev =>
          produce(prev, draft => {
            const index = _.findIndex(draft, { id: formValues.id })
            if (index > -1) {
              draft[index] = {
                ...draft[index],
                ...formValues,
              }
            }
          }),
        )
        handleToggleForm(false)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setLoading(false)
      }
    },
    [handleToggleForm],
  )

  const addRow = useCallback(
    async formValues => {
      try {
        setCompanyUserGroups(prev => [...prev, formValues])
        handleToggleForm(false)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.createError)
      }
    },
    [handleToggleForm],
  )

  useEffect(() => {
    getUserGroupData()
  }, [getUserGroupData])

  return (
    <>
      {loading && <Loading />}
      <CompanyTable
        description='View and control the various user groups.'
        title='User Groups'
        renderCountText={() => `${mapCompanyUserGroups.length} User Groups`}
        buttonProps={{
          buttonText: 'Add User Group',
          loading,
        }}
        FormComponent={UserGroupForm}
        formProps={{
          isLoading: loading,
          isOpen: isOpenForm,
          onToggleForm: handleToggleForm,
          formData: currentRowData,
          afterCreate(newData) {
            addRow(newData)
            handleToggleForm()
          },
          afterUpdate(newData) {
            updateRow(newData)
            handleToggleForm()
          },
        }}
        renderTable={() => (
          <CompanyUserGroupBody
            userGroups={mapCompanyUserGroups}
            onEditClick={handleOpenUpdateForm}
            onDeleteClick={handleDeleteRow}
          />
        )}
        filterProps={{
          searchKey,
          moduleName: moduleName.company.userGroups,
          onSearchKeyChange: handleSearchKeyChange,
        }}
      />
    </>
  )
}

CompanyUserGroupSubTab.propTypes = {}

export default CompanyUserGroupSubTab
