import useCheckCompanyInfoSection from './useCheckCompanyInfoSection'

import { InfoSection } from '../InfoSection'
import { DialogCreateCheckCompany } from '../DialogCreateCheckCompany'

import type { ICheckCompanyInfoSectionProps } from './type'

function CheckCompanyInfoSection(props: ICheckCompanyInfoSectionProps) {
  const {
    renderCompanyCheckInfo,
    isLoadingCheckCompany,
    onEdit,
    isOpenCompanyDialog,
    companyFormValues,
    afterUpdateCheckCompany,
    onCloseCompanyDialog,
  } = useCheckCompanyInfoSection(props)

  return (
    <>
      <InfoSection
        data={renderCompanyCheckInfo}
        isLoading={isLoadingCheckCompany}
        color='info'
        isHiddenEditButton={false}
        onEdit={onEdit}
      />
      <DialogCreateCheckCompany
        isOpen={isOpenCompanyDialog}
        onClose={onCloseCompanyDialog}
        formValues={companyFormValues}
        afterUpdateCheckCompany={afterUpdateCheckCompany}
      />
    </>
  )
}
export default CheckCompanyInfoSection
