import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useQueryPayrolls } from '~/hooks/useQueryData'
import { usePayrollReportFormValuesProvider } from '~/contexts'

import {
  Button,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'
import { When } from 'react-if'
import ContractorPaymentReportsTable from './ContractorPaymentReportsTable'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { ApiClientRequestOption, apiClient } from '~/api/ApiClient'

function ContractorPaymentReportsForm() {
  const formRef = useRef<any>()

  const isMobile = useIsMobile()

  const { payrollsOptions } = useQueryPayrolls()

  const { reportFormValues, onChangeReportFormValues } =
    usePayrollReportFormValuesProvider()

  const formData = useMemo(
    () => ({
      content: reportFormValues.content,
      date: reportFormValues.dateRange,
      payroll: reportFormValues.payroll,
      preview: false,
    }),
    [
      reportFormValues.content,
      reportFormValues.dateRange,
      reportFormValues.payroll,
    ],
  )

  const [summaryData, setSummaryData] = useState([])
  const [isShowTable, setIsShowTable] = useState(false)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'date',
        label: 'Date',
        type: EFieldType.dateRange,
        size: isMobile ? 12 : 3,
        isRequired: true,
      },
      {
        name: 'payroll',
        label: 'Payroll',
        type: EFieldType.multipleSelect,
        size: isMobile ? 12 : 3,
        options: payrollsOptions,
      },
      {
        name: 'content',
        label: 'Content',
        size: 12,
        type: EFieldType.radio,
        options: [
          {
            label: 'Table',
            value: 'table',
          },
          {
            label: 'CSV',
            value: 'csv',
          },
        ],
      },
      {
        name: 'preview',
        label: 'Preview',
        size: 12,
        type: EFieldType.checkbox,
      },
    ],
    [isMobile, payrollsOptions],
  )

  const onSubmit = useCallback(() => {
    formRef.current.handleSubmit(async (formData: any) => {
      try {
        const params: Partial<ApiClientRequestOption> = {
          query: {},
        }
        params.query.qsParams = {
          payload: formData.payroll,
          preview: formData.preview,
          start: moment(formData.date.startDate).format('YYYY-MM-DD'),
          end: moment(formData.date.endDate).format('YYYY-MM-DD'),
        }
        if (formData.content === 'csv') {
          params.query.csv = true
          params.isCsv = true
        }
        onChangeReportFormValues({
          dateRange: formData.date,
          payroll: formData.payroll,
          content: formData.content,
        })
        const response = await apiClient.check.contractorPaymentsReport(params)
        setSummaryData(response.results)
        setIsShowTable(true)
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [onChangeReportFormValues])

  return (
    <div>
      <ConcordFormStructure
        fields={fields}
        isHiddenCancelButton
        isHiddenSubmitButton
        ref={formRef}
        formData={formData}
      />
      <Button label='Submit' onClick={onSubmit} />
      <When condition={isShowTable}>
        <ContractorPaymentReportsTable data={summaryData} />
      </When>
    </div>
  )
}
export default ContractorPaymentReportsForm
