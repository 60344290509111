import { EAddressType, EAddressTypeStr } from '~/types/enums/EAddress'
import { IAddress } from '~/types/models/IAddress'

const getAddressType = (type: EAddressType) => {
  switch (type) {
    case EAddressType.ap: {
      return 'AP'
    }
    case EAddressType.ar: {
      return 'AR'
    }
    case EAddressType.mailing: {
      return 'Mailing'
    }
    case EAddressType.office: {
      return 'Office'
    }
    case EAddressType.residence: {
      return 'Residence'
    }
    case EAddressType.billing: {
      return 'Biling'
    }
    case EAddressType.hr: {
      return 'HR'
    }
    case EAddressType.location: {
      return 'Location'
    }
    case EAddressType.other: {
      return 'Other'
    }
    case EAddressType.physical: {
      return 'Physical'
    }
  }
}

export const convertAddressTypeFromEnumToString = (type: EAddressType) => {
  switch (type) {
    case EAddressType.ap: {
      return EAddressTypeStr.ap
    }
    case EAddressType.ar: {
      return EAddressTypeStr.ar
    }
    case EAddressType.billing: {
      return EAddressTypeStr.billing
    }
    case EAddressType.hr: {
      return EAddressTypeStr.hr
    }
    case EAddressType.location: {
      return EAddressTypeStr.location
    }
    case EAddressType.mailing: {
      return EAddressTypeStr.mailing
    }
    case EAddressType.office: {
      return EAddressTypeStr.office
    }
    case EAddressType.other: {
      return EAddressTypeStr.other
    }
    case EAddressType.physical: {
      return EAddressTypeStr.physical
    }
    case EAddressType.residence: {
      return EAddressTypeStr.residence
    }
  }
}

const getAddressTypesText = (address: IAddress) => {
  const arr: string[] = []
  address.addressTypes.forEach(val => {
    arr.push(getAddressType(val))
  })

  return arr.join(', ')
}

export default getAddressTypesText
