import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'
import companyEmailsUiSlice from '~/redux/reducers/ui/companyEmails'
import {
  selectMyCurrentCompany,
  selectCompanyEmailsFilters,
} from '~/redux/selectors'

export const fetchCompanyEmails = createAsyncThunk(
  'fetchCompanyEmails',
  async (payload, { dispatch, getState }) => {
    dispatch(companyEmailsUiSlice.actions.setLoadingTable(true))
    try {
      const state = getState()
      const currentCompany = selectMyCurrentCompany(state)
      const filters = selectCompanyEmailsFilters(state)
      const { emails } = await apiClient.emails.get({
        filters: {
          companyId: currentCompany.id,
          ...filters,
        }
      })
      dispatch(companyEmailSlice.actions.index(emails))
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(companyEmailsUiSlice.actions.setLoadingTable(false))
    }
  },
)

const companyEmailSlice = createSlice({
  name: 'companyEmail',
  initialState: {},
  reducers: {
    index(_state, { payload }) {
      const dataList = {}
      payload.forEach(item => {
        dataList[item.id] = item
      })
      return dataList
    },
    add(state, { payload }) {
      state[payload.id] = payload
    },
    update(state, { payload }) {
      state[payload.id] = {
        ...state[payload.id],
        ...payload,
      }
    },
    remove(state, { payload }) {
      delete state[payload.id]
    },
  },
})

export default companyEmailSlice
