import companiesSlice from '~/redux/reducers/data/companies.js'
import companiesUiSlice from '~/redux/reducers/ui/companies.js'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt.js'
import { updateBuyerSeller } from '~/redux/actions/CommonData/buyerSellerActions'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { sessionService } from 'redux-react-session'
import { apiClient } from '~/api/ApiClient'

export const getCompanies =
  (getAll = false) =>
    dispatch => {
      dispatch(companiesUiSlice.actions.updateUi({ loading: true }))
      apiClient.companies.get({ all: getAll })
        .then(response => {
          const unix = Math.round(+new Date() / 1000)
          dispatch(companiesSlice.actions.initializeCompanies(response))
          dispatch(
            lastFetchedAtSlice.actions.updateLastFetchedAt({
              model: 'companies',
              lastFetchedAt: unix,
            }),
            dispatch(companiesUiSlice.actions.updateUi({ loading: false })),
          )
        })
        .catch(error => {
          console.log(error)
          dispatch(companiesUiSlice.actions.updateUi({ loading: false }))
        })
    }

export const getCompanyById = companyId => dispatch => {
  dispatch(companiesUiSlice.actions.updateUi({ loading: true }))
  apiClient.companies.getById(companyId)
    .then(async (company) => {
      const sessionData = await sessionService.loadUser()

      const companyDetail = {
        ...sessionData.company,
            ...company,
      }
      dispatch(
        companiesUiSlice.actions.updateUi({
          loading: false,
          companyDetail: companyDetail,
        }),
      )
      if (sessionData?.company?.id == companyId) {
        const newData = {
          ...sessionData,
          company: companyDetail,
          currentFleet: companyDetail,
        }
        await sessionService.saveUser(newData)
      }
      dispatch(updateCompany(companyDetail))
    })
    .catch(error => {
      console.log(error)
      dispatch(companiesUiSlice.actions.updateUi({ loading: false, error }))
    })
}

export const updateCompanyById = (companyId, params) => async (dispatch) => {
  dispatch(
    companiesUiSlice.actions.updateUi({
      loading: true,
      updateSuccess: false,
      error: undefined,
    }),
  )
  try {
    const response = await apiClient.companies.update(companyId, { company: params })

    dispatch(
      companiesUiSlice.actions.updateUi({
        loading: false,
        companyDetail: {
          ...response.company,
          dateTimeFormat: params.dateTimeFormat,
          timeFormat: params.timeFormat
        },
        updateSuccess: true,
      }),
    )
    dispatch(updateCompany({
      ...response,
      company: {
        ...response.company,
        dateTimeFormat: params.dateTimeFormat,
        timeFormat: params.timeFormat
      }
    }))

    const user = await sessionService.loadUser()

    if (user.company.id === response.company.id) {
      sessionService.saveUser({
        ...user,
        company: {
          ...response.company,
          dateTimeFormat: params.dateTimeFormat,
          timeFormat: params.timeFormat
        }
      })
    }

    toast.success(toastMessages.updateSuccess)
  } catch (error) {
    console.log(error)
    dispatch(
      companiesUiSlice.actions.updateUi({ loading: false, error }),
    )
    toast.error(toastMessages.updateError)
  }

}

export const fetchAllCompanies = () => dispatch => {
  dispatch(companiesUiSlice.actions.updateUi({ loading: true }))
  try {
    apiClient.companies.get({ all: true }).then(response => {
      dispatch(
        companiesUiSlice.actions.initializeAllCompanies(response.companies),
      )
      dispatch(companiesUiSlice.actions.updateUi({ loading: false }))
    })
  } catch (error) {
    console.log('error', error)
    dispatch(companiesUiSlice.actions.updateUi({ loading: false }))
  }
}

export const updateCompany = payload => dispatch => {
  if (payload.buyerSeller) {
    dispatch(updateBuyerSeller(payload.buyerSeller))
  }
  dispatch(companiesSlice.actions.updateCompany(payload))
}

export const updateCompanies = payload => dispatch => {
  dispatch(companiesSlice.actions.updateCompanies(payload))
}

export const updateCompaniesUi = payload => dispatch => {
  dispatch(
    companiesUiSlice.actions.updateUi({ [payload.field]: [payload.value] }),
  )
}
