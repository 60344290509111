const DEFAULT_COMPANY_USER_COLUMNS = [
  {
    displayIndex: 0,
    field: 'id',
    show: true,
    label: 'Id',
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    sortIndex: 0,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    field: 'firstName',
    show: true,
    label: 'Profile',
    value: 'firstName',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'firstName',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    field: 'phoneNumber',
    show: true,
    label: 'Phone',
    value: 'phoneNumber',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 2,
    isAsc: true,
    sortField: 'phoneNumber',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 3,
    label: 'User Group(s)',
    field: 'userAccess.userGroupId',
    show: true,
    value: 'userAccess.userGroupId',
    type: 'number',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'userAccess.userGroupId',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 4,
    label: 'Status',
    field: 'userAccess.status',
    show: true,
    value: 'userAccess.status',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 3,
    isAsc: true,
    sortField: 'userAccess.status',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 5,
    label: 'Default',
    field: 'userAccess.default',
    show: true,
    value: 'userAccess.default',
    type: 'string',
    sorted: false,
    sortable: true,
    sortIndex: 4,
    isAsc: true,
    sortField: 'userAccess.default',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 6,
    label: 'Disallowed Scopes',
    field: 'userAccess.disallowedScopes',
    show: true,
    value: 'userAccess.disallowedScopes',
    type: 'string',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'userAccess.disallowedScopes',
    isHidden: false,
    hideable: true,
  },
  {
    displayIndex: 7,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    isHidden: false,
    hideable: true,
  },
]

export default DEFAULT_COMPANY_USER_COLUMNS
