import { useState, useEffect, useCallback, useRef, useMemo } from 'react'

import { RegexField, ToolTipOverlay } from '~/components/shared'
import KeyValueFormFieldLayout from '../FieldLayout'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './KeyValueFormRegexField.scss'

const KeyValueFormRegexField = props => {
  const {
    label,
    value,
    onChange,
    className,
    onConfirmUpdate,
    field,
    loading,
    editable,
    hideConfirmButton,
    error,
    ...inputProps
  } = props

  const inputRef = useRef()

  const [regexValue, setRegexValue] = useState('')

  const formatRegex = useCallback(regex => {
    if (_.size(regex) === 0) {
      return '//'
    }
    const [, , regexStr, flags] = regex.match(/(\/?)(.+)\1([a-z]*)/i)

    return `/${regexStr}/${flags}`
  }, [])

  const isEdited = useMemo(() => {
    const regexState = formatRegex(regexValue)
    const regexProps = formatRegex(value)

    return regexState !== regexProps
  }, [formatRegex, regexValue, value])

  useEffect(() => {
    setRegexValue(value)
  }, [value])

  const handleChange = useCallback(
    (event, { value }) => {
      setRegexValue(value)
      onChange && onChange(event, value)
    },
    [onChange],
  )

  const handleConfirmUpdateValue = useCallback(
    event => {
      const newValue = {
        field,
        value: regexValue,
      }
      onConfirmUpdate && onConfirmUpdate(event, newValue)
      inputRef.current.blur()
    },
    [field, onConfirmUpdate, regexValue],
  )

  const handleKeyPress = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleConfirmUpdateValue(event)
      } else if (event.keyCode === 27) {
        inputRef.current.blur()
      }
    },
    [handleConfirmUpdateValue],
  )

  return (
    <KeyValueFormFieldLayout
      hideConfirmButton={hideConfirmButton}
      label={label}
      loading={loading}
      editable={editable}
      renderValue={() => regexValue}
      isEdited={isEdited}
      onConfirm={handleConfirmUpdateValue}
    >
      <ToolTipOverlay content={error} placement='bottom'>
        <RegexField
          value={regexValue}
          onChange={handleChange}
          className={clsx(
            'KeyValueFormFieldLayout__input KeyValueFormRegexField__input',
            { error },
          )}
          onKeyDown={handleKeyPress}
          ref={inputRef}
          {...inputProps}
        />
      </ToolTipOverlay>
    </KeyValueFormFieldLayout>
  )
}

KeyValueFormRegexField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onConfirmUpdate: PropTypes.func,
  field: PropTypes.string,
}

export default KeyValueFormRegexField
