import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchFieldValidations } from '~/redux/actions/fieldValidationsActions'

export const useInitializeReduxFieldValidationsStore = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFieldValidations())
  }, [dispatch])
}
