import _ from 'lodash'
import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { selectFleetRelationshipOptions } from './selectBuyerSellers'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { selectMyCurrentCompany, selectSessionUser } from './selectSessionUser'

export const selectCompanies = state => state.data.companies
const companyUiState = state => state.ui.companies
const selectBuyerSellers = state => state.data.buyerSellers
const selectCurrentCompanyId = state => state.session?.user?.company?.id

export const selectCurrentCompanies = createSelector(
  selectCompanies,
  companies => columnHashToColumnsArray(companies),
)

export const selectCompanyDetail = createSelector(
  companyUiState,
  state => state.companyDetail || {},
)

export const selectCompanyUiLoading = createSelector(
  companyUiState,
  state => state.loading,
)

const selectCompaniesFleet = createSelector(selectCurrentCompanies, companies =>
  companies
    .filter(company => company.fleet === EYesNo.Yes)
    .map(c => ({
      ...c,
      image: c.logo,
    })),
)

const selectCompaniesFleetOptions = createSelector(
  selectCompaniesFleet,
  fleets =>
    _(fleets)
      .map(fleet => ({
        value: fleet.id,
        label: fleet.name,
        name: fleet.name,
        code: fleet.code,
        image: fleet.image,
      }))
      .orderBy('label')
      .value(),
)

export const selectCurrentCompanyOptions = createSelector(
  selectCurrentCompanies,
  companies =>
    companies.map(company => ({
      label: `${company.code} - ${company.name}`,
      value: company.id,
      imageUrl: company?.logo,
      image: company?.logo,
      name: company.name,
      code: company.code,
    })),
)

export const selectSellerCompanies = createSelector(
  selectCurrentCompanies,
  companies => companies.filter(company => company.seller === EYesNo.Yes),
)

export const selectBuyerCompanies = createSelector(
  selectCurrentCompanies,
  companies => companies.filter(company => company.buyer === EYesNo.Yes),
)

const selectFleetCompanies = createSelector(selectCurrentCompanies, companies =>
  companies.filter(company => company.fleet === EYesNo.Yes),
)

export const selectCompanyBuyerOptions = createSelector(
  selectBuyerCompanies,
  buyer =>
    _(buyer)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        otherOption: true,
      }))
      .orderBy('label')
      .value(),
)

export const selectCompanySellerOptions = createSelector(
  selectSellerCompanies,
  sellers =>
    _(sellers)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        otherOption: true,
      }))
      .orderBy('label')
      .value(),
)

export const selectCompanyFleetOptions = createSelector(
  selectFleetCompanies,
  sellers =>
    _(sellers)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        otherOption: true,
      }))
      .orderBy('label')
      .value(),
)

export const selectAllFleetOptions = createSelector(
  selectFleetRelationshipOptions,
  selectCompaniesFleetOptions,
  (fleetRelationships, companiesFleets) =>
    _.unionBy(fleetRelationships, companiesFleets, 'value'),
)

////////////////////////////// BUYER COMPANIES ///////////////////////////////////

export const selectBuyersFromCompanies = createSelector(
  selectCompanies,
  selectBuyerSellers,
  selectCurrentCompanyId,

  (companies, buyerSellers, curId) => {
    const companiesArray = columnHashToColumnsArray(companies)
    const bsArray = columnHashToColumnsArray(buyerSellers)

    if (companiesArray.length === 0 || bsArray.length === 0) {
      return []
    }

    return companiesArray
      .filter(company => {
        for (let i = 0; i < bsArray.length; i++) {
          if (bsArray[i].buyerId === company.id && curId != company.id) {
            return true
          }
        }
      })
      .map(company => {
        return {
          ...company,
        }
      })
  },
)

export const selectCommonBuyerOptions = createSelector(
  selectBuyersFromCompanies,

  buyers => {
    return buyers.map(buyer => {
      const { id, code, name, imageUrl } = buyer
      const label = code ? `${code} - ${name}` : name

      return {
        label,
        value: id,
        checked: false,
        imageUrl,
        id,
        name,
        code,
      }
    })
  },
)

export const selectBuyerOptionsWithCurrentCompany = createSelector(
  selectCommonBuyerOptions,
  selectMyCurrentCompany,
  selectSessionUser,
  (buyerOptions, currentCompany, currentUser) => {
    if (currentUser.currentScope === EScope.buyer) {
      return [
        {
          label: `${currentCompany.code} - ${currentCompany.name}`,
          value: currentCompany.id,
          checked: false,
          imageUrl: currentCompany.avatar,
          id: currentCompany.id,
          name: currentCompany.name,
          code: currentCompany.code,
        },
        ...buyerOptions,
      ]
    }

    return buyerOptions
  },
)

export const selectSortedBuyerCompanies = createSelector(
  selectBuyersFromCompanies,
  buyerCompanies => _.orderBy(buyerCompanies, 'name'),
)

////////////////////////////////  SELLER COMPANIES /////////////////////////////////

export const selectSellersFromCompanies = createSelector(
  selectCompanies,
  selectBuyerSellers,
  selectCurrentCompanyId,

  (companies, buyerSellers, curId) => {
    const companiesArray = columnHashToColumnsArray(companies)
    const bsArray = columnHashToColumnsArray(buyerSellers)

    if (companiesArray.length === 0 || bsArray.length === 0) {
      return []
    }

    return companiesArray
      .filter(company => {
        for (let i = 0; i < bsArray.length; i++) {
          if (bsArray[i].sellerId === company.id && curId != company.id) {
            return true
          }
        }
      })
      .map(company => {
        return {
          ...company,
        }
      })
  },
)

export const selectCommonSellerOptions = createSelector(
  selectSellersFromCompanies,

  sellers => {
    const mapSellers = []
    sellers.forEach(seller => {
      const { id, code, name, imageUrl } = seller
      const label = `${code} - ${name}`

      mapSellers.push({
        id,
        value: id,
        label,
        checked: false,
        imageUrl,
        name,
        code,
        image: imageUrl,
      })
    })

    return _.sortBy(mapSellers, 'label')
  },
)

export const selectSellerOptionsWithCurrentCompany = createSelector(
  selectCommonSellerOptions,
  selectMyCurrentCompany,
  selectSessionUser,
  (sellerOptions, currentCompany, currentUser) => {
    if (currentUser.currentScope === EScope.seller) {
      return [
        {
          label: `${currentCompany.code} - ${currentCompany.name}`,
          value: currentCompany.id,
          checked: false,
          imageUrl: currentCompany.avatar,
          id: currentCompany.id,
          name: currentCompany.name,
          code: currentCompany.code,
        },
        ...sellerOptions,
      ]
    }

    return sellerOptions
  },
)

export const selectCommonSellerOptionWithNotSet = createSelector(
  selectCommonSellerOptions,
  sellers => {
    const notSetOption = {
      label: 'Not Set',
      value: 'null',
    }

    return [notSetOption, ...sellers]
  },
)

export const selectSortedSellerCompanies = createSelector(
  selectSellersFromCompanies,
  sellerCompanies => _.orderBy(sellerCompanies, 'name'),
)

/////////////////////// Both Companies //////////////////

export const selectMergeSellerBuyerOptions = createSelector(
  selectCommonSellerOptions,
  selectCommonBuyerOptions,

  (sellers, buyers) => _.merge(sellers, buyers),
)

/////////////////////// All Companies /////////////////////////
export const selectAllCompanies = createSelector(companyUiState, state =>
  columnHashToColumnsArray(state.allCompanies),
)

export const selectAllBuyerCompanies = createSelector(
  selectAllCompanies,
  companies => companies.filter(company => company.buyer === EYesNo.Yes),
)

export const selectAllSellerCompanies = createSelector(
  selectAllCompanies,
  companies => companies.filter(company => company.seller === EYesNo.Yes),
)
export const selectAllBuyerCompaniesOptions = createSelector(
  selectAllBuyerCompanies,
  sellers =>
    _(sellers)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        allOption: true,
      }))
      .orderBy('label')
      .value(),
)

export const selectAllSellerCompaniesOptions = createSelector(
  selectAllSellerCompanies,
  sellers =>
    _(sellers)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        allOption: true,
      }))
      .orderBy('label')
      .value(),
)

export const selectAllFleetCompanies = createSelector(
  selectAllCompanies,
  companies => companies.filter(company => company.fleet === EYesNo.Yes),
)

export const selectAllFleetCompaniesOptions = createSelector(
  selectAllFleetCompanies,
  fleets =>
    _(fleets)
      .map(({ id, code, name, logo }) => ({
        value: id,
        name,
        code,
        label: `${code} - ${name}`,
        image: logo,
        allOption: true,
      }))
      .orderBy('label')
      .value(),
)
