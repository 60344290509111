import { combineReducers } from 'redux'
import { sessionReducer as session } from 'redux-react-session'
import { connectRouter } from 'connected-react-router'
import myCompany from './myCompanyReducer'
import load from './loadReducer'
import truck from './truckReducer'
import breadcrumbLoad from './breadcrumbLoadReducer'
import notes from './noteReducer'
import chat from './chatReducer'
import orders from './ordersReducer'
import startTimes from './startTimesReducer'
import schedules from './schedulesReducer'
import terminal from './terminalReducer'
import document from './documentReducer'
import invoice from './invoiceReducer'
import app from './appReducer'
import documents from './documentsReducer'
import dashboard from './dashboardReducer'
import sidebar from './sidebarReducer'
import deltaFetch from './deltaFetchReducer'
import userAccesses from './userAccessReducer'
import loadDeleteFieldModal from './loadDeleteFieldModalReducer'
import data from './data/index'
import ui from './ui/index'

const rootReducer = history =>
  combineReducers({
    app,
    router: connectRouter(history),
    session,
    breadcrumbLoad,
    chat,
    dashboard,
    data,
    deltaFetch,
    document,
    documents,
    invoice,
    load,
    loadDeleteFieldModal,
    myCompany,
    notes,
    orders,
    startTimes: startTimes.reducer,
    schedules,
    sidebar,
    terminal,
    truck,
    userAccesses,
    ui,
  })

export default rootReducer
