import { useState, useEffect, useMemo, useCallback } from 'react'

// see https://getbootstrap.com/docs/5.0/layout/breakpoints/
export const BREAK_POINT = {
  xs: 0,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
}

export const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState('')
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    orientation: 0,
  })

  const platForm = useMemo(() => {
    if (breakpoint === 'xl') {
      return 'desktop'
    }

    if (breakpoint === 'lg') {
      return 'tablet'
    }

    return 'mobile'
  }, [breakpoint])

  const isMobileScreen = useMemo(
    () => windowSize.width < BREAK_POINT.lg,
    [windowSize.width],
  )
  const isMobileSmallScreen = useMemo(
    () => windowSize.width < BREAK_POINT.md,
    [windowSize.width],
  )

  const getMax = useCallback(breakpoint => {
    if (breakpoint === 'xl') {
      return null
    }

    let value = undefined

    const arrBreakpoint = Object.keys(BREAK_POINT)

    arrBreakpoint.forEach((key, index) => {
      if (breakpoint === key) {
        const nextKey = arrBreakpoint[index + 1]
        value = BREAK_POINT[nextKey]
      }
    })

    return value - 1
  }, [])

  const isScreenUp = useCallback(
    breakpoint => {
      return windowSize.width > BREAK_POINT[breakpoint]
    },
    [windowSize],
  )

  const isScreenDown = useCallback(
    breakpoint => {
      return windowSize.width <= BREAK_POINT[breakpoint]
    },
    [windowSize],
  )

  const isScreenOnly = useCallback(
    breakpoint => {
      const min = BREAK_POINT[breakpoint]
      const max = getMax(breakpoint)

      if (!max) {
        return isScreenUp(breakpoint)
      }

      return windowSize.width >= min && windowSize.width <= max
    },
    [getMax, isScreenUp, windowSize.width],
  )

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      orientation: window.orientation,
    })
  }

  const isLandscape = useMemo(() => {
    return windowSize.orientation === 90 || windowSize.orientation === -90
  }, [windowSize.orientation])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (0 < windowSize.width && windowSize.width < BREAK_POINT.md) {
      setBreakPoint('xs')
    }
    if (
      BREAK_POINT.md < windowSize.width &&
      windowSize.width < BREAK_POINT.lg
    ) {
      setBreakPoint('md')
    }
    if (
      BREAK_POINT.lg < windowSize.width &&
      windowSize.width < BREAK_POINT.xl
    ) {
      setBreakPoint('lg')
    }
    if (windowSize.width >= BREAK_POINT.xl) {
      setBreakPoint('xl')
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])

  return {
    breakpoint,
    windowSize,
    platForm,
    isMobileScreen,
    isMobileSmallScreen,
    isLandscape,
    isScreenUp,
    isScreenDown,
    isScreenOnly,
  }
}
