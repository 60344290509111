import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { getTitleRange } from '~/utils'

import type { IUser } from '~/types/models/IUser'
import type { IGetInvoicesParams, IInvoice } from '~/types/models/IInvoice'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryInvoices = (
  params: Partial<IGetInvoicesParams>,
  options?: Partial<UseQueryOptions<IInvoice[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'invoices',
      sessionUser?.id,
      buildGetUrl(apiClient.invoices.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.invoices.get(params)
      return response.invoices
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const invoicesData = useMemo(() => data || [], [data])

  const invoiceOptions = useMemo(
    () =>
      invoicesData.map(invoice => {
        let label = invoice.num ? `#${invoice.num} ` : ''
        label += getTitleRange(invoice)

        return {
          value: invoice.id,
          label: label,
          total: invoice.total,
          tax: invoice.tax,
          sub: invoice.sub,
        }
      }),
    [invoicesData],
  )

  return {
    invoicesData,
    invoiceOptions,
    isLoadingInvoicesData: isLoading,
    refetchQueryInvoices: refetch,
    isInvoicesFetched: isFetched,
  }
}

export default useQueryInvoices
