import {
  documentRoutesPaths,
  companyRoutesPaths,
  invoicesPaths,
} from '~/constants/routesPaths'
import { formatMetaData, mapPropertyIds } from '~/utils'

const formatDocumentGraphCard = (dashboardData, newArray = []) => {
  const parsedDocumentData = parseDashboardDocumentData(dashboardData)

  newArray.push(
    getGraphData(dashboardData['byDocTypeCount'], { ...documentGraphProps[0] }),
  )

  newArray.push(
    getGraphData(dashboardData['byDocTypeSumOfTotalPages'], {
      ...documentGraphProps[3],
    }),
  )

  newArray.push(
    getGraphData(dashboardData['byDocTypeCount'], { ...documentGraphProps[0] }),
  )

  newArray.push(
    getGraphData(parsedDocumentData['Load'], { ...documentGraphProps[1] }),
  )
  newArray.push(
    getGraphData(parsedDocumentData['Invoice'], { ...documentGraphProps[2] }),
  )

  return newArray
}

const parseDashboardDocumentData = data => {
  const groupByHash = data['by["docType","status"]Count']

  const newHash = {
    Invoice: {
      New: groupByHash['["Invoice","New"]'] || 0,
      Used: groupByHash['["Invoice","Used"]'] || 0,
    },
    Load: {
      New: groupByHash['["Load","New"]'] || 0,
      Used: groupByHash['["Load","Used"]'] || 0,
      Issue: groupByHash['["Load","Issue"]'] || 0,
    },
    NotSet: {
      New: groupByHash['["NotSet","New"]'] || 0,
      Used: groupByHash['["NotSet","Used"]'] || 0,
    },
  }

  return newHash
}

const getLoadsByStatus = data => {
  const doneByP = data.byPrimaryParserStatusCount.done || 0
  const noCollectionByP = data.byPrimaryParserStatusCount.noCollection || 0
  const notSentByP = data.byPrimaryParserStatusCount.notSent || 0
  const waitingByP = data.byPrimaryParserStatusCount.waiting || 0
  const waitingByH = data.byHitlStatusCount.waiting || 0
  const notSentByH = data.byHitlStatusCount.notSet || 0

  const totalLoadsByStatus = {
    title: 'Loads by status',
    data: [
      ['Status', '# of loads', 'urlParams'],
      ['Done with parsing', doneByP, 'primaryParserStatus=done'],
      ['No Collection', noCollectionByP, 'primaryParserStatus=noCollection'],
      ['Not Sent to parsing', notSentByP, 'primaryParserStatus=notSent'],
      ['Waiting on parsing', waitingByP, 'primaryParserStatus=waiting'],
      ['Waiting on HITL', waitingByH, 'hitlStatus=waiting'],
      ['Not sent to HITL', notSentByH, 'hitlStatus=notSent'],
      ['Done', 0, 'primaryParserStatus=done&hitlStatus=done'],
      // Done is coming out negative, crashing the chart.  Waiting for good data
      // (data.count - notSentByP - waitingByP - waitingByH)
    ],
    urlParams: '',
    loadsByStatus: true,
    metaData: true,
  }

  return totalLoadsByStatus
}

const getInvoicesByStatus = (data, invoiceKey) => {
  const totalInvoicesByStatus = {
    title: 'Invoices by Status',
    data: [
      ['Status', '# of invoices', 'urlParams'],
      ['Progress', data[invoiceKey].progress || 0, 'statuses=Progress'],
      ['Processed', data[invoiceKey].processed || 0, 'statuses=Processed'],
      ['Approved', data[invoiceKey].approved || 0, 'statuses=Approved'],
      ['Rejected', data[invoiceKey].rejected || 0, 'statuses=Rejected'],
      ['Uploaded', data[invoiceKey].uploaded || 0, 'statuses=Uploaded'],
    ],
    urlParams: '',
    invoicesByStatus: true,
    metaData: true,
  }

  return totalInvoicesByStatus
}

const documentGraphProps = [
  {
    title: 'Total Documents',
    data: [['Doc Type', '# of docs']],
    urlParams: 'docType=',
    byDocType: true,
  },
  {
    title: 'Load Documents by Status',
    data: [['Status', '# of docs']],
    urlParams: 'docType=load&status=',
  },
  {
    title: 'Invoice Documents by Status',
    data: [['Status', '# of docs']],
    urlParams: 'docType=invoice&status=',
  },
  {
    title: 'Total Pages',
    data: [['Doc Type', '# of docs']],
    urlParams: 'docType=',
    byDocType: true,
  },
]

const loadGraphProps = [
  {
    title: 'Loads by Buyer Terminal',
    data: [['Buyer Terminals', '# from each', 'urlParams']],
    urlParams: 'buyerTerminalId=',
    metaData: true,
  },
  {
    title: 'Loads by Product',
    data: [['Products', '# from each', 'urlParams']],
    urlParams: 'productId=',
    metaData: true,
  },
]

const invoiceGraphProps = [
  {
    title: 'Invoices by category',
    data: [['Category', '# of invoices']],
    urlParams: 'types=',
  },
  {
    title: 'Invoices by Seller',
    data: [['Seller', '# of Invoices by seller', 'urlParams']],
    urlParams: 'sellers=',
    metaData: true,
  },
]

const getGraphData = (data, graphPresets) => {
  const newDataArray = [...graphPresets.data]

  if (!Array.isArray(data)) {
    for (const key in data) {
      const capitalizeString = key.charAt(0).toUpperCase() + key.slice(1)
      newDataArray.push([capitalizeString || 'Not Set', data[key]])
    }
    graphPresets.data = newDataArray
  } else {
    for (let i = 0; i < data.length; i++) {
      newDataArray.push(data[i])
    }
    graphPresets.data = newDataArray
  }

  return graphPresets
}

const formatLoadGraphCard = (
  dashboardData,
  buyerTerminals,
  products,
  newArray = [],
) => {
  const newBuyerTerminals = formatMetaData(buyerTerminals)
  const formattedTerminals = mapPropertyIds(
    dashboardData['byBuyerTerminalIdCount'],
    newBuyerTerminals,
  )
  const formattedProducts = mapPropertyIds(
    dashboardData.byBuyerSellerProductIdCount,
    products,
  )

  newArray.push(getLoadsByStatus(dashboardData))
  newArray.push(getGraphData(formattedTerminals, { ...loadGraphProps[0] }))
  newArray.push(getGraphData(formattedProducts, { ...loadGraphProps[1] }))

  return newArray
}

const formatInvoiceGraphCard = (dashboardData, sellers, newArray = []) => {
  const newSellersList = formatMetaData(sellers, 'seller')
  const formattedSellers = mapPropertyIds(
    dashboardData['bySellerIdCount'],
    newSellersList,
  )

  newArray.push(
    getGraphData(dashboardData['byCategoryCount'], { ...invoiceGraphProps[0] }),
  )
  newArray.push(getInvoicesByStatus(dashboardData, 'byStatusCount'))
  newArray.push(getGraphData(formattedSellers, { ...invoiceGraphProps[1] }))

  return newArray
}

export const dashboardGraphCardHelper = (
  dashboardData,
  sellers,
  buyerTerminals,
  products,
) => {
  switch (dashboardData.model) {
    case 'document': {
      return formatDocumentGraphCard(dashboardData)
    }
    case 'load': {
      return formatLoadGraphCard(dashboardData, buyerTerminals, products)
    }
    case 'invoice': {
      return formatInvoiceGraphCard(dashboardData, sellers)
    }
    default: {
      return
    }
  }
}

export const dashboardGraphCardOnClick = (
  chartWrapper,
  google,
  entry,
  history,
  modelType,
  createdDateRange,
) => {
  const chart = chartWrapper.getChart()
  const dataTable = chartWrapper.getDataTable()
  const selection = chart.getSelection()
  const sliceLabel = dataTable.getFormattedValue(selection[0].row, 0)
  const lowerCaseLabel =
    sliceLabel.charAt(0).toLowerCase() + sliceLabel.slice(1)
  let urlParams = undefined
  const { startDate, endDate } = createdDateRange
  const urlDateString = `startDate=${startDate}&endDate=${endDate}`

  if (entry.metaData) {
    urlParams = dataTable.getFormattedValue(selection[0].row, 2)
  }

  switch (modelType) {
    case 'document': {
      if (entry.byDocType) {
        history.push(
          documentRoutesPaths.documents +
            '?' +
            entry.urlParams +
            lowerCaseLabel +
            '&' +
            urlDateString,
        )
      } else {
        history.push(
          documentRoutesPaths.documents +
            '?' +
            entry.urlParams +
            sliceLabel +
            '&' +
            urlDateString,
        )
      }
      break
    }
    case 'load': {
      if (entry.loadsByStatus) {
        history.push(
          companyRoutesPaths.loads + '?' + urlParams + '&' + urlDateString,
        )
      } else {
        history.push(
          companyRoutesPaths.loads +
            '?' +
            entry.urlParams +
            urlParams +
            '&' +
            urlDateString,
        )
      }
      break
    }
    case 'invoice': {
      if (urlParams) {
        if (entry.invoicesByStatus) {
          history.push(
            invoicesPaths.index + '?' + urlParams + '&' + urlDateString,
          )
        } else {
          history.push(
            invoicesPaths.index +
              '?' +
              entry.urlParams +
              urlParams +
              '&' +
              urlDateString,
          )
        }
      } else {
        history.push(
          invoicesPaths.index +
            '?' +
            entry.urlParams +
            sliceLabel +
            '&' +
            urlDateString,
        )
      }
      break
    }
    default:
      break
  }
}
