import { CompanyAvatar } from '~/components/shared'

import _ from 'lodash'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './CompanyFormatOptionLabel.scss'

const CompanyFormatOptionLabel = props => {
  const {
    label,
    image,
    imageUrl,
    className,
    name,
    hideAvatar,
    value,
    onClick,
  } = props

  const isNotSet = _.toLower(label) === 'not set'

  const renderAvatar = () => {
    if (isNotSet || _.size(label) === 0 || hideAvatar) {
      return null
    }

    return (
      <CompanyAvatar
        showPopupImage
        width={28}
        height={28}
        companyType='seller'
        company={{
          label,
          name,
          value,
          image: image || imageUrl,
        }}
      />
    )
  }

  const handleClickOption = event => {
    onClick && onClick(event, { label, value, name, image, imageUrl })
  }

  return (
    <div
      className={clsx('CompanyFormatOptionLabel__container', className, {
        notSet: label === '',
      })}
      onClick={handleClickOption}
    >
      {renderAvatar()}
      <div className='CompanyFormatOptionLabel__label'>{label}</div>
    </div>
  )
}

CompanyFormatOptionLabel.propTypes = {
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
}

export default CompanyFormatOptionLabel
